import * as React from "react";
import { styled } from "@boligportal/juice";
import { css } from "@emotion/react";
import { useDebounce } from "hooks/useDebounce";

const inputStyles = (props) => css`
appearance: none;
display: block;
width: 100%;
transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
color: ${props.theme.color.text.base};
border: none;
padding-left: ${props.theme.unit(5)};
padding-right: ${props.theme.unit(1.5)};
padding-top: ${props.theme.unit(1)};
padding-bottom: ${props.theme.unit(1)};
border-radius: ${props.theme.borderRadius.sm};
background: white;
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23B9BFC2'%3E%3Cpath d='M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z'/%3E%3C/svg%3E");
background-repeat: no-repeat;
background-position: ${props.theme.unit(1)}; center;
background-size: 24px 24px;
font-weight: ${props.theme.fontWeight.base};
font-size: ${props.theme.fontSize.body};
line-height: ${props.theme.lineHeight};
outline: none;
box-shadow: 0 0.4px 0.7px rgba(0, 0, 0, 0.07), 0 1.3px 2.2px rgba(0, 0, 0, 0.042);
box-shadow: inset 0 -1px 0 1px ${
  props.theme.color.inputBorder
}, inset 0 0 0 1px ${props.theme.color.inputBorder};

&:hover {
  background-color: ${props.theme.colorPalette.gray[10]};
}

&:disabled {
  cursor: not-allowed;
  color: ${props.theme.colorPalette.gray[70]};
  background-color: ${props.theme.colorPalette.gray[10]};
}

&:focus {
  outline: none;
  background-color: white;
  box-shadow: inset 0 0 0 2px ${props.theme.color.focusBorder};
}

&::placeholder {
  color: ${props.theme.color.inputPlaceholder};
}

&:-webkit-autofill,
&:-internal-autofill-selected,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus {
  -webkit-text-fill-color: ${props.theme.color.text.base};
  -webkit-box-shadow: inset 0 -1px 0 1px ${
    props.theme.colorPalette.yellow[200]
  },
    inset 0 0 0 1px ${props.theme.colorPalette.yellow[200]}, 0 0 0 30px ${
      props.theme.colorPalette.yellow[50]
    } inset !important;
}
`;

const StyledInput = styled.input`
  ${inputStyles};
`;

interface Props {
  onValueChanged: (value: string) => void;
  delay?: number;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

const DebouncedSearchInput = ({
  delay,
  placeholder,
  onValueChanged,
  value,
  disabled,
}: Props) => {
  const [inputFilter, setInputFilter] = React.useState(value || "");
  const inputFilterDebounced = useDebounce(inputFilter, delay ? delay : 250);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputFilter(event.target.value);
  };

  React.useEffect(() => {
    onValueChanged(inputFilter);
  }, [inputFilterDebounced]);

  return (
    <StyledInput
      disabled={disabled}
      type="search"
      onChange={handleChange}
      value={inputFilter}
      placeholder={placeholder}
      formNoValidate
      autoComplete="none"
    />
  );
};

export { DebouncedSearchInput };
