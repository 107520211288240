import { Box, Notice } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { useIsParentWidthLessThan } from "hooks/useIsParentWidthLessThan";
import { t } from "lib/i18n";
import { UpsellProps } from "../../types";
import { GridContainer } from "./components/CardGrids";
import { ProLandlordUpsellScreenCardList } from "./components/ProLandlordUpsellScreenCardList";
import { VolumeBarsDisplayLabelRow } from "./components/VolumeBarsDisplayLabelRow";

export const ProLandlordUpsellScreen = ({
  activeUpsellSubscriptions,
  handleOpenModal,
  listingId,
  plans,
  isPrivateLandlord,
  isAdLessThan24HoursOld,
}: UpsellProps) => {
  const { containerWidthIsLessThanSpecified, containingRef } =
    useIsParentWidthLessThan(900);

  const handleRequestBuyPlan = (plan: Plan) => {
    handleOpenModal(plan, listingId);
  };

  const filteredPlans = Object.keys(plans).filter(
    (key) => plans[key].length > 0,
  );
  const numberOfPlans = filteredPlans.length;

  if (filteredPlans.length < 3) {
    return (
      <Box pt={2}>
        <Notice type="info">
          Missing plans for Pro Landlords. Please contact customer support...
        </Notice>
      </Box>
    );
  }

  return (
    <>
      <VolumeBarsDisplayLabelRow
        text={t("upsell_products.pro_landlord.volume_bars.label")}
      />
      <GridContainer
        numberOfPlans={numberOfPlans}
        ref={containingRef}
      >
        {filteredPlans.map((plansKey) => {
          return (
            <ProLandlordUpsellScreenCardList
              key={plansKey}
              plans={plans[plansKey]}
              activeUpsellSubscriptions={activeUpsellSubscriptions}
              onRequestBuyPlan={handleRequestBuyPlan}
              hasVolumeBars={containerWidthIsLessThanSpecified}
              isPrivateLandlord={isPrivateLandlord}
              isAdLessThan24HoursOld={isAdLessThan24HoursOld}
            />
          );
        })}
      </GridContainer>
    </>
  );
};
