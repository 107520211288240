import { http } from "@boligportal/juice";
import { fetchFromAPI, RequestSource } from "lib/api";
import {
  DefectActionType,
  getDefectActionLabel,
  MovingReportType,
  UnitCondition,
} from "./enums";
import { EmailRecipient } from "./interfaces/additionalEmailRecipient";
import {
  CustomAction,
  DefaultAction,
  DefectStatus,
  DefectUpdate,
  NewDefectMultipleUnits,
} from "./interfaces/defect";
import { Key } from "./interfaces/keys";
import { Meter } from "./interfaces/meter";
import { MovingReport } from "./interfaces/movingReport";
import { MovingReportPatch } from "./interfaces/movingReportPatch";
import { Room } from "./interfaces/room";
import { Tenant } from "./interfaces/tenant";
import { Unit } from "./interfaces/unit";
import {
  DuplicateMovingReportRequestPayload,
  GetMovingReportsRequestPayload,
} from "./store/slices/movingReports";
import { MovingReportForTenant } from "./tenant_app/interfaces/movingReportForTenant";
import { NewDefectByTenant } from "./tenant_app/interfaces/newDefectByTenant";

const baseUrl = "/api/moving_reports";

export type LandlordRemarksUpdate = {
  status_id: number | null;
  freehand_status: string | null;
  comment: string | null;
};

export interface MovingReportResponse {
  report: MovingReport;
  errors: any;
}

interface DefaultActionsResponse {
  default_defect_actions: DefaultAction[];
}

interface DefaultActionResponse {
  default_defect_action: DefaultAction;
}

interface CustomActionsResponse {
  custom_defect_actions: CustomAction[];
}

interface CustomActionResponse {
  custom_defect_action: CustomAction;
}

interface DefaultDefectStatuses {
  default_landlord_defect_statuses: DefectStatus[];
}

export class API {
  // REPORTS

  static async createMovingReport(
    rentable_id: number,
    type: MovingReportType,
    isInitiatedFromApp?: boolean,
    tenancy_id?: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/moving_report/`, {
        method: "POST",
        body: JSON.stringify({
          rentable_id,
          tenancy_id,
          type,
        }),
        ...(isInitiatedFromApp
          ? {
              "X-Request-Source": RequestSource.MOVING_REPORTS_APP,
            }
          : {}),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getMovingReport(
    moving_report_id: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/`,
        {
          method: "GET",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getCopyRecipients(moving_report_id: number) {
    return http.get<{
      additional_email_recipients: Required<EmailRecipient>[];
    }>(
      `moving_reports/moving_report/${moving_report_id}/additional_email_recipients/`,
    );
  }

  static async createCopyRecipients(
    moving_report_id: number,
    data: { email_address: string },
  ) {
    return http.post<{ additional_email_recipient: Required<EmailRecipient> }>(
      `moving_reports/moving_report/${moving_report_id}/additional_email_recipients/`,
      data,
    );
  }

  static async updateCopyRecipients(
    moving_report_id: number,
    recipient_id: number,
    data: { email_address: string },
  ) {
    return http.put<Required<EmailRecipient>>(
      `moving_reports/moving_report/${moving_report_id}/additional_email_recipients/${recipient_id}/`,
      data,
    );
  }

  static async deleteCopyRecipients(
    moving_report_id: number,
    recipient_id: number,
  ) {
    return http.delete(
      `moving_reports/moving_report/${moving_report_id}/additional_email_recipients/${recipient_id}/`,
    );
  }

  static async updateMovingReport(
    moving_report_id: number,
    reportUpdate: Partial<MovingReportPatch>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify(reportUpdate),
        },
      );

      if (!response.ok) {
        return Promise.reject(await response.json());
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async duplicateMovingReport(
    moving_report_id: number,
    payload: DuplicateMovingReportRequestPayload,
    isInitiatedFromApp?: boolean,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/duplicate/`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          ...(isInitiatedFromApp
            ? {
                "X-Request-Source": RequestSource.MOVING_REPORTS_APP,
              }
            : {}),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getMovingReports(
    payload: Partial<GetMovingReportsRequestPayload>,
  ) {
    try {
      const response = await fetchFromAPI(`${baseUrl}/moving_reports/`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateAllReportUnitsCondition(
    moving_report_id: number,
    condition: UnitCondition,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/update_units_condition/`,
        {
          method: "PATCH",
          body: JSON.stringify({
            condition,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteReport(reportId: number) {
    return http.delete(`/moving_reports/moving_report/${reportId}/delete/`);
  }

  // ROOMS

  static async createRoom(
    moving_report_id: number,
    room: Partial<Room>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/room/`, {
        method: "POST",
        body: JSON.stringify({
          moving_report_id,
          ...room,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateRoom(
    roomId: number,
    roomUpdate: Partial<Room>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/room/${roomId}/update/`, {
        method: "PATCH",
        body: JSON.stringify(roomUpdate),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteRoom(room_id: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/room/${room_id}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateAllRoomUnitsCondition(
    roomId: number,
    condition: UnitCondition,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/room/${roomId}/update_units_condition/`,
        {
          method: "PATCH",
          body: JSON.stringify({
            condition,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static setRoomOrder = (moving_report_id: number, room_order: number[]) =>
    http.put<MovingReportResponse>(
      `/moving_reports/moving_report/${moving_report_id}/set_room_order/`,
      {
        room_order,
      },
    );

  // METERS

  static async createMeter(
    moving_report_id: number,
    meter: Partial<Meter>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/meter/`, {
        method: "POST",
        body: JSON.stringify({
          moving_report_id,
          ...meter,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateMeter(
    meterId: number,
    meterUpdate: Partial<Meter>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/meter/${meterId}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify(meterUpdate),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteMeter(meter_id: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/meter/${meter_id}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // KEYS

  static async createKey(
    moving_report_id: number,
    key: Partial<Key>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/key/`, {
        method: "POST",
        body: JSON.stringify({
          moving_report_id,
          ...key,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateKey(
    keyId: number,
    keyUpdate: Partial<Key>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/key/${keyId}/update/`, {
        method: "PATCH",
        body: JSON.stringify(keyUpdate),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteKey(keyId: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/key/${keyId}/delete/`, {
        method: "DELETE",
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateKeysImages(
    moving_report_id: number,
    image_ids: number[],
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/keys/images/`,
        {
          method: "PATCH",
          body: JSON.stringify({
            image_ids,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // TENANTS

  static async createTenant(
    moving_report_id: number,
    tenant: Partial<Tenant>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/tenant/`, {
        method: "POST",
        body: JSON.stringify({
          moving_report_id,
          ...tenant,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateTenant(
    tenantId: number,
    tenantUpdate: Partial<Tenant>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenantId}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify(tenantUpdate),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteTenant(tenant_id: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenant_id}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // UNITS

  static async createUnit(
    room_id: number,
    unit: Partial<Unit>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/unit/`, {
        method: "POST",
        body: JSON.stringify({
          room_id,
          ...unit,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateUnit(
    unitId: number,
    unitUpdate: Partial<Unit>,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/unit/${unitId}/update/`, {
        method: "PATCH",
        body: JSON.stringify(unitUpdate),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteUnit(unit_id: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/unit/${unit_id}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // DEFECTS

  static async createDefectMultipleUnits(
    defect: NewDefectMultipleUnits,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/multi_defect/`, {
        method: "POST",
        body: JSON.stringify(defect),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateDefect(
    defectId: number,
    defectUpdate: DefectUpdate,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/defect/${defectId}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify(defectUpdate),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteDefect(defect_id: number): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/defect/${defect_id}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getDefectStatuses(): Promise<DefectStatus[]> {
    const { default_landlord_defect_statuses } =
      await http.get<DefaultDefectStatuses>(
        "/moving_reports/landlord_defect_status/default/",
      );

    return default_landlord_defect_statuses;
  }

  static async updateDefectRemarks(
    defectId: number,
    remarks: LandlordRemarksUpdate,
  ) {
    return http.patch(
      `/moving_reports/defect/${defectId}/landlord_remarks/`,
      remarks,
    );
  }

  static async updateViewedByLandlord(
    defectIds: number[],
  ): Promise<MovingReportResponse> {
    return http.post("/moving_reports/defect/bulk_mark_viewed_by_landlord/", {
      defect_ids: defectIds,
    });
  }

  // DEFECT ACTIONS

  static async getDefaultActions() {
    return http.get<DefaultActionsResponse>(
      "/moving_reports/defect_actions/default/",
    );
  }

  static async getDefaultAction(id: number) {
    return http.get<DefaultActionResponse>(
      `/moving_reports/defect_actions/default/${id}`,
    );
  }

  static async deleteDefaultAction(id: number) {
    return http.delete(`/moving_reports/defect_actions/default/${id}/`);
  }

  static async getCustomActions() {
    return http.get<CustomActionsResponse>(
      "/moving_reports/defect_actions/custom/",
    );
  }

  static async createCustomAction(description: string) {
    return http.post<CustomActionResponse>(
      "/moving_reports/defect_actions/custom/",
      {
        description,
      },
    );
  }

  static async getCustomAction(id: number) {
    return http.get<CustomActionResponse>(
      `/moving_reports/defect_actions/custom/${id}`,
    );
  }

  static async updateCustomAction(id: number, description: string) {
    return http.put(`/moving_reports/defect_actions/custom/${id}/`, {
      description,
    });
  }

  static async deleteCustomAction(id: number) {
    return http.delete(`/moving_reports/defect_actions/custom/${id}/`);
  }

  static async resetActions() {
    return http.post("/moving_reports/defect_actions/reset/");
  }

  static async getReportDefaultActions(reportId: number) {
    return http.get<DefaultActionsResponse>(
      `/moving_reports/moving_report/${reportId}/defect_actions/default/`,
    );
  }

  static async getReportCustomActions(reportId: number) {
    return http.get<CustomActionsResponse>(
      `/moving_reports/moving_report/${reportId}/defect_actions/custom/`,
    );
  }

  /**
   * Use report id to get actions for specific report
   * and use no parameters to get actions for user
   **/
  static async getReportCombinedActions(reportId?: number) {
    const requests: [
      Promise<DefaultActionsResponse>,
      Promise<CustomActionsResponse>,
    ] = reportId
      ? [
          this.getReportDefaultActions(reportId),
          this.getReportCustomActions(reportId),
        ]
      : [this.getDefaultActions(), this.getCustomActions()];

    const [{ default_defect_actions }, { custom_defect_actions }] =
      await Promise.all(requests);

    const defaultActions = default_defect_actions.map((item) => ({
      id: item.id,
      description: getDefectActionLabel(item.keyword),
      value: item.keyword,
      type: DefectActionType.DEFAULT,
    }));

    const customActions = custom_defect_actions.map((item) => ({
      id: item.id,
      description: item.description,
      value: item.description,
      type: DefectActionType.CUSTOM,
    }));

    return [...defaultActions, ...customActions];
  }

  // TENANT_HUB Defects Page API

  static async getMovingReportForTenantHub(payload: {
    moving_report_id: number;
    accessKey: string;
  }): Promise<MovingReportForTenant> {
    try {
      const response = await fetchFromAPI(
        `/api/tenant-hub/${payload.accessKey}/moving_report/${payload.moving_report_id}`,
        {
          method: "GET",
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createDefectsByUnitTypeByTenantHub(payload: {
    defect: NewDefectByTenant;
    accessKey: string;
  }): Promise<MovingReportForTenant> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant_app/${payload.accessKey}/defect/`,
        {
          method: "POST",
          body: JSON.stringify(payload.defect),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // TENANT HOME API

  static async getMovingReportForTenant(
    moving_report_id: number,
  ): Promise<MovingReportForTenant> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant_app/moving_report/${moving_report_id}/`,
        {
          method: "GET",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createDefectsByUnitTypeByTenant(
    defect: NewDefectByTenant,
  ): Promise<MovingReportForTenant> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/tenant_app/defect/`, {
        method: "POST",
        body: JSON.stringify(defect),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // SIGNING

  static async landlordSigned(
    moving_report_id: number,
    landlord_representative_signature_image_id: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/landlord_signed/`,
        {
          method: "POST",
          body: JSON.stringify({
            landlord_representative_signature_image_id,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async tenantSigned(
    tenant_id: number,
    signature_image_id: number,
    is_content_approved: boolean,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenant_id}/signed/`,
        {
          method: "POST",
          body: JSON.stringify({
            signature_image_id,
            is_content_approved,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async tenantRefusedToSign(
    tenant_id: number,
    is_content_approved: boolean,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenant_id}/refused_to_sign/`,
        {
          method: "POST",
          body: JSON.stringify({
            is_content_approved,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async undoTenantRefusedToSign(
    tenant_id: number,
    is_content_approved: boolean,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenant_id}/undo_refused_to_sign/`,
        {
          method: "POST",
          body: JSON.stringify({
            is_content_approved,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async validateReport(moving_report_id: number): Promise<any> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/validate/`,
        {
          method: "GET",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async finalizeReport(
    moving_report_id: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/finalize/`,
        {
          method: "POST",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async removeSignatures(
    moving_report_id: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/moving_report/${moving_report_id}/remove_signatures/`,
        {
          method: "POST",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async removeTenantsSignatures(
    tenant_id: number,
  ): Promise<MovingReportResponse> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenant_id}/remove_tenant_signatures/`,
        {
          method: "POST",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static getCalendarEventLink = (uuid: string) =>
    `/moving-reports/add_to_calendar/${uuid}`;

  static updateMovingReportFromTenancySelector = (
    movingReportId: number,
    tenancyId: number | null,
  ) =>
    http.patch<MovingReportResponse>(
      `moving_reports/moving_report/${movingReportId}/set_tenancy/`,
      {
        tenancy_id: tenancyId,
      },
    );
}
