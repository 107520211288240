import * as React from "react";
import { Box } from "@boligportal/juice";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  StyledImage,
  StyledImageFooter,
  StyledImageWrapper,
  StyledMenuWrapper,
  StyledPrimaryWrapper,
} from "./imageStyles";

export const DraggableImageItem = ({
  imageId,
  imageUrl,
  imagePrimaryComponent,
  imageOptionsComponent,
  imageFloorPlanComponent,
  imageControlsComponent,
  imageItemStyles: styles,
  fixedHeight,
}: {
  imageId: string;
  imageUrl: string;
  imagePrimaryComponent: React.ReactNode;
  imageFloorPlanComponent: React.ReactNode;
  imageControlsComponent: React.ReactNode;
  imageOptionsComponent: React.ReactNode;
  imageItemStyles?: React.CSSProperties;
  fixedHeight?: number;
}) => {
  const {
    index,
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: imageId,
    transition: {
      duration: 300,
      easing: "cubic-bezier(0.65, 0, 0.35, 1)",
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.0 : 1.0,
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
      }}
    >
      <div
        style={{
          ...styles,
        }}
      >
        <StyledImageWrapper fixedHeight={fixedHeight}>
          <StyledImage
            src={imageUrl}
            {...attributes}
            {...listeners}
            style={{
              cursor: "grab",
            }}
          />
          <StyledPrimaryWrapper visible>
            <Box hidden={index > 0}>{imagePrimaryComponent}</Box>
            {imageFloorPlanComponent}
          </StyledPrimaryWrapper>
          <StyledMenuWrapper>{imageOptionsComponent}</StyledMenuWrapper>
          <StyledImageFooter>{imageControlsComponent}</StyledImageFooter>
        </StyledImageWrapper>
      </div>
    </div>
  );
};
export const NonDraggableImageItem = ({
  imageId,
  imageUrl,
  imageOptionsComponent,
  imageControlsComponent,
  imageItemStyles: styles,
}: {
  imageId: string;
  imageUrl: string;
  imagePrimaryComponent: React.ReactNode;
  imageFloorPlanComponent: React.ReactNode;
  imageControlsComponent: React.ReactNode;
  imageOptionsComponent: React.ReactNode;
  imageItemStyles?: React.CSSProperties;
}) => {
  const { setNodeRef } = useSortable({
    id: imageId,
    transition: {
      duration: 300,
      easing: "cubic-bezier(0.65, 0, 0.35, 1)",
    },
  });

  return (
    <div ref={setNodeRef}>
      <div
        style={{
          ...styles,
        }}
      >
        <StyledImageWrapper>
          <StyledImage src={imageUrl} />
          <StyledMenuWrapper>{imageOptionsComponent}</StyledMenuWrapper>
          {imageControlsComponent && (
            <StyledImageFooter>{imageControlsComponent}</StyledImageFooter>
          )}
        </StyledImageWrapper>
      </div>
    </div>
  );
};
