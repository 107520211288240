import { useState } from "react";
import { Flex, styled } from "@boligportal/juice";
import { css } from "@emotion/react";
import { t } from "lib/i18n";
import {
  useRentalAdsFeature,
  useRentalAdsState,
} from "../../RentalAdsProvider";
import { RentalAdViewQueryParamsForAdStateEnum } from "../../enums/RentalAdViewQueryParamsForAdStateEnum";

export const RentalAdsAdStateTabsFilter = () => {
  const {
    ad_state,
    advertisedCount,
    reservedCount,
    rentedOutCount,
    draftCount,
    allCount,
  } = useRentalAdsState();
  const { updateAdState } = useRentalAdsFeature();

  return (
    <Flex gap={1}>
      <CustomTab
        label={`${t("rentable_list.advertised")} (${advertisedCount})`}
        value={RentalAdViewQueryParamsForAdStateEnum.ADVERTISED}
        onSelect={updateAdState}
        active={ad_state === RentalAdViewQueryParamsForAdStateEnum.ADVERTISED}
      />
      <CustomTab
        label={`${t("rentable_list.reserved")} (${reservedCount})`}
        value={RentalAdViewQueryParamsForAdStateEnum.RESERVED}
        onSelect={updateAdState}
        active={ad_state === RentalAdViewQueryParamsForAdStateEnum.RESERVED}
      />
      <CustomTab
        label={`${t("rentable_list.rented_out")} (${rentedOutCount})`}
        value={RentalAdViewQueryParamsForAdStateEnum.RENTED_OUT}
        onSelect={updateAdState}
        active={ad_state === RentalAdViewQueryParamsForAdStateEnum.RENTED_OUT}
      />
      <CustomTab
        label={`${t("rentable_list.draft")} (${draftCount})`}
        value={RentalAdViewQueryParamsForAdStateEnum.DRAFT}
        onSelect={updateAdState}
        active={ad_state === RentalAdViewQueryParamsForAdStateEnum.DRAFT}
      />
      <CustomTab
        label={`${t("rentable_list.all")} (${allCount})`}
        value={RentalAdViewQueryParamsForAdStateEnum.ALL}
        onSelect={updateAdState}
        active={ad_state === RentalAdViewQueryParamsForAdStateEnum.ALL}
      />
    </Flex>
  );
};

interface CustomTabProps<T extends unknown> {
  label: string;
  value: T;
  active: boolean;
  onSelect: (value: T) => void;
}

export const CustomTab = <T extends unknown>({
  label,
  value,
  active,
  onSelect,
}: CustomTabProps<T>) => {
  const [hasMouseOver, setHasMouseOver] = useState(false);

  const clickHandler = () => {
    onSelect(value);
  };

  const mouseOverHandler = () => {
    setHasMouseOver(true);
  };

  const mouseOutHandler = () => {
    setHasMouseOver(false);
  };

  return (
    <StyledTab
      onClick={clickHandler}
      onMouseOver={mouseOverHandler}
      onMouseOut={mouseOutHandler}
    >
      <StyledTabButton
        isActiveTab={active}
        hasMouseOver={hasMouseOver}
      >
        {label}
      </StyledTabButton>
      <StyledTabMarker
        isActiveTab={active}
        hasMouseOver={hasMouseOver}
      />
    </StyledTab>
  );
};

const idleStyles = (props) => css`
  background-color: #ffffff;
  color: ${props.theme.colorPalette.gray[100]};
`;

const mouseOverStyles = (props) => css`
  background-color: ${props.theme.colorPalette.gray[40]};
  color: ${props.theme.colorPalette.gray[300]};
`;

const activeTabStyles = (props) => css`
  background-color: ${props.theme.colorPalette.primary[700]};
  color: ${props.theme.color.base};
`;

const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledTabMarker = styled.div<{
  isActiveTab: boolean;
  hasMouseOver: boolean;
}>`
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  ${(p) =>
    p.isActiveTab
      ? activeTabStyles
      : p.hasMouseOver
        ? mouseOverStyles
        : idleStyles}
`;

const StyledTabButton = styled.button<{
  isActiveTab: boolean;
  hasMouseOver: boolean;
}>`
  ${(p) =>
    p.isActiveTab
      ? activeTabStyles
      : p.hasMouseOver
        ? mouseOverStyles
        : idleStyles}
  background-color: transparent;
  border: none;

  padding: ${(p) => `${p.theme.unit(1)} ${p.theme.unit(2)}`};

  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  font-size: ${(p) => p.theme.fontSize.small};

  &:focus {
    outline: none;
  }
`;
