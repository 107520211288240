import {
  Button,
  Flex,
  Box,
  Text,
  Badge,
  IconArrowForward,
} from "@boligportal/juice";
import { PAGE_PAYMENTS } from "apps/customer_service";
import { terminateSubscription } from "lib/customerServiceApi";
import { subscriptionDateAndTime } from "lib/date";
import {
  SubscriptionPaymentsItemInterface,
  SubscriptionFeedItemInterface,
} from "./interfaces";

const CustomSubscriptionStatusBadge = (props: {
  subscription: SubscriptionFeedItemInterface;
}) => {
  let status = "";
  let color: "red" | "green" | "blue" | "yellow" = "red";

  const { subscription: sub } = props;
  const { temporal_status } = sub;

  switch (temporal_status) {
    case "EXPIRED":
      if (sub.activation_date === null && sub.ended_at === null) {
        // Hack: Upsell subscriptions that are not yet active incorrectly get temporal status "EXPIRED",
        // so we handle that here.
        // The real fix is to use the same structure as the AdSubscription interface and change the backend to serialize
        // using that structure as well. We want to get CS shipped now, hence this hack.
        status = "Not yet active";
      } else {
        status = "Expired";
      }
      color = "red";
      break;
    case "IN_GRACE_PERIOD":
      status = "In grace period";
      color = "blue";
      break;
    case "IN_PERIOD":
      status = "Active";
      color = "green";
      break;
    default:
      status = "";
  }

  return sub.canceled_at && sub.can_be_terminated ? null : (
    <Text
      mb={2}
      size="small"
    >
      <Badge color={color}>{status}</Badge>
    </Text>
  );
};

const CustomSubscriptionStatusBlock = (props: {
  subscription: SubscriptionFeedItemInterface;
}) => {
  const { subscription: sub } = props;

  const items: { label: string; value: string }[] = [];

  items.push({
    label: "Activated",
    value: sub.activation_date
      ? subscriptionDateAndTime(sub.activation_date)
      : "Not yet",
  });

  if (sub.canceled_at !== null && sub.temporal_status === "IN_PERIOD") {
    items.push({
      label: "Expires",
      value: subscriptionDateAndTime(sub.current_period_end!),
    });
  }

  if (sub.last_renewal_date !== null && sub.temporal_status === "IN_PERIOD") {
    items.push({
      label: "Last renewal",
      value: subscriptionDateAndTime(sub.last_renewal_date),
    });
  }

  if (sub.canceled_at !== null && sub.temporal_status === "EXPIRED") {
    items.push({
      label: "Expired",
      value: subscriptionDateAndTime(sub.ended_at!),
    });
  }

  if (
    sub.canceled_at === null &&
    sub.temporal_status === "IN_PERIOD" &&
    sub.current_period_end !== null
  ) {
    items.push({
      label: "Next renewal",
      value: subscriptionDateAndTime(sub.current_period_end),
    });
  }

  return (
    <Flex column>
      <Box inline>
        <Text
          weight="bold"
          mr={1}
        >
          {`${sub.plan}`}
        </Text>
        <CustomSubscriptionStatusBadge subscription={sub} />
      </Box>
      {items.map((item) => (
        <Flex key={item.value}>
          <Text
            size="tiny"
            mr={1}
          >
            {item.label}:
          </Text>
          <Text size="tiny">{item.value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

const CustomSubscriptionsFeed = ({
  items,
  userId,
  onSubscriptionUpdated,
}: {
  items: SubscriptionPaymentsItemInterface[];
  userId: number;
  onSubscriptionUpdated: () => void;
}) => {
  const doTerminateSubscription = async (subscriptionId: number) => {
    await terminateSubscription(subscriptionId);
    onSubscriptionUpdated();
  };

  return (
    <div>
      {items.map((item) => (
        <Flex
          column
          borderBottom
          mb={3}
          key={item.subscription.id}
        >
          <CustomSubscriptionStatusBlock subscription={item.subscription} />
          <Flex mt={1}>
            <Button
              disabled={item.subscription.can_be_terminated === false}
              onClick={() => doTerminateSubscription(item.subscription.id)}
            >
              Delete
            </Button>
          </Flex>
          <Flex
            my={2}
            gap
          >
            <Flex flexPush>
              <Button
                as="a"
                href={`${PAGE_PAYMENTS}?uid=${userId}&sid=${item.subscription.id}`}
                iconAfter={IconArrowForward}
              >
                View in payments page
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </div>
  );
};

export { CustomSubscriptionsFeed };
