import { TenantAgentView } from "apps/find_tenant/components/tenant_agent_view";
import { Agent } from "components/interfaces/agent";
import { PublicProfile } from "components/interfaces/profile";
import { CardDetails } from "components/tenant-card/card_details";
import { t } from "lib/i18n";
import * as ProfileUtils from "lib/profile";

interface Props {
  agents?: Agent[];
  profile?: PublicProfile;
  isNotResponsive?: boolean;
}

export const TenantCardDetails = ({
  agents,
  profile,
  isNotResponsive,
}: Props) => {
  const details = [
    {
      title: t("inbox.looking_for"),
      text:
        agents && agents.length ? (
          <TenantAgentView agent={agents[0]} />
        ) : (
          t("inbox.notSpecified")
        ),
    },
    {
      title: t("inbox.info"),
      text:
        ProfileUtils.profilePreferencesShortSummary(profile) ||
        t("inbox.notSpecified"),
    },
    {
      title: t("inbox.description"),
      text: (profile && profile.description) || t("inbox.notSpecified"),
    },
  ];

  return (
    <CardDetails
      details={details}
      isNotResponsive={isNotResponsive}
    />
  );
};
