import { styled } from "@boligportal/juice";

export const StyledImageWrapper = styled.div<{ fixedHeight?: number }>`
  position: relative;
  height: ${(props) => (props.fixedHeight ? `${props.fixedHeight}px` : "auto")};
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const StyledMenuWrapper = styled.div`
  position: absolute;
  top: ${(p) => p.theme.unit(1)};
  right: ${(p) => p.theme.unit(0)};
`;

export const StyledPrimaryWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: ${(p) => p.theme.unit(1)};
  left: ${(p) => p.theme.unit(1)};
  display: ${(p) => (p.visible ? "block" : "none")};
`;

export const StyledImage = styled.div<{ src: string; hideBorder?: boolean }>`
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  background-image: url(${(p) => p.src});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const StyledImageFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.35);
`;

export const StyledFakeImage = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23DEDEDE' stroke-width='3' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
