import { styled } from "@boligportal/juice";

export const VolumeBarFreeAd = styled.div<{ isHovered: boolean }>`
  width: 100%;
  height: 7px;
  background: url(/static/images/upsell/FreeAdBar${(props) =>
    props.isHovered ? "Hover" : ""}.svg);
  background-repeat: space;
`;

export const VolumeBarOpenAd = styled.div<{ isHovered: boolean }>`
  width: 100%;
  height: 14px;
  background: url(/static/images/upsell/OpenAdBar${(props) =>
    props.isHovered ? "Hover" : ""}.svg);
  background-repeat: space;
`;

export const VolumeBarTopAd = styled.div<{ isHovered: boolean }>`
  width: 100%;
  height: 24px;
  background: url(/static/images/upsell/TopAdBar${(props) =>
    props.isHovered ? "Hover" : ""}.svg);
  background-repeat: space;
`;

export const VolumeBarExposure = styled.div<{ isHovered: boolean }>`
  width: 100%;
  height: 60px;
  background: url(/static/images/upsell/ExposureBar${(props) =>
    props.isHovered ? "Hover" : ""}.svg);
  background-repeat: space;
`;

export const VolumeBarExposurePlus = styled.div<{ isHovered: boolean }>`
  width: 100%;
  height: 96px;
  background: url(/static/images/upsell/ExposurePlusBar${(props) =>
    props.isHovered ? "Hover" : ""}.svg);
  background-repeat: space;
`;
