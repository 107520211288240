import * as React from "react";

const SvgFloorplan = ({
  color = "#FFF",
  ...props
}: React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={22}
    height={18}
    viewBox="0 0 22 18"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <path
        fill="none"
        d="M0 0h22v18H0z"
      />
      <path
        fill={color}
        fillRule="nonzero"
        d="M17.167.5a1.89 1.89 0 011.889 1.889v6.27a1 1 0 01-1 1.002H15.94v1.511a.661.661 0 11-1.322 0v-1.51h-5.29a.661.661 0 110-1.323h1.04V6.828a.661.661 0 111.322 0v1.51h6.044v-5.95a.567.567 0 00-.474-.558l-.092-.008H11.69v1.795a.661.661 0 11-1.322 0V1.82l-5.478.001a.567.567 0 00-.56.475l-.007.092v5.95h1.795a.661.661 0 010 1.322H4.322v5.95c0 .282.206.515.475.56l.092.007h9.728v-1.795a.661.661 0 111.322 0V16.5a1 1 0 01-.999 1H4.889A1.889 1.889 0 013 15.611V2.39A1.89 1.89 0 014.889.5h12.278z"
      />
    </g>
  </svg>
);

export default SvgFloorplan;
