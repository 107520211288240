/* eslint-disable comma-dangle */
import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Hidden,
  SelectField,
} from "@boligportal/juice";
import { AdFiltersView } from "components/ad_filters_view";
import { Filters } from "components/interfaces/filters";
import { Option } from "components/interfaces/option";
import { OrderType } from "lib/enums";
import { t, tn } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { getSortingOptions } from "../../helpers/getSortingOptions";
import {
  Action_hidewMobileFilters,
  Action_toggleShowMobileFilters,
  Action_clearFilters,
  Action_updateFilters,
} from "../../search_result_reducer";

interface Props {
  showMobileFilters: boolean;
  dispatch: React.Dispatch<
    | Action_updateFilters
    | Action_clearFilters
    | Action_hidewMobileFilters
    | Action_toggleShowMobileFilters
  >;
  filters: Filters;
  isMap: boolean;
  categoryOptions: Array<Option>;
  count: number;
}

export const MobileFilterSlideOver = ({
  showMobileFilters,
  dispatch,
  filters,
  isMap,
  categoryOptions,
  count,
}: Props) => {
  const dispatchNewOrder = (newOrder: OrderType) => {
    TrackingEvent.filterClickedSorting();

    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        order: newOrder,
      },
    });
  };

  const dispatchClearFilters = () => {
    dispatch({
      payload: null,
      type: "clearFilters",
    });
  };

  return (
    <Box>
      <Dialog
        slideOver
        size="small"
        label="Filters"
        open={showMobileFilters}
        onClose={() =>
          dispatch({
            type: "hideMobileFilters",
          })
        }
      >
        <DialogHeader>{t("ad_filter.FilterSort")}</DialogHeader>
        <DialogContent>
          <SelectField
            label={t("sort_by.Sort Descriptor")}
            items={getSortingOptions()}
            onChange={(event) =>
              dispatchNewOrder(event.target.value as OrderType)
            }
          />
          <Hidden
            on={{
              lg: true,
            }}
          >
            <AdFiltersView
              dispatch={dispatch}
              filters={filters}
              categoryOptions={categoryOptions}
              isMapView={isMap}
            />
          </Hidden>
        </DialogContent>

        <DialogFooter>
          <Box mr="auto">
            <Button onClick={dispatchClearFilters}>
              {t("filters.clear_filter")}
            </Button>
          </Box>

          <Button
            variant="branded"
            onClick={() =>
              dispatch({
                type: "toggleShowMobileFilters",
              })
            }
          >
            {tn("ad_filter.Show %{smart_count} results", count)}
          </Button>
        </DialogFooter>
      </Dialog>
    </Box>
  );
};
