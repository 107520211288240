import { useContext, useState } from "react";
import { Button, IconArrowForward } from "@boligportal/juice";
import { useSubscription } from "api/queries";
import { FavouriteAdsContext } from "apps/my_favourite_ads";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { ContactLandlordModal } from "components/design-system/organisms/contact_landlord_modal/contact_landlord_modal";
import { Ad } from "components/interfaces/ad";
import { useOldModal } from "components/modal_controller";
import { useRegisterPaywall } from "features/paywall/PaywallProvider";
import { PlanAndPaymentSelectorDialog } from "features/paywall/coordinators/PlanAndPaymentSelectorDialog";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";

export const ContactLandlordPaywallButton = ({
  disabled,
  listing,
  isFreeContact,
  textButton,
}: {
  disabled: boolean;
  isFreeContact: boolean;
  listing: Ad;
  textButton?: boolean;
}) => {
  const [shouldDisplayPaymentSelector, setShouldDisplayPaymentSelector] =
    useState(false);
  const { showModal } = useOldModal();

  // In the MyFavoritesAdsHeader component we might render PurchasePremiumAccessPaywallBanner or ReactivatePremiumAccessBanner,
  // therefore we refetch the subscription when the user purchased a subscription.
  const { refetch } = useSubscription();

  const { plans, profile, onUpdate } = useContext(FavouriteAdsContext);

  const UNIQUE_PAYWALL_KEY = `favorite_listing_contact_landlord_${listing.id}`;
  const { setPaywallCandidate, paymentMethods } = useRegisterPaywall(
    UNIQUE_PAYWALL_KEY,
    plans,
    {
      onSubscriptionActivated: () => {
        TrackingEvent.favoritesPremiumAccessPurchase();
        refetch();
      },
      onPaymentFailedRetryButtonClick: () => {
        setShouldDisplayPaymentSelector(true);
      },
      onPaymentSuccessContinueButtonClick: () => {
        onUpdate().then(() => {
          showContactForm();
        });
      },
    },
  );

  const showContactForm = async () => {
    const { profile: fetchedProfile } = await API.getProfile();
    if (!profile && !fetchedProfile) {
      return;
    }

    if (listing.is_contactable_via_message) {
      await showModal(
        <ContactLandlordModal
          ad={listing}
          profile={profile ? profile : fetchedProfile}
          hideUpdateContactsDialog
          onSuccess={onUpdate}
        />,
      );
    } else {
      navigateToCrawledListing(listing.id);
    }
  };

  return (
    <>
      <ContactLandlordButton
        isFreeContact={isFreeContact}
        disabled={disabled}
        onShowContactForm={showContactForm}
        onShowPaymentSelector={() => setShouldDisplayPaymentSelector(true)}
        textButton={textButton}
      />
      {shouldDisplayPaymentSelector && (
        <PlanAndPaymentSelectorDialog
          paymentMethods={paymentMethods}
          title={t("payment_modal.title")}
          plans={plans}
          onClose={() => setShouldDisplayPaymentSelector(false)}
          onChoosePaymentMethod={({ plan, type }) => {
            setPaywallCandidate({
              plan: plan,
              paymentMethodType: type,
              uniquePaywallKey: UNIQUE_PAYWALL_KEY,
              adId: listing.id,
              rentableId: listing.rentable_id,
              title: t("payment_modal.title"),
            });
          }}
        />
      )}
    </>
  );
};

const ContactLandlordButton = ({
  disabled,
  isFreeContact,
  onShowContactForm,
  onShowPaymentSelector,
  textButton,
}: {
  disabled: boolean;
  isFreeContact: boolean;
  onShowContactForm: () => void;
  onShowPaymentSelector: () => void;
  textButton?: boolean;
}) => {
  const { user } = useUser();
  return (
    <Button
      text={textButton}
      onClick={(event) => {
        event.preventDefault();
        if (user?.has_premium_seeker_access || isFreeContact) {
          TrackingEvent.favoritesClickContactLandlord();
          onShowContactForm();
        } else {
          onShowPaymentSelector();
        }
      }}
      variant="branded"
      disabled={disabled}
      fullWidth={!textButton}
      {...(textButton && {
        iconAfter: IconArrowForward,
      })}
    >
      {t("contact_cta.native_ad.Contact")}
    </Button>
  );
};

const navigateToCrawledListing = (listingId: number) => {
  window.open(
    App.settings.routes.redirect_page + "?destination_id=" + listingId,
    "_blank",
  );
};
