import { useContext } from "react";
import { Box, Card, CardSection, Flex, Text } from "@boligportal/juice";
import { PAGE_CRAWLINGS_ADS } from "apps/customer_service";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { CompanyDetailsContext } from "./company_details_context";
import { CompanyFeed } from "./interfaces";

const CompanyFeedsList = ({ companyFeed }: { companyFeed: CompanyFeed }) => (
  <Flex align="center">
    <Flex
      column
      flexGrow
    >
      <StyledLink
        to={`${PAGE_CRAWLINGS_ADS}/?crawlingSourceId=${companyFeed.id}`}
      >
        <Text
          weight="bold"
          block
          size="small"
        >{`${companyFeed.feed_name}`}</Text>
      </StyledLink>
      <Text
        block
        size="small"
      >{`#${companyFeed.id}`}</Text>
    </Flex>
  </Flex>
);

const CompanyFeedsPanel = () => {
  const { companyFeeds } = useContext(CompanyDetailsContext);

  return companyFeeds.length > 0 ? (
    <Card sectioned>
      <CardSection title="Company Crawlings">
        <Box pb={4}>
          {companyFeeds.map((companyFeed) => (
            <CompanyFeedsList
              key={companyFeed.id}
              companyFeed={companyFeed}
            />
          ))}
        </Box>
      </CardSection>
    </Card>
  ) : (
    <div />
  );
};

export { CompanyFeedsPanel };
