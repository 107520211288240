import { Notice, Flex } from "@boligportal/juice";
import { useTenancyDiffUpdater } from "features/tenancy/useTenancyDiffUpdater";
import { t } from "lib/i18n";

type Props = {
  onDiffDisplayCloseButtonClick: () => void;
  noticeDescription: string;
};

export const DiffUpdateNotice = ({
  onDiffDisplayCloseButtonClick,
  noticeDescription,
}: Props) => {
  const { displayDiffDialog } = useTenancyDiffUpdater();

  if (!displayDiffDialog) {
    return null;
  }

  return (
    <Notice
      type="info"
      onDismissClick={onDiffDisplayCloseButtonClick}
      actions={[
        {
          label: t("tenancy.diff_notice.action_compare_information"),
          onClick: displayDiffDialog,
        },
      ]}
    >
      <Flex>
        <strong>{t("tenancy.diff_notice.title")}</strong>
      </Flex>
      {noticeDescription}
    </Notice>
  );
};
