import * as React from "react";
import { Box, Container, Flex, Logo, styled } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { ImpersonationWarning } from "components/impersonation_warning";
import { DropDownMenu } from "../header/drop_down_menu";

const SidebarHeaderLayout = styled.header<{
  inverseColor?: boolean;
  alwaysShowBorder?: boolean;
  hideOnXl?: boolean;
}>`
  display: block;
  width: 100%;
  padding: ${(props) => props.theme.unit(2)} 0;

  background: ${(props) =>
    props.inverseColor ? "transparent" : props.theme.color.bg.base};
  border-bottom: ${(props) =>
    props.alwaysShowBorder ? ` 1px solid ${props.theme.color.border}` : "none"};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    border-bottom: ${(props) =>
      props.inverseColor || !props.alwaysShowBorder
        ? "none"
        : ` 1px solid ${props.theme.color.border}`};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    display: ${(props) => (props.hideOnXl ? "none" : "block")};
  }
  @media print {
    display: none;
  }
`;

interface Props {
  component?: React.ReactElement;
  noMarginsOnComponent?: boolean;
  ignoreBorder?: boolean;
  showUserDropdownOnXL?: boolean;
}

export const SidebarHeader = ({
  component,
  ignoreBorder,
  noMarginsOnComponent,
  showUserDropdownOnXL = false,
}: Props) => {
  const headerHasContent = Boolean(component);
  const { user } = useUser();

  return (
    <>
      <ImpersonationWarning />
      <SidebarHeaderLayout
        alwaysShowBorder={!ignoreBorder}
        hideOnXl={Boolean(user) && !headerHasContent}
      >
        <Container fluid>
          <Flex
            justify="space-between"
            hidden={{
              xl: !showUserDropdownOnXL,
            }}
          >
            <a href={App.settings.routes.index}>
              <Logo
                width={"180px"}
                market={App.settings.market}
              />
            </a>
            <DropDownMenu
              user={user}
              inverseColor={false}
            />
          </Flex>
          {component && !noMarginsOnComponent && <Box>{component}</Box>}
        </Container>
        {component && noMarginsOnComponent && <Box>{component}</Box>}
      </SidebarHeaderLayout>
    </>
  );
};
