import { useFormContext } from "react-hook-form";
import { Flex, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { ControlledPaymentNumberField } from "../ControlledPaymentNumberField";
import { MonthButtons } from "./month_buttons";

interface Props {
  validationRule: (monthly_rent: number) => (value: number) => boolean;
}

export const PrepaidRent = ({ validationRule }: Props) => {
  const { getValues, setValue, control, formState } = useFormContext();
  const validateFunc = validationRule(getValues("monthly_rent"));

  const { errors } = formState;

  const calculateAndSetCost = (numMonths: number) => {
    const rent = getValues("monthly_rent");

    if (rent > 0) {
      setValue("prepaid_rent", rent * numMonths, {
        shouldDirty: true,
      });
    }
  };

  const value = getValues("prepaid_rent");
  const errorTranslationKey = value
    ? "deposit.error.exceeds_rent_rule"
    : "required_field.numeric";

  return (
    <Flex
      column
      flexGrow
      mb={2}
    >
      <TextFieldWithoutMarginsOrGrow
        name="prepaid_rent"
        control={control}
        validator={validateFunc}
        label={t("create.field.prepaid_rent.heading")}
        required
        errorText={errors.prepaid_rent && t(errorTranslationKey)}
        testId="createAdPrepaidRent"
      />
      <MonthButtons onMonthClick={calculateAndSetCost} />
    </Flex>
  );
};

const TextFieldWithoutMarginsOrGrow = styled(ControlledPaymentNumberField)`
  margin-bottom: 0;
  flex-grow: 0;
`;
