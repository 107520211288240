import { useContext } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  IconClose,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  dialogActions,
} from "@boligportal/juice";
import { BlackListIndicator } from "apps/customer_service/components/blacklist_indicator/BlackListIndicator";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { USER_TYPE } from "components/interfaces/profile";
import { CompanyUser } from "../interfaces";

const CancelButton = () => {
  const actions = useContext(dialogActions);
  return (
    <Button
      variant="subtle"
      onClick={actions.hide}
    >
      Cancel
    </Button>
  );
};

const CompanyUsersList = ({
  companyUser,
  onRemove,
}: {
  companyUser: CompanyUser;
  onRemove: (userId: number) => void;
}) => {
  const handleRemoveClick = () => {
    onRemove(companyUser.user_id);
  };
  return (
    <Flex align="center">
      <Flex
        column
        flexGrow
      >
        <StyledLink to={`/customer-service/users/${companyUser.user_id}`}>
          <Flex align="start">
            {companyUser.user_ban_reasons && (
              <BlackListIndicator
                showTooltip
                reasons={companyUser.user_ban_reasons}
              />
            )}
            <Text
              weight="bold"
              block
              size="small"
            >
              {companyUser.user_type === USER_TYPE.COMPANY
                ? `${companyUser.company_name}`
                : `${companyUser.first_name} ${companyUser.last_name}`}
            </Text>
          </Flex>
        </StyledLink>
        <Text
          block
          size="small"
        >{`#${companyUser.user_id}`}</Text>
      </Flex>

      <Box>
        <Dialog
          opener={
            <Button
              variant="subtle"
              icon={IconClose}
            />
          }
        >
          <DialogHeader>Are you sure?</DialogHeader>

          <DialogContent>
            <Text block>
              Are you sure you would like to remove {companyUser.first_name}{" "}
              {companyUser.last_name}?
            </Text>
          </DialogContent>

          <DialogFooter>
            <Flex gap>
              <CancelButton />
              <Button
                variant="primary"
                onClick={handleRemoveClick}
              >
                Confirm
              </Button>
            </Flex>
          </DialogFooter>
        </Dialog>
      </Box>
    </Flex>
  );
};

export { CompanyUsersList };
