import * as React from "react";
import {
  Box,
  Flex,
  IconAlertCircle,
  Notice,
  Text,
  styled,
  IconListOutline,
  Tooltip,
} from "@boligportal/juice";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { t } from "lib/i18n";
import SvgBetalingsserviceLogo from "media/svg/BetalingsserviceLogo";
import SvgMobilePayWithText from "media/svg/MobilePayWithText";

type PaymentMethodTypeProps = {
  paymentMethodType: TenancyPaymentType;
  wasManuallyChanged: boolean;
};

type PaymentInfoMessageProps = {
  status: TenancyPaymentStatus;
  message: string;
};

export type RentPaymentInfoCell = {
  rentPaymentInfoCell: PaymentMethodTypeProps & PaymentInfoMessageProps;
};

export const rentPaymentInfoCellRenderer = ({
  rentPaymentInfoCell,
}: RentPaymentInfoCell) => (
  <Flex column>
    <Box>
      <PaymentMethodType
        wasManuallyChanged={rentPaymentInfoCell.wasManuallyChanged}
        paymentMethodType={rentPaymentInfoCell.paymentMethodType}
      />
    </Box>
    {rentPaymentInfoCell.message && (
      <Tooltip text={rentPaymentInfoCell.message}>
        <InlineFlex>
          <PaymentInfoMessage
            status={rentPaymentInfoCell.status}
            message={rentPaymentInfoCell.message}
          />
        </InlineFlex>
      </Tooltip>
    )}
  </Flex>
);

const PaymentInfoMessage = ({ status, message }: PaymentInfoMessageProps) => {
  if (status === TenancyPaymentStatus.NOT_PAID && message.length > 0) {
    return (
      <Notice
        size="tiny"
        icon={<IconAlertCircle />}
        type={tenancyPaymentStatusToNoticeType[status]}
      >
        <Box
          minWidth="0px"
          maxWidth="150px"
          truncate
        >
          <Text size="tiny">{message}</Text>
        </Box>
      </Notice>
    );
  }

  if (status === TenancyPaymentStatus.PAID && message.length > 0) {
    return (
      <Notice
        size="tiny"
        type={tenancyPaymentStatusToNoticeType[status]}
      >
        <Box
          minWidth="0px"
          maxWidth="150px"
          truncate
        >
          <Text size="tiny">{message}</Text>
        </Box>
      </Notice>
    );
  }

  return null;
};

const InlineFlex = styled.div`
  display: inline-flex;
`;

export const MobilePay = () => (
  <SvgMobilePayWithText
    width={70}
    height={30}
  />
);

export const BetalingsService = ({
  height = 30,
  width = 70,
}: {
  height?: number;
  width?: number;
}) => (
  <SvgBetalingsserviceLogo
    width={width}
    height={height}
  />
);

export const BankTransfer = () => (
  <Flex align="center">
    <IconListOutline size="medium" />
    <Text
      size="small"
      pl={0.5}
    >
      {t("common.account.transfer")}
    </Text>
  </Flex>
);

const PaymentMethodType = ({
  wasManuallyChanged,
  paymentMethodType,
}: PaymentMethodTypeProps) => {
  if (
    wasManuallyChanged &&
    paymentMethodType === TenancyPaymentType.MANUAL_BANK_TRANSFER
  ) {
    return <BankTransfer />;
  }

  const PaymentMethodTypeComponent =
    paymentMethodTypeDisplayMap[paymentMethodType];

  if (
    wasManuallyChanged &&
    paymentMethodType !== TenancyPaymentType.MANUAL_BANK_TRANSFER
  ) {
    return (
      <WithOpacityFilter>
        <PaymentMethodTypeComponent />
      </WithOpacityFilter>
    );
  }

  return <PaymentMethodTypeComponent />;
};

const paymentMethodTypeDisplayMap: Record<
  TenancyPaymentType,
  React.ElementType
> = {
  [TenancyPaymentType.MANUAL_BANK_TRANSFER]: BankTransfer,
  [TenancyPaymentType.MOBILEPAY]: MobilePay,
  [TenancyPaymentType.BETALINGS_SERVICE]: () =>
    BetalingsService({
      width: 120,
      height: 50,
    }),
};

const tenancyPaymentStatusToNoticeType: Record<
  TenancyPaymentStatus,
  "info" | "warning" | "success"
> = {
  [TenancyPaymentStatus.NOT_PAID]: "warning",
  [TenancyPaymentStatus.PAID]: "success",
};

const WithOpacityFilter = styled.div`
  filter: opacity(50%);
`;
