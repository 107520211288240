import { arrayMove } from "@dnd-kit/sortable";
import { SortableReducer } from "../types/SortableReducer";
import { SortableReducerAction } from "../types/SortableReducerAction";
import { SortableReducerState } from "../types/SortableReducerState";

export const dndKitSortableReducer: SortableReducer = (
  state: SortableReducerState,
  action: SortableReducerAction,
) => {
  switch (action.type) {
    case "UPDATE_IMAGES": {
      return {
        ...state,
        images: action.images,
      };
    }
    case "UPDATE_IMAGE": {
      const newImages = state.images.map((image) => {
        if (image.id === action.oldImage.id) {
          return {
            id: action.newImage.id.toString(),
            url: action.newImage.url,
          };
        }
        return image;
      });

      return {
        ...state,
        images: newImages,
      };
    }
    case "ADD_IMAGE": {
      return {
        ...state,
        images: [...state.images, action.image],
      };
    }
    case "REMOVE_IMAGE": {
      const imagesWithoutCandidate = state.images.filter(
        (x) => x.id !== action.image.id,
      );

      return {
        ...state,
        images: [...imagesWithoutCandidate],
      };
    }
    case "PUT_IMAGE_FIRST": {
      const candidateIndex = state.images
        .map((x) => x.id)
        .indexOf(action.image.id);

      return {
        ...state,
        images: arrayMove([...state.images], candidateIndex, 0),
      };
    }
    case "ACTIVE_IMAGE_ID": {
      const { imageId } = action;

      const candidateImage = state.images.find((image) => image.id === imageId);

      return {
        ...state,
        images: [...state.images],
        activeImage: candidateImage ?? null,
      };
    }
    case "MOVE_IMAGES": {
      const { movingImageId, replacingImageId } = action;

      const imageIds = state.images.map((image) => image.id);

      const oldPosition = imageIds.indexOf(movingImageId);
      const newPosition = imageIds.indexOf(replacingImageId);

      return {
        ...state,
        images: arrayMove([...state.images], oldPosition, newPosition),
        activeImageId: null,
      };
    }
    case "MOVE_IMAGE_BACKWARD": {
      const { image } = action;
      const candidateIndex = state.images.indexOf(image);

      if (candidateIndex <= 0) {
        return state;
      }

      return {
        ...state,
        images: arrayMove(
          [...state.images],
          candidateIndex,
          candidateIndex - 1,
        ),
      };
    }
    case "MOVE_IMAGE_FORWARD": {
      const { image } = action;
      const candidateIndex = state.images.indexOf(image);

      if (candidateIndex >= state.images.length - 1) {
        return state;
      }

      return {
        ...state,
        images: arrayMove(
          [...state.images],
          candidateIndex,
          candidateIndex + 1,
        ),
      };
    }
    case "FLOOR_PLAN_IMAGE_ID": {
      const { imageId } = action;

      const images = state.images.map((image) => {
        if (image.id === imageId) {
          return {
            ...image,
            isFloorPlan: true,
          };
        }
        return {
          ...image,
          isFloorPlan: false,
        };
      });

      return {
        ...state,
        images,
      };
    }

    case "REMOVE_FLOOR_PLAN_IMAGE_ID": {
      const { imageId } = action;

      const images = state.images.map((image) => {
        if (image.id === imageId) {
          return {
            ...image,
            isFloorPlan: false,
          };
        }
        return image;
      });

      return {
        ...state,
        images,
      };
    }
  }
};
