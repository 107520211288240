import { useSortableImages } from "features/image_organizer/hooks/useSortableImages";
import { updateCompanyPage } from "../api";
import { convertFromSlideImages } from "../helpers";
import { CompanyPage } from "../types";
import { CompanyPageDialogForm } from "./CompanyPageDialogForm";
import {
  CompanyPageSlideOverFormValues,
  createPayload,
  useCompanyPageForm,
} from "./useCompanyPageForm";

type Props = {
  companyId: number;
  companyPage: CompanyPage;
  onClose: () => void;
};

export const EditCompanyPageDialog = ({
  companyId,
  companyPage,
  onClose,
}: Props) => {
  const sortableImagesFeature = useSortableImages(
    companyPage.images ? convertFromSlideImages(companyPage.images) : [],
  );

  const logoImageFeature = useSortableImages(
    companyPage.logo_url && companyPage.logo_id
      ? [
          {
            id: companyPage.logo_id.toString(),
            url: companyPage.logo_url,
          },
        ]
      : [],
  );

  const { register, handleSubmit, watch } = useCompanyPageForm({
    slug: companyPage.slug,
    filter_url: companyPage.filter_url,
    headline: companyPage.headline,
    lead: companyPage.lead,
    body: companyPage.body,
    meta_description: companyPage.meta_description,
    demo: companyPage.demo,
  });

  const submit = (companyPageForm: CompanyPageSlideOverFormValues) => {
    const payload = createPayload(
      companyPageForm,
      logoImageFeature.images,
      sortableImagesFeature.images,
    );

    if (typeof payload === "string") {
      alert(payload);
      return;
    }

    updateCompanyPage(companyId, companyPage, payload)
      .then(onClose)
      .catch((err) => {
        alert(err.error);
      });
  };

  return (
    <CompanyPageDialogForm
      isOpen
      title="Edit Company Page"
      submitButtonLabel="Update"
      logoImageFeature={logoImageFeature}
      sortableImagesFeature={sortableImagesFeature}
      register={register}
      watch={watch}
      submit={handleSubmit(submit)}
      onClose={onClose}
    />
  );
};
