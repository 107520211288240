import * as React from "react";
import {
  Flex,
  FlexColumn,
  RadioField,
  SelectField,
  TextField,
} from "@boligportal/juice";
import {
  OCCUPATION,
  USER_GENDER,
  USER_TYPE,
} from "components/interfaces/profile";
import {
  getHistoricProfileDetails,
  HistoricProfileDetailItem,
} from "lib/customerServiceApi";
import { ProfileDetailsDTO } from "./helpers";

enum TriState {
  NONE = "NONE",
  YES = "YES",
  NO = "NO",
}

const occupationToFormValue = (
  value?: OCCUPATION | null,
): string | undefined => {
  if (typeof value === "undefined") {
    return undefined;
  }

  return (value === null ? TriState.NONE : value).toString();
};

const userTypeToFormValue = (value?: USER_TYPE | null): string | undefined => {
  if (typeof value === "undefined") {
    return undefined;
  }

  return (value === null ? TriState.NONE : value).toString();
};

const userGenderToFormValue = (
  value?: USER_GENDER | null,
): string | undefined => {
  if (typeof value === "undefined") {
    return undefined;
  }

  return (value === null ? TriState.NONE : value).toString();
};

const triStateOptions = [
  {
    value: TriState.NONE,
    label: "None selected",
  },
  {
    value: TriState.YES,
    label: "YES",
  },
  {
    value: TriState.NO,
    label: "NO",
  },
];

const valueToTriState = (value?: boolean | null): TriState | undefined => {
  if (typeof value === "undefined") {
    return undefined;
  }

  if (value !== null) {
    return value === true ? TriState.YES : TriState.NO;
  }

  return TriState.NONE;
};

const triStateToValue = (value: TriState) => {
  if (value === TriState.NONE) {
    return null;
  }

  return value === TriState.YES;
};

const nullToUndefined = (value: string | null) =>
  value === null ? undefined : value;

export const ProfileDetails = ({
  profileDetails,
  updateField,
}: {
  profileDetails: ProfileDetailsDTO;
  updateField: (fieldname: string, value: any) => void;
}) => {
  const [historicProfileData, setHistoricProfileData] =
    React.useState<HistoricProfileDetailItem>();
  const [editableProfileDetails, setEditableProfileDetails] =
    React.useState<ProfileDetailsDTO>(profileDetails);

  React.useEffect(() => {
    setEditableProfileDetails(profileDetails);
  }, [profileDetails]);

  React.useEffect(() => {
    if (profileDetails && !historicProfileData) {
      getHistoricProfileDetails(profileDetails.user_id).then((response) => {
        setHistoricProfileData(response.data || {});
      });
    }
  }, [profileDetails, historicProfileData]);

  const saveUpdate = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;

    if (editableProfileDetails[fieldName] !== profileDetails[fieldName]) {
      updateField(fieldName, editableProfileDetails[fieldName]);
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;

    setEditableProfileDetails({
      ...editableProfileDetails,
      [fieldName]: event.target.value,
    });
  };

  const handleRadioChangeAndSave = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditableProfileDetails({
      ...editableProfileDetails,
      [event.target.name]: triStateToValue(event.target.value as TriState),
    });

    updateField(
      event.target.name,
      triStateToValue(event.target.value as TriState),
    );
  };

  const handleSelectChangeAndSave = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value =
      event.target.value === TriState.NONE
        ? null
        : parseInt(event.target.value, 10);

    setEditableProfileDetails({
      ...editableProfileDetails,
      [event.target.name]: value,
    });

    updateField(event.target.name, value);
  };

  if (!historicProfileData || !editableProfileDetails) {
    return null;
  }

  return (
    <Flex column>
      <TextField
        name="description"
        label="Description"
        value={editableProfileDetails.description}
        previousValue={nullToUndefined(historicProfileData.description)}
        onChange={handleTextChange}
        onBlur={saveUpdate}
        multiLine
      />

      <Flex gap>
        {editableProfileDetails.user_type !== USER_TYPE.COMPANY && (
          <>
            <FlexColumn size={4}>
              <TextField
                type="text"
                name="first_name"
                label="First name"
                value={editableProfileDetails.first_name}
                previousValue={nullToUndefined(historicProfileData.first_name)}
                onChange={handleTextChange}
                onBlur={saveUpdate}
              />
            </FlexColumn>

            <FlexColumn size={4}>
              <TextField
                type="text"
                name="last_name"
                label="Last name"
                value={editableProfileDetails.last_name}
                previousValue={nullToUndefined(historicProfileData.last_name)}
                onChange={handleTextChange}
                onBlur={saveUpdate}
              />
            </FlexColumn>
          </>
        )}

        {editableProfileDetails.user_type === USER_TYPE.COMPANY && (
          <FlexColumn size={8}>
            <TextField
              name="company_name"
              label="Company name"
              value={editableProfileDetails.company_name}
              previousValue={nullToUndefined(historicProfileData.company_name)}
              onChange={handleTextChange}
              onBlur={saveUpdate}
            />
          </FlexColumn>
        )}

        <FlexColumn size={4}>
          <TextField
            type="text"
            name="email"
            label="Email"
            value={editableProfileDetails.email}
            onChange={handleTextChange}
            onBlur={saveUpdate}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <TextField
            type="date"
            name="birthday"
            label="Birthday"
            value={editableProfileDetails.birthday}
            previousValue={nullToUndefined(historicProfileData.birthday)}
            onChange={handleTextChange}
            onBlur={(event) =>
              updateField(
                event.target.name,
                editableProfileDetails[event.target.name] === ""
                  ? null
                  : editableProfileDetails[event.target.name],
              )
            }
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <TextField
            name="address"
            label="Address"
            value={editableProfileDetails.address}
            previousValue={nullToUndefined(historicProfileData.address)}
            onChange={handleTextChange}
            onBlur={saveUpdate}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <TextField
            name="postal_code"
            label="Zip code"
            value={editableProfileDetails.postal_code}
            previousValue={nullToUndefined(historicProfileData.postal_code)}
            onChange={handleTextChange}
            onBlur={saveUpdate}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <TextField
            name="postal_town"
            label="City"
            value={editableProfileDetails.postal_town}
            previousValue={nullToUndefined(historicProfileData.postal_town)}
            onChange={handleTextChange}
            onBlur={saveUpdate}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <TextField
            name="phone_number"
            label="Phone"
            value={editableProfileDetails.phone_number}
            previousValue={nullToUndefined(historicProfileData.phone_number)}
            onChange={handleTextChange}
            onBlur={saveUpdate}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={3}>
          <SelectField
            name="occupation"
            label="Occupation"
            value={occupationToFormValue(editableProfileDetails.occupation)}
            previousValue={occupationToFormValue(
              historicProfileData.occupation,
            )}
            onChange={handleSelectChangeAndSave}
            items={[
              {
                value: TriState.NONE,
                label: "None selected",
              },
              {
                value: OCCUPATION.STUDENT.toString(),
                label: "Student",
              },
              {
                value: OCCUPATION.EMPLOYED.toString(),
                label: "Employed",
              },
              {
                value: OCCUPATION.UNEMPLOYED.toString(),
                label: "Unemployed",
              },
              {
                value: OCCUPATION.RETIRED.toString(),
                label: "Retired",
              },
            ]}
          />
        </FlexColumn>

        <FlexColumn size={3}>
          <SelectField
            name="user_type"
            label="User Type"
            value={userTypeToFormValue(editableProfileDetails.user_type)}
            previousValue={userTypeToFormValue(historicProfileData.user_type)}
            onChange={handleSelectChangeAndSave}
            items={[
              {
                value: TriState.NONE,
                label: "None selected",
              },
              {
                value: USER_TYPE.COMPANY.toString(),
                label: "Company",
              },
              {
                value: USER_TYPE.COUPLE.toString(),
                label: "Couple",
              },
              {
                value: USER_TYPE.FAMILY.toString(),
                label: "Family",
              },
              {
                value: USER_TYPE.GROUP.toString(),
                label: "Group",
              },
              {
                value: USER_TYPE.INDIVIDUAL.toString(),
                label: "Individual",
              },
            ]}
          />
        </FlexColumn>

        <FlexColumn size={3}>
          <SelectField
            name="user_gender"
            label="Gender"
            value={userGenderToFormValue(editableProfileDetails.user_gender)}
            previousValue={userGenderToFormValue(
              historicProfileData.user_gender,
            )}
            onChange={handleSelectChangeAndSave}
            items={[
              {
                value: TriState.NONE,
                label: "None selected",
              },
              {
                value: USER_GENDER.MALE.toString(),
                label: "Male",
              },
              {
                value: USER_GENDER.FEMALE.toString(),
                label: "Female",
              },
              {
                value: USER_GENDER.OTHER.toString(),
                label: "Other",
              },
            ]}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <RadioField
            name="pets"
            label="Pets"
            value={valueToTriState(editableProfileDetails.pets)}
            previousValue={valueToTriState(historicProfileData.pets)}
            onChange={handleRadioChangeAndSave}
            items={triStateOptions}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <RadioField
            name="kids"
            label="Kids"
            value={valueToTriState(editableProfileDetails.kids)}
            previousValue={valueToTriState(historicProfileData.kids)}
            onChange={handleRadioChangeAndSave}
            items={triStateOptions}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <RadioField
            name="smoking"
            label="Smoking"
            value={valueToTriState(editableProfileDetails.smoking)}
            previousValue={valueToTriState(historicProfileData.smoking)}
            onChange={handleRadioChangeAndSave}
            items={triStateOptions}
          />
        </FlexColumn>

        <FlexColumn size={4}>
          <RadioField
            name="share_apartment"
            label="Share apartment"
            value={valueToTriState(editableProfileDetails.share_apartment)}
            previousValue={valueToTriState(historicProfileData.share_apartment)}
            onChange={handleRadioChangeAndSave}
            items={triStateOptions}
          />
        </FlexColumn>
      </Flex>
    </Flex>
  );
};
