import { Box, Flex, Text } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { USER_TYPE } from "components/interfaces/profile";
import { User } from "components/interfaces/user";
import { ProfileImage } from "components/profile_image";
import { t } from "lib/i18n";

export const ProfileMenuItem = () => {
  const { user } = useUser();

  if (!user) {
    throw new Error("ProfileMenuItem should be used for authenticated Menu");
  }

  return <ProfileCard user={user} />;
};

const ProfileCard = ({ user }: { user: User }) => {
  const { first_name, last_name, profile_company_name, email } = user;
  let displayName = "";

  if (user.user_type === USER_TYPE.COMPANY) {
    displayName = profile_company_name;
  } else {
    displayName = `${first_name} ${last_name}`;
  }
  if (first_name === "" && last_name === "") {
    displayName = t("dropdown.EmptyName");
  }

  return (
    <Flex
      gap
      align="center"
    >
      <Box>
        <ProfileImage
          user={user}
          size={60}
        />
      </Box>
      <Box truncate>
        <Box truncate>
          <Text
            weight="bold"
            size="lead"
          >
            {displayName}
          </Text>
        </Box>
        <Box truncate>
          <Text size="small">{email}</Text>
        </Box>
      </Box>
    </Flex>
  );
};
