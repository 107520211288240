import { AdStates } from "../../../../lib/enums";
import { AdsPageAll } from "../ads_page/ads_page_all";

const CompanyAdsPage = () => (
  <AdsPageAll
    crawlingAds={false}
    companyAds
    initialReviewReasons={["MODIFIED", "AUTO_APPROVED"]}
    initialStates={[AdStates.ACTIVE_APPROVED]}
  />
);

export { CompanyAdsPage };
