import * as React from "react";
import { Badge, Box, Text } from "@boligportal/juice";
import { States } from "apps/newbuilds/types";
import { useNewBuildStatusFeature } from "features/cs/newbuild_status_feature/hooks/use_newbuild_status_feature";

export const NewBuildStateLabel = ({
  size = "tiny",

  state,
}: {
  state: States;

  size?: React.ComponentProps<typeof Text>["size"];
}) => {
  const { newbuildStatusColor, newbuildStatusText } =
    useNewBuildStatusFeature(state);

  return (
    <Text size={size}>
      <Box inline>
        <Badge color={newbuildStatusColor}>{newbuildStatusText}</Badge>
      </Box>
    </Text>
  );
};
