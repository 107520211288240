import { useFormContext } from "react-hook-form";
import { SelectField } from "@boligportal/juice";
import { t } from "lib/i18n";

const RentalPeriod = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const items = [
    {
      value: "6",
      label: t("rental_period.half_a_year"),
    },
    {
      value: "12",
      label: t("rental_period.year"),
    },
    {
      value: "24",
      label: t("rental_period.two_years"),
    },
    {
      value: "0",
      label: t("rental_period.unlimited"),
    },
  ];

  return (
    <SelectField
      required
      items={items}
      {...register("rental_period", {
        required: true,
      })}
      label={t("create.field.rental_period.heading")}
      errorText={errors.rental_period && t("required_field.selection")}
    />
  );
};
export { RentalPeriod };
