import { Flex, Box, Button, IconArrowForward, Text } from "@boligportal/juice";
import { MyRentablesListDataItem } from "apps/property_owner/my_rentables/interfaces/MyRentablesListDataItem";
import { MobileDataList } from "components/mobile-data-list/MobileDataList";
import { t } from "lib/i18n";
import {
  useChooseOrCreateRentableFeature,
  useChooseOrCreateRentableState,
} from "../ChooseOrCreateRentableProvider";

export const RentableMobileDataList = () => {
  const { rentables } = useChooseOrCreateRentableState();
  const { choseRental } = useChooseOrCreateRentableFeature();

  const rowRenderer = (data: MyRentablesListDataItem) => (
    <Flex align="center">
      <Flex column>
        <Box>{data.street_address}</Box>
        <Box>
          <Text
            size="small"
            color="muted"
          >
            {data.postal_city}
          </Text>
        </Box>
      </Flex>

      <Box flexPush>
        <Button
          variant="subtle"
          icon={IconArrowForward}
        ></Button>
      </Box>
    </Flex>
  );

  if (rentables.length === 0) {
    return (
      <Flex
        borderTop
        justify="center"
        align="center"
        minHeight={20}
        bg="base"
        borderBottom
        px={2}
      >
        <Text
          color="muted"
          textAlign="center"
        >
          {t("feature.choose_or_create_rentable.list_empty_state.text")}
        </Text>
      </Flex>
    );
  }

  return (
    <Box mt={2}>
      <MobileDataList<MyRentablesListDataItem>
        items={rentables}
        onItemClick={(item) => {
          choseRental(item.id);
        }}
        keyField="id"
        primary={{
          rowRenderer,
        }}
        secondaries={[]}
      />
    </Box>
  );
};
