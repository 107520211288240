import { Dialog } from "@boligportal/juice";
import { User } from "components/interfaces/user";
import { ModalActions } from "components/modal_controller";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { withModalActions } from "lib/injectors";
import { Translatable } from "lib/translations";
import { LoginView } from "./auth_views/login_view";
import { SignUpWithEmail } from "./auth_views/signup_with_email";
import useAuthActions from "./useAuthActions";

export const enum AuthView {
  LOGIN = "Login",
  SIGNUPWITHEMAIL = "SignUpWithEmail",
}
interface AuthModalProps {
  view: AuthView;
  onSuccess?: (user: User, props?: any) => void;
  modalActions: ModalActions;
  targetUrl?: string;
  context?: SignupContext;
}

export const LoginSignupContextTranslation =
  (): Translatable<SignupContext> => ({
    [SignupContext.CONTRACTS]: t("authentication.context.contracts"),
    [SignupContext.CREATE_RENTABLE]: t(
      "authentication.context.create_rentable",
    ),
    [SignupContext.FREE_CONTACT]: t("authentication.context.free_contact"),
    [SignupContext.MOVING_IN]: t(
      "authentication.context.moving_reports.moving_in",
    ),
    [SignupContext.MOVING_OUT]: t(
      "authentication.context.moving_reports.moving_out",
    ),
    [SignupContext.MOVING_REPORTS]: t("authentication.context.moving_reports"),
    [SignupContext.SEARCH_AGENT]: t("authentication.context.search_agent"),
    [SignupContext.NEWSLETTER]: t("authentication.context.newsletter"),
    [SignupContext.PROMOTE]: t("authentication.context.promote"),
    [SignupContext.CREATE_RENT_COLLECTION]: t(
      "authentication.context.create_rent_collection",
    ),
    [SignupContext.CREATE_TENANCY]: t("authentication.context.create_tenancy"),
    [SignupContext.FAVORITE]: t("authentication.context.favorite"),
    [SignupContext.RENTAL_DATA]: t("authentication.context.rental_data"),
    [SignupContext.CONTACT_LANDLORD]: t(
      "authentication.context.contact_landlord",
    ),
    [SignupContext.AD_DIGITAL_SHOWING]: t(
      "authentication.context.ad_digital_showing",
    ),
    [SignupContext.SEE_AD_VIDEO]: t("authentication.context.see_ad_video"),
    [SignupContext.NEW_BUILDS]: t("authentication.context.new_builds"),
  });

const AuthModalComponent = ({
  view,
  onSuccess,
  targetUrl,
  context,
}: AuthModalProps) => {
  const { actions, modalActionsInstance, authModalState } = useAuthActions({
    view: view,
    onSuccess: onSuccess,
    targetUrl: targetUrl,
  });

  const getViews = () => ({
    [AuthView.LOGIN]: (
      <LoginView
        authActions={actions}
        context={context}
      />
    ),
    [AuthView.SIGNUPWITHEMAIL]: (
      <SignUpWithEmail
        modalActions={modalActionsInstance}
        authActions={actions}
        context={context}
      />
    ),
  });

  const renderCurrentView = () => {
    const views = getViews();
    return views[authModalState.view];
  };

  return (
    <Dialog
      onClose={modalActionsInstance.closeModal}
      size="medium"
      open
      hideOnClickOutside={false}
    >
      {renderCurrentView()}
    </Dialog>
  );
};

export const AuthModal = withModalActions<AuthModalProps>(AuthModalComponent);
