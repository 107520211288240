import { unSkipPublishAd } from "lib/customerServiceApi";
import { UnSkipPublishAdResponseInterface } from "../../../interfaces";

export async function unSkipPublishAdTask(
  adId: number,
): Promise<UnSkipPublishAdResponseInterface> {
  try {
    const res = await unSkipPublishAd(adId);
    return res;
  } catch (e) {
    return {
      success: false,
      errors: e,
    };
  }
}
