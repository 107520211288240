import { useMemo } from "react";
import { t } from "lib/i18n";

export enum MovingReportType {
  IN = "IN",
  OUT = "OUT",
}

export enum MovingReportState {
  IN_PROGRESS = "IN_PROGRESS",
  TENANT_DEFECT_FLOW = "TENANT_DEFECT_FLOW",
  FINISHED = "FINISHED",
}

export enum DefectFlowStatus {
  NOT_STARTED = "NOT_STARTED",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  NOT_ENABLED = "NOT_ENABLED",
}

export enum ReportStepStatus {
  COMPLETED = "COMPLTED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum RoomType {
  KITCHEN = "KITCHEN",
  BATHROOM = "BATHROOM",
  HALLWAY = "HALLWAY",
  LIVING_ROOM = "LIVING_ROOM",
  BEDROOM = "BEDROOM",
  TOILET = "TOILET",
  ROOM = "ROOM",
  OUTSIDE = "OUTSIDE",
  OTHER = "OTHER",
}

export enum MeterType {
  WATER = "WATER",
  HOT_WATER = "HOT_WATER",
  HEATING_KWH = "HEATING_KWH",
  HEATING_MWH = "HEATING_MWH",
  HEATING_M3 = "HEATING_M3",
  HEATING_GJ = "HEATING_GJ",
  HEATING_HOURS = "HEATING_HOURS",
  HOURS = "HOURS",
  GAS = "GAS",
  ELECTRICITY = "ELECTRICITY",
  OIL = "OIL",
  OTHER = "OTHER",
  OTHER_M3 = "OTHER_M3",
  OTHER_L = "OTHER_L",
  OTHER_KWH = "OTHER_KWH",
}

export enum MeterUnit {
  UNIT_M3,
  UNIT_GJ,
  UNIT_KWH,
  UNIT_MWH,
  UNIT_HOURS,
  UNIT_L,
  UNIT_NONE,
}

export enum KeyAction {
  HAND_IN = "HAND IN",
  HAND_OUT = "HAND OUT",
}

export enum UnitType {
  CEILING = "CEILING",
  WALLS = "WALLS",
  FLOOR = "FLOOR",
  WOODWORK = "WOODWORK",
  WINDOWS = "WINDOWS",
  RADIATORS = "RADIATORS",
  POWER_AND_SWITCHES = "POWER_AND_SWITCHES",
  DOORS = "DOORS",
  TOILET = "TOILET",
  SINK = "SINK",
  FAUCETS = "FAUCETS",
  LAMPS = "LAMPS",
  SHOWER_TUB = "SHOWER_TUB",
  TABLETOP = "TABLETOP",
  CABINETS_SHELVES_DRAWERS = "CABINETS_SHELVES_DRAWERS",
  TILES = "TILES",
  GUTTERS = "GUTTERS",
  GARDEN_HEDGE_BEDS = "GARDEN_HEDGE_BEDS",
  TERRACE = "TERRACE",
  FASCIAS = "FASCIAS",
  OVERHANG = "OVERHANG",

  STOVE = "STOVE",
  OVEN = "OVEN",
  EXTRACTOR_HOOD = "EXTRACTOR_HOOD",
  REFRIGERATOR = "REFRIGERATOR",
  DISHWASHER = "DISHWASHER",
  WASHING_MACHINE = "WASHING_MACHINE",
  DRYER = "DRYER",
  ENTRYPHONE = "ENTRYPHONE",
  ANTENNA_CONNECTOR = "ANTENNA_CONNECTOR",
  LOCKS_KEYS = "LOCKS_KEYS",
  MAILBOX = "MAILBOX",

  OTHER = "OTHER",
}

export enum UnitCondition {
  PRISTINE = "PRISTINE",
  GOOD = "GOOD",
  POOR = "POOR",
  WORN = "WORN",
  MISSING = "MISSING",
}

export enum TenantPresenceAtInspection {
  PRESENT = "PRESENT",
  NOT_PRESENT = "NOT_PRESENT",
  REPRESENTED = "REPRESENTED",
}

export enum DefectAccountable {
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  NOBODY = "NOBODY",
}

export enum DefectActionType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

export enum DefectAction {
  NO_ACTION = "NO_ACTION",
  ONLY_DOCUMENTATION = "ONLY_DOCUMENTATION",
  REPAIR = "REPAIR",
  PAINT = "PAINT",
  CLEAN = "CLEAN",
  GRIND = "GRIND",
  VARNISH = "VARNISH",
  CHANGE = "CHANGE",
  PAPERING = "PAPERING",
  OTHER = "OTHER",
}

// Labels

export const getDefectAccountableLabel = (
  defectAccountable: DefectAccountable,
) => {
  const translations = {
    [DefectAccountable.TENANT]: t("moving_reports.defects.accountable_tenant"),
    [DefectAccountable.LANDLORD]: t(
      "moving_reports.defects.accountable_landlord",
    ),
  };

  return translations[defectAccountable];
};

export const getDefectActionLabel = (defectAction: string) => {
  const translations = {
    [DefectAction.ONLY_DOCUMENTATION]: t(
      "moving_reports.defects.action.only_documentation",
    ),
    [DefectAction.REPAIR]: t("moving_reports.defects.action.repair"),
    [DefectAction.PAINT]: t("moving_reports.defects.action.paint"),
    [DefectAction.CLEAN]: t("moving_reports.defects.action.clean"),
    [DefectAction.GRIND]: t("moving_reports.defects.action.grind"),
    [DefectAction.VARNISH]: t("moving_reports.defects.action.varnish"),
    [DefectAction.CHANGE]: t("moving_reports.defects.action.change"),
    [DefectAction.PAPERING]: t("moving_reports.defects.action.papering"),
    [DefectAction.OTHER]: t("moving_reports.defects.action.other"),
    [DefectAction.NO_ACTION]: t("moving_reports.defects.action.no_action"),
  };

  return translations[defectAction] || defectAction;
};

export const useTenantPresenceAtInspectionLabels = () => {
  const translations = useMemo(
    () => ({
      [TenantPresenceAtInspection.PRESENT]: t(
        "moving_reports.tenants.presence.present",
      ),
      [TenantPresenceAtInspection.NOT_PRESENT]: t(
        "moving_reports.tenants.presence.not_present",
      ),
      [TenantPresenceAtInspection.REPRESENTED]: t(
        "moving_reports.tenants.presence.represented",
      ),
    }),
    [],
  );

  const getTenantPresenceAtInspectionLabel = (
    tenantPresenceAtInspection: TenantPresenceAtInspection,
  ) => translations[tenantPresenceAtInspection];

  return {
    getTenantPresenceAtInspectionLabel,
  };
};

export const useUnitTypeLabels = () => {
  const translations = useMemo(
    () => ({
      [UnitType.CEILING]: t("moving_reports.units.ceiling"),
      [UnitType.WALLS]: t("moving_reports.units.walls"),
      [UnitType.FLOOR]: t("moving_reports.units.floor"),
      [UnitType.WOODWORK]: t("moving_reports.units.woodwork"),
      [UnitType.WINDOWS]: t("moving_reports.units.windows"),
      [UnitType.RADIATORS]: t("moving_reports.units.radiators"),
      [UnitType.POWER_AND_SWITCHES]: t(
        "moving_reports.units.power_and_switches",
      ),
      [UnitType.DOORS]: t("moving_reports.units.doors"),
      [UnitType.TOILET]: t("moving_reports.units.toilet"),
      [UnitType.SINK]: t("moving_reports.units.sink"),
      [UnitType.FAUCETS]: t("moving_reports.units.faucets"),
      [UnitType.LAMPS]: t("moving_reports.units.lamps"),
      [UnitType.SHOWER_TUB]: t("moving_reports.units.shower_tub"),
      [UnitType.TABLETOP]: t("moving_reports.units.tabletop"),
      [UnitType.CABINETS_SHELVES_DRAWERS]: t(
        "moving_reports.units.cabinets_shelves_drawers",
      ),
      [UnitType.TILES]: t("moving_reports.units.tiles"),
      [UnitType.GUTTERS]: t("moving_reports.units.gutters"),
      [UnitType.GARDEN_HEDGE_BEDS]: t("moving_reports.units.garden_hedge_beds"),
      [UnitType.TERRACE]: t("moving_reports.units.terrace"),
      [UnitType.FASCIAS]: t("moving_reports.units.fascias"),
      [UnitType.OVERHANG]: t("moving_reports.units.overhang"),

      [UnitType.STOVE]: t("moving_reports.units.stove"),
      [UnitType.OVEN]: t("moving_reports.units.oven"),
      [UnitType.EXTRACTOR_HOOD]: t("moving_reports.units.extractor_hood"),
      [UnitType.REFRIGERATOR]: t("moving_reports.units.refrigerator"),
      [UnitType.DISHWASHER]: t("moving_reports.units.dishwasher"),
      [UnitType.WASHING_MACHINE]: t("moving_reports.units.washing_machine"),
      [UnitType.DRYER]: t("moving_reports.units.dryer"),
      [UnitType.ENTRYPHONE]: t("moving_reports.units.entryphone"),
      [UnitType.ANTENNA_CONNECTOR]: t("moving_reports.units.antenna_connector"),
      [UnitType.LOCKS_KEYS]: t("moving_reports.units.locks_keys"),
      [UnitType.MAILBOX]: t("moving_reports.units.mailbox"),

      [UnitType.OTHER]: t("moving_reports.units.other"),
    }),
    [],
  );

  const getUnitTypeLabel = (unitType: UnitType) => translations[unitType];

  return {
    getUnitTypeLabel,
  };
};

export const useUnitConditionLabels = () => {
  const translations = useMemo(
    () => ({
      [UnitCondition.PRISTINE]: t("moving_reports.units.condition.pristine"),
      [UnitCondition.GOOD]: t("moving_reports.units.condition.good"),
      [UnitCondition.POOR]: t("moving_reports.units.condition.poor"),
      [UnitCondition.WORN]: t("moving_reports.units.condition.worn"),
      [UnitCondition.MISSING]: t("moving_reports.units.condition.missing"),
    }),
    [],
  );

  const getUnitConditionLabel = (unitCondition: UnitCondition) =>
    translations[unitCondition];

  return {
    getUnitConditionLabel,
  };
};

export const useRoomTypeLabels = () => {
  const translations = useMemo(
    () => ({
      [RoomType.KITCHEN]: t("moving_reports.rooms.kitchen"),
      [RoomType.BATHROOM]: t("moving_reports.rooms.bathroom"),
      [RoomType.HALLWAY]: t("moving_reports.rooms.hallway"),
      [RoomType.LIVING_ROOM]: t("moving_reports.rooms.living_room"),
      [RoomType.BEDROOM]: t("moving_reports.rooms.bedroom"),
      [RoomType.TOILET]: t("moving_reports.rooms.toilet"),
      [RoomType.ROOM]: t("moving_reports.rooms.room"),
      [RoomType.OUTSIDE]: t("moving_reports.rooms.outside"),
      [RoomType.OTHER]: t("moving_reports.rooms.other"),
    }),
    [],
  );

  const getRoomTypeLabel = (roomType: RoomType) => translations[roomType];

  return {
    getRoomTypeLabel,
  };
};
