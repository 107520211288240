export const HammerIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2:185)">
      <path
        d="M37.2074 29.9585L40.3308 25.9385L49.2489 31.8995C50.8498 32.9696 51.2066 35.1732 50.0252 36.6938C48.8438 38.2144 46.6203 38.4133 45.1878 37.1266L37.2074 29.9585Z"
        fill="white"
      />
      <rect
        x="21.6006"
        y="25.9833"
        width="17.9632"
        height="13.236"
        rx="0.945429"
        transform="rotate(-52.1549 21.6006 25.9833)"
        fill="white"
      />
      <rect
        x="21.8013"
        y="25.7249"
        width="17.9632"
        height="13.236"
        rx="0.945429"
        transform="rotate(-52.1549 21.8013 25.7249)"
        fill="white"
      />
      <rect
        x="16.7222"
        y="30.6027"
        width="4.7726"
        height="15.2723"
        rx="2.3863"
        transform="rotate(-52.1549 16.7222 30.6027)"
        fill="white"
      />
      <rect
        x="32.9636"
        y="9.69844"
        width="4.7726"
        height="15.2723"
        rx="2.3863"
        transform="rotate(-52.1549 32.9636 9.69844)"
        fill="white"
      />
      <rect
        x="6"
        y="47"
        width="28"
        height="3"
        rx="1"
        fill="white"
      />
      <path
        d="M9 44.5C9 43.9477 9.44772 43.5 10 43.5H30C30.5523 43.5 31 43.9477 31 44.5V46.5H9V44.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2:185">
        <rect
          width="57"
          height="57"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
