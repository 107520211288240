import * as React from "react";
import { Button, IconTrashOutline } from "@boligportal/juice";
import { IconProps } from "@boligportal/juice/dist/components/Icon/Icon";

type Props = Pick<React.ComponentProps<typeof Button>, "onClick">;

export const TrashButton = ({ onClick }: Props) => (
  <Button
    variant="subtle"
    onClick={onClick}
    icon={(props: IconProps) => (
      <IconTrashOutline
        {...props}
        color="danger"
      />
    )}
  />
);
