import { NavLink } from "react-router-dom";
import { Flex, Box, Text, IconArrowBackForward } from "@boligportal/juice";
import { Tenant } from "apps/moving_reports/interfaces/tenant";
import { t } from "lib/i18n";

export const AddressAndTentantsCellRenderer = ({
  id,
  address,
  tenants,
  internalId,
}: {
  id: number;
  address: string;
  tenants: Tenant[];
  internalId?: string;
}) => (
  <NavLink
    to={`${id}`}
    style={{
      textDecoration: "none",
    }}
  >
    <AddressAndTenants
      address={address}
      internalId={internalId}
      tenants={tenants}
    />
  </NavLink>
);

export const AddressAndTenants = ({
  address,
  internalId,
  tenants,
}: {
  address: string;
  internalId?: string;
  tenants: Array<Tenant>;
}) => (
  <Flex>
    <Box pr={1}>
      <IconArrowBackForward
        size="large"
        color="muted"
      />
    </Box>

    <Flex column>
      <Box>
        <Text
          weight="bold"
          size="small"
        >
          {address}
        </Text>
      </Box>
      {internalId && (
        <Box>
          <Text
            color="muted"
            size="small"
          >
            {internalId}
          </Text>
        </Box>
      )}
      <Box>
        <Text
          color="muted"
          size="small"
        >
          {tenants
            .map((tenant) => {
              if (!tenant.first_name && !tenant.last_name) {
                return t("moving_reports.list.unnamed_tenant");
              }

              return `${tenant.first_name || ""} ${
                tenant.last_name || ""
              }`.trim();
            })
            .join(", ")}
        </Text>
      </Box>
    </Flex>
  </Flex>
);
