import { NewBuild, FilterTabs, Unit } from "apps/newbuilds/types";

export type StatesCounts = Record<FilterTabs, number>;

export const getStatesCounts = (list: NewBuild[]): StatesCounts => {
  return list.reduce<Record<FilterTabs, number>>(
    (acc, project) => {
      acc[project.state] = (acc[project.state] || 0) + 1;
      return acc;
    },
    {
      [FilterTabs.ALL]: list.length,
      [FilterTabs.DRAFT]: 0,
      [FilterTabs.ADVERTISED]: 0,
      [FilterTabs.RENTED_OUT]: 0,
    },
  );
};

export const sortTableByColumn = <T>(
  items: T[],
  column: keyof T,
  descending: boolean,
) => {
  // TODO: temporary fix for wrong endpoint response types
  const numberFields = ["size_m2", "monthly_rent", "rooms"];

  const getValue = (value: string) => {
    if (numberFields.includes(column.toString())) {
      return parseFloat(value);
    }

    return value;
  };

  return items.sort((first, second) => {
    const firstItem = getValue(first[column] as string);
    const secondItem = getValue(second[column] as string);

    if (!firstItem || !secondItem) {
      return 0;
    }

    if (firstItem < secondItem) {
      return descending ? 1 : -1;
    }

    if (firstItem > secondItem) {
      return descending ? -1 : 1;
    }

    return 0;
  });
};

export const isNotContacted = (unit: Unit) => unit.seeker_thread_id === null;
