import { Dialog, Menu, MenuBaseItem } from "@boligportal/juice";
import { App } from "components/app";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { isUserAllowedToSeeRent } from "components/sidebar-menu/conditionals/isUserAllowedToSeeRent";
import { isUserAllowedToSeeRentalData } from "components/sidebar-menu/conditionals/isUserAllowedToSeeRentalData";
import { ImpersonateCompanyColleagueDialogContent } from "features/impersonate_company_colleague/components/ImpersonateCompanyColleagueDialogContent";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { AvatarMenuOpener } from "../components/AvatarMenuOpener";
import { InboxMenuItem } from "../components/InboxMenuItem";
import { ProfileMenuItem } from "../components/ProfileMenuItem";
import { RentMenuItem } from "../components/RentMenuItem";
import { ConfirmLogoutDialog } from "../confirm_logout_dialog/ConfirmLogoutDialog";
import { useLogout } from "../confirm_logout_dialog/useLogout";
import { useSignInAsColleague } from "./useSignInAsColleague";

interface Props {
  user: User;
  inverseColor: boolean;
}

interface LandlordMenuItem extends MenuBaseItem<LandlordMenuItem> {}

export const UserAvatarLandlordMenu = ({ user, inverseColor }: Props) => {
  const {
    confirmLogout,
    hideConfirmLogoutDialog,
    showConfirmLogoutDialog,
    shouldShowConfirmLogoutDialog,
  } = useLogout();

  const {
    state,
    shouldShowSignInAsCompanyColleagueDialog,
    showSignInAsColleague,
    hideSignInAsColleague,
    impersonateCompanyColleague,
    isUserAllovedToImpersonateColleague,
  } = useSignInAsColleague();

  const { routes, market, support_url, blog_url, webinar_url } = App.settings;

  const isDK = market === Market.BOLIGPORTAL_DK;

  const {
    ECONOMY,
    FIND_TENANT,
    INBOX,
    LOG_OUT,
    MOVING_REPORTS,
    MY_FAVOURITE_ADS,
    MY_AGENTS,
    MY_LISTINGS,
    MY_RENTABLES,
    LANDLORD_PROFILE,
    RENTAL_CONTRACTS,
    RENT,
    RENTAL_DATA,
    SETTINGS,
    SIGN_IN_AS_COLLEAGUE,
    LANDLORD_SUBSCRIPTIONS,
    SUPPORT,
    TENANCY,
    BENEFITS,
    NEWBUILDS,
  } = menuTranslations();

  const moreItems: LandlordMenuItem[] = [
    {
      name: LANDLORD_PROFILE,
      href: routes.profile,
    },
    {
      name: LANDLORD_SUBSCRIPTIONS,
      href: routes.subscription,
    },
    {
      name: MY_FAVOURITE_ADS,
      href: routes.my_favourite_ads,
    },
    {
      name: MY_AGENTS,
      href: routes.agents,
    },
    ...(isDK
      ? [
          {
            name: BENEFITS,
          },
        ]
      : []),
    {},
    ...(isUserAllovedToImpersonateColleague()
      ? [
          {
            name: SIGN_IN_AS_COLLEAGUE,
          },
        ]
      : []),
    {
      name: LOG_OUT,
    },
  ];

  const supportItems: LandlordMenuItem[] = [
    {
      name: SUPPORT,
      href: support_url,
    },
    ...(isDK
      ? [
          {
            name: t("footer.webinar"),
            href: webinar_url,
          },
        ]
      : []),
    {
      name: t("footer.Blog"),
      href: blog_url,
    },
  ];

  const items: LandlordMenuItem[] = [
    {
      href: routes.profile,
      renderFunction: ProfileMenuItem,
    },
    {},
    {
      name: MY_RENTABLES,
      href: routes.property_owner.my_rentables,
    },
    {},
    {
      name: MY_LISTINGS,
      href: routes.landlord_listings,
    },
    ...(user.company_new_build_access
      ? [
          {
            name: NEWBUILDS,
            href: routes.newbuilds,
          },
        ]
      : []),
    {
      name: INBOX,
      href: routes.inbox,
      renderFunction: InboxMenuItem,
    },
    {
      name: FIND_TENANT,
      href: routes.find_tenant,
    },
    {},
    {
      name: TENANCY,
      href: routes.tenancy_list,
    },
    {
      name: RENTAL_CONTRACTS,
      href: routes.contracts,
    },
    {
      name: MOVING_REPORTS,
      href: routes.moving_reports,
    },
    ...(isUserAllowedToSeeRent() && isDK
      ? [
          {
            name: RENT,
            href: routes.property_owner.rent,
            renderFunction: RentMenuItem,
          },
        ]
      : []),
    ...(isUserAllowedToSeeRentalData() && isDK
      ? [
          {
            name: RENTAL_DATA,
            href: routes.rental_data,
          },
        ]
      : []),
    {},
    {
      name: ECONOMY,
      href: routes.economy_page,
    },
    {
      name: t("menu.support-and-video"),
      items: supportItems,
    },
    {
      name: SETTINGS,
      href: routes.user_settings,
    },
    {
      name: t("menu.landlord.more"),
      items: moreItems,
    },
  ];

  const onHandleMenuItemSelect = (item: LandlordMenuItem) => {
    if (item.name === SIGN_IN_AS_COLLEAGUE) {
      showSignInAsColleague();
    }

    if (item.name === LOG_OUT) {
      showConfirmLogoutDialog();
    }

    if (item.name === BENEFITS) {
      TrackingEvent.clickBenefitsDropdownMenu();
      window.location.href = routes.benefits;
    }
  };

  if (shouldShowConfirmLogoutDialog) {
    return (
      <ConfirmLogoutDialog
        onClose={hideConfirmLogoutDialog}
        onConfirmed={confirmLogout}
      />
    );
  }

  return (
    <>
      <Menu
        align="end"
        width={280}
        items={items}
        onMenuItemSelect={onHandleMenuItemSelect}
        openerEl={(isOpened) => (
          <AvatarMenuOpener
            isOpened={isOpened}
            inverseColor={inverseColor}
            user={user}
          />
        )}
        backButtonLabel={t("menu.landlord.back")}
      />

      {isUserAllovedToImpersonateColleague() && (
        <Dialog
          open={shouldShowSignInAsCompanyColleagueDialog}
          onClose={hideSignInAsColleague}
        >
          <ImpersonateCompanyColleagueDialogContent
            state={state}
            impersonateCompanyColleague={impersonateCompanyColleague}
          />
        </Dialog>
      )}
    </>
  );
};
