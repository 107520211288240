import { Box, IconMail, IconMailOutline, styled } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Badge } from "components/design-system/atoms/badge/badge";
import { useAuthenticatedUserPolling } from "features/polling/AuthenticatedUserPollingProvider";
import { HeaderAction } from "lib/tracking/events";
import { ItemProps } from "../top_nav";

const StyledIconMailOutline = styled(IconMailOutline)`
  &:hover {
    opacity: 0.8;
  }
`;

const StyledIconMail = styled(IconMail)`
  &:hover {
    opacity: 0.8;
  }
`;

export const TopNavItemUserInbox = ({
  inverseColor,
  onItemClick,
}: ItemProps) => {
  const { user } = useUser();
  const { unseenThreadCount } = useAuthenticatedUserPolling();

  const url = App.settings.routes.inbox;
  const trackingAction = HeaderAction.INBOX;

  return (
    <Box mx={0.5}>
      <Badge
        href={url}
        onClick={(event) =>
          onItemClick(
            {
              url,
              trackingAction,
            },
            event,
          )
        }
        count={user ? unseenThreadCount : 0}
        clickable
      >
        {inverseColor ? (
          <StyledIconMail
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        ) : (
          <StyledIconMailOutline
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        )}
      </Badge>
    </Box>
  );
};
