import * as React from "react";
import { useForm } from "react-hook-form";
import { Box, TextField } from "@boligportal/juice";
import { App } from "components/app";
import { FormItemRadios } from "components/forms/form_item_radios";
import {
  OCCUPATION,
  USER_TYPE,
  USER_GENDER,
  occupationLabel,
  userTypeLabel,
  genderLabel,
} from "components/interfaces/profile";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { ProfileFormData } from "../helpers/profile_helpers";

type Props = {
  profile: ProfileFormData;
  onProfileChange(profile: ProfileFormData): void;
  setTitle(value: React.SetStateAction<string>): void;
};

export const PersonalInformation = ({
  onProfileChange,
  profile,
  setTitle,
}: Props) => {
  const { register, getValues, setValue, watch, control } =
    useForm<ProfileFormData>({
      mode: "onChange",
      defaultValues: profile,
    });

  React.useEffect(() => {
    setTitle(t("profile_completion.personal_information_modal.title"));
    Tracking.trackVirtualPageview(
      "/fill-profile-modal/personal-information",
      "Personal information",
    );
  }, []);

  React.useEffect(() => {
    setValue("user_gender", profile && profile.user_gender);
    setValue("first_name", profile && profile.first_name);
    setValue("last_name", profile && profile.last_name);
    setValue("occupation", profile && profile.occupation);
    setValue("birthday", profile && profile.birthday);
    setValue("user_type", profile && profile.user_type);
    setValue("company_name", profile && profile.company_name);
  }, [profile]);

  const handleChange = () => {
    onProfileChange(getValues());
  };

  //const watcher = watch(["user_type"]);
  const watchUserType = watch("user_type");

  return (
    <Box>
      <strong>{t("profile_completion.personal_information_form.title")}</strong>

      <form onChange={handleChange}>
        <FormItemRadios
          control={control}
          name="user_type"
          label={t("profile.user_type.heading")}
          options={[
            {
              id: USER_TYPE.INDIVIDUAL,
              name: t(userTypeLabel[USER_TYPE.INDIVIDUAL]),
            },
            {
              id: USER_TYPE.COMPANY,
              name: t(userTypeLabel[USER_TYPE.COMPANY]),
            },
            {
              id: USER_TYPE.COUPLE,
              name: t(userTypeLabel[USER_TYPE.COUPLE]),
            },
            {
              id: USER_TYPE.FAMILY,
              name: t(userTypeLabel[USER_TYPE.FAMILY]),
            },
            {
              id: USER_TYPE.GROUP,
              name: t(userTypeLabel[USER_TYPE.GROUP]),
            },
          ]}
        />

        {watchUserType === USER_TYPE.COMPANY.toString() && (
          <TextField
            type="text"
            label={t("profile.company_name.heading")}
            {...register("company_name")}
          />
        )}

        {watchUserType !== USER_TYPE.COMPANY.toString() && (
          <>
            <FormItemRadios
              control={control}
              name="user_gender"
              label={t("profile.user_gender.heading")}
              options={[
                {
                  id: USER_GENDER.MALE,
                  name: t(genderLabel[USER_GENDER.MALE]),
                },
                {
                  id: USER_GENDER.FEMALE,
                  name: t(genderLabel[USER_GENDER.FEMALE]),
                },
                {
                  id: USER_GENDER.OTHER,
                  name: t(genderLabel[USER_GENDER.OTHER]),
                },
              ]}
            />

            <Box mb={2}>
              <TextField
                type="date"
                label={t("profile.birthday.heading")}
                lang={App.settings.language}
                {...register("birthday")}
              />
            </Box>

            <FormItemRadios
              control={control}
              name="occupation"
              label={t("profile.occupation.heading")}
              options={[
                {
                  id: OCCUPATION.STUDENT,
                  name: t(occupationLabel[OCCUPATION.STUDENT]),
                },
                {
                  id: OCCUPATION.EMPLOYED,
                  name: t(occupationLabel[OCCUPATION.EMPLOYED]),
                },
                {
                  id: OCCUPATION.UNEMPLOYED,
                  name: t(occupationLabel[OCCUPATION.UNEMPLOYED]),
                },
                {
                  id: OCCUPATION.RETIRED,
                  name: t(occupationLabel[OCCUPATION.RETIRED]),
                },
              ]}
            />
          </>
        )}
      </form>
    </Box>
  );
};
