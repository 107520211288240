import {
  Box,
  Flex,
  Text,
  IconBed,
  IconWallet,
  IconExpand,
  IconLocation,
  IconBusiness,
} from "@boligportal/juice";
import styled from "@emotion/styled";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { AdUtils } from "lib/ad";
import { t } from "lib/i18n";
import { formatNumber } from "lib/utils";
import { FreeContactBadge, UnavailableBadge } from "../components/Badges";
import { NewBuildSlider } from "./NewBuildsSlider";

type Props = {
  ad: Ad;
  onClick?: (id: Ad["id"]) => void;
  linkTarget?: string;
};

const StyledContainer = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-decoration: none;
  z-index: 2;
  color: ${(props) => props.theme.color.text.muted};
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: ${(props) => props.theme.borderRadius.md};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: ${(props) => props.theme.unit(1)} 0;
    flex-direction: row;
    height: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: ${(props) => props.theme.borderRadius.sm};
    margin: 0;
  }

  :hover {
    text-decoration: none;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.unit(1)};
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.unit(2.5)};
  margin-top: ${(props) => props.theme.unit(2)};
`;

const SliderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    order: 1;
    width: 50%;
    height: 100%;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => props.theme.unit(2)};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 50%;
  }
`;

const StyledIconCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.bg.tint};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewBuildAdCard = ({
  ad,
  onClick,
  linkTarget = "_self",
}: Props) => {
  const { id, url, newbuild_details, city, title, street_name } = ad;

  if (!newbuild_details) {
    return null;
  }

  const {
    units_amount,
    min_rooms,
    max_rooms,
    min_size_m2,
    max_size_m2,
    min_monthly_rent,
    max_monthly_rent,
    lead_text,
  } = newbuild_details;

  const IconsArray: { icon: React.ReactNode; text: string }[] = [
    {
      icon: <IconLocation color={"branded"} />,
      text: `${city}, ${street_name}`,
    },
    {
      icon: <IconBusiness color={"branded"} />,
      text: `${formatNumber(units_amount)} ${t("newbuild.units")}`,
    },
    {
      icon: <IconBed color={"branded"} />,
      text: `${formatNumber(min_rooms)} - ${formatNumber(max_rooms)} ${t(
        "ad_card.short_rooms",
      )}`,
    },
    {
      icon: <IconExpand color={"branded"} />,
      text: `${formatNumber(min_size_m2)} - ${formatNumber(max_size_m2)} m²`,
    },
    {
      icon: <IconWallet color={"branded"} />,
      text: `${AdUtils.formatCostsTwoDecimalsCurrency(
        min_monthly_rent,
        false,
      )} - ${AdUtils.formatCostsTwoDecimalsCurrency(
        max_monthly_rent,
        false,
      )} ${t("common.form_fields.max_price.append")}`,
    },
  ];

  const handleOnClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const getUrl = () => {
    if (!App.settings.isEmbedded) {
      return url;
    }
    return `${App.settings.apiBaseUrl}${url}`;
  };

  const isFreeContact = AdUtils.isFreeContact(ad);
  const isUnavailable = AdUtils.isUnavailable(ad);

  const renderBadge = () => {
    if (isUnavailable) {
      return <UnavailableBadge />;
    }

    if (isFreeContact) {
      return <FreeContactBadge />;
    }

    return null;
  };

  return (
    <Box
      maxHeight={{ md: "400px" }}
      width="100%"
    >
      <StyledContainer
        href={getUrl()}
        target={linkTarget}
        onClick={handleOnClick}
      >
        <SliderContainer>
          <NewBuildSlider
            ad={ad}
            isDisabled={isUnavailable}
            actionsLayer={<>{renderBadge()}</>}
          />
        </SliderContainer>
        <StyledBox>
          <Flex column>
            <Text
              size="tiny"
              weight="headline"
              as="h3"
              transform="uppercase"
              color="branded"
            >
              {t("newbuild.newbuild_ad_card.title")}
            </Text>
            <Box
              truncate={1}
              width="100%"
            >
              <Text
                size="h3"
                weight="bold"
                color="base"
                as="h2"
              >
                {title}
              </Text>
            </Box>
            <Box
              truncate={2}
              width="100%"
            >
              <Text
                color="muted"
                weight="base"
                size="small"
              >
                {lead_text}
              </Text>
            </Box>
          </Flex>
          <StyledFlexColumn>
            {IconsArray.map((icon, i) => (
              <StyledFlex key={i}>
                <StyledIconCircle>{icon.icon}</StyledIconCircle>
                <Text
                  size="small"
                  textAlign="center"
                  weight="semiBold"
                >
                  {icon.text}
                </Text>
              </StyledFlex>
            ))}
          </StyledFlexColumn>
        </StyledBox>
      </StyledContainer>
    </Box>
  );
};
