import * as React from "react";

const SvgEnergifynLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={119}
    height={14}
    fill="none"
    viewBox="0 0 119 14"
    {...props}
  >
    <g
      fill="#00543B"
      clipPath="url(#energifyn_logo_svg__energifyn_logo_svg__clip0_33_2329)"
    >
      <path d="M117.331 2.156h-10.769l-2.31 8.62h2.156l1.732-6.46h8.615l-1.731 6.46h2.155l1.73-6.466c.32-1.19-.394-2.154-1.578-2.154zm-27.763 0L90.146 0H79.375c-1.184 0-2.413.966-2.732 2.156l-2.31 8.62h2.154l1.156-4.307h10.771l.576-2.153H78.22l.577-2.154 10.771-.006zm14.84-.004h-2.156l-1.441 5.384s-2.462 1.448-7.928 1.448c-.658 0-1.07-.02-.986-.369l1.73-6.461h-2.134l-1.732 6.461c-.436 1.628.395 2.154 1.578 2.152 6.53.008 9.184-2.162 9.184-2.162l-.866 3.233H88.885l-.577 2.156h10.77c1.184 0 2.413-.964 2.733-2.154l2.597-9.688zM69.992 10.77h2.154l2.31-8.616H72.3l-2.308 8.615zM14.734 2.156L15.31 0H4.54C3.358 0 2.127.964 1.81 2.156L.077 8.617c-.32 1.184.395 2.152 1.578 2.152h10.77l.577-2.152H2.231l.578-2.153h10.775l.572-2.154H3.385l.578-2.154h10.77zm34.467 0c-1.183 0-2.412.964-2.732 2.154l-1.721 6.465H46.9l1.732-6.46h6.464l.578-2.153-6.474-.006zm-21.542 0H16.887l-2.317 8.62h2.17l1.731-6.46h8.616l-1.738 6.46h2.156l1.732-6.46c.323-1.196-.39-2.16-1.578-2.16zm15.874 5.031l.495-1.8.288-1.077c.317-1.184-.395-2.154-1.578-2.154h-8.616c-1.183 0-2.414.964-2.731 2.154l-1.156 4.307c-.318 1.184.394 2.152 1.578 2.152h10.765l.592-2.152H32.39l.371-1.43h10.772zM33.544 4.31h8.618l-.395 1.508H33.14l.404-1.508zm36.602-2.154H59.373c-1.184 0-2.412.964-2.732 2.154l-1.154 4.307c-.436 1.627.395 2.154 1.578 2.152 6.543.006 9.195-2.164 9.195-2.164l-.866 3.233H54.621l-.576 2.156h10.772c1.183 0 2.412-.964 2.731-2.154l2.598-9.684zm-11.527 6.83c-.656 0-1.07-.02-.976-.369l1.154-4.307h8.617l-.866 3.228s-2.461 1.448-7.929 1.448z" />
    </g>
    <defs>
      <clipPath id="energifyn_logo_svg__energifyn_logo_svg__clip0_33_2329">
        <path
          fill="#fff"
          d="M0 0h118.99v14H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEnergifynLogo;
