import { SortableColumn } from "@boligportal/juice";
import { MyRentablesListDataItem } from "apps/property_owner/my_rentables/interfaces/MyRentablesListDataItem";
import { RentableRecordPresentor } from "apps/property_owner/my_rentables/views/rentables_list/presentation/RentableRecordPresenter";

export interface ChooseOrCreateRentableState {
  rentables: Array<MyRentablesListDataItem>;
  activeSortableColumn: SortableColumn<RentableRecordPresentor>;
  filterCount: number;
  allCount: number;
  limit: number;
  start_index: number;
  searchValue: string;
  shouldShowAddressDialog: boolean;
}

export const initialChooseOrCreateRentableState: ChooseOrCreateRentableState = {
  rentables: [],

  activeSortableColumn: {
    column: "address",
    descending: true,
  },
  // we use allCount ZERO to automatically open the AddressDialog,
  // therefore we defaults it to -1
  allCount: -1,
  filterCount: 0,
  limit: 25,
  searchValue: "",
  start_index: 0,
  shouldShowAddressDialog: false,
};
