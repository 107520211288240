import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRentableListingProductsQuery } from "api/queries";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { useOldModal } from "components/modal_controller";
import { StartSubscriptionModal } from "components/start_subscription_modal";
import { t } from "lib/i18n";
import { getQueryVariable } from "lib/utils";

// This logic is basically copy paste from web_ui/frontend/src/components/premium_seeker_action.tsx
// The StartSubscriptionModal performs some requests to start a subscription, and handles the success of it.
export const useStartSubscriptionModal = ({
  onSuccess,
  onSubscriptionActivated,
}: {
  onSuccess?: () => void;
  onSubscriptionActivated?: () => void; // API startSubscription call was successful
}) => {
  const location = useLocation();
  const appActions = useAppActions();
  const oldModal = useOldModal();

  const payment_plans = getQueryVariable("payment_plans", location);
  const payment_method_id = getQueryVariable("payment_method_id", location);

  const ad_id = getQueryVariable("ad_id", location);
  const rentableId = getQueryVariable("rentable_id", location);

  const { data } = useRentableListingProductsQuery(
    rentableId ? Number(rentableId) : null,
  );
  const plans = data?.plans;

  const oneTimeGuard = useRef(false);

  useEffect(() => {
    if (oneTimeGuard.current === true || !plans || !ad_id) {
      return;
    }

    oneTimeGuard.current = true;

    if (payment_method_id === undefined || payment_plans === undefined) {
      return;
    }

    /* eslint-disable compat/compat */
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("ad_id");
    queryParams.delete("rentable_id");
    queryParams.delete("payment_plans");
    queryParams.delete("payment_method_id");
    queryParams.delete("is_app");
    let params = queryParams.toString();
    if (params) {
      params = "?" + params;
    }

    // Note that we use window.location.pathname instead of location.pathname, to get the full path of the url
    // e.g. location.pathname would return /1/listing/products, where as the former would return property-owner/my-rentables/1/listing/products
    history.replaceState(
      {},
      document.title,
      window.location.pathname + params.toString(),
    );

    const planIds = Object.values(plans)
      .flat()
      .filter((plan) => {
        return plan.plan_id === Number(payment_plans);
      });

    // Payment success, and user clicked continue button
    const purchaseCompleteHandler = () => {
      onSuccess?.();
      oldModal.closeModal();
    };

    // Payment failed, and user clicked retry button
    const paymentFailedHandler = () => {
      oldModal.closeModal();
    };

    oldModal.showModal(
      <StartSubscriptionModal
        onClose={oldModal.closeModal}
        paymentMethodId={Number(payment_method_id)!}
        onPurchaseComplete={purchaseCompleteHandler}
        onSubscriptionActivated={onSubscriptionActivated}
        paymentFailedAction={paymentFailedHandler}
        plans={planIds}
        adId={Number(ad_id)}
        appActions={appActions}
        errorButtonLabel={t("payment_modal.button.close")}
      />,
    );
  }, [
    plans,
    ad_id,
    payment_plans,
    payment_method_id,
    onSubscriptionActivated,
    onSuccess,
    appActions,
    oldModal,
    location.search,
    location.pathname,
  ]);

  return null;
};
