import { Notice } from "@boligportal/juice";
import { PublishDraftError } from "../../interfaces/PublishDraftError";

interface Props {
  publishErrors: Array<PublishDraftError>;
}
export const PublishErrors = ({ publishErrors }: Props) => (
  <>
    {publishErrors.map((e) => (
      <Notice
        type="danger"
        key={e.errorMessage}
      >
        {e.errorMessage}
      </Notice>
    ))}
  </>
);
