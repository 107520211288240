import { ReactNode, HTMLAttributes, forwardRef, useState } from "react";
import { Menu, MenuBaseItem } from "@boligportal/juice";
import { App } from "components/app";
import { ChevronIndicator } from "../../icons/chevron_indicator/ChevronIndicator";
import { FlagDenmark } from "../flags/FlagDenmark";
import { FlagSweeden } from "../flags/FlagSweden";
import { FlagUk } from "../flags/FlagUk";

interface FlagOpenerProps extends HTMLAttributes<HTMLButtonElement> {
  isOpened: boolean;
  icon: ReactNode;
  chevronColor?: "text-base" | "text-white";
}

const FlagOpener = forwardRef<HTMLButtonElement, FlagOpenerProps>(
  (props, ref) => {
    const { isOpened, icon, chevronColor, ...openerProps } = props;

    return (
      <button
        {...openerProps}
        className="m-0 border-none bg-transparent p-0 focus:outline-none"
        ref={ref}
      >
        <div className="flex items-center gap-2">
          {icon}
          <ChevronIndicator
            shouldPointDown={!isOpened}
            chevronColor={chevronColor}
          />
        </div>
      </button>
    );
  },
);

const FlagItemRenderer = ({ name, icon }: FlagItem) => (
  <div className="flex items-center gap-2">
    {icon}
    {name}
  </div>
);

interface FlagItem extends MenuBaseItem<FlagItem> {
  icon: ReactNode;
}

type Props = {
  chevronColor?: "text-base" | "text-white";
};

const FlagsMap = {
  da: <FlagDenmark />,
  en: <FlagUk />,
  sv: <FlagSweeden />,
};

export const TenantHubLanguageSelector = ({ chevronColor }: Props) => {
  const [selectedLanguage, setSelectedLanguage] = useState<FlagItem>(() => ({
    icon: FlagsMap[App.settings.language],
  }));

  const flagItems: FlagItem[] = App.settings.languages.map((link) => ({
    name: `${link.text} (${link.code.toUpperCase()})`,
    icon: FlagsMap[link.code],
    href: link.href,
    renderFunction: FlagItemRenderer,
  }));

  return (
    <Menu<FlagItem>
      width={220}
      align="end"
      openerEl={(isOpened) => (
        <FlagOpener
          isOpened={isOpened}
          icon={selectedLanguage.icon}
          chevronColor={chevronColor}
        />
      )}
      onMenuItemSelect={setSelectedLanguage}
      items={flagItems}
    />
  );
};
