import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { Product } from "components/interfaces/product";
import { User } from "components/interfaces/user";

export const isNotEligible = (subscriptions: AdSubscription[], plan: Plan) => {
  if (!subscriptions) {
    return false;
  }

  const productName = plan.product.name;
  const exposureIsActive = isExposureActive(subscriptions);
  const exposurePlusIsActive = isExposurePlusActive(subscriptions);
  const planIsActive = isPlanAlreadyActive(subscriptions, plan);

  if (exposurePlusIsActive || planIsActive) {
    return true;
  }

  if (
    productName === "exposure_ad" ||
    productName === "open_ad" ||
    productName === "promote_ad"
  ) {
    return exposureIsActive;
  }

  return false;
};

const isExposureActive = (subscriptions: AdSubscription[]) =>
  subscriptions.some((sub) => sub.plan.product.name === "exposure_ad");

const isExposurePlusActive = (subscriptions: AdSubscription[]) =>
  subscriptions.some((sub) => sub.plan.product.name === "exposure_plus_ad");

export const isPlanAlreadyActive = (
  subscriptions: AdSubscription[],
  plan: Plan,
) => {
  if (!subscriptions) {
    return false;
  }

  return subscriptions.some(
    (sub) => sub.plan.product.name === plan.product.name,
  );
};

export const isPopularPlan = (user: User | null, plan: Plan) =>
  Boolean(
    user && user.is_private_landlord && plan.product.name === "promote_ad",
  );

export const trafficMagnitude = (value: Product["name"]) => {
  switch (value) {
    case "open_ad":
      return 2;
    case "promote_ad":
      return 3;
    case "exposure_ad":
      return 6;
    case "exposure_plus_ad":
      return 10;
    default:
      return 0;
  }
};
