import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  CheckField,
  Container,
  DataTable,
  Flex,
  SortableColumn,
  Text,
  TextField,
} from "@boligportal/juice";
import { format } from "date-fns";
import { useDebounce } from "hooks/useDebounce";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import { getAds, landlordAdStats } from "lib/customerServiceApi";
import { AdStates } from "lib/enums";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { PAGE_HOME } from "../..";
import { StyledLink } from "../../components/topbar/global_search/components/styled_link";
import { AdStatusRenderer } from "./components/ad_status_renderer";
import { AddressCellRenderer } from "./components/address_cell_renderer";
import { LandlordCellRenderer } from "./components/landlord_cell_renderer";
import {
  AdItem,
  AdItemsInterface,
  CrawledAdItemsInterface,
  IFilterState,
  LandlordAdStats,
  ReviewReason,
} from "./interfaces";
import { ListPaginator } from "./list_paginator";

const INITIAL_FILTER_STATE: IFilterState = {};

const cssSafariFix = `
  /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
    .safari-filter {
      z-index: 999;
    }
    .safari-filter-size {
      z-index: 999;
      min-height: 150px;
      max-height: 300px;
    }
  }}
`;

const getAdDetailLink = (
  item: AdItemsInterface,
  filters: IFilterState,
): string => {
  let url = `${PAGE_HOME}/ads/detail/${item.id}`;
  const id = item.landlord_id;

  if (id) {
    url = `${url}?landlordId=${id}`;
  }

  if (filters) {
    if (id) {
      url = `${url}&`;
    } else {
      url = `${url}?`;
    }
    url = `${url}filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  return url;
};

const AdIdCellRenderer = (item: AdItemsInterface, filters: IFilterState) => (
  <StyledLink to={getAdDetailLink(item, filters)}>
    <Text size="tiny">{item.id}</Text>
  </StyledLink>
);

const CrawlingNameCell = (item: CrawledAdItemsInterface) => (
  <>
    <Text
      block
      color="base"
      size="tiny"
      weight="bold"
    >
      {item.feed.name}
    </Text>
    <Text
      block
      size="tiny"
    >{`${item.feed.source}`}</Text>
  </>
);

const CrawlingAdsPageAll = (props: {
  initialStates?: AdStates[];
  actionLabelText?: string;
  initialReviewReasons?: ReviewReason[];
  initialNeedsFollowUp?: boolean;
}) => {
  const { items, setItems, count, setCount, limit, offset, setOffset } =
    usePagination<CrawledAdItemsInterface>();
  const [sorting, setSorting] = React.useState<
    SortableColumn<AdItemsInterface>
  >({
    column: "advertised_date",
    descending: true,
  });
  const history = useHistory();
  const location = useLocation();
  // Pull initial state from url params
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const landlordId = searchParams.get("landlordId");
  const adId = searchParams.get("adId");
  const feed = searchParams.get("feed");
  const crawlingSourceId = searchParams.get("crawlingSourceId");

  const actionLabelText = props.actionLabelText
    ? props.actionLabelText
    : "View";

  // If a flag checkbox is unchecked, the flag must be false (not undefined)
  // So the flags have a value from the beginning
  const filterState: IFilterState = {
    ...INITIAL_FILTER_STATE,
    landlordId: landlordId ? Number(landlordId) : undefined,
    adId: adId ? Number(adId) : undefined,
    crawlingAds: true,
    crawlingSourceId: crawlingSourceId ? Number(crawlingSourceId) : undefined,
    feed: feed ? feed : undefined,
    adStates: props.initialStates || [],
    needsFollowUp:
      props.initialNeedsFollowUp === undefined
        ? false
        : props.initialNeedsFollowUp,
    review_reasons: props.initialReviewReasons ?? null,
    customAddress: false,
  };

  const [filters, setFilters] = React.useState<IFilterState>(filterState);
  const prevFilters = usePrevious(filters);
  const tempAdItems = React.useRef<AdItem[]>([]);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (prevFilters !== filters && offset !== 0) {
      // Clear offset on filters changed
      setOffset(0);
      return;
    }
    if (scrollContainerRef.current) {
      // Scroll to top
      scrollContainerRef.current.scrollTop = 0;
    }
    const abortController = new AbortController();
    const { signal } = abortController;
    getAds({
      signal,
      offset,
      limit,
      sorting: sorting.column,
      descending: sorting.descending,
      filters: {
        crawlingAds: filters.crawlingAds,
        crawlingSourceId: filters.crawlingSourceId,
        feed: filters.feed,
        adId: filters.adId,
        address: filters.address,
        landlordEmail: filters.landlordEmail,
        landlordId: filters.landlordId,
        contactEmail: filters.contactEmail,
        contactPhone: filters.contactPhone,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        adStates: filters.adStates,
        needsFollowUp: filters.needsFollowUp,
        review_reasons: filters.review_reasons,
        customAddress: filters.customAddress,
      },
    })
      .then((response) => {
        tempAdItems.current = response.data;

        const landlord_ids: number[] = [];

        tempAdItems.current.forEach((item) => {
          // The Ad could be a CRAWLED ad, and we can tell that
          // by checking if the ad has a landlord id.
          const id = item.landlord_id;
          if (id) {
            landlord_ids.push(id);
          }
        });

        //const landlord_ids = tempAdItems.current.map(item => item.landlord_id);
        landlordAdStats(landlord_ids).then((response) => {
          const adItemsWithStats = tempAdItems.current.map((item) => {
            let landlordAdStats: LandlordAdStats | null = null;
            response.data.forEach((l) => {
              if (l.id === item.landlord_id) {
                landlordAdStats = l;
              }
            });

            const newItem: CrawledAdItemsInterface = {
              ...item,
              landlord_ad_stats: landlordAdStats,
              landlord_id: item.landlord_id,
            };
            return newItem;
          });
          setItems(adItemsWithStats);
        });
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [filters, offset, limit, sorting]);

  const handleGotoDetails = (item: CrawledAdItemsInterface) => {
    history.push(getAdDetailLink(item, filters));
  };

  const filterChangeHandler = (newFilter: IFilterState) => {
    setFilters(newFilter);
  };

  const handleSortedClick = (fieldName, descending: boolean) => {
    setSorting({
      column: fieldName,
      descending: descending,
    });
  };

  return (
    <Flex
      column
      width="100%"
    >
      <style>{cssSafariFix}</style>
      <Flex
        className={
          tempAdItems.current.length ? "safari-filter-size" : "safari-filter"
        }
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <CrawlingAdsPageAllFilters
            onFilterChange={filterChangeHandler}
            initialFilterState={filterState}
          />
        </Container>
      </Flex>
      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<CrawledAdItemsInterface>
            fullscreen
            keyField="id"
            items={items}
            activeSortableColumn={sorting}
            onSortClick={handleSortedClick}
            rowActions={[
              {
                label: actionLabelText,
                callback: handleGotoDetails,
              },
            ]}
            columns={[
              {
                fieldName: "feed",
                fieldLabel: "Feed",
                cellRenderer: (item) => <CrawlingNameCell {...item} />,
              },
              {
                fieldName: "id",
                fieldLabel: "Ad ID",
                cellRenderer: (item: AdItemsInterface) =>
                  AdIdCellRenderer(item, filters),
                width: "170px",
                sortable: true,
              },
              {
                fieldName: "address",
                fieldLabel: "Address",
                cellRenderer: AddressCellRenderer,
                sortable: true,
              },
              {
                fieldName: "landlord_email",
                fieldLabel: "Landlord email",
                cellRenderer: LandlordCellRenderer,
              },
              {
                fieldName: "landlord_id",
                fieldLabel: "Landlord ID",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.landlord_id}</Text>
                ),
                width: "100px",
              },
              {
                fieldName: "contact_email",
                fieldLabel: "Contact email",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.contact_email}</Text>
                ),
              },
              {
                fieldName: "contact_phone",
                fieldLabel: "Contact phone",
                width: "150px",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.contact_phone}</Text>
                ),
              },
              {
                fieldName: "state",
                fieldLabel: "Status",
                cellRenderer: AdStatusRenderer,
              },
              {
                fieldName: "instance_created_date",
                fieldLabel: "Date created",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(
                      new Date(item.instance_created_date),
                      "yyyy-MM-dd HH:mm:ss",
                    )}
                  </Text>
                ),
                width: "170px",
              },
              {
                fieldName: "advertised_date",
                fieldLabel: "Advertised date",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {item.advertised_date
                      ? format(
                          new Date(item.advertised_date),
                          "yyyy-MM-dd HH:mm:ss",
                        )
                      : "-"}
                  </Text>
                ),
                width: "170px",
                sortable: true,
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                totalCount={count}
                limit={limit}
                itemCount={items.length}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { CrawlingAdsPageAll };

// ************************************************************************************************
// Sub component used only by this component. Isolates the Input filters and notifies the
// implementing view after debounced delay to avoid having the Implementing view to rerender
// on each Keystroke.
// ************************************************************************************************

const CrawlingAdsPageAllFilters = ({
  initialFilterState,
  onFilterChange,
}: {
  initialFilterState: IFilterState;
  onFilterChange: (newFilters: IFilterState) => void;
}) => {
  const [filterState, setFilterState] =
    React.useState<IFilterState>(initialFilterState);
  const debouncedValue = useDebounce(filterState, 250);

  React.useEffect(() => {
    if (debouncedValue) {
      onFilterChange(debouncedValue);
    }
  }, [onFilterChange, debouncedValue]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const { type } = event.target;
    let value: string | null = event.target.value; //eslint-disable-line

    // Backend expect null for "empty date or text values"
    if ((type === "date" || type === "text") && value === "") {
      value = null;
    }

    console.log(field, value);

    const newFilterState: IFilterState = {
      ...filterState,
      [field]: value,
    };

    setFilterState(newFilterState);
  };

  const handlCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id as AdStates;
    const { checked } = event.target;

    const newAdStates = filterState.adStates ? filterState.adStates : [];

    if (checked) {
      newAdStates.push(field);
    } else {
      newAdStates.splice(newAdStates.indexOf(field), 1);
    }

    const newFilterState: IFilterState = {
      ...filterState,
      adStates: newAdStates,
    };
    setFilterState(newFilterState);
  };

  const handlesNeedsFollowUpChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;

    const newFilterState: IFilterState = {
      ...filterState,
      needsFollowUp: checked,
    };
    setFilterState(newFilterState);
  };

  const handleReviewReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    reviewReason: ReviewReason,
  ) => {
    const { checked } = event.target;
    let reasonsState = filterState.review_reasons ?? [];

    if (checked) {
      reasonsState.push(reviewReason);
    } else {
      reasonsState = reasonsState.filter((reason) => reason !== reviewReason);
    }

    const newFilterState: IFilterState = {
      ...filterState,
      review_reasons: reasonsState.length === 0 ? null : reasonsState,
    };

    setFilterState(newFilterState);
  };

  const handleCustomAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;

    const newFilterState: IFilterState = {
      ...filterState,
      customAddress: checked,
    };
    setFilterState(newFilterState);
  };

  const states = filterState.adStates || [];
  const { needsFollowUp } = filterState;
  const { customAddress } = filterState;

  return (
    <Flex column>
      <Flex
        gap
        pt={2}
      >
        <TextField
          label="Feed"
          defaultValue={filterState?.feed?.toString() ?? ""}
          id="feed"
          onChange={handleFilterChange}
        />
        <TextField
          label="Ad ID"
          defaultValue={filterState?.adId?.toString() ?? ""}
          id="adId"
          onChange={handleFilterChange}
        />
        <TextField
          label="Address"
          defaultValue={filterState?.address?.toString() ?? ""}
          id="address"
          onChange={handleFilterChange}
        />
        <TextField
          label="Landlord email"
          defaultValue={filterState?.landlordEmail?.toString() ?? ""}
          id="landlordEmail"
          onChange={handleFilterChange}
        />
        <TextField
          label="Landlord ID"
          defaultValue={filterState?.landlordId?.toString() ?? ""}
          id="landlordId"
          onChange={handleFilterChange}
        />
        <TextField
          label="Contact email"
          defaultValue={filterState?.contactEmail?.toString() ?? ""}
          id="contactEmail"
          onChange={handleFilterChange}
        />
        <TextField
          label="Contact phone"
          defaultValue={filterState?.contactPhone?.toString() ?? ""}
          id="contactPhone"
          onChange={handleFilterChange}
        />

        <TextField
          label="Date from"
          type="date"
          value={filterState?.dateFrom?.toString() ?? ""}
          id="dateFrom"
          onChange={handleFilterChange}
        />
        <TextField
          label="Date to"
          type="date"
          value={filterState?.dateTo?.toString() ?? ""}
          id="dateTo"
          onChange={handleFilterChange}
        />
      </Flex>
      <Flex mt={-4}>
        <Box>
          <CheckField
            inline
            id={AdStates.ACTIVE_APPROVED}
            label="Active"
            checked={filterState.adStates?.includes(AdStates.ACTIVE_APPROVED)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id={AdStates.INACTIVE_PENDING_APPROVAL}
            label="Waiting for approval"
            checked={states.includes(AdStates.INACTIVE_PENDING_APPROVAL)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id={AdStates.INACTIVE_DRAFT}
            label="Inactive"
            checked={states.includes(AdStates.INACTIVE_DRAFT)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id={AdStates.INACTIVE_REJECTED}
            label="Rejected"
            checked={states.includes(AdStates.INACTIVE_REJECTED)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id={AdStates.RESERVED_APPROVED}
            label="Reserved"
            checked={states.includes(AdStates.RESERVED_APPROVED)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id={AdStates.RENTED_OUT_APPROVED}
            label="Rented out"
            checked={states.includes(AdStates.RENTED_OUT_APPROVED)}
            onChange={handlCheckboxChange}
          />
          <CheckField
            inline
            id="customAddress"
            label="Custom Address"
            checked={customAddress}
            onChange={handleCustomAddressChange}
          />
        </Box>
        <Box
          textAlign={"right"}
          flexGrow
        >
          <CheckField
            inline
            id="reviewReason"
            label="Needs review"
            checked={filterState.review_reasons?.includes("MODIFIED")}
            onChange={(e) => handleReviewReasonChange(e, "MODIFIED")}
          />
          <CheckField
            inline
            id={"pendingApproval"}
            label={"Pending approval"}
            checked={filterState.review_reasons?.includes("AUTO_APPROVED")}
            onChange={(e) => handleReviewReasonChange(e, "AUTO_APPROVED")}
          />
          <CheckField
            inline
            id="needsFollowUp"
            label="Needs follow up"
            checked={needsFollowUp}
            onChange={handlesNeedsFollowUpChange}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
