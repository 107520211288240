import { useFormContext } from "react-hook-form";
import {
  Box,
  Text,
  Dialog,
  DialogContent,
  DialogHeader,
  TextField,
  Button,
  styled,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { StyledTextBlock } from "./rental_3d_floor_plan";

export const MatterportFloorPlanWithModalExplainer = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        {...register("interactive_floor_plan_id")}
        label={t("rentable_details.editor.3d_floor_plan")}
        type="text"
      />
      <Dialog
        opener={
          <StyledReadMoreButton
            variant="subtle"
            text
          >
            {t("rentable_details.editor.3d_floor_plan.read_more")}
          </StyledReadMoreButton>
        }
      >
        <DialogHeader>
          {t("rentable_details.editor.3d_floor_plan.dialog.header")}
        </DialogHeader>
        <DialogContent>
          <InteractiveFloorPlanInfo />
        </DialogContent>
      </Dialog>
    </>
  );
};

const StyledReadMoreButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSize.tiny};
  color: ${(props) => props.theme.color.text.muted} !important;
  margin-top: ${(props) => props.theme.unit(-2)};
  margin-bottom: ${(props) => props.theme.unit(1)};
  display: block;
`;

export const InteractiveFloorPlanInfo = () => (
  <Box>
    <Text
      block
      weight="bold"
      size="small"
      mt={4}
    >
      {t("create.field.3d_floor_plan.helptext.heading")}
    </Text>

    <Text
      block
      mt={1}
    >
      {t("create.field.3d_floor_plan.helptext.content")}
    </Text>

    <Text
      block
      weight="bold"
      size="small"
      mt={1}
    >
      {t("create.field.3d_floor_plan.example.heading")}
    </Text>

    <Text
      block
      mt={1}
    >
      https://my.matterport.com/show/?m=
      <StyledTextBlock>7Rdik2QnFzE</StyledTextBlock>
    </Text>
  </Box>
);
