import {
  Card,
  Text,
  Badge,
  Flex,
  Box,
  Button,
  Hidden,
  SortingSelect,
  SelectField,
} from "@boligportal/juice";
import styled from "@emotion/styled";
import { App } from "components/app";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { Market } from "components/markets/market_settings";
import { isPlanAlreadyActive } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getProductName } from "lib/product";
import { calculatePrice, formatCurrency } from "lib/utils";
import { SellingPoint } from "../SellingPoint/SellingPoint";

const PurchaseButton = styled(Button)`
  min-width: 130px;
`;

const CardWithinCard = styled(Card)`
  background: ${(props) => props.theme.color.bg.tint};
  & {
    padding: ${(props) => props.theme.unit(2)};
  }
`;

const StyledCard = styled(Card)`
  background: theme.color.bg.base;
`;

const LineTroughText = styled(Text)`
  text-decoration: line-through;
`;

const StyledSortingSelect = styled(SortingSelect)`
  select {
    direction: unset !important;
  }
`;

type Props = {
  activeUpsellSubscriptions: AdSubscription[];
  plans: Plan[];
  benefits: string[];
  badgeText?: string;
  selectedPlan: number;
  isDisabled: boolean;
  onClick: () => void;
  onSelectPlan?: (plan: number) => void;
};

export const UpsellProduct = ({
  activeUpsellSubscriptions,
  plans,
  benefits,
  badgeText,
  selectedPlan,
  isDisabled,
  onClick,
  onSelectPlan,
}: Props) => {
  const hasMultiplePlans = plans.length > 1;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onSelectPlan) {
      onSelectPlan(parseInt(e.target.value));
    }
  };
  const headline = getProductName(plans[selectedPlan].product);
  const price = formatCurrency(
    calculatePrice(
      plans[selectedPlan].price.total,
      plans[selectedPlan].price.percent_off,
    ),
    plans[selectedPlan].currency,
  );
  const subtotal = formatCurrency(
    calculatePrice(
      plans[selectedPlan].price.subtotal,
      plans[selectedPlan].price.percent_off,
    ),
    plans[selectedPlan].currency,
  );
  const originalPrice = plans[selectedPlan].price.percent_off
    ? formatCurrency(
        plans[selectedPlan].price.total,
        plans[selectedPlan].currency,
      )
    : "";
  const purchased = isPlanAlreadyActive(
    activeUpsellSubscriptions,
    plans[selectedPlan],
  );

  const isSubscription = plans[selectedPlan].is_subscription;
  const planDurationText = plans[selectedPlan].is_subscription
    ? tn("upsell_products.runningPeriod", plans[selectedPlan].duration_days)
    : tn(
        "fixed_upsell_products.runningPeriod",
        plans[selectedPlan].duration_days,
      );

  const purchaseButtonText = purchased
    ? t("upsell.dialog.action_button_text_purchased")
    : t("upsell.dialog.action_button_text");

  return (
    <StyledCard>
      <Flex justify="space-between">
        <Flex align="center">
          <Text
            size="h3"
            weight="bold"
            mr={2}
          >
            {headline}
          </Text>
          {badgeText && (
            <Badge
              state="success"
              variant="solid"
            >
              {badgeText}
            </Badge>
          )}
        </Flex>
        <Hidden
          on={{
            xs: true,
            sm: false,
          }}
        >
          <PurchaseButton
            onClick={onClick}
            variant="primary"
            disabled={isDisabled}
          >
            {purchaseButtonText}
          </PurchaseButton>
        </Hidden>
      </Flex>

      <Flex
        justify="space-between"
        mt={1}
        mb={2}
      >
        <Text
          size="h5"
          mr={2}
        >
          {originalPrice && (
            <LineTroughText mr={1}>{originalPrice}</LineTroughText>
          )}
          <b>{price} </b>
          {App.settings.market === Market.BOLIGPORTAL_DK && (
            <Text
              size="small"
              color="muted"
            >
              ({subtotal} {t("common.ex_vat")})
            </Text>
          )}
        </Text>
        <Hidden
          on={{
            xs: true,
            sm: false,
          }}
        >
          <Box>
            {hasMultiplePlans ? (
              <StyledSortingSelect
                disabled={isDisabled}
                label={t("upsell.selected_duration_label")}
                items={plans.map((plan, index) => ({
                  label: tn(
                    "fixed_upsell_products.runningPeriod",
                    plan.duration_days,
                  ),
                  value: index.toString(),
                }))}
                onChange={handleChange}
                value={selectedPlan?.toString()}
              />
            ) : (
              <Text
                block
                size="small"
                color="muted"
              >
                {planDurationText}
              </Text>
            )}

            {isSubscription && (
              <Text
                block
                size="small"
                color="muted"
              >
                {t("upsell.dialog.cancel_anytime")}
              </Text>
            )}
          </Box>
        </Hidden>
      </Flex>

      <CardWithinCard>
        {benefits.map((benefit) => (
          <SellingPoint
            key={benefit}
            text={benefit}
          />
        ))}
      </CardWithinCard>
      <Box
        hidden={{
          sm: true,
        }}
        textAlign="center"
      >
        <>
          <PurchaseButton
            fullWidth
            onClick={onClick}
            variant="primary"
            disabled={isDisabled}
          >
            {purchaseButtonText}
          </PurchaseButton>
          {hasMultiplePlans ? (
            <SelectField
              disabled={isDisabled}
              label={t("upsell.selected_duration_label")}
              items={plans.map((plan, index) => ({
                label: tn(
                  "fixed_upsell_products.runningPeriod",
                  plan.duration_days,
                ),
                value: index.toString(),
              }))}
              onChange={handleChange}
              value={selectedPlan?.toString()}
            />
          ) : (
            <Text
              mt={1}
              block
              size="small"
              color="muted"
            >
              {planDurationText}
            </Text>
          )}

          {isSubscription && (
            <Text
              block
              size="small"
              color="muted"
            >
              {t("upsell.dialog.cancel_anytime")}
            </Text>
          )}
        </>
      </Box>
    </StyledCard>
  );
};
