import { fetchFromAPI } from "lib/api";

interface LeadFormPayload {
  address: string;
  move_in_date: string | null;
  tenant_first_name: string;
  tenant_last_name: string;
  email: string;
  phone: string;
  cpr: string | null;
  category: string;
  provider: string;
  address_changed: boolean;
}

interface SendTenantHubLeadResponse {
  success: boolean;
  error: string;
}

export const sendTenantHubLead = async (
  payload: LeadFormPayload,
  userToken?: string,
): Promise<SendTenantHubLeadResponse> => {
  const response = await fetchFromAPI(`/api/leads/send`, {
    method: "POST",
    body: JSON.stringify({
      ...payload,
      user_token: userToken,
    }),
  });

  return response.json();
};
