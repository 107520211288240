export enum ThreadColorEnum {
  RED = 1,
  YELLOW = 2,
  GREEN = 3,
}

export const getThreadColorCode = (color: ThreadColorEnum | null) => {
  if (!color) {
    return "#FFFFFF";
  }

  return {
    [ThreadColorEnum.RED]: "#D02F06",
    [ThreadColorEnum.YELLOW]: "#FED450",
    [ThreadColorEnum.GREEN]: "#2EC74A",
  }[color];
};

export const threadColorLabel = {
  [ThreadColorEnum.RED]: "inbox.red",
  [ThreadColorEnum.YELLOW]: "inbox.yellow",
  [ThreadColorEnum.GREEN]: "inbox.green",
};
