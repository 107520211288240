import { Dialog, DialogContent } from "@boligportal/juice";
import { ModalActions } from "components/modal_controller";
import { SignupContext } from "lib/enums";
import { withModalActions } from "lib/injectors";
import { OnboardingButtons } from "./OnboardingButtons";
import { SlideshowContent } from "./SlideshowContent";
import { useOnboarding } from "./useOnboarding";

interface Props {
  context?: SignupContext;
  modalActions: ModalActions;
  onClose: () => void;
}

const OnboardingDialog = ({ context, modalActions, onClose }: Props) => {
  const handleOnClose = () => {
    modalActions.closeModal();
    onClose();
  };
  const {
    currentSlide,
    filteredSlides,
    isLastSlide,
    setCurrentSlide,
    handleNextClick,
    handleOnSkip,
  } = useOnboarding(handleOnClose, context);

  return (
    <Dialog
      open
      hideOnClickOutside
      dismissable={false}
      onClose={handleOnClose}
    >
      <DialogContent>
        <SlideshowContent
          handleOnSkip={handleOnSkip}
          currentSlide={currentSlide}
          filteredSlides={filteredSlides}
          handleNextClick={handleNextClick}
          onIndexChange={setCurrentSlide}
        />
      </DialogContent>
      <OnboardingButtons
        currentSlide={currentSlide}
        filteredSlides={filteredSlides}
        onIndexChange={setCurrentSlide}
        handleNextClick={handleNextClick}
        isLastSlide={isLastSlide}
        handleOnSkip={handleOnSkip}
      />
    </Dialog>
  );
};

export const OnboardingModal = withModalActions(OnboardingDialog);
