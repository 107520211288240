import { useEffect } from "react";
import {
  Text,
  Box,
  Flex,
  IconCloseCircleOutline,
  EmptyBox,
  IconCheckmarkCircleOutline,
  Spinner,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { useVideoPlaybackFeature } from "features/video_feature/hooks/use_video_playback_feature";

// ************************************************************************************************
// Vimeo Video Playback Feature
// Given a videoId, user can see a video in Vimeo's video player.
// ************************************************************************************************
const VimeoVideoPlayback = ({
  videoId,
  videoHash,
  uploadFinishedHeader,
  uploadFinishedInfo,
  uploadMalformedHeader,
  uploadMalformedInfo,
}: {
  videoId: number;
  videoHash?: string;
  uploadMalformedHeader: string;
  uploadMalformedInfo: string;
  uploadFinishedHeader: string;
  uploadFinishedInfo: string;
}) => {
  const { videoStatus, checkVideoStatus } = useVideoPlaybackFeature();

  useEffect(() => {
    checkVideoStatus(videoId);
  }, [videoId, checkVideoStatus]);

  return (
    <>
      {videoStatus === "malformed" && (
        <VideoIsMalformed
          uploadMalformedHeader={uploadMalformedHeader}
          uploadMalformedInfo={uploadMalformedInfo}
        />
      )}
      {videoStatus === "idle" && (
        <div>
          <Spinner />
        </div>
      )}
      {videoStatus === "playable" && (
        <Box bg="tint">
          <VimeoVideoPlayer
            videoId={videoId}
            videoHash={videoHash}
          />
        </Box>
      )}
      {videoStatus === "processing" && (
        <UploadFinished
          uploadFinishedHeader={uploadFinishedHeader}
          uploadFinishedInfo={uploadFinishedInfo}
        />
      )}
    </>
  );
};

export { VimeoVideoPlayback };

// ************************************************************************************************
// Sub components that only used by VimeoVideoPlayback Feature
// ************************************************************************************************

const createVideoMarkup = (videoId: number, videoHash?: string) => {
  const url = videoHash
    ? `https://player.vimeo.com/video/${videoId}?h=${videoHash}&`
    : `https://player.vimeo.com/video/${videoId}?`;
  const videoSettings = "color=ed692c&title=0&byline=0&portrait=0&autoplay=0";
  const videoUrl = `${url}${videoSettings}`;

  return {
    __html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="${videoUrl}" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`,
  };
};

const VimeoVideoPlayer = ({
  videoId,
  videoHash,
}: {
  videoId: number;
  videoHash?: string;
}) => <div dangerouslySetInnerHTML={createVideoMarkup(videoId, videoHash)} />;

const VideoIsMalformed = ({
  uploadMalformedHeader,
  uploadMalformedInfo,
}: {
  uploadMalformedHeader: string;
  uploadMalformedInfo: string;
}) => (
  <EmptyBox>
    <Flex justify="center">
      <IconCloseCircleOutline
        css={css`
          font-size: 100px;
        `}
        color="danger"
      />
    </Flex>
    <Box mt={2}>
      <Text size="h3">{uploadMalformedHeader}</Text>
    </Box>
    <Box>
      <Text>{uploadMalformedInfo}</Text>
    </Box>
  </EmptyBox>
);

const UploadFinished = ({
  uploadFinishedHeader,
  uploadFinishedInfo,
}: {
  uploadFinishedHeader: string;
  uploadFinishedInfo: string;
}) => (
  <EmptyBox>
    <Flex justify="center">
      <IconCheckmarkCircleOutline
        css={css`
          font-size: 100px;
        `}
        color="branded"
      />
    </Flex>
    <Box mt={1}>
      <Text
        size="h3"
        weight="semiBold"
      >
        {uploadFinishedHeader}
      </Text>
    </Box>
    <Box>
      <Text>{uploadFinishedInfo}</Text>
    </Box>
  </EmptyBox>
);
