import * as React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  type UseControllerProps,
} from "react-hook-form";
import { NumberField } from "@boligportal/juice";

export const ControlledPaymentNumberField = ({
  control,
  name,
  errors,
  validator,
  required,
  rules,
  ...rest
}: {
  control: Control<any>;
  name: string;
  errors?: FieldErrors;
  validator?: (value: any) => boolean;
  rules?: UseControllerProps["rules"];
  required?: boolean;
} & React.ComponentProps<typeof NumberField>) => (
  <Controller
    render={({ field: { onChange, value, ref, ...fieldRest } }) => (
      <NumberField
        ref={ref}
        required={required}
        onValueChange={(values) => {
          //It is right now impossible to delete the value of the inputfield, therefor we need to have this check.
          onChange(values.floatValue ?? null);
        }}
        append={"kr."}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        onKeyDown={(event) => {
          const { key } = event;
          if (key === ".") {
            event.preventDefault();
          }
        }}
        {...rest}
        value={value as any}
        {...fieldRest}
      />
    )}
    name={name}
    control={control}
    rules={{
      required: required,
      validate: validator,
      ...rules,
    }}
  />
);
