import { useState, useCallback, useEffect } from "react";
import { AdItem } from "apps/customer_service/pages/ads_page/interfaces";
import { Company } from "apps/customer_service/pages/user_detail_page/company_details_view/interfaces";
import { useDebounce } from "hooks/useDebounce";
import { UserListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { searchGloballyService } from "../services/search_globally_service";

const useGloballySearch = (
  withDebouncedSearchDelay: number = 250,
  minCharsRequired: number = 3,
) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(
    searchValue,
    withDebouncedSearchDelay,
  );
  const [users, setUsers] = useState<{
    results: UserListItem[];
    count: number;
  }>({
    results: [],
    count: 0,
  });

  const [ads, setAds] = useState<{
    results: AdItem[];
    count: number;
  }>({
    results: [],
    count: 0,
  });

  const [companies, setCompanies] = useState<{
    results: Company[];
    count: number;
  }>({
    results: [],
    count: 0,
  });

  const search = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    if (debouncedSearchValue.length < minCharsRequired) {
      setUsers({
        results: [],
        count: 0,
      });
      setAds({
        results: [],
        count: 0,
      });
      setCompanies({
        results: [],
        count: 0,
      });
      return;
    }

    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    const { signal } = abortController;

    searchGloballyService(debouncedSearchValue, signal)
      .then((response) => {
        setUsers(response.users);
        setAds(response.ads);
        setCompanies(response.companies);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [debouncedSearchValue, minCharsRequired]);

  return {
    searchValue,
    search,
    users,
    ads,
    companies,
  };
};

export { useGloballySearch };
