import { SignupContext } from "lib/enums";

export enum OnboardingSlideType {
  WELCOME = "WELCOME",
  SEARCH_AGENT = "SEARCH_AGENT",
  FAVOURITES = "FAVOURITES",
  FILL_OUT_PROFILE = "FILL_OUT_PROFILE",
}

export interface Slide {
  slide: OnboardingSlideType;
  title: string;
  description: string;
  image: string;
  shouldShow: (context?: SignupContext) => boolean;
}
