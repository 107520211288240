import {
  Flex,
  Box,
  FlexColumn,
  Text,
  Hidden,
  styled,
  useTheme,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";

export const ScalabaleHeadline = styled.h2`
  font-size: 60px;
  line-height: 1.2em;
  color: #191919;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 42px;
  }
`;

const StyledOverflow = styled.div`
  overflow: hidden;
`;

interface StatementProps {
  headline: string;
  text: string;
  mb: boolean;
}

const Statement = ({ headline, text, mb }: StatementProps) => (
  <Flex
    column
    mb={mb ? 4 : 0}
  >
    <Box mb={1}>
      <Text
        size="h3"
        weight="bold"
        color="branded"
      >
        {headline}
      </Text>
    </Box>

    <Text>{text}</Text>
  </Flex>
);

interface Props {
  imageSource: string;
  headline: string;
  text: string;
  statement1Headline: string;
  statement2Headline: string;
  statement3Headline: string;
  statement1Text: string;
  statement2Text: string;
  statement3Text: string;
}

export const StatusPageSection = ({
  imageSource,
  headline,
  text,
  statement1Headline,
  statement2Headline,
  statement3Headline,
  statement1Text,
  statement2Text,
  statement3Text,
}: Props) => {
  const theme = useTheme();

  return (
    <StyledOverflow>
      <PageBlock
        bg="default"
        py="extraLarge"
      >
        <ContractsLandingPageContainer>
          <Flex
            gap={10}
            mt={{
              xs: 0,
              lg: 8,
            }}
            mb={{
              xs: 0,
              lg: 8,
            }}
            wrap
          >
            <FlexColumn
              size={{
                xs: 12,
                lg: 5,
              }}
            >
              <Flex
                column
                mr={{
                  xs: 0,
                  lg: 6,
                }}
              >
                <Box
                  textAlign={{
                    xs: "center",
                    lg: "left",
                  }}
                  mb={2}
                >
                  <ScalabaleHeadline>{headline}</ScalabaleHeadline>
                </Box>

                <Box
                  textAlign={{
                    xs: "center",
                    lg: "left",
                  }}
                  mb={4}
                >
                  <Text size="h4">{text}</Text>
                </Box>

                {/* Desktop - text section */}
                <Box
                  hidden={{
                    xs: true,
                    lg: false,
                  }}
                >
                  <Statement
                    headline={statement1Headline}
                    text={statement1Text}
                    mb
                  />
                  <Statement
                    headline={statement2Headline}
                    text={statement2Text}
                    mb
                  />
                  <Statement
                    headline={statement3Headline}
                    text={statement3Text}
                    mb={false}
                  />
                </Box>
              </Flex>
            </FlexColumn>
            <FlexColumn
              css={{
                alignItems: "center",
              }}
              size={{
                xs: 12,
                lg: 7,
              }}
            >
              <Flex
                hidden={{
                  xs: true,
                  lg: false,
                }}
                height="100%"
                align="center"
              >
                <Box>
                  <CdnImage
                    style={{
                      boxShadow: theme.shadow.lg,
                      maxHeight: 600,
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                    src={imageSource}
                  />
                </Box>
              </Flex>
              <Hidden
                on={{
                  xs: false,
                  md: true,
                }}
              >
                <CdnImage
                  style={{
                    maxHeight: 330,
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                  src={imageSource}
                />
              </Hidden>
              <Hidden
                on={{
                  xs: true,
                  md: false,
                  lg: true,
                }}
              >
                <CdnImage
                  style={{
                    maxHeight: 440,
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                  src={imageSource}
                />
              </Hidden>
            </FlexColumn>
            {/* Mobile - text section */}

            <Box
              mt={8}
              hidden={{
                xs: false,
                lg: true,
              }}
            >
              <Statement
                headline={statement1Headline}
                text={statement1Text}
                mb
              />
              <Statement
                headline={statement2Headline}
                text={statement2Text}
                mb
              />
              <Statement
                headline={statement3Headline}
                text={statement3Text}
                mb={false}
              />
            </Box>
          </Flex>
        </ContractsLandingPageContainer>
      </PageBlock>
    </StyledOverflow>
  );
};
