import { Flex, Box, Text } from "@boligportal/juice";
import { AdStateLabel } from "../../../ad_state_label";
import { ISearchItemAd } from "../interfaces";

type Props = {
  data: ISearchItemAd;
};

const SearchItemAd = ({ data }: Props) => (
  <Flex
    px={1}
    py={0.5}
  >
    <Box>
      <Text size="small">{`${data.formatted_address}`}</Text>
    </Box>
    <Box ml={0.5}>
      <AdStateLabel
        state={data.state}
        needsFollowUp={data.needs_follow_up}
        reviewReason={data.review_reason}
        claimer={null}
        isDeleted={Boolean(data.deleted_date)}
      />
    </Box>
    <Box flexPush>
      <Text
        size="small"
        weight="bold"
      >{`#${data.ad_id}`}</Text>
    </Box>
  </Flex>
);

export { SearchItemAd };
