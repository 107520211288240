import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Badge,
  Box,
  Button,
  Flex,
  IconArrowForward,
  IconPeopleCircleOutline,
  Text,
  styled,
  useNotifications,
} from "@boligportal/juice";
import { PAGE_USERS } from "apps/customer_service";
import { SimpleTextLink } from "apps/customer_service/components/simple_text_link/SimpleTextLink";
import { TitleFormElement } from "apps/customer_service/pages/ad_detail_page/components/form_elements/title_form_element";
import { TaskResponseInterface } from "apps/customer_service/pages/ad_detail_page/interfaces";
import { LandlordResponseInterface } from "apps/customer_service/pages/ads_page/interfaces";
import { Ad } from "components/interfaces/ad";
import { USER_TYPE } from "components/interfaces/profile";
import { useExternalPhoneWebsites } from "features/cs/market_specific_feature/hooks/useExternalPhoneWebsites";
import { getLandlord, updateProfile } from "lib/customerServiceApi";

type NewBuildLandlordInformationProps = {
  newBuild: Ad | undefined;
};

interface LandlordInformationInterface {
  phone_number: string | null;
  email: string | null;
}

const StyledA = styled.a`
  color: ${(props) => props.theme.color.text.base};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.color.text.base};
    text-decoration: none;
  }
`;

export const NewBuildLandlordInformation = ({
  newBuild,
}: NewBuildLandlordInformationProps) => {
  const { externalPhoneWebsites } = useExternalPhoneWebsites();
  const { addNotification } = useNotifications();

  const [landlordDetailItem, setLandlordDetailItem] =
    useState<LandlordResponseInterface>();
  useEffect(() => {
    if (newBuild) {
      getLandlord(newBuild.id).then((response) => {
        setLandlordDetailItem(response);
        formMethods.reset({
          phone_number: response.phone_number,
          email: response.email,
        });
      });
    }
  }, [newBuild]);

  const formMethods = useForm<LandlordInformationInterface>({
    mode: "onBlur",
  });

  const watchPhoneNumber = formMethods.watch("phone_number");

  const getLandlordViewUrl = (showCompanyTab: boolean): string | null => {
    if (landlordDetailItem?.landlord_id) {
      return `${PAGE_USERS}/${landlordDetailItem.landlord_id}${
        showCompanyTab ? "?tab=COMPANY_DETAILS" : ""
      }`;
    }
    return null;
  };

  const displaySaveNotification = () => {
    addNotification({
      title: "Saving data...",
      autoDismiss: true,
      autoDismissTimeout: 1000,
    });
  };

  const displayErrorNotification = (errorMessage: string) => {
    addNotification({
      title: <Badge state="danger">ERROR</Badge>,
      content: (
        <Box mt={1}>
          <Text color="inverted">{errorMessage ? errorMessage : ""}</Text>
        </Box>
      ),
    });
  };

  const handleLandlordUpdater = async (
    fieldname: string,
    value: any,
  ): Promise<TaskResponseInterface> => {
    if (!landlordDetailItem?.landlord_id) {
      return new Promise((resolve, reject) => {
        reject("Missing landlord id");
      });
    }

    return updateProfile(landlordDetailItem?.landlord_id, fieldname, value)
      .then((response) => {
        if (response.errors) {
          displayErrorNotification("Updating landlord failed");
        } else {
          displaySaveNotification();
          return response;
        }
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <Box mb={1}>
          <Text size="small">{"Id: "}</Text>
          <Text
            size="small"
            weight="bold"
          >
            {landlordDetailItem?.landlord_id}
          </Text>
        </Box>
        <Box mb={1}>
          <Text size="small">{"Name: "}</Text>
          <Text
            size="small"
            weight="bold"
          >
            {landlordDetailItem?.user_type === USER_TYPE.COMPANY
              ? landlordDetailItem?.profile_company_name || "n/a"
              : `${landlordDetailItem?.first_name} ${landlordDetailItem?.last_name}`}
          </Text>
        </Box>
        {landlordDetailItem?.company_name && (
          <Box mb={2}>
            <Text size="small">{"Company name: "}</Text>
            <Text
              weight="bold"
              size="small"
            >
              <StyledA href={getLandlordViewUrl(true) || "#"}>
                {landlordDetailItem.company_name}{" "}
                {newBuild?.social_housing && (
                  <IconPeopleCircleOutline
                    inline
                    size="medium"
                  />
                )}
              </StyledA>
            </Text>
          </Box>
        )}
        <TitleFormElement<LandlordInformationInterface>
          updateTask={handleLandlordUpdater}
          fieldname="phone_number"
          fieldLabel="Contact phone"
        />

        <Flex
          column
          mb={3}
        >
          <Text
            color="muted"
            size="tiny"
            mr={1}
          >
            Lookup phone number in:
          </Text>
          <Flex
            gap={1}
            wrap
          >
            {externalPhoneWebsites.map((item) => (
              <SimpleTextLink
                key={item.name}
                url={item.urlResolver(watchPhoneNumber ?? "")}
              >
                {item.name}
              </SimpleTextLink>
            ))}
          </Flex>
        </Flex>
        <TitleFormElement<LandlordInformationInterface>
          updateTask={handleLandlordUpdater}
          fieldname="email"
          fieldLabel="User email"
        />
      </FormProvider>
      <Flex
        mt={2}
        gap
      >
        <Flex flexPush>
          <Button
            variant="subtle"
            iconAfter={IconArrowForward}
            as="a"
            href={`${getLandlordViewUrl(false)}`}
          >
            View landlord
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
