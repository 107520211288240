import { useQuery } from "react-query";
import { Ad } from "components/interfaces/ad";
import { API } from "lib/api";

export const adminKeys = {
  adminAdDetailToolInfo: (ad: Ad) => ["adminAdDetailToolInfo", ad.id] as const,
};

export const useAdminAdDetailToolInfo = (ad: Ad) =>
  useQuery(adminKeys.adminAdDetailToolInfo(ad), () =>
    API.getDetailAppAdminToolInfo(ad),
  );
