import { useEffect } from "react";
import { Alert, Button, Flex, Box, Confirm } from "@boligportal/juice";
import { useVideoEditorFeature } from "../hooks/use_video_editor_feature";
import { VimeoVideoPlayback } from "./vimeo_video_playback";

type VideoEditorProps = {
  adId: number;
  videoId: number;
  videoHash?: string;
  onVideoDeleted: () => void;
  onDeleteClicked?: () => void;
  deleteVideoButtonLabel: string;
  deleteVideoConfirmTitle: string;
  deleteVideoConfirmMessage: string;
  deleteVideoConfirmButtonConfirmText: string;
  deleteVideoConfirmButtonCancelText: string;
  uploadMalformedHeader: string;
  uploadMalformedInfo: string;
  uploadFinishedHeader: string;
  uploadFinishedInfo: string;
};

// ************************************************************************************************
// Video Editor Feature
// Given an adId and a videoId user can see a video and delete it if needed
// ************************************************************************************************
const VideoEditor = ({
  adId,
  videoId,
  videoHash,
  onVideoDeleted,
  onDeleteClicked,
  deleteVideoButtonLabel,
  deleteVideoConfirmButtonCancelText,
  deleteVideoConfirmButtonConfirmText,
  deleteVideoConfirmMessage,
  deleteVideoConfirmTitle,
  uploadMalformedHeader,
  uploadMalformedInfo,
  uploadFinishedHeader,
  uploadFinishedInfo,
}: VideoEditorProps) => {
  const { deleteVideo, errorMessage, videoEditorStatus } =
    useVideoEditorFeature();

  const deleteVideoButtonClickHandler = () => {
    if (onDeleteClicked) {
      onDeleteClicked();
    }

    deleteVideo(adId, videoId);
  };

  useEffect(() => {
    if (videoEditorStatus === "videoDeletedSuccessfully") {
      onVideoDeleted();
    }
  }, [videoEditorStatus, onVideoDeleted]);

  return (
    <Flex column>
      <VimeoVideoPlayback
        videoId={videoId}
        videoHash={videoHash}
        uploadMalformedHeader={uploadMalformedHeader}
        uploadMalformedInfo={uploadMalformedInfo}
        uploadFinishedHeader={uploadFinishedHeader}
        uploadFinishedInfo={uploadFinishedInfo}
      />
      <Box
        align="start"
        textAlign="left"
        pt={1}
      >
        <Confirm
          size="large"
          opener={
            <Button
              variant="danger"
              text
            >
              {deleteVideoButtonLabel}
            </Button>
          }
          dangerous
          onConfirm={deleteVideoButtonClickHandler}
          title={deleteVideoConfirmTitle}
          message={deleteVideoConfirmMessage}
          confirmText={deleteVideoConfirmButtonConfirmText}
          cancelText={deleteVideoConfirmButtonCancelText}
        />
      </Box>
      {errorMessage && <Alert type="danger">{errorMessage}</Alert>}
    </Flex>
  );
};

export { VideoEditor };
