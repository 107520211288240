import * as React from "react";
import { Flex } from "@boligportal/juice";
import { Image } from "apps/moving_reports/interfaces/image";
import { useDropzoneImageUpload } from "features/image_organizer/hooks/useDropzoneImageUpload";
import { useMovingReport } from "../providers/MovingReportProvider";

type Props = {
  children: (
    fakeClickableRef: React.RefObject<HTMLDivElement>,
    dropzoneRef: React.RefObject<HTMLDivElement>,
    isUploading: boolean,
  ) => React.ReactNode;
  customUploadUrl?: string;
  onUploadDone: (responsePayload: Image[]) => void;
  onUploadStart?: () => void;
  singleFile?: boolean;
};

export const MovingReportImageUploader = ({
  children,
  customUploadUrl,
  onUploadDone,
  onUploadStart,
  singleFile,
}: Props) => {
  const { report } = useMovingReport();

  const dropzone = useDropzoneImageUpload(
    onUploadDone,
    customUploadUrl ||
      `/api/moving_reports/moving_report/${report!.id}/image_upload/`,
    true,
    onUploadStart,
    singleFile,
  );

  return (
    <Flex>
      {children(
        dropzone.fakeClickableRef,
        dropzone.dropzoneRef,
        dropzone.isUploading,
      )}
    </Flex>
  );
};
