import { useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { App } from "components/app";
import { PaywallProvider } from "./PaywallProvider";
import { StripePaymentProvider } from "./coordinators/stripe/StripePaymentProvider";

export const SeekerPaywall = ({ children }: { children: React.ReactNode }) => {
  const {
    paywall: { seekerPaymentFlowCoordinators },
  } = useMarketSettings();
  const stripePromise = useMemo(() => {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    return loadStripe(App.settings.stripe_pk);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <StripePaymentProvider>
        <PaywallProvider
          paymentFlowCoordinators={seekerPaymentFlowCoordinators}
        >
          {children}
        </PaywallProvider>
      </StripePaymentProvider>
    </Elements>
  );
};
