import { Box, Flex, Text } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { UserForCustomerService } from "../../user_detail_view/user_detail_view";
import { RefundReasonsEnum } from "../enums";
import { RefundInfo } from "./refund_info";

type Props = {
  refundCandidate: UserForCustomerService;
  amountSumStr: string;
  reason: RefundReasonsEnum;
  note: string;
  zendeskId: string;
  terminate: boolean;
  plan: string;
};

const RefundConfirmation = ({
  amountSumStr,
  refundCandidate,
  note,
  plan,
  reason,
  terminate,
  zendeskId,
}: Props) => {
  const { user: customerServiceAgent } = useUser();

  return (
    <Box>
      <RefundInfo title="User">
        <Text weight="bold">{`${refundCandidate.email} - ${refundCandidate.first_name} ${refundCandidate.last_name}`}</Text>
      </RefundInfo>
      <RefundInfo title="Customer Service Agent">
        {customerServiceAgent && (
          <Text weight="bold">
            `${customerServiceAgent.first_name} $
            {customerServiceAgent.last_name}`
          </Text>
        )}
      </RefundInfo>
      <RefundInfo title="Reason">
        <Box>{`${reason}`}</Box>
        {note !== "" && <Box>{`${note}`}</Box>}
      </RefundInfo>
      <RefundInfo title="Zendesk ID">
        <Box>{`${zendeskId}`}</Box>
      </RefundInfo>
      <Flex justify="space-between">
        <Text
          block
          size="h1"
        >
          Total refund:
        </Text>
        <Text
          block
          weight="bold"
          size="h1"
        >
          {`${amountSumStr} ${App.settings.currency_symbol}`}
        </Text>
      </Flex>
      {terminate && (
        <>
          <Box
            borderTop
            my={2}
          >
            <Text markdown>
              {`*Note: The subscription **${plan}** will automatically be **cancelled** and set to **expired** when refunding has completed.*`}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

export { RefundConfirmation };
