import { useReducer, useEffect } from "react";
import { RentableId } from "business/domain/Rentable";
import { Tenancy, TenancyId } from "business/domain/tenancy/Tenancy";
import { TenancyAPI } from "../TenancyAPI";

export type TenancySelectorFeature = ReturnType<typeof useTenancySelector>;

export const useTenancySelector = (
  rentableId: RentableId,
  tenancyId: TenancyId | null,
  shouldAlert: (
    currentSelection: Tenancy | null,
    targetSelection: Tenancy | null,
  ) => boolean,
) => {
  const [state, dispatch] = useReducer(defaultTenancySelectorReducer, {
    isReady: false,
    tenancies: [],
    selectedTenancy: null,
    alertCandidate: null,
  });

  const tryChangeTenancy = (value: string) => {
    const tenancy =
      state.tenancies.find(
        (candidate) => candidate.tenancy_id.toString() === value,
      ) || null;

    if (shouldAlert(state.selectedTenancy, tenancy)) {
      dispatch({
        type: "SHOULD_SHOW_ALERT_FOR",
        tenancy: tenancy,
      });
    } else {
      dispatch({
        type: "UPDATE_SELECTED_TENANCY",
        tenancy: tenancy,
      });
    }
  };

  const cancelTenancyChange = () => {
    dispatch({
      type: "CANCEL_TENANCY_CHANGE",
    });
  };

  const confirmTenancyChange = () => {
    dispatch({
      type: "CONFIRM_TENANCY_CHANGE",
    });
  };

  useEffect(() => {
    TenancyAPI.getTenanciesByRentableId(rentableId).then((tenancies) =>
      dispatch({
        type: "UPDATE_TENANCIES",
        tenancies,
        initialTenancyId: tenancyId,
      }),
    );
  }, [rentableId, tenancyId]);

  return {
    state,
    tryChangeTenancy,
    cancelTenancyChange,
    confirmTenancyChange,
  };
};

type TenancySelectorReduserAction =
  | {
      type: "UPDATE_TENANCIES";
      tenancies: Tenancy[];
      initialTenancyId: TenancyId | null;
    }
  | { type: "UPDATE_SELECTED_TENANCY"; tenancy: Tenancy | null }
  | { type: "SHOULD_SHOW_ALERT_FOR"; tenancy: Tenancy | null }
  | { type: "CONFIRM_TENANCY_CHANGE" }
  | { type: "CANCEL_TENANCY_CHANGE" };

type TenancySelectorReduserState = Readonly<{
  isReady: boolean;
  tenancies: Tenancy[];
  selectedTenancy: Tenancy | null;
  alertCandidate: AlertCandidate | null;
}>;

type AlertCandidate = {
  tenancy: Tenancy | null;
};

type TenancySelectorReducer = (
  state: TenancySelectorReduserState,
  action: TenancySelectorReduserAction,
) => TenancySelectorReduserState;

const defaultTenancySelectorReducer: TenancySelectorReducer = (
  state: TenancySelectorReduserState,
  action: TenancySelectorReduserAction,
) => {
  switch (action.type) {
    case "UPDATE_TENANCIES": {
      const selectedTenancyCandidate = findPreselectableTenancyOrNull(
        action.tenancies,
        action.initialTenancyId,
      );

      return {
        ...state,
        isReady: true,
        tenancies: action.tenancies,
        selectedTenancy: selectedTenancyCandidate,
      };
    }

    case "UPDATE_SELECTED_TENANCY": {
      return {
        ...state,
        selectedTenancy: action.tenancy,
      };
    }

    case "SHOULD_SHOW_ALERT_FOR": {
      return {
        ...state,
        alertCandidate: {
          tenancy: action.tenancy,
        },
      };
    }

    case "CONFIRM_TENANCY_CHANGE": {
      if (state.alertCandidate) {
        return {
          ...state,
          selectedTenancy: state.alertCandidate.tenancy,
          alertCandidate: null,
        };
      }

      return state;
    }

    case "CANCEL_TENANCY_CHANGE": {
      return {
        ...state,
        alertCandidate: null,
      };
    }
  }
};

const findPreselectableTenancyOrNull = (
  candidates: Tenancy[],
  tenancyId: TenancyId | null,
): Tenancy | null => {
  if (!tenancyId) {
    return null;
  }

  const candidate = candidates.find(
    (candidate) => candidate.tenancy_id === tenancyId,
  );

  return candidate || null;
};

export type TenancySelectorItem = { label: string; value: string };
export const createTenancySelectorOption = (
  tenancy: Tenancy,
): TenancySelectorItem => ({
  label: `${tenancy.tenants_display_label}`,
  value: tenancy.tenancy_id.toString(),
});
