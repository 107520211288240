import { TenancyPaymentTransaction } from "business/domain/tenancy/TenancyPaymentTransaction";

export const calculateArrears = (
  rent: number,
  transactions: TenancyPaymentTransaction[],
) => {
  const totalPayment = Number(
    transactions
      .reduce((acc, transaction) => acc + transaction.amount, 0)
      .toFixed(2),
  );

  return {
    totalPayment: totalPayment,
    arrears: totalPayment - Number(rent.toFixed(2)),
  };
};
