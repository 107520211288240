import { Badge } from "@boligportal/juice";
import { AdStates } from "lib/enums";
import { t } from "lib/i18n";

const AdStateBadge = ({ data }) => {
  if (!data) {
    return null;
  }

  enum BadgeState {
    Success = "success",
    Danger = "danger",
    Warning = "warning",
    Disabled = "disabled",
  }

  function badge(state: BadgeState, text: string) {
    return (
      <Badge
        state={state}
        maxWidth="100%"
      >
        {text}
      </Badge>
    );
  }

  const BadgeData = {
    [AdStates.ACTIVE_APPROVED]: {
      text: t("rentable_list.states.advertised"),
      state: BadgeState.Success,
    },
    [AdStates.INACTIVE_REJECTED]: {
      text: t("rentable_list.states.rejected"),
      state: BadgeState.Danger,
    },
    [AdStates.RESERVED_APPROVED]: {
      text: t("rentable_list.states.reserved"),
      state: BadgeState.Warning,
    },
    [AdStates.INACTIVE_PENDING_APPROVAL]: {
      text: t("rentable_list.states.waiting_approval"),
      state: BadgeState.Warning,
    },
    [AdStates.RENTED_OUT_APPROVED]: {
      text: t("rentable_list.states.rented_out_approved"),
      state: BadgeState.Disabled,
    },
    [AdStates.INACTIVE_DRAFT]: {
      text: t("rentable_list.states.offline"),
      state: BadgeState.Disabled,
    },
    [AdStates.DRAFT]: {
      text: t("rentable_list.states.draft"),
      state: undefined,
    },
  };

  return badge(BadgeData[data.state].state, BadgeData[data.state].text);
};

export default AdStateBadge;
