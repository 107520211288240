/* eslint-disable comma-dangle */
import { FormProvider } from "react-hook-form";
import {
  Button,
  IconLogOutOutline,
  IconTrashOutline,
  Notice,
  Text,
} from "@boligportal/juice";
import { App } from "components/app";
import {
  ListingFields,
  RentableFields,
} from "components/forms/elements/FormElements";
import {
  maxThreeMonthlyRentValidation,
  noMaxMonthlyRentValidation,
} from "components/forms/validation";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { LandlordInformation } from "./components/LandlordInformation";
import { NoLandlordAvailableDisplay } from "./components/NoLandlordAvailableDisplay";
import { SourceLinkDisplay } from "./components/SourceLinkDisplay";
import { ContactInformation } from "./components/contact_information/ContactInformation";
import { CS_EditAddress as EditAddressDenmark } from "./components/edit_address/dk/CS_EditAddress";
import { CS_EditAddress as EditAddressSweeden } from "./components/edit_address/se/CS_EditAddress";
import { PublishErrors } from "./components/publish_errors/PublishErrors";
import { useCrawlingDraft } from "./hooks/useCrawlingDraft";
import { ContactInfo } from "./interfaces/ContactInfo";
import { CrawlingDraftForm } from "./interfaces/CrawlingDraftForm";
import { CrawlingDraftDetailPageLayout } from "./layout/CrawlingDraftDetailPageLayout";

interface Props {
  draftId: string;
  sourceUrl?: string;
  contactInfo?: ContactInfo;
  dataValidationError;
  initialAddress: string;
  initialFieldValues: Partial<CrawlingDraftForm>;
  onAddressUpdated: (address: string) => void;
}

export const CrawlingDraftDetailMain = ({
  draftId,
  sourceUrl,
  contactInfo,
  dataValidationError,
  initialAddress,
  initialFieldValues,
  onAddressUpdated,
}: Props) => {
  const {
    viewState,
    allFormMethods,
    addressViewState,
    setAddressViewState,
    tryPublishDraft,
    tryDelete,
    publishErrors,
  } = useCrawlingDraft(draftId, initialAddress, initialFieldValues);

  const landlordId = contactInfo?.landlord_id;

  const depositValidationRule =
    App.settings.market === Market.BOSTADSPORTAL_SE
      ? noMaxMonthlyRentValidation
      : maxThreeMonthlyRentValidation;

  return (
    <FormProvider {...allFormMethods}>
      <form
        onSubmit={allFormMethods.handleSubmit(tryPublishDraft)}
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <CrawlingDraftDetailPageLayout
          publishErrors={<PublishErrors publishErrors={publishErrors} />}
          contactInformation={
            contactInfo ? (
              <ContactInformation
                email={contactInfo.contact_email}
                phone={contactInfo.contact_phone}
                feedName={contactInfo.feed_name}
              />
            ) : null
          }
          dataValidationError={
            dataValidationError !== "" ? (
              <Notice type="danger">{dataValidationError}</Notice>
            ) : null
          }
          missingLandlord={!landlordId ? <NoLandlordAvailableDisplay /> : null}
          landlordInformation={
            landlordId ? <LandlordInformation landlordId={landlordId} /> : null
          }
          sourceLink={
            sourceUrl ? <SourceLinkDisplay sourceUrl={sourceUrl} /> : null
          }
          address={
            <>
              {App.settings.market === Market.BOLIGPORTAL_DK && (
                <EditAddressDenmark
                  onAddressUpdated={onAddressUpdated}
                  initialAddress={initialAddress}
                  addressViewState={addressViewState}
                  setAddressViewState={setAddressViewState}
                />
              )}
              {App.settings.market === Market.BOSTADSPORTAL_SE && (
                <EditAddressSweeden
                  onAddressUpdated={onAddressUpdated}
                  initialAddress={initialAddress}
                  addressViewState={addressViewState}
                  setAddressViewState={setAddressViewState}
                />
              )}
            </>
          }
          category={
            <RentableFields.RentalCategory
              options={App.settings.rental_categories}
            />
          }
          deposit={
            <ListingFields.Deposit
              label={t("create.field.deposit.heading")}
              validationRule={depositValidationRule}
            />
          }
          description={<ListingFields.RentalDescription />}
          energyLabel={<ListingFields.RentalEnergyLabel />}
          errors={<div />}
          features={<ListingFields.RentalFeatures />}
          monthlyRent={<ListingFields.MonthlyRent />}
          monthlyRentExtraCosts={<ListingFields.MonthlyRentExtraCosts />}
          moveInDate={<ListingFields.RentalMoveInDate />}
          period={<ListingFields.RentalPeriod />}
          prepaidRent={
            App.settings.market === Market.BOLIGPORTAL_DK ? (
              <ListingFields.PrepaidRent
                validationRule={maxThreeMonthlyRentValidation}
              />
            ) : null
          }
          rooms={<ListingFields.RentalNumberOfRooms />}
          size={<ListingFields.RentalSize />}
          pageTitle={<Text size="h3">Crawling Draft: {draftId}</Text>}
          deleteButton={
            <Button
              onClick={tryDelete}
              iconAfter={IconTrashOutline}
              loading={viewState === "deleting"}
              disabled={viewState === "publishing" || viewState === "deleting"}
            >
              Delete Crawling Draft
            </Button>
          }
          submitButton={
            <Button
              variant="primary"
              type="submit"
              iconAfter={IconLogOutOutline}
              loading={viewState === "publishing"}
              disabled={viewState === "publishing" || viewState === "deleting"}
            >
              Publish Crawling Draft
            </Button>
          }
          title={<ListingFields.RentalTitle />}
        />
      </form>
    </FormProvider>
  );
};
