import { useEffect } from "react";
import { usePagination } from "hooks/usePagination";
import { DraftsListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { getCrawlersDraftsService } from "../services/get_crawling_drafts_service";

const useCrawlingsDraftsPageFeature = () => {
  const { offset, limit, items, setItems, setOffset, count, setCount } =
    usePagination<DraftsListItem>(20);

  const pageChange = (value: number) => {
    setOffset(value);
  };

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();

    getCrawlersDraftsService({
      signal: abortController.signal,
      offset,
      limit,
    })
      .then((response) => {
        setItems(response.results);
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      abortController.abort();
    };
  }, [offset, limit, setCount, setItems]);

  return {
    items,
    offset,
    limit,
    count,
    pageChange,
  };
};

export { useCrawlingsDraftsPageFeature };
