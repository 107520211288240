/* eslint-disable comma-dangle */

/* eslint-disable object-curly-newline */
import { useState, useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormElementUpdateTask } from "../../types";

const useUpdatableFormElement = (
  fieldname: string,
  updateTask: FormElementUpdateTask,
  castValueToNumber?: boolean,
  successCallback?: () => void,
) => {
  const {
    register,
    getValues,
    setValue,
    watch,
    trigger: triggerValidation,
  } = useFormContext();
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const initialValue = useRef();

  useEffect(() => {
    initialValue.current = getValues()[fieldname];
  }, [getValues, fieldname]);

  const save = () => {
    let newValue = getValues()[fieldname];
    // Skip Saving if value hasn't changed
    if (initialValue.current === newValue) {
      return;
    }

    if (castValueToNumber !== undefined && castValueToNumber) {
      newValue = Number(newValue);
    }

    updateTask(fieldname, newValue)
      .then(
        (response) => {
          if (response.success) {
            // Happy Path
            if (successCallback) {
              // Update initialValue to reflect the value that will be saved
              initialValue.current = newValue;
              successCallback();
            }
          } else {
            // Sad Path
            setErrors(response);
          }
          setIsSaving(false);
        },
        (error) => {
          setErrors(error);
          setIsSaving(false);
        },
      )
      .catch((error) => {
        setErrors(error);
        // Sad Path
        setIsSaving(false);
      });
  };

  return {
    register,
    save,
    isSaving,
    getValues,
    watch,
    setValue,
    triggerValidation,
    errors,
  };
};

export default useUpdatableFormElement;
