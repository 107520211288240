import { useState, useEffect, useCallback } from "react";
import { Text } from "@boligportal/juice";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { useOldModal } from "components/modal_controller";
import { StartSubscriptionModal } from "components/start_subscription_modal";
import { AccountInfo, API } from "lib/api";
import { Tracking } from "lib/tracking/common";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { formatCurrency } from "lib/utils";
import { t } from "../../lib/i18n";
import { PaymentSpinner } from "../payment_spinner";

const checkoutFormVirtualPageURL = "/payment/checkout_form";

interface Props {
  onSuccess: () => void;
  onSubscriptionActivated?: () => void;
  plans: Plan[];
  adId: number | null;
  backToPaymentModal: () => void;
}

export const AccountPaymentModal = (props: Props) => {
  const {
    plans,
    adId,
    onSuccess,
    onSubscriptionActivated,
    backToPaymentModal,
  } = props;

  const appActions = useAppActions();
  const { user } = useUser();
  const oldModal = useOldModal();

  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

  const [paymentMethodId, setPaymentMethodId] = useState<number | null>(null);

  useEffect(() => {
    Tracking.trackVirtualPageview(checkoutFormVirtualPageURL);
    if (adId !== null) {
      FacebookTrackingEvent.trackInitiateCheckout(adId);
    }
  }, [adId]);

  const createAccountPaymentMethod = useCallback(async () => {
    const paymentMethod = await API.createAccountPaymentMethod();
    setPaymentMethodId(paymentMethod.payment_method_id);
  }, []);

  useEffect(() => {
    createAccountPaymentMethod();
  }, [createAccountPaymentMethod]);

  if (!user) {
    return null;
  }

  if (!paymentMethodId) {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <PaymentSpinner submitted={false} />
      </div>
    );
  } else {
    return (
      <StartSubscriptionModal
        onClose={oldModal.closeModal}
        paymentMethodId={paymentMethodId}
        onPurchaseComplete={onSuccess}
        onSubscriptionActivated={async () => {
          const accountInfo = await API.getAccountInfo();
          setAccountInfo(accountInfo);
          if (onSubscriptionActivated) {
            onSubscriptionActivated();
          }
        }}
        paymentFailedAction={() => {
          backToPaymentModal();
        }}
        plans={plans}
        adId={adId}
        appActions={appActions}
        extraSuccessMessage={
          accountInfo === null ? (
            ""
          ) : (
            <>
              {
                // i18n: %{company_name}
                t("account_payment_modal.success.extra_message", {
                  company_name: user.company_name,
                })
              }{" "}
              <Text
                weight="bold"
                size="small"
              >
                {formatCurrency(accountInfo.balance, App.settings.currency)}
              </Text>
            </>
          )
        }
      />
    );
  }
};
