import { Flex, Button, Hidden, IconClose, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { Slide } from "./Slide";
import { Slideshow } from "./Slideshow";

export const StyledIndicator = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPalette.gray[50]};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ active, theme }) =>
    active && `background-color: ${theme.colorPalette.brand[500]};`}
`;

interface Props {
  currentSlide: number;
  filteredSlides: Slide[];
  onIndexChange: (index: number) => void;
  handleNextClick: () => void;
  handleOnSkip: () => void;
}

export const SlideshowContent = ({
  currentSlide,
  filteredSlides,
  handleNextClick,
  onIndexChange,
  handleOnSkip,
}: Props) => (
  <>
    <Flex
      justify="end"
      hidden={{
        md: true,
      }}
    >
      <Button
        onClick={handleOnSkip}
        text
        variant="subtle"
        icon={IconClose}
      />
    </Flex>
    <Flex
      align="center"
      justify="center"
      mb={2}
      hidden={{
        md: true,
      }}
    >
      {filteredSlides.map((slide, index) => (
        <StyledIndicator
          key={slide.slide}
          onClick={() => onIndexChange(index)}
          active={index === currentSlide}
        />
      ))}
    </Flex>
    <Flex
      justify="center"
      align="center"
      column={{
        xs: true,
        md: false,
      }}
    >
      <Slideshow
        slides={filteredSlides}
        currentSlide={currentSlide}
      />
      <Hidden
        on={{
          md: true,
        }}
      >
        <Button
          fullWidth
          variant="branded"
          onClick={handleNextClick}
        >
          {currentSlide === filteredSlides.length - 1
            ? t("onboarding.dialog_button.confirm")
            : t("onboarding.dialog_button.next")}
        </Button>
      </Hidden>
    </Flex>
  </>
);
