import { useFormContext } from "react-hook-form";
import { CheckField, Text } from "@boligportal/juice";
import { HeaderLabel } from "components/forms/header_label";
import { t } from "lib/i18n";

const RentalDigitalShowing = () => {
  const { register } = useFormContext();
  return (
    <>
      <HeaderLabel
        isOptional
        label={t("rentable_details.editor.digital_showing.section_headline")}
      />

      <CheckField
        label={t("create.field.digital_showing")}
        {...register("digital_showing")}
      />

      <Text size="small">{t("create.field.digital_showing.extra_text")}</Text>
    </>
  );
};

export { RentalDigitalShowing };
