import { ChangeEvent } from "react";
import * as React from "react";
import {
  Box,
  Button,
  CheckField,
  Flex,
  Hidden,
  Labelled,
  RadioField,
  SelectField,
  styled,
  TextField,
} from "@boligportal/juice";
import {
  Action_clearFilters,
  Action_updateFilters,
} from "apps/search_result/search_result_reducer";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { commonFormTrans } from "lib/translations";
import { isBoligPortal } from "lib/utils";
import { Categories } from "./ad_filter_parts/categories";
import { App } from "./app";
import { Filters, StringListFilter } from "./interfaces/filters";
import { Option } from "./interfaces/option";
import { Rooms } from "./interfaces/rooms";
import { Market } from "./markets/market_settings";

const RoomSeperator = styled.div`
  color: ${(props) => props.theme.colorPalette.gray[60]};
  transform: translateY(7px);
  padding: 0 4px 0 4px;
`;

interface Props {
  filters: Filters;
  dispatch: React.Dispatch<Action_updateFilters | Action_clearFilters>;
  categoryOptions: Array<Option>;
  isMapView: boolean;
}

export const AdFiltersView = ({
  dispatch,
  filters,
  isMapView,
  categoryOptions,
}: Props) => {
  const { maxPriceAppend, minSizeAppend } = commonFormTrans();

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    TrackingEvent.filterClickedRent();

    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        max_monthly_rent:
          event.target.value === "" ? null : parseInt(event.target.value, 10),
      },
    });
  };

  const handleMoveInDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        max_available_from: event.target.value,
      },
    });
    TrackingEvent.filterClickedDate(isMapView ? "maps-view" : "list-view");
  };

  const handleMinSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        min_size_m2:
          event.target.value === "" ? null : parseInt(event.target.value, 10),
      },
    });
  };

  const handleMinRoomsChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLte = filters.rooms ? filters.rooms.lte : null;
    const newGte =
      event.target.value === "All" ? null : parseInt(event.target.value, 10);
    const rooms = {
      gte: newGte,
      lte:
        newGte !== null && newLte !== null ? Math.max(newGte, newLte) : newLte,
    };
    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        rooms,
      },
    });
    TrackingEvent.filterClickedRooms(isMapView ? "maps-view" : "list-view");
  };

  const handleMaxRoomsChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newLte =
      event.target.value === "All" ? null : parseInt(event.target.value, 10);
    const newGte = filters.rooms ? filters.rooms.gte : null;
    const rooms = {
      gte:
        newLte !== null && newGte !== null ? Math.min(newLte, newGte) : newGte,
      lte: newLte,
    } as Rooms;
    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        rooms,
      },
    });
    TrackingEvent.filterClickedRooms(isMapView ? "maps-view" : "list-view");
  };

  const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const field = event.target.id;
    const categoryCandidates =
      categories && categories.values ? [...categories.values] : [];

    if (checked) {
      categoryCandidates.push(field);
    } else {
      const index = categoryCandidates.indexOf(field);
      if (index > -1) {
        categoryCandidates.splice(index, 1);
      }
    }

    const newCategories: StringListFilter = {
      values: categoryCandidates.length === 0 ? null : categoryCandidates,
    };

    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        categories: newCategories,
      },
    });
  };

  const handleNewBuildsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;

    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        newbuild: newValue,
      },
    });
  };

  const handleAdFeatureChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const field = event.target.id;
    TrackingEvent.filterClickedFeature(isMapView ? "maps-view" : "list-view");
    if (field) {
      dispatch({
        type: "updateFilters",
        payload: {
          ...filters,
          [field]: newValue,
        },
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(
        "Ad features Checkboxes need an id matching the filter name",
      );
    }
  };

  const handleRentalPeriodSelectionChange = (event) => {
    const newPeriod =
      event.target.value === "" ? null : parseInt(event.target.value, 10);
    dispatch({
      type: "updateFilters",
      payload: {
        ...filters,
        min_rental_period: newPeriod,
      },
    });
    TrackingEvent.filterClickedRentalPeriod(
      isMapView ? "maps-view" : "list-view",
    );
  };

  const {
    categories,
    rooms,
    min_size_m2,
    max_monthly_rent,
    pet_friendly,
    balcony,
    elevator,
    parking,
    senior_friendly,
    newbuild,
    student_only,
    furnished,
    shareable,
    social_housing,
    dishwasher,
    dryer,
    washing_machine,
    electric_charging_station,
  } = filters;

  const minRooms = rooms && rooms.gte !== null ? rooms.gte : undefined;
  const maxRooms = rooms && rooms.lte !== null ? rooms.lte : undefined;

  const handleClearFilterClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch({
      payload: null,
      type: "clearFilters",
    });
  };

  const rentalPeriods = [
    {
      value: "",
      label: t("rental_period.any"),
    },
    {
      value: "6",
      label: t("rental_period.half_a_year"),
    },
    {
      value: "12",
      label: t("rental_period.year"),
    },
    {
      value: "24",
      label: t("rental_period.two_years"),
    },
    {
      value: "0",
      label: t("rental_period.unlimited"),
    },
  ];

  const roomChoices = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => ({
    value: num.toString(),
    label: num.toString(),
  }));

  const swedishMarket = App.settings.market === Market.BOSTADSPORTAL_SE;

  return (
    <Box mb={4}>
      <div>
        <Labelled label={t("ad_filter.category")}>
          <Categories
            categories={
              categories && categories.values ? categories.values : []
            }
            categoryOptions={categoryOptions}
            onChange={handleCategoryChange}
            isMapView={isMapView}
          />
        </Labelled>

        {swedishMarket && (
          <Labelled label={t("newbuilds.category")}>
            <CheckField
              label={t("details.newbuild")}
              id="newbuild"
              checked={newbuild}
              onChange={handleNewBuildsChange}
            />
          </Labelled>
        )}

        <TextField
          placeholder={t("ad_filter.max_price.placeholder")}
          label={t("ad_filter.max_price.label")}
          helpText={t("ad_filter.max")}
          append={maxPriceAppend}
          onChange={handlePriceChange}
          value={max_monthly_rent ? max_monthly_rent.toString() : ""}
        />

        <TextField
          placeholder={t("ad_filter.min_size.placeholder")}
          label={t("ad_filter.min_size.label")}
          helpText={t("ad_filter.min")}
          append={minSizeAppend}
          onChange={handleMinSizeChange}
          value={min_size_m2 ? min_size_m2.toString() : ""}
          onFocus={() =>
            TrackingEvent.filterClickedSize(
              isMapView ? "maps-view" : "list-view",
            )
          }
        />

        <Labelled label={t("ad_filter.Number of rooms")}>
          <Flex>
            <Box flexGrow>
              <SelectField
                label=""
                onChange={handleMinRoomsChange}
                helpText={t("ad_filter.min")}
                value={minRooms ? minRooms.toString() : ""}
                items={[
                  {
                    label: t("ad_filter.min_rooms.all"),
                    value: "All",
                  },
                ].concat(roomChoices)}
              />
            </Box>
            <RoomSeperator>-</RoomSeperator>
            <Box flexGrow>
              <SelectField
                label=""
                onChange={handleMaxRoomsChange}
                helpText={t("ad_filter.max")}
                value={maxRooms ? maxRooms.toString() : ""}
                items={[
                  {
                    label: t("ad_filter.max_rooms.all"),
                    value: "All",
                  },
                ].concat(roomChoices)}
              />
            </Box>
          </Flex>
        </Labelled>
        <form>
          <RadioField
            name="rentalPeriodGroup"
            onChange={handleRentalPeriodSelectionChange}
            label={t("filters.rental_period")}
            value={
              filters.min_rental_period === null
                ? ""
                : filters.min_rental_period.toString()
            }
            items={rentalPeriods}
          />
        </form>
        <TextField
          label={t("ad_filter.move_in_date")}
          type="date"
          value={filters.max_available_from ? filters.max_available_from : ""}
          lang={App.settings.language}
          onChange={handleMoveInDateChanged}
        />

        <Labelled label={t("ad_filter.lifestyle")}>
          <CheckField
            label={t("details.pet_friendly")}
            id="pet_friendly"
            checked={pet_friendly}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.senior_friendly")}
            id="senior_friendly"
            checked={senior_friendly}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.student_only")}
            id="student_only"
            checked={student_only}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.shareable")}
            id="shareable"
            checked={shareable}
            onChange={handleAdFeatureChange}
          />
          {isBoligPortal() && (
            <CheckField
              label={t("details.social_housing")}
              id="social_housing"
              checked={social_housing}
              onChange={handleAdFeatureChange}
            />
          )}
        </Labelled>

        <Labelled label={t("ad_filter.facilities")}>
          <CheckField
            label={t("details.parking")}
            id="parking"
            checked={parking}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.elevator")}
            id="elevator"
            checked={elevator}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.balcony")}
            id="balcony"
            checked={balcony}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.electric_charging_station")}
            id="electric_charging_station"
            checked={electric_charging_station}
            onChange={handleAdFeatureChange}
          />
        </Labelled>

        <Labelled label={t("ad_filter.inventory")}>
          <CheckField
            label={t("details.furnished")}
            id="furnished"
            checked={furnished}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.dishwasher")}
            id="dishwasher"
            checked={dishwasher}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.washing_machine")}
            id="washing_machine"
            checked={washing_machine}
            onChange={handleAdFeatureChange}
          />
          <CheckField
            label={t("details.dryer")}
            id="dryer"
            checked={dryer}
            onChange={handleAdFeatureChange}
          />
        </Labelled>
      </div>
      <Hidden
        on={{
          xs: true,
          lg: false,
        }}
      >
        <Button onClick={handleClearFilterClick}>
          {t("filters.clear_filter")}
        </Button>
      </Hidden>
    </Box>
  );
};
