import { memo, useState } from "react";
import { Center, DataTable, Text } from "@boligportal/juice";
import { StyledAnchor } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { format } from "date-fns";
import { EmailEventsDialog } from "../EmailEventsDialog";
import { EmailLogEntry } from "../types";
import { ActionCell } from "./ActionCell";
import { StatusCell } from "./StatusCell";

type Props = {
  isError: boolean;
  entries?: EmailLogEntry[];
};

export const EmailLogsTable = memo(({ isError, entries }: Props) => {
  const [dialogEmailLogEntry, setDialogEmailLogEntry] =
    useState<EmailLogEntry>();

  const openEventsDialog = (log: EmailLogEntry) => {
    setDialogEmailLogEntry(log);
  };

  const closeEventsDialog = () => setDialogEmailLogEntry(undefined);

  if (!entries || entries.length == 0) {
    return (
      <Center minHeight={10}>
        <Text
          size="h3"
          weight="bold"
        >
          No logs found
        </Text>
      </Center>
    );
  }

  if (isError) {
    return (
      <Center minHeight={10}>
        <Text
          size="h3"
          weight="bold"
        >
          An error occured
        </Text>
      </Center>
    );
  }

  return (
    <>
      <EmailEventsDialog
        show={!!dialogEmailLogEntry}
        emailLog={dialogEmailLogEntry}
        onClose={closeEventsDialog}
      />

      <DataTable<EmailLogEntry>
        fullscreen
        items={entries}
        keyField="id"
        columns={[
          {
            width: "250px",
            fieldLabel: "Status",
            fieldName: "status",
            cellRenderer: StatusCell,
          },
          {
            width: "250px",
            fieldLabel: "Date/time",
            fieldName: "date_time",
            cellRenderer: (item) => (
              <Text size="tiny">
                {format(new Date(item.date_time), "dd-MM-yyyy HH:mm:ss")}
              </Text>
            ),
          },
          {
            width: "270px",
            fieldLabel: "Email address",
            fieldName: "email_address",
            cellRenderer: (item) => (
              <StyledAnchor
                href={`/customer-service/mail-user-redirect?email=${item.email}`}
              >
                {item.email_address}
              </StyledAnchor>
            ),
          },
          {
            width: "730px",
            fieldLabel: "Email subject",
            fieldName: "email_topic",
            cellRenderer: (item) => <Text size="tiny">{item.email_topic}</Text>,
          },
          {
            width: "30px",
            fieldLabel: "Open",
            fieldName: "open",
            cellRenderer: (item) => <Text size="tiny">{item.open}</Text>,
          },
          {
            width: "30px",
            fieldLabel: "Clicks",
            fieldName: "click",
            cellRenderer: (item) => <Text size="tiny">{item.click}</Text>,
          },
          {
            fieldLabel: "Email",
            fieldName: "id",
            cellRenderer: (item) => (
              <ActionCell
                log={item}
                showEvents={openEventsDialog}
              />
            ),
          },
        ]}
      />
    </>
  );
});
