import { ManualAddress } from "features/address/common/interfaces/ManualAddress";
import { AddressManualFormDenmark } from "features/address/dk/AddressManualDenmark";
import { getDoorValue } from "features/address/helpers/getDoorValue";
import { getFloorValue } from "features/address/helpers/getFloorValue";
import { manualAddressTrimmer } from "../../helpers/manualAddressTrimmer";

export const transformAddressManualFormDenmarkToManualAddress = (
  addressForm: AddressManualFormDenmark,
): ManualAddress => {
  const { address } = addressForm;

  manualAddressTrimmer(address);

  const manualAddress: ManualAddress = {
    street_name: address.street_name,
    street_number: address.street_number,
    postal_code: address.postal_code,
    postal_town: address.postal_town,
    floor: getFloorValue(address.floor),
    door: getDoorValue(address.door, address.side),
  };

  return manualAddress;
};
