import * as React from "react";
import { Box, Flex, Text } from "@boligportal/juice";
import { DesktopTintedText } from "apps/ad_detail/refactor/shared_components/DesktopTintedText";

export interface NameValueProps {
  name: string;
  value: string | React.ReactNode;
}

export const NameValueCell = ({ name, value }: NameValueProps) => (
  <Flex width="100%">
    <Box>
      <DesktopTintedText>{name}</DesktopTintedText>
    </Box>
    <Box flexPush>
      {React.isValidElement(value) ? value : <Text weight="bold">{value}</Text>}
    </Box>
  </Flex>
);
