import { useState, useCallback } from "react";
import { getVideoUploadLinkService } from "../services/get_video_upload_link_service";
import { saveVideoService } from "../services/save_video_service";
import { useTusUploader } from "./use_tus_uploader";

// Custom hook that hides away all the different services that
// are used in combination to upload a video to vimeo.
//
// Exposes state and methods for the implementing View to use.
//
// Steps involved when uploading a video:
// # 1 reguest a video upload url
// # 2 upload the file
// # 3 save the video on the ad
const useVideoUploadFeature = () => {
  const { startUpload, progress, resetTusUploader } = useTusUploader();
  const [videoUploadStatus, setVideoUploadStatus] = useState<
    "idle" | "busy" | "failed" | "finished"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [videoId, setVideoId] = useState<number | null>(null);

  const uploadAndSave = useCallback(
    (adId: number, file: File) => {
      resetTusUploader();
      setVideoUploadStatus("busy");

      // Before uploding to vimeo we need to request a Video Upload Link
      getVideoUploadLinkService(file).then((response) => {
        setVideoId(response.videoId);
        // Then, we can start uploading our file
        startUpload(
          response.uploadUrl,
          file,
          // When successfully uploaded, we need to save the video on the ad
          () => {
            saveVideoService(adId, response.videoId).then(
              (saveVideoResponse) => {
                setErrorMessage(saveVideoResponse.errorMessage);
                setVideoUploadStatus(
                  saveVideoResponse.success ? "finished" : "failed",
                );
              },
            );
          },
          // If an error ocurred while tus-js-client was uploading
          (error) => {
            setErrorMessage(error.message);
            setVideoUploadStatus("failed");
          },
        );
      });
    },
    [resetTusUploader, startUpload],
  );

  return {
    uploadAndSave,
    errorMessage,
    progress,
    videoUploadStatus,
    videoId,
  };
};

export { useVideoUploadFeature };
