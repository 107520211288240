import * as React from "react";
import { IsRetrivingEnergyLabelOverlay } from "features/rentable/IsRetrivingEnergyLabelOverlay";
import { RentableUpsellDialogContainer } from "features/upsell/components/RentableUpsellDialog/Container";
import { createInitialRentalAdsState } from "./helpers/createInitialRentalAdsState";
import {
  RentalAdsFeature,
  initialRentableAdsFeature,
} from "./interfaces/RentalAdsFeature";
import { RentalAdsState } from "./interfaces/RentalAdsState";
import { RentalAdsReducer } from "./reducer/RentalAdsReducer";
import { useRentalAds } from "./useRentalAds";

const RentalAdsFeatureContext = React.createContext<RentalAdsFeature>(
  initialRentableAdsFeature,
);
const RentalAdsStateContext = React.createContext<RentalAdsState>(
  createInitialRentalAdsState(),
);

export const RentalAdsProvider = ({
  children,
  isAdDetails,
}: {
  children: React.ReactNode;
  isAdDetails?: boolean;
}) => {
  const {
    feature,
    state,
    showUpsellForRentableId,
    isRetrivingEnergyLabel,
    visiblePlans,
    trigger,
  } = useRentalAds(RentalAdsReducer, isAdDetails);

  return (
    <RentalAdsFeatureContext.Provider value={feature}>
      <RentalAdsStateContext.Provider value={state}>
        <RentableUpsellDialogContainer
          open={!!showUpsellForRentableId}
          rentableId={showUpsellForRentableId}
          close={feature.closeUpsellDialog}
          onUpsellPurchased={feature.loadRentalAds}
          visiblePlans={visiblePlans}
          trigger={trigger}
        />
        {children}
        {isRetrivingEnergyLabel && <IsRetrivingEnergyLabelOverlay />}
      </RentalAdsStateContext.Provider>
    </RentalAdsFeatureContext.Provider>
  );
};

export const RentalAdDetailProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <RentalAdsProvider isAdDetails>{children}</RentalAdsProvider>;
};

export const useRentalAdsFeature = () =>
  React.useContext(RentalAdsFeatureContext);

export const useRentalAdsState = () => React.useContext(RentalAdsStateContext);
