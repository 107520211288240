import { Tracking } from "lib/tracking/common";

export const trackUserUploadedVideo = (
  from: "rentable detail" | "create ad",
) => {
  Tracking.trackEvent({
    category: from,
    label: "upload video",
    action: "video",
    isInteraction: true,
  });
};
