import { App } from "components/app";

interface InterpolationOptions {
  [key: string]: any;
}

export const t = (key: string, interpolationOptions?: InterpolationOptions) => {
  if (interpolationOptions && "smart_count" in interpolationOptions) {
    throw Error(
      "Use i18n.tn when instead of passing smart_count in interpolationOptions.",
    );
  }
  return App.i18n.t(key, interpolationOptions);
};

export const tn = (
  key: string,
  count: number,
  interpolationOptions?: InterpolationOptions,
) => {
  if (!interpolationOptions) {
    interpolationOptions = {};
  }
  return App.i18n.t(key, {
    ...interpolationOptions,
    smart_count: count,
  });
};
