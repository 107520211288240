import { ValidationErrors } from "lib/api";

export type BackendValidationErrors<FieldTypes> = Array<{
  fieldName: keyof FieldTypes;
  message: string;
}>;

export class BackendValidationError extends Error {
  private errors: ValidationErrors;

  constructor(errors: ValidationErrors) {
    super("BackendValidationError");
    this.errors = errors;
  }

  public getFieldAndMessageErrors<
    FieldTypes,
  >(): BackendValidationErrors<FieldTypes> {
    const errors: Array<{ fieldName: keyof FieldTypes; message: string }> = [];

    Object.entries(this.errors).forEach(([key, value]) => {
      errors.push({
        fieldName: key as keyof FieldTypes,
        message: value.length > 0 ? value[0] : "",
      });
    });

    return errors;
  }
}
