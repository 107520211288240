import * as React from "react";
import {
  Control,
  Controller,
  FieldPath,
  UseFormRegister,
} from "react-hook-form";
import {
  Button,
  EmptyBox,
  IconAdd,
  IconTrash,
  Label,
  NumberField,
  SelectField,
  Text,
  TextField,
} from "@boligportal/juice";
import { TenancyRentEntityType } from "business/domain/tenancy/TenancyRentEntityType";
import { t } from "lib/i18n";
import { TenancyRentTableFeature } from "../hooks/useTenancyRentTable";
import { TenancyRecurringRentForm } from "../types/TenancyRecurringRentForm";

export const TenancyRentTable = ({
  feature,
  buttonLabel,
}: {
  feature: TenancyRentTableFeature;
  buttonLabel: string;
}) => {
  const {
    controlledFields,
    removeOption,
    register,
    control,
    updatePaymentNameForIndex,
    addEmptyOption,
  } = feature;
  return (
    <table
      style={{
        width: "100%",
      }}
    >
      <PaymentTypesHeader />
      <tbody>
        {controlledFields.map((field, index) => (
          <PaymentType
            key={field["id_that_doesnt_collide_with_a_tenancy_rent_base_key"]}
            index={index}
            id={field.id}
            onDelete={removeOption}
            register={register}
            control={control}
            onUpdateName={updatePaymentNameForIndex}
            amount={field.amount}
          />
        ))}
      </tbody>

      <TableFooter>
        <EmptyBox
          p={3}
          bgColor="gray"
        >
          <Button
            icon={IconAdd}
            onClick={addEmptyOption}
            testId="tenancy_add_payment_option"
          >
            {buttonLabel}
          </Button>
        </EmptyBox>
      </TableFooter>
    </table>
  );
};

const TableFooter = ({ children }: { children: React.ReactNode }) => (
  <tfoot>
    <tr>
      <td
        colSpan={4}
        style={{
          width: "100%",
          paddingTop: 8,
        }}
      >
        {children}
      </td>
    </tr>
  </tfoot>
);

const PaymentTypesHeader = () => (
  <thead>
    <tr>
      <th
        style={{
          width: "33%",
          paddingRight: 24,
        }}
      >
        <Text
          weight="base"
          size="small"
        >
          {t("common.form_fields.payment_type.label")}
        </Text>
      </th>
      <th
        style={{
          width: "33%",
          paddingRight: 24,
        }}
      >
        <Text
          weight="base"
          size="small"
        >
          {t("common.form_fields.name.label")}
        </Text>
      </th>
      <th
        style={{
          width: "33%",
          paddingRight: 12,
        }}
      >
        <Label required>{t("common.form_fields.amount.label")}</Label>
      </th>
      <th></th>
    </tr>
  </thead>
);

const PaymentRow = ({
  col1,
  col2,
  col3,
  col4,
}: {
  col1: JSX.Element;
  col2: JSX.Element;
  col3: JSX.Element;
  col4: JSX.Element;
}) => (
  <tr>
    <td
      style={{
        width: "33%",
        paddingRight: 24,
        paddingBottom: 16,
      }}
    >
      {col1}
    </td>
    <td
      style={{
        width: "33%",
        paddingRight: 24,
        paddingBottom: 16,
      }}
    >
      {col2}
    </td>
    <td
      style={{
        width: "33%",
        paddingRight: 12,
        paddingBottom: 16,
      }}
    >
      {col3}
    </td>
    <td
      style={{
        paddingBottom: 16,
      }}
    >
      {col4}
    </td>
  </tr>
);

const PaymentType = ({
  index,
  id,
  control,
  register,
  onDelete,
  onUpdateName,
  amount,
}: {
  index: number;
  id: number | null;
  control: Control<TenancyRecurringRentForm>;
  register: UseFormRegister<TenancyRecurringRentForm>;
  onDelete: (index: number) => void;
  onUpdateName: (
    index: number,
    name: string,
    type: TenancyRentEntityType,
    amount: number,
    id: number | null,
  ) => void;
  amount: number;
}) => {
  const items = getPaymentTypeItems();

  const deleteRow = () => {
    onDelete(index);
  };

  const updateNameForSelectedIndex = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const itemIndex = event.target.selectedIndex;

    if (itemIndex < 0) {
      return;
    }

    const item = items[event.target.selectedIndex];

    onUpdateName(
      index,
      item.label,
      item.value as TenancyRentEntityType,
      amount,
      id,
    );
  };

  return (
    <PaymentRow
      col1={
        <SelectField
          label=""
          items={items}
          {...register(`recurringRent.${index}.type` as const, {
            validate: (value) => value !== items[0].value,
            onChange: updateNameForSelectedIndex,
          })}
        />
      }
      col2={
        <TextField
          type="text"
          label=""
          {...register(`recurringRent.${index}.name` as const, {
            required: true,
          })}
        />
      }
      col3={
        <ControlledNumberField
          control={control}
          name={`recurringRent.${index}.amount` as const}
        />
      }
      col4={
        <Button
          onClick={deleteRow}
          variant="subtle"
          icon={IconTrash}
        />
      }
    />
  );
};

const ControlledNumberField = ({
  control,
  name,
}: {
  control: any;
  name: FieldPath<TenancyRecurringRentForm>;
}) => (
  <Controller
    render={({ field: { onChange, onBlur, value, ref } }) => (
      <NumberField
        ref={ref}
        value={value as any}
        onValueChange={(value) => {
          onChange(value.floatValue ?? null);
        }}
        name={name}
        onBlur={onBlur}
        append={"kr."}
        decimalScale={2}
        thousandSeparator="."
        decimalSeparator=","
        onKeyDown={(event) => {
          const { key } = event;
          if (key === ".") {
            event.preventDefault();
          }
        }}
      />
    )}
    name={name}
    control={control}
    rules={{
      required: true,
    }}
  />
);

const getPaymentTypeItems = (): Array<{ label: string; value: string }> => [
  {
    label: t("common.select_payment_option"),
    value: "",
  },
  {
    label: t("common.form_fields.rent.label"),
    value: "rent",
  },
  {
    label: t("common.form_fields.on_account_heating.label"),
    value: "on_account_heating",
  },
  {
    label: t("common.form_fields.on_account_water.label"),
    value: "on_account_water",
  },
  {
    label: t("common.form_fields.on_account_electricity.label"),
    value: "on_account_electricity",
  },
  {
    label: t("common.form_fields.on_account_cooling.label"),
    value: "on_account_cooling",
  },
  {
    label: t("common.form_fields.on_account_joint_accounts.label"),
    value: "on_account_joint_accounts",
  },
  {
    label: t("common.form_fields.commercial_rental.label"),
    value: "commercial_rental",
  },
  {
    label: t("common.form_fields.storage_room_rental.label"),
    value: "storage_room_rental",
  },
  {
    label: t("common.form_fields.parking_rental.label"),
    value: "parking_rental",
  },
  {
    label: t("common.form_fields.garage_rental.label"),
    value: "garage_rental",
  },
  {
    label: t("common.form_fields.antenna_contribution.label"),
    value: "antenna_contribution",
  },
  {
    label: t("common.form_fields.resident_representation.label"),
    value: "resident_representation",
  },
  {
    label: t("common.form_fields.taxes_and_fees.label"),
    value: "taxes_and_fees",
  },
  {
    label: t("common.form_fields.deposit_regulation.label"),
    value: "deposit_regulation",
  },
  {
    label: t("common.form_fields.prepaid_rent_regulation.label"),
    value: "prepaid_rent_regulation",
  },
  {
    label: t("common.form_fields.internet.label"),
    value: "internet",
  },
  {
    label: t("common.form_fields.expenses_at_tenants.label"),
    value: "expenses_at_tenants",
  },
  {
    label: t("common.form_fields.water_drainage.label"),
    value: "water_drainage",
  },
  {
    label: t("common.form_fields.house_common_area.label"),
    value: "house_common_area",
  },
  {
    label: t("common.form_fields.improvement_supplement.label"),
    value: "improvement_supplement",
  },
  {
    label: t("common.form_fields.laundry.label"),
    value: "laundry",
  },
  {
    label: t("common.form_fields.other.label"),
    value: "other",
  },
];
