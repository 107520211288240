import { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
} from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { useAppActions } from "./Providers/AppActionsProvider";
import { App } from "./app";
import { MarketingCookiesComponent } from "./marketing_cookies";

export const Consent = () => {
  const location = useLocation();
  const { user } = useUser();
  const appActions = useAppActions();
  const [requireTermsAcceptance, setRequireTermsAcceptance] = useState(false);

  const isUserOnTermsRoute = useMemo(
    () =>
      decodeURIComponent(location.pathname).indexOf(
        decodeURIComponent(App.settings.routes.terms),
      ) !== -1,
    [location],
  );
  const isUserOnPrivacyPolicyRoute = useMemo(
    () =>
      decodeURIComponent(location.pathname).indexOf(
        decodeURIComponent(App.settings.routes.privacy),
      ) !== -1,
    [location],
  );

  useEffect(() => {
    if (
      user?.must_accept_new_terms &&
      !isUserOnTermsRoute &&
      !isUserOnPrivacyPolicyRoute
    ) {
      setRequireTermsAcceptance(true);
    }
  }, [user, isUserOnTermsRoute, isUserOnPrivacyPolicyRoute]);

  const handleAcceptTerms = () => {
    API.acceptTerms().then((success) => {
      if (success) {
        setRequireTermsAcceptance(false);
        appActions.reloadAppProps();
      }
    });
  };

  return (
    <>
      <MarketingCookiesComponent />
      {requireTermsAcceptance && (
        <Dialog
          dismissable={false}
          open
        >
          <DialogHeader>
            {t("terms.dialog.header", {
              brand_name: App.settings.brand_name,
            })}
          </DialogHeader>

          <DialogContent>
            <Box>
              {t("terms.dialog.content", {
                brand_name: App.settings.brand_name,
              })}
            </Box>

            <Link href={App.settings.routes.privacy}>
              {t("terms.dialog.link")}
            </Link>
          </DialogContent>

          <DialogFooter>
            <Flex>
              <Button
                variant="branded"
                onClick={() => handleAcceptTerms()}
              >
                {t("terms.dialog.accept_button")}
              </Button>
            </Flex>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
