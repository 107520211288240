import { Box, Button, Flex, Text } from "@boligportal/juice";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogSidebar,
} from "components/dialog/dialog";
import { Modal } from "components/modal";
import { terminateSubscription } from "lib/customerServiceApi";
import SvgX16px from "media/icons/X16px";
import { UserForCustomerService } from "../../user_detail_view/user_detail_view";
import { SubscriptionPaymentsItemInterface } from "../interfaces";
import { SubscriptionStatusBlock } from "./subscription_status_block";

interface TerminateSubscriptionSidebarProps {
  closeHandler: () => void;
  user: UserForCustomerService;
  item: SubscriptionPaymentsItemInterface;
}

const TerminateSubscriptionSidebar = ({
  closeHandler,
  user,
  item,
}: TerminateSubscriptionSidebarProps) => {
  async function onSubmit() {
    const subscriptionId = item.subscription.id;
    await terminateSubscription(subscriptionId);
    closeHandler();
  }

  return (
    <Modal>
      <DialogSidebar
        width="500px"
        onClose={closeHandler}
      >
        <DialogHeader>
          <Flex
            width="100%"
            align="center"
          >
            <Text size="h3">Expire subscription</Text>
            <Box flexPush>
              <Button onClick={closeHandler}>
                <SvgX16px />
              </Button>
            </Box>
          </Flex>
        </DialogHeader>
        <DialogBody>
          <Flex column>
            <Text
              block
              size="tiny"
            >
              User
            </Text>
            <Text
              block
              weight="bold"
            >{`${user.email} - ${user.first_name} ${user.last_name}`}</Text>
          </Flex>

          <Flex
            column
            mt={2}
          >
            <Text
              block
              size="tiny"
            >
              Product
            </Text>
            <Flex gap>
              <SubscriptionStatusBlock subscription={item.subscription} />
            </Flex>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap>
            <Button
              variant="subtle"
              onClick={closeHandler}
            >
              No, keep subscription
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
            >
              Expire subscription
            </Button>
          </Flex>
        </DialogFooter>
      </DialogSidebar>
    </Modal>
  );
};

export { TerminateSubscriptionSidebar };
