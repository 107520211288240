import * as React from "react";
import {
  Button,
  CheckField,
  Text,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Notice,
} from "@boligportal/juice";
import { usePaymentRecordDeletionMutation } from "api/mutations";
import { t } from "lib/i18n";

type PaymentRecordDeletionCandidate = {
  paymentRecordId: number;
  onDeleteSuccess?: () => void;
};

type PaymentRecordDeletionContextValue = {
  open: boolean;
  candidate: PaymentRecordDeletionCandidate | null;
  setPaymentRecordDeletionCandidate: (
    candidate: PaymentRecordDeletionCandidate | null,
  ) => void;
  onCancel: () => void;
  invalidationQueries: string[];
};

const PaymentRecordDeletionContext =
  React.createContext<PaymentRecordDeletionContextValue>(null!);

const PaymentRecordDeletionDialog = () => {
  const [didConsentToDelection, setDidConsentToDelection] =
    React.useState(false);
  const { candidate, open, onCancel, invalidationQueries } = React.useContext(
    PaymentRecordDeletionContext,
  );
  const { canDeletePaymentRecordDryRun, deletePaymentRecordMutation } =
    usePaymentRecordDeletionMutation(invalidationQueries);

  React.useEffect(() => {
    if (candidate?.paymentRecordId) {
      setDidConsentToDelection(false);
      canDeletePaymentRecordDryRun.mutate(candidate.paymentRecordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  if (!candidate) {
    return null;
  }

  if (
    canDeletePaymentRecordDryRun.status === "idle" ||
    canDeletePaymentRecordDryRun.status === "loading"
  ) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      size="large"
    >
      <DialogHeader>{t("rentable.rent.delete_payment.title")}</DialogHeader>
      <DialogContent>
        {canDeletePaymentRecordDryRun.status === "error" && (
          <Flex column>
            <Text
              markdown
              pb={2}
            >
              {t("tenancy.payment.deletion.message_markdown")}
            </Text>
            <Notice type="danger">
              {t(canDeletePaymentRecordDryRun.error.response.data.error.code)}
            </Notice>
          </Flex>
        )}
        {canDeletePaymentRecordDryRun.status === "success" && (
          <Flex column>
            <Text
              markdown
              pb={2}
            >
              {t("tenancy.payment.deletion.message_consent_markdown")}
            </Text>
            <CheckField
              label={t("tenancy.payment.deletion.check_field_consent")}
              checked={didConsentToDelection}
              onChange={(event) =>
                setDidConsentToDelection(event.target.checked)
              }
            />
          </Flex>
        )}
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onCancel}
          >
            {t("rentable.rent.delete_payment.cancel")}
          </Button>
          <Button
            disabled={!didConsentToDelection}
            variant="danger"
            onClick={() => {
              deletePaymentRecordMutation
                .mutateAsync(candidate.paymentRecordId)
                .then((isSuccess) => {
                  if (isSuccess && candidate.onDeleteSuccess) {
                    candidate.onDeleteSuccess();
                  }
                })
                .finally(() => {
                  onCancel();
                });
            }}
          >
            {t("rentable.rent.delete_payment.confirm")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};

type ProviderProps = {
  children: React.ReactNode;
  invalidationQueries: string[];
};

export const PaymentRecordDeletionProvider = ({
  children,
  invalidationQueries,
}: ProviderProps) => {
  const [paymentRecordDeletionCandidate, setPaymentRecordDeletionCandidate] =
    React.useState<PaymentRecordDeletionCandidate | null>(null);
  return (
    <PaymentRecordDeletionContext.Provider
      value={{
        open: Boolean(paymentRecordDeletionCandidate),
        candidate: paymentRecordDeletionCandidate,
        onCancel: () => setPaymentRecordDeletionCandidate(null),
        setPaymentRecordDeletionCandidate,
        invalidationQueries,
      }}
    >
      {children}
      <PaymentRecordDeletionDialog />
    </PaymentRecordDeletionContext.Provider>
  );
};

export const usePaymentRecordDeletion = () => {
  const context = React.useContext(PaymentRecordDeletionContext);
  if (!context) {
    throw "usePaymentRecordDeletion hook can only be used inside function component as a Child of PaymentRecordDeletionProvider";
  }

  const { setPaymentRecordDeletionCandidate, open } = context;

  return {
    isActive: open,
    setPaymentRecordDeletionCandidate,
  };
};
