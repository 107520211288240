import { Box, Flex, IconAdd, styled } from "@boligportal/juice";
import { EnergyLabel } from "apps/ad_detail/refactor/components/general_information/striped_name_values/components/EnergyLabel";
import { t } from "lib/i18n";

export type RentableEnergyCell = {
  energy_rating: {
    rating: string | null;
    retrieveEnergyRatingLabel: string;
    onClick: () => void;
  };
};

export const RentableEnergyCellRenderer = ({
  energy_rating,
}: RentableEnergyCell) => {
  if (energy_rating.rating) {
    return (
      <EnergyLabel
        label={energy_rating.rating}
        width="20px"
        border
      />
    );
  }

  return (
    <StyledLinkBox onClick={energy_rating.onClick}>
      <Flex
        align="center"
        gap={0.5}
      >
        <IconAdd size="medium" />
        {t("rentable_list.energy_rating_cell.fetch_label")}
      </Flex>
    </StyledLinkBox>
  );
};

const StyledLinkBox = styled(Box)`
  color: ${(props) => props.theme.color.text.primary};
  &:hover {
    text-decoration: underline;
  }
`;
