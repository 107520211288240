import {
  UpdatePaginationStartIndex,
  UpdateSearchValue,
} from "apps/rental_ads/interfaces/RentalAdsFeature";

export type ChoseRentable = (rentableId: number) => void;
export type LoadRentables = () => void;
type ShowAddressDialog = () => void;
type HideAddressDialog = () => void;

export interface ChooseOrCreateRentableFeature {
  loadRentables: LoadRentables;
  choseRental: ChoseRentable;
  showAddressDialog: ShowAddressDialog;
  hideAddressDialog: HideAddressDialog;
  updatePaginationStartIndex: UpdatePaginationStartIndex;
  updateSearchValue: UpdateSearchValue;
}

export const initialChooseOrCreateRentableFeature: ChooseOrCreateRentableFeature =
  {
    choseRental: () => {},
    loadRentables: () => {},
    showAddressDialog: () => {},
    hideAddressDialog: () => {},
    updatePaginationStartIndex: () => {},
    updateSearchValue: () => {},
  };
