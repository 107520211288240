import { useEffect, useState, useCallback } from "react";
import { Box } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { PaymentSpinner } from "components/payment_spinner";
import { calculateTotalPriceFromPlans } from "features/payments_feature/utils/calculateTotalPriceFromPlans";
import { fetchFromAPI } from "lib/api";
import {
  DialogModal,
  DialogModalBody,
  DialogModalHeader,
} from "../dialog_modal";

interface MobilePayPaymentModalProps {
  plans: Plan[];
  title: string;
  onBeforeRedirect?: () => void;
  adId: number | null;
  rentableId?: number;
}

const MobilePayPaymentModal = ({
  plans,
  title,
  onBeforeRedirect,
  adId,
  rentableId,
}: MobilePayPaymentModalProps) => {
  const { paymentLink, getPaymentLink } = useMobilePayService();

  useEffect(() => {
    const amount = calculateTotalPriceFromPlans(plans);
    const planIds = plans.map((plan) => plan.plan_id);
    getPaymentLink(amount, planIds, adId, rentableId);
  }, [plans, adId, rentableId, getPaymentLink]);

  useEffect(() => {
    if (paymentLink !== undefined) {
      onBeforeRedirect?.();
      window.location.href = paymentLink;
    }
  }, [paymentLink, plans, onBeforeRedirect]);

  return (
    <DialogModal>
      <DialogModalHeader title={title} />
      <DialogModalBody>
        <Box height="275px">
          <PaymentSpinner submitted={false} />
        </Box>
      </DialogModalBody>
    </DialogModal>
  );
};

export { MobilePayPaymentModal };

const useMobilePayService = () => {
  const [paymentLink, setPaymentLink] = useState<string>();

  const getPaymentLink = useCallback(
    async (
      amount: number,
      plan_ids: number[],
      adId: number | null,
      rentableId?: number,
    ) => {
      let returnUrl = document.location.href;
      if (adId && rentableId) {
        const params = new URLSearchParams(document.location.search);
        params.append("ad_id", adId.toString());
        params.append("rentable_id", rentableId.toString());
        returnUrl =
          document.location.origin +
          document.location.pathname +
          "?" +
          params.toString();
      }

      const response = await fetchFromAPI(
        "/api/payments/mobilepay_payment_link",
        {
          method: "POST",
          body: JSON.stringify({
            amount,
            plan_ids,
            return_url: returnUrl,
            ad_id: adId,
          }),
        },
      );
      const data = await response.json();

      if (response.ok && data.url) {
        setPaymentLink(data.url);
      }
    },
    [],
  );

  return {
    paymentLink,
    getPaymentLink,
  };
};
