/// <reference types="facebook-pixel" />

const fbq: facebook.Pixel.Event = function () {
  const trackFunc = (retryNum: number = 0) => {
    // Track with Facebook, or delay and try again
    if (retryNum >= 5) {
      // Give up after trying for awhile
      return;
    }
    if (window.fbq) {
      window.fbq.apply(null, arguments);
    } else {
      setTimeout(trackFunc, 1000, retryNum + 1);
    }
  };
  trackFunc();
};

export class FacebookTrackingEvent {
  public static trackDetailView(adId: number, category: string) {
    fbq("track", "ViewContent", {
      content_ids: [adId.toString()],
      content_type: "product",
      property_type: this.mapAdCategory(category),
      listing_type: "for_rent_by_owner",
    });
  }

  private static mapAdCategory(category: string): string {
    const categoryMap = {
      rental_apartment: "apartment",
      rental_house: "house",
      rental_townhouse: "townhouse",
      rental_room: "studio",
      rental_cabin: "other",
    };
    return categoryMap[category];
  }

  public static trackEvent(eventName: string, params = {}) {
    fbq("trackCustom", eventName, params);
  }

  public static trackPurchase(value: number, currency: string, adId: number) {
    fbq("track", "Purchase", {
      value: value,
      currency: currency,
      content_ids: [adId.toString()],
      content_type: "product",
    });
  }

  public static trackCompleteRegistration() {
    fbq("track", "CompleteRegistration", {});
  }

  public static trackInitiateCheckout(adId: number) {
    fbq("track", "InitiateCheckout", {
      content_ids: [adId.toString()],
      content_type: "product",
    });
  }

  public static trackAgentCreated() {
    fbq("track", "AgentCreated", {});
  }
}
