import { useState } from "react";
import { Card, CardSection, Text, TextField } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { NewBuildProjectDetailsDialog } from "./NewBuildProjectDetailsDialog";

type NewBuildProjectDetailsProps = {
  newBuild: Ad;
  newBuildId: number;
};
export const NewBuildProjectDetails = ({
  newBuild,
  newBuildId,
}: NewBuildProjectDetailsProps) => {
  const [showDialog, setShowDialog] = useState(false);
  if (!newBuild) {
    return null;
  }

  const { formatted_address, address } = newBuild;

  const handleEditDialogOpen = () => {
    setShowDialog(true);
  };

  const handleEditDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Card sectioned>
        <CardSection>
          <Text
            size="lead"
            weight="bold"
          >
            Project details
          </Text>
        </CardSection>
        <CardSection>
          <TextField
            label="Address"
            value={formatted_address ? formatted_address : "No address"}
            onClick={handleEditDialogOpen}
          />
        </CardSection>
      </Card>
      <NewBuildProjectDetailsDialog
        newBuildId={newBuildId}
        address={address}
        open={showDialog}
        onClose={handleEditDialogClose}
      />
    </>
  );
};
