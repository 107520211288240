import { ContractEllipsisMenuItem } from "components/cells/ContractEllipsisMenuCellRenderer";
import { localizedMonthDayAndYear } from "lib/date";
import { ContractViewModel, SigningStatus } from "../../interfaces";
import { ContractListItem } from "../../store/contracts";
import {
  createAddressLink,
  createTenantsDisplayLabel,
  createStateSubLabel,
  createStateLabel,
} from "./createDisplayLabels";

type Config = {
  contracts: ContractListItem[];
  onEllipsisMenuSelect: (
    record: ContractListItem,
    item: ContractEllipsisMenuItem,
  ) => void;
};

export const createContractsListViewModels = (
  config: Config,
): ContractViewModel[] => {
  const items: ContractViewModel[] = [];

  config.contracts.forEach((contractItem) => {
    items.push({
      id: contractItem.id,
      rentable_address: contractItem.rentable_address ?? "",
      addressInfo: {
        link: createAddressLink(contractItem),
        tenantsDisplayLabel: createTenantsDisplayLabel(contractItem),
      },
      leasing_period_start_date: contractItem.move_in_date
        ? localizedMonthDayAndYear(new Date(contractItem.move_in_date))
        : "-",
      state: contractItem.state as SigningStatus,
      stateInfo: {
        stateLabel: createStateLabel(contractItem),
        stateSubLabel: createStateSubLabel(contractItem),
      },
      contractEllipsisMenuCell: {
        onMenuItemSelect: (item) =>
          config.onEllipsisMenuSelect(contractItem, item),
      },
    });
  });

  return items;
};
