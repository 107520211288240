import { SubscriptionFeedItemInterface } from "apps/customer_service/pages/user_detail_page/subscriptions_feed/interfaces";
import { fetchFromAPI } from "lib/api";
import { UserListItem } from "lib/customerServiceApi";

interface SearchUsersFilters {
  query: string;
  ban_reasons: string[] | null;
  subscription_status: string[] | null;
  creation_date_from: string | null;
  creation_date_to: string | null;
  login_date_from: string | null;
  login_date_to: string | null;
  profile_needs_review?: boolean;
}

interface SearchUsersResponse {
  results: SearchUsersResult[];
  count: number;
  results_pr_page: number;
  page: number;
}
interface SearchUsersResult {
  user: UserListItem;
  seeker_subscription: SubscriptionFeedItemInterface;
}

const searchUsersService = async (
  filters: SearchUsersFilters,
  offset: number,
  limit: number,
  signal: AbortSignal,
  sorting?: string,
  descending?: boolean,
): Promise<SearchUsersResponse> => {
  const url = encodeURI("/customer-service/api/users/");
  try {
    const response = await fetchFromAPI(url, {
      signal,
      method: "POST",
      body: JSON.stringify({
        filters,
        offset,
        limit,
        sorting,
        descending,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line compat/compat
    return Promise.reject(error);
  }
};

export { searchUsersService };
