import { useState } from "react";
import {
  Flex,
  Box,
  Text,
  Notice,
  Dialog,
  DialogHeader,
  DialogContent,
  styled,
} from "@boligportal/juice";
import { localizedDateTimeLongAndPretty } from "lib/date";
import { t } from "lib/i18n";
import { DefectFlowStatus } from "../enums/DefectFlowState";
import { MovingReportForTenant } from "../interfaces/movingReportForTenant";

const StyledMore = styled.span`
  color: ${(props) => props.theme.color.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DetailsDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogHeader>
          {t("moving_reports.defects_period_notice.info_dialog.header")}
        </DialogHeader>

        <DialogContent>
          <Text block>
            {t("moving_reports.defects_period_notice.info_dialog.line_1")}
          </Text>
          <Text block>
            {t("moving_reports.defects_period_notice.info_dialog.line_2")}
          </Text>
          <Text block>
            {t("moving_reports.defects_period_notice.info_dialog.line_3")}
          </Text>
        </DialogContent>
      </Dialog>
      <StyledMore onClick={() => setIsOpen(true)}>
        {t("moving_reports.defects_period_notice.read_more")}
      </StyledMore>
    </>
  );
};

export const FlowInfoNotice = ({
  report,
}: {
  report: MovingReportForTenant;
}) => {
  const infoText = getInfoText(report);

  if (!infoText) {
    return null;
  }

  return (
    <Box mb={1}>
      <Notice type="info">
        <Flex column>
          <Box>
            <Text
              weight="bold"
              size="body"
              block
              mb={0.5}
            >
              {infoText.title}
            </Text>
          </Box>

          <Text
            block
            size="small"
          >
            {infoText.content}
          </Text>
        </Flex>
      </Notice>
    </Box>
  );
};

export const getInfoText = (report: MovingReportForTenant) => {
  if (report.defect_flow_status === DefectFlowStatus.NOT_STARTED) {
    return {
      title: t("moving_reports.defects_period_notice.not_started_info"),
      content: (
        <>
          {t("moving_reports.defects_period_notice.not_started_description", {
            daysToStart: report.defect_flow_days_to_start,
            startDate: localizedDateTimeLongAndPretty(
              report.defect_flow_start_date,
            ),
          })}{" "}
          <DetailsDialog />
        </>
      ),
    };
  }

  if (report.defect_flow_status === DefectFlowStatus.ACTIVE) {
    return {
      title: t("moving_reports.defects_period_notice.active_info"),
      content: (
        <>
          {t("moving_reports.defects_period_notice.active_description", {
            daysRemaining: report.defect_flow_days_remaining,
            endDate: localizedDateTimeLongAndPretty(
              report.defect_flow_end_date,
            ),
          })}{" "}
          <DetailsDialog />
        </>
      ),
    };
  }

  if (report.defect_flow_status === DefectFlowStatus.EXPIRED) {
    return {
      title: t("moving_reports.defects_period_notice.expired_info"),
      content: (
        <>
          {t("moving_reports.defects_period_notice.expired_description", {
            endDate: localizedDateTimeLongAndPretty(
              report.defect_flow_end_date,
            ),
          })}
        </>
      ),
    };
  }

  return null;
};
