import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { localizedMonthDayAndYear } from "lib/date";
import { AdCategories, AdStates } from "lib/enums";
import { t } from "lib/i18n";
import { formatNumber, getLocale } from "lib/utils";

export class AdUtils {
  public static hasActiveAdPromotion(ad?: Ad) {
    return ad && (ad.is_open || ad.is_promoted || ad.is_exposure);
  }

  public static isFreeContact(ad: Ad) {
    return ad.is_open || ad.is_exposure;
  }

  public static isUnavailable(ad: Ad) {
    return (
      ad.state === AdStates.RENTED_OUT_APPROVED ||
      ad.state === AdStates.RESERVED_APPROVED ||
      ad.deleted
    );
  }

  public static moveInDate(date: Date | string | null): string {
    const current_date = new Date();
    const moveInDate = date === null ? null : new Date(date);
    if (moveInDate === null || moveInDate < current_date) {
      return t("move_in_date.asap");
    }
    return localizedMonthDayAndYear(moveInDate);
  }

  public static dateHasPassed(date: Date | string | null) {
    const current_date = new Date();
    const moveInDate = date === null ? null : new Date(date);
    if (moveInDate === null || moveInDate < current_date) {
      return true;
    }
    return false;
  }

  public static formatNumber(num: number) {
    return Math.round(num).toLocaleString(getLocale());
  }

  public static currencyLabel(currency: string) {
    if (App.settings.language === "en") {
      return `${currency}`;
    } else {
      return "kr";
    }
  }

  public static formatCosts(num: number): string {
    return `${Math.round(num < 0 ? 0 : num).toLocaleString(getLocale())},-`;
  }

  public static formatCostsTwoDecimalsCurrency(
    num: number,
    showCurrency: boolean = true,
  ): string {
    if (Number.isInteger(num)) {
      return `${(num < 0 ? 0 : num).toLocaleString(getLocale())} ${
        showCurrency ? "kr." : ""
      }`;
    }
    return `${num.toLocaleString(getLocale(), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })} ${showCurrency ? "kr." : ""}`;
  }

  public static calcMoveInPrice(ad: Ad): number {
    return (
      Number(ad.monthly_rent) +
      Number(ad.monthly_rent_extra_costs) +
      Number(ad.prepaid_rent) +
      Number(ad.deposit)
    );
  }

  public static getTransForCategory = (category: string): string => {
    switch (category) {
      case AdCategories.APARTMENT: {
        return t("adcard.rental_apartment");
      }
      case AdCategories.HOUSE: {
        return t("adcard.rental_house");
      }
      case AdCategories.ROOM: {
        return t("adcard.rental_room");
      }
      case AdCategories.CABIN: {
        return t("adcard.rental_cabin");
      }
      case AdCategories.TOWNHOUSE: {
        return t("adcard.rental_townhouse");
      }
      default:
        return "";
    }
  };

  public static getTransForSRPCategory = (category: string): string => {
    switch (category) {
      case AdCategories.APARTMENT: {
        return t("srp_card.rental_apartment");
      }
      case AdCategories.HOUSE: {
        return t("srp_card.rental_house");
      }
      case AdCategories.ROOM: {
        return t("srp_card.rental_room");
      }
      case AdCategories.CABIN: {
        return t("srp_card.rental_cabin");
      }
      case AdCategories.TOWNHOUSE: {
        return t("srp_card.rental_townhouse");
      }
      default:
        return "";
    }
  };

  public static constructShortTitle = (
    rooms: number,
    category: string,
    size_m2: number,
  ) =>
    `${formatNumber(rooms)} ${AdUtils.getTransForSRPCategory(category)} ${t(
      "ad_details.title.of",
    )} ${formatNumber(size_m2, {
      round: true,
    })} ${t("m2")}`;
}
