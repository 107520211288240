import * as React from "react";
import { Button, Flex, IconArrowBack, Text } from "@boligportal/juice";
import { HeaderLabel } from "components/forms/header_label";
import { t } from "lib/i18n";

export const GoBackToAutoAddressFormButton = ({
  onClick,
  hideHelpText = false,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hideHelpText?: boolean;
}) => (
  <>
    <Flex
      pb={1}
      align="center"
      gap={1}
    >
      <IconArrowBack />
      <Button
        text
        onClick={onClick}
      >
        {t("feature.address_form.go_back_to_address_search_button.text")}
      </Button>
    </Flex>
    <Flex
      column
      pb={2}
      hidden={hideHelpText}
    >
      <HeaderLabel
        label={t("feature.address_form.custom_address.heading")}
        htmlFor=""
      />
      <Text
        size="tiny"
        color="muted"
        block
      >
        {t("Only paying members will see the location on a map")}
      </Text>
    </Flex>
  </>
);
