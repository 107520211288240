import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Flex, RadioField, TextField } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";

const RentalMoveInDate = () => {
  const { register, control, watch, setValue } = useFormContext();

  const watchAvailableFrom = watch("available_from");
  const watchAvailableFromOption = watch("available_from_option");

  useEffect(() => {
    if (watchAvailableFrom) {
      setValue("available_from_option", "date");
    } else {
      setValue("available_from_option", "asap");
    }
  }, [watchAvailableFrom, setValue]);

  useEffect(() => {
    if (watchAvailableFromOption === "asap") {
      setValue("available_from", "");
    }
  }, [watchAvailableFromOption, setValue]);

  return (
    <Box>
      <Flex
        gap
        column={{
          xs: true,
          md: false,
        }}
        align={{
          xs: "stretch",
          md: "center",
        }}
      >
        <TextField
          {...register("available_from")}
          label={t("rentable_details.editor.move_in_date")}
          type="date"
          lang={App.settings.language}
          required
        />

        <Box
          mt={{
            xs: -1,
            md: 0,
          }}
          mb={{
            xs: 1,
            md: 0,
          }}
        >
          <Controller
            render={({ field }) => (
              <RadioField
                label=""
                items={[
                  {
                    value: "asap",
                    label: t(
                      "rentable_details.editor.move_in_as_soon_as_possible",
                    ),
                  },
                ]}
                {...field}
              />
            )}
            name="available_from_option"
            control={control}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export { RentalMoveInDate };
