/* eslint-disable compat/compat */
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Filters } from "components/interfaces/filters";
import { getSRPUrlWithFilters } from "../helpers/getSRPUrlWithFilters";

export const useUpdateBrowserHistory = ({
  canReplaceUrl,
  currentUrl,
  filters,
  view,
  offset,
  mapCenter,
  mapZoom,
  selectedAds,
}: {
  canReplaceUrl: React.MutableRefObject<boolean>;
  currentUrl: string;
  filters: Filters;
  view: "map" | "list";
  offset: number;
  mapCenter: [number, number] | null;
  mapZoom: number | null;
  selectedAds: number[];
}) => {
  const history = useHistory();
  const location = useLocation();
  const [url, setUrl] = React.useState(() =>
    getSRPUrlWithFilters(
      {
        currentUrl,
        filters,
        view,
        offset,
        mapCenter,
        mapZoom,
        selectedAds,
      },
      location.search,
    ),
  );

  React.useEffect(() => {
    const newUrl = getSRPUrlWithFilters(
      {
        currentUrl,
        filters,
        view,
        offset,
        mapCenter,
        mapZoom,
        selectedAds,
      },
      location.search,
    );

    setUrl(newUrl);
  }, [
    canReplaceUrl,
    currentUrl,
    filters,
    view,
    offset,
    mapCenter,
    mapZoom,
    selectedAds,
    location.search,
  ]);

  React.useEffect(() => {
    // Don't replace the URL on component mount. Doing so would erase extra query string parameters,
    // such as UTM params / gclid, could cause the pageview to be tracked incorrectly in Google Analytics,
    // and therefore increase our bounce rate.
    if (!canReplaceUrl.current) {
      return;
    }

    history.replace(url);
  }, [canReplaceUrl, url, history]);
};
