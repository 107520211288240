import { useState } from "react";
import { Box, Button, styled } from "@boligportal/juice";
import { t } from "lib/i18n";

const StyledTruncated = styled.span<{ maxLines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ReadMoreContainer = ({
  content,
  maxLength,
}: {
  content: string;
  maxLength: number;
}) => {
  const [showAllContent, setShowAllContent] = useState(false);

  const contentExceedsMaxLength = content.length > maxLength;

  const processedContent =
    showAllContent || !contentExceedsMaxLength
      ? content
      : `${content.slice(0, maxLength)}…`;

  return (
    <div>
      {processedContent}
      {contentExceedsMaxLength && (
        <Box
          ml={0.5}
          inline
        >
          <Button
            onClick={() => setShowAllContent(!showAllContent)}
            text
          >
            {showAllContent
              ? t("read_more_container.close")
              : t("read_more_container.read_more")}
          </Button>
        </Box>
      )}
    </div>
  );
};

export const TruncateByLines = ({
  children,
  maxLines,
}: {
  children: string;
  maxLines: number;
}) => <StyledTruncated maxLines={maxLines}>{children}</StyledTruncated>;
