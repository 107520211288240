import { Box } from "@boligportal/juice";
import { User } from "components/interfaces/user";
import { UnauthenticatedHamburgerMenu } from "./UnauthenticatedHamburgerMenu";
import { UserAvatarLandlordMenu } from "./UserAvatarLandlordMenu";
import { UserAvatarSeekerMenu } from "./UserAvatarSeekerMenu";

interface Props {
  user: User | null;
  inverseColor: boolean;
}

export const DropDownMenu = ({ user, inverseColor }: Props) => {
  if (!user) {
    return (
      <Box
        hidden={{
          xs: false,
          xl: true,
        }}
      >
        <UnauthenticatedHamburgerMenu inverseColor={inverseColor} />
      </Box>
    );
  }

  if (user.has_created_rentable || user.has_created_newbuild) {
    return (
      <UserAvatarLandlordMenu
        user={user}
        inverseColor={inverseColor}
      />
    );
  }

  return (
    <UserAvatarSeekerMenu
      user={user}
      inverseColor={inverseColor}
    />
  );
};
