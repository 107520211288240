import * as React from "react";
import { Box, Flex, styled, Text } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";

const HouseSvg = ({ className = "" }: { className?: string }) => (
  <svg
    width="200"
    height="125"
    viewBox="8 -2 32 32"
    className={className}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(246,239,236,1)"
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501
        1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095
        1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const TextWrapper = styled(Box)`
  flex: 1;
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 66px;
  height: 60px;
  margin-left: ${(props) => props.theme.unit(2)};
  z-index: 1;
`;

const Image = styled(CdnImage)`
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 100;
`;

const TopLeftImage = styled(Image)`
  position: relative;
`;

const BottomRightImage = styled(Image)`
  position: relative;
  left: 22px;
  bottom: 28px;
`;

const HouseIcon = styled(HouseSvg)`
  position: absolute;
  top: 6px;
  right: -90px;
`;

type Props = Pick<
  React.ComponentProps<typeof Flex>,
  "className" | "mt" | "border" | "borderRadius" | "bg"
> & {
  href?: string;
};

const ConditionalLinkWrap = ({
  href,
  children,
}: {
  href?: string;
  children: React.ReactNode;
}) => {
  if (href) {
    return <a href={href}>{children}</a>;
  }

  return <>{children}</>;
};

export const CustomerSupportBanner = ({
  href,
  bg = "tint",
  ...props
}: Props) => (
  <ConditionalLinkWrap href={href}>
    <Wrapper
      borderTop
      align="center"
      bg={bg}
      p={2}
      {...props}
    >
      <TextWrapper>
        <Text
          weight="bold"
          block
          size="small"
          color="branded"
        >
          {App.settings.market === Market.BOLIGPORTAL_DK
            ? t("customer_support_banner.main_text")
            : t("customer_support_banner.se.main_text")}
        </Text>
        <Text
          block
          size="small"
        >
          {App.settings.market === Market.BOLIGPORTAL_DK
            ? t("customer_support_banner.description")
            : t("customer_support_banner.se.description")}
        </Text>
      </TextWrapper>
      <ImageWrapper>
        <TopLeftImage
          width={44}
          height={44}
          src="/static/images/customer_support_portrait/portrait_1.png"
          alt="Customer service person"
        />
        <BottomRightImage
          width={44}
          height={44}
          src="/static/images/customer_support_portrait/portrait_2.png"
          alt="Customer service person"
        />
      </ImageWrapper>
      <HouseIcon />
    </Wrapper>
  </ConditionalLinkWrap>
);
