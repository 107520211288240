import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Box, Button } from "@boligportal/juice";
import { AdImageInterface } from "apps/customer_service/pages/ad_detail_page/interfaces";
import { useReactCrop } from "./hooks/useReactCrop";

type Props = {
  image: AdImageInterface;
  adId: number;
  selectedEditId: number;
};

export const EditImageMain = ({ image, adId, selectedEditId }: Props) => {
  const {
    dragEndHandler,
    imageLoadedHandler,
    changeHandler,
    save,
    crop,
    canSave,
    isSaving,
    imageUrl,
  } = useReactCrop(adId, image.id, image.url);

  return (
    <>
      <ReactCrop
        ruleOfThirds
        disabled={isSaving}
        src={imageUrl}
        crop={crop}
        onChange={changeHandler}
        onDragEnd={dragEndHandler}
        onImageLoaded={(imageEl) => {
          imageLoadedHandler(imageEl);
          if (image.id === selectedEditId) {
            imageEl.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }}
      />
      <Box
        mb={3}
        bg="tint"
      >
        <Button
          disabled={isSaving || !canSave}
          loading={isSaving}
          onClick={save}
        >
          Save cropped area
        </Button>
      </Box>
    </>
  );
};
