/* eslint-disable comma-dangle */
import { SuggestionList } from "@boligportal/juice";
import { t } from "lib/i18n";
import { useAddressSuggestionList } from "../common/hooks/useAddressSuggestionList";
import { AddressSuggestionProps } from "../common/types/AddressSuggestionProps";
import { AddressCandidateRenderer } from "./components/AddressCandidateRenderer";
import { resolveAddressFromCandidate } from "./helpers/resolveAddressFromCandidate";
import { getAddressCandidates } from "./services/getAddressCandidates";

export const AddressSuggestionDenmark = ({
  onAddress,
  onAddressCleared,
  initialAddress,
}: AddressSuggestionProps) => {
  const { matches, updateInput, inputText, lookForMatchesAgainOrChooseItem } =
    useAddressSuggestionList({
      onAddress,
      onAddressCleared,
      getAddressCandidates,
      resolveAddressFromCandidate,
      initialAddress,
    });

  return (
    <SuggestionList
      items={matches}
      itemRenderer={AddressCandidateRenderer}
      onSelect={lookForMatchesAgainOrChooseItem}
      inputProps={{
        type: "search",
        autoComplete: "off",
        onChange: updateInput,
        value: inputText,
        placeholder: t("rentable_list.search.placeholder"),
      }}
    />
  );
};
