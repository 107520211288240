import * as React from "react";
import { Text, Link, styled, IconCheckmark } from "@boligportal/juice";

type Props = {
  translationStringWithLinebreaks: string;
  checkIcon?: React.ReactNode;
  isBold?: boolean;
  color?: "base" | "inverted";
  fontSize?: "lead" | "body" | "small";
};

/**
 * Renders a list of features with a green checkmark.
 * Each feature item is genereated from the translationStringWithLinebreaks prop.
 *
 * -> usually coming from a translation string with linebreaks
 */
export const FeatureList = ({
  translationStringWithLinebreaks,
  checkIcon,
  isBold = true,
  color = "base",
  fontSize = "lead",
}: Props) => (
  <StyledUL>
    {translationStringWithLinebreaks.split("\n").map((text) => (
      <StyledLI key={text}>
        {checkIcon ?? (
          <DefaultIcon
            size="large"
            mr={1}
          />
        )}
        <FeatureItem
          text={text}
          isBold={isBold}
          color={color}
          fontSize={fontSize}
        />
      </StyledLI>
    ))}
  </StyledUL>
);

const FeatureItem = ({
  text,
  isBold,
  link,
  color,
  fontSize,
}: {
  text: string;
  isBold: boolean;
  color: "base" | "inverted";
  link?: string;
  fontSize?: "lead" | "body" | "small";
}) => (
  <Text
    markdown
    size={fontSize}
    block
    weight={isBold ? "bold" : "base"}
    color={color}
  >
    {link ? (
      <Link
        color="text"
        href={link}
        newTab
      >
        {text}
      </Link>
    ) : (
      text
    )}
  </Text>
);

const StyledUL = styled.ul`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StyledLI = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DefaultIcon = styled(IconCheckmark)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colorPalette.green[500]};
  border-radius: 50%;
  color: #fff;
  padding: 4px;
`;
