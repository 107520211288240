/* eslint-disable comma-dangle */
import { Box, Headline, Center, Container, Text } from "@boligportal/juice";

interface Props {
  headline: string;
  text: string;
  button: JSX.Element;
}

export const Onboarding = ({ headline, text, button }: Props) => (
  <Center
    height="100%"
    pb={8}
    pt={{
      xs: 4,
      lg: 0,
    }}
  >
    <Container>
      <Headline as="h2">{headline}</Headline>
      <Text block>{text}</Text>
      <Box pt={3}>{button}</Box>
    </Container>
  </Center>
);
