import { IconOpenOutline, Notice } from "@boligportal/juice";

export const SourceLinkDisplay = ({ sourceUrl }: { sourceUrl: string }) => {
  const navigateToSource = () => {
    window.open(sourceUrl, "_blank$");
  };
  return (
    <div
      onClick={navigateToSource}
      style={{
        cursor: "pointer",
      }}
    >
      <Notice type="info">
        <IconOpenOutline
          size="medium"
          inline
        />{" "}
        Navigate to source url
      </Notice>
    </div>
  );
};
