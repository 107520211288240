import {
  MenuBaseItem,
  IconEllipsisVertical,
  Menu,
  Button,
} from "@boligportal/juice";

export interface MovingReportElipsisMenuItem
  extends MenuBaseItem<MovingReportElipsisMenuItem> {
  id?: string;
}

export type MovingReportElipsisMenuCell = {
  movingReportElipsisMenuCell: {
    items: MovingReportElipsisMenuItem[];
    onMenuItemSelect: (item: MovingReportElipsisMenuItem) => void;
  };
};

export const movingReportElipsisMenuCellRenderer = ({
  movingReportElipsisMenuCell,
}: MovingReportElipsisMenuCell) => (
  <Menu<MovingReportElipsisMenuItem>
    align="end"
    verticalContentPadding={0}
    verticalSeparatorPadding={0}
    items={movingReportElipsisMenuCell.items}
    onMenuItemSelect={movingReportElipsisMenuCell.onMenuItemSelect}
    openerEl={
      <Button
        variant="subtle"
        icon={IconEllipsisVertical}
      />
    }
  />
);
