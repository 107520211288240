import {
  Button,
  DataTable,
  Flex,
  IconArrowForward,
  Text,
} from "@boligportal/juice";
import { MyRentablesListDataItem } from "apps/property_owner/my_rentables/interfaces/MyRentablesListDataItem";
import { t } from "lib/i18n";
import { commonRowActionsTrans } from "lib/translations";
import {
  useChooseOrCreateRentableFeature,
  useChooseOrCreateRentableState,
} from "../ChooseOrCreateRentableProvider";

export const RentablesDataTable = () => {
  const { chooseRentableRowActionLabel } = commonRowActionsTrans();
  const { rentables } = useChooseOrCreateRentableState();
  const { choseRental } = useChooseOrCreateRentableFeature();

  const choseRentableCallback = (data: MyRentablesListDataItem) => {
    choseRental(data.id);
  };

  if (rentables.length === 0) {
    return (
      <Flex
        borderTop
        justify="center"
        align="center"
        minHeight={20}
      >
        <Text color="muted">
          {t("feature.choose_or_create_rentable.list_empty_state.text")}
        </Text>
      </Flex>
    );
  }

  return (
    <DataTable<MyRentablesListDataItem>
      rowActionsDisplayedOnHover={!("ontouchstart" in document.documentElement)}
      items={rentables}
      keyField="id"
      columns={[
        {
          fieldName: "address",
          fieldLabel: "Adresse",
          cellRenderer: (item) => (
            <Button
              text
              onClick={() => choseRentableCallback(item)}
            >
              {item.address}
            </Button>
          ),
        },
      ]}
      rowActions={[
        {
          label: chooseRentableRowActionLabel,
          callback: choseRentableCallback,
          variant: "subtle",
          iconAfter: IconArrowForward,
        },
      ]}
    />
  );
};
