export const scriptUrl = "https://embed.boligportal.dk/bundle.js";

export const embeddedScriptTemplate = `<div id="bp_embeddable_placeholder"></div>
<script src="${scriptUrl}" defer></script>
<script>
  window.onload = () => {
    if (window.bpe) {
      window.bp_company_key = "COMPANY_KEY";
      window.bpe("bp_embeddable_placeholder");
    }
  };
</script>`;
