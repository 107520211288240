import { t } from "lib/i18n";
import {
  BeforeAfterItemField,
  BeforeAfterItemType,
  TenancyTenantDiff,
  TenantBeforeAfterItemType,
} from "../types";
import { BeforeAfterItem } from "./BeforeAfterItem";

type Props = {
  diff: TenancyTenantDiff[];
  onChange: (item: BeforeAfterItemType, isChecked: boolean) => void;
};

export const CheckableTenancyTenantsDiffView = ({ diff, onChange }: Props) => (
  <>
    {diff.map((item, index) => (
      <TenancyTenantDiffView
        // We cant guarantee a unique key from any of the item fields
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        tenantIndex={index}
        diff={item}
        onChange={onChange}
      />
    ))}
  </>
);

type TenancyTenantDiffViewProps = {
  tenantIndex: number;
  diff: TenancyTenantDiff;
  onChange: (item: BeforeAfterItemType, isChecked: boolean) => void;
};

const TenancyTenantDiffView = ({
  diff,
  tenantIndex,
  onChange,
}: TenancyTenantDiffViewProps) => {
  const { id, ...rest } = diff;
  const entries = Object.entries(rest);
  const items: TenantBeforeAfterItemType[] = [];

  const fieldMap = {
    first_name: t("common.form_fields.first_name.label"),
    last_name: t("common.form_fields.last_name.label"),
    email: t("common.form_fields.email.label"),
    phone: t("common.form_fields.phone_number.label"),
  };

  entries.forEach((entry) => {
    const [field, fieldDiff] = entry;

    items.push({
      field: field as BeforeAfterItemField,
      isChecked: fieldDiff.isChecked,
      index: tenantIndex,
      label: `${fieldMap[field]} ${t("moving_reports.tenant_card.tenant", {
        index: tenantIndex + 1,
      })}`,
      before: fieldDiff.before,
      after: fieldDiff.after,
    });
  });

  return (
    <>
      {items.map((item) => (
        <BeforeAfterItem
          key={item.field}
          item={item}
          onChange={onChange}
        />
      ))}
    </>
  );
};
