/* eslint-disable compat/compat */
import { Filters } from "components/interfaces/filters";
import { addFiltersToQueryParams } from "./addFiltersToQueryParams";

export const getSRPUrlWithFilters = (
  props: {
    currentUrl: string;
    filters: Filters;
    view: "map" | "list";
    offset: number;
    mapCenter: [number, number] | null;
    mapZoom: number | null;
    selectedAds: number[];
  },
  search: string,
) => {
  const { filters, mapCenter, mapZoom, offset, selectedAds, view, currentUrl } =
    props;
  const queryParams = new URLSearchParams(search);

  addFiltersToQueryParams(queryParams, {
    filters,
    mapCenter,
    mapZoom,
    offset,
    selectedAds,
    view,
  });

  const [urlWithoutQueryParams] = currentUrl.split("?");

  return `${urlWithoutQueryParams}?${queryParams.toString()}`;
};
