import { Flex } from "@boligportal/juice";
import { AppStoreBadge } from "media/badges/AppStoreBadge";
import { GooglePlayBadge } from "media/badges/GooglePlayBadge";

export const AppBadges = () => (
  <Flex
    gap
    align="center"
  >
    <AppStoreBadge />
    <GooglePlayBadge />
  </Flex>
);
