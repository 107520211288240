import {
  Button,
  Flex,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
} from "@boligportal/juice";

type Props = {
  title: string;
  children: React.ReactNode;
  cancelLabel: string;
  confirmLabel: string;
  onCloseClick: () => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
  loading: boolean;
  disableConfirmButton: boolean;
};

export const AdActionConfirmation = ({
  loading,
  title,
  onCloseClick,
  onCancelClick,
  onConfirmClick,
  children,
  cancelLabel,
  confirmLabel,
  disableConfirmButton,
}: Props) => (
  <Dialog
    open
    slideOver
    onClose={onCloseClick}
    hideOnClickOutside={false}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogContent>{children}</DialogContent>
    <DialogFooter>
      <Flex gap>
        <Button
          disabled={loading}
          variant="subtle"
          onClick={onCancelClick}
        >
          {cancelLabel}
        </Button>
        <Button
          loading={loading}
          variant="primary"
          onClick={onConfirmClick}
          disabled={disableConfirmButton}
        >
          {confirmLabel}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
