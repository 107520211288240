import * as React from "react";

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 14"
    className="Icon"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M21.477 1.597L20.42.527A1.8 1.8 0 0019.134 0c-.5 0-.923.176-1.271.527L11 7.457 4.137.526A1.722 1.722 0 002.867 0a1.8 1.8 0 00-1.285.528L.537 1.597A1.778 1.778 0 000 2.894c0 .513.179.941.537 1.283l9.192 9.281c.34.361.763.542 1.271.542.499 0 .927-.18 1.285-.542l9.192-9.28c.349-.352.523-.78.523-1.284 0-.494-.174-.926-.523-1.297z"
    />
  </svg>
);
export default SvgArrowDown;
