import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Divider,
  Flex,
  Hidden,
  Spinner,
  styled,
  Text,
} from "@boligportal/juice";
import { TextProps } from "@boligportal/juice/dist/components/Text/Text";
import { useWindowWidth } from "components/empty_screens/FeaturesDisplayScreen";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../types";
import { SelectRentableListItem } from "./SelectRentableListItem";
import { ShouldCreateAllCheckField } from "./ShouldCreateAllCheckField";

const Grid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: min-content minmax(30%, auto) 140px 15% 15%;
  grid-column-gap: ${(props) => props.theme.unit(3)};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;

    fieldset > :nth-child(5n + 1):not(:first-child) {
      border-top: ${(props) =>
        `${props.theme.borderWidth.sm} solid ${props.theme.color.border}`};
      padding-top: ${(props) => props.theme.unit(2)};
    }

    // Checkfield
    fieldset > :nth-child(5n + 1) {
      grid-column: span 3;
    }
    fieldset:not(:nth-of-type(1)) > :nth-child(5n + 1) {
      // Border between each rentable item
      ${(props) => `
        border-top: ${props.theme.borderWidth.sm} solid ${
          props.theme.color.border
        };
        padding-top: ${props.theme.unit(2)};
      `}
    }

    // Address field
    fieldset > :nth-child(5n + 2) {
      grid-column: span 3;
      margin: ${(props) => props.theme.unit(1)} 0;
      font-weight: bold;
    }

    // Category, Size and Number of Rooms field
    fieldset > :nth-child(5n + 3),
    :nth-child(5n + 4),
    :nth-child(5n + 5) {
      grid-column: span 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;

    // All fields
    fieldset > :nth-child(1n) {
      grid-column: span 1;
    }

    // Address field
    fieldset > :nth-child(5n + 2) {
      margin: ${(props) => props.theme.unit(1)} 0;
      font-weight: bold;
    }
  }
`;

const StyledShouldCreateAllCheckField = styled(ShouldCreateAllCheckField)`
  position: relative;
  top: 2px;
`;

const StyledDivider = styled(Divider)`
  grid-column: span 5;
  width: 100%;
`;

// Needs to be full width to make the border span the entire grid
const FullWidthFlexOnTablet = styled(Flex)`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-column: span 3;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-column: span 1;
  }
`;

export const SelectRentableList = React.memo(() => {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<CreateRentableForm>();
  const width = useWindowWidth();

  const { fields, replace } = useFieldArray({
    control,
    name: "rentables",
  });

  const handleCreateAllToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { rentables } = getValues();

    replace(
      rentables.map((rentable) => ({
        ...rentable,
        shouldCreate: event.currentTarget.checked || rentable.disabled,
      })),
    );
  };

  if (fields.length === 0) {
    return (
      <Flex
        align="center"
        justify="center"
        height={30}
      >
        <Spinner size="large" />
      </Flex>
    );
  }

  return (
    <>
      <Grid>
        <FullWidthFlexOnTablet
          align="center"
          borderBottom={{
            xs: true,
            lg: false,
          }}
          pb={{
            xs: 2,
            lg: 0,
          }}
          mb={{
            xs: 2,
            lg: 0,
          }}
        >
          <StyledShouldCreateAllCheckField
            control={control}
            onChange={handleCreateAllToggle}
          />
          <Hidden
            on={{
              lg: true,
            }}
          >
            <GridHeader ml={1}>
              {t("feature.address_form.rentable_list.create_all")}
            </GridHeader>
          </Hidden>
        </FullWidthFlexOnTablet>

        {width > 992 && (
          <>
            <GridHeader>{t("common.address")}</GridHeader>
            <GridHeader>{t("create.field.category.heading")}</GridHeader>
            <GridHeader>{t("create.field.size_m2.heading")}</GridHeader>
            <GridHeader>{t("create.field.rooms.heading")}</GridHeader>

            <StyledDivider my={2} />
          </>
        )}

        {fields.map((field, index) => (
          <SelectRentableListItem
            key={field.id}
            index={index}
            disabled={field.disabled}
            address={field.address}
            register={register}
            errors={errors}
            getValues={getValues}
            trigger={trigger}
            setValue={setValue}
          />
        ))}
      </Grid>
    </>
  );
});

const GridHeader = ({
  children,
  ...props
}: { children: React.ReactNode } & TextProps) => (
  <Text
    size="body"
    color="base"
    weight="bold"
    {...props}
  >
    {children}
  </Text>
);
