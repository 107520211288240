export const LocationIcon = () => (
  <svg
    width="12"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8.56c.373 0 .72-.093 1.04-.28.32-.187.573-.437.76-.75.187-.313.28-.657.28-1.03s-.093-.72-.28-1.04a2.077 2.077 0 0 0-.76-.76A2.028 2.028 0 0 0 6 4.42c-.373 0-.717.093-1.03.28-.313.187-.563.44-.75.76-.187.32-.28.667-.28 1.04 0 .573.2 1.06.6 1.46.4.4.887.6 1.46.6zM6 .68a5.74 5.74 0 0 1 2.94.78 5.87 5.87 0 0 1 2.1 2.1c.52.893.78 1.873.78 2.94 0 .813-.2 1.747-.6 2.8-.347.893-.827 1.86-1.44 2.9a38.256 38.256 0 0 1-1.74 2.58c-.453.627-.933 1.24-1.44 1.84l-.6.7-.6-.7c-.507-.6-.987-1.213-1.44-1.84a38.256 38.256 0 0 1-1.74-2.58c-.613-1.04-1.093-2.007-1.44-2.9-.4-1.053-.6-1.987-.6-2.8 0-1.067.26-2.047.78-2.94a5.87 5.87 0 0 1 2.1-2.1A5.74 5.74 0 0 1 6 .68z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
