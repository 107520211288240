import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { awaitTimeout } from "lib/timeout";
import { Tracking } from "lib/tracking/common";

const trackUserAddsAmountToPayment = (addedOrDeducedAmountInMoney: number) => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label:
      "tenancy - rent tab / tenancy - overview tab / rentable - rent tab / rent details slideover / rent sidebar",
    action: "add amount to payment",
    isInteraction: true,
    value: addedOrDeducedAmountInMoney.toString(),
  });
};

const trackUserCompletesRentSetupStartedFromRentOverview = async (
  numberOfPayments: number,
) => {
  Tracking.trackEvent({
    category: "rent setup",
    label: "started from rent overview - rentable",
    action: "setup completed and saved",
    isInteraction: true,
    value: numberOfPayments.toString(),
  });

  await awaitTimeout(500);
  return Promise.resolve(true);
};

const trackUserCompletesRentSetupStartedFromTenancy = async (
  numberOfPayments: number,
) => {
  Tracking.trackEvent({
    category: "rent setup",
    label: "started from tenancy",
    action: "setup completed and saved",
    isInteraction: true,
    value: numberOfPayments.toString(),
  });

  await awaitTimeout(500);
  return Promise.resolve(true);
};

const trackUserCreatedTenancyFromRentableTenancyWidget = async (
  numberOfTenants: number,
) => {
  Tracking.trackEvent({
    category: "tenancy",
    label: "widget on rentable",
    action: "tenancy created",
    isInteraction: true,
    value: numberOfTenants.toString(),
  });

  await awaitTimeout(500);
  return Promise.resolve(true);
};

const trackUserCreatedTenancyFromTenancyTab = async (
  numberOfTenants: number,
) => {
  Tracking.trackEvent({
    category: "tenancy",
    label: "tenancy tab",
    action: "tenancy created",
    isInteraction: true,
    value: numberOfTenants.toString(),
  });

  await awaitTimeout(500);
  return Promise.resolve(true);
};

const trackUserNavigatesToRentTabForTenancyFromPaymentDetailsSlideover = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "payment details slideover",
    action: "navigate to tenancy - rent tab ",
    isInteraction: true,
  });
};

const trackUserOpensAddAmountToPaymentDialog = () => {
  Tracking.trackVirtualPageview("/rent-add-amount-payment");
};

const trackUserOpensRentRegulationDialog = () => {
  Tracking.trackVirtualPageview("/rent-regulation");
};

const trackUserRegulatesRentForTenancy = (regulatedAmountInMoney: number) => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "tenancy - rent tab",
    action: "rent regulated",
    isInteraction: true,
    value: regulatedAmountInMoney.toString(),
  });
};

const trackUserStopsRentCollectionForTenancy = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "tenancy - rent tab",
    action: "stop collection",
    isInteraction: true,
  });
};

const trackUserUsesDropdownToLookFurtherAheadInThePaymentsLists = (
  numMonths: number,
) => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "tenancy - rent tab / rentable - rent tab",
    action: "see payments further in future",
    isInteraction: true,
    value: numMonths.toString(),
  });
};

const trackUserOpensStopRentcollectionDialog = () => {
  Tracking.trackVirtualPageview("/rent-stop-collection");
};

const trackUserClicksConnectMobilePay = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "connection started",
    action: "connect mobilepay",
    isInteraction: true,
  });
};

const trackUserSuccessfullyConnectedToMobilePay = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "connection successfull",
    action: "connect mobilepay",
    isInteraction: true,
  });
};
const trackUserUnSuccessfullyConnectedToMobilePay = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "connection failed - no subscription product",
    action: "connect mobilepay",
    isInteraction: true,
  });
};

const trackUserCreateMobilePayAccountClick = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "create user",
    action: "connect mobilepay",
    isInteraction: true,
  });
};

const trackUserRemovesMobilePayConnection = () => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: "",
    action: "remove mobilepay connection",
    isInteraction: true,
  });
};

const trackUserCompletesRentSetup = async (
  paymentProviderSelected: TenancyPaymentType,
) => {
  Tracking.trackEvent({
    category: "rent collection tenancy",
    label: paymentProviderSelected,
    action: "setup rent successful",
    isInteraction: true,
    value: paymentProviderSelected,
  });

  await awaitTimeout(500);
  return Promise.resolve(true);
};

export const RentableTenancyTracking = {
  trackUserAddsAmountToPayment,
  trackUserCompletesRentSetupStartedFromRentOverview,
  trackUserCompletesRentSetupStartedFromTenancy,
  trackUserCreatedTenancyFromRentableTenancyWidget,
  trackUserCreatedTenancyFromTenancyTab,
  trackUserNavigatesToRentTabForTenancyFromPaymentDetailsSlideover,
  trackUserOpensAddAmountToPaymentDialog,
  trackUserOpensRentRegulationDialog,
  trackUserRegulatesRentForTenancy,
  trackUserStopsRentCollectionForTenancy,
  trackUserUsesDropdownToLookFurtherAheadInThePaymentsLists,
  trackUserOpensStopRentcollectionDialog,
  trackUserClicksConnectMobilePay,
  trackUserSuccessfullyConnectedToMobilePay,
  trackUserCreateMobilePayAccountClick,
  trackUserRemovesMobilePayConnection,
  trackUserCompletesRentSetup,
  trackUserUnSuccessfullyConnectedToMobilePay,
};
