import { Card } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { MapLocationManager } from "features/cs/map_location_manager_feature/components/map_location_manager";

type NewBuildProjectLocationProps = {
  newBuild: Ad;
};

export const NewBuildProjectLocation = ({
  newBuild,
}: NewBuildProjectLocationProps) => {
  return (
    <Card sectioned>
      <MapLocationManager adId={newBuild.id} />
    </Card>
  );
};
