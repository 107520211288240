/* eslint-disable comma-dangle */

/* eslint-disable react/display-name */
import { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Text,
  Card,
  CardSection,
  CheckField,
  Button,
  Spinner,
  Box,
  Dialog,
  DialogFooter,
  DialogContent,
  DialogHeader,
  TextField,
  Flex,
  useNotifications,
  Confirm,
  DataTable,
} from "@boligportal/juice";
import { App } from "components/app";
import { format } from "date-fns";
import { AccountInfo } from "lib/api";
import {
  AccountSettingsUpdate,
  AccountTransaction,
  getCompanyAccount,
  getCompanyAccountTransactions,
  makeDepositToCompanyAccount,
  setCompanyAccountSettings,
} from "lib/customerServiceApi";
import { formatCurrency } from "lib/utils";
import { Company } from "./interfaces";
import { ResetBalanceConfirmationDialog } from "./reset_balance_comfirmation";

type MakeDepositForm = {
  amount: string;
  salesForceID: string;
};

const MakeDepositDialog = (props: {
  company: Company;
  account: AccountInfo;
  makeDeposit: (deposit: MakeDepositForm) => Promise<{ error?: string }>;
  closeDialog: () => void;
}) => {
  const { company, account, makeDeposit, closeDialog } = props;
  const { addNotification } = useNotifications();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    register,
    formState,
    watch,
    trigger: triggerValidation,
    getValues,
  } = useForm<MakeDepositForm>({
    defaultValues: {
      amount: "",
      salesForceID: "",
    },
  });

  const { errors } = formState;

  const [transactions, setTransactions] = useState<
    AccountTransaction[] | undefined
  >(undefined);

  const loadTransactions = useCallback(async (companyId: number) => {
    const result = await getCompanyAccountTransactions(companyId);
    const deposits = result.transactions.filter(
      (transaction) => transaction.type == "DEPOSIT",
    );
    setTransactions(deposits);
  }, []);

  useEffect(() => {
    loadTransactions(company.id);
  }, [company.id, loadTransactions]);

  const watchAmount = watch("amount");

  const onSubmit = async (values: MakeDepositForm) => {
    const result = await makeDeposit(values);
    if (addNotification) {
      if (!result.error) {
        addNotification({
          title: "Deposit successful",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        closeDialog();
      } else {
        addNotification({
          title: `Error: ${result.error}`,
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    }
  };

  return (
    <>
      <DialogHeader>
        Make deposit to account for company: {company.name}
      </DialogHeader>
      <DialogContent>
        <TextField
          {...register("amount", {
            required: {
              value: true,
              message: "Required",
            },
            min: {
              value: 0,
              message: "Must be greater than 0",
            },
          })}
          label="Amount"
          type="number"
          required
          append={App.settings.currency_symbol}
          errorText={
            errors.amount !== undefined
              ? (errors.amount.message as string)
              : undefined
          }
        />
        <TextField
          {...register("salesForceID", {
            required: {
              value: true,
              message: "Required",
            },
          })}
          label="SalesForce ID"
          required
          errorText={
            errors.salesForceID !== undefined
              ? (errors.salesForceID.message as string)
              : undefined
          }
        />
        <Card sectioned>
          <CardSection title="Deposit History">
            {transactions === undefined && <Spinner />}
            {transactions !== undefined && (
              <DataTable
                keyField="id"
                items={transactions}
                columns={[
                  {
                    fieldLabel: "Date",
                    fieldName: "date",
                    cellRenderer: (item) => (
                      <Text size="tiny">
                        {format(new Date(item.date), "yyyy-MM-dd HH:mm:ss")}
                      </Text>
                    ),
                  },
                  {
                    fieldLabel: "Amount",
                    fieldName: "amount",
                    alignRight: true,
                    cellRenderer: (item: AccountTransaction) =>
                      formatCurrency(item.amount, App.settings.currency),
                  },
                  {
                    fieldLabel: "User",
                    fieldName: "user",
                    cellRenderer: (item: AccountTransaction) => (
                      <>
                        {item.user.first_name} {item.user.last_name}{" "}
                        <Text size="tiny">({item.user.username})</Text>
                      </>
                    ),
                  },
                ]}
              />
            )}
          </CardSection>
        </Card>
      </DialogContent>
      <DialogFooter>
        <Flex
          gap
          width="100%"
        >
          <Flex column>
            <Box>
              Previous balance:{" "}
              {formatCurrency(account.balance, App.settings.currency)}
            </Box>
            <Box>
              <Text weight={"bold"}>
                New balance:{" "}
                {formatCurrency(
                  account.balance +
                    (watchAmount ? Number.parseFloat(watchAmount) : 0),
                  App.settings.currency,
                )}
              </Text>
            </Box>
          </Flex>
          <Flex flexPush>
            {showConfirm && (
              <Confirm
                size="large"
                title="Confirm deposit"
                confirmText="Confirm"
                cancelText="Cancel"
                onCancel={() => setShowConfirm(false)}
                message={
                  <>
                    You are about to deposit{" "}
                    <Text weight="bold">
                      {formatCurrency(
                        Number.parseFloat(watchAmount),
                        App.settings.currency,
                      )}
                    </Text>{" "}
                    to <Text weight="bold">{company.name}</Text>&apos;s account.
                  </>
                }
                onConfirm={async () => {
                  if (isSubmitting) {
                    return;
                  }
                  setIsSubmitting(true);
                  try {
                    await onSubmit(getValues());
                  } finally {
                    setIsSubmitting(false);
                  }
                  setShowConfirm(false);
                }}
                open={showConfirm}
              />
            )}
            <Button
              variant="primary"
              onClick={async () => {
                const isValid = await triggerValidation();

                if (isValid) {
                  setShowConfirm(true);
                }
              }}
            >
              Make deposit
            </Button>
          </Flex>
        </Flex>
      </DialogFooter>
    </>
  );
};

const AccountWidget = (props: {
  company: Company;
  account: AccountInfo | null | undefined;
  setAccountSettings: (settings: AccountSettingsUpdate) => void;
  makeDeposit: (deposit: MakeDepositForm) => Promise<{ error?: string }>;
}) => {
  const { company, account, setAccountSettings, makeDeposit } = props;
  const [showMakeDeposit, setShowMakeDeposit] = useState(false);

  return (
    <>
      {account === undefined && <Spinner size={"medium"} />}
      {account !== undefined && (
        <>
          <CheckField
            label={"Enabled"}
            checked={account !== null && account.enabled}
            onChange={(event) =>
              setAccountSettings({
                enabled: event.target.checked,
              })
            }
          />
          {account !== null && (
            <>
              <CheckField
                label={"Allow negative balance"}
                checked={account.allow_negative_balance}
                onChange={(event) =>
                  setAccountSettings({
                    allowNegativeBalance: event.target.checked,
                  })
                }
              />
              <Box pb={2}>
                Balance:{" "}
                <Text weight={"bold"}>
                  {formatCurrency(account.balance, App.settings.currency)}
                </Text>
              </Box>
              <Flex
                gap
                wrap
              >
                <Button onClick={() => setShowMakeDeposit(true)}>
                  Make deposit
                </Button>

                <ResetBalanceConfirmationDialog companyId={company.id} />

                {showMakeDeposit && (
                  <Dialog
                    slideOver
                    open={showMakeDeposit}
                    onClose={() => setShowMakeDeposit(false)}
                  >
                    <MakeDepositDialog
                      account={account}
                      makeDeposit={makeDeposit}
                      company={company}
                      closeDialog={() => setShowMakeDeposit(false)}
                    />
                  </Dialog>
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};

export const CompanyAccountPanel = (props: {
  company: Company;
  onAccountChanged: () => void;
}) => {
  const { company } = props;
  const [account, setAccount] = useState<AccountInfo | null | undefined>(
    undefined,
  );

  const setAccountSettings = useCallback(
    (settings: AccountSettingsUpdate) => {
      setCompanyAccountSettings(company.id, settings).then((response) => {
        setAccount(response.account);
        props.onAccountChanged();
      });
    },
    [company.id],
  );

  const makeDeposit = useCallback(
    async (deposit: MakeDepositForm) => {
      const result = await makeDepositToCompanyAccount(company.id, deposit);
      props.onAccountChanged();
      setAccount(result.account);
      return {
        error: result.error,
      };
    },
    [company.id],
  );

  const loadAccount = useCallback(() => {
    getCompanyAccount(company.id).then((response) => {
      setAccount(response.account);
    });
  }, [company.id]);

  useEffect(() => {
    loadAccount();
  }, [company.id, loadAccount]);

  return (
    <Card sectioned>
      <CardSection title="Account">
        <AccountWidget
          company={company}
          account={account}
          setAccountSettings={setAccountSettings}
          makeDeposit={makeDeposit}
        />
      </CardSection>
    </Card>
  );
};
