import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { PaymentFlowCoordinatorProps } from "features/paywall/PaywallProvider";
import { QuickpayPaymentFlow } from "./QuickpayPaymentFlow";

export const QuickpayPaymentFlowCoordinator = ({
  paywallCandidate,
  registeredPaywalls,
  onClose,
}: PaymentFlowCoordinatorProps) => {
  if (
    !paywallCandidate ||
    paywallCandidate.paymentMethodType !== PaymentMethodType.QuickPay
  ) {
    return null;
  }

  const registeredPaywall = registeredPaywalls.find(
    (item) => item.uniquePaywallKey === paywallCandidate.uniquePaywallKey,
  );

  if (!registeredPaywall) {
    throw new Error(
      "Then paywall paywallCandidate's uniquePaywallKey does not match any of the registered paywall uniquePaywallKey",
    );
  }

  const { onSubscriptionActivated, onPaymentSuccessContinueButtonClick } =
    registeredPaywall;

  return (
    <QuickpayPaymentFlow
      adId={paywallCandidate.adId}
      plans={[paywallCandidate.plan]}
      title={paywallCandidate.title}
      onClose={onClose}
      onSubscriptionActivated={() => {
        onSubscriptionActivated?.();
      }}
      onPurchaseComplete={(plan) => {
        onPaymentSuccessContinueButtonClick(plan);
        onClose();
      }}
    />
  );
};
