import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { SelectField, Text } from "@boligportal/juice";
import { RentableEnergyLabelProvider } from "features/rentable/useRentableEnergyLabelRetriever";
import { DomutechSource } from "leads/Domutech/DomuTechDialog";
import { DomutechNoticeWithDialog } from "leads/Domutech/DomutechNoticeWithDialog";
import { t } from "lib/i18n";

const RentalEnergyLabel = () => {
  const { register } = useFormContext();
  return (
    <>
      <SelectField
        mb={0}
        label={t("create.field.energy_rating.heading")}
        {...register("energy_rating")}
        items={[
          // empty string gets converted to null before submit
          {
            value: "",
            label: t("ad_form.choose_energy_rating_label"),
          },
          {
            value: "A2020",
            label: "A2020",
          },
          {
            value: "A2015",
            label: "A2015",
          },
          {
            value: "A2010",
            label: "A2010",
          },
          {
            value: "B",
            label: "B",
          },
          {
            value: "C",
            label: "C",
          },
          {
            value: "D",
            label: "D",
          },
          {
            value: "E",
            label: "E",
          },
          {
            value: "F",
            label: "F",
          },
          {
            value: "G",
            label: "G",
          },
        ]}
      />
      <Text
        size="tiny"
        color="muted"
      >
        {t("create.field.energy_rating.fetched_automatically")}
      </Text>
    </>
  );
};

type Props = {
  rentableId: number;
  rentableAddress: string;
  source: DomutechSource;
};

const RentalEnergyLabelWithDomutechNotice = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { rentableAddress, rentableId, source } = props;
    return (
      <div ref={ref}>
        <RentalEnergyLabel />
        <RentableEnergyLabelProvider>
          <DomutechNoticeWithDialog
            rentableAddress={rentableAddress}
            rentableId={rentableId}
            source={source}
          />
        </RentableEnergyLabelProvider>
      </div>
    );
  },
);

export { RentalEnergyLabel, RentalEnergyLabelWithDomutechNotice };
