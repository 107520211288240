import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Divider, Labelled } from "@boligportal/juice";
import { vestResolver } from "@hookform/resolvers/vest";
import { useCreateRentableMutation } from "api/mutations";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { TinyTextButton } from "features/eco_system/components/dialog/common/TinyTextButton";
import { formatAddressWithExtraText } from "features/eco_system/utils/formatAddressWithExtraText";
import { t } from "lib/i18n";
import { CreateRentableForm, RentableCreationCandidate } from "../../types";
import { validationSuite } from "../../utils/validationSuite";
import { AddressSearch } from "../AddressSearch/AddressSearch";
import { AddressSearchCandidate } from "../AddressSearch/AddressSearchItemRenderer";
import { CardWithNoNegativeMargin } from "../CommonStyled";
import { CreateSingleRentable } from "../CreateSingleRentable";
import { BulkCreateButton } from "./BulkCreateButton";
import { BulkCreateForm } from "./BulkCreateForm";
import { CanOnlyCreateRoomsOnSameAddressNotice } from "./CanOnlyCreateRoomsOnSameAddressNotice";
import {
  getRentableCreationCandidatesDK,
  getRentableCreationCandidatesSE,
} from "./getRentableCreationCandidates";
import { getShouldCreateRentableWithEstate } from "./getShouldCreateRentableWithEstate";

type Props = {
  onClickManualAddress: () => void;
  onRentablesCreated: (rentableIds: number[]) => void;
};

export const BulkCreate = ({
  onClickManualAddress,
  onRentablesCreated,
}: Props) => {
  const { mutateAsync } = useCreateRentableMutation();

  const queryClient = useQueryClient();
  const formMethods = useForm<CreateRentableForm>({
    mode: "onChange",
    resolver: vestResolver(validationSuite),
    defaultValues: {
      rentables: [],
      isLoading: false,
    },
  });

  const address = formMethods.watch("address");

  const onAddressSelected = async (item: AddressSearchCandidate) => {
    if (item.type !== "address" && item.type !== "bulk_create") {
      return;
    }
    formMethods.reset({
      address: item,
      rentables: [],
      isLoading: true,
    });

    let rentables: RentableCreationCandidate[] = [];

    if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      rentables = getRentableCreationCandidatesSE(item);
    } else {
      rentables = await getRentableCreationCandidatesDK(
        item,
        item.address,
        queryClient,
      );
    }

    formMethods.setValue("rentables", rentables);
    formMethods.setValue("isLoading", false);
  };

  const onSubmit: SubmitHandler<CreateRentableForm> = async (data) => {
    const shouldCreateRentableWithEstate = getShouldCreateRentableWithEstate(
      data.rentables,
    );

    const createdRentables = await Promise.allSettled(
      data.rentables.map((rentable, index) => {
        // If it has a rentableId it has already been created
        if (
          !rentable.shouldCreate ||
          rentable.disabled ||
          rentable.rentableId
        ) {
          return Promise.resolve();
        }

        return mutateAsync({
          address: rentable.address,
          // Validation will fail if category, room_count or size_m2 is missing.
          category: rentable.category!,
          room_count: rentable.roomCount!,
          size_m2: rentable.sizeM2!,
          apartment_number: rentable.apartmentNumber || null,
          estate: shouldCreateRentableWithEstate ? rentable.estate : null,
        }).then(({ rentable_id }) => {
          formMethods.setValue(`rentables.${index}` as const, {
            ...rentable,
            // @ts-ignore // RHF doesn't seem to have proper typings for setValue when working with arrays.
            rentableId: rentable_id,
          });
        });
      }),
    );

    // If all requests succeeded, invoke the callback
    if (createdRentables.every((request) => request.status === "fulfilled")) {
      handleRentablesCreated();
    }
  };

  const handleRentablesCreated = () => {
    const { rentables } = formMethods.getValues();

    // We only want to trigger the callback with rentables that were successfully created
    const rentableIds: number[] = [];
    rentables.forEach((rentable) => {
      if (rentable.rentableId) {
        rentableIds.push(rentable.rentableId);
      }
    });

    onRentablesCreated(rentableIds);
  };

  const resetForm = () => {
    formMethods.reset({
      rentables: [],
      address: undefined,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <CardWithNoNegativeMargin>
        {address?.type === "address" && (
          <CanOnlyCreateRoomsOnSameAddressNotice address={address?.address} />
        )}

        <Labelled label={t("common.address")}>
          <AddressSearch
            onAddress={onAddressSelected}
            onSearchValueChange={resetForm}
          />

          <TinyTextButton
            onClick={onClickManualAddress}
            text={t("feature.address_form.goto_manual_form_button.text")}
          />
        </Labelled>

        {address?.type === "address" && (
          <>
            <Divider my={4} />
            <CreateSingleRentable
              address={formatAddressWithExtraText(
                address.address,
                address.extraText,
              )}
            />
          </>
        )}
      </CardWithNoNegativeMargin>

      {address?.type === "bulk_create" && <BulkCreateForm />}

      <BulkCreateButton
        onClick={formMethods.handleSubmit(onSubmit)}
        onContinue={handleRentablesCreated}
        disabled={!address}
      />
    </FormProvider>
  );
};
