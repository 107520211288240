import { Badge, Text } from "@boligportal/juice";
import { SubscriptionFeedItemInterface } from "../interfaces";

interface Props {
  subscription: SubscriptionFeedItemInterface;
}

export const SubscriptionStatusBadge = ({ subscription }: Props) => {
  let status = "";
  let color: "red" | "green" | "blue" | "yellow" = "red";

  switch (subscription.temporal_status) {
    case "EXPIRED":
      if (
        subscription.activation_date === null &&
        subscription.ended_at === null
      ) {
        // Hack: Upsell subscriptions that are not yet active incorrectly get temporal status "EXPIRED",
        // so we handle that here.
        // The real fix is to use the same structure as the AdSubscription interface and change the backend to serialize
        // using that structure as well. We want to get CS shipped now, hence this hack.
        status = "Not yet active";
      } else {
        status = "Expired";
      }
      color = "red";
      break;
    case "IN_GRACE_PERIOD":
      status = "In grace period";
      color = "blue";
      break;
    case "IN_PERIOD":
      if (subscription.canceled_at && subscription.can_be_terminated) {
        status = "Cancelled";
        color = "yellow";
        break;
      }
      status = "Active";
      color = "green";
      break;
    default:
      status = "";
  }

  return (
    <Text
      mb={2}
      size="small"
    >
      <Badge color={color}>{status}</Badge>
      {!subscription.is_subscription && <Badge>Fixed</Badge>}
    </Text>
  );
};
