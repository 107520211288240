import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  open: boolean;
  onConfirm: () => void;
  message: string;
};

export const PaymentRecordChangeStatusNotPossible = ({
  open,
  onConfirm,
  message,
}: Props) => (
  <Dialog
    open={open}
    size="large"
    onClose={onConfirm}
  >
    <DialogHeader>
      {t("payment.status_change_not_possible.dialog.title")}
    </DialogHeader>
    <DialogContent>
      <Text
        block
        markdown
      >
        {message}
      </Text>
    </DialogContent>

    <DialogFooter>
      <Flex gap>
        <Button
          variant="primary"
          onClick={onConfirm}
        >
          {t("Ok")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
