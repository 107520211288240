/* eslint-disable react/display-name */
import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  DataTable,
  Flex,
  Text,
  TextField,
  Container,
  Box,
  Labelled,
} from "@boligportal/juice";
import { DropdownMultiple } from "apps/customer_service/components/dropdown/DropdownMultiple";
import { App } from "components/app";
import { Option } from "components/interfaces/option";
import { modalActions } from "components/modal_controller";
import { format } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import {
  getCustomerServiceAgents,
  getRefundsFeed,
} from "lib/customerServiceApi";
import { blacklistReasonEnum } from "lib/enums";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { ListPaginator } from "../ads_page/list_paginator";
import { RefundReasonsEnum } from "../user_detail_page/subscriptions_feed/enums";
import { UserCell } from "../users_page/cell_renderers/UserCell";
import { RefundDetailsModal } from "./components/refund_details_modal";
import { createRefundsTableItems } from "./helpers";

export interface IRefundsTableItem {
  user: {
    fullname: string;
    username: string;
    id: number | null;
    user_ban_reasons: blacklistReasonEnum[];
  };
  agent: {
    username: string;
  };
  amount: string;
  reason: string;
  zendeskTicketId: string;
  orderId: string;
  date: string;
  note: string;
}

interface IFilterState {
  username: string;
  orderId: string;
  zendeskTicketId: string;
  dateFrom: string | null;
  dateTo: string | null;
  amountMin: string;
  amountMax: string;
  reasons: string[];
  agents: number[];
}

const AgentCell = (props: { username: string }) => (
  <Text size="tiny">{props.username}</Text>
);

const AmountCell = (props: { amount: string }) => (
  <Text size="tiny">{`${props.amount} ${App.settings.currency_symbol}`}</Text>
);

const OrderCell = (props: { orderId: string }) => (
  <Text size="tiny">{props.orderId}</Text>
);
const ReasonCell = (props: { reason: string }) => (
  <Text size="tiny">{props.reason}</Text>
);

const ZendeskCell = (props: { zendeskTicketId: string }) => (
  <Text size="tiny">#{props.zendeskTicketId}</Text>
);

const DateCell = (props: { date: string }) => (
  <Text size="tiny">{format(new Date(props.date), "yyyy-MM-dd HH:mm:ss")}</Text>
);

const RefundsPage = () => {
  const { items, setItems, count, setCount, limit, offset, setOffset } =
    usePagination<IRefundsTableItem>();

  const modal = React.useContext(modalActions);
  const closeHandler = () => modal.closeModal();

  const location = useLocation();

  // Pull initial state from url params
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const userId = searchParams.get("uid") || "";

  const [agents, setAgents] = React.useState<Option[]>([]);
  const [filters, setFilters] = React.useState<IFilterState>({
    username: userId,
    orderId: "",
    zendeskTicketId: "",
    dateFrom: null,
    dateTo: null,
    amountMin: "",
    amountMax: "",
    reasons: [],
    agents: [],
  });
  const prevFilters = usePrevious(filters);

  React.useEffect(() => {
    getCustomerServiceAgents()
      .then((response) => {
        const options: Option[] = [];
        response.results.forEach((item) => {
          options.push({
            id: item.agent_id,
            name: item.email,
          });
        });
        setAgents(options);
      })
      .catch(() => {
        // error.name -> AbortError
        // The operation was aborted
      });
  }, []);

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (prevFilters !== filters && offset !== 0) {
      // Clear offset on filters changed
      setOffset(0);
      return;
    }
    if (scrollContainerRef.current) {
      // Scroll to top
      scrollContainerRef.current.scrollTop = 0;
    }
    const abortController = new AbortController();
    const { signal } = abortController;
    getRefundsFeed({
      signal,
      filters: {
        ...filters,
      },
      offset: offset,
      limit: limit,
    })
      .then((response) => {
        setCount(response.count);
        // Construct Table Items
        const items: IRefundsTableItem[] = createRefundsTableItems(
          response.results,
        );

        setItems(items);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [filters, offset, limit]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const { type } = event.target;
    let value: string | null = event.target.value; //eslint-disable-line

    // Backend expect null for "empty date values"
    if (type === "date" && value === "") {
      value = null;
    }

    const newFilterState: IFilterState = {
      ...filters,
      [field]: value,
    };

    setFilters(newFilterState);
  };

  const handleReasonsSelectionChange = (value: Option[]) => {
    const reasonsValue = value.map((option) => option.id.toString());
    setFilters({
      ...filters,
      reasons: reasonsValue,
    });
  };

  const handleAgentSelectionChange = (value: Option[]) => {
    const agentsValue = value.map((option) => Number(option.id));
    setFilters({
      ...filters,
      agents: agentsValue,
    });
  };
  return (
    <Flex
      column
      width="100%"
    >
      <Flex
        bg="tint"
        borderBottom
      >
        <Container fluid>
          <Flex
            gap
            align="end"
            mt={2}
          >
            <TextField
              label="User"
              value={filters.username}
              id="username"
              onChange={handleFilterChange}
            />
            <Labelled label="Agents">
              <DropdownMultiple
                title="All Agents"
                multiple
                options={agents}
                onSelectionChange={handleAgentSelectionChange}
              />
            </Labelled>
            <TextField
              label="Min. amount"
              value={filters.amountMin}
              type="number"
              id="amountMin"
              onChange={handleFilterChange}
            />
            <TextField
              label="Max. amount"
              value={filters.amountMax}
              type="number"
              id="amountMax"
              onChange={handleFilterChange}
            />

            <TextField
              label="Order ID"
              value={filters.orderId}
              type="text"
              id="orderId"
              onChange={handleFilterChange}
            />
            <Labelled label="Reasons">
              <DropdownMultiple
                title="All Reasons"
                multiple
                options={[
                  {
                    id: RefundReasonsEnum.CustomerValue,
                    name: "CustomerValue",
                  },
                  {
                    id: RefundReasonsEnum.DiscountForCustomer,
                    name: "DiscountForCustomer",
                  },
                  {
                    id: RefundReasonsEnum.Duplicate,
                    name: "Duplicate",
                  },
                  {
                    id: RefundReasonsEnum.Error40,
                    name: "Error40",
                  },
                  {
                    id: RefundReasonsEnum.Exception,
                    name: "Exception",
                  },
                  {
                    id: RefundReasonsEnum.InternalError,
                    name: "InternalError",
                  },
                  {
                    id: RefundReasonsEnum.TechnicalError,
                    name: "TechnicalError",
                  },
                ]}
                onSelectionChange={handleReasonsSelectionChange}
              />
            </Labelled>
            <TextField
              label="Zendesk Ticket ID"
              value={filters.zendeskTicketId}
              type="text"
              id="zendeskTicketId"
              onChange={handleFilterChange}
            />

            <TextField
              label="Date From"
              type="date"
              value={filters.dateFrom || ""}
              id="dateFrom"
              onChange={handleFilterChange}
            />
            <TextField
              label="Date To"
              type="date"
              value={filters.dateTo || ""}
              id="dateTo"
              onChange={handleFilterChange}
            />
          </Flex>
        </Container>
      </Flex>

      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<IRefundsTableItem>
            fullscreen
            items={items}
            keyField="orderId"
            rowActions={[
              {
                label: "View",
                callback: (item) => {
                  modal.showModal(
                    <RefundDetailsModal
                      closeHandler={closeHandler}
                      amount={item.amount}
                      date={item.date}
                      zendesk_ticket_id={item.zendeskTicketId}
                      username={item.user.username}
                      agent={item.agent.username}
                      fullname={item.user.fullname}
                      reason={item.reason}
                      user_id={Number(item.user.id)}
                      note={item.note}
                    />,
                  );
                },
              },
            ]}
            columns={[
              {
                fieldName: "user",
                fieldLabel: "User",
                cellRenderer: (item) => <UserCell {...item.user} />,
              },
              {
                fieldName: "agent",
                fieldLabel: "Agent",
                cellRenderer: (item) => <AgentCell {...item.agent} />,
              },
              {
                fieldName: "amount",
                fieldLabel: "Total amount",
                cellRenderer: (item) => <AmountCell amount={item.amount} />,
              },
              {
                fieldName: "orderId",
                fieldLabel: "Order ID",
                cellRenderer: (item) => <OrderCell orderId={item.orderId} />,
              },
              {
                fieldName: "reason",
                fieldLabel: "Reason",
                cellRenderer: (item) => <ReasonCell reason={item.reason} />,
              },
              {
                fieldName: "zendeskTicketId",
                fieldLabel: "Zendesk ID",
                cellRenderer: (item) => (
                  <ZendeskCell zendeskTicketId={item.zendeskTicketId} />
                ),
              },
              {
                fieldName: "date",
                fieldLabel: "Date",
                cellRenderer: (item) => <DateCell date={item.date} />,
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                totalCount={count}
                limit={limit}
                itemCount={items.length}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { RefundsPage };
