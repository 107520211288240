import * as React from "react";

const SvgLogoDomutech = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={132}
    height={16}
    fill="none"
    viewBox="0 0 132 16"
    {...props}
  >
    <g clipPath="url(#LogoDomutech_svg__LogoDomutech_svg__clip0_451_2742)">
      <path
        fill="#262A2E"
        d="M1.894 2.125H.009V.274h1.885v1.85zM9.444.274V2.18H3.781V.274h5.663zm-7.55 11.424H.009V4.083h1.885v7.615zm7.55 0H7.557v-7.61h1.887v7.61zm-3.775 3.804H3.781v-6.66H5.67v6.66z"
      />
      <path
        fill="#C8D1D3"
        d="M13.22 15.504h-1.888l-.003-2.856.948-.95.946.95-.003 2.856z"
      />
      <path
        fill="#262A2E"
        d="M9.447 15.505H7.554v-1.903h1.893v1.903z"
      />
      <path
        fill="#C8D1D3"
        d="M5.675 6.94H3.78V4.98l.943-.952.95.953v1.958z"
      />
      <path
        fill="#262A2E"
        d="M13.226 9.795h-1.894V4.083h1.894v5.712zm-11.333 5.71H0v-1.903h1.893v1.903zM13.22 2.18h-1.888V.277h1.888V2.18zM17 .292h4.392c4.75 0 7.805 2.314 7.805 7.545s-3.05 7.665-7.603 7.665h-4.593V.292zm4.184 12.441c2.639 0 4.42-1.268 4.42-4.908 0-3.64-1.781-4.774-4.42-4.774h-.66v9.682h.66zm9.855-4.908C31.039 2.853 33.906 0 38.1 0s7.065 2.87 7.065 7.825-2.87 7.956-7.065 7.956-7.062-2.981-7.062-7.956zm10.522 0c0-3.051-1.338-4.852-3.469-4.852-2.13 0-3.469 1.8-3.469 4.852 0 3.051 1.356 4.998 3.478 4.998s3.46-1.947 3.46-4.998zm23.586.732V.397h3.518v8.512c0 2.982.96 4.01 2.7 4.01 1.74 0 2.755-1.028 2.755-4.01V.391h3.391v8.16c0 5.112-2.124 7.335-6.146 7.335-4.02 0-6.218-2.217-6.218-7.33z"
      />
      <path
        fill="#C8D1D3"
        d="M83.718 3.276h-4.282V.39h12.078v2.885h-4.281v12.337h-3.515V3.276zM93.515.39h9.745v2.886h-6.23v3.057h5.305v2.885H97.03v3.498h6.461v2.888h-9.976V.39zm11.91 7.808c0-5.05 3.414-7.98 7.342-7.98a6.62 6.62 0 014.678 1.977L115.592 4.4c-.781-.711-1.619-1.21-2.747-1.21-2.13 0-3.815 1.84-3.815 4.89 0 3.13 1.488 4.955 3.758 4.955a4.134 4.134 0 003.041-1.425l1.856 2.163a6.498 6.498 0 01-2.267 1.678 6.443 6.443 0 01-2.758.554c-3.94-.006-7.235-2.681-7.235-7.808zM119.598.292h3.516v5.855h5.368V.292H132v15.21h-3.518V9.148h-5.368v6.354h-3.516V.292z"
      />
      <path
        fill="#000"
        d="M62.329.289h-4.42L55.07 11.084h-.075L52.098.289h-4.732v15.207h3.061V4.98l2.726 10.515h3.57l2.541-9.927v9.927h3.076V.289h-.011z"
      />
    </g>
    <defs>
      <clipPath id="LogoDomutech_svg__LogoDomutech_svg__clip0_451_2742">
        <path
          fill="#fff"
          d="M0 0h132v16H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogoDomutech;
