import { createContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Paginator,
  SortingSelect,
  Spinner,
} from "@boligportal/juice";
import { useGetFavourites } from "api/queries";
import { getSortingOptions } from "apps/search_result/helpers/getSortingOptions";
import { App } from "components/app";
import { EmptyState } from "components/empty_screens/EmptyState";
import { Plan } from "components/interfaces/plan";
import { Profile } from "components/interfaces/profile";
import { SidebarLayout } from "components/sidebar_layout";
import { SeekerPaywall } from "features/paywall/SeekerPaywall";
import { OrderType, FavoriteCardStatus } from "lib/enums";
import { t } from "lib/i18n";
import { usePagination } from "../../hooks/usePagination";
import { MyFavouriteAds } from "./components/MyFavouriteAds";
import { MyFavouriteAdsHeader } from "./components/MyFavouriteAdsHeader";

const PAGE_SIZE = 20;
const REMINDER_THRESHOLD = 0.15;

type Props = {
  empty_state_srp_link: string;
  user_profile: Profile;
  seeker_plans: Plan[];
};

type ContextType = {
  plans: Plan[];
  profile?: Profile;
  onUpdate: () => Promise<unknown>;
};

export const FavouriteAdsContext = createContext<ContextType>({
  plans: [],
  onUpdate: () => Promise.resolve(),
});

const MyFavouriteAdsApp = ({ seeker_plans, user_profile }: Props) => {
  const [sorting, setSorting] = useState(OrderType.DEFAULT);
  const [activeTab, setActiveTab] = useState<FavoriteCardStatus>(
    FavoriteCardStatus.ALL,
  );
  const [totalFavoritesCount, setTotalFavoritesCount] = useState(0);
  const { offset, setOffset, limit } = usePagination(PAGE_SIZE);

  const { data, refetch, isFetching } = useGetFavourites({
    offset,
    limit,
    status: activeTab,
    sorting: sorting,
  });

  const total = data?.allCount;
  useEffect(() => {
    if (total && !isFetching && activeTab === FavoriteCardStatus.ALL) {
      setTotalFavoritesCount(total);
    }
  }, [total, isFetching, activeTab]);

  if (!data) {
    return (
      <SidebarLayout>
        <Loading />
      </SidebarLayout>
    );
  }

  const isNotFiltered = activeTab === FavoriteCardStatus.ALL;

  if (data.ads.length === 0 && isNotFiltered) {
    return (
      <SidebarLayout>
        <Container>
          <EmptyState
            ctaButton={
              <Button
                href={App.settings.routes.search_result_page}
                variant="branded"
              >
                {t("favourites.empty_state.button_text")}
              </Button>
            }
            headline={t("favourites.empty_state.headline")}
            text={t("favourites.empty_state.text")}
            imageSrc={"/static/images/empty_states/empty_favourite.png"}
          />
        </Container>
      </SidebarLayout>
    );
  }

  const showPaginator = data.allCount > PAGE_SIZE && !isFetching;

  const unavailablePercent = (data.allCount / totalFavoritesCount) * 100;

  const showReminderCard =
    data.allCount / totalFavoritesCount > REMINDER_THRESHOLD &&
    activeTab === FavoriteCardStatus.UNAVAILABLE;

  const handleSwitchTab = (tab: FavoriteCardStatus) => {
    setActiveTab(tab);
    setOffset(0);
  };

  return (
    <SeekerPaywall>
      <FavouriteAdsContext.Provider
        value={{
          plans: seeker_plans,
          profile: user_profile,
          onUpdate: refetch,
        }}
      >
        <SidebarLayout
          header={{
            component: (
              <MyFavouriteAdsHeader
                active={activeTab}
                onTabSelect={handleSwitchTab}
              />
            ),
          }}
        >
          <MyFavouriteAds
            ads={data.ads}
            unavailablePercent={unavailablePercent.toFixed()}
            showReminderCard={showReminderCard}
            isLoading={isFetching}
            sortingSlot={
              <SortingSelect
                value={sorting}
                label={t("sort_by.Sort Descriptor")}
                items={getSortingOptions()}
                onChange={(event) =>
                  setSorting(event.target.value as OrderType)
                }
              />
            }
          />

          {showPaginator && (
            <Box
              pt={2}
              pb={8}
            >
              <Container>
                <Paginator
                  totalRecords={data.allCount}
                  pageLimit={limit}
                  onChange={setOffset}
                  value={offset}
                  nextButtonLabel={t("paginator.next")}
                  previousButtonLabel={t("paginator.previous")}
                />
              </Container>
            </Box>
          )}
        </SidebarLayout>
      </FavouriteAdsContext.Provider>
    </SeekerPaywall>
  );
};

export const Loading = () => (
  <Flex
    align="center"
    justify="center"
    height="100%"
    m="auto"
  >
    <Spinner size="large" />
  </Flex>
);

export default MyFavouriteAdsApp;
