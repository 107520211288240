import { useRef, useEffect } from "react";
import {
  Flex,
  FlexColumn,
  Container,
  Box,
  TextField,
  DataTable,
  Text,
  CheckField,
  SortableColumn,
} from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { CompanyListItem } from "lib/customerServiceApi";
import { AdStates } from "lib/enums";
import { PAGE_ADS_ALL, PAGE_COMPANY_DETAIL } from "../..";
import { StyledLink } from "../../components/topbar/global_search/components/styled_link";
import { ListPaginator } from "../ads_page/list_paginator";
import { indicatorsCellRenderer } from "./indicators_cell_renderer";
import {
  useSearchCompaniesWithPagination,
  Filters,
} from "./use_search_companies_with_pagination";

const CompaniesFilter = ({
  filters,
  onChange,
}: {
  filters: Filters;
  onChange: (updatedFilters: Filters) => void;
}) => (
  <Flex
    borderBottom
    bg="tint"
  >
    <Container fluid>
      <Flex
        gap
        mt={3}
      >
        <FlexColumn size={3}>
          <TextField
            label="Company ID"
            value={filters.company_id?.toString() || ""}
            id="query"
            onChange={(event) => {
              onChange({
                ...filters,
                company_id:
                  event.target.value !== null && event.target.value.length
                    ? Number(event.target.value)
                    : null,
              });
            }}
          />
        </FlexColumn>

        <FlexColumn size={3}>
          <TextField
            label="Company name"
            value={filters.company_name || ""}
            id="query"
            onChange={(event) => {
              onChange({
                ...filters,
                company_name: event.target.value,
              });
            }}
          />
        </FlexColumn>

        <FlexColumn size={3}>
          <CheckField
            label="Company Page enabled"
            checked={filters.has_company_page}
            onChange={(event) => {
              onChange({
                ...filters,
                has_company_page: event.target.checked,
              });
            }}
          />

          <CheckField
            label="Iframe enabled"
            checked={filters.is_embedding_enabled}
            onChange={(event) => {
              onChange({
                ...filters,
                is_embedding_enabled: event.target.checked,
              });
            }}
          />
        </FlexColumn>

        {App.settings.market === Market.BOLIGPORTAL_DK && (
          <FlexColumn size={3}>
            <CheckField
              label="Danske udlejere"
              checked={filters.is_danske_udlejere}
              onChange={(event) => {
                onChange({
                  ...filters,
                  is_danske_udlejere: event.target.checked,
                });
              }}
            />

            <CheckField
              label="Almen bolig"
              checked={filters.social_housing}
              onChange={(event) => {
                onChange({
                  ...filters,
                  social_housing: event.target.checked,
                });
              }}
            />
          </FlexColumn>
        )}
      </Flex>
    </Container>
  </Flex>
);

const CompaniesList = ({
  items,
  sorting,
  setSorting,
}: {
  items: CompanyListItem[];
  sorting: SortableColumn<CompanyListItem>;
  setSorting: (column: keyof CompanyListItem, descending: boolean) => void;
}) => (
  <DataTable<CompanyListItem>
    fullscreen
    items={items}
    keyField="id"
    activeSortableColumn={sorting}
    onSortClick={setSorting}
    columns={[
      {
        fieldLabel: "ID",
        fieldName: "id",
        sortable: true,
        // eslint-disable-next-line react/display-name
        cellRenderer: (item) => (
          <div>
            <Text>{item.id}</Text>
          </div>
        ),
      },
      {
        fieldLabel: "Name",
        fieldName: "name",
        // eslint-disable-next-line react/display-name
        cellRenderer: (item) => (
          <StyledLink
            to={PAGE_COMPANY_DETAIL.replace(":id", item.id.toString())}
          >
            <Text>{item.name}</Text>
          </StyledLink>
        ),
      },
      {
        fieldLabel: "Active ads",
        fieldName: "active_ads_count",
        sortable: true,
        // eslint-disable-next-line react/display-name
        cellRenderer: (item) => (
          <StyledLink
            to={`${PAGE_ADS_ALL}?companyId=${item.id}&adState=${AdStates.ACTIVE_APPROVED}`}
          >
            <Text>{item.active_ads_count}</Text>
          </StyledLink>
        ),
      },
      {
        fieldLabel: "Total rentables",
        fieldName: "total_rentables_count",
        sortable: true,
        // eslint-disable-next-line react/display-name
        cellRenderer: (item) => (
          <StyledLink to={`${PAGE_ADS_ALL}?companyId=${item.id}`}>
            <Text>{item.total_rentables_count}</Text>
          </StyledLink>
        ),
      },
      {
        fieldLabel: "Other",
        fieldName: "ban_reason",
        sortable: false,
        cellRenderer: indicatorsCellRenderer,
      },
    ]}
  />
);

const CompaniesPage = () => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    items,
    count,
    offset,
    limit,
    setOffset,
    filters,
    applyFilters,
    sorting,
    applySorting,
  } = useSearchCompaniesWithPagination();

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [offset, limit]);

  const handleSetSorting = (
    column: keyof CompanyListItem,
    descending: boolean,
  ) => {
    applySorting({
      column,
      descending,
    });
  };

  return (
    <Flex
      column
      width="100%"
    >
      <CompaniesFilter
        filters={filters}
        onChange={(updatedFilters) => {
          applyFilters(updatedFilters);
        }}
      />

      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <CompaniesList
            items={items}
            sorting={sorting}
            setSorting={handleSetSorting}
          />
        </Flex>
      </Box>

      {count > limit && (
        <Flex
          column
          pt={4}
          pb={6}
          align="center"
          bg="tint"
        >
          <ListPaginator
            offset={offset}
            itemCount={items.length}
            totalCount={count}
            limit={limit}
            onNextPage={() => setOffset(offset + limit)}
            onPrevPage={() => setOffset(offset - limit)}
          />
        </Flex>
      )}
    </Flex>
  );
};

export { CompaniesPage };
