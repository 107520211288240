import { useState } from "react";
import { VideoEditor } from "features/video_feature/components/video_editor";
import { VideoUploader } from "features/video_feature/components/video_uploader";

const CSVideoEditor = ({
  adId,
  initialVideoId,
}: {
  adId: number;
  initialVideoId: number | null;
}) => {
  const [videoId, setVideoId] = useState<number | null>(initialVideoId);

  const uploadFinishedHandler = (newVideoId: number) => {
    setVideoId(newVideoId);
  };

  const videoDeletedHandler = () => {
    setVideoId(null);
  };

  return (
    <>
      {videoId && (
        <VideoEditor
          adId={adId}
          videoId={videoId}
          onVideoDeleted={videoDeletedHandler}
          deleteVideoButtonLabel="Delete Video"
          deleteVideoConfirmTitle="Deleting video"
          deleteVideoConfirmMessage="Are you sure you want to delete this video"
          deleteVideoConfirmButtonConfirmText="Yes, Delete video"
          deleteVideoConfirmButtonCancelText="Cancel"
          uploadMalformedHeader="Video error"
          uploadMalformedInfo="This video can't be played because of an error with its file type."
          uploadFinishedHeader="The video got added and is currently being processed"
          uploadFinishedInfo="Will be visible in about 5 - 10 minutes "
        />
      )}
      {!videoId && (
        <VideoUploader
          adId={adId}
          onUploadFinished={uploadFinishedHandler}
          buttonLabel="Upload Video"
          uploadHelpLabel="Choose video by clicking the button"
          supportedFileTypesLabel="Supported File types"
          supportedFileTypeNames="MP4, MOV, WMV, AVI, and FLV"
          uploadProgressLabel="Uploading video: "
        />
      )}
    </>
  );
};

export { CSVideoEditor };
