import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Dialog,
  Flex,
  Text,
  Button,
  DialogHeader,
  DialogContent,
  Box,
  TextField,
  CheckFieldNaked,
  DialogFooter,
  Divider,
  Notice,
  IconAlertCircle,
  Center,
  IconCheckmarkCircle,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import * as api from "api";
import { useUser } from "components/Providers/UserProvider";
import { ControlledPhoneNumberInput } from "components/forms/ControlledPhoneNumberField";
import { t } from "lib/i18n";
import SvgLogoBoligportal from "media/svg/LogoBoligportal";
import SvgLogoDomutech from "media/svg/LogoDomutech";

export enum DomutechSource {
  AD_CREATION_FLOW = "AD_CREATION_FLOW",
  ALL_RENTABLES = "ALL_RENTABLES",
  ADVERTISED_RENTABLES = "ADVERTISED_RENTABLES",
  RENTABLE_OVERVIEW_PAGE = "RENTABLE_OVERVIEW_PAGE",
  EDIT_AD_PAGE = "EDIT_AD_PAGE",
}

export const DomuTechDialog = ({
  open,
  onClose,
  rentable_id,
  source,
}: {
  open: boolean;
  onClose: () => void;
  rentable_id: number;
  source: DomutechSource;
}) => {
  const { user } = useUser();
  const [showSuccessState, setShowSuccessState] = useState(false);
  const {
    formState,
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
  } = useForm<{
    name: string;
    phoneNumber: string;
    email: string;
    acceptConsent: boolean;
  }>({
    defaultValues: {
      acceptConsent: false,
      name: `${user?.first_name} ${user?.last_name}`,
      phoneNumber: "",
      email: user?.email,
    },
    mode: "onSubmit",
  });
  const didConsent = watch("acceptConsent");

  const toggleConsent = () => setValue("acceptConsent", !didConsent);

  const handleDialogSubmit: SubmitHandler<{
    name: string;
    phoneNumber: string;
    acceptConsent: boolean;
    email: string;
  }> = (data) => {
    api
      .saveDomutechLeadData({
        name: data.name,
        phone_number: data.phoneNumber,
        rentable_id: rentable_id,
        source: source,
        email: data.email,
      })
      .then(() => {
        setShowSuccessState(true);
      })
      .catch((err) => {
        if (err.response.data.errors.phone_number) {
          err.response.data.errors.phone_number.forEach((phoneError) => {
            setError("phoneNumber", phoneError);
          });
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setShowSuccessState(false);
      }}
    >
      <DialogHeader>{t("leads.domutech.signup_dialog.header")}</DialogHeader>
      <form onSubmit={handleSubmit(handleDialogSubmit)}>
        <DialogContent>
          {showSuccessState ? (
            <DomutechSuccessState onClose={onClose} />
          ) : (
            <>
              <Notice
                type="info"
                icon={<IconAlertCircle size="large" />}
              >
                <strong>
                  {t("energy_label.fetching.not_found.error.title")}
                </strong>
                <div>{t("energy_label.fetching.not_found.error.content")}</div>
              </Notice>
              <Divider mt={3} />

              <Text markdown>
                {t("leads.domutech.signup_dialog.colab_text.markdown")}
              </Text>

              <TextField
                {...register("name", {
                  required: t("leads.domutech.signup_dialog.field_error.name"),
                })}
                required
                label={t("common.form_fields.name.label")}
                errorText={formState.errors.name?.message}
              />

              <Flex
                gap
                mt={3}
                column={{
                  xs: true,
                  md: false,
                }}
              >
                <Box
                  width={{
                    md: "50%",
                  }}
                >
                  <TextField
                    {...register("email", {
                      required: t(
                        "leads.domutech.signup_dialog.field_error.email",
                      ),
                    })}
                    required
                    label={t("common.form_fields.email.label")}
                    errorText={formState.errors.email?.message}
                  />
                </Box>
                <Box
                  width={{
                    md: "50%",
                  }}
                >
                  <ControlledPhoneNumberInput
                    onlyCountries={["dk"]}
                    control={control}
                    name="phoneNumber"
                    label={t("common.form_fields.phone_number.label")}
                    required
                    errorText={
                      formState.errors.phoneNumber && t("required_field.phone")
                    }
                  />
                </Box>
              </Flex>
              <Text
                size="tiny"
                color="muted"
                mt={-2.5}
                block
              >
                {t("leads.domutech.signup_dialog.check_information")}
              </Text>

              <Flex align="center">
                <CheckFieldNaked
                  required
                  {...register("acceptConsent", {
                    required: t("leads.hedvig.consent_required"),
                  })}
                />
                <Box
                  onClick={toggleConsent}
                  ml={1}
                >
                  <Text
                    markdown
                    size="tiny"
                  >
                    {t("leads.domutech.signup_dialog.consent_text.markdown")}
                  </Text>
                </Box>
              </Flex>

              {formState.errors.acceptConsent?.message && (
                <Text
                  block
                  mt={0.5}
                  size="tiny"
                  color="danger"
                >
                  {formState.errors.acceptConsent?.message}
                </Text>
              )}
              <Divider my={3} />
              <Flex>
                <SvgLogoDomutech
                  width="90px"
                  height="20px"
                />
                <Box
                  ml={2}
                  pl={2}
                  borderLeft
                />
                <SvgLogoBoligportal
                  width="90px"
                  height="20px"
                />
              </Flex>
            </>
          )}
        </DialogContent>
      </form>
      {!showSuccessState && (
        <DialogFooter>
          <Flex gap>
            <Button
              variant="subtle"
              onClick={onClose}
            >
              {t("leads.dialog.cancel_button_label")}
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleSubmit(handleDialogSubmit)}
            >
              {t("leads.domutech.signup_dialog.get_offer_btn")}
            </Button>
          </Flex>
        </DialogFooter>
      )}
    </Dialog>
  );
};

const DomutechSuccessState = ({ onClose }: { onClose: () => void }) => (
  <Center>
    <IconCheckmarkCircle
      color="success"
      css={css`
        font-size: 40px;
      `}
    />
    <Text
      size="h3"
      weight="bold"
      mt={2}
    >
      {t("leads.domutech.signup_dialog.success.headline")}
    </Text>
    <Text
      paragraph
      mb={3}
    >
      {t("leads.domutech.signup_dialog.success.text")}
    </Text>
    <Box mb={2}>
      <Button
        variant="primary"
        onClick={onClose}
      >
        {t("leads.domutech.signup_dialog.success.continue_button")}
      </Button>
    </Box>
  </Center>
);
