import { Box, Button, styled } from "@boligportal/juice";
import { t } from "lib/i18n";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.colorPalette.blue[400]} !important;
`;

const MonthButtons = ({
  onMonthClick,
}: {
  onMonthClick: (numMonth: number) => void;
}) => {
  const handleOneMonthClick = () => {
    onMonthClick(1);
  };
  const handleTwoMonthClick = () => {
    onMonthClick(2);
  };
  const handleThreeMonthClick = () => {
    onMonthClick(3);
  };

  return (
    <Box>
      <StyledButton
        text
        onClick={handleOneMonthClick}
      >
        {t("create.finances.1_month's")}
      </StyledButton>
      {", "}
      <StyledButton
        text
        onClick={handleTwoMonthClick}
      >
        {t("create.finances.2_month's")}
      </StyledButton>
      {` ${t("or")} `}
      <StyledButton
        text
        onClick={handleThreeMonthClick}
      >
        {t("create.finances.3_month's")}
      </StyledButton>
      {` ${t("create.finances.rent")}`}
    </Box>
  );
};

export { MonthButtons };
