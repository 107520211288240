import * as React from "react";
import {
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogContent,
  Button,
  Box,
  Text,
  dialogActions,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { Filter, FilterInterface } from "./filter";

interface FilterSlideoverInterface extends FilterInterface {
  resultsCount: number;
  onClearFilter: () => void;
  opener: React.ReactElement;
}

export const FilterSlideover = ({
  resultsCount,
  onClearFilter,
  opener,
  filter,
  onChange,
  trackingEventsCategory,
  showColorMarkFilter,
}: FilterSlideoverInterface) => (
  <Dialog
    slideOver
    opener={opener}
    size="small"
  >
    <DialogHeader>
      <Text
        size="h3"
        ml="8px"
      >
        {t("profileFilter.filter")}
      </Text>
    </DialogHeader>

    <DialogContent>
      <Filter
        {...{
          filter,
          onChange,
          trackingEventsCategory,
          showColorMarkFilter,
        }}
      />
    </DialogContent>

    <dialogActions.Consumer>
      {({ hide: hideSlideover }) => (
        <DialogFooter>
          <Button onClick={onClearFilter}>
            {t("profileFilter.clear_filter")}
          </Button>

          <Box flexPush>
            <Button
              variant="branded"
              onClick={hideSlideover}
            >
              {resultsCount}{" "}
              {resultsCount === 1
                ? t("profileFilter.result")
                : t("profileFilter.results")}
            </Button>
          </Box>
        </DialogFooter>
      )}
    </dialogActions.Consumer>
  </Dialog>
);
