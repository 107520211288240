import * as React from "react";
import { useSettings } from "components/Providers/SettingsProvider";
import { Market } from "components/markets/market_settings";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import { LandlordDialog } from "features/landlord_onboarding/components/LandlordDialog";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { TrackingEvent } from "lib/tracking/events";
import { createOnboardingViewModel_DK } from "./config/dk/config";
import { createOnboardingViewModel_SE } from "./config/se/config";

export type Flow = "ad" | "contract" | "moving-report";

type LandlordOnboardingContextValue = {
  flow: Flow | null;
  market: Market;
  setLandlordOnboardingFlow: (flow: Flow | null) => void;
  onCancel: () => void;
};

const LandlordOnboardingContext =
  React.createContext<LandlordOnboardingContextValue>(null!);

const LandlordOnboardingDialog = () => {
  const { flow, market } = React.useContext(LandlordOnboardingContext);

  if (!flow) {
    return null;
  }

  return (
    <LocalStorageManagedDialog
      flow={flow}
      market={market}
    />
  );
};

type LocalStorageManagedDialogProps = {
  flow: Flow;
  market: Market;
};

const LocalStorageManagedDialog = ({
  flow,
  market,
}: LocalStorageManagedDialogProps) => {
  const [didShowOnboarding, setDidShowOnboarding] = useLocalStorageState(
    `LandlordOnboardingDialog_${flow}${market}`,
    false,
  );

  const handleClose = () => setDidShowOnboarding(true);

  React.useEffect(() => {
    if (!didShowOnboarding) {
      TrackingEvent.landlordOnboardingTrackViewEvent(flow);
    }
  }, [didShowOnboarding, flow]);

  if (didShowOnboarding) {
    return null;
  }

  const viewModel = createViewModel(market, flow, handleClose);

  return (
    <LandlordDialog
      title={viewModel.title}
      description={viewModel.description}
      items={viewModel.items}
      isOpen={!didShowOnboarding}
      onClose={handleClose}
    />
  );
};

const createViewModel = (market: Market, flow: Flow, onClose: () => void) => {
  switch (market) {
    case Market.BOLIGPORTAL_DK:
      return createOnboardingViewModel_DK(flow, onClose);
    case Market.BOSTADSPORTAL_SE:
      return createOnboardingViewModel_SE(flow, onClose);
    default:
      throw new Error(`Unsupported market: ${market}`);
  }
};

export const LandlordOnboardingFeature = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { market } = useSettings();
  const [flow, setFlow] = React.useState<Flow | null>(null);
  return (
    <LandlordOnboardingContext.Provider
      value={{
        market,
        flow,
        setLandlordOnboardingFlow: setFlow,
        onCancel: () => setFlow(null),
      }}
    >
      {children}
      <LandlordOnboardingDialog />
    </LandlordOnboardingContext.Provider>
  );
};

export const useLandlordOnboardingFeature = () => {
  const context = React.useContext(LandlordOnboardingContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useLandlordOnBoarding",
      "LandlordOnboardingFeature",
    );
  }

  const { setLandlordOnboardingFlow } = context;

  return {
    setLandlordOnboardingFlow,
  };
};
