import * as React from "react";

const SvgCustomerService = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1.56em"
    height="1em"
    viewBox="0 0 78 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Final-design-and-spec"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop"
        transform="translate(-921.000000, -4808.000000)"
        fill="#ED6B2D"
      >
        <g
          id="Customer-Support"
          transform="translate(239.000000, 4584.000000)"
        >
          <g
            transform="translate(534.000000, 224.000000)"
            id="Group-4"
          >
            <g transform="translate(148.000000, 0.000000)">
              <g
                id="person"
                transform="translate(16.000000, 0.000000)"
              >
                <path
                  d="M32.1944365,3.63616071 C30.0011031,1.29129464 26.9376499,0 23.5563549,0 C20.1570264,0 17.0834293,1.28348214 14.9002398,3.61383929 C12.6933813,5.96986607 11.6181295,9.171875 11.8705995,12.6294643 C12.3710312,19.4508929 17.6131655,25 23.5563549,25 C29.4995444,25 34.7326619,19.4520089 35.2409832,12.6316964 C35.4968345,9.20535714 34.4148201,6.01004464 32.1944365,3.63616071 Z"
                  id="Path"
                ></path>
                <path
                  d="M43.3932854,50 L3.71942446,50 C2.66776601,50.0135459 1.66589372,49.5572755 0.991846523,48.7578125 C0.259232614,47.890625 -0.0360671463,46.7064732 0.182589928,45.5089286 C1.13386091,40.2834821 4.10263789,35.8939732 8.76882494,32.8125 C12.9142926,30.0770089 18.1654436,28.5714286 23.5563549,28.5714286 C28.9472662,28.5714286 34.1984173,30.078125 38.3438849,32.8125 C43.0100719,35.8928571 45.9788489,40.2823661 46.9301199,45.5078125 C47.148777,46.7053571 46.8534772,47.8895089 46.1208633,48.7566964 C45.4470347,49.5565758 44.4451302,50.0132709 43.3932854,50 Z"
                  id="Path"
                ></path>
              </g>
              <g
                id="person-copy"
                transform="translate(56.000000, 9.000000)"
              >
                <path
                  d="M15.5532147,1.74535714 C14.5240707,0.619821429 13.0866539,0 11.500101,0 C9.90508657,0 8.46291005,0.616071429 7.43852577,1.73464286 C6.40303562,2.86553571 5.89851182,4.4025 6.01697443,6.06214286 C6.25178425,9.33642857 8.71146999,12 11.500101,12 C14.2887321,12 16.744187,9.33696429 16.9826988,6.06321429 C17.1027479,4.41857143 16.595051,2.88482143 15.5532147,1.74535714 Z"
                  id="Path"
                ></path>
                <path
                  d="M20.3074364,22.9998631 L1.69256362,22.9998631 C1.1991282,23.0061845 0.729052313,22.7932612 0.412791113,22.4201836 C0.0690505647,22.0155016 -0.0695033178,21.462905 0.0330900151,20.9040585 C0.479423896,18.4655502 1.87236636,16.4171407 4.06172924,14.9791396 C6.00677191,13.7025945 8.4705984,13 11,13 C13.5294016,13 15.9932281,13.7031154 17.9382708,14.9791396 C20.1276336,16.4166199 21.5205761,18.4650294 21.96691,20.9035377 C22.0695033,21.4623842 21.9309494,22.0149808 21.5872089,22.4196627 C21.2710502,22.7929347 20.8009592,23.0060561 20.3074364,22.9998631 Z"
                  id="Path"
                ></path>
              </g>
              <g
                id="person-copy-2"
                transform="translate(0.000000, 9.000000)"
              >
                <path
                  d="M15.5532147,1.74535714 C14.5240707,0.619821429 13.0866539,0 11.500101,0 C9.90508657,0 8.46291005,0.616071429 7.43852577,1.73464286 C6.40303562,2.86553571 5.89851182,4.4025 6.01697443,6.06214286 C6.25178425,9.33642857 8.71146999,12 11.500101,12 C14.2887321,12 16.744187,9.33696429 16.9826988,6.06321429 C17.1027479,4.41857143 16.595051,2.88482143 15.5532147,1.74535714 Z"
                  id="Path"
                ></path>
                <path
                  d="M20.3074364,22.9998631 L1.69256362,22.9998631 C1.1991282,23.0061845 0.729052313,22.7932612 0.412791113,22.4201836 C0.0690505647,22.0155016 -0.0695033178,21.462905 0.0330900151,20.9040585 C0.479423896,18.4655502 1.87236636,16.4171407 4.06172924,14.9791396 C6.00677191,13.7025945 8.4705984,13 11,13 C13.5294016,13 15.9932281,13.7031154 17.9382708,14.9791396 C20.1276336,16.4166199 21.5205761,18.4650294 21.96691,20.9035377 C22.0695033,21.4623842 21.9309494,22.0149808 21.5872089,22.4196627 C21.2710502,22.7929347 20.8009592,23.0060561 20.3074364,22.9998631 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCustomerService;
