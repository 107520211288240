import * as React from "react";

export const useSkipInitialEffect = (
  effectCallback: React.EffectCallback,
  dependencies: React.DependencyList,
) => {
  const isInitial = React.useRef(true);

  // @ts-ignore
  React.useEffect(() => {
    let destructorFunction: ReturnType<React.EffectCallback> = undefined;

    if (isInitial.current) {
      isInitial.current = false;
    } else {
      destructorFunction = effectCallback();
    }

    if (typeof destructorFunction === "function") {
      return destructorFunction;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
