export const MailIcon = () => (
  <svg
    width="56px"
    height="40px"
    viewBox="0 0 56 40"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.9"
    >
      <g
        transform="translate(-241.000000, -2628.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 1961.000000)">
          <g transform="translate(240.000000, 607.000000)">
            <g transform="translate(1.000000, 60.000000)">
              <path
                d="M55.5423077,5.08497658 L41.0576923,20.2281763 C40.95,20.3387106 40.95,20.5045121 41.0576923,20.6150464 L51.1942308,31.6961104 C51.8807692,32.4007666 51.8807692,33.5337432 51.1942308,34.2383994 C50.8576923,34.5838191 50.4,34.7634373 49.9557692,34.7634373 C49.5115385,34.7634373 49.0538462,34.5838191 48.7173077,34.2383994 L38.6211538,23.1987857 C38.5134615,23.0882514 38.3384615,23.0882514 38.2307692,23.1987857 L35.7673077,25.7687083 C33.7076923,27.9103105 30.975,29.0985542 28.0403846,29.112371 C25.0653846,29.1261878 22.2384615,27.8412265 20.1519231,25.6719908 L17.7826923,23.1987857 C17.675,23.0882514 17.5,23.0882514 17.3923077,23.1987857 L7.29615385,34.2383994 C6.95961538,34.5838191 6.50192308,34.7634373 6.05769231,34.7634373 C5.61346154,34.7634373 5.15576923,34.5838191 4.81923077,34.2383994 C4.13269231,33.5337432 4.13269231,32.4007666 4.81923077,31.6961104 L14.9557692,20.6150464 C15.05,20.5045121 15.05,20.3387106 14.9557692,20.2281763 L0.457692308,5.08497658 C0.282692308,4.90535834 0,5.02970943 0,5.27841161 L0,35.5786278 C0,38.0103825 1.93846154,40 4.30769231,40 L51.6923077,40 C54.0615385,40 56,38.0103825 56,35.5786278 L56,5.27841161 C56,5.02970943 55.7038462,4.91917512 55.5423077,5.08497658 Z"
                id="Path"
              />
              <path
                d="M27.9928553,26 C30.1076805,26 32.0939015,25.1764063 33.5799949,23.6712179 L56,0.993992354 C55.2140852,0.36919716 54.2424088,0 53.1707068,0 L2.82929319,0 C1.75759122,0 0.771625415,0.36919716 0,0.993992354 L22.4200051,23.6712179 C23.8918091,25.1622064 25.8780301,26 27.9928553,26 L27.9928553,26 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
