import { useMutation, useQueryClient } from "react-query";
import { cancelProductSubscription, reactivateProductSubscription } from "api";
import { subscriptionKeys } from "api/queries";

type CancelPayload = {
  ids: number[];
  reason: string;
};

export const useCancelProductSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CancelPayload) =>
      cancelProductSubscription(payload.ids, payload.reason),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          subscriptionKeys.landlordSubscriptionProducts(),
        ),
    },
  );
};

export const useReactivateProductSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (subscriptionId: number[]) => reactivateProductSubscription(subscriptionId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          subscriptionKeys.landlordSubscriptionProducts(),
        ),
    },
  );
};
