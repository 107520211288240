import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Notice } from "@boligportal/juice";
import { CreateRentableForm } from "features/eco_system/types";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";

type Props = {
  address?: string;
};

export const CanOnlyCreateRoomsOnSameAddressNotice = ({ address }: Props) => {
  const { control } = useFormContext<CreateRentableForm>();

  const disabled = useWatch({
    control,
    name: "rentables.0.disabled" as const,
  });

  useEffect(() => {
    if (disabled && address) {
      TrackingEvent.addressAlreadyCreated();
    }
  }, [disabled, address]);

  if (!disabled || !address) {
    return null;
  }

  return (
    <Notice type="info">
      {t(
        "feature.choose_or_create_rentable.rentable_exists_choose_room_to_create%{address}",
        {
          address,
        },
      )}
    </Notice>
  );
};
