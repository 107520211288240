import * as React from "react";
import { styled } from "@boligportal/juice";
import { modalActions, ModalController } from "components/modal_controller";

const StyledAppWrapper = styled.div<{
  modalIsOpen: boolean;
  offsetTop: number;
}>`
  ${(props) =>
    props.modalIsOpen &&
    `
    position: fixed;
    width: 100%;
    overflow: hidden;
    top: ${props.offsetTop}px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  `};
`;

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const modal = React.useContext(modalActions);

  return (
    <StyledAppWrapper
      id="app"
      offsetTop={modal.offsetTop}
      modalIsOpen={modal.isOpen}
    >
      {children}
    </StyledAppWrapper>
  );
};

export const ModalProvider = ({ children }: { children: React.ReactNode }) => (
  <ModalController>
    <AppWrapper>{children}</AppWrapper>
  </ModalController>
);
