import { AdCategories } from "lib/enums";

export const oisHousingIdToRentalCategory = (housingTypeId: number) => {
  const roomId = 150;
  const townHouseId = 130;
  const apartmentId = 140;
  const rentalHouseIds = [110, 120, 510, 190, 520, 540];

  if (housingTypeId === roomId) {
    return AdCategories.ROOM;
  }

  if (housingTypeId === townHouseId) {
    return AdCategories.TOWNHOUSE;
  }

  if (housingTypeId === apartmentId) {
    return AdCategories.APARTMENT;
  }

  if (housingTypeId && rentalHouseIds.includes(housingTypeId)) {
    return AdCategories.HOUSE;
  }

  return undefined;
};
