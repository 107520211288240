import { UserListItem } from "lib/customerServiceApi";
import { Company, UserListItemViewModel } from "./interfaces";

export interface CompanyDetailsDTO {
  id: number;
  name: string;
  postal_code: string;
  postal_town: string;
  cvr: string;
  embedded_allowed: boolean;
  iframe_allowed: boolean;
  address: string;
  country: string;
  phone_number: string;
  contact_email: string;
  invoice_email?: string;
  reference_no?: string;
  number_of_rentables: string;
  has_data_insights_access: boolean;
  att: string;
  danske_udlejere: boolean;
  verified: boolean;
  interactive_floor_plan_allowed: boolean;
  social_housing: boolean;
  fixed_upsell: boolean;
  can_impersonate: boolean;
  sales_agent?: string;
  rental_data_free_access?: boolean;
  company_new_build_access?: boolean;
  ean?: number;
}

export const createCompanyDetailsDTO = (
  company: Company,
): CompanyDetailsDTO => ({
  id: company.id,
  name: company.name,
  company_new_build_access: company.company_new_build_access,
  postal_code: company.postal_code,
  postal_town: company.postal_town,
  cvr: company.cvr,
  embedded_allowed: company.embedded_allowed,
  iframe_allowed: company.iframe_allowed,
  address: company.address,
  country: company.country,
  phone_number: company.phone_number,
  contact_email: company.contact_email,
  number_of_rentables: company.number_of_rentables,
  has_data_insights_access: company.has_data_insights_access,
  att: company.att,
  danske_udlejere: company.danske_udlejere,
  verified: company.verified,
  interactive_floor_plan_allowed: company.interactive_floor_plan_allowed,
  social_housing: company.social_housing,
  fixed_upsell: company.fixed_upsell,
  can_impersonate: company.can_impersonate,
  sales_agent: company.sales_agent,
  rental_data_free_access: company.rental_data_free_access,
  invoice_email: company.invoice_email,
  reference_no: company.reference_no,
  ean: company.ean,
});

export const formatUser = (user: UserListItemViewModel | UserListItem) =>
  `${user.username} - ${user.first_name} ${user.last_name} #${user.id}`;

export const stringToSlug = (str: string) => {
  let slug = str.replace(/^\s+|\s+$/g, ""); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-zæøå0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return slug;
};
