import { useState, useEffect } from "react";
import { Text } from "@boligportal/juice";

const jokes = [
  {
    joke: "Did you hear about the restaurant on the moon? Great food, no atmosphere.",
  },
  {
    joke: "What do you call a fake noodle? An Impasta.",
  },
  {
    joke: "Want to hear a joke about paper? Nevermind it's tearable.",
  },
  {
    joke: "I just watched a program about beavers. It was the best dam program I've ever seen.",
  },
  {
    joke: "Why did the coffee file a police report? It got mugged.",
  },
  {
    joke: "Did you hear about the baguette at the zoo? It was bread in captivity.",
  },
  {
    joke: "How does a penguin build it's house? Igloos it together.",
  },
  {
    joke: "What do you call a Mexican who has lost his car? Carlos",
  },
  {
    joke: "Bad puns are how eye roll.",
  },
  {
    joke: "What do you call a can opener that's broken? A Can't opener.",
  },
  {
    joke: "Why did the scarecrow win an award? Because he was outstanding in his field.",
  },
  {
    joke: "Why don't skeletons ever go trick or treating? Because they have no body to go with.",
  },
  {
    joke: "What do you call an elephant that doesn't matter? An irrelephant",
  },
  {
    joke: "What do you call cheese that isn't yours? Nacho Cheese.",
  },
  {
    joke: "My friend has designed an invisible aeroplane but I can't see it taking off.",
  },
  {
    joke: "The shovel was a ground-breaking invention.",
  },
  {
    joke: "Son: Dad, did you get a haircut? Dad: No I got them all cut.",
  },
  {
    joke: "Why couldn't the bicycle stand up by itself? It was two tired.",
  },
  {
    joke: "What did the grape do when he got stepped on? He let out a little wine.",
  },
  {
    joke: "I wouldn't buy anything with velcro. It's a total rip-off.",
  },
  {
    joke: "Don’t trust stairs, they’re always up to something.",
  },
  {
    joke: "Want to hear a joke about construction? I'm still working on it.",
  },
  {
    joke: "I accidentally handed my wife a glue stick instead of a chapstick, she still isn't talking to me.",
  },
  {
    joke: "This graveyard looks overcrowded. People must be dying to get in there.",
  },
  {
    joke: "I built an electric fence around my garden and my neighbour is dead against it.",
  },
  {
    joke: "Whoever stole my antidepressants I hope you are happy now.",
  },
  {
    joke: 'Two goldfish are in a tank. One says to the other, "do you know how to drive this thing?"',
  },
  {
    joke: "What do you call a man with a rubber toe? Roberto.",
  },
  {
    joke: "Son: Dad can you put on my shoes for me? Dad: I'll try but they might not fit!",
  },
  {
    joke: "My wife accused me of having zero empathy. I don't understand how she can feel that way.",
  },
  {
    joke: "What do you call a fat psychic? A four-chin teller.",
  },
  {
    joke: 'My wife asked me if "I was listening to her", strange way to start a conservation.',
  },
  {
    joke: "To the man in the wheelchair that stole my camouflage jacket... You can hide but you can't run.",
  },
  {
    joke: "I thought about going on an all-almond diet. But that's just nuts",
  },
  {
    joke: "Son: Dad can you tell me what a solar eclipse is? Dad: No sun.",
  },
  {
    joke: "Why do you never see elephants hiding in trees? Because they're so good at it.",
  },
  {
    joke: "A furniture store keeps calling me. All I wanted was one night stand.",
  },
  {
    joke: "Why do you never see elephants hiding in trees? Because they're so good at it.",
  },
  {
    joke: "What's brown and sticky? A stick.",
  },
  {
    joke: "Son: Dad, can you put the cat out? Dad: I didn't know it was on fire.",
  },
  {
    joke: "My wife says I only have 2 faults. I don't listen and something else...",
  },
  {
    joke: "My wife told me i need to stop behaving like a flamingo. So I had to put my foot down.",
  },
  {
    joke: "I used to work in a shoe recycling shop. It was sole destroying.",
  },
  {
    joke: "Son: I'll call you later. Dad: Don't call me later, call me Dad.",
  },
  {
    joke: "I like waiters, they bring a lot to the table.",
  },
  {
    joke: "Did I tell you the time I fell in love during a backflip? I was heels over head.",
  },
  {
    joke: "My wife is like a newspaper, a new issue everyday.",
  },
  {
    joke: "I don’t play soccer because I enjoy the sport. I’m just doing it for kicks.",
  },
  {
    joke: "People don’t like having to bend over to get their drinks. We really need to raise the bar.",
  },
  {
    joke: "What do you call a shoe made of a banana? A Slipper.",
  },
  {
    joke: "Wife: Stop being an idiot. Just be yourself. Me: Make your mind up!",
  },
  {
    joke: "My mate says I'm getting fat, but in my defense I've had a lot on my plate recently.",
  },
  {
    joke: "I love jokes about eyes, the cornea the better.",
  },
  {
    joke: "Some mornings I wake up grumpy, on others I let her sleep in.",
  },
  {
    joke: "I'm going to stand outside, so if anyone asks tell them I'm outstanding.",
  },
  {
    joke: "You should always knock on the fridge before opening it just in case there's a salad dressing.",
  },
  {
    joke: "Son: Dad, can you put my shoes on? Dad: No, I don't think they'll fit me.",
  },
  {
    joke: "My wife told me to take the spider out instead of killing him. Went out, had drinks and he's a web designer.",
  },
  {
    joke: "A guy tried to sell me a mirror but I knew it was a scam, I could see right through it.",
  },
  {
    joke: "I was shocked when I found out my toaster wasn't waterproof.",
  },
  {
    joke: "I once ate a dictionary, it gave me thesaurus throat I've ever had.",
  },
  {
    joke: "I would avoid the sushi if I was you, it’s a little fishy.",
  },
  {
    joke: "How many apples grow on a tree? All of them.",
  },
  {
    joke: "My 3 favorite things are eating my family and not using commas.",
  },
  {
    joke: "You know what actually makes me smile? My facial muscles.",
  },
  {
    joke: "I am reading a horror story in Braille. Something bad is going to happen, I can feel it.",
  },
  {
    joke: "I spent $100 on a new belt that didn't even fit, my wife said it was a huge waist.",
  },
  {
    joke: "I hate it when people say age is only a number, age is clearly a word.",
  },
  {
    joke: "Son: Dad, I'm hungry! Dad: Hi Hungry, I'm Dad.",
  },
  {
    joke: "Did you hear about the kidnapping at school? It's fine, he woke up.",
  },
  {
    joke: "I have this strange talent that I can always guess what's inside a wrapped present - It's a gift.",
  },
  {
    joke: "Sundays are always a little sad but, the day before is a sadder day.",
  },
  {
    joke: "To whoever stole my copy of Microsoft Office, I will find you. You have my Word!",
  },
  {
    joke: "I left my wife because she was obsessed with counting. I wonder what she's up to now?",
  },
  {
    joke: "Singing in the shower is all fun and games until you get shampoo in your mouth. Then it's a soap opera.",
  },
  {
    joke: "I went to the store to pick up 8 cans of Sprite. When I got home I realised I'd only picked 7up.",
  },
  {
    joke: "When is a door not a door? When it’s ajar.",
  },
  {
    joke: "What's blue and smells like red paint? Blue paint.",
  },
  {
    joke: 'I purchased a deodorant stick today, instructions say "remove cap and push up buttom" I can hardly walk but when I fart the room smells lovely.',
  },
  {
    joke: "What is the least spoken language in the world? Sign language.",
  },
  {
    joke: "My wife is really mad at the fact that I have no sense of direction. So I packed up my stuff and right.",
  },
  {
    joke: "Today my wife left me because I am insecure. Oh wait! She's back, she went to get coffee.",
  },
  {
    joke: "My wife changed a lot when she went vegan, it's like I never knew herbivore.",
  },
  {
    joke: 'A man is washing the car with his son. Son: "Dad can\'t you just use a sponge?"',
  },
  {
    joke: "Son: How much do all of our bones weigh? Dad: A Skele-ton.",
  },
  {
    joke: "The rotation of earth really makes my day.",
  },
  {
    joke: "A storm blew away 25% of my roof last night, oof.",
  },
  {
    joke: "To the person who stole my glasses. I will find you, I have contacts.",
  },
  {
    joke: "Son: How do I look? Dad: With your eyes.",
  },
  {
    joke: "I know a lot of jokes about retired people but none of them work.",
  },
  {
    joke: "What's red and bad for your teeth? A brick.",
  },
  {
    joke: "2 years ago my doctor told me I was going deaf, I haven't heard from him since.",
  },
  {
    joke: "Don't trust atoms. They make up everything!",
  },
  {
    joke: "As i suspected, someone has been adding soil to my garden. The plot thickens.",
  },
  {
    joke: "Son: There’s something in my eye. Dad: Looks like an eyeball!",
  },
  {
    joke: "After you die what part of your body is the last to stop working? Your pupils, they dilate.",
  },
  {
    joke: "Saturday and Sunday are strong days because the others are just weekdays.",
  },
  {
    joke: "I don't want to brag, I finished the puzzle in a week and it said 2-4 years on the box.",
  },
  {
    joke: "How do you get a farm girl to like you? A tractor.",
  },
  {
    joke: "Dark is spelled with a C not a K because you can't C in the dark.",
  },
  {
    joke: "Did you hear about the guy who cut off the left side of his body? He's all right now.",
  },
  {
    joke: "I was gonna tell a time-traveling joke, but you guys didn’t like it.",
  },
  {
    joke: "I was driving my bread car and it caught alight, now it's toast.",
  },
  {
    joke: "I just swapped our bed for a trampoline, my wife hit the roof.",
  },
  {
    joke: "What lies at the bottom of the ocean and twitches? A nervous wreck.",
  },
  {
    joke: "You know what the loudest pet you can get is? A trum-pet.",
  },
  {
    joke: "What do you call someone with no body and no nose? Nobody knows.",
  },
  {
    joke: "Based upon my shameful behaviour after drinking, I finally decided to quit drinking altogether. Now I drink alone.",
  },
  {
    joke: "I have a fear of elevators, I'm taking steps to avoid them.",
  },
  {
    joke: "How does a meteorologist go up a mountain? They climate.",
  },

  {
    joke: "I'm tired of following my dreams. I'm just going to ask them where they are going and meet up with them later.",
  },
  {
    joke: "Did you hear about the guy whose whole left side was cut off? He's all right now.",
  },
  {
    joke: "Why didn’t the skeleton cross the road? Because he had no guts.",
  },
  {
    joke: "What did one nut say as he chased another nut?  I'm a cashew!",
  },
  {
    joke: "Chances are if you' ve seen one shopping center, you've seen a mall.",
  },
  {
    joke: "I knew I shouldn't steal a mixer from work, but it was a whisk I was willing to take.",
  },
  {
    joke: "How come the stadium got hot after the game? Because all of the fans left.",
  },
  {
    joke: "Why was it called the dark ages? Because of all the knights.",
  },
  {
    joke: "A steak pun is a rare medium well done.",
  },
  {
    joke: "Why did the tomato blush? Because it saw the salad dressing.",
  },
  {
    joke: "Did you hear the joke about the wandering nun? She was a roman catholic.",
  },
  {
    joke: "What creature is smarter than a talking parrot? A spelling bee.",
  },
  {
    joke: "I'll tell you what often gets over looked... garden fences.",
  },
  {
    joke: "Why did the kid cross the playground? To get to the other slide.",
  },
  {
    joke: "Why do birds fly south for the winter? Because it's too far to walk.",
  },
  {
    joke: "What is a centipedes's favorite Beatle song?  I want to hold your hand, hand, hand, hand...",
  },
  {
    joke: "My first time using an elevator was an uplifting experience. The second time let me down.",
  },
  {
    joke: "To be Frank, I'd have to change my name.",
  },
  {
    joke: "Slept like a log last night … woke up in the fireplace.",
  },
  {
    joke: "Why does a Moon-rock taste better than an Earth-rock? Because it's a little meteor.",
  },
  {
    joke: "I thought my wife was joking when she said she'd leave me if I didn't stop signing \"I'm A Believer\"... Then I saw her face.",
  },
  {
    joke: "What do you call two barracuda fish?  A Pairacuda!",
  },
  {
    joke: "Why is Peter Pan always flying? Because he Neverlands.",
  },
  {
    joke: "What do you do on a remote island? Try and find the TV island it belongs to.",
  },
  {
    joke: "Did you know that protons have mass? I didn't even know they were catholic.",
  },
  {
    joke: "Dad I’m hungry’ … ‘Hi hungry I’m dad",
  },
  {
    joke: "I was fired from the keyboard factory yesterday.  I wasn't putting in enough shifts.",
  },
  {
    joke: "Whoever invented the knock-knock joke should get a no bell prize.",
  },
  {
    joke: "Wife: Honey I’m pregnant.\n\nMe: Well…. what do we do now?\n\nWife: Well, I guess we should go to a baby doctor.\n\nMe: Hm.. I think I’d be a lot more comfortable going to an adult doctor.",
  },
  {
    joke: "Why was Pavlov's beard so soft?  Because he conditioned it.",
  },
  {
    joke: "Do I enjoy making courthouse puns? Guilty",
  },
  {
    joke: "Why did the kid throw the clock out the window? He wanted to see time fly!",
  },
  {
    joke: "Hear about the new restaurant called Karma? There’s no menu: You get what you deserve.",
  },
  {
    joke: "Why couldn't the kid see the pirate movie? Because it was rated arrr!",
  },
  {
    joke: "Man, I really love my furniture... me and my recliner go way back.",
  },
  {
    joke: "What did the traffic light say to the car as it passed? \"Don't look I'm changing!\"",
  },
  {
    joke: "Why did the man run around his bed? Because he was trying to catch up on his sleep!",
  },
  {
    joke: "What did one wall say to the other wall? I'll meet you at the corner!",
  },
  {
    joke: "Sometimes I tuck my knees into my chest and lean forward.  That’s just how I roll.",
  },
  {
    joke: "Conjunctivitis.com – now that’s a site for sore eyes.",
  },
  {
    joke: "How many South Americans does it take to change a lightbulb? A Brazilian",
  },
  {
    joke: "I don't trust stairs. They're always up to something.",
  },
  {
    joke: "Which is the fastest growing city in the world? Dublin'",
  },
  {
    joke: "A police officer caught two kids playing with a firework and a car battery. He charged one and let the other one off.",
  },
  {
    joke: "What is the difference between ignorance and apathy?\n\nI don't know and I don't care.",
  },
  {
    joke: "I went to a Foo Fighters Concert once... It was Everlong...",
  },
  {
    joke: "Some people eat light bulbs. They say it's a nice light snack.",
  },
  {
    joke: "I cut my finger chopping cheese, but I think that I may have grater problems.",
  },
  {
    joke: "What do you get hanging from Apple trees? Sore arms.",
  },
  {
    joke: "Last night me and my girlfriend watched three DVDs back to back. Luckily I was the one facing the TV.",
  },
  {
    joke: "I got a reversible jacket for Christmas, I can't wait to see how it turns out.",
  },
  {
    joke: "What did Romans use to cut pizza before the rolling cutter was invented? Lil Caesars",
  },
  {
    joke: "My pet mouse 'Elvis' died last night. He was caught in a trap..",
  },
  {
    joke: "Never take advice from electrons. They are always negative.",
  },
  {
    joke: "Why are oranges the smartest fruit? Because they are made to concentrate.",
  },
  {
    joke: "What did the beaver say to the tree? It's been nice gnawing you.",
  },
  {
    joke: "How do you fix a damaged jack-o-lantern? You use a pumpkin patch.",
  },
  {
    joke: "What did the late tomato say to the early tomato? I’ll ketch up",
  },
  {
    joke: "I have kleptomania, but when it gets bad, I take something for it.",
  },
  {
    joke: "I used to be addicted to soap, but I'm clean now.",
  },
  {
    joke: "When is a door not a door? When it's ajar.",
  },
  {
    joke: "I made a belt out of watches once... It was a waist of time.",
  },
  {
    joke: 'Why did Mozart kill all his chickens?\nBecause when he asked them who the best composer was, they\'d all say "Bach bach bach!"',
  },
  {
    joke: "This furniture store keeps emailing me, all I wanted was one night stand!",
  },
  {
    joke: "How do you find Will Smith in the snow?  Look for fresh prints.",
  },
  {
    joke: "I just read a book about Stockholm syndrome. It was pretty bad at first, but by the end I liked it.",
  },
  {
    joke: "Why do trees seem suspicious on sunny days? Dunno, they're just a bit shady.",
  },
  {
    joke: "If at first you don't succeed, sky diving is not for you!",
  },
  {
    joke: "What kind of music do mummy's like? Rap",
  },
  {
    joke: "What's large, grey, and doesn't matter?\nAn irrelephant.",
  },
  {
    joke: "A book just fell on my head. I only have my shelf to blame.",
  },
  {
    joke: "What did the dog say to the two trees? Bark bark.",
  },
  {
    joke: "If a child refuses to sleep during nap time, are they guilty of resisting a rest?",
  },
  {
    joke: "Why can't your nose be 12 inches long? Because then it'd be a foot!",
  },
  {
    joke: "Have you ever heard of a music group called Cellophane? They mostly wrap.",
  },
  {
    joke: "What did the mountain climber name his son? Cliff.",
  },
  {
    joke: "Why should you never trust a pig with a secret? Because it's bound to squeal.",
  },
  {
    joke: "Why are mummys scared of vacation? They're afraid to unwind.",
  },
  {
    joke: "Whiteboards ... are remarkable.",
  },
  {
    joke: "What kind of dinosaur loves to sleep? A stega-snore-us.",
  },
  {
    joke: "What’s Forest Gump’s Facebook password? 1forest1",
  },
  {
    joke: "What kind of tree fits in your hand? A palm tree!",
  },
  {
    joke: "Whenever the cashier at the grocery store asks my dad if he would like the milk in a bag he replies, ‘No, just leave it in the carton!’",
  },
  {
    joke: "I used to be addicted to the hokey pokey, but I turned myself around.",
  },
  {
    joke: "How many tickles does it take to tickle an octopus? Ten-tickles!",
  },
  {
    joke: "Me: If humans lose the ability to hear high frequency volumes as they get older, can my 4 week old son hear a dog whistle?\n\nDoctor: No, humans can never hear that high of a frequency no matter what age they are.\n\nMe: Trick question... dogs can't whistle.",
  },
  {
    joke: "What musical instrument is found in the bathroom? A tuba toothpaste.",
  },
  {
    joke: "My boss told me to attach two pieces of wood together... I totally nailed it!",
  },
  {
    joke: "What was the pumpkin’s favorite sport?\n\nSquash.",
  },
  {
    joke: "Recent survey revealed 6 out of 7 dwarf's aren't happy.",
  },
  {
    joke: "What do you call corn that joins the army? Kernel.",
  },
  {
    joke: "I've been trying to come up with a dad joke about momentum . . . but I just can't seem to get it going.",
  },
  {
    joke: "‘Put the cat out’ … ‘I didn’t realize it was on fire",
  },
  {
    joke: "Is there a hole in your shoe? No… Then how’d you get your foot in it?",
  },
  {
    joke: "Every night at 11:11, I make a wish that someone will come fix my broken clock.",
  },
  {
    joke: "Two muffins were sitting in an oven, and the first looks over to the second, and says, “man, it’s really hot in here”. The second looks over at the first with a surprised look, and answers, “WHOA, a talking muffin!”",
  },
  {
    joke: "What's the difference between a guitar and a fish? You can tune a guitar but you can't \"tuna\" fish!",
  },
  {
    joke: "Did you hear that the police have a warrant out on a midget psychic ripping people off? It reads “Small medium at large.”",
  },
  {
    joke: "Why don't sharks eat clowns?  Because they taste funny.",
  },
  {
    joke: "Just read a few facts about frogs. They were ribbiting.",
  },
  {
    joke: "Two satellites decided to get married. The wedding wasn't much, but the reception was incredible.",
  },
  {
    joke: "What do you get if you put a duck in a cement mixer? Quacks in the pavement.",
  },
  {
    joke: "They tried to make a diamond shaped like a duck. It quacked under the pressure.",
  },
  {
    joke: "Why do bears have hairy coats? Fur protection.",
  },
  {
    joke: "What do you get when you cross a bee and a sheep? A bah-humbug.",
  },
  {
    joke: "What did one snowman say to the other snow man? Do you smell carrot?",
  },
  {
    joke: "Why do bees hum? Because they don't know the words.",
  },
  {
    joke: "What do you call a troublesome Canadian high schooler? A poutine.",
  },
  {
    joke: "A magician was driving down the street and then he turned into a driveway.",
  },
  {
    joke: "Don't trust atoms. They make up everything.",
  },
  {
    joke: "If you walk into a forest and cut down a tree, but the tree doesn't understand why you cut it down, do you think it's stumped?",
  },
  {
    joke: "Where do bees go to the bathroom?  The BP station.",
  },
  {
    joke: "What is the best way to carve?\nWhittle by whittle.",
  },
  {
    joke: "Why did the tree go to the dentist? It needed a root canal.",
  },
  {
    joke: "It was raining cats and dogs the other day. I almost stepped in a poodle.",
  },
  {
    joke: "Why do bananas have to put on sunscreen before they go to the beach? Because they might peel!",
  },
  {
    joke: "What do you call a bee that lives in America? A USB.",
  },
  {
    joke: "I was wondering why the frisbee was getting bigger, then it hit me.",
  },
  {
    joke: "What's the difference between a hippo and a zippo? One is really heavy, the other is a little lighter.",
  },
  {
    joke: "I couldn't figure out how the seat belt worked. Then it just clicked.",
  },
  {
    joke: "What did the green grape say to the purple grape?\nBREATH!!",
  },
  {
    joke: "What do you call a dad that has fallen through the ice? A Popsicle.",
  },
  {
    joke: 'Two parrots are sitting on a perch. One turns to the other and asks, "do you smell fish?"',
  },
  {
    joke: "Bad at golf? Join the club.",
  },
  {
    joke: "What do you call a pile of cats?  A Meowtain.",
  },
  {
    joke: "How do hens stay fit? They always egg-cercise!",
  },
  {
    joke: "Can a kangaroo jump higher than the Empire State Building? Of course. The Empire State Building can't jump.",
  },
  {
    joke: "What do you give a sick lemon? Lemonaid.",
  },
  {
    joke: "What do you call an old snowman? Water.",
  },
  {
    joke: "I just got fired from a florist, apparently I took too many leaves.",
  },
  {
    joke: "Why don’t skeletons ever go trick or treating? Because they have nobody to go with.",
  },
  {
    joke: "What does a female snake use for support? A co-Bra!",
  },
  {
    joke: '"Dad, I\'m cold."\n"Go stand in the corner, I hear it\'s 90 degrees."',
  },
  {
    joke: "Child: Dad, make me a sandwich. Dad: Poof! You're a sandwich.",
  },
  {
    joke: "Why are graveyards so noisy? Because of all the coffin.",
  },
  {
    joke: "What kind of bagel can fly? A plain bagel.",
  },
  {
    joke: "How many apples grow on a tree? All of them!",
  },
  {
    joke: "What do you call a careful wolf? Aware wolf.",
  },
  {
    joke: "I was just looking at my ceiling. Not sure if it’s the best ceiling in the world, but it’s definitely up there.",
  },
  {
    joke: "Why do valley girls hang out in odd numbered groups? Because they can't even.",
  },
  {
    joke: "“My Dog has no nose.” “How does he smell?” “Awful”",
  },
  {
    joke: "What do you call a cow with no legs? Ground beef.",
  },
  {
    joke: "Why did the half blind man fall in the well? Because he couldn't see that well!",
  },
  {
    joke: "As I suspected, someone has been adding soil to my garden. The plot thickens.",
  },
  {
    joke: "It’s hard to explain puns to kleptomaniacs, because they take everything literally.",
  },
  {
    joke: "It's difficult to say what my wife does, she sells sea shells by the sea shore.",
  },
  {
    joke: "Why did Dracula lie in the wrong coffin? He made a grave mistake.",
  },
  {
    joke: "What did one plate say to the other plate? Dinner is on me!",
  },
  {
    joke: "what do you call a dog that can do magic tricks? a labracadabrador",
  },
  {
    joke: "Doctor: Do you want to hear the good news or the bad news?\nPatient: Good news please.\nDoctor: we're naming a disease after you.",
  },
  {
    joke: "Atheism is a non-prophet organisation.",
  },
  {
    joke: "I tried to write a chemistry joke, but could never get a reaction.",
  },
  {
    joke: "What do you call a monkey in a mine field? A babooooom!",
  },
  {
    joke: "I cut my finger cutting cheese. I know it may be a cheesy story but I feel grate now.",
  },
  {
    joke: "How do you steal a coat? You jacket.",
  },
  {
    joke: "Why don't you find hippopotamuses hiding in trees?\nThey're really good at it.",
  },
  {
    joke: "I’m reading a book on the history of glue – can’t put it down.",
  },
  {
    joke: "Want to hear a joke about construction? Nah, I'm still working on it.",
  },
  {
    joke: "Just watched a documentary about beavers… It was the best damn program I’ve ever seen.",
  },
];

const DadJokes = () => {
  const [joke, setJoke] = useState("");

  useEffect(() => {
    getRandom();
  }, []);

  const getRandom = () => {
    const numJokes = jokes.length;
    const rndmNum = Math.floor(Math.random() * numJokes);
    setJoke(jokes[rndmNum].joke);
  };

  return (
    <Text
      color="muted"
      size="lead"
    >
      <em>{joke}</em>
    </Text>
  );
};

export { DadJokes };
