import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Notice,
  TextField,
  styled,
  Text,
  Flex,
} from "@boligportal/juice";
import emailSpellChecker from "@zootools/email-spell-checker";
import { App } from "components/app";
import {
  emailValidation,
  fullNameValidation,
  passwordValidation,
} from "components/forms/validation";
import { ModalActions } from "components/modal_controller";
import { useDebounce } from "hooks/useDebounce";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { parseFullName } from "lib/utils";
import { CustomerSupportBanner } from "../CustomerSupportBanner";
import { AuthView, LoginSignupContextTranslation } from "../auth_modal";
import { OnboardingModal } from "./onboarding/OnboardingDialog";
import { SignUpForm } from "./signup_form";

type SignUpWithEmailFormFields = {
  fullName: string;
  username: string;
  password: string;
  signupPermissions: {
    accepted_terms: boolean;
    send_newsletter: boolean;
    send_relevant_content: boolean;
    send_statistics_emails: boolean;
  };
};

const StyledSuggestButton = styled(Button)`
  color: ${(props) => props.theme.color.context.warning[700]} !important;

  &:hover {
    text-decoration-color: ${(props) =>
      props.theme.color.context.warning[700]} !important;
  }
`;

const HINT_TIMEOUT = 500;

const useAuthShowAcceptTermsError = (termsAccepted: boolean) => {
  const [showTermsError, setShowTermsError] = useState(false);
  useEffect(() => {
    if (termsAccepted) {
      setShowTermsError(false);
    } else {
      setShowTermsError(true);
    }
  }, [termsAccepted]);

  return {
    showTermsError,
    setShowTermsError,
  };
};

const SignUpWithEmail = (props: {
  authActions;
  context?: SignupContext;
  modalActions: ModalActions;
}) => {
  const { authActions, context, modalActions } = props;
  const emailRequiredTrans = t("required_field.email");
  const passwordRequiredTrans = t("required_field.password");
  const fullNameRequiredTrans = t("required_field.fullName");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const {
    formState: { errors },
    setError,
    setValue,
    register,
    handleSubmit,
    watch,
  } = useForm<SignUpWithEmailFormFields>({
    mode: "onChange",
    defaultValues: {
      signupPermissions: {
        send_relevant_content: true,
        send_statistics_emails: true,
      },
    },
  });

  const { username, signupPermissions } = watch();

  const { showTermsError, setShowTermsError } = useAuthShowAcceptTermsError(
    signupPermissions.accepted_terms,
  );

  const debouncedUsername = useDebounce(username, HINT_TIMEOUT);

  useEffect(() => {
    Tracking.trackVirtualPageview(
      "/signup-with-email",
      t("signup_with_email.title.Sign up"),
    );
  }, []);

  useEffect(() => {
    if (debouncedUsername) {
      const suggestedEmail = emailSpellChecker.run({
        email: debouncedUsername,
      });

      setSuggestion("");

      if (suggestedEmail && debouncedUsername !== suggestedEmail.full) {
        setSuggestion(suggestedEmail.full);
      }
    }
  }, [debouncedUsername]);

  const handleUserSuggestion = () => setValue("username", suggestion);

  const onSubmit = (data: SignUpWithEmailFormFields) => {
    const parsedName = parseFullName(data.fullName);
    const fields: Partial<keyof SignUpWithEmailFormFields>[] = [
      "fullName",
      "username",
      "password",
    ];

    if (showTermsError) {
      return;
    }

    setDisableSubmitButton(true);
    authActions
      .signup(
        parsedName.firstName,
        parsedName.lastName,
        data.username,
        data.password,
        data.signupPermissions,
        App.settings.language,
      )
      .then((response) => {
        setDisableSubmitButton(false);
        if (!response.errors && data.signupPermissions.accepted_terms) {
          modalActions.showModal(
            <OnboardingModal
              context={context}
              onClose={() => authActions.onLoginSuccessful()}
            />,
          );
        } else {
          if (!data.signupPermissions.accepted_terms) {
            setShowTermsError(true);
          }
          const serverErrors = response.errors;
          fields.forEach((key) => {
            if (!(key in serverErrors)) {
              return;
            }
            const err = serverErrors[key];
            if (err.length > 0) {
              setError(key, {
                message: err[0],
              });
            }
          });
        }
      });
  };
  const getTitle = () => {
    if (context) {
      return `${t("signup_view.title")} ${
        LoginSignupContextTranslation()[context]
      }`;
    }
    return t("signup_view.title");
  };

  const switchToLoginView = () => authActions.switchView(AuthView.LOGIN);

  return (
    <>
      <DialogHeader>{getTitle()}</DialogHeader>
      <DialogForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            testId={"signUpName"}
            label={t("signup.full_name")}
            required
            type="text"
            errorText={errors.fullName?.message?.toString()}
            helpText={t("signup.full_name.help_text")}
            {...register("fullName", {
              validate: (value: string) =>
                fullNameValidation(value) || fullNameRequiredTrans,
            })}
          />

          <TextField
            testId={"signUpUsername"}
            label={t("signup.E-mail address")}
            required
            type="text"
            errorText={errors.username?.message?.toString()}
            {...register("username", {
              validate: (value: string) =>
                emailValidation(value) || emailRequiredTrans,
            })}
          />

          {suggestion && (
            <Box mt={-2}>
              <Notice
                type="warning"
                size="small"
              >
                <StyledSuggestButton
                  text
                  onClick={handleUserSuggestion}
                  fontSize="tiny"
                >
                  {t("signup.username.hint", {
                    suggestion,
                  })}
                </StyledSuggestButton>
              </Notice>
            </Box>
          )}

          <TextField
            testId={"signUpPassword"}
            type="password"
            required
            label={t("signup.Password")}
            helpText={t("signup.password.help_text")}
            errorText={errors.password?.message?.toString()}
            {...register("password", {
              validate: (value: string) =>
                passwordValidation(value) || passwordRequiredTrans,
            })}
          />

          <Box pt={2}>
            <SignUpForm
              register={register}
              showError={showTermsError}
            />
          </Box>
        </DialogContent>

        <DialogFooter>
          <Flex
            column
            flexGrow
          >
            <Button
              testId="signUpButton"
              disabled={disableSubmitButton}
              variant="branded"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              fullWidth
            >
              {t("signup_with_email.button.Sign up")}
            </Button>
            <Box
              width="100%"
              textAlign="center"
              mt={2}
            >
              <Text>{t("signup_view.footer.Already have an account?")} </Text>
              <Button
                testId={"loginHere"}
                variant="branded"
                text
                onClick={switchToLoginView}
              >
                {t("signup_view.footer.Log in here")}
              </Button>
            </Box>
          </Flex>
        </DialogFooter>
        <CustomerSupportBanner />
      </DialogForm>
    </>
  );
};

export { SignUpWithEmail };
