import * as React from "react";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { ManualAddressDenmark } from "./ManualAddressDenmark";
import { ManualAddressSweden } from "./ManualAddressSweden";

type Props =
  | React.ComponentProps<typeof ManualAddressSweden>
  | React.ComponentProps<typeof ManualAddressDenmark>;

export const ManualAddress = (props: Props) => {
  if (App.settings.market === Market.BOSTADSPORTAL_SE) {
    return <ManualAddressSweden {...props} />;
  }

  return <ManualAddressDenmark {...props} />;
};
