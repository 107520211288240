import * as React from "react";
import { useFormContext } from "react-hook-form";
import { CheckField } from "@boligportal/juice";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";

const CheckboxFormElement = <T extends any>(
  props: FormElementInterface<T, boolean>,
) => {
  const { register } = useFormContext();
  const [isSaving, setIsSaving] = React.useState(false);
  const fieldname: string = props.fieldname!;

  if (!fieldname) {
    throw new Error("This Component need a fieldname Prop");
  }

  const handleInputCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsSaving(true);

    props
      .updateTask(fieldname, event.target.checked)
      .then((response) => {
        if (response.success) {
          // Happy Path
        } else {
          // Sad Path
        }
        setIsSaving(false);
      })
      .catch(() => {
        // Sad Path
        setIsSaving(false);
      });
  };

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel=""
    >
      <CheckField
        {...register(fieldname)}
        label={props.fieldLabel}
        disabled={isSaving || props.isLocked}
        onChange={handleInputCheckChange}
        previousValue={props.previousValue}
        defaultChecked={props.defaultValue}
      />
    </LockableFormField>
  );
};

export { CheckboxFormElement };
