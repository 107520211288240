import { fetchFromAPI } from "lib/api";
import { DocumentsResponse } from "./types";

export const getDocuments = async (userToken: string) =>
  getTenantHubDocuments(userToken);

// using fetchFromAPI bacause juice http client doesnt support language prefix
const getTenantHubDocuments = async (
  userToken: string,
): Promise<DocumentsResponse> => {
  const response = await fetchFromAPI(
    `/api/tenant-hub/${userToken}/documents`,
    {
      method: "GET",
    },
  );
  return response.json();
};
