import { TenancyFormData } from "features/rentable_tenancy/types/TenancyFormData";

export const ignorePhoneWithOnlyLanguageCodes = (
  formData: TenancyFormData,
): TenancyFormData => ({
  ...formData,
  tenants: formData.tenants.map((tenant) => ({
    ...tenant,
    phone: ignoreLanguageCode(tenant.phone),
  })),
});

const ignoreLanguageCode = (phone: string | undefined) => {
  if (!phone || phone.length < 4) {
    return undefined;
  }
  return phone;
};
