import * as React from "react";
import { Flex, MenuBaseItem, styled } from "@boligportal/juice";
import { App } from "components/app";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { useOldModal } from "components/modal_controller";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { HeaderAction, TrackingEvent } from "lib/tracking/events";
import { TopNavItemCommercialMenu } from "../components/SE/TopNavItemCommercialMenu";
import { TopNavItemCreateMenu } from "../components/TopNavItemCreateMenu";
import { TopNavItemDivider } from "../components/TopNavItemDivider";
import { TopNavItemRentableAdminMenu } from "../components/TopNavItemRentableAdminMenu";
import { TopNavItemUserFavorites } from "../components/TopNavItemUserFavorites";
import { TopNavItemUserInbox } from "../components/TopNavItemUserInbox";
import { TopNavItemUserRentable } from "../components/TopNavItemUserRentable";

const LoginItem = styled.a<{ inverseColor: boolean }>`
  padding: 0 ${(props) => props.theme.unit(2)};
  border-radius: ${(props) => props.theme.borderRadius.md};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.inverseColor ? props.theme.color.bg.base : props.theme.color.border};
  color: ${(props) =>
    props.inverseColor
      ? props.theme.color.bg.base
      : props.theme.color.text.base};
  line-height: ${(props) => props.theme.unit(3.5)};

  &:hover {
    text-decoration: none;
    color: ${(props) =>
      props.inverseColor
        ? props.theme.colorPalette.gray[40]
        : props.theme.colorPalette.gray[300]};
  }
`;

export type ItemProps = {
  inverseColor: boolean;
  onItemClick: (
    item: MenuItem,
    event?: React.SyntheticEvent,
    context?: SignupContext,
  ) => void;
  isLoggedIn?: boolean;
};

type Props = {
  user: User | null;
  inverseColor: boolean;
};

export type MenuItem = MenuBaseItem & {
  url?: string;
  trackingAction?: HeaderAction;
  context?: SignupContext;
};

export const TopNav = ({ user, inverseColor }: Props) => {
  const modalActions = useOldModal();
  const { modalActionsInstance } = useAuthActions({});

  const openLoginModal = () => {
    modalActions.showModal(<AuthModal view={AuthView.LOGIN} />);
  };

  const handleItemClick = async (
    item: MenuItem,
    event?: React.SyntheticEvent,
  ) => {
    if (event) {
      event.preventDefault();
    }

    const { url, trackingAction, context } = item;

    if (trackingAction) {
      await TrackingEvent.clickNavigationBar(trackingAction);
    }

    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={url}
          context={context}
        />,
      );
    } else {
      window.location.href = url!;
    }
  };

  const handleAdminItemClick = async (
    item: MenuItem,
    event?: React.SyntheticEvent,
  ) => {
    if (event) {
      event.preventDefault();
    }

    const { url, trackingAction } = item;

    if (trackingAction) {
      await TrackingEvent.clickNavigationBar(trackingAction);
    }

    window.location.href = url!;
  };

  return (
    <Flex
      pl={2}
      pr={1}
      align="center"
      hidden={{
        xs: true,
        xl: false,
      }}
    >
      <TopNavItemRentableAdminMenu
        inverseColor={inverseColor}
        onItemClick={handleAdminItemClick}
      />

      <TopNavItemDivider inverseColor={inverseColor} />

      {App.settings.market === Market.BOSTADSPORTAL_SE && (
        <>
          <TopNavItemCommercialMenu
            inverseColor={inverseColor}
            onItemClick={handleAdminItemClick}
          />
          <TopNavItemDivider inverseColor={inverseColor} />
        </>
      )}

      <TopNavItemCreateMenu
        inverseColor={inverseColor}
        isLoggedIn={!!user}
        onItemClick={handleItemClick}
      />

      <TopNavItemDivider inverseColor={inverseColor} />

      <TopNavItemUserRentable
        inverseColor={inverseColor}
        onItemClick={handleItemClick}
      />

      <TopNavItemDivider inverseColor={inverseColor} />

      <TopNavItemUserInbox
        inverseColor={inverseColor}
        onItemClick={handleItemClick}
      />

      <TopNavItemDivider inverseColor={inverseColor} />

      <TopNavItemUserFavorites
        inverseColor={inverseColor}
        onItemClick={handleItemClick}
      />

      <TopNavItemDivider inverseColor={inverseColor} />

      {!user && (
        <LoginItem
          inverseColor={inverseColor}
          onClick={openLoginModal}
          href="#"
        >
          {t("header.Login")}
        </LoginItem>
      )}
    </Flex>
  );
};
