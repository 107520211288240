import { useMemo } from "react";
import { Defect } from "apps/moving_reports/interfaces/defect";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { MovingReportForTenant } from "apps/moving_reports/tenant_app/interfaces/movingReportForTenant";

export type CombinedDefect = Omit<Defect, "created_date"> & {
  room: string;
  unit: string;
  created_date: Date;
};

export const useDefectsFromReport = (
  report?: MovingReport | MovingReportForTenant,
): CombinedDefect[] => {
  const defects = useMemo(() => {
    let combinedDefects: CombinedDefect[] = [];

    if (report) {
      report.rooms.forEach((room) => {
        room.units.forEach((unit) => {
          unit.defects.forEach((defect) => {
            combinedDefects = [
              ...combinedDefects,
              {
                ...defect,
                room: room.label,
                unit: unit.label,
                created_date: new Date(defect.created_date),
              },
            ];
          });
        });
      });
    }

    return combinedDefects;
  }, [report]);

  return defects;
};
