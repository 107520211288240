import { skipPublishAd } from "lib/customerServiceApi";
import { SkipPublishAdResponseInterface } from "../../../interfaces";

export async function skipPublishAdTask(
  adId: number,
): Promise<SkipPublishAdResponseInterface> {
  try {
    const res = await skipPublishAd(adId);
    return res;
  } catch (e) {
    return {
      success: false,
      errors: e,
    };
  }
}
