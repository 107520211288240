import * as React from "react";
import { Box, Text } from "@boligportal/juice";
import { GoBackToAutoAddressFormButton } from "features/address/common/components/GoBackToAutoAddressFormButton";
import { GotoManualAddressFormButton } from "features/address/common/components/GotoManualAddressFormButton";
import { AddressManualSweeden } from "features/address/se/AddressManualSweeden";
import { AddressSuggestionSweeden } from "features/address/se/AddressSuggestionSweeden";

interface Props {
  initialAddress: string;
  onAddressUpdated: (address: string | Object) => void;
  addressViewState: "manual" | "auto";
  setAddressViewState: React.Dispatch<React.SetStateAction<"manual" | "auto">>;
}

export const CS_EditAddress = ({
  onAddressUpdated,
  initialAddress,
  addressViewState,
  setAddressViewState,
}: Props) => (
  <>
    {addressViewState === "auto" && (
      <>
        <Box>
          <Text size="h1">{initialAddress}</Text>
        </Box>
        <Text size="small">Find new address</Text>
        <AddressSuggestionSweeden onAddress={onAddressUpdated} />
        <Box pt={1}>
          <GotoManualAddressFormButton
            hideHelpText
            onClick={() => {
              setAddressViewState("manual");
            }}
          />
        </Box>
      </>
    )}
    {addressViewState === "manual" && (
      <>
        <Box pb={1}>
          <GoBackToAutoAddressFormButton
            hideHelpText
            onClick={() => {
              setAddressViewState("auto");
            }}
          />
        </Box>
        <AddressManualSweeden />
      </>
    )}
  </>
);
