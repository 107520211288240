import { Button, Flex, IconArrowForward, Text } from "@boligportal/juice";
import { PAGE_HOME } from "apps/customer_service";
import { AdItem } from "../../ads_page/interfaces";
import { TotalAdsItem } from "./total_ads_item";

interface AllLandslordAdsProps {
  userId?: number;
  items: AdItem[];
  totalCount: number;
}
const AllLandslordAds = ({
  items,
  totalCount,
  userId,
}: AllLandslordAdsProps) => {
  const numberOfAds = items.length;

  return (
    <>
      {numberOfAds === 0 && (
        <Text>
          <em>This user does not have any ads</em>
        </Text>
      )}
      {numberOfAds !== 0 && (
        <>
          {items.map((item) => (
            <TotalAdsItem
              key={`total-ads-item-${item.id}`}
              item={item}
            />
          ))}
          {totalCount > numberOfAds && (
            <Flex
              mt={2}
              gap
            >
              <Flex flexPush>
                <Button
                  variant="subtle"
                  as="a"
                  href={`${PAGE_HOME}/ads/all/?landlordId=${userId}`}
                  iconAfter={IconArrowForward}
                >{`See all ${totalCount} ads`}</Button>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export { AllLandslordAds };
