import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Link,
  Notice,
  styled,
  Text,
  TextField,
} from "@boligportal/juice";
import { App } from "components/app";
import { LoginResult } from "lib/api";
import { t } from "lib/i18n";

interface LoginViewState {
  alertMessage?: string | null;
  fieldErrors?: {
    [field: string]: string;
  };
  wasValidated?: boolean;
  email?: string;
}

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
`;

type Props = {
  title: string;
  login: (username: string, password: string) => Promise<LoginResult>;
  onLoginSuccessful: () => void;
  onSwitchToSignUp: () => void;
};

export const LoginWithEmail = ({
  title,
  login,
  onLoginSuccessful,
  onSwitchToSignUp,
}: Props) => {
  const [loginViewState, setLoginViewState] = useState<LoginViewState>({
    alertMessage: null,
    fieldErrors: {},
    wasValidated: false,
    email: "",
  });

  const { register, handleSubmit, watch } = useForm<{
    username: string;
    password: string;
  }>({
    mode: "onChange",
  });

  const watchedFields = watch();

  const handleLogin = async (data: { username: string; password: string }) => {
    const loginResult = await login(data.username, data.password);

    if (loginResult === LoginResult.successful) {
      onLoginSuccessful();
    } else if (loginResult === LoginResult.invalid_password) {
      setLoginViewState({
        alertMessage: t("Invalid username/password."),
      });
    } else {
      setLoginViewState({
        alertMessage: t("Something went wrong. Try again."),
      });
    }
  };

  return (
    <>
      <DialogHeader>{title}</DialogHeader>
      <DialogForm
        onSubmit={handleSubmit(handleLogin)}
        noValidate
      >
        <DialogContent>
          {loginViewState.alertMessage && (
            <Notice type="danger">{loginViewState.alertMessage}</Notice>
          )}

          <TextField
            testId={"loginUsername"}
            type="text"
            {...register("username")}
            label={t("signup.E-mail address")}
          />

          <TextField
            testId={"loginPassword"}
            type="password"
            {...register("password")}
            label={t("login.Password")}
          />

          <Button
            testId={"loginSubmit"}
            type="submit"
            variant="branded"
            fullWidth
            disabled={!watchedFields["username"] || !watchedFields["password"]}
          >
            {t("login_view.login_button")}
          </Button>
          <Box
            textAlign="center"
            mt={2}
          >
            <StyledLink
              href={App.settings.routes.reset_password}
              newTab
            >
              {t("login_view.footer.Forgot password?")}
            </StyledLink>
          </Box>
        </DialogContent>
      </DialogForm>
      <DialogFooter>
        <Box
          width="100%"
          textAlign="center"
        >
          <Text>{t("login_view.footer.New user?")} </Text>
          <Button
            variant="branded"
            text
            onClick={onSwitchToSignUp}
            testId={"switchToSignUpView"}
          >
            {t("login_view.footer.Sign up here")}
          </Button>
        </Box>
      </DialogFooter>
    </>
  );
};
