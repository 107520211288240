import { formatMonths, formatDays, format24Hours } from "lib/date";
import { t } from "lib/i18n";
import { App } from "./app";
import { Plan } from "./interfaces/plan";
import { Market } from "./markets/market_settings";

interface PlanComparison {
  percentDiscount: number;

  plan1: Plan;
  plan1DurationDays: number;
  plan1Price: number;

  plan2: Plan;
  plan2DurationDays: number;
  plan2Price: number;
}

const DURATION_OF_4_WEEK_PLAN = 28;

const calculateDiscount = (
  plan: Plan,
  plans: Plan[],
): PlanComparison | null => {
  /**
   * Calculate how much `plan` compares to the plan with the cheapest intro in `plans`
   *
   * Return a comparison between this plan and the plan with cheapest intro plan
   *
   * Here is an example calculation:
   * We have a plan that is 3,99 for 4 days + 39 for 28 days
   * The other plan is 59,99 for 62 days
   *
   * So I am comparing the price for 60 days (4 + 28 + 28 days) on the first plan: 3,99+39+39 = 81,99
   * to 62 days on the other plan = 59,99
   *
   * The reason for comparing 60 days to 62 days is that we are conservative: you actually save a bit more because you get 2 extra days
   *
   * (81,99-59,99)/(81,99)
   *
   * so you save 22, and then divide that with the price you would have paid (81,99)
   * This gives 26,8%
   */

  const getNextPlan = (plan: Plan): Plan | null => {
    /**
     * Return the next plan for the plan, assuming that undefined next_plan means it repeats.
     * If null is returned it means the subscription has stopped.
     */
    if (plan.next_plan === undefined) {
      // If next plan is undefined, assume it repeats
      return plan;
    }
    if (plan.next_plan === null) {
      // Subscription stops here
      return null;
    } else {
      return plan.next_plan;
    }
  };

  let plan1 = getPlanWithCheapestIntro(plans);
  let plan1DurationDays = plan1.duration_days;
  let plan1Price = plan1.price.total;

  let plan2 = plan;
  let plan2DurationDays = plan2.duration_days;
  let plan2Price = plan2.price.total;

  // Compare price of this plan versus the plan with cheapest intro, counting how much it would
  // cost to be on the plan with cheapest intro for just enough days without going over this plan's duration.
  while (plan1Price < plan2Price) {
    // console.log(plan1DurationDays, plan2DurationDays, plan1Price, plan2Price);
    if (plan1DurationDays == plan2DurationDays) {
      break;
    } else if (plan1DurationDays < plan2DurationDays) {
      const nextPlan1 = getNextPlan(plan1);
      if (nextPlan1 === null) {
        break;
      }
      plan1 = nextPlan1;
      plan1Price += plan1.price.total;
      plan1DurationDays += plan1.duration_days;
    } else if (plan2DurationDays < plan1DurationDays) {
      plan2Price += plan2.price.total;
      plan2DurationDays += plan2.duration_days;
      const nextPlan2 = getNextPlan(plan2);
      if (nextPlan2 === null) {
        break;
      }
      plan2 = nextPlan2;
    }
  }

  if (plan2DurationDays < plan1DurationDays) {
    // Went a bit longer on plan 2 than plan 1, so subtract 1 period of plan 2 from plan2
    plan2Price += plan2.price.total;
    plan2DurationDays += plan2.duration_days;
  }

  const percentDiscount = (100 * (plan1Price - plan2Price)) / plan1Price;
  // console.log(`Save ${plan1Price - plan2Price} on ${plan2DurationDays} days of plan ${plan2.plan_id} versus ${plan1DurationDays} days of plan ${plan1.plan_id}`);
  if (percentDiscount < 1) {
    // No discount if less than 1 percent (or negative)
    return null;
  } else {
    return {
      percentDiscount: percentDiscount,
      plan1,
      plan1DurationDays,
      plan1Price,
      plan2,
      plan2DurationDays,
      plan2Price,
    };
  }
};

const isTrial = (plan: Plan): boolean => {
  // A plan is considered a trial here if it has a next_plan with a longer period than the initial plan
  if (plan.next_plan && plan.next_plan.duration_days > plan.duration_days) {
    return true;
  }

  return false;
};

const formatDuration = (days: number) => {
  if (days % 31 === 0) {
    // If the duration is an exact multiple of 31 days (the longest possible month),
    // then return it as "X months".
    return formatMonths(days / 31);
  } else if (days === 1) {
    return format24Hours();
  } else {
    return formatDays(days);
  }
};

const getPlanWithCheapestIntro = (plans: Plan[]) => {
  let planWithCheapestIntro: Plan = plans[0];
  for (const plan of plans) {
    if (plan.price.total <= planWithCheapestIntro.price.total) {
      planWithCheapestIntro = plan;
    }
  }
  return planWithCheapestIntro;
};

const getFormattedPlanDuration = (plan: Plan) => {
  if (plan.duration_days === DURATION_OF_4_WEEK_PLAN) {
    return t("product_explainer.plan.duration_4_week_plan");
  }
  return t("product_explainer.plan.duration_2_months_plan");
};

const getPlanTitle = (plan: Plan) => {
  if (isTrial(plan)) {
    return App.settings.market === Market.BOLIGPORTAL_DK
      ? t("product_explainer.plan.trial.limited_title_dk_market")
      : t("product_explainer.plan.trial.limited_title_se_market_24h");
  } else if (plan.duration_days === DURATION_OF_4_WEEK_PLAN) {
    return t("product_explainer.plan.1_month.full_access_title.new");
  }
  return t("product_explainer.plan.2_month.full_access_title.new");
};

const isPlanPopular = (plan: Plan) => {
  if (plan.duration_days === DURATION_OF_4_WEEK_PLAN) {
    return true;
  }
  return false;
};

export {
  DURATION_OF_4_WEEK_PLAN,
  isTrial,
  formatDuration,
  getPlanWithCheapestIntro,
  calculateDiscount,
  getFormattedPlanDuration,
  getPlanTitle,
  isPlanPopular,
};
