import React, { useContext } from "react";
import GooglePayButton from "@google-pay/button-react";
import { Stripe } from "@stripe/stripe-js";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { fetchFromAPI } from "lib/api";
import { StripePaymentContext } from "../stripe/StripePaymentProvider";

interface GooglePayPaymentMethodButtonProps {
  selectedPlan: Plan;
  onClick: (paymentMethodType: PaymentMethodType) => void;
  googlePaymentRequest: google.payments.api.PaymentDataRequest;
  stripe: Stripe | null;
}

export const GooglePayPaymentMethodButton: React.FC<
  GooglePayPaymentMethodButtonProps
> = ({ selectedPlan, onClick, googlePaymentRequest, stripe }) => {
  const { setPaymentMethodId, setStripeError } =
    useContext(StripePaymentContext);

  const handleLoadPaymentData = async (
    paymentData: google.payments.api.PaymentData,
  ) => {
    onClick(PaymentMethodType.GooglePay);
    await processPayment(paymentData);
  };

  const processPayment = async (
    paymentData: google.payments.api.PaymentData,
  ) => {
    if (!stripe || !selectedPlan) return;

    const {
      error: backendError,
      clientSecret,
      payment_method_id: paymentMethodId,
    } = await fetchFromAPI("/api/payments/google-pay-link", {
      method: "POST",
      body: JSON.stringify({
        token: paymentData.paymentMethodData.tokenizationData.token,
        plan_id: selectedPlan.plan_id,
      }),
    }).then((response) => response.json());

    if (backendError) {
      console.error("Backend error:", backendError);
      return;
    }

    try {
      let result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: {
            token: JSON.parse(
              paymentData.paymentMethodData.tokenizationData.token,
            )["id"],
          },
        },
      });

      if (result.error) {
        setStripeError(result.error);
        return;
      }

      if (result.paymentIntent?.status === "requires_action") {
        result = await stripe.confirmCardPayment(clientSecret);
      }

      if (result.paymentIntent?.status === "succeeded") {
        setPaymentMethodId(paymentMethodId);
      }
    } catch (error) {
      setStripeError(error);
      console.error("Error confirming card payment:", error);
    } finally {
      setPaymentMethodId(paymentMethodId);
    }
  };

  return (
    <GooglePayButton
      environment={App.settings.env === "production" ? "PRODUCTION" : "TEST"}
      buttonColor="default"
      buttonType="pay"
      buttonSizeMode="fill"
      buttonLocale={App.settings.language}
      style={{ height: 56 }}
      paymentRequest={googlePaymentRequest}
      onLoadPaymentData={handleLoadPaymentData}
    />
  );
};
