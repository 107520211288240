import { styled, Flex, Text } from "@boligportal/juice";
import { TransparentButton } from "../components/TransparentButton";

export type MovingReportStatusCell = {
  movingReportStatusCell: {
    color: string;
    title: string;
    subtitle: string;
    onClick?: () => void;
  };
};

export const movingReportStatusCellRenderer = ({
  movingReportStatusCell,
}: MovingReportStatusCell) =>
  movingReportStatusCell.onClick ? (
    <TransparentButton onClick={movingReportStatusCell.onClick}>
      <StateCell
        color={movingReportStatusCell.color}
        title={movingReportStatusCell.title}
        subtitle={movingReportStatusCell.subtitle}
      />
    </TransparentButton>
  ) : (
    <StateCell
      color={movingReportStatusCell.color}
      title={movingReportStatusCell.title}
      subtitle={movingReportStatusCell.subtitle}
    />
  );

const StateCell = ({
  color,
  title,
  subtitle,
}: {
  color: string;
  title: string;
  subtitle: string;
}) => (
  <Flex align="center">
    <StyledStateColor color={color} />
    <Flex column>
      <Text
        weight="semiBold"
        size="small"
      >
        {title}
      </Text>
      <Text
        color="muted"
        size="small"
      >
        {subtitle}
      </Text>
    </Flex>
  </Flex>
);

const StyledStateColor = styled.div<{ color: string }>`
  border-radius: 1px;
  width: 6px;
  height: 46px;
  margin-right: ${(props) => props.theme.unit(1)};
  background-color: ${(props) => props.color};
`;
