export const RentIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2:184)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27512 9.65387C2.62944 11.2349 2.62915 11.313 2.62915 13.0399V21.7935C2.62915 22.3904 2.91008 22.6621 3.53224 22.6621H22.7597C23.3905 22.6621 23.6621 22.3981 23.6621 21.7935V13.0399C23.6621 11.313 23.6621 11.231 22.0202 9.65387C21.4793 9.13435 19.1466 6.89331 15.022 2.93145C14.8035 2.72147 14.5791 2.50601 14.3487 2.2846C13.9835 1.93395 13.6491 1.62912 13.1456 1.62912C12.6422 1.62912 12.3127 1.93555 11.948 2.2846C11.4629 2.74915 11.2349 2.96668 11.264 2.93763C7.39104 6.65954 5.06138 8.89849 4.27512 9.65387Z"
        fill="#F7F7F7"
      />
      <path
        d="M46.2443 8.11053C45.3834 7.18104 44.1809 6.66917 42.8537 6.66917C41.5194 6.66917 40.313 7.17794 39.4561 8.10168C38.5898 9.03559 38.1678 10.3049 38.2669 11.6754C38.4633 14.3794 40.5209 16.579 42.8537 16.579C45.1865 16.579 47.2406 14.3798 47.4401 11.6763C47.5406 10.3181 47.1159 9.05152 46.2443 8.11053V8.11053Z"
        fill="white"
      />
      <path
        d="M50.6404 26.4889H35.0678C34.864 26.4915 34.6621 26.4487 34.4769 26.3635C34.2917 26.2784 34.1278 26.153 33.9972 25.9965C33.7096 25.6527 33.5937 25.1833 33.6795 24.7086C34.0529 22.6373 35.2182 20.8973 37.0498 19.6759C38.6769 18.5915 40.7381 17.9947 42.8541 17.9947C44.9701 17.9947 47.0313 18.592 48.6585 19.6759C50.49 20.8969 51.6553 22.6369 52.0287 24.7082C52.1145 25.1829 51.9986 25.6523 51.711 25.996C51.5804 26.1526 51.4166 26.2781 51.2314 26.3633C51.0462 26.4486 50.8443 26.4915 50.6404 26.4889V26.4889Z"
        fill="white"
      />
      <circle
        cx="23.0123"
        cy="42.9628"
        r="12.0372"
        stroke="white"
        strokeWidth="2.21671"
      />
      <path
        d="M18.4224 46.39H19.7773V43.9479L20.5231 43.0604L22.8811 46.39H24.5301L21.4999 42.1571L24.299 38.8118H22.7498L19.8141 42.3672H19.7773V38.8118H18.4224V46.39ZM25.1078 46.39H26.4155V43.1602C26.4155 42.3357 26.8619 41.8683 27.6024 41.8683C27.8229 41.8683 28.0225 41.8998 28.1328 41.9418V40.7602C28.033 40.7339 27.8807 40.7076 27.7179 40.7076C27.0772 40.7076 26.615 41.112 26.4417 41.7842H26.4155V40.8232H25.1078V46.39Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2:184">
        <rect
          width="57"
          height="57"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
