import { followUp, sendEmail } from "lib/customerServiceApi";
import { TaskResponseInterface } from "../../../interfaces";

export const moveToFollowUpTask = async (
  templateId: number,
  adId: number,
  emailMessageBody: string,
): Promise<TaskResponseInterface> => {
  const followUpResponse = await followUp(adId);

  if (followUpResponse.success === false) {
    return Promise.resolve({
      success: false,
    });
  }

  if (templateId > -1) {
    const sendEmailResponse = await sendEmail(
      templateId,
      adId,
      emailMessageBody,
    );
    return Promise.resolve({
      success: sendEmailResponse.success,
    });
  }

  return Promise.resolve({
    success: followUpResponse.success,
  });
};
