import {
  Box,
  Center,
  Dialog,
  DialogContent,
  DialogHeader,
} from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { PaymentSpinner } from "components/payment_spinner";
import { useMobilePay } from "./useMobilePay";

type Props = {
  plans: Plan[];
  title: string;
  onBeforeRedirect?: () => void;
  onClose?: () => void;
  adId: number | null;
  rentableId: number | null;
  uniquePaywallKey?: string;
};

export const MobilePayPaymentFlow = ({
  adId,
  plans,
  rentableId,
  title,
  onClose,
  onBeforeRedirect,
  uniquePaywallKey,
}: Props) => {
  const { error } = useMobilePay({
    adId,
    plans,
    rentableId,
    onBeforeRedirect,
    uniquePaywallKey,
  });

  return (
    <Dialog
      open
      size="564px"
      hideOnClickOutside={false}
      onClose={onClose}
      dismissable={error ? true : false}
    >
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>
        <Box height="275px">
          {error ? (
            <Center height="100%">{error}</Center>
          ) : (
            <PaymentSpinner submitted={false} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
