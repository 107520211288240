/* eslint-disable comma-dangle */
import { ListItemRendererPosition, Box, Text } from "@boligportal/juice";

export type AddressSearchCandidateType = "address" | "street" | "unknown";

export type AddressSearchCandidate = {
  address: string;
  type: AddressSearchCandidateType | "bulk_create";
  addressIds: string[];
  accessAddressId: string;
  id: string;
  extraText: string | null;
};

export const AddressSearchItemRenderer = (
  { address, type, addressIds, extraText }: AddressSearchCandidate,
  isFocussed: boolean,
  position: ListItemRendererPosition,
) => {
  const borderRadiusTop = position.isFirst ? "sm" : undefined;
  const borderRadiusBottom = position.isLast ? "sm" : undefined;

  return (
    <Box
      px={1.5}
      py={1}
      borderLeft
      borderRight
      borderTop
      borderBottom={position.isLast}
      borderRadiusTL={borderRadiusTop}
      borderRadiusTR={borderRadiusTop}
      borderRadiusBL={borderRadiusBottom}
      borderRadiusBR={borderRadiusBottom}
      bg={isFocussed ? "tint" : "base"}
    >
      <Text block>
        {type === "bulk_create"
          ? `${address} - (Opret alle ${addressIds.length} lejemål i ejendommen)`
          : address}
      </Text>
      {extraText && <Text size="small">{extraText}</Text>}
    </Box>
  );
};
