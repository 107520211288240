import * as React from "react";
import { Control, useWatch } from "react-hook-form";
import { CheckFieldNaked } from "@boligportal/juice";
import { CreateRentableForm, RentableCreationCandidate } from "../types";

type Props = {
  className?: string;
  control: Control<CreateRentableForm>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ShouldCreateAllCheckField = ({
  className,
  control,
  onChange,
}: Props) => {
  const rentables = useWatch({
    control,
    name: "rentables",
  });
  const { checked, isIndeterminate } = getCheckFieldState(rentables);

  return (
    <CheckFieldNaked
      indeterminate={isIndeterminate}
      className={className}
      checked={checked}
      onChange={onChange}
    />
  );
};

// When user has only selected some rentables we want to show the inderterminate icon on the checkfield
const getCheckFieldState = (rentables: RentableCreationCandidate[]) => {
  let isAnySelected = false;
  let isAllSelected = true;

  for (let i = 0; i < rentables.length; i++) {
    const rentable = rentables[i];
    if (!rentable.disabled && rentable.shouldCreate) {
      isAnySelected = true;
    } else if (!rentable.shouldCreate) {
      isAllSelected = false;
    }
  }

  return {
    isIndeterminate: isAnySelected && !isAllSelected,
    checked: isAnySelected,
  };
};
