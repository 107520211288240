import { Flex, Text, Box } from "@boligportal/juice";
import { PAGE_HOME } from "apps/customer_service";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { ClaimItem } from "../interfaces";

interface Props {
  item: ClaimItem;
  landlordId: number;
}

const ApprovalQueueItem = ({ item, landlordId }: Props) => {
  const { floor, street_name, street_number, city, postal_code, ad_id } = item;
  const floorValue = floor ? `- ${floor} floor` : "";

  return (
    <StyledLink
      to={`${PAGE_HOME}/ads/detail/${ad_id}?landlordId=${landlordId}`}
    >
      <Flex>
        <Box>
          <Text size="small">{`${street_name} ${street_number} ${
            floorValue !== "" ? floorValue : ""
          } - ${postal_code} - ${city}`}</Text>
        </Box>
        <Box flexPush>
          <Text
            size="small"
            weight="bold"
          >{`#${ad_id}`}</Text>
        </Box>
      </Flex>
    </StyledLink>
  );
};

export { ApprovalQueueItem };
