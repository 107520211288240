import { IconAlertCircle, Notice } from "@boligportal/juice";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { t } from "lib/i18n";
import { SignedLead } from "../LeadsSections";

type Props = {
  leadsSigned: SignedLead[] | undefined;
  isElectricityIncludedInRent: boolean | undefined;
};

export const InternetNotice = ({
  leadsSigned,
  isElectricityIncludedInRent,
}: Props) => {
  const [hasBeenDismissed, setHasBeenDismissed] = useLocalStorageState(
    "TENANT_HUB_lead_internet_section",
    false,
  );

  const hasLeadsSignedEl = leadsSigned?.some(
    (lead) => lead.section === "ELECTRICITY",
  );
  const hasLeadsSignedInternet = leadsSigned?.some(
    (lead) => lead.section === "INTERNET",
  );

  if (
    (!hasLeadsSignedEl && !isElectricityIncludedInRent) ||
    hasLeadsSignedInternet ||
    hasBeenDismissed
  ) {
    return null;
  }

  return (
    <Notice
      type="info"
      icon={<IconAlertCircle size="medium" />}
      onDismissClick={() => setHasBeenDismissed(true)}
    >
      {t("tenant_hub.leads.notice.internet")}
    </Notice>
  );
};
