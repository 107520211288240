import { useEffect, useState } from "react";

export type SavedSearchItem = {
  name: string;
  url: string;
  city: string;
  filters: string;
};

type SavedSearchHistory = {
  list: SavedSearchItem[];
  isHidden: boolean;
};

const SEARCH_HISTORY = "LATEST_SEARCH_HISTORY";
const HISTORY_SIZE = 4;

const getSearchHistory = () => {
  const history_json = window.localStorage.getItem(SEARCH_HISTORY);

  if (history_json === null) {
    return [];
  }

  const history: SavedSearchHistory = JSON.parse(history_json);

  return history.list;
};

const clearSearchHistory = () => {
  window.localStorage.removeItem(SEARCH_HISTORY);
};

const addSearchHistory = (item: SavedSearchItem) => {
  const history = getSearchHistory();

  const unduplicatedHistory = history.filter((el) => el.url !== item.url);

  unduplicatedHistory.unshift(item);

  const list = unduplicatedHistory.slice(0, HISTORY_SIZE);

  const updatedHistory = {
    list,
    isHidden: false,
  };

  window.localStorage.setItem(SEARCH_HISTORY, JSON.stringify(updatedHistory));
};

const getHiddenState = () => {
  const history_json = window.localStorage.getItem(SEARCH_HISTORY);

  if (history_json === null) {
    return false;
  }

  const history: SavedSearchHistory = JSON.parse(history_json);

  return history.isHidden;
};

const setHiddenState = (state: boolean) => {
  const history_json = window.localStorage.getItem(SEARCH_HISTORY);

  if (history_json === null) {
    return;
  }

  const history: SavedSearchHistory = JSON.parse(history_json);

  const updatedHistory = {
    ...history,
    isHidden: state,
  };

  window.localStorage.setItem(SEARCH_HISTORY, JSON.stringify(updatedHistory));
};

export const useHiddenState = () => {
  const [isLatestSearchesHidden, setIsLatestSearchesHidden] = useState(false);

  useEffect(() => {
    setIsLatestSearchesHidden(getHiddenState());
  }, []);

  const handleHiddenState = (state: boolean) => {
    setHiddenState(state);
    setIsLatestSearchesHidden(state);
  };

  return {
    isLatestSearchesHidden,
    setIsLatestSearchesHidden: handleHiddenState,
  };
};

export const useSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useState<SavedSearchItem[]>([]);

  useEffect(() => {
    setSearchHistory(getSearchHistory());
  }, []);

  return {
    searchHistory,
    addSearchHistory,
    clearSearchHistory,
  };
};
