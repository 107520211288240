import * as React from "react";

const SvgPaymentCards = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={18}
    viewBox="0 0 25 18"
    {...props}
  >
    <defs>
      <linearGradient
        id="PaymentCards_svg__a"
        x1="100%"
        x2="0%"
        y1="24.08%"
        y2="75.92%"
      >
        <stop
          offset="0%"
          stopColor="#FFF"
          stopOpacity={0.8}
        />
        <stop
          offset="100%"
          stopColor="#FFF"
        />
      </linearGradient>
    </defs>
    <path
      fill="url(#PaymentCards_svg__a)"
      fillRule="nonzero"
      d="M25 1.8c0-.99-.804-1.8-1.786-1.8H1.786C.804 0 0 .81 0 1.8v2.7h25V1.8zM0 16.2c0 .99.804 1.8 1.786 1.8h21.428c.982 0 1.786-.81 1.786-1.8v-9H0v9zm7.924-2.7h9.152a.787.787 0 010 1.575H7.924a.787.787 0 010-1.575zm-4.018 0h.67a.787.787 0 010 1.575h-.67a.787.787 0 010-1.575z"
    />
  </svg>
);
export default SvgPaymentCards;
