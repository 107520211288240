import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

const RentalReferenceId = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      type="text"
      label={t("create.field.reference_id.heading")}
      errorText={
        errors.reference_id && t("rentable_details.reference_id.error.length")
      }
      {...register("reference_id", {
        validate: (value: string) => {
          if (!value) {
            return true;
          }
          return value.length <= 255;
        },
      })}
    />
  );
};

export { RentalReferenceId };
