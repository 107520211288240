import { AdCard } from "components/cards/ad_card";
import { Ad } from "components/interfaces/ad";
import { AdStates } from "lib/enums";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";

interface AdCardProps {
  imgUrl: string;
  description: string;
  is_promoted?: boolean;
  canShowOpenAdRibbon: boolean | false;
  rooms: number;
  size_m2: number;
  monthly_rent: number;
  category: string;
  street_name: string;
  city: string;
  monthly_rent_currency: string;
}

export const MockCard = ({
  imgUrl,
  description,
  monthly_rent,
  rooms,
  category,
  size_m2,
  city,
  street_name,
  is_promoted,
  monthly_rent_currency,
  canShowOpenAdRibbon,
}: AdCardProps) => {
  const mockAd: Ad = {
    city,
    street_name,
    description,
    category,
    size_m2,
    rooms,
    monthly_rent,
    monthly_rent_currency,
    is_promoted: is_promoted || false,

    ...(canShowOpenAdRibbon
      ? {
          is_open: true,
          is_exposure: true,
        }
      : {
          is_open: false,
          is_exposure: false,
        }),

    is_exposure_plus: false,
    is_owner: false,
    is_contacted: false,
    is_seen: false,
    has_video: false,
    has_owner: true,
    url: "",
    created: "",
    created_draft_contract: false,
    advertised_date: "",
    id: 1,
    city_area: "",
    short_description: "",
    title: "",
    monthly_rent_extra_costs: null,
    prepaid_rent: null,
    deposit: null,
    deposit_currency: null,
    location: {
      lat: 0,
      lng: 0,
    },
    address: {
      floor: "",
      street_name: "",
      street_number: "",
      postal_code: "",
      postal_town: "",
      door: "",
    },
    images: [
      {
        url: imgUrl,
      },
    ],
    state: AdStates.ACTIVE_APPROVED,
    is_contactable_via_message: null,
    other_details: null,
    open_house: null,
    floor: null,
    rental_period: null,
    available_from: null,
    needs_review: false,
    needs_follow_up: false,
    city_level_1: null,
    city_level_2: null,
    city_level_3: null,
    upsell_url: "",
    video_url: "",
    rentable_id: 1,
    video: null,
    digital_showing: false,
    energy_rating: null,
    ad_phone_number: null,
    reference_id: null,
    postal_town: null,
    deleted: false,
    social_housing: false,
  };

  return (
    <div
      style={{
        pointerEvents: "none",
      }}
    >
      <AdCard
        isPromoted
        ad={mockAd}
        placement={FavouriteAdButtonPlacementTracking.home_page}
      />
    </div>
  );
};
