import { SortableColumn } from "@boligportal/juice";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { API, MovingReportResponse } from "apps/moving_reports/api";
import {
  DefectFlowStatus,
  MovingReportState,
  MovingReportType,
} from "apps/moving_reports/enums";
import { createInitialMovingReportsFilters } from "apps/moving_reports/helpers/createInitialMovingReportsFilters";
import { ReportDuplicationType } from "apps/moving_reports/interfaces/reportDuplicationType";
import { Tenant } from "apps/moving_reports/interfaces/tenant";
import { ReportFilterStateEnum } from "apps/moving_reports/views/reports_list/enums/ReportFilterStateEnum";

export type MovingReportListItem = {
  id: number;
  last_edited: string;
  address: string;
  defect_flow_days_remaining: number | null;
  defect_flow_days_to_start: number | null;
  defect_flow_defects_reported: number;
  tenant_defect_flow_end_date: string | null;
  inspection_date: string | null;
  internal_id?: string;
  state: MovingReportState;
  tenants: Tenant[];
  type: MovingReportType;
  calendar_invite_access_key: string;
  landlord_representative_link: string;
  unviewed_tenant_defects_count: number;
  is_deletable: boolean;
  defect_flow_status: DefectFlowStatus;
};

export interface MovingReportsFilters {
  search: string;
  selectedStateFilter: ReportFilterStateEnum;
  pagination: {
    filterCount: number;
    limit: number;
    startIndex: number;
  };
  activeSortableColumn: SortableColumn<MovingReportListItem>;
}

interface MovingReportsState {
  loading: boolean;
  entries: MovingReportListItem[];
  filters: MovingReportsFilters;
  allCount: number;
  inProgressCount: number;
  tenantDefectFlowCount: number;
  finishedCount: number;
}

const initialMovingReportsState: MovingReportsState = {
  entries: [],
  loading: true,
  filters: createInitialMovingReportsFilters(),
  allCount: 0,
  inProgressCount: 0,
  finishedCount: 0,
  tenantDefectFlowCount: 0,
};

interface GetMovingReportsResponsePayload {
  moving_reports: MovingReportListItem[];
  all_count: number;
  filtered_count: number;
  finished_count: number;
  in_progress_count: number;
  tenant_defect_flow_count: number;
}

export interface GetMovingReportsRequestPayload {
  report_state: ReportFilterStateEnum | null;
  search_term: string | null;
  sorting: keyof MovingReportListItem | null;
  descending: boolean | null;
  start_index: number | null;
  limit: number | null;
}

export interface DuplicateMovingReportRequestPayload {
  to_rentable_id?: number;
  tenancy_id?: number;
  type: MovingReportType;
  simple_copy: boolean;
}

export const fetchMovingReports = createAsyncThunk<
  GetMovingReportsResponsePayload,
  Partial<GetMovingReportsRequestPayload>
>("movingReports/fetchMovingReportsStatus", async (payload) => {
  const response = await API.getMovingReports(payload);
  return response;
});

export const duplicateMovingReport = createAsyncThunk<
  MovingReportResponse,
  {
    reportId: number;
    rentableId?: number;
    tenancyId?: number;
    type: MovingReportType;
    isInitiatedFromApp?: boolean;
    duplicationType: ReportDuplicationType;
  }
>("movingReports/duplicateMovingReportStatus", async (payload) => {
  const response = await API.duplicateMovingReport(
    payload.reportId,
    {
      to_rentable_id: payload.rentableId,
      tenancy_id: payload.tenancyId,
      type: payload.type,
      simple_copy: payload.duplicationType === "simple",
    },
    payload.isInitiatedFromApp,
  );
  return response as MovingReportResponse;
});

export const movingReportsSlice = createSlice({
  name: "movingReports",
  initialState: initialMovingReportsState,
  reducers: {
    setStateFilter: (state, action: PayloadAction<ReportFilterStateEnum>) => {
      state.filters.selectedStateFilter = action.payload;
      state.filters.pagination.startIndex = 0;
      state.filters.pagination.filterCount = 0;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filters.search = action.payload;
    },
    setActiveSortableColumnFilter: (
      state,
      action: PayloadAction<SortableColumn<MovingReportListItem>>,
    ) => {
      state.filters.activeSortableColumn = action.payload;
    },
    setPaginationStartIndexFilter: (state, action: PayloadAction<number>) => {
      state.filters.pagination.startIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMovingReports.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.entries = payload.moving_reports;
      state.allCount = payload.all_count;
      state.finishedCount = payload.finished_count;
      state.inProgressCount = payload.in_progress_count;
      state.tenantDefectFlowCount = payload.tenant_defect_flow_count;
      state.filters.pagination.filterCount = payload.filtered_count;
    });
  },
});

export const {
  setStateFilter,
  setSearchFilter,
  setActiveSortableColumnFilter,
  setPaginationStartIndexFilter,
} = movingReportsSlice.actions;
