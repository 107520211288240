import { styled } from "@boligportal/juice";

export const TransparentButton = styled.button`
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  &:focus {
    outline: none;
  }
`;
