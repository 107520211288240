import { Box, styled } from "@boligportal/juice";

const StyledBox = styled(Box)<{ isVisible: boolean }>`
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  width: 100%;
  pointer-events: none;
  border-top-left-radius: ${(props) => props.theme.borderRadius.sm};
  border-top-right-radius: ${(props) => props.theme.borderRadius.sm};
  color: white;
  text-align: center;
  background-color: ${(props) => props.theme.colorPalette.green[500]};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  font-size: ${(props) => props.theme.fontSize.small};
`;

type Props = {
  isVisible: boolean;
  text: string;
};

export const UpsellCardPopularDisplay = ({ isVisible, text }: Props) => (
  <StyledBox
    isVisible={isVisible}
    py={0.5}
  >
    {text}
  </StyledBox>
);
