export enum RefundStepsEnum {
  FillInForm = 0,
  ConfirmForm = 1,
}

export enum RefundReasonsEnum {
  TechnicalError = "Technical error",
  Error40 = "Error 40",
  InternalError = "Internal Error",
  Duplicate = "Duplicate",
  DiscountForCustomer = "Discount for customer",
  CustomerValue = "Customer Value",
  Exception = "Exception",
}
