import * as React from "react";
import { Button, IconArrowBack, styled } from "@boligportal/juice";
import {
  Action_changeView,
  Action_toggleShowMobileFilters,
  Action_updateAgentId,
} from "apps/search_result/search_result_reducer";
import { Filters } from "components/interfaces/filters";
import { TrackingEvent } from "lib/tracking/events";
import SvgFilter18px from "media/icons/Filter18px";
import { CreateAgentFromMap } from "./CreateAgentFromMap";

const StyledViewToggleMap = styled.div<{ ignorePointerEvents: boolean }>`
  position: fixed;
  top: 100px;
  left: 15px;
  right: 15px;
  white-space: nowrap;
  z-index: 1;
  display: flex;
  height: 45px;
  justify-content: space-between;
  ${(props) => props.ignorePointerEvents && "pointer-events: none;"}

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    left: 265px;
    justify-content: center;
  }
`;

const MobileIconButton = styled(Button)`
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.full};
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const MapActions = ({
  filters,
  dispatch,
  agentId,
  ignorePointerEvents,
}: {
  filters: Filters;
  dispatch: React.Dispatch<
    Action_updateAgentId | Action_changeView | Action_toggleShowMobileFilters
  >;
  agentId: number | null;
  ignorePointerEvents: boolean;
}) => (
  <StyledViewToggleMap ignorePointerEvents={ignorePointerEvents}>
    <MobileIconButton
      onClick={() => {
        dispatch({
          type: "changeView",
          payload: {
            view: "list",
          },
        });
        TrackingEvent.filterClickedBackToListFromMap();
      }}
    >
      <IconArrowBack size="medium" />
    </MobileIconButton>

    <CreateAgentFromMap
      agentId={agentId}
      dispatch={dispatch}
      filters={filters}
    />

    <MobileIconButton
      onClick={() => {
        dispatch({
          type: "toggleShowMobileFilters",
        });
      }}
    >
      <SvgFilter18px />
    </MobileIconButton>
  </StyledViewToggleMap>
);
