import { useCallback, Fragment } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  EmptyBox,
  Flex,
  IconAddCircleOutline,
  SelectField,
  Text,
  TextField,
} from "@boligportal/juice";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { t } from "lib/i18n";
import { TenancyFormData } from "../types/TenancyFormData";
import { TenancyTenantCandidateForm } from "./TenancyTenantCandidateForm";

interface Props {
  show: boolean;
  onClose: () => void;
  onSubmit: (data: TenancyFormData) => void;
  headline: string;
  submitButtonLabel: string;
  form: UseFormReturn<TenancyFormData>;
  paymentType?: TenancyPaymentType;
}

export const TenancyFormSlideOver = ({
  show,
  onClose,
  onSubmit,
  headline,
  submitButtonLabel,
  form,
  paymentType,
}: Props) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    formState,
    getValues,
    formState: { errors },
  } = form;

  const tenancyType = watch("tenancy_type");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tenants",
  });

  const addTenantCandidate = useCallback(() => {
    append({});
  }, [append]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      slideOver
    >
      <DialogHeader>{headline}</DialogHeader>
      <DialogContent>
        <Box>
          <Text weight="headline">{t("common.dates")}</Text>
        </Box>
        <Flex
          gap
          pt={2}
          column={{
            xs: true,
            sm: false,
          }}
        >
          <TextField
            type="date"
            {...register("move_in_date")}
            label={t("common.form_fields.move_in_date.label")}
            errorText={errors.move_in_date?.message}
          />
          <TextField
            type="date"
            {...register("move_out_date")}
            label={t("common.form_fields.move_out_date.label")}
            errorText={errors.move_out_date?.message}
          />
        </Flex>

        <Divider />

        <Box
          pt={1}
          pb={2}
        >
          <Text weight="headline">{t("common.people.tenants")}</Text>
        </Box>
        <Flex>
          <SelectField
            items={[
              {
                label: t("common.form_fields.tenancy_type.private"),
                value: "private",
              },
              {
                label: t("common.form_fields.tenancy_type.company"),
                value: "company",
              },
            ]}
            label={t("common.form_fields.tenancy_type.label")}
            {...register("tenancy_type")}
          />
        </Flex>
        <Box>
          {tenancyType === "company" && (
            <>
              <TextField
                label={t("common.form_fields.company_name.label")}
                {...register("company_name")}
              />
              <TextField
                label={t("common.form_fields.central_business_register.label")}
                {...register("company_cvr")}
              />
            </>
          )}
        </Box>
        <Divider />
        <Box pb={2}>
          {fields.map((data, index) => (
            <Fragment key={data.id}>
              {index > 0 && <Divider />}
              <TenancyTenantCandidateForm
                tenancyType={tenancyType}
                index={index}
                control={control}
                getValues={getValues}
                remove={remove}
                register={register}
                unregister={unregister}
                paymentType={paymentType}
                errors={errors}
              />
            </Fragment>
          ))}
        </Box>
        <EmptyBox
          p={4}
          bgColor="gray"
        >
          <Button
            icon={IconAddCircleOutline}
            onClick={addTenantCandidate}
            testId="tenancy_create_new_tenant"
          >
            {t("common.tenancy.add_tenant")}
          </Button>
        </EmptyBox>
      </DialogContent>
      <DialogFooter>
        <Flex gap={1}>
          <Button
            variant="subtle"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            testId="create_new_tenancy_slideover_btn"
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            {submitButtonLabel}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
