import { http } from "@boligportal/juice";
import { MovingReportListItem } from "apps/moving_reports/store/slices/movingReports";
import { Tenancy, TenancyId } from "business/domain/tenancy/Tenancy";
import { TenancyRent } from "business/domain/tenancy/TenancyRent";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { AccountInfo, fetchFromAPI } from "lib/api";

export class TenancyAPI {
  static async getTenanciesByRentableId(
    rentableId: number,
  ): Promise<Tenancy[]> {
    try {
      const response = await fetchFromAPI(`/api/tenancy/${rentableId}/`);
      const data = await response.json();
      return data.tenancies;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async regulateTenancyRent(
    tenancyId: TenancyId,
    payload: { due_date: string; items: TenancyRentEntity[] },
  ): Promise<TenancyRent> {
    try {
      const response = await fetchFromAPI(
        `/api/tenancy/${tenancyId}/regulate-rent`,
        {
          method: "PATCH",
          body: JSON.stringify(payload),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async stopTenancyRent(
    tenancyId: TenancyId,
    dueDate: string,
  ): Promise<TenancyRent> {
    try {
      const response = await fetchFromAPI(
        `/api/tenancy/${tenancyId}/stop-rent`,
        {
          method: "PATCH",
          body: JSON.stringify({
            due_date: dueDate,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async resumeTenancyRent(
    tenancyId: TenancyId,
    dueDate: string,
  ): Promise<TenancyRent> {
    try {
      const response = await fetchFromAPI(
        `/api/tenancy/${tenancyId}/resume-rent`,
        {
          method: "PATCH",
          body: JSON.stringify({
            due_date: dueDate,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async resendRentCollectionAgreement(tenancyId: number) {
    const url = encodeURI(
      `/api/tenancy/${tenancyId}/resend-rent-collection-agreement/`,
    );
    try {
      const response = await fetchFromAPI(url, {
        method: "POST",
      });
      return await response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // PAGE DATA

  static async getRentableTenanciesPage(
    rentableId: number,
    sorting: {
      column: string;
      descending: boolean;
    },
  ): Promise<{
    address: string;
    tenancies: Tenancy[];
  }> {
    try {
      const response = await fetchFromAPI(
        `/api/rentable/${rentableId}/tenancies-page`,
        {
          method: "POST",
          body: JSON.stringify({
            sorting,
          }),
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getRentableMovingReports(rentableId: number): Promise<{
    address: string;
    movingReports: MovingReportListItem[];
  }> {
    try {
      const response = await fetchFromAPI(
        `/api/rentable/${rentableId}/moving-reports-page`,
        {
          method: "GET",
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getActiveUpsellSubscriptions(rentableId: number): Promise<{
    activeUpsellSubscriptions: AdSubscription[];
  }> {
    try {
      const response = await fetchFromAPI(
        `/api/rentable/${rentableId}/active-upsell-subscriptions`,
        {
          method: "GET",
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getRentableListingProducts(rentableId: number): Promise<{
    plans: Record<string, Plan[]>;
    activeUpsellSubscriptions: AdSubscription[];
    accountInfo: AccountInfo | null;
    listingId: number;
    advertisedDate: string;
  }> {
    try {
      const response = await fetchFromAPI(
        `/api/rentable/${rentableId}/listing-products`,
        {
          method: "GET",
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteMobilePayConnection(): Promise<void> {
    const request = fetchFromAPI("/api/tenancy/delete-mobilepay-merchant", {
      method: "DELETE",
    });
    return request.then((response) => {
      if (!response.ok) {
        throw Error(`Got non-OK response with status ${response.status}`);
      }
    });
  }

  static async createTenancyPayment(
    tenancyPaymentRecordId: number,
    data: { transactions: Array<{ accounting_date: string; amount: number }> },
  ) {
    return http.post(
      `/tenancy/payments/${tenancyPaymentRecordId}/register-payment`,
      data,
    );
  }

  static async transferTenancyArrears(
    fromTenancyPaymentRecordId: number,
    toTenancyPaymentRecordId: number,
  ) {
    return http.post(
      `/tenancy/payments/${fromTenancyPaymentRecordId}/transfer-arrears`,
      {
        payment_id: toTenancyPaymentRecordId,
      },
    );
  }
}
