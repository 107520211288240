import { styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

const StyledLabel = styled.label`
  margin-top: ${(props) => props.theme.unit(1)};
  margin-bottom: ${(props) => props.theme.unit(0.5)};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.h5};
  color: ${(props) => props.theme.color.text.label};
`;

export const HeaderLabel = (props: {
  htmlFor?: string;
  label: string;
  isOptional?: boolean;
}) => (
  <StyledLabel htmlFor={props.htmlFor}>
    {props.label}
    {props.isOptional && (
      <Text
        as="small"
        size="small"
        color="muted"
      >
        {" "}
        ({t("field.optional")})
      </Text>
    )}
  </StyledLabel>
);
