import {
  Flex,
  MenuBaseItem,
  Text,
  IconDocumentTextOutline,
  IconArrowBackForward,
  IconLogo,
} from "@boligportal/juice";

export const DangerMenuItem = <T extends MenuBaseItem<T>>({ name }: T) => (
  <Text color="danger">{name}</Text>
);

export const LogoMenuItem = <T extends MenuBaseItem<T>>({ name }: T) => (
  <Flex align="center">
    <Flex
      justify="center"
      width="24px"
    >
      <IconLogo />
    </Flex>
    <Text pl={1}>{name}</Text>
  </Flex>
);

export const DocumentMenuItem = <T extends MenuBaseItem<T>>({ name }: T) => (
  <Flex align="center">
    <Flex
      justify="center"
      width="24px"
    >
      <IconDocumentTextOutline />
    </Flex>
    <Text pl={1}>{name}</Text>
  </Flex>
);

export const MovingMenuItem = <T extends MenuBaseItem<T>>({ name }: T) => (
  <Flex align="center">
    <Flex
      justify="center"
      width="24px"
    >
      <IconArrowBackForward />
    </Flex>
    <Text pl={1}>{name}</Text>
  </Flex>
);
