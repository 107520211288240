import { memo, useRef, useState, useEffect, useCallback } from "react";
import { IconAdd, styled } from "@boligportal/juice";
import { LatLngLocation } from "components/interfaces/location";
import { MAP_STYLE_URL } from "components/maps/mapHelpers";
import * as MapboxGl from "mapbox-gl";

const CustomerServiceLocationMap = memo<{
  location: LatLngLocation;
  onMapMove: (newLocation: LatLngLocation) => void;
}>(({ location, onMapMove }) => {
  const triggerMapMove = useRef(false);
  const markerRef = useRef<MapboxGl.Marker>();
  const [mapboxMap, setMapboxMap] = useState<MapboxGl.Map>();

  useEffect(() => {
    if (!mapboxMap) {
      const mapbox = new MapboxGl.Map({
        container: "customer-service-ad-location-map-id",
        style: MAP_STYLE_URL,
        center: location,
        zoom: 10,
        minZoom: 2,
        maxZoom: 20,
        interactive: true,
        attributionControl: false,
        dragRotate: false,
        touchZoomRotate: true,
        scrollZoom: false,
      });

      setMapboxMap(mapbox);

      mapbox.addControl(new MapboxGl.NavigationControl());
    } else {
      if (markerRef && markerRef.current) {
        markerRef.current.remove();
      }
      markerRef.current = new MapboxGl.Marker()
        .setLngLat(location)
        .addTo(mapboxMap);
      mapboxMap.flyTo({
        center: location,
      });
    }
  }, [location, mapboxMap]);

  const moveendHandler = useCallback(() => {
    if (mapboxMap && triggerMapMove.current) {
      triggerMapMove.current = false;
      const center = mapboxMap.getCenter();
      onMapMove({
        lat: center.lat,
        lng: center.lng,
      });
    }
  }, [mapboxMap, onMapMove]);

  const dragstartHandler = () => {
    triggerMapMove.current = true;
  };

  const mapClickHandler = useCallback(() => {
    mapboxMap?.scrollZoom.enable();
    mapboxMap?.off("click", mapClickHandler);
  }, [mapboxMap]);

  useEffect(() => {
    if (mapboxMap) {
      mapboxMap.on("moveend", moveendHandler);
      mapboxMap.on("dragstart", dragstartHandler);
      mapboxMap.on("click", mapClickHandler);
    }

    () => {
      mapboxMap?.off("moveend", moveendHandler);
      mapboxMap?.off("dragstart", dragstartHandler);
      mapboxMap?.off("click", mapClickHandler);
    };
  }, [mapboxMap, moveendHandler, mapClickHandler]);

  return (
    <>
      <div
        id="customer-service-ad-location-map-id"
        style={{
          position: "relative",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          height: "400px",
        }}
      >
        <Overlay>
          <div
            style={{
              transform: "translate(-12px,-12px)",
            }}
          >
            <IconAdd size="large" />
          </div>
        </Overlay>
      </div>
    </>
  );
});

export { CustomerServiceLocationMap };

const Overlay = styled.div`
  position: absolute;
  pointerevents: none;
  z-index: 99999;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
`;
