import { Box, styled, Text } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { formatDuration, isTrial } from "components/plan_utils";
import { formatMonths, formatOrdinal } from "lib/date";
import { t, tn } from "lib/i18n";
import { calculatePrice, formatCurrency } from "lib/utils";

const Title = styled(Text)`
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
`;
interface OrderInfoProps {
  plans: Plan[];
}

const durationInMonths = (months: number, price: string) => {
  // We don't use the formatMonths method since we want to say Én rather than En when it's one month.
  if (months === 1) {
    return t("plan_box.title.normal.duration_one_month", {
      price,
    });
  }

  return t("plan_box.title.normal.duration_months", {
    duration: formatMonths(months),
    price,
  });
};

const PlanView = (props: { plan: Plan }) => {
  const { plan } = props;
  const isPlanTrial = isTrial(plan);
  const isMonths = plan.duration_days % 31 === 0;

  const title = isPlanTrial
    ? t("plan_box.title.trial", {
        duration: formatDuration(plan.duration_days),
        price: formatCurrency(plan.price.total, plan.currency),
      })
    : isMonths
      ? durationInMonths(
          plan.duration_days / 31,
          formatCurrency(
            calculatePrice(plan.price.total, plan.price.percent_off!),
            plan.currency,
          ),
        )
      : tn("plan_box.title.normal", plan.duration_days, {
          price: formatCurrency(
            calculatePrice(plan.price.total, plan.price.percent_off!),
            plan.currency,
          ),
        });

  const description = isPlanTrial
    ? tn("plan_box.description.trial", plan.next_plan!.duration_days, {
        price: formatCurrency(plan.next_plan!.price.total, plan.currency),
      })
    : isMonths
      ? t("plan_box.description.duration_months", {
          ordinal:
            plan.duration_days === 31
              ? ""
              : formatOrdinal(plan.duration_days / 31),
        })
      : t("plan_box.description.normal_new", {
          days: plan.duration_days,
        });

  return (
    <Box>
      {plan.product.name === "seeker_subscription" && (
        <Text
          color="muted"
          block
        >
          {t("payment_modal.order_info.seeker_subscription.headline")}
        </Text>
      )}
      <Title color="muted">{title}</Title>
      <Text
        color="muted"
        block
      >
        {plan.is_subscription && description}
      </Text>
    </Box>
  );
};

export const QuickpayOrderInfo = ({ plans }: OrderInfoProps) => (
  <Box>
    <Text
      color="muted"
      size="small"
    >
      {t("payment_modal.order_info.headline")}
    </Text>
    {plans.map((plan) => (
      <PlanView
        key={plan.plan_id}
        plan={plan}
      />
    ))}
    <Text color="muted">{t("payment_modal.order_info.usage_disclaimer")} </Text>
  </Box>
);
