import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import {
  PaymentFlowCoordinatorProps,
  RegisteredPaywall,
} from "features/paywall/PaywallProvider";
import { StartSubscriptionFlow } from "../StartSubscriptionFlow";
import { MobilePayPaymentFlow } from "./MobilePayPaymentFlow";
import { useMobilePaySubscriptionLauncher } from "./useMobilePaySubscriptionLauncher";

export const MobilePayPaymentFlowCoordinator = ({
  paywallCandidate,
  registeredPaywalls,
  onClose,
}: PaymentFlowCoordinatorProps) => (
  <>
    {/**
     * MobilePay's process involves a redirection away from our site, which necessitates
     * a return to our site to display the StartSubscriptionFlow dialog.
     * To manage this, MobilePaySubscriptionLauncher is monitoring the URL for signs of a
     * MobilePay transaction for each registered paywall.
     */}
    {registeredPaywalls.map((paywall) => (
      <MobilePaySubscriptionLauncher
        key={paywall.uniquePaywallKey}
        registeredPaywall={paywall}
      />
    ))}
    {paywallCandidate?.paymentMethodType === PaymentMethodType.MobilePay && (
      <MobilePayPaymentFlow
        uniquePaywallKey={paywallCandidate.uniquePaywallKey}
        adId={paywallCandidate.adId}
        plans={[paywallCandidate.plan]}
        rentableId={paywallCandidate.rentableId}
        title={paywallCandidate.title}
        onClose={onClose}
      />
    )}
  </>
);

type MobilePaySubscriptionLauncherProps = {
  registeredPaywall: RegisteredPaywall;
};

const MobilePaySubscriptionLauncher = ({
  registeredPaywall,
}: MobilePaySubscriptionLauncherProps) => {
  const { mobilePaySubscriptionCandidate, closeMobilePaySubscriptionDialog } =
    useMobilePaySubscriptionLauncher(
      registeredPaywall.plans,
      registeredPaywall.uniquePaywallKey,
    );

  if (!mobilePaySubscriptionCandidate) {
    return null;
  }

  const {
    onPaymentSuccessContinueButtonClick,
    onPaymentFailedRetryButtonClick,
    onSubscriptionActivated,
  } = registeredPaywall;

  return (
    <StartSubscriptionFlow
      plans={mobilePaySubscriptionCandidate.plans}
      adId={mobilePaySubscriptionCandidate.listingId}
      onClose={closeMobilePaySubscriptionDialog}
      paymentMethodId={mobilePaySubscriptionCandidate.paymentMethodId}
      onPurchaseComplete={() => {
        closeMobilePaySubscriptionDialog();
        onPaymentSuccessContinueButtonClick(
          mobilePaySubscriptionCandidate.plans[0],
        );
      }}
      onSubscriptionActivated={() => {
        onSubscriptionActivated?.();
      }}
      onPaymentFailedRetryButtonClick={() => {
        closeMobilePaySubscriptionDialog();
        onPaymentFailedRetryButtonClick(
          mobilePaySubscriptionCandidate.plans[0],
        );
      }}
    />
  );
};
