import { useCallback } from "react";
import { useForm, useWatch, UseFormRegister } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useEmailLogStreamQuery } from "api/queries";
import { useDebounce } from "hooks/useDebounce";
import { EmailLogsFilters } from "lib/customerServiceApi";
import { queryParamStringToFilters } from "./utils";

export const emailLogsPageLimit = 100;

export const useEmailLogData = () => {
  const { search } = useLocation();

  const { register, control, setValue } = useForm<EmailLogsFilters>({
    defaultValues: queryParamStringToFilters(search),
  });

  const filters = useWatch({
    control,
  });

  const debouncedFilters = useDebounce(filters, 500);
  const { data, isFetching, isError } =
    useEmailLogStreamQuery(debouncedFilters);

  const setOffset = (offset: number) => {
    setValue("start_index", offset);
    setValue("end_index", offset + emailLogsPageLimit);
  };

  // Override register function to reset offset whenever filters changes
  const _register: UseFormRegister<EmailLogsFilters> = useCallback(
    (name, options: any) =>
      register(name, {
        ...options,
        onChange: (event) => {
          options?.onChange?.(event);
          setValue("start_index", 0);
          setValue("end_index", emailLogsPageLimit);
        },
      }),
    [register, setValue],
  );

  return {
    register: _register,
    offset: filters.start_index,
    setOffset,
    limit: emailLogsPageLimit,
    data,
    isFetching,
    isError,
  };
};
