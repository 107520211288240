import { useEffect } from "react";
import { Text } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { formatCurrency } from "lib/utils";
import { StartSubscriptionFlow } from "../StartSubscriptionFlow";
import { useAccountPaymentFlow } from "./useAccountPaymentFlow";

interface Props {
  onPaymentSuccessContinueButtonClick: (plan: Plan) => void;
  onSubscriptionActivated?: () => void;
  onPaymentFailedRetryButtonClick: (plan: Plan) => void;
  onClose?: () => void;
  plan: Plan;
  adId: number | null;
}

export const AccountPaymentFlow = ({
  plan,
  adId,
  onPaymentSuccessContinueButtonClick,
  onSubscriptionActivated,
  onPaymentFailedRetryButtonClick,
  onClose,
}: Props) => {
  const { user } = useUser();
  const { accountInfo, paymentMethodId, handleSubscriptionActivated } =
    useAccountPaymentFlow({
      onSubscriptionActivated,
    });

  useEffect(() => {
    Tracking.trackVirtualPageview("/payment/checkout_form");
    if (adId !== null) {
      FacebookTrackingEvent.trackInitiateCheckout(adId);
    }
  }, [adId]);

  if (!paymentMethodId) {
    return null;
  }

  return (
    <StartSubscriptionFlow
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      paymentMethodId={paymentMethodId}
      onPurchaseComplete={onPaymentSuccessContinueButtonClick}
      onSubscriptionActivated={handleSubscriptionActivated}
      onPaymentFailedRetryButtonClick={onPaymentFailedRetryButtonClick}
      plans={[plan]}
      adId={adId}
      extraSuccessMessage={
        accountInfo ? (
          <AccountInfoMessage
            balanceFormatted={formatCurrency(
              accountInfo.balance,
              App.settings.currency,
            )}
            companyName={user?.company_name ?? ""}
          />
        ) : null
      }
    />
  );
};

const AccountInfoMessage = ({
  balanceFormatted,
  companyName,
}: {
  balanceFormatted: string;
  companyName: string;
}) => (
  <>
    {t("account_payment_modal.success.extra_message", {
      company_name: companyName,
    })}{" "}
    <Text
      weight="bold"
      size="small"
    >
      {balanceFormatted}
    </Text>
  </>
);
