import { useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  Flex,
  Text,
} from "@boligportal/juice";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { CurrencyAmountLabel } from "components/juice-candidates/CurrencyAmountLabel";
import { localizedMonthDayAndYear } from "lib/date";
import { t } from "lib/i18n";
import { calculateTenancyPaymentsSum } from "../helpers/calculateTenancyPaymentsSum";
import { useTenancyRentTable } from "../hooks/useTenancyRentTable";
import { RentableTenancyTracking } from "../tracking/RentableTenancyTracking";
import { TenancyPaymentsForm } from "../types/TenancyPaymentsForm";
import { TenancyRentTable } from "./TenancyRentTable";

interface Props {
  open: boolean;
  rentItems: TenancyRentEntity[];
  oneTimePayments: TenancyRentEntity[];
  dueDate: string;
  onSubmit: (extraPayments: Array<TenancyRentEntity>) => void;
  onCancel: () => void;
}

export const TenancyOneTimePaymentDialog = ({
  open,
  rentItems,
  oneTimePayments,
  dueDate,
  onCancel,
  onSubmit,
}: Props) => {
  const allFormMethods = useForm<TenancyPaymentsForm>({
    defaultValues: {
      recurringRent: oneTimePayments,
    },
  });

  const tenancyRentTableFeature = useTenancyRentTable(allFormMethods);
  const { sum, controlledFields, addEmptyOption } = tenancyRentTableFeature;

  const { reset } = allFormMethods;
  const hasInititalOneTimePayments = oneTimePayments.length > 0;

  const totalSum = sum + calculateTenancyPaymentsSum(rentItems);

  const submitPaymentOptions = (data: TenancyPaymentsForm) => {
    onSubmit(data.recurringRent);
  };

  useLayoutEffect(() => {
    if (open) {
      if (!hasInititalOneTimePayments) {
        addEmptyOption();
      }
      RentableTenancyTracking.trackUserOpensAddAmountToPaymentDialog();
    } else {
      reset();
    }
  }, [open, addEmptyOption, reset, hasInititalOneTimePayments]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      size="xlarge"
    >
      <DialogHeader>
        {t(
          "features.rentable.tenancy.one_time_payment_dialog.title_%{due_date}",
          {
            due_date: localizedMonthDayAndYear(new Date(dueDate)),
          },
        )}
      </DialogHeader>
      <DialogContent noPadding>
        <Box
          px={3}
          pb={3}
        >
          <TenancyRentTable
            feature={tenancyRentTableFeature}
            buttonLabel={t("common.buttons.add_amount")}
          />
        </Box>

        <Divider />

        <Flex
          column
          px={3}
          pt={1}
        >
          <Text
            weight="headline"
            pb={3}
            size="lead"
          >
            {t("rentable.rent.summary")}
          </Text>

          {rentItems.map((rentItem) => (
            <BaseRentItem
              key={rentItem.id}
              name={rentItem.name}
              amount={rentItem.amount}
            />
          ))}

          {controlledFields.map((field) => (
            <BaseRentItem
              key={field.id_that_doesnt_collide_with_a_tenancy_rent_base_key}
              name={field.name ?? ""}
              amount={field.amount ?? 0}
              isOneTimePayment
            />
          ))}

          <Box>
            <Divider />
          </Box>

          <Flex justify="space-between">
            <Text>
              {t("rentable.rent.total_amount_for_date", {
                date: localizedMonthDayAndYear(new Date(dueDate)),
              })}
            </Text>
            <CurrencyAmountLabel
              amount={totalSum}
              emphasizeAmount="bold"
              errorText={
                totalSum < 1
                  ? t(
                      "tenancy_payments.setup_rent.error.sum_less_than_minimum_not_allowed",
                    )
                  : undefined
              }
            />
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onCancel}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={totalSum < 1}
            variant="primary"
            onClick={allFormMethods.handleSubmit(submitPaymentOptions)}
            testId="one-time-payment-submit"
          >
            {t("rentable.rent.add_amount_to_payment")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};

export const BaseRentItem = ({
  name,
  amount,
  isOneTimePayment,
}: {
  name: string;
  amount: number;
  isOneTimePayment?: boolean;
}) => {
  if (isNaN(amount)) {
    return null;
  }
  const showOnetimeLabel = isOneTimePayment && name.length !== 0;

  return (
    <Flex justify="space-between">
      <Text>
        {name}{" "}
        {showOnetimeLabel && `(${t("rentable.rent.one_time_payment.label")})`}
      </Text>
      <CurrencyAmountLabel
        amount={amount}
        emphasizeAmount="bold"
      />
    </Flex>
  );
};
