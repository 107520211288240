import * as React from "react";
import { IconArrowForward } from "@boligportal/juice";
import { createTenantsDisplayLabel } from "business/domain/tenancy/Tenancy";
import { TenancyTenant } from "business/domain/tenancy/TenancyTenant";
import { App } from "components/app";
import { WordWrapButton } from "components/buttons/WordWrapButton";
import { PaymentRecordDetailSliderOver } from "features/rentable_tenancy/components/PaymentRecordDetailSliderOver/PaymentRecordDetailSliderOver";
import { RentableTenancyTracking } from "features/rentable_tenancy/tracking/RentableTenancyTracking";
import { t } from "lib/i18n";

export type PaymentRecordDetailCandidate = {
  address: string;
  paymentRecordId: number;
  rentableId: number;
  tenancyId: number;
  tenants: TenancyTenant[];
};

type PaymentRecordDetailContextValue = {
  open: boolean;
  candidate: PaymentRecordDetailCandidate | null;
  setPaymentRecordDetailCandidate: (
    candidate: PaymentRecordDetailCandidate | null,
  ) => void;
  onCancel: () => void;
  invalidationQueries: string[];
};

const PaymentRecordDetailContext =
  React.createContext<PaymentRecordDetailContextValue>(null!);

type ProviderProps = {
  children: React.ReactNode;
  invalidationQueries: string[];
};

const PaymentRecordDetailSliderOverImpl = () => {
  const { candidate, invalidationQueries, onCancel, open } = React.useContext(
    PaymentRecordDetailContext,
  );
  const settingsLanguagePrefix = App.settings.i18n_prefix;

  if (!candidate) {
    return null;
  }

  return (
    <PaymentRecordDetailSliderOver
      open={open}
      invalidationQueries={invalidationQueries}
      onCancel={onCancel}
      paymentRecordId={candidate.paymentRecordId}
      shortcutToTenancyOverview={
        <WordWrapButton
          text
          href={`${settingsLanguagePrefix}/property-owner/my-rentables/${candidate.rentableId}/tenancies/${candidate.tenancyId}`}
        >
          {createTenantsDisplayLabel(candidate.tenants)}
        </WordWrapButton>
      }
      shortcutToRentableOverview={
        <WordWrapButton
          text
          href={`${settingsLanguagePrefix}/property-owner/my-rentables/${candidate.rentableId}`}
        >
          {candidate.address}
        </WordWrapButton>
      }
      shortcutToTenancySetupRent={
        <WordWrapButton
          text
          href={`${settingsLanguagePrefix}/property-owner/my-rentables/${candidate.rentableId}/tenancies/${candidate.tenancyId}/rent`}
          iconAfter={IconArrowForward}
          onClick={
            RentableTenancyTracking.trackUserNavigatesToRentTabForTenancyFromPaymentDetailsSlideover
          }
        >
          {t("rentable.rent.details_slideover.go_to_tenancy_rent")}
        </WordWrapButton>
      }
    />
  );
};

export const PaymentRecordDetailProvider = ({
  children,
  invalidationQueries,
}: ProviderProps) => {
  const [paymentRecordDetailCandidate, setPaymentRecordDetailCandidate] =
    React.useState<PaymentRecordDetailCandidate | null>(null);
  return (
    <PaymentRecordDetailContext.Provider
      value={{
        open: Boolean(paymentRecordDetailCandidate),
        candidate: paymentRecordDetailCandidate,
        setPaymentRecordDetailCandidate,
        onCancel: () => setPaymentRecordDetailCandidate(null),
        invalidationQueries,
      }}
    >
      {children}
      <PaymentRecordDetailSliderOverImpl />
    </PaymentRecordDetailContext.Provider>
  );
};

export const usePaymentRecordDetail = () => {
  const context = React.useContext(PaymentRecordDetailContext);
  if (!context) {
    throw "usePaymentRecordDetail hook can only be used inside function component as a Child of PaymentRecordDetailProvider";
  }

  const { setPaymentRecordDetailCandidate } = context;

  return {
    setPaymentRecordDetailCandidate,
  };
};
