import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardSection,
  Flex,
  FlexColumn,
  Spinner,
} from "@boligportal/juice";
import { getLandlord, getUser } from "lib/customerServiceApi";
import { useQueryNewBuildDetails } from "lib/customerServiceQueries";
import BlacklistUserNotice from "../pages/user_detail_page/blacklist/blacklist_user_notice";
import { UserForCustomerService } from "../pages/user_detail_page/user_detail_view/user_detail_view";
import { NewBuildBoostPackage } from "./components/NewBuildBoostPackage";
import { NewBuildLandlordInformation } from "./components/NewBuildLandlordInformation";
import { NewBuildProjectDetails } from "./components/NewBuildProjectDetails";
import { NewBuildProjectLocation } from "./components/NewBuildProjectLocation";
import { NewBuildProjectSEO } from "./components/NewBuildProjectSEO";
import { NewBuildToolbarActions } from "./components/NewBuildToolbarActions";

interface NewBuildDetailPageMatchParams {
  id: string;
}

export const NewBuildDetailPage = () => {
  const newBuildId: number = Number(
    useParams<NewBuildDetailPageMatchParams>().id,
  );

  const [user, setUser] = useState<UserForCustomerService>();

  const { data: newbuild, isLoading } = useQueryNewBuildDetails(newBuildId);

  useEffect(() => {
    if (newbuild) {
      getLandlord(newbuild.data.id).then((response) => {
        getUser(response.landlord_id).then((response) => {
          setUser(response.user);
        });
      });
    }
  }, [newbuild]);

  if (!newbuild) {
    return null;
  }

  const { title, url, is_open, boost_until } = newbuild.data;

  return (
    <Flex
      flexGrow
      column
      bg="tint"
    >
      <NewBuildToolbarActions
        newBuildId={newBuildId}
        title={title}
        url={url}
      />
      <Box
        height="100%"
        scrollable
        bg="tint"
      >
        <Box
          px={{
            xs: 2,
            sm: 4,
            md: 6,
            lg: 8,
            xl: 10,
          }}
          m="auto"
          maxWidth="1140px"
        >
          <Flex
            py={4}
            gap
          >
            <FlexColumn size={8}>
              <NewBuildProjectDetails
                newBuild={newbuild.data}
                newBuildId={newBuildId}
              />
              <NewBuildProjectLocation newBuild={newbuild.data} />
              <NewBuildProjectSEO
                newBuildId={newBuildId}
                newBuild={newbuild.data}
              />
            </FlexColumn>
            <FlexColumn size={4}>
              <Card sectioned>
                <CardSection title="Landlord information">
                  {user && (
                    <BlacklistUserNotice
                      bans={user.user_bans}
                      view="newbuild_detail"
                    />
                  )}
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <NewBuildLandlordInformation newBuild={newbuild.data} />
                  )}
                </CardSection>
              </Card>
              <Card sectioned>
                <CardSection title="Boost package">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <NewBuildBoostPackage
                      isActive={is_open}
                      advertiseDate={boost_until}
                      newBuildId={newBuildId}
                    />
                  )}
                </CardSection>
              </Card>
            </FlexColumn>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
