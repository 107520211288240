import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  TextField,
  useNotifications,
} from "@boligportal/juice";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { Market } from "components/markets/market_settings";
import {
  VALID_DANISH_POSTAL_CODE_REG_EX,
  VALID_SWEDISH_POSTAL_CODE_REG_EX,
} from "features/address/common/hooks/postCodes";
import { useFetchAndFillPostalTown } from "features/address/common/hooks/useFetchAndFillPostalTown";
import { useMutationEditNewBuildAddress } from "lib/customerServiceMutations";
import { t } from "lib/i18n";

type NewBuildProjectDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  address: Ad["address"];
  newBuildId: number;
};

export const NewBuildProjectDetailsDialog = ({
  open,
  onClose,
  address,
  newBuildId,
}: NewBuildProjectDetailsDialogProps) => {
  const isDK = App.settings.market === Market.BOLIGPORTAL_DK;

  const defaultPostCodePattern = isDK
    ? VALID_DANISH_POSTAL_CODE_REG_EX
    : VALID_SWEDISH_POSTAL_CODE_REG_EX;

  const form = useForm<Ad["address"]>({
    defaultValues: address,
  });

  const { register, formState, setValue, watch, handleSubmit, reset } = form;

  const { getAndSetPostalTown } = useFetchAndFillPostalTown(
    setValue,
    "postal_town",
    defaultPostCodePattern,
  );

  const watchPostalCode = watch("postal_code");

  useEffect(() => {
    getAndSetPostalTown(watchPostalCode);
  }, [watchPostalCode]);

  const { addNotification } = useNotifications();

  const displaySaveNotification = () => {
    if (addNotification) {
      addNotification({
        title: "Saving data...",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  const mutation = useMutationEditNewBuildAddress();

  const handleUpdate: SubmitHandler<Ad["address"]> = async (payload) => {
    await mutation.mutateAsync({ newbuildId: newBuildId, payload });
    displaySaveNotification();

    onClose();
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      onClose={handleOnClose}
      open={open}
      slideOver
    >
      <DialogHeader>Edit address</DialogHeader>
      <DialogContent>
        <TextField
          required
          label={t("newbuilds.map_dialog.street")}
          errorText={formState.errors.street_name?.message}
          {...register("street_name", {
            required: t("newbuilds.edit.details.error.required"),
          })}
        />
        <TextField
          required
          label={t("newbuilds.map_dialog.house")}
          helpText={t("newbuilds.map_dialog.house_help")}
          maxLength={10}
          errorText={formState.errors.street_number?.message}
          {...register("street_number", {
            required: t("newbuilds.edit.details.error.required"),
          })}
        />
        <TextField
          required
          label={t("newbuilds.map_dialog.postcode")}
          errorText={formState.errors.postal_code?.message}
          {...register("postal_code", {
            required: t("newbuilds.edit.details.error.required"),
          })}
        />
        <TextField
          required
          readOnly
          label={t("newbuilds.map_dialog.city")}
          errorText={formState.errors.city?.message}
          {...register("postal_town", {
            required: t("newbuilds.edit.details.error.required"),
          })}
        />
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="default"
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleUpdate)}
            variant="primary"
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Save address
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
