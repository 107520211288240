import { useMutation, useQueryClient } from "react-query";
import {
  BetalingsserviceMerchantStatus,
  handleCompanyPageSubscription,
  toggleMobilePay,
  updateBetalingsserviceMerchantStatus,
} from "lib/customerServiceApi";
import { customerServiceKeys } from "./queries";

export const useMerchantStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { merchantId: number; status: BetalingsserviceMerchantStatus }) =>
      updateBetalingsserviceMerchantStatus(payload.merchantId, payload.status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(customerServiceKeys.merchantStatus());
      },
    },
  );
};

export const useCompanyPageSubscriptionMutation = () => {
  return useMutation((payload: { userId: number; enabled: boolean }) =>
    handleCompanyPageSubscription(payload.userId, payload.enabled),
  );
};

export const useToggleMobilePayMutation = () =>
  useMutation((enabled: boolean) => toggleMobilePay(enabled));
