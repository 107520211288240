import { Text } from "@boligportal/juice";
import { formatCurrency } from "lib/utils";

type Props = {
  priceTotal: number;
  currency: string;
};

export const UpsellCardPrice = ({ priceTotal, currency }: Props) => (
  <Text
    size="h3"
    block
    weight="headline"
    my={2}
  >
    {formatCurrency(priceTotal, currency)}
  </Text>
);
