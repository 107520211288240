import { useState, useEffect } from "react";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { Slide, OnboardingSlideType } from "./Slide";
import { OnboardingTracking } from "./Tracking";

const ignoreList = [
  SignupContext.CREATE_RENTABLE,
  SignupContext.MOVING_REPORTS,
  SignupContext.CREATE_TENANCY,
  SignupContext.CREATE_RENT_COLLECTION,
  SignupContext.CONTRACTS,
  SignupContext.MOVING_IN,
  SignupContext.MOVING_OUT,
  SignupContext.CONTACT_LANDLORD,
  SignupContext.FREE_CONTACT,
];
export const useOnboarding = (
  handleOnClose: () => void,
  signupContext?: SignupContext,
) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides: Slide[] = [
    {
      slide: OnboardingSlideType.WELCOME,
      title: t("onboarding.welcome.title"),
      description: t("onboarding.welcome.description"),
      image: "/static/images/onboarding/welcome.svg",
      shouldShow: () => true,
    },
    {
      slide: OnboardingSlideType.SEARCH_AGENT,
      title: t("onboarding.search_agent.title"),
      description: t("onboarding.search_agent.description"),
      image: "/static/images/onboarding/search_agent.svg",
      shouldShow: (context) => context !== SignupContext.SEARCH_AGENT,
    },
    {
      slide: OnboardingSlideType.FAVOURITES,
      title: t("onboarding.favorites.title"),
      description: t("onboarding.favorites.description"),
      image: "/static/images/onboarding/favorites.svg",
      shouldShow: (context) => context !== SignupContext.FAVORITE,
    },
    {
      slide: OnboardingSlideType.FILL_OUT_PROFILE,
      title: t("onboarding.fill_out_profile.title"),
      description: t("onboarding.fill_out_profile.description"),
      image: "/static/images/onboarding/fill_out_profile.svg",
      shouldShow: () => true,
    },
  ];

  const filteredSlides = slides.filter((slide) =>
    slide.shouldShow(signupContext),
  );

  const isLastSlide = currentSlide === filteredSlides.length - 1;

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (
      (signupContext && !ignoreList.includes(signupContext)) ||
      !signupContext
    ) {
      OnboardingTracking.userSawOnboardingModal();
    }
  }, [signupContext]);

  const handleFinishClick = () => {
    OnboardingTracking.userFinishesOnboardingModal();
    handleOnClose();
  };

  const handleNextClick = () => {
    isLastSlide
      ? handleFinishClick()
      : setCurrentSlide(
          currentSlide === filteredSlides.length - 1 ? 0 : currentSlide + 1,
        );
  };

  const handlePrevClick = () => {
    setCurrentSlide(
      currentSlide === 0 ? filteredSlides.length - 1 : currentSlide - 1,
    );
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 37 && currentSlide !== 0) {
      handlePrevClick();
    } else if (
      event.keyCode === 39 &&
      currentSlide !== filteredSlides.length - 1
    ) {
      handleNextClick();
    }
  };

  const handleOnSkip = () => {
    OnboardingTracking.userSkipsOnboardingModal();
    handleOnClose();
  };

  if (signupContext && ignoreList.includes(signupContext)) {
    handleOnClose();
  }

  return {
    currentSlide,
    filteredSlides,
    isLastSlide,
    setCurrentSlide,
    handleNextClick,
    handleOnSkip,
    handleOnClose,
  };
};
