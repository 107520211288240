import {
  Center,
  Dialog,
  DialogContent,
  DialogHeader,
} from "@boligportal/juice";
import { PaymentSpinner } from "components/payment_spinner";

type Props = {
  title: string;
};

export const StartSubscriptionProcessingDialog = ({ title }: Props) => (
  <Dialog
    open
    size="medium"
    hideOnClickOutside={false}
    dismissable={false}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogContent>
      <Center height="275px">
        <PaymentSpinner submitted={false} />
      </Center>
    </DialogContent>
  </Dialog>
);
