import { Box, TextField, IconSearch, IconClose } from "@boligportal/juice";
import { t } from "lib/i18n";

export const Filter = ({
  filter,
  onFilter,
}: {
  filter: string;
  onFilter: (value: string) => void;
}) => {
  const renderSearchIconAction = () => {
    if (filter !== "") {
      return (
        <Box onClick={() => onFilter("")}>
          <IconClose />
        </Box>
      );
    }

    return <IconSearch />;
  };

  return (
    <TextField
      mb={3}
      value={filter}
      placeholder={t("common.buttons.search")}
      append={renderSearchIconAction()}
      onChange={(value) => onFilter(value.currentTarget.value)}
    />
  );
};
