import * as React from "react";
import { SelectField } from "@boligportal/juice";
import { useGetRentableTenancies } from "api/queries";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import {
  createTenancySelectorOption,
  TenancySelectorItem,
} from "features/rentable_tenancy/hooks/useTenancySelector";
import { useTenancySelection } from "features/tenancy/useTenancySelection";
import { t } from "lib/i18n";

const CHOOSE_TENANCY_OPTION = "-1";

const createItems = (candidates: Tenancy[]): TenancySelectorItem[] => {
  const result: TenancySelectorItem[] = [];

  result.push({
    label: t("tenancy_selctor.option.new_tenancy"),
    value: CHOOSE_TENANCY_OPTION,
  });

  candidates.forEach((candidate) => {
    result.push(createTenancySelectorOption(candidate));
  });

  return result;
};

type Props = {
  rentableId: number;
};

export const BuilderTenancySelector = ({ rentableId }: Props) => {
  const { data } = useGetRentableTenancies(rentableId);
  const { selectedTenancyId, setTenancyChangeCandidate } =
    useTenancySelection();

  const onHandleTenancySelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const targetValue = event.target.value;

    const tenancyIdValue =
      targetValue === CHOOSE_TENANCY_OPTION ? null : Number(targetValue);

    setTenancyChangeCandidate({
      tenancies: data?.tenancies ?? [],
      tenancyId: tenancyIdValue,
    });
  };

  if (!data) {
    return null;
  }

  const items = createItems(data.tenancies);
  const selectedItemValue = selectedTenancyId
    ? selectedTenancyId.toString()
    : CHOOSE_TENANCY_OPTION;

  return (
    <SelectField
      items={items}
      label={t("tenancy_selctor.label")}
      value={selectedItemValue}
      onChange={onHandleTenancySelectionChange}
    />
  );
};
