import { useFormContext } from "react-hook-form";
import { CheckField, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

const RentalFeatures = () => {
  const { register } = useFormContext();

  return (
    <>
      <Text
        block
        size="small"
        weight="bold"
        mb={1}
        mt={2}
      >
        {t("details.group.lifestyle")}
      </Text>

      <CheckField
        label={t("details.shareable")}
        {...register("shareable")}
      />
      <CheckField
        label={t("details.pet_friendly")}
        {...register("pet_friendly")}
      />
      <CheckField
        label={t("details.senior_friendly")}
        {...register("senior_friendly")}
      />
      <CheckField
        label={t("details.student_only")}
        {...register("student_only")}
      />

      <Text
        block
        size="small"
        weight="bold"
        mb={1}
        mt={2}
      >
        {t("details.group.facilities")}
      </Text>

      <CheckField
        label={t("details.parking")}
        {...register("parking")}
      />
      <CheckField
        label={t("details.elevator")}
        {...register("elevator")}
      />
      <CheckField
        label={t("details.balcony")}
        {...register("balcony")}
      />
      <CheckField
        label={t("details.electric_charging_station")}
        {...register("electric_charging_station")}
      />

      <Text
        block
        size="small"
        weight="bold"
        mb={1}
        mt={2}
      >
        {t("details.group.inventory")}
      </Text>

      <CheckField
        label={t("details.dishwasher")}
        {...register("dishwasher")}
      />
      <CheckField
        label={t("details.washing_machine")}
        {...register("washing_machine")}
      />
      <CheckField
        label={t("details.dryer")}
        {...register("dryer")}
      />
      <CheckField
        label={t("details.furnished")}
        {...register("furnished")}
      />
    </>
  );
};

export { RentalFeatures };
