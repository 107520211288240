import { useCallback } from "react";

interface DawaResponse {
  id: null | string;
  address: string;
  type: string;
}

export const useDawa = () => {
  const getAddressSuggestions = useCallback(
    async (address: string, id: string | null): Promise<DawaResponse[]> => {
      const extra = id ? `?adgangsadresseid=${id}` : "";
      const response = await fetch(
        `https://address.boligportal.dk/autocomplete/dk/${address}${extra}`,
      );
      const data = await response.json();
      return Promise.resolve(data ?? []);
    },
    [],
  );

  return {
    getAddressSuggestions,
  };
};
