import { Flex, Text } from "@boligportal/juice";
import { App } from "components/app";
import { formatWithLocale } from "lib/date";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";

export const TransactionLine = ({
  amount,
  paymentDate,
}: {
  amount: number | null;
  paymentDate: string | null;
}) => {
  const accountingDateFormatted = formatWithLocale(
    new Date(paymentDate ?? 0),
    "d. MMM u",
  );
  return (
    <Flex
      justify="space-between"
      my={2}
    >
      {paymentDate && (
        <>
          <Text>{`${accountingDateFormatted}: ${
            amount && amount >= 0
              ? t("partial_payments.manual_payment")
              : t("partial_payments.manual_refund")
          }`}</Text>
          <Text>
            {amount &&
              formatCurrency(isNaN(amount) ? 0 : amount, App.settings.currency)}
          </Text>
        </>
      )}
    </Flex>
  );
};
