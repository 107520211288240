/**
 * Customer-Service
 * Impersonating user Feature
 *
 */
const useImpersonateUserFeature = () => {
  const impersonateUserLabel = "Login as user";

  const getImpersonateUserHref = (userId: number) =>
    `/customer-service/begin-impersonation/${userId}`;

  const impersonateUser = (userId: number) => {
    window.open(getImpersonateUserHref(userId), "_self");
  };

  return {
    getImpersonateUserHref,
    impersonateUserLabel,
    impersonateUser,
  };
};

export { useImpersonateUserFeature };
