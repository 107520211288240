import {
  Box,
  Button,
  Flex,
  IconArrowForward,
  IconCheckmarkCircle,
  IconDocumentTextOutline,
  Text,
} from "@boligportal/juice";
import styled from "@emotion/styled";
import { Props as ContractNudgeDialogProps } from "./ContractNudgeDialog";

const Icon = styled(IconCheckmarkCircle)`
  font-size: 48px;
`;

export const ContractNudgeDialogContent = ({
  description,
  onConfirm,
  onConfirmText,
  subtitle,
}: Pick<
  ContractNudgeDialogProps,
  "description" | "onConfirm" | "onConfirmText" | "subtitle"
>) => (
  <Flex
    column
    align="center"
  >
    <Icon
      color="success"
      mb={3}
    />
    <Box
      textAlign="center"
      mb={4}
    >
      <Text
        weight="headline"
        size="h3"
      >
        {subtitle}
      </Text>
    </Box>

    <Flex
      border
      bg="tint"
      p={2}
      borderRadius="md"
      align="start"
      mb={3}
      width="100%"
    >
      <Box
        border
        bg="base"
        borderRadius="full"
        p={1}
        mr={1.5}
      >
        <IconDocumentTextOutline size="medium" />
      </Box>
      <Box>
        <Text
          block
          mb={1.5}
        >
          {description}
        </Text>
        <Button
          text
          variant="subtle"
          iconAfter={IconArrowForward}
          onClick={onConfirm}
        >
          {onConfirmText}
        </Button>
      </Box>
    </Flex>
  </Flex>
);
