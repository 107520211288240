import { Box, Flex } from "@boligportal/juice";
import { AgreementsTable } from "./AgreementsTable";
import { PaymentsTable } from "./PaymentsTable";

export const PaymentsAndAgreements = () => (
  <Box
    m={4}
    width="100%"
  >
    <Flex gap={2}>
      <Box
        width="50%"
        border
      >
        <AgreementsTable />
      </Box>
      <Box
        width="50%"
        border
      >
        <PaymentsTable />
      </Box>
    </Flex>
  </Box>
);
