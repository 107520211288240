import { Box, Text } from "@boligportal/juice";
import { ImageList } from "apps/moving_reports/components/images_list/ImageList";
import { CombinedDefect } from "apps/moving_reports/hooks/useDefectsFromReport";
import { ReadMoreContainer } from "components/read_more_container";
import { MAX_LENGTH_DETAILS } from "./DefectInfoTenantRemarks";

export const DefectInfoMain = ({ defect }: { defect: CombinedDefect }) => {
  const { room, unit, images, description } = defect;

  return (
    <>
      <Text
        weight="bold"
        block
      >
        {room}/{unit}
      </Text>
      <Box mt={2}>
        {description ? (
          <ReadMoreContainer
            content={description}
            maxLength={MAX_LENGTH_DETAILS}
          />
        ) : (
          "–"
        )}
      </Box>
      <Box mt={2}>
        {images.length !== 0 ? (
          <ImageList
            images={images}
            size={2}
          />
        ) : (
          "–"
        )}
      </Box>
    </>
  );
};
