import * as React from "react";
import { Flex, Box, styled } from "@boligportal/juice";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const RentOutTodayHeader = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: #191919;
`;

export const CallToActionTextSection = ({
  text,
  ctaButtonComponent,
}: {
  text: string;
  ctaButtonComponent: React.ReactNode;
}) => (
  <PageBlock
    bg="gray"
    py="medium"
    pyMobile="large"
    bottomShadow
  >
    <ContractsLandingPageContainer>
      <Flex
        column={{
          xs: true,
          md: false,
        }}
        align="center"
        justify="center"
        textAlign="center"
      >
        <RentOutTodayHeader>{text}</RentOutTodayHeader>

        <Box
          ml={{
            xs: 0,
            md: 4,
          }}
          mt={{
            xs: 2,
            md: 0,
          }}
        >
          {ctaButtonComponent}
        </Box>
      </Flex>
    </ContractsLandingPageContainer>
  </PageBlock>
);
