export enum AdStates {
  ACTIVE_APPROVED = "ACTIVE_APPROVED",
  INACTIVE_DRAFT = "INACTIVE_DRAFT",
  DRAFT = "DRAFT",
  INACTIVE_PENDING_APPROVAL = "INACTIVE_PENDING_APPROVAL",
  RESERVED_APPROVED = "RESERVED_APPROVED",
  RENTED_OUT_APPROVED = "RENTED_OUT_APPROVED",
  INACTIVE_REJECTED = "INACTIVE_REJECTED",
}

export enum ImportStates {
  IMPORT_ENABLED = "IMPORT_ENABLED",
  IMPORT_DISABLED = "IMPORT_DISABLED",
}

export enum CancellationReasons {
  TOO_EXPENSIVE = "TOO_EXPENSIVE",
  FOUND_PLACE_ON_THE_PLATFORM = "FOUND_PLACE_ON_THE_PLATFORM",
  FOUND_ANOTHER_PLACE_ELSEWHERE = "FOUND_ANOTHER_PLACE_ELSEWHERE",
  OTHER = "OTHER",
}

export enum ReportAdReasons {
  POTENTIAL_SCAM = "POTENTIAL_SCAM",
  RENTED_OUT = "RENTED_OUT",
  WRONG_INFORMATION = "WRONG_INFORMATION",
}

export enum AdCategories {
  APARTMENT = "rental_apartment",
  TOWNHOUSE = "rental_townhouse",
  HOUSE = "rental_house",
  ROOM = "rental_room",
  CABIN = "rental_cabin",
}

export enum blacklistReasonEnum {
  undefined = "",
  PROFILE_SHARING = "profile_sharing",
  COMPANY_DEAL = "company_deal",
  PHISHED = "phished",
  SOCIAL_HOUSING = "social_housing",
  MAYBE_SCAMMER = "maybe_scammer",
  OTHER_YELLOW = "other_yellow",
  BANNED_USER = "banned_user",
  REJECTED_LANDLORD = "rejected_landlord",
  REJECTED_SEEKER = "rejected_seeker",
  OTHER_RED = "other_red",
}
export enum CrawlingFeedStatus {
  PAUSED = 0,
  ACTIVE = 1,
}

export enum NumberOfRentables {
  range_1_1 = "1",
  range_2_19 = "2-19",
  range_20_99 = "20-99",
  range_100_249 = "100-249",
  range_250_499 = "250-499",
  range_500_999 = "500-999",
  range_1000_1999 = "1000-1999",
  range_2000_infinity = "2000+",
}

export enum SubscriptionStatusFilter {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  NEVER_SUBSCRIBED = "NEVER_SUBSCRIBED",
}

export enum SignupContext {
  CREATE_RENTABLE = "CREATE_RENTABLE",
  CONTRACTS = "CONTRACTS",
  MOVING_REPORTS = "MOVING_REPORTS",
  MOVING_OUT = "MOVING_OUT",
  MOVING_IN = "MOVING_IN",
  SEARCH_AGENT = "SEARCH_AGENT",
  FREE_CONTACT = "FREE_CONTACT",
  NEWSLETTER = "NEWSLETTER",
  PROMOTE = "PROMOTE",
  CREATE_TENANCY = "CREATE_TENANCY",
  CREATE_RENT_COLLECTION = "CREATE_RENT_COLLECTION",
  FAVORITE = "FAVORITE",
  RENTAL_DATA = "RENTAL_DATA",
  CONTACT_LANDLORD = "CONTACT_LANDLORD",
  AD_DIGITAL_SHOWING = "AD_DIGITAL_SHOWING",
  SEE_AD_VIDEO = "SEE_AD_VIDEO",
  NEW_BUILDS = "NEW_BUILDS",
}

export enum OrderType {
  SIZE_M2_DESC = "SIZE_M2_DESC",
  SIZE_M2_ASC = "SIZE_M2_ASC",
  RENT_ASC = "RENT_ASC",
  RENT_DESC = "RENT_DESC",
  DEFAULT = "DEFAULT",
}

export enum FavoriteCardStatus {
  ALL = "ALL",
  AVAILABLE = "AVAILABLE",
  CONTACTED = "CONTACTED",
  UNAVAILABLE = "UNAVAILABLE",
}
