import { useContext, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  Box,
  Flex,
  Button,
  CardSection,
  Text,
  Switch,
} from "@boligportal/juice";
import { useCompanyPageSubscriptionMutation } from "api/mutations";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { localizedDateTimeLongAndPretty } from "lib/date";
import { CompanyDetailsContext } from "./company_details_context";

export const CompanyPageSubscriptionPanel = () => {
  const { company, companyUsers, fetchCompany } = useContext(
    CompanyDetailsContext,
  );
  const [
    isCompanyPageSubscriptionDialogVisible,
    setIsCompanyPageSubscriptionDialogVisible,
  ] = useState(false);

  const subscriptionMutation = useCompanyPageSubscriptionMutation();

  if (!company) {
    return null;
  }

  const handleStartCompanyPageSubscription = async (userId: number) => {
    if (userId) {
      subscriptionMutation.mutate(
        {
          userId,
          enabled: true,
        },
        {
          onSuccess: (data) => {
            if (!data.error) {
              setIsCompanyPageSubscriptionDialogVisible(false);
              fetchCompany(company.id, true);
            }
          },
        },
      );
    }
  };

  const toggleCompanyPageSubscription = () => {
    const activeSubscriptionUserId = companyUsers.find(
      (user) => user.company_page_subscription,
    )?.user_id;
    if (company?.company_page_subscription && activeSubscriptionUserId) {
      subscriptionMutation.mutate(
        {
          userId: activeSubscriptionUserId,
          enabled: false,
        },
        {
          onSuccess: (data) => {
            if (!data.error) {
              fetchCompany(company.id, true);
            }
          },
        },
      );
    } else {
      setIsCompanyPageSubscriptionDialogVisible(true);
    }
  };

  const usersOfCopanyHaveSubscription = companyUsers.some(
    (user) => user.company_page_subscription,
  );

  return (
    <>
      {isCompanyPageSubscriptionDialogVisible && (
        <Dialog
          open
          onClose={() => setIsCompanyPageSubscriptionDialogVisible(false)}
        >
          <DialogHeader>Select which user to add subscription to</DialogHeader>
          <DialogContent>
            <Box>
              {companyUsers.map((user) => (
                <Flex
                  key={user.user_id}
                  justify="space-between"
                  align="center"
                  width="100%"
                  py={2}
                >
                  <Text>{`${user.first_name} ${user.last_name}`}</Text>
                  <Button
                    variant="primary"
                    loading={subscriptionMutation.isLoading}
                    disabled={
                      usersOfCopanyHaveSubscription &&
                      !company.company_page_subscription_expiration_date
                    }
                    onClick={() =>
                      handleStartCompanyPageSubscription(user.user_id)
                    }
                  >
                    Start subscription
                  </Button>
                </Flex>
              ))}
            </Box>
            {subscriptionMutation.data?.error && (
              <Text
                color="danger"
                block
                textAlign={"right"}
              >
                {subscriptionMutation.data.error}{" "}
                {subscriptionMutation.data.error_code}
              </Text>
            )}
          </DialogContent>
        </Dialog>
      )}
      {App.settings.market === Market.BOLIGPORTAL_DK && (
        <CardSection title="Company page subscription">
          <Switch
            checked={company.company_page_subscription}
            onChange={toggleCompanyPageSubscription}
          />
          {companyUsers.some((user) => user.company_page_subscription) && (
            <StyledLink
              to={`/customer-service/users/${companyUsers.find(
                (user) => user.company_page_subscription,
              )?.user_id}`}
            >
              <Text block>
                {(() => {
                  const subscriber = companyUsers.find(
                    (user) => user.company_page_subscription,
                  );
                  return `${subscriber?.first_name} ${subscriber?.last_name} is the subscriber.`;
                })()}
              </Text>
            </StyledLink>
          )}
          {company.company_page_subscription_expiration_date && (
            <Text block>{`Expires: ${localizedDateTimeLongAndPretty(
              new Date(company.company_page_subscription_expiration_date),
            )}`}</Text>
          )}
        </CardSection>
      )}
    </>
  );
};
