import { useState, useCallback, useEffect } from "react";
import { SortableColumn } from "@boligportal/juice";
import { CrawlersListItem, UserListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getCrawlersService } from "../services/get_crawlers_service";

const createCrawlersTableItems = (
  items: CrawlersListItem[],
): CrawlersListItem[] => {
  const tableDataItems: CrawlersListItem[] = [];

  items.forEach((item) => {
    tableDataItems.push({
      user: {
        id: item.default_owner_id !== null ? item.default_owner_id : null,
        fullname: `${item.first_name} ${item.last_name}`,
        username: item.username,
      },
      first_name: item.first_name,
      last_name: item.last_name,
      username: item.username,
      name: item.name,
      source: item.source,
      default_owner_id: item.default_owner_id,
      id: item.id,
      created_date: item.created_date,
      ads: item.ads,
      owner_mappings: item.owner_mappings,
      status: item.status,
    });
  });

  return tableDataItems;
};

const useCrawlingsPageFeature = (config?: {
  debouncedSearchDelay?: number;
  initialSearchValue?: string;
}) => {
  const debouncedSearchDelay = config?.debouncedSearchDelay ?? 250;
  const initialSearchValue = config?.initialSearchValue ?? "";
  const [items, setItems] = useState<CrawlersListItem[]>([]);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [active, setActiveFilter] = useState(false);
  const [sorting, setSorting] = useState<SortableColumn<CrawlersListItem>>({
    column: "created_date",
    descending: true,
  });

  const debouncedSearchValue = useDebounce(searchValue, debouncedSearchDelay);

  const search = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const filterChange = useCallback((value: boolean) => {
    setActiveFilter(value);
  }, []);

  const sortingChange = useCallback(
    (value: SortableColumn<CrawlersListItem>) => {
      setSorting(value);
    },
    [],
  );

  const refetch = () => {
    const abortController = new AbortController();
    getCrawlersService(
      debouncedSearchValue,
      offset,
      limit,
      abortController.signal,
      active,
      sorting.column,
      sorting.descending,
    ).then((response) => {
      setItems(createCrawlersTableItems(response.results));
      setCount(response.count);
    });
  };

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    getCrawlersService(
      debouncedSearchValue,
      offset,
      limit,
      abortController.signal,
      active,
      sorting.column,
      sorting.descending,
    )
      .then((response) => {
        setItems(createCrawlersTableItems(response.results));
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [debouncedSearchValue, offset, active, sorting]);

  return {
    items,
    search,
    filterChange,
    sortingChange,
    searchValue,
    refetch,
    count,
    limit,
    offset,
    setOffset,
    active,
    sorting,
  };
};

export { useCrawlingsPageFeature };
