import { Center, Spinner } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { ApplePayButton } from "components/modals/ApplePayButton";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { useApplePay } from "../coordinators/applepay/useApplePay";
import { GooglePayPaymentMethodButton } from "../coordinators/googlepay/GooglePayButton";
import { useGooglePay } from "../coordinators/googlepay/useGooglePay";
import { MobilePayPaymentMethodButton } from "../coordinators/mobilepay/MobilePayPaymentMethodButton";
import { QuickpayPaymentMethodButton } from "../coordinators/quickpay/QuickpayPaymentMethodButton";

const paymentMethodTypeToButtonMap = {
  [PaymentMethodType.QuickPay]: QuickpayPaymentMethodButton,
  [PaymentMethodType.MobilePay]: MobilePayPaymentMethodButton,
  [PaymentMethodType.ApplePay]: ApplePayButton,
  [PaymentMethodType.GooglePay]: GooglePayPaymentMethodButton,
};

export const PaymentMethodButtons = ({
  paymentMethods,
  onClick,
  plan,
  plans,
}: {
  paymentMethods: PaymentMethodType[];
  onClick: (type: PaymentMethodType) => void;
  plans: Plan[];
  plan: Plan;
}) => {
  const { canMakeApplePay, isLoading, paymentRequest, stripe } =
    useApplePay(plan);

  const { paymentRequest: googlePaymentRequest } = useGooglePay(plan);

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <div className="mb-8 flex flex-1 flex-col space-y-4 md:mb-0">
      {paymentMethods
        .filter((item) => item !== PaymentMethodType.Account)
        .map((paymentMethod, index) => {
          const PaymentMethodTypeButton =
            paymentMethodTypeToButtonMap[paymentMethod];
          return (
            <PaymentMethodTypeButton
              vitestId={`PaymentMethodButton${index + 1}`}
              key={paymentMethod}
              onClick={onClick}
              plans={plans}
              selectedPlan={plan}
              paymentRequest={paymentRequest}
              googlePaymentRequest={googlePaymentRequest}
              stripe={stripe}
              canMakeApplePay={canMakeApplePay}
            />
          );
        })}
    </div>
  );
};
