import { Flex, Text } from "@boligportal/juice";
import { InboxCountBadge } from "components/InboxBadgeCount";

export const InboxMenuItem = (item: any, isOpened: boolean) => {
  const { name } = item;
  return (
    <Flex
      align="center"
      justify="space-between"
    >
      <Text>{name}</Text>
      {isOpened && <InboxCountBadge />}
    </Flex>
  );
};
