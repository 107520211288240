import { Button, IconArrowForward } from "@boligportal/juice";
import { t } from "lib/i18n";
import { LeadsSection } from "../LeadsSections";

type Props = {
  name: string;
  onClick: () => void;
  logo: React.ReactNode;
  isSigned: boolean;
  disabled?: boolean;
  section: LeadsSection;
  successText?: string;
};

export const LeadsPartner = ({
  name,
  onClick,
  logo,
  disabled = false,
  isSigned = false,
  section,
  successText,
}: Props) => {
  const isInternet = section === LeadsSection.INTERNET;
  const isActive = (!isSigned && !disabled) || isInternet;
  return (
    <div
      key={name}
      className={`${
        isSigned ? "bg-success-50" : "bg-gray-10"
      } flex w-auto items-center justify-between rounded-sm border border-solid p-3 py-3 ${
        isSigned ? "border-success-500" : "border-gray-40"
      } ${
        isActive &&
        `hover:cursor-pointer ${
          isSigned ? "hover:bg-success-100" : "hover:bg-gray-20"
        }`
      }`}
      {...(isActive
        ? {
            onClick: () => onClick(),
          }
        : {})}
    >
      <div>
        <div>{logo}</div>
        {isInternet && isSigned ? (
          <div className="text-sm text-muted">
            {successText ?? t("tenant_hub.leads.lead_is_signed")}
          </div>
        ) : (
          <p className="mb-1 text-sm text-muted">{name}</p>
        )}
      </div>

      {isActive && (
        <Button
          variant="primary"
          text
          onClick={onClick}
          disabled={isInternet ? false : isSigned}
          iconAfter={IconArrowForward}
        >
          {t("tenant_hub.leads.read_more")}
        </Button>
      )}
      {isSigned && !isInternet && (
        <div className="text-sm text-muted">
          {t("tenant_hub.leads.lead_is_signed")}
        </div>
      )}
    </div>
  );
};
