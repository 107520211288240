import { fetchFromAPI } from "lib/api";
import { DraftsListItem } from "lib/customerServiceApi";

const getCrawlersDraftsService = async (data: {
  signal: AbortSignal;
  offset: number;
  limit: number;
}): Promise<{ results: DraftsListItem[]; count: number }> => {
  const url = "/customer-service/api/crawlings/drafts/";
  const { signal, offset, limit } = data;
  try {
    const response = await fetchFromAPI(url, {
      signal: signal,
      method: "POST",
      body: JSON.stringify({
        offset,
        limit,
      }),
    });
    return await response.json();
  } catch (error) {
    // eslint-disable-next-line compat/compat
    return Promise.reject(error);
  }
};

export { getCrawlersDraftsService };
