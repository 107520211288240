import { useFormContext } from "react-hook-form";
import { t } from "lib/i18n";
import { ControlledPaymentNumberField } from "../ControlledPaymentNumberField";

export const MonthlyRent = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <ControlledPaymentNumberField
      name="monthly_rent"
      control={control}
      testId={"createAdMonthlyRent"}
      required
      label={t("create.field.monthly_rent.heading")}
      errorText={errors.monthly_rent && t("required_field.numeric")}
    />
  );
};
