import {
  Button,
  Flex,
  Text,
  styled,
  Box,
  IconCheckmarkCircleOutline,
  Dialog,
  DialogHeader,
  DialogContent,
} from "@boligportal/juice";
import { useUpsellPaymentMethodCreditCardSVGs } from "components/Providers/MarketSettingsProvider";
import { App } from "components/app";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { PaymentMethodCreditCards } from "features/paywall/components/PaymentMethodCreditCards";
import { PaymentPolicyButtonsWrapper } from "features/paywall/components/PaymentPolicyButtonsWrapper";
import { t } from "lib/i18n";
import SvgPaymentCards from "media/svg/PaymentCards";
import SvgPaymentMobilePay from "media/svg/PaymentMobilePay";

interface ChoosePaymentMethodModalProps {
  paymentMethodTypes: PaymentMethodType[];
  onPaymentMethodSelected: (paymentMethod: PaymentMethodType) => void;
  type: "default" | "upsell";
}

const ChoosePaymentMethodModal = ({
  paymentMethodTypes,
  onPaymentMethodSelected,
  type,
}: ChoosePaymentMethodModalProps) => {
  const canUseMobilePay = paymentMethodTypes.includes(
    PaymentMethodType.MobilePay,
  );
  const canUseQuickPay = paymentMethodTypes.includes(
    PaymentMethodType.QuickPay,
  );

  const creditCardIcons = useUpsellPaymentMethodCreditCardSVGs();

  return (
    <Dialog
      open
      size="564px"
    >
      <DialogHeader>
        {t("features.payments.choose_payment_method.title")}
      </DialogHeader>
      <DialogContent>
        <PaymentPolicyButtonsWrapper>
          <div className="flex flex-col gap-4">
            {canUseMobilePay && App.settings.mobile_pay_enabled && (
              <MobilePayOption onSelected={onPaymentMethodSelected} />
            )}
            {canUseQuickPay && (
              <QuickPayOption onSelected={onPaymentMethodSelected} />
            )}
          </div>
        </PaymentPolicyButtonsWrapper>

        <Box py={3}>
          <PaymentMethodCreditCards cards={creditCardIcons} />
        </Box>
        {type === "default" ? (
          <Box
            borderRadius="md"
            border
            p={2}
          >
            <Benefits
              items={[
                t("features.payments.choose_payment_method.benefit.1"),
                t("features.payments.choose_payment_method.benefit.2"),
                t("features.payments.choose_payment_method.benefit.3"),
              ]}
            />
          </Box>
        ) : (
          <Box
            textAlign="center"
            borderRadius="md"
            border
            p={1}
            order={{ md: 3 }}
          >
            <Text size="tiny">
              {t("features.payments_choose_payment_method.cancel_anytime")}
            </Text>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { ChoosePaymentMethodModal };

// ************************************************************************************************
// Sub Components only used by this modal
// ************************************************************************************************

export const StyledMobilePayButton = styled(Button)`
  background-color: #5a78ff;
  &:hover {
    background-color: #5d74f4;
  }
`;

const StyledCardPayButton = styled(Button)`
  background-color: #00b362;
  &:hover {
    background-color: #55ba72;
  }
`;

interface PayOptionProps {
  onSelected: (option: PaymentMethodType) => void;
}

export const MobilePayOption = ({ onSelected }: PayOptionProps) => {
  const clickHandler = () => {
    onSelected(PaymentMethodType.MobilePay);
  };
  return (
    <StyledMobilePayButton onClick={clickHandler}>
      <Flex
        align="center"
        justify="center"
        height="40px"
      >
        <SvgPaymentMobilePay />
        <Text
          ml={1}
          color="inverted"
        >
          {t("features.payments.choose_payment_method.pay_with_mobilepay")}
        </Text>
      </Flex>
    </StyledMobilePayButton>
  );
};

export const QuickPayOption = ({ onSelected }: PayOptionProps) => {
  const clickHandler = () => {
    onSelected(PaymentMethodType.QuickPay);
  };
  return (
    <StyledCardPayButton
      testId={"payWithQuickPay"}
      onClick={clickHandler}
    >
      <Flex
        align="center"
        justify="center"
        height="40px"
      >
        <SvgPaymentCards />
        <Text
          ml={1.5}
          color="inverted"
        >
          {t("features.payments.choose_payment_method.pay_with_creditcard")}
        </Text>
      </Flex>
    </StyledCardPayButton>
  );
};

const Benefits = ({ items }: { items: string[] }) => (
  <>
    {items.map((item) => (
      <Flex
        key={item}
        align="center"
      >
        <Box>
          <IconCheckmarkCircleOutline
            size="medium"
            inline
            mr={1}
            color="success"
          />
        </Box>
        <Text size="tiny">{item}</Text>
      </Flex>
    ))}
  </>
);
