import * as React from "react";
import { styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import SvgFilter12Px from "media/icons/Filter12Px";
import SvgMaps12Px from "media/icons/Maps12Px";
import {
  Action_changeView,
  Action_toggleShowMobileFilters,
} from "../../search_result_reducer";

const StyledViewToggle = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: ${(props) => props.theme.borderWidth.sm} solid
    ${(props) => props.theme.color.border};
  border-top: ${(props) => props.theme.borderWidth.sm} solid
    ${(props) => props.theme.color.border};
  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0;
    border-right: ${(props) => props.theme.borderWidth.sm} solid
      ${(props) => props.theme.color.border};
    border-left: ${(props) => props.theme.borderWidth.sm} solid
      ${(props) => props.theme.color.border};
    border-radius: ${(props) => props.theme.borderRadius.sm};
  }
`;

const StyledViewToggleBtn = styled.button`
  background-color: ${(props) => props.theme.colorPalette.gray[10]};
  box-shadow: none;
  -webkit-appearance: none;
  padding: 0.8em 1em;
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: $body-color;
  cursor: pointer;
  border: none;
  flex: 1;
  &:first-of-type {
    border-right: ${(props) => props.theme.borderWidth.sm} solid
      ${(props) => props.theme.color.border};
  }
  &:focus {
    // Only shown on mobile, so we're okay with no focus styling
    outline: none;
  }
  .Icon {
    margin-right: 0.3em;
  }
`;

const ViewToggle = ({
  dispatch,
}: {
  dispatch: React.Dispatch<Action_changeView | Action_toggleShowMobileFilters>;
}) => (
  <StyledViewToggle>
    <StyledViewToggleBtn
      onClick={() => {
        TrackingEvent.clickViewMapResults();
        dispatch({
          type: "changeView",
          payload: {
            view: "map",
          },
        });
      }}
    >
      <SvgMaps12Px />
      {t("srp.map")}
    </StyledViewToggleBtn>
    <StyledViewToggleBtn
      onClick={() => {
        dispatch({
          type: "toggleShowMobileFilters",
        });
      }}
    >
      <SvgFilter12Px /> {t("srp.filter")}
    </StyledViewToggleBtn>
  </StyledViewToggle>
);

export { ViewToggle };
