import { useState } from "react";
import { CrawlingDetails } from "lib/customerServiceApi";
import { getCrawlingService } from "../services/get_crawling_service";
import {
  updateCrawlingService,
  CrawlingUpdatePayload,
} from "../services/update_crawling_service";

export interface CrawlingOwnerMapping {
  email: string;
  phone: string;
  user?: {
    id: number;
    username: string;
  };
}

const useEditCrawlingFeature = () => {
  const [crawlingDetails, setCrawlingDetails] =
    useState<CrawlingDetails | null>(null);
  const getCrawling = (crawlingId: number) => {
    getCrawlingService(crawlingId).then((response) => {
      console.log(response);
      setCrawlingDetails(response);
    });
  };

  const saveCrawling = (
    payload: CrawlingUpdatePayload,
    onSuccess: () => void,
    onError: () => void,
  ) => {
    if (crawlingDetails) {
      updateCrawlingService(crawlingDetails.id, payload).then((response) => {
        console.log(response);
        if (response.success) {
          onSuccess();
        } else {
          onError();
        }
      });
    }
  };

  return {
    crawlingDetails,
    getCrawling,
    saveCrawling,
  };
};

export { useEditCrawlingFeature };
