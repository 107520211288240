import { useForm } from "react-hook-form";
import { MAX_COMPANY_PAGE_HERO_IMAGES } from "apps/company_page/list/components/company_hero_section/consts";
import { SortableImage } from "features/image_organizer/interfaces/SortableImage";
import { CompanyPageForm } from "../types";

export type CompanyPageSlideOverFormValues = Omit<
  CompanyPageForm,
  "image_ids" | "logo_image_id"
>;

export const useCompanyPageForm = (
  initialValues?: CompanyPageSlideOverFormValues,
) => {
  const { register, handleSubmit, watch, reset } =
    useForm<CompanyPageSlideOverFormValues>({
      defaultValues: initialValues ?? {
        slug: "",
        filter_url: "",
        headline: "",
        lead: "",
        body: "",
        meta_description: "",
        demo: false,
      },
    });
  return {
    register,
    handleSubmit,
    watch,
    reset,
  };
};

export const createPayload = (
  formData: CompanyPageSlideOverFormValues,
  logo: SortableImage[],
  images: SortableImage[],
) => {
  if (images.length > MAX_COMPANY_PAGE_HERO_IMAGES) {
    return "You can only upload 5 images";
  }

  const logoImageId = logo.length > 0 ? logo[0].id : null;

  const payload: CompanyPageForm = {
    ...formData,
    logo_image_id: logoImageId,
    image_ids: images.map((image) => image.id),
  };

  return payload;
};
