import { AdItem } from "apps/customer_service/pages/ads_page/interfaces";
import { Company } from "apps/customer_service/pages/user_detail_page/company_details_view/interfaces";
import { UserListItem } from "lib/customerServiceApi";
import {
  ISearchItem,
  ISearchItemAd,
  ISearchItemUser,
  ISearchItemCompany,
} from "./interfaces";

export const createGlobalSearchUserItems = (
  users: UserListItem[],
): ISearchItem[] => {
  const userCandidates: ISearchItem[] = [];
  users.forEach((item) => {
    const userCandidate: ISearchItemUser = {
      id: item.id,
      fullname: `${item.first_name} ${item.last_name}`,
      username: item.username,
      ban_reasons: item.user_ban_reasons,
    };

    userCandidates.push({
      link: `/customer-service/users/${item.id}`,
      data: userCandidate,
    });
  });

  return userCandidates;
};

export const createGlobalSearchAdItems = (ads: AdItem[]): ISearchItem[] => {
  const adCandidates: ISearchItem[] = [];
  ads.map((item) => {
    const adCandidate: ISearchItemAd = {
      ad_id: item.id,
      address: item.address,
      formatted_address: item.formatted_address,
      state: item.state,
      landlord_id: item.landlord_id,
      needs_follow_up: item.needs_follow_up,
      review_reason: item.review_reason,
      deleted_date: item.deleted_date,
    };

    adCandidates.push({
      link: item.landlord_id
        ? `/customer-service/ads/detail/${item.id}?landlordId=${item.landlord_id}`
        : `/customer-service/ads/detail/${item.id}`,
      data: adCandidate,
    });
  });
  return adCandidates;
};

export const createGlobalSearchCompanyItems = (
  companies: Company[],
): ISearchItem[] =>
  companies.map((company) => ({
    link: `/customer-service/companies/${company.id}`,
    data: {
      id: company.id,
      name: company.name,
    } as ISearchItemCompany,
  }));
