import { Box, Flex } from "@boligportal/juice";
import { SellingPoint } from "features/upsell/components/SellingPoint/SellingPoint";

type Props = {
  benefits: string[];
};

export const UpsellCardBenefits = ({ benefits }: Props) => (
  <Box
    textAlign="left"
    mb={{
      xs: 1,
      md: 4,
    }}
  >
    <Flex column>
      {benefits.map((benefit) => (
        <SellingPoint
          key={benefit}
          text={benefit}
        />
      ))}
    </Flex>
  </Box>
);
