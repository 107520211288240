import { Plan } from "components/interfaces/plan";
import { StartSubscriptionFailedDialog } from "./StartSubscriptionFailedDialog";
import { StartSubscriptionProcessingDialog } from "./StartSubscriptionProcessingDialog";
import { StartSubscriptionSuccessfulDialog } from "./StartSubscriptionSuccessfulDialog";
import { useStartSubscription } from "./useStartSubscription";

type Props = {
  adId: number | null;
  errorButtonLabel?: string;
  extraSuccessMessage?: React.ReactNode;
  paymentMethodId: number;
  plans: Plan[];
  onClose: () => void;
  onPurchaseComplete: (plan: Plan) => void;
  onSubscriptionActivated?: () => void;
  onPaymentFailedRetryButtonClick: (plan: Plan) => void;
  additionalErrorText?: string | null;
};

export const StartSubscriptionFlow = ({
  adId,
  errorButtonLabel,
  extraSuccessMessage = "",
  paymentMethodId,
  plans,
  onClose,
  onPurchaseComplete,
  onSubscriptionActivated,
  onPaymentFailedRetryButtonClick,
  additionalErrorText,
}: Props) => {
  const {
    submitting,
    submitted,
    subscriptionActivated,
    paymentFailed,
    invoicePaid,
    getTitle,
  } = useStartSubscription(
    paymentMethodId,
    plans,
    adId,
    onSubscriptionActivated,
  );

  if (paymentFailed) {
    return (
      <StartSubscriptionFailedDialog
        onClose={onClose}
        additionalErrorText={additionalErrorText}
        retryPayment={() => onPaymentFailedRetryButtonClick(plans[0])}
        errorButtonLabel={errorButtonLabel}
      />
    );
  }

  if (submitting) {
    return <StartSubscriptionProcessingDialog title={getTitle()} />;
  }

  if (submitted && !paymentFailed && !additionalErrorText) {
    return (
      <StartSubscriptionSuccessfulDialog
        subscriptionActivated={subscriptionActivated}
        onClose={onClose}
        getTitle={getTitle}
        invoicePaid={invoicePaid}
        extraSuccessMessage={extraSuccessMessage}
        onPurchaseComplete={() => onPurchaseComplete(plans[0])}
      />
    );
  }

  return null;
};
