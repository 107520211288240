import { Tracking } from "lib/tracking/common";

// This file is only for GA4 events.
// In order to create a GTM, you must first create a new trigger in GTM, which should
// use the "track-event-ga4" as event name, and it should only fire on the eventName
// that is defined for the newly created event.
// Then one must create a new tag that uses this trigger, as well as define the parameters
// that should be sent to GA4. These parameters also needs to be created as a custom dimension
// in order for them to show up in reports
export const userLoggedIn = (method: "Email") => {
  Tracking.trackGA4Event("login", {
    method,
  });
};
