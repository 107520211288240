import { Fragment } from "react";
import { styled, Box, Flex } from "@boligportal/juice";
import SvgCheckmark from "media/svg/Checkmark";
import SvgRemove from "media/svg/Remove";
import { getSaaSSellingPoints, getSwedishSaaSSellingPoints } from "../../utils";

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 12px 0;
`;

const StyledTableData = styled.td<{ group?: boolean; last?: boolean }>`
  background-color: ${(props) =>
    props.group ? props.theme.color.bg.tint : props.theme.color.bg.base};
  font-weight: ${(props) => props.group && props.theme.fontWeight.bold};
  font-size: ${(props) => props.group && props.theme.fontSize.body};
  padding: 16px 24px;
  border: 1px solid ${(props) => props.theme.color.border};
  border-bottom: ${({ last, theme }) =>
    last ? `1px solid ${theme.color.border}` : "none"};
  min-width: 100px;
`;

export const SaasFeatureComparisonTable = ({
  sellingPointsOrder = [],
  isBoligPortal = true,
}: {
  sellingPointsOrder?: typeof isBoligPortal extends true
    ? Parameters<typeof getSaaSSellingPoints>
    : Parameters<typeof getSwedishSaaSSellingPoints>;
  isBoligPortal?: boolean;
}) => {
  const sellingPoints = isBoligPortal
    ? getSaaSSellingPoints(
        ...(sellingPointsOrder as Parameters<typeof getSaaSSellingPoints>),
      )
    : getSwedishSaaSSellingPoints(
        ...(sellingPointsOrder as Parameters<
          typeof getSwedishSaaSSellingPoints
        >),
      );

  const renderFeatureList = () => {
    const totalSellingPoints = sellingPoints.length;

    return (
      <>
        {sellingPoints.map((entry, entryIndex) => (
          <Fragment key={entry.group.id}>
            <tr>
              <StyledTableData group>{entry.group.name}</StyledTableData>
              <StyledTableData group />
            </tr>
            {entry.features.map((feature) => {
              const isLast = entryIndex === totalSellingPoints - 1;
              return (
                <tr key={feature.name}>
                  <StyledTableData last={isLast}>
                    {feature.name}
                  </StyledTableData>
                  <StyledTableData last={isLast}>
                    <Flex justify="center">
                      {feature.free ? <SvgCheckmark /> : <SvgRemove />}
                    </Flex>
                  </StyledTableData>
                </tr>
              );
            })}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <Box
      width={{
        xs: "100%",
        md: "auto",
      }}
    >
      <StyledTable>
        <tbody>{renderFeatureList()}</tbody>
      </StyledTable>
    </Box>
  );
};
