import { RentalAdViewQueryParamsForAdStateEnum } from "../enums/RentalAdViewQueryParamsForAdStateEnum";

export const convertAdStateEnumToArrayOfStrings = (
  adState: RentalAdViewQueryParamsForAdStateEnum,
): string[] => {
  if (adState === RentalAdViewQueryParamsForAdStateEnum.ALL) {
    return [];
  }
  return adState.split(",");
};
