import { useState, useMemo } from "react";
import * as React from "react";
import {
  Center,
  Confirm,
  Divider,
  DialogContent,
  DialogFooter,
  Text,
  Flex,
  Button,
  SortingSelect,
  TextField,
  Box,
} from "@boligportal/juice";
import { App } from "components/app";
import Fuse from "fuse.js";
import { InTouchTenant } from "lib/api";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { TenantItem } from "./choose_tenant_tenant_item";

enum Sortings {
  Latest = "LATEST",
  Most = "MOST",
  NameAscending = "NAME_ASC",
  NameDescending = "NAME_DESC",
}

const ChooseTenantList = ({
  tenants,
  selectedTenant,
  onContinue,
  onTenantSelect,
}: {
  tenants: InTouchTenant[];
  selectedTenant: undefined | InTouchTenant;
  onContinue: (skipSelection?: boolean) => void;
  onTenantSelect: (tenant: InTouchTenant) => void;
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [filter, setFilter] = useState("");
  const [sorting, setSorting] = useState<Sortings>(Sortings.Latest);

  React.useEffect(() => {
    Tracking.trackVirtualPageview("/mark-as-rented-out/choose-tenant");
  }, []);

  const fuse = React.useMemo(
    () =>
      new Fuse(tenants, {
        keys: ["display_name"],
        threshold: 0.0,
        tokenize: true,
      }),
    [tenants],
  );

  const filteredTenants = filter ? fuse.search(filter) : tenants;

  const sortedFilteredTenants = useMemo(
    () =>
      filteredTenants?.sort((a: InTouchTenant, b: InTouchTenant) => {
        if (sorting === Sortings.Latest) {
          return (
            new Date(b.sent_date).valueOf() - new Date(a.sent_date).valueOf()
          );
        }

        if (sorting === Sortings.Most) {
          return b.msg_cnt - a.msg_cnt;
        }

        if (
          sorting === Sortings.NameAscending ||
          sorting === Sortings.NameDescending
        ) {
          const nameA = a.display_name.toUpperCase();
          const nameB = b.display_name.toUpperCase();

          if (sorting === Sortings.NameAscending) {
            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }
          }

          if (sorting === Sortings.NameDescending) {
            if (nameA > nameB) {
              return -1;
            }

            if (nameA < nameB) {
              return 1;
            }
          }
        }

        return 0;
      }),
    [sorting, filteredTenants],
  );

  const refs = sortedFilteredTenants.reduce((acc, value) => {
    acc[value.user_id] = React.createRef<HTMLLabelElement>();
    return acc;
  }, {});

  React.useEffect(() => {
    if (!selectedTenant) {
      return;
    }

    const rowEl = refs[selectedTenant.user_id];

    if (!rowEl) {
      return;
    }

    rowEl.current.scrollIntoView({
      behavior: "smooth",
    });
  });

  const handleContinueWithout = () => {
    onContinue(true);
  };

  return (
    <>
      <Confirm
        open={showConfirm}
        size="large"
        title={t(
          "rentable_details.choose_tenant.tenant_not_found_on_platform",
          {
            market: App.settings.market,
          },
        )}
        message={
          <>
            <Text
              paragraph
              weight="bold"
            >
              {t(
                "rentable_details.choose_tenant.confirm_continue_without_tenant",
                {
                  market: App.settings.market,
                },
              )}
            </Text>
            <Text>
              {t(
                "rentable_details.choose_tenant.confirm_continue_without_tenant_body",
              )}
            </Text>
          </>
        }
        confirmText={t(
          "rentable_details.choose_tenant.continue_without_tenant_continue",
        )}
        cancelText={t(
          "rentable_details.choose_tenant.continue_without_tenant_cancel",
        )}
        onConfirm={() => {
          setShowConfirm(false);
          handleContinueWithout();
        }}
        onCancel={() => setShowConfirm(false)}
      />
      <DialogContent noPadding>
        <Box
          px={{
            xs: 2,
            md: 3,
          }}
          mb={1}
        >
          <Text paragraph>
            {t("rentable_details.choose_tenant.choose_from_list_of_tenants")}
          </Text>

          <Divider />

          <TextField
            label={t("rentable_details.choose_tenant.search_by_name")}
            placeholder={t(
              "rentable_details.choose_tenant.search_by_name_placeholder",
            )}
            autoComplete="off"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            onClearClicked={() => setFilter("")}
          />

          <SortingSelect
            items={[
              {
                value: Sortings.Latest,
                label: t("rentable_details.choose_tenant.sorting_latest"),
              },
              {
                value: Sortings.Most,
                label: t("rentable_details.choose_tenant.sorting_most"),
              },
              {
                value: Sortings.NameAscending,
                label: t("rentable_details.choose_tenant.sorting_name_az"),
              },
              {
                value: Sortings.NameDescending,
                label: t("rentable_details.choose_tenant.sorting_name_za"),
              },
            ]}
            disabled={sortedFilteredTenants?.length === 0}
            value={sorting}
            onChange={(event) => setSorting(event.target.value as Sortings)}
            label={t("rentable_details.choose_tenant.sorting_sort_by")}
          />
        </Box>

        {sortedFilteredTenants &&
          sortedFilteredTenants.map((tenant) => (
            <TenantItem
              key={tenant.user_id}
              name={tenant.display_name}
              message={tenant.latest_message}
              latestMessageSelf={tenant.is_me}
              date={tenant.sent_date}
              image={tenant.profile_image}
              onSelect={() => onTenantSelect(tenant)}
              selected={selectedTenant === tenant}
              ref={refs[tenant.user_id]}
            />
          ))}

        {filter && sortedFilteredTenants?.length === 0 && (
          <Center
            pt={{
              md: 20,
            }}
          >
            <Text weight="bold">
              {t("rentable_details.choose_tenant.no_tenant_match")}
            </Text>
            <Text mb={1}>
              {t("rentable_details.choose_tenant.no_tenant_match_body")}
            </Text>
          </Center>
        )}

        {!filter.length && sortedFilteredTenants?.length === 0 && (
          <Center
            pt={{
              md: 20,
            }}
          >
            <Text weight="bold">
              {t("rentable_details.choose_tenant.no_tenants_found")}
            </Text>
            <Text mb={1}>
              {t("rentable_details.choose_tenant.no_tenants_found_body")}
            </Text>

            <Button
              variant="subtle"
              onClick={handleContinueWithout}
            >
              {t(
                "rentable_details.choose_tenant.continue_without_selecting_tenant",
              )}
            </Button>
          </Center>
        )}
      </DialogContent>

      <DialogFooter>
        <Flex
          flexGrow
          justify="space-between"
        >
          <Button
            variant="subtle"
            onClick={() => setShowConfirm(true)}
          >
            {t("rentable_details.choose_tenant.continue_without_tenant")}
          </Button>

          <Button
            variant="primary"
            disabled={!selectedTenant}
            onClick={() => onContinue()}
          >
            {t("rentable_details.choose_tenant.choose_tenant")}
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};

export { ChooseTenantList };
