import { useContext } from "react";
import { Button, Flex, dialogActions } from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  isEditMode: boolean;
  isWaiting: boolean;
  onSubmit: () => void;
  submitButtonVariant?: "primary" | "branded";
};

export const Buttons = ({
  isEditMode,
  isWaiting,
  onSubmit,
  submitButtonVariant,
}: Props) => {
  const dialogMethods = useContext(dialogActions);

  return (
    <Flex gap>
      <Button
        variant="subtle"
        onClick={dialogMethods.hide}
      >
        {t("moving_reports.room.unit.create_defect.cancel")}
      </Button>
      <Button
        variant={submitButtonVariant ?? "primary"}
        onClick={onSubmit}
        disabled={isWaiting}
      >
        {isEditMode
          ? t("moving_reports.room.unit.create_defect.save")
          : t("moving_reports.room.unit.create_defect.create")}
      </Button>
    </Flex>
  );
};
