import { format } from "date-fns";
import { blacklistReasonEnum } from "lib/enums";
import { IRefundsTableItem } from "./refunds_page";

export interface RefundFeedItem {
  id: number;
  amount: string;
  date: string;
  payment_method: string;
  username: string;
  first_name: string;
  last_name: string;
  status: string;
  order_id: string;
  user_id: number | null;
  agent: string;
  zendesk_ticket_id: string;
  reason: string;
  note: string;
  user_ban_reasons: blacklistReasonEnum[];
}

export const createRefundsTableItems = (
  items: RefundFeedItem[],
): IRefundsTableItem[] => {
  const tableDataItems: IRefundsTableItem[] = [];

  items.forEach((item) => {
    tableDataItems.push({
      user: {
        id: item.user_id !== null ? item.user_id : null,
        fullname: `${item.first_name} ${item.last_name}`,
        username: item.username,
        user_ban_reasons: item.user_ban_reasons,
      },
      agent: {
        username: item.agent,
      },
      amount: item.amount,
      reason: item.reason,
      orderId: item.order_id,
      zendeskTicketId: item.zendesk_ticket_id,
      date: format(new Date(item.date), "yyyy-MM-dd HH:mm"),
      note: item.note,
    });
  });

  return tableDataItems;
};
