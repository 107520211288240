/* eslint-disable comma-dangle */
import { fetchFromAPI } from "lib/api";

export const lockableFormfieldService = async (
  adId: number,
  name: string,
  lock: boolean,
): Promise<{ success: boolean }> => {
  try {
    const response = await fetchFromAPI(
      `/customer-service/api/ad/${adId}/lock_field`,
      {
        method: "POST",
        body: JSON.stringify({
          name,
          lock,
        }),
      },
    );

    if (response.status === 204) {
      return Promise.resolve({
        success: true,
      });
    } else {
      return Promise.resolve({
        success: false,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
