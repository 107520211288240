import { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Flex, Box, styled, Text } from "@boligportal/juice";
import { css } from "@emotion/react";
import { shouldShowMobilePaySettings } from "apps/customer_service/pages/settings_page";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import {
  PAGE_USERS,
  PAGE_EMAIL_LOGS,
  PAGE_PAYMENTS,
  PAGE_REFUNDS,
  PAGE_ADS_ALL,
  PAGE_ADS_WAITING_FOR_APPROVAL,
  PAGE_ADS_APPROVED,
  PAGE_ADS_REJECTED,
  PAGE_ADS_FOLLOW_UP,
  PAGE_CRAWLINGS,
  PAGE_CRAWLINGS_ADS,
  PAGE_CRAWLINGS_DRAFTS,
  PAGE_CRAWILNGS_NEEDS_REVIEW,
  PAGE_COMPANIES,
  PAGE_ADS_APPROVE_USER_CHANGES,
  PAGE_COMPANY_ADS,
  PAGE_PROFILES_APPROVE_USER_CHANGES,
  PAGE_BETALINGSSERVICE,
  PAGE_SETTINGS,
} from "../..";
import { adCounts } from "../../../../lib/customerServiceApi";

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #333;
  }
`;

const inactiveStyles = (props) => css`
  padding-left: ${props.theme.unit(2)};
  background-color: ${props.theme.colorPalette.primary[800]};
  border-left: 5px solid ${props.theme.colorPalette.primary[800]};
  color: ${props.theme.colorPalette.gray[40]};
`;
const activeStyles = (props) => css`
  padding-left: ${props.theme.unit(2)};
  background-color: ${props.theme.colorPalette.primary[500]};
  border-left: 5px solid ${props.theme.colorPalette.primary[100]};
  color: ${props.theme.colorPalette.gray[20]};
`;

const StyledBox = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  height: 38px;
  ${(p) => (p.active ? activeStyles : inactiveStyles)};
`;

const styledHeaderStyles = (props) => css`
  color: ${props.theme.colorPalette.gray[20]};
  font-size: ${props.theme.fontSize.tiny};
`;
const StyledHeader = styled.span`
  ${styledHeaderStyles};
`;

interface MenuLinkProps {
  url: string;
  name: string;
  children?: string | number;
}
const MenuLink = ({ url, name, children }: MenuLinkProps) => {
  const match = useRouteMatch({
    path: url,
    sensitive: true,
  });

  return (
    <StyledLink to={url}>
      <StyledBox active={match !== null}>
        <Text
          color="inverted"
          size="small"
        >
          {name}
          <Text
            size="small"
            mr={1}
            ml={0.6}
            color="inverted"
          >
            {children ? `(${children})` : ""}
          </Text>
        </Text>
      </StyledBox>
    </StyledLink>
  );
};

const Sidebar = () => {
  const { user } = useUser();
  const pollingDelay: number = 300000;
  const [pendingApprovalNum, setPendingApprovalNum] = useState(0);
  const [needsReviewNum, setNeedsReviewNum] = useState(0);
  const [companyNeedsReviewNum, setCompanyNeedsReviewNum] = useState(0);
  const [profilesNeedsReviewNum, setProfilesNeedsReviewNum] = useState(0);
  const [followUpNum, setFollowUpNum] = useState(0);
  const [crawlingsPendingReview, setCrawlingsPendingReview] = useState(0);
  const [betalingsserviceNum, setBetalingsserviceNum] = useState(0);

  useEffect(() => {
    getData();
    const pollIntervalID = setInterval(getData, pollingDelay);
    return () => {
      clearInterval(pollIntervalID);
    };
  }, []);

  const getData = () => {
    if (!document.hidden) {
      const adsCount = adCounts();
      adsCount.then((res) => {
        setPendingApprovalNum(res.needs_approval_count);
        setFollowUpNum(res.needs_follow_up_count);
        setNeedsReviewNum(res.needs_review_count);
        setCrawlingsPendingReview(res.crawlings_needs_review_count);
        setCompanyNeedsReviewNum(res.company_needs_review_count);
        setProfilesNeedsReviewNum(res.profiles_needs_review);
        setBetalingsserviceNum(res.betalingsservice_pending_count);
      });
    }
  };

  const shouldShowSettingsLink = shouldShowMobilePaySettings(user?.email || "");

  return (
    <Flex column>
      <Box flexGrow>
        <nav>
          <Box
            px={2}
            pt={3}
          >
            <StyledHeader>Users</StyledHeader>
          </Box>

          <MenuLink
            url={PAGE_USERS}
            name="All users"
          />
          <MenuLink
            url={PAGE_PROFILES_APPROVE_USER_CHANGES}
            name="Needs review"
          >
            {profilesNeedsReviewNum}
          </MenuLink>
          <MenuLink
            url={PAGE_EMAIL_LOGS}
            name="Email logs"
          />
          {App.settings.market === Market.BOLIGPORTAL_DK && (
            <MenuLink
              url={PAGE_BETALINGSSERVICE}
              name="Betalingsservice"
            >
              {betalingsserviceNum}
            </MenuLink>
          )}
          <Box
            px={2}
            pt={3}
          >
            <StyledHeader>Payments</StyledHeader>
          </Box>
          <MenuLink
            url={PAGE_PAYMENTS}
            name="Payments"
          />
          <MenuLink
            url={PAGE_REFUNDS}
            name="Refunds"
          />

          <Box
            px={2}
            pt={3}
          >
            <StyledHeader>Ads</StyledHeader>
          </Box>
          <MenuLink
            url={PAGE_ADS_ALL}
            name="All Ads"
          />

          <MenuLink
            url={PAGE_ADS_WAITING_FOR_APPROVAL}
            name="Waiting for approval"
          >
            {pendingApprovalNum}
          </MenuLink>

          <MenuLink
            url={PAGE_ADS_APPROVE_USER_CHANGES}
            name="Needs review"
          >
            {needsReviewNum}
          </MenuLink>

          <MenuLink
            url={PAGE_ADS_APPROVED}
            name="Approved"
          />
          <MenuLink
            url={PAGE_ADS_REJECTED}
            name="Rejected"
          />

          <MenuLink
            url={PAGE_ADS_FOLLOW_UP}
            name="Follow up"
          >
            {followUpNum}
          </MenuLink>

          <Box
            px={2}
            pt={3}
          >
            <StyledHeader>Companies</StyledHeader>
          </Box>
          <MenuLink
            url={PAGE_COMPANIES}
            name="All companies"
          />
          <MenuLink
            url={PAGE_COMPANY_ADS}
            name="Approve/review company ads"
          >
            {companyNeedsReviewNum}
          </MenuLink>

          <Box
            px={2}
            pt={3}
          >
            <StyledHeader>Crawling</StyledHeader>
          </Box>
          <MenuLink
            url={PAGE_CRAWLINGS}
            name="All feeds"
          />
          <MenuLink
            url={PAGE_CRAWLINGS_ADS}
            name="All crawling ads"
          />
          <MenuLink
            url={PAGE_CRAWILNGS_NEEDS_REVIEW}
            name="Review crawled ads"
          >
            {crawlingsPendingReview}
          </MenuLink>
          <MenuLink
            url={PAGE_CRAWLINGS_DRAFTS}
            name="Crawling drafts"
          />
          {shouldShowSettingsLink && (
            <>
              <Box
                px={2}
                pt={3}
              >
                <StyledHeader>Settings</StyledHeader>
              </Box>
              <MenuLink
                url={PAGE_SETTINGS}
                name="Settings"
              />
            </>
          )}
        </nav>
      </Box>
      {/*
      // For now we dont need marked selector, as we have only 1 market
      <Box p={2}>
      <MarketSelector />
      </Box>
   */}
    </Flex>
  );
};

export { Sidebar };
