import { useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDraftDetails } from "lib/customerServiceApi";
import { CrawlingDraftDetailMain } from "./CrawlingDraftDetailMain";
import { createContactInfoFromContactData } from "./helpers/createContactInfoFromContactData";
import { createInitialFieldValuesFromData } from "./helpers/createInitialFieldValuesFromData";
import { ContactInfo } from "./interfaces/ContactInfo";
import { CrawlingDraftForm } from "./interfaces/CrawlingDraftForm";

interface CrawlingDraftState {
  sourceUrl?: string;
  contactInfo?: ContactInfo;
  address?: string;
  initialFieldValues?: Partial<CrawlingDraftForm>;
  dataValidationError?: string;
  publishErrors?: Array<any>;
}

type ReducerAction =
  | {
      type: "UPDATE";
      data: any;
      contact: any;
    }
  | {
      type: "UPDATE_ADDRESS";
      address: string;
    };

const initialReducerState = {};

const reducer = (
  state: CrawlingDraftState,
  action: ReducerAction,
): CrawlingDraftState => {
  switch (action.type) {
    case "UPDATE": {
      return {
        ...state,
        address: action.data.formatted_address ?? "",
        sourceUrl: action.data.source_url,
        contactInfo: createContactInfoFromContactData(action.contact),
        initialFieldValues: createInitialFieldValuesFromData(action.data),
        dataValidationError: action.data.validation_errors,
      };
    }
    case "UPDATE_ADDRESS": {
      return {
        ...state,
        address: action.address,
      };
    }
  }
};

export const CrawlingDraftDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(reducer, initialReducerState);

  useEffect(() => {
    getDraftDetails(Number(id)).then((response) => {
      dispatch({
        type: "UPDATE",
        data: response.data,
        contact: response.contact_data,
      });
    });
  }, [id]);

  if (!state.address || !state.initialFieldValues) {
    return null;
  }

  return (
    <CrawlingDraftDetailMain
      draftId={id}
      contactInfo={state.contactInfo}
      sourceUrl={state.sourceUrl}
      initialAddress={state.address}
      initialFieldValues={state.initialFieldValues}
      dataValidationError={state.dataValidationError}
      onAddressUpdated={(address) =>
        dispatch({
          type: "UPDATE_ADDRESS",
          address,
        })
      }
    />
  );
};
