import { Center, Flex, Headline, styled, Text } from "@boligportal/juice";

export interface ChooseOrCreateRentableLayoutHeaderProps {
  headline: string;
  text: string;
}

export const ChooseOrCreateRentableHeaderLayout = ({
  headline,
  text,
}: ChooseOrCreateRentableLayoutHeaderProps) => (
  <Center>
    <Flex
      column
      mx={3}
    >
      <CustomHeadline as="h1">{headline}</CustomHeadline>
      <Text
        size={{
          xs: "h4",
          md: "h2",
        }}
      >
        {text}
      </Text>
    </Flex>
  </Center>
);

const CustomHeadline = styled(Headline)`
  margin-bottom: 0;
  color: ${(p) => p.theme.colorPalette.primary[800]};
  font-size: 32px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 50px;
  }
`;
