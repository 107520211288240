import { Badge, Box, Text } from "@boligportal/juice";
import { AdItemsInterface } from "../interfaces";

const AddressCellRenderer = (data: AdItemsInterface) => {
  const { formatted_address, address } = data;
  const isCustomAddress = !address.place_id;
  return (
    <Box>
      <Text
        block
        size="tiny"
      >
        {formatted_address}
      </Text>

      {isCustomAddress && (
        <Box>
          <Badge color="blue">Custom</Badge>
        </Box>
      )}
    </Box>
  );
};

export { AddressCellRenderer };
