import { RentalAdsState } from "../interfaces/RentalAdsState";
import { RentalAdsReducerAction } from "./RentalAdsReducerAction";
import { RentalAdsReducerType } from "./RentalAdsReducerType";

export const RentalAdsReducer: RentalAdsReducerType = (
  state: RentalAdsState,
  action: RentalAdsReducerAction,
) => {
  switch (action.type) {
    case "UPDATE_RENTABLE_ADS": {
      return {
        ...state,
        rentalAds: action.rentalAds,
        filterCount: action.filterCount,
        allCount: action.allCount,
        rentedOutCount: action.rentedOutCount,
        draftCount: action.draftCount,
        advertisedCount: action.advertisedCount,
        reservedCount: action.reservedCount,
        start_index: action.start_index,
        isInitialLoad: false,
      };
    }
    case "UPDATE_ACTIVE_SORTABLE_COLUMN": {
      return {
        ...state,
        activeSortableColumn: {
          column: action.fieldName,
          descending: action.descending,
        },
      };
    }
    case "UPDATE_PAGINATION_START_INDEX": {
      return {
        ...state,
        start_index: action.newStartIndex,
      };
    }
    case "UPDATE_SEARCH_VALUE": {
      return {
        ...state,
        searchValue: action.newSearchValue,
      };
    }
    case "UPDATE_AD_STATE": {
      return {
        ...state,
        ad_state: action.newAdState,
      };
    }
  }
};
