import {
  Box,
  Button,
  DataTable,
  Flex,
  http,
  Spinner,
  Text,
} from "@boligportal/juice";
import { useMobilepaySimulatorAgreements } from "api/queries";
import { Agreement } from "lib/customerServiceApi";

export const AgreementsTable = () => {
  const url = window.location.href;
  const arr = url.split("/");
  const cs_url = arr[0] + "//" + arr[2];
  const agreement_url =
    cs_url + "/customer-service/api/mobilepay-simulator/agreements";

  const { data, isLoading, refetch } =
    useMobilepaySimulatorAgreements(agreement_url);

  const onAccept = (agreement: Agreement) =>
    http
      .post(`${agreement_url}/state`, {
        agreement_id: agreement.agreement_id,
        state: "Accepted",
      })
      .then(() => refetch())
      .catch((error) => {
        console.log(error.response.data); // eslint-disable-line no-console
        alert(error);
      });

  const onReject = (agreement: Agreement) =>
    http
      .post(`${agreement_url}/state`, {
        agreement_id: agreement.agreement_id,
        state: "Rejected",
      })
      .then(() => refetch())
      .catch((error) => {
        console.log(error.response.data); // eslint-disable-line no-console
        alert(error);
      });

  const onCancel = (agreement: Agreement) =>
    http
      .post(`${agreement_url}/state`, {
        agreement_id: agreement.agreement_id,
        state: "Cancelled",
      })
      .then(() => refetch())
      .catch((error) => {
        console.log(error.response.data); // eslint-disable-line no-console
        alert(error);
      });

  return (
    <Box>
      <Text
        size="h3"
        weight="bold"
        block
        p={2}
      >
        Agreements
      </Text>
      {isLoading || !data ? (
        <Flex
          justify="center"
          align="center"
          height="200px"
        >
          <Spinner />
        </Flex>
      ) : (
        <DataTable<Agreement>
          items={data.agreements}
          keyField={"agreement_id"}
          columns={[
            {
              fieldLabel: "Tenancy",
              fieldName: "user",
              cellRenderer: (item) => <Text size="tiny">{item.user}</Text>,
            },
            {
              fieldLabel: "Agreement ID",
              fieldName: "agreement_id",
              cellRenderer: (item) => (
                <Text size="tiny">{item.agreement_id}</Text>
              ),
            },
            {
              fieldLabel: "Status",
              fieldName: "status",
              cellRenderer: (item) => <Text size="tiny">{item.status}</Text>,
            },
            {
              fieldLabel: "Accept",
              fieldName: "" as any,
              cellRenderer: (item) =>
                item.status == "PENDING" ? (
                  <Button
                    variant="primary"
                    onClick={() => onAccept(item)}
                  >
                    Accept
                  </Button>
                ) : (
                  <Box textAlign="center">-</Box>
                ),
            },
            {
              fieldLabel: "Reject",
              fieldName: "" as any,
              cellRenderer: (item) =>
                item.status == "PENDING" ? (
                  <Button
                    variant="danger"
                    onClick={() => onReject(item)}
                  >
                    Reject
                  </Button>
                ) : (
                  <Box textAlign="center">-</Box>
                ),
            },
            {
              fieldLabel: "Cancel",
              fieldName: "" as any,
              cellRenderer: (item) =>
                item.status == "Accepted" && (
                  <Button onClick={() => onCancel(item)}>Cancel</Button>
                ),
            },
          ]}
        />
      )}
    </Box>
  );
};
