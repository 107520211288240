import { useFormContext } from "react-hook-form";
import { Flex, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { ControlledPaymentNumberField } from "../ControlledPaymentNumberField";
import { MonthButtons } from "./month_buttons";

interface Props {
  label: string;
  validationRule: (monthly_rent: number) => (value: number) => boolean;
  isSocialHousing?: boolean;
}

export const Deposit = ({
  label,
  validationRule,
  isSocialHousing = false,
}: Props) => {
  const { setValue, getValues, formState, control } = useFormContext();
  const validateFunc = validationRule(getValues("monthly_rent"));
  const { errors } = formState;

  const calculateAndSetCost = (numMonths: number) => {
    const rent = getValues("monthly_rent");

    if (rent > 0) {
      setValue("deposit", rent * numMonths, {
        shouldDirty: true,
      });
    }
  };

  // Field should only be required if the listing is not an social housing.
  const fieldRequired = !isSocialHousing;

  const value = getValues("deposit");
  const errorTranslationKey = value
    ? "deposit.error.exceeds_rent_rule"
    : "required_field.numeric";

  return (
    <Flex
      column
      flexGrow
      mb={2}
    >
      <TextFieldWithoutMarginsOrGrow
        label={label}
        errorText={errors.deposit && t(errorTranslationKey)}
        name="deposit"
        control={control}
        required={fieldRequired}
        validator={validateFunc}
        testId="createAdDeposit"
      />
      <MonthButtons onMonthClick={calculateAndSetCost} />
    </Flex>
  );
};

const TextFieldWithoutMarginsOrGrow = styled(ControlledPaymentNumberField)`
  margin-bottom: 0;
  flex-grow: 0;
`;
