import { Flex, Box, FlexColumn, Card, CardSection } from "@boligportal/juice";
import { ContainerCustomMaxWidth } from "../components/ContainerCustomMaxWidth";
import { ToolbarWrapper } from "../components/ToolbarWrapper";

interface Props {
  publishErrors: JSX.Element | null;
  missingLandlord: JSX.Element | null;
  landlordInformation: JSX.Element | null;
  contactInformation: JSX.Element | null;
  sourceLink: JSX.Element | null;
  address: JSX.Element;
  dataValidationError: JSX.Element | null;
  rooms: JSX.Element;
  size: JSX.Element;
  pageTitle: JSX.Element;
  deposit: JSX.Element;
  period: JSX.Element;
  moveInDate: JSX.Element;
  features: JSX.Element;
  title: JSX.Element;
  description: JSX.Element;
  errors: JSX.Element;
  monthlyRent: JSX.Element;
  monthlyRentExtraCosts: JSX.Element;
  prepaidRent: JSX.Element | null;
  energyLabel: JSX.Element;
  category: JSX.Element;
  deleteButton: JSX.Element;
  submitButton: JSX.Element;
}

export const CrawlingDraftDetailPageLayout = ({
  publishErrors,
  landlordInformation,
  dataValidationError,
  contactInformation,
  pageTitle,
  missingLandlord,
  sourceLink,
  address,
  rooms,
  size,
  period,
  moveInDate,
  features,
  title,
  description,
  category,
  errors,
  energyLabel,
  deleteButton,
  submitButton,
  deposit,
  monthlyRent,
  monthlyRentExtraCosts,
  prepaidRent,
}: Props) => (
  <Flex
    flexGrow
    column
    bg="tint"
  >
    <ToolbarWrapper>
      {pageTitle}
      <Flex
        gap
        flexPush
      >
        {deleteButton}
        {submitButton}
      </Flex>
    </ToolbarWrapper>
    <Box
      height="100%"
      scrollable
      bg="tint"
    >
      <ContainerCustomMaxWidth>
        <Box pt={4}>
          <Flex gap>
            <FlexColumn size={8}>
              <>
                {missingLandlord}
                {sourceLink}
              </>
              <Card sectioned>
                <CardSection>{address}</CardSection>
              </Card>
              <Card sectioned>
                <CardSection>
                  {title}
                  {description}
                </CardSection>
              </Card>
              <Card sectioned>
                <CardSection>
                  <Flex
                    mt={1}
                    gap
                  >
                    <FlexColumn size={6}>
                      {category}
                      {monthlyRent}
                      {monthlyRentExtraCosts}
                      {deposit}
                      {prepaidRent}
                      {energyLabel}
                    </FlexColumn>
                    <FlexColumn size={6}>
                      {size}
                      {rooms}
                      {moveInDate}
                      <Box pt={2}>{period}</Box>
                    </FlexColumn>
                  </Flex>
                </CardSection>
              </Card>
              <Card sectioned>
                <CardSection title="Other Features">
                  <Box>
                    <Flex
                      wrap
                      gap={4}
                    >
                      {features}
                    </Flex>
                  </Box>
                </CardSection>
              </Card>
            </FlexColumn>
            <FlexColumn size={4}>
              {landlordInformation}
              <Card sectioned>
                <CardSection title="Landlord information (feed)">
                  {contactInformation}
                </CardSection>
              </Card>
              {dataValidationError}
              {errors}
              {publishErrors}
            </FlexColumn>
          </Flex>
        </Box>
      </ContainerCustomMaxWidth>
    </Box>
  </Flex>
);
