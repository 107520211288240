export enum Keys {
  AllThreadsPolling = "AllThreadsPolling",
  AdThreadsPolling = "AdThreadsPolling",
  ThreadPolling = "ThreadPolling",
  UnseenThreadsCount = "UnseenThreadsCount",
}

const ONE_MINUTE = 60_000;
const POLLING_DELAY_IN_MILLISECONDS = ONE_MINUTE;

export class InboxPolling {
  private static intervalStarted: boolean = false;
  private static pollingDelay: number = POLLING_DELAY_IN_MILLISECONDS;
  private static callbacks: Map<string, () => void> = new Map();

  static startOrContinueInterval() {
    if (!InboxPolling.intervalStarted) {
      InboxPolling.intervalStarted = true;
      window.setInterval(InboxPolling.trigger, InboxPolling.pollingDelay);
    }
  }

  static add(callback: () => void, key: string) {
    InboxPolling.callbacks.set(key, callback);
    callback();
    InboxPolling.startOrContinueInterval();
  }

  static remove(key: string) {
    InboxPolling.callbacks.delete(key);
  }

  static removeAll = () => {
    InboxPolling.callbacks.clear();
  };

  static trigger() {
    InboxPolling.callbacks.forEach((callback) => {
      callback();
    });
  }
}
