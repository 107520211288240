import { Box, Flex, Text } from "@boligportal/juice";
import { subscriptionDateAndTime } from "lib/date";
import { SubscriptionFeedItemInterface } from "../interfaces";
import { SubscriptionStatusBadge } from "./subscription_status_badge";

export const SubscriptionStatusBlock = ({
  subscription,
}: {
  subscription: SubscriptionFeedItemInterface;
}) => {
  const items: { label: string; value: string }[] = [];
  items.push({
    label: "Activated",
    value: subscription.activation_date
      ? subscriptionDateAndTime(subscription.activation_date!)
      : "Not yet",
  });

  if (subscription.canceled_at !== null) {
    items.push({
      label: "Canceled",
      value: subscriptionDateAndTime(subscription.canceled_at),
    });
  }

  if (
    (subscription.canceled_at !== null &&
      subscription.temporal_status === "IN_PERIOD") ||
    !subscription.is_subscription
  ) {
    items.push({
      label: "Expires",
      value: subscription.current_period_end
        ? subscriptionDateAndTime(subscription.current_period_end!)
        : subscriptionDateAndTime(subscription.ended_at!),
    });
  }

  if (
    subscription.last_renewal_date !== null &&
    subscription.temporal_status === "IN_PERIOD"
  ) {
    items.push({
      label: "Last renewal",
      value: subscriptionDateAndTime(subscription.last_renewal_date),
    });
  }

  if (
    subscription.canceled_at !== null &&
    subscription.temporal_status === "EXPIRED"
  ) {
    items.push({
      label: "Expired",
      value: subscriptionDateAndTime(subscription.ended_at!),
    });
  }

  if (
    subscription.canceled_at === null &&
    subscription.temporal_status === "IN_PERIOD" &&
    subscription.current_period_end !== null
  ) {
    items.push({
      label: "Next renewal",
      value: subscriptionDateAndTime(subscription.current_period_end),
    });
  }
  return (
    <Flex column>
      <Box inline>
        <Text
          weight="bold"
          mr={1}
        >
          {`${subscription.plan}`}
        </Text>
        <SubscriptionStatusBadge subscription={subscription} />
      </Box>
      {items.map((item) => (
        <Flex key={item.value}>
          <Text
            size="tiny"
            mr={1}
          >
            {item.label}:
          </Text>
          <Text size="tiny">{item.value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
