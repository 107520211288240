import { useContext } from "react";
import { ActivityStream } from "apps/customer_service/pages/ad_detail_page/components/activity_stream";
import { leaveCommentOnCompany } from "lib/customerServiceApi";
import { CompanyDetailsContext } from "../company_details_context";

export const ActivityStreamCard = () => {
  const {
    company,
    activityStreamData,
    activityStreamOffset,
    setActivityStreamOffset,
    fetchActivityStream,
    isLoadingActivityStream,
  } = useContext(CompanyDetailsContext);

  const handleCreateComment = (text: string): Promise<any> => {
    if (company) {
      const saveCommentPromise = leaveCommentOnCompany(company.id, text);

      saveCommentPromise.then(() => {
        fetchActivityStream();
      });

      return saveCommentPromise;
    }

    return Promise.reject();
  };
  return (
    <ActivityStream
      offset={activityStreamOffset}
      setOffset={setActivityStreamOffset}
      totalRecords={activityStreamData?.count}
      items={activityStreamData?.stream || []}
      isLoading={isLoadingActivityStream}
      onCreateComment={handleCreateComment}
      filterComponent={[]}
      isCompanyView
    />
  );
};
