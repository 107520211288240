import { fetchFromAPI } from "lib/api";

export interface GetCompanyColleaguesResponse {
  results: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  }[];
}

export const getCompanyColleagues =
  async (): Promise<GetCompanyColleaguesResponse> => {
    const request = await fetchFromAPI("/api/user/company-users");
    const data = await request.json();
    return data;
  };
