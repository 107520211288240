import { Box, Text } from "@boligportal/juice";
import { TinyTextButton } from "features/eco_system/components/dialog/common/TinyTextButton";
import { t } from "lib/i18n";

export const GotoManualAddressFormButton = ({
  onClick,
  hideHelpText = false,
}: {
  onClick: () => void;
  hideHelpText?: boolean;
}) => (
  <Box>
    <Box hidden={hideHelpText}>
      <Text
        size="tiny"
        color="muted"
        block
      >
        {t("Only paying members will see the location on a map")}
      </Text>
    </Box>

    <TinyTextButton
      onClick={onClick}
      text={t("feature.address_form.goto_manual_form_button.text")}
    />
  </Box>
);
