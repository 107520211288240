import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Container,
  DataTable,
  Flex,
  Labelled,
  Text,
  TextField,
} from "@boligportal/juice";
import { PAGE_USERS } from "apps/customer_service";
import { DropdownMultiple } from "apps/customer_service/components/dropdown/DropdownMultiple";
import { Option } from "components/interfaces/option";
import { format } from "date-fns";
import { useImpersonateUserFeature } from "features/cs/impersonate_user/hooks/use_impersonate_user_feature";
import {
  UsersListViewModel,
  useSearchUsersWithPagination,
} from "features/cs/search_users_feature/hooks/use_search_users_with_pagination";
import { useAutomaticScrollToTopOnPagination } from "hooks/useAutomaticScrollToTopOnPagination";
import { UserListItem } from "lib/customerServiceApi";
import { SubscriptionStatusFilter } from "lib/enums";
import { AdItemsInterface } from "../ads_page/interfaces";
import { ListPaginator } from "../ads_page/list_paginator";
import { prettifiedReason } from "../user_detail_page/blacklist/blacklist_user_notice";
import { SubscriptionStatusBadge } from "../user_detail_page/subscriptions_feed/components/subscription_status_badge";
import { UserCell } from "./cell_renderers/UserCell";

const UsersPage = () => {
  const { impersonateUser, impersonateUserLabel } = useImpersonateUserFeature();
  const history = useHistory();
  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const initialSearchValue = searchParams.get("q") ?? "";

  const {
    items,
    searchValue,
    count,
    offset,
    limit,
    setOffset,
    sorting,
    blacklistChangeHandler,
    seekerStatusChangeHandler,
    filterChangeHandler,
    sortingChangeHandler,
  } = useSearchUsersWithPagination(initialSearchValue);

  const scrollContainerRef = useAutomaticScrollToTopOnPagination(offset);

  const handleGotoDetails = (item: UserListItem) => {
    history.push(`${PAGE_USERS}/${item.id}`);
  };

  const impersonateUserHandler = (item: UserListItem) => {
    impersonateUser(item.id);
  };

  const handleBlacklistReasonSelectionChange = (value: Option[]) => {
    const blacklistFilterReasons: any[] = value.map((val) =>
      Object.keys(prettifiedReason).find(
        (key) => prettifiedReason[key] === val.name,
      ),
    );
    //setBlacklistFilter(blacklistFilterReasons);
    blacklistChangeHandler(blacklistFilterReasons);
  };

  const seekerStatusSelectionChangeHandler = (value: Option[]) => {
    const statusFilter = value.map((option) => `${option.id}`);
    seekerStatusChangeHandler(statusFilter);
  };

  const handleSortedClick = (fieldName, descending: boolean) => {
    const sorting = {
      column: fieldName,
      descending: descending,
    };
    sortingChangeHandler(sorting);
  };

  return (
    <Flex
      column
      width="100%"
    >
      <Flex
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <Flex
            mt={2}
            gap
          >
            <Box width="300px">
              <TextField
                label="User filter"
                value={searchValue}
                id="searchValue"
                onChange={filterChangeHandler}
              />
            </Box>
            <Box>
              <Labelled label="Subscription status">
                <DropdownMultiple
                  title="All states"
                  options={[
                    {
                      name: "Active",
                      id: SubscriptionStatusFilter.ACTIVE,
                    },
                    {
                      name: "Cancelled",
                      id: SubscriptionStatusFilter.CANCELED,
                    },
                    {
                      name: "Expired",
                      id: SubscriptionStatusFilter.EXPIRED,
                    },
                    {
                      name: "Never subscribed",
                      id: SubscriptionStatusFilter.NEVER_SUBSCRIBED,
                    },
                  ]}
                  multiple
                  onSelectionChange={seekerStatusSelectionChangeHandler}
                />
              </Labelled>
            </Box>
            <DateFromTo
              nameFrom="lastLoginDateFrom"
              nameTo="lastLoginDateTo"
              labelFrom="Last login from"
              labelTo="Last login to"
              changeHandler={filterChangeHandler}
            />
            <DateFromTo
              nameFrom="creationDateFrom"
              nameTo="creationDateTo"
              labelFrom="Created from"
              labelTo="Created to"
              changeHandler={filterChangeHandler}
            />

            <Box>
              <Labelled label="Blacklist Reason">
                <DropdownMultiple
                  title="Blacklist Reason"
                  options={Object.keys(prettifiedReason).map(
                    (reason, index) => ({
                      name: prettifiedReason[reason],
                      id: index,
                    }),
                  )}
                  multiple
                  onSelectionChange={handleBlacklistReasonSelectionChange}
                />
              </Labelled>
            </Box>
          </Flex>
        </Container>
      </Flex>
      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<UsersListViewModel>
            fullscreen
            items={items}
            activeSortableColumn={sorting}
            onSortClick={handleSortedClick}
            keyField="id"
            rowActions={[
              {
                label: "Go to user",
                callback: handleGotoDetails,
              },
              {
                label: impersonateUserLabel,
                callback: impersonateUserHandler,
              },
            ]}
            columns={[
              {
                fieldLabel: "User",
                fieldName: "username",
                cellRenderer: (item) => (
                  <UserCell
                    id={item.id}
                    fullname={`${item.first_name} ${item.last_name}`}
                    username={item.username}
                    user_ban_reasons={item.user_ban_reasons}
                  />
                ),
                sortable: true,
              },
              {
                fieldLabel: "Access subscription status",
                fieldName: "id",
                cellRenderer: (item) =>
                  item.seeker_subscription ? (
                    <SubscriptionStatusBadge
                      subscription={item.seeker_subscription}
                    />
                  ) : (
                    "-"
                  ),
              },

              {
                fieldLabel: "Id",
                fieldName: "id",
                cellRenderer: (item) => <Text size="tiny">{item.id}</Text>,
                width: "150px",
              },
              {
                fieldLabel: "Date last login",
                fieldName: "date_last_login",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {item.date_last_login
                      ? format(
                          new Date(item.date_last_login),
                          "yyyy-MM-dd HH:mm:ss",
                        )
                      : "-"}
                  </Text>
                ),
                sortable: true,
              },
              {
                fieldLabel: "Date created",
                fieldName: "date_joined",

                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(new Date(item.date_joined), "yyyy-MM-dd HH:mm:ss")}
                  </Text>
                ),
                sortable: true,
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                itemCount={items.length}
                totalCount={count}
                limit={limit}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { UsersPage };

// ----------------------------------------------

interface DateFromToProps {
  labelFrom: string;
  labelTo: string;
  nameFrom: string;
  nameTo: string;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const DateFromTo = ({
  labelFrom,
  labelTo,
  nameFrom,
  nameTo,
  changeHandler,
}: DateFromToProps) => (
  <Box>
    <Flex gap={0.5}>
      <Box width="175px">
        <TextField
          type="date"
          label={labelFrom}
          name={nameFrom}
          id={nameFrom}
          onChange={changeHandler}
        />
      </Box>
      <Box width="175px">
        <TextField
          type="date"
          label={labelTo}
          name={nameTo}
          id={nameTo}
          onChange={changeHandler}
        />
      </Box>
    </Flex>
  </Box>
);
