import { styled } from "@boligportal/juice";

type Position = "start" | "end";

const StyledCounter = styled.span<{ position: Position }>`
  ${(props) =>
    props.position === "start" ? "margin-right: 6px;" : "margin-left: 6px;"}
  padding: 2px 6px;
  background-color: ${(props) => props.theme.color.bg.brand};
  color: ${(props) => props.theme.color.text.inverted};
  font-size: ${(props) => props.theme.fontSize.tiny};
  border-radius: 12px;
`;

export const UnreadCounter = ({
  count,
  position = "start",
}: {
  count: number;
  position?: Position;
}) => {
  if (!count) {
    return null;
  }

  return <StyledCounter position={position}>{count}</StyledCounter>;
};
