import * as React from "react";
import { useForm } from "react-hook-form";
import { Box } from "@boligportal/juice";
import { FormItemRadios } from "components/forms/form_item_radios";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { ProfileFormData } from "../helpers/profile_helpers";

type Props = {
  profile: ProfileFormData;
  onProfileChange(profile: ProfileFormData): void;
  setTitle(value: React.SetStateAction<string>): void;
};

export const Preferences = ({ onProfileChange, profile, setTitle }: Props) => {
  const { getValues, setValue, control } = useForm<ProfileFormData>({
    mode: "onChange",
    defaultValues: profile,
  });

  React.useEffect(() => {
    setValue("pets", profile && profile.pets);
    setValue("smoking", profile && profile.smoking);
    setValue("kids", profile && profile.kids);
  }, [profile]);

  React.useEffect(() => {
    setTitle(t("profile_completion.preferences_modal.title"));
    Tracking.trackVirtualPageview(
      "/fill-profile-modal/more-information",
      "More information",
    );
  }, []);

  const handleChange = () => {
    onProfileChange(getValues());
  };

  return (
    <Box>
      <strong>{t("profile_completion.preferences_form.title")}</strong>

      <form onChange={handleChange}>
        <FormItemRadios
          control={control}
          name="smoking"
          label={t("profile.smoking.heading")}
          options={[
            {
              id: "true",
              name: t("profile.page.details.smoking.true"),
            },
            {
              id: "false",
              name: t("profile.page.details.smoking.false"),
            },
          ]}
        />

        <FormItemRadios
          control={control}
          name="pets"
          label={t("profile.pets.heading")}
          options={[
            {
              id: "true",
              name: t("profile.page.details.pets.true"),
            },
            {
              id: "false",
              name: t("profile.page.details.pets.false"),
            },
          ]}
        />

        <FormItemRadios
          control={control}
          name="kids"
          label={t("profile.kids.heading")}
          options={[
            {
              id: "true",
              name: t("profile.page.details.kids.true"),
            },
            {
              id: "false",
              name: t("profile.page.details.kids.false"),
            },
          ]}
        />
      </form>
    </Box>
  );
};
