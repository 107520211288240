import { Text, Flex, IconTrendingUp, useTheme } from "@boligportal/juice";
import { css } from "@emotion/react";
import { GridItem } from "./CardGrids";

type Props = {
  text: string;
};

export const VolumeBarsDisplayLabelRow = ({ text }: Props) => {
  const theme = useTheme();
  return (
    <GridItem name="label">
      <Flex
        align="center"
        pt={1}
      >
        <IconTrendingUp
          size="medium"
          css={css`
            color: ${theme.color.text.muted};
          `}
        />
        <Text
          pl={1}
          color="muted"
        >
          {text}
        </Text>
      </Flex>
    </GridItem>
  );
};
