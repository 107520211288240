import { useQuery } from "react-query";
import {
  getNewBuildById,
  getNewBuildUnitsList,
  getNewBuildsList,
  getNewBuildsProjectInboxData,
  getNewBuildsUnitInboxData,
} from "apps/newbuilds/api";
import { NewBuild, Unit } from "apps/newbuilds/types";

export const newBuildsKeys = {
  newBuildsList: () => ["newBuildsList"] as const,
  newBuild: (id: NewBuild["id"]) => ["newBuild", id] as const,
  newBuildUnitsList: (id: NewBuild["id"]) => ["newBuildUnitsList", id] as const,
  newBuildsInboxData: (id: NewBuild["id"]) =>
    ["newBuildsInboxData", id] as const,
  newBuildsUnitInboxData: (id: Unit["id"]) =>
    ["newBuildsUnitInboxData", id] as const,
  showInterestForUnit: (id: Unit["id"]) => ["showInterestForUnit", id] as const,
};

export const useNewBuild = (newBuildId: NewBuild["id"]) =>
  useQuery(
    newBuildsKeys.newBuild(newBuildId),
    () => getNewBuildById(newBuildId),
    {
      staleTime: Infinity,
    },
  );

export const useNewBuildsList = () =>
  useQuery(newBuildsKeys.newBuildsList(), () => getNewBuildsList(), {
    staleTime: Infinity,
  });

export const useNewBuildUnitsList = (newBuildId: number) =>
  useQuery(
    newBuildsKeys.newBuildUnitsList(newBuildId),
    () => getNewBuildUnitsList(newBuildId),
    {
      staleTime: Infinity,
    },
  );

export const useGetNewBuildsProjectInboxData = (newBuildId?: number) => {
  return useQuery(
    newBuildsKeys.newBuildsInboxData(newBuildId),
    () => getNewBuildsProjectInboxData(newBuildId),
    { enabled: !!newBuildId },
  );
};

export const useGetUnitInboxData = (unitId: number) => {
  return useQuery(
    newBuildsKeys.newBuildsUnitInboxData(unitId),
    () => getNewBuildsUnitInboxData(unitId),
    { enabled: !!unitId },
  );
};
