/* eslint-disable react/jsx-max-depth */
import * as React from "react";
import { Box, Flex, Hidden, styled, Text, Button } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { AnimationReveal } from "components/animation/AnimationReveal";
import { App } from "components/app";
import ArrowLeft from "media/icons/ArrowLeft14px";
import CustomerService from "media/icons/CustomerService";
import RoundCheckmark from "media/icons/RoundCheckmark";
import Star from "media/icons/Star";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const ScalabaleHeadline = styled.h2`
  font-size: 60px;
  line-height: 1.2em;
  color: #191919;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 30px;
  }
`;

const BarelyTintedText = styled(Text)`
  color: #1c1c1c;
`;

const CustomArrow = styled(ArrowLeft)`
  margin-left: 10px;
`;

const IconWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box height="100px">{children}</Box>
);

const HeadlineAndText = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <Flex column>
    <Text
      size="h3"
      weight="bold"
    >
      {headline}
    </Text>
    <Box maxWidth="300px">
      <BarelyTintedText size="small">{text}</BarelyTintedText>
    </Box>
  </Flex>
);

const TextSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <HeadlineAndText
    headline={headline}
    text={text}
  />
);

const CustomerServiceButton = ({
  trackingEvent,
  text,
}: {
  trackingEvent: () => void;
  text: string;
}) => (
  <Flex mt={2}>
    <Box>
      <Button
        text
        variant="branded"
        onClick={() => trackingEvent()}
        href={App.settings.support_url}
      >
        {text}
        <CustomArrow
          fontSize="12px"
          style={{
            transform: "rotate(180deg)",
          }}
        />
      </Button>
    </Box>
  </Flex>
);

interface Props {
  trackingEvent: () => void;
  headline: string;
  contactButtonText: string;
  headlineApprovedAds: string;
  textApprovedAds: string;
  headlineXp: string;
  textXp: string;
  headlineCS: string;
  textCS: string;
}

export const YearsOfExperienceSection = ({
  trackingEvent,
  headline,
  contactButtonText,
  headlineApprovedAds,
  headlineCS,
  headlineXp,
  textApprovedAds,
  textCS,
  textXp,
}: Props) => (
  <PageBlock
    bg="gray"
    py="extraLarge"
    topShadow
  >
    <ContractsLandingPageContainer>
      <Flex
        my={{
          xs: 0,
          md: 6,
        }}
        textAlign="center"
        align="center"
        column
      >
        <Box mb={8}>
          <ScalabaleHeadline>{headline}</ScalabaleHeadline>
        </Box>

        <Flex
          width="100%"
          mb={6}
          column={{
            xs: true,
            md: false,
          }}
          gap={4}
          justify="space-between"
        >
          {/* Desktop + Mobile - approved ads */}
          <AnimationReveal>
            <Flex
              column
              align="center"
              my={1}
            >
              <IconWrapper>
                <RoundCheckmark fontSize="60px" />
              </IconWrapper>

              <TextSection
                headline={headlineApprovedAds}
                text={textApprovedAds}
              />
            </Flex>
          </AnimationReveal>

          {/* Mobile - 20 years of experience */}
          <Flex
            hidden={{
              xs: false,
              md: true,
            }}
            column
            align="center"
            my={1}
          >
            <AnimationReveal delayInMilliseconds={300}>
              <IconWrapper>
                <Star fontSize="60px" />
              </IconWrapper>

              <TextSection
                headline={headlineXp}
                text={textXp}
              />
            </AnimationReveal>
          </Flex>

          {/* Mobile - customer service */}
          <Hidden
            on={{
              xs: false,
              md: true,
            }}
          >
            <AnimationReveal delayInMilliseconds={600}>
              <Flex
                column
                align="center"
                my={1}
              >
                <IconWrapper>
                  <CustomerService fontSize="60px" />
                </IconWrapper>

                <TextSection
                  headline={headlineCS}
                  text={textCS}
                />

                <CustomerServiceButton
                  trackingEvent={trackingEvent}
                  text={contactButtonText}
                />
              </Flex>
            </AnimationReveal>
          </Hidden>

          {/* Desktop - customer service */}
          <Hidden
            on={{
              xs: true,
              md: false,
            }}
          >
            <AnimationReveal delayInMilliseconds={300}>
              <Flex
                column
                align="center"
                my={1}
              >
                <IconWrapper>
                  <CustomerService fontSize="60px" />
                </IconWrapper>

                <TextSection
                  headline={headlineCS}
                  text={textCS}
                />
                <CustomerServiceButton
                  trackingEvent={trackingEvent}
                  text={contactButtonText}
                />
              </Flex>
            </AnimationReveal>
          </Hidden>

          {/* Desktop - 20 years of experience */}

          <Flex
            hidden={{
              xs: true,
              md: false,
            }}
            column
            align="center"
            my={1}
          >
            <AnimationReveal delayInMilliseconds={600}>
              <IconWrapper>
                <Star fontSize="60px" />
              </IconWrapper>

              <TextSection
                headline={headlineXp}
                text={textXp}
              />
            </AnimationReveal>
          </Flex>
        </Flex>
        <Box width="100%">
          <CdnImage
            style={{
              width: "100%",
              overflowX: "hidden",
            }}
            src="/static/images/landing_page/renting_out/Customer-service.png"
          />
        </Box>
      </Flex>
    </ContractsLandingPageContainer>
  </PageBlock>
);
