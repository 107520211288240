import {
  Dialog,
  IconArrowBackForward,
  IconBusinessOutline,
  IconCardOutline,
  IconChatboxOutline,
  IconCog,
  IconDocumentTextOutline,
  IconEllipsisHorizontal,
  IconHelpCircleOutline,
  IconLogo,
  IconPeopleOutline,
  IconRentables,
  IconSearchOutline,
  IconServerOutline,
  IconTrendingUp,
  Menu,
  MenuBaseItem,
} from "@boligportal/juice";
import { trackUserClicksRentalDataSideBarMenuItem } from "apps/rental_data/tracking";
import { InboxCountBadge } from "components/InboxBadgeCount";
import { RentCollectionNotificationBadge } from "components/RentCollectionNotificationBadge";
import { App } from "components/app";
import { ConfirmLogoutDialog } from "components/header/confirm_logout_dialog/ConfirmLogoutDialog";
import { useLogout } from "components/header/confirm_logout_dialog/useLogout";
import { useSignInAsColleague } from "components/header/drop_down_menu/useSignInAsColleague";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { ImpersonateCompanyColleagueDialogContent } from "features/impersonate_company_colleague/components/ImpersonateCompanyColleagueDialogContent";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import {
  SidebarMenuArea,
  SidebarMenuAreaTop,
  SidebarMenuAreaBottom,
  SideBarMenuSpacer,
} from ".";
import { MenuItemsGroupText } from "./components/menu_group_collapse/MenuGroupCollapse";
import { MenuItem, MenuMoreOpener } from "./components/menu_item/MenuItem";
import { isUserAllowedToSeeEconomy } from "./conditionals/isUserAllowedToSeeEconomy";
import { isUserAllowedToSeeRent } from "./conditionals/isUserAllowedToSeeRent";
import { isUserAllowedToSeeRentalData } from "./conditionals/isUserAllowedToSeeRentalData";

const MenuIconSize = "medium";
interface Props {
  user: User;
}

export const LandlordSidebar = ({ user }: Props) => {
  const {
    INBOX,
    MY_LISTINGS,
    MY_RENTABLES,
    FIND_TENANT,
    RENTAL_CONTRACTS,
    MOVING_REPORTS,
    ECONOMY,
    RENT,
    RENTAL_DATA,
    SETTINGS,
    TENANCY,
    NEWBUILDS,
  } = menuTranslations();

  const { routes } = App.settings;

  return (
    <SidebarMenuArea>
      <SidebarMenuAreaTop>
        <MenuItem
          href={routes.property_owner.my_rentables}
          icon={<IconLogo size={MenuIconSize} />}
          title={MY_RENTABLES}
        />
        <SideBarMenuSpacer />

        <MenuItemsGroupText
          px={3}
          color="inverted"
          size="tiny"
          weight="semiBold"
        >
          {t("menu.landlord.group.advertising_label")}
        </MenuItemsGroupText>
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.landlord_listings}
          icon={<IconRentables size={MenuIconSize} />}
          title={MY_LISTINGS}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        {user.company_new_build_access && (
          <MenuItem
            href={routes.newbuilds}
            icon={<IconBusinessOutline size={MenuIconSize} />}
            title={NEWBUILDS}
            paddingLeft={3}
          />
        )}
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.inbox}
          icon={<IconChatboxOutline size={MenuIconSize} />}
          title={INBOX}
          info={<InboxCountBadge />}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.find_tenant}
          icon={<IconSearchOutline size={MenuIconSize} />}
          title={FIND_TENANT}
          paddingLeft={3}
        />
        <SideBarMenuSpacer />

        <MenuItemsGroupText
          px={3}
          color="inverted"
          size="tiny"
          weight="semiBold"
        >
          {t("menu.landlord.group.administration_label")}
        </MenuItemsGroupText>
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.tenancy_list}
          icon={<IconPeopleOutline size={MenuIconSize} />}
          title={TENANCY}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.contracts}
          icon={<IconDocumentTextOutline size={MenuIconSize} />}
          title={RENTAL_CONTRACTS}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.moving_reports}
          icon={<IconArrowBackForward size={MenuIconSize} />}
          title={MOVING_REPORTS}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        {isUserAllowedToSeeRent() && (
          <>
            <MenuItem
              href={routes.property_owner.rent}
              icon={<IconServerOutline size={MenuIconSize} />}
              title={RENT}
              paddingLeft={3}
              info={<RentCollectionNotificationBadge />}
            />
            <SideBarMenuSpacer size={1} />
          </>
        )}

        {isUserAllowedToSeeRentalData() && (
          <>
            <MenuItem
              href={routes.rental_data}
              icon={<IconTrendingUp size={MenuIconSize} />}
              title={RENTAL_DATA}
              paddingLeft={3}
              onClick={() => trackUserClicksRentalDataSideBarMenuItem()}
            />
            <SideBarMenuSpacer size={1} />
          </>
        )}
      </SidebarMenuAreaTop>

      <SidebarMenuAreaBottom>
        {isUserAllowedToSeeEconomy(user) && (
          <>
            <MenuItem
              href={routes.economy_page}
              icon={<IconCardOutline size={MenuIconSize} />}
              title={ECONOMY}
              paddingLeft={3}
            />
            <SideBarMenuSpacer size={1} />
          </>
        )}

        <SupportMoreMenu />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.user_settings}
          icon={<IconCog size={MenuIconSize} />}
          title={SETTINGS}
          paddingLeft={3}
        />
        <SideBarMenuSpacer size={1} />

        <LandlordMoreMenu />
        <SideBarMenuSpacer />
      </SidebarMenuAreaBottom>
    </SidebarMenuArea>
  );
};

interface LandlordMoreItem extends MenuBaseItem<LandlordMoreItem> {}

const LandlordMoreMenu = () => {
  const {
    confirmLogout,
    hideConfirmLogoutDialog,
    showConfirmLogoutDialog,
    shouldShowConfirmLogoutDialog,
  } = useLogout();

  const {
    state,
    shouldShowSignInAsCompanyColleagueDialog,
    showSignInAsColleague,
    hideSignInAsColleague,
    impersonateCompanyColleague,
    isUserAllovedToImpersonateColleague,
  } = useSignInAsColleague();

  const { routes, market } = App.settings;

  const isDK = market === Market.BOLIGPORTAL_DK;

  const {
    LANDLORD_PROFILE,
    LANDLORD_SUBSCRIPTIONS,
    SIGN_IN_AS_COLLEAGUE,
    MY_FAVOURITE_ADS,
    MY_AGENTS,
    LOG_OUT,
    BENEFITS,
  } = menuTranslations();
  const moreItems: LandlordMoreItem[] = [
    {
      name: LANDLORD_PROFILE,
      href: routes.profile,
    },
    {
      name: LANDLORD_SUBSCRIPTIONS,
      href: routes.subscription,
    },
    {
      name: MY_FAVOURITE_ADS,
      href: routes.my_favourite_ads,
    },
    {
      name: MY_AGENTS,
      href: routes.agents,
    },
    ...(isDK
      ? [
          {
            name: BENEFITS,
          },
        ]
      : []),
    {},
    {
      name: LOG_OUT,
    },
  ];

  if (isUserAllovedToImpersonateColleague()) {
    moreItems.splice(3, 0, {
      name: SIGN_IN_AS_COLLEAGUE,
    });
  }

  const onHandleMenuItemSelect = (item: LandlordMoreItem) => {
    if (item.name === SIGN_IN_AS_COLLEAGUE) {
      showSignInAsColleague();
    }

    if (item.name === BENEFITS) {
      TrackingEvent.clickBenefitsSidebarMenu();
      window.location.href = routes.benefits;
    }

    if (item.name === LOG_OUT) {
      showConfirmLogoutDialog();
    }
  };

  return (
    <>
      <Menu
        align="center"
        verticalContentPadding={0}
        verticalSeparatorPadding={0}
        openerEl={() => (
          <MenuMoreOpener
            icon={<IconEllipsisHorizontal size={MenuIconSize} />}
            title={t("menu.landlord.more")}
            paddingLeft={3}
          />
        )}
        items={moreItems}
        onMenuItemSelect={onHandleMenuItemSelect}
      />
      {shouldShowConfirmLogoutDialog && (
        <ConfirmLogoutDialog
          onClose={hideConfirmLogoutDialog}
          onConfirmed={confirmLogout}
        />
      )}
      {isUserAllovedToImpersonateColleague() && (
        <Dialog
          open={shouldShowSignInAsCompanyColleagueDialog}
          onClose={hideSignInAsColleague}
        >
          <ImpersonateCompanyColleagueDialogContent
            state={state}
            impersonateCompanyColleague={impersonateCompanyColleague}
          />
        </Dialog>
      )}
    </>
  );
};

const SupportMoreMenu = () => {
  const { market, webinar_url, support_url, blog_url } = App.settings;

  const isDK = market === Market.BOLIGPORTAL_DK;

  const { SUPPORT } = menuTranslations();
  const moreItems: LandlordMoreItem[] = [
    {
      name: SUPPORT,
      href: support_url,
    },
    ...(isDK
      ? [
          {
            name: t("footer.webinar"),
            href: webinar_url,
          },
        ]
      : []),
    {
      name: t("footer.Blog"),
      href: blog_url,
    },
  ];

  return (
    <Menu
      align="center"
      verticalContentPadding={0}
      verticalSeparatorPadding={0}
      openerEl={() => (
        <MenuMoreOpener
          icon={<IconHelpCircleOutline size={MenuIconSize} />}
          title={t("menu.support-and-video")}
          paddingLeft={3}
        />
      )}
      items={moreItems}
      onMenuItemSelect={() => {}}
    />
  );
};
