import * as React from "react";
import { Box, Flex, styled, Text } from "@boligportal/juice";
import AdStateBadge from "apps/rental_ads/components/AdStateBadge";
import { ColumnBackLink } from "components/design-system/molecules/column_header/column_back_link";
import { Ad } from "components/interfaces/ad";

const Wrapper = styled.div`
  padding-bottom: 64px;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

interface Props {
  title: string;
  subtitle?: string | JSX.Element;
  backLink?: { label: string; path: string };
  children: React.ReactNode;
  headerSubcomponent?: React.ReactNode;
  titleRightSideComponent?: React.ReactNode;
  ad?: Ad;
}

export const ListBasis = ({
  title,
  subtitle,
  backLink,
  children,
  headerSubcomponent,
  titleRightSideComponent,
  ad,
}: Props) => (
  <Wrapper>
    {backLink && (
      <ColumnBackLink
        label={backLink.label}
        to={backLink.path}
      />
    )}
    <Box>
      <Flex
        column
        minHeight="65px"
        justify="center"
        p={2}
        borderBottom
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Text
            weight="bold"
            size="h4"
          >
            {title}
          </Text>

          {titleRightSideComponent && titleRightSideComponent}
        </Flex>

        {subtitle && (
          <Text
            size="tiny"
            mt="3px"
          >
            {subtitle}
            <Box
              inline
              ml={1}
            >
              <Text size="small">
                <AdStateBadge data={ad}></AdStateBadge>
              </Text>
            </Box>
          </Text>
        )}
        {headerSubcomponent && headerSubcomponent}
      </Flex>

      <UL>{children}</UL>
    </Box>
  </Wrapper>
);

const UL = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
