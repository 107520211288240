import * as React from "react";
import {
  Button,
  IconHeart,
  IconHeartOutline,
  Spinner,
  styled,
} from "@boligportal/juice";
import { useMarkAdAsFavouriteMutation } from "api/mutations";
import { useUser } from "components/Providers/UserProvider";
import { useOldModal } from "components/modal_controller";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import { t } from "lib/i18n";
import {
  FavouriteAdButtonPlacementTracking,
  TrackingEvent,
} from "lib/tracking/events";

export const favouriteHeartSelector = "favourite_heart";

const Wrapper = styled(Button)`
  padding: 7px 6px 5px 6px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.bg.tint};
  box-shadow: ${(props) => props.theme.shadow.md};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.color.bg.tint};
  }
`;

const TextWrapper = styled(Button)`
  outline: none;

  &:hover,
  & > span:hover {
    text-decoration: none !important;
  }
`;

type Props = {
  adId: number;
  isFavorited: boolean;
  placement: FavouriteAdButtonPlacementTracking;
  showText?: boolean;
  size?: React.ComponentProps<typeof IconHeart>["size"];
};

export const FavouriteButton = ({
  adId,
  size = "medium",
  showText = false,
  isFavorited,
  placement,
}: Props) => {
  const { mutate, isLoading } = useMarkAdAsFavouriteMutation(adId);
  const { user } = useUser();
  const modalActions = useOldModal();

  const handleToggleFavourite = () => {
    if (isLoading) {
      return;
    }

    const toggledValue = !isFavorited;
    TrackingEvent.markAdAsFavourite(toggledValue, placement);

    mutate({
      markAsFavourite: toggledValue,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!user) {
      modalActions.showModal(
        <AuthModal
          view={AuthView.SIGNUPWITHEMAIL}
          onSuccess={handleToggleFavourite}
        />,
      );

      return;
    }

    handleToggleFavourite();
  };

  const renderIcon = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isFavorited) {
      return (
        <IconHeart
          color="branded"
          size={size}
        />
      );
    }

    return (
      <IconHeartOutline
        color="base"
        size={size}
      />
    );
  };

  if (showText) {
    return (
      <TextWrapper
        onClick={handleClick}
        icon={isFavorited ? IconHeart : IconHeartOutline}
        variant={isFavorited ? "branded" : "default"}
        text
      >
        {isFavorited
          ? t("ad_details.saved_as_favourite")
          : t("ad_details.save_as_favourite")}
      </TextWrapper>
    );
  }

  return <Wrapper onClick={handleClick}>{renderIcon()}</Wrapper>;
};
