import { Box, IconHeart, IconHeartOutline, styled } from "@boligportal/juice";
import { App } from "components/app";
import { Badge } from "components/design-system/atoms/badge/badge";
import { SignupContext } from "lib/enums";
import { HeaderAction } from "lib/tracking/events";
import { ItemProps } from "../top_nav";

const StyledIconHeartOutline = styled(IconHeartOutline)`
  &:hover {
    opacity: 0.8;
  }
`;

const StyledIconHeart = styled(IconHeart)`
  &:hover {
    opacity: 0.8;
  }
`;

export const TopNavItemUserFavorites = ({
  inverseColor,
  onItemClick,
}: ItemProps) => {
  const url = App.settings.routes.my_favourite_ads;
  const trackingAction = HeaderAction.FAVOURITES;

  return (
    <Box mx={0.5}>
      <Badge
        href={url}
        onClick={(event) =>
          onItemClick(
            {
              url,
              trackingAction,
              context: SignupContext.FAVORITE,
            },
            event,
          )
        }
        clickable
      >
        {inverseColor ? (
          <StyledIconHeart
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        ) : (
          <StyledIconHeartOutline
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        )}
      </Badge>
    </Box>
  );
};
