import { useState, useEffect } from "react";
import { Button, IconEyeOutline } from "@boligportal/juice";
import { GetPreviewAdResponseInterface } from "../../interfaces";

const PreviewAdAction = (props: {
  action: () => Promise<GetPreviewAdResponseInterface>;
}) => {
  const [absoluteUrl, setAbsoluteUrl] = useState<string>("");

  useEffect(() => {
    props
      .action()
      .then((response) => {
        // Happy path
        if (response.success) {
          setAbsoluteUrl(response.url);
        }
      })
      .catch((error) => {
        // Sad path
      });
  }, []);

  const handlePreviewButtonClick = () => {
    window.open(absoluteUrl, "_blank");
  };

  return (
    <Button
      onClick={handlePreviewButtonClick}
      icon={IconEyeOutline}
    >
      See Ad
    </Button>
  );
};

export { PreviewAdAction };
