import * as React from "react";
import { Box, Button, Flex, IconEye, styled } from "@boligportal/juice";

type Props = {
  children: React.ReactNode;
  count: number;
} & Pick<React.ComponentProps<typeof Button>, "onClick" | "variant">;

export const ButtonBadgeOrEye = ({
  children,
  count,
  onClick,
  variant,
}: Props) => (
  <Button
    variant={variant}
    onClick={onClick}
  >
    <Flex align="center">
      {count > 0 ? (
        <Box pr={1}>
          <BadgeCountWrapper>{count}</BadgeCountWrapper>
        </Box>
      ) : (
        <Flex pr={1}>
          <Box
            width="20px"
            height="20px"
          >
            <IconEye size="medium" />
          </Box>
        </Flex>
      )}
      {children}
    </Flex>
  </Button>
);

const BadgeCountWrapper = styled.span`
  pointer-events: none;
  background-color: ${(props) => props.theme.colorPalette.brand[500]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0;
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: 10px;
  z-index: 1;
  transform-origin: 100% 0%;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 0 0 2px white;
`;
