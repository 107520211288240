import * as React from "react";
import { Box, Flex, Text } from "@boligportal/juice";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { App } from "components/app";
import { CurrencyAmountLabel } from "components/juice-candidates/CurrencyAmountLabel";
import { calculateTenancyPaymentsSum } from "features/rentable_tenancy/helpers/calculateTenancyPaymentsSum";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";

export const PaymentSection = ({
  recurringRent,
  rentRegulation,
  oneTimePayments,
  onTimePaymentTrigger,
}: {
  recurringRent: TenancyRentEntity[]; // base payment
  rentRegulation: TenancyRentEntity[];
  oneTimePayments: TenancyRentEntity[];
  onTimePaymentTrigger?: React.ReactNode;
}) => {
  // checks for any rent regulations and uses those if present, else fallbacks to base payments
  const items = rentRegulation.length > 0 ? rentRegulation : recurringRent;

  const sumTotal =
    calculateTenancyPaymentsSum(items) +
    calculateTenancyPaymentsSum(oneTimePayments);

  return (
    <Box>
      {items.map((payment) => (
        <Flex
          key={payment.id}
          justify="space-between"
        >
          <Text
            weight="bold"
            mb={2.25}
          >
            {payment.name}
          </Text>
          <Text>{formatCurrency(payment.amount, App.settings.currency)}</Text>
        </Flex>
      ))}

      {oneTimePayments.map((payment) => (
        <React.Fragment key={`${payment.id}-${payment.name}`}>
          <Flex justify="space-between">
            <Text
              weight="bold"
              mb={2.25}
            >
              {`${t("rentable.rent.one_time_payment.label")}: ${payment.name}`}
            </Text>
            <Text>{formatCurrency(payment.amount, App.settings.currency)}</Text>
          </Flex>
        </React.Fragment>
      ))}

      {onTimePaymentTrigger}

      <Flex
        justify="end"
        pt={2}
      >
        <CurrencyAmountLabel
          label={`${t("Total")}:`}
          amount={sumTotal}
          size="body"
          labelWeight="base"
          emphasizeAmount="headline"
          amountSize="lead"
        />
      </Flex>
    </Box>
  );
};
