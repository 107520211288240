import { t } from "lib/i18n";
import {
  BeforeAfterItemField,
  BeforeAfterItemType,
  TenancyBaseDiff,
} from "../types";
import { BeforeAfterItem } from "./BeforeAfterItem";

type Props = {
  diff: TenancyBaseDiff;
  onChange: (item: BeforeAfterItemType, isChecked: boolean) => void;
};

export const CheckableTenancyBaseDiffView = ({ diff, onChange }: Props) => {
  const entries = Object.entries(diff);
  const items: BeforeAfterItemType[] = [];

  const fieldMap = {
    prepaid_rent: t("common.form_fields.prepaid_rent.label"),
    deposit: t("common.form_fields.deposit.label"),
    move_in_date: t("common.form_fields.move_in_date.label"),
    move_out_date: t("common.form_fields.move_out_date.label"),
  };

  entries.forEach((entry) => {
    const [field, fieldDiff] = entry;

    // TS, was complaining about fieldDiff possible undefined.
    const isChecked = fieldDiff?.isChecked ?? false;
    const beforeValue = fieldDiff?.before ?? "";
    const afterValue = fieldDiff?.after ?? "";

    items.push({
      field: field as BeforeAfterItemField,
      label: fieldMap[field],
      isChecked: isChecked,
      before: `${beforeValue}`,
      after: `${afterValue}`,
    });
  });

  return (
    <>
      {items.map((item) => (
        <BeforeAfterItem
          onChange={onChange}
          key={item.field}
          item={item}
        />
      ))}
    </>
  );
};
