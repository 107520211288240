import { JsonLd } from "react-schemaorg";
import { Box, Flex } from "@boligportal/juice";
import { FAQPage } from "schema-dts";
import { FaqPanel } from "./faq_panel";

export interface FaqItem {
  question: string;
  answer: string;
  html_answer?: string;
}

const JsonLdFAQ = ({ items }: { items: FaqItem[] }) => (
  <JsonLd<FAQPage>
    item={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      name: "FAQ",
      mainEntity: items.map((item) => ({
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.html_answer || item.answer,
        },
      })),
    }}
  />
);

// Structured data for FAQ section - https://developers.google.com/search/docs/data-types/faqpage
export const FAQSection = ({ items }: { items: FaqItem[] }) => (
  <Box>
    <JsonLdFAQ items={items} />

    <Flex column>
      {items.map((item) => (
        <FaqPanel
          key={item.question}
          title={item.question}
          collapsableContent={item.answer}
        />
      ))}
    </Flex>
  </Box>
);
