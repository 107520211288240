import * as React from "react";
import { SortingSelect } from "@boligportal/juice";
import { useGetRentableTenancies } from "api/queries";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import { TenancyTenant } from "business/domain/tenancy/TenancyTenant";
import {
  createTenancySelectorOption,
  TenancySelectorItem,
} from "features/rentable_tenancy/hooks/useTenancySelector";
import { useTenancyCreation } from "features/tenancy/useTenancyCreation";
import { useTenancySelection } from "features/tenancy/useTenancySelection";
import { t } from "lib/i18n";

const CHOOSE_TENANCY = "CHOOSE_TENANCY";
const CREATE_NEW_TENANCY = "CREATE_NEW_TENANCY";

const createItems = (candidates: Tenancy[]): TenancySelectorItem[] => {
  const result: TenancySelectorItem[] = [];

  result.push({
    label: t("tenancy_selctor.option.choose_tenancy"),
    value: CHOOSE_TENANCY,
  });

  result.push({
    label: t("tenancy_selctor.option.new_tenancy"),
    value: CREATE_NEW_TENANCY,
  });

  candidates.forEach((candidate) => {
    result.push(createTenancySelectorOption(candidate));
  });

  return result;
};

const getSelectedItem = (items: TenancySelectorItem[], id: number | null) => {
  if (id === null) {
    return CHOOSE_TENANCY;
  }
  const selectedItem = items.find((item) => item.value === id.toString());
  return selectedItem?.value ?? CHOOSE_TENANCY;
};

type Props = {
  rentableId: number;
  createNewTenancyOptions: {
    tenants: TenancyTenant[];
    moveInDate?: string;
  };
};

export const StatusPageTenancySelector = ({
  rentableId,
  createNewTenancyOptions,
}: Props) => {
  const { setTenancyCreationCandidate } = useTenancyCreation();
  const { data, refetch } = useGetRentableTenancies(rentableId);
  const { selectedTenancyId, setTenancyChangeCandidate } =
    useTenancySelection();

  const onHandleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const nextSelectedValue = event.target.value;

    if (nextSelectedValue === CHOOSE_TENANCY) {
      setTenancyChangeCandidate({
        tenancies: data?.tenancies ?? [],
        tenancyId: null,
      });
    } else if (nextSelectedValue === CREATE_NEW_TENANCY) {
      setTenancyCreationCandidate({
        rentableId: rentableId,
        tenants: createNewTenancyOptions.tenants,
        moveInDate: createNewTenancyOptions.moveInDate,
        onCreateSuccess: (tenancy) => {
          refetch().then(() => {
            setTenancyChangeCandidate({
              tenancies: data?.tenancies ?? [],
              tenancyId: tenancy.tenancy_id,
            });
            setTenancyCreationCandidate(null);
          });
        },
      });
    } else {
      setTenancyChangeCandidate({
        tenancies: data?.tenancies ?? [],
        tenancyId: Number(nextSelectedValue),
      });
    }
  };

  if (!data) {
    return (
      <SortingSelect
        label={t("rentable.rent.tenancy")}
        items={createItems([])}
      />
    );
  }

  const items = createItems(data.tenancies);
  const selectedItemValue = getSelectedItem(items, selectedTenancyId);

  return (
    <SortingSelect
      label={t("rentable.rent.tenancy")}
      items={items}
      value={selectedItemValue}
      onChange={onHandleChange}
    />
  );
};
