import { styled } from "@boligportal/juice";

export const StyledMessageButton = styled.a<{ highlighted: boolean }>`
  background-color: ${(props) =>
    props.highlighted
      ? props.theme.colorPalette.brand[500]
      : props.theme.colorPalette.gray[30]};
  color: ${(props) =>
    props.highlighted ? props.theme.colorPalette.gray[10] : "black"};
  padding: ${(props) => props.theme.unit(0.5) + " " + props.theme.unit(1)};
  border-radius: ${(props) => props.theme.unit(0.5)};
  border: 1px solid
    ${(props) =>
      props.highlighted
        ? props.theme.colorPalette.brand[600]
        : props.theme.colorPalette.gray[50]};
  &:hover {
    text-decoration: underline;
    color: ${(props) => (props.highlighted ? "white" : "black")};
  }
  a {
    color: rgb(24, 90, 121);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: ${(props) => props.theme.unit(0.5)};
  }
`;
