import * as React from "react";

const SvgMaps12Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    className="Icon"
    {...props}
  >
    <path
      fill="none"
      stroke="#262626"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      d="M4.09 11.727L.274 9V.273L4.09 3 7.909.273 11.727 3v8.727L7.91 9l-3.818 2.727zM4.09 3v8.727V3zm3.82 6V.29 9z"
    />
  </svg>
);
export default SvgMaps12Px;
