export const FacebookLogo = () => (
  <svg
    width="57px"
    height="57px"
    viewBox="0 0 57 57"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.9"
    >
      <g
        transform="translate(-1349.000000, -2753.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 1961.000000)">
          <g transform="translate(1142.000000, 761.000000)">
            <g transform="translate(207.000000, 31.000000)">
              <path
                d="M49.875,0 L7.125,0 C3.1955625,0 0,3.1955625 0,7.125 L0,49.875 C0,53.8044375 3.1955625,57 7.125,57 L28.5,57 L28.5,37.40625 L21.375,37.40625 L21.375,28.5 L28.5,28.5 L28.5,21.375 C28.5,15.4719375 33.2844375,10.6875 39.1875,10.6875 L46.3125,10.6875 L46.3125,19.59375 L42.75,19.59375 C40.7835,19.59375 39.1875,19.4085 39.1875,21.375 L39.1875,28.5 L48.09375,28.5 L44.53125,37.40625 L39.1875,37.40625 L39.1875,57 L49.875,57 C53.8044375,57 57,53.8044375 57,49.875 L57,7.125 C57,3.1955625 53.8044375,0 49.875,0 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
