import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { emailLogsFiltersToQueryParams } from "apps/customer_service/pages/email_logs_page/utils";
import {
  BetalingsserviceMerchantStatus,
  EmailLogsFilters,
  getActivityStream,
  getAgreements,
  getBetalingsserviceList,
  getCompanyActivityStream,
  getEmailEvents,
  getPayments,
  getUsersEmailLogStream,
  SortOrder,
} from "lib/customerServiceApi";

export const activityStreamPageLimit = 200;

export const customerServiceKeys = {
  activityStream: (...args: Parameters<typeof getActivityStream>) =>
    ["CSActivityStream", ...args] as const,
  companyActivityStream: (
    companyId: number | undefined,
    order: SortOrder,
    end_index: number,
  ) => ["CSCompanyActivityStream", companyId, order, end_index] as const,
  simulatedPayment: () => ["Payment"] as const,
  simulatedAgreements: () => ["Agreements"] as const,
  emailLogStream: (filters: EmailLogsFilters) =>
    ["emailLogStream", filters] as const,
  emailEvents: (id?: string) => ["emailEvents", id] as const,
  merchantStatus: () => ["betalingsservice"] as const,
  merchantStatusFilters: (filters?: BetalingsserviceMerchantStatus[]) =>
    [...customerServiceKeys.merchantStatus(), filters] as const,
};

export const useUserActivityStream = (
  ...args: Parameters<typeof getActivityStream>
) =>
  useQuery(
    customerServiceKeys.activityStream(...args),
    () => getActivityStream(...args),
    {
      keepPreviousData: true,
    },
  );

export const useCompanyActivityStream = (
  companyId: number | undefined,
  order: SortOrder,
  start_index: number,
  end_index: number,
) =>
  useQuery(
    customerServiceKeys.companyActivityStream(companyId, order, end_index),
    () =>
      getCompanyActivityStream(companyId || -1, order, start_index, end_index),
    {
      enabled: !!companyId,
      keepPreviousData: true,
    },
  );

export const useMobilepaySimulatorPayment = (url: string) =>
  useQuery(customerServiceKeys.simulatedPayment(), () => getPayments(url));

export const useMobilepaySimulatorAgreements = (url: string) =>
  useQuery(customerServiceKeys.simulatedAgreements(), () => getAgreements(url));

export const useEmailLogStreamQuery = (filters: EmailLogsFilters) => {
  const history = useHistory();

  return useQuery(
    customerServiceKeys.emailLogStream(filters),
    () => getUsersEmailLogStream(filters),
    {
      enabled:
        filters.start_index !== undefined && filters.end_index !== undefined,
      onSettled: () => {
        const queryParams = emailLogsFiltersToQueryParams(filters);
        const url = `${history.location.pathname}?${queryParams.toString()}`;
        history.replace(url);
      },
    },
  );
};

export const useEmailEventsQuery = (id?: string) =>
  useQuery(["emailEvents", id], () => getEmailEvents(id!), {
    enabled: !!id,
  });

export const useBetalingsserviceCSQuery = (
  filters?: BetalingsserviceMerchantStatus[],
) =>
  useQuery(customerServiceKeys.merchantStatusFilters(filters), () =>
    getBetalingsserviceList({
      status: filters,
    }),
  );
