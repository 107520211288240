import { useState } from "react";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { isNotEligible } from "features/upsell/helpers";
import { DisabledHoverableCard } from "./DisabledHoverableCard";
import { HoverableCard } from "./HoverableCard";
import { HoverableCardVolumeBars } from "./HoverableCardVolumeBars";
import { HoverableCardWrapper } from "./HoverableCardWrapper";

type Props = {
  plans: Plan[];
  onRequestBuyPlan: (plan: Plan) => void;
  activeUpsellSubscriptions: AdSubscription[];
  hasVolumeBars: boolean;
  isPrivateLandlord: boolean;
  isAdLessThan24HoursOld: boolean;
};

export const ProLandlordUpsellScreenCardList = ({
  plans,
  onRequestBuyPlan,
  activeUpsellSubscriptions,
  hasVolumeBars,
  isPrivateLandlord,
  isAdLessThan24HoursOld,
}: Props) => {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const hasMultiplePlans = plans.length > 1;

  const disableAd = isNotEligible(
    activeUpsellSubscriptions,
    plans[selectedPlan],
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setSelectedPlan !== undefined) {
      setSelectedPlan(parseInt(e.target.value));
    }
  };

  const handleClick = () => {
    if (!hasMultiplePlans) {
      onRequestBuyPlan(plans[selectedPlan]);
    }
  };

  const activeSubscription = activeUpsellSubscriptions.find(
    (subscription) => subscription.plan.product.name === plans[0].product.name,
  );

  return (
    <HoverableCardWrapper
      isDisabled={disableAd}
      onMouseOut={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      onClick={handleClick}
    >
      {!hasVolumeBars && (
        <HoverableCardVolumeBars
          plans={plans}
          selectedPlan={selectedPlan}
          isHovered={isHovered}
        />
      )}
      {!activeSubscription ? (
        <HoverableCard
          isHovered={isHovered}
          plans={plans}
          isPrivateLandlord={isPrivateLandlord}
          handleChange={handleChange}
          selectedPlan={selectedPlan}
          onRequestBuyPlan={onRequestBuyPlan}
          disableAd={disableAd}
          isAdLessThan24HoursOld={isAdLessThan24HoursOld}
        />
      ) : (
        <DisabledHoverableCard
          hasMultiplePlans={hasMultiplePlans}
          disableAd={disableAd}
          isPrivateLandlord={isPrivateLandlord}
          activeUpsellSubscription={activeSubscription}
        />
      )}
    </HoverableCardWrapper>
  );
};
