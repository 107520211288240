import * as React from "react";
import { App } from "components/app";
import { Settings } from "components/interfaces/settings";

export const SettingsProvider = ({
  children,
  settings,
}: {
  children: React.ReactNode;
  settings: Settings;
}) => {
  // This must set the _settings on App in order to not break existing functionalities.
  // When we refactor this, any import of App.settings should import/use the context from this file.
  // @ts-ignore
  App._settings = settings;

  return (
    <App.SettingsContext.Provider value={settings}>
      {children}
    </App.SettingsContext.Provider>
  );
};

export const useSettings = () => React.useContext(App.SettingsContext);
