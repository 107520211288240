import { Dialog, Menu, MenuBaseItem } from "@boligportal/juice";
import { App } from "components/app";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { isUserAllowedToSeeRent } from "components/sidebar-menu/conditionals/isUserAllowedToSeeRent";
import { ImpersonateCompanyColleagueDialogContent } from "features/impersonate_company_colleague/components/ImpersonateCompanyColleagueDialogContent";
import { t } from "lib/i18n";
import { menuTranslations } from "lib/translations";
import { AvatarMenuOpener } from "../components/AvatarMenuOpener";
import { BenefitsMenuItem } from "../components/BenefitsMenuItem";
import { InboxMenuItem } from "../components/InboxMenuItem";
import { ProfileMenuItem } from "../components/ProfileMenuItem";
import { ConfirmLogoutDialog } from "../confirm_logout_dialog/ConfirmLogoutDialog";
import { useLogout } from "../confirm_logout_dialog/useLogout";
import { useSignInAsColleague } from "./useSignInAsColleague";

interface Props {
  user: User;
  inverseColor: boolean;
}

export interface SeekerMenuItem extends MenuBaseItem<SeekerMenuItem> {}

export const UserAvatarSeekerMenu = ({ user, inverseColor }: Props) => {
  const {
    confirmLogout,
    hideConfirmLogoutDialog,
    showConfirmLogoutDialog,
    shouldShowConfirmLogoutDialog,
  } = useLogout();

  const {
    state,
    shouldShowSignInAsCompanyColleagueDialog,
    showSignInAsColleague,
    hideSignInAsColleague,
    impersonateCompanyColleague,
    isUserAllovedToImpersonateColleague,
  } = useSignInAsColleague();

  const { routes, support_url, market, blog_url } = App.settings;

  const isDK = market === Market.BOLIGPORTAL_DK;

  const {
    CREATE_LISTING,
    FIND_TENANT,
    HOUSING_ADMINISTRATION,
    INBOX,
    LOG_OUT,
    MOVING_REPORTS,
    RENT,
    MY_AGENTS,
    MY_FAVOURITE_ADS,
    RENTAL_CONTRACTS,
    SETTINGS,
    SIGN_IN_AS_COLLEAGUE,
    SUBSCRIPTIONS,
    SUPPORT,
    BENEFITS,
    NEWBUILDS,
  } = menuTranslations();

  const moreItems: SeekerMenuItem[] = [
    {
      name: CREATE_LISTING,
      href: routes.property_owner.create_listing,
    },
    ...(user.company_new_build_access
      ? [
          {
            name: NEWBUILDS,
            href: routes.newbuilds,
          },
        ]
      : []),
    {
      name: FIND_TENANT,
      href: routes.find_tenant,
    },
    {
      name: RENTAL_CONTRACTS,
      href: routes.contracts,
    },
    {
      name: MOVING_REPORTS,
      href: routes.moving_reports,
    },
    ...(isUserAllowedToSeeRent()
      ? [
          {
            name: RENT,
            href: routes.property_owner.rent,
          },
        ]
      : []),
  ];

  const items: SeekerMenuItem[] = [
    {
      href: routes.profile,
      renderFunction: ProfileMenuItem,
    },
    {},
    {
      name: INBOX,
      href: routes.inbox,
      renderFunction: InboxMenuItem,
    },
    {
      name: MY_FAVOURITE_ADS,
      href: routes.my_favourite_ads,
    },
    {
      name: MY_AGENTS,
      href: routes.agents,
    },
    {
      name: SUBSCRIPTIONS,
      href: routes.subscription,
    },
    ...(isDK
      ? [
          {
            name: BENEFITS,
            href: routes.benefits,
            renderFunction: BenefitsMenuItem,
          },
        ]
      : []),
    {},
    {
      name: t("menu.seeker.group.rental_label"),
      items: moreItems,
    },
    ...(isDK
      ? [
          {
            name: HOUSING_ADMINISTRATION,
            href: routes.housing_administration_landingpage,
          },
        ]
      : []),
    {},
    {
      name: SUPPORT,
      href: support_url,
    },
    ...(isDK
      ? [
          {
            name: t("footer.Blog"),
            href: blog_url,
          },
        ]
      : []),
    {
      name: SETTINGS,
      href: routes.user_settings,
    },
    ...(isUserAllovedToImpersonateColleague()
      ? [
          {
            name: SIGN_IN_AS_COLLEAGUE,
          },
        ]
      : []),
    {
      name: LOG_OUT,
    },
  ];

  const onHandleMenuItemSelect = (item: SeekerMenuItem) => {
    if (item.name === SIGN_IN_AS_COLLEAGUE) {
      showSignInAsColleague();
    }

    if (item.name === LOG_OUT) {
      showConfirmLogoutDialog();
    }
  };

  if (shouldShowConfirmLogoutDialog) {
    return (
      <ConfirmLogoutDialog
        onClose={hideConfirmLogoutDialog}
        onConfirmed={confirmLogout}
      />
    );
  }

  return (
    <>
      <Menu
        align="end"
        width={280}
        items={items}
        onMenuItemSelect={onHandleMenuItemSelect}
        openerEl={(isOpened) => (
          <AvatarMenuOpener
            isOpened={isOpened}
            inverseColor={inverseColor}
            user={user}
          />
        )}
        backButtonLabel={t("menu.seeker.back")}
      />

      {isUserAllovedToImpersonateColleague() && (
        <Dialog
          open={shouldShowSignInAsCompanyColleagueDialog}
          onClose={hideSignInAsColleague}
        >
          <ImpersonateCompanyColleagueDialogContent
            state={state}
            impersonateCompanyColleague={impersonateCompanyColleague}
          />
        </Dialog>
      )}
    </>
  );
};
