import { useState, useEffect, useCallback } from "react";
import { Box, Confirm, DataTable, Link } from "@boligportal/juice";
import { deleteCompanyPage, getCompanyPages } from "./api";
import { CreateCompanyPageDialog } from "./company_page_slideover/create_company_page_dialog";
import { EditCompanyPageDialog } from "./company_page_slideover/edit_company_page_dialog";
import { createCompanyPages } from "./helpers";
import { CompanyPage } from "./types";

type Props = {
  companyId: number;
};

export const CompanyPageCustomerServiceWidget = ({ companyId }: Props) => {
  const [companyPages, setCompanyPages] = useState<CompanyPage[]>([]);
  const [companyPageForEditing, setCompanyPageForEditing] =
    useState<CompanyPage | null>(null);
  const [companyPageForDeletion, setCompanyPageForDeletion] =
    useState<CompanyPage | null>(null);

  const load = useCallback(() => {
    getCompanyPages(companyId).then((result) => {
      const companyPages = createCompanyPages(result.company_pages);
      setCompanyPages(companyPages);
    });
  }, [companyId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      {companyPages.length > 0 && (
        <Box mb={2}>
          <DataTable<CompanyPage>
            items={companyPages}
            columns={[
              {
                fieldLabel: "",
                fieldName: "slug",
                cellRenderer: (item) => (
                  <Link
                    href={item.url!}
                    newTab
                  >
                    {item.slug}
                  </Link>
                ),
              },
            ]}
            rowActions={[
              {
                label: "Edit",
                callback: (item) => setCompanyPageForEditing(item),
              },
              {
                label: "Delete",
                callback: (item) => setCompanyPageForDeletion(item),
              },
            ]}
            keyField="id"
          />
        </Box>
      )}
      <CreateCompanyPageDialog
        companyId={companyId}
        onClose={load}
      />
      {companyPageForEditing && (
        <EditCompanyPageDialog
          companyId={companyId}
          companyPage={companyPageForEditing}
          onClose={() => {
            setCompanyPageForEditing(null);
            load();
          }}
        />
      )}
      <Confirm
        dangerous
        size="large"
        open={companyPageForDeletion !== null}
        onConfirm={() =>
          deleteCompanyPage(companyId, companyPageForDeletion!).then(() => {
            setCompanyPageForDeletion(null);
            load();
          })
        }
        onCancel={() => setCompanyPageForDeletion(null)}
        title="Delete Company Page"
        message="Are you sure you want to delete this Company Page?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};
