import { Flex, IconCheckmarkCircle, Text } from "@boligportal/juice";
import { css } from "@emotion/react";

export const HeroBullets = ({ bulletTexts }: { bulletTexts: string[] }) => (
  <Flex column>
    {bulletTexts.map((text) => (
      <HeroBullet
        key={text}
        text={text}
      />
    ))}
  </Flex>
);

const HeroBullet = ({ text }: { text: string }) => (
  <Flex
    gap
    align="center"
    py={1}
  >
    <IconCheckmarkCircle
      css={css`
        color: #ffffff;
        font-size: 32px;
      `}
    />
    <Text color="inverted">{text}</Text>
  </Flex>
);
