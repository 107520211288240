import { memo } from "react";
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../types";
import { getRentalCategoryOptions } from "../utils/getRentalCategoryOptions";
import { CreationStatusAndAddress } from "./CreationStatusAndAddress";
import { RentalCategoryItem } from "./FormElements/RentalCategoryItem";
import { RentalNumberOfRoomsItem } from "./FormElements/RentalNumberOfRooms";
import { RentalSizeItem } from "./FormElements/RentalSizeItem";

const StyledRentalCategoryItem = styled(RentalCategoryItem)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    label {
      display: none;
    }
  }
`;
const StyledRentalSizeItem = styled(RentalSizeItem)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    label {
      display: none;
    }
  }
`;
const StyledRentalNumberOfRoomsItem = styled(RentalNumberOfRoomsItem)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    label {
      display: none;
    }
  }
`;

const StyledFieldset = styled.fieldset`
  display: contents;
`;

type Props = {
  index: number;
  disabled: boolean;
  errors: FieldErrors<CreateRentableForm>;
  register: UseFormRegister<CreateRentableForm>;
  address: string;
  getValues: UseFormGetValues<CreateRentableForm>;
  setValue: UseFormSetValue<CreateRentableForm>;
  trigger: UseFormTrigger<CreateRentableForm>;
};

export const SelectRentableListItem = memo(
  ({
    index,
    disabled,
    errors,
    register,
    address,
    getValues,
    setValue,
    trigger,
  }: Props) => {
    const categories = getRentalCategoryOptions();
    const fieldName = `rentables.${index}` as const;

    return (
      <StyledFieldset disabled={disabled}>
        <CreationStatusAndAddress
          register={register}
          fieldName={fieldName}
          disabled={disabled}
          address={address}
          getValues={getValues}
          trigger={trigger}
          setValue={setValue}
        />

        <StyledRentalCategoryItem
          label={t("create.field.category.heading")}
          categories={categories}
          errorText={errors.rentables?.[index]?.category?.message}
          {...register(`${fieldName}.category` as const)}
        />

        <StyledRentalSizeItem
          label={t("create.field.size_m2.heading")}
          errorText={errors.rentables?.[index]?.sizeM2?.message}
          {...register(`${fieldName}.sizeM2` as const, {
            valueAsNumber: true,
          })}
        />

        <StyledRentalNumberOfRoomsItem
          label={t("create.field.rooms.heading")}
          errorText={errors.rentables?.[index]?.roomCount?.message}
          {...register(`${fieldName}.roomCount` as const, {
            valueAsNumber: true,
          })}
        />
      </StyledFieldset>
    );
  },
);
