import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  IconHome,
  IconMail,
  Text,
  styled,
  Button,
} from "@boligportal/juice";
import {
  useFBPPaymentMethodCreditCardSVGs,
  useMarketSettings,
} from "components/Providers/MarketSettingsProvider";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { PaymentMethodButtons } from "../components/PaymentMethodButtons";
import { PaymentMethodCreditCards } from "../components/PaymentMethodCreditCards";
import { PaymentPolicyButtonsWrapper } from "../components/PaymentPolicyButtonsWrapper";
import { PaywallBenefit, PaywallBenefits } from "../components/PaywallBenefits";
import { PlanSelector } from "../components/PlanSelector";
import { findPlanIdOfFirstNonTrialPlan } from "../plans/findPlanIdOfFirstNonTrialPlan";

const TintColoredDialogHeader = styled(DialogHeader)`
  background-color: ${(props) => props.theme.color.bg.tint};
`;
const TintColoredDialogContent = styled(DialogContent)`
  background-color: ${(props) => props.theme.color.bg.tint};
  border-bottom-left-radius: ${(props) => props.theme.unit(2)};
  border-bottom-right-radius: ${(props) => props.theme.unit(2)};
`;

type Props = {
  title: string;
  benefits?: PaywallBenefit[];
  paymentMethods: PaymentMethodType[];
  plans: Plan[];
  renderNoticeSlot?: (selectedPlanId: number | null) => React.ReactNode;
  onClose: () => void;
  onChoosePaymentMethod: (item: {
    type?: PaymentMethodType;
    plan: Plan;
  }) => void;
  initialView?: PlanAndPaymentSelectorDialogView;
  initialSelectedPlanId?: number;
};

export const enum PlanAndPaymentSelectorDialogView {
  PLAN_SELECTION_VIEW = "PLAN_SELECTION_VIEW",
  PAYMENT_METHOD_VIEW = "PAYMENT_METHOD_VIEW",
}

export const PlanAndPaymentSelectorDialog = ({
  onChoosePaymentMethod,
  onClose,
  paymentMethods,
  plans,
  title,
  renderNoticeSlot,
  benefits = [
    {
      icon: IconHome,
      text: t("product_explainer.bullets.contact_landlord"),
    },
    {
      icon: IconMail,
      text: t("product_explainer.bullets.get_contacted_by_landlords"),
    },
  ],
  initialView = PlanAndPaymentSelectorDialogView.PLAN_SELECTION_VIEW,
  initialSelectedPlanId,
}: Props) => {
  const {
    paywall: { createSeekerPlanItems: createSeekerPlanSelectorItems },
  } = useMarketSettings();
  const creditCardIcons = useFBPPaymentMethodCreditCardSVGs();
  const preselectedPlanId = findPlanIdOfFirstNonTrialPlan(plans);
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(
    initialSelectedPlanId ?? preselectedPlanId,
  );
  const { user } = useUser();
  const [view, setView] =
    useState<PlanAndPaymentSelectorDialogView>(initialView);
  useEffect(() => {
    if (view === PlanAndPaymentSelectorDialogView.PAYMENT_METHOD_VIEW) {
      TrackingEvent.viewFBPPaymentsDialog();
    } else {
      TrackingEvent.viewFBPDialog();
    }
  }, [view]);

  const plan = plans.find((plan) => plan.plan_id === selectedPlanId)!;

  const closeDialog = () => {
    onClose();
    if (view === PlanAndPaymentSelectorDialogView.PAYMENT_METHOD_VIEW) {
      TrackingEvent.closeFBPPaymentsDialog();
    } else {
      TrackingEvent.closeFBPDialog();
    }
    setSelectedPlanId(null);
  };

  const handlePaymentMethodClick = (type?: PaymentMethodType) => {
    const plan = plans.find((plan) => plan.plan_id === selectedPlanId);

    if (!plan) {
      return;
    }

    onChoosePaymentMethod({
      type,
      plan,
    });
  };

  const planSelectorItems = createSeekerPlanSelectorItems(plans);

  const filteredPaymentMethods = paymentMethods.filter(
    (method) =>
      method !== PaymentMethodType.MobilePay || App.settings.mobile_pay_enabled,
  );

  const dialogTitle =
    view === PlanAndPaymentSelectorDialogView.PLAN_SELECTION_VIEW
      ? title
      : t("product_explainer.payment_method");

  return (
    <Dialog
      open
      onClose={closeDialog}
      size="564px"
    >
      <TintColoredDialogHeader>{dialogTitle}</TintColoredDialogHeader>
      <TintColoredDialogContent>
        {view === PlanAndPaymentSelectorDialogView.PLAN_SELECTION_VIEW && (
          <Flex column>
            {benefits.length > 0 && (
              <div className="space-y-2 pb-6">
                <PaywallBenefits benefits={benefits} />
              </div>
            )}
            {renderNoticeSlot?.(selectedPlanId)}
            <PlanSelector
              planItems={planSelectorItems}
              selectedPlanId={selectedPlanId}
              onSelect={setSelectedPlanId}
            />
            <Box my={2}>
              <PaymentMethodCreditCards cards={creditCardIcons} />
            </Box>

            <Box>
              <Button
                variant="branded"
                fullWidth
                onClick={() =>
                  !user
                    ? handlePaymentMethodClick()
                    : setView(
                        PlanAndPaymentSelectorDialogView.PAYMENT_METHOD_VIEW,
                      )
                }
              >
                {t("product_explainer.select_payment_method")}
              </Button>
            </Box>
            <Text
              mt={2}
              size="body"
              textAlign="center"
              as="em"
            >
              {t("product_explainer.cancel_any_time")}
            </Text>
          </Flex>
        )}

        {view === PlanAndPaymentSelectorDialogView.PAYMENT_METHOD_VIEW && (
          <>
            <PaymentPolicyButtonsWrapper>
              <PaymentMethodButtons
                paymentMethods={filteredPaymentMethods}
                onClick={handlePaymentMethodClick}
                plan={plan}
                plans={plans}
              />
            </PaymentPolicyButtonsWrapper>
          </>
        )}
      </TintColoredDialogContent>
    </Dialog>
  );
};
