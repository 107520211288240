import * as React from "react";
import {
  Box,
  Button,
  Flex,
  Paginator,
  Spinner,
  Text,
  TextField,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { activityStreamPageLimit } from "api/queries";
import { getListingStateInfo } from "apps/property_owner/my_rentables/views/rentable_detail/views/listings/helpers/getListingStateInfo";
import { App } from "components/app";
import { format } from "date-fns";
import {
  ActionGroup,
  ActivityStreamItem,
  ActivityStreamVerb,
} from "lib/customerServiceApi";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";
import { prettifiedReason } from "../../user_detail_page/blacklist/blacklist_user_notice";

interface ActivityStreamProps {
  items: ActivityStreamItem[];
  onCreateComment: (value: string) => Promise<any>;
  filterComponent?: React.ReactNode;
  isCompanyView?: boolean;
  isLoading?: boolean;
  offset: number;
  setOffset: (page: number) => void;
  totalRecords?: number;
}

export const StyledTimeline = styled.ul`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
`;
export const StyledTimelineItem = styled.li`
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
`;

export const StyledTimelineItemTail = styled.div`
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #e8e8e8;
`;

const timelineHeadStyles = (props) => css`
  background-color: ${props.theme.colorPalette.primary[400]};
  border: 2px solid ${props.theme.colorPalette.primary[400]};
`;

export const StyledTimelineItemHead = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  ${timelineHeadStyles};
`;
const StyledTimelineItemHead2 = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
  color: #ffffff;
  border-color: #eeeeee;
`;

export const StyledTimelineItemContent = styled.div`
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
  word-break: break-word;
`;

interface TimestampProps {
  value: string;
}

const Timestamp = ({ value }: TimestampProps) => {
  const timestampFormated = format(new Date(value), "yyyy-MM-dd - HH:mm:ss");
  return <Text size="small">{timestampFormated}</Text>;
};

const activityCreator = (item: ActivityStreamItem): string => {
  let result = "User";

  if (item["staff"] && item["staff"]["email"]) {
    result = `${item["staff"] && item["staff"]["email"]}`;
  }
  if (item["category"] === ActionGroup.SystemAction) {
    result = "System";
  }
  return result;
};

const activityTarget = (
  item: ActivityStreamItem,
  isCompanyView?: boolean,
): React.ReactElement => {
  if ("company" in item.target && !isCompanyView) {
    const { company } = item.target;
    return <>Company: {company.name}</>;
  } else if ("ad" in item.target) {
    const ad_id = item.target.ad.id;
    return <>Ad ID: #{ad_id}</>;
  } else {
    return <></>;
  }
};

const activityAction = (item: ActivityStreamItem): React.ReactElement => {
  if ("comment" in item.action && item.action.comment.comment) {
    return <>{`"${item.action.comment.comment}"`}</>;
  } else {
    return <></>;
  }
};

const activityVerb = (item: ActivityStreamItem): string => {
  if (item.verb === ActivityStreamVerb.ChangedState) {
    const old_state = getListingStateInfo(item.data["old_state"]).label;
    const new_state = getListingStateInfo(item.data["new_state"]).label;
    return item.verb
      .replace("{old_state}", old_state)
      .replace("{new_state}", new_state);
  }
  if (item.verb === ActivityStreamVerb.SetCompanyDiscount) {
    return item.verb.replace("{percent_off}", item.data["percent_off"] + "%");
  }
  if (item.verb === ActivityStreamVerb.DepositedToCompanyAccount) {
    return item.verb
      .replace(
        "{amount}",
        formatCurrency(item.data["amount"], App.settings.currency),
      )
      .replace("{company_name}", item.data.company_name)
      .replace("{salesforce_id}", item.data.salesforce_id);
  }
  if (item.verb === ActivityStreamVerb.BlacklistedUser) {
    return item.verb
      .replace("{comment}", item.data["comment"])
      .replace("{reason}", prettifiedReason[item.data["reason"]]);
  }
  if (item.verb === ActivityStreamVerb.RemovedBlacklistOnUser) {
    return item.verb
      .replace("{comment}", item.data["comment"])
      .replace("{reason}", prettifiedReason[item.data["reason"]]);
  }
  if (item.verb === ActivityStreamVerb.RequestedPhoneNumber) {
    return item.verb.replace("{ad_id}", item.data.ad_id);
  }
  if (item.verb === ActivityStreamVerb.ChoosedTenantForAd) {
    return item.verb
      .replace("{user_id}", item.data.user_id)
      .replace("{ad_id}", item.data.ad_id);
  }
  if (item.verb === ActivityStreamVerb.ChoosenAsTenantForAd) {
    return item.verb
      .replace("{landlord_id}", item.data.landlord_id)
      .replace("{ad_id}", item.data.ad_id);
  }
  if (item.verb === ActivityStreamVerb.AddedUserIdToCompany) {
    return item.verb
      .replace("{user_name}", item.data.user_name)
      .replace("{user_id}", item.data.user_id)
      .replace("{company_name}", item.data.company_name)
      .replace("{company_id}", item.data.company_id);
  }
  if (item.verb === ActivityStreamVerb.RemovedUserIdFromCompany) {
    return item.verb
      .replace("{user_name}", item.data.user_name)
      .replace("{user_id}", item.data.user_id)
      .replace("{company_name}", item.data.company_name)
      .replace("{company_id}", item.data.company_id);
  }

  return item.verb;
};

const ActivityStream = ({
  items,
  onCreateComment,
  filterComponent,
  isCompanyView,
  isLoading,
  offset,
  setOffset,
  totalRecords,
}: ActivityStreamProps) => {
  // ==============================================================================================
  // State
  // ==============================================================================================

  const [canCreateComment, setCanCreateComment] = React.useState(true);
  const [comment, setComment] = React.useState("");
  const [updating, setUpdating] = React.useState(false);

  // ==============================================================================================
  // Callback Handlers
  // ==============================================================================================

  const handleAddCommentButtonClick = () => {
    if (comment.length < 5) {
      alert("Please write a comment");
      return;
    }

    setUpdating(true);
    setCanCreateComment(false);

    onCreateComment(comment).then(() => {
      setCanCreateComment(true);
      setUpdating(false);
      setComment("");
    });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  return (
    <>
      <TextField
        multiLine
        label="Comment"
        value={comment}
        onChange={handleCommentChange}
        disabled={!canCreateComment}
      />

      <Flex
        mt={2}
        pb={2}
        mb={2}
        borderBottom
      >
        <Flex
          flexPush
          gap
        >
          <Button
            loading={updating}
            variant="primary"
            onClick={handleAddCommentButtonClick}
          >
            Add Comment
          </Button>
        </Flex>
      </Flex>

      {filterComponent}
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <StyledTimeline>
          {items.map((item, index) => (
            <StyledTimelineItem key={item.timestamp}>
              {index < items.length - 1 && <StyledTimelineItemTail />}
              {item.category === ActionGroup.CustomerServiceAction ? (
                <StyledTimelineItemHead />
              ) : (
                <StyledTimelineItemHead2 />
              )}
              <StyledTimelineItemContent>
                <Timestamp value={item.timestamp} />
                <Box>
                  <Text
                    size="small"
                    weight="headline"
                  >
                    {activityCreator(item)}:
                  </Text>
                  <Text
                    size="small"
                    ml={1}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: activityVerb(item),
                      }}
                    ></span>
                  </Text>
                </Box>
                <div
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <Text
                    color="muted"
                    size="small"
                  >
                    {activityTarget(item, isCompanyView)}
                  </Text>
                </div>
                <div
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <Text size="small">
                    <em>{activityAction(item)}</em>
                  </Text>
                </div>
              </StyledTimelineItemContent>
            </StyledTimelineItem>
          ))}
        </StyledTimeline>
      )}
      {!!totalRecords && (
        <Paginator
          totalRecords={totalRecords || 0}
          pageLimit={activityStreamPageLimit}
          value={offset}
          nextButtonLabel={t("paginator.next")}
          previousButtonLabel={t("paginator.previous")}
          onChange={setOffset}
        />
      )}
    </>
  );
};

export { ActivityStream };
