import * as React from "react";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { Env } from "components/interfaces/settings";
import { Market } from "components/markets/market_settings";
import { ElectricityLeadMovingReports } from "./electricity/ElectricityLeadMovingReports";
import { ElectricityLeadServices } from "./electricity/services/electricityLeadService";

interface LeadsContextAPI {
  environment: Env;
}

const LeadsContext = React.createContext<LeadsContextAPI>({
  environment: "develop",
});

interface Props {
  children: React.ReactNode;
  market: Market;
  environment: Env;
}

export const LeadsProvider = ({ children, environment }: Props) => (
  <LeadsContext.Provider
    value={{
      environment,
    }}
  >
    {children}
  </LeadsContext.Provider>
);

export const useLeads = () => {
  const { environment } = React.useContext(LeadsContext);

  return {
    getElectricityLeadForMovingReports: (
      report: MovingReport,
      hasNeededFields: boolean,
    ) => (
      <ElectricityLeadMovingReports
        report={report}
        missingData={hasNeededFields}
        leadService={ElectricityLeadServices.fromMovingReports(environment)}
      />
    ),
  };
};
