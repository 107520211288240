import { Box, Flex, Text, IconMapOutline, styled } from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";

const OuterButton = styled.button`
  height: 100px;
  -webkit-appearance: none;
  width: 100%;
  background-size: cover;
  background-position: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  &:focus {
    outline: 0;
  }
`;

const Inside = styled.div`
  display: inline-block;
  background-color: white;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.3em 0.8em;
  border-radius: 3px;
`;

const DKMapButton = styled(OuterButton)`
  background-image: url("/static/images/DK.jpg");
`;

const SEMapButton = styled(OuterButton)`
  background-image: url("/static/images/SWE.jpg");
`;

const RawMapButton = ({ onClick }: { onClick: () => void }) => {
  const Outer =
    App.settings.market === Market.BOLIGPORTAL_DK ? DKMapButton : SEMapButton;

  return (
    <Outer onClick={onClick}>
      <Inside>
        <Flex>
          <IconMapOutline mt={0.5} />
          <Box ml={1}>
            <Text weight={"semiBold"}>{t("srp.map")}</Text>
          </Box>
        </Flex>
      </Inside>
    </Outer>
  );
};

export const MapButton = RawMapButton;
