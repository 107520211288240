import { useEffect } from "react";
import { IconAlertCircleOutline, Flex, Box, Text } from "@boligportal/juice";
import { useIPAddressCheckFeature } from "./hooks/use_ip_address_check_feature";

const IPAddressCheck = ({ adId }: { adId: number }) => {
  const { ipAddressInfo, getIPAddressInfo } = useIPAddressCheckFeature();

  useEffect(() => {
    getIPAddressInfo(adId);
  }, [getIPAddressInfo, adId]);

  if (!ipAddressInfo) {
    return null;
  }

  return (
    <Flex align="center">
      {!ipAddressInfo.isSafe && (
        <Box pr={0.5}>
          <IconAlertCircleOutline
            size="medium"
            color="danger"
          />
        </Box>
      )}
      <Text
        color={ipAddressInfo.isSafe ? "muted" : "danger"}
        size="tiny"
      >
        {ipAddressInfo.displayLabel}
      </Text>
    </Flex>
  );
};

export { IPAddressCheck };
