import React, { useEffect, useContext, useRef, useCallback } from "react";
import styled from "@emotion/styled";
import { useElements } from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeError,
} from "@stripe/stripe-js";
import { Plan } from "components/interfaces/plan";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { StripePaymentContext } from "features/paywall/coordinators/stripe/StripePaymentProvider";
import { fetchFromAPI } from "lib/api";
import { getApplePayLocale } from "lib/utils";

export const ApplePayButton = ({
  selectedPlan,
  onClick,
  stripe,
  paymentRequest,
  canMakeApplePay,
}: {
  stripe: Stripe | null;
  selectedPlan: Plan;
  onClick: (paymentMethodType: PaymentMethodType) => void;
  paymentRequest: PaymentRequest;
  canMakeApplePay: boolean;
}) => {
  const elements = useElements();
  const { setPaymentMethodId, setStripeError } =
    useContext(StripePaymentContext);
  const applePayButtonRef = useRef<null | HTMLElement>(null);

  const processApplePayPayment = async (
    event: PaymentRequestPaymentMethodEvent,
    stripe: Stripe,
    selectedPlan: Plan,
    setPaymentMethodId: (id: number | null) => void,
    setStripeError: (error: StripeError) => void,
  ) => {
    const {
      error: backendError,
      clientSecret,
      payment_method_id: paymentMethodId,
    } = await fetchFromAPI("/api/payments/apple-pay-link", {
      method: "POST",
      body: JSON.stringify({ plan_id: selectedPlan.plan_id }),
    }).then((res) => res.json());

    if (backendError) {
      console.error("Backend error:", backendError);
      return;
    }

    try {
      let result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: event.paymentMethod.id,
      });

      if (result.error) {
        setStripeError(result.error);
        return;
      }

      if (result.paymentIntent?.status === "requires_action") {
        result = await stripe.confirmCardPayment(clientSecret);
      }

      if (result.paymentIntent?.status === "succeeded") {
        setPaymentMethodId(paymentMethodId);
      }
    } catch (error) {
      console.error("Error confirming card payment:", error);
    } finally {
      setPaymentMethodId(paymentMethodId);
    }
  };

  useEffect(() => {
    if (!stripe || !elements || !canMakeApplePay) return;

    const handlePaymentMethod = async (event) => {
      onClick(PaymentMethodType.ApplePay);
      await processApplePayPayment(
        event,
        stripe,
        selectedPlan,
        setPaymentMethodId,
        setStripeError,
      );
      event.complete(event.paymentMethod.id ? "success" : "fail");
    };

    paymentRequest.on("paymentmethod", handlePaymentMethod);

    return () => {
      paymentRequest.off("paymentmethod", handlePaymentMethod);
    };
  }, [
    stripe,
    elements,
    selectedPlan,
    canMakeApplePay,
    onClick,
    setPaymentMethodId,
    paymentRequest,
    setStripeError,
  ]);

  const onApplePayButtonClicked = useCallback(() => {
    if (canMakeApplePay) {
      paymentRequest.show();
    }
  }, [canMakeApplePay, paymentRequest]);

  useEffect(() => {
    const applePayButton = applePayButtonRef.current;
    if (applePayButton) {
      applePayButton.addEventListener("click", onApplePayButtonClicked);
    }

    return () => {
      if (applePayButton) {
        applePayButton.removeEventListener("click", onApplePayButtonClicked);
      }
    };
  }, [onApplePayButtonClicked]);

  if (!canMakeApplePay) {
    return null;
  }

  return (
    <ApplePayButtonWrapper>
      <apple-pay-button
        buttonstyle="black"
        type="pay"
        locale={getApplePayLocale()}
        ref={applePayButtonRef}
      />
    </ApplePayButtonWrapper>
  );
};

const ApplePayButtonWrapper = styled.div`
  margin-bottom: 8px;
  apple-pay-button {
    --apple-pay-button-width: 100%;
    --apple-pay-button-padding: 13px 0;
    --apple-pay-button-height: 20px;
  }
`;
