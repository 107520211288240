import { Flex, Text } from "@boligportal/juice";
import { RentCollectionNotificationBadge } from "components/RentCollectionNotificationBadge";

export const RentMenuItem = (item: any, isOpened: boolean) => {
  const { name } = item;
  return (
    <Flex
      align="center"
      justify="space-between"
    >
      <Text>{name}</Text>
      {isOpened && <RentCollectionNotificationBadge />}
    </Flex>
  );
};
