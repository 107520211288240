import { Flex, styled } from "@boligportal/juice";
import { LocationIcon } from "components/icons/location";
import { LocationAutoCompleteOnSelect, LocationResult } from "../types";

type Props = {
  suggestion: LocationResult;
  onSelect: LocationAutoCompleteOnSelect;
  hasSelection: boolean;
};

export const SuggestionItem = ({
  suggestion,
  hasSelection,
  onSelect,
}: Props) => {
  const onSelectSetter = () => onSelect(suggestion);
  return (
    <li id={suggestion.id}>
      <StyledSuggestion
        onClick={onSelectSetter}
        hasSelection={hasSelection}
      >
        <StyledIconWrapper
          mr={2}
          align="baseline"
        >
          <LocationIcon />
        </StyledIconWrapper>
        <span>{suggestion.text}</span>
      </StyledSuggestion>
    </li>
  );
};

const StyledIconWrapper = styled(Flex)`
  color: ${(props) => props.theme.colorPalette.gray[60]};
`;

const StyledSuggestion = styled.a<{ hasSelection: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${(props) => props.theme.unit(1.5)}
    ${(props) => props.theme.unit(2.5)};
  background-color: ${(props) =>
    props.hasSelection
      ? props.theme.colorPalette.gray[30]
      : props.theme.color.bg.base};
  &:hover {
    background-color: ${(props) => props.theme.colorPalette.gray[30]};
  }
`;
