import { t } from "lib/i18n";
import { Translatable } from "lib/translations";
import { SigningStatus, SigningOrder } from "./interfaces";

export const SigningStatusTranslations = (): Translatable<SigningStatus> => ({
  [SigningStatus.AWAITING]: t("contracts.signing_status.awaiting"),
  [SigningStatus.COMPLETED]: t("contracts.signing_status.completed"),
  [SigningStatus.DRAFT]: t("contracts.signing_status.draft"),
  [SigningStatus.EXPIRED]: t("contracts.signing_status.expired"),
});

export const SigningOrderTranslations = (): Translatable<SigningOrder> => ({
  [SigningOrder.NO_ORDER]: t("contracts.finalize.signing_order.no_order"),
  [SigningOrder.LANDLORD_SIGNS_LAST]: t(
    "contracts.finalize.signing_order.landlord_signs_last",
  ),
});
