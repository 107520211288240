import { t } from "lib/i18n";

export type SellingPointId =
  | "RENTABLES"
  | "ADS"
  | "CONTRACTS"
  | "MOVING_REPORTS"
  | "SUPPORT";

export const getSaaSSellingPoints = (...order: SellingPointId[]) => {
  const sellingPoints = [
    {
      group: {
        name: t("saas_plan.comparison_table.section_2.headline"),
        id: "RENTABLES" as SellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.section_2.feature_1"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.section_3.headline"),
        id: "ADS" as SellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.section_3.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_3.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_3.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_3.feature_4"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.section_4.headline"),
        id: "CONTRACTS" as SellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.section_4.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_4"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_5"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_6"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_7"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_8"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_9"),
          free: true,
          pro: true,
        },

        {
          name: t("saas_plan.comparison_table.section_4.feature_11"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_12"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_13"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_14"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_15"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.section_4.feature_16"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.section.moving_reports.headline"),
        id: "MOVING_REPORTS" as SellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_4"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_5"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_6"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_7"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_8"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_9"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_10"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_11"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_12"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_13"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_14"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.moving_reports.feature_15"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.section_5.headline"),
        id: "SUPPORT" as SellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.section_5.feature_1"),
          free: true,
          pro: true,
        },
      ],
    },
  ];

  return sellingPoints.sort((entryA, entryB) =>
    order.indexOf(entryA.group.id) > order.indexOf(entryB.group.id) ? -1 : 1,
  );
};

export type SwedishSellingPointId =
  | "CRM"
  | "ADS"
  | "COMMUNICATION"
  | "CONTRACTS"
  | "MOVING_REPORTS"
  | "SUPPORT";

export const getSwedishSaaSSellingPoints = (
  ...order: SwedishSellingPointId[]
) => {
  const sellingPoints = [
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_1.headline"),
        id: "CRM" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_4"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_5"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_1.feature_6"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_2.headline"),
        id: "ADS" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_2.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_2.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_2.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_2.feature_4"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_3.headline"),
        id: "COMMUNICATION" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_3.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_3.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_3.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_3.feature_4"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_4.headline"),
        id: "CONTRACTS" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_4"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_5"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_4.feature_6"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_5.headline"),
        id: "MOVING_REPORTS" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_1"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_2"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_3"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_4"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_5"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_6"),
          free: true,
          pro: true,
        },
        {
          name: t("saas_plan.comparison_table.se.section_5.feature_7"),
          free: true,
          pro: true,
        },
      ],
    },
    {
      group: {
        name: t("saas_plan.comparison_table.se.section_6.headline"),
        id: "SUPPORT" as SwedishSellingPointId,
      },
      features: [
        {
          name: t("saas_plan.comparison_table.se.section_6.feature_1"),
          free: true,
          pro: true,
        },
      ],
    },
  ];

  return sellingPoints.sort((entryA, entryB) =>
    order.indexOf(entryA.group.id) > order.indexOf(entryB.group.id) ? -1 : 1,
  );
};
