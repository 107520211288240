/* eslint-disable comma-dangle */
import { ContractRoutes } from "apps/contracts/routes";
import { RentableId } from "business/domain/Rentable";
import { App } from "components/app";

const rentableUpsellPage = (rentableId: RentableId) =>
  App.settings.routes.property_owner.my_rentables_detail_upsell.replace(
    "rentable_id",
    rentableId.toString(),
  );

const createListingPage = (rentableId: RentableId) =>
  App.settings.routes.property_owner.create_listing_fill_form.replace(
    "rentable_id",
    rentableId.toString(),
  );

const createListingVideoPage = (rentableId: RentableId) =>
  App.settings.routes.property_owner.create_listing_video.replace(
    "rentable_id",
    rentableId.toString(),
  );

const createListingUpdateProfilePage = (rentableId: RentableId) =>
  App.settings.routes.property_owner.create_listing_update_profile_view.replace(
    "rentable_id",
    rentableId.toString(),
  );

const contractChooseRentablePage = () =>
  // Replace double slash caused by contracts base route having trailing slash and choose rentable route having leading slash
  `${App.settings.routes.contracts + ContractRoutes.CHOOSE_RENTABLE}`.replace(
    "//",
    "/",
  );

const contractCreateRentablePage = () =>
  `${App.settings.routes.contracts + ContractRoutes.CREATE_RENTABLE}`.replace(
    "//",
    "/",
  );

const readvertiseListing = (rentableId: RentableId, listingId: number) =>
  App.settings.routes.property_owner.create_listing_fill_form.replace(
    "rentable_id",
    `${rentableId}/re-listing/${listingId}`,
  );

const createListingFromDraft = (rentableId: RentableId, listingId: number) =>
  App.settings.routes.property_owner.create_listing_fill_form.replace(
    "rentable_id",
    `${rentableId}/new-from-draft/${listingId}`,
  );

const RouteUtil = {
  createListingPage,
  createListingVideoPage,
  createListingUpdateProfilePage,
  rentableUpsellPage,
  contractChooseRentablePage,
  contractCreateRentablePage,
  readvertiseListing,
  createListingFromDraft,
};

export default RouteUtil;
