import { useContext } from "react";
import { MarketSpecificContext } from "../context/MarketSpecificContext";

const useExternalAddressWebsites = () => {
  const { externalAddressWebsites } = useContext(MarketSpecificContext);
  return {
    externalAddressWebsites,
  };
};

export { useExternalAddressWebsites };
