import { useState } from "react";
import { useUser } from "components/Providers/UserProvider";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { isNotEligible } from "features/upsell/helpers";
import { t } from "lib/i18n";
import { getBenefitsAfterCreateAdFlow } from "lib/product";
import { DisabledUpsellProduct } from "./DisabledUpsellProduct";
import { UpsellProduct } from "./UpsellProduct";

type UpsellContainerProps = {
  plans: Plan[];
  selectPlan: (plan: Plan) => void;
  activeUpsellSubscriptions: AdSubscription[];
  isAdLessThan24HoursOld: boolean;
};

export const UpsellContainer = ({
  plans,
  selectPlan,
  activeUpsellSubscriptions,
  isAdLessThan24HoursOld,
}: UpsellContainerProps) => {
  const { isPartOfACompany } = useUser();
  const { user } = useUser();
  const [selectedPlan, setSelectedPlan] = useState(0);

  const hasMultiplePlans = plans.length > 1;

  const activeSubscrtiption = activeUpsellSubscriptions.find(
    (subscription) => subscription.plan.product.name === plans[0].product.name,
  );

  const isDisabled = isNotEligible(
    activeUpsellSubscriptions,
    plans[selectedPlan],
  );

  return activeSubscrtiption ? (
    <DisabledUpsellProduct
      activeUpsellSubscriptions={activeSubscrtiption}
      benefits={getBenefitsAfterCreateAdFlow(
        activeUpsellSubscriptions[0].plan.product,
        isAdLessThan24HoursOld,
        isPartOfACompany,
      )}
      badgeText={
        !user?.is_private_landlord
          ? plans[0].product.name === "exposure_ad"
            ? t("upsell.dialog.badge.best_value")
            : ""
          : plans[0].product.name === "promote_ad"
            ? t("upsell.dialog.badge.best_value")
            : ""
      }
      hasMultiplePlans={hasMultiplePlans}
      isDisabled={isDisabled}
    />
  ) : (
    <UpsellProduct
      activeUpsellSubscriptions={activeUpsellSubscriptions}
      benefits={getBenefitsAfterCreateAdFlow(
        plans[selectedPlan].product,
        isAdLessThan24HoursOld,
        isPartOfACompany,
      )}
      badgeText={
        !user?.is_private_landlord
          ? plans[0].product.name === "exposure_ad"
            ? t("upsell.dialog.badge.best_value")
            : ""
          : plans[0].product.name === "promote_ad"
            ? t("upsell.dialog.badge.best_value")
            : ""
      }
      plans={plans}
      selectedPlan={selectedPlan}
      isDisabled={isDisabled}
      onClick={() => selectPlan(plans[selectedPlan])}
      onSelectPlan={setSelectedPlan}
    />
  );
};
