import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  IconAlertCircleOutline,
  IconCheckmarkCircle,
  Notice,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

const SuccessDialog = ({
  onClose,
  text,
}: {
  onClose: () => void;
  text: string;
}) => (
  <Dialog
    open
    onClose={onClose}
  >
    <DialogHeader>
      <IconCheckmarkCircle
        inline
        color="success"
        mr={1}
        size="large"
      />
      {t("leads.common.dialog.subscribed.title")}
    </DialogHeader>

    <DialogContent>
      <Box>
        <Text
          block
          weight="semiBold"
          mb={3}
        >
          {text}
        </Text>

        <Notice
          icon={<IconAlertCircleOutline size="medium" />}
          type="info"
        >
          {t("leads.common.dialog.subscribed.hand_over_device_to_landlord")}
        </Notice>
      </Box>
    </DialogContent>

    <DialogFooter>
      <Button
        fullWidth
        variant="primary"
        onClick={onClose}
      >
        {t("leads.common.dialog.subscribed.confirm_button")}
      </Button>
    </DialogFooter>
  </Dialog>
);

export { SuccessDialog };
