import {
  TenancyBasePayload,
  TenancyTenantPayload,
  TenancyBaseDiff,
  TenancyTenantDiff,
  DiffSelection,
  BeforeAfterItemType,
  TenantBeforeAfterItemType,
} from "./types";

export const makeTenancyBasePayloadFrom = (diff: TenancyBaseDiff) => {
  const payload: TenancyBasePayload = {};

  if (diff.deposit?.isChecked) {
    payload.deposit = diff.deposit.after;
  }

  if (diff.prepaid_rent?.isChecked) {
    payload.prepaid_rent = diff.prepaid_rent.after;
  }

  if (diff.move_in_date?.isChecked) {
    payload.move_in_date = diff.move_in_date.after;
  }

  if (diff.move_out_date?.isChecked) {
    payload.move_out_date = diff.move_out_date.after;
  }

  return payload;
};

export const makeTenancyTenantPayloadFrom = (
  tenancy_tenants_diff: TenancyTenantDiff[] | null,
): Partial<TenancyTenantPayload>[] | undefined => {
  const result: Partial<TenancyTenantPayload>[] = [];

  tenancy_tenants_diff?.forEach((tenantDiff) => {
    const { id, ...rest } = tenantDiff;
    const entries = Object.entries(rest);

    const candidate = {
      id: id ?? null,
    };
    const filteredEntries = entries.filter((entry) => entry[1].isChecked);

    filteredEntries.forEach((entry) => {
      const [field, value] = entry;
      candidate[field] = value.after;
    });

    result.push(candidate);
  });

  return result;
};

export const getTenancyBaseDiffSelectionFrom = (
  base: TenancyBaseDiff,
): DiffSelection => {
  const items = Object.values(base);
  const filtered = items.filter((item) => item?.isChecked);

  if (filtered.length === 0) {
    return "none";
  }

  if (filtered.length === items.length) {
    return "all";
  }

  return "some";
};

export const getTenancyTenantsDiffSelectionFrom = (
  tenants: TenancyTenantDiff[],
): DiffSelection => {
  const filteredTenants = tenants?.map((tenantDiff) => {
    const { id, ...rest } = tenantDiff;
    const items = Object.values(rest);
    const filtered = items.filter((item) => item.isChecked);

    if (filtered.length === 0) {
      return "none";
    }

    if (filtered.length === items.length) {
      return "all";
    }

    return "some";
  });

  if (filteredTenants.every((item) => item === "none")) {
    return "none";
  }

  if (filteredTenants.every((item) => item === "all")) {
    return "all";
  }

  return "some";
};

export const makeAllBaseFieldsChecked = (
  candidate: TenancyBaseDiff | null,
  isChecked: boolean,
) => {
  const result: Partial<TenancyBaseDiff> = {};

  if (candidate?.deposit) {
    result.deposit = {
      before: candidate?.deposit.before,
      after: candidate?.deposit.after,
      isChecked,
    };
  }

  if (candidate?.prepaid_rent) {
    result.prepaid_rent = {
      before: candidate?.prepaid_rent.before,
      after: candidate?.prepaid_rent.after,
      isChecked,
    };
  }

  if (candidate?.move_in_date) {
    result.move_in_date = {
      before: candidate.move_in_date.before,
      after: candidate.move_in_date.after,
      isChecked,
    };
  }

  if (candidate?.move_out_date) {
    result.move_out_date = {
      before: candidate.move_out_date.before,
      after: candidate.move_out_date.after,
      isChecked,
    };
  }

  return result;
};

export const makeAllTenantFieldsChecked = (
  candidates: Partial<TenancyTenantDiff>[] | null,
  isChecked: boolean,
) => {
  const newTenants: Partial<TenancyTenantDiff>[] = [];

  candidates?.forEach((tenant) => {
    const { id, ...rest } = tenant;
    const entries = Object.entries(rest);

    const withChecked = {};

    entries.forEach((entry) => {
      const [field, value] = entry;
      withChecked[field] = {
        ...value,
        isChecked,
      };
    });

    newTenants.push({
      id,
      ...withChecked,
    });
  });

  return newTenants;
};

export const makeNewStateForTenancyBaseDiff = (
  current: TenancyBaseDiff,
  item: BeforeAfterItemType,
  isChecked: boolean,
) => {
  const { field, label, ...rest } = item;
  return {
    ...current,
    [field]: {
      ...rest,
      isChecked,
    },
  };
};

export const makeNewStateForTenancyTenantsDiff = (
  current: TenancyTenantDiff[],
  item: TenantBeforeAfterItemType,
  isChecked: boolean,
) => {
  const mutatableTenants = [...current];
  const mutatableTenant = {
    ...mutatableTenants[item.index],
  };
  mutatableTenant[item.field].isChecked = isChecked;
  return mutatableTenants;
};
