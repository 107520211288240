import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useAutoScrollIntoView } from "apps/moving_reports/hooks/useAutoScrollIntoView";
import { useBrowserFeatureCheck } from "apps/moving_reports/hooks/useBrowserFeatureCheck";
import { useFormAutosave } from "apps/moving_reports/hooks/useFormAutosave";
import {
  useRoomIdFromParams,
  useRentableIdFromParams,
  useMovingReportIdFromParams,
} from "apps/moving_reports/hooks/useIdFromParams";
import { useReportSteps } from "apps/moving_reports/hooks/useReportSteps";
import { useResponseErrorsNotification } from "apps/moving_reports/hooks/useResponseErrorsNotification";
import { useSaveOnLeave } from "apps/moving_reports/hooks/useSaveOnLeave";
import { useSkipInitialEffect } from "apps/moving_reports/hooks/useSkipInitialEffect";
import { AppDispatch, RootState } from "./store";

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
  useAppDispatch,
  useAppSelector,
  useAutoScrollIntoView,
  useBrowserFeatureCheck,
  useFormAutosave,
  useRoomIdFromParams,
  useRentableIdFromParams,
  useMovingReportIdFromParams,
  useReportSteps,
  useResponseErrorsNotification,
  useSaveOnLeave,
  useSkipInitialEffect,
};
