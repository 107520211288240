import { AddressCandidate } from "features/address/common/interfaces/AddressCandidate";

export const transformDataToAddressCandidates = (
  data: Array<any>,
): Array<AddressCandidate> => {
  const matches: Array<AddressCandidate> = [];

  data.forEach((item, index) => {
    if (item.type) {
      matches.push({
        id: item.id ?? `${index}`,
        candidateAddress: item.address,
        candidateId: item.id,
        isAddress: item.type === "adresse",
      });
    }
  });

  return matches;
};
