import { Box, Text } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";

export const DialogLeadIntro = ({
  description,
  introImage,
}: {
  description?: string;
  introImage?: string;
}) => {
  if (!description || !introImage) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center align-middle">
      <Box
        minHeight={"50px"}
        mb={3}
      >
        <CdnImage
          src={introImage}
          alt="intro image"
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Text
        block
        mb={3}
        markdown
      >
        {description}
      </Text>
    </div>
  );
};
