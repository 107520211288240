import * as React from "react";
import { AppProps } from "components/interfaces/app_props";
import { User } from "components/interfaces/user";

type ReloadAppProps = () => Promise<AppProps>;
type UpdatePageProps = (pageProps: object, callback?: () => void) => void;
type UpdateUserProps = (
  userProps: Partial<User>,
  callback?: () => void,
) => void;
type ReplaceUser = (user: User) => void;

export type AppActions = {
  reloadAppProps: ReloadAppProps;
  updatePageProps: UpdatePageProps;
  updateUserProps: UpdateUserProps;
  replaceUser: ReplaceUser;
};

const AppActionsContext = React.createContext<AppActions>(null!);

type Props = {
  children: React.ReactNode;
  actions: AppActions;
};

export const AppActionsProvider = ({ children, actions }: Props) => (
  <AppActionsContext.Provider value={actions}>
    {children}
  </AppActionsContext.Provider>
);

export const useAppActions = () => {
  const context = React.useContext(AppActionsContext);

  if (!context) {
    throw "useAppActions hook can only be used inside function component as a Child of AppActionsProvider";
  }

  return context;
};
