import { fetchFromAPI } from "lib/api";

const getIPAddressInfoService = async (
  adId: number,
): Promise<{
  ip: string | null;
  country: string | null;
  city: string | null;
  is_safe: boolean | null;
  is_boligportal: boolean | null;
}> => {
  const response = await fetchFromAPI(
    `/customer-service/api/ad/${adId}/ip-check`,
  );
  const data = await response.json();

  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return Promise.resolve(data);
};

export { getIPAddressInfoService };
