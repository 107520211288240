import { useState } from "react";
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  EmptyBox,
  Flex,
  IconAdd,
  Text,
} from "@boligportal/juice";
import { vestResolver } from "@hookform/resolvers/vest";
import { useCreateTenancyPaymentMutation } from "api/mutations";
import { TenancyPaymentTransactionEntity } from "business/domain/tenancy/TenancyPaymentTransaction";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { CurrencyAmountLabel } from "components/juice-candidates/CurrencyAmountLabel";
import { format } from "date-fns";
import { t } from "lib/i18n";
import { PaymentRecordChangeStatusConfirmation } from "../PaymentRecordChangeStatusConfirmation";
import { AddTenantPaymentDialogForm } from "./AddTenantPaymentDialogForm";
import { TransactionLine } from "./TransactionLine";
import { partialPaymentValidationSuite } from "./partialPaymentValidationSuite";

type Props = {
  open: boolean;
  onClose: () => void;
  arrears?: number;
  tenancyPaymentRecordId: number;
  transactions: TenancyPaymentTransactionEntity;
  rentAmount: number;
  paymentType: TenancyPaymentType;
};

export type AddPaymentForm = {
  accounting_date: string;
  amount: number;
};

export type PartialPaymentForm = {
  partial_payments: AddPaymentForm[];
};

export const AddTenantPaymentDialog = ({
  open,
  onClose,
  tenancyPaymentRecordId,
  arrears = 0,
  transactions,
  rentAmount,
  paymentType,
}: Props) => {
  const amountToPay = arrears ? arrears * -1 : 0;
  const { mutate: createPayment, isLoading: isCreatingPayment } =
    useCreateTenancyPaymentMutation(onClose);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const allFormMethods = useForm<PartialPaymentForm>({
    resolver: vestResolver(partialPaymentValidationSuite),
    mode: "onSubmit",
    defaultValues: {
      partial_payments: [
        {
          accounting_date: format(Date.now(), "yyyy-MM-dd"),
          amount: amountToPay,
        },
      ],
    },
  });
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = allFormMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "partial_payments",
  });

  const formValues = useWatch({
    control,
  });

  const watchedFields = watch("partial_payments");

  const watchedFieldsTotal = () => {
    if (!formValues.partial_payments) {
      return 0;
    }
    const total = formValues.partial_payments.reduce(
      (acc, current) => acc + (current.amount || 0),
      0,
    );
    return total;
  };

  const onSubmit: SubmitHandler<PartialPaymentForm> = (data) => {
    createPayment({
      paymentRecordId: tenancyPaymentRecordId,
      data: {
        transactions: data.partial_payments,
      },
    });
  };

  const totalPayment = transactions.items.reduce(
    (acc, transaction) => acc + transaction.amount,
    0,
  );
  const paymentHasTransactions = transactions.items.length > 0;

  const getDialogText = () => {
    if (arrears < 0) {
      return t(
        "payment.status_change.add_payment_dialog.negative_arrears.markdown",
      );
    } else if (arrears > 0) {
      return t(
        "payment.status_change.add_payment_dialog.positive_arrears.markdown",
      );
    } else {
      return t(
        "payment.status_change.add_payment_dialog.from_paid_to_unpaid.markdown",
      );
    }
  };

  const onSubmitHandler: SubmitHandler<PartialPaymentForm> = (data) => {
    if (paymentType === TenancyPaymentType.MANUAL_BANK_TRANSFER) {
      onSubmit(data);
    } else {
      setShowConfirmationDialog(true);
    }
  };

  return (
    <>
      {showConfirmationDialog && (
        <PaymentRecordChangeStatusConfirmation
          open
          title={t("payment.status_change.paid.title")}
          message={t("payment.status_change.paid.message_new", {
            paymentType:
              paymentType === TenancyPaymentType.MOBILEPAY
                ? t("common.mobilepay")
                : t("common.betalings_service"),
          })}
          cancelText={t("payment.status_change.paid.cancel_text")}
          confirmText={t("payment.status_change.paid.confirm_text")}
          checkFieldLabel={t(
            "payment.status_change.paid.check_field_label_new",
            {
              paymentType:
                paymentType === TenancyPaymentType.MOBILEPAY
                  ? t("common.mobilepay")
                  : t("common.betalings_service"),
            },
          )}
          checkFieldErrorText={t(
            "payment.status_change.paid.check_field_error_text",
          )}
          checkFieldDisclaimer={t(
            "payment.status_change.paid.check_field_disclaimer_text",
          )}
          onCancel={() => setShowConfirmationDialog(false)}
          paymentProvider={paymentType}
          onConfirm={handleSubmit(onSubmit)}
        />
      )}
      <Dialog
        open={open}
        onClose={onClose}
        hideOnClickOutside={showConfirmationDialog}
        dismissable={!showConfirmationDialog}
      >
        <form
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <DialogHeader>
            {t("payment.status_change.add_payment_dialog.title")}
          </DialogHeader>
          <DialogContent>
            <Text
              block
              mb={1}
              markdown
            >
              {getDialogText()}
            </Text>
            <Text
              block
              mb={2}
              color="muted"
            ></Text>
            {fields.map((field, index) => (
              <AddTenantPaymentDialogForm
                key={field.id}
                control={control}
                errors={errors}
                index={index}
                register={register}
                remove={remove}
              />
            ))}
            <EmptyBox
              mb={2}
              p={3}
            >
              <Button
                onClick={() =>
                  append({
                    accounting_date: format(Date.now(), "yyyy-MM-dd"),
                    amount: amountToPay,
                  })
                }
                variant="subtle"
                icon={IconAdd}
              >
                {t("partial_payments.add_payment")}
              </Button>
            </EmptyBox>

            <Divider
              mx={{
                xs: -2,
                md: -3,
              }}
              my={3}
            />

            {transactions.items.map((transaction) => (
              <TransactionLine
                key={transaction.id}
                amount={transaction.amount}
                paymentDate={transaction.accounting_date}
              />
            ))}
            {watchedFields.map((field, index) => (
              <TransactionLine
                key={index}
                amount={field.amount ?? null}
                paymentDate={field.accounting_date ?? null}
              />
            ))}

            {(paymentHasTransactions || watchedFieldsTotal() !== 0) && (
              <>
                <Flex
                  justify="end"
                  mt={2}
                >
                  <CurrencyAmountLabel
                    label={`${t("partial_payments.payments_sum")}:`}
                    amount={totalPayment + watchedFieldsTotal()}
                    size="body"
                    labelWeight="base"
                    emphasizeAmount="headline"
                    amountSize="lead"
                  />
                </Flex>

                <Divider my={3} />

                <CurrencyAmountLabel
                  label={`${t("partial_payments.rent_sum")}:`}
                  amount={rentAmount}
                  size="body"
                  labelWeight="base"
                  emphasizeAmount="headline"
                  amountSize="lead"
                />

                <Divider my={3} />

                <CurrencyAmountLabel
                  label={
                    arrears + watchedFieldsTotal() > 0
                      ? `${t("partial_payments.paid_too_much")}:`
                      : `${t("partial_payments.not_paid_yet")}:`
                  }
                  amount={arrears + watchedFieldsTotal()}
                  size="body"
                  labelWeight="base"
                  emphasizeAmount="headline"
                  amountSize="lead"
                />
              </>
            )}
          </DialogContent>
        </form>
        <DialogFooter>
          <Flex gap>
            <Button
              onClick={onClose}
              variant="subtle"
            >
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              loading={isCreatingPayment}
              disabled={isCreatingPayment}
              onClick={handleSubmit(onSubmitHandler)}
              variant="primary"
            >
              {t("common.save")}
            </Button>
          </Flex>
        </DialogFooter>
      </Dialog>
    </>
  );
};
