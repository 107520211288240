import { Flex, Headline, IconCheckmarkCircle } from "@boligportal/juice";
import { css } from "@emotion/react";
import { t } from "lib/i18n";

export const CheckmarkAndHeader = () => (
  <Flex
    column
    align="center"
  >
    <IconCheckmarkCircle
      color="success"
      css={css`
        font-size: 32px;
      `}
    />
    <Headline as="h3">{t("contracts.signing.signed")}</Headline>
  </Flex>
);
