import * as React from "react";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { createExternalAddressWebsites } from "../factory/createExternalAddressWebsites";
import { createExternalPhoneWebsites } from "../factory/createExternalPhoneWebsites";
import { ExternalLookupable } from "../interfaces/ExternalLookupable";

export const MarketSpecificContext = React.createContext<{
  externalAddressWebsites: ExternalLookupable[];
  externalPhoneWebsites: ExternalLookupable[];
}>({
  externalAddressWebsites: [],
  externalPhoneWebsites: [],
});

type Props = {
  children: React.ReactNode;
};

export const MarketSpecificProvider = ({ children }: Props) => {
  const { customerService } = useMarketSettings();
  return (
    <MarketSpecificContext.Provider
      value={{
        externalAddressWebsites: createExternalAddressWebsites(
          customerService.externalAddressWebsites,
        ),
        externalPhoneWebsites: createExternalPhoneWebsites(
          customerService.externalPhoneWebsites,
        ),
      }}
    >
      {children}
    </MarketSpecificContext.Provider>
  );
};
