import { Flex, Button, Box, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { Slide } from "./Slide";
import { StyledIndicator } from "./SlideshowContent";

export const StyledInvisibleBox = styled(Box)<{ hide: boolean }>`
  & * {
    visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
  }
`;

interface Props {
  currentSlide: number;
  filteredSlides: Slide[];
  onIndexChange: (index: number) => void;
  handleNextClick: () => void;
  isLastSlide: boolean;
  handleOnSkip: () => void;
}

export const OnboardingButtons = ({
  currentSlide,
  filteredSlides,
  handleNextClick,
  isLastSlide,
  onIndexChange,
  handleOnSkip,
}: Props) => (
  <Flex
    justify="space-between"
    align="center"
    px={3}
    py={2}
    hidden={{
      xs: true,
      md: false,
    }}
  >
    <StyledInvisibleBox
      order={1}
      hide={isLastSlide}
    >
      <Button
        testId="onboardingSkipButton"
        onClick={handleOnSkip}
        variant="subtle"
      >
        {t("onboarding.dialog_button.skip")}
      </Button>
    </StyledInvisibleBox>

    <Flex order={2}>
      {filteredSlides.map((slide, index) => (
        <StyledIndicator
          key={slide.slide}
          onClick={() => onIndexChange(index)}
          active={index === currentSlide}
        />
      ))}
    </Flex>

    <Box order={3}>
      <Button
        onClick={handleNextClick}
        variant="branded"
      >
        {currentSlide === filteredSlides.length - 1
          ? t("onboarding.dialog_button.confirm")
          : t("onboarding.dialog_button.next")}
      </Button>
    </Box>
  </Flex>
);
