import { Box } from "@boligportal/juice";
import { t } from "../../lib/i18n";
import { useUser } from "../Providers/UserProvider";
import { Alert } from "../alert";

export type AgentAlertState = "created" | "deleted" | null;

export const AgentAlert = ({ state }: { state: AgentAlertState }) => {
  const { user } = useUser();
  if (state === null) {
    return null;
  }

  if (state === "created" && user) {
    return (
      <Box mt={2}>
        <Alert key={state}>
          {t("create_agent_button.success", {
            email: user.email,
          })}
        </Alert>
      </Box>
    );
  } else if (state === "deleted") {
    return (
      <Box mt={2}>
        <Alert key={state}>
          {t("create_agent_button.remove_agent_success")}
        </Alert>
      </Box>
    );
  } else {
    return null;
  }
};
