import * as React from "react";
import { Box, Flex, Text, Container, styled } from "@boligportal/juice";

type Props = {
  headline: string;
  text: string;
  ctaButton: React.ReactNode;
  imageSrc?: string;
  children?: React.ReactNode;
};

const StyledImg = styled.img`
  object-fit: contain;
`;

export const EmptyState = ({
  headline,
  text,
  ctaButton,
  imageSrc,
  children,
}: Props) => (
  <Container>
    <Flex
      column
      align="center"
      justify="center"
    >
      <Box
        width="100%"
        maxWidth="400px"
        textAlign="center"
      >
        <Box my={5}>
          {children}
          {imageSrc && (
            <StyledImg
              width={"75%"}
              src={imageSrc}
              alt={`empty state image for ${headline}`}
            />
          )}
        </Box>
        <Box mb={2}>
          <Text
            as="h2"
            size="h2"
          >
            {headline}
          </Text>
        </Box>
        <Box mb={3}>
          <Text color="muted">{text}</Text>
        </Box>
        <Box mb={2}>{ctaButton}</Box>
      </Box>
    </Flex>
  </Container>
);
