import { useParams } from "react-router-dom";
import { CompanyDetailsContextProvider } from "apps/customer_service/pages/user_detail_page/company_details_view/company_details_context";
import { CompanyDetailsView } from "apps/customer_service/pages/user_detail_page/company_details_view/company_details_view";

export const CompanyDetailPage = () => {
  const { id: companyId } = useParams<{ id: string }>();

  return (
    <CompanyDetailsContextProvider>
      <CompanyDetailsView
        companyId={companyId ? Number(companyId) : undefined}
      />
    </CompanyDetailsContextProvider>
  );
};
