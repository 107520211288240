/* eslint-disable comma-dangle */
import { useReducer, useState, useEffect, useCallback } from "react";
import { useSidebarLayout } from "components/sidebar_layout";
import { useRentableEnergyLabelRetriever } from "features/rentable/useRentableEnergyLabelRetriever";
import { UpsellDialogTrigger } from "features/upsell/components/RentableUpsellDialog/rentableDialogTrigger";
import { DomutechSource } from "leads/Domutech/DomuTechDialog";
import RouteUtil from "lib/routes";
import { RentalAdViewQueryParamsForAdStateEnum } from "./enums/RentalAdViewQueryParamsForAdStateEnum";
import { convertAdStateEnumToArrayOfStrings } from "./helpers/convertAdStateEnumToArrayOfStrings";
import { createInitialRentalAdsState } from "./helpers/createInitialRentalAdsState";
import { createRentalAdViewModels } from "./helpers/createRentalAdViewModels";
import { usePersistStateInUrl } from "./hooks/usePersistStateInUrl";
import { RentalAdViewModel } from "./interfaces/RentalAdViewModel";
import {
  RentalAdsFeature,
  UpdateActiveSortableColumn,
  UpdateAdState,
  UpdatePaginationStartIndex,
  UpdateSearchValue,
  MarkAsActive,
  ShowUpsellDialog,
  CloseUpsellDialog,
} from "./interfaces/RentalAdsFeature";
import { RentalAdsState } from "./interfaces/RentalAdsState";
import { RentalAdsReducerType } from "./reducer/RentalAdsReducerType";
import { getRentalAdsAsync } from "./services/getRentalAds";

let prevAdState: RentalAdViewQueryParamsForAdStateEnum;

interface UseRentalAdsReturn {
  feature: RentalAdsFeature;
  state: RentalAdsState;
  showUpsellForRentableId: number | null;
  isRetrivingEnergyLabel: boolean;
  trigger: UpsellDialogTrigger;
  visiblePlans?: string[];
}

export const useRentalAds = (
  reducer: RentalAdsReducerType,
  isAdDetails?: boolean,
): UseRentalAdsReturn => {
  const { scrollToTop } = useSidebarLayout();
  const [state, dispatch] = useReducer(reducer, createInitialRentalAdsState());
  const [showUpsellForRentableId, setShowUpsellForRentableId] = useState<
    number | null
  >(null);
  const { isRetrivingEnergyLabel, setRentableEnergyLabelCandidate } =
    useRentableEnergyLabelRetriever();

  const [visiblePlans, setVisiblePlans] = useState<string[]>();
  const [trigger, setTrigger] = useState<UpsellDialogTrigger>(
    "list of rentable link",
  );

  const sorting = state.activeSortableColumn.column;
  const { descending } = state.activeSortableColumn;
  const { limit } = state;
  const search_term = state.searchValue;
  const { ad_state } = state;
  const start_index =
    prevAdState && prevAdState !== ad_state ? 0 : state.start_index;

  usePersistStateInUrl({
    sorting,
    descending,
    limit,
    start_index,
    search_term,
    ad_state,
    isAdDetails,
  });

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop, start_index]);

  const loadRentalAds = useCallback(async () => {
    const response = await getRentalAdsAsync({
      sorting,
      descending,
      limit,
      start_index,
      search_term,
      ad_state: convertAdStateEnumToArrayOfStrings(ad_state),
    });

    const rentalAds = createRentalAdViewModels(response, {
      onDisplayEnergyRatingRetriever: (rentableId: number, address: string) => {
        setRentableEnergyLabelCandidate({
          address,
          rentableId,
          onSuccess: loadRentalAds,
          source: DomutechSource.ADVERTISED_RENTABLES,
        });
      },
    });

    dispatch({
      type: "UPDATE_RENTABLE_ADS",
      rentalAds,
      advertisedCount: response.advertised_count,
      allCount: response.all_count,
      filterCount: response.filter_count,
      rentedOutCount: response.rented_out_count,
      draftCount: response.draft_count,
      reservedCount: response.reserved_count,
      start_index,
    });

    prevAdState = ad_state;
  }, [search_term, sorting, descending, start_index, limit, ad_state]);

  const updateActiveSortableColumn: UpdateActiveSortableColumn = (
    fieldName: keyof RentalAdViewModel,
    descending: boolean,
  ) => {
    dispatch({
      type: "UPDATE_ACTIVE_SORTABLE_COLUMN",
      fieldName,
      descending,
    });
  };

  const updatePaginationStartIndex: UpdatePaginationStartIndex = (
    newStartIndex: number,
  ) => {
    dispatch({
      type: "UPDATE_PAGINATION_START_INDEX",
      newStartIndex,
    });
  };

  const updateSearchValue: UpdateSearchValue = (newSearchValue: string) => {
    dispatch({
      type: "UPDATE_SEARCH_VALUE",
      newSearchValue,
    });
  };

  const updateAdState: UpdateAdState = (
    newAdState: RentalAdViewQueryParamsForAdStateEnum,
  ) => {
    dispatch({
      type: "UPDATE_AD_STATE",
      newAdState,
    });
  };

  const markAsActive: MarkAsActive = async (
    rentableId: number,
    listingId: number,
  ) => {
    try {
      window.location.href = RouteUtil.readvertiseListing(
        rentableId,
        listingId,
      );
    } catch {
      console.warn("Something went wrong"); //eslint-disable-line
    }
  };

  const showUpsellDialog: ShowUpsellDialog = (
    rentableId: number,
    trigger: UpsellDialogTrigger,
    visiblePlans?: string[],
  ) => {
    if (visiblePlans) {
      setVisiblePlans(visiblePlans);
    } else {
      setVisiblePlans(undefined);
    }

    setTrigger(trigger);

    setShowUpsellForRentableId(rentableId);
  };

  const closeUpsellDialog: CloseUpsellDialog = () => {
    setShowUpsellForRentableId(null);
  };

  return {
    state,
    showUpsellForRentableId,
    isRetrivingEnergyLabel,
    visiblePlans,
    trigger,
    feature: {
      loadRentalAds,
      updateActiveSortableColumn,
      updatePaginationStartIndex,
      updateSearchValue,
      updateAdState,
      markAsActive,
      showUpsellDialog,
      closeUpsellDialog,
    },
  };
};
