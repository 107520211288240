import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  useTheme,
} from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { Profile, USER_TYPE } from "components/interfaces/profile";
import { ModalActions } from "components/modal_controller";
import { Step } from "components/steppedView/step";
import { SteppedView } from "components/steppedView/steppedView";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { withModalActions } from "lib/injectors";
import { AgentSetup } from "./forms/agent_setup";
import { ContactedInfo } from "./forms/contacted_info";
import { Description } from "./forms/description";
import { PersonalInformation } from "./forms/personal_information";
import { Preferences } from "./forms/preferences";
import { ProfilePictureUpload } from "./forms/profile_picture_upload";
import {
  convertProfileToFormData,
  convertFormDataToProfile,
  ProfileFormData,
} from "./helpers/profile_helpers";

type DialogViews = "PROFILE" | "AGENT" | "CONTACTED";

type VisibleSteps = {
  showPersonalInformationForm: boolean;
  showProfilePictureUploadForm: boolean;
  showPreferencesForm: boolean;
  showDescriptionForm: boolean;
};

export enum Option {
  NO_CONTACTED = "NO_CONTACTED",
}

type Props = {
  modalActions: ModalActions;
  ad: Ad;
  option?: Option;
};

const FillProfileModalComponent = ({ modalActions, ad, option }: Props) => {
  const theme = useTheme();
  const [profile, setProfile] = useState<ProfileFormData>();
  const [stepsVisibility, setStepsVisibility] = useState<VisibleSteps>({
    showPersonalInformationForm: true,
    showProfilePictureUploadForm: true,
    showPreferencesForm: true,
    showDescriptionForm: true,
  });
  const [userHasAgents, setUserHasAgents] = useState(false);
  const [title, setTitle] = useState("");
  const [dialogView, setDialogView] = useState<DialogViews>("PROFILE");

  const updateProfile = (updatedValues: ProfileFormData) => {
    const updatedProfile = {
      ...profile,
      ...updatedValues,
    };

    if (updatedProfile.user_type === USER_TYPE.COMPANY.toString()) {
      updatedProfile.first_name = "";
      updatedProfile.last_name = "";
    } else {
      updatedProfile.company_name = "";
    }

    setProfile(updatedProfile);
    return updatedProfile;
  };

  const updateAndSaveProfile = (updatedValues: ProfileFormData) => {
    const updatedProfile = updateProfile(updatedValues);
    saveProfile(updatedProfile);
  };

  const updateStepsVisibility = (profile: Profile) => {
    const hasUserType =
      typeof profile.user_type !== "undefined" && profile.user_type !== null;
    const hasOccupation =
      typeof profile.occupation !== "undefined" && profile.occupation !== null;

    return {
      showPersonalInformationForm:
        !hasUserType ||
        (hasUserType &&
          profile.user_type !== USER_TYPE.COMPANY &&
          !profile.birthday) ||
        (hasUserType &&
          profile.user_type !== USER_TYPE.COMPANY &&
          !hasOccupation) ||
        (hasUserType &&
          profile.user_type === USER_TYPE.COMPANY &&
          !profile.company_name),
      showProfilePictureUploadForm: !profile.profile_image,
      showPreferencesForm:
        typeof profile.pets === "undefined" ||
        profile.pets === null ||
        typeof profile.smoking === "undefined" ||
        profile.smoking === null ||
        typeof profile.kids === "undefined" ||
        profile.kids === null,
      showDescriptionForm: !profile.description,
    };
  };

  useEffect(() => {
    API.getProfile().then(({ profile }) => {
      setStepsVisibility(updateStepsVisibility(profile));
      setProfile(convertProfileToFormData(profile));
    });
  }, []);

  useEffect(() => {
    API.getAgents().then((agents) => {
      const cities = agents.map(
        (agent) =>
          agent.filters.city_level_1?.values &&
          agent.filters.city_level_1.values[0],
      );
      const hasAgent = cities.includes(ad.city);

      setUserHasAgents(hasAgent);
    });
  }, [ad]);

  useEffect(() => {
    const isProfileIncomplete = Object.values(stepsVisibility).some(Boolean);

    if (isProfileIncomplete) {
      setDialogView("PROFILE");
      return;
    }

    if (!userHasAgents) {
      setDialogView("AGENT");
      return;
    }

    if (option !== Option.NO_CONTACTED) {
      setDialogView("CONTACTED");
    }
  }, [userHasAgents, stepsVisibility, option]);

  useEffect(() => {
    if (dialogView === "AGENT") {
      setTitle(t("profile_completion.agent_modal.title"));
    }

    if (dialogView === "CONTACTED") {
      setTitle(t("profile_completion.agent_modal.title_contacted"));
    }
  }, [dialogView]);

  const saveProfile = (profile: ProfileFormData) => {
    API.createOrUpdateProfile(convertFormDataToProfile(profile));
  };

  if (!profile) {
    return null;
  }

  const onStepsCompleted = () => {
    saveProfile(profile);

    if (userHasAgents && option !== Option.NO_CONTACTED) {
      setDialogView("CONTACTED");
    } else if (!userHasAgents) {
      setDialogView("AGENT");
    } else {
      modalActions.closeModal();
    }
  };

  const renderSteps = () => (
    <SteppedView
      completionButtonAction={onStepsCompleted}
      completionButtonLabel={t("profile_completion.done.button")}
      forwardButtonLabel={t("profile_completion.next.button")}
      backButtonLabel={t("profile_completion.previous.button")}
      controlsContainerStyle={`
        @media (min-width: ${theme.breakpoints.md}) {
          margin-top: 15px;
        }

        @media (max-width: ${theme.breakpoints.sm}) {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
        }
      `}
      controlsInnerContainerStyle={`
        @media (max-width: ${theme.breakpoints.sm}) {
          max-width: 500px;
          padding: 16px;
          margin: 0 auto;
          background: white;
          border-top: 1px solid #ececec;
        }
      `}
    >
      {stepsVisibility?.showPersonalInformationForm && (
        <Step>
          <PersonalInformation
            profile={profile}
            onProfileChange={updateAndSaveProfile}
            setTitle={setTitle}
          />
        </Step>
      )}

      {stepsVisibility?.showProfilePictureUploadForm && (
        <Step>
          <ProfilePictureUpload setTitle={setTitle} />
        </Step>
      )}

      {stepsVisibility?.showPreferencesForm && (
        <Step>
          <Preferences
            profile={profile}
            onProfileChange={updateAndSaveProfile}
            setTitle={setTitle}
          />
        </Step>
      )}

      {stepsVisibility?.showDescriptionForm && (
        <Step>
          <Description
            profile={profile}
            onProfileChange={updateProfile}
            setTitle={setTitle}
          />
        </Step>
      )}
    </SteppedView>
  );

  const renderAgent = () => (
    <>
      {option === Option.NO_CONTACTED ? (
        <AgentSetup
          ad={ad}
          onCreated={() => modalActions.closeModal()}
        />
      ) : (
        <AgentSetup
          ad={ad}
          onCreated={() => setDialogView("CONTACTED")}
        />
      )}
    </>
  );

  const renderContacted = () => <ContactedInfo />;

  const handleClose = () => {
    if (
      userHasAgents &&
      dialogView === "PROFILE" &&
      option !== Option.NO_CONTACTED
    ) {
      setDialogView("CONTACTED");
      return false;
    }

    if (dialogView === "PROFILE" && option !== Option.NO_CONTACTED) {
      setDialogView("AGENT");
      return false;
    }

    if (dialogView === "AGENT" && option !== Option.NO_CONTACTED) {
      setDialogView("CONTACTED");
      return false;
    }

    if (dialogView === "CONTACTED" || option === Option.NO_CONTACTED) {
      modalActions.closeModal();
      return true;
    }

    return true;
  };

  return (
    <Dialog
      onClose={handleClose}
      open
    >
      <DialogHeader>{title}</DialogHeader>

      <DialogContent>
        {dialogView === "PROFILE" && renderSteps()}
        {dialogView === "AGENT" && renderAgent()}
        {dialogView === "CONTACTED" && renderContacted()}
      </DialogContent>
    </Dialog>
  );
};

export const FillProfileModal = withModalActions(FillProfileModalComponent);
