import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  SelectField,
  Spinner,
  Text,
  useNotifications,
} from "@boligportal/juice";
import * as Sentry from "@sentry/react";
import { useGetRentRegulationDueDateOptions } from "api/queries";
import { TenancyAPI } from "features/rentable_tenancy/TenancyAPI";
import { localizedMonthDayAndYear } from "lib/date";
import { t } from "lib/i18n";

type Candidate = {
  tenancyId: number;
  paymentRecordId: number;
  arrears: number;
  paymentDueDate: string;
};

type TransferArrearsContextValue = {
  candidate: Candidate | null;
  onClose: () => void;
  setTransferArrearsCandidate: (candidate: Candidate) => void;
  invalidationQueries: string[];
};

const TransferArrearsContext = React.createContext<TransferArrearsContextValue>(
  {
    candidate: null,
    onClose: () => {},
    setTransferArrearsCandidate: () => {},
    invalidationQueries: [],
  },
);

export const TransferArrearsProvider = ({
  children,
  invalidationQueries,
}: {
  children: React.ReactNode;
  invalidationQueries: string[];
}) => {
  const [candidate, setCandidate] = React.useState<Candidate | null>(null);

  const onClose = () => setCandidate(null);

  return (
    <TransferArrearsContext.Provider
      value={{
        candidate,
        onClose,
        setTransferArrearsCandidate: setCandidate,
        invalidationQueries,
      }}
    >
      <TransferArrearsDialogWrapper />
      {children}
    </TransferArrearsContext.Provider>
  );
};

export const useTransferArrears = () => {
  const context = React.useContext(TransferArrearsContext);
  if (!context) {
    throw "useTransferArrears hook can only be used inside function component as a Child of TransferArrearsProvider";
  }

  const { setTransferArrearsCandidate, candidate } = context;

  return {
    isActive: !!candidate,
    setTransferArrearsCandidate,
  };
};

const TransferArrearsDialogWrapper = () => {
  const { candidate, onClose, invalidationQueries } = React.useContext(
    TransferArrearsContext,
  );

  if (!candidate) {
    return null;
  }

  return (
    <TransferArrearsDialog
      candidate={candidate}
      onClose={onClose}
      invalidationQueries={invalidationQueries}
    />
  );
};

type TransferArrearsForm = {
  due_date: string;
};
const TransferArrearsDialog = ({
  candidate: { tenancyId, paymentRecordId, arrears, paymentDueDate },
  onClose,
  invalidationQueries,
}: {
  candidate: Candidate;
  onClose: () => void;
  invalidationQueries: string[];
}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TransferArrearsForm>();
  const { data: dueDateOptions, isLoading } =
    useGetRentRegulationDueDateOptions(tenancyId);

  const onSubmit: SubmitHandler<TransferArrearsForm> = async ({ due_date }) => {
    const paymentId = dueDateOptions?.find(
      (option) => option.value === due_date,
    )?.paymentId;
    if (!paymentId) {
      return;
    }

    return TenancyAPI.transferTenancyArrears(paymentRecordId, paymentId)
      .then(() => {
        invalidationQueries.forEach((query) =>
          queryClient.invalidateQueries(query),
        );
        onClose();
      })
      .catch((err) => {
        addNotification({
          title: t("Something went wrong. Try again."),
        });

        Sentry.captureException(err, {
          level: "error",
        });
      });
  };

  return (
    <Dialog
      open
      size="medium"
      onClose={onClose}
    >
      <DialogHeader>{t("rent.transfer_arrears.title")}</DialogHeader>
      <DialogContent>
        <Text
          size="h4"
          weight="bold"
          block
          mb={1}
        >
          {t("rent.transfer_arrears.description_1", {
            arrears: arrears * -1,
          })}
        </Text>
        <Text
          mt={1}
          block
          markdown
        >
          {t("rent.transfer_arrears.description_2", {
            date: localizedMonthDayAndYear(new Date(paymentDueDate)),
          })}
        </Text>

        {isLoading || !dueDateOptions ? (
          <Box my={2}>
            <Spinner size="small" />
          </Box>
        ) : (
          <SelectField
            mt={2}
            label={t("rent.transfer_arrears.choose_payment_label")}
            items={dueDateOptions}
            {...register("due_date")}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t("rent.transfer_arrears.cta_button_text")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
