import { Box, Button, Flex, IconEye, Spinner, Text } from "@boligportal/juice";
import { useQueryNewBuildToolbar } from "lib/customerServiceQueries";
import { NewBuildDateDisplay } from "./NewBuildDateDisplay";

type NewBuildToolbarActionsProps = {
  title: string;
  url: string;
  newBuildId: number;
};
export const NewBuildToolbarActions = ({
  title,
  url,
  newBuildId,
}: NewBuildToolbarActionsProps) => {
  const { data: itemToolbar, isLoading } = useQueryNewBuildToolbar(newBuildId);

  if (!itemToolbar) {
    return null;
  }

  const { created, advertised_date, modified, rented_out_date } =
    itemToolbar.data;

  return (
    <div
      style={{
        zIndex: 799,
      }}
    >
      <Flex
        p={2}
        shadow="sm"
        bg="base"
        borderBottom
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Flex
              gap
              flexGrow
            >
              <Flex
                flexGrow
                minWidth="450px"
              >
                <Flex column>
                  <Flex>
                    <Text weight="bold">{title}</Text>
                    <Text pl={1}>#{newBuildId}</Text>
                  </Flex>
                  <Flex column>
                    <Flex gap={4}>
                      <NewBuildDateDisplay
                        label="Created:"
                        date={created}
                      />

                      <NewBuildDateDisplay
                        label="Advertised:"
                        date={advertised_date}
                      />

                      <NewBuildDateDisplay
                        label="Rented Out:"
                        date={rented_out_date}
                      />
                    </Flex>

                    <NewBuildDateDisplay
                      label="Last edited:"
                      date={modified}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Box>
              <Button
                as="a"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                variant="default"
              >
                <Flex
                  gap={2}
                  align="center"
                >
                  <IconEye />
                  <Text
                    color="primary"
                    weight="bold"
                    mr={1}
                  >
                    See ad
                  </Text>
                </Flex>
              </Button>
            </Box>
          </>
        )}
      </Flex>
    </div>
  );
};
