import * as React from "react";

const SvgStar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1.08em"
    height="1em"
    viewBox="0 0 66 61"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Final-design-and-spec"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop"
        transform="translate(-1460.000000, -4797.000000)"
      >
        <g
          id="Customer-Support"
          transform="translate(239.000000, 4584.000000)"
        >
          <g
            id="Experience"
            transform="translate(1067.000000, 213.000000)"
          >
            <g
              id="star"
              transform="translate(154.000000, 0.000000)"
            >
              <path
                d="M41.6507277,60.8888889 C41.2796478,60.8903494 40.9175725,60.7737004 40.6160707,60.5555556 L26.4449064,50.1955556 L12.2737422,60.5555556 C11.6524999,61.0099233 10.8111373,61.0068396 10.193187,60.5479299 C9.57523657,60.0890202 9.32267747,59.2797228 9.56864865,58.5466667 L15.0956341,42.0388889 L0.771309771,32.1333333 C0.134458031,31.6934314 -0.144527521,30.8869028 0.0828436292,30.1430236 C0.310214779,29.3991444 0.991058154,28.8909343 1.76299376,28.8888889 L19.4348025,28.8888889 L24.7678586,12.3388889 C25.0035484,11.6058645 25.680724,11.1094149 26.4449064,11.1094149 C27.2090889,11.1094149 27.8862645,11.6058645 28.1219543,12.3388889 L33.4550104,28.8944444 L51.1268191,28.8944444 C51.8997712,28.8940606 52.5828323,29.4014339 52.8116563,30.1459303 C53.0404804,30.8904268 52.7616981,31.6984054 52.1240125,32.1388889 L37.7941788,42.0388889 L43.3178586,58.5422222 C43.4994222,59.0842786 43.4111776,59.6813232 43.0807526,60.1464319 C42.7503277,60.6115407 42.2181101,60.8878636 41.6507277,60.8888889 Z"
                id="Path"
                fill="#FF991F"
              ></path>
              <path
                d="M61.5122691,45.9992485 C61.4631532,45.9994539 61.4152292,45.9830524 61.3753226,45.9523801 L59.4996359,44.4957112 L57.6239493,45.9523801 C57.541722,46.0162666 57.4303598,46.015833 57.3485682,45.9513079 C57.2667767,45.8867829 57.2333481,45.7729915 57.2659047,45.6699201 L57.9974531,43.3488422 L56.1014943,41.9560704 C56.017201,41.8942179 55.9802746,41.7808159 56.0103694,41.6762227 C56.0404641,41.5716295 56.1305801,41.5001725 56.232753,41.4998849 L58.5717828,41.4998849 L59.2776629,39.1728703 C59.3088586,39.0698034 59.3984892,39 59.4996359,39 C59.6007826,39 59.6904132,39.0698034 59.7216089,39.1728703 L60.427489,41.5006661 L62.7665188,41.5006661 C62.8688263,41.5006121 62.9592358,41.5719514 62.9895228,41.6766314 C63.0198099,41.7813114 62.9829104,41.8949173 62.8985067,41.9568515 L61.0018187,43.3488422 L61.7329296,45.6692952 C61.7569612,45.7455111 61.7452812,45.8294586 61.7015464,45.8948553 C61.6578115,45.9602519 61.5873675,45.9991044 61.5122691,45.9992485 Z"
                id="Path-Copy"
                fill="#FFC400"
              ></path>
              <path
                d="M64.5122691,27.9992485 C64.4631532,27.9994539 64.4152292,27.9830524 64.3753226,27.9523801 L62.4996359,26.4957112 L60.6239493,27.9523801 C60.541722,28.0162666 60.4303598,28.015833 60.3485682,27.9513079 C60.2667767,27.8867829 60.2333481,27.7729915 60.2659047,27.6699201 L60.9974531,25.3488422 L59.1014943,23.9560704 C59.017201,23.8942179 58.9802746,23.7808159 59.0103694,23.6762227 C59.0404641,23.5716295 59.1305801,23.5001725 59.232753,23.4998849 L61.5717828,23.4998849 L62.2776629,21.1728703 C62.3088586,21.0698034 62.3984892,21 62.4996359,21 C62.6007826,21 62.6904132,21.0698034 62.7216089,21.1728703 L63.427489,23.5006661 L65.7665188,23.5006661 C65.8688263,23.5006121 65.9592358,23.5719514 65.9895228,23.6766314 C66.0198099,23.7813114 65.9829104,23.8949173 65.8985067,23.9568515 L64.0018187,25.3488422 L64.7329296,27.6692952 C64.7569612,27.7455111 64.7452812,27.8294586 64.7015464,27.8948553 C64.6578115,27.9602519 64.5873675,27.9991044 64.5122691,27.9992485 Z"
                id="Path-Copy-2"
                fill="#FFC400"
              ></path>
              <path
                d="M41.5122691,8.99924851 C41.4631532,8.99945388 41.4152292,8.98305242 41.3753226,8.95238014 L39.4996359,7.49571118 L37.6239493,8.95238014 C37.541722,9.01626658 37.4303598,9.01583298 37.3485682,8.95130793 C37.2667767,8.88678288 37.2333481,8.77299153 37.2659047,8.6699201 L37.9974531,6.34884215 L36.1014943,4.95607041 C36.017201,4.89421795 35.9802746,4.7808159 36.0103694,4.67622268 C36.0404641,4.57162947 36.1305801,4.50017253 36.232753,4.49988493 L38.5717828,4.49988493 L39.2776629,2.17287033 C39.3088586,2.06980336 39.3984892,2 39.4996359,2 C39.6007826,2 39.6904132,2.06980336 39.7216089,2.17287033 L40.427489,4.50066607 L42.7665188,4.50066607 C42.8688263,4.5006121 42.9592358,4.57195137 42.9895228,4.67663138 C43.0198099,4.78131139 42.9829104,4.89491732 42.8985067,4.95685155 L41.0018187,6.34884215 L41.7329296,8.66929518 C41.7569612,8.74551108 41.7452812,8.8294586 41.7015464,8.89485527 C41.6578115,8.96025194 41.5873675,8.99910436 41.5122691,8.99924851 Z"
                id="Path-Copy-3"
                fill="#FFC400"
              ></path>
              <path
                d="M15.5122691,6.99924851 C15.4631532,6.99945388 15.4152292,6.98305242 15.3753226,6.95238014 L13.4996359,5.49571118 L11.6239493,6.95238014 C11.541722,7.01626658 11.4303598,7.01583298 11.3485682,6.95130793 C11.2667767,6.88678288 11.2333481,6.77299153 11.2659047,6.6699201 L11.9974531,4.34884215 L10.1014943,2.95607041 C10.017201,2.89421795 9.98027464,2.7808159 10.0103694,2.67622268 C10.0404641,2.57162947 10.1305801,2.50017253 10.232753,2.49988493 L12.5717828,2.49988493 L13.2776629,0.172870329 C13.3088586,0.0698033576 13.3984892,0 13.4996359,0 C13.6007826,0 13.6904132,0.0698033576 13.7216089,0.172870329 L14.427489,2.50066607 L16.7665188,2.50066607 C16.8688263,2.5006121 16.9592358,2.57195137 16.9895228,2.67663138 C17.0198099,2.78131139 16.9829104,2.89491732 16.8985067,2.95685155 L15.0018187,4.34884215 L15.7329296,6.66929518 C15.7569612,6.74551108 15.7452812,6.8294586 15.7015464,6.89485527 C15.6578115,6.96025194 15.5873675,6.99910436 15.5122691,6.99924851 Z"
                id="Path-Copy-4"
                fill="#FFC400"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgStar;
