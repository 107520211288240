import * as React from "react";
import { Box, Link, Logo, Flex, styled } from "@boligportal/juice";
import { App } from "components/app";

const SidebarMenuArea = ({ children }: { children: React.ReactNode }) => (
  <FlexWithBgPrimary800
    column
    width="240px"
    minWidth="240px"
    hidden={{
      xs: true,
      xl: false,
    }}
  >
    <Box
      px={3}
      py={4}
    >
      <Link
        href={App.settings.routes.index}
        css={{ height: "100%" }}
      >
        <Logo
          width="180px"
          inverse
          market={App.settings.market}
        />
      </Link>
    </Box>
    {children}
  </FlexWithBgPrimary800>
);

const SidebarMenuAreaTop = ({ children }: { children: React.ReactNode }) => (
  <>
    {children}
    <Box flexGrow />
  </>
);
const SidebarMenuAreaBottom = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

const SideBarMenuSpacer = ({ size = 3 }: { size?: 1 | 2 | 3 }) => (
  <Box pt={size} />
);

const FlexWithBgPrimary800 = styled(Flex)`
  background-color: ${(props) => props.theme.colorPalette.primary[800]};
  color: white;
  overflow-y: auto;
`;

export {
  SidebarMenuArea,
  SidebarMenuAreaTop,
  SidebarMenuAreaBottom,
  SideBarMenuSpacer,
};
