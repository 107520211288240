import { useCallback } from "react";

export const useOis = () => {
  const getRentalInformation = useCallback(
    async (dawaID: string): Promise<any> => {
      const response = await fetch(
        `https://address.boligportal.dk/info/ois/${dawaID}`,
      );
      const data = await response.json();
      return Promise.resolve(data);
    },
    [],
  );
  return {
    getRentalInformation,
  };
};
