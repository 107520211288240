import { http } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { NewBuild, Unit } from "./types";

type NewBuildsResponse = {
  new_builds: NewBuild;
  is_owner: boolean;
};

export type CreateNewBuildPayload = {
  name: string;
};

export type UpdateNewBuildPayload = Omit<NewBuild, "images"> & {
  image_ids?: number[];
};

type NewBuildsListResponse = {
  new_builds: NewBuild[];
};

type CreateNewBuildResponse = {
  new_build_id: number;
};

type UpdateNewBuildResponse = {
  new_build_id: number;
};

export type UnitPayload = Omit<Unit, "images"> & {
  image_ids: number[];
};

type NewBuildUnitsListResponse = {
  units: Unit[];
};

type CreateNewBuildUnitResponse = {
  unit_id: number;
};

type UpdateNewBuildUnitResponse = {
  unit_id: number;
};

type NewBuildsInboxDataResponse = {
  totalThreadCount: number;
  unseenThreadCount: number;
  inboxUrl?: string;
};

export const getNewBuildsList = () => {
  return http.get<NewBuildsListResponse>("new-builds/list/");
};

export const getNewBuildById = (id: NewBuild["id"]) => {
  return http.get<NewBuildsResponse>(`new-builds/${id}/`);
};

export const createNewBuild = (payload: CreateNewBuildPayload) => {
  return http.post<CreateNewBuildResponse>("new-builds/", payload);
};

export const updateNewBuildById = (
  id: NewBuild["id"],
  payload: UpdateNewBuildPayload,
) => {
  return http.patch<UpdateNewBuildResponse>(`new-builds/${id}/update`, payload);
};

export const getNewBuildUnitsList = (id: number) => {
  return http.get<NewBuildUnitsListResponse>(`new-builds/${id}/list`);
};

export const createNewBuildUnit = (id: number, payload: UnitPayload) => {
  return http.post<CreateNewBuildUnitResponse>(
    `new-builds/${id}/unit`,
    payload,
  );
};

export const updateNewBuildUnit = (id: number, payload: UnitPayload) => {
  return http.patch<UpdateNewBuildUnitResponse>(
    `new-builds/${id}/unit/${payload.id}`,
    payload,
  );
};

export const markNewBuildActive = (id: NewBuild["id"]) => {
  return http.post(`new-builds/${id}/activate`);
};

export const markNewBuildRentedOut = (id: NewBuild["id"]) => {
  return http.post(`new-builds/${id}/markrentedout`);
};

export const markNewBuildUnitActive = (
  buildId: NewBuild["id"],
  unitId: Unit["id"],
) => {
  return http.post(`new-builds/${buildId}/unit/${unitId}/activate`);
};

export const markNewBuildUnitRentedOut = (
  buildId: NewBuild["id"],
  unitId: Unit["id"],
) => {
  return http.post(`new-builds/${buildId}/unit/${unitId}/markrentedout`);
};

export const getNewBuildsProjectInboxData = (id: NewBuild["id"]) => {
  return http.get<NewBuildsInboxDataResponse>(`new-builds/${id}/inbox-info`);
};

export const getNewBuildsUnitInboxData = (id: Unit["id"]) => {
  return http.get<NewBuildsInboxDataResponse>(
    `new-builds/${id}/unit-unseen-thread-count`,
  );
};

export const showInterestForUnit = async (unitId: number) => {
  return http.post<{ ad: Ad }>(`new-builds/${unitId}/show-interest-for-unit`);
};
