import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { AccountPaymentFlowCoordinator } from "features/paywall/coordinators/account/AccountPaymentFlowCoordinator";
import { ApplePayPaymentFlowCoordinator } from "features/paywall/coordinators/applepay/ApplePayPaymentFlowCoordinator";
import { GooglePayPaymentFlowCoordinator } from "features/paywall/coordinators/googlepay/GooglePayPaymentFlowCoordinator";
import { MobilePayPaymentFlowCoordinator } from "features/paywall/coordinators/mobilepay/MobilePayPaymentFlowCoordinator";
import { QuickpayPaymentFlowCoordinator } from "features/paywall/coordinators/quickpay/QuickpayPaymentFlowCoordinator";
import { Paywall } from "features/paywall/paywall";
import SvgApplePay from "media/svg/ApplePay";
import SvgDankort from "media/svg/Dankort";
import SvgGooglePay from "media/svg/Googlepay";
import SvgMastercard from "media/svg/Mastercard";
import SvgMobilepayLogoBlue from "media/svg/MobilepayLogoBlue";
import SvgVisa from "media/svg/Visa";
import { createSeekerPlanItemsDK } from "./seekerPlanItems";
import { startSubscriptionTrackingDK } from "./startSubscriptionTracker";

export const paywall_dk: Paywall = {
  creditCardIcons: {
    [PaymentMethodType.QuickPay]: [SvgVisa, SvgMastercard, SvgDankort],
    [PaymentMethodType.MobilePay]: [SvgMobilepayLogoBlue],
    [PaymentMethodType.ApplePay]: [SvgApplePay],
    [PaymentMethodType.GooglePay]: [SvgGooglePay],
  },
  seekerPaymentFlowCoordinators: {
    [PaymentMethodType.ApplePay]: ApplePayPaymentFlowCoordinator,
    [PaymentMethodType.QuickPay]: QuickpayPaymentFlowCoordinator,
    [PaymentMethodType.GooglePay]: GooglePayPaymentFlowCoordinator,
    [PaymentMethodType.MobilePay]: MobilePayPaymentFlowCoordinator,
  },
  landlordPaymentFlowCoordinators: {
    [PaymentMethodType.Account]: AccountPaymentFlowCoordinator,
    [PaymentMethodType.MobilePay]: MobilePayPaymentFlowCoordinator,
    [PaymentMethodType.QuickPay]: QuickpayPaymentFlowCoordinator,
  },
  startSubscriptionTracker: startSubscriptionTrackingDK,
  createSeekerPlanItems: createSeekerPlanItemsDK,
};
