import { LatLngLocation } from "components/interfaces/location";
import { fetchFromAPI } from "lib/api";

const saveAdLocationService = async (
  adId: number,
  location: LatLngLocation,
): Promise<{ success: boolean }> => {
  const url = `/customer-service/api/ad/${adId}/location/update`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        lat: location.lat,
        lng: location.lng,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line compat/compat
    return Promise.reject(error);
  }
};

export { saveAdLocationService };
