import * as React from "react";
import { useIntersectionObserver } from "./hooks/useIntersectionObserver";

type Props = {
  children: React.ReactNode;
  delayInMilliseconds?: number;
  freezeOnceVisible?: boolean;
};

export const AnimationReveal = ({
  children,
  delayInMilliseconds,
  freezeOnceVisible,
}: Props) => {
  const triggerRef = React.useRef<HTMLDivElement>(null);

  const observerEntryRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: freezeOnceVisible ?? true,
  });

  const styleOffScreen = {
    opacity: 0,
    transform: "scale(1.1)",
  };

  const styleIntersecting = {
    opacity: 1,
    transform: "scale(1)",
    transition: "500ms ease-in-out",
    transitionDelay: `${delayInMilliseconds}ms`,
  };

  return (
    <div
      style={
        observerEntryRef?.isIntersecting ? styleIntersecting : styleOffScreen
      }
    >
      {children}
      <div ref={triggerRef} />
    </div>
  );
};
