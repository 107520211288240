export const getInitialSelectedAdsFromParam = (location): Array<number> => {
  let selectedAds: Array<number> = [];

  // eslint-disable-next-line compat/compat
  const urlSearchParams = new URLSearchParams(location.search);
  const paramValue = urlSearchParams.get("ads");

  if (paramValue) {
    const adsArr: Array<string> = paramValue.split(",");
    if (adsArr.length > 0) {
      selectedAds = adsArr.map((ad) => Number(ad));

      // We currently have a bug where;
      // -> Given the "closest zoom level"
      // -> And with a pin that represents multiple ads (cluster).
      // -> When the pin is selected the url correctly gets updated with the ads param.
      // However, when refreshing the page, the map does not zoom to the "closest zoom level"
      // leaving the ads as a normal cluster element, instead of the selectable pin.
      if (selectedAds.some(isNaN)) {
        selectedAds = [];
      }
    }
  }
  return selectedAds;
};
