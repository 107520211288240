import { Flex } from "@boligportal/juice";
import { UpsellProps } from "../../types";
import { LandlordUpsellContainer } from "./components/LandlordUpsellContainer";

export const DefaultUpsellScreen = ({
  plans,
  activeUpsellSubscriptions,
  listingId,
  handleOpenModal,
  isAdLessThan24HoursOld,
}: UpsellProps) => {
  const sortedPlans = Object.keys(plans)
    .filter((key) => plans[key].length > 0)
    .sort((a, b) => plans[b][0].price.total - plans[a][0].price.total);

  return (
    <Flex
      gap={4}
      align={{
        xs: "center",
        lg: "start",
      }}
      column={{
        xs: true,
        lg: false,
      }}
    >
      {sortedPlans.map((plansKey) => (
        <LandlordUpsellContainer
          key={plansKey}
          plans={plans[plansKey]}
          activeUpsellSubscriptions={activeUpsellSubscriptions}
          listingId={listingId}
          handleOpenModal={handleOpenModal}
          isAdLessThan24HoursOld={isAdLessThan24HoursOld}
        />
      ))}
    </Flex>
  );
};
