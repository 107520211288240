import { Product } from "components/interfaces/product";
import { t } from "lib/i18n";

export const getProductName = (product: Product) => {
  switch (product.name) {
    case "promote_ad":
      return t("upsell_products.top_ad.name");
    case "open_ad":
      return t("upsell_products.open_ad.name");
    case "exposure_ad":
      return t("upsell_products.exposure_ad.name");
    case "exposure_plus_ad":
      return t("upsell_products.exposure_plus_ad.name");
    case "rental_data_product":
      return t("products.rental_data.name");
    default:
      return "";
  }
};

export const getBenefits = (product: Product, isPartOfACompany: boolean) => {
  switch (product.name) {
    case "promote_ad":
      return [
        t("upsell_products.promote_ad.feature1"),
        t("upsell_products.promote_ad.feature2"),
      ].filter((item) => item.trim());
    case "open_ad":
      return [
        t("upsell_products.open_ad.feature1"),
        t("upsell_products.open_ad.feature2"),
      ].filter((item) => item.trim());
    case "exposure_ad":
      return [
        t("upsell_products.exposure_ad.feature1"),
        t("upsell_products.exposure_ad.feature2"),
        t("upsell_products.exposure_ad.feature3"),
        t("upsell_products.exposure_ad.feature4"),
        t("upsell_products.exposure_ad.feature5"),
      ].filter((item) => item.trim());
    case "exposure_plus_ad":
      if (isPartOfACompany) {
        return [
          t("upsell_products.exposure_plus_ad.base_features"),
          t("upsell_products.exposure_plus_ad.feature1"),
          t("upsell_products.exposure_plus_ad.feature2"),
          t("upsell_products.exposure_plus_ad.feature3"),
          t("upsell_products.exposure_plus_ad.feature4"),
          t("upsell_products.exposure_plus_ad.feature5"),
        ].filter((item) => item.trim());
      } else {
        return [
          t("upsell_products.exposure_plus_ad.base_features"),
          t("upsell_products.exposure_plus_ad.feature1"),
          t("upsell_products.exposure_plus_ad.feature2"),
          t("upsell_products.exposure_plus_ad.feature3"),
          t("upsell_products.exposure_plus_ad.feature4"),
        ].filter((item) => item.trim());
      }
    default:
      return [];
  }
};

export const getBenefitsCreateAdFlow = (
  product: Product,
  isUserPartOfCompany: boolean,
) => {
  if (product.name === "exposure_plus_ad") {
    const baseBenefits = [
      t("upsell_products.exposure_plus_ad.create_ad_flow.base_features"),
      t("upsell_products.exposure_plus_ad.create_ad_flow.feature1"),
      t("upsell_products.exposure_plus_ad.create_ad_flow.feature2"),
      t("upsell_products.exposure_plus_ad.create_ad_flow.feature3"),
      t("upsell_products.exposure_plus_ad.feature4"),
    ];
    if (!isUserPartOfCompany) {
      return baseBenefits.filter((item) => item.trim());
    }
    baseBenefits.push(t("upsell_products.exposure_plus_ad.feature5"));
    return baseBenefits.filter((item) => item.trim());
  }
  return getBenefits(product, isUserPartOfCompany);
};

export const getBenefitsAfterCreateAdFlow = (
  product: Product,
  isAdLessThan24HoursOld: boolean,
  isUserPartOfCompany: boolean,
) => {
  if (isAdLessThan24HoursOld) {
    return getBenefitsCreateAdFlow(product, isUserPartOfCompany);
  }
  return getBenefits(product, isUserPartOfCompany);
};
