import { useState } from "react";
import {
  Flex,
  styled,
  IconArrowBack,
  IconArrowForward,
  Spinner,
} from "@boligportal/juice";
import { SortableImage } from "../interfaces/SortableImage";
import {
  RotateImageFeature,
  MoveImageBackwardFeature,
  MoveImageForwardFeature,
} from "../types/SortableFeatures";
import { RotateIcon } from "./RotateIcon";

interface ImageItemControlsProps {
  image: SortableImage;
  rotateImage: RotateImageFeature;
  canMoveBackward: boolean;
  canMoveForward: boolean;
  moveImageBackward: MoveImageBackwardFeature;
  moveImageForward: MoveImageForwardFeature;
}

export const ImageItemControls = ({
  image,
  rotateImage,
  canMoveBackward,
  canMoveForward,
  moveImageBackward,
  moveImageForward,
}: ImageItemControlsProps) => {
  const [isRotating, setIsRotating] = useState(false);

  const moveBackwardClickHandler = () => {
    moveImageBackward(image);
  };

  const rotateClickHandler = async () => {
    setIsRotating(true);
    await rotateImage(image);
    setIsRotating(false);
  };

  const moveForwardClickHandler = () => {
    moveImageForward(image);
  };

  return (
    <Flex
      align="center"
      px={1}
      width="100%"
      height="100%"
      justify="space-between"
    >
      <StyledIconWrapper
        onClick={moveBackwardClickHandler}
        enabled={canMoveBackward}
      >
        <IconArrowBack color="inverted" />
      </StyledIconWrapper>

      {!isRotating && (
        <StyledIconWrapper
          onClick={rotateClickHandler}
          enabled
        >
          <RotateIcon />
        </StyledIconWrapper>
      )}
      {isRotating && (
        <StyledSpinnerWrapper>
          <Spinner size="small" />
        </StyledSpinnerWrapper>
      )}

      <StyledIconWrapper
        onClick={moveForwardClickHandler}
        enabled={canMoveForward}
      >
        <IconArrowForward color="inverted" />
      </StyledIconWrapper>
    </Flex>
  );
};

const StyledIconWrapper = styled.div<{ enabled: boolean }>`
  pointer-events: ${(p) => (p.enabled ? "auto" : "none")};
  opacity: ${(p) => (p.enabled ? 1 : 0)};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;
const StyledSpinnerWrapper = styled.div`
  padding: 4px;
  background-color: #ffffff;
  border-radius: 999px;
`;
