export enum TenancyRentEntityType {
  rent = "rent",
  on_account_heating = "on_account_heating",
  on_account_water = "on_account_water",
  on_account_electricity = "on_account_electricity",
  on_account_cooling = "on_account_cooling",
  on_account_joint_accounts = "on_account_cooling",
  commercial_rental = "commercial_rental",
  storage_room_rental = "storage_room_rental",
  parking_rental = "parking_rental",
  garage_rental = "garage_rental",
  antenna_contribution = "antenna_contribution",
  resident_representation = "resident_representation",
  taxes_and_fees = "taxes_and_fees",
  deposit_regulation = "deposit_regulation",
  prepaid_rent_regulation = "prepaid_rent_regulation",
  internet = "internet",
  expenses_at_tenants = "expenses_at_tenants",
  water_drainage = "water_drainage",
  house_common_area = "house_common_area",
  improvement_supplement = "improvement_supplement",
  laundry = "laundry",
  other = "other",
}
