import { forwardRef } from "react";
import {
  IconAdd,
  IconArrowBackForward,
  IconDocumentTextOutline,
  IconLogo,
  IconPeopleOutline,
  IconRentables,
  IconServerOutline,
  Menu,
  styled,
} from "@boligportal/juice";
import { App } from "components/app";
import { MenuItemWithIcon } from "components/header/components/MenuItemWithIcon";
import { Market } from "components/markets/market_settings";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { HeaderAction, TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { ItemProps, MenuItem } from "../top_nav";

const StyledOpener = styled.div<{
  inverseColor: boolean;
  isLoggedIn?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: inherit;
  padding: 0 ${(props) => (props.isLoggedIn ? props.theme.unit(2) : "0")};
  border-radius: ${(props) => props.theme.borderRadius.md};
  border-width: ${(props) => (props.isLoggedIn ? "1px" : "0")};
  border-style: solid;
  border-color: ${(props) =>
    props.inverseColor
      ? props.theme.color.bg.base
      : props.theme.color.text.base};
  color: ${(props) =>
    props.inverseColor
      ? props.theme.color.bg.base
      : props.theme.color.text.base};
  line-height: ${(props) => props.theme.unit(3.5)};
  cursor: pointer;

  & > span {
    margin-left: ${(props) => props.theme.unit(0.5)};
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Opener = forwardRef<
  HTMLDivElement,
  Pick<ItemProps, "isLoggedIn" | "inverseColor">
>((props, ref) => {
  const { inverseColor, isLoggedIn, ...openerProps } = props;
  const handleTracking = () => {
    TrackingEvent.clickNavigationBar(HeaderAction.CREATE);
  };

  return (
    <StyledOpener
      ref={ref}
      inverseColor={inverseColor}
      isLoggedIn={isLoggedIn}
      onClick={handleTracking}
      {...openerProps}
    >
      {t("menu.create")}
      <IconAdd />
    </StyledOpener>
  );
});

const getItems = (market: string) => {
  const {
    CREATE_LISTING_NEW_MENU,
    CREATE_RENTABLE,
    CREATE_CONTRACT,
    CREATE_MOVING_REPORT,
    CREATE_RENT,
    CREATE_TENANCY,
  } = menuTranslations();

  const items = [
    {
      name: CREATE_LISTING_NEW_MENU,
      url: App.settings.routes.property_owner.create_listing,
      icon: <IconRentables />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.ADVERTISE_RENTABLE,
      context: SignupContext.CREATE_RENTABLE,
    },
    {
      name: CREATE_RENTABLE,
      url: App.settings.routes.property_owner.my_rentables,
      icon: <IconLogo />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_RENTABLE,
      context: SignupContext.CREATE_RENTABLE,
    },
    {},
    {
      name: CREATE_CONTRACT,
      url: `${App.settings.routes.contracts}choose-rentable`,
      icon: <IconDocumentTextOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_CONTRACT,
      context: SignupContext.CONTRACTS,
    },
    {
      name: CREATE_MOVING_REPORT,
      url: `${App.settings.routes.moving_reports}choose-rentable`,
      icon: <IconArrowBackForward />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_MOVING_REPORT,
      context: SignupContext.MOVING_REPORTS,
    },
    {
      name: CREATE_TENANCY,
      url: `${App.settings.routes.tenancy_list}choose-rentable`,
      icon: <IconPeopleOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_TENANCY,
      context: SignupContext.CREATE_TENANCY,
    },
  ];

  if (market === Market.BOLIGPORTAL_DK) {
    items.push({
      name: CREATE_RENT,
      url: `${App.settings.routes.property_owner.rent}create`,
      icon: <IconServerOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_RENT,
      context: SignupContext.CREATE_RENT_COLLECTION,
    });
  }

  return items;
};

export const TopNavItemCreateMenu = ({
  inverseColor,
  isLoggedIn,
  onItemClick,
}: ItemProps) => {
  const items = getItems(App.settings.market);

  return (
    <Menu<MenuItem>
      items={items}
      openerEl={() => (
        <Opener
          inverseColor={inverseColor}
          isLoggedIn={isLoggedIn}
        />
      )}
      onMenuItemSelect={onItemClick}
      verticalContentPadding={1}
      verticalSeparatorPadding={1}
    />
  );
};
