import * as React from "react";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  IconCrop,
  IconTrashOutline,
  ImageSliderOverlay,
  styled,
  Tooltip,
} from "@boligportal/juice";
import { EditImageMain } from "features/cs/edit_image_feature/EditImageMain";
import Floorplan from "media/svg/Floorplan";
import { useCustomerServiceAdDetailPage } from "../../ad_detail_page";
import { AdImageInterface } from "../../interfaces";
import {
  AdImageDeletionTask,
  AdImageRemoveManualFloorPlanTask,
  AdImageReorderTask,
  AdImageSetManualFloorPlanTask,
} from "../../types";

type ThumbProps = {
  url: string;
  index: number;
  onClick: (index: number) => void;
};

const Thumb = ({ index, onClick, url }: ThumbProps) => (
  <img
    style={{
      width: 160,
      height: 160,
      objectFit: "cover",
    }}
    src={url}
    onClick={() => onClick(index)}
  />
);

const StyledTagPosition = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const StyledTagPositionBottom = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const DraggableDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

type ThumbItemProps = {
  image: AdImageInterface;
  index: number;
  onPreview: (index: number) => void;
  onDelete: (image: AdImageInterface) => void;
  onMarkAsFloorPlan: (image: AdImageInterface) => void;
  onRemoveAsFloorPlan: (image: AdImageInterface) => void;
  onEdit: (image: AdImageInterface) => void;
  onMove: (fromIndex: number, toIndex) => void;
  historicDetailsDate?: string;
};

const ThumbItem = ({
  image,
  index,
  onDelete,
  onEdit,
  onMove,
  onMarkAsFloorPlan,
  onRemoveAsFloorPlan,
  onPreview,
  historicDetailsDate,
}: ThumbItemProps) => {
  const handleThumbClick = () => onPreview(index);
  const handleButtonClick = () => onDelete(image);
  const editButtonClickHandler = () => onEdit(image);
  const markAsFloorPlanButtonClickHandler = () => onMarkAsFloorPlan(image);
  const removeAsFloorPlanButtonClickHandler = () => onRemoveAsFloorPlan(image);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData("index", `${index}`);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const fromIndex = Number(event.dataTransfer.getData("index"));
    const toIndex = index;
    if (fromIndex === toIndex) {
      return;
    }
    onMove(fromIndex, toIndex);
  };

  const isNew = React.useMemo(() => {
    if (historicDetailsDate) {
      const imageDate = new Date(image.date_created).getTime();
      const historicDetailsDateTime = new Date(historicDetailsDate).getTime();
      return imageDate > historicDetailsDateTime;
    } else {
      return null;
    }
  }, [image.date_created, historicDetailsDate]);

  return (
    <DraggableDiv
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Box
        p={1}
        bg="tint"
      >
        <Thumb
          url={image.url}
          index={index}
          onClick={handleThumbClick}
        />
      </Box>
      <Box
        bg="tint"
        px={1}
        pb={1}
      >
        {isNew && (
          <StyledTagPosition>
            <Badge state="warning">NEW</Badge>
          </StyledTagPosition>
        )}
        {image.is_floor_plan && (
          <StyledTagPositionBottom>
            <Badge state="warning">Floor Plan</Badge>
          </StyledTagPositionBottom>
        )}
        <Flex>
          <Tooltip text="Delete image">
            <Button
              variant="subtle"
              icon={IconTrashOutline}
              onClick={handleButtonClick}
            />
          </Tooltip>
          <Tooltip text="Enter Crop mode">
            <Button
              variant="subtle"
              icon={IconCrop}
              onClick={editButtonClickHandler}
            />
          </Tooltip>
          <Tooltip
            text={
              image.is_floor_plan
                ? "Remove as floor plan"
                : "Mark as floor plan"
            }
          >
            <Button
              variant="subtle"
              onClick={
                image.is_floor_plan
                  ? removeAsFloorPlanButtonClickHandler
                  : markAsFloorPlanButtonClickHandler
              }
            >
              <Floorplan color="#333" />
            </Button>
          </Tooltip>
        </Flex>
      </Box>
    </DraggableDiv>
  );
};

type ImagesFormElementProps = {
  images: AdImageInterface[];
  imageDeletionTask: AdImageDeletionTask;
  imageReorderTask: AdImageReorderTask;
  imageSetManualFloorPlanTask: AdImageSetManualFloorPlanTask;
  imageRemoveManualFloorPlanTask: AdImageRemoveManualFloorPlanTask;
  historicDetailsDate?: string;
};

export const ImagesFormElement = ({
  imageDeletionTask,
  imageReorderTask,
  imageSetManualFloorPlanTask,
  imageRemoveManualFloorPlanTask,
  images,
  historicDetailsDate,
}: ImagesFormElementProps) => {
  const { adId, requestRefetch } = useCustomerServiceAdDetailPage();
  const [showFullscreen, setShowFullscreen] = React.useState(false);
  const [selectedEditId, setSelectedEditId] = React.useState<number | null>(
    null,
  );

  const initialIndexRef = React.useRef(0);

  const handleThumbPreview = (index: number) => {
    initialIndexRef.current = index;
    setShowFullscreen(true);
  };

  const handeDelete = (image: AdImageInterface) => {
    const wasConfirmed = confirm("Are you sure you want to delete the image?");
    if (wasConfirmed) {
      imageDeletionTask(image.id);
    }
  };

  const handleSetManualFloorPlan = (image: AdImageInterface) => {
    const wasConfirmed = confirm(
      "Are you sure you want to set this image as the manual floor plan?",
    );
    if (wasConfirmed) {
      imageSetManualFloorPlanTask(image.id);
    }
  };

  const handleRemoveManualFloorPlan = (image: AdImageInterface) => {
    const wasConfirmed = confirm(
      "Are you sure you want to remove this image as the manual floor plan?",
    );
    if (wasConfirmed) {
      imageRemoveManualFloorPlanTask(image.id);
    }
  };

  const editImageCancelHandler = () => {
    setSelectedEditId(null);
    requestRefetch();
  };

  const handleMove = (fromIndex: number, toIndex: number) => {
    const imageIds = images.map((image) => image.id);
    const [item] = imageIds.splice(fromIndex, 1);

    imageIds.splice(toIndex, 0, item);
    imageReorderTask(imageIds);
  };

  const handleExitFullscreen = () => {
    setShowFullscreen(false);
  };

  return (
    <div>
      <Flex
        gap
        wrap
      >
        {images.map((image, index) => (
          <ThumbItem
            key={`Thumb_${image.id}`}
            image={image}
            index={index}
            onPreview={handleThumbPreview}
            onDelete={handeDelete}
            onEdit={() => {
              setSelectedEditId(image.id);
            }}
            onMarkAsFloorPlan={handleSetManualFloorPlan}
            onRemoveAsFloorPlan={handleRemoveManualFloorPlan}
            onMove={handleMove}
            historicDetailsDate={historicDetailsDate}
          />
        ))}
      </Flex>

      {showFullscreen && (
        <ImageSliderOverlay
          initialIndex={initialIndexRef.current}
          images={images.map((image) => image.url)}
          onClose={handleExitFullscreen}
        />
      )}
      {adId && selectedEditId && (
        <Dialog
          slideOver
          open
          size="large"
          onClose={editImageCancelHandler}
        >
          <DialogHeader>Image Crop</DialogHeader>
          <DialogContent>
            {images.map((image) => (
              <EditImageMain
                selectedEditId={selectedEditId}
                key={`EditImageMain_${image.id}`}
                image={image}
                adId={adId}
              />
            ))}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
