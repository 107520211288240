import { useContext } from "react";
import { SetProfileNameDialog } from "apps/user_settings/profile_picture_and_name/set_profile_name_dialog";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { useUser } from "components/Providers/UserProvider";
import { SendMessageToAdModal } from "components/design-system/organisms/send_message_to_ad_modal/send_message_to_ad_modal";
import { Ad } from "components/interfaces/ad";
import { Profile, USER_TYPE } from "components/interfaces/profile";
import { modalActions } from "components/modal_controller";

type Props = {
  ad: Ad;
  profile: Profile;
  hideUpdateContactsDialog?: boolean;
  onSuccess?: () => void;
};

export const ContactLandlordModal = ({
  ad,
  profile,
  hideUpdateContactsDialog,
  onSuccess,
}: Props) => {
  const appActions = useAppActions();
  const { user } = useUser();
  const { closeModal } = useContext(modalActions);

  if (!user) {
    return null;
  }

  if (
    profile.user_type !== USER_TYPE.COMPANY &&
    (user.first_name === "" || user.last_name === "")
  ) {
    return (
      <SetProfileNameDialog
        user={user}
        profile={profile}
        isOpen
        onClose={(isNameChanged?: boolean) => {
          if (!isNameChanged) {
            closeModal();
          }
        }}
      />
    );
  }

  return (
    <SendMessageToAdModal
      ad={ad}
      appActions={appActions}
      hideUpdateContactsDialog={hideUpdateContactsDialog}
      onSend={onSuccess}
    />
  );
};
