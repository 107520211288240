import * as React from "react";
import {
  Box,
  Flex,
  IconChevronDown,
  IconChevronUp,
  Text,
  styled,
  focusBorder,
  useMedia,
} from "@boligportal/juice";
import { useGetRentCollectionNotificationCount } from "components/RentCollectionNotificationBadge";
import { Badge } from "components/design-system/atoms/badge/badge";
import { User } from "components/interfaces/user";
import { ProfileImage } from "components/profile_image";
import { useAuthenticatedUserPolling } from "features/polling/AuthenticatedUserPollingProvider";

interface Props {
  isOpened: boolean;
  inverseColor: boolean;
  user: User;
}

export const AvatarMenuOpener = React.forwardRef<any, Props>((props, ref) => {
  const { isOpened, inverseColor, user, ...rest } = props;
  return (
    <StyledMenuButton
      aria-label="User dropdown"
      data-test-id="userDropdownMenuLink"
      expanded={isOpened}
      inverted={inverseColor}
      ref={ref}
      {...rest}
    >
      <Flex align="center">
        <NotificationCounterWrapperOnMobile>
          <ProfileImage
            user={user}
            size={37}
            inverseColor={inverseColor}
          />
        </NotificationCounterWrapperOnMobile>

        {user.first_name && (
          <Box
            maxWidth={"140px"}
            truncate
            hidden={{
              xs: true,
              xl: false,
            }}
          >
            <Text
              color={inverseColor ? "inverted" : "base"}
              pl={1}
            >
              {user.first_name}
            </Text>
          </Box>
        )}
        <Box
          ml={1}
          mr={1}
        >
          {isOpened ? (
            <IconChevronUp
              color={inverseColor ? "inverted" : "base"}
              size="medium"
            />
          ) : (
            <IconChevronDown
              color={inverseColor ? "inverted" : "base"}
              size="medium"
            />
          )}
        </Box>
      </Flex>
    </StyledMenuButton>
  );
});

const NotificationCounterWrapperOnMobile = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const media = useMedia(0);

  if (media.lg.down) {
    return <NotificationBadgeCounter>{children}</NotificationBadgeCounter>;
  }

  return <>{children}</>;
};

const NotificationBadgeCounter = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { localStorageValue } = useGetRentCollectionNotificationCount();
  const { unseenThreadCount } = useAuthenticatedUserPolling();

  const joinedCount = localStorageValue + unseenThreadCount;
  if (joinedCount === 0) {
    return <>{children}</>;
  }
  return <Badge count={joinedCount}>{children}</Badge>;
};

const StyledMenuButton = styled.button<{
  expanded?: boolean;
  inverted?: boolean;
}>`
  -webkit-appearance: none;
  background: transparent;
  padding: 0;

  color: ${(props) =>
    props.inverted
      ? props.theme.color.text.inverted
      : props.theme.color.text.base};

  /*
    This targets the Background fill in the svg code inside UserSilhouette.tsx
  */
  .UserIcon__Background {
    fill: ${(props) => props.theme.colorPalette.primary[50]};

    &--inverse {
      fill: rgba(255, 255, 255, 0.25);
    }
  }

  border-radius: 30px;
  border: ${(props) =>
    props.expanded
      ? props.inverted
        ? "1px solid rgba(255, 255, 255, 0.5)"
        : "1px solid rgba(0, 0, 0, 0.08)"
      : props.inverted
        ? "1px solid rgba(255, 255, 255, 0)"
        : "1px solid rgba(0, 0, 0, 0)"};

  &:hover {
    color: ${(props) =>
      props.inverted
        ? props.theme.colorPalette.gray[40]
        : props.theme.colorPalette.gray[300]};
    border: ${(props) =>
      props.expanded
        ? props.inverted
          ? "1px solid rgba(255, 255, 255, 0.5)"
          : "1px solid rgba(0, 0, 0, 0.08)"
        : props.inverted
          ? "1px solid rgba(255, 255, 255, 0)"
          : "1px solid rgba(0, 0, 0, 0)"};

    /*
      This targets the Background fill in the svg code inside UserSilhouette.tsx
    */
    .UserIcon__Background {
      fill: ${(props) => props.theme.colorPalette.brand[500]};

      &--inverse {
        fill: rgba(255, 255, 255, 0.35);
      }
    }
  }

  ${focusBorder};
`;
