import { reserveAd } from "lib/customerServiceApi";
import { ReserveAdResponseInterface } from "../../../interfaces";

export async function reserveAdTask(
  adId: number,
): Promise<ReserveAdResponseInterface> {
  try {
    const res = await reserveAd(adId);
    return res;
  } catch (e) {
    return {
      success: false,
      errors: e,
    };
  }
}
