import { useState } from "react";
import { Box, DataTable, TextField } from "@boligportal/juice";
import { Company } from "../interfaces";

const CompanySelector = ({
  companies,
  onCompanySelected,
}: {
  companies: Company[];
  onCompanySelected: (company: Company) => Promise<void>;
}) => {
  const [filter, setFilter] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChoose = async (company: Company) => {
    setSubmitting(true);
    try {
      await onCompanySelected(company);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Box>
        <TextField
          label="Filter by company name or ID"
          onChange={(event) => setFilter(event.target.value)}
        />
      </Box>
      <Box>
        <DataTable<Company>
          fullscreen
          items={companies.filter(
            (item) =>
              item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
              item.id.toString() === filter,
          )}
          keyField="id"
          columns={[
            {
              alignRight: true,
              fieldName: "id",
              fieldLabel: "ID",
            },
            {
              fieldName: "name",
              fieldLabel: "Name",
            },
          ]}
          rowActions={[
            {
              label: "Choose",
              callback: handleChoose,
              disableFunction: () => isSubmitting,
            },
          ]}
        />
      </Box>
    </>
  );
};

export { CompanySelector };
