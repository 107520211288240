import { Box, Flex, Text } from "@boligportal/juice";
import { App } from "components/app";
import { formatCurrency } from "lib/utils";

interface Props {
  amount: number;
  appendLabel?: string;
  label?: string;
  emphasizeAmount?: "base" | "semiBold" | "bold" | "headline";
  alignRight?: boolean;
  size?: "small" | "body" | "h1" | "h2" | "h3" | "h4" | "h5" | "tiny" | "lead";
  errorText?: string;
  noMarginLeft?: boolean;
  labelWeight?: "base" | "semiBold" | "bold" | "headline";
  amountSize?:
    | "small"
    | "body"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "tiny"
    | "lead";
}

export const CurrencyAmountLabel = ({
  size,
  amount,
  appendLabel,
  label,
  emphasizeAmount,
  alignRight,
  errorText,
  noMarginLeft,
  labelWeight,
  amountSize,
}: Props) => (
  <Flex
    column
    justify={alignRight ? "end" : "start"}
  >
    <Flex
      align="baseline"
      ml={noMarginLeft ? "unset" : "auto"}
    >
      {label && (
        <Box pr={1}>
          <Text
            weight={labelWeight ?? "headline"}
            size={size ?? "small"}
          >
            {label}
          </Text>
        </Box>
      )}

      <Text
        weight={emphasizeAmount ? emphasizeAmount : "base"}
        size={amountSize ?? "small"}
      >
        {formatCurrency(amount, App.settings.currency)}
      </Text>

      {appendLabel && (
        <Text
          weight={emphasizeAmount ? emphasizeAmount : "base"}
          size={size ?? "small"}
        >
          {appendLabel}
        </Text>
      )}
    </Flex>

    {Boolean(errorText) && (
      <Flex justify="end">
        <Text
          block
          color="danger"
          size="small"
        >
          {errorText}
        </Text>
      </Flex>
    )}
  </Flex>
);
