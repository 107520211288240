import { useState, useEffect } from "react";
import { getRentables } from "api";

export const useHasRentables = () => {
  const [hasRentables, setHasRentables] = useState<boolean>();

  useEffect(() => {
    const findRentalAds = async () => {
      const rentables = await getRentables({
        filters: {
          sorting: "address",
          descending: true,
        },
        limit: 1,
        start_index: 0,
      });
      setHasRentables(rentables.all_count > 0);
    };
    findRentalAds();
  }, []);

  return hasRentables;
};
