import { AdStates } from "lib/enums";
import { AdsPageAll } from "./ads_page_all";

const AdsPageWaitingForApproval = () => (
  <AdsPageAll
    initialStates={[AdStates.INACTIVE_PENDING_APPROVAL]}
    initialNeedsFollowUp={false}
    actionLabelText={"Begin approval"}
    companyAds={false}
  />
);

export { AdsPageWaitingForApproval };
