import {
  Flex,
  Box,
  FlexColumn,
  Text,
  Hidden,
  styled,
  useTheme,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { isBoligPortal } from "lib/utils";
import { LandingPageContainer } from "./LandingPageContainer";
import { PageBlock } from "./PageBlock";
import { ScalabaleHeadline } from "./ScalabaleHeadline";

const Statement = ({
  headline,
  text,
  mb,
}: {
  headline: string;
  text: string;
  mb: boolean;
}) => (
  <Flex
    column
    mb={mb ? 4 : 0}
  >
    <Box mb={1}>
      <Text
        size="h3"
        weight="bold"
        color="branded"
      >
        {headline}
      </Text>
    </Box>

    <Text>{text}</Text>
  </Flex>
);

const StyledOverflow = styled.div`
  overflow: hidden;
`;

const FindTenantSection = ({
  headline,
  text,
  s1Headline,
  s1Text,
  s2Headline,
  s2Text,
  s3Headline,
  s3Text,
}: {
  headline: string;
  text: string;
  s1Headline: string;
  s1Text: string;
  s2Headline: string;
  s2Text: string;
  s3Headline: string;
  s3Text: string;
}) => {
  const findTenantMockup = isBoligPortal()
    ? "BoligPortal_FindTenant.png"
    : "BostadsPortal_FindTenant.png";
  const theme = useTheme();

  return (
    <StyledOverflow>
      <PageBlock
        bg="default"
        py="extraLarge"
      >
        <LandingPageContainer>
          <Flex
            gap={10}
            mt={{
              xs: 0,
              lg: 8,
            }}
            mb={{
              xs: 0,
              lg: 8,
            }}
            wrap
          >
            <FlexColumn
              size={{
                xs: 12,
                lg: 5,
              }}
            >
              <Flex
                column
                mr={{
                  xs: 0,
                  lg: 6,
                }}
              >
                <Box
                  textAlign={{
                    xs: "center",
                    lg: "left",
                  }}
                  mb={2}
                >
                  <ScalabaleHeadline>{headline}</ScalabaleHeadline>
                </Box>

                <Box
                  textAlign={{
                    xs: "center",
                    lg: "left",
                  }}
                  mb={4}
                >
                  <Text size="h4">{text}</Text>
                </Box>

                {/* Desktop - text section */}
                <Box
                  hidden={{
                    xs: true,
                    lg: false,
                  }}
                >
                  <Statement
                    headline={s1Headline}
                    text={s1Text}
                    mb
                  />
                  <Statement
                    headline={s2Headline}
                    text={s2Text}
                    mb
                  />
                  <Statement
                    headline={s3Headline}
                    text={s3Text}
                    mb={false}
                  />
                </Box>
              </Flex>
            </FlexColumn>
            <FlexColumn
              css={{
                alignItems: "center",
              }}
              size={{
                xs: 12,
                lg: 7,
              }}
            >
              <Flex
                hidden={{
                  xs: true,
                  lg: false,
                }}
                height="100%"
                align="center"
              >
                <Box>
                  <CdnImage
                    style={{
                      boxShadow: theme.shadow.lg,
                      maxHeight: 600,
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                    src={`/static/images/landing_page/renting_out/${findTenantMockup}`}
                  />
                </Box>
              </Flex>
              <Hidden
                on={{
                  xs: false,
                  md: true,
                }}
              >
                <CdnImage
                  style={{
                    maxHeight: 330,
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                  src={`/static/images/landing_page/renting_out/${findTenantMockup}`}
                />
              </Hidden>
              <Hidden
                on={{
                  xs: true,
                  md: false,
                  lg: true,
                }}
              >
                <CdnImage
                  style={{
                    maxHeight: 440,
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                  src={`/static/images/landing_page/renting_out/${findTenantMockup}`}
                />
              </Hidden>
            </FlexColumn>
            {/* Mobile - text section */}

            <Box
              mt={8}
              hidden={{
                xs: false,
                lg: true,
              }}
            >
              <Statement
                headline={s1Headline}
                text={s1Text}
                mb
              />
              <Statement
                headline={s2Headline}
                text={s2Text}
                mb
              />
              <Statement
                headline={s3Headline}
                text={s3Text}
                mb={false}
              />
            </Box>
          </Flex>
        </LandingPageContainer>
      </PageBlock>
    </StyledOverflow>
  );
};

export { FindTenantSection };
