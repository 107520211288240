import { Box, Flex, Text } from "@boligportal/juice";

type Props = {
  createButtonSlot: React.ReactNode;
  heading: string;
  infoText: {
    title: string;
    content: React.ReactNode;
  } | null;
};

export const DefectsListFlowInfo = ({
  heading,
  infoText,
  createButtonSlot,
}: Props) => (
  <Flex
    justify="space-between"
    column={{
      xs: true,
      md: false,
    }}
  >
    <Box>
      <Box>
        <Text
          weight="bold"
          size="h4"
          mb={1}
          block
        >
          {heading}
        </Text>
      </Box>
      {infoText && (
        <Text>
          {infoText.title}. {infoText.content}
        </Text>
      )}
    </Box>
    {createButtonSlot && (
      <Box
        pl={{
          xs: 0,
          md: 4,
        }}
        mt={{
          xs: 2,
          md: 0,
        }}
      >
        {createButtonSlot}
      </Box>
    )}
  </Flex>
);
