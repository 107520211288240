import { UseFormRegister } from "react-hook-form";
import { Box, CheckField, Flex, Labelled } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";

export const SignUpForm = (props: {
  register: UseFormRegister<any>;
  showError?: boolean;
}) => {
  const { register, showError } = props;

  return (
    <Box>
      <CheckField
        {...register("signupPermissions.send_newsletter")}
        label={t("signup_form.Sign me up to newsletter")}
      />
      <Flex align="center">
        <CheckField
          {...register("signupPermissions.send_relevant_content")}
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: t("signup_form.send_me_relevant_content"),
              }}
            />
          }
        />
      </Flex>

      <Labelled
        errorText={
          showError ? t("You must accept the terms and conditions.") : ""
        }
      >
        <CheckField
          {...register("signupPermissions.accepted_terms")}
          required
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: t("signup_form.agree_to_terms", {
                  termsRoute: App.settings.routes.terms,
                }),
              }}
            />
          }
          testId={"signupAcceptedTerms"}
        />
      </Labelled>
    </Box>
  );
};
