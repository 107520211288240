import { useState } from "react";
import { Button, Flex, Text, TextField } from "@boligportal/juice";
import { DialogModalBody, DialogModalFooter } from "components/dialog_modal";
import { t } from "lib/i18n";

interface MessageWriterProps {
  onSend: () => void;
  onMessageChange: (value: string) => void;
  message: string;
  disabled: boolean;
}

const MessageWriter = ({
  onSend,
  onMessageChange,
  disabled,
  message,
}: MessageWriterProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleButtonClick = () => {
    if (message === "") {
      setErrorMessage(t("contact_landlord_modal.error.empty_message"));
    } else {
      onSend();
    }
  };

  const handleTextinputChange = (event) => {
    if (errorMessage) {
      setErrorMessage(null);
    }

    onMessageChange(event.currentTarget.value);
  };

  return (
    <>
      <DialogModalBody>
        <TextField
          multiLine={8}
          autoFocus
          disabled={disabled}
          value={message}
          onChange={handleTextinputChange}
          placeholder={t("message_writer.textbox.placeholder")}
        />
        <Text
          block
          as="em"
          mt={0.5}
          color="muted"
          size="small"
        >
          {t("message_writer.description")}
        </Text>
        {errorMessage && (
          <Text
            block
            as="em"
            mt={0.5}
            color="danger"
            size="small"
          >
            {errorMessage}
          </Text>
        )}
      </DialogModalBody>
      <DialogModalFooter>
        <Flex
          column
          width="100%"
        >
          <Button
            variant="branded"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            {t("message_writer.send_button.text")}
          </Button>
        </Flex>
      </DialogModalFooter>
    </>
  );
};

export { MessageWriter };
