import { Fragment } from "react";
import { Box, Flex, Spinner, styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { NewBuildAdCard } from "./cards/NewBuildsAdCard/NewBuildsAdCard";
import { AdCard } from "./cards/ad_card";
import { Ad } from "./interfaces/ad";

const StyledText = styled(Text)`
  cursor: pointer;
  text-decoration: underline !important;
  text-decoration-color: ${(props) => props.theme.color.text.muted} !important;

  &:hover {
    text-decoration: none !important;
  }
`;

const StyledFlex = styled.div<{ gapFlex: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.gapFlex}px;
`;

const StyledAdRow = styled.div<{ gapFlex: number; adsPerRow: number }>`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: calc(
      (
          100% -
            (${(props) => props.adsPerRow - 1} * ${(props) => props.gapFlex}px)
        ) / ${(props) => props.adsPerRow}
    );
  }
`;

interface ListingsDisplayProps {
  adsPerRow: number;
  promotedListings?: Ad[];
  canLoadMorePromotedListings?: boolean;
  listings: Ad[];
  onAdClicked?: (Ad, index) => void;
  loadingListings?: boolean;
  loadingPromotedListings?: boolean;
  fetchNewPromotedAds?: () => void;
  openListingInNewTab?: boolean;
  favouriteTrackingPlacementLabel: FavouriteAdButtonPlacementTracking;
  disableFavouriteFeature?: boolean;
  shouldIncludeNewBuilds?: boolean;
}

const StyledCurtain = styled(Box)`
  z-index: 500;
  position: absolute;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
`;

const Curtain = () => (
  <StyledCurtain>
    <Box mt={10}>
      <Spinner size="large" />
    </Box>
  </StyledCurtain>
);

export const RelativeBox = styled(Box)`
  position: relative;
`;

export const ListingsDisplay = ({
  adsPerRow,
  promotedListings,
  canLoadMorePromotedListings,
  listings,
  onAdClicked,
  loadingListings,
  loadingPromotedListings,
  fetchNewPromotedAds,
  openListingInNewTab,
  favouriteTrackingPlacementLabel,
  disableFavouriteFeature,
  shouldIncludeNewBuilds = false,
}: ListingsDisplayProps) => {
  //Logic that avoid showing an uncompleted row of ads
  function dynamicRowSortingWithNewBuilds(
    listings: Ad[],
    numberOfNormalAdsPerRow: number,
  ): Ad[] {
    const newBuildAds = listings.filter((listing) => listing.is_newbuild);
    const normalAds = listings.filter((listing) => !listing.is_newbuild);

    if (newBuildAds.length === 0) {
      return normalAds;
    }

    if (normalAds.length === 0) {
      return newBuildAds;
    }

    const result: Ad[] = [];

    for (let i = 0; i < listings.length; i++) {
      if (i % (numberOfNormalAdsPerRow + 1) === 0 && newBuildAds.length !== 0) {
        result.push(newBuildAds.shift() as Ad);
      }

      if (i % (numberOfNormalAdsPerRow + 1) !== 0 && normalAds.length !== 0) {
        result.push(normalAds.shift() as Ad);
      }
    }

    result.push(...newBuildAds);
    result.push(...normalAds);

    return result;
  }

  const gapFlexValuePx = 16;

  const newOrderedListings = dynamicRowSortingWithNewBuilds(
    listings,
    adsPerRow,
  );

  const handleClick = (listing: Ad, index: number) => {
    if (onAdClicked) {
      onAdClicked(listing, index);
    }
  };

  return (
    <>
      <RelativeBox pb={2}>
        {loadingPromotedListings && <Curtain />}
        {promotedListings && (
          <>
            <StyledFlex gapFlex={gapFlexValuePx}>
              {promotedListings.map((listing, index) => (
                <Fragment key={listing.id}>
                  {!listing.is_newbuild ? (
                    <StyledAdRow
                      gapFlex={gapFlexValuePx}
                      adsPerRow={adsPerRow}
                    >
                      <AdCard
                        isPromoted
                        ad={listing}
                        morePromotedAdsButton={
                          canLoadMorePromotedListings && index === 2
                        }
                        onClick={() => {
                          handleClick(listing, index);
                        }}
                        onFetchNewPromotedAds={fetchNewPromotedAds}
                        linkTarget={openListingInNewTab ? "_blank" : "_self"}
                        placement={favouriteTrackingPlacementLabel}
                        isFavouriteDisabled={disableFavouriteFeature}
                      />
                    </StyledAdRow>
                  ) : null}
                </Fragment>
              ))}
            </StyledFlex>
            {canLoadMorePromotedListings && (
              <Flex
                hidden={{
                  xs: true,
                  md: false,
                }}
                justify="start"
              >
                <a onClick={fetchNewPromotedAds}>
                  <StyledText color="muted">
                    {t("srp.load_more_promoted_ads")}
                  </StyledText>
                </a>
              </Flex>
            )}
          </>
        )}
      </RelativeBox>

      <RelativeBox>
        {loadingListings && <Curtain />}
        <StyledFlex gapFlex={gapFlexValuePx}>
          {newOrderedListings.map((listing, index) => (
            <Fragment key={listing.id}>
              {!listing.is_newbuild ? (
                <StyledAdRow
                  gapFlex={gapFlexValuePx}
                  adsPerRow={adsPerRow}
                >
                  <AdCard
                    ad={listing}
                    onClick={() => {
                      handleClick(listing, index);
                    }}
                    linkTarget={openListingInNewTab ? "_blank" : "_self"}
                    placement={favouriteTrackingPlacementLabel}
                    isFavouriteDisabled={disableFavouriteFeature}
                  />
                </StyledAdRow>
              ) : (
                shouldIncludeNewBuilds && (
                  <NewBuildAdCard
                    ad={listing}
                    linkTarget={openListingInNewTab ? "_blank" : "_self"}
                  />
                )
              )}
            </Fragment>
          ))}
        </StyledFlex>
      </RelativeBox>
    </>
  );
};
