import { Flex, IconEye, IconFlame, IconMail, Text } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { AdUtils } from "lib/ad";
import { timePassedSince } from "lib/date";
import { t } from "lib/i18n";

type Props = {
  ad: Ad;
  isPromoted: boolean;
};

export const ShortPrice = ({ ad, isPromoted }: Props) => {
  const {
    monthly_rent,
    monthly_rent_currency,
    advertised_date,
    is_contacted,
    is_seen,
  } = ad;

  const monthlyRent = `${AdUtils.formatCostsTwoDecimalsCurrency(
    monthly_rent,
    false,
  )} ${monthly_rent_currency}.`;
  const advertisedDate = timePassedSince(new Date(advertised_date));

  const renderStatus = () => {
    if (is_contacted) {
      return (
        <Text
          size="tiny"
          color="success"
        >
          <IconMail inline /> {t("ad_card.contacted")}
        </Text>
      );
    }

    if (isPromoted) {
      return (
        <Text
          size="tiny"
          color="branded"
        >
          <IconFlame inline /> {t("ad_card.promoted")}
        </Text>
      );
    }

    if (is_seen) {
      return (
        <Text
          size="tiny"
          color="base"
        >
          <IconEye inline /> {t("ad_card.seen")}
        </Text>
      );
    }

    return (
      <Text
        size="tiny"
        color="muted"
      >
        {advertisedDate}
      </Text>
    );
  };

  return (
    <Flex
      justify="space-between"
      align="baseline"
      px={2}
      mt={1}
      mb={2}
    >
      <Text weight="headline">{monthlyRent}</Text>

      {renderStatus()}
    </Flex>
  );
};
