export type ConstructReturnUrlOptions = {
  adId: number | null;
  rentableId: number | null;
  uniquePaywallKey?: string;
  document: Document;
};

export const UNIQUE_PAYWALL_QUERY_KEY = "usecase";
export const PAYWALL_PAYMENT_METHOD_ID_QUERY_KEY = "payment_method_id"; // This query key is added by the backend
export const PAYWALL_PAYMENT_PLANS_QUERY_KEY = "payment_plans"; // This query key is added by the backend

export const constructReturnUrl = ({
  adId,
  rentableId,
  uniquePaywallKey,
  document,
}: ConstructReturnUrlOptions) => {
  let returnUrl = document.location.href;

  if (adId && rentableId) {
    const params = new URLSearchParams(document.location.search);
    params.append("ad_id", adId.toString());
    params.append("rentable_id", rentableId.toString());
    returnUrl = `${document.location.origin}${document.location.pathname}?${params}`;
  }

  if (uniquePaywallKey) {
    const separator = returnUrl.includes("?") ? "&" : "?";
    returnUrl = `${returnUrl}${separator}${UNIQUE_PAYWALL_QUERY_KEY}=${uniquePaywallKey}`;
  }

  return returnUrl;
};

export const removeBrowserQueryVariables = (replacementKeys: string[]) => {
  // eslint-disable-next-line compat/compat
  const currentUrl = new URL(window.location.href);

  replacementKeys.forEach((key) => {
    currentUrl.searchParams.delete(key);
  });

  history.replaceState(
    {},
    document.title,
    currentUrl.pathname + currentUrl.search,
  );
};
