/* eslint-disable */
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CheckFieldNaked,
  Flex,
  styled,
  Text,
  Link,
  IconArrowForward,
  Divider,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { trackError } from "lib/tracking/errors";
import { EventCategory } from "lib/tracking/events";
import { App } from "../../components/app";
import { HedvigMessageHandler } from "./HedvigIframe";
import { HedvigIframeWrapper } from "./HedvigIframeWrapper";

type HedvigFormData = {
  acceptConsent: boolean;
};

const DefaultSubmitButton = () => (
  <LeadSubmitButton
    fullWidth
    variant="subtle"
    type="submit"
  >
    {t("leads.hedvig.submit_button.text")}
    <IconArrowForward
      inline
      size="medium"
      ml={1}
    />
  </LeadSubmitButton>
);

export const HedvigForm = ({
  iframeUrlResolver,
  eventLabel,
  onLeadComplete,
  buttons = <DefaultSubmitButton />,
  isShownInDialog,
}: {
  eventLabel: string;
  onLeadComplete: () => Promise<void>;
  iframeUrlResolver: () => Promise<{ iframe_url: string }>;
  cancelButton?: React.ReactNode;
  submitButton?: React.ReactNode;
  buttons?: React.ReactNode;
  isShownInDialog?: boolean;
}) => {
  return null;

  /*
  const [iframeUrl, setIframeUrl] = React.useState<string>();
  const [showIframe, setShowIframe] = React.useState(false);
  const { register, watch, handleSubmit, setValue, formState: { errors, isSubmitSuccessful } } = useForm<HedvigFormData>({
    defaultValues: {
      acceptConsent: false,
    },
  });

  const didConsent = watch("acceptConsent");

  const trackLeadSubmitted = React.useCallback(() => {
    Tracking.trackEvent({
      category: EventCategory.leads,
      label: eventLabel,
      action: "hedvig lead successfully submitted",
      isInteraction: true,
    });
  }, [eventLabel]);

  React.useEffect(() => {
    iframeUrlResolver()
      .then(res => setIframeUrl(res.iframe_url))
      .catch(trackError);
  }, []);

  const onMessage: HedvigMessageHandler = message => {
    switch (message.data.status) {
      case "close":
        setShowIframe(false);
        break;
      case "success": {
        trackLeadSubmitted();
        onLeadComplete()
          .catch(trackError);
      }
    }
  };

  const trackUserClicksGivesConsent = React.useCallback(() => {
    Tracking.trackEvent({
      category: EventCategory.leads,
      label: eventLabel,
      action: "consent activated",
      isInteraction: true,
    });
  }, [eventLabel]);

  React.useEffect(() => {
    if (didConsent) {
      trackUserClicksGivesConsent();
    }
  }, [didConsent, trackUserClicksGivesConsent]);

  const submitForm: SubmitHandler<HedvigFormData> = () => {
    setShowIframe(true);
  };

  const handleClose = () => {
    setShowIframe(false);
  };

  const toggleConsent = () => setValue("acceptConsent", !didConsent);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        p={2}
        bg="base"
      >
        <Flex
          justify={"space-between"}
          align="start"
          mb={1}
        >
          <Text weight="bold">{t("leads.hedvig.form.headline")}</Text>
          <HedvigLogo
            width={80}
            height={23}
            resolution="3x"
            src="/static/images/leads/hedvig/logo.png"
          />
        </Flex>

        <Text
          block
          size="small"
          mb={2}
        >
          {t("leads.hedvig.description")}
        </Text>

        <img
          style={{
            width: "100%",
          }}
          src="/static/images/leads/hedvig/promotion_pic.jpg"
        />

        <Flex
          align="center"
          border
          p={1}
          mt={2}
        >
          <CheckFieldNaked
            {...register("acceptConsent", {
              required: t("leads.hedvig.consent_required"),
            })}
          />
          <Text
            ml={2}
            block
            size="tiny"
          >
            <ConsentText onClick={toggleConsent}>
              {t("leads.hedvig.form.consent_text")}
            </ConsentText>
            <PrivacyPolicyLink
              color="text"
              newTab
              href={App.settings.routes.privacy}
            >
              {t("leads.hedvig.form.data_policy.text_link")}
            </PrivacyPolicyLink>
          </Text>
        </Flex>
        {errors.acceptConsent?.message && (
          <Text
            block
            mt={1.5}
            size="tiny"
            color="danger"
          >
            {errors.acceptConsent?.message}
          </Text>
        )}
      </Box>

      <Divider
        mx={isShownInDialog ? -3 : 0}
        my={0}
      />

      {buttons}

      <HedvigIframeWrapper
        src={iframeUrl}
        show={showIframe}
        onClose={handleClose}
        hasGivenConsent={isSubmitSuccessful}
        onMessage={onMessage}
      />
    </form>
  );
  */
};

const LeadSubmitButton = styled(Button)`
  background: ${(props) => props.theme.color.bg.base};
  height: 55px;
`;

const PrivacyPolicyLink = styled(Link)`
  display: block;
`;

const HedvigLogo = styled(CdnImage)`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

const ConsentText = styled.span`
  cursor: pointer;
`;
