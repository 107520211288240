import { Route } from "react-router-dom";
import { Flex, Card, CardSection } from "@boligportal/juice";
import { NewBuildDetailPage } from "apps/customer_service/newbuild_detail_page/NewBuildDetailPage";
import { BetalingsservicePage } from "apps/customer_service/pages/betalingsservice_page/betalingsservice_page";
import { CustomerServiceSettingsPage } from "apps/customer_service/pages/settings_page";
import {
  PAGE_HOME,
  PAGE_USERS,
  PAGE_USER_DETAIL,
  PAGE_PAYMENTS,
  PAGE_REFUNDS,
  PAGE_AD_DETAIL,
  PAGE_NEW_BUILD_DETAIL,
  PAGE_ADS_ALL,
  PAGE_ADS_WAITING_FOR_APPROVAL,
  PAGE_ADS_APPROVE_USER_CHANGES,
  PAGE_ADS_REJECTED,
  PAGE_ADS_APPROVED,
  PAGE_ADS_FOLLOW_UP,
  PAGE_CRAWLINGS,
  PAGE_CRAWLINGS_ADS,
  PAGE_CRAWLINGS_DRAFTS,
  PAGE_CRAWLING_DRAFT_DETAIL,
  PAGE_CRAWILNGS_NEEDS_REVIEW,
  PAGE_COMPANIES,
  PAGE_COMPANY_DETAIL,
  PAGE_COMPANY_ADS,
  PAGE_PROFILES_APPROVE_USER_CHANGES,
  PAGE_PAYMENTS_AND_AGREEMENTS,
  PAGE_EMAIL_LOGS,
  PAGE_BETALINGSSERVICE,
  PAGE_SETTINGS,
} from "../..";
import { PaymentsAndAgreements } from "../..//pages/payments_and_agreements_page";
import { AdDetailPage } from "../../pages/ad_detail_page/ad_detail_page";
import { AdsPageAll } from "../../pages/ads_page/ads_page_all";
import { AdsPageApproveUserChanges } from "../../pages/ads_page/ads_page_approve_user_changes";
import { AdsPageApproved } from "../../pages/ads_page/ads_page_approved";
import { AdsPageFollowUp } from "../../pages/ads_page/ads_page_follow_up";
import { AdsPageRejected } from "../../pages/ads_page/ads_page_rejected";
import { AdsPageWaitingForApproval } from "../../pages/ads_page/ads_page_waiting_for_approval";
import { CrawlingAdsPageAll } from "../../pages/ads_page/crawling_ads_page_all";
import { CrawlingAdsPendingApproval } from "../../pages/ads_page/crawling_ads_pending_approval";
import { CompaniesPage } from "../../pages/companies_page/companies_page";
import { CompanyAdsPage } from "../../pages/company_ads_page/compay_ads_page";
import { CompanyDetailPage } from "../../pages/company_detail_page/company_detail_page";
import { CrawlingDraftDetailPage } from "../../pages/crawlings/crawlings_detail_page/CrawlingDraftDetailPage";
import { CrawlingsDraftsPage } from "../../pages/crawlings/crawlings_drafts_page";
import { CrawlingsPage } from "../../pages/crawlings/crawlings_page";
import { EmailLogsPage } from "../../pages/email_logs_page/email_logs_page";
import { PaymentsPage } from "../../pages/payments_page/payments_page";
import { ProfilesPageApproveUserChanges } from "../../pages/profiles_page/profiles_page";
import { RefundsPage } from "../../pages/refunds_page/refunds_page";
import { UserDetailPage } from "../../pages/user_detail_page/user_detail_page";
import { UsersPage } from "../../pages/users_page/users_page";
import { DadJokes } from "./dad_jokes";

const Empty = () => (
  <Flex
    width="100%"
    align="center"
    bg="tint"
  >
    <Flex mx="auto">
      <Card sectioned>
        <CardSection title="Dad joke of the day">
          <DadJokes />
        </CardSection>
      </Card>
    </Flex>
  </Flex>
);

const ContentArea = () => (
  <>
    <Route
      path={PAGE_HOME}
      exact
      component={Empty}
    />
    <Route
      path={PAGE_USERS}
      exact
      component={UsersPage}
    />
    <Route
      path={PAGE_USER_DETAIL}
      exact
      component={UserDetailPage}
    />
    <Route
      path={PAGE_EMAIL_LOGS}
      exact
      component={EmailLogsPage}
    />
    <Route
      path={PAGE_BETALINGSSERVICE}
      exact
      component={BetalingsservicePage}
    />
    <Route
      path={PAGE_PAYMENTS}
      exact
      component={PaymentsPage}
    />
    <Route
      path={PAGE_REFUNDS}
      exact
      component={RefundsPage}
    />
    <Route
      path={PAGE_COMPANIES}
      exact
      component={CompaniesPage}
    />
    <Route
      path={PAGE_COMPANY_DETAIL}
      exact
      component={CompanyDetailPage}
    />
    <Route
      path={PAGE_COMPANY_ADS}
      exact
      component={CompanyAdsPage}
    />
    <Route
      path={PAGE_ADS_ALL}
      exact
      component={AdsPageAll}
    />
    <Route
      path={PAGE_ADS_WAITING_FOR_APPROVAL}
      exact
      component={AdsPageWaitingForApproval}
    />
    <Route
      path={PAGE_ADS_APPROVE_USER_CHANGES}
      exact
      component={AdsPageApproveUserChanges}
    />
    <Route
      path={PAGE_PROFILES_APPROVE_USER_CHANGES}
      exact
      component={ProfilesPageApproveUserChanges}
    />
    <Route
      path={PAGE_ADS_APPROVED}
      exact
      component={AdsPageApproved}
    />
    <Route
      path={PAGE_ADS_REJECTED}
      exact
      component={AdsPageRejected}
    />
    <Route
      path={PAGE_ADS_FOLLOW_UP}
      exact
      component={AdsPageFollowUp}
    />
    <Route
      path={PAGE_AD_DETAIL}
      exact
      render={({ match }) => <AdDetailPage key={match.params.id} />}
    />
    <Route
      path={PAGE_NEW_BUILD_DETAIL}
      exact
      render={({ match }) => <NewBuildDetailPage key={match.params.id} />}
    />
    <Route
      path={PAGE_CRAWLING_DRAFT_DETAIL}
      exact
      component={CrawlingDraftDetailPage}
    />
    <Route
      path={PAGE_CRAWLINGS_ADS}
      exact
      component={CrawlingAdsPageAll}
    />
    <Route
      path={PAGE_CRAWILNGS_NEEDS_REVIEW}
      exact
      component={CrawlingAdsPendingApproval}
    />
    <Route
      path={PAGE_CRAWLINGS_DRAFTS}
      exact
      component={CrawlingsDraftsPage}
    />
    <Route
      path={PAGE_CRAWLINGS}
      exact
      component={CrawlingsPage}
    />
    <Route
      path={PAGE_PAYMENTS_AND_AGREEMENTS}
      exact
      component={PaymentsAndAgreements}
    />
    <Route
      path={PAGE_SETTINGS}
      exact
      component={CustomerServiceSettingsPage}
    />
  </>
);

export { ContentArea };
