import { Box, Text, Flex } from "@boligportal/juice";
import { BlackListIndicator } from "../../../blacklist_indicator/BlackListIndicator";
import { ISearchItemUser } from "../interfaces";

type Props = {
  data: ISearchItemUser;
};

const SearchItemUser = ({ data }: Props) => {
  const { username, fullname, id, ban_reasons } = data;

  return (
    <Flex
      px={1}
      py={0.5}
    >
      <Box>
        <BlackListIndicator
          showTooltip={false}
          reasons={ban_reasons}
        />
        <Text size="small">{`${username} - ${fullname}`}</Text>
      </Box>
      <Box flexPush>
        <Text
          size="small"
          weight="bold"
        >{`#${id}`}</Text>
      </Box>
    </Flex>
  );
};

export { SearchItemUser };
