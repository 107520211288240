import * as React from "react";
import {
  Flex,
  styled,
  useMedia,
  Text,
  Box,
  IconChevronDown,
  IconChevronUp,
} from "@boligportal/juice";

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

interface Props {
  title: string;
  autoCollapseOnMobile?: boolean;
  children: React.ReactNode;
}

export const Collapser = ({
  children,
  title,
  autoCollapseOnMobile = true,
}: Props) => {
  const media = useMedia(0);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useEffect(() => {
    if (media.sm.down) {
      setIsCollapsed(autoCollapseOnMobile);
    } else {
      setIsCollapsed(false);
    }
  }, [media, autoCollapseOnMobile]);

  return (
    <>
      <TransparentButton onClick={() => setIsCollapsed(!isCollapsed)}>
        <Flex
          justify="space-between"
          align="center"
        >
          <Text
            size="lead"
            weight="bold"
          >
            {title}
          </Text>
          <Flex>
            {isCollapsed ? (
              <IconChevronDown size="medium" />
            ) : (
              <IconChevronUp size="medium" />
            )}
          </Flex>
        </Flex>
      </TransparentButton>
      <Box
        hidden={isCollapsed}
        mt={2.5}
      >
        {children}
      </Box>
    </>
  );
};
