import { NavLink } from "react-router-dom";
import { styled } from "@boligportal/juice";
import { css } from "@emotion/react";

interface Props {
  label: string;
  href?: string;
  onClick?: () => void;
}

const StyledTabButton = styled(NavLink)`
  border: none;
  cursor: pointer;

  padding: ${(props) => `${props.theme.unit(1)}`};

  // Using Flex Gap property is using negative margins that messes up overflow-x.
  // There for we are adding margin right to added the Gap between tabs
  margin-right: ${(props) => `${props.theme.unit(1)}`};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: auto;
    width: 33.33%;
  }

  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  font-size: ${(props) => props.theme.fontSize.body};
  color: ${(props) => props.theme.colorPalette.gray[100]} !important;
  text-decoration: none !important;
  text-align: center;

  background-color: #ffffff;

  border-bottom: 3px solid transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    ${(props) => css`
      color: ${props.theme.colorPalette.gray[300]} !important;
      border-bottom: 3px solid ${props.theme.colorPalette.gray[50]};
    `}
  }

  &.active {
    border-bottom: 3px solid ${(props) => props.theme.colorPalette.primary[500]};
    color: ${(props) => props.theme.color.text.base} !important;
    pointer-events: none;
  }
`;

export const TabLink = ({ label, href }: Props) => (
  <StyledTabButton
    exact
    to={href!}
  >
    {label}
  </StyledTabButton>
);
