import {
  Box,
  IconRentables,
  IconRentablesOutline,
  Link,
  styled,
} from "@boligportal/juice";
import { App } from "components/app";
import { SignupContext } from "lib/enums";
import { HeaderAction } from "lib/tracking/events";
import { ItemProps } from "../top_nav";

const StyledIconRentables = styled(IconRentables)`
  margin-top: 6px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledIconRentablesOutline = styled(IconRentablesOutline)`
  margin-top: 6px;

  &:hover {
    opacity: 0.8;
  }
`;

export const TopNavItemUserRentable = ({
  inverseColor,
  onItemClick,
}: ItemProps) => {
  const url = App.settings.routes.landlord_listings;
  const trackingAction = HeaderAction.ADVERTISED_RENTABLES;

  return (
    <Box mx={0.5}>
      <Link
        href={url}
        onClick={(event) =>
          onItemClick(
            {
              url,
              trackingAction,
              context: SignupContext.CREATE_RENTABLE,
            },
            event,
          )
        }
      >
        {inverseColor ? (
          <StyledIconRentables
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        ) : (
          <StyledIconRentablesOutline
            color={inverseColor ? "inverted" : "base"}
            size="large"
          />
        )}
      </Link>
    </Box>
  );
};
