import { Text, Button, Flex } from "@boligportal/juice";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { t } from "lib/i18n";
import SvgPaymentCards from "media/svg/PaymentCards";

type Props = {
  onClick: (option: PaymentMethodType) => void;
  vitestId?: string;
};

export const QuickpayPaymentMethodButton = ({ onClick, vitestId }: Props) => {
  const clickHandler = () => {
    onClick(PaymentMethodType.QuickPay);
  };

  return (
    <Button
      data-testid={vitestId}
      testId={"payWithQuickPay"}
      variant="branded"
      fullWidth
      onClick={clickHandler}
    >
      <Flex
        align="center"
        justify="center"
        height="40px"
      >
        <SvgPaymentCards />
        <Text
          ml={1.5}
          color="inverted"
        >
          {t("features.payments.choose_payment_method.pay_with_creditcard")}
        </Text>
      </Flex>
    </Button>
  );
};
