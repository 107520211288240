import {
  Box,
  Dialog,
  DialogContent,
  DialogHeader,
  Spinner,
} from "@boligportal/juice";
import { t } from "lib/i18n";

export const IsRetrivingEnergyLabelOverlay = () => (
  <Dialog
    open
    dismissable={false}
    size="medium"
  >
    <DialogHeader>{t("common.fetch_energy_rating")}</DialogHeader>
    <DialogContent>
      <Box py={2}>
        <Spinner />
      </Box>
    </DialogContent>
  </Dialog>
);
