import { useRef, useEffect } from "react";

export function usePrevious<T>(value: T): T | undefined {
  /**
   * Returns the previous value if you pass in the current value.
   * The first time it's called, it returns undefined (since no previous value exists).
   *
   * Converted to typescript, from: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
   */
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
