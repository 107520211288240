import {
  Box,
  Button,
  DataTable,
  Flex,
  http,
  Spinner,
  Text,
} from "@boligportal/juice";
import { useMobilepaySimulatorPayment } from "api/queries";
import { Payment } from "lib/customerServiceApi";

export const PaymentsTable = () => {
  const url = window.location.href;
  const arr = url.split("/");
  const cs_url = arr[0] + "//" + arr[2];
  const payments_url =
    cs_url + "/customer-service/api/mobilepay-simulator/payments";
  const { data, isLoading, refetch } =
    useMobilepaySimulatorPayment(payments_url);

  const onAccept = (payment: Payment) =>
    http
      .post(`${payments_url}/state`, {
        payment_id: payment.payment_id,
        state: "Refresh",
      })
      .then(() => refetch())
      .catch((error) => {
        console.log(error.response.data); // eslint-disable-line no-console
        alert(error);
      });

  const onReject = (payment: Payment) =>
    http
      .post(`${payments_url}/state`, {
        payment_id: payment.payment_id,
        state: "Rejected",
      })
      .then(() => refetch())
      .catch((error) => {
        console.log(error.response.data); // eslint-disable-line no-console
        alert(error);
      });

  return (
    <Box>
      <Text
        size="h3"
        weight="bold"
        block
        p={2}
      >
        Payment
      </Text>
      {isLoading || !data ? (
        <Flex
          justify="center"
          align="center"
          height="200px"
        >
          <Spinner />
        </Flex>
      ) : (
        <DataTable<Payment>
          items={data.payments}
          keyField={"payment_id"}
          rowActions={[]}
          columns={[
            {
              fieldLabel: "User",
              fieldName: "user",
              cellRenderer: (item) => <Text size="tiny">{item.user}</Text>,
            },
            {
              fieldLabel: "Agreement ID",
              fieldName: "agreement_id",
              cellRenderer: (item) => (
                <Text size="tiny">{item.agreement_id}</Text>
              ),
            },
            {
              fieldLabel: "Payment ID",
              fieldName: "payment_id",
              cellRenderer: (item) => (
                <Text size="tiny">{item.payment_id}</Text>
              ),
            },
            {
              fieldLabel: "Status",
              fieldName: "status",
              cellRenderer: (item) => <Text size="tiny">{item.status}</Text>,
            },
            {
              fieldLabel: "Refresh",
              fieldName: "" as any,
              cellRenderer: (item) => (
                <Button
                  variant="primary"
                  onClick={() => onAccept(item)}
                >
                  Refresh
                </Button>
              ),
            },
            {
              fieldLabel: "Reject",
              fieldName: "" as any,
              cellRenderer: (item) =>
                item.status !== "rejected" ? (
                  <Button
                    variant="danger"
                    onClick={() => onReject(item)}
                  >
                    Reject
                  </Button>
                ) : (
                  <Box textAlign="center">-</Box>
                ),
            },
          ]}
        />
      )}
    </Box>
  );
};
