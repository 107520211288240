import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FlexColumn,
  SelectField,
  styled,
  TextField,
} from "@boligportal/juice";
import { emailValidation } from "components/forms/validation";
import { NumberOfRentables } from "lib/enums";
import { isBoligPortal } from "lib/utils";
import { CompanyDetailsDTO } from "../helpers";

export type CompanyDraft = {
  id: number;
  name: string;
  postal_code: string;
  postal_town: string;
  cvr: string;
  ean?: number;
  embedded_allowed: boolean;
  iframe_allowed: boolean;
  user_id?: number;
  address: string;
  country: string;
  phone_number: string;
  contact_email: string;
  invoice_email?: string;
  reference_no?: string;
  number_of_rentables: string;
  att: string;
  verified: boolean;
  danske_udlejere?: boolean;
  social_housing?: boolean;
  interactive_floor_plan_allowed?: boolean;
  sales_agent?: string;
};

const CompanyDetails = (props: {
  item: CompanyDetailsDTO;
  updateField: (fieldname: string, value: any) => void;
}) => {
  const {
    id,
    name,
    postal_code,
    postal_town,
    cvr,
    embedded_allowed,
    iframe_allowed,
    address,
    country,
    phone_number,
    contact_email,
    invoice_email,
    reference_no,
    number_of_rentables,
    att,
    sales_agent,
    ean,
    verified,
  } = props.item;

  const isFirstRun = useRef(true);

  const {
    register,
    setValue,
    getValues,
    watch,
    formState,
    trigger: triggerValidation,
  } = useForm<CompanyDraft>({
    defaultValues: {
      id,
      name,
      postal_code,
      postal_town,
      cvr,
      iframe_allowed,
      embedded_allowed,
      address,
      country,
      phone_number,
      contact_email,
      number_of_rentables,
      att,
      sales_agent,
      ean,
      invoice_email,
      reference_no,
      verified,
    },
  });
  const { errors } = formState;
  const watchNumberOfRentables = watch("number_of_rentables");

  useEffect(() => {
    // We dont want to touch the form initially because they get default values
    // set initially
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setValue("id", id);
    setValue("name", name);
    setValue("postal_code", postal_code);
    setValue("postal_town", postal_town);
    setValue("cvr", cvr);
    setValue("embedded_allowed", embedded_allowed);
    setValue("iframe_allowed", iframe_allowed);
    setValue("address", address);
    setValue("country", country);
    setValue("phone_number", phone_number);
    setValue("contact_email", contact_email);
    setValue("number_of_rentables", number_of_rentables);
    setValue("att", att);
    setValue("sales_agent", sales_agent);
    setValue("ean", ean);
    setValue("invoice_email", invoice_email);
    setValue("reference_no", reference_no);
    setValue("verified", verified);
  }, [
    id,
    name,
    postal_code,
    postal_town,
    cvr,
    embedded_allowed,
    iframe_allowed,
    address,
    country,
    phone_number,
    contact_email,
    number_of_rentables,
    att,
    setValue,
    sales_agent,
    ean,
    invoice_email,
    reference_no,
    verified,
    getValues,
  ]);

  useEffect(() => {
    props.updateField("number_of_rentables", watchNumberOfRentables);
  }, [watchNumberOfRentables]);

  return (
    <Flex column>
      <Flex gap>
        <FlexColumn size={4}>
          <TextField
            {...register("id")}
            disabled={verified}
            type="text"
            label="Company ID"
            value={`${id}`}
          />
        </FlexColumn>
        <FlexColumn size={8}>
          <TextField
            {...register("name")}
            disabled={verified}
            type="text"
            label="Name"
            required
            onBlur={() => props.updateField("name", getValues().name)}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <Box mb={-3}>
            <TextField
              {...register("cvr")}
              disabled={verified}
              type="text"
              label="CVR"
              onBlur={() => props.updateField("cvr", getValues().cvr)}
            />
          </Box>
        </FlexColumn>

        <FlexColumn size={8}>
          <TextField
            {...register("address")}
            disabled={verified}
            type="text"
            label="Address"
            onBlur={() => props.updateField("address", getValues().address)}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <TextField
            {...register("postal_code")}
            disabled={verified}
            type="text"
            label="Zip code"
            onBlur={() =>
              props.updateField("postal_code", getValues().postal_code)
            }
          />
        </FlexColumn>
        <FlexColumn size={4}>
          <TextField
            {...register("postal_town")}
            disabled={verified}
            type="text"
            label="City"
            onBlur={() =>
              props.updateField("postal_town", getValues().postal_town)
            }
          />
        </FlexColumn>
        <FlexColumn size={4}>
          <TextField
            {...register("country")}
            disabled={verified}
            type="text"
            label="Country"
            onBlur={() => props.updateField("country", getValues().country)}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <TextField
            {...register("phone_number")}
            type="text"
            label="Company phone number"
            onBlur={async () => {
              props.updateField("phone_number", getValues().phone_number);
            }}
            errorText={errors.phone_number && "Phone number is not valid"}
          />
        </FlexColumn>
        <FlexColumn size={8}>
          <TextField
            {...register("contact_email", {
              validate: (value: string) => !value || emailValidation(value),
            })}
            disabled={verified}
            type="text"
            label="Contact email"
            onBlur={async () => {
              await triggerValidation();
              const value = getValues().contact_email;
              if (!value || emailValidation(value)) {
                props.updateField("contact_email", getValues().contact_email);
              }
            }}
            errorText={errors.contact_email && "Email is not valid"}
          />
        </FlexColumn>
      </Flex>
      <Flex gap>
        <FlexColumn size={6}>
          <TextField
            {...register("invoice_email", {
              validate: (value: string) => !value || emailValidation(value),
            })}
            disabled={verified}
            type="text"
            label="Inovice email"
            onBlur={async () => {
              await triggerValidation();
              const value = getValues().invoice_email;
              if (!value || emailValidation(value)) {
                props.updateField("invoice_email", getValues().invoice_email);
              }
            }}
            errorText={errors.invoice_email && "Email is not valid"}
          />
        </FlexColumn>
        <FlexColumn size={4}>
          <TextField
            {...register("reference_no")}
            disabled={verified}
            type="text"
            label="ReferenceNo"
            onBlur={() => props.updateField("reference_no", getValues().reference_no)}
          />
        </FlexColumn>
      </Flex>

      <Flex gap>
        <FlexColumn size={4}>
          <SelectField
            {...register("number_of_rentables")}
            label="Number of rentables"
            items={Object.keys(NumberOfRentables).map((key) => ({
              value: NumberOfRentables[key],
              label: NumberOfRentables[key],
            }))}
          />
        </FlexColumn>
        <FlexColumn size={8}>
          <TextField
            {...register("att")}
            disabled={verified}
            type="text"
            label="Att."
            onBlur={() => props.updateField("att", getValues().att)}
          />
        </FlexColumn>
      </Flex>
      {isBoligPortal() && (
        <Flex gap>
          <FlexColumn size={4}>
            <TextField
              {...register("sales_agent")}
              type="text"
              label="Sales agent"
              disabled={verified}
            />
          </FlexColumn>
          <FlexColumn size={4}>
            <TextField
              {...register("ean")}
              disabled={verified}
              type="text"
              label="EAN"
            />
          </FlexColumn>
        </Flex>
      )}
    </Flex>
  );
};
export { CompanyDetails };
