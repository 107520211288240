import * as React from "react";
import {
  Box,
  DialogContent,
  DialogFooter,
  DialogHeader,
  ImageSliderOverlay,
  Text,
  TextField,
} from "@boligportal/juice";
import styled from "@emotion/styled";
import { TenantAgentView } from "apps/find_tenant/components/tenant_agent_view";
import { Avatar } from "components/design-system/atoms/avatar/avatar";
import { ReadMoreContainer } from "components/read_more_container";
import { ThreadUser } from "lib/api";
import { t } from "lib/i18n";
import * as ProfileUtils from "lib/profile";

const Header = styled(DialogHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  -webkit-print-color-adjust: exact;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  color: white;
`;

const Label = styled.div`
  margin-bottom: 4px;
  color: #6c757d;
`;

const LinkContainer = styled.div`
  padding: 24px;
  border-radius: 3px;
  background-color: #f3f3f3;
  @media print {
    display: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  @media print {
    border-bottom: 1px solid ${(props) => props.theme.color.border};
  }
`;

interface Props {
  otherUser: ThreadUser | null;
  footerComponent?: React.ReactNode;
}

export const ProfileOverview = ({ otherUser, footerComponent }: Props) => {
  const [showProfileImage, setShowProfileImage] = React.useState(false);

  if (!otherUser || !otherUser.profile) {
    return null;
  }

  const profileLink = `${window.location.origin}${otherUser.profile.profile_url}`;

  return (
    <>
      {showProfileImage && Boolean(otherUser?.profile?.profile_image) && (
        <ImageSliderOverlay
          initialIndex={0}
          fullscreen
          images={[otherUser.profile.profile_image] as string[]}
          onClose={() => {
            setShowProfileImage(false);
          }}
        />
      )}

      <Header color="brand">
        <Avatar
          src={otherUser.profile.profile_image}
          size={88}
          onClick={() => {
            setShowProfileImage(true);
          }}
        />
        <ProfileDetails>
          <Text
            size="h4"
            weight="bold"
            color="inverted"
            block
          >
            {ProfileUtils.profileName(otherUser.profile)}
          </Text>
          <Text
            size="body"
            color="inverted"
          >
            {ProfileUtils.profileShortSummary(otherUser.profile)}
          </Text>
        </ProfileDetails>
      </Header>

      <StyledDialogContent>
        <Box mb={3}>
          <Label>{t("profile_overview.looking_for")}</Label>
          <Box>
            {otherUser.agents && otherUser.agents.length ? (
              <TenantAgentView agent={otherUser.agents[0]} />
            ) : (
              t("inbox.notSpecified")
            )}
          </Box>
        </Box>

        <Box mb={3}>
          <Label>{t("profile_overview.info")}</Label>
          <Box>
            {ProfileUtils.profilePreferencesShortSummary(otherUser.profile) ||
              t("inbox.notSpecified")}
          </Box>
        </Box>

        <Box mb={3}>
          <Label>{t("profile_overview.description")}</Label>
          <ReadMoreContainer
            content={otherUser.profile.description || t("inbox.notSpecified")}
            maxLength={500}
          />
        </Box>

        <LinkContainer>
          <Box pb={1.5}>
            <Text weight="bold">
              {t("profile_overview.share_profile_link")}
            </Text>
          </Box>
          <Box pb={1}>
            {t("profile_overview.profile_link_explained", {
              name: otherUser.profile.first_name,
            })}
          </Box>

          <Box pb={1.5}>
            <TextField
              multiLine={3}
              value={profileLink}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </Box>

          <a href={profileLink}>
            {t("profile_overview.profile_link_with_name", {
              name: otherUser.profile.first_name,
            })}
          </a>
        </LinkContainer>
      </StyledDialogContent>

      {footerComponent && <DialogFooter>{footerComponent}</DialogFooter>}
    </>
  );
};
