import { Flex, Logo } from "@boligportal/juice";
import { App } from "components/app";

type LogoLinkProps = {
  hideLogoOnDesktop?: boolean;
  inverseColor?: boolean;
};

export const LogoLink = ({
  hideLogoOnDesktop,
  inverseColor,
}: LogoLinkProps) => (
  <Flex
    hidden={{
      xl: hideLogoOnDesktop,
    }}
  >
    <a
      href={App.settings.routes.index}
      aria-label={App.settings.brand_name}
    >
      <Logo
        width="180px"
        inverse={inverseColor}
        market={App.settings.market}
      />
    </a>
  </Flex>
);
