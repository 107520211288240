import * as React from "react";

const SvgCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#18AA3C"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.4}
      d="M19.5 6L9 18l-4.5-4.5"
    />
  </svg>
);
export default SvgCheckmark;
