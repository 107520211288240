import { Flex, Box, Text } from "@boligportal/juice";
import { IconProps } from "@boligportal/juice/dist/components/Icon/Icon";
import { TransparentButton } from "../components/TransparentButton";

export type MovingReportAddressAndTenancyCell = {
  movingReportAddressAndTenancyCellRenderer: {
    address: string;
    tenancyName: string;
    Icon?: (props: IconProps) => JSX.Element;
    onClick?: () => void;
  };
};

export const movingReportAddressAndTenancyCellRenderer = ({
  movingReportAddressAndTenancyCellRenderer,
}: MovingReportAddressAndTenancyCell) =>
  movingReportAddressAndTenancyCellRenderer.Icon ? (
    <ClickableIconTitleAndSubtitle
      movingReportAddressAndTenancyCellRenderer={
        movingReportAddressAndTenancyCellRenderer
      }
    />
  ) : (
    <IconTitleAndSubtitle
      movingReportAddressAndTenancyCellRenderer={
        movingReportAddressAndTenancyCellRenderer
      }
    />
  );

const ClickableIconTitleAndSubtitle = ({
  movingReportAddressAndTenancyCellRenderer,
}: MovingReportAddressAndTenancyCell) => (
  <TransparentButton
    onClick={movingReportAddressAndTenancyCellRenderer.onClick}
  >
    <IconTitleAndSubtitle
      movingReportAddressAndTenancyCellRenderer={
        movingReportAddressAndTenancyCellRenderer
      }
    />
  </TransparentButton>
);

const IconTitleAndSubtitle = ({
  movingReportAddressAndTenancyCellRenderer,
}: MovingReportAddressAndTenancyCell) => (
  <Flex align="center">
    {movingReportAddressAndTenancyCellRenderer.Icon && (
      <Box pr={1}>
        <movingReportAddressAndTenancyCellRenderer.Icon
          color="muted"
          size="medium"
        />
      </Box>
    )}

    <Flex column>
      <Text
        weight="bold"
        size="small"
      >
        {movingReportAddressAndTenancyCellRenderer.address}
      </Text>
      <Text
        size="small"
        color="muted"
        whiteSpace="normal"
      >
        {movingReportAddressAndTenancyCellRenderer.tenancyName}
      </Text>
    </Flex>
  </Flex>
);
