import {
  Button,
  Flex,
  IconDocumentTextOutline,
  IconEye,
} from "@boligportal/juice";
import {
  SigningStatus,
  ContractViewModel,
} from "apps/contracts/components/common/interfaces";
import { commonRowActionsTrans } from "lib/translations";

export const ContractStateRelatedActionCellRenderer = ({
  state,
  id,
}: ContractViewModel) => (
  <Flex
    gap
    justify="end"
  >
    {shouldDisplayFillContractButton(state) && <GotoContractButton id={id} />}
    {shouldDisplayGotoStatusPageButton(state) && (
      <GotoContractStatusButton id={id} />
    )}
  </Flex>
);

const shouldDisplayFillContractButton = (status: SigningStatus) => {
  if (status === SigningStatus.DRAFT) {
    return true;
  }
  return false;
};

const shouldDisplayGotoStatusPageButton = (status: SigningStatus) => {
  if (status !== SigningStatus.DRAFT) {
    return true;
  }
  return false;
};

const GotoContractStatusButton = ({ id }: { id: number }) => {
  const { gotoOverviewRowActionLabel } = commonRowActionsTrans();
  return (
    <Flex justify="end">
      <Button
        variant="subtle"
        icon={IconEye}
        href={`/contracts/${id}/status`}
      >
        {gotoOverviewRowActionLabel}
      </Button>
    </Flex>
  );
};

const GotoContractButton = ({ id }: { id: number }) => {
  const { fillContractRowActionLabel } = commonRowActionsTrans();
  return (
    <Flex justify="end">
      <Button
        variant="subtle"
        fontSize="small"
        icon={IconDocumentTextOutline}
        href={`/contracts/${id}`}
      >
        {fillContractRowActionLabel}
      </Button>
    </Flex>
  );
};
