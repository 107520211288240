import { Tracking } from "lib/tracking/common";

const userSawOnboardingModal = () => {
  Tracking.trackGA4Event("New seeker begins onboarding");
  Tracking.trackEvent({
    category: "New seeker onboarding",
    action: "New seeker begins onboarding",
    label: "",
    isInteraction: true,
  });
};

const userSkipsOnboardingModal = () => {
  Tracking.trackGA4Event("New seeker skips onboarding");
  Tracking.trackEvent({
    category: "New seeker onboarding",
    action: "New seeker skips onboarding",
    label: "",
    isInteraction: true,
  });
};

const userFinishesOnboardingModal = () => {
  Tracking.trackGA4Event("New seeker finishes onboarding");
  Tracking.trackEvent({
    category: "New seeker onboarding",
    action: "New seeker finishes onboarding",
    label: "",
    isInteraction: true,
  });
};

export const OnboardingTracking = {
  userSawOnboardingModal,
  userSkipsOnboardingModal,
  userFinishesOnboardingModal,
};
