import {
  Box,
  Center,
  Dialog,
  DialogContent,
  DialogHeader,
  styled,
  Text,
} from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { PaymentSpinner } from "components/payment_spinner";
import { t } from "lib/i18n";
import { QuickpayOrderInfo } from "./QuickpayOrderInfo";

type Props = {
  title: string;
  plans: Plan[];
  paymentUrl: string | null;
  isWaitingForIFrameToLoad: boolean;
  orderId: string | null;
  handleIFrameLoad: () => void;
  onClose: () => void;
};

export const QuickpayFormDialog = ({
  title,
  plans,
  paymentUrl,
  isWaitingForIFrameToLoad,
  handleIFrameLoad,
  onClose,
  orderId,
}: Props) => (
  <Dialog
    open
    size="medium"
    onClose={onClose}
    hideOnClickOutside={false}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogContent>
      <Box
        minHeight="100px"
        mb="10px"
      >
        <QuickpayOrderInfo plans={plans} />
      </Box>
      <Box
        height="500px"
        width="100%"
      >
        <Box width="100%">
          {paymentUrl && (
            <QuickpayIframe
              data-test-id="QuickPayIframe"
              isWaitingForIFrameToLoad={isWaitingForIFrameToLoad}
              src={paymentUrl}
              onLoad={handleIFrameLoad}
            />
          )}
        </Box>
        {isWaitingForIFrameToLoad && (
          <Center height="100%">
            <PaymentSpinner submitted={false} />
          </Center>
        )}
      </Box>

      <Text color="muted">
        {t("payment_modal.order_info.order_id")} {orderId}
      </Text>
    </DialogContent>
  </Dialog>
);

const QuickpayIframe = styled.iframe<{ isWaitingForIFrameToLoad: boolean }>`
  display: ${(props) => (props.isWaitingForIFrameToLoad ? "none" : "block")};
  width: 100%;
  min-height: 500px;
  border: none;
`;
