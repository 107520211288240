import * as React from "react";
import { useLocationAutoCompleteSuggestions } from "api/queries";
import { LocationAutoCompleteOnSelect } from "../types";

export const useLocationAutoCompleteState = (
  onSelect: LocationAutoCompleteOnSelect,
) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containingDivRef = React.useRef<HTMLDivElement>(null);
  const suggestionsRef = React.useRef<HTMLUListElement>(null);
  const [search, setSearch] = React.useState();
  const [show, setShow] = React.useState(false);
  const { data } = useLocationAutoCompleteSuggestions(search, () =>
    setShow(true),
  );
  const [selectionIndex, setSelectionIndex] = React.useState(-1);

  const onInputChange = (event) => setSearch(event.target.value);
  const onInputFocus = () => setShow(true);

  const documentOnMouseDown = (event: MouseEvent) => {
    const el: EventTarget | null = event.target;
    if (
      el &&
      containingDivRef.current &&
      !containingDivRef.current.contains(el as HTMLElement)
    ) {
      setShow(false);
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (data === undefined) {
      return;
    }

    if (isDownArrow(event)) {
      event.preventDefault();
      if (data.length && selectionIndex >= data.length - 1) {
        setSelectionIndex(0);
      } else {
        setSelectionIndex((curent) => curent + 1);
      }
    } else if (isUpArrow(event)) {
      event.preventDefault();
      if (data.length && selectionIndex <= 0) {
        setSelectionIndex(data.length - 1);
      } else {
        setSelectionIndex((curent) => curent - 1);
      }
    } else if (isEnter(event)) {
      event.preventDefault();
      if (data.length && selectionIndex > -1) {
        setShow(false);
        onSelect(data[selectionIndex]);
      }
    } else if (isEscape(event)) {
      event.preventDefault();
      setShow(false);
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousedown", documentOnMouseDown);
    }
    return () => {
      window.removeEventListener("mousedown", documentOnMouseDown);
    };
  }, []);

  React.useEffect(() => {
    setSelectionIndex(-1);
  }, [data]);

  React.useEffect(() => {
    if (!data || selectionIndex < 0 || !suggestionsRef.current) {
      return;
    }

    const suggestion = data[selectionIndex];
    if (!suggestion || !suggestion.id) {
      return;
    }
    const el = window.document.getElementById(suggestion.id);

    if (el) {
      suggestionsRef.current.scrollTo({
        top: el.getBoundingClientRect().height * selectionIndex,
        behavior: "smooth",
      });
    }
  }, [data, selectionIndex]);

  return {
    show,
    suggestions: data ?? [],
    inputProps: {
      value: search,
      onChange: onInputChange,
      onFocus: onInputFocus,
      onKeyDown: onInputKeyDown,
      ref: inputRef,
    },
    containingDivRef,
    suggestionsRef,
    selectionIndex,
  };
};

const isDownArrow = (event: React.KeyboardEvent<HTMLInputElement>) =>
  event.keyCode === 40;
const isUpArrow = (event: React.KeyboardEvent<HTMLInputElement>) =>
  event.keyCode === 38;
const isEnter = (event: React.KeyboardEvent<HTMLInputElement>) =>
  event.keyCode === 13;
const isEscape = (event: React.KeyboardEvent<HTMLInputElement>) =>
  event.keyCode === 27;
