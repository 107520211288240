import { useParams } from "react-router-dom";

export const useMovingReportIdFromParams = (): { movingReportId: number } => {
  const { movingReportId } = useParams<{ movingReportId: string }>();
  return {
    movingReportId: parseInt(movingReportId, 10),
  };
};

export const useRoomIdFromParams = (): { roomId: number } => {
  const { roomId } = useParams<{ roomId: string }>();
  return {
    roomId: parseInt(roomId, 10),
  };
};

export const useRentableIdFromParams = (): { rentableId: number } => {
  const { rentableId } = useParams<{ rentableId: string }>();
  return {
    rentableId: parseInt(rentableId, 10),
  };
};
