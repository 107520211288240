import { CompanyPageResponse, CompanyPage, SlideImage } from "./types";

export const createCompanyPageImages = (
  images: { image_id: number; image_url: string }[],
): { id: number; url: string }[] =>
  images.map((image) => ({
    id: image.image_id,
    url: image.image_url,
  }));

export const createCompanyPages = (
  companyPages: CompanyPageResponse[],
): CompanyPage[] =>
  companyPages.map((companyPage) => ({
    ...companyPage,
    images: companyPage.images
      ? createCompanyPageImages(companyPage.images)
      : [],
  }));

export const convertFromSlideImages = (images: SlideImage[]) =>
  images.map((image) => ({
    id: image.id.toString(),
    url: image.url,
  }));
