import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useSaveOnLeave = (callback: () => Promise<void | void[]>) => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("beforeunload", callback);

    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [callback]);

  useEffect(() => {
    const unblock = history.block(() => {
      callback().finally(() => {
        unblock();
      });
    });

    return () => {
      unblock();
    };
  }, [callback, history]);
};
