import { useContext } from "react";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { Plan } from "components/interfaces/plan";
import { modalActions } from "components/modal_controller";
import { PaymentModal } from "components/modals/payment_modal";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { t } from "lib/i18n";

export const useHandleUpsellPlanPurchase = () => {
  const { paymentMethodTypes } = useMarketSettings();
  const modalActionsInstance = useContext(modalActions);

  const openPaymentModal = ({
    plan,
    listingId,
    rentableId,
    onSuccess,
    onSubscriptionActivated,
    onBeforeMobilePayRedirect,
  }: {
    plan: Plan;
    listingId: number;
    rentableId: number;
    onSuccess?: () => void;
    onSubscriptionActivated?: () => void;
    onBeforeMobilePayRedirect?: () => void;
  }) => {
    const product_name = t(`products.${plan.product.name}.name`).toLowerCase();
    const upsellPaymentMethodTypes = plan.is_subscription
      ? paymentMethodTypes
      : paymentMethodTypes.filter(
          (item) => item !== PaymentMethodType.MobilePay,
        );

    const onSuccessHandler = () => {
      modalActionsInstance.closeModal();
      onSuccess?.();
    };

    const modal = (
      <PaymentModal
        plans={[plan]}
        adId={listingId}
        rentableId={rentableId}
        onSuccess={onSuccessHandler}
        onSubscriptionActivated={onSubscriptionActivated}
        type="upsell"
        paymentMethodTypes={upsellPaymentMethodTypes}
        title={t("payment_modal.upsell.title_with_productname", {
          product_name: product_name,
        })}
        onBeforeMobilePayRedirect={onBeforeMobilePayRedirect}
      />
    );
    modalActionsInstance.showModal(modal);
  };

  return {
    openPaymentModal,
  };
};
