import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { SelectField } from "@boligportal/juice";

type Props = {
  categories: {
    value: string;
    label: string;
  }[];
  label?: string;
  errorText?: string;
  className?: string;
} & UseFormRegisterReturn;

/**
 * In contrary to RentalCategory from form/elements, this component supports flows where
 * multiple categories are being selected in the same form.
 * */
export const RentalCategoryItem = forwardRef<HTMLSelectElement, Props>(
  ({ label = "", categories, errorText, className, ...registerProps }, ref) => (
    <SelectField
      items={categories}
      className={className}
      label={label}
      errorText={errorText}
      {...registerProps}
      ref={ref}
    />
  ),
);
