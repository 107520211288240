import { ElectricityPartner } from "leads/electricity/ElectricityPartner";
import { t } from "lib/i18n";
import { InternetPartner } from "../LeadsSections";

export type ConfigType = {
  title: string;
  description: string;
  agreeText: string;
  introImage: string;
  notice?: string;
};

export const leadPartnerDialogConfigs = () => {
  const ElecricityLeadConfig: Record<ElectricityPartner, ConfigType> = {
    [ElectricityPartner.OK]: {
      title: t("tenant_hub.leads.ok.dialog.title"),
      description: t("tenant_hub.leads.ok.dialog.description.markdown"),
      agreeText: t("leads.OK.agree"),
      introImage: "/static/images/tenant_hub/services/OK.png",
    },
    [ElectricityPartner.ENERGI_FYN]: {
      title: t("tenant_hub.leads.energifyn.dialog.title"),
      description: t("tenant_hub.leads.energifyn.dialog.description.markdown"),
      agreeText: t("leads.energifyn.agree"),
      introImage: "/static/images/tenant_hub/services/EnergiFyn.png",
    },
    [ElectricityPartner.NORLYS]: {
      title: t("tenant_hub.leads.norlys.dialog.title"),
      description: t("tenant_hub.leads.norlys.dialog.description.markdown"),
      agreeText: t("leads.norlys.agree"),
      introImage: "/static/images/tenant_hub/services/Norlys.png",
    },
    [ElectricityPartner.ANDEL]: {
      title: t("tenant_hub.leads.andel.dialog.title"),
      description: t("tenant_hub.leads.andel.dialog.description.markdown"),
      agreeText: t("leads.andel.agree"),
      introImage: "/static/images/tenant_hub/services/Andel.png",
    },
    [ElectricityPartner.AURA]: {
      title: t("tenant_hub.leads.aura.dialog.title"),
      description: t("tenant_hub.leads.aura.dialog.description.markdown"),
      agreeText: t("leads.aura.agree"),
      introImage: "/static/images/tenant_hub/services/Aura.png",
    },
  };

  const InternetLeadConfig: Record<InternetPartner, ConfigType> = {
    [InternetPartner.NORLYS_INTERNET]: {
      title: t("tenant_hub.leads.internet.norlys.dialog.title"),
      description: t(
        "tenant_hub.leads.internet.norlys.dialog.description.markdown",
      ),
      agreeText: t("leads.norlys_internet.agree"),
      introImage: "/static/images/tenant_hub/services/Norlys_internet.png",
      notice: t("tenant_hub.leads.dialog.notice_already_contacted"),
    },
  };

  return {
    ElecricityLeadConfig,
    InternetLeadConfig,
  };
};
