import { styled, Flex, IconArrowDown } from "@boligportal/juice";
import { css } from "@emotion/react";

const Line = styled.div<{
  borderStyle: "dashed" | "solid";
  lineColor: string;
  lineHeight: number;
}>`
  border: 1px ${({ borderStyle }) => borderStyle}
    ${({ lineColor }) => lineColor};
  height: ${(props) => props.lineHeight}px;
`;

type Props = {
  lineColor: string;
  lineStyle: "dashed" | "solid";
  displayArrow: boolean;
};

export const DialogContentConnectingLine = ({
  lineColor,
  lineStyle,
  displayArrow,
}: Props) => {
  const targetLineHeight = 30;
  const lineHeightWithArrow = targetLineHeight - 16;
  const lineHeight = displayArrow ? lineHeightWithArrow : targetLineHeight;

  return (
    <Flex
      pl={2}
      column
      width="50px"
      align="center"
    >
      <Line
        lineColor={lineColor}
        lineHeight={lineHeight}
        borderStyle={lineStyle}
      />
      {displayArrow && (
        <Flex
          height="16px"
          align="center"
          justify="center"
        >
          <IconArrowDown
            css={css`
              display: block;
              color: ${lineColor};
              font-size: 24px;
            `}
          />
        </Flex>
      )}
    </Flex>
  );
};
