import { Ad } from "components/interfaces/ad";
import { Tracking } from "lib/tracking/common";
import { EventCategory } from "lib/tracking/events";

export enum ProductListName {
  srp_popular = "SRP: Popular Right Now",
  srp_promoted = "SRP: Promoted",
  srp_results = "SRP: Results",
  srp_other_results = "SRP: Other results",
  detail_other_results = "Detail: Other results",
  // home_page_top_city_areas = "Home page: Top city areas",
}

interface ProductData {
  id: string;
  name?: string;
  brand?: string;
  category?: string;
  variant?: string;
  price?: string;
  quantity?: number;
  coupon?: string;
  position?: number;
}

interface ProductImpression {
  id: string;
  name?: string;
  list?: string;
  brand?: string;
  category?: string;
  variant?: string;
  position?: number;
  price?: string;
}

export class ProductTracking {
  // Helper function which returns an onClick handler that tracks a product click.
  public static getTrackOnClickHandler(ad: Ad, list: string, position: number) {
    return () => {
      const productData = ProductTracking.getProduct(ad, position);
      ProductTracking.trackProductClick(productData, list);
    };
  }

  public static getProduct(ad: Ad, position?: number): ProductData {
    const product: ProductData = {
      id: this.getProductId(ad),
    };
    const category = ProductTracking.getProductCategory(ad);

    if (category) {
      product.category = category;
    }
    if (position !== undefined) {
      product.position = position;
    }
    return product;
  }

  public static getProductImpressions(
    ads: Ad[],
    list: string,
    positionOffset: number = 1,
  ) {
    return ads.map((ad, index) => {
      const productImpression: ProductImpression = this.getProduct(
        ad,
        index + positionOffset,
      );
      productImpression.list = list;
      return productImpression;
    });
  }

  private static getProductId(ad: Ad): string {
    return `Access-${ad.id}`;
  }

  private static getProductCategory(ad: Ad): string | null {
    const categoryParts = [
      ad.category,
      ad.city || null,
      `${Math.floor(ad.rooms)}-room`,
      ad.city_area || null,
    ];
    return categoryParts.filter((part) => part !== null).join("/");
  }

  public static getProductBrand(ad: Ad): string | null {
    const brandParts = [
      ad.city || null,
      ad.city_area || null,
      `${ad.rooms} rm`,
      `${ad.size_m2}m2`,
      `${ad.monthly_rent} ${ad.monthly_rent_currency}`,
    ];

    return brandParts.filter((part) => part !== null).join(" - ");
  }

  public static trackProductImpressions(impressions: ProductImpression[]) {
    Tracking.trackEvent({
      category: EventCategory.product_list_tracking,
      label: "",
      action: "product impression",
      isInteraction: false,
      extraData: {
        ecommerce: {
          impressions: impressions,
        },
      },
    });
  }

  public static trackProductClick(product: ProductData, list: string) {
    Tracking.trackEvent({
      category: EventCategory.product_list_tracking,
      label: "",
      action: "product click",
      isInteraction: true,
      extraData: {
        ecommerce: {
          click: {
            actionField: {
              list: list,
            },
            products: [product],
          },
        },
      },
    });
  }

  public static trackProductDetailView(product: ProductData) {
    Tracking.trackEvent({
      category: EventCategory.product_list_tracking,
      label: "",
      action: "product detail",
      isInteraction: false,
      extraData: {
        ecommerce: {
          detail: {
            products: [product],
          },
        },
      },
    });
  }
}
