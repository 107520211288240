import { Box, SelectField } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { isPlanAlreadyActive } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getProductName } from "lib/product";
import { UpsellCardBenefits } from "./UpsellCardBenefits";
import { UpsellCardContent } from "./UpsellCardContent";
import { UpsellCardPopularDisplay } from "./UpsellCardPopularDisplay";
import { UpsellCardPriceBox } from "./UpsellCardPriceBox";
import { UpsellCardPurchaseButton } from "./UpsellCardPurchaseButton";

type Props = {
  disabled: boolean;
  showIsPopular: boolean;
  price: {
    percent_off: number;
    total: number;
    subtotal: number;
    tax: number;
  };
  currency: string;
  price_text: string;
  benefits: string[];
  onClick: () => void;
  hasMultiplePlans: boolean;
  activeUpsellSubscriptions: AdSubscription;
};

export const DisabledUpsellCard = ({
  disabled,
  showIsPopular,
  price,
  currency,
  price_text,
  benefits,
  onClick,
  hasMultiplePlans,
  activeUpsellSubscriptions,
}: Props) => {
  const { isPartOfACompany: showVAT } = useUser();

  const headline = getProductName(activeUpsellSubscriptions.plan.product);

  const purchased = isPlanAlreadyActive(
    activeUpsellSubscriptions[0],
    activeUpsellSubscriptions.plan,
  );

  return (
    <Box width="100%">
      <UpsellCardPopularDisplay
        isVisible={showIsPopular}
        text={t("upsell.dialog.badge.best_value")}
      />
      <UpsellCardContent
        hideTopBorders={showIsPopular}
        title={headline}
      >
        <UpsellCardPriceBox
          showVAT={showVAT}
          price={price}
          priceText={price_text}
          currency={currency}
        />
        {hasMultiplePlans ? (
          <SelectField
            disabled={purchased || disabled}
            label={t("upsell.selected_duration_label")}
            items={[
              {
                label: tn(
                  "fixed_upsell_products.runningPeriod",
                  activeUpsellSubscriptions.plan.duration_days,
                ),
                value: activeUpsellSubscriptions.plan.plan_id.toString(),
              },
            ]}
            value={activeUpsellSubscriptions.plan.plan_id.toString()}
          />
        ) : null}
        <UpsellCardBenefits benefits={benefits} />
        <UpsellCardPurchaseButton
          disabled={purchased || disabled}
          label={
            purchased
              ? t("upsell_page.button_bought")
              : t("upsell_page.submit_button")
          }
          onClick={onClick}
        />
      </UpsellCardContent>
    </Box>
  );
};
