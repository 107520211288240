import { ChangeEvent } from "react";
import { CheckField } from "@boligportal/juice";
import { Option } from "components/interfaces/option";
import { TrackingEvent } from "lib/tracking/events";

interface CategoriesProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  categories: string[];
  categoryOptions: Option[];
  isMapView: boolean;
}
const Categories = ({
  onChange,
  categories,
  categoryOptions,
  isMapView,
}: CategoriesProps) => {
  const options = categoryOptions.filter(
    (option) => option.id !== "rental_any",
  );
  const changeCategory = (event) => {
    TrackingEvent.filterClickedCategory(isMapView ? "maps-view" : "list-view");
    onChange(event);
  };
  return (
    <>
      {options.map((option) => {
        const checked = categories.indexOf(`${option.id}`) > -1;
        return (
          <CheckField
            key={option.id}
            checked={checked}
            id={`${option.id}`}
            onChange={(event) => {
              changeCategory(event);
            }}
            label={option.name}
          />
        );
      })}
    </>
  );
};

export { Categories };
