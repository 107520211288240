import * as React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Box, RadioField, SelectField, TextField } from "@boligportal/juice";
import { useCombinedActions } from "api/queries";
import {
  DefectAccountable,
  DefectAction,
  DefectActionType,
  getDefectAccountableLabel,
  getDefectActionLabel,
  MovingReportType,
} from "apps/moving_reports/enums";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { t } from "lib/i18n";
import { FormDefectDetails } from "./DefectDetails";

type CombinedAction = {
  id?: number;
  description: string;
  value: DefectAction | string;
  type: DefectActionType;
};

export const Actions = ({
  reportId,
  reportType,
  isTenantFlow,
  isImageUploaded,
  onLoading,
  onEdit,
  formMethods,
}: {
  reportId: MovingReport["id"];
  reportType: MovingReportType;
  isTenantFlow: boolean;
  isImageUploaded: boolean;
  onLoading: (state: boolean) => void;
  onEdit: () => void;
  formMethods: UseFormReturn<FormDefectDetails>;
}) => {
  const { data, isLoading } = useCombinedActions(reportId, !isTenantFlow);

  React.useEffect(() => {
    onLoading(isLoading);
  }, [isLoading, onLoading]);

  const { register, watch, getValues, setValue, control, formState } =
    formMethods;

  const localAction = watch("action");

  const actions: CombinedAction[] = [
    ...(data ?? []),
    {
      description: getDefectActionLabel(DefectAction.OTHER),
      value: DefectAction.OTHER,
      type: DefectActionType.DEFAULT,
    },
  ];

  const defectActionOptions = actions.map((action) => ({
    label: action.description,
    value: action.value,
    disabled: false,
  }));

  const hasDeleted = defectActionOptions.every(
    (action) => action.value !== localAction.action,
  );
  if (hasDeleted) {
    defectActionOptions.push({
      label: getDefectActionLabel(localAction.action),
      value: localAction.action,
      disabled: true,
    });
  }

  const handleAction = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const previousValue = getValues("accountable");

    if (value === DefectAction.ONLY_DOCUMENTATION) {
      setValue("accountable", DefectAccountable.NOBODY);
    }

    if (
      value !== DefectAction.ONLY_DOCUMENTATION &&
      previousValue === DefectAccountable.NOBODY
    ) {
      setValue(
        "accountable",
        reportType === MovingReportType.IN
          ? DefectAccountable.LANDLORD
          : DefectAccountable.TENANT,
      );
    }

    setValue("actionOther", null);

    const currentAction = actions.find((action) => action.value === value)!;
    setValue("action", {
      action: currentAction.value,
      type: currentAction.type,
      id: currentAction.id,
    });

    onEdit();
  };

  return (
    <>
      {!isTenantFlow && (
        <Box>
          <SelectField
            {...register("action.action", {
              onChange: handleAction,
              validate: (value) =>
                value !== DefectAction.NO_ACTION ||
                t("moving_reports.room.unit.create_defect.action.placeholder"),
            })}
            label={t("moving_reports.room.unit.create_defect.action")}
            items={defectActionOptions}
            value={localAction.action}
            errorText={formState.errors.action?.action?.message}
          />
        </Box>
      )}

      {localAction.action === DefectAction.OTHER && (
        <TextField
          required
          {...register("actionOther", {
            required: t("moving_reports.room.unit.create_defect.error.action"),
            onChange: onEdit,
          })}
          label={t("moving_reports.room.unit.create_defect.custom_action")}
          maxLength={254}
          errorText={formState.errors.actionOther?.message}
        />
      )}

      {localAction.action !== DefectAction.ONLY_DOCUMENTATION &&
        localAction.action !== DefectAction.NO_ACTION && (
          <Controller
            control={control}
            name="accountable"
            render={({ field: { onChange, ...rest } }) => (
              <RadioField
                {...rest}
                onChange={(event) => {
                  onChange(event);
                  onEdit();
                }}
                label={t("moving_reports.room.unit.create_defect.responsible")}
                items={[
                  {
                    label: getDefectAccountableLabel(DefectAccountable.TENANT),
                    value: DefectAccountable.TENANT,
                  },
                  {
                    label: getDefectAccountableLabel(
                      DefectAccountable.LANDLORD,
                    ),
                    value: DefectAccountable.LANDLORD,
                  },
                ]}
              />
            )}
          />
        )}

      <TextField
        multiLine
        label={t("moving_reports.room.unit.create_defect.description")}
        errorText={formState.errors.description?.message}
        {...register("description", {
          onChange: onEdit,
          validate: (value) =>
            isTenantFlow
              ? value !== "" ||
                isImageUploaded ||
                t("moving_reports.room.unit.create_defect.error.description")
              : undefined,
        })}
      />
    </>
  );
};
