import {
  Button,
  Center,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@boligportal/juice";
import { PaymentSpinner } from "components/payment_spinner";
import { t } from "lib/i18n";

type Props = {
  invoicePaid: boolean;
  subscriptionActivated: boolean;
  extraSuccessMessage?: React.ReactNode;
  getTitle: () => string;
  onPurchaseComplete: () => void;
  onClose: () => void;
};

export const StartSubscriptionSuccessfulDialog = ({
  subscriptionActivated,
  onClose,
  getTitle,
  invoicePaid,
  extraSuccessMessage,
  onPurchaseComplete,
}: Props) => (
  <Dialog
    open
    size="medium"
    hideOnClickOutside={false}
    dismissable={false}
    onClose={onClose}
  >
    <DialogHeader>{getTitle()}</DialogHeader>
    <DialogContent>
      <Center height="275px">
        <PaymentSpinner
          submitted
          successHeadline={
            invoicePaid
              ? t("payment_modal.success.headline")
              : t("payment_modal.success.payment_info_registered.headline")
          }
          successMessage={
            invoicePaid
              ? t("payment_modal.success.message")
              : t("payment_modal.success.payment_info_registered.message")
          }
          extraSuccessMessage={invoicePaid ? extraSuccessMessage : ""}
        />
      </Center>
    </DialogContent>
    {subscriptionActivated && (
      <DialogFooter>
        <Button
          testId={"continueAfterPaying"}
          variant="branded"
          autoFocus
          onClick={onPurchaseComplete}
        >
          {t("payment_modal.button.continue")}
        </Button>
      </DialogFooter>
    )}
  </Dialog>
);
