import { Menu } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";
import { HeaderAction, TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { ItemProps, MenuItem } from "../../top_nav";
import { Opener } from "../TopNavItemRentableAdminMenu";

const getItems = () => {
  const {
    HOUSING_ADMINISTRATION,
    COMMERCIAL_CONTRACT,
    COMMERCIAL_MOVING_REPORT,
    COMMERCIAL_RENTING_OUT,
  } = menuTranslations();

  const items = [
    {
      name: HOUSING_ADMINISTRATION,
      url: App.settings.routes.housing_administration_landingpage,
      trackingAction: HeaderAction.BOLIGADMINISTRATION,
    },
    {
      name: COMMERCIAL_RENTING_OUT,
      url: App.settings.routes.commercial_landing_pages.renting_out,
      trackingAction: HeaderAction.RENT_OUT,
    },
    {
      name: COMMERCIAL_CONTRACT,
      url: App.settings.routes.commercial_landing_pages.contract,
      trackingAction: HeaderAction.CONTRACTS,
    },
    {
      name: COMMERCIAL_MOVING_REPORT,
      url: App.settings.routes.commercial_landing_pages.moving_report,
      trackingAction: HeaderAction.MOVING_REPORTS,
    },
  ];

  return items;
};

export const TopNavItemCommercialMenu = ({
  inverseColor,
  onItemClick,
}: ItemProps) => {
  const items = getItems();

  const handleTracking = () => {
    TrackingEvent.clickNavigationBar(HeaderAction.PRODUCTS);
  };

  return (
    <Menu<MenuItem>
      items={items}
      openerEl={(isOpen) => (
        <Opener
          inverseColor={inverseColor}
          isOpen={isOpen}
          tracking={handleTracking}
          title={t("menu.commercial.dropdown")}
        />
      )}
      onMenuItemSelect={onItemClick}
      verticalContentPadding={1}
      verticalSeparatorPadding={1}
    />
  );
};
