import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
  Notice,
  Text,
} from "@boligportal/juice";
import { App } from "components/app";
import { localizedDateTimeLongAndPretty } from "lib/date";
import { t } from "lib/i18n";

export const InfoDialog = ({
  onClose,
  onSubmit,
  reportId,
  isDeletable,
  defectFlowEndDate,
}: {
  onClose: () => void;
  onSubmit: () => void;
  reportId: number;
  isDeletable: boolean;
  defectFlowEndDate: string | null;
}) => (
  <Dialog
    open
    size="large"
    onClose={onClose}
  >
    <DialogHeader>{t("moving_reports.delete_info_dialog.title")}</DialogHeader>

    <DialogContent>
      <Flex column>
        <Text markdown>
          {t("moving_reports.delete_info_dialog.error_message_markdown")}
        </Text>
      </Flex>

      {!isDeletable && (
        <Flex column>
          <Flex
            column
            border
            borderRadius="md"
            bg="tint"
            pt={2}
            px={2}
          >
            <Text
              block
              weight="bold"
              size="small"
              pb={1}
            >
              {t("moving_reports.delete_info_dialog.error.title")}
            </Text>
            <Notice type="danger">
              <Link
                href="#"
                underline="hover"
                onClick={() =>
                  (window.location.href = `${App.settings.routes.moving_reports}${reportId}/status`)
                }
              >
                {t(
                  "moving_reports.delete_info_dialog.error.has_defect_flow_markdown",
                  {
                    date:
                      defectFlowEndDate &&
                      localizedDateTimeLongAndPretty(
                        new Date(defectFlowEndDate),
                      ),
                  },
                )}
              </Link>
            </Notice>
          </Flex>
        </Flex>
      )}
    </DialogContent>

    <DialogFooter>
      <Flex gap>
        <Button
          variant="subtle"
          onClick={onClose}
        >
          {t("contracts.delete_moving_report_dialog.cancel_button")}
        </Button>

        <Button
          disabled={!isDeletable}
          variant="danger"
          onClick={onSubmit}
        >
          {t("moving_reports.delete_info_dialog.confirm_button")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
