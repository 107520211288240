import { LatLngLocation } from "components/interfaces/location";
import { SortableImage } from "features/image_organizer/interfaces/SortableImage";
import { AdCategories } from "lib/enums";

export enum States {
  DRAFT = "DRAFT",
  ADVERTISED = "ACTIVE_APPROVED",
  RENTED_OUT = "RENTED_OUT",
}

export enum UnitStates {
  DRAFT = "DRAFT",
  ADVERTISED = "ACTIVE_APPROVED",
  RENTED_OUT = "RENTED_OUT_APPROVED",
}

export enum FilterTabs {
  ALL = "All",
  DRAFT = "DRAFT",
  ADVERTISED = "ACTIVE_APPROVED",
  RENTED_OUT = "RENTED_OUT",
}

export enum UnitType {
  UNIT = "UNIT",
  WAITING_LIST = "WAITING_LIST",
}

export type Address = {
  street: string;
  street_number: string;
  postal_code: string;
  city: string;
  location: LatLngLocation;
};

export type NewBuild = {
  id?: number;
  name: string;
  state: States;
  move_in_date: string | null;
  units_amount: number;
  min_rooms: number;
  max_rooms: number;
  min_size_m2: number;
  max_size_m2: number;
  min_monthly_rent: number;
  max_monthly_rent: number;
  advertised_date?: string;
  images: SortableImage[];
  lead_text?: string;
  description?: string;
  company_name?: string;
  company_link?: string;
  company_description?: string;
  company_image?: SortableImage[];
  units: Unit[];
  url: string;
  is_open: boolean;
} & Address;

export type Unit = {
  id: number;
  ad_id: number;
  address: string;
  category: AdCategories;
  size_m2: number;
  title: string;
  monthly_rent: number;
  rooms: number;
  reference_id?: string;
  images: SortableImage[];
  type: UnitType;
  state: UnitStates;
  seeker_thread_id: number;
};
