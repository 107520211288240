type Props = {
  shouldPointDown: boolean;
  chevronColor?: "text-base" | "text-white";
};

export const ChevronIndicator = ({
  shouldPointDown,
  chevronColor = "text-base",
}: Props) => (
  <div className={chevronColor}>
    {shouldPointDown ? <IconChevronDown /> : <IconChevronUp />}
  </div>
);

const IconChevronDown = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.712087 1.02459C1.0782 0.658471 1.6718 0.658471 2.03791 1.02459L7 5.98667L11.9621 1.02459C12.3282 0.658471 12.9218 0.658471 13.2879 1.02459C13.654 1.3907 13.654 1.9843 13.2879 2.35041L7.66291 7.97541C7.2968 8.34153 6.7032 8.34153 6.33709 7.97541L0.712087 2.35041C0.345971 1.9843 0.345971 1.3907 0.712087 1.02459Z"
      fill="currentColor"
    />
  </svg>
);

const IconChevronUp = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.712087 7.97541C1.0782 8.34153 1.6718 8.34153 2.03791 7.97541L7 3.01333L11.9621 7.97541C12.3282 8.34153 12.9218 8.34153 13.2879 7.97541C13.654 7.60929 13.654 7.0157 13.2879 6.64959L7.66291 1.02459C7.2968 0.658471 6.7032 0.658471 6.33709 1.02459L0.712087 6.64959C0.345971 7.0157 0.345971 7.60929 0.712087 7.97541Z"
      fill="currentColor"
    />
  </svg>
);
