import * as React from "react";
import { Flex, Box, styled } from "@boligportal/juice";
import { MovingReportsLandingPageContainer } from "../MovingReportsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const RentOutTodayHeader = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: #191919;
`;

interface Props {
  text: string;
  ctaButtonComponent: React.ReactNode;
}

export const CallToActionTextSection = ({
  text,
  ctaButtonComponent,
}: Props) => (
  <PageBlock
    bg="gray"
    py="medium"
    pyMobile="large"
    bottomShadow
  >
    <MovingReportsLandingPageContainer>
      <Flex
        column={{
          xs: true,
          md: false,
        }}
        align="center"
        justify="center"
        textAlign="center"
      >
        <RentOutTodayHeader>{text}</RentOutTodayHeader>

        <Box
          ml={{
            xs: 0,
            md: 4,
          }}
          mt={{
            xs: 2,
            md: 0,
          }}
        >
          {ctaButtonComponent}
        </Box>
      </Flex>
    </MovingReportsLandingPageContainer>
  </PageBlock>
);
