import { Box, Button } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import RouteUtil from "lib/routes";

interface Props {
  label: string;
  gaTracking: () => void;
}

export const ContractsLandingPageCTAButton = ({ label, gaTracking }: Props) => {
  const { user } = useUser();
  const { modalActionsInstance } = useAuthActions({});

  const ctaButtonClickHandler = () => {
    gaTracking();
    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={RouteUtil.contractChooseRentablePage()}
          context={SignupContext.CONTRACTS}
        />,
      );
    } else {
      window.location.href = RouteUtil.contractChooseRentablePage();
    }
  };

  return (
    <Button
      variant="branded"
      onClick={ctaButtonClickHandler}
      fullWidth={{
        xs: true,
        lg: false,
      }}
    >
      <Box py={1}>{label}</Box>
    </Button>
  );
};
