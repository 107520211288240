import { useQuery } from "react-query";
import { API } from "apps/moving_reports/api";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";

export const movingReportsKeys = {
  reportActions: (id: MovingReport["id"]) => ["reportActions", id] as const,
  defectRemarkStatuses: () => ["defectRemarkStatuses"] as const,
  additionalEmailRecipients: (id: MovingReport["id"]) =>
    ["additionalEmailRecipients", id] as const,
};

export const useCombinedActions = (
  reportId: MovingReport["id"],
  isEnabled: boolean = true,
) =>
  useQuery(
    movingReportsKeys.reportActions(reportId),
    () => API.getReportCombinedActions(reportId),
    {
      enabled: isEnabled,
    },
  );

export const useMovingReportAdditionalEmailRecipients = (
  movingReportId: number,
) =>
  useQuery(movingReportsKeys.additionalEmailRecipients(movingReportId), () =>
    API.getCopyRecipients(movingReportId),
  );

export const useDefectRemarkStatuses = () =>
  useQuery(movingReportsKeys.defectRemarkStatuses(), () =>
    API.getDefectStatuses(),
  );
