import { Box, Button, SelectField } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { SellingPoint } from "features/upsell/components/SellingPoint/SellingPoint";
import { trafficMagnitude } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getBenefits, getProductName } from "lib/product";
import { calculatePrice, formatCurrency } from "lib/utils";
import { HoverableCardHeader } from "./HoverableCardHeader";
import { StyledHoverableCard } from "./styles";

type Props = {
  hasMultiplePlans: boolean;
  disableAd: boolean;
  isPrivateLandlord: boolean;
  activeUpsellSubscription: AdSubscription;
};
export const DisabledHoverableCard = ({
  hasMultiplePlans,
  disableAd,
  isPrivateLandlord,
  activeUpsellSubscription,
}: Props) => {
  const { isPartOfACompany } = useUser();
  return (
    <StyledHoverableCard isHovered={false}>
      <HoverableCardHeader
        trafficMagnitude={trafficMagnitude(
          activeUpsellSubscription.plan.product.name,
        )}
        trafficMagnitudeText={t(
          "upsell_products.pro_landlord.traffic_magnitude.text",
        )}
        title={getProductName(activeUpsellSubscription.plan.product)}
        priceOriginalLabel={
          activeUpsellSubscription.plan.price.percent_off
            ? formatCurrency(
                activeUpsellSubscription.plan.price.total,
                activeUpsellSubscription.plan.currency,
              )
            : undefined
        }
        priceLabel={formatCurrency(
          calculatePrice(
            activeUpsellSubscription.plan.price.total,
            activeUpsellSubscription.plan.price.percent_off,
          ),
          activeUpsellSubscription.plan.currency,
        )}
        priceExVatLabel={`(${formatCurrency(
          calculatePrice(
            activeUpsellSubscription.plan.price.subtotal,
            activeUpsellSubscription.plan.price.percent_off,
          ),
          activeUpsellSubscription.plan.currency,
        )} ${t("upsell_page.ex_vat")})`}
        mutedCaptionText={
          !hasMultiplePlans && activeUpsellSubscription.plan.is_subscription
            ? tn(
                "upsell_products.runningPeriod",
                activeUpsellSubscription.plan.duration_days,
              )
            : undefined
        }
        mutedCaptionText2={
          !hasMultiplePlans && activeUpsellSubscription.plan.is_subscription
            ? t("product_explainer.cancel_any_time")
            : undefined
        }
        isPrivateLandlord={isPrivateLandlord}
        isPopular={
          !isPrivateLandlord
            ? activeUpsellSubscription.plan.product.name === "exposure_ad"
            : activeUpsellSubscription.plan.product.name === "promote_ad"
        }
      />
      {hasMultiplePlans ? (
        <SelectField
          label={t("upsell.selected_duration_label")}
          items={[
            {
              label: tn(
                "fixed_upsell_products.runningPeriod",
                activeUpsellSubscription.plan.duration_days,
              ),
              value: activeUpsellSubscription.plan.plan_id.toString(),
            },
          ]}
          value={activeUpsellSubscription.plan.plan_id.toString()}
        />
      ) : null}
      <Box mt={2}>
        <Button
          fullWidth
          variant="primary"
          disabled={disableAd}
        >
          {t("upsell_page.submit_button")}
        </Button>
      </Box>
      <Box
        pt={4}
        textAlign="left"
      >
        {getBenefits(
          activeUpsellSubscription.plan.product,
          isPartOfACompany,
        ).map((benefit, i) => (
          <SellingPoint
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            text={benefit}
          />
        ))}
      </Box>
    </StyledHoverableCard>
  );
};
