import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Flex,
  Text,
  Confirm,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
} from "@boligportal/juice";
import { PAGE_COMPANIES } from "apps/customer_service";
import { format } from "date-fns";
import { deleteCompany } from "lib/customerServiceApi";
import { Company } from "../interfaces";

export const CompanyDetailsHeader = ({ company }: { company: Company }) => {
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const handleDeleteCompanyClick = (companyId: number) => {
    deleteCompany(companyId).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        history.push(PAGE_COMPANIES);
      }
    });
  };

  return (
    <div
      style={{
        zIndex: 700,
        height: 70,
      }}
    >
      <Flex
        p={2}
        shadow="sm"
        bg="base"
        borderBottom
      >
        <Flex
          gap
          width="100%"
        >
          <Flex
            column
            flexGrow
          >
            <Flex>
              <Text weight="bold">{company.name}</Text>
              <Text pl={1}>#{company.id}</Text>
            </Flex>
            <Flex gap={4}>
              <Flex>
                <Text
                  weight="bold"
                  size="small"
                >
                  Created:
                </Text>
                <Text
                  pl={1}
                  size="small"
                >
                  {format(
                    new Date(company.date_created),
                    "yyyy-MM-dd HH:mm:ss",
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={2}
          >
            <Confirm
              size="large"
              opener={<Button>Delete company</Button>}
              dangerous
              onConfirm={() => handleDeleteCompanyClick(company.id)}
              title="Are you sure about deleting the company?"
              message="The deletion will be permanent"
              confirmText="Delete"
              cancelText="Cancel"
            />
          </Flex>
        </Flex>
      </Flex>

      <Dialog
        open={Boolean(error)}
        size="large"
        onClose={() => {
          setError(null);
        }}
      >
        <DialogHeader>Failed to delete the company</DialogHeader>

        <DialogContent>
          <Text block>{error}</Text>
        </DialogContent>

        <DialogFooter>
          <Flex gap>
            <Button
              variant="primary"
              onClick={() => {
                setError(null);
              }}
            >
              Ok
            </Button>
          </Flex>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
