import * as React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  UseFormGetValues,
  UseFormRegister,
  UseFormUnregister,
} from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  PhoneField,
  Text,
  TextField,
  NumberField,
} from "@boligportal/juice";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { TenancyType } from "business/domain/tenancy/TenancyType";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { TenancyFormData } from "features/rentable_tenancy/types/TenancyFormData";
import { t } from "lib/i18n";

export const TenancyTenantCandidateForm = ({
  control,
  index,
  register,
  unregister,
  getValues,
  remove,
  tenancyType,
  paymentType,
  errors,
}: {
  control: Control<TenancyFormData>;
  index: number;
  register: UseFormRegister<TenancyFormData>;
  unregister: UseFormUnregister<TenancyFormData>;
  getValues: UseFormGetValues<TenancyFormData>;
  remove: (index: number) => void;
  tenancyType: TenancyType;
  paymentType?: TenancyPaymentType;
  errors?: FieldErrors<TenancyFormData>;
}) => {
  const isCompany = tenancyType === "company";
  const isRequiredForPaymentType =
    paymentType &&
    [
      TenancyPaymentType.MOBILEPAY,
      TenancyPaymentType.BETALINGS_SERVICE,
    ].includes(paymentType);

  React.useEffect(() => {
    if (!isCompany) {
      unregister("company_name");
      unregister("company_cvr");
    } else {
      unregister(`tenants.${index}.cpr` as const);
    }
  }, [isCompany, index, unregister]);

  return (
    <>
      <Flex align="center">
        <Box pb={1}>
          <Text>{`${t("common.people.tenant")} #${index + 1}`}</Text>
        </Box>
        <Box flexPush>
          <Button
            text
            variant="danger"
            onClick={() => remove(index)}
          >
            {t("Delete")}
          </Button>
        </Box>
      </Flex>

      <Flex
        gap
        column={{
          xs: true,
          sm: false,
        }}
      >
        <TextField
          label={t("common.form_fields.first_name.label")}
          required={isRequiredForPaymentType}
          errorText={
            errors?.tenants?.[index]?.first_name && t("required_field.input")
          }
          {...register(`tenants.${index}.first_name` as const, {
            required: isRequiredForPaymentType,
          })}
        />

        <TextField
          label={t("common.form_fields.last_name.label")}
          required={isRequiredForPaymentType}
          errorText={
            errors?.tenants?.[index]?.last_name && t("required_field.input")
          }
          {...register(`tenants.${index}.last_name` as const, {
            required: isRequiredForPaymentType,
          })}
        />
      </Flex>

      {!isCompany && App.settings.market === Market.BOLIGPORTAL_DK && (
        <ControlledNumberField
          label={t("common.form_fields.social_security_number.label")}
          helpText={t("common.form_fields.social_security_number.help_text")}
          name={`tenants.${index}.cpr` as const}
          control={control}
          asString
          allowLeadingZeros
          format="######-####"
          mask="_"
          placeholder="XXXXXX-XXXX"
          errorText={errors?.tenants?.[index]?.cpr?.message}
        />
      )}
      <Flex
        gap
        column={{
          xs: true,
          sm: false,
        }}
      >
        <TextField
          label={t("common.form_fields.email.label")}
          errorText={
            errors?.tenants?.[index]?.email && t("required_field.email")
          }
          {...register(`tenants.${index}.email` as const)}
          required={isRequiredForPaymentType}
        />

        <ControlledPhoneNumberInput
          label={t("common.form_fields.phone_number.label")}
          name={`tenants.${index}.phone` as const}
          control={control}
          getValues={getValues}
          errors={errors}
          index={index}
          required={isRequiredForPaymentType}
        />
      </Flex>
    </>
  );
};

const ControlledPhoneNumberInput = ({
  control,
  name,
  getValues,
  errors,
  index,
  ...phoneFieldProps
}: {
  control: Control<TenancyFormData>;
  name: FieldPath<TenancyFormData>;
  getValues: UseFormGetValues<TenancyFormData>;
  errors?: FieldErrors<TenancyFormData>;
  index: number;
} & Omit<
  React.ComponentProps<typeof PhoneField>,
  "value" | "onChange" | "country"
>) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: phoneFieldProps.required,
    }}
    render={({ field: { value, onChange } }) => (
      <PhoneField
        country={App.settings.market === Market.BOLIGPORTAL_DK ? "dk" : "se"}
        value={value as string}
        errorText={errors?.tenants?.[index]?.phone && t("required_field.phone")}
        required={phoneFieldProps.required}
        onChange={onChange}
        {...phoneFieldProps}
      />
    )}
  />
);

const ControlledNumberField = ({
  control,
  name,
  asString,
  type = "tel",
  ...rest
}: {
  control: Control<TenancyFormData>;
  name: FieldPath<TenancyFormData>;
  asString?: boolean;
} & React.ComponentProps<typeof NumberField>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, ref, value, ...fieldRest } }) => (
      <NumberField
        type={type}
        onValueChange={(values) =>
          onChange(asString ? values.value : values.floatValue)
        }
        {...rest}
        value={value as any}
        {...fieldRest}
      />
    )}
  />
);
