import { Button, Box } from "@boligportal/juice";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { t } from "lib/i18n";
import { awaitTimeout } from "lib/timeout";
import { TrackingEvent } from "lib/tracking/events";
import { ContactLandlordPaywallButton } from "./ContactLandlordPaywallButton";

type Props = {
  ad: Ad;
  isContacted: boolean;
  isUnavailable: boolean;
  isFreeContact: boolean;
  textButton?: boolean;
};

export const Buttons = ({
  ad,
  isContacted,
  isUnavailable,
  isFreeContact,
  textButton,
}: Props) => (
  <Box
    p={!textButton ? 2 : 0}
    mt={textButton ? 0 : "auto"}
  >
    {isContacted ? (
      <NavigateToInBoxButton
        inboxRoute={`${App.settings.routes.inbox}t/${ad.seeker_thread_id}`}
      />
    ) : (
      <ContactLandlordPaywallButton
        isFreeContact={isFreeContact}
        disabled={isUnavailable}
        listing={ad}
        textButton={textButton}
      />
    )}
  </Box>
);

const NavigateToInBoxButton = ({ inboxRoute }: { inboxRoute: string }) => {
  const handleInboxRedirect = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    TrackingEvent.favoritesClickGoToInbox();
    await awaitTimeout(500);

    window.location.replace(inboxRoute);
  };

  return (
    <Button
      onClick={handleInboxRedirect}
      fullWidth
    >
      {t("goto_inbox_button.text")}
    </Button>
  );
};
