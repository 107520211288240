import { Box, Flex, Text } from "@boligportal/juice";
import { App } from "components/app";
import { RefundInfo } from "../../user_detail_page/subscriptions_feed/components/refund_info";

type Props = {
  amount: string;
  zendesk_ticket_id: string;
  username: string | null;
  agent: string;
  fullname: string;
  reason: string;
  note: string;
};

const RefundsDetails = ({
  agent,
  amount,
  fullname,
  note,
  reason,
  username,
  zendesk_ticket_id,
}: Props) => (
  <Box>
    <RefundInfo title="User">
      <Text weight="bold">{`${
        username || "(user deleted)"
      } - ${fullname}`}</Text>
    </RefundInfo>

    <RefundInfo title="Customer Service Agent">
      <Text weight="bold">{agent}</Text>
    </RefundInfo>

    <RefundInfo title="Reason">
      <Box>{`${reason}`}</Box>
      {note !== "" && <Box>{`${note}`}</Box>}
    </RefundInfo>

    <RefundInfo title="Zendesk Ticket ID">
      <Box>{`${zendesk_ticket_id}`}</Box>
    </RefundInfo>

    <Flex justify="space-between">
      <Text size="h1">Total refund:</Text>
      <Text
        weight="bold"
        size="h1"
      >
        {`${amount} ${App.settings.currency_symbol}`}
      </Text>
    </Flex>
  </Box>
);

export { RefundsDetails };
