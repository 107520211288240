import { useFormContext, useWatch } from "react-hook-form";
import { Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../../types";
import { CardWithNoNegativeMargin } from "../CommonStyled";
import { SelectRentableList } from "../SelectRentableList";

export const BulkCreateForm = () => {
  const { control } = useFormContext<CreateRentableForm>();

  const address = useWatch({
    control,
    name: "address",
  });

  return (
    <>
      <Text
        block
        weight="headline"
        size="h5"
        mt={4}
        mb={2}
        mx={{
          xs: 2,
          sm: 3,
          lg: 0,
        }}
      >
        {address &&
          t(
            "feature.address_form.rentable_list.title_%{address}_%{rentable_count}",
            {
              address: address.address,
              rentable_count: address.addressIds.length,
            },
          )}
      </Text>
      <CardWithNoNegativeMargin>
        <SelectRentableList />
      </CardWithNoNegativeMargin>
    </>
  );
};
