import { App } from "components/app";
import {
  addWeeks,
  getYear as dateFnsGetYear,
  getMonth as dateFnsGetMonth,
  getDate as dateFnsGetDate,
  formatISO as dateFnsFormatIso,
  addMonths as dateFnsAddMonths,
  compareAsc as dateFnsCompareAsc,
  isToday as dateFnsIsToday,
  sub as dateFnsSub,
  addDays as dataFnsAddDays,
  format as dateFnsFormat,
  differenceInDays,
  differenceInHours,
  format,
  formatDuration,
  formatDistanceToNow,
} from "date-fns";
import { da, enGB, sv } from "date-fns/locale";
import { t, tn } from "lib/i18n";
import { getLocale } from "./utils";

const isDateInPast = (date: Date): boolean =>
  dateFnsCompareAsc(date, new Date()) === -1;
const isDateInFuture = (date: Date): boolean =>
  dateFnsCompareAsc(date, new Date()) === 1;
const isDateToday = (date: Date): boolean => dateFnsIsToday(date);
const isDateCertainDayOfMonth = (date: Date, day: number): boolean =>
  dateFnsGetDate(date) === day;
const isDateMoreThanOneWeekInFuture = (date: Date) =>
  dateFnsCompareAsc(date, addWeeks(new Date(), 1)) === 1;

const isDateMoreThanTwoWeeksAgo = (date: Date) =>
  dateFnsCompareAsc(new Date(), addWeeks(date, 2)) === 1;

const getYear = (date: number | Date) => dateFnsGetYear(date);

const getMonth = (date: number | Date) => dateFnsGetMonth(date);

const getDateFormatted = (date: number | Date): string =>
  dateFnsFormatIso(date, {
    representation: "date",
  });

const getDatehInFuture = (date: Date, month: number, day?: number): Date => {
  const future = dateFnsAddMonths(date, month);

  if (day) {
    future.setDate(day);
  }

  return future;
};

const addDays = (date: Date, days: number) => dataFnsAddDays(date, days);

const subtractDays = (date: number | Date, duration: number) =>
  dateFnsSub(date, {
    days: duration,
  });

const getDateFnsLocale = () => {
  switch (App.settings.language) {
    case "sv":
      return sv;
    case "da":
      return da;
    case "en":
      return enGB;
    default:
      return da;
  }
};

const formatDateLocalePretty = (date: Date): string =>
  dateFnsFormat(date, "PPP", {
    locale: getDateFnsLocale(),
  });

const formatWithLocale = (date: number | Date, formating: string) =>
  format(date, formating, {
    locale: getDateFnsLocale(),
  });

const localizedDate = (date: Date) => date.toLocaleDateString(getLocale());

const localizedDateTimeLongAndPretty = (date: string | Date) => {
  //using translation string for language specific date formatting e.g. "dd/MM-yy' kl. 'HH:mm"
  const languageSpecificFormat = t("date.language_specific_date_time_format");
  if (typeof date === "string") {
    return formatWithLocale(new Date(date), languageSpecificFormat);
  }

  return formatWithLocale(date, languageSpecificFormat);
};

const paymentHistoryDateAndTime = (date: Date) =>
  format(date, "dd/MM-yyyy HH:mm");

const localizedTimeOnly = (date: string | Date) => {
  //using translation string for language specific date formatting e.g. "'kl. 'HH:mm"
  const languageSpecificFormat = t("date.language_specific_time_format");
  if (typeof date === "string") {
    return formatWithLocale(new Date(date), languageSpecificFormat);
  }

  return formatWithLocale(date, languageSpecificFormat);
};

const localizedSubscriptionDate = (date: Date) =>
  format(new Date(date), "dd/MM-yyyy HH:mm");

const subscriptionDateAndTime = (date: string) =>
  format(new Date(date), "yyyy-MM-dd HH:mm:ss");

const localizedDayOfWeekName = (date: Date) =>
  formatWithLocale(new Date(date), "EEEE");

const datetimeLocal = (date: Date) => format(date, "yyyy-MM-dd'T'HH:MM");

const simpleDateMonthTimeFormatter = (date: Date) => {
  //using translation string for language specific date formatting e.g. "DD MMMMM - HH:mm"
  const languageSpecificFormat = t(
    "date_format.language_specific_date_format.simple",
  );
  return formatWithLocale(date, languageSpecificFormat);
};

const localizedMonthAndDay = (date: Date) =>
  date.toLocaleDateString(getLocale(), {
    month: "long",
    day: "numeric",
  });

const localizedMonthDayAndYear = (date: Date) =>
  date.toLocaleDateString(getLocale(), {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

const localizedMonthAndYear = (date: Date) =>
  date.toLocaleDateString(getLocale(), {
    month: "long",
    year: "numeric",
  });

const format24Hours = (): string =>
  formatDuration(
    {
      hours: 24,
    },
    {
      format: ["hours"],
      locale: getDateFnsLocale(),
    },
  );

const formatDays = (amount: number): string =>
  formatDuration(
    {
      days: amount,
    },
    {
      format: ["days"],
      locale: getDateFnsLocale(),
    },
  );

const formatMonths = (amount: number): string =>
  formatDuration(
    {
      months: amount,
    },
    {
      format: ["months"],
      locale: getDateFnsLocale(),
    },
  );

const timePassedSinceShort = (targetDate: Date): string => {
  const currentDate = new Date();
  const daysAgo = differenceInDays(currentDate, targetDate);

  if (daysAgo < 1 && dateFnsIsToday(targetDate)) {
    return format(targetDate, "H:mm");
  }

  if (daysAgo <= 5) {
    return formatWithLocale(targetDate, "E");
  }

  if (getYear(targetDate) === getYear(currentDate)) {
    return formatWithLocale(targetDate, "d. MMM");
  }

  return formatWithLocale(targetDate, "P");
};

const timePassedSince = (date: Date) => {
  const currentDate = new Date();
  const timeDiff = currentDate.valueOf() - date.valueOf();
  const seconds = timeDiff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (hours < 1) {
    return tn("timestamp_minutes_ago", Math.max(1, Math.floor(minutes)));
  } else if (days < 1) {
    return tn("timestamp_hours_ago", Math.floor(hours));
  } else if (days < 7) {
    return tn("timestamp_days_ago", Math.floor(days));
  } else {
    return localizedMonthAndDay(date);
  }
};

const dateHoursOld = (date: Date) => differenceInHours(new Date(), date);

const formatOrdinal = (amount: number): string =>
  getDateFnsLocale().localize?.ordinalNumber(amount);

const formatRelativeTime = (dateString: string) =>
  formatDistanceToNow(new Date(dateString), {
    locale: getDateFnsLocale(),
  });

export {
  dateHoursOld,
  datetimeLocal,
  formatDateLocalePretty,
  format24Hours,
  formatDays,
  formatMonths,
  formatOrdinal,
  formatRelativeTime,
  formatWithLocale,
  getDateFnsLocale,
  getDateFormatted,
  getMonth,
  getDatehInFuture,
  getYear,
  isDateCertainDayOfMonth,
  isDateInFuture,
  isDateInPast,
  isDateToday,
  isDateMoreThanOneWeekInFuture,
  isDateMoreThanTwoWeeksAgo,
  localizedDate,
  localizedDateTimeLongAndPretty,
  localizedDayOfWeekName,
  localizedMonthAndDay,
  localizedMonthAndYear,
  localizedMonthDayAndYear,
  localizedSubscriptionDate,
  localizedTimeOnly,
  paymentHistoryDateAndTime,
  simpleDateMonthTimeFormatter,
  subscriptionDateAndTime,
  addDays,
  subtractDays,
  timePassedSince,
  timePassedSinceShort,
};
