import { Text, Flex, styled, useTheme } from "@boligportal/juice";

type Props = {
  text: string;
  isHovered: boolean;
};

export const VolumeToolTip = ({ text, isHovered }: Props) => {
  const theme = useTheme();

  const bg = isHovered
    ? theme.colorPalette.primary[700]
    : theme.colorPalette.gray[40];

  return (
    <Tip>
      <Flex
        pb={1}
        column
        align="center"
      >
        <TipContainer bg={bg}>
          <Text
            color={isHovered ? "inverted" : "primary"}
            weight="bold"
          >
            {text}
          </Text>
        </TipContainer>
        <TipArrow bg={bg} />
      </Flex>
    </Tip>
  );
};

const Tip = styled.div`
  display: flex;
  justify-content: center;
`;

const TipContainer = styled.div<{ bg: string }>`
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
`;

type TipArrowProps = {
  bg: string;
};

const TipArrow = ({ bg }: TipArrowProps) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8L0 0H16L8 8Z"
      fill={bg}
    />
  </svg>
);
