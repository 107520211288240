import { Market } from "components/markets/market_settings";

export const getDesktopVideoSource = (market: Market) =>
  market === Market.BOLIGPORTAL_DK
    ? "https://player.vimeo.com/external/594465275.sd.mp4?s=414c83b65fea6a46638b74c5044f54b5967b3df5&profile_id=165"
    : "https://player.vimeo.com/progressive_redirect/playback/817624290/rendition/1080p/file.mp4?loc=external&signature=5a75d24780440bae565fa768e87f1f8371676f0333846b9e1a53abbf13f03134";

export const getMobileVideoSource = (market: Market) =>
  market === Market.BOLIGPORTAL_DK
    ? "https://player.vimeo.com/external/594465275.sd.mp4?s=414c83b65fea6a46638b74c5044f54b5967b3df5&profile_id=164"
    : "https://player.vimeo.com/progressive_redirect/playback/817624290/rendition/540p/file.mp4?loc=external&signature=f7c0d1fb83b3984c20a7f9aa000f6f4e7b008f36d40386169be3706514bd2190";
