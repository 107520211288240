import * as React from "react";
import { User } from "components/interfaces/user";

const UserContext = React.createContext<User | null>(null);

type Props = {
  user: User | null;
  children: React.ReactNode;
};

export const UserProvider = ({ children, user }: Props) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export const useUser = () => {
  const user = React.useContext(UserContext);
  const [isPartOfACompany, setIsPartOfACompany] = React.useState<boolean>(
    Boolean(user?.company_name),
  );

  React.useEffect(() => {
    setIsPartOfACompany(Boolean(user?.company_name));
  }, [user]);

  return {
    user,
    isPartOfACompany,
  };
};
