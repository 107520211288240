import { fetchFromAPI } from "lib/api";
import { t } from "lib/i18n";
import { RentalContract } from "../types";
import { GetStatusPageDataResponsePayload } from "./contract_status_page/interfaces/GetStatusPageDataResponsePayload";
import {
  CancelContractSigningResponsePayload,
  ContractAttachment,
  CopyContractResponsePayload,
  GetContractsRequestPayload,
  GetContractsResponsePayload,
  AttachmentUploadConfig,
} from "./interfaces";

const baseUrl = "/api/contracts";

export class ContractAPI {
  static async deleteContract(
    id: number,
  ): Promise<GetContractsResponsePayload> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/contract/${id}/delete/`, {
        method: "DELETE",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async duplicateContract(
    contractId: number,
    rentableId: number,
    tenancyId?: number,
  ): Promise<CopyContractResponsePayload> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/duplicate/`,
        {
          method: "POST",
          body: JSON.stringify({
            to_rentable_id: rentableId,
            tenancy_id: tenancyId,
          }),
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getContracts(
    requestPayload: Partial<GetContractsRequestPayload>,
  ) {
    try {
      const response = await fetchFromAPI(`${baseUrl}/`, {
        method: "POST",
        body: JSON.stringify(requestPayload),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createContract(rentableId: number): Promise<{
    contract_id: RentalContract["contract_id"];
  }> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/create-or-duplicate/`,
        {
          method: "POST",
          body: JSON.stringify({
            rentable_id: rentableId,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async sendContract(contractId: number) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/send/`,
        {
          method: "POST",
        },
      );

      if (!response.ok) {
        if (response.status === 413) {
          throw new Error(t("contracts.send_for_signature.file_size.error"));
        }
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteTenant(tenantId: number) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/tenant/${tenantId}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getContractsAttachmentsConfig(
    contractId: number,
    maxFileSize: number = 0,
  ): Promise<AttachmentUploadConfig> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/attachment/get_attachment_upload_config/`,
        {
          method: "POST",
          body: JSON.stringify({
            contract_id: contractId,
            size: maxFileSize,
          }),
        },
      );

      if (!response.ok) {
        throw {
          status: response.status,
          statusText: response.statusText,
        };
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getStandardTerms() {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/special_terms/standard_terms/view/`,
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async addStandardTerms(contractId: number, termIds: number[]) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/special_terms/standard_terms/create/`,
        {
          method: "POST",
          body: JSON.stringify({
            contract_id: contractId,
            term_ids: termIds,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getContractTerms(contractId: number) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/special_terms/view/`,
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteContractTerm(termId: number) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/special_terms/${termId}/delete/`,
        {
          method: "DELETE",
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateContractTerm(
    termId: number,
    data: { title: string; description: string },
  ) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/special_terms/${termId}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify({
            ...data,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateContractTermsOrder(contractId: number, sorting: number[]) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/update_special_terms_sorting/`,
        {
          method: "PUT",
          body: JSON.stringify({
            sorting: sorting,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async addSpecialTerm(contractId: number) {
    try {
      const response = await fetchFromAPI(`${baseUrl}/special_terms/create/`, {
        method: "POST",
        body: JSON.stringify({
          contract_id: contractId,
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createAttachmentView(
    attachment,
    contractId: number,
  ): Promise<ContractAttachment> {
    try {
      const response = await fetchFromAPI(`${baseUrl}/attachment/`, {
        method: "POST",
        body: JSON.stringify({
          contract_id: contractId,
          attachment: attachment,
        }),
      });

      if (!response.ok) {
        const { error } = await response.json();

        throw {
          status: response.status,
          statusText: response.statusText,
          code: error.code,
          message: error.message,
        };
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteAttachment(attachmentId: number) {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/attachment/${attachmentId}/delete/`,
        {
          method: "DELETE",
        },
      );

      if (!response.ok) {
        throw {
          status: response.status,
          statusText: response.statusText,
        };
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async fetchStatusPageData(
    contractId: number,
  ): Promise<GetStatusPageDataResponsePayload> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/status/`,
      );

      if (!response.ok) {
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async cancelSigning(
    contractId: number,
  ): Promise<CancelContractSigningResponsePayload> {
    try {
      const response = await fetchFromAPI(
        `${baseUrl}/contract/${contractId}/cancel_sign_request/`,
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
