import * as React from "react";
import { Flex, styled } from "@boligportal/juice";

const StyledToolbarWrapper = styled.div`
  z-index: ${(props) => props.theme.zIndex.fixed};
`;

export const ToolbarWrapper = ({ children }: { children: React.ReactNode }) => (
  <StyledToolbarWrapper>
    <Flex
      p={2}
      shadow="sm"
      bg="base"
      borderBottom
      align="center"
    >
      {children}
    </Flex>
  </StyledToolbarWrapper>
);
