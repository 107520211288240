import { Button, styled, Flex } from "@boligportal/juice";
import { EmailLogEntry } from "../types";
import { getEmailRenderLink } from "../utils";

type Props = {
  log: EmailLogEntry;
  showEvents: (emailLogEntry: EmailLogEntry) => void;
};

export const ActionCell = ({ log, showEvents }: Props) => (
  <Flex>
    <Button href={getEmailRenderLink(log.email, log.tags, log.id)}>
      View content
    </Button>
    <Button onClick={() => showEvents(log)}>See all events</Button>
  </Flex>
);
