import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  DialogHeader,
  DialogContent,
  DialogFooter,
  Flex,
} from "@boligportal/juice";
import {
  DefectAccountable,
  DefectAction,
  DefectActionType,
} from "apps/moving_reports/enums";
import { Image } from "apps/moving_reports/interfaces/image";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { MovingReportForTenant } from "apps/moving_reports/tenant_app/interfaces/movingReportForTenant";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { t } from "lib/i18n";
import { DefectSelections } from "../CreateDefectDialog";
import { Actions } from "./Actions";
import { Buttons } from "./Buttons";
import { NoImagesConfirmationDialog } from "./ConfirmationDialog";
import { Images } from "./Images";
import { Units } from "./Units";

export interface LocalAction {
  id?: number;
  action: DefectAction | string;
  type: DefectActionType;
}

export type SelectedUnit = {
  id: number;
  label: string;
  roomLabel: string;
};

export type FormDefectDetails = {
  accountable: DefectAccountable;
  action: LocalAction;
  actionOther: string | null;
  description: string;
};

export const LOCAL_STORAGE_KEY = "is_defect_no_image_confirmation_disabled";

type Props = {
  report: MovingReport | MovingReportForTenant;
  isEditMode: boolean;
  isTenantFlow: boolean;
  defectSelections: DefectSelections;
  onSelectUnit: () => void;
  onEdit: (payload: Partial<DefectSelections>) => void;
  onSaveDefect: () => void;
  imageUploadUrl: string;
};

export const DefectDetails = ({
  report,
  isEditMode,
  isTenantFlow,
  defectSelections,
  onSelectUnit,
  onEdit,
  onSaveDefect,
  imageUploadUrl,
}: Props) => {
  const { images, units, description, accountable, action_data, action_other } =
    defectSelections;

  const getLocalAction = () => {
    if (action_data === null && action_other === null) {
      return {
        action: DefectAction.NO_ACTION,
        type: DefectActionType.DEFAULT,
      };
    }

    if (action_data === null) {
      return {
        action: DefectAction.OTHER,
        type: DefectActionType.DEFAULT,
      };
    }

    return action_data;
  };

  const [isConfirmationDisabled] = useLocalStorageState(
    LOCAL_STORAGE_KEY,
    false,
  );
  const [showDefectImagesNotice, setShowDefectImagesNotice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormDefectDetails>({
    defaultValues: {
      accountable,
      action: getLocalAction(),
      actionOther: action_other,
      description,
    },
  });

  const { handleSubmit, getValues } = formMethods;

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, []);

  const handleActionsChange = () => {
    const [action, accountable, action_other, description] = getValues([
      "action",
      "accountable",
      "actionOther",
      "description",
    ]);

    onEdit({
      description,
      accountable,
      action_other,
      action_data: action_other ? null : action,
    });
  };

  const handleUnitRemove = (id: number) => {
    const updatedUnits = units.filter((unit) => unit !== id);
    const { [id]: _, ...updatedImages } = images;

    onEdit({
      units: updatedUnits,
      images: updatedImages,
    });
  };

  const handleUploadImage = (payload: Image[], unitId: number) => {
    const newUnitImages = images[unitId]
      ? [...images[unitId], ...payload]
      : payload;

    const newImages = {
      ...images,
      [unitId]: newUnitImages,
    };

    onEdit({
      images: newImages,
    });
  };

  const handleRemoveImage = (imageId: number, unitId: number) => {
    const newUnitImages = images[unitId].filter(
      (item: Image) => item.id !== imageId,
    );

    const { [unitId]: _, ...imagesWithoutUnit } = images;

    const updatedImages =
      newUnitImages.length === 0
        ? imagesWithoutUnit
        : {
            ...images,
            [unitId]: newUnitImages,
          };

    onEdit({
      images: updatedImages,
    });
  };

  const isImageUploaded = Object.keys(images).length !== 0;

  const handleSave = () => {
    if (!isTenantFlow && !isImageUploaded && !isConfirmationDisabled) {
      setShowDefectImagesNotice(true);
    } else {
      setIsLoading(true);
      onSaveDefect();
    }
  };

  const handleValidate = () => {
    handleSubmit(handleSave)();
  };

  const handleBack = () => {
    onSelectUnit();
  };

  const selectedUnits = report.rooms.reduce<SelectedUnit[]>((memo, room) => {
    const filteredUnits = room.units
      .filter((item) => units.includes(item.id))
      .map((item) => ({
        id: item.id,
        label: item.label,
        roomLabel: room.label,
      }));

    if (filteredUnits.length !== 0) {
      return [...memo, ...filteredUnits];
    }

    return memo;
  }, []);

  return (
    <>
      <DialogHeader ref={scrollRef}>
        {isEditMode
          ? t("moving_reports.room.unit.create_defect.edit")
          : t("moving_reports.room.unit.create_defect.create")}
      </DialogHeader>

      <DialogContent waiting={isLoading}>
        <Flex column>
          <Units
            units={selectedUnits}
            isTenantFlow={isTenantFlow}
            isEditMode={isEditMode}
            onRemove={handleUnitRemove}
            onEdit={handleBack}
          />

          <Actions
            reportId={report.id}
            reportType={report.type}
            onLoading={setIsLoading}
            onEdit={handleActionsChange}
            isTenantFlow={isTenantFlow}
            isImageUploaded={isImageUploaded}
            formMethods={formMethods}
          />

          <Images
            units={selectedUnits}
            images={images}
            onUpload={handleUploadImage}
            onRemove={handleRemoveImage}
            onLoading={setIsLoading}
            imageUploadUrl={imageUploadUrl}
          />
        </Flex>
      </DialogContent>

      <DialogFooter>
        <Buttons
          isEditMode={isEditMode}
          isWaiting={isLoading}
          onSubmit={handleValidate}
          submitButtonVariant={isTenantFlow ? "branded" : "primary"}
        />
      </DialogFooter>

      <NoImagesConfirmationDialog
        open={showDefectImagesNotice}
        onClose={setShowDefectImagesNotice}
        onConfirm={onSaveDefect}
      />
    </>
  );
};
