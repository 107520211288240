import {
  styled,
  Box,
  Button,
  Flex,
  IconChevronForward,
  Text,
} from "@boligportal/juice";
import { TenantAvatar } from "apps/property_owner/my_rentables/components/cell_renderer/TenantsCellRenderer";
import { createTenantsDisplayLabel } from "business/domain/tenancy/Tenancy";
import { TenancyTenant } from "business/domain/tenancy/TenancyTenant";

export type AddressAndTenancyLinkCell = {
  addressAndTenancyCell: {
    tenancyLink: string;
    rentableLink: string;
    address: string;
    tenants: TenancyTenant[];
    shouldShowAddressLink: boolean;
  };
};

const StyledFlexWrapper = styled(Flex)`
  padding-bottom: 0;
  @media (max-width: 1425px) {
    padding-bottom: ${(props) => props.theme.unit(1)};
  }
`;

export const addressAndTenancyCellRenderer = ({
  addressAndTenancyCell,
}: AddressAndTenancyLinkCell) => (
  <StyledFlexWrapper align="center">
    <Box pr={1.5}>
      <TenantAvatar isMultiple={addressAndTenancyCell.tenants.length > 1} />
    </Box>
    <Flex column>
      <WordWrapButton
        href={addressAndTenancyCell.tenancyLink}
        text
      >
        <Flex align="center">
          <Text
            weight="bold"
            size="small"
            block
          >
            {createTenantsDisplayLabel(addressAndTenancyCell.tenants)}
          </Text>
          <Box pl={1}>
            <IconChevronForward />
          </Box>
        </Flex>
      </WordWrapButton>

      {addressAndTenancyCell.shouldShowAddressLink && (
        <WordWrapButton
          href={addressAndTenancyCell.rentableLink}
          text
        >
          <Flex align="center">
            <Text
              size="tiny"
              color="muted"
            >
              {addressAndTenancyCell.address}
            </Text>
          </Flex>
        </WordWrapButton>
      )}
    </Flex>
  </StyledFlexWrapper>
);

const WordWrapButton = styled(Button)`
  white-space: normal;
  text-align: left;
  margin: 0 !important;
`;
