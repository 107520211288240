import { Box, SelectField } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { isPlanAlreadyActive } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getProductName } from "lib/product";
import { UpsellCardBenefits } from "./UpsellCardBenefits";
import { UpsellCardContent } from "./UpsellCardContent";
import { UpsellCardPopularDisplay } from "./UpsellCardPopularDisplay";
import { UpsellCardPriceBox } from "./UpsellCardPriceBox";
import { UpsellCardPurchaseButton } from "./UpsellCardPurchaseButton";

type Props = {
  showIsPopular: boolean;
  price: {
    percent_off: number;
    total: number;
    subtotal: number;
    tax: number;
  };
  currency: string;
  price_text: string;
  benefits: string[];
  onClick: () => void;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;

  selectedPlan: number;
  plans: Plan[];
  activeUpsellSubscriptions: AdSubscription[];
};

export const UpsellCard = ({
  showIsPopular,
  price,
  currency,
  price_text,
  benefits,
  onClick,
  handleChange,

  selectedPlan,
  plans,
  activeUpsellSubscriptions,
}: Props) => {
  const hasMultiplePlans = plans.length > 1;

  const { isPartOfACompany: showVAT } = useUser();

  const headline = getProductName(plans[selectedPlan].product);

  const purchased = isPlanAlreadyActive(
    activeUpsellSubscriptions,
    plans[selectedPlan],
  );
  return (
    <Box width="100%">
      <UpsellCardPopularDisplay
        isVisible={showIsPopular}
        text={t("upsell.dialog.badge.best_value")}
      />
      <UpsellCardContent
        hideTopBorders={showIsPopular}
        title={headline}
      >
        <UpsellCardPriceBox
          showVAT={showVAT}
          price={price}
          priceText={price_text}
          currency={currency}
        />
        {hasMultiplePlans ? (
          <SelectField
            label={t("upsell.selected_duration_label")}
            items={plans.map((plan, index) => ({
              label: tn(
                "fixed_upsell_products.runningPeriod",
                plan.duration_days,
              ),
              value: index.toString(),
            }))}
            onChange={handleChange}
            value={selectedPlan?.toString()}
          />
        ) : null}
        <UpsellCardBenefits benefits={benefits} />
        <UpsellCardPurchaseButton
          disabled={purchased}
          label={
            purchased
              ? t("upsell_page.button_bought")
              : t("upsell_page.submit_button")
          }
          onClick={onClick}
        />
      </UpsellCardContent>
    </Box>
  );
};
