import * as React from "react";

const SvgTenantHubElectricity = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <circle
      cx={12.7}
      cy={12}
      r={11.16}
      fill="#FFDCC7"
    />
    <path
      fill="#FFE8DD"
      d="M6.526 13.353l7.616-10.63-.284 8.186h4.547L11.357 22.05v-8.697H6.526z"
    />
    <path
      fill="#fff"
      d="M6.07 12.728l7.618-10.63-.285 8.186h4.548l-7.049 11.14v-8.696H6.071z"
    />
  </svg>
);
export default SvgTenantHubElectricity;
