import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  IconPeople,
  IconPerson,
  Text,
  styled,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { TenancyListItem } from "../../views/rentable_detail/views/tenancies/views/tenancies/components/TenanciesList";

const RoundedFlex = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 999px;
  background-color: ${(props) => props.theme.colorPalette.gray[30]};
`;

const StyledLink = styled(Link)`
  &:hover {
    color: ${(props) => props.theme.color.text.base};
  }
`;

export const TenantsCellRenderer = (item: TenancyListItem) => {
  const { tenancy_id, tenants, tenants_display_label } = item;
  return (
    <StyledLink to={`./tenancies/${tenancy_id}`}>
      <Flex
        align="center"
        minWidth="200px"
      >
        <Box pr={2}>
          <TenantAvatar isMultiple={tenants.length > 1} />
        </Box>
        <Box>
          <Text
            weight="bold"
            size="small"
          >
            {tenants_display_label}
          </Text>
        </Box>
      </Flex>
    </StyledLink>
  );
};

export const TenantAvatar = ({ isMultiple }: { isMultiple: boolean }) => (
  <RoundedFlex
    align="center"
    justify="center"
  >
    {isMultiple ? (
      <IconPeople
        css={css`
          color: #647292;
          font-size: 18px;
        `}
      />
    ) : (
      <IconPerson
        css={css`
          color: #647292;
          font-size: 18px;
        `}
      />
    )}
  </RoundedFlex>
);
