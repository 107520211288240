import { Box, Flex, styled, Text } from "@boligportal/juice";
import { Claimer } from "../../interfaces";
import { takeOverAdTask } from "../toolbar_actions/action_tasks/take_over_ad_task";
import { ConfirmableDialogAction } from "../toolbar_actions/confirmable_dialog_action";

type Props = {
  adId: number;
  landlordId: number;
  claimer: Claimer;
  onTakeOver: () => void;
};

const TakeOverAdWarning = ({
  adId,
  claimer,
  landlordId,
  onTakeOver,
}: Props) => {
  const hasName = claimer.first_name + claimer.last_name !== "";
  const claimerName = hasName
    ? `${claimer.first_name} ${claimer.last_name}`
    : claimer.email;

  return (
    <DangerFlex
      py={2}
      px={1}
      align="center"
      bg="brand"
      justify="space-between"
    >
      <Box>
        <Box>
          <Text
            weight="bold"
            size="h5"
          >
            {`${claimerName}`} is currently working on this ad.
          </Text>
        </Box>
        <Box>
          <Text>{`Please ask ${claimerName} before you claim this Ad.`}</Text>
        </Box>
      </Box>
      <ConfirmableDialogAction
        adId={adId}
        landLordId={landlordId}
        title="Take Over Ad"
        actionConfirmedTask={(adId: number, landlordId: number) =>
          takeOverAdTask(adId, landlordId)
        }
        onTaskSuccess={onTakeOver}
      />
    </DangerFlex>
  );
};
//

export { TakeOverAdWarning };

const DangerFlex = styled(Flex)`
  background-color: ${(props) => props.theme.color.context.danger};
`;
