import * as React from "react";
import * as api from "api";
import { InboxPolling, Keys } from "apps/InboxPolling";
import { useUser } from "components/Providers/UserProvider";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { trackError } from "lib/tracking/errors";

const AuthenticatedUserPollingContext = React.createContext({
  unseenThreadCount: 0,
});

export const AuthenticatedUserPollingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUser();
  const { unseenThreadCount, getUnseenThreadCount } = useUnseenThreadCount();

  React.useEffect(() => {
    if (user) {
      InboxPolling.add(getUnseenThreadCount, Keys.UnseenThreadsCount);
    } else {
      InboxPolling.remove(Keys.UnseenThreadsCount);
    }
    return () => {
      InboxPolling.remove(Keys.UnseenThreadsCount);
    };
  }, [user, getUnseenThreadCount]);

  return (
    <AuthenticatedUserPollingContext.Provider
      value={{
        unseenThreadCount,
      }}
    >
      {children}
    </AuthenticatedUserPollingContext.Provider>
  );
};

const useUnseenThreadCount = () => {
  const [unseenThreadCount, setUnseenThreadCount] = useLocalStorageState(
    "Polling_Inbox_Count",
    0,
  );

  const getUnseenThreadCount = React.useCallback(async () => {
    try {
      const { unseen_thread_count } = await api.getUnseenThreadCount();
      setUnseenThreadCount(unseen_thread_count);
    } catch (err) {
      trackError(err);
    }
  }, []);

  return {
    unseenThreadCount,
    getUnseenThreadCount,
  };
};

export const useAuthenticatedUserPolling = () => {
  const { unseenThreadCount } = React.useContext(
    AuthenticatedUserPollingContext,
  );
  return {
    unseenThreadCount,
  };
};
