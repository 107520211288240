import { AddressManualFormDenmark } from "../dk/AddressManualDenmark";
import { AddressManualFormSweeden } from "../se/AddressManualSweeden";

export const manualAddressTrimmer = (
  address:
    | AddressManualFormDenmark["address"]
    | AddressManualFormSweeden["address"],
) => {
  Object.keys(address).forEach((key) => {
    if (typeof address[key] === "string") {
      address[key] = address[key].trim();
    }
  });
};
