/* eslint-disable comma-dangle */
import { useState, useCallback } from "react";
import { useUser } from "components/Providers/UserProvider";
import { t } from "lib/i18n";
import { Colleague } from "./interfaces/Colleague";
import {
  getCompanyColleagues,
  GetCompanyColleaguesResponse,
} from "./services/get_company_users";
import { trackUserClickedToImpersonateCompanyColleague } from "./tracking/trackUserClickedToImpersonateCompanyColleague";

export type ImpersonateCompanyColleague = (id: number) => void;
type LoadCompanyColleagues = () => void;
type IsUserAllovedToImpersonateColleague = () => boolean;

export interface ImpersonateCompanyColleagueState {
  title: string;
  companyName: string;
  colleagues: Colleague[];
}

interface ImpersonateCompanyColleagueFeatures {
  isUserAllovedToImpersonateColleague: IsUserAllovedToImpersonateColleague;
  impersonateCompanyColleague: ImpersonateCompanyColleague;
  loadCompanyColleagues: LoadCompanyColleagues;
}

export const useImpersonateCompanyColleague = (): {
  state: ImpersonateCompanyColleagueState;
  features: ImpersonateCompanyColleagueFeatures;
} => {
  const { user } = useUser();
  const title = t("header.impersonate_company_colleague");
  const [companyName] = useState(user?.company_name ?? "");
  const [colleagues, setColleagues] = useState<Colleague[]>([]);

  const impersonateCompanyColleague: ImpersonateCompanyColleague = useCallback(
    (id: number) => {
      trackUserClickedToImpersonateCompanyColleague(companyName);
      window.location.href = `/company-impersonation/${id}`;
    },
    [companyName],
  );

  const isUserAllovedToImpersonateColleague: IsUserAllovedToImpersonateColleague =
    useCallback(() => {
      const canImpersonate = user?.can_impersonate ?? false;
      const isImpersonating = user?.impersonating?.logout_url ?? false;
      return canImpersonate && !isImpersonating;
    }, [user]);

  const loadCompanyColleagues: LoadCompanyColleagues = useCallback(() => {
    getCompanyColleagues().then((response) => {
      const colleagues = createColleaguesFromResponse(response);
      setColleagues(colleagues);
    });
  }, []);

  return {
    state: {
      title,
      companyName,
      colleagues,
    },
    features: {
      loadCompanyColleagues,
      impersonateCompanyColleague,
      isUserAllovedToImpersonateColleague,
    },
  };
};

const createColleaguesFromResponse = (
  response: GetCompanyColleaguesResponse,
): Colleague[] => {
  const results: Colleague[] = [];

  if (response.results && Array.isArray(response.results)) {
    response.results.forEach((item) => {
      results.push({
        id: item.id,
        email: item.email,
        name: `${item.first_name} ${item.last_name}`,
      });
    });
  }

  return results;
};
