import { ElectricityPartner } from "leads/electricity/ElectricityPartner";
import { fetchFromAPI } from "lib/api";
import { LeadsSection } from "../pages/tenant_hub_services_page/LeadsSections";

type Lead = {
  category: LeadsSection;
  lead_provider: ElectricityPartner;
  id: number;
};

type LeadResponse = {
  moving_reports?: Lead[];
  contracts?: Lead[];
  tenant_hub?: Lead[];
  benefits_leads?: Lead[];
};

export const getLeads = async (userToken: string) =>
  getTenantHubLeads(userToken);

// using fetchFromAPI bacause juice http client doesnt support language prefix
const getTenantHubLeads = async (userToken: string): Promise<LeadResponse> => {
  const response = await fetchFromAPI(`/api/tenant-hub/${userToken}/leads`, {
    method: "GET",
  });
  return response.json();
};

export const getBenefitsPageLeads = async (): Promise<LeadResponse> => {
  const response = await fetchFromAPI(`/api/leads/benefits-leads`, {
    method: "GET",
  });
  return response.json();
};

export const getSignedLeads = (leadsData: LeadResponse) => {
  const signedLeads: {
    source: string;
    section: LeadsSection;
    signedPartner: ElectricityPartner;
  }[] = Object.entries(leadsData).reduce((result, [source, leads]) => {
    const signedLeads = leads.map((lead) => ({
      source,
      section: lead.category,
      signedPartner: lead.lead_provider,
    }));
    return [...result, ...signedLeads];
  }, []);

  return signedLeads;
};
