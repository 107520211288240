import { useState, useCallback, useEffect } from "react";
import { SortableColumn } from "@boligportal/juice";
import { useDebounce } from "hooks/useDebounce";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import { CompanyListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { searchCompaniesService } from "./services/search_companies_service";

export interface Filters {
  has_company_page: boolean;
  is_embedding_enabled: boolean;
  is_danske_udlejere: boolean;
  company_id: number | null;
  company_name: string | null;
  social_housing: boolean;
}

const useSearchCompaniesWithPagination = () => {
  const { items, setItems, count, setCount, offset, setOffset, limit } =
    usePagination<CompanyListItem>(25);
  const [sorting, setSorting] = useState<SortableColumn<CompanyListItem>>({
    column: "id",
    descending: true,
  });
  const [filters, setFilters] = useState<Filters>({
    has_company_page: false,
    is_embedding_enabled: false,
    is_danske_udlejere: false,
    company_id: null,
    company_name: null,
    social_housing: false,
  });

  const debouncedFilters = useDebounce(filters, 300);
  const prev = usePrevious(debouncedFilters);

  const applyFilters = useCallback((value) => {
    setFilters(value);
  }, []);

  const applySorting = useCallback((value) => {
    setSorting(value);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    const { signal } = abortController;

    searchCompaniesService(signal, offset, limit, debouncedFilters, sorting)
      .then((response) => {
        setItems(response.companies);
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    debouncedFilters,
    sorting,
    offset,
    setOffset,
    prev,
    limit,
    setItems,
    setCount,
  ]);

  return {
    filters,
    applyFilters,
    sorting,
    applySorting,
    items,
    count,
    limit,
    offset,
    setOffset,
  };
};

export { useSearchCompaniesWithPagination };
