import { useState, useEffect } from "react";
import { useImpersonateCompanyColleague } from "features/impersonate_company_colleague/useImpersonateCompanyColleague";

export const useSignInAsColleague = () => {
  const { features, state } = useImpersonateCompanyColleague();
  const {
    loadCompanyColleagues,
    isUserAllovedToImpersonateColleague,
    impersonateCompanyColleague,
  } = features;
  const [showDialog, setShowDialog] = useState(false);

  const showSignInAsColleague = () => {
    setShowDialog(true);
  };
  const hideSignInAsColleague = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (showDialog) {
      loadCompanyColleagues();
    }
  }, [showDialog, loadCompanyColleagues]);

  return {
    state,
    shouldShowSignInAsCompanyColleagueDialog: showDialog,
    isUserAllovedToImpersonateColleague,
    impersonateCompanyColleague,
    showSignInAsColleague,
    hideSignInAsColleague,
  };
};
