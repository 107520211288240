import * as React from "react";
import { styled } from "@boligportal/juice";
import { css } from "@emotion/react";
import { formatUser } from "apps/customer_service/pages/user_detail_page/company_details_view/helpers";
import { UserListItem } from "lib/customerServiceApi";
import { useSearchUsersForDropdown } from "../hooks/use_search_users_for_dropdown";

const SearchUsersDropdown = ({
  onUserSelect,
}: {
  onUserSelect: (user: UserListItem) => void;
}) => {
  const { items, search, searchValue } = useSearchUsersForDropdown();
  const clickOutsideWrapper = React.useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const searchUsersInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    search(event.target.value);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("focusin", handleFocusIn);

    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  const handleDocumentMouseDown = (event) => {
    // inside click
    if (
      clickOutsideWrapper &&
      clickOutsideWrapper.current &&
      clickOutsideWrapper.current.contains(event.target)
    ) {
      return;
    }
    // outside click
    setIsVisible(false);
  };

  const handleFocusIn = (event) => {
    // inside focus
    if (
      clickOutsideWrapper &&
      clickOutsideWrapper.current &&
      clickOutsideWrapper.current.contains(event.target)
    ) {
      return;
    }
    // outside focus
    setIsVisible(false);
  };

  const handleInputFocus = () => {
    setIsVisible(true);
  };
  const selectHandler = (user: UserListItem) => {
    search("");
    setIsVisible(false);
    onUserSelect(user);
  };
  return (
    <div ref={clickOutsideWrapper}>
      <StyledInput
        onChange={searchUsersInputChangeHandler}
        type="text"
        value={searchValue}
        onFocus={handleInputFocus}
      />
      {isVisible && (
        <StyledSearchItemsWrapper>
          {items.map((user) => (
            <SearchResultUserItemView
              key={user.id}
              user={user}
              onSelect={selectHandler}
            />
          ))}
        </StyledSearchItemsWrapper>
      )}
    </div>
  );
};

export { SearchUsersDropdown };

// ************************************************************************************************
// Subcomponent used only by this file
// ************************************************************************************************

const inputStyles = (props) => css`
  display: block;
  width: 100%;

  color: ${props.theme.color.text.base};
  border: 2px solid ${props.theme.colorPalette.gray[20]};
  padding-left: ${props.theme.unit(5)};
  padding-top: ${props.theme.unit(1)};
  padding-bottom: ${props.theme.unit(1)};
  border-radius: ${props.theme.borderRadius.none};

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23333'%3E%3Cpath d='M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: ${props.theme.unit(1)}; center;
  background-size: 24px 24px;

  font-weight: ${props.theme.fontWeight.base};
  font-size: ${props.theme.fontSize.body};
  outline: none;

  &:hover {
    border: 2px solid ${props.theme.colorPalette.gray[40]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props.theme.colorPalette.gray[70]};
    background-color: ${props.theme.colorPalette.gray[10]};
  }

  &:focus {
    outline: none;
    background-color: white;
    border-color: ${props.theme.color.focusBorder};
  }

  &::placeholder {
    color: ${props.theme.color.inputPlaceholder};
  }
`;

const StyledInput = styled.input`
  ${inputStyles};
`;

const wrapperStyles = (props) => css`
  box-shadow: ${props.theme.shadow.lg};
  display: flex;
  flex-direction: column;
  margin-top: 0;
  position: fixed;
  background-color: #ffffff;
  color: ${props.theme.color.text.base};
  z-index: 1;
`;

const StyledSearchItemsWrapper = styled.div`
  ${wrapperStyles};
`;

const StyledListItem = styled.button`
  border: none;
  padding: 8px 12px;
  background-color: #ffffff;
  text-align: left;
`;

const SearchResultUserItemView = ({
  user,
  onSelect,
}: {
  user: UserListItem;
  onSelect: (user: UserListItem) => void;
}) => {
  const handleClick = () => {
    onSelect(user);
  };
  return (
    <StyledListItem onClick={handleClick}>{formatUser(user)}</StyledListItem>
  );
};
