import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  errorText?: string;
} & UseFormRegisterReturn;

/**
 * In contrary to RentalSize from form/elements, this component supports flows where
 * multiple sized are being set in the same form.
 * */
export const RentalApartmentNumber = forwardRef<HTMLInputElement, Props>(
  ({ errorText, ...registerProps }, ref) => (
    <TextField
      type="number"
      label={t("create.field.swedish_apartment_info.heading")}
      errorText={errorText}
      {...registerProps}
      ref={ref}
    />
  ),
);
