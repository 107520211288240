import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Flex, TextField } from "@boligportal/juice";
import { t } from "lib/i18n";
import { commonFormTrans } from "lib/translations";
import { VALID_SWEDISH_POSTAL_CODE_REG_EX } from "../common/hooks/postCodes";
import { useFetchAndFillPostalTown } from "../common/hooks/useFetchAndFillPostalTown";

export interface AddressManualFormSweeden {
  address: {
    postal_code: string;
    postal_town: string;
    street_name: string;
    street_number: string;
  };
}

export const AddressManualSweeden = () => {
  const { register, formState, setValue, watch } =
    useFormContext<AddressManualFormSweeden>();
  const { errors } = formState;

  const { getAndSetPostalTown } = useFetchAndFillPostalTown(
    setValue,
    "address.postal_town",
    VALID_SWEDISH_POSTAL_CODE_REG_EX,
  );

  const {
    streetNameLabel,
    houseNumberLabel,
    postalCodeLabel,
    postalTownLabel,
  } = commonFormTrans();

  const inputfieldIsRequiredTrans = t("required_field.input");

  const watchZipCode = watch("address.postal_code");

  useEffect(() => {
    getAndSetPostalTown(watchZipCode);
  }, [watchZipCode, getAndSetPostalTown]);

  return (
    <>
      <Flex gap>
        <Box width="100%">
          <TextField
            required
            {...register("address.street_name", {
              required: true,
            })}
            label={streetNameLabel}
            errorText={errors.address?.street_name && inputfieldIsRequiredTrans}
          />
        </Box>
      </Flex>
      <Flex>
        <TextField
          required
          {...register("address.street_number", {
            required: true,
          })}
          label={houseNumberLabel}
          errorText={errors.address?.street_number && inputfieldIsRequiredTrans}
        />
      </Flex>
      <Flex gap>
        <TextField
          required
          type="number"
          {...register("address.postal_code", {
            required: true,
          })}
          label={postalCodeLabel}
          errorText={errors.address?.postal_code && inputfieldIsRequiredTrans}
        />
        <TextField
          required
          {...register("address.postal_town", {
            required: true,
          })}
          readOnly
          label={postalTownLabel}
          errorText={errors.address?.postal_town && inputfieldIsRequiredTrans}
        />
      </Flex>
    </>
  );
};
