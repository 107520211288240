import { AdItem } from "apps/customer_service/pages/ads_page/interfaces";
import { Company } from "apps/customer_service/pages/user_detail_page/company_details_view/interfaces";
import { fetchFromAPI } from "lib/api";
import { UserListItem } from "lib/customerServiceApi";

export interface UsersGlobalSearchResult {
  results: UserListItem[];
  count: number;
}

export interface AdsGlobalSearchResult {
  results: AdItem[];
  count: number;
}

export interface CompaniesGlobalSearchResult {
  results: Company[];
  count: number;
}

interface SearchGloballyResponse {
  users: UsersGlobalSearchResult;
  ads: AdsGlobalSearchResult;
  companies: CompaniesGlobalSearchResult;
}

const searchGloballyService = async (
  query: string,
  signal: AbortSignal,
): Promise<SearchGloballyResponse> => {
  const url = `/customer-service/api/search/?query=${encodeURIComponent(
    query,
  )}`;

  try {
    const response = await fetchFromAPI(url, {
      signal,
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export { searchGloballyService };
