import { Flex, Text, Badge, Tooltip } from "@boligportal/juice";
import { TenancyPaymentRecord } from "business/domain/tenancy/TenancyPaymentRecord";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { App } from "components/app";
import { TenancyPaymentStatusControl } from "features/rentable_tenancy/components/TenancyPaymentStatusControl";
import { calculateTenancyPaymentsSum } from "features/rentable_tenancy/helpers/calculateTenancyPaymentsSum";
import { localizedMonthDayAndYear } from "lib/date";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";

export const DateCellRenderer = (item: TenancyPaymentRecord) =>
  localizedMonthDayAndYear(new Date(item.due_date));

export const PaymentStatusRenderer = ({
  item,
  statusUpdater,
  size,
}: {
  item: TenancyPaymentRecord;
  statusUpdater: (
    paymentRecordId: TenancyPaymentRecord["id"],
    status: TenancyPaymentStatus,
  ) => void;
  size?: "tiny" | "small" | "body" | "lead";
}) => {
  const alertUserBeforeUpdatingStatus = (value: string) => {
    statusUpdater(item.id, value as TenancyPaymentStatus);
  };

  return (
    <TenancyPaymentStatusControl
      size={size ? size : "small"}
      onChange={alertUserBeforeUpdatingStatus}
      value={item.status}
    />
  );
};

export const PaymentCellRenderer = (item: TenancyPaymentRecord) => {
  const { amount, rent_regulation, one_time_payment } = item;
  if (amount === 0) {
    return <Text>-</Text>;
  }

  return (
    <Flex
      column
      align="end"
    >
      <Text size="small">{formatCurrency(amount, App.settings.currency)}</Text>
      <Flex
        align="start"
        mr={"-6px"}
      >
        {rent_regulation && (
          <RentRegulationLabel items={rent_regulation.items} />
        )}
        {one_time_payment && (
          <OneTimePaymentLabel items={one_time_payment.items} />
        )}
      </Flex>
    </Flex>
  );
};

export const RentRegulationLabel = ({
  items,
}: {
  items: TenancyRentEntity[];
}) => {
  const sum = calculateTenancyPaymentsSum(items);

  const arr: string[] = [];
  items.forEach((item) => {
    arr.push(
      `${item.name}: ${formatCurrency(item.amount, App.settings.currency)}`,
    );
  });

  return (
    <Text
      block
      size="small"
    >
      <Badge color={sum > 0 ? "green" : "red"}>
        {t("rentable.rent.regulation")}
      </Badge>
    </Text>
  );
};

export const OneTimePaymentLabel = ({
  items,
}: {
  items: TenancyRentEntity[];
}) => {
  const sum = calculateTenancyPaymentsSum(items);

  const arr: string[] = [];
  items.forEach((item) => {
    arr.push(
      `${item.name}: ${formatCurrency(item.amount, App.settings.currency)}`,
    );
  });

  const labelTrans =
    sum > 0
      ? t("rentable.rent.one_time_payment.added")
      : t("rentable.rent.one_time_payment.deducted");

  return (
    <Text
      block
      size="small"
    >
      <Badge color={sum > 0 ? "green" : "red"}>
        <Tooltip text={arr.join(", ")}>
          <div>{labelTrans}</div>
        </Tooltip>
      </Badge>
    </Text>
  );
};
