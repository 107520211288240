import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Notice,
  TextField,
  styled,
  Text,
  Flex,
} from "@boligportal/juice";
import emailSpellChecker from "@zootools/email-spell-checker";
import { App } from "components/app";
import {
  emailValidation,
  fullNameValidation,
  passwordValidation,
} from "components/forms/validation";
import { SignUpPermissions } from "components/interfaces/signup_permissions";
import { CustomerSupportBanner } from "components/modals/CustomerSupportBanner";
import { SignUpForm } from "components/modals/auth_views/signup_form";
import { useDebounce } from "hooks/useDebounce";
import { ValidationErrors } from "lib/api";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { parseFullName } from "lib/utils";

type SignUpWithEmailFormFields = {
  fullName: string;
  username: string;
  password: string;
  signupPermissions: {
    accepted_terms: boolean;
    send_newsletter: boolean;
    send_relevant_content: boolean;
    send_statistics_emails: boolean;
  };
};

const StyledSuggestButton = styled(Button)`
  color: ${(props) => props.theme.color.context.warning[700]} !important;

  &:hover {
    text-decoration-color: ${(props) =>
      props.theme.color.context.warning[700]} !important;
  }
`;

const HINT_TIMEOUT = 500;

type Props = {
  title: string;
  signup: (
    firstName: string,
    lastName: string,
    username: string,
    password: string,
    signupPermissions: SignUpPermissions,
    preferredLanguage: string,
  ) => Promise<{ errors?: ValidationErrors }>;
  context?: SignupContext;
  onSignupSuccessful: () => void;
  onSwitchToLogin: () => void;
};

export const SignUpWithEmail = ({
  title,
  signup,
  onSignupSuccessful,
  onSwitchToLogin,
}: Props) => {
  const emailRequiredTrans = t("required_field.email");
  const passwordRequiredTrans = t("required_field.password");
  const fullNameRequiredTrans = t("required_field.fullName");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const {
    formState: { errors },
    setError,
    setValue,
    register,
    handleSubmit,
    watch,
  } = useForm<SignUpWithEmailFormFields>({
    mode: "onChange",
    defaultValues: {
      signupPermissions: {
        send_relevant_content: true,
        send_statistics_emails: true,
      },
    },
  });

  const { username, signupPermissions } = watch();

  const { showTermsError, setShowTermsError } = useAuthShowAcceptTermsError(
    signupPermissions.accepted_terms,
  );
  const { correctEmailSuggestion } = useSuggestCorrectEmail(username);

  const onSubmit = async (data: SignUpWithEmailFormFields) => {
    const parsedName = parseFullName(data.fullName);
    const fields: Partial<keyof SignUpWithEmailFormFields>[] = [
      "fullName",
      "username",
      "password",
    ];

    if (showTermsError) {
      return;
    }

    setDisableSubmitButton(true);

    const response = await signup(
      parsedName.firstName,
      parsedName.lastName,
      data.username,
      data.password,
      data.signupPermissions,
      App.settings.language,
    );

    setDisableSubmitButton(false);

    if (!response.errors && data.signupPermissions.accepted_terms) {
      onSignupSuccessful();
    } else {
      if (!data.signupPermissions.accepted_terms) {
        setShowTermsError(true);
      }

      const serverErrors = response.errors;
      if (!serverErrors) {
        return;
      }

      fields.forEach((key) => {
        if (!(key in serverErrors)) {
          return;
        }
        const err = serverErrors[key];
        if (err.length > 0) {
          setError(key, {
            message: err[0],
          });
        }
      });
    }
  };

  return (
    <>
      <DialogHeader>{title}</DialogHeader>
      <DialogForm onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            testId={"signUpName"}
            label={t("signup.full_name")}
            required
            type="text"
            errorText={errors.fullName?.message?.toString()}
            helpText={t("signup.full_name.help_text")}
            {...register("fullName", {
              validate: (value: string) =>
                fullNameValidation(value) || fullNameRequiredTrans,
            })}
          />

          <TextField
            testId={"signUpUsername"}
            label={t("signup.E-mail address")}
            required
            type="text"
            errorText={errors.username?.message?.toString()}
            {...register("username", {
              validate: (value: string) =>
                emailValidation(value) || emailRequiredTrans,
            })}
          />

          {correctEmailSuggestion && (
            <Box mt={-2}>
              <Notice
                type="warning"
                size="small"
              >
                <StyledSuggestButton
                  text
                  onClick={() => {
                    setValue("username", correctEmailSuggestion, {
                      shouldValidate: true,
                    });
                  }}
                  fontSize="tiny"
                >
                  {t("signup.username.hint", {
                    correctEmailSuggestion,
                  })}
                </StyledSuggestButton>
              </Notice>
            </Box>
          )}

          <TextField
            testId={"signUpPassword"}
            type="password"
            required
            label={t("signup.Password")}
            helpText={t("signup.password.help_text")}
            errorText={errors.password?.message?.toString()}
            {...register("password", {
              validate: (value: string) =>
                passwordValidation(value) || passwordRequiredTrans,
            })}
          />

          <Box pt={2}>
            <SignUpForm
              register={register}
              showError={showTermsError}
            />
          </Box>
        </DialogContent>

        <DialogFooter>
          <Flex
            column
            flexGrow
          >
            <Button
              testId="signUpButton"
              disabled={disableSubmitButton}
              variant="branded"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              fullWidth
            >
              {t("signup_with_email.button.Sign up")}
            </Button>
            <Box
              width="100%"
              textAlign="center"
              mt={2}
            >
              <Text>{t("signup_view.footer.Already have an account?")} </Text>
              <Button
                testId={"loginHere"}
                variant="branded"
                text
                onClick={onSwitchToLogin}
              >
                {t("signup_view.footer.Log in here")}
              </Button>
            </Box>
          </Flex>
        </DialogFooter>
        <CustomerSupportBanner />
      </DialogForm>
    </>
  );
};

const useSuggestCorrectEmail = (email: string) => {
  const [correctEmailSuggestion, setSuggestion] = useState("");
  const debouncedUsername = useDebounce(email, HINT_TIMEOUT);

  useEffect(() => {
    if (debouncedUsername) {
      const suggestedEmail = emailSpellChecker.run({
        email: debouncedUsername,
      });

      setSuggestion("");

      if (suggestedEmail && debouncedUsername !== suggestedEmail.full) {
        setSuggestion(suggestedEmail.full);
      }
    }
  }, [debouncedUsername]);

  return {
    correctEmailSuggestion,
  };
};

const useAuthShowAcceptTermsError = (termsAccepted: boolean) => {
  const [showTermsError, setShowTermsError] = useState(false);
  useEffect(() => {
    if (termsAccepted) {
      setShowTermsError(false);
    } else {
      setShowTermsError(true);
    }
  }, [termsAccepted]);

  return {
    showTermsError,
    setShowTermsError,
  };
};
