import { Filters } from "components/interfaces/filters";
import { t } from "lib/i18n";

export const getReadableFilter = (filters: Filters) => {
  const textualSegments: string[] = [];
  let numericSegments: string[] = [];

  const location = [
    ...(filters.city_level_1?.values || []),
    ...(filters.city_level_2?.values || []),
    ...(filters.city_level_3?.values || []),
  ]
    .join(", ")
    .replace(/(^[a-zA-Z]{1})|((\s{1})[a-zA-Z]{1})/gi, (match) =>
      match.toUpperCase(),
    );

  if (location.length) {
    textualSegments.push(location);
  }

  if (filters.categories?.values?.length) {
    const categories = filters.categories.values
      .map((category) =>
        t(category).replace(/^[a-z]{1}/gi, (match) => match.toUpperCase()),
      )
      .join("/");

    textualSegments.push(categories);
  }

  if (filters.max_monthly_rent) {
    numericSegments.push(
      `${t("ad_filter.max")} ${t("ad_filter.max_price.label")} ${
        filters.max_monthly_rent
      },-`,
    );
  }

  if (filters.rooms?.gte || filters.rooms?.lte) {
    const roomsSegements: string[] = [];
    let usePlural = false;

    if (filters.rooms?.gte) {
      usePlural = filters.rooms.gte > 1;
      roomsSegements.push(`${t("ad_filter.min")} ${filters.rooms.gte}`);
    }

    if (filters.rooms?.lte) {
      usePlural = filters.rooms.lte > 1;
      roomsSegements.push(`${t("ad_filter.max")} ${filters.rooms.lte}`);
    }

    const roomsLabel = usePlural ? t("ad_filter.rooms") : t("ad_filter.room");
    numericSegments.push(
      `${roomsSegements.join(` ${t("ad_filter.and")} `)} ${roomsLabel}`,
    );
  }

  if (filters.min_size_m2) {
    numericSegments.push(`${t("ad_filter.min")} ${filters.min_size_m2} m²`);
  }

  numericSegments = numericSegments.map((segment) =>
    segment.toLocaleLowerCase(),
  );

  return [...textualSegments, ...numericSegments].join(", ");
};
