import { Ad } from "components/interfaces/ad";
import { Image } from "components/interfaces/image";

const getImageUrl = (options: {
  image: Image;
  width?: number;
  height?: number;
  dpr?: number;
  crop?: boolean;
  mask?: string;
}) => {
  const { dpr, crop, mask, width, height, image } = options;

  const dprParam = dpr ? "&dpr=" + dpr : "";
  // Don't crop floor plans, even if crop = true
  const cropParam =
    crop && !image.is_floor_plan ? "&fit=crop&crop=focalpoint" : "";
  const maskParam = mask ? `&mask=${mask}` : "";
  const widthParam = width ? `&w=${width}` : "";
  const heightParam = height ? `&h=${height}` : "";

  return `${image.url}?auto=compress,enhance,format${widthParam}${heightParam}${cropParam}${dprParam}${maskParam}`;
};

const getAdImages = (options: {
  ad: Ad;
  width?: number;
  height?: number;
  dpr?: number;
  crop?: boolean;
}): string[] => {
  const { ad, width, height, dpr, crop } = options;

  if (ad.images.length > 0) {
    return ad.images.map((image) =>
      getImageUrl({
        image,
        width,
        height,
        dpr,
        crop,
      }),
    );
  } else {
    return ["/static/images/no-image.jpg"];
  }
};

const getAdImage = (options: {
  ad: Ad;
  width?: number;
  height?: number;
  dpr?: number;
  crop?: boolean;
}): string => getAdImages(options)[0];

const getAdImageSrcSet = (options: {
  ad: Ad;
  width?: number;
  height?: number;
  index?: number;
  crop?: boolean;
}) => {
  const dprs = [1, 2];
  return dprs
    .map(
      (dpr) =>
        getAdImages({
          ad: options.ad,
          crop: options.crop,
          dpr: dpr,
          height: options.height,
          width: options.width,
        })[options.index ?? 0] + ` ${dpr}x`,
    )
    .join(", ");
};

export { getAdImage, getAdImages, getAdImageSrcSet, getImageUrl };
