import { useMutation, useQueryClient } from "react-query";
import { captureException } from "@sentry/react";
import { Ad } from "components/interfaces/ad";
import {
  getNewBuildBoostPackage,
  editNewBuildAddress,
  toggleBankIdVerificationBadge,
  updateNewBuild,
  NewBuildSEOPayload,
} from "./customerServiceApi";
import { customerServiceNewBuildKeys } from "./customerServiceQueries";

export const useMutationNewBuildBoostPackage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { newbuildId: number; value: boolean }) =>
      getNewBuildBoostPackage(params.newbuildId, params.value),
    {
      onSettled: (_, __, newbuildId) => {
        queryClient.invalidateQueries(
          customerServiceNewBuildKeys.newbuild(newbuildId.newbuildId),
        );
      },
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
    },
  );
};

export const useMutationEditNewBuildAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { newbuildId: number; payload: Ad["address"] }) =>
      editNewBuildAddress(params.newbuildId, params.payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, newbuildId) => {
        queryClient.invalidateQueries(
          customerServiceNewBuildKeys.newbuild(newbuildId.newbuildId),
        );
      },
    },
  );
};

export const useMutationEditNewBuildSEO = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { newbuildId: number; payload: NewBuildSEOPayload }) =>
      updateNewBuild(params.newbuildId, params.payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, newbuildId) => {
        queryClient.invalidateQueries(
          customerServiceNewBuildKeys.newbuild(newbuildId.newbuildId),
        );
      },
    },
  );
};

export const useMutationToggleBankIdVerificationBadge = () => {
  return useMutation(
    (params: { userId: number }) =>
      toggleBankIdVerificationBadge(params.userId),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
    },
  );
};
