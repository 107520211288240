import {
  Dialog,
  DialogHeader,
  DialogContent,
  Text,
  Box,
  Spinner,
  Flex,
} from "@boligportal/juice";
import { App } from "components/app";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { CustomerSupportBanner } from "components/modals/CustomerSupportBanner";
import { AccountInfo } from "lib/api";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";
import { UpsellContainer } from "./UpsellContainer";

type Props = {
  plans: Record<string, Plan[]>;
  accountInfo: AccountInfo | null;
  open: boolean;
  selectPlan: (plan: Plan) => void;
  loading: boolean;
  close: () => void;
  activeUpsellSubscriptions: AdSubscription[];
  title?: string;
  isAdLessThan24HoursOld: boolean;
};

export const RentableUpsellDialog = ({
  plans,
  accountInfo,
  open,
  selectPlan,
  loading,
  close,
  activeUpsellSubscriptions,
  isAdLessThan24HoursOld,
  title = t("upsell.dialog.title"),
}: Props) => {
  const sortedPlans = Object.keys(plans)
    .filter((key) => plans[key].length > 0)
    .sort((a, b) => plans[b][0].price.total - plans[a][0].price.total);

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogHeader>{title}</DialogHeader>

      <DialogContent>
        {loading ? (
          <Flex
            minHeight={50}
            justify="center"
            align="center"
          >
            <Spinner size="large" />
          </Flex>
        ) : (
          <>
            {accountInfo?.enabled && (
              <Box
                textAlign="center"
                mb={3}
              >
                <Text weight="semiBold">
                  {t("upsell.dialog.account_balance")}:
                </Text>
                {formatCurrency(accountInfo.balance, App.settings.currency)}
              </Box>
            )}
            {sortedPlans.map((plansKey) => {
              return (
                <UpsellContainer
                  selectPlan={selectPlan}
                  activeUpsellSubscriptions={activeUpsellSubscriptions}
                  isAdLessThan24HoursOld={isAdLessThan24HoursOld}
                  plans={plans[plansKey]}
                  key={plansKey}
                />
              );
            })}
          </>
        )}
      </DialogContent>
      <CustomerSupportBanner />
    </Dialog>
  );
};
