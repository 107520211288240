import { useCustomerServiceAdDetailPage } from "apps/customer_service/pages/ad_detail_page/ad_detail_page";
import { lockableFormfieldService } from "../services/lockable_formfield_service";

export const useLockableFormFieldFeature = () => {
  const { adId, requestRefetch } = useCustomerServiceAdDetailPage();

  const lockFormField = (fieldName: string) => {
    if (adId === null) {
      return;
    }

    lockableFormfieldService(adId, fieldName, true).then((result) => {
      if (result.success) {
        requestRefetch();
      }
    });
  };

  const unlockFormField = (fieldName: string) => {
    if (adId === null) {
      return;
    }
    lockableFormfieldService(adId, fieldName, false).then((result) => {
      if (result.success) {
        requestRefetch();
      }
    });
  };

  return {
    lockFormField,
    unlockFormField,
  };
};
