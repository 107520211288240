import * as React from "react";
import { Badge } from "@boligportal/juice";
import { EmailEventStatus, EmailLogEntry } from "../types";

const StatusColor: Partial<
  Record<EmailEventStatus, React.ComponentProps<typeof Badge>["color"]>
> = {
  Delivery: "green",
  DeliveryDelay: "yellow",
  Bounce: "red",
};

export const StatusCell = ({ status }: EmailLogEntry) => (
  <Badge color={StatusColor[status]}>{status}</Badge>
);
