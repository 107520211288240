import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { useResponseErrorsNotification } from "apps/moving_reports/hooks";
import { t } from "lib/i18n";
import { API } from "../api";
import { MovingReportType } from "../enums";
import { MovingReportPatch } from "../interfaces/movingReportPatch";
import { useMovingReport } from "../providers/MovingReportProvider";

export const useReportActions = () => {
  const { replaceReport, report } = useMovingReport();
  const { addErrorNotification } = useErrorNotification();
  const { showNotification } = useResponseErrorsNotification();

  const createReport = async (
    type: MovingReportType,
    rentableId: number,
    isInitiatedFromApp?: boolean,
    tenancyId?: number,
  ) =>
    await API.createMovingReport(
      rentableId,
      type,
      isInitiatedFromApp,
      tenancyId,
    );

  const updateReport = async (reportUpdate: Partial<MovingReportPatch>) => {
    API.updateMovingReport(report.id, reportUpdate)
      .then((response) => {
        if (response.errors && Object.keys(response.errors).length) {
          showNotification(response);
        }

        replaceReport(response.report);
      })
      .catch(() => {
        addErrorNotification(t("moving_reports.error.failed_to_update_report"));
      });
  };

  const validateReport = async (movingReportId: number) => {
    try {
      return await API.validateReport(movingReportId);
    } catch {
      addErrorNotification(t("moving_reports.error.failed_to_validate_report"));
    }
  };

  const finalizeReport = async (movingReportId: number) => {
    try {
      replaceReport((await API.finalizeReport(movingReportId)).report);
    } catch {
      addErrorNotification(t("moving_reports.error.failed_to_finalize_report"));
    }
  };

  const landlordSigned = async (
    movingReportId: number,
    landlordRepresentativeSignatureImageId: number,
  ) => {
    try {
      replaceReport(
        (
          await API.landlordSigned(
            movingReportId,
            landlordRepresentativeSignatureImageId,
          )
        ).report,
      );
    } catch {
      addErrorNotification(
        t("moving_reports.error.failed_to_sign_as_landlord"),
      );
    }
  };

  const removeSignatures = async (movingReportId: number) => {
    try {
      replaceReport((await API.removeSignatures(movingReportId)).report);
    } catch {
      addErrorNotification(t("moving_reports.error.failed_to_update_report"));
    }
  };

  const removeTenantsSignatures = async (tenantId: number) => {
    try {
      replaceReport((await API.removeTenantsSignatures(tenantId)).report);
    } catch {
      addErrorNotification(t("moving_reports.error.failed_to_update_report"));
    }
  };

  return {
    createReport,
    updateReport,
    validateReport,
    finalizeReport,
    landlordSigned,
    removeSignatures,
    removeTenantsSignatures,
  };
};
