/* eslint-disable compat/compat */
import { Filters } from "components/interfaces/filters";

export const addFiltersToQueryParams = (
  queryParams: URLSearchParams,
  data: {
    filters: Filters;
    view: "map" | "list";
    offset: number;
    mapCenter: [number, number] | null;
    mapZoom: number | null;
    selectedAds: number[];
  },
) => {
  const { filters, view, offset, mapCenter, mapZoom, selectedAds } = data;

  if (view === "list") {
    queryParams.delete("view");
  } else {
    queryParams.set("view", view);
  }

  if (mapCenter && mapZoom) {
    queryParams.set("zoom", mapZoom.toString());
    queryParams.set("center", `${mapCenter[0]},${mapCenter[1]}`);
  } else {
    queryParams.delete("zoom");
    queryParams.delete("center");
  }

  if (selectedAds.length > 0) {
    queryParams.set("ads", selectedAds.toString());
  } else {
    queryParams.delete("ads");
  }

  if (offset > 1) {
    queryParams.set("offset", offset.toString());
  } else {
    queryParams.delete("offset");
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (key === "order" && value === "DEFAULT") {
      queryParams.delete(key);
      return;
    }
    if (!value && value !== 0) {
      queryParams.delete(key);
      return;
    }

    if (typeof value === "object") {
      return;
    }
    const paramValue = value === true ? "1" : `${value}`;

    queryParams.set(key, paramValue);
  });
};
