import { SEAddressCandidate } from "../interfaces/SEAddressCandidate";

export const transformDataToAddressCandidates = (
  data: Array<any>,
): Array<SEAddressCandidate> => {
  const matches: Array<SEAddressCandidate> = [];

  data.forEach((item) => {
    if (item.type) {
      matches.push({
        id: item.id,
        candidateAddress: item.address,
        isAddress: item.type === "Address",
        candidateId: item.id,
        extra: item.extra_text,
      });
    }
  });

  return matches;
};
