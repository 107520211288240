import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CheckField,
  Flex,
  SelectField,
  Text,
  TextField,
} from "@boligportal/juice";
import { App } from "components/app";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogSidebar,
} from "components/dialog/dialog";
import { Modal } from "components/modal";
import { cancelSubscription } from "lib/customerServiceApi";
import { CancellationReasons } from "lib/enums";
import SvgX16px from "media/icons/X16px";
import { UserForCustomerService } from "../../user_detail_view/user_detail_view";
import { SubscriptionPaymentsItemInterface } from "../interfaces";
import { SubscriptionStatusBlock } from "./subscription_status_block";

interface CancellationForm {
  cancellationReasonSelect: CancellationReasons;
  cancellationReasonTextArea: string;
}

interface CancelSubscriptionSidebarProps {
  closeHandler: () => void;
  user: UserForCustomerService;
  item: SubscriptionPaymentsItemInterface;
}

const CancelSubscriptionSidebar = ({
  closeHandler,
  user,
  item,
}: CancelSubscriptionSidebarProps) => {
  const { register, watch, handleSubmit } = useForm<CancellationForm>({
    defaultValues: {
      cancellationReasonSelect: CancellationReasons.TOO_EXPENSIVE,
      cancellationReasonTextArea: "",
    },
  });

  const watcher = watch("cancellationReasonSelect");
  const watchTextArea = watch("cancellationReasonTextArea", "");
  const [expire, setExpire] = useState(false);

  const onSubmit = (data: CancellationForm) => {
    const reason =
      data.cancellationReasonSelect === CancellationReasons.OTHER
        ? data.cancellationReasonTextArea
        : data.cancellationReasonSelect;
    cancelSubscription(item.subscription.id, reason, expire).then(() => {
      closeHandler();
    });
  };

  return (
    <Modal>
      <DialogSidebar
        width="500px"
        onClose={closeHandler}
      >
        <DialogHeader>
          <Flex
            width="100%"
            align="center"
          >
            <Text size="h3">Cancel subscription</Text>
            <Box flexPush>
              <Button onClick={closeHandler}>
                <SvgX16px />
              </Button>
            </Box>
          </Flex>
        </DialogHeader>
        <DialogBody>
          <Flex column>
            <Text
              block
              size="tiny"
            >
              User
            </Text>
            <Text
              block
              weight="bold"
            >{`${user.email} - ${user.first_name} ${user.last_name}`}</Text>
          </Flex>

          <Flex
            column
            mt={2}
          >
            <Text
              block
              size="tiny"
            >
              Product
            </Text>
            <Flex gap>
              <SubscriptionStatusBlock subscription={item.subscription} />
            </Flex>
          </Flex>
          <Flex
            column
            mt={2}
          >
            <Flex gap>
              <Text
                block
                weight="bold"
              >
                Total payments:
              </Text>
              <Text ml={1}>{item.payments.length}</Text>
            </Flex>
          </Flex>
          <Flex
            column
            mt={2}
          >
            <SelectField
              {...register("cancellationReasonSelect")}
              label="Reason for cancellation"
              items={[
                {
                  value: CancellationReasons.TOO_EXPENSIVE,
                  label: "It's too expensive",
                },
                {
                  value: CancellationReasons.FOUND_PLACE_ON_THE_PLATFORM,
                  label: `Found another place on ${App.settings.market}`,
                },
                {
                  value: CancellationReasons.FOUND_ANOTHER_PLACE_ELSEWHERE,
                  label: "Found another place elsewhere",
                },
                {
                  value: CancellationReasons.OTHER,
                  label: "Other",
                },
              ]}
            />
          </Flex>

          <Flex
            column
            mt={2}
          >
            {watcher && watcher === CancellationReasons.OTHER && (
              <TextField
                {...register("cancellationReasonTextArea")}
                multiLine
                label="Please write reason for cancelling"
              />
            )}
          </Flex>
          <Flex mt={2}>
            <CheckField
              onChange={() => setExpire(!expire)}
              checked={expire}
              label="Mark subscription as expired"
            />
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap>
            <Button
              variant="subtle"
              onClick={closeHandler}
            >
              No, keep subscription
            </Button>
            <Button
              variant="primary"
              disabled={
                watcher &&
                watcher === CancellationReasons.OTHER &&
                watchTextArea === ""
              }
              onClick={handleSubmit(onSubmit)}
            >
              Cancel subscription
            </Button>
          </Flex>
        </DialogFooter>
      </DialogSidebar>
    </Modal>
  );
};

export { CancelSubscriptionSidebar };
