import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  FlexColumn,
  Spinner,
  Text,
} from "@boligportal/juice";
import { useSimilarAds } from "api/queries";
import { AdCard } from "components/cards/ad_card";
import { t } from "lib/i18n";
import { awaitTimeout } from "lib/timeout";
import {
  FavouriteAdButtonPlacementTracking,
  TrackingEvent,
} from "lib/tracking/events";
import { getAdIdFromUrl } from "lib/utils";

export const ContactedInfo = () => {
  const history = useHistory();

  const adId = getAdIdFromUrl(history.location.pathname);

  const { data, isLoading } = useSimilarAds(adId);

  if (isLoading) {
    return <Spinner />;
  }

  const handleSimilar = async () => {
    if (data) {
      TrackingEvent.clickSeeAllRecomendedAds();

      await awaitTimeout(500);

      window.location.href = data.similar_ads_link;
    } else {
      TrackingEvent.clickUserBacklinkedAgentCreated();

      await awaitTimeout(500);

      history.goBack();
    }
  };

  const handleTracking = () => {
    TrackingEvent.clickRecomendedAd();
  };

  const adsCount = data?.similar_ads.length;

  const renderContent = () => {
    if (adsCount === 0) {
      return (
        <Text
          block
          markdown
          mx={1}
        >
          {t("profile_completion.contacted.text")}
        </Text>
      );
    }

    return (
      <>
        <Text block>
          {t("profile_completion.similar_ads.title", {
            count: adsCount,
          })}
        </Text>

        <Flex
          gap
          column={{
            xs: true,
            md: false,
          }}
          justify="center"
          mt={4}
        >
          {data?.similar_ads.map((ad) => (
            <FlexColumn
              size={{
                xs: 12,
                md: 4,
              }}
              key={ad.id}
            >
              <Box>
                <AdCard
                  placement={
                    FavouriteAdButtonPlacementTracking.similar_ads_ad_details
                  }
                  isPromoted={ad.is_promoted}
                  ad={ad}
                  onClick={handleTracking}
                />
              </Box>
            </FlexColumn>
          ))}
        </Flex>
      </>
    );
  };

  return (
    <Box>
      {renderContent()}

      <Divider
        my={2}
        mx={{
          xs: -2,
          md: -3,
        }}
      />

      <Box mt={4}>
        <Button
          variant="branded"
          onClick={handleSimilar}
          fullWidth
        >
          {t("profile_completion.similar_ads.button")}
        </Button>
      </Box>
    </Box>
  );
};
