import { BaseAttachmentType } from "../../common/interfaces";

export enum HousingTypes {
  APARTMENT = "APARTMENT",
  ROOM = "ROOM",
  HOUSE = "HOUSE",
  CABIN = "CABIN",
  OTHER = "OTHER",
}

export enum PaymentFrequency {
  MONTHLY = "MONTHLY",
  // ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
}

export enum UtilityOptions {
  PART_OF_RENT = "PART_OF_RENT",
  TENANT_PAYS = "TENANT_PAYS",
  TENANT_SUBSCRIPTION = "TENANT_SUBSCRIPTION",
  CONSUMPTION_BASED = "CONSUMPTION_BASED",
  OTHER = "OTHER",
}

export enum RightOfUse {
  ATTIC = "ATTIC",
  CELLAR = "CELLAR",
  GARAGE = "GARAGE",
  OTHER = "OTHER",
}

export enum InventoryOptions {
  APPENDIX = "APPENDIX",
  LIST = "LIST",
  NOT_INCLUDED = "NOT_INCLUDED",
}

export enum PetsAllowed {
  ALLOWED = "ALLOWED",
  DISPENSATION = "DISPENSATION",
  DISALLOWED = "DISALLOWED",
}

export enum PaymentOptions {
  PLUS_GIRO_NO = "PLUS_GIRO_NO",
  BANK_GIRO_NO = "BANK_GIRO_NO",
  BANK_TRANSFER_NO = "BANK_TRANSFER_NO",
  CASH_AGAINST_RECEIPT = "CASH_AGAINST_RECEIPT",
}

export enum StandardTerms_SE {}

// Enums aren't extendable, so use a plain object instead
export const AttachmentType = {
  ...BaseAttachmentType,
} as const;
