import { awaitTimeout } from "lib/timeout";
import { Tracking } from "lib/tracking/common";
import { EventCategory } from "lib/tracking/events";

export const trackUserDeleteContractFromContractList = async () => {
  Tracking.trackEvent({
    category: EventCategory.content_deletion,
    label: "contracts-list",
    action: "Contract successfully deleted",
    isInteraction: true,
    value: "",
  });

  return awaitTimeout(200);
};

export const trackUserDeleteContractFromStatusPage = async () => {
  Tracking.trackEvent({
    category: EventCategory.content_deletion,
    label: "contracts-status-page",
    action: "Contract successfully deleted",
    isInteraction: true,
    value: "",
  });

  return awaitTimeout(200);
};

export const trackUserDeleteContractFromRentableContractsTab = async () => {
  Tracking.trackEvent({
    category: EventCategory.content_deletion,
    label: "rentable-detail-page-contracts-tab",
    action: "Contract successfully deleted",
    isInteraction: true,
    value: "",
  });

  return awaitTimeout(200);
};

export const trackUserDeletePOAFromContractsStatusPage = async () => {
  Tracking.trackEvent({
    category: EventCategory.content_deletion,
    label: "poa-contract-status-page",
    action: "POA successfully deleted",
    isInteraction: true,
    value: "",
  });

  return awaitTimeout(200);
};
