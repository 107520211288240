import { useState, useCallback } from "react";
import { checkVideoStatusService } from "../services/check_video_status_service";

const POLLING_DELAY = 5000;

const useVideoPlaybackFeature = () => {
  const [videoStatus, setVideoStatus] = useState<
    "idle" | "processing" | "playable" | "malformed"
  >("idle");

  const checkVideoStatus = useCallback((videoId: number) => {
    checkVideoStatusService(videoId).then((response) => {
      switch (response.status) {
        case "not_ready": {
          setVideoStatus("processing");
          setTimeout(() => checkVideoStatus(videoId), POLLING_DELAY);
          break;
        }
        case "playable": {
          setVideoStatus("playable");
          break;
        }
        case "error": {
          setVideoStatus("malformed");
          break;
        }
      }
    });
  }, []);

  return {
    videoStatus,
    checkVideoStatus,
  };
};

export { useVideoPlaybackFeature };
