import { useState } from "react";
import {
  Flex,
  Button,
  Text,
  TextField,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
} from "@boligportal/juice";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { fullNameValidation } from "components/forms/validation";
import { Profile } from "components/interfaces/profile";
import { User } from "components/interfaces/user";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { formatFullName, parseFullName } from "lib/utils";

export const SetProfileNameDialog = ({
  user,
  profile,
  isOpen,
  onClose,
}: {
  user: User;
  profile: Profile;
  isOpen: boolean;
  onClose?: (isNameChanged?: boolean) => void;
}) => {
  const appActions = useAppActions();

  const [fullName, setFullName] = useState(
    formatFullName(user.first_name, user.last_name),
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async () => {
    setIsLoading(true);

    const parsedNames = parseFullName(fullName);

    try {
      await API.createOrUpdateProfile({
        ...profile,
        first_name: parsedNames.firstName,
        last_name: parsedNames.lastName,
      });

      appActions.reloadAppProps();

      onClose && onClose(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose && onClose()}
    >
      <DialogHeader>
        {t("change_profile_name_dialog.insert_your_name")}
      </DialogHeader>

      <DialogContent>
        <Text
          size="small"
          color="label"
          mb={4}
          block
        >
          {t("change_profile_name_dialog.name_cannot_be_changed")}
        </Text>

        <TextField
          required
          autoFocus
          label={t("change_profile_name_dialog.full_name")}
          placeholder={t("change_profile_name_dialog.full_name_placeholder")}
          helpText={t("change_profile_name_dialog.minimum_two_words_required")}
          value={fullName}
          onChange={(event) => setFullName(event.target.value)}
          disabled={isLoading}
        />
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={() => onClose && onClose()}
          >
            {t("change_profile_name_dialog.cancel")}
          </Button>
          <Button
            variant="branded"
            onClick={onSave}
            disabled={isLoading || !fullNameValidation(fullName)}
          >
            {t("change_profile_name_dialog.submit")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
