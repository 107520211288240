import { useState } from "react";
import * as React from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Flex,
  Text,
} from "@boligportal/juice";
import { useUnitTypeLabels } from "apps/moving_reports/enums";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { MovingReportForTenant } from "apps/moving_reports/tenant_app/interfaces/movingReportForTenant";
import { t } from "lib/i18n";
import { uniq } from "lodash-es";
import { DefectSelections } from "../CreateDefectDialog";
import { Filter } from "./Filter";
import { List } from "./List";

export const UnitsSelector = ({
  report,
  defectSelections,
  isTenantFlow,
  onEdit,
  onNext,
  onClose,
}: {
  report: MovingReport | MovingReportForTenant;
  defectSelections: DefectSelections;
  onEdit: (payload: Partial<DefectSelections>) => void;
  isTenantFlow: boolean;
  onNext: () => void;
  onClose: () => void;
}) => {
  const { rooms } = report;
  const { units, room } = defectSelections;

  const { getUnitTypeLabel } = useUnitTypeLabels();

  const [ids, setIds] = useState(units);
  const [filter, setFilter] = React.useState("");

  const handleUnitSelect = (values: number[], checked: boolean) => {
    setIds((previous) =>
      checked
        ? uniq([...previous, ...values])
        : previous.filter((item) => !values.includes(item)),
    );
  };

  const handleSelectSingle = (id: number) => {
    onEdit({
      units: [id],
    });
    onNext();
  };

  const handleNext = () => {
    onEdit({
      units: ids,
    });
    onNext();
  };

  const filtered = rooms.reduce((memo, item) => {
    const searchTerm = filter.toLowerCase();
    const isRoom = item.label.toLowerCase().includes(searchTerm);

    if (isRoom) {
      return [...memo, item];
    }

    const units = item.units.filter((unit) => {
      const isUnitLabel = unit.label.toLowerCase().includes(searchTerm);
      const isUnitType = getUnitTypeLabel(unit.type)
        .toLowerCase()
        .includes(searchTerm);

      return isUnitLabel || isUnitType;
    });

    return units.length !== 0
      ? [
          ...memo,
          {
            ...item,
            units,
          },
        ]
      : memo;
  }, []);

  const getCounter = () => {
    const count = ids.length;

    if (count === 0) {
      return t("moving_reports.batch_unit_selector.no_units_selected");
    }

    if (count === 1) {
      return t("moving_reports.batch_unit_selector.one_unit_selected");
    }

    return t("moving_reports.batch_unit_selector.units_selected", {
      count,
    });
  };

  const roomToShow = room && units.length === 0 ? room.id : undefined;

  return (
    <>
      <DialogHeader>
        {t("moving_reports.batch_unit_selector.header")}
      </DialogHeader>

      <DialogContent>
        <Filter
          filter={filter}
          onFilter={setFilter}
        />

        <List
          rooms={filtered}
          selected={ids}
          roomToShow={roomToShow}
          isTenantFlow={isTenantFlow}
          onSelect={handleUnitSelect}
          onSelectSingle={handleSelectSingle}
        />
      </DialogContent>

      <DialogFooter>
        <Flex
          gap
          width={100}
        >
          {!isTenantFlow && (
            <Text
              block
              as="em"
              mt={1}
              ml={1}
            >
              {getCounter()}
            </Text>
          )}
          <Box flexPush>
            <Box
              mr={2}
              inline
            >
              <Button
                variant="subtle"
                onClick={onClose}
              >
                {t("common.cancel")}
              </Button>
            </Box>
            <Button
              variant={isTenantFlow ? "branded" : "primary"}
              onClick={handleNext}
              disabled={ids.length === 0}
            >
              {t("moving_reports.batch_unit_selector.create_defect")}
            </Button>
          </Box>
        </Flex>
      </DialogFooter>
    </>
  );
};
