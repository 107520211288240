import { useContext } from "react";
import { MarketSpecificContext } from "../context/MarketSpecificContext";

const useExternalPhoneWebsites = () => {
  const { externalPhoneWebsites } = useContext(MarketSpecificContext);
  return {
    externalPhoneWebsites,
  };
};

export { useExternalPhoneWebsites };
