import { useState, useContext } from "react";
import { Box, Confirm, Flex, Notice, styled, Text } from "@boligportal/juice";
import { format } from "date-fns";
import { liftBanOnUser } from "lib/customerServiceApi";
import { simpleDateMonthTimeFormatter } from "lib/date";
import { blacklistReasonEnum } from "lib/enums";
import { UserDetailPageContext } from "../user_detail_page_context";
import { blacklisted } from "../user_detail_view/user_detail_view";

interface BlacklistUserNoticeProps {
  bans: blacklisted[];
  view: "ad_detail" | "user_detail" | "newbuild_detail";
}

export const prettifiedReason = {
  profile_sharing: "Profile sharing",
  company_deal: "Company deal",
  phished: "Phising",
  social_housing: "Social housing",
  maybe_scammer: "Maybe scammer",
  other_yellow: "Other (yellow)",
  banned_user: "Banned user",
  rejected_landlord: "Rejected landlord",
  rejected_seeker: "Rejected seeker",
  other_red: "Other (red)",
};

const BlacklistUserNotice = ({ bans, view }: BlacklistUserNoticeProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [stateBanId, setStateBanId] = useState<number>(0);
  const { refetch } = useContext(UserDetailPageContext);

  const yellowCategory = [
    "profile_sharing",
    "company_deal",
    "phished",
    "social_housing",
    "maybe_scammer",
    "other_yellow",
  ];

  const getNoticeType = (reason: blacklistReasonEnum) => {
    if (yellowCategory.includes(reason)) {
      return "warning";
    } else {
      return "danger";
    }
  };

  const handleRemoveBanClick = (id: number) => {
    setStateBanId(id);
    setShowConfirm(true);
  };

  const handleRemoveBan = (id: number) => {
    liftBanOnUser(id).then(refetch);
    setShowConfirm(false);
  };

  const StyledClickableSpan = styled.span`
    font-weight: ${(props) => props.theme.fontWeight.bold};
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `;

  return (
    <Flex column>
      <Confirm
        open={showConfirm}
        dangerous
        size="large"
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          handleRemoveBan(stateBanId);
        }}
        title="Blacklist remove"
        message={
          <Box>
            <Text>Are you sure you want to the remove the blacklisting?</Text>
            <ul>
              <li>
                If the user is restricted to have all ads go to &quot;waiting
                for approval&quot;, this will be removed.
              </li>
              <li>
                If the user is set not to receive emails, this will be removed.
              </li>
            </ul>
          </Box>
        }
        confirmText="Remove"
        cancelText="Cancel"
      />
      {bans.map((ban) => {
        const timestampAsYear = format(new Date(ban.timestamp), "yyyy");

        return (
          <Notice
            type={getNoticeType(ban.reason)}
            key={ban.id}
          >
            <strong>Blacklist: {prettifiedReason[ban.reason]}</strong>
            {view === "user_detail" && (
              <Box mt={1}>
                {ban.agent} -{" "}
                {`${timestampAsYear} ${simpleDateMonthTimeFormatter(
                  new Date(ban.timestamp),
                )} `}
                <span
                  dangerouslySetInnerHTML={{
                    __html: ban.note,
                  }}
                ></span>
                <Box>
                  <Box mt={1}>
                    <StyledClickableSpan
                      onClick={() => handleRemoveBanClick(ban.id)}
                    >
                      Remove blacklist
                    </StyledClickableSpan>
                  </Box>
                </Box>
              </Box>
            )}
          </Notice>
        );
      })}
    </Flex>
  );
};

export default BlacklistUserNotice;
