import { Box, Headline, Flex, Text } from "@boligportal/juice";
import { PageBlock } from "apps/landingpages/renting_out/components/PageBlock";

interface Props {
  headline: string;
  text: string;
}

export const SeoSection = ({ headline, text }: Props) => (
  <PageBlock
    bg="default"
    py="none"
  >
    <Box
      pb={16}
      px={{
        xs: 2,
        md: 6,
      }}
      m="auto"
      width={"auto"}
      maxWidth={"1390px"}
    >
      <Flex
        column
        justify="center"
        width={{
          xs: "100%",
          md: "100%",
          lg: "70%",
        }}
      >
        <Headline as="h2">{headline}</Headline>
        <Text>{text}</Text>
      </Flex>
    </Box>
  </PageBlock>
);
