import { Box, Button } from "@boligportal/juice";

type Props = {
  disabled: boolean;
  label: string;
  onClick: () => void;
};

export const UpsellCardPurchaseButton = ({
  disabled,
  label,
  onClick,
}: Props) => (
  <Box
    mb={{
      xs: 1,
      md: 4,
    }}
  >
    <Button
      fullWidth
      disabled={disabled}
      variant="branded"
      onClick={onClick}
    >
      {label}
    </Button>
  </Box>
);
