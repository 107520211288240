import { Flex, InfoMarker, Text } from "@boligportal/juice";
import { localizedMonthDayAndYear } from "lib/date";

export type RentDueDateCell = {
  rentDueDateCell: {
    dueDate: Date;
    infoDialog?: {
      title: string;
      text: string;
    };
  };
};

export const rentDueDateCellRenderer = ({
  rentDueDateCell,
}: RentDueDateCell) => (
  <Flex align="baseline">
    {localizedMonthDayAndYear(rentDueDateCell.dueDate)}
    {rentDueDateCell.infoDialog && (
      <InfoMarker
        infoHeader={rentDueDateCell.infoDialog.title}
        infoText={<Text markdown>{rentDueDateCell.infoDialog.text}</Text>}
      />
    )}
  </Flex>
);
