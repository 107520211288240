import * as React from "react";
import { SortableColumn } from "@boligportal/juice";
import { SubscriptionFeedItemInterface } from "apps/customer_service/pages/user_detail_page/subscriptions_feed/interfaces";
import { useDebounce } from "hooks/useDebounce";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import { UserListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { AdItemsInterface } from "../../../../apps/customer_service/pages/ads_page/interfaces";
import { searchUsersService } from "../services/search_users_service";

export interface UsersListViewModel extends UserListItem {
  seeker_subscription: SubscriptionFeedItemInterface | null;
}

interface SearchUserFilters {
  searchValue: string;
  seekerStatus: string[];
  blacklistReasons: string[];
  creationDateFrom: string | null;
  creationDateTo: string | null;
  lastLoginDateFrom: string | null;
  lastLoginDateTo: string | null;
}

const INITIAL_FILTERS = {
  searchValue: "",
  seekerStatus: [],
  blacklistReasons: [],
  creationDateFrom: null,
  creationDateTo: null,
  lastLoginDateFrom: null,
  lastLoginDateTo: null,
};

const useSearchUsersWithPagination = (initialSearchValue: string) => {
  const { items, setItems, count, setCount, offset, setOffset, limit } =
    usePagination<UsersListViewModel>(25);
  const [filters, setFilters] = React.useState<SearchUserFilters>({
    ...INITIAL_FILTERS,
    searchValue: initialSearchValue,
  });
  const debouncedSearchValue = useDebounce(filters.searchValue, 250);
  const previousSearchValue = usePrevious(filters);
  const shouldResetOffset = useShouldResetOffset(
    previousSearchValue,
    filters,
    offset,
  );
  const [sorting, setSorting] = React.useState<SortableColumn<UserListItem>>({
    column: "date_joined",
    descending: true,
  });

  const filterChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const field = event.target.id;
      const { type } = event.target;
      let value: string | null = event.target.value; //eslint-disable-line

      // Backend expect null for "empty date values"
      if (type === "date" && value === "") {
        value = null;
      }

      const newFilterState: SearchUserFilters = {
        ...filters,
        [field]: value,
      };

      setFilters(newFilterState);
    },
    [filters],
  );

  const blacklistChangeHandler = React.useCallback(
    (value: string[]) => {
      const newFilterState: SearchUserFilters = {
        ...filters,
        blacklistReasons: value,
      };

      setFilters(newFilterState);
    },
    [filters],
  );

  const seekerStatusChangeHandler = React.useCallback(
    (value: string[]) => {
      const newFilterState: SearchUserFilters = {
        ...filters,
        seekerStatus: value,
      };

      setFilters(newFilterState);
    },
    [filters],
  );

  const sortingChangeHandler = React.useCallback(
    (value: SortableColumn<UserListItem>) => {
      setSorting(value);
    },
    [sorting],
  );

  React.useEffect(() => {
    const numChars = debouncedSearchValue.length;
    const skipRequest = numChars > 0 && numChars < 3;

    if (skipRequest) {
      return;
    }

    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    const { signal } = abortController;

    searchUsersService(
      {
        query: debouncedSearchValue,
        ban_reasons:
          filters.blacklistReasons.length > 0 ? filters.blacklistReasons : null,
        subscription_status:
          filters.seekerStatus.length > 0 ? filters.seekerStatus : null,
        creation_date_from: filters.creationDateFrom,
        creation_date_to: filters.creationDateTo,
        login_date_from: filters.lastLoginDateFrom,
        login_date_to: filters.lastLoginDateTo,
      },
      offset,
      limit,
      signal,
      sorting.column,
      sorting.descending,
    )
      .then((response) => {
        setItems(
          response.results.map((item) => ({
            ...item.user,
            seeker_subscription: item.seeker_subscription,
          })),
        );
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    seekerStatusChangeHandler,
    debouncedSearchValue,
    offset,
    limit,
    filters,
    setItems,
    setCount,
    setOffset,
    sorting,
  ]);

  React.useEffect(() => {
    if (shouldResetOffset) {
      setOffset(0);
    }
  }, [shouldResetOffset, setOffset]);

  return {
    filterChangeHandler,
    blacklistChangeHandler,
    seekerStatusChangeHandler,
    sortingChangeHandler,
    searchValue: filters.searchValue,
    items,
    count,
    limit,
    offset,
    setOffset,
    sorting,
  };
};

export { useSearchUsersWithPagination };

const useShouldResetOffset = (
  previousValue: SearchUserFilters | undefined,
  currentValue: SearchUserFilters,
  offset: number,
): boolean => {
  const shouldResetOffset = React.useCallback(
    () => previousValue !== currentValue && offset !== 0,
    [previousValue, currentValue, offset],
  );

  return shouldResetOffset();
};
