import * as React from "react";
import { Button, styled } from "@boligportal/juice";
import { Option } from "components/interfaces/option";

const DropdownWrapper = styled.div`
  font-family: Arial;
  position: relative;
  display: inline-block;
`;

const DropdownListGroup = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: ${(props) => props.theme.zIndex.popover};
  box-shadow: ${(props) => props.theme.shadow.sm};
  min-width: 100%;
  box-sizing: border-box;
  border: ${(props) => props.theme.color.border};
`;

const DropdownListItem = styled.div<{ selected: boolean }>`
  border: none;
  margin: 0;
  background-color: #ffffff;
  color: #333333;
  padding: ${(props) => props.theme.unit(1)} ${(props) => props.theme.unit(1)};
  white-space: nowrap;
  width: 100%;
  font-weight: ${(props) => props.theme.fontWeight.base};
  font-size: ${(props) => props.theme.fontSize.body};
  line-height: ${(props) => props.theme.unit(2)};
  cursor: pointer;
  -webkit-appearance: none;
  ${(props) =>
    props.selected &&
    `
      background-color: ${props.theme.colorPalette.brand[500]};
      color: #FFFFFF;
  `};
`;

type Props = {
  title: string;
  options: Option[];
  multiple?: boolean;
  onSelectionChange: (value: Option[]) => void;
};

export const DropdownMultiple = ({
  onSelectionChange,
  options,
  title,
  multiple,
}: Props) => {
  const isFirstCall = React.useRef(true);

  const clickOutsideWrapper = React.useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);

  const handleTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleDocumentMouseDown = (event) => {
    if (
      clickOutsideWrapper &&
      clickOutsideWrapper.current &&
      clickOutsideWrapper.current.contains(event.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  const handleItemClick = (option: Option) => {
    if (multiple) {
      const index = findOptionIndex(option);
      if (index > -1) {
        const newItems = [...selectedOptions];
        newItems.splice(index, 1);
        setSelectedOptions(newItems);
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      setSelectedOptions([option]);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
    };
  }, []);

  React.useEffect(() => {
    if (isFirstCall.current) {
      isFirstCall.current = false;
      return;
    }

    onSelectionChange(selectedOptions);
  }, [selectedOptions]);

  const findOptionIndex = (option: Option): number =>
    selectedOptions.findIndex((el) => el.id === option.id);

  const renderTitle = (): string => {
    if (selectedOptions.length === 0) {
      return title;
    } else if (selectedOptions.length === 1) {
      return selectedOptions[0].name;
    } else {
      return `${selectedOptions.length} selected`;
    }
  };

  return (
    <DropdownWrapper ref={clickOutsideWrapper}>
      <Button
        fullWidth
        variant={"primary"}
        onClick={handleTriggerClick}
      >
        {renderTitle()}
      </Button>

      {isOpen && (
        <DropdownListGroup>
          {options.map((option) => (
            <DropdownListItem
              selected={findOptionIndex(option) > -1}
              key={option.id}
              onClick={() => handleItemClick(option)}
            >
              {option.name}
            </DropdownListItem>
          ))}
        </DropdownListGroup>
      )}
    </DropdownWrapper>
  );
};
