import { Box, Flex, IconArrowForward, styled, Text } from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";

export const SuggestionMap = () => {
  const srpPathname = App.settings.routes.search_result_page;
  const handleOnClick = () => {
    TrackingEvent.clickSearchMapSweden();
    window.location.href = `${srpPathname}?view=map`;
  };

  return App.settings.market === Market.BOSTADSPORTAL_SE ? (
    <StyledSuggestion onClick={handleOnClick}>
      <Box
        width={7}
        height={7}
        mr={2}
      >
        <img
          src="/static/images/SE_HomePage_Map.jpg"
          style={{
            height: "100%",
            width: "100%",
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
        />
      </Box>
      <Text weight="semiBold">{t("search.on_map")}</Text>
      <StyledIconWrapper
        ml="auto"
        align="baseline"
        mr={2}
      >
        <IconArrowForward size={2.5} />
      </StyledIconWrapper>
    </StyledSuggestion>
  ) : null;
};

const StyledIconWrapper = styled(Flex)`
  color: ${(props) => props.theme.colorPalette.gray[100]};
`;

const StyledSuggestion = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: ${(props) => props.theme.unit(2.5)};
  background-color: ${(props) => props.theme.colorPalette.gray[30]};
  &:hover {
    background-color: ${(props) => props.theme.colorPalette.gray[40]};
  }
  border-radius: ${(props) => props.theme.borderRadius.sm};
`;
