import { useFormContext } from "react-hook-form";
import { Flex, CheckField, Text } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";

export const CompanySearchToggle = () => {
  const { register } = useFormContext();

  return (
    <Flex column>
      <CheckField
        {...register("hide_from_company_search")}
        label={t("rentable_details.hide_from_company_search")}
        inline
      />
      <Text size="small">
        {t("rentable_details.hide_from_company_search.extra_text", {
          brand_name: App.settings.brand_name,
        })}
      </Text>
    </Flex>
  );
};
