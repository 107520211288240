import { useMutation, useQueryClient } from "react-query";
import {
  addPaymentNote,
  deletePaymentRecord,
  deleteTenancy,
  GetPaymentDetailsResponse,
  setupRent,
  updatePaymentRecord,
  updatePaymentStatus,
  updateTenancyAfterCompletion,
  updateTenancyField,
} from "api";
import { rentableKeys } from "api/queries";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { TenancyAPI } from "features/rentable_tenancy/TenancyAPI";
import { AddPaymentForm } from "features/rentable_tenancy/components/PartialPaymentDialog/AddTenantPaymentDialog";
import { TenancySetupRentPayload } from "features/rentable_tenancy/setup_rent/types/TenancySetupRentPayload";
import { RentableTenancyTracking } from "features/rentable_tenancy/tracking/RentableTenancyTracking";
import {
  TenancyBasePayload,
  TenancyTenantPayload,
} from "features/tenancy/useTenancyDiffUpdater/types";
import { t } from "lib/i18n";
import { tenancyKeys } from "./queries";

export const useTenancyFieldMutation = () =>
  useMutation((payload: { tenancyId: number; field: any; value: any }) =>
    updateTenancyField({
      tenancyId: payload.tenancyId,
      field: payload.field,
      value: payload.value,
    }),
  );

export const useUpdateExistingTenancy = () =>
  useMutation(
    (payload: {
      tenancyId: number;
      tenancyBasePayload: TenancyBasePayload;
      tenancyTenantPayload?: Partial<TenancyTenantPayload>[];
    }) =>
      updateTenancyAfterCompletion({
        tenancyId: payload.tenancyId,
        tenancyBasePayload: payload.tenancyBasePayload,
        tenancyTenantPayload: payload.tenancyTenantPayload,
      }),
  );

export const useTenancyDeletionMutation = (invalidationQueries?: string[]) => {
  const queryClient = useQueryClient();

  const canDeleteTenancyDryRun = useMutation((tenancyId: number) =>
    deleteTenancy(tenancyId, true),
  );

  const deleteTenancyMutation = useMutation(
    (tenancyId: number) => deleteTenancy(tenancyId),
    {
      onSuccess: () => {
        invalidationQueries?.forEach((key) =>
          queryClient.invalidateQueries(key),
        );
      },
    },
  );

  return {
    canDeleteTenancyDryRun,
    deleteTenancyMutation,
  };
};

export const useSetupRentMutation = (
  rentableId: number,
  onSuccess: (tenancy: Tenancy) => void,
) => {
  const { addErrorNotification } = useErrorNotification();

  const errorCodes = {
    mobilepay_not_approved: t(
      "tenancy_payments.setup_rent.error.mobilepay_not_approved",
    ),
  };

  return useMutation(
    (payload: TenancySetupRentPayload) => setupRent(rentableId, payload),
    {
      onSuccess,
      onError: (error: any) => {
        addErrorNotification(errorCodes[error.response.data.error.code]);
      },
    },
  );
};

export const usePaymentRecordNoteMutation = (
  paymentRecordId: number,
  invalidationQueries: string[],
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: string) => addPaymentNote(paymentRecordId, payload),
    {
      onMutate: async (mutationPayload) => {
        await queryClient.cancelQueries(
          tenancyKeys.paymentRecordDetails(paymentRecordId),
        );

        const data = queryClient.getQueryData<GetPaymentDetailsResponse>(
          tenancyKeys.paymentRecordDetails(paymentRecordId),
        );

        if (data) {
          queryClient.setQueryData<GetPaymentDetailsResponse>(
            tenancyKeys.paymentRecordDetails(paymentRecordId),
            {
              ...data,
              note: mutationPayload,
            },
          );
        }
      },
      onSettled: () => {
        invalidationQueries.forEach((key) =>
          queryClient.invalidateQueries(key),
        );
      },
    },
  );
};

export const useCreateTenancyPaymentMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      paymentRecordId,
      data,
    }: {
      paymentRecordId: number;
      data: {
        transactions: AddPaymentForm[];
      };
    }) => TenancyAPI.createTenancyPayment(paymentRecordId, data),
    {
      onSuccess: (_, { paymentRecordId }) => {
        queryClient.invalidateQueries(rentableKeys.rentableRentOverview());
        queryClient.invalidateQueries(
          tenancyKeys.paymentRecordDetails(paymentRecordId),
        );
        queryClient.invalidateQueries(tenancyKeys.tenancyRent());
        queryClient.invalidateQueries(tenancyKeys.rentOverviewData());
        queryClient.invalidateQueries(tenancyKeys.rentOverviewDetails());
        onSuccess();
      },
    },
  );
};

export const usePaymentRecordOneTimePaymentMutation = (
  invalidationQueries?: string[],
) => {
  const queryClient = useQueryClient();
  let amount = 0;

  return useMutation(
    (data: {
      tenancyId: number;
      paymentRecordId: number;
      payload: TenancyRentEntity[];
    }) => {
      amount = data.payload.reduce((acc, item) => (acc += item.amount), 0);

      return updatePaymentRecord(data);
    },
    {
      onSuccess: () => {
        invalidationQueries?.forEach((key) =>
          queryClient.invalidateQueries(key),
        );
        RentableTenancyTracking.trackUserAddsAmountToPayment(amount);
      },
    },
  );
};

type Error = {
  response: {
    status: number;
    data: {
      error: {
        message: string;
        code: string;
      };
    };
  };
};

export const usePaymentRecordDeletionMutation = (
  invalidateQueries?: string[],
) => {
  const queryClient = useQueryClient();

  const canDeletePaymentRecordDryRun = useMutation(
    (paymentRecordId: number) => deletePaymentRecord(paymentRecordId, true),
    {
      onError: (error: Error) => {}, //eslint-disable-line
    },
  );

  const deletePaymentRecordMutation = useMutation(
    (paymentRecordId: number) => deletePaymentRecord(paymentRecordId),
    {
      onSuccess: () => {
        invalidateQueries?.forEach((key) => queryClient.invalidateQueries(key));
      },
    },
  );

  return {
    canDeletePaymentRecordDryRun,
    deletePaymentRecordMutation,
  };
};

export const usePaymentRecordStatus = (invalidateQueries?: string[]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { status: TenancyPaymentStatus; paymentRecordId: number }) =>
      updatePaymentStatus(payload),
    {
      onSuccess: () => {
        invalidateQueries?.forEach((key) => queryClient.invalidateQueries(key));
      },
    },
  );
};
