import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";

export const DanishExtraRoomInfo = () => {
  const { register } = useFormContext();

  return (
    <TextField
      label="Room info"
      {...register("room_info")}
    />
  );
};
