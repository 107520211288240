import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentStatusControl } from "features/rentable_tenancy/components/TenancyPaymentStatusControl";

export type RentPaymentStatusCell = {
  rentPaymentStatusCell: {
    status: TenancyPaymentStatus;
    onStatusChange: (newStatus: TenancyPaymentStatus) => void;
  };
};

export const rentPaymentStatusCellRenderer = ({
  rentPaymentStatusCell,
}: RentPaymentStatusCell) => {
  const onChangeHandler = (value: string) => {
    if (value === rentPaymentStatusCell.status) {
      return;
    }

    rentPaymentStatusCell.onStatusChange(value as TenancyPaymentStatus);
  };

  return (
    <TenancyPaymentStatusControl
      size="tiny"
      onChange={onChangeHandler}
      value={rentPaymentStatusCell.status}
    />
  );
};
