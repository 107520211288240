import * as React from "react";

const SvgLinkedInLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <filter id="LinkedIn_Logo_svg__a">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
      filter="url(#LinkedIn_Logo_svg__a)"
    >
      <path
        fill="#000"
        fillRule="nonzero"
        d="M45 0H5C2.25 0 0 2.25 0 5v40c0 2.75 2.25 5 5 5h40c2.75 0 5-2.25 5-5V5c0-2.75-2.25-5-5-5zM15 42.5H7.5V20H15v22.5zm-3.75-26.75c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5zM42.5 42.5H35V29.25c0-2-1.75-3.75-3.75-3.75s-3.75 1.75-3.75 3.75V42.5H20V20h7.5v3c1.25-2 4-3.5 6.25-3.5 4.75 0 8.75 4 8.75 8.75V42.5z"
      />
    </g>
  </svg>
);
export default SvgLinkedInLogo;
