import { App } from "components/app";
import { t } from "lib/i18n";

export const getRentalCategoryOptions = () => {
  const chooseCategory = t("Choose category");

  const rentalCategories = [
    {
      value: chooseCategory,
      label: chooseCategory,
    },
  ];

  App.settings.rental_categories.forEach((option) => {
    rentalCategories.push({
      value: `${option.id}`,
      label: option.name,
    });
  });

  return rentalCategories;
};
