import React from "react";
import { Badge, Text, styled } from "@boligportal/juice";

type Props = {
  selectedPlanId: number | null;
  planItems: SeekerPlanItem[];
  onSelect: (planId: number) => void;
};

export const PlanSelector = ({
  planItems,
  selectedPlanId,
  onSelect,
}: Props) => (
  <div className="space-y-2">
    {planItems.map(({ planId, planCardItem }) => (
      <PlanSelectorItemView
        key={planId}
        planId={planId}
        isSelected={selectedPlanId === planId}
        planCardItem={planCardItem}
        onSelect={onSelect}
      />
    ))}
  </div>
);

const PlanSelectorItemView = ({
  planId,
  planCardItem,
  isSelected,
  onSelect,
}: SeekerPlanItem & {
  planId: number;
  isSelected: boolean;
  onSelect: (planId: number) => void;
}) => {
  const {
    planName,
    priceFormatted,
    badgeText,
    planInfo,
    priceInfo,
    planSelectedInfoIcon,
    planSelectedInfoSlot,
  } = planCardItem;

  return (
    <StyledPlanCard selected={isSelected}>
      <label className={"m-0 flex cursor-pointer items-center"}>
        <input
          type="radio"
          name="plan_option"
          value={planId}
          checked={isSelected}
          onChange={() => {
            onSelect(planId);
          }}
          className="absolute opacity-0"
        />
        <div
          className={`mr-4 h-4 w-4 rounded-full border-2 border-solid md:mr-6 ${
            isSelected ? "border-primary-600" : "border-gray-50"
          } flex items-center justify-center`}
        >
          {isSelected && (
            <div className="bg-primary-600 h-1.5 w-1.5 rounded-full"></div>
          )}
        </div>
        <div className="flex-1 flex-col space-y-1 md:pr-6">
          <div className="flex flex-row space-x-1">
            <div className="text-md font-bold leading-snug">{planName}</div>
            {badgeText && (
              <Text size="small">
                <Badge state="success">{badgeText}</Badge>
              </Text>
            )}
          </div>
          {planInfo && <div className="text-sm leading-snug">{planInfo}</div>}
        </div>

        <div className="text-end">
          <div className="text-md ml-4 font-bold">{priceFormatted}</div>
          {priceInfo && <div className="ml-2 text-sm">{priceInfo}</div>}
        </div>
      </label>

      <div className="flex">
        {planSelectedInfoIcon && isSelected && (
          <div className="mr-5 mt-4 h-4 w-4">{planSelectedInfoIcon}</div>
        )}
        {planSelectedInfoSlot && isSelected && (
          <div className="mt-4">{planSelectedInfoSlot}</div>
        )}
      </div>
    </StyledPlanCard>
  );
};

const StyledPlanCard = styled.div<{ selected: boolean }>`
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.border};
  box-shadow: ${(props) =>
    props.selected
      ? `inset 0 0 0 2px ${props.theme.colorPalette.blue[600]}`
      : "none"};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 24px;
  }
`;

export type SeekerPlanItem = {
  planId: number;
  planCardItem: {
    planName: string;
    planInfo?: string;
    planSelectedInfoSlot?: React.ReactNode;
    planSelectedInfoIcon?: React.ReactNode;
    badgeText?: string;
    priceFormatted: string;
    priceInfo?: string;
  };
};
