/* eslint-disable comma-dangle */
import { http } from "@boligportal/juice";
import { Env } from "components/interfaces/settings";
import { ElectricityPartner } from "leads/electricity/ElectricityPartner";
import { fetchFromAPI } from "lib/api";
import { ElectricityLeadService } from "../interfaces/ElectricityLeadService";

interface ElectricityLeadBaseData {
  environment: string;
}

interface ElectricityMovingReportLeadData extends ElectricityLeadBaseData {
  moving_report_tenant_id: number;
  tenant_cpr?: string;
  company_cvr?: string;
  provider: ElectricityPartner;
}

export interface LeadAPIResponse {}

class ElectricityLeadAPI {
  static async electricityLeadFromMovingReports(
    payload: ElectricityMovingReportLeadData,
  ): Promise<LeadAPIResponse> {
    try {
      const response = await fetchFromAPI(
        "/api/moving_reports/leads/electricity/send/",
        {
          method: "POST",
          body: JSON.stringify(payload),
        },
      );

      return await response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async deleteElectricityLeadPOA(leadId: number) {
    return http.delete(`/moving_reports/leads/electricity/${leadId}/delete/`);
  }
}

const fromMovingReports =
  (environment: Env): ElectricityLeadService =>
  async (
    leadData: ElectricityMovingReportLeadData,
  ): Promise<LeadAPIResponse> => {
    const response = await ElectricityLeadAPI.electricityLeadFromMovingReports({
      environment: environment === "production" ? "production" : "test",
      moving_report_tenant_id: leadData.moving_report_tenant_id,
      tenant_cpr: leadData.tenant_cpr,
      company_cvr: leadData.company_cvr,
      provider: leadData.provider,
    });
    return response;
  };

const ElectricityLeadServices = {
  fromMovingReports,
  deletePOA: ElectricityLeadAPI.deleteElectricityLeadPOA,
};

export { ElectricityLeadServices };
