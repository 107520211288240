import { styled } from "@boligportal/juice";
import { useAuthenticatedUserPolling } from "features/polling/AuthenticatedUserPollingProvider";

export const InboxCountBadge = () => {
  const { unseenThreadCount } = useAuthenticatedUserPolling();
  if (unseenThreadCount === 0) {
    return null;
  }
  return <BadgeCountWrapper>{unseenThreadCount}</BadgeCountWrapper>;
};

export const BadgeCountWrapper = styled.span`
  pointer-events: none;
  background-color: ${(props) => props.theme.colorPalette.brand[500]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0;
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: 10px;
  z-index: 1;
  transform-origin: 100% 0%;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 0 0 2px white;
`;
