import {
  Box,
  Dialog,
  DialogContent,
  DialogHeader,
  Divider,
  Text,
} from "@boligportal/juice";
import { CombinedDefect } from "apps/moving_reports/hooks/useDefectsFromReport";
import { ReadMoreContainer } from "components/read_more_container";
import { localizedDateTimeLongAndPretty } from "lib/date";
import { t } from "lib/i18n";
import { DefectInfoMain } from "./DefectInfoMain";

export const MAX_LENGTH_DETAILS = 300;

export const DefectInfoTenantRemarks = ({
  defect,
  onClose,
}: {
  defect: CombinedDefect;
  onClose: () => void;
}) => {
  const { landlord_remarks } = defect;

  const renderRemarks = () => {
    if (
      !landlord_remarks?.freehand_status &&
      !landlord_remarks?.status &&
      !landlord_remarks?.comment
    ) {
      return null;
    }

    const modificationTime = landlord_remarks
      ? localizedDateTimeLongAndPretty(
          landlord_remarks.latest_landlord_modification_time,
        )
      : "";

    return (
      <Box
        bg="tint"
        borderRadius="sm"
        mt={4}
        p={4}
        border
      >
        <Text
          weight="bold"
          block
        >
          {t("moving_reports.status.defect_dialog.remarks.title_tenant")}
        </Text>
        <Text
          size="small"
          color="muted"
        >
          {t("moving_reports.status.defect_dialog.remarks.description_tenant")}
        </Text>
        <Divider
          my={4}
          mx={-4}
        />
        <Box>
          {(landlord_remarks?.status || landlord_remarks?.freehand_status) && (
            <Box mb={1}>
              <Text
                color="muted"
                size="small"
                block
              >
                {t("moving_reports.status.defect_dialog.remarks.status")}
              </Text>
              <Text>
                {landlord_remarks?.freehand_status || landlord_remarks?.status}
              </Text>
            </Box>
          )}
          {landlord_remarks?.comment && (
            <Box mb={1}>
              <Text
                color="muted"
                size="small"
                block
              >
                {t("moving_reports.status.defect_dialog.remarks.comment")}
              </Text>
              <Text>
                <ReadMoreContainer
                  content={landlord_remarks?.comment}
                  maxLength={MAX_LENGTH_DETAILS}
                />
              </Text>
            </Box>
          )}

          {modificationTime && (
            <Box mt={0.5}>
              <Text
                as="i"
                color="muted"
                size="tiny"
              >
                {t("moving_reports.status.defect_dialog.modification_time", {
                  modificationTime,
                })}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open
      slideOver
      size="large"
      onClose={onClose}
    >
      <DialogHeader>
        {t("moving_reports.status.defect_dialog.header")}
      </DialogHeader>
      <DialogContent>
        <DefectInfoMain defect={defect} />

        {renderRemarks()}
      </DialogContent>
    </Dialog>
  );
};
