import { useCallback, useEffect, useReducer } from "react";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { calculateTotalPriceFromPlans } from "features/payments_feature/utils/calculateTotalPriceFromPlans";
import { API } from "lib/api";

interface QuickpayPaymentModalState {
  paymentUrl: string | null;
  orderId: string | null;
  cardAccepted: boolean;
  paymentMethodId: number | null;
  isWaitingForIFrameToLoad: boolean;
}

declare global {
  interface Window {
    cardAccepted: (paymentMethodId: number) => void;
  }
}

export const useQuickpay = (plans: Plan[], adId: number | null) => {
  const [state, setState] = useReducer<
    React.Reducer<QuickpayPaymentModalState, Partial<QuickpayPaymentModalState>>
  >(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      paymentUrl: null,
      orderId: null,
      cardAccepted: false,
      paymentMethodId: null,
      isWaitingForIFrameToLoad: true,
    },
  );

  const handleIFrameLoad = () => {
    setState({
      isWaitingForIFrameToLoad: false,
    });
  };

  const handlePaymentFailedRetryButtonClick = () => {
    setState({
      paymentUrl: null,
      cardAccepted: false,
      paymentMethodId: null,
    });
    getPaymentFormUrl();
  };

  const getPaymentFormUrl = useCallback(() => {
    const amount = calculateTotalPriceFromPlans(plans);
    API.getPaymentLink(App.settings.language, amount, plans, adId).then(
      (link) => {
        setState({
          paymentUrl: link.url,
          orderId: link.order_id,
        });
      },
    );
  }, [adId, plans]);

  useEffect(() => {
    // quickpayTracker.trackInitiateCheckout(adId);
    window.cardAccepted = (paymentMethodId: number) => {
      setState({
        paymentMethodId,
        cardAccepted: true,
      });
    };
    getPaymentFormUrl();
  }, [adId, getPaymentFormUrl]);

  return {
    ...state,
    handleIFrameLoad,
    handlePaymentFailedRetryButtonClick,
  };
};
