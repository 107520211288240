import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const linkStyles = (props) => css`
  font-size: ${props.theme.fontSize.tiny};
  color: ${props.theme.color.text.base};
  text-decoration: underline;
  &:hover {
    color: ${props.theme.color.text.base};
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  ${linkStyles};
`;

const StyledAnchor = styled.a`
  ${linkStyles}
`;

export { StyledLink, StyledAnchor };
