import * as MapboxGl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MAP_STYLE_URL = "/static/mapTileConfig.json";

const pinLayoutSettings = (): MapboxGl.SymbolLayout => ({
  "icon-allow-overlap": true,
  "icon-size": 1 / (window.devicePixelRatio || 1),
  "icon-offset": [0, 12 * (window.devicePixelRatio || 1)],
  "icon-anchor": "bottom",
  "text-ignore-placement": true,
  "text-allow-overlap": true,
  "text-justify": "center",
  "text-anchor": "bottom",
  "text-size": 12,
  "text-offset": [0, -1.3],
});

const createMap = (
  container: string | HTMLElement,
  style: string,
  zoom: number,
  minZoom: number,
  maxZoom: number,
  center?: MapboxGl.LngLatLike,
  interactive: boolean = true,
  fadeDuration: number = 0,
  debug: boolean = false,
): MapboxGl.Map => {
  const map: MapboxGl.Map = new MapboxGl.Map({
    container,
    style,
    center,
    zoom,
    minZoom,
    maxZoom,
    fadeDuration,
    interactive,
    attributionControl: false,
  });

  map.dragRotate.disable();
  map.touchZoomRotate.disable();
  map.touchZoomRotate.disableRotation();

  map.showCollisionBoxes = debug;
  map.showTileBoundaries = debug;

  return map;
};

const createSinglePinLayer = (
  id: string,
  source: string,
  selected: boolean,
): MapboxGl.Layer => {
  const layer: MapboxGl.Layer = {
    id,
    source,
    type: "symbol",
    filter: selected ? ["in", "ad_id", ""] : ["all", ["!has", "point_count"]],
    layout: {
      ...pinLayoutSettings(),
      "icon-image": `pin_1_${selected ? "selected" : "normal"}`,
      "text-field": "{price_formatted}",
    },
  };
  return layer;
};

const createClusterLayer = (
  id: string,
  source: string,
  maxZoom: number,
): MapboxGl.Layer => ({
  id,
  source,
  type: "circle",
  filter: ["all", ["has", "point_count"], ["<", ["zoom"], maxZoom]],
  paint: {
    "circle-color": "#EEEEEE",
    "circle-opacity": 0.8,
    "circle-stroke-width": 2,
    "circle-stroke-color": "#FFFFFF",
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
});

const createClusterCountLayer = (
  source: string,
  maxZoom: number,
): MapboxGl.Layer => ({
  id: "clusterCount",
  type: "symbol",
  source,
  filter: ["all", ["has", "point_count"], ["<", ["zoom"], maxZoom]],
  layout: {
    "text-allow-overlap": true,
    "text-field": "{point_count_abbreviated}",
    "text-size": {
      property: "point_count",
      type: "interval",
      stops: [
        [0, 14],
        [100, 16],
        [1000, 22],
      ],
    },
  },
});
const createMultiplePinLayer = (
  id: string,
  source: string,
  selected: boolean,
  maxZoom: number,
  label: string,
): MapboxGl.Layer => {
  const layer: MapboxGl.Layer = {
    id,
    source,
    filter: selected
      ? ["in", "cluster_id", ""]
      : ["all", ["has", "point_count"], ["==", ["zoom"], maxZoom]],
    type: "symbol",
    layout: {
      ...pinLayoutSettings(),
      "icon-image": [
        "image",
        [
          "at",
          [
            "case",
            ["<", ["get", "point_count"], 3],
            0,
            [">", ["get", "point_count"], 2],
            1,
            0,
          ],
          [
            "literal",
            [
              `pin_2_${selected ? "selected" : "normal"}`,
              `pin_3_${selected ? "selected" : "normal"}`,
            ],
          ],
        ],
      ],
      "text-field": `{point_count} ${label}`,
    },
  };
  return layer;
};

// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/37794326
const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

const scheduleMaxZoomOnNextRender = (
  map: MapboxGl.Map,
  zoomDifferenceThreshold: number,
) => {
  setTimeout(() => {
    const currentZoom = map.getZoom();
    const maxZoom = map.getMaxZoom();

    // If the difference between the current zoom and the max zoom is greater than 0.5,
    // then we're will asume that the user zoomed out
    if (Math.abs(currentZoom - maxZoom) > zoomDifferenceThreshold) {
      return;
    }

    map.setZoom(map.getMaxZoom());
  }, 0);
};

export {
  MAP_STYLE_URL,
  createMap,
  createSinglePinLayer,
  createClusterLayer,
  createClusterCountLayer,
  createMultiplePinLayer,
  metersToPixelsAtMaxZoom,
  scheduleMaxZoomOnNextRender,
};
