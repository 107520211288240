import * as React from "react";
import { activityStreamPageLimit, useCompanyActivityStream } from "api/queries";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import {
  getCompanies,
  getCompany,
  updateCompany as _updateCompany,
  createCompany as _createCompany,
  addUsersToCompany as _addUsersToCompany,
  detachUserFromCompany,
  ActivityStreamResponse,
} from "lib/customerServiceApi";
import { UserDetailPageContext } from "../user_detail_page_context";
import { CompanyDraft } from "./components/company_details";
import { CompanyDetailsDTO, createCompanyDetailsDTO } from "./helpers";
import {
  Company,
  CompanyDataProps,
  CompanyFeed,
  CompanyUser,
} from "./interfaces";

interface CompanyDetailsContextAPI {
  isDataReady: boolean;
  company: Company | undefined;
  companyUsers: CompanyUser[];
  companyFeeds: CompanyFeed[];
  companies: Company[];
  pageLimit: number;
  isLoadingActivityStream: boolean;
  totalCompaniesCount: number;
  companyEditableDetails: CompanyDetailsDTO | undefined;
  fetchCompany: (companyId: number, silent?: boolean) => Promise<void>;
  fetchCompanies: (offset: number, limit: number) => Promise<void>;
  // Mutations
  updateCompany: (
    company: number,
    fieldName: string,
    value: any,
  ) => Promise<void>;
  addUsersToCompany: (companyId: number, userIds: number[]) => Promise<void>;
  removeUserFromCompany: (userId: number) => Promise<void>;
  createCompany: (
    draft: CompanyDraft,
  ) => Promise<{ company?: Company; errors?: { [key: string]: string[] } }>;
  activityStreamData?: ActivityStreamResponse;
  activityStreamOffset: number;
  setActivityStreamOffset: (page: number) => void;
  fetchActivityStream: () => void;
}

const pageLimit = 20;
const stubMethod = (): any => {};

export const CompanyDetailsContext =
  React.createContext<CompanyDetailsContextAPI>({
    isDataReady: false,
    company: undefined,
    companyUsers: [],
    companyFeeds: [],
    companies: [],
    pageLimit,
    isLoadingActivityStream: true,
    totalCompaniesCount: 0,
    companyEditableDetails: undefined,
    setActivityStreamOffset: () => {},
    activityStreamOffset: 0,
    fetchCompany: stubMethod,
    fetchCompanies: stubMethod,
    fetchActivityStream: () => {},
    //Mutations
    updateCompany: stubMethod,
    addUsersToCompany: stubMethod,
    removeUserFromCompany: stubMethod,
    createCompany: stubMethod,
  });

// =====
const loadCompanyData = async (
  companyId: number,
): Promise<CompanyDataProps> => {
  const response = await getCompany(companyId);
  return {
    company: response.company,
    companyUsers: response.company_users,
    companyFeeds: response.company_feeds,
  };
};

export const CompanyDetailsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDataReady, setIsDataReady] = React.useState(false);
  const [company, setCompany] = React.useState<Company | undefined>();
  const [companyFeeds, setCompanyFeeds] = React.useState<CompanyFeed[]>([]);
  const [companyUsers, setCompanyUsers] = React.useState<CompanyUser[]>([]);
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [totalCompaniesCount, setTotalCompaniesCount] = React.useState(0);
  const [companyEditableDetails, setCompanyEditableDetails] =
    React.useState<CompanyDetailsDTO>();
  const { userId } = React.useContext(UserDetailPageContext);
  const { addErrorNotification, addResponseErrorNotification } =
    useErrorNotification();
  const [activityStreamOffset, setActivityStreamOffset] = React.useState(0);

  const {
    data: activityStreamData,
    refetch: fetchActivityStream,
    isLoading: isLoadingActivityStream,
  } = useCompanyActivityStream(
    company?.id,
    "desc",
    activityStreamOffset,
    activityStreamOffset + activityStreamPageLimit,
  );

  const fetchCompanies = React.useCallback(
    async (offset: number, limit: number) => {
      const response = await getCompanies(offset, limit);

      setCompanies(response.companies);
      setTotalCompaniesCount(response.count);
    },
    [],
  );

  const fetchCompany = React.useCallback(
    async (companyId?: number, silent: boolean = false) => {
      if (!silent) {
        setIsDataReady(false);
      }

      if (companyId) {
        const companyData = await loadCompanyData(companyId);
        setCompany(companyData.company);
        setCompanyUsers(companyData.companyUsers);
        setCompanyFeeds(companyData.companyFeeds);
        setCompanyEditableDetails(createCompanyDetailsDTO(companyData.company));
      } else {
        setCompany(undefined);
        setCompanyUsers([]);
        setCompanyEditableDetails(undefined);
      }

      if (!silent) {
        setIsDataReady(true);
      }
    },
    [],
  );

  const updateCompany = React.useCallback(
    async (companyId: number, fieldName: string, value: any) => {
      try {
        const response = await _updateCompany(companyId, fieldName, value);

        if (response.errors) {
          addResponseErrorNotification(response.errors);
        }
      } catch (errors) {
        addErrorNotification(
          "An unexpected error occured while saving the company",
        );
      }

      await fetchCompany(companyId, true);
    },
    [fetchCompany],
  );

  const addUsersToCompany = React.useCallback(
    async (companyId: number, userIds: number[]) => {
      const response = await _addUsersToCompany(companyId, {
        user_ids: userIds,
      });
      if ("error" in response) {
        addErrorNotification(response.error.message);
      } else if (response.users_updated) {
        await fetchCompany(companyId, true);
      }
    },
    [fetchCompany],
  );

  const removeUserFromCompany = React.useCallback(
    async (id: number) => {
      const response = await detachUserFromCompany(id);
      if ("error" in response) {
        addErrorNotification(response.error.message);
      } else if (response.user_updated && company) {
        if (id === userId) {
          // User got removed from company
          fetchCompany();
        } else {
          fetchCompany(company.id);
        }
      }
    },
    [company, fetchCompany, userId],
  );

  const createCompany = React.useCallback(
    async (draft: CompanyDraft) => {
      const finalDraft = {
        ...draft,
      };

      const request = _createCompany(finalDraft);

      request
        .then((response) => {
          if (response.errors) {
            addResponseErrorNotification(response.errors);
          }

          if (response.company?.id) {
            fetchCompany(response.company.id, true);
            fetchCompanies(0, pageLimit);
          }
        })
        .catch(() => {
          addErrorNotification(
            "An unexpected error occured while saving the company",
          );
        });

      return request;
    },
    [fetchCompany, userId],
  );

  return (
    <CompanyDetailsContext.Provider
      value={{
        isDataReady,
        companyEditableDetails,
        company,
        companyUsers,
        companyFeeds,
        companies,
        pageLimit,
        fetchCompany,
        fetchCompanies,
        totalCompaniesCount,
        createCompany,
        updateCompany,
        addUsersToCompany,
        removeUserFromCompany,
        setActivityStreamOffset,
        activityStreamOffset,
        activityStreamData,
        isLoadingActivityStream,
        fetchActivityStream,
      }}
    >
      {children}
    </CompanyDetailsContext.Provider>
  );
};
