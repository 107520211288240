import * as React from "react";
import { Box } from "@boligportal/juice";
import { threadColorLabel } from "apps/inbox/thread_color_helper";
import { occupationLabel, userTypeLabel } from "components/interfaces/profile";
import { ThreadFilter } from "components/interfaces/thread_filter";
import { t } from "lib/i18n";
import { FilterBadge } from "./filterBadge";

interface Props {
  filter: ThreadFilter;
  updateFilter: React.Dispatch<React.SetStateAction<ThreadFilter>>;
}

const createAgeFilterDisplayLabel = (
  minAge: number | null,
  maxAge: number | null,
) => {
  if (!minAge && !maxAge) {
    return null;
  }

  const ageParts: string[] = [];
  if (minAge) {
    ageParts.push(`min. ${minAge}`);
  }

  if (maxAge) {
    ageParts.push(`max. ${maxAge}`);
  }

  const ageFilter = ageParts.join(` ${t("profileFilter.and")} `);

  return ageFilter + t("profileFilter.years");
};

export const FilterPreview = ({ filter, updateFilter }: Props) => {
  const ageFilterLabel = createAgeFilterDisplayLabel(
    filter.profile.min_age,
    filter.profile.max_age,
  );

  return (
    <Box mt={1}>
      {filter.color.map((element) => (
        <FilterBadge
          key={element}
          text={t(threadColorLabel[element])}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              color: filter.color.filter((color) => color !== element),
            }))
          }
        />
      ))}

      {filter.profile.user_type.map((element) => (
        <FilterBadge
          key={element}
          text={t(userTypeLabel[element])}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                user_type: filter.profile.user_type.filter(
                  (userType) => userType !== element,
                ),
              },
            }))
          }
        />
      ))}

      {ageFilterLabel && (
        <FilterBadge
          text={ageFilterLabel}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                min_age: null,
                max_age: null,
              },
            }))
          }
        />
      )}

      {filter.profile.occupation.map((element) => (
        <FilterBadge
          key={element}
          text={t(occupationLabel[element])}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                occupation: filter.profile.occupation.filter(
                  (occupation) => occupation !== element,
                ),
              },
            }))
          }
        />
      ))}

      {filter.profile.pets.map((element) => (
        <FilterBadge
          key={String(element)}
          text={element ? t("inbox.has_pets") : t("inbox.has_no_pets")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                pets: filter.profile.pets.filter((pet) => pet !== element),
              },
            }))
          }
        />
      ))}

      {filter.profile.smoking.map((element) => (
        <FilterBadge
          key={String(element)}
          text={element ? t("inbox.smoker") : t("inbox.non_smoker")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                smoking: filter.profile.smoking.filter(
                  (smoking) => smoking !== element,
                ),
              },
            }))
          }
        />
      ))}

      {filter.profile.share_apartment.map((element) => (
        <FilterBadge
          key={String(element)}
          text={element ? t("inbox.will_share") : t("inbox.will_not_share")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                share_apartment: filter.profile.share_apartment.filter(
                  (shareAppartment) => shareAppartment !== element,
                ),
              },
            }))
          }
        />
      ))}

      {filter.profile.kids.map((element) => (
        <FilterBadge
          key={String(element)}
          text={element ? t("inbox.has_kids") : t("inbox.has_no_kids")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                kids: filter.profile.kids.filter((kid) => kid !== element),
              },
            }))
          }
        />
      ))}

      {filter.profile.expat.map((element) => (
        <FilterBadge
          key={String(element)}
          text={element ? t("inbox.expat") : t("inbox.not_expat")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              profile: {
                ...filter.profile,
                expat: filter.profile.expat.filter(
                  (expat) => expat !== element,
                ),
              },
            }))
          }
        />
      ))}

      {filter.visibility.excludeReadMessages && (
        <FilterBadge
          text={t("inbox.filter_unread_messages")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              visibility: {
                ...filter.visibility,
                excludeReadMessages: false,
              },
            }))
          }
        />
      )}

      {filter.visibility.includeArchivedMessages && (
        <FilterBadge
          text={t("inbox.filter_archived_messages")}
          clear={() =>
            updateFilter((filter) => ({
              ...filter,
              visibility: {
                ...filter.visibility,
                includeArchivedMessages: false,
              },
            }))
          }
        />
      )}
    </Box>
  );
};
