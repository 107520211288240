import * as React from "react";
import { Flex, IconCheckmark, Text, styled } from "@boligportal/juice";

const Wrapper = styled(Flex)`
  position: relative;
`;

const Line = styled.div<{
  $height: number;
  $width: number;
  verticalOffset: number;
  horizontalOffset: number;
}>`
  position: absolute;
  background: ${(props) => props.theme.colorPalette.green[500]};
  position: absolute;
  ${(props) => `
    width: ${props.$width}px;
    height: ${props.$height}px;
    left: ${props.horizontalOffset}px;
    top: ${props.verticalOffset}px;
  `}
`;

const HeroBulletIcon = styled(IconCheckmark)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colorPalette.green[500]};
  border-radius: 50%;
  color: #fff;
  padding: 4px;
`;

export const HeroBullets = ({ bulletTexts }: { bulletTexts: string[] }) => {
  const firstRef = React.useRef<HTMLSpanElement>(null);
  const lastRef = React.useRef<HTMLSpanElement>(null);
  const { width, height, xOffset, yOffset } = useGetOffsetBetweenRefs(
    firstRef,
    lastRef,
    2,
  );

  return (
    <Wrapper column>
      <Line
        $width={width}
        $height={height}
        verticalOffset={yOffset}
        horizontalOffset={xOffset}
      />
      {bulletTexts.map((text, index, self) => (
        <HeroBullet
          key={text}
          ref={
            index === 0
              ? firstRef
              : index === self.length - 1
                ? lastRef
                : undefined
          }
          text={text}
        />
      ))}
    </Wrapper>
  );
};

const HeroBullet = React.forwardRef<HTMLSpanElement, { text: string }>(
  ({ text }, ref) => (
    <Flex
      gap
      align="center"
      py={1}
      pb={2}
    >
      <span ref={ref}>
        <HeroBulletIcon size="large" />
      </span>
      <Text
        color="inverted"
        ml={1}
      >
        {text}
      </Text>
    </Flex>
  ),
);

const useGetOffsetBetweenRefs = (
  ref1: React.RefObject<HTMLSpanElement>,
  ref2: React.RefObject<HTMLSpanElement>,
  lineWidth: number,
) => {
  const [state, setState] = React.useState({
    width: lineWidth,
    height: 0,
    xOffset: 0,
    yOffset: 0,
  });

  React.useEffect(() => {
    const first = getCenterPosition(ref1);
    const last = getCenterPosition(ref2);
    const verticalDistanceBetweenCenters = first.center - last.center;

    setState({
      width: lineWidth,
      height: verticalDistanceBetweenCenters,
      xOffset: first.xOffset - Math.floor(lineWidth / 2),
      yOffset: first.centerPosWithinParent,
    });
  }, [ref1, ref2, lineWidth]);

  return state;
};

const getCenterPosition = (ref: React.RefObject<HTMLElement>) => {
  if (!ref.current) {
    return {
      xOffset: 0,
      yOffset: 0,
      center: 0,
      centerPosWithinParent: 0,
    };
  }

  const { height, width, y } = ref.current.getBoundingClientRect();
  const parentHeight =
    ref.current.parentElement?.getBoundingClientRect().height || 0;

  return {
    xOffset: Math.max(width / 2),
    yOffset: Math.max(height / 2),
    center: Math.max(height / 2) - y,
    centerPosWithinParent: parentHeight / 2,
  };
};
