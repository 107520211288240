import { Tracking } from "lib/tracking/common";

export const trackUserDeletedVideo = (
  from: "rentable detail" | "create ad",
) => {
  Tracking.trackEvent({
    category: from,
    label: "delete video",
    action: "video",
    isInteraction: true,
  });
};
