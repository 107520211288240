import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  getContractTenancyDiff,
  getExportableYearsOptions,
  getFailedPaymentNotificationsCount,
  getMerchantPlaces,
  getMovingReportTenancyDiff,
  getNextPaymentDay,
  getPaymentDetails,
  getRentableTenancies,
  getRentOverviewData,
  getRentRecords,
  GetRentRecordsQueryParams,
  getRentRegulationDueDateOptions,
  getStopRentDueDateOptions,
  getTenancy,
  getTenancyContracts,
  getTenancyMovingReport,
  getTenancyRent,
  getTenansiesList,
  PaginatedTenancyRent,
} from "api";
import { TenanciesListPayload } from "api/types";
import { MovingReportListItem } from "apps/moving_reports/store/slices/movingReports";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { useUser } from "components/Providers/UserProvider";
import { TenancyAPI } from "features/rentable_tenancy/TenancyAPI";
import {
  TenancyBaseDiff,
  TenancyBaseDiffResponse,
  TenancyDiff,
  TenancyDiffResponse,
  TenancyTenantDiff,
  TenancyTenantDiffResponse,
} from "features/tenancy/useTenancyDiffUpdater/types";
import { formatDateLocalePretty } from "lib/date";

type GetTenancyMovingReportsResponse = {
  address: string;
  movingReports: MovingReportListItem[];
};

type GetRentableRentOverviewQueryParams = {
  num_months: number;
  sorting: {
    column: string;
    descending: boolean;
  };
  offset: number;
  limit: number;
};

export interface PaymentStat {
  amount: number;
  num_payments: number;
}

export const tenancyKeys = {
  rentableTenanciesPage: (
    rentableId: number,
    sorting: {
      column: string;
      descending: boolean;
    },
  ) => ["rentableTenancies", rentableId, sorting] as const,
  tenancy: (id: number) => ["tenancy", id] as const,
  tenancyList: (payload: TenanciesListPayload) =>
    ["tenancyList", payload] as const,
  tenancyMovingReports: (id: number) =>
    ["tenancyMovingReportsQuery", id] as const,
  tenancyContractsQuery: (id: number) => ["tenancyContractsQuery", id] as const,
  rentOverviewData: () => ["rentOverviewData"] as const,
  rentOverviewDetails: () => ["rentOverviewDetails"] as const,
  rentOverviewDetailsParams: (params: GetRentRecordsQueryParams) =>
    [...tenancyKeys.rentOverviewDetails(), params] as const,
  rentableRentOverview: () => ["rentableRentOverview"] as const,
  rentableRentOverviewParams: (
    id: number,
    params: GetRentableRentOverviewQueryParams,
  ) => [...tenancyKeys.rentableRentOverview(), id, params] as const,
  tenancyRent: () => ["getTenancyRentQuery"] as const,
  tenancyRentParams: (tenancyId: number, params: PaginatedTenancyRent) =>
    [...tenancyKeys.tenancyRent(), tenancyId, params] as const,
  rentRegulationDueDateOptions: (tenancyId: number) =>
    ["rentRegulationDueDateOptions", tenancyId] as const,
  stopRentDueDateOptions: (tenancyId: number) =>
    ["stopRentDueDateOptions", tenancyId] as const,
  rentableTenancies: (rentableId: number) =>
    ["rentableTenancies", rentableId] as const,
  paymentRecords: () => ["paymentRecords"] as const,
  paymentRecordDetails: (paymentRecordId: number) =>
    [...tenancyKeys.paymentRecords(), paymentRecordId] as const,
  failedPaymentNotificationsCount: () =>
    ["failedPaymentNotificationsCount"] as const,
  merchantPlaces: () => ["merchantPlaces"] as const,
  contractTenancyDiff: (contractId: number) =>
    ["contractTenancyDiff", contractId] as const,
  movingReportTenancyDiff: (movingReportId: number) =>
    ["movingReportTenancyDiff", movingReportId] as const,
};

export const useRentableTenanciesPage = (
  rentableId: number,
  sorting: {
    column: string;
    descending: boolean;
  },
) =>
  useQuery(tenancyKeys.rentableTenanciesPage(rentableId, sorting), () =>
    TenancyAPI.getRentableTenanciesPage(rentableId, sorting),
  );

export const useTenancyQuery = (tenancyId: number) =>
  useQuery(tenancyKeys.tenancy(tenancyId), () => getTenancy(tenancyId));

export const useTenanciesList = (payload: TenanciesListPayload = {}) =>
  useQuery(tenancyKeys.tenancyList(payload), () => getTenansiesList(payload));

export const useTenancyMovingReports = (tenancyId: number) =>
  useQuery<GetTenancyMovingReportsResponse>(
    tenancyKeys.tenancyMovingReports(tenancyId),
    () => getTenancyMovingReport(tenancyId),
    {
      keepPreviousData: true,
    },
  );

export const useTenancyContracts = (tenancyId: number) =>
  useQuery(
    tenancyKeys.tenancyContractsQuery(tenancyId),
    () => getTenancyContracts(tenancyId),
    {
      keepPreviousData: true,
    },
  );

export const useGetRentableTenancies = (rentableId: number) =>
  useQuery(
    tenancyKeys.rentableTenancies(rentableId),
    () => getRentableTenancies(rentableId),
    {
      keepPreviousData: true,
    },
  );

export const useGetTenancyRentQuery = (
  tenancyId: number,
  pagination: PaginatedTenancyRent,
) =>
  useQuery(tenancyKeys.tenancyRentParams(tenancyId, pagination), () =>
    getTenancyRent(tenancyId, pagination),
  );

export const useGetTenancyRentPaymentTypeQuery = (
  tenancyId: number,
  pagination: PaginatedTenancyRent,
) =>
  useQuery(
    tenancyKeys.tenancyRentParams(tenancyId, pagination),
    () => getTenancyRent(tenancyId, pagination),
    {
      select: (data) => ({
        paymentType: data.payment_type,
      }),
    },
  );

export const useGetPaymentRecordDetails = (paymentRecordId: number) => {
  const getPaymentRecordDetailsQuery = useQuery(
    tenancyKeys.paymentRecordDetails(paymentRecordId),
    async () => {
      const response = await getPaymentDetails(paymentRecordId);

      return Promise.resolve({
        eventLog: response.event_log,
        note: response.note,
        oneTimePayments: response.one_time_payments || [],
        paymentDueDate: response.payment_due_date,
        paymentInfo: response.paymentInfo,
        paymentRecordId: response.payment_id,
        paymentStatus: response.payment_state,
        paymentType: response.payment_type,
        recurringRent: response.base_payments || [],
        rentRegulation: response.rent_regulation || [],
        tenancyId: response.tenancy.tenancy_id,
        paymentSubscriptionProviderId:
          response.tenancy.payment_subscription_provider_id,
        transactions: response.transactions,
        isPaymentEditable: response.is_within_edit_deadline,
      });
    },
    {
      staleTime: 100,
    },
  );

  return {
    getPaymentRecordDetailsQuery,
  };
};

const usePersistSearchParamsInUrl = (params: Record<string, unknown>) => {
  const history = useHistory();

  const persist = () => {
    // eslint-disable-next-line compat/compat
    const urlSearchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      urlSearchParams.append(key, `${value}`);
    });

    history.replace(`?${urlSearchParams.toString()}`);
  };

  return persist;
};

export const useGetRentOverviewData = () =>
  useQuery(tenancyKeys.rentOverviewData(), () => getRentOverviewData());

export const useGetRentRecords = (params: GetRentRecordsQueryParams) => {
  const { category, ...searchParams } = params;
  const persist = usePersistSearchParamsInUrl(searchParams);

  return useQuery(
    tenancyKeys.rentOverviewDetailsParams(params),
    () => getRentRecords(params),
    {
      keepPreviousData: true,
      enabled: params.category !== null,
      onSuccess: persist,
    },
  );
};

export const useFirstAvailableAutomaticPaymentDateQuery = (
  selectedDate: string,
  selectedPaymentProvider: TenancyPaymentType,
) =>
  useQuery([selectedDate, selectedPaymentProvider], () =>
    getNextPaymentDay(selectedDate, selectedPaymentProvider),
  );

export const useMerchantPlacesQuery = (hasMobilePayConnection?: boolean) => {
  const merchantPlacesQuery = useQuery(
    tenancyKeys.merchantPlaces(),
    () => getMerchantPlaces(),
    {
      enabled: hasMobilePayConnection,
    },
  );

  return merchantPlacesQuery;
};

export const useGetRentRegulationDueDateOptions = (tenancyId: number) =>
  useQuery(
    tenancyKeys.rentRegulationDueDateOptions(tenancyId),
    () => getRentRegulationDueDateOptions(tenancyId),
    {
      select: (data) =>
        data.due_dates.map((item) => ({
          label: formatDateLocalePretty(new Date(item.date)),
          value: item.date,
          paymentId: item.payment_id,
          disabled: !item.available,
        })),
    },
  );

export const useGetStopRentDueDateOptions = (tenancyId: number) =>
  useQuery(
    tenancyKeys.stopRentDueDateOptions(tenancyId),
    () => getStopRentDueDateOptions(tenancyId),
    {
      select: (data) => ({
        canUseTodaysDate: data.can_use_todays_date,
        dueDates: data.due_dates.map((item) => ({
          label: formatDateLocalePretty(new Date(item.date)),
          value: item.date,
          paymentId: item.payment_id,
        })),
      }),
    },
  );

export const useGetExportableYearsOptions = () =>
  useQuery(["exportableYearsQuery"], () => getExportableYearsOptions(), {
    select: (data) =>
      data.years.map((year) => ({
        id: year,
        year: year,
        link: `/api/tenancy/payments/export_to_excel/${year}/`,
      })),
  });

export const useRentCollectionNotificationCountQuery = () => {
  const { user } = useUser();
  const userId = user?.id!;
  const currentStorage = localStorage.getItem(
    "rent_collection_notification_count",
  );

  const shouldBeEnabled = user?.payment_connections.MOBILEPAY;

  useQuery(
    tenancyKeys.failedPaymentNotificationsCount(),
    async () => {
      const res = await getFailedPaymentNotificationsCount();
      localStorage.setItem(
        "rent_collection_notification_count",
        JSON.stringify({
          ...JSON.parse(currentStorage!),
          ...{
            [userId]: res.paymentNotificationsCount,
          },
        }),
      );
    },
    {
      // Refetch the data every 2 min and only if user have connected mobilepay
      enabled: shouldBeEnabled,
      refetchInterval: 120000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );
  return {
    rentCollectionNotificationCount: JSON.parse(
      localStorage.getItem("rent_collection_notification_count")!,
    ),
    user: user,
  };
};

const makeTenancyBaseDiff = (responseData: TenancyBaseDiffResponse) => {
  const tenancyBaseDiff: TenancyBaseDiff = {};

  Object.entries(responseData).forEach(([key, value]) => {
    if (value !== null) {
      tenancyBaseDiff[key] = value;
    }
  });

  return tenancyBaseDiff;
};

export const makeTenancyTenantDiffFrom = (
  candidate: TenancyTenantDiffResponse,
) => {
  const { id, ...rest } = candidate;

  const changesList = Object.entries(rest).filter(
    ([_, value]) => value !== null,
  );

  let obj: TenancyTenantDiff = {
    id: id ?? null,
  };

  changesList.forEach((item) => {
    obj = {
      ...obj,
      [item[0]]: item[1],
    };
  });

  return obj;
};

const makeDiffFrom = (dataWithNullValues: TenancyDiffResponse): TenancyDiff => {
  const base = dataWithNullValues.tenancy_base_diff
    ? makeTenancyBaseDiff(dataWithNullValues.tenancy_base_diff)
    : null;

  const tenants = dataWithNullValues.tenancy_tenants_diff.map(
    makeTenancyTenantDiffFrom,
  );

  return {
    tenancy_base_diff: base,
    tenancy_tenants_diff: tenants,
  };
};

export const useGetContractTenancyDiff = (
  contractId: number,
  enabled: boolean,
) => {
  const { data: dataWithNullValues } = useQuery(
    tenancyKeys.contractTenancyDiff(contractId),
    () => getContractTenancyDiff(contractId),
    {
      enabled,
    },
  );

  return {
    tenancyDiff: dataWithNullValues ? makeDiffFrom(dataWithNullValues) : null,
  };
};

export const useGetMovingReportTenancyDiff = (
  movingReportId: number,
  enabled: boolean,
) => {
  const { data } = useQuery(
    tenancyKeys.movingReportTenancyDiff(movingReportId),
    () => getMovingReportTenancyDiff(movingReportId),
    {
      enabled,
    },
  );

  return {
    tenancyDiff: data ? makeDiffFrom(data) : null,
  };
};
