import * as React from "react";
import * as Polyglot from "node-polyglot";
import { Consent } from "../components/consent";
import { setTrackingErrorUser } from "../lib/tracking/errors";
import { Providers } from "./Providers";
import { AppProps } from "./interfaces/app_props";
import { Settings } from "./interfaces/settings";
import { User } from "./interfaces/user";

export class App extends React.Component<
  {
    appProps: AppProps;
    component: React.ComponentType;
    isEmbedMode?: boolean;
  },
  {
    appProps: AppProps;
  }
> {
  public static i18n: Polyglot;
  private static _settings: Settings;

  constructor(props: any) {
    super(props);
    App._settings = props.appProps.globals.settings;

    this.state = {
      appProps: props.appProps,
    };
    this.setSentryData(props.appProps.user);
  }

  static get settings(): Settings {
    return App._settings;
  }

  setSentryData = (user: User | null) => {
    if (!user) {
      return;
    }

    setTrackingErrorUser({
      id: user.id.toString(),
      email: user.email,

      // custom meta data below this point
      name: `${user.first_name} ${user.last_name}`,
      is_premium: user.has_premium_seeker_access,
    });
  };

  reloadAppProps = () => {
    // "Refresh" the page and return a Promise<AppProps>

    // Gets rid of the "next" url param to prevent the backend from actually doing the redirect there
    let url = `${window.location.pathname}${window.location.search}`;
    url = url.replace(/((&)*next=([^&]*))/g, "");

    return window
      .fetch(url, {
        headers: {
          accept: "application/json",
        },
        credentials: "same-origin",
      })
      .then((response) => {
        if (response.status === 401 || response.status === 404) {
          // Page now requires authentication or is missing
          // Until we have a better idea, let's just take them back to the front page.
          window.location.href = App.settings.routes.index;
        }
        return response.json();
      })
      .then((newProps: AppProps) => {
        this.setState((state) => ({
          appProps: {
            ...state.appProps,
            page_props: newProps.page_props,
            user: newProps.user,
          },
        }));
        return newProps;
      });
  };

  // Merge the properties from pageProps into the current page_props
  // Useful for updating part of the page data,
  // e.g. if you know that you only need to refresh part of the page data.
  updatePageProps = (pageProps: object, callback?: () => void) => {
    this.setState((state) => {
      Object.assign(state.appProps.page_props, pageProps);
      return state;
    }, callback);
  };

  replaceUser = (user: User) => {
    const { appProps } = this.state;
    this.setState({
      appProps: {
        ...appProps,
        user,
      },
    });
  };

  updateUserProps = (userProps: Partial<User>, callback?: () => void) => {
    this.setState((state) => {
      if (state.appProps.user) {
        state.appProps.user = {
          ...state.appProps.user,
          ...userProps,
        };
      }

      this.setSentryData(state.appProps.user);

      return state;
    }, callback);
  };

  public static SettingsContext: React.Context<Settings> =
    React.createContext<Settings>(App.settings);

  render() {
    const { isEmbedMode, component } = this.props;

    const {
      appProps: {
        page_props,
        user,
        globals: { settings },
      },
    } = this.state;

    return (
      <Providers
        actions={{
          reloadAppProps: this.reloadAppProps,
          updatePageProps: this.updatePageProps,
          updateUserProps: this.updateUserProps,
          replaceUser: this.replaceUser,
        }}
        settings={settings}
        user={user}
      >
        {Boolean(!isEmbedMode && !page_props.is_iframe) && <Consent />}
        {React.createElement(component, page_props)}
      </Providers>
    );
  }
}
