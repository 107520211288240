import { fetchFromAPI } from "lib/api";

export interface OwnerMappingPayload {
  email: string;
  phone: string;
  user_id: number;
}

export interface CrawlingUpdatePayload {
  name: string;
  default_owner_id: number | null;
  owner_mappings: OwnerMappingPayload[];
}

const updateCrawlingService = async (
  crawlingId: number,
  update: CrawlingUpdatePayload,
): Promise<{ success: boolean }> => {
  const url = encodeURI(
    `/customer-service/api/crawlings/${crawlingId}/update/`,
  );
  const response = await fetchFromAPI(url, {
    method: "PUT",
    body: JSON.stringify(update),
  });
  return response.json();
};

export { updateCrawlingService };
