import { ElectricityResponsibility } from "apps/contracts/components/dk/store/enums";
import { ElectricityPartner } from "leads/electricity/ElectricityPartner";

export type MapFunction<T, U extends ElectricityEnumMember> = (
  option: U,
) => T | null;

type ElectricityEnumMember = ElectricityPartner | ElectricityResponsibility;

const isPostalCodeInRange = (
  postalCode: number,
  rangeStart: number,
  rangeEnd: number,
): boolean => postalCode >= rangeStart && postalCode <= rangeEnd;

const getFilteredOptionsByPostalCode = (
  postalCodeNumber: number,
  enumType: typeof ElectricityPartner | typeof ElectricityResponsibility,
): ElectricityEnumMember[] => {
  if (isPostalCodeInRange(postalCodeNumber, 1000, 4999) /* Zeland */) {
    return [enumType.OK, enumType.ANDEL, enumType.AURA];
  } else if (isPostalCodeInRange(postalCodeNumber, 5000, 5999) /* Fyn */) {
    return [enumType.OK, enumType.ENERGI_FYN, enumType.AURA];
  } else if (isPostalCodeInRange(postalCodeNumber, 6000, 9999) /* Jutland */) {
    return [enumType.OK, enumType.AURA, enumType.ANDEL];
  }
  return [];
};

/*
 * Retrieves and processes Electricity supplier options for the given rentable postal code.
 * The function first filters the options based on visibility(showAllOptions) (all or filtered)
 * and then sorts them, ensuring that filtered options are prioritized.
 * It then applies the mapping function to curate the final array of relevant options.
 */

export const getFilteredAndSortedOptions = <T, U extends ElectricityEnumMember>(
  postalCode: string,
  showAllOptions: boolean,
  enumType: typeof ElectricityPartner | typeof ElectricityResponsibility,
  mapFunction: MapFunction<T, U>,
): T[] => {
  const postalCodeNumber = Number(postalCode);
  const filteredOptions = getFilteredOptionsByPostalCode(
    postalCodeNumber,
    enumType,
  );

  return (Object.keys(enumType) as U[])
    .filter((option) => showAllOptions || filteredOptions.includes(option))
    .sort((a, b) => {
      const aIsSorted = filteredOptions.includes(a);
      const bIsSorted = filteredOptions.includes(b);
      if (aIsSorted && !bIsSorted) {
        return -1;
      }
      if (!aIsSorted && bIsSorted) {
        return 1;
      }
      return filteredOptions.indexOf(a) - filteredOptions.indexOf(b);
    })
    .map(mapFunction)
    .filter((option): option is T => option !== null);
};
