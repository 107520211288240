import { QueryClient } from "react-query";
import * as api from "api";
import { PropertyDataResponse } from "api/types";
import { createRentableQueryKeys } from "./keys";

const setPropertyInfoCache = (
  queryClient: QueryClient,
  accessAddressId: string,
  data: PropertyDataResponse,
) => {
  queryClient.setQueryData(
    createRentableQueryKeys.propertyInfo(accessAddressId),
    data,
  );
};

export const getPropertyInfo = async (
  queryClient: QueryClient,
  accessAddressId: string,
) => {
  let data = queryClient.getQueryData<PropertyDataResponse>(
    createRentableQueryKeys.propertyInfo(accessAddressId),
  );

  if (!data) {
    data = await api.getPropertyInfo(accessAddressId);

    if (data.results.length > 1) {
      // If the property info endpoint returns multiple results, it's due to multiple rentables being on that property
      // In case theres multiple rentables, we want to filter out the access address itself from the list.
      data.results = data.results.filter(
        (result) =>
          result.address.address !== result.property_info.access_address,
      );
    }
    setPropertyInfoCache(queryClient, accessAddressId, data);
  }

  return data;
};
