/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable comma-dangle */
import { useEffect } from "react";
import { SubscriptionStatusFilter } from "lib/enums";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { usePagination } from "../../../../hooks/usePagination";
import { searchUsersService } from "../services/search_users_service";
import { UsersListViewModel } from "./use_search_users_with_pagination";

export const useSearchUsersProfileChangesPendingReview = () => {
  const { items, setItems, count, setCount, offset, setOffset, limit } =
    usePagination<UsersListViewModel>(25);

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    const { signal } = abortController;

    searchUsersService(
      {
        query: "",
        ban_reasons: null,
        subscription_status: [
          SubscriptionStatusFilter.ACTIVE,
          SubscriptionStatusFilter.CANCELED,
        ],
        creation_date_from: null,
        creation_date_to: null,
        login_date_from: null,
        login_date_to: null,
        profile_needs_review: true,
      },
      offset,
      limit,
      signal,
    )
      .then((response) => {
        setItems(
          response.results.map((x) => ({
            ...x.user,
            seeker_subscription: x.seeker_subscription,
          })),
        );
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [offset, limit, setItems, setCount, setOffset]);

  return {
    items,
    count,
    limit,
    offset,
    setOffset,
  };
};
