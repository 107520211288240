import { SelectedPaymentItemInterface } from "./interfaces";

export const getAmountSum = (items: SelectedPaymentItemInterface[]): number => {
  let sum: number = 0;
  items.forEach((item) => {
    sum += Number(item.amount);
  });
  return sum;
};

export const getAmountSumStr = (
  items: SelectedPaymentItemInterface[],
): string => {
  const result = getAmountSum(items);
  if (result == 0) {
    return "0";
  }
  if (result > 0 && result < 1) {
    return result.toString();
  }
  return result.toFixed(2);
};

export const findPaymentId = (
  items: SelectedPaymentItemInterface[],
  payment: SelectedPaymentItemInterface,
): boolean => {
  let result = false;

  items.forEach((item) => {
    if (item.payment_id === payment.payment_id) {
      result = true;
    }
  });

  return result;
};

export const disablePaymentItem = (
  items: SelectedPaymentItemInterface[],
  payment_id: number,
): boolean => {
  const item = items.find((p) => p.payment_id === payment_id);
  return item == undefined;
};

// Returns the amount for a given payment input element in the list of payment
export const getPaymentAmount = (
  items: SelectedPaymentItemInterface[],
  payment_id: number,
): number => {
  const item = items.find((p) => p.payment_id === payment_id);

  if (item !== undefined) {
    return Number(item.amount);
  } else {
    return 0;
  }
};
