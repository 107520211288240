import * as React from "react";
import {
  Box,
  Flex,
  IconChevronDown,
  IconChevronUp,
  Text,
  styled,
  focusBorder,
} from "@boligportal/juice";
import { useLocalStorageState } from "hooks/useLocalStorageState";

const MenuCollapseContext = React.createContext<{
  isCollapsed: boolean;
  toggle: () => void;
} | null>(null);

const MenuGroupCollapseContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isCollapsed } = useMenuGroupCollapse();

  return <Box hidden={isCollapsed}>{children}</Box>;
};

const useMenuGroupCollapse = () => {
  const context = React.useContext(MenuCollapseContext);
  if (!context) {
    throw new Error(
      "CollapseToggle or CollapseContent must be used within a <Collapse>",
    );
  }
  return context;
};

const MenuGroupCollapseToggle = ({ title }) => {
  const { toggle, isCollapsed } = useMenuGroupCollapse();
  return (
    <MenuGroupToggleButton onClick={toggle}>
      <Flex
        justify="space-between"
        px={2}
        py={1}
        align="center"
      >
        <MenuItemsGroupText
          size="tiny"
          weight="semiBold"
        >
          {title}
        </MenuItemsGroupText>
        {isCollapsed ? (
          <IconChevronDown color="inverted" />
        ) : (
          <IconChevronUp color="inverted" />
        )}
      </Flex>
    </MenuGroupToggleButton>
  );
};

const MenuGroupCollapse = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorageState(id, true);
  const toggle = () => setIsCollapsed(!isCollapsed);
  return (
    <MenuCollapseContext.Provider
      value={{
        isCollapsed,
        toggle,
      }}
    >
      {children}
    </MenuCollapseContext.Provider>
  );
};

export const MenuItemsGroupText = styled(Text)`
  /* 
    Menu Items have extra width because of the selection 
    border-left selection marker.
    Therefore we add same value here as well in order to
    have this text align perfect with its menu items.
  */
  color: inherit;
  border-left: 4px solid transparent;
  opacity: 0.8;
`;

const MenuGroupToggleButton = styled.button`
  background-color: ${(props) => props.theme.colorPalette.primary[800]};
  color: rgba(255, 255, 255, 0.85);
  border: none;
  padding: 0;
  margin: 0;

  ${focusBorder};

  &:hover {
    background-color: ${(props) => props.theme.colorPalette.primary[700]};
    color: white;
  }
`;

export { MenuGroupCollapse, MenuGroupCollapseContent, MenuGroupCollapseToggle };
