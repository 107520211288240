import { http } from "@boligportal/juice";
import { AdminInfo } from "apps/ad_detail/refactor/components/AdBanner/AdAdminTools";
import { AttachmentUploadConfig } from "apps/contracts/components/common/interfaces";
import { TaskResponseInterface } from "apps/customer_service/pages/ad_detail_page/interfaces";
import { FindTenantTenant } from "apps/find_tenant/interfaces/find_tenant_tenant";
import { ThreadColorEnum } from "apps/inbox/thread_color_helper";
import { Reasons } from "apps/property_owner/my_rentables/views/rentable_detail/views/listings/components/upsell/components/CancelSubscription/CancelSubscriptionConfirmDialog";
import { OptionEnums } from "apps/user_settings/enums";
import {
  OptInResponseInterface,
  UserSettingsAppInterface,
} from "apps/user_settings/interfaces";
import { Rentable } from "business/domain/Rentable";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { Agent } from "components/interfaces/agent";
import { Filters } from "components/interfaces/filters";
import { MapResults } from "components/interfaces/map_results";
import { Pagination } from "components/interfaces/pagination";
import { Plan } from "components/interfaces/plan";
import { Profile, PublicProfile } from "components/interfaces/profile";
import { ProfileFilter } from "components/interfaces/profile_filter";
import { SearchListResultsResponse } from "components/interfaces/search_list_results_response";
import { SignUpPermissions } from "components/interfaces/signup_permissions";
import { Subscription } from "components/interfaces/subscription";
import { Market } from "components/markets/market_settings";
import { Tracking } from "lib/tracking/common";
import { InvoiceEntry } from "../components/interfaces/invoice_entry";
import { Option } from "../components/interfaces/option";
import { getCookie } from "./utils";

export const RequestSource = {
  WEB_FRONTEND: "WEB_FRONTEND",
  MOVING_REPORTS_APP: "MOVING_REPORTS_APP",
};

export function fetchFromAPI(
  url: string,
  init?: RequestInit,
): Promise<Response> {
  const csrfToken = getCookie("csrftoken");
  const csrfTokenHeader = {
    "X-CSRFToken": csrfToken || "",
  };
  const languageHeader = {
    "Accept-Language": App.settings.language,
  };
  const sourceToken = {
    "X-Request-Source": RequestSource.WEB_FRONTEND,
  };

  if (!init) {
    init = {
      headers: {
        ...csrfTokenHeader,
        ...sourceToken,
        ...languageHeader,
      },
    };
  } else {
    init.headers = {
      ...sourceToken,
      ...init.headers,
      ...csrfTokenHeader,
      ...languageHeader,
    };
  }

  return window.fetch(App.settings.i18n_prefix + url, {
    credentials: "same-origin",
    ...init,
  });
}

interface DetailedStatisticsData {
  views: number;
  total_views: number;
  graph: Array<{
    id: string;
    data: Array<{ x: string; y: number }>;
  }>;
}

export const enum LoginResult {
  successful,
  invalid_password,
  unknown_failure,
}

export const enum ListingEvent {
  contact_clicked = 1,
}

interface APIError<T = string> {
  message: string;
  code?: T;
  field?: string;
}

export interface ValidationErrors {
  [field: string]: string[];
}

export interface ThreadViewAd extends Ad {
  new_build_name?: string | null;
}

interface ThreadMessages {
  ad: ThreadViewAd;
  messages: Message[];
  other_user?: ThreadUser;
  color: ThreadColorEnum | null;
  tenant_id: number;
  can_be_contacted: boolean;
  eligible_for_hedvig_lead: boolean;
  is_behind_paywall: boolean;
}

export interface MessageAttachment {
  url: string;
  filename: string;
  mime_type: string;
  size: number;
  preview_urls: {
    small: string;
    large: string;
  };
}

interface BaseMessage {
  id: number;
  body: string;

  // Author is only present if you are the ad owner/.
  // Seekers cannot see landlord names, or their own names for that matter :)
  author?: string;
  sent_date: string;
  thread_id: number;
  is_me: boolean;
  is_read_by_me: boolean;
  read_by: {
    user_id: number;
    is_read: boolean;
  }[];
  type: number;
}

export interface Message extends BaseMessage {
  attachments: MessageAttachment[];
}

export interface LastMessage extends BaseMessage {
  num_attachments: number;
}

export interface ThreadUser {
  id: number;
  first_name: string;
  last_name: string;
  profile_image?: string;
  profile?: PublicProfile;
  agents?: Agent[];
}

export interface ThreadAd extends Ad {
  new_build_name: string | null;
  new_build_url: string | null;
}

export interface Thread {
  ad: ThreadAd;
  last_message: LastMessage;
  is_owner: boolean;
  /**
   * This is only present for seekers as you can only archive one conversation
   * and landlords threads consists of multiple conversations
   *  */
  archived?: boolean;
  thread_group_info?: {
    unseen_thread_count: number;
    other_user_count: number;
    other_user_profile_images: Array<string | null>;
  };
  is_behind_paywall: boolean;
}

export interface StandardMessages {
  messages: StandardMessage[];
}

export interface StandardMessage {
  id: number | string;
  title: string;
  body: string;
}

interface AdThreadsResponse {
  ad: ThreadAd;
  threads: AdThread[];
}

export interface AdThread {
  id: number;
  color: ThreadColorEnum | null;
  other_user: ThreadUser;
  last_message: LastMessage;
  archived?: boolean;
  read: boolean;
}

export interface InTouchTenant {
  msg_cnt: number;
  user_id: number;
  profile_image: string;
  display_name: string;
  latest_message: string;
  sent_date: string;
  is_me: boolean;
}

interface TenantsInTouchWithAdResponse {
  count: number;
  results: InTouchTenant[];
}

interface SendMessageResponse {
  error?: APIError<
    | "ad_does_not_exist"
    | "not_thread_participant"
    | "thread_does_not_exist"
    | "cannot_send_to_yourself"
  >;
  // The following are present in the response if error is undefined.
  // TypeScript cannot seem to represent this accurately.
  thread_ids?: number[];
  message_ids?: number[];
}
export interface CompanyPageData {
  filters: Filters;
  company_page_slug: string;
  agent_id: number | null;
  category_options: Option[];
  listings: Ad[];
  current_url: string;
  offset: number;
  limit: number;
  result_count: number;
}
export interface AdOpenHouse {
  event_date: string;
  start_time: string;
  end_time: string;
}
interface StatisticsDataProps {
  day: string;
  views: number;
}

interface PromotedAdsData {
  ads: Ad[];
  more_promoted_ads: boolean;
}

export interface AccountInfo {
  balance: number;
  allow_negative_balance: boolean;
  enabled: boolean;
}

export class API {
  static async signUp(
    firstName: string,
    lastName: string,
    username: string,
    password: string,
    signupPermissions: SignUpPermissions,
    preferredLanguage: string,
  ): Promise<{ errors?: ValidationErrors }> {
    const data = {
      first_name: firstName,
      last_name: lastName,
      username,
      password,
      language: preferredLanguage,
      ...signupPermissions,
    };
    const response = await fetchFromAPI("/api/signup", {
      method: "POST",
      body: JSON.stringify(data),
    });

    return response.json();
  }

  static login = async (
    username: string,
    password: string,
  ): Promise<LoginResult> => {
    const data = {
      username,
      password,
    };
    const response = await fetchFromAPI("/api/login", {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return LoginResult.successful;
    }

    if (response.status === 403) {
      return LoginResult.invalid_password;
    }

    return LoginResult.unknown_failure;
  };

  static logout = async () => {
    const response = await fetchFromAPI("/api/logout", {
      method: "POST",
    });

    return response.ok;
  };

  static async getAgents(): Promise<Agent[]> {
    const response = await fetchFromAPI("/api/agents", {
      method: "GET",
    });

    return response.json();
  }

  static async createAgent(filters: Filters): Promise<{ agent_id: number }> {
    const datalayer = window.dataLayer;

    const data = {
      filters,
    };

    const response = await fetchFromAPI("/api/agent", {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok && App.settings.market === Market.BOSTADSPORTAL_SE) {
      // Used for tracking Google Ads conversion on Bostadsportal
      datalayer.push({
        event: "agent_created",
      });
    }

    return response.json();
  }

  static extendAgentExpirationDate(agent_id: number) {
    return http.post<Agent>(`agent/${agent_id}/prolong`);
  }

  static async deleteAgent(agent_id: number): Promise<void> {
    const response = await fetchFromAPI(`/api/agent/${agent_id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw Error(`Got non-OK response with status ${response.status}`);
    }
  }

  static async deleteAllAgents(): Promise<TaskResponseInterface> {
    const url = encodeURI("/api/agents/delete");
    try {
      const response = await fetchFromAPI(url, {
        method: "DELETE",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async cancelSubscription(
    reason: string,
    otherReason?: string,
  ): Promise<Subscription> {
    const response = await fetchFromAPI("/api/seeker/subscription/cancel", {
      method: "POST",
      body: JSON.stringify({
        reason,
        other_reason: otherReason,
      }),
    });

    return response.json();
  }

  static async reactivateSubscription(): Promise<Subscription> {
    const response = await fetchFromAPI("/api/seeker/subscription/reactivate", {
      method: "POST",
    });

    return response.json();
  }

  static async activateWinbackSubscription(): Promise<Subscription> {
    const response = await fetchFromAPI(
      "/api/seeker/subscription/accept-winback",
      {
        method: "POST",
      },
    );

    return response.json();
  }

  static async sendResetPasswordMail(email: string): Promise<boolean> {
    const response = await fetchFromAPI("/api/request-password-reset", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });

    return response.ok;
  }

  static async updateEmail(newEmail: string): Promise<{ success: boolean }> {
    const response = await fetchFromAPI("/api/update-email", {
      method: "POST",
      body: JSON.stringify({
        email: newEmail,
      }),
    });

    return response.json();
  }

  static async setPreferredLanguage(
    language: string,
  ): Promise<{ success: boolean }> {
    const response = await fetchFromAPI("/api/preferred-language/", {
      method: "POST",
      body: JSON.stringify({
        preferred_language: language,
      }),
    });

    return response.json();
  }

  static async setReservedToRentedOutFrequency(
    numWeeks: number,
  ): Promise<{ success: boolean }> {
    const response = await fetchFromAPI(
      "/api/reserved-to-rented-out-frequency/",
      {
        method: "POST",
        body: JSON.stringify({
          weeks_reserved_to_rented_out: numWeeks,
        }),
      },
    );

    return response.json();
  }

  static async createOrUpdateProfile(profile: Profile): Promise<{
    profile: Profile;
    errors?: { [key in keyof Profile]: string[] };
  }> {
    const response = await fetchFromAPI("/api/create-or-update-profile", {
      method: "POST",
      body: JSON.stringify(profile),
    });

    return response.json();
  }

  /* Get the private profile for the logged in user */
  static async getProfile(): Promise<{ profile: Profile }> {
    const response = await fetchFromAPI("/api/get-profile");

    return response.json();
  }

  static async resetPassword(
    userId: number,
    token: string,
    password: string,
  ): Promise<{ success: boolean; message: string }> {
    const response = await fetchFromAPI("/api/reset-password", {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        token: token,
        password: password,
      }),
    });

    return response.json();
  }

  static async activateAd(adId: number): Promise<Record<string, never>> {
    const response = await fetchFromAPI(`/api/listing/${adId}/activate`, {
      method: "POST",
    });
    return response.json();
  }

  static async reserveAd(adId: number): Promise<Record<string, never>> {
    const response = await fetchFromAPI(`/api/listing/${adId}/reserve`, {
      method: "POST",
    });
    return response.json();
  }

  static async markAdRentedOut(
    adId: number,
    tenantId?: number,
    rejectionMessage?: string,
  ): Promise<Record<string, never>> {
    const response = await fetchFromAPI(`/api/listing/${adId}/markrentedout`, {
      method: "POST",
      body: JSON.stringify({
        tenant_id: tenantId,
        ...(rejectionMessage?.length && {
          rejection_message: rejectionMessage,
        }),
      }),
    });
    return response.json();
  }

  static async acceptTerms(): Promise<boolean> {
    const response = await fetchFromAPI("/api/accept-new-terms", {
      method: "POST",
    });

    return response.ok;
  }

  static async ensureAdExists(
    adId: number,
  ): Promise<{ status: "ok" | "error"; url: string | null }> {
    const response = await fetchFromAPI(`/api/listing/${adId}/ensure-exists`);

    return response.json();
  }

  static trackListingEvent(listing: Ad, event: ListingEvent) {
    fetchFromAPI(
      `/api/analytics/?listing=${encodeURIComponent(
        listing.id.toString(),
      )}&event=${event}`,
      {
        method: "POST",
      },
    );
  }
  static async stopImportingAndSetAdOffline(
    ad: Ad,
  ): Promise<{ success: boolean }> {
    const response = await fetchFromAPI(
      `/api/admin/listing/${ad.id}/stop-importing-and-set-offline`,
      {
        method: "POST",
      },
    );

    return response.json();
  }

  static async getDetailAppAdminToolInfo(ad: Ad): Promise<AdminInfo> {
    const response = await fetchFromAPI(`/api/admin/listing/${ad.id}/tools`);
    return response.json();
  }

  static uploadProfileImage(file: File) {
    return fetchFromAPI("/api/user/profile/image", {
      method: "POST",
      body: file,
    });
  }

  static deleteProfileImage() {
    return fetchFromAPI("/api/user/profile/image/delete", {
      method: "DELETE",
    });
  }

  static async getPaymentLink(
    language: string,
    amount: number,
    plans: Plan[],
    adId: number | null = null,
  ): Promise<{ url: string; order_id: string }> {
    const [plan_id] = plans.map((plan) => plan.plan_id);
    const payment_endpoint = plans[0].is_subscription
      ? "payment_link"
      : "one_time_payment_link";

    const response = await fetchFromAPI(`/api/payments/${payment_endpoint}`, {
      method: "POST",
      body: JSON.stringify({
        language,
        amount,
        plan_id,
        ad_id: adId,
      }),
    });

    return response.json();
  }

  static async startSubscriptions(
    paymentMethodId: number,
    plans: Plan[],
    adId: number | null,
  ): Promise<
    [
      {
        plan: number;
        subscription_created: boolean;
        invoice_paid: boolean;
        pending_payment: boolean;
        error_message:
          | "subscription_already_active"
          | ""
          | "plan_is_not_available_for_purchase"
          | "subscription_to_this_product_already_exists"
          | "only_1_intro_pr_user";
      },
    ]
  > {
    const planIds = plans.map((plan) => plan.plan_id);

    const response = await fetchFromAPI(
      "/api/subscription-products/start-subscriptions",
      {
        method: "POST",
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          plan_ids: planIds,
          google_analytics_client_id: Tracking.getGoogleAnalyticsClientId(),
          ad_id: adId,
        }),
      },
    );

    return response.json();
  }

  static async cancelSubscriptions({
    subscription_ids,
    cancellation_reason,
  }: {
    subscription_ids: number[];
    cancellation_reason: Reasons;
  }): Promise<[Record<string, unknown>]> {
    const response = await fetchFromAPI(
      "/api/subscription-products/cancel-subscriptions",
      {
        method: "POST",
        body: JSON.stringify({
          subscription_ids,
          cancellation_reason,
        }),
      },
    );

    return response.json();
  }

  static async undoCancelSubscriptions(
    subscription_ids: number[],
  ): Promise<[Record<string, unknown>]> {
    const response = await fetchFromAPI(
      "/api/subscription-products/undo-cancel-subscriptions",
      {
        method: "POST",
        body: JSON.stringify({
          subscription_ids: subscription_ids,
        }),
      },
    );

    return response.json();
  }

  static async changeThreadsReadState(
    threadIds: number[],
    isRead: boolean,
  ): Promise<{ updated_count: number }> {
    const response = await fetchFromAPI("/api/messaging/threads/read", {
      method: "PUT",
      body: JSON.stringify({
        thread_ids: threadIds,
        read: isRead,
      }),
    });

    return response.json();
  }

  static async archiveThreads(
    threadIds: number[],
    archive: boolean,
  ): Promise<{ updated_count: number }> {
    const response = await fetchFromAPI("/api/messaging/threads/archive", {
      method: "PUT",
      body: JSON.stringify({
        thread_ids: threadIds,
        archived: archive,
      }),
    });

    return response.json();
  }

  static fetchAllMessagesInThread(threadId: number, markRead: boolean) {
    return http.get<ThreadMessages>(
      `/messaging/threads/${threadId}?mark_read=${markRead ? 1 : 0}`,
    );
  }

  static async fetchAdThreads(
    adId: number,
    filters?: { includeArchived: boolean; excludeReadMessages: boolean },
  ): Promise<AdThreadsResponse> {
    const response = await fetchFromAPI(
      `/api/messaging/ads/${adId}/threads?show_archived=${
        filters?.includeArchived ? 1 : 0
      }&exclude_read=${filters?.excludeReadMessages ? 1 : 0}`,
    );

    return response.json();
  }

  static async sendMessageToAd(
    adId: number,
    body: string,
  ): Promise<SendMessageResponse> {
    const response = await fetchFromAPI("/api/messaging/send", {
      method: "POST",
      body: JSON.stringify({
        ad_id: adId,
        body: body,
      }),
    });

    return response.json();
  }

  static async sendMessageToUser(
    adId: number,
    publicProfileId: string,
    body: string,
    attachmentURLs: string[] = [],
  ): Promise<SendMessageResponse> {
    const response = await fetchFromAPI("/api/messaging/send", {
      method: "POST",
      body: JSON.stringify({
        ad_id: adId,
        public_profile_id: publicProfileId,
        body: body,
        attachments: attachmentURLs,
      }),
    });

    return response.json();
  }

  static async sendMessageToThread(
    threadId: number,
    body: string,
    attachmentURLs: string[] = [],
  ): Promise<SendMessageResponse> {
    const response = await fetchFromAPI("/api/messaging/send", {
      method: "POST",
      body: JSON.stringify({
        thread_ids: [threadId],
        body: body,
        attachments: attachmentURLs,
      }),
    });

    return response.json();
  }

  static async getTenantsInTouch(
    adId: number,
  ): Promise<TenantsInTouchWithAdResponse> {
    const response = await fetchFromAPI(
      `/api/get-tenants-in-touch-with-ad/${adId}`,
      {
        method: "POST",
      },
    );

    return response.json();
  }

  static async getStandardMessages(): Promise<StandardMessages> {
    const response = await fetchFromAPI("/api/messaging/standard-messages/");

    return response.json();
  }

  static async createStandardMessage(
    title: string,
    body: string,
  ): Promise<StandardMessage> {
    const response = await fetchFromAPI("/api/messaging/standard-message/", {
      method: "POST",
      body: JSON.stringify({
        title,
        body,
      }),
    });

    return response.json();
  }

  static async updateStandardMessage(
    id: string | number,
    title: string,
    body: string,
  ): Promise<StandardMessage> {
    const response = await fetchFromAPI(
      `/api/messaging/standard-message/${id}/`,
      {
        method: "POST",
        body: JSON.stringify({
          title,
          body,
        }),
      },
    );

    return response.json();
  }

  static async deleteStandardMessage(id: string | number): Promise<void> {
    const response = await fetchFromAPI(
      `/api/messaging/standard-message/${id}/`,
      {
        method: "DELETE",
      },
    );

    return response.json();
  }

  static async getMessageAttachmentUploadConfig(): Promise<AttachmentUploadConfig> {
    const response = await fetchFromAPI(
      "/api/messaging/get-attachment-upload-config/",
      {
        method: "POST",
      },
    );

    return response.json();
  }

  static async getMatchingTenantsForAd(
    id: number,
    signal: AbortSignal,
    queryString?: string,
  ): Promise<{ pagination: Pagination; tenants: FindTenantTenant[] }> {
    const response = await fetchFromAPI(
      `/api/get-matching-tenants-for-ad/${id}${
        queryString && queryString !== "" ? `${queryString}` : ""
      }`,
      {
        signal: signal,
      },
    );

    return response.json();
  }

  static async getMatchingTenantsForAdNew(data: {
    adId: number;
    signal: AbortSignal;
    filters: ProfileFilter;
    sortBy: "score" | "last_activity_date";
    descending?: boolean;
    offset?: number;
    limit?: number;
  }): Promise<{ count: number; results: FindTenantTenant[] }> {
    const { adId, offset, limit, filters, sortBy, descending, signal } = data;
    const response = await fetchFromAPI(
      `/api/get-matching-tenants-for-ad/${adId}`,
      {
        signal: signal,
        method: "POST",
        body: JSON.stringify({
          filters,
          offset,
          limit,
          sort_by: sortBy,
          descending,
        }),
      },
    );

    return response.json();
  }

  static async getListResults(
    filters: Filters,
    offset: number,
  ): Promise<SearchListResultsResponse> {
    const response = await fetchFromAPI(`/api/search/list?offset=${offset}`, {
      method: "POST",
      body: JSON.stringify(filters),
    });

    return response.json();
  }

  static async getCompanyPageResults(
    filters: Filters,
    company_page_slug: string,
    offset: number,
  ): Promise<CompanyPageData> {
    const response = await fetchFromAPI(
      `/api/search/company-page?offset=${offset}&company_page_slug=${company_page_slug}`,
      {
        method: "POST",
        body: JSON.stringify(filters),
      },
    );

    return response.json();
  }

  static async getMapResults(
    filters: Filters,
    bbox: {
      min_lat: number;
      max_lng: number;
      max_lat: number;
      min_lng: number;
    } | null,
    signal: AbortSignal,
  ): Promise<{
    results: MapResults;
    current_url: string;
    agent_id: number | null;
  }> {
    if (bbox) {
      filters.city_level_1 = null;
      filters.city_level_2 = null;
      filters.city_level_3 = null;
    }

    const response = await fetchFromAPI("/api/search/map", {
      method: "POST",
      body: JSON.stringify({
        filter: filters,
        bbox: bbox,
      }),
      signal,
    });

    return response.json();
  }

  static async getAdsForIds(adIds: number[]): Promise<Ad[]> {
    const request = fetchFromAPI("/api/listings", {
      method: "POST",
      body: JSON.stringify(adIds),
    });
    try {
      const response = await request;
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static appendCancelClickedActivity() {
    fetchFromAPI("/api/activity-stream/cancel-clicked", {
      method: "POST",
    });
  }

  static async digestOptIn(
    action: OptionEnums,
  ): Promise<OptInResponseInterface> {
    const url = encodeURI(`/api/digest/?action=${action}`);
    try {
      const response = await fetchFromAPI(url, {
        method: "POST",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async newsletterOptIn(
    action: OptionEnums,
  ): Promise<OptInResponseInterface> {
    const url = encodeURI(`/api/newsletter/?action=${action}`);
    try {
      const response = await fetchFromAPI(url, {
        method: "POST",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async relevantContentOptIn(
    action: OptionEnums,
  ): Promise<OptInResponseInterface> {
    const url = encodeURI(`/api/relevant-content/?action=${action}`);
    try {
      const response = await fetchFromAPI(url, {
        method: "POST",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async statisticsEmailOptIn(
    action: OptionEnums,
  ): Promise<OptInResponseInterface> {
    const url = encodeURI(`/api/statistics-emails/?action=${action}`);
    try {
      const response = await fetchFromAPI(url, {
        method: "POST",
      });
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateProductSettings(
    payload: Partial<UserSettingsAppInterface["product_settings"]>,
  ): Promise<boolean> {
    try {
      const response = await fetchFromAPI("/api/product-settings/", {
        body: JSON.stringify(payload),
        method: "PATCH",
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async fetchRentable(
    rentableId: number,
  ): Promise<{ data: { ad: Ad | null; rentable: Rentable } }> {
    const [rentableDetails, adDetails] = await Promise.all([
      fetchFromAPI(`/api/rentable/${rentableId}/`).then((response) =>
        response.json(),
      ),
      fetchFromAPI(`/api/rentable/${rentableId}/ad`).then((response) =>
        response.json(),
      ),
    ]);
    return {
      data: {
        ad: adDetails,
        rentable: rentableDetails,
      },
    };
  }

  static async getLightStatistics(
    rentableId: number,
  ): Promise<{ graph: Array<StatisticsDataProps>; views: number }> {
    const response = await fetchFromAPI(
      `/api/rentable/${rentableId}/stats/light/`,
    );

    return response.json();
  }

  static async getDetailedStatistics(
    rentableId: number,
    startInterval?: string,
    endInterval?: string,
  ): Promise<DetailedStatisticsData> {
    const response = await fetchFromAPI(
      `/api/rentable/${rentableId}/stats/detailed/${
        startInterval
          ? `?interval-end=${endInterval}&interval-start=${startInterval}`
          : ""
      }`,
    );

    return response.json();
  }

  static async getRentableReceipts(
    rentableId: number,
    product_name?: string,
  ): Promise<{ receipts: InvoiceEntry[] }> {
    const response = await fetchFromAPI(
      `/api/rentable/${rentableId}/receipts/${
        product_name ? "?product_name=" + product_name : ""
      }`,
    );

    return response.json();
  }

  static async setAdOpenHouse(
    adId: number,
    input: {
      event_date: string;
      start_time: string;
      end_time: string;
    },
  ): Promise<{ open_house: AdOpenHouse }> {
    const response = await fetchFromAPI(`/api/listing/${adId}/open-house`, {
      method: "PUT",
      body: JSON.stringify(input),
    });

    return response.json();
  }

  static async deleteAdOpenHouse(adId: number): Promise<{ success: boolean }> {
    const response = await fetchFromAPI(`/api/listing/${adId}/open-house`, {
      method: "DELETE",
    });

    return response.json();
  }

  static async getAdOpenHouse(
    adId: number,
  ): Promise<{ open_house: AdOpenHouse | null }> {
    const response = await fetchFromAPI(`/api/listing/${adId}/open-house`);

    return response.json();
  }

  static async changePassword(
    old_password: string,
    new_password: string,
  ): Promise<{ success: boolean; message: string | null }> {
    const data = {
      old_password,
      new_password,
    };

    const response = await fetchFromAPI("/api/change-password", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  static async getPromotedAds(filters: Filters): Promise<PromotedAdsData> {
    const response = await fetchFromAPI("/api/search/promoted_ads", {
      method: "POST",
      body: JSON.stringify(filters),
    });

    if (!response.ok) {
      const message = await response.text();
      throw new Error(message);
    }

    return response.json();
  }

  static async getAccountInfo(): Promise<AccountInfo | null> {
    const response = await fetchFromAPI("/api/account/info/");
    const data = await response.json();
    return data.account_info;
  }

  static async createAccountPaymentMethod(): Promise<{
    payment_method_id: number;
  }> {
    const response = await fetchFromAPI("/api/account/create-payment-method/", {
      method: "POST",
    });
    return response.json();
  }

  static async getPostalTown(
    postalCode: string,
  ): Promise<{ postal_town: string | null }> {
    const response = await fetchFromAPI(`/api/postal-code/${postalCode}`);

    return response.json();
  }

  static getPurchasablePlan(planId: number) {
    return http.get(`payments/get-purchasable-plan/${planId}`);
  }

  static async prolongAdAutoReserve(adId: number) {
    const response = await fetchFromAPI(`/api/listing/${adId}/prolong-ad`, {
      method: "POST",
    });
    return response.json();
  }

  static async getAuthUser() {
    const response = await fetchFromAPI("/api/user/info", {
      method: "GET",
    });
    return response.json();
  }

  static async getSeekerPlans(): Promise<Plan[]> {
    const response = await fetchFromAPI("/api/seeker/plans", {
      method: "GET",
    });
    return response.json();
  }

  static getSimilarAds(adId?: number, count = 3) {
    if (adId === undefined) {
      return Promise.resolve({
        similar_ads: [],
        similar_ads_link: "",
      });
    }

    return http.post<{ similar_ads: Ad[]; similar_ads_link: string }>(
      "similar-ads",
      {
        ad_id: adId,
        count,
      },
    );
  }
}

export async function deleteAccount(
  name: string,
  email: string,
  text: string,
): Promise<unknown> {
  const request = fetchFromAPI("/api/delete-account-mail", {
    method: "POST",
    body: JSON.stringify({
      name,
      email,
      text,
    }),
  });

  return request.then((response) => response.json());
}
