import * as React from "react";
import {
  Badge,
  Box,
  Button,
  CheckField,
  Container,
  DataTable,
  Dialog,
  Flex,
  Text,
  TextField,
} from "@boligportal/juice";
import { format } from "date-fns";
import { useCrawlingsPageFeature } from "features/cs/crawlings_feature/hooks/use_crawlings_page_feature";
import {
  activateCrawling,
  CrawlersListItem,
  pauseCrawling,
  makeAdsNative,
} from "lib/customerServiceApi";
import { PAGE_CRAWLINGS_ADS, PAGE_USERS } from "../..";
import { AdStates, CrawlingFeedStatus } from "../../../../lib/enums";
import { StyledLink } from "../../components/topbar/global_search/components/styled_link";
import { ListPaginator } from "../ads_page/list_paginator";
import { EditCrawlingDialog } from "./crawling_edit_dialog";

const CrawlingNameCell = (props: { source: string; name: string }) => (
  <>
    <Text
      block
      color="base"
      size="tiny"
      weight="bold"
    >
      {props.name}
    </Text>
    <Text
      block
      size="tiny"
    >{`${props.source}`}</Text>
  </>
);

const CrawlingMappingCell = (item: CrawlersListItem) => (
  <>
    <Text
      block
      color={item.user.id === null ? "danger" : "base"}
      size="tiny"
      weight="bold"
    >
      {item.user.fullname}{" "}
      {item.user.id !== null ? `#${item.user.id}` : "USER NOT ASSIGNED"}
    </Text>
    <Button
      text
      href={`${PAGE_USERS}/${item.user.id}`}
    >
      {item.user.username}
    </Button>
    {item.owner_mappings.length > 0 && (
      <Text>+ {item.owner_mappings.length} mappings</Text>
    )}
  </>
);

const AdsNumCell = (props: { id: number; ads: number }) => (
  <StyledLink to={`${PAGE_CRAWLINGS_ADS}/?crawlingSourceId=${props.id}`}>
    <Text
      block
      size="tiny"
    >{`${props.ads}`}</Text>
  </StyledLink>
);
// TODO we need create some spinner to show that list updating
const CrawlingsPage = () => {
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const query = searchParams.get("q") ?? undefined;
  const {
    items,
    search,
    filterChange,
    sortingChange,
    searchValue,
    refetch,
    count,
    limit,
    offset,
    setOffset,
    active,
    sorting,
  } = useCrawlingsPageFeature({
    initialSearchValue: query,
  });

  const [editModalCrawlingId, setEditModalCrawlingId] = React.useState<
    number | null
  >(null);

  const handleEdit = (item: CrawlersListItem) => {
    setEditModalCrawlingId(item.id);
  };

  const handleSkip = (item: CrawlersListItem) => {
    window.confirm(
      `Are you sure you wish to skip updates of this feed and put offline ${item.ads} ads?`,
    ) &&
      pauseCrawling(item.id).then((res) => {
        refetch();
      });
  };

  const handleConvertToNative = (item: CrawlersListItem) => {
    window.confirm(
      `Are you sure you wish to convert ALL feed ads (${item.ads})  to be native ads?`,
    ) &&
      makeAdsNative(item.id).then((res) => {
        refetch();
      });
  };

  const handleActivate = (item: CrawlersListItem) => {
    activateCrawling(item.id).then((res) => {
      refetch();
    });
  };

  const onEditCrawlingClose = () => setEditModalCrawlingId(null);

  const onCrawlingSaved = () => {
    //
    refetch();
  };

  const userSearchInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    search(value);
  };

  const handleActiveFilterChange = () => {
    filterChange(!active);
  };

  const handleSortedClick = (fieldName, descending: boolean) => {
    const sorting = {
      column: fieldName,
      descending: descending,
    };
    sortingChange(sorting);
  };

  return (
    <>
      <Flex
        column
        width="100%"
      >
        <Flex
          borderBottom
          bg="tint"
        >
          <Container fluid>
            <Flex
              mt={2}
              gap
              align="center"
            >
              <Box width="300px">
                <TextField
                  label="Feed filter"
                  value={searchValue}
                  id="query"
                  onChange={userSearchInputChangeHandler}
                />
              </Box>
              <CheckField
                inline
                id={"active"}
                label="Active"
                checked={active}
                onChange={handleActiveFilterChange}
              />
            </Flex>
          </Container>
        </Flex>
        <Box scrollable>
          <Flex column>
            <DataTable<CrawlersListItem>
              fullscreen
              items={items}
              keyField="id"
              activeSortableColumn={sorting}
              onSortClick={handleSortedClick}
              rowActions={[
                {
                  label: "Edit",
                  callback: handleEdit,
                },
                {
                  label: "Skip",
                  callback: handleSkip,
                },
                {
                  label: "Convert to native ads",
                  callback: handleConvertToNative,
                },
                {
                  label: "Activate",
                  callback: handleActivate,
                },
              ]}
              columns={[
                {
                  fieldLabel: "Id",
                  fieldName: "id",
                  width: "150px",
                  sortable: true,
                },
                {
                  fieldLabel: "Name",
                  fieldName: "name",
                  cellRenderer: (item) => <CrawlingNameCell {...item} />,
                  sortable: true,
                },
                {
                  fieldLabel: "Status",
                  fieldName: "status",
                  cellRenderer: (item) => (
                    <Badge
                      color={
                        item.status === CrawlingFeedStatus.ACTIVE
                          ? "yellow"
                          : "blue"
                      }
                    >
                      {item.status === CrawlingFeedStatus.ACTIVE
                        ? "Active"
                        : "Paused"}
                    </Badge>
                  ),
                },
                {
                  fieldLabel: "Owner/mapping",
                  fieldName: "default_owner_id",
                  cellRenderer: (item) => <CrawlingMappingCell {...item} />,
                },
                {
                  fieldLabel: "Ads",
                  fieldName: "ads",
                  cellRenderer: (item) => <AdsNumCell {...item} />,
                },
                {
                  fieldLabel: "Created",
                  fieldName: "created_date",
                  width: "150px",
                  cellRenderer: (item) => (
                    <Text size="tiny">
                      {format(
                        new Date(item.created_date),
                        "yyyy-MM-dd HH:mm:ss",
                      )}
                    </Text>
                  ),
                  sortable: true,
                },
              ]}
            />
            {count > items.length && (
              <Flex
                column
                pt={4}
                pb={6}
                align="center"
                bg="tint"
              >
                <ListPaginator
                  offset={offset}
                  totalCount={count}
                  limit={limit}
                  itemCount={items.length}
                  onNextPage={() => setOffset(offset + limit)}
                  onPrevPage={() => setOffset(offset - limit)}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
      <Dialog
        size="xlarge"
        slideOver
        open={editModalCrawlingId !== null}
        onClose={onEditCrawlingClose}
      >
        {editModalCrawlingId !== null && (
          <EditCrawlingDialog
            crawlingId={editModalCrawlingId}
            onClose={onEditCrawlingClose}
            onSaved={onCrawlingSaved}
          />
        )}
      </Dialog>
    </>
  );
};

export { CrawlingsPage };
