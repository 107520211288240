import * as React from "react";
import { Box } from "@boligportal/juice";
import { CreateAgentButton } from "components/CreateAgent/create_agent_button";
import { Filters } from "components/interfaces/filters";
import { TrackingEvent } from "lib/tracking/events";
import {
  Action_changeView,
  Action_toggleShowMobileFilters,
  Action_updateAgentId,
} from "../../search_result_reducer";
import { MapButton } from "./map_button";
import { ViewToggle } from "./view_toggle";

interface Props {
  filters: Filters;
  dispatch: React.Dispatch<
    Action_changeView | Action_updateAgentId | Action_toggleShowMobileFilters
  >;
  agentId: number | null;
  colorStyle?: "brand" | "neutral";
}

export const CreateAgentAndGotoMap = ({
  dispatch,
  filters,
  agentId,
  colorStyle = "brand",
}: Props) => (
  <Box>
    <Box mb={2}>
      <CreateAgentButton
        dispatch={dispatch}
        filters={filters}
        agentId={agentId}
        colorStyle={colorStyle}
      />
      <Box mt={2}>
        <ViewToggle dispatch={dispatch} />
      </Box>
    </Box>
    <Box
      hidden={{
        xs: true,
        lg: false,
      }}
    >
      <MapButton
        onClick={() => {
          TrackingEvent.clickViewMapResults();
          dispatch({
            type: "changeView",
            payload: {
              view: "map",
            },
          });
        }}
      />
    </Box>
  </Box>
);
