import {
  Dialog,
  DialogContent,
  DialogHeader,
  Text,
  Spinner,
  Flex,
} from "@boligportal/juice";
import { useEmailEventsQuery } from "api/queries";
import { StyledAnchor } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { EventsTimeline } from "./EventsTimeline";
import { EmailLogEntry } from "./types";
import { getEmailRenderLink } from "./utils";

type Props = {
  show: boolean;
  emailLog?: EmailLogEntry;
  onClose: () => void;
};

export const EmailEventsDialog = ({ show, onClose, emailLog }: Props) => {
  const { isFetching, isError, data } = useEmailEventsQuery(emailLog?.id);

  const renderContent = () => {
    if (isFetching || !data?.events) {
      return (
        <Flex
          height="300px"
          justify="center"
          align="center"
        >
          <Spinner size="large" />
        </Flex>
      );
    }

    if (isError || !emailLog) {
      return <Text>Something went wrong :(</Text>;
    }

    if (data.events.length === 0) {
      return (
        <Text>
          No events found for mail:
          <Text size="tiny">
            <pre>{JSON.stringify(emailLog, null, 2)}</pre>
          </Text>
        </Text>
      );
    }

    return (
      <>
        <StyledAnchor
          href={`/customer-service/mail-user-redirect?email=${emailLog.email}`}
        >
          {emailLog.email_address}
        </StyledAnchor>
        <Text
          block
          size="tiny"
          mb={3}
        >
          <a
            href={getEmailRenderLink(
              emailLog.email,
              emailLog.tags,
              emailLog.id,
            )}
            target="_blank"
            rel="noreferrer"
          >
            {emailLog.email_topic}
          </a>
        </Text>

        <EventsTimeline events={data?.events} />
      </>
    );
  };

  return (
    <Dialog
      slideOver
      open={show}
      onClose={onClose}
    >
      <DialogHeader>All events</DialogHeader>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
