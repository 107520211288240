import * as React from "react";
import { Box, Flex, Hidden, styled, Text } from "@boligportal/juice";
import { AnimationReveal } from "components/animation/AnimationReveal";
import { MovingReportsLandingPageContainer } from "../MovingReportsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const HeroHeader = styled.h2`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 30px;
  }
`;

const MarketingSubHeader = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: #ffffff;
`;

// AGENT ===============================================================================================================

const MailIcon = () => (
  <svg
    version="1.1"
    width="80"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <path
      fill="#FFF"
      d="M171.1,72.6h121.6c25.5,0,46.3,20.8,46.3,46.3v260.5c0,6.4-5.2,11.6-11.6,11.6H136.3c-6.4,0-11.6-5.2-11.6-11.6
	V118.9C124.7,93.5,145.6,72.6,171.1,72.6z M182.6,165.3c19.1,0,34.7-15.6,34.7-34.7s-15.6-34.7-34.7-34.7s-34.7,15.6-34.7,34.7
	S163.5,165.3,182.6,165.3z M182.6,49.5c0,6.4-5.2,11.6-11.6,11.6s-11.6-5.2-11.6-11.6V20.5c0-6.4,5.2-11.6,11.6-11.6
	s11.6,5.2,11.6,11.6V49.5z M101.6,107.4c6.4,0,11.6,5.2,11.6,11.6s-5.2,11.6-11.6,11.6H72.6c-6.4,0-11.6-5.2-11.6-11.6
	s5.2-11.6,11.6-11.6H101.6z M129.9,61.6c4.6,4.6,4.6,11.6,0,16.2c-4.6,4.6-11.6,4.6-16.2,0L87.7,51.8c-4.6-4.6-4.6-11.6,0-16.2
	s11.6-4.6,16.2,0L129.9,61.6z M182.6,142.1c-6.4,0-11.6-5.2-11.6-11.6c0-6.4,5.2-11.6,11.6-11.6c6.4,0,11.6,5.2,11.6,11.6
	C194.2,136.9,189,142.1,182.6,142.1z"
    />
  </svg>
);

const AgentArrow = () => (
  <svg
    width="100%"
    viewBox="0 0 364 72"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M12.6970887,72.9858189 C78.243308,37.0370626 140.558585,14.4387225 199.64292,5.19079889 C258.727255,-4.05712477 314.644817,-1.52851357 367.395605,12.7766325"
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
        transform="translate(190.046347, 36.364967) rotate(19.000000) translate(-190.046347, -36.364967) "
      />
      <polyline
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        transform="translate(5.697089, 14.744114) rotate(-13.000000) translate(-5.697089, -14.744114) "
        points="9.69708869 6.74411446 1.69708869 14.7441145 9.69708869 22.7441145"
      />
    </g>
  </svg>
);

const TranslateAgentWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 150px;
    padding-right: 15px;
    transform: translate(-100%, -28px);

    .RentOutPage__TranslateWrapper__Content {
      width: 200px;

      transform: translate(-100px, -27px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 250px;
    padding-right: 30px;
    transform: translate(-100%, -68px);

    .RentOutPage__TranslateWrapper__Content {
      width: 300px;

      transform: translate(-110px, -45px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 364px;
    padding-right: 30px;
    transform: translate(-100%, -68px);

    .RentOutPage__TranslateWrapper__Content {
      transform: translate(-100px, -65px);
    }
  }
`;

// FACEBOOK ===============================================================================================================
const FacebookLogo = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="100"
    viewBox="0 0 400 400"
  >
    <g id="Layer_1_1_">
      <path
        className="st0"
        fill="#FFF"
        display="none"
        d="M210.6,200.8c-18.9-0.4-37.3,6.7-56.2,5.9c-1.1,0-4.1-0.7-5.5-1.5c1.5-2.2,3.3-4.1,5.9-6.7
		c12.9-12.6,29.6-21.1,44.4-31.1c10.7-7,18.1-23.7,2.2-29.9c-7.4-3-15.9-1.1-23.7,0c-10.7,1.5-21.1,4.4-31.4,7.4
		c-14.4,4.8-29.2,11.1-42.5,18.5c-9.2,5.2-22.9,13.7-20.3,26.2c1.1,6.3,10,5.5,11.8,0c1.1-3,7.8-5.5,10-7c5.2-3,10.7-5.2,16.3-7.8
		c14.8-6.7,29.9-11.8,45.8-15.5c4.8-1.1,9.6-2.2,14.4-3c-4.8,3.3-10,6.7-14.8,10.4c-11.1,8.1-37,23.7-32.2,40.7
		c2.6,10,16.6,10,24.4,9.2c7.8-0.7,15.9-2.6,23.7-4.1c10-1.8,20.3-2.6,29.6-7C213.9,204.1,212.8,200.8,210.6,200.8z"
      />
      <path
        fill="#FFF"
        d="M222.8,199c0-6.7,5.5-12.2,12.2-12.2h0.7c6.7,0,12.2,5.5,12.2,12.2l0.4,44.7c0-6.7,5.5-12.2,12.2-12.2h0.7
		c6.7,0,12.2,5.5,12.2,12.2v7.8c0-6.7,5.5-12.2,12.2-12.2h0.7c6.7,0,12.2,5.5,12.2,12.2v12.2c0-6.7,5.5-12.2,12.2-12.2h0.7
		c6.7,0,12.2,5.5,12.2,12.2v41.8c0,22.9-22.2,52.9-55.8,52.9s-44.7-28.1-53.2-37.7c-8.5-9.6-38.4-31.8-38.4-39.9s6.3-10,11.8-10
		c14.8,0,34.4,19.2,34.4,19.2L222.8,199z"
      />
      <path
        fill="#FFF"
        d="M377.3,228.9v-99.5c0-13.5-10.4-24.3-22.9-24.3h-19.6h-4.4h-16.6h-18.1h-12.6h-17.7h-12.2h-15.5H42.4
		c-12.6-0.4-22.9,10.8-22.9,24.3v128c0,13.5,10.4,24.3,22.9,24.3h123.7c1.1,0,1.9-0.9,1.9-1.9v-5.8c0-1.1-0.9-1.9-1.9-1.9H68.3
		V114.4H330v144.2v13.5v6.9c0,1.5,1.2,2.7,2.7,2.7h21.7c11.8,0,21.8-10,22.5-22.4 M42.8,210.8c-8.9,0-16.3-7.7-16.3-17.4
		s7.4-17.4,16.3-17.4S59,183.8,59,193.5C59,203.1,51.6,210.8,42.8,210.8z"
      />
      <g>
        <path
          className="st2"
          stroke="#FFF"
          strokeWidth="4px"
          strokeMiterlimit="10"
          d="M121.1,228.2c-1.3,0.2-4.4-1.8-7-5.2c-5.9-8.5-4.4-18.1,4.4-26.2c5.5-4.8,11.5-8.5,17.4-11.1
			c0.4-2.6,0.4-4.8,0.4-7.4c0-0.7,0-1.8,0-3.3c0.4-8.1,0.7-30.3-7-31.4c-4.4-0.4-13.7,10.7-17,16.6c-11.1,17.7-15.9,32.2-14,42.1
			c0.4,1.1-0.4,1.8-1.5,2.2c-1.1,0-1.8-0.4-2.2-1.5c-1.8-11.1,3-26.2,14.4-44.7c0,0,12.2-19.6,20.7-18.5c11.1,1.5,10.7,24,10.4,35.1
			c0,1.1,0,2.2,0,3c0,1.8,0,4.1-0.4,5.9c2.6-0.7,4.4-1.1,5.9,0c1.8,1.1,2.2,3.7,2.6,7c0,1.5,0,3.3,0,5.2s-0.4,5.2,0,5.9
			c0,0,0.7-0.4,3-1.8c0.4-0.4,1.1-1.1,1.5-1.5c2.6-2.6,5.5-5.2,9.2-5.5l0,0c5.9,0,6.7,3.3,7,5.9c0.4,1.5,0.7,2.6,1.5,3.7
			c3,2.6,5.9,0.7,11.8-4.4c4.1-3.7,8.1-7.4,12.9-6.3c2.6,0.4,4.4,3,6.3,5.2c1.1,1.5,2.6,3,3.7,3.7c2.6,0.7,6.3,0.7,9.2,0
			c1.1-0.4,2.2,0.4,2.2,1.1c0.4,1.1-0.4,2.2-1.1,2.2c-3.7,1.1-8.1,1.1-11.8,0c-2.2-1.1-3.7-3-5.2-4.8c-1.5-1.8-3-3.3-4.1-3.7
			c-2.6-0.7-6.3,2.6-9.6,5.5c-4.8,4.4-10.7,10-16.6,4.4c-1.8-1.8-2.2-4.1-2.6-5.5c-0.4-2.2-0.7-3-3.3-3c-2.2,0.4-4.8,2.6-7,4.8
			c-0.7,0.7-1.1,1.1-1.8,1.8c-2.2,1.8-4.8,3.3-7.4,2.2c-2.6-1.5-2.2-5.2-2.2-9.2c0-1.5,0.4-3.3,0-4.8c-0.4-3.3-0.7-4.1-0.7-4.1
			c-0.4-0.4-1.1,0-4.4,1.1c-1.1,8.5-4.1,17-8.9,27c-0.4,0.7-0.7,1.8-1.1,3C126.3,223.7,120.6,229.5,121.1,228.2
			C122.3,227.1,121.1,228.2,121.1,228.2z M135.6,189.7c-4.8,2.2-10,5.5-14.4,9.6c-4.4,4.1-10.7,11.8-4.1,21.1
			c2.2,3.3,3.7,3.7,4.1,3.7c1.8,0,3.7-4.8,5.2-8.1c0.4-1.1,0.7-1.8,1.1-3C131.9,204.9,134.4,197.1,135.6,189.7z"
        />
      </g>
    </g>
    <g id="Layer_2_1_"></g>
  </svg>
);

const FacebookArrow = () => (
  <svg
    width="100%"
    height="53px"
    viewBox="0 0 166 53"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M2,6.77777778 C34.5378599,0.407407407 63.8901232,0.407407407 90.0567899,6.77777778 C116.223457,13.1481481 139.204527,25.8888889 159,45"
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
      />
      <polyline
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        transform="translate(160.000000, 48.000000) rotate(-124.000000) translate(-160.000000, -48.000000) "
        points="164 40 156 48 164 56"
      />
    </g>
  </svg>
);

const TranslateFacebookWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100px;
    padding-left: 15px;
    transform: translate(256px, 10px);

    .RentOutPage__TranslateWrapper__Content {
      width: 160px;

      transform: translate(50px, 0px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 140px;
    padding-left: 30px;
    transform: translate(256px, 10px);

    .RentOutPage__TranslateWrapper__Content {
      width: 240px;

      transform: translate(80px, 20px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 166px;
    padding-left: 30px;
    transform: translate(256px, 15px);

    .RentOutPage__TranslateWrapper__Content {
      width: 280px;

      transform: translate(180px, -20px);
    }
  }
`;

// Google ===============================================================================================================

const GoogleArrow = () => (
  <svg
    width="100%"
    height="107px"
    viewBox="0 0 210 107"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M11,95 C56.53668,88.373195 95.7216866,77.3097925 128.55502,61.8097925 C161.388353,46.3097925 187.870013,26.373195 208,2"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
        transform="translate(109.500000, 48.500000) scale(-1, -1) translate(-109.500000, -48.500000) "
      />
      <polyline
        stroke="#FFFFFF"
        strokeWidth="2"
        transform="translate(6.941467, 102.000000) rotate(-62.000000) translate(-6.941467, -102.000000) "
        points="10.941467 94 2.94146699 102 10.941467 110"
      />
    </g>
  </svg>
);

const GoogleAdsLogo = () => (
  <svg
    version="1.1"
    width="65"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M187,183.5h177.6c22.8,0,22.8,33,0,33H187c-8.9,0-16.5-7.6-16.5-16.5C170.5,189.9,178.1,183.5,187,183.5z
      M187,56.7h177.6c22.8,0,22.8,33,0,33H187C164.2,89.7,164.2,56.7,187,56.7z M187,310.3h177.6c22.8,0,22.8,33,0,33H187
     C164.2,343.3,164.2,310.3,187,310.3z"
      />
    </g>
    <g id="Artwork_15_1_">
      <g>
        <defs>
          <rect
            id="SVGID_1_"
            x="18.3"
            y="23.4"
            width="96.4"
            height="96.4"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use
            xlinkHref="#SVGID_1_"
            overflow="visible"
          />
        </clipPath>
        <g clipPath="url(#SVGID_2_)">
          <defs>
            <rect
              id="SVGID_3_"
              x="18.3"
              y="23.4"
              width="96.4"
              height="96.4"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use
              xlinkHref="#SVGID_3_"
              overflow="visible"
            />
          </clipPath>
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFF"
            d="M66.5,23.4c-26.6,0-48.2,21.6-48.2,48.2c0,26.6,21.6,48.2,48.2,48.2c26.6,0,48.2-21.6,48.2-48.2
         C114.8,45,93.2,23.4,66.5,23.4 M94.7,56.4C94.6,56.4,94.6,56.5,94.7,56.4L60.5,90.6c-0.5,0.5-1.2,0.8-1.9,0.8
         c-0.7,0-1.4-0.3-1.9-0.8L38.4,72.2c-1-1-1-2.7,0.1-3.7c1-1,2.6-1,3.6,0L58.6,85l32.3-32.3c1-1,2.7-1.1,3.7-0.1
         C95.6,53.7,95.7,55.4,94.7,56.4"
          />
        </g>
      </g>
    </g>
    <g id="Artwork_15_2_">
      <g>
        <defs>
          <rect
            id="SVGID_5_"
            x="18.3"
            y="151.8"
            width="96.4"
            height="96.4"
          />
        </defs>
        <clipPath id="SVGID_6_">
          <use
            xlinkHref="#SVGID_5_"
            overflow="visible"
          />
        </clipPath>
        <g clipPath="url(#SVGID_6_)">
          <defs>
            <rect
              id="SVGID_7_"
              x="18.3"
              y="151.8"
              width="96.4"
              height="96.4"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use
              xlinkHref="#SVGID_7_"
              overflow="visible"
            />
          </clipPath>
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFF"
            d="M66.5,151.8c-26.6,0-48.2,21.6-48.2,48.2c0,26.6,21.6,48.2,48.2,48.2c26.6,0,48.2-21.6,48.2-48.2
         C114.8,173.4,93.2,151.8,66.5,151.8 M94.7,184.8C94.6,184.8,94.6,184.8,94.7,184.8l-34.2,34.2c-0.5,0.5-1.2,0.8-1.9,0.8
         c-0.7,0-1.4-0.3-1.9-0.8l-18.4-18.4c-1-1-1-2.7,0.1-3.7c1-1,2.6-1,3.6,0l16.5,16.5l32.3-32.3c1-1,2.7-1.1,3.7-0.1
         C95.6,182,95.7,183.7,94.7,184.8"
          />
        </g>
      </g>
    </g>
    <g id="Artwork_15_3_">
      <g>
        <defs>
          <rect
            id="SVGID_9_"
            x="18.3"
            y="280.1"
            width="96.4"
            height="96.4"
          />
        </defs>
        <clipPath id="SVGID_10_">
          <use
            xlinkHref="#SVGID_9_"
            overflow="visible"
          />
        </clipPath>
        <g clipPath="url(#SVGID_10_)">
          <defs>
            <rect
              id="SVGID_11_"
              x="18.3"
              y="280.1"
              width="96.4"
              height="96.4"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use
              xlinkHref="#SVGID_11_"
              overflow="visible"
            />
          </clipPath>
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFF"
            d="M66.5,280.1c-26.6,0-48.2,21.6-48.2,48.2c0,26.6,21.6,48.2,48.2,48.2c26.6,0,48.2-21.6,48.2-48.2
         C114.8,301.7,93.2,280.1,66.5,280.1 M94.7,313.1C94.6,313.1,94.6,313.1,94.7,313.1l-34.2,34.2c-0.5,0.5-1.2,0.8-1.9,0.8
         c-0.7,0-1.4-0.3-1.9-0.8l-18.4-18.4c-1-1-1-2.7,0.1-3.7c1-1,2.6-1,3.6,0l16.5,16.5l32.3-32.3c1-1,2.7-1.1,3.7-0.1
         C95.6,310.4,95.7,312.1,94.7,313.1"
          />
        </g>
      </g>
    </g>
  </svg>
);

const TranslateGoogleWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 150px;
    padding-right: 15px;
    transform: translate(-100%, 200px);

    .RentOutPage__TranslateWrapper__Content {
      width: 250px;

      transform: translate(-30px, 0px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 170px;
    padding-right: 30px;
    transform: translate(-100%, 180px);

    .RentOutPage__TranslateWrapper__Content {
      width: 280px;

      transform: translate(-130px, -10px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 210px;
    padding-right: 30px;
    transform: translate(-100%, 180px);

    .RentOutPage__TranslateWrapper__Content {
      width: 330px;

      transform: translate(-130px, -10px);
    }
  }
`;

const FeatureIconItem = ({
  headline,
  text,
  icon,
}: {
  headline: string;
  text: string;
  icon: JSX.Element;
}) => (
  <Flex
    textAlign={{
      xs: "center",
      md: "left",
    }}
    column
  >
    <Box mb={4}>{icon}</Box>
    <Box mb={1}>
      <Text
        color="inverted"
        weight="bold"
        size={{
          xs: "h2",
          md: "h4",
          lg: "h3",
        }}
      >
        {headline}
      </Text>
    </Box>
    <Box>
      <Text
        color="inverted"
        size={{
          xs: "body",
          md: "small",
          lg: "body",
        }}
      >
        {text}
      </Text>
    </Box>
  </Flex>
);

const MarketingMailDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateAgentWrapper>
      <AgentArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={<MailIcon />}
          />
        </AnimationReveal>
      </div>
    </TranslateAgentWrapper>
  </div>
);

const MarketingMailMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal>
    <FeatureIconItem
      headline={headline}
      text={text}
      icon={<MailIcon />}
    />
  </AnimationReveal>
);

const MarketingFacebookDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateFacebookWrapper>
      <FacebookArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={<FacebookLogo />}
          />
        </AnimationReveal>
      </div>
    </TranslateFacebookWrapper>
  </div>
);
const MarketingFacebookMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal>
    <Box pt={8}>
      <FeatureIconItem
        headline={headline}
        text={text}
        icon={<FacebookLogo />}
      />
    </Box>
  </AnimationReveal>
);

const MarketingGoogleDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateGoogleWrapper>
      <GoogleArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={<GoogleAdsLogo />}
          />
        </AnimationReveal>
      </div>
    </TranslateGoogleWrapper>
  </div>
);

const MarketingGoogleMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal>
    <Box pt={8}>
      <FeatureIconItem
        headline={headline}
        text={text}
        icon={<GoogleAdsLogo />}
      />
    </Box>
  </AnimationReveal>
);
const MarketingSectionHouseSvg = () => (
  <svg
    width="536"
    height="547"
    viewBox="-5 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        // eslint-disable-next-line max-len
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const RelativeBox = styled(Box)`
  position: relative;
`;

interface Props {
  headline: string;
  subHeadline: string;
  feature1Headline: string;
  feature1SubHeadline: string;
  feature2Headline: string;
  feature2SubHeadline: string;
  feature3Headline: string;
  feature3SubHeadline: string;
  centeredComponent: React.ReactNode;
}

export const FeaturesIconSection = ({
  headline,
  subHeadline,
  feature1Headline,
  feature1SubHeadline,
  feature2Headline,
  feature2SubHeadline,
  feature3Headline,
  feature3SubHeadline,
  centeredComponent,
}: Props) => (
  <PageBlock
    bg="business"
    py="extraLarge"
    pyMobile="extraLarge"
    bgGradient
    bgBottomRightOverlay={
      <Hidden
        on={{
          xs: true,
          md: false,
        }}
      >
        <MarketingSectionHouseSvg />
      </Hidden>
    }
    negativeTopMargin="-250px"
  >
    <MovingReportsLandingPageContainer>
      <Flex
        mt={{
          xs: 20,
          md: 22,
        }}
        mb={{
          xs: 0,
          md: 30,
        }}
        textAlign="center"
        align="center"
        column
      >
        <HeroHeader>{headline}</HeroHeader>
        <Box
          maxWidth={{
            xs: "100%",
            sm: "500px",
          }}
          mb={{
            xs: 10,
            md: 24,
          }}
          mt={4}
        >
          <MarketingSubHeader>{subHeadline}</MarketingSubHeader>
        </Box>

        {/* Desktop */}
        <Flex
          width="100%"
          justify="center"
          hidden={{
            xs: true,
            md: false,
          }}
        >
          <RelativeBox>
            <MarketingMailDesktopSection
              headline={feature1Headline}
              text={feature1SubHeadline}
            />
            <MarketingFacebookDesktopSection
              headline={feature2Headline}
              text={feature2SubHeadline}
            />
            <MarketingGoogleDesktopSection
              headline={feature3Headline}
              text={feature3SubHeadline}
            />
          </RelativeBox>

          {centeredComponent}
        </Flex>

        {/* Mobile */}
        <Flex
          column
          hidden={{
            xs: false,
            md: true,
          }}
        >
          <MarketingMailMobileSection
            headline={feature1Headline}
            text={feature1SubHeadline}
          />
          <MarketingFacebookMobileSection
            headline={feature2Headline}
            text={feature2SubHeadline}
          />
          <MarketingGoogleMobileSection
            headline={feature3Headline}
            text={feature3SubHeadline}
          />
        </Flex>
      </Flex>
    </MovingReportsLandingPageContainer>
  </PageBlock>
);
