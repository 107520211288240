import { Button, IconClose, Text } from "@boligportal/juice";
import {
  DialogSidebar,
  DialogHeader,
  DialogBody,
} from "components/dialog/dialog";
import { Modal } from "components/modal";
import { RefundsDetails } from "./refund_details";

type Props = {
  closeHandler: () => void;
  amount: string;
  date: string;
  zendesk_ticket_id: string;
  username: string;
  agent: string;
  fullname: string;
  user_id: number;
  reason: string;
  note: string;
};

const RefundDetailsModal = ({
  username,
  fullname,
  agent,
  note,
  reason,
  amount,
  zendesk_ticket_id,
  closeHandler,
}: Props) => (
  <Modal>
    <DialogSidebar
      width="500px"
      onClose={closeHandler}
    >
      <DialogHeader>
        <Text size="h3">Refund Details</Text>
        <Button
          icon={IconClose}
          onClick={closeHandler}
        />
      </DialogHeader>
      <DialogBody>
        <RefundsDetails
          username={username}
          fullname={fullname}
          agent={agent}
          note={note}
          reason={reason}
          amount={amount}
          zendesk_ticket_id={zendesk_ticket_id}
        />
      </DialogBody>
    </DialogSidebar>
  </Modal>
);

export { RefundDetailsModal };
