import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  Flex,
  IconArrowForward,
  IconListOutline,
  Labelled,
  Text,
} from "@boligportal/juice";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { t } from "lib/i18n";
import SvgBetalingsserviceLogo from "media/svg/BetalingsserviceLogo";
import SvgMobilePayWithText from "media/svg/MobilePayWithText";

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
  checkFieldDisclaimer: string;
  checkFieldLabel: string;
  checkFieldErrorText: string;
  paymentProvider: TenancyPaymentType;
};

export const PaymentRecordChangeStatusConfirmation = ({
  open,
  onCancel,
  onConfirm,
  title,
  message,
  cancelText,
  confirmText,
  checkFieldErrorText,
  checkFieldLabel,
  checkFieldDisclaimer,
  paymentProvider,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmitHandler = () => {
    if (isChecked) {
      setShowError(false);
      onConfirm();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    setShowError(false);
  }, [isChecked]);

  return (
    <Dialog
      open={open}
      size="large"
      onClose={onCancel}
    >
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>
        <>
          <Text
            block
            pb={2}
          >
            {message}
          </Text>
          <Flex
            p={2}
            bg="tint"
            border
            justify="center"
            align="center"
          >
            {paymentProvider === TenancyPaymentType.MOBILEPAY && (
              <SvgMobilePayWithText
                width={102}
                height={32}
              />
            )}
            {paymentProvider === TenancyPaymentType.BETALINGS_SERVICE && (
              <SvgBetalingsserviceLogo
                width={102}
                height={32}
              />
            )}
            <Box px={4}>
              <IconArrowForward />
            </Box>
            <Flex align="center">
              <IconListOutline size="medium" />
              <Text
                size="small"
                pl={0.5}
              >
                {t("common.account.manual_transfer")}
              </Text>
            </Flex>
          </Flex>
          <Box py={2}>
            <Divider />
          </Box>
          <Text
            block
            weight="bold"
          >
            {checkFieldDisclaimer}
          </Text>
          <Labelled errorText={showError ? checkFieldErrorText : ""}>
            <CheckField
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
              label={checkFieldLabel}
            />
          </Labelled>
        </>
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            variant="primary"
            onClick={onSubmitHandler}
          >
            {confirmText}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
