import { NavLink, NavLinkProps } from "react-router-dom";
import { styled, useTheme } from "@boligportal/juice";
import { css } from "@emotion/react";

const StyledNavLink = styled(NavLink)<{ whiteSpace: "pre" | "normal" }>`
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.small};

  padding: ${(props) => `${props.theme.unit(1)} ${props.theme.unit(2)}`};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: auto;
    width: ${(props) => (props.whiteSpace === "normal" ? "33.33%" : "auto")};
  }

  white-space: ${(props) => props.whiteSpace};

  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.colorPalette.gray[100]};
  text-decoration: none !important;
  text-align: center;

  background-color: transparent;
  border-bottom: 3px solid transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    ${(props) => css`
      color: ${props.theme.colorPalette.gray[300]};
      border-bottom: 3px solid ${props.theme.colorPalette.gray[40]};
    `}
  }
`;

export const TabButton = ({
  children,
  exact = true,
  ...props
}: NavLinkProps & { whiteSpace?: "pre" | "normal" }) => {
  const theme = useTheme();

  const { whiteSpace = "normal", ...rest } = props;

  return (
    <StyledNavLink
      whiteSpace={whiteSpace}
      {...rest}
      exact={exact}
      activeStyle={{
        borderBottom: `3px solid ${theme.colorPalette.primary[700]}`,
        color: theme.color.text.base,
      }}
    >
      {children}
    </StyledNavLink>
  );
};
