import { AdStates } from "lib/enums";
import { AdsPageAll } from "./ads_page_all";

const AdsPageApproveUserChanges = () => (
  <AdsPageAll
    initialStates={[AdStates.ACTIVE_APPROVED]}
    initialReviewReasons={["MODIFIED"]}
    actionLabelText={"Review"}
    crawlingAds={false}
    companyAds={false}
  />
);

export { AdsPageApproveUserChanges };
