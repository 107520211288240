import { LandlordOnbordingItem } from "../../types/LandlordOnbordingItem";
import { Flow } from "../../useLandlordOnboardingFeature";
import {
  createItemsForAdFlow,
  createItemsForContractFlow,
  getTitleAndDescription,
} from "../shared";

export const createOnboardingViewModel_SE = (
  flow: Flow,
  onClose: () => void,
) => {
  const { title, description } = getTitleAndDescription(flow);
  let items: LandlordOnbordingItem[] = [];

  switch (flow) {
    case "ad":
      items = createItemsForAdFlow(flow, onClose);
      break;
    case "contract":
      items = createItemsForContractFlow(flow, onClose);
      break;
    case "moving-report":
      throw "moving-report not supported in Bostadsportal";
  }

  return {
    items,
    title,
    description,
  };
};
