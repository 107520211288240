import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Plan } from "components/interfaces/plan";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import {
  OptionalPaymentMethod,
  PaymentMethodType,
} from "features/payments_feature/enums/PaymentMethodType";

type PaywallCandidate = {
  plan: Plan;
  paymentMethodType?: PaymentMethodType;
  adId: number | null;
  rentableId: number | null;
  uniquePaywallKey: string;
  title: string;
};
type PaymentFlowCoordinatorProps = {
  registeredPaywalls: RegisteredPaywall[];
  paywallCandidate: PaywallCandidate | null;
  onClose: () => void;
};
type PaymentFlowCoordinator = React.ComponentType<PaymentFlowCoordinatorProps>;
type PaymentFlowCoordinatorMap = OptionalPaymentMethod<
  PaymentMethodType,
  PaymentFlowCoordinator
>;
type PaymentFlowCallbacks = {
  onPaymentFailedRetryButtonClick: (plan: Plan) => void;
  onPaymentSuccessContinueButtonClick: (plan: Plan) => void;
  onSubscriptionActivated?: () => void;
};
type RegisteredPaywall = PaymentFlowCallbacks & {
  uniquePaywallKey: string;
  plans: Plan[];
};
type PaywallProviderContextType = {
  paymentMethods: PaymentMethodType[];
  setPaywallCandidate: (paywallCandidate: PaywallCandidate | null) => void;
  setRegisteredPaywalls: React.Dispatch<
    React.SetStateAction<RegisteredPaywall[]>
  >;
};
type Props = {
  children: React.ReactNode;
  paymentFlowCoordinators: PaymentFlowCoordinatorMap;
};

const PaywallProviderContext = createContext<PaywallProviderContextType | null>(
  null,
);

/**
 * paymentFlowCoordinators:
 * This map associates each PaymentMethodType with its corresponding PaymentFlowCoordinator component.
 * These coordinators are responsible for managing the payment process for their specific method.
 * They render the appropriate payment flow UI components and handle user interactions during the payment process,
 * including success, failure, and retries. This design allows for a modular and extensible approach to handling
 * different payment methods within the application.
 */
export const PaywallProvider = ({
  children,
  paymentFlowCoordinators,
}: Props) => {
  const memoizedPaymentMethods = useMemo(
    () => Object.keys(paymentFlowCoordinators) as PaymentMethodType[],
    [paymentFlowCoordinators],
  );
  const [registeredPaywalls, setRegisteredPaywalls] = useState<
    RegisteredPaywall[]
  >([]);
  const [paywallCandidate, setPaywallCandidate] =
    useState<PaywallCandidate | null>(null);

  const resetPaywallCandidate = () => {
    setPaywallCandidate(null);
  };

  return (
    <>
      <PaywallProviderContext.Provider
        value={{
          paymentMethods: memoizedPaymentMethods,
          setPaywallCandidate,
          setRegisteredPaywalls,
        }}
      >
        {children}
      </PaywallProviderContext.Provider>
      {Object.entries(paymentFlowCoordinators).map(
        ([paymentMethod, PaymentFlowCoordinatorImpl]) => (
          <PaymentFlowCoordinatorImpl
            key={paymentMethod}
            paywallCandidate={paywallCandidate}
            registeredPaywalls={registeredPaywalls}
            onClose={resetPaywallCandidate}
          />
        ),
      )}
    </>
  );
};

/**
 * @param uniquePaywallKey Unique ID for managing paywalls.
 * ----
 * @param plans
 * 🚨 **Array of `Plan` objects, MUST be stable across renders.**
 *
 * 🚨 **Important: Do NOT use dynamic arrays like `[plan]` as this WILL cause an endless loop.**
 *
 * ----
 * @param callbacks `PaymentFlowCallbacks` object for payment events.
 *
 * Uses the "latest ref pattern" for stable callbacks across renders, aligning with React's best practices.
 * (Details: https://mtg-dev.tech/blog/the-best-way-to-pass-callback-functions-to-your-custom-hooks).
 * ----
 * Adheres to React's `useEvent` principles.
 * (Read more: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md).
 *
 * Returns `paymentMethods` and a `setPaywallCandidate` function for valid `PaywallCandidate` setup.
 */

const useRegisterPaywall = (
  uniquePaywallKey: string,
  plans: Plan[],
  callbacks: PaymentFlowCallbacks,
) => {
  const callbacksRef = useRef(callbacks);
  const context = useContext(PaywallProviderContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useRegisterPaywall",
      "PaywallProvider",
    );
  }

  const { setPaywallCandidate, setRegisteredPaywalls, paymentMethods } =
    context;

  useLayoutEffect(() => {
    callbacksRef.current = callbacks;
  });

  useEffect(() => {
    setRegisteredPaywalls((prev) => [
      ...prev,
      {
        uniquePaywallKey,
        plans,
        ...callbacksRef.current,
      },
    ]);
    return () => {
      setRegisteredPaywalls((prev) =>
        prev.filter((item) => item.uniquePaywallKey !== uniquePaywallKey),
      );
    };
  }, [uniquePaywallKey, setRegisteredPaywalls, plans]);

  /**
   * Consumers of the hook should not be allowed to set the paywall candidate to null.
   * Therefore, we wrap the setter to enforce the requirement of a valid paywall candidate.
   */
  const ensureRequiredPaywallCandidate = useCallback(
    (paywallCandidate: PaywallCandidate) => {
      setPaywallCandidate(paywallCandidate);
    },
    [setPaywallCandidate],
  );

  /**
   * These are the only elements we want to expose to the consumers of the hook.
   *
   * paymentMethods:
   * Contains the types of payment methods that match
   * the paymentFlowCoordinators passed to the PaywallProvider. These can be used
   * for rendering buttons, or for other purposes. They are guaranteed to only contain
   * values for payment flows that are available to the user.
   *
   * setPaywallCandidate:
   * Allows the consumer to set a paywall candidate. This will trigger the payment flow
   * coordinator to render the appropriate UI for the selected paywall candidate.
   */
  return {
    paymentMethods,
    setPaywallCandidate: ensureRequiredPaywallCandidate,
  };
};

export {
  useRegisterPaywall,
  PaymentFlowCoordinatorMap,
  PaymentFlowCoordinatorProps,
  RegisteredPaywall,
};
