import { fetchFromAPI } from "lib/api";
import { ContactsResponse } from "./types";

export const getContacts = async (userToken: string) =>
  getTenantHubContacts(userToken);

// using fetchFromAPI bacause juice http client doesnt support language prefix
const getTenantHubContacts = async (
  userToken: string,
): Promise<ContactsResponse> => {
  const response = await fetchFromAPI(`/api/tenant-hub/${userToken}/contacts`, {
    method: "GET",
  });
  return response.json();
};
