import { useState } from "react";
import {
  Text,
  Box,
  Button,
  IconCheckmark,
  IconCalendarOutline,
  Flex,
  styled,
  useMedia,
} from "@boligportal/juice";
import { TenancyPaymentTransaction } from "business/domain/tenancy/TenancyPaymentTransaction";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { isToday, isPast } from "date-fns";
import { useTransferArrears } from "features/rent_collection/useTransferArrears";
import { AddTenantPaymentDialog } from "features/rentable_tenancy/components/PartialPaymentDialog/AddTenantPaymentDialog";
import { calculateArrears } from "features/rentable_tenancy/helpers/calculateArrears";
import { formatArrears } from "features/rentable_tenancy/helpers/formatArrears";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";

export const TenancyPaymentArrears = ({
  rent,
  currency,
  transactions,
  tenancyId,
  paymentRecordId,
  paymentDueDate,
  paymentType,
}: {
  rent: number;
  currency: string;
  transactions: TenancyPaymentTransaction[];
  tenancyId: number;
  paymentRecordId: number;
  paymentDueDate: string;
  paymentType: TenancyPaymentType;
}) => {
  const [hover, setHover] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { setTransferArrearsCandidate } = useTransferArrears();
  const { arrears, totalPayment } = calculateArrears(rent, transactions);
  const paymentDueDateIsPastAndNotToday =
    isPast(new Date(paymentDueDate)) && !isToday(new Date(paymentDueDate));
  const media = useMedia(0);

  const onTransferArrears = () => {
    if (tenancyId) {
      setTransferArrearsCandidate({
        tenancyId,
        paymentRecordId,
        arrears,
        paymentDueDate,
      });
    }
  };

  const handleHover = (hoverState: boolean) => {
    //hover should only activate on larger screens
    if (media.xl.up) {
      setHover(hoverState);
    } else {
      setHover(false);
    }
  };

  if (transactions.length === 0) {
    return null;
  }

  if (arrears === 0) {
    return null;
  }

  return (
    <>
      {showDialog && (
        <AddTenantPaymentDialog
          open
          onClose={() => setShowDialog(false)}
          arrears={arrears}
          tenancyPaymentRecordId={paymentRecordId}
          transactions={{
            items: transactions,
          }}
          rentAmount={rent}
          paymentType={paymentType}
        />
      )}
      <Text size="tiny">
        {t("rentable.rent.partial_payments_from_tenant")}{" "}
        {formatCurrency(totalPayment, currency)}
      </Text>

      <div
        onMouseOver={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <Text
          size="tiny"
          color={arrears >= 0 ? "base" : "danger"}
        >
          {`${
            arrears > 0
              ? t("rentable.rent.paid_too_much")
              : t("rentable.rent.missing_payment")
          } ${formatArrears(arrears)}`}
        </Text>
        {hover && (
          <StyledFlex
            column
            gap
            border
            shadow={"sm"}
            borderRadius={"sm"}
            bg="base"
            p={1}
            align="baseline"
          >
            {arrears < 0 && paymentDueDateIsPastAndNotToday && (
              <>
                <Box
                  mb={1}
                  ml={-0.5}
                >
                  <Button
                    fullWidth
                    variant="subtle"
                    text
                    icon={IconCalendarOutline}
                    onClick={onTransferArrears}
                  >
                    {t("rentable.rent.transfer_arrears_to_another_payment")}
                  </Button>
                </Box>
              </>
            )}

            <Box ml={-0.5}>
              <Button
                fullWidth
                variant="subtle"
                text
                icon={IconCheckmark}
                onClick={() => setShowDialog(true)}
              >
                {arrears > 0
                  ? t("rentable.rent.arrears_mark_as_manual_refund")
                  : t("rentable.rent.arrears_mark_as_manual_paid")}
              </Button>
            </Box>
          </StyledFlex>
        )}
      </div>
    </>
  );
};

const StyledFlex = styled(Flex)`
  position: absolute;
`;
