import * as React from "react";
import { Box, Button, IconAdd, Flex, Text } from "@boligportal/juice";
import { ImageList } from "apps/moving_reports/components/images_list/ImageList";
import { Image } from "apps/moving_reports/interfaces/image";
import { t } from "lib/i18n";
import { MovingReportImageUploader } from "../../MovingReportImageUploader";
import { DefectSelections } from "../CreateDefectDialog";
import { SelectedUnit } from "./DefectDetails";

export const Images = ({
  units,
  images,
  onUpload,
  onRemove,
  onLoading,
  imageUploadUrl,
}: {
  units: SelectedUnit[];
  images: DefectSelections["images"];
  onUpload: (payload: Image[], id: number) => void;
  onRemove: (imageId: number, unitId: number) => void;
  onLoading: (state: boolean) => void;
  imageUploadUrl: string;
}) => {
  const handleUpload = (payload: Image[], id: number) => {
    onLoading(false);
    onUpload(payload, id);
  };

  return (
    <>
      {units.map((item) => (
        <Box
          key={item.id}
          mt={1}
          pt={3}
          borderTop
        >
          <Flex
            justify="space-between"
            mb={1}
          >
            <Text
              size="h5"
              weight="bold"
              mb={1}
              block
            >
              {`${item.roomLabel} – ${item.label}`}
            </Text>
            <MovingReportImageUploader
              onUploadDone={(payload) => handleUpload(payload, item.id)}
              customUploadUrl={imageUploadUrl}
              onUploadStart={() => onLoading(true)}
            >
              {(
                fakeClickableRef: React.RefObject<HTMLDivElement>,
                dropzoneRef: React.RefObject<HTMLDivElement>,
              ) => (
                <div ref={fakeClickableRef}>
                  <Button
                    ref={dropzoneRef}
                    text
                  >
                    <IconAdd
                      size="small"
                      inline
                    />
                    {t("common.upload")}
                  </Button>
                </div>
              )}
            </MovingReportImageUploader>
          </Flex>
          <ImageList
            images={images[item.id] || []}
            onRemoveImage={(id) => onRemove(id, item.id)}
          />
        </Box>
      ))}
    </>
  );
};
