import {
  Box,
  Card,
  CardSection,
  Container,
  Flex,
  Headline,
  Hidden,
  styled,
} from "@boligportal/juice";
import { t } from "lib/i18n";

const HeadlineWithoutMargin = styled(Headline)`
  margin: 0;
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.color.border};
`;

interface ChooseOrCreateRentableLayoutProps {
  sharedHeaderComponent: JSX.Element;
  sharedSearchComponent: JSX.Element;
  sharedCreateButton: JSX.Element;
  sharedPaginatorComponent: JSX.Element;
  desktopRentalListComponent: JSX.Element;
  mobileRentalListComponent: JSX.Element;
}

export const ChooseOrCreateRentableResponsiveLayout = ({
  sharedHeaderComponent,
  sharedCreateButton,
  sharedSearchComponent,
  sharedPaginatorComponent,
  desktopRentalListComponent,
  mobileRentalListComponent,
}: ChooseOrCreateRentableLayoutProps) => (
  <div>
    <Box
      py={{
        xs: 4,
        lg: 8,
      }}
    >
      {sharedHeaderComponent}
    </Box>
    <Hidden
      on={{
        xs: true,
        lg: false,
      }}
    >
      <Container>
        <Card sectioned>
          <CardSection noPadding>
            <Flex
              column
              px={2}
              pt={1}
              pb={3}
            >
              <Flex
                justify="space-between"
                align="center"
              >
                <Headline as="h4">
                  {t("feature.choose_or_create_rentable.list_headline")}
                </Headline>
                <Box>{sharedCreateButton}</Box>
              </Flex>
              <Box maxWidth="420px">{sharedSearchComponent}</Box>
            </Flex>
            {desktopRentalListComponent}
          </CardSection>
        </Card>
        {sharedPaginatorComponent}
      </Container>
    </Hidden>

    <Hidden
      on={{
        xs: false,
        lg: true,
      }}
    >
      <>
        <Box
          bg="base"
          shadow="none"
          borderTop
          borderBottom
          py={3}
        >
          <Container fluid>
            <Flex align="center">
              <Box>
                <HeadlineWithoutMargin as="h4">
                  {t("header.MyRentables")}
                </HeadlineWithoutMargin>
              </Box>
              <Box flexPush>{sharedCreateButton}</Box>
            </Flex>

            <Box py={2}>
              <StyledDivider />
            </Box>

            <Box>{sharedSearchComponent}</Box>
          </Container>
        </Box>

        {mobileRentalListComponent}
        {sharedPaginatorComponent}
      </>
    </Hidden>
  </div>
);
