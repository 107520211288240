import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { shouldDisplayPaymentInfoNotice } from "business/domain/tenancy/shouldDisplayPaymentInfoNotice";
import { App } from "components/app";
import { RentRecordElipsisMenuItem } from "components/cells";
import { isPast } from "date-fns";
import { calculateArrears } from "features/rentable_tenancy/helpers/calculateArrears";
import { calculateTenancyPaymentsSum } from "features/rentable_tenancy/helpers/calculateTenancyPaymentsSum";
import { isDateCertainDayOfMonth } from "lib/date";
import { t } from "lib/i18n";
import { RentRecord } from "../types/RentRecord";
import { RentRecordPresenter } from "./RentRecordPresenter";

type Config = {
  records: RentRecord[];
  onDisplayRecordDetail: (item: RentRecord) => void;
  onDisplayPaymentStatusChange: (
    item: RentRecord,
    newStatus: TenancyPaymentStatus,
  ) => void;
  onMenuItemSelect: (
    record: RentRecord,
    item: RentRecordElipsisMenuItem,
  ) => void;
};

export const createRentRecordPresenterItems = (
  config: Config,
): RentRecordPresenter[] => {
  const rentRecordItems: RentRecordPresenter[] = [];
  const settingsLanguagePrefix = App.settings.i18n_prefix;

  const paymentArrearsUtil = (
    record: RentRecord,
  ): { arrears: number; paymentRecordHasArrearsAndIsInthePast: boolean } => {
    const items =
      record.rent_regulation && record.rent_regulation.items.length > 0
        ? record.rent_regulation.items
        : record.recurring_rent;
    const totalRent = items
      ? calculateTenancyPaymentsSum(items) +
        calculateTenancyPaymentsSum(record.one_time_payment?.items ?? [])
      : 0;
    const { arrears } = calculateArrears(
      totalRent,
      record?.transactions.items ?? [],
    );
    const paymentRecordHasArrearsAndIsInthePast =
      arrears < 0 && isPast(new Date(record?.due_date));
    return {
      arrears,
      paymentRecordHasArrearsAndIsInthePast,
    };
  };

  config.records.forEach((record) => {
    rentRecordItems.push({
      id: record.id,
      addressAndTenancyCell: {
        tenancyLink: `${settingsLanguagePrefix}/property-owner/my-rentables/${record.rentableId}/tenancies/${record.tenancyId}/rent`,
        rentableLink: `${settingsLanguagePrefix}/property-owner/my-rentables/${record.rentableId}`,
        address: record.address,
        tenants: record.tenants,
        shouldShowAddressLink: !window.location.href.includes("my-rentables"),
      },
      rentDueDateCell: {
        dueDate: new Date(record.due_date),
        infoDialog: shouldDisplayDueDateInfo(new Date(record.due_date))
          ? {
              text: t("rent.due_date_info_dialog.text"),
              title: t("rent.rent.due_date_info_dialog.title"),
            }
          : undefined,
      },
      rentAmountCell: {
        currency: App.settings.currency,
        amount: record.amount,
        regulationItems: record.rent_regulation?.items ?? [],
        oneTimePaymentItems: record.one_time_payment?.items ?? [],
        transactions: record.transactions.items,
        paymentRecordId: record.id,
        tenancyId: record.tenancyId,
        paymentDueDate: record.due_date,
        paymentType: record.paymentInfo.paymentMethodType,
      },
      rentPaymentInfoCell: {
        status: record.status,
        message: record.paymentInfo.messageKey
          ? t(record.paymentInfo.messageKey)
          : "",
        paymentMethodType: record.paymentInfo.paymentMethodType,
        wasManuallyChanged: record.paymentInfo.wasManuallyChanged,
      },
      rentPaymentDetailButtonCell: {
        count:
          shouldDisplayPaymentInfoNotice(record.paymentInfo, record.status) ||
          paymentArrearsUtil(record).paymentRecordHasArrearsAndIsInthePast
            ? 1
            : 0,
        note: record.note,
        onClick: () => config.onDisplayRecordDetail(record),
      },
      rentPaymentStatusCell: {
        status: record.status,
        onStatusChange: (newStatus) =>
          config.onDisplayPaymentStatusChange(record, newStatus),
      },
      rentRecordElipsisMenuCell: {
        status: record.status,
        onMenuItemSelect: (menuItem) =>
          config.onMenuItemSelect(record, menuItem),
        paymentType: record.paymentInfo.paymentMethodType,
        arrears: paymentArrearsUtil(record).arrears,
        paymentDueDate: record.due_date,
      },
    });
  });
  return rentRecordItems;
};

const shouldDisplayDueDateInfo = (dueDate: Date): boolean => {
  if (
    isDateCertainDayOfMonth(dueDate, 1) ||
    isDateCertainDayOfMonth(dueDate, 15)
  ) {
    return false;
  }

  return true;
};
