import { useState, useEffect } from "react";
import { getAds } from "lib/customerServiceApi";
import { AdItem } from "../../ads_page/interfaces";
import { AllLandslordAds } from "./all_landlord_ads";

interface LandlordAdsItem {
  ads: AdItem[];
  count: number;
}

const getAllLandlordAds = async (
  landlordId: number,
  limit: number,
): Promise<LandlordAdsItem> => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const allLandlordAds = await getAds({
    signal,
    filters: {
      landlord_id_exact: landlordId,
    },
    limit,
    sorting: "advertised_date",
    descending: true,
  })
    .then((response) => ({
      ads: response.data,
      count: response.count,
    }))
    .catch((error) => Promise.reject(error));

  return allLandlordAds;
};

const DeferedAllLandslordAds = ({ landlordId }: { landlordId: number }) => {
  const [adsItem, setAdsItem] = useState<LandlordAdsItem>({
    ads: [],
    count: 0,
  });

  useEffect(() => {
    const run = async () => {
      const landlordAdsItem = await getAllLandlordAds(landlordId, 15);
      setAdsItem(landlordAdsItem);
    };
    run();
  }, [landlordId]);

  return (
    <AllLandslordAds
      items={adsItem.ads}
      totalCount={adsItem.count}
      userId={landlordId}
    />
  );
};

export { DeferedAllLandslordAds };
