import { NotificationsProvider, styled } from "@boligportal/juice";
import { css } from "@emotion/react";
import { FullLayout } from "components/sidebar_layout";
import { MarketSpecificProvider } from "features/cs/market_specific_feature/context/MarketSpecificContext";
import { ContentArea } from "./components/content_area/content_area";
import { Sidebar } from "./components/sidebar/sidebar";
import { Topbar } from "./components/topbar/topbar";

export const PAGE_HOME: string = "/customer-service";
export const PAGE_USERS: string = `${PAGE_HOME}/users`;
export const PAGE_EMAIL_LOGS: string = `${PAGE_HOME}/email-logs`;
export const PAGE_BETALINGSSERVICE: string = `${PAGE_HOME}/betalingsservice`;
export const PAGE_COMPANIES: string = `${PAGE_HOME}/companies`;
export const PAGE_COMPANY_DETAIL: string = `${PAGE_COMPANIES}/:id`;
export const PAGE_COMPANY_ADS: string = `${PAGE_HOME}/company/ads`;
export const PAGE_USER_DETAIL: string = `${PAGE_USERS}/:id`;
export const PAGE_PAYMENTS: string = `${PAGE_HOME}/payments`;
export const PAGE_REFUNDS: string = `${PAGE_HOME}/refunds`;
export const PAGE_SETTINGS: string = `${PAGE_HOME}/settings`;

export const PAGE_ADS_ALL: string = `${PAGE_HOME}/ads/all`;
export const PAGE_ADS_WAITING_FOR_APPROVAL: string = `${PAGE_HOME}/ads/waiting-for-approval`;
export const PAGE_ADS_APPROVE_USER_CHANGES: string = `${PAGE_HOME}/ads/approve-user-changes`;
export const PAGE_PROFILES_APPROVE_USER_CHANGES: string = `${PAGE_HOME}/profiles/approve-user-changes`;
export const PAGE_ADS_APPROVED: string = `${PAGE_HOME}/ads/approved`;
export const PAGE_ADS_REJECTED: string = `${PAGE_HOME}/ads/rejected`;
export const PAGE_ADS_FOLLOW_UP: string = `${PAGE_HOME}/ads/follow-up`;

export const PAGE_AD_DETAIL: string = `${PAGE_HOME}/ads/detail/:id`;
export const PAGE_NEW_BUILD_DETAIL: string = `${PAGE_HOME}/newbuild/detail/:id`;

export const PAGE_CRAWLINGS: string = `${PAGE_HOME}/crawlings/all`;
export const PAGE_CRAWLINGS_ADS: string = `${PAGE_HOME}/crawlings/ads/all`;
export const PAGE_CRAWILNGS_NEEDS_REVIEW: string = `${PAGE_HOME}/crawlings/ads/waiting-for-approval`;
export const PAGE_CRAWLINGS_DRAFTS: string = `${PAGE_HOME}/crawlings/drafts`;
export const PAGE_CRAWLING_DRAFT_DETAIL: string = `${PAGE_HOME}/crawlings/drafts/detail/:id`;

export const PAGE_PAYMENTS_AND_AGREEMENTS = `${PAGE_HOME}/payments-and-agreements`;

export const TOP_BAR_HEIGHT: string = "68px";
export const SIDE_BAR_WIDTH: string = "250px";

const StyledHGroup = styled.div`
  flex-grow: 1;
  display: flex;
  min-height: 0;
`;

const StyledScrollContainer = styled.div<{ bg?: string }>`
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
`;

const styledSidebarWrapperStyles = (props) => css`
  background-color: ${props.theme.colorPalette.primary[800]};
`;

const StyledSidebarWrapper = styled.div<{ width: string }>`
  ${styledSidebarWrapperStyles};
  display: flex;
  flex-shrink: 0;
  zindex: 3001;
  width: ${(props) => props.width};
  border-right: 1px solid #eee;
`;

const CustomerServiceApp = () => (
  <MarketSpecificProvider>
    <NotificationsProvider>
      <FullLayout>
        <div
          style={{
            height: TOP_BAR_HEIGHT,
            flexShrink: 0,
            zIndex: 800,
          }}
        >
          <Topbar />
        </div>
        <StyledHGroup>
          <StyledSidebarWrapper width={SIDE_BAR_WIDTH}>
            <StyledScrollContainer>
              <Sidebar />
            </StyledScrollContainer>
          </StyledSidebarWrapper>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <ContentArea />
          </div>
        </StyledHGroup>
      </FullLayout>
    </NotificationsProvider>
  </MarketSpecificProvider>
);

export default CustomerServiceApp;
