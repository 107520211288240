import {
  Box,
  Button,
  Flex,
  IconArrowBackForward,
  IconDocumentTextOutline,
  IconLogo,
  Text,
} from "@boligportal/juice";
import { useWindowWidth } from "components/empty_screens/FeaturesDisplayScreen";
import { t } from "lib/i18n";

export type RentableActionsCell = {
  actions: {
    onCreateListing: () => void;
    onCreateMovingReport: () => void;
    onCreateContractClick: () => void;
  };
};

export const RentableActionsCellRenderer = ({
  actions,
}: RentableActionsCell) => {
  const windowWidth = useWindowWidth();
  const shouldHideActions = windowWidth < 1550;

  const { onCreateListing, onCreateMovingReport, onCreateContractClick } =
    actions;

  // The extra Box Wrapping was needed in order for Gap to work :-/
  return (
    <Flex
      align="baseline"
      justify="end"
      hidden={shouldHideActions}
    >
      <Box mx={0.5}>
        <CreateListing createListingHref={onCreateListing} />
      </Box>
      <Box mx={0.5}>
        <CreateContract onCreateContractClick={onCreateContractClick} />
      </Box>
      <Box mx={0.5}>
        <CreateMovingReport createMovingReportHref={onCreateMovingReport} />
      </Box>
    </Flex>
  );
};

const CreateListing = ({
  createListingHref,
}: {
  createListingHref: () => void;
}) => (
  <Button
    variant="subtle"
    onClick={createListingHref}
  >
    <Flex align="center">
      <Box>
        <IconLogo />
      </Box>
      <Text
        pl={1}
        color="primary"
        size="small"
      >
        {t("common.list_rentable")}
      </Text>
    </Flex>
  </Button>
);

const CreateContract = ({
  onCreateContractClick,
}: {
  onCreateContractClick: () => void;
}) => (
  <Button
    variant="subtle"
    onClick={onCreateContractClick}
    icon={IconDocumentTextOutline}
  >
    {t("common.create_contract")}
  </Button>
);
const CreateMovingReport = ({
  createMovingReportHref,
}: {
  createMovingReportHref: () => void;
}) => (
  <Button
    variant="subtle"
    onClick={createMovingReportHref}
    icon={IconArrowBackForward}
  >
    {t("common.create_moving_report")}
  </Button>
);
