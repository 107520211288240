import { useContext } from "react";
import { Flex, IconArrowForward, Text } from "@boligportal/juice";
import { GlobalSearchContext } from "../global_search_context";
import { StyledLink } from "./styled_link";

type Props = {
  count: number;
  numItems: number;
  title: string;
  seeAllLink: string;
};

export const SearchGroupHeader = ({
  count,
  numItems,
  title,
  seeAllLink,
}: Props) => {
  const { reset } = useContext(GlobalSearchContext);

  return (
    <Flex
      align="center"
      bg="tint"
      p={1}
    >
      <Text weight="headline">{title}</Text>
      {count > numItems && (
        <Flex flexPush>
          <StyledLink
            to={seeAllLink}
            onClick={reset}
          >
            <div>
              <Text
                size="tiny"
                mr={1}
              >{`See all ${count} ${title}`}</Text>
              <IconArrowForward
                size="small"
                inline
              />
            </div>
          </StyledLink>
        </Flex>
      )}
    </Flex>
  );
};
