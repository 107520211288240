import { Box, Text, Flex } from "@boligportal/juice";
import { ISearchItemCompany } from "../interfaces";

type Props = {
  data: ISearchItemCompany;
};

const SearchItemCompany = ({ data }: Props) => {
  const { id, name } = data;

  return (
    <Flex
      px={1}
      py={0.5}
    >
      <Box>
        <Text size="small">{`${name}`}</Text>
      </Box>
      <Box flexPush>
        <Text
          size="small"
          weight="bold"
        >{`#${id}`}</Text>
      </Box>
    </Flex>
  );
};

export { SearchItemCompany };
