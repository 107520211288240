import * as React from "react";
import { Link } from "react-router-dom";
import { IconArrowBack, Box, styled } from "@boligportal/juice";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  line-height: ${(props) => props.theme.lineHeight};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.color.text.base};

  &,
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
`;

const StickyBox = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1;
  @media print {
    display: none;
  }
  //Only show the backlink when display size is smaller than 1366px
  @media (min-width: 1366px) {
    display: none;
  }
`;

const ColumnBackLink = (props: {
  label: string;
  to: string;
  transparent?: boolean;
  hidden?: React.ComponentProps<typeof Box>["hidden"];
}) => (
  <StickyBox
    p={2}
    borderBottom={
      props.transparent
        ? true
        : {
            xs: true,
            md: false,
          }
    }
    bg={
      props.transparent
        ? undefined
        : {
            xs: "tint",
            md: "base",
          }
    }
    hidden={props.hidden}
  >
    <StyledLink to={props.to}>
      <IconArrowBack
        size="medium"
        mr={0.5}
      />
      <Box
        truncate
        flexGrow
      >
        {props.label}
      </Box>
    </StyledLink>
  </StickyBox>
);

export { ColumnBackLink };
