import * as React from "react";
import { Header } from "components/header";

interface Header {
  title?: React.ReactElement | string;
  subtitle?: string;
  back?: {
    label: string;
    to: string;
    desktop?: boolean;
  };
  actions?: React.ReactElement;
  tabs?: React.ReactNode;
}

interface ContextInterface {
  header?: Header;
  setHeader: (header?: Header) => void;
}

export const HeaderContext = React.createContext<ContextInterface>({
  setHeader: () => {},
});

export const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [headerValue, setHeaderValue] = React.useState<Header>();

  const setHeader = (header?: Header) => {
    setHeaderValue(header);
  };

  return (
    <HeaderContext.Provider
      value={{
        header: headerValue,
        setHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = (header?: Partial<Header>) => {
  const context = React.useContext(HeaderContext);

  React.useEffect(() => {
    if (!header) {
      return;
    }

    context.setHeader(header);

    return () => {
      context.setHeader(undefined);
    };
  }, []);

  const updateHeader = (headerUpdate: Partial<Header>) => {
    context.setHeader({
      ...context.header,
      ...headerUpdate,
    });
  };

  return {
    updateHeader,
  };
};
