import { useState, useEffect } from "react";
import { Mutation, MutationStatus, useQueryClient } from "react-query";
import { CreateRentablePayload, CreateRentableResponse } from "api/types";
import { trackError } from "lib/tracking/errors";

export const useGetMutationStatusByAddress = (
  address: string,
  isDisabled: boolean,
) => {
  const [status, setStatus] = useState<MutationStatus>("idle");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    // Our queries are triggered in the BulkCreate SubmitHandler.
    // We cannot execute them all with a mutationKey from a central point.
    // Therefore we subscribe to mutations here and find the one that relevant.
    // We update the status based on the mutation result.
    // This listener is also used when retrying a failed mutation.
    queryClient.getMutationCache().subscribe((listener) => {
      if (!listener) {
        return;
      }
      // @ts-ignore
      const { variables } = listener.options as any;
      if (variables?.address !== address) {
        return;
      }

      setStatus(listener.state.status);
    });
  }, [queryClient, address, isDisabled]);

  const createRentable = (payload: CreateRentablePayload) => {
    // Find the failed mutation in the mutation cache and execute it again.
    const mutation = queryClient.getMutationCache().find({
      predicate: (mutation) => mutation.options.variables.address === address,
    });

    if (mutation) {
      // Data might have changed since the original mutation was triggered
      // Therefore we overwrite it with the latest data.
      mutation.options.variables = payload;

      return (mutation as Mutation<CreateRentableResponse>).execute();
    } else {
      trackError("Couldn't find mutation to retry", {
        extra: {
          address,
          status,
        },
      });

      return Promise.reject();
    }
  };

  return {
    status,
    createRentable,
  };
};
