import React, { useState } from "react";
import { StripeError } from "@stripe/stripe-js";
import { t } from "lib/i18n";

export const StripePaymentContext = React.createContext<{
  paymentMethodId: number | null;
  setPaymentMethodId: (paymentMethodId: number | null) => void;
  stripeError: StripeError | null;
  setStripeError: (stripeError: StripeError | null) => void;
  translateStripeErrorMessage: (
    errorCode: StripeError["code"],
  ) => string | null;
}>({
  paymentMethodId: null,
  setPaymentMethodId: () => {},
  stripeError: null,
  setStripeError: () => {},
  translateStripeErrorMessage: () => null,
});

export const StripePaymentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [paymentMethodId, setPaymentMethodId] = useState<number | null>(null);
  const [stripeError, setStripeError] = useState<StripeError | null>(null);

  const translateStripeErrorMessage = (errorCode: StripeError["code"]) => {
    switch (errorCode) {
      case "insufficient_funds":
        return t("paywall.stripe_payment_error.insufficient_funds");
      case "lost_card":
        return t("paywall.stripe_payment_error.lost_card");
      case "stolen_card":
        return t("paywall.stripe_payment_error.stolen_card");
      case "expired_card":
        return t("paywall.stripe_payment_error.expired_card");
      case "card_declined":
        return t("paywall.stripe_payment_error.card_declined");
      case "card_velocity_exceeded":
        return t("paywall.stripe_payment_error.card_velocity_exceeded");
      default:
        return t("paywall.stripe_payment_error.default_error");
    }
  };

  return (
    <StripePaymentContext.Provider
      value={{
        paymentMethodId,
        setPaymentMethodId,
        stripeError,
        setStripeError,
        translateStripeErrorMessage,
      }}
    >
      {children}
    </StripePaymentContext.Provider>
  );
};
