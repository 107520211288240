/* eslint-disable comma-dangle */
import { t } from "lib/i18n";
import { RentalAdViewModel } from "../interfaces/RentalAdViewModel";
import { RentalAdsResponsePayload } from "../interfaces/RentalAdsResponsePayload";

export const createRentalAdViewModels = (
  response: RentalAdsResponsePayload,
  options: {
    onDisplayEnergyRatingRetriever: (id: number, address: string) => void;
  },
): RentalAdViewModel[] => {
  const result: RentalAdViewModel[] = [];

  response.rentables.forEach((item) => {
    const { ad, rentable } = item;

    result.push({
      ad_id: ad.id,
      rentable_id: rentable.id,
      reference_id: ad?.reference_id ?? "-",
      state: ad.state,
      rentable_address: rentable.formatted_address,
      displayed_advertised_date: ad?.displayed_advertised_date,
      ad_active_products_count: ad?.active_products ?? [],
      ad_view_count: ad?.view_count ?? 0,
      unseen_thread_count: ad
        ? {
            unseenThreadCount: ad.unseen_thread_count,
            totalThreadCount: ad.total_thread_count,
            inboxUrl: ad.inbox_url,
          }
        : null,
      energy_rating: {
        rating: rentable.energy_rating,
        retrieveEnergyRatingLabel: t(
          "rentable_list.energy_rating_cell.fetch_label",
        ),
        onClick: () =>
          options.onDisplayEnergyRatingRetriever(
            rentable.id,
            rentable.formatted_address,
          ),
      },
      street_address: rentable.street_address,
      postal_city: rentable.postal_city,
      srp_page_num: ad.srp_page_num,
      city_level_1: ad.city_level_1,
    });
  });

  return result;
};
