import { useFormContext, useWatch } from "react-hook-form";
import { Box, Button, Flex, styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm, RentableCreationCandidate } from "../../types";
import { FullWidthMobileContainer } from "../CommonStyled";
import { CreateRentableButton } from "../CreateRentableButton";

const Wrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: ${(props) => props.theme.unit(2)};
    background: ${(props) => props.theme.color.bg.base};
    border-top: 1px solid ${(props) => props.theme.color.border};
  }
`;

const ButtonWrapper = styled(Flex)`
  gap: 8px;
`;

type Props = {
  onClick: () => void;
  onContinue?: () => void;
  disabled: boolean;
};

export const BulkCreateButton = ({ onClick, onContinue, disabled }: Props) => {
  const {
    control,
    formState: { isSubmitting, isSubmitted },
  } = useFormContext<CreateRentableForm>();
  const rentables = useWatch({
    control,
    name: "rentables",
  });
  const isLoading = useWatch({
    control,
    name: "isLoading",
  });

  const rentablesToCreate = rentables.filter(
    (rentable) => rentable.shouldCreate && !rentable.disabled,
  );
  const rentablesNotYetCreated = rentablesToCreate.filter(
    (rentable) => !rentable.rentableId,
  );

  return (
    <Wrapper>
      <FullWidthMobileContainer>
        <Flex
          justify="space-between"
          align={{
            xs: "end",
            md: "center",
          }}
          column={{
            xs: true,
            md: false,
          }}
        >
          {rentablesToCreate.length > 1 ? (
            <Text>
              {t(
                "feature.address_form.submit_text.create_one_property_with_%{amount_of_rentables}",
                {
                  amount_of_rentables: rentablesToCreate.length,
                },
              )}
            </Text>
          ) : (
            <div />
          )}

          <ButtonWrapper
            justify="end"
            align="start"
            mt={{
              xs: 2,
              md: 0,
            }}
          >
            {shouldShowContinueButton(rentables, isSubmitted) && onContinue && (
              <Box>
                <Button onClick={onContinue}>
                  {t("feature.address_form.continue_button")}
                </Button>
              </Box>
            )}

            <CreateRentableButton
              isLoading={isSubmitting}
              onClick={onClick}
              type="button"
              disabled={disabled || isLoading}
            >
              {t(
                "feature.address_form.submit_button.create_%{amount}_rentables",
                {
                  amount: rentablesNotYetCreated.length,
                },
              )}
            </CreateRentableButton>
          </ButtonWrapper>
        </Flex>
      </FullWidthMobileContainer>
    </Wrapper>
  );
};

const shouldShowContinueButton = (
  rentables: RentableCreationCandidate[],
  isSubmitted: boolean,
) => {
  if (!isSubmitted) {
    return false;
  }

  const createdRentables = rentables.filter(
    (rentable) => !!rentable.rentableId,
  );
  if (createdRentables.length === 0) {
    return false;
  }

  const rentablesUnsuccessfullyCreated = rentables.filter(
    (rentable) => rentable.shouldCreate && !rentable.rentableId,
  );
  return rentablesUnsuccessfullyCreated.length > 0;
};
