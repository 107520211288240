import {
  Dropdown,
  DropdownMenuButton,
  DropdownMenuSeparator,
  Flex,
  Text,
  IconEllipsisVertical,
  IconFlag,
  styled,
  IconCloseCircle,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { t } from "lib/i18n";
import { commonButtonTrans } from "lib/translations";
import Floorplan from "media/svg/Floorplan";
import { SortableImage } from "../interfaces/SortableImage";
import {
  PutImageFirstFeature,
  RemoveImageFeature,
  MakeImageFloorPlanFeature,
  RemoveImageFloorPlanFeature,
} from "../types/SortableFeatures";

interface ImageOptionsMenuProps {
  image: SortableImage;
  removeImage: RemoveImageFeature;
  putImageFirst?: PutImageFirstFeature;
  makeImageFloorPlan?: MakeImageFloorPlanFeature;
  removeImageFloorPlan?: RemoveImageFloorPlanFeature;
}

export const ImageOptionsMenu = ({
  image,
  removeImage,
  putImageFirst,
  makeImageFloorPlan,
  removeImageFloorPlan,
}: ImageOptionsMenuProps) => {
  const { makePrimaryButton, makeFloorPlanButton, removeFloorPlanButton } =
    commonButtonTrans();

  const items = [
    <DropdownMenuButton
      key={`${image.id}removeImage`}
      onClick={() => {
        removeImage(image);
      }}
    >
      <Flex
        gap
        align="center"
      >
        <IconCloseCircle color="danger" />
        <Text>{t("Remove image")}</Text>
      </Flex>
    </DropdownMenuButton>,
  ];

  if (putImageFirst) {
    items.unshift(
      <DropdownMenuButton
        key={`${image.id}putImageFirst`}
        onClick={() => {
          putImageFirst(image);
        }}
      >
        <Flex
          gap
          align="center"
        >
          <IconFlag />
          <Text>{makePrimaryButton}</Text>
        </Flex>
      </DropdownMenuButton>,
      <DropdownMenuSeparator key={`${image.id}DropdownMenuSeparator1`} />,
    );
  }

  if (makeImageFloorPlan && removeImageFloorPlan) {
    items.unshift(
      <DropdownMenuButton
        key={`${image.id}makeImageFloorPlan`}
        onClick={() => {
          image.isFloorPlan
            ? removeImageFloorPlan(image)
            : makeImageFloorPlan(image);
        }}
      >
        <Flex
          gap
          align="center"
        >
          <Floorplan
            color="#000"
            width={16}
          />
          <Text>
            {image.isFloorPlan ? removeFloorPlanButton : makeFloorPlanButton}
          </Text>
        </Flex>
      </DropdownMenuButton>,
      <DropdownMenuSeparator key={`${image.id}DropdownMenuSeparator2`} />,
    );
  }

  return (
    <Dropdown
      opener={
        <StyledButton>
          <IconEllipsisVertical
            css={css`
              font-size: inherit;
              color: inherit;
              background-color: transparent;
              filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
            `}
          />
        </StyledButton>
      }
      items={items}
    />
  );
};

const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 32px;
  color: white;
  &:focus {
    outline: none;
    box-shadow: none;
    color: #eeeeee;
  }
`;
