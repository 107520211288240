import { ReactNode } from "react";

type Props = {
  leadsIconSlot: ReactNode;
  leadsTitle: string;
  leadsDescription: string;
  children: ReactNode;
  leadsNotice?: React.ReactNode;
};

export const TenantHubLeadsSection = ({
  children,
  leadsDescription,
  leadsIconSlot,
  leadsTitle,
  leadsNotice,
}: Props) => {
  return (
    <div
      className={
        "width: auto mb-5 border border-b border-solid border-gray-40 bg-white p-4 lg:rounded-sm lg:p-6"
      }
    >
      <div className="mb-2 flex flex-col">
        <div className="flex items-center">
          <div className="mr-1">{leadsIconSlot}</div>

          <div className="text-md font-bold">{leadsTitle}</div>
        </div>
      </div>
      <p className="m-0 mb-4 p-0 text-sm text-muted">{leadsDescription}</p>
      {leadsNotice}
      <div className="grid w-auto grid-cols-1 items-start gap-4 md:grid-cols-2 xl:grid-cols-3">
        {children}
      </div>
    </div>
  );
};
