import { useEffect, useState } from "react";
import { Plan } from "components/interfaces/plan";
import {
  PAYWALL_PAYMENT_METHOD_ID_QUERY_KEY,
  PAYWALL_PAYMENT_PLANS_QUERY_KEY,
  UNIQUE_PAYWALL_QUERY_KEY,
  removeBrowserQueryVariables,
} from "./helpers";

export type MobilePaySubscriptionCandidate = {
  plans: Plan[];
  paymentMethodId: number;
  listingId: number | null;
};

export const useMobilePaySubscriptionLauncher = (
  allPlans: Plan[],
  uniquePaywallKey: string,
) => {
  const [mobilePaySubscriptionCandidate, setMobilePaySubscriptionCandidate] =
    useState<MobilePaySubscriptionCandidate | null>(null);

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const searchParams = new URLSearchParams(location.search);

    const payment_plan_ids = searchParams.get(PAYWALL_PAYMENT_PLANS_QUERY_KEY);
    const payment_method_id = searchParams.get(
      PAYWALL_PAYMENT_METHOD_ID_QUERY_KEY,
    );
    const uniquePaywallKeyFromQueryParam = searchParams.get(
      UNIQUE_PAYWALL_QUERY_KEY,
    );

    if (
      payment_method_id &&
      payment_plan_ids &&
      uniquePaywallKeyFromQueryParam === uniquePaywallKey
    ) {
      const targetPlans = getPlansByIds(allPlans, payment_plan_ids);
      const candidate = {
        listingId: null,
        plans: targetPlans,
        paymentMethodId: Number(payment_method_id),
      };
      setMobilePaySubscriptionCandidate(candidate);

      removeBrowserQueryVariables([
        PAYWALL_PAYMENT_METHOD_ID_QUERY_KEY,
        PAYWALL_PAYMENT_PLANS_QUERY_KEY,
        UNIQUE_PAYWALL_QUERY_KEY,
        "ad_id",
        "rentable_id",
      ]);
    }
  }, [allPlans, uniquePaywallKey]);

  return {
    mobilePaySubscriptionCandidate,
    closeMobilePaySubscriptionDialog: () =>
      setMobilePaySubscriptionCandidate(null),
  };
};

const getPlansByIds = (plans: Plan[], planIds: string) => {
  const planIdsArray = planIds.split(",").map((planId) => Number(planId));
  return plans.filter((plan) => planIdsArray.includes(plan.plan_id));
};
