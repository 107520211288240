import { useContext } from "react";
import { useRentableListingQuery } from "api/queries";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { modalActions } from "components/modal_controller";
import { AccountInfo } from "lib/api";
import { dateHoursOld } from "lib/date";
import { UpsellPlansWithPaymentModal } from "./UpsellPlansWithPaymentModal";

type Props = {
  rentableId: number;
  listingId: number;
  accountInfo: AccountInfo | null;
  activeUpsellSubscriptions: AdSubscription[];
  plans: Record<string, Plan[]>;
  onSuccess: (plan?: Plan) => void;
};

export const ListingUpsellProducts = ({
  rentableId,
  listingId,
  plans,
  onSuccess,
  accountInfo,
  activeUpsellSubscriptions,
}: Props) => {
  const modalActionsInstance = useContext(modalActions);
  const { data } = useRentableListingQuery(rentableId);
  const onSuccessHandler = (plan?: Plan) => {
    modalActionsInstance.closeModal();
    onSuccess(plan);
  };
  let isAdLessThan24HoursOld = false;
  if (data && data.listingData) {
    const advertisedDate = data.listingData.listing.advertised_date;
    const numHoursOld = dateHoursOld(new Date(advertisedDate));
    isAdLessThan24HoursOld = numHoursOld < 24 || !advertisedDate;
  }

  return (
    <UpsellPlansWithPaymentModal
      isAdLessThan24HoursOld={isAdLessThan24HoursOld}
      rentableId={rentableId}
      modalActions={modalActionsInstance}
      accountInfo={accountInfo}
      activeUpsellSubscriptions={activeUpsellSubscriptions}
      plans={plans}
      listingId={listingId}
      onSuccess={onSuccessHandler}
    />
  );
};
