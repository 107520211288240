import { useEffect } from "react";
import { AttachmentUploadConfig } from "apps/contracts/components/common/interfaces";
import { da, enGB, sv } from "date-fns/locale";
import { Location } from "history";
import { App } from "../components/app";
import { Ad } from "../components/interfaces/ad";
import { Market } from "../components/markets/market_settings";
import { AdCategories } from "./enums";
import { t } from "./i18n";

const adDetailsFloor = (
  floor: string | null,
  category: string,
  showFloor?: boolean | false,
) => {
  if (floor === null) {
    return null;
  }

  const nFloor = Number(floor);
  const SetSwedishOrdinal = (number: number) => {
    const valueA = Math.abs(number) % 10;
    const valueB = Math.abs(number) % 100;
    if ((valueA === 1 || valueA === 2) && valueB !== 11 && valueB !== 12) {
      return ":a";
    }
    return ":e";
  };

  // do not display floor number if property type is a house
  if (category === AdCategories.HOUSE) {
    return null;
  }

  if (nFloor === -1) {
    return t("floor.basement");
  } else if (nFloor === 0 || nFloor === null) {
    return t("floor.ground_floor");
  } else {
    return getLocale() === "sv-SE"
      ? `${nFloor}${SetSwedishOrdinal(nFloor)} ${showFloor ? t("floor") : ""}`
      : `${formatOrdinal(nFloor)} ${showFloor ? t("floor") : ""}`;
  }
};

const prettifiedAddress = (ad: Ad) =>
  `${ad.street_name}, ${ad.postal_code ? `${ad.postal_code}` : ""} ${ad.city}${
    ad.city_area !== null ? `, ${ad.city_area}` : ""
  } ${
    adDetailsFloor(ad.floor, ad.category, true) !== null
      ? ` - ${adDetailsFloor(ad.floor, ad.category, true)}`
      : ""
  }`;

const getLocale = () => App.settings.language + "-" + App.settings.country_code;

const getDateFnsLocale = () => {
  switch (App.settings.language) {
    case "sv":
      return sv;
    case "da":
      return da;
    case "en":
      return enGB;
    default:
      return da;
  }
};

const getLanguagePrefix = () => {
  const { language } = App.settings;

  let prefix = "";

  if (language === "en") {
    prefix = "en";
  }

  return prefix;
};

const getApplePayLocale = () => {
  switch (App.settings.language) {
    case "sv":
      return "sv-SE";
    case "da":
      return "da-DK";
    case "en":
      return "en-GB";
    default:
      return "da-DK";
  }
};

const stripHtml = (input: string) => input.replace(/<\/?[^>]+(>|$)/g, "");

const calculatePrice = (price: number, percent_off: number) =>
  price - (price * percent_off) / 100;

const formatCurrency = (
  number: number,
  currency: string,
  formatOptions = {
    round: false,
  },
) => {
  if (number === null || isNaN(Number(number))) {
    return "";
  }

  const numericValue = Number(number);

  const extraOptions = formatOptions.round
    ? {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    : {};
  const options = {
    style: "currency",
    currency: currency,
    ...extraOptions,
  };
  return numericValue.toLocaleString(getLocale(), options);
};

/**
 * formatNumber and formatCurrency Function - Defensive Programming Approach:
 *
 * This function includes runtime checks for null and isNaN, even though the 'number'
 * parameter is strongly typed. This defensive approach is adopted to handle potential
 * type inconsistencies from external data sources, like backend services, where data
 * might not strictly adhere to expected types.
 *
 * Rationale:
 * - Ensures robustness against runtime errors from unexpected input types, enhancing system stability.
 * - Acts as a safeguard in a dynamic environment where static type guarantees by TypeScript
 *   can't be solely relied upon.
 *
 * Note: This is an interim solution. Ideally, data validation should be enforced at the data source
 * and throughout the application's data flow to maintain type integrity.
 */

const formatNumber = (
  number: number,
  formatOptions = {
    round: false,
  },
) => {
  if (number === null || isNaN(Number(number))) {
    return "";
  }

  const numericValue = Number(number);

  const extraOptions = formatOptions.round
    ? {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    : {};
  const options = {
    ...extraOptions,
  };

  return numericValue.toLocaleString(getLocale(), options);
};

const formatOrdinal = (number: number): string =>
  getDateFnsLocale().localize?.ordinalNumber(number);

const isBoligPortal = (): boolean =>
  App.settings.market === Market.BOLIGPORTAL_DK;

const parseFullName = (
  fullName: string,
): { firstName: string; lastName: string } => {
  const elements = fullName.replace(/\s\s+/g, " ").trim().split(" ");

  if (elements.length > 1) {
    return {
      lastName: elements.pop()!,
      firstName: elements.join(" "),
    };
  } else {
    throw new Error("Full name must have at least two words");
  }
};

const formatFullName = (firstName: string, lastName: string): string =>
  `${firstName} ${lastName}`;

const formatNumberWithoutCurrency = (number: number): string =>
  number.toLocaleString(getLocale(), {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

const userFullName = (firstName?: string, lastName?: string) =>
  `${firstName || ""} ${lastName || ""}`.trim();

const isValidCpr = (cpr: string | undefined): boolean => {
  // Check for repeated patterns
  const repeatedPatterns = /(\d)\1{9}/;
  const alternatingPatterns = /(\d\d)\1{4}/;

  if (!cpr) {
    return false;
  }

  const datePart = cpr.slice(0, 6);
  const codePart = cpr.slice(6);

  if (!datePart) {
    return false;
  }

  if (!codePart || codePart.length !== 4 || codePart === "0000") {
    return false;
  }

  const day = parseInt(datePart.slice(0, 2));
  const month = parseInt(datePart.slice(2, 4));

  if (!(day >= 1 && day <= 31)) {
    return false;
  }

  if (!(month >= 1 && month <= 12)) {
    return false;
  }

  if (repeatedPatterns.test(cpr) || alternatingPatterns.test(cpr)) {
    return false;
  }

  return true;
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const kv = cookie.split("=");
    if (kv.length !== 2) {
      continue;
    }
    const key = kv[0].trim();
    const value = kv[1].trim();
    if (key === name) {
      return value;
    }
  }
  return null;
};

const setCookie = (
  name: string,
  value: string,
  days: number = 6 * 30 * 24 * 60 * 60,
) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const deleteCookie = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

const getQueryVariable = (
  variable: string,
  location?: Location | undefined,
): string | undefined => {
  let query: string;
  if (location) {
    query = location.search.substring(1);
  } else {
    query = window.location.search.substring(1);
  }
  const vars = query.split("&");
  for (let index = 0; index < vars.length; index++) {
    const pair = vars[index].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return undefined;
};

const useSetDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const uploadAttachment = (file: File, conf: AttachmentUploadConfig) => {
  const filename = file.name;
  const body = new FormData();

  Object.keys(conf.fields).forEach((key) => {
    if (key === "key") {
      body.append(
        "key",
        conf.fields.key
          .replace("${filename}", filename)
          .replace(/[^\w\/.-]/g, ""), // eslint-disable-line no-useless-escape
      );
    } else {
      body.append(key, conf.fields[key]);
    }
  });

  body.append("file", file);

  return new Promise<string | null>((resolve) => {
    fetch(conf.url, {
      method: "POST",
      body: body,
      headers: {},
    }).then((response) => {
      resolve(response.headers.get("location"));
    });
  });
};

const getAdIdFromUrl = (url: string) => {
  const [id] = url.split("-").slice(-1);

  // Check if the string is empty or not a valid integer
  if (/^\d+$/.test(id)) {
    return parseInt(id, 10);
  }

  return undefined;
};

export {
  setCookie,
  getCookie,
  deleteCookie,
  isValidCpr,
  userFullName,
  formatNumberWithoutCurrency,
  formatFullName,
  parseFullName,
  isBoligPortal,
  formatCurrency,
  formatNumber,
  calculatePrice,
  prettifiedAddress,
  stripHtml,
  adDetailsFloor,
  getLocale,
  getLanguagePrefix,
  getQueryVariable,
  useSetDocumentTitle,
  uploadAttachment,
  getAdIdFromUrl,
  getApplePayLocale,
};
