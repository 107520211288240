import * as React from "react";
import { Box, styled } from "@boligportal/juice";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { Filters } from "components/interfaces/filters";
import { MapResults } from "components/interfaces/map_results";
import { Maps, StyledMapsPageWrapper } from "components/maps/maps";
import { FeatureCollection, GeoJsonProperties } from "geojson";
import { t } from "lib/i18n";
import { LngLatBounds } from "mapbox-gl";
import {
  Action_mapAdsSelected,
  Action_mapPositionChange,
} from "../../search_result_reducer";

interface MapContainerProps {
  results: MapResults;
  selectedAds: Array<number>;
  mapCenter?: [number, number];
  mapZoom?: number;
  filters: Filters;
  slotForFilters: React.ReactNode;
  dispatch: React.Dispatch<Action_mapPositionChange | Action_mapAdsSelected>;
  isFetchingData: boolean;
}

const getBoundsFromResults = (results: FeatureCollection): LngLatBounds => {
  const bounds: LngLatBounds = new LngLatBounds();
  if (results && results.features) {
    results.features.forEach((feature) => {
      const geometry = feature.geometry as GeoJsonProperties;
      if (geometry && geometry.coordinates) {
        bounds.extend(geometry.coordinates);
      }
    });
  }
  return bounds;
};

const MapContainer = ({
  dispatch,
  filters,
  isFetchingData,
  mapZoom,
  results,
  selectedAds,
  slotForFilters,
  mapCenter,
}: MapContainerProps) => {
  const { countryBoundingBox } = useMarketSettings();
  const [fitToBounds, setFitToBounds] = React.useState(() => {
    const { min_lat, max_lat, min_lng, max_lng } = filters;

    if (
      min_lat &&
      typeof min_lat === "number" &&
      max_lat &&
      typeof max_lat === "number" &&
      min_lng &&
      typeof min_lng === "number" &&
      max_lng &&
      typeof max_lng === "number"
    ) {
      return new LngLatBounds(
        {
          lat: min_lat,
          lng: min_lng,
        },
        {
          lat: max_lat,
          lng: max_lng,
        },
      );
    }

    // Fit to country bounds initially
    if (!mapCenter && !mapZoom) {
      return new LngLatBounds(countryBoundingBox.sw, countryBoundingBox.ne);
    } else {
      return undefined;
    }
  });

  // Fit bounds to results if no coordinates are defined
  React.useEffect(() => {
    if (
      filters.min_lat ||
      filters.min_lng ||
      filters.max_lat ||
      filters.max_lng
    ) {
      return;
    }
    if (!mapCenter && !mapZoom) {
      if (results && results.features.length > 0) {
        setFitToBounds(getBoundsFromResults(results));
      }
    }
  }, [results, mapCenter, mapZoom, filters]);

  const handleMapMove = (
    center: [number, number],
    zoom: number,
    bounds: LngLatBounds,
  ) => {
    dispatch({
      type: "mapPositionChange",
      payload: {
        mapCenter: center,
        mapZoom: zoom,
        bounds: {
          min_lat: bounds.getSouth(),
          max_lng: bounds.getEast(),
          max_lat: bounds.getNorth(),
          min_lng: bounds.getWest(),
        },
      },
    });
  };

  const handleAdsSelected = (ads: Array<number>) => {
    dispatch({
      type: "mapAdsSelected",
      payload: ads,
    });
  };

  return (
    <>
      <StyledMapsPageWrapper>
        <Maps
          fitToBounds={fitToBounds}
          results={results}
          zoom={mapZoom}
          center={mapCenter}
          selectedAds={selectedAds}
          onAdsSelected={handleAdsSelected}
          onMapMove={handleMapMove}
        />

        <MapFilterAbsoluteBox
          bg="base"
          hidden={{
            xs: true,
            lg: false,
          }}
        >
          {slotForFilters}
        </MapFilterAbsoluteBox>
        {isFetchingData && (
          <LoadingMapDataDisplay>{t("srp.map.loading")}</LoadingMapDataDisplay>
        )}
      </StyledMapsPageWrapper>
    </>
  );
};

const MapFilterAbsoluteBox = styled(Box)`
  position: absolute;
  width: $filterSidebarWidth;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
`;

const LoadingMapDataDisplay = styled.div`
  position: absolute !important;
  font-size: 10px;
  color: #999999;
  left: 50%;
  margin: 15px;
  padding: 3px 10px;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 14px;
`;

export { MapContainer };
