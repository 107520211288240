import * as React from "react";
import { Control, Controller, FieldPath } from "react-hook-form";
// eslint-disable-next-line import/order
import { PhoneField } from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";

export const ControlledPhoneNumberInput = <T extends object>({
  control,
  name,
  ...phoneFieldProps
}: {
  control: Control<T>;
  name: FieldPath<T>;
} & Omit<
  React.ComponentProps<typeof PhoneField>,
  "value" | "onChange" | "country"
>) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: phoneFieldProps.required,
    }}
    render={({ field: { value, onChange } }) => (
      <PhoneField
        country={App.settings.market === Market.BOLIGPORTAL_DK ? "dk" : "se"}
        value={value as string}
        onChange={onChange}
        placeholder=""
        {...phoneFieldProps}
      />
    )}
  />
);
