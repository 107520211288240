import { useNotifications, Box, Badge, Text } from "@boligportal/juice";
import { NotificationOptions } from "@boligportal/juice/dist/components/Notifications/interfaces/NotificationOptions";
import { t } from "lib/i18n";

export const useErrorNotification = () => {
  const { addNotification } = useNotifications();

  const addErrorNotification = (
    errorMessage: string,
    options: Omit<NotificationOptions, "content" | "title"> = {},
  ) => {
    if (addNotification) {
      addNotification({
        title: <Badge state="danger">{t("moving_reports.error")}</Badge>,
        content: (
          <Box mt={1}>
            <Text color="inverted">{errorMessage}</Text>
          </Box>
        ),
        ...options,
      });
    }
  };

  const addResponseErrorNotification = (errors?: {
    [key: string]: string[];
  }) => {
    if (errors) {
      const keys = Object.keys(errors);

      const messages = keys.map((key) => `${key}: ${errors[key].join(" | ")}`);

      const messagesJoined = messages.join(", ");

      addErrorNotification(messagesJoined);
    }
  };

  const addBasicResponseErrorNotification = (errors?: {
    [key: string]: string[];
  }) => {
    if (errors) {
      const keys = Object.keys(errors);

      const messages = keys.map((key) => errors[key].join(" | "));

      const messagesJoined = messages.join(", ");

      addErrorNotification(messagesJoined);
    }
  };

  return {
    addErrorNotification,
    addResponseErrorNotification,
    addBasicResponseErrorNotification,
  };
};
