import { useState } from "react";
import {
  Card,
  CardSection,
  Text,
  TextField,
  useNotifications,
} from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { useMutationEditNewBuildSEO } from "lib/customerServiceMutations";

type Props = {
  newBuildId: number;
  newBuild: Ad;
};

export const NewBuildProjectSEO = ({ newBuildId, newBuild }: Props) => {
  const { addNotification } = useNotifications();
  const mutation = useMutationEditNewBuildSEO();

  const { newbuild_details } = newBuild;

  const [metaTitle, setMetaTitle] = useState(
    newbuild_details?.meta_title || "",
  );
  const [metaDescription, setMetaDescription] = useState(
    newbuild_details?.meta_description || "",
  );

  if (!newBuild) {
    return null;
  }

  const displaySaveNotification = () => {
    addNotification({
      title: "Saving data...",
      autoDismiss: true,
      autoDismissTimeout: 1000,
    });
  };

  const handleSEOUpdater = async () => {
    await mutation.mutateAsync({
      newbuildId: newBuildId,
      payload: {
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    });

    displaySaveNotification();
  };

  return (
    <Card sectioned>
      <CardSection>
        <Text
          size="lead"
          weight="bold"
        >
          SEO
        </Text>
      </CardSection>
      <CardSection>
        <TextField
          label="Meta title"
          value={metaTitle}
          onBlur={handleSEOUpdater}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <TextField
          label="Meta description"
          value={metaDescription}
          onBlur={handleSEOUpdater}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
      </CardSection>
    </Card>
  );
};
