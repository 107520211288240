import { Box, Button, Flex } from "@boligportal/juice";
import { Company } from "./interfaces";

export const IframeWidget = ({
  company,
  updateField,
}: {
  company: Company;
  updateField: (fieldname: string, value: any) => void;
}) => (
  <>
    <Box key={company.id}>
      <Flex gap={1}>
        <Button
          onClick={() => {
            updateField("iframe_allowed", false);
          }}
        >
          Disable
        </Button>
      </Flex>
    </Box>
  </>
);
