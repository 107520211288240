import { Flex, Text } from "@boligportal/juice";
import { TenancyPaymentArrears } from "apps/property_owner/my_rentables/views/rentable_detail/views/tenancies/views/tenancy/views/rent/components/TenancyPaymentArrears";
import {
  OneTimePaymentLabel,
  RentRegulationLabel,
} from "apps/property_owner/my_rentables/views/rentable_detail/views/tenancies/views/tenancy/views/rent/components/TenancyRentTableCellRenders";
import { TenancyPaymentTransaction } from "business/domain/tenancy/TenancyPaymentTransaction";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { formatCurrency } from "lib/utils";

export type RentAmountCell = {
  rentAmountCell: {
    currency: string;
    amount: number;
    regulationItems: TenancyRentEntity[];
    oneTimePaymentItems: TenancyRentEntity[];
    transactions: TenancyPaymentTransaction[];
    tenancyId: number;
    paymentRecordId: number;
    paymentDueDate: string;
    paymentType: TenancyPaymentType;
  };
};

export const rentAmountCellRenderer = ({ rentAmountCell }: RentAmountCell) => {
  const {
    amount,
    currency,
    regulationItems,
    oneTimePaymentItems,
    transactions,
    tenancyId,
    paymentRecordId,
    paymentDueDate,
    paymentType,
  } = rentAmountCell;

  if (amount === 0) {
    return <Text>-</Text>;
  }

  return (
    <Flex
      column
      align="end"
    >
      <Text size="small">{formatCurrency(amount, currency)}</Text>
      <TenancyPaymentArrears
        currency={currency}
        rent={amount}
        transactions={transactions}
        tenancyId={tenancyId}
        paymentRecordId={paymentRecordId}
        paymentDueDate={paymentDueDate}
        paymentType={paymentType}
      />
      <Flex
        column
        align="end"
      >
        {regulationItems.length > 0 && (
          <RentRegulationLabel items={regulationItems} />
        )}
        {oneTimePaymentItems.length > 0 && (
          <OneTimePaymentLabel items={oneTimePaymentItems} />
        )}
      </Flex>
    </Flex>
  );
};
