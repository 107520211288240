import { StartSubscriptionTrackable } from "features/paywall/coordinators/StartSubscriptionTrackable";
import { Tracking } from "lib/tracking/common";
import { TrackingEvent } from "lib/tracking/events";
import { FacebookTrackingEvent } from "lib/tracking/facebook";

const trackVirtualPageview = (path: string, title: string) => {
  Tracking.trackVirtualPageview(path, title);
};

const trackPaymentSuccessfullyCompleted = (productName: string) => {
  TrackingEvent.paymentSuccessfullyCompleted(productName);
};

const trackFacebookPurchase = (
  total: number,
  currency: string,
  adId: number | null,
  productName: string,
) => {
  if (adId !== null && productName === "seeker_subscription") {
    FacebookTrackingEvent.trackPurchase(total, currency, adId);
  }
};

const createStartSubscriptionTracking = (
  customTrackingForSE?: Partial<StartSubscriptionTrackable>,
): StartSubscriptionTrackable => ({
  subscriptionProcessing: (title) => {
    trackVirtualPageview("/payment/processing", title);
  },
  subscriptionCreated: (title, plan, adId) => {
    trackVirtualPageview("/payment/success", title);
    trackPaymentSuccessfullyCompleted(plan.product.name);
    trackFacebookPurchase(
      plan.price.total,
      plan.currency,
      adId,
      plan.product.name,
    );
    customTrackingForSE?.subscriptionCreated?.(title, plan, adId);
  },
  subscriptionFailed: (title) => {
    trackVirtualPageview("/payment/failure", title);
  },
});

const customTrackingForSE: Partial<StartSubscriptionTrackable> = {
  subscriptionCreated: (_, plan, adId) => {
    if (adId !== null && plan.product.name === "seeker_subscription") {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "seeker_subscription_purchased",
      });
    }
  },
};

export const startSubscriptionTrackingDK = createStartSubscriptionTracking();
export const startSubscriptionTrackingSE =
  createStartSubscriptionTracking(customTrackingForSE);
