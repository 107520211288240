import * as React from "react";
import { App } from "./app";
import { Market } from "./markets/market_settings";

type Resolution = "1x" | "2x" | "3x";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  /** Increases the dimensions of the actual rendered image, which makes images look crisp on retina displays. */
  resolution?: Resolution;
};

const getSizeMultipliedByResolution = (
  size: number,
  resolution: Resolution,
) => {
  switch (resolution) {
    case "1x":
      return size;
    case "2x":
      return size * 2;
    case "3x":
      return size * 3;
  }
};

export const CdnImage = ({
  src,
  width,
  height,
  resolution = "1x",
  ...props
}: Props) => {
  if (process.env.NODE_ENV === "production" && App.settings.env !== "staging") {
    if (App.settings.market === Market.BOLIGPORTAL_DK) {
      src = src.replace("/static/images", "https://images.boligportal.dk");
    } else if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      src = src.replace(
        "/static/images",
        "https://static-images.bostadsportal.se",
      );
    }

    const params = new URLSearchParams();
    params.append("fm", "webp");
    params.append("auto", "compress");

    if (typeof width === "number") {
      params.append(
        "w",
        getSizeMultipliedByResolution(width, resolution).toString(),
      );
    }

    if (typeof height === "number") {
      params.append(
        "h",
        getSizeMultipliedByResolution(height, resolution).toString(),
      );
    }

    src = src + "?" + params.toString();
  }

  return (
    <img
      src={src}
      width={width}
      height={height}
      {...props}
    />
  );
};
