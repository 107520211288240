import {
  MovingReportListItem,
  MovingReportsFilters,
} from "../store/slices/movingReports";
import { ReportFilterStateEnum } from "../views/reports_list/enums/ReportFilterStateEnum";

export const createInitialMovingReportsFilters = (): MovingReportsFilters => {
  // eslint-disable-next-line compat/compat
  const searchParams = new URLSearchParams(location.search);

  const defaultFilters: MovingReportsFilters = {
    search: "",
    selectedStateFilter: ReportFilterStateEnum.ALL,
    pagination: {
      filterCount: 0,
      limit: 50,
      startIndex: 0,
    },
    activeSortableColumn: {
      column: "state",
      descending: true,
    },
  };

  const querySearchTerm = searchParams.get("search_term");
  const queryState = searchParams.get("filter");
  const querySorting = searchParams.get("sorting");
  const queryDescending = searchParams.get("descending");
  const queryLimit = searchParams.get("limit");
  const queryStartIndex = searchParams.get("start_index");

  if (querySearchTerm) {
    defaultFilters.search = querySearchTerm;
  }

  if (queryState) {
    defaultFilters.selectedStateFilter = queryState as ReportFilterStateEnum;
  }

  if (querySorting && queryDescending) {
    defaultFilters.activeSortableColumn = {
      column: querySorting as keyof MovingReportListItem,
      descending: queryDescending === "true",
    };
  }

  if (queryLimit) {
    defaultFilters.pagination.limit = parseInt(queryLimit, 10);
  }

  if (queryStartIndex) {
    defaultFilters.pagination.startIndex = parseInt(queryStartIndex, 10);
  }

  return defaultFilters;
};
