/* eslint-disable comma-dangle */
import { useReducer } from "react";
import { uploadImage } from "lib/customerServiceApi";
import { SortableImage } from "../interfaces/SortableImage";
import { SortableImagesFeature } from "../interfaces/SortableImagesFeature";
import { dndKitSortableReducer } from "../reducers/dndKitSortableReducer";
import { SortableReducerAction } from "../types/SortableReducerAction";
import { SortableReducerState } from "../types/SortableReducerState";
import { createRotatedImageBlob } from "../utils/createRotatedImageBlob";

export const useSortableImages = (
  initialImages: SortableImage[],
  reducer: (
    state: SortableReducerState,
    action: SortableReducerAction,
  ) => SortableReducerState = dndKitSortableReducer,
): SortableImagesFeature => {
  const [state, dispatch] = useReducer(reducer, {
    images: initialImages,
    activeImage: null,
  });

  const addImage = (image: SortableImage) => {
    dispatch({
      type: "ADD_IMAGE",
      image,
    });
  };

  const addImages = (images: SortableImage[]) => {
    dispatch({
      type: "UPDATE_IMAGES",
      images,
    });
  };

  const removeImage = (image: SortableImage) => {
    dispatch({
      type: "REMOVE_IMAGE",
      image,
    });
  };

  const rotateImage = async (image: SortableImage) => {
    try {
      const file = await createRotatedImageBlob(image.url);
      const uploadImageResponse = await uploadImage(file);
      dispatch({
        type: "UPDATE_IMAGE",
        oldImage: image,
        newImage: {
          id: uploadImageResponse.image_id.toString(),
          url: uploadImageResponse.url,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const putImageFirst = (image: SortableImage) => {
    dispatch({
      type: "PUT_IMAGE_FIRST",
      image,
    });
  };

  const setActiveImageFromId = (imageId: string) => {
    dispatch({
      type: "ACTIVE_IMAGE_ID",
      imageId,
    });
  };

  const makeImageFloorPlan = (image: SortableImage) => {
    dispatch({
      type: "FLOOR_PLAN_IMAGE_ID",
      imageId: image.id,
    });
  };

  const removeImageFloorPlan = (image: SortableImage) => {
    dispatch({
      type: "REMOVE_FLOOR_PLAN_IMAGE_ID",
      imageId: image.id,
    });
  };

  const clearActiveImage = () => {
    dispatch({
      type: "ACTIVE_IMAGE_ID",
      imageId: null,
    });
  };

  const clearFloorPlanImage = () => {
    dispatch({
      type: "FLOOR_PLAN_IMAGE_ID",
      imageId: null,
    });
  };

  const moveImages = (movingImageId: string, replacingImageId: string) => {
    dispatch({
      type: "MOVE_IMAGES",
      movingImageId,
      replacingImageId,
    });
  };

  const moveImageForward = (image: SortableImage) => {
    dispatch({
      type: "MOVE_IMAGE_FORWARD",
      image,
    });
  };

  const moveImageBackward = (image: SortableImage) => {
    dispatch({
      type: "MOVE_IMAGE_BACKWARD",
      image,
    });
  };

  return {
    images: state.images,
    activeImage: state.activeImage,
    addImage,
    addImages,
    moveImages,
    removeImage,
    rotateImage,
    moveImageForward,
    moveImageBackward,
    putImageFirst,
    setActiveImageFromId,
    makeImageFloorPlan,
    removeImageFloorPlan,
    clearActiveImage,
    clearFloorPlanImage,
  };
};
