import { useState, useCallback, useEffect } from "react";

// list of current elements here: http://www.w3.org/TR/html5/index.html#elements-1
type TagNames = "input" | "textarea";

export function useKey(key: string, ignoreList: TagNames[] = []) {
  // Keep track of key state
  const [pressed, setPressed] = useState(false);

  // Does an event match the key we're watching?
  const match = useCallback(
    (event) => key.toLowerCase() == event.key.toLowerCase(),
    [key],
  );

  // Event handlers
  const onDown = useCallback(
    (event) => {
      const tagName = event.target.tagName.toLowerCase();

      if (ignoreList.includes(tagName)) {
        return;
      }

      if (match(event)) {
        setPressed(true);
      }
    },
    [match, ignoreList],
  );

  const onUp = useCallback(
    (event) => {
      if (match(event)) {
        setPressed(false);
      }
    },
    [match],
  );

  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown);
    window.addEventListener("keyup", onUp);
    return () => {
      window.removeEventListener("keydown", onDown);
      window.removeEventListener("keyup", onUp);
    };
  }, [onDown, onUp]);

  return pressed;
}
