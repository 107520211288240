import * as React from "react";
import { useContractDeletionMutation } from "api/mutations";
import { SigningStatus } from "apps/contracts/components/common/interfaces";
import { ConfirmDialog } from "./ConfirmDialog";
import { InfoDialog } from "./InfoDialog";

type DialogProps = {
  apiPrefix: APIPrefix;
};

const ContractDeletionDialog = ({ apiPrefix }: DialogProps) => {
  const [activeModal, setActiveModal] = React.useState<
    "DELETE_INFO_DIALOG" | "DELETE_CONFIRM_DIALOG"
  >("DELETE_INFO_DIALOG");
  const { candidate, onClose } = React.useContext(ContractDeletionContext);
  const { deleteContractMutation } = useContractDeletionMutation(apiPrefix);

  const closeAndResetActiveModal = () => {
    onClose();
    setActiveModal("DELETE_INFO_DIALOG");
  };

  const deleteContractAndResetActiveModal = () => {
    if (!candidate) {
      return;
    }

    deleteContractMutation
      .mutateAsync({
        contractId: candidate.contractId,
      })
      .then((isSuccess) => {
        if (isSuccess && candidate?.onDeleteSuccess) {
          candidate.onDeleteSuccess();
        }
      })
      .finally(closeAndResetActiveModal);
  };

  if (!candidate) {
    return null;
  }

  return (
    <>
      {activeModal === "DELETE_INFO_DIALOG" && (
        <InfoDialog
          contractId={candidate.contractId}
          onClose={closeAndResetActiveModal}
          onSubmit={() => setActiveModal("DELETE_CONFIRM_DIALOG")}
          status={candidate.status}
        />
      )}

      {activeModal === "DELETE_CONFIRM_DIALOG" && (
        <ConfirmDialog
          onClose={closeAndResetActiveModal}
          onSubmit={deleteContractAndResetActiveModal}
          status={candidate.status}
          errorMessage={
            deleteContractMutation.error?.response.data.error.message
          }
        />
      )}
    </>
  );
};

// ==========================================================================================

type ContractDeletionCandidate = {
  contractId: number;
  status: SigningStatus;
  onDeleteSuccess?: () => void;
};

type ContractDeletionContextValue = {
  candidate: ContractDeletionCandidate | null;
  setContractDeletionCandidate: (
    candidate: ContractDeletionCandidate | null,
  ) => void;
  onClose: () => void;
};

const ContractDeletionContext =
  React.createContext<ContractDeletionContextValue>(null!);

type APIPrefix = "/contracts/contract" | "/dk_contracts/migrated_contract";

type ProviderProps = {
  children: React.ReactNode;
  apiPrefix: APIPrefix;
};

export const ContractDeletionProvider = ({
  children,
  apiPrefix,
}: ProviderProps) => {
  const [contractDeletionCandidate, setContractDeletionCandidate] =
    React.useState<ContractDeletionCandidate | null>(null);
  return (
    <ContractDeletionContext.Provider
      value={{
        candidate: contractDeletionCandidate,
        setContractDeletionCandidate,
        onClose: () => setContractDeletionCandidate(null),
      }}
    >
      {children}
      <ContractDeletionDialog apiPrefix={apiPrefix} />
    </ContractDeletionContext.Provider>
  );
};

// ==========================================================================================

export const useContractDeletion = () => {
  const context = React.useContext(ContractDeletionContext);

  if (!context) {
    throw "useContractDeletion hook can only be used inside function component as a Child of ContractDeletionProvider";
  }

  const { setContractDeletionCandidate } = context;

  return {
    setContractDeletionCandidate,
  };
};
