import { useState } from "react";
import { Confirm } from "@boligportal/juice";
import { t } from "lib/i18n";

export const MarkAsReserved = ({
  open,
  onConfirm,
  onClose,
}: {
  open: boolean;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    setLoading(true);
    onConfirm().finally(() => setLoading(false));
  };

  return (
    <Confirm
      loading={loading}
      open={open}
      size="large"
      onConfirm={confirm}
      onCancel={onClose}
      title={t("rentable_details.editor.mark_rentable_reserved")}
      message={
        <div>
          <div>
            {t("rentable_details.editor.mark_rentable_reserved_question")}
          </div>
          <br />
          <ul>
            <li>
              {t("rentable_details.editor.mark_rentable_reserved_explained_1")}
            </li>
            <li>
              {t("rentable_details.editor.mark_rentable_reserved_explained_2")}
            </li>
          </ul>
        </div>
      }
      confirmText={t("rentable_details.editor.yes_mark_reserved")}
      cancelText={t("rentable_details.editor.no_cancel")}
    />
  );
};
