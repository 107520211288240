import * as React from "react";
import { useForm } from "react-hook-form";
import { vestResolver } from "@hookform/resolvers/vest";
import { RentableId } from "business/domain/Rentable";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import { TenancyTenant } from "business/domain/tenancy/TenancyTenant";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import { BackendValidationError } from "errors/backend_validation_error/BackendValidationError";
import { TenancyFormSlideOver } from "features/rentable_tenancy/components/TenancyFormSlideOver";
import { tenancyFormValidationSuite } from "features/rentable_tenancy/helpers/tenancyFormValidationSchema";
import { TenancyFormData } from "features/rentable_tenancy/types/TenancyFormData";
import { fetchFromAPI } from "lib/api";
import { t } from "lib/i18n";
import { ignorePhoneWithOnlyLanguageCodes } from "./helpers/ignorePhoneWithOnlyLanguageCodes";

type TenancyCreationCandidate = {
  rentableId: number;
  moveInDate?: Tenancy["move_in_date"];
  tenants: Partial<TenancyTenant>[];
  onCreateSuccess?: (tenancy: Tenancy) => void;
};

type TenancyCreationContextValue = {
  open: boolean;
  candidate: TenancyCreationCandidate | null;
  setTenancyCreationCandidate: (
    candidate: TenancyCreationCandidate | null,
  ) => void;
  onCancel: () => void;
};

const TenancyCreationContext = React.createContext<TenancyCreationContextValue>(
  null!,
);

const TenancyCreationSlideOver = () => {
  const { candidate, onCancel } = React.useContext(TenancyCreationContext);

  if (!candidate) {
    return null;
  }

  return (
    <FormWithCandidate
      candidate={candidate}
      onCancel={onCancel}
    />
  );
};

const FormWithCandidate = ({
  candidate,
  onCancel,
}: {
  candidate: TenancyCreationCandidate;
  onCancel: () => void;
}) => {
  const { moveInDate, rentableId, tenants, onCreateSuccess } = candidate;

  const form = useForm<TenancyFormData>({
    defaultValues: {
      company_cvr: "",
      company_name: "",
      tenancy_type: "private",
      tenants: tenants,
      move_in_date: moveInDate === null ? "" : moveInDate,
      move_out_date: "",
    },
    resolver: vestResolver(tenancyFormValidationSuite),
    mode: "onChange",
  });

  const tryCreateTenancy = async (formData: TenancyFormData) => {
    try {
      const payloadData = ignorePhoneWithOnlyLanguageCodes(formData);
      const tenancy = await createTenancy(rentableId, payloadData);
      onCreateSuccess?.(tenancy);
    } catch (error) {
      if (error instanceof BackendValidationError) {
        const backendValidationErrors =
          error.getFieldAndMessageErrors<TenancyFormData>();
        backendValidationErrors.forEach((error) => {
          form.setError(error.fieldName, {
            message: error.message,
          });
        });
      }
    }
  };

  return (
    <TenancyFormSlideOver
      show
      form={form}
      headline={t("common.tenancy.create_tenancy")}
      submitButtonLabel={t("common.tenancy.create_tenancy")}
      onClose={onCancel}
      onSubmit={tryCreateTenancy}
    />
  );
};

type ProviderProps = {
  children: React.ReactNode;
};

export const TenancyCreationProvider = ({ children }: ProviderProps) => {
  const [tenancyCreationCandidate, setTenancyCreationCandidate] =
    React.useState<TenancyCreationCandidate | null>(null);

  return (
    <TenancyCreationContext.Provider
      value={{
        open: Boolean(tenancyCreationCandidate),
        candidate: tenancyCreationCandidate,
        setTenancyCreationCandidate,
        onCancel: () => setTenancyCreationCandidate(null),
      }}
    >
      {children}
      <TenancyCreationSlideOver />
    </TenancyCreationContext.Provider>
  );
};

export const useTenancyCreation = () => {
  const context = React.useContext(TenancyCreationContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useTenancyCreation",
      "TenancyCreationProvider",
    );
  }

  const { setTenancyCreationCandidate } = context;

  return {
    setTenancyCreationCandidate,
  };
};

const createTenancy = async (
  rentable_id: RentableId,
  payload: TenancyFormData,
): Promise<Tenancy> => {
  try {
    const payloadWithNoEmptyDates = {
      ...payload,
      move_in_date:
        payload.move_in_date === "" ? undefined : payload.move_in_date,
      move_out_date:
        payload.move_out_date === "" ? undefined : payload.move_out_date,
    };

    const response = await fetchFromAPI(`/api/tenancy/${rentable_id}/create`, {
      method: "POST",
      body: JSON.stringify(payloadWithNoEmptyDates),
    });

    const data = await response.json();

    if (response.status === 400) {
      throw new BackendValidationError(data.errors);
    }

    return data;
  } catch (error) {
    if (error.errors) {
      throw new BackendValidationError(error.errors);
    } else {
      throw new Error("Something went wrong");
    }
  }
};
