import { BaseAttachmentType } from "apps/contracts/components/common/interfaces";

export enum HousingTypes {
  APARTMENT = "APARTMENT",
  ROOM = "ROOM",
  CONDOMINIUM = "CONDOMINIUM",
  HOUSING_COOPERATIVE = "HOUSING_COOPERATIVE",
  VILLA = "VILLA",
  TOWN_HOUSE = "TOWN_HOUSE",
  OTHER = "OTHER",
}

export enum Inventory {
  STOVE = "STOVE",
  DISH_WASHER = "DISH_WASHER",
  EXTRACTOR_HOOD = "EXTRACTOR_HOOD",
  REFRIGERATOR = "REFRIGERATOR",
  WASHING_MACHINE = "WASHING_MACHINE",
  FREEZER = "FREEZER",
  DRYER = "DRYER",
  FREEZER_AND_REFRIGERATOR_COMBO = "FREEZER_AND_REFRIGERATOR_COMBO",
  WASHING_MACHINE_AND_DRYER_COMBO = "WASHING_MACHINE_AND_DRYER_COMBO",
  OTHER = "OTHER",
}

export enum RightOfUse {
  COMMON_LAUNDRY = "COMMON_LAUNDRY",
  COMMON_BACK_YARD = "COMMON_BACK_YARD",
  LOFT_BASEMENT_STORAGE = "LOFT_BASEMENT_STORAGE",
  BIKE_PARKING = "BIKE_PARKING",
  GARAGE = "GARAGE",
  OTHER = "OTHER",
}

export enum PaymentFrequency {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
}

export enum PaymentPlace {
  BANK_TRANSFER = "BANK_TRANSFER",
  PAYMENT_SERVICE = "PAYMENT_SERVICE",
}

export enum FreeRentRegulationReason {
  NEWLY_BUILT_PROPERTY = "NEWLY_BUILT_PROPERTY",
  CONVERTED_BUSINESS = "CONVERTED_BUSINESS",
  NEW_ATTIC = "NEW_ATTIC",
  NEW_FLOOR = "NEW_FLOOR",
}

export enum LandlordSuppliedHeating {
  DISTRICT_HEATING_OR_NATURAL_GAS = "DISTRICT_HEATING_OR_NATURAL_GAS",
  CENTRAL_HEATING_OIL = "CENTRAL_HEATING_OIL",
  ELECTRIC_HEATING = "ELECTRIC_HEATING",
  OTHER = "OTHER",
}

export enum TenantSuppliedHeating {
  ELECTRIC = "ELECTRIC",
  GAS = "GAS",
  OIL_PETROL = "OIL_PETROL",
  DISTRICT_HEATING_OR_NATURAL_GAS = "DISTRICT_HEATING_OR_NATURAL_GAS",
  OTHER = "OTHER",
}

export enum MaintenanceDuty {
  LANDLORD = "LANDLORD",
  TENANT = "TENANT",
}

export enum PetsAllowed {
  ALLOWED = "ALLOWED",
  DISALLOWED = "DISALLOWED",
  DISPENSATION = "DISPENSATION",
}

export enum HouseRules {
  NO_HOUSE_RULES = "NO_HOUSE_RULES",
  STANDARD_RULES = "STANDARD_RULES",
  CUSTOM_RULES = "CUSTOM_RULES",
}

export enum HotWaterAndHeatingResponsibility {
  LANDLORD = "LANDLORD",
  TENANT = "TENANT",
  PART_OF_RENT = "PART_OF_RENT",
}

export enum WaterResponsibility {
  LANDLORD = "LANDLORD",
  TENANT = "TENANT",
  PART_OF_RENT = "PART_OF_RENT",
}

export enum ElectricityResponsibility {
  OK = "OK",
  ENERGI_FYN = "ENERGI_FYN",
  ANDEL = "ANDEL",
  AURA = "AURA",
  NORLYS = "NORLYS",
  TENANT = "TENANT",
  LANDLORD = "LANDLORD",
  PART_OF_RENT = "PART_OF_RENT",
}

export enum TenantGenericElectricityPOA {
  GENERIC = "GENERIC",
  NO_POA = "NO_POA",
}

export enum CoolingResponsibility {
  LANDLORD = "LANDLORD",
  TENANT = "TENANT",
  PART_OF_RENT = "PART_OF_RENT",
}

export enum TvSignalSupplier {
  LANDLORD = "LANDLORD",
  TELEVISION_UNION = "TELEVISION_UNION",
  NO_TELEVISION = "NO_TELEVISION",
}

// Enums aren't extendable, so use a plain object instead
export const AttachmentType = {
  ...BaseAttachmentType,
  HOUSE_RULES: "HOUSE_RULES",
  ENERGY_LABEL: "ENERGY_LABEL",
  SOIL_POLLUTION: "SOIL_POLLUTION",
  COMPUTED: "COMPUTED",
  HOUSE_RULES_STANDARD: "HOUSE_RULES_STANDARD",
} as const;

export enum DepositMonths {
  ZERO = "ZERO",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  OTHER = "OTHER",
}

// Standard terms

export enum StandardTerms_DK {
  RENT_REGULATION = 1,
  TENANCY_LIMITED = 2,
  TENANCY_MOVE_OUT = 4,
  UTILITIES_HEATING = 5,
  UTILITIES_METER_FEE = 6,
  UTILITIES_ENERGY = 7,
  UTILITIES_WATER = 8,
  UTILITIES_TV = 9,
  STATE_INSPECTION = 10,
  MAINTENANCE_INTERIOR_DECOR = 11,
  MAINTENANCE_OUTSIDE = 12,
  PARTS_ENERGY_LABEL = 13,
  PARTS_COMMUNICATION = 14,
  PETS_SPECIAL = 15,
  PARTS_HOUSE_REGULATIONS = 16,
  PARTS_SMOKING = 17,
  MAINTENANCE_USAGE = 18,
  MAINTENANCE_EXTENDED = 19,
  TENANCY_LIMITED_NO_TERMINATION = 20,
  TENANCY_NOTERMINATION = 21,
  TENANCY_OWNED = 22,
  TENANCY_MOVE_IN = 24,
  STATE_INSPECTION_SHORT = 25,
  STATE_REFURBISHED = 26,
  STATE_MAINTAINED = 27,
  MAINTENANCE_PAINT = 28,
  MAINTENANCE_WORKMEN = 29,
  PART_SCLIMATE = 30,
  TENANCY_COHOUSED = 31,
  TYPE_1991_UNREGULATED = 32,
  TYPE_COMMERCIAL_UNREGULATED = 33,
  TYPE_ATTIC_UNREGULATED = 34,
  TYPE_NEW_FLOOR_UNREGULATED = 35,
  TYPE_1991_REGULATED = 36,
  TYPE_COMMERCIAL_REGULATED = 37,
  TYPE_ATTIC_REGULATED = 38,
  TYPE_NEW_FLOOR_REGULATED = 39,
  UTILITIES_INTERNET = 40,
  UTILITIES_ROOM = 41,
  MAINTENANCE_GARDEN = 42,
  PETS_NONE = 43,
  PETS_TINY = 44,
  TENANCY_TERMS_VALIDITY = 45,
  DIGITAL_MOVING_REPORT = 46,
  ALL_TENANTS_WHOLE_PERIOD = 47,
}
