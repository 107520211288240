import * as React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, styled, useTheme } from "@boligportal/juice";
import { UnreadCounter } from "apps/moving_reports/components/UnreadCounter";
import { DefectFlowStatus, MovingReportState } from "apps/moving_reports/enums";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { MovingReportListItem } from "apps/moving_reports/store/slices/movingReports";
import { MovingReportStateTranslations } from "apps/moving_reports/translations";
import { t } from "lib/i18n";

const StyledStateColor = styled.div<{ color: string }>`
  border-radius: 1px;
  width: 6px;
  height: 46px;
  margin-right: ${(props) => props.theme.unit(1)};
  background-color: ${(props) => props.color};
`;

const StyledFlow = styled.div<{ isTenantDefectFlow: boolean }>`
  cursor: ${(props) => (props.isTenantDefectFlow ? "pointer" : "auto")};
`;

export const StateCellRenderer = (item: MovingReportListItem) => {
  const {
    id,
    state,
    defect_flow_days_remaining,
    defect_flow_defects_reported,
    defect_flow_days_to_start,
    unviewed_tenant_defects_count,
    defect_flow_status,
  } = item;

  let subLabel: React.ReactNode = "";

  if (
    state === MovingReportState.TENANT_DEFECT_FLOW &&
    typeof defect_flow_days_to_start === "number"
  ) {
    subLabel = t("moving_reports.defects_period_notice.not_started_count", {
      days_to_start: defect_flow_days_to_start,
    });
  }

  if (
    state === MovingReportState.TENANT_DEFECT_FLOW &&
    typeof defect_flow_days_remaining === "number"
  ) {
    subLabel = (
      <>
        <span>
          {t("moving_reports.state_label.days_left_and_defect_count", {
            days_left: defect_flow_days_remaining,
            defect_count: defect_flow_defects_reported,
          })}
        </span>
        <UnreadCounter
          count={unviewed_tenant_defects_count}
          position="end"
        />
      </>
    );
  }

  if (
    state === MovingReportState.FINISHED &&
    defect_flow_status !== DefectFlowStatus.NOT_ENABLED
  ) {
    subLabel = (
      <>
        <span>
          {t("moving_reports.state_label.defect_count", {
            defect_count: defect_flow_defects_reported,
          })}
        </span>
        <UnreadCounter
          count={unviewed_tenant_defects_count}
          position="end"
        />
      </>
    );
  }

  return (
    <StateCell
      reportId={id}
      state={state}
      stateLabel={MovingReportStateTranslations()[state]}
      stateSubLabel={subLabel}
    />
  );
};

const StateCell = ({
  reportId,
  state,
  stateLabel,
  stateSubLabel,
}: {
  reportId: MovingReport["id"];
  state: MovingReportState;
  stateLabel: string;
  stateSubLabel: React.ReactNode;
}) => {
  const theme = useTheme();
  const history = useHistory();

  const contractStateColorMap = {
    [MovingReportState.IN_PROGRESS]: theme.colorPalette.gray[60],
    [MovingReportState.TENANT_DEFECT_FLOW]: theme.colorPalette.primary[800],
    [MovingReportState.FINISHED]: theme.colorPalette.green[500],
  };

  const stateColor =
    contractStateColorMap[state] ?? theme.colorPalette.gray[500];
  const isTenantDefectFlow = state === MovingReportState.TENANT_DEFECT_FLOW;

  const navigateToDefectsList = (reportId: MovingReport["id"]) => {
    if (isTenantDefectFlow) {
      history.push(`/${reportId}/status/defects`);
    }
  };

  return (
    <Flex align="center">
      <StyledStateColor color={stateColor} />

      <StyledFlow
        onClick={() => navigateToDefectsList(reportId)}
        isTenantDefectFlow={isTenantDefectFlow}
      >
        <Flex column>
          <Text
            weight="semiBold"
            size="small"
          >
            {stateLabel}
          </Text>

          {Boolean(stateSubLabel) && (
            <Text
              color="muted"
              size="small"
            >
              {stateSubLabel}
            </Text>
          )}
        </Flex>
      </StyledFlow>
    </Flex>
  );
};
