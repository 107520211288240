import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import {
  Box,
  Divider,
  NumberField,
  CheckField,
  TextField,
  Labelled,
  Link,
  Markdown,
  Text,
  Flex,
} from "@boligportal/juice";
import { App } from "components/app";
import { ControlledPhoneNumberInput } from "components/forms/ControlledPhoneNumberField";
import { AddressSuggestionDenmark } from "features/address/dk/AddressSuggestionDenmark";
import { t } from "lib/i18n";
import { LeadForm } from "./LeadServiceDialog";

interface Props {
  register: UseFormRegister<LeadForm>;
  control: Control<LeadForm>;
  errors: FieldErrors<LeadForm>;
  agreeText?: string;
  isInternet: boolean;
}

export const DialogLeadForm = ({
  register,
  control,
  errors,
  agreeText,
  isInternet,
}: Props) => (
  <>
    <Box>
      <Labelled
        label={t("tenant_hub.leads.dialog.address.label")}
        errorText={errors.address?.message}
        required
      >
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value, ...fieldRest } }) => (
            <AddressSuggestionDenmark
              initialAddress={value ?? ""}
              onAddress={(value) => {
                onChange(value);
              }}
              onAddressCleared={() => onChange("")}
              {...fieldRest}
            />
          )}
        />
      </Labelled>

      {!isInternet && (
        <TextField
          type="date"
          label={t("tenant_hub.leads.dialog.move_in_date.label")}
          errorText={errors.move_in_date && errors.move_in_date.message}
          {...register("move_in_date", {
            required: true,
          })}
          required
        />
      )}
      <Flex gap>
        <TextField
          label={t("tenant_hub.leads.dialog.first_name.label")}
          errorText={errors.tenant_first_name && t("required_field.input")}
          {...register("tenant_first_name", {
            required: true,
          })}
          required
        />
        <TextField
          label={t("tenant_hub.leads.dialog.last_name.label")}
          errorText={errors.tenant_last_name && t("required_field.input")}
          {...register("tenant_last_name", {
            required: true,
          })}
          required
        />
      </Flex>

      <TextField
        label={t("tenant_hub.leads.dialog.email.label")}
        errorText={errors.email && t("required_field.input")}
        {...register("email", {
          required: true,
        })}
        required
      />

      <ControlledPhoneNumberInput
        label={t("tenant_hub.leads.dialog.phone.label")}
        errorText={errors.phone && t("required_field.input")}
        name="phone"
        control={control}
        required
      />
      {!isInternet && (
        <Box pb={2}>
          <Controller
            control={control}
            name="cpr"
            render={({ field: { onChange, value, ...fieldRest } }) => (
              <NumberField
                type="tel"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                allowLeadingZeros
                label={t("teanant_hub.leads.dialog.cpr_input_label")}
                helpText={t("teanant_hub.leads.dialog.cpr_input_help_text")}
                format="######-####"
                mask="_"
                placeholder="XXXXXX-XXXX"
                required
                errorText={errors.cpr?.message}
                value={value ?? ""}
                {...fieldRest}
                mb={-1}
              />
            )}
          />
          {!errors.cpr && (
            <Text
              size="tiny"
              color="muted"
              mb={2}
            >
              {t("tenant_hub.leads.dialog.cpr_input_help_text")}
            </Text>
          )}
        </Box>
      )}
    </Box>

    {agreeText && (
      <>
        <Divider />
        <Box>
          <Labelled errorText={errors.isAgree && errors.isAgree.message}>
            <CheckField
              {...register("isAgree", {
                required: true,
              })}
              label={
                <Markdown
                  overrides={{
                    a: {
                      newTab: true,
                    },
                  }}
                >
                  {agreeText}
                </Markdown>
              }
            />
          </Labelled>
        </Box>
      </>
    )}

    <Box
      pl={{
        sm: 3,
      }}
    >
      <Link
        newTab
        color="text"
        href={App.settings.routes.privacy}
      >
        {t("tenant_hub.leads.dialog.privacy_policy")}
      </Link>
    </Box>
  </>
);
