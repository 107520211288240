import { Flex, Text } from "@boligportal/juice";
import { PAGE_USERS } from "apps/customer_service";
import { BlackListIndicator } from "apps/customer_service/components/blacklist_indicator/BlackListIndicator";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { blacklistReasonEnum } from "lib/enums";

interface UserCellRendererProps {
  id: number | null;
  fullname: string;
  username: string;
  user_ban_reasons?: blacklistReasonEnum[];
}

export const UserCell = (props: UserCellRendererProps) => {
  const { id, username, fullname, user_ban_reasons } = props;
  return (
    <>
      <Text
        block
        color={id === null ? "danger" : "base"}
        size="tiny"
        weight="bold"
      >
        {fullname} {id !== null ? `#${id}` : "USER DELETED"}
      </Text>
      <StyledLink to={`${PAGE_USERS}/${id}`}>
        <Flex>
          {user_ban_reasons && (
            <BlackListIndicator
              showTooltip
              reasons={user_ban_reasons}
            />
          )}
          <Text size="tiny">{`${username}`}</Text>
        </Flex>
      </StyledLink>
    </>
  );
};
