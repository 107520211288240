/* eslint-disable object-curly-newline */

/* eslint-disable import/order */
import * as React from "react";
import {
  Text,
  Flex,
  IconLockClosed,
  IconLockOpen,
  Tooltip,
  Box,
} from "@boligportal/juice";
import { useLockableFormFieldFeature } from "features/cs/lockable_form_field_feature/hooks/use_lockable_formfield_feature";

export const LockableFormField = ({
  isLocked,
  fieldLabel,
  fieldname,
  children,
}: {
  isLocked?: boolean;
  fieldname: string;
  fieldLabel: string;
  children: React.ReactNode;
}) => {
  const { unlockFormField, lockFormField } = useLockableFormFieldFeature();

  const unlockField = () => {
    unlockFormField(fieldname);
  };

  const lockField = () => {
    lockFormField(fieldname);
  };

  return (
    <>
      <Flex pb={0.5}>
        {isLocked !== undefined && (
          <Box pr={0.5}>
            {isLocked && (
              <Tooltip text="Click to unlock field">
                <div onClick={unlockField}>
                  <IconLockClosed color="danger" />
                </div>
              </Tooltip>
            )}
            {!isLocked && (
              <Tooltip text="Click to lock field">
                <div onClick={lockField}>
                  <IconLockOpen color="muted" />
                </div>
              </Tooltip>
            )}
          </Box>
        )}
        <Text size="small">{fieldLabel}</Text>
      </Flex>
      <Box pb={3}>{children}</Box>
    </>
  );
};
