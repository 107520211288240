import { useState } from "react";
import { Box, Text, Card, Flex, styled, Markdown } from "@boligportal/juice";
import SvgArrowDown from "../../media/icons/ArrowDown";
import SvgArrowUp from "../../media/icons/ArrowUp";

const StyledCard = styled(Card)`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    border-radius: 0;
  }
`;

const FaqPanel = ({
  title,
  collapsableContent,
}: {
  title: string;
  collapsableContent: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <StyledCard onClick={handleCollapseClick}>
      <Box>
        <Flex justify="space-between">
          <Box mr={1}>
            <Text weight={isCollapsed ? "base" : "semiBold"}>{title}</Text>
          </Box>
          {collapsableContent && (
            <Flex
              justify="start"
              align="start"
            >
              <Box>
                {isCollapsed ? (
                  <SvgArrowDown color="#252525" />
                ) : (
                  <SvgArrowUp color="#252525" />
                )}
              </Box>
            </Flex>
          )}
        </Flex>

        <Box
          hidden={isCollapsed}
          maxWidth={{
            xs: "100%",
            lg: "75%",
          }}
          mt={2}
        >
          <Text color="base">
            <Markdown>{collapsableContent}</Markdown>
          </Text>
        </Box>
      </Box>
      <Box
        hidden={{
          xs: false,
          md: true,
        }}
      />
    </StyledCard>
  );
};
export { FaqPanel };
