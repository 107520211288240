import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Center, Spinner } from "@boligportal/juice";
import { getSignedLeads } from "apps/tenant_hub/api/get_leads";
import {
  useTenantHubContacts,
  useTenantHubLeads,
  useTenantHubTimeline,
} from "apps/tenant_hub/api_queries";
import { ErrorMessageScreen } from "apps/tenant_hub/components/error/ErrorMessageScreen";
import { TrackingEvent } from "lib/tracking/events";
import {
  AllPartners,
  InternetPartner,
  LeadsSection,
  LeadsSections,
} from "./LeadsSections";
import { LeadServiceDialog } from "./components/LeadServiceDialog";

interface SearchParamDialogSetting {
  section: LeadsSection;
  partner: InternetPartner;
}

type SearchParamDialogConfig = {
  [key: string]: SearchParamDialogSetting;
};

type Props = {
  userToken: string;
  tenancyAddress: string;
  isElectricityIncludedInRent: boolean;
};

interface DialogState {
  section: LeadsSection;
  partner: AllPartners;
  isSigned: boolean;
}

export const TenantHubServicesPage = ({
  userToken,
  tenancyAddress,
  isElectricityIncludedInRent,
}: Props) => {
  const { data, errorMessage } = useTenantHubContacts(userToken);
  const { data: leadsData, errorMessage: leadsErrorMessage } =
    useTenantHubLeads(userToken);
  const { data: timelineData } = useTenantHubTimeline(userToken);
  const { dialogState, closeDialog, handlePartnerClick } =
    useLeadServiceDialog();

  if (errorMessage) {
    return <ErrorMessageScreen errorMessage={errorMessage} />;
  }

  if (leadsErrorMessage) {
    return <ErrorMessageScreen errorMessage={leadsErrorMessage} />;
  }

  if (!data || !leadsData || !timelineData) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    );
  }

  const signedLeads = getSignedLeads(leadsData);

  const { tenant_contacts } = data;

  const { move_in_date } = timelineData;

  return (
    <div className="w- grid lg:mx-20 lg:grid-flow-col lg:justify-stretch lg:gap-10">
      <LeadsSections
        handlePartnerClick={handlePartnerClick}
        signedLeads={signedLeads}
        hasNoticeMessage={true}
        isElectricityIncludedInRent={isElectricityIncludedInRent}
      />
      {dialogState && (
        <LeadServiceDialog
          onClose={closeDialog}
          section={dialogState.section}
          partner={dialogState.partner}
          userToken={userToken}
          tenantContact={tenant_contacts[0]}
          tenancyAddress={tenancyAddress}
          isSigned={dialogState.isSigned}
          moveInDate={move_in_date}
        />
      )}
    </div>
  );
};

export const useLeadServiceDialog = () => {
  const [dialogState, setDialogState] = useState<DialogState | null>(null);
  const { search } = useLocation();

  useEffect(() => {
    const config: SearchParamDialogConfig = {
      internet: {
        section: LeadsSection.INTERNET,
        partner: InternetPartner.NORLYS_INTERNET,
      },
    };
    const foundKey = Object.keys(config).find((key) => search.includes(key));
    if (foundKey) {
      const { section, partner } = config[foundKey];
      setDialogState({
        section,
        partner,
        isSigned: false,
      });
    }
  }, [search, setDialogState]);

  const handlePartnerClick = (
    section: LeadsSection,
    partner: AllPartners,
    isSigned: boolean,
  ) => {
    setDialogState({
      section,
      partner,
      isSigned,
    });
    TrackingEvent.tenantHubLeadsClickLeadReadMore(partner);
  };

  const closeDialog = useCallback(() => {
    setDialogState(null);
  }, []);

  return {
    dialogState,
    closeDialog,
    handlePartnerClick,
  };
};
