import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  Flex,
  Notice,
  Text,
  TextField,
} from "@boligportal/juice";
import { t } from "lib/i18n";

export const ConfirmDialog = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    confirmDelete: string | null;
  }>({
    mode: "onSubmit",
  });

  const [isWaiting, setIsWaiting] = useState(false);

  const confirmationText = t("content_deletion.confirm_deletion");

  return (
    <Dialog
      open
      size="large"
      onClose={onClose}
    >
      <DialogHeader>
        {t("moving_reports.delete_confirm_dialog.title")}
      </DialogHeader>

      <DialogContent>
        <form>
          <Notice type="danger">
            <Box mb={-2}>
              <Text
                size="small"
                markdown
                color={"danger"}
              >
                {t("common.content_deletion.confirm_dialog.notice_markdown")}
              </Text>
            </Box>
          </Notice>

          <Divider />

          <TextField
            {...register("confirmDelete", {
              validate: (value: string) =>
                value === confirmationText ||
                t("common.content_deletion.confirm_dialog.input_error"),
            })}
            required
            errorText={errors.confirmDelete?.message}
            label={t("common.content_deletion.confirm_dialog.input_label", {
              confirmationText,
            })}
            type="text"
          />
        </form>
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onClose}
          >
            {t("contracts.delete_moving_report_dialog.cancel_button")}
          </Button>

          <Button
            variant="danger"
            type="submit"
            onClick={handleSubmit(() => {
              setIsWaiting(true);
              onSubmit();
            })}
            loading={isWaiting}
          >
            {t("moving_reports.delete_confirm_dialog.confirm_button")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
