import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Flex, FlexColumn } from "@boligportal/juice";
import * as api from "api";
import { App } from "components/app";
import { RentableFields } from "components/forms/elements/FormElements";
import { AddressManualSweeden } from "features/address/se/AddressManualSweeden";
import { transformAddressManualFormSwedenToManualAddress } from "features/address/se/helpers/transformAddressManualFormSwedenToManualAddress";
import {
  ManualAddressLayout,
  ManualAddressRentableEntity,
} from "./ManualAddressLayout";

export type ManualAddressInfo = ManualAddressRentableEntity & {
  category: string;
  apartment_number: string;
  rooms: number;
  size_m2: number;
};

type Props = {
  onUseAutocomplete: () => void;
  onRentableCreated: (rentableId: number) => void;
};

export const ManualAddressSweden = ({
  onRentableCreated,
  onUseAutocomplete,
}: Props) => {
  const [canCreateRentable, setCanCreateRentable] = useState(true);
  const formMethods = useForm<ManualAddressInfo>();

  const watchedCategory = formMethods.watch("category");

  const shouldShowApartmentInfo =
    watchedCategory === "rental_room" || watchedCategory === "rental_apartment";

  const onSubmit: SubmitHandler<ManualAddressInfo> = async (data) => {
    const manualAddress = transformAddressManualFormSwedenToManualAddress(data);
    const { category, rooms, size_m2 } = data;
    const { can_create_rentable } = await api.checkCanCreateRentable(
      manualAddress,
      category,
    );
    setCanCreateRentable(can_create_rentable);

    if (!can_create_rentable) {
      return;
    }

    const { rentable_id } = await api.createRentable({
      address: manualAddress,
      apartment_number: null,
      category: category,
      room_count: rooms,
      size_m2,
      estate: null,
    });

    onRentableCreated(rentable_id);
  };

  return (
    <ManualAddressLayout<ManualAddressInfo>
      onGoBack={onUseAutocomplete}
      onRentableCreated={onRentableCreated}
      canCreateRentable={canCreateRentable}
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <AddressManualSweeden />

      <Flex flexGrow>
        <RentableFields.RentalCategory
          options={App.settings.rental_categories}
        />

        {shouldShowApartmentInfo && (
          <Box pl={2}>
            <RentableFields.SwedishApartmentInfo />
          </Box>
        )}
      </Flex>

      <Flex gap={2}>
        <FlexColumn
          size={{
            xl: 6,
            xs: 12,
          }}
        >
          <RentableFields.RentalSize />
        </FlexColumn>
        <FlexColumn
          size={{
            xl: 6,
            xs: 12,
          }}
        >
          <RentableFields.RentalNumberOfRooms />
        </FlexColumn>
      </Flex>
    </ManualAddressLayout>
  );
};
