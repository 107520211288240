import * as React from "react";

const SvgGooglePay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={52}
    height={29}
    fill="none"
    viewBox="0 0 52 29"
    {...props}
  >
    <path
      fill="#fff"
      d="M38.02 0H13.775C6.2 0 0 6.525 0 14.5S6.199 29 13.775 29H38.02c7.576 0 13.775-6.525 13.775-14.5S45.596 0 38.02 0z"
    />
    <path
      fill="#3C4043"
      d="M38.02 1.175c1.701 0 3.354.355 4.91 1.05a12.757 12.757 0 014.03 2.865 13.42 13.42 0 012.72 4.24c.662 1.64 1 3.38 1 5.17s-.338 3.53-1 5.17a13.502 13.502 0 01-2.72 4.24 12.68 12.68 0 01-4.03 2.864 11.96 11.96 0 01-4.91 1.052H13.775a11.96 11.96 0 01-4.91-1.052 12.756 12.756 0 01-4.03-2.864 13.42 13.42 0 01-2.72-4.24 13.753 13.753 0 01-1-5.17c0-1.79.338-3.53 1-5.17a13.501 13.501 0 012.72-4.24 12.679 12.679 0 014.03-2.864 11.96 11.96 0 014.91-1.051H38.02zm0-1.175H13.775C6.2 0 0 6.525 0 14.5S6.199 29 13.775 29H38.02c7.576 0 13.775-6.525 13.775-14.5S45.596 0 38.02 0z"
    />
    <path
      fill="#3C4043"
      d="M24.7 15.53v4.386h-1.323V9.084h3.506c.888 0 1.646.312 2.266.936.633.623.95 1.384.95 2.283 0 .921-.317 1.682-.95 2.298-.613.617-1.371.921-2.266.921h-2.184v.008zm0-5.112v3.777h2.21a1.72 1.72 0 001.309-.558c.351-.37.53-.819.53-1.327 0-.5-.179-.942-.53-1.312-.345-.384-.779-.572-1.309-.572H24.7v-.008zm8.857 1.842c.978 0 1.75.275 2.314.826.565.551.847 1.305.847 2.262v4.568h-1.26v-1.03h-.055c-.545.849-1.275 1.27-2.184 1.27-.778 0-1.426-.24-1.949-.726a2.368 2.368 0 01-.785-1.812c0-.769.275-1.378.826-1.827.551-.457 1.288-.682 2.204-.682.785 0 1.433.152 1.936.457v-.32c0-.485-.18-.89-.544-1.232a1.814 1.814 0 00-1.281-.507c-.737 0-1.323.326-1.75.986l-1.164-.769c.64-.978 1.591-1.464 2.845-1.464zm-1.709 5.38c0 .362.145.666.441.906.29.239.634.362 1.027.362.557 0 1.053-.217 1.487-.652.434-.435.655-.943.655-1.53-.414-.34-.985-.515-1.722-.515-.537 0-.985.138-1.343.406-.365.283-.544.624-.544 1.022zm12.061-5.14l-4.408 10.67h-1.364l1.64-3.734L36.87 12.5h1.44l2.093 5.322h.028l2.038-5.322h1.44z"
    />
    <path
      fill="#4285F4"
      d="M19.439 14.645c0-.454-.039-.888-.11-1.306h-5.543v2.393h3.192a2.895 2.895 0 01-1.185 1.928v1.552h1.9c1.11-1.081 1.746-2.68 1.746-4.567z"
    />
    <path
      fill="#34A853"
      d="M15.794 17.66c-.529.375-1.21.595-2.007.595-1.54 0-2.845-1.092-3.313-2.564h-1.96v1.6c.971 2.03 2.967 3.421 5.273 3.421 1.594 0 2.933-.551 3.908-1.501l-1.9-1.551z"
    />
    <path
      fill="#FABB05"
      d="M10.29 14.504c0-.414.065-.813.184-1.189v-1.6h-1.96a6.444 6.444 0 00-.627 2.789c0 1.003.226 1.95.627 2.789l1.96-1.601a3.923 3.923 0 01-.184-1.188z"
    />
    <path
      fill="#E94235"
      d="M13.787 10.752c.87 0 1.65.315 2.264.931l1.684-1.77c-1.023-1.003-2.356-1.619-3.948-1.619-2.305 0-4.302 1.392-5.273 3.42l1.96 1.601c.468-1.471 1.774-2.563 3.313-2.563z"
    />
  </svg>
);
export default SvgGooglePay;
