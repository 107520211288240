import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  CheckField,
  Container,
  DataTable,
  Flex,
  SortableColumn,
  Text,
  TextField,
} from "@boligportal/juice";
import { format } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import {
  beginApprovalActivityStream,
  getAds,
  landlordAdStats,
} from "lib/customerServiceApi";
import { AdStates } from "lib/enums";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { PAGE_HOME } from "../..";
import { StyledLink } from "../../components/topbar/global_search/components/styled_link";
import { AdStatusRenderer } from "./components/ad_status_renderer";
import { AddressCellRenderer } from "./components/address_cell_renderer";
import { LandlordCellRenderer } from "./components/landlord_cell_renderer";
import {
  AdItem,
  AdItemsInterface,
  IFilterState,
  LandlordAdStats,
  ReviewReason,
} from "./interfaces";
import { ListPaginator } from "./list_paginator";

const INITIAL_FILTER_STATE: IFilterState = {};

const cssSafariFix = `
  /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
    .safari-filter {
      z-index: 999;
    }
    .safari-filter-size {
      z-index: 999;
      min-height: 150px;
      max-height: 300px;
    }
  }}
`;

const getAdDetailLink = (
  item: AdItemsInterface,
  filters: IFilterState,
): string => {
  let url = `${PAGE_HOME}/ads/detail/${item.id}`;
  const id = item.landlord_id;

  if (id) {
    url = `${url}?landlordId=${id}`;
  }

  if (filters) {
    if (id) {
      url = `${url}&`;
    } else {
      url = `${url}?`;
    }
    url = `${url}filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  return url;
};

const AdIdCellRenderer = (item: AdItemsInterface, filters: IFilterState) => (
  <StyledLink to={getAdDetailLink(item, filters)}>
    <Text size="tiny">{item.id}</Text>
  </StyledLink>
);

type AdsPageAllProps = {
  initialStates?: AdStates[];
  actionLabelText?: string;
  initialReviewReasons?: ReviewReason[];
  initialNeedsFollowUp?: boolean;
  companyAds?: boolean;
  crawlingAds?: boolean;
};

const AdsPageAll = ({
  actionLabelText,
  companyAds,
  crawlingAds,
  initialNeedsFollowUp,
  initialReviewReasons,
  initialStates,
}: AdsPageAllProps) => {
  const { items, setItems, count, setCount, limit, offset, setOffset } =
    usePagination<AdItemsInterface>();
  const [sorting, setSorting] = React.useState<
    SortableColumn<AdItemsInterface>
  >({
    column: "advertised_date",
    descending: true,
  });
  const history = useHistory();
  const location = useLocation();
  // Pull initial state from url params
  const paramsString = location.search;
  // eslint-disable-next-line compat/compat
  const searchParams = new URLSearchParams(paramsString);
  const address = searchParams.get("address");
  const landlordId = searchParams.get("landlordId");
  const adId = searchParams.get("adId");
  const companyId = searchParams.get("companyId");
  const adState = searchParams.get("adState");
  const statesFromQuery: AdStates[] | null = adState
    ? [AdStates[adState]]
    : null;

  const globalSearchHack = React.useRef(address);
  const [isKeyDown, setIsKeyDown] = React.useState<boolean>(false);

  // If a flag checkbox is unchecked, the flag must be false (not undefined)
  // So the flags have a value from the beginning
  const filterState: IFilterState = {
    ...INITIAL_FILTER_STATE,
    address: address ?? undefined,
    crawlingAds: crawlingAds,
    landlordId: Number(landlordId),
    adId: Number(adId),
    companyId: Number(companyId),
    adStates: initialStates || statesFromQuery || [],
    needsFollowUp:
      initialNeedsFollowUp === undefined ? false : initialNeedsFollowUp,
    review_reasons: initialReviewReasons ?? null,
    verified_company_ads: companyAds ?? null,
    customAddress: false,
  };
  const [filters, setFilters] = React.useState<IFilterState>(filterState);
  const prevFilters = usePrevious(filters);
  const tempAdItems = React.useRef<AdItem[]>([]);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (prevFilters !== filters && offset !== 0) {
      // Clear offset on filters changed
      setOffset(0);
      return;
    }
    if (scrollContainerRef.current) {
      // Scroll to top
      scrollContainerRef.current.scrollTop = 0;
    }
    const abortController = new AbortController();
    const { signal } = abortController;
    getAds({
      signal,
      offset,
      limit,
      global_address_search: globalSearchHack.current || undefined,
      descending: sorting.descending,
      sorting: sorting.column,
      filters: {
        adId: filters.adId,
        companyId: filters.companyId,
        address: filters.address,
        landlordEmail: filters.landlordEmail,
        landlordId: filters.landlordId,
        contactEmail: filters.contactEmail,
        contactPhone: filters.contactPhone,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        adStates: filters.adStates,
        needsFollowUp: filters.needsFollowUp,
        review_reasons: filters.review_reasons,
        verified_company_ads: filters.verified_company_ads,
        crawlingAds: filters.crawlingAds,
        customAddress: filters.customAddress,
      },
    })
      .then((response) => {
        tempAdItems.current = response.data;
        globalSearchHack.current = null;
        const landlord_ids: number[] = [];

        tempAdItems.current.forEach((item) => {
          // The Ad could be a CRAWLED ad, and we can tell that
          // by checking if the ad has a landlord id.
          const id = item.landlord_id;
          if (id) {
            landlord_ids.push(id);
          }
        });

        landlordAdStats(landlord_ids).then((response) => {
          const adItemsWithStats = tempAdItems.current.map((item) => {
            let landlordAdStats: LandlordAdStats | null = null;
            response.data.forEach((landlord) => {
              if (landlord.id === item.landlord_id) {
                landlordAdStats = landlord;
              }
            });

            const newItem: AdItemsInterface = {
              ...item,
              landlord_ad_stats: landlordAdStats,
              landlord_id: item.landlord_id,
            };
            return newItem;
          });
          setItems(adItemsWithStats);
        });
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [filters, offset, limit, sorting]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const { type } = event.target;
    let value: string | null = event.target.value; //eslint-disable-line

    // Backend expect null for "empty date or text values"
    if ((type === "date" || type === "text") && value === "") {
      value = null;
    }

    const newFilterState: IFilterState = {
      ...filters,
      [field]: value,
    };

    setFilters(newFilterState);
  };
  React.useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Meta" || e.ctrlKey) {
        setIsKeyDown(true);
      }
    });
    document.addEventListener("keyup", () => {
      setIsKeyDown(false);
    });
  }, []);
  const handleGotoDetails = (item: AdItemsInterface, filters: IFilterState) => {
    beginApprovalActivityStream(item.id);
    if (isKeyDown) {
      window.open(getAdDetailLink(item, filters));
    } else {
      history.push(getAdDetailLink(item, filters));
    }
  };
  const handleSortedClick = (fieldName, descending: boolean) => {
    setSorting({
      column: fieldName,
      descending: descending,
    });
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id as AdStates;
    const { checked } = event.target;

    const newAdStates = filters.adStates ? filters.adStates : [];

    if (checked) {
      newAdStates.push(field);
    } else {
      newAdStates.splice(newAdStates.indexOf(field), 1);
    }

    const newFilterState: IFilterState = {
      ...filters,
      adStates: newAdStates,
    };
    setFilters(newFilterState);
  };

  function handlesNeedsFollowUpChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = event.target;

    const newFilterState: IFilterState = {
      ...filters,
      needsFollowUp: checked,
    };
    setFilters(newFilterState);
  }

  function handleReviewReasonChange(
    event: React.ChangeEvent<HTMLInputElement>,
    reviewReason: ReviewReason,
  ) {
    let reasonsState = [...(filters.review_reasons ?? [])];

    if (event.currentTarget.checked) {
      if (!reasonsState.includes(reviewReason)) {
        reasonsState.push(reviewReason);
      }
    } else {
      reasonsState = reasonsState.filter((reason) => reason !== reviewReason);
    }

    const newFilterState: IFilterState = {
      ...filters,
      review_reasons: reasonsState.length === 0 ? null : reasonsState,
    };

    setFilters(newFilterState);
  }

  function handleCustomAddressChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = event.target;

    const newFilterState: IFilterState = {
      ...filters,
      customAddress: checked,
    };
    setFilters(newFilterState);
  }

  const states = filters.adStates || [];
  const { needsFollowUp } = filters;
  const { customAddress } = filters;
  const isCompanyAdsList = filters.verified_company_ads;

  return (
    <Flex
      column
      width="100%"
    >
      <style>{cssSafariFix}</style>
      <Flex
        className={
          tempAdItems.current.length ? "safari-filter-size" : "safari-filter"
        }
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <Flex
            column
            mb={1}
          >
            <Flex
              gap
              align="end"
              mt={2}
            >
              <TextField
                label="Ad ID"
                defaultValue={`${filters.adId || ""}`}
                id="adId"
                onChange={handleFilterChange}
              />
              <TextField
                label="Address"
                defaultValue={`${filters.address || ""}`}
                id="address"
                onChange={handleFilterChange}
              />

              <TextField
                label="Landlord email"
                defaultValue={`${filters.landlordEmail || ""}`}
                id="landlordEmail"
                onChange={handleFilterChange}
              />

              <TextField
                label="Landlord ID"
                defaultValue={`${filters.landlordId || ""}`}
                id="landlordId"
                onChange={handleFilterChange}
              />
              <TextField
                label="Contact email"
                defaultValue={`${filters.contactEmail || ""}`}
                id="contactEmail"
                onChange={handleFilterChange}
              />
              <TextField
                label="Contact phone"
                defaultValue={`${filters.contactPhone || ""}`}
                id="contactPhone"
                onChange={handleFilterChange}
              />

              <TextField
                label="Created Date from"
                type="date"
                value={filters.dateFrom || ""}
                id="dateFrom"
                onChange={handleFilterChange}
              />

              <TextField
                label="Created Date to"
                type="date"
                value={filters.dateTo || ""}
                id="dateTo"
                onChange={handleFilterChange}
              />
            </Flex>
            <Flex mt={-4}>
              <Box>
                {!isCompanyAdsList && (
                  <>
                    <CheckField
                      inline
                      id={AdStates.ACTIVE_APPROVED}
                      label="Active"
                      checked={states.includes(AdStates.ACTIVE_APPROVED)}
                      onChange={handleStateChange}
                    />
                    <CheckField
                      inline
                      id={AdStates.INACTIVE_PENDING_APPROVAL}
                      label="Waiting for approval"
                      checked={states.includes(
                        AdStates.INACTIVE_PENDING_APPROVAL,
                      )}
                      onChange={handleStateChange}
                    />
                    <CheckField
                      inline
                      id={AdStates.INACTIVE_DRAFT}
                      label="Inactive"
                      checked={states.includes(AdStates.INACTIVE_DRAFT)}
                      onChange={handleStateChange}
                    />
                    <CheckField
                      inline
                      id={AdStates.INACTIVE_REJECTED}
                      label="Rejected"
                      checked={states.includes(AdStates.INACTIVE_REJECTED)}
                      onChange={handleStateChange}
                    />
                    <CheckField
                      inline
                      id={AdStates.RESERVED_APPROVED}
                      label="Reserved"
                      checked={states.includes(AdStates.RESERVED_APPROVED)}
                      onChange={handleStateChange}
                    />
                    <CheckField
                      inline
                      id={AdStates.RENTED_OUT_APPROVED}
                      label="Rented out"
                      checked={states.includes(AdStates.RENTED_OUT_APPROVED)}
                      onChange={handleStateChange}
                    />
                  </>
                )}
                <CheckField
                  inline
                  id={"customAddress"}
                  label="Custom Address"
                  checked={customAddress}
                  onChange={handleCustomAddressChange}
                />
              </Box>
              <Box
                textAlign={"right"}
                flexGrow
              >
                <CheckField
                  inline
                  id={"needsReview"}
                  label="Needs review"
                  checked={!!filters.review_reasons?.includes("MODIFIED")}
                  onChange={(e) => handleReviewReasonChange(e, "MODIFIED")}
                />
                {!isCompanyAdsList ? (
                  <CheckField
                    inline
                    id={"needsFollowUp"}
                    label="Needs follow up"
                    checked={needsFollowUp}
                    onChange={handlesNeedsFollowUpChange}
                  />
                ) : (
                  <CheckField
                    inline
                    id={"pendingApproval"}
                    label={"Pending approval"}
                    checked={
                      !!filters.review_reasons?.includes("AUTO_APPROVED")
                    }
                    onChange={(e) =>
                      handleReviewReasonChange(e, "AUTO_APPROVED")
                    }
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>
      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<AdItemsInterface>
            fullscreen
            keyField="id"
            items={items}
            activeSortableColumn={sorting}
            onSortClick={handleSortedClick}
            rowActions={[
              {
                label: actionLabelText ?? "View",
                callback: (item: AdItemsInterface) =>
                  handleGotoDetails(item, filters),
              },
            ]}
            columns={[
              {
                fieldName: "id",
                fieldLabel: "Ad ID",
                cellRenderer: (item: AdItemsInterface) =>
                  AdIdCellRenderer(item, filters),
                width: "170px",
                sortable: true,
              },
              {
                fieldName: "address",
                fieldLabel: "Address",
                cellRenderer: AddressCellRenderer,
                sortable: true,
              },
              {
                fieldName: "landlord_email",
                fieldLabel: "Landlord email",
                cellRenderer: LandlordCellRenderer,
              },
              {
                fieldName: "landlord_id",
                fieldLabel: "Landlord ID",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.landlord_id}</Text>
                ),
                width: "100px",
              },
              {
                fieldName: "contact_email",
                fieldLabel: "Contact email",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.contact_email}</Text>
                ),
              },
              {
                fieldName: "contact_phone",
                fieldLabel: "Contact phone",
                width: "150px",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.contact_phone}</Text>
                ),
              },
              {
                fieldName: "state",
                fieldLabel: "Status",
                cellRenderer: AdStatusRenderer,
              },
              {
                fieldName: "instance_created_date",
                fieldLabel: "Date created",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(
                      new Date(item.instance_created_date),
                      "yyyy-MM-dd HH:mm:ss",
                    )}
                  </Text>
                ),
                width: "170px",
                sortable: true,
              },
              {
                fieldName: "advertised_date",
                fieldLabel: "Advertised date",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {item.advertised_date
                      ? format(
                          new Date(item.advertised_date),
                          "yyyy-MM-dd HH:mm:ss",
                        )
                      : "-"}
                  </Text>
                ),
                width: "170px",
                sortable: true,
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                totalCount={count}
                limit={limit}
                itemCount={items.length}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { AdsPageAll };
