import { Flex, Box, Button, styled } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import { LandingPageContainer } from "./LandingPageContainer";
import { PageBlock } from "./PageBlock";

const RentOutTodayHeader = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: #191919;
`;

const OneLineSection = ({
  text,
  buttonText,
  trackingEvent,
}: {
  text: string;
  buttonText: string;
  trackingEvent: () => void;
}) => {
  const { user } = useUser();
  const { modalActionsInstance } = useAuthActions({});

  const trackEventAndNavigateToCreateListing = () => {
    trackingEvent();
    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={App.settings.routes.property_owner.create_listing}
          context={SignupContext.CREATE_RENTABLE}
        />,
      );
    } else {
      window.location.href = App.settings.routes.property_owner.create_listing;
    }
  };

  return (
    <PageBlock
      bg="gray"
      py="medium"
      pyMobile="large"
      bottomShadow
    >
      <LandingPageContainer>
        <Flex
          column={{
            xs: true,
            md: false,
          }}
          align="center"
          justify="center"
          textAlign="center"
        >
          <RentOutTodayHeader>{text}</RentOutTodayHeader>

          <Box
            ml={{
              xs: 0,
              md: 4,
            }}
            mt={{
              xs: 2,
              md: 0,
            }}
          >
            <Button
              onClick={trackEventAndNavigateToCreateListing}
              variant="branded"
            >
              {buttonText}
            </Button>
          </Box>
        </Flex>
      </LandingPageContainer>
    </PageBlock>
  );
};
export { OneLineSection };
