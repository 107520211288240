import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Card,
  CardSection,
  CheckField,
  Flex,
  FlexColumn,
  Notice,
  Text,
  useNotifications,
} from "@boligportal/juice";
import { activityStreamPageLimit, useUserActivityStream } from "api/queries";
import { Image } from "components/interfaces/image";
import { USER_TYPE } from "components/interfaces/profile";
import {
  RentalAddressValueObject,
  AutoSuggestedAddressUUID,
} from "components/rental_address/types";
import { MapLocationManager } from "features/cs/map_location_manager_feature/components/map_location_manager";
import { OISOwner } from "features/danish_property_data/components/OISOwner";
import { AdOpenHouse } from "lib/api";
import {
  claimLandlord,
  commentOnAd,
  deleteAdImage,
  getAdDetails,
  getAdSubscriptions,
  getAdToolbar,
  getAndClaimNextAd,
  getSortedEmailTemplates,
  getHistoricAdDetails,
  getLandlord,
  getPreviewURL,
  getUser,
  HistoricAdDetailItem,
  unclaimLandlord,
  updateAd,
  updateProfile,
  updateUsername,
  removeFloorPlan,
} from "lib/customerServiceApi";
import { AdStates } from "lib/enums";
import { isBoligPortal } from "lib/utils";
import { PAGE_HOME, PAGE_USERS } from "../..";
import { IFilterState, ReviewReason } from "../ads_page/interfaces";
import BlacklistUserNotice from "../user_detail_page/blacklist/blacklist_user_notice";
import { CustomSubscriptionsFeed } from "../user_detail_page/subscriptions_feed/custom_subscriptions_feed";
import { SubscriptionPaymentsItemInterface } from "../user_detail_page/subscriptions_feed/interfaces";
import { SubscriptionsFeed } from "../user_detail_page/subscriptions_feed/subscriptions_feed";
import { UserForCustomerService } from "../user_detail_page/user_detail_view/user_detail_view";
import { ActivityStream } from "./components/activity_stream";
import { EditableAdDetails } from "./components/ad_details_form";
import { AdToolbarActions } from "./components/ad_toolbar_actions";
import { ApprovalQueue } from "./components/approval_queue";
import { CSVideoEditor } from "./components/cs_video_editor";
import { DeferedAllLandslordAds } from "./components/defered_all_landlord_ads";
import { LandlordInformation } from "./components/landlord_information";
import { PreviewAdAction } from "./components/toolbar_actions/preview_ad_action";
import { TakeOverAdWarning } from "./components/warnings/take_over_ad_warning";
import {
  Claimer,
  ClaimItem,
  EmailTemplateItem,
  TaskResponseInterface,
} from "./interfaces";

interface AdDetailPageMatchParams {
  id: string;
}

export interface ClaimAdAttempt {
  didAttempt: boolean;
  claims: ClaimItem[] | null;
  claimer: Claimer | null;
}

interface LandlordInfoItem {
  email: string;
  phoneNumber: string;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  profileCompanyName: string | null;
  user_type: USER_TYPE | null;
  companySearchEnabled: boolean;
  isBankIdVerified: boolean;
}

interface EditableAdDetailItem {
  formatted_address: string;
  title: string;
  description: string;
  floor: number | undefined;
  rental_period: number;
  rooms: number;
  size_m2: number;
  deposit: number | undefined;
  monthly_rent: number;
  monthly_rent_extra_costs: number | undefined;
  prepaid_rent: number | undefined;
  available_from: string | undefined;
  elevator: boolean;
  balcony: boolean;
  furnished: boolean;
  parking: boolean;
  pet_friendly: boolean;
  senior_friendly: boolean;
  student_only: boolean;
  shareable: boolean;
  washing_machine: boolean;
  dishwasher: boolean;
  electric_charging_station: boolean;
  dryer: boolean;
  digital_showing: boolean;
  open_house: AdOpenHouse | null;
  images: Image[];
  category: string;
  native_ad: boolean;
  has_owner: boolean;
  validation_errors?: string | null;
  adId: number;
  videoId: number | null;
  source_url?: string;
  ad_phone_number: string | undefined;
  locked_fields?: string[];
  hide_from_company_search: boolean;
  interactive_floor_plan_id: string;
  reference_id: string | null;
  address: RentalAddressValueObject;
  postal_code?: string;
  place_id: AutoSuggestedAddressUUID;
  social_housing: boolean;
}

interface AdToolbarItem {
  latestBumpTime: string | null;
  address: string;
  advertisedDate: string;
  createdDate: string;
  modifiedDate: string;
  rentedOutDate: string;
  hasContract: boolean;
  rentableId: number;
  adState: AdStates;
  adNeedsFollowUp: boolean;
  reviewReason: ReviewReason | null;
  claimer: Claimer | null;
  deletedDate: string | null;
}

interface AdDetailPageState {
  claimAdAttempt: ClaimAdAttempt | null;
  subscriptionPaymentItems: SubscriptionPaymentsItemInterface[] | null;
  freeSubscriptionPaymentItems: SubscriptionPaymentsItemInterface[] | null;
  editableLandlordDetailItem: LandlordInfoItem | null;
  emailTemplateItems: EmailTemplateItem[] | null;
  editableAdDetailItem: EditableAdDetailItem | null;
  adToolbarItem: AdToolbarItem | null;
  landlordUser: UserForCustomerService | null;
  historicAdDetails: HistoricAdDetailItem | null;
}

const claimLandlordAttempt = async (
  adId: number,
  landlordId: number,
  takeOver: boolean,
): Promise<ClaimAdAttempt> => {
  const claimAttempt = await claimLandlord(adId, landlordId, takeOver)
    .then((response) => {
      let claims: ClaimItem[] | null = null;
      let claimer: Claimer | null = null;
      if (response.success) {
        // The Claimer IS ME
        claims = response.claims; // eslint-disable-line prefer-destructuring
      } else {
        // The Claimer is NOT ME
        claimer = response.claimer; // eslint-disable-line prefer-destructuring
      }
      return {
        didAttempt: response.claim_attempted,
        claims,
        claimer,
      };
    })
    .catch((error) => Promise.reject(error));
  return claimAttempt;
};

const getAllAdSubscriptions = async (
  adId: number,
): Promise<SubscriptionPaymentsItemInterface[]> => {
  const allAdSubscriptions = await getAdSubscriptions(adId)
    .then((response) => response.results)
    .catch((error) => Promise.reject(error));
  return allAdSubscriptions;
};

const getEditableLandlordDetails = async (
  adId: number,
): Promise<LandlordInfoItem> => {
  const landlordInfo = await getLandlord(adId)
    .then((response) => ({
      email: response.email,
      phoneNumber: response.phone_number,
      firstName: response.first_name,
      lastName: response.last_name,
      companyName: response.company_name,
      profileCompanyName: response.profile_company_name,
      companySearchEnabled: response.company_search_enabled,
      user_type: response.user_type,
      isBankIdVerified: response.bank_id_verified,
    }))
    .catch((error) => Promise.reject(error));

  return landlordInfo;
};

const getAllEmailTemplates = async (): Promise<EmailTemplateItem[]> => {
  const allEmailTemplates = getSortedEmailTemplates()
    .then((response) => response)
    .catch((error) => Promise.reject(error));
  return allEmailTemplates;
};

const getEditableAdDetails = async (
  adId: number,
): Promise<EditableAdDetailItem> => {
  const editableAdDetails = await getAdDetails(adId)
    .then((response) => {
      const {
        title,
        formatted_address,
        description,
        floor,
        rental_period,
        rooms,
        size_m2,
        deposit,
        monthly_rent,
        monthly_rent_extra_costs,
        prepaid_rent,
        available_from,
        features,
        digital_showing,
        images,
        category,
        open_house,
        native_ad,
        has_owner,
        validation_errors,
        draft,
        id,
        video,
        source_url,
        ad_phone_number,
        locked_fields,
        hide_from_company_search,
        interactive_floor_plan_id,
        reference_id,
        address,
        place_id,
        social_housing,
      } = response.data;

      return {
        formatted_address: formatted_address,
        title: title,
        description: description,
        floor: floor === null ? undefined : floor,
        rental_period: rental_period || 0,
        rooms: rooms,
        size_m2: size_m2,
        deposit: deposit === null ? undefined : deposit,
        monthly_rent: monthly_rent,
        monthly_rent_extra_costs:
          monthly_rent_extra_costs === null
            ? undefined
            : monthly_rent_extra_costs,
        prepaid_rent: prepaid_rent === null ? undefined : prepaid_rent,
        available_from: available_from === null ? undefined : available_from,
        balcony: features ? features.balcony : false,
        elevator: features ? features.elevator : false,
        furnished: features ? features.furnished : false,
        parking: features ? features.parking : false,
        pet_friendly: features ? features.pet_friendly : false,
        senior_friendly: features ? features.senior_friendly : false,
        student_only: features ? features.student_only : false,
        shareable: features ? features.shareable : false,
        washing_machine: features ? features.washing_machine : false,
        dishwasher: features ? features.dishwasher : false,
        electric_charging_station: features
          ? features.electric_charging_station
          : false,
        dryer: features ? features.dryer : false,
        digital_showing: digital_showing || false,
        open_house: open_house || null,
        images: images,
        category: category,
        native_ad: native_ad,
        has_owner: has_owner,
        validation_errors: validation_errors,
        draft: draft,
        adId: id,
        videoId: video?.video_id,
        source_url: source_url,
        ad_phone_number: ad_phone_number || undefined,
        locked_fields: locked_fields,
        hide_from_company_search: hide_from_company_search,
        interactive_floor_plan_id: interactive_floor_plan_id,
        reference_id: reference_id,
        address: address,
        postal_code: address ? address?.postal_code : undefined,
        place_id: place_id,
        social_housing: social_housing,
      };
    })

    .catch((error) => Promise.reject(error));

  return editableAdDetails;
};

const getLandlordUser = async (
  landlordId: number,
): Promise<UserForCustomerService> => {
  const landlordUser = await getUser(landlordId)
    .then((response) => response.user)
    .catch((error) => Promise.reject(error));

  return landlordUser;
};

const getHistoricAdDetailsNew = async (
  adId: number,
): Promise<HistoricAdDetailItem> => {
  const historicAdDetails = await getHistoricAdDetails(adId)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
  return historicAdDetails;
};

const getAdToolbarItem = async (adId: number): Promise<AdToolbarItem> => {
  const adToolbarItem = await getAdToolbar(adId)
    .then((response) => ({
      latestBumpTime: response.data.latest_bump_time,
      advertisedDate: response.data.advertised_date,
      address: response.data.address,
      createdDate: response.data.created,
      modifiedDate: response.data.modified,
      rentedOutDate: response.data.rented_out_date,
      adState: response.data.state,
      adNeedsFollowUp: response.data.needs_follow_up,
      reviewReason: response.data.review_reason,
      claimer: response.data.claimed_by,
      hasContract: response.data.has_contract,
      rentableId: response.data.rentable_id,
      deletedDate: response.data.deleted_date,
    }))
    .catch((error) => Promise.reject(error));
  return adToolbarItem;
};

// ################################################################################################

async function getDataForLandlordUsecase(
  adId: number,
  landlordId: number,
): Promise<AdDetailPageState> {
  // If ad is native, the first thing we must do is to claim the landlord, so that no other Customer Service Agents
  // cant work on this Landlord.
  const claimAdAttempt = await claimLandlordAttempt(adId, landlordId, false);

  //landlordAdsItem,
  const [
    emailTemplateItems,
    landlordUser,
    subscriptionPaymentItems,
    editableLandlordDetailItem,
    adToolbarItem,
    editableAdDetailItem,
    historicAdDetails,
  ] = await Promise.all([
    getAllEmailTemplates(),
    getLandlordUser(landlordId),
    getAllAdSubscriptions(adId),
    getEditableLandlordDetails(adId),
    getAdToolbarItem(adId),
    getEditableAdDetails(adId),
    getHistoricAdDetailsNew(adId),
  ]);

  const paidSubscriptionPaymentItems = subscriptionPaymentItems.filter(
    (item) => !item.subscription.free_plan,
  );
  const freeSubscriptionPaymentItems = subscriptionPaymentItems.filter(
    (item) => item.subscription.free_plan,
  );
  // landlordAdsItem,

  return {
    claimAdAttempt,
    subscriptionPaymentItems: paidSubscriptionPaymentItems,
    freeSubscriptionPaymentItems,
    editableLandlordDetailItem,
    emailTemplateItems,
    editableAdDetailItem,
    adToolbarItem,
    landlordUser,
    historicAdDetails,
  };
}

async function getDataForCrawledAdUseCase(adId: number) {
  // If ad is Crawled, only ad details can be edited, and the toolbar can only delete it.
  const [editableAdDetailItem, adToolbarItem, editableLandlordDetailItem] =
    await Promise.all([
      getEditableAdDetails(adId),
      getAdToolbarItem(adId),
      getEditableLandlordDetails(adId),
    ]);
  return {
    editableAdDetailItem,
    editableLandlordDetailItem,
    adToolbarItem,
  };
}

async function fetchPage(adId: number, landlordId: number | null) {
  let newState: any | null = null;

  if (landlordId === null) {
    newState = await getDataForCrawledAdUseCase(adId);
  } else {
    newState = await getDataForLandlordUsecase(adId, landlordId);
  }

  return newState;
}

const AdDetailPage = () => {
  const firstRun = React.useRef(true);
  const { addNotification } = useNotifications();
  const history = useHistory();
  const adId: number = Number(useParams<AdDetailPageMatchParams>().id);
  const landlordIdParam = new URLSearchParams(useLocation().search).get(
    "landlordId",
  );
  const filtersParam = new URLSearchParams(useLocation().search).get("filters");
  const filters: IFilterState | null = filtersParam
    ? JSON.parse(decodeURIComponent(filtersParam))
    : null;
  const initialLandlordIdFromParams: number | null = landlordIdParam
    ? Number(landlordIdParam)
    : null;
  const [includeStaffActivities, setIncludeStaffActivities] =
    React.useState(true);
  const [includeUserActivities, setIncludeUserActivities] =
    React.useState(false);
  const [activityStreamOffset, setActivityStreamOffset] = React.useState(0);

  const {
    data: activityStreamData,
    refetch: refetchActivityStream,
    isLoading: isLoadingActivityStream,
  } = useUserActivityStream(
    adId,
    "ad",
    includeUserActivities,
    includeStaffActivities,
    false,
    "desc",
    activityStreamOffset,
    activityStreamOffset + activityStreamPageLimit,
  );

  /**
   *
   */
  const [adInfo, setInfo] = React.useState<{
    adId: number;
    landlordId: number | null;
  }>({
    adId,
    landlordId: initialLandlordIdFromParams,
  });

  const [state, setState] = React.useState<AdDetailPageState>({
    claimAdAttempt: null,
    subscriptionPaymentItems: null,
    freeSubscriptionPaymentItems: null,
    editableLandlordDetailItem: null,
    emailTemplateItems: null,
    editableAdDetailItem: null,
    adToolbarItem: null,
    landlordUser: null,
    historicAdDetails: null,
  });

  const refetch = React.useCallback(() => {
    const doRefetch = async () => {
      refetchActivityStream();
      const newState = await fetchPage(adInfo.adId, adInfo.landlordId);
      setState(newState);
    };

    doRefetch();
  }, [
    adInfo.adId,
    adInfo.landlordId,
    includeUserActivities,
    includeStaffActivities,
  ]);

  React.useEffect(() => {
    if (!firstRun.current) {
      setInfo({
        adId: adId,
        landlordId: initialLandlordIdFromParams,
      });
    }

    if (firstRun.current) {
      firstRun.current = false;
    }
  }, [adId, initialLandlordIdFromParams]);

  React.useEffect(() => {
    refetch();
  }, [adInfo, includeUserActivities, includeStaffActivities, refetch]);

  React.useEffect(
    () =>
      // Unclaim the landlord only when the landlord changes!
      // Note that we don't depend on the adId here, because you might navigate to a different ad detail page for the
      // same landlord.
      () => {
        if (initialLandlordIdFromParams) {
          unclaimLandlord(initialLandlordIdFromParams);
        }
      },
    [initialLandlordIdFromParams],
  );

  const handleTakeOver = () => {
    refetch();
  };

  const handleLandlordUpdater = (
    fieldname: string,
    value: any,
  ): Promise<TaskResponseInterface> => {
    if (!adInfo.landlordId) {
      return new Promise((resolve, reject) => {
        reject("Missing landlord id");
      });
    }

    return updateProfile(adInfo.landlordId, fieldname, value)
      .then((response) => {
        if (response.errors) {
          displayErrorNotification("Updating landlord failed");
        } else {
          displaySaveNotification();
          refetch();
          return response;
        }
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleUserUpdater = (
    fieldname: string,
    value: any,
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();
    // this handler get called from the LandlordInformation Component that is only show
    // when we have a landlordId.
    return updateUsername(adInfo.landlordId!, fieldname, value)
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          displayErrorNotification("Updating landlord failed");
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleActionSuccess = async (
    autoClaimNext: boolean,
    adState: AdStates,
    needsFollowUp: boolean,
    review_reason: ReviewReason | null,
    adId: number,
  ) => {
    if (autoClaimNext && filters) {
      const nextClaimedAd = await getAndClaimNextAd(adId, filters);
      if (nextClaimedAd.next_ad_id && nextClaimedAd.next_landlord_id) {
        history.replace(
          `${PAGE_HOME}/ads/detail/${nextClaimedAd.next_ad_id}?landlordId=${
            nextClaimedAd.next_landlord_id
          }&filters=${encodeURIComponent(JSON.stringify(filters))}`,
        );
        return;
      }
    }
    refetch();
  };

  const handleSubscriptionUpdated = async () => {
    refetch();
  };

  const displaySaveNotification = () => {
    addNotification({
      title: "Saving data...",
      autoDismiss: true,
      autoDismissTimeout: 1000,
    });
  };

  const displayErrorNotification = (errorMessage: string) => {
    addNotification({
      title: <Badge state="danger">ERROR</Badge>,
      content: (
        <Box mt={1}>
          <Text color="inverted">{errorMessage ? errorMessage : ""}</Text>
        </Box>
      ),
    });
  };

  const handleEditableAdDetailUpdater = (
    fieldname: string,
    value: any,
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();

    return updateAd(adInfo.adId, fieldname, value)
      .then((response) => {
        if (!response.success) {
          if (response.errors) {
            const arrError: string[] | null = response.errors[fieldname];
            if (arrError && arrError.length > 0) {
              const errorMessage = response.errors[fieldname][0];
              displayErrorNotification(`${fieldname}: ${errorMessage}`);
            }
          }
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleImageDeletionTask = (
    id: number,
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();
    return deleteAdImage(adInfo.adId, id)
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          displayErrorNotification("Deleting image failed");
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleImageReorderTask = (
    imageIds: number[],
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();
    return updateAd(adInfo.adId, "images", imageIds)
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          refetch();
          displayErrorNotification("Reordering the image failed");
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleImageSetManualFloorPlanTask = (
    imageId: number,
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();
    return updateAd(adInfo.adId, "manual_floor_plan_image_id", imageId)
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          refetch();
          displayErrorNotification("Reordering the image failed");
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleImageRemoveManualFloorPlanTask = (
    imageId: number,
  ): Promise<TaskResponseInterface> => {
    displaySaveNotification();
    return removeFloorPlan(adInfo.adId, imageId)
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          refetch();
          displayErrorNotification("Reordering the image failed");
        }
        return response;
      })
      .catch((error) => {
        displayErrorNotification(`Some unknown error ocurres: ${error}`);
        return Promise.reject(error);
      });
  };

  const handleAddressSaveSuccess = async () => {
    refetch();
  };

  const getLandlordViewUrl = (showCompanyTab: boolean): string | null => {
    if (adInfo.landlordId) {
      return `${PAGE_USERS}/${adInfo.landlordId}${
        showCompanyTab ? "?tab=COMPANY_DETAILS" : ""
      }`;
    }
    return null;
  };

  const handleSourceUrlClick = () => {
    window.open(state.editableAdDetailItem?.source_url, "_blank");
  };

  const handleCreateComment = (value: string): Promise<void> => {
    displaySaveNotification();

    return new Promise((resolve, reject) => {
      commentOnAd(adInfo.adId, value)
        .then((response) => {
          if (response.success) {
            refetch();
            resolve();
          } else {
            displayErrorNotification("Creating comment failed");
            reject();
          }
        })
        .catch((error) => {
          displayErrorNotification(`Some unknown error ocurres: ${error}`);
          reject();
          return Promise.reject(error);
        });
    });
  };

  const handleIncludeUserActivitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeUserActivities(event.target.checked);
  };
  const handleIncludeStaffActivitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeStaffActivities(event.target.checked);
  };

  const allLoaded =
    state.claimAdAttempt &&
    state.subscriptionPaymentItems &&
    state.freeSubscriptionPaymentItems &&
    state.editableLandlordDetailItem &&
    state.emailTemplateItems &&
    state.editableAdDetailItem &&
    state.adToolbarItem &&
    state.landlordUser &&
    state.historicAdDetails;

  return (
    <AdDetailPageContext.Provider
      value={{
        adId,
        requestRefetch: refetch,
      }}
    >
      <Flex
        flexGrow
        column
        bg="tint"
      >
        {state.claimAdAttempt &&
          state.claimAdAttempt.claimer &&
          adInfo.landlordId && (
            <TakeOverAdWarning
              claimer={state.claimAdAttempt.claimer}
              adId={adInfo.adId}
              landlordId={adInfo.landlordId}
              onTakeOver={handleTakeOver}
            />
          )}

        {state.adToolbarItem && (
          <AdToolbarActions
            latestBumpTime={state.adToolbarItem.latestBumpTime}
            advertisedDate={state.adToolbarItem.advertisedDate}
            landlordId={adInfo.landlordId}
            adId={adInfo.adId}
            address={state.adToolbarItem.address}
            createdDate={state.adToolbarItem.createdDate}
            modifiedDate={state.adToolbarItem.modifiedDate}
            rentedOutDate={state.adToolbarItem.rentedOutDate}
            adState={state.adToolbarItem.adState}
            needs_follow_up={state.adToolbarItem.adNeedsFollowUp}
            review_reason={state.adToolbarItem.reviewReason}
            emailTemplateItems={state.emailTemplateItems}
            claimAdAttempt={state.claimAdAttempt}
            onActionSuccess={handleActionSuccess}
            isCrawledAd={state.editableAdDetailItem?.native_ad === false}
            hasOwner={state.editableAdDetailItem?.has_owner === true}
            hasContract={state.adToolbarItem.hasContract}
            deletedDate={state.adToolbarItem.deletedDate}
          />
        )}
        <Box
          height="100%"
          scrollable
          bg="tint"
        >
          <Box
            px={{
              xs: 2,
              sm: 4,
              md: 6,
              lg: 8,
              xl: 10,
            }}
            m="auto"
            maxWidth="1140px"
          >
            <Flex
              py={4}
              gap
            >
              <FlexColumn size={8}>
                <>
                  {state.editableAdDetailItem && (
                    <>
                      <EditableAdDetails
                        key={adId}
                        onAddressSaveSuccess={handleAddressSaveSuccess}
                        historicAdDetails={state.historicAdDetails}
                        initialValues={state.editableAdDetailItem}
                        updater={handleEditableAdDetailUpdater}
                        imageDeletionTask={handleImageDeletionTask}
                        imageReorderTask={handleImageReorderTask}
                        imageSetManualFloorPlanTask={
                          handleImageSetManualFloorPlanTask
                        }
                        imageRemoveManualFloorPlanTask={
                          handleImageRemoveManualFloorPlanTask
                        }
                        companySearchEnabled={
                          state.editableLandlordDetailItem
                            ?.companySearchEnabled || false
                        }
                        social_housing={
                          state.editableAdDetailItem.social_housing
                        }
                        landLordUser={state.landlordUser}
                      />
                      <Card sectioned>
                        <MapLocationManager adId={adInfo.adId} />
                      </Card>
                    </>
                  )}
                  {state.editableAdDetailItem?.videoId && (
                    <Card sectioned>
                      <CardSection title="Ad Video">
                        <CSVideoEditor
                          adId={state.editableAdDetailItem.adId}
                          initialVideoId={state.editableAdDetailItem.videoId}
                        />
                      </CardSection>
                    </Card>
                  )}
                  <Card sectioned>
                    <CardSection title="Create comment">
                      <ActivityStream
                        offset={activityStreamOffset}
                        setOffset={setActivityStreamOffset}
                        totalRecords={activityStreamData?.count}
                        isLoading={isLoadingActivityStream}
                        items={activityStreamData?.stream || []}
                        onCreateComment={handleCreateComment}
                        filterComponent={
                          <Flex
                            gap
                            mb={1}
                          >
                            <CheckField
                              inline
                              id="includeStaffActivities"
                              label="Include Staff activities in timeline"
                              onChange={handleIncludeStaffActivitiesChange}
                              checked={includeStaffActivities}
                            />
                            <CheckField
                              inline
                              id="includeUserActivities"
                              label="Include user activities in timeline"
                              onChange={handleIncludeUserActivitiesChange}
                              checked={includeUserActivities}
                            />
                          </Flex>
                        }
                      />
                    </CardSection>
                  </Card>
                </>
              </FlexColumn>
              <FlexColumn size={4}>
                {/* CRAWLED AD */}
                {state.editableAdDetailItem?.native_ad === false && (
                  <Card sectioned>
                    <CardSection title="Ad info">
                      <Notice type="info">🤖 This is a crawled ad</Notice>
                      <div>
                        {state.adToolbarItem && (
                          <Text paragraph>
                            <Text weight="bold">Status:</Text>{" "}
                            {state.adToolbarItem.adState}
                          </Text>
                        )}
                      </div>
                      {state.editableAdDetailItem?.validation_errors && (
                        <Notice type="warning">
                          {state.editableAdDetailItem?.validation_errors}
                        </Notice>
                      )}
                      <Flex gap>
                        <PreviewAdAction action={() => getPreviewURL(adId)} />
                        {state.editableAdDetailItem?.source_url && (
                          <Button onClick={handleSourceUrlClick}>
                            Source site
                          </Button>
                        )}
                      </Flex>
                    </CardSection>
                  </Card>
                )}
                {state.editableLandlordDetailItem && (
                  <Card sectioned>
                    <CardSection title="Landlord information">
                      {state.landlordUser && (
                        <BlacklistUserNotice
                          bans={state.landlordUser.user_bans}
                          view="ad_detail"
                        />
                      )}
                      <LandlordInformation
                        landlordUpdater={handleLandlordUpdater}
                        userUpdater={handleUserUpdater}
                        initialValues={state.editableLandlordDetailItem}
                        landlordUrlGenerator={getLandlordViewUrl}
                        landlordId={adInfo.landlordId}
                        hasOwner={state.editableAdDetailItem?.has_owner}
                        social_housing={
                          state.editableAdDetailItem?.social_housing
                        }
                        oisOwnerComponent={
                          isBoligPortal() && (
                            <OISOwner
                              placeId={
                                state.editableAdDetailItem?.place_id ?? ""
                              }
                            />
                          )
                        }
                      />
                    </CardSection>
                  </Card>
                )}

                {state.claimAdAttempt &&
                  state.claimAdAttempt.claims &&
                  adInfo.landlordId && (
                    <Card sectioned>
                      <CardSection title="Approval queue">
                        <ApprovalQueue
                          items={state.claimAdAttempt.claims}
                          currentAdId={adInfo.adId}
                          landlordId={adInfo.landlordId}
                        />
                      </CardSection>
                    </Card>
                  )}

                {allLoaded && adInfo.landlordId && (
                  <Card sectioned>
                    <CardSection title="All landlord ads">
                      <DeferedAllLandslordAds landlordId={adInfo.landlordId} />
                    </CardSection>
                  </Card>
                )}

                {state.freeSubscriptionPaymentItems &&
                  state.freeSubscriptionPaymentItems.length > 0 &&
                  state.landlordUser && (
                    <Card sectioned>
                      <CardSection title="Manually added products">
                        <CustomSubscriptionsFeed
                          items={state.freeSubscriptionPaymentItems}
                          onSubscriptionUpdated={handleSubscriptionUpdated}
                          userId={state.landlordUser.id}
                        />
                      </CardSection>
                    </Card>
                  )}
                {state.subscriptionPaymentItems && state.landlordUser && (
                  <Card sectioned>
                    <CardSection title="Subscriptions">
                      <SubscriptionsFeed
                        items={state.subscriptionPaymentItems}
                        refundCandidate={state.landlordUser}
                        onSubscriptionUpdated={handleSubscriptionUpdated}
                      />
                    </CardSection>
                  </Card>
                )}
              </FlexColumn>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </AdDetailPageContext.Provider>
  );
};

export { AdDetailPage };

interface AdDetailPageAPI {
  adId: number | null;
  requestRefetch: () => void;
}

const AdDetailPageContext = React.createContext<AdDetailPageAPI>({
  adId: null,
  requestRefetch: () => {},
});

export const useCustomerServiceAdDetailPage = () => ({
  ...React.useContext(AdDetailPageContext),
});
