import { MarketSettings } from "components/markets/market_settings";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import SvgLogoBoligportal from "media/svg/LogoBoligportal";
import SvgLogoBoligportalWhite from "media/svg/LogoBoligportalWhite";
import { paywall_dk } from "./paywall/paywall_dk";

export class BoligportalDKMarketSettings implements MarketSettings {
  getHeaderLogoComponent(inverseTextColor?: boolean) {
    if (inverseTextColor) {
      return (
        <SvgLogoBoligportalWhite
          width="180px"
          height="33px"
        />
      );
    }
    return (
      <SvgLogoBoligportal
        width="180px"
        height="33px"
      />
    );
  }

  expectedMaximumRentLength = 5;

  countryBoundingBox = {
    sw: {
      lat: 55.1331192,
      lng: 10.5930952,
    },
    ne: {
      lat: 69.0599699,
      lng: 24.1776819,
    },
  };

  customerService = {
    externalAddressWebsites: ["Krak"],
    externalPhoneWebsites: ["Krak", "180", "DeGuleSider"],
  };

  paymentMethodTypes = [
    PaymentMethodType.Account,
    PaymentMethodType.MobilePay,
    PaymentMethodType.QuickPay,
    PaymentMethodType.ApplePay,
    PaymentMethodType.GooglePay,
  ];

  paywall = paywall_dk;
}
