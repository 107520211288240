import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Box, CheckField, Flex, Text, TextField } from "@boligportal/juice";
import { App } from "components/app";
import { HeaderLabel } from "components/forms/header_label";
import { t } from "lib/i18n";

interface Props {
  showContactByMessage: boolean;
}

export const RentalContactAdOptions = ({ showContactByMessage }: Props) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setFocus,
  } = useFormContext();
  const headline = t("create.contact_ad_options.heading");
  const subheading = t("create.contact_ad_options.subheading");
  const byMessageLabel = t("create.contact_ad_options.by_message_label", {
    brand_name: App.settings.brand_name,
  });
  const byPhoneLabel = t("create.field.ad_phone_number.heading");
  const byPhoneTextFieldPlaceholder = t(
    "create.contact_ad_options.by_phone_placeholder",
  );
  const byPhoneErrorText = t("required_field.phone");
  const [byPhoneChecked, setByPhoneChecked] = React.useState(() =>
    Boolean(getValues().ad_phone_number),
  );

  const byPhoneCheckFieldChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setByPhoneChecked(event.target.checked);
  };

  React.useEffect(() => {
    const { ad_phone_number } = getValues();

    if (byPhoneChecked && !ad_phone_number) {
      setFocus("ad_phone_number");
    } else if (!byPhoneChecked) {
      setValue("ad_phone_number", "", {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue("ad_phone_number", ad_phone_number, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [byPhoneChecked, setValue, setFocus, getValues]);

  return (
    <Flex column>
      <HeaderLabel label={headline} />
      <Text size="body">{subheading}</Text>

      {showContactByMessage && (
        <CheckField
          checked
          name="byMessage"
          label={byMessageLabel}
          disabled
        />
      )}
      <Box>
        <CheckField
          name="byPhone"
          label={byPhoneLabel}
          checked={byPhoneChecked}
          onChange={byPhoneCheckFieldChangeHandler}
        />
      </Box>
      <Box hidden={!byPhoneChecked}>
        <TextField
          type="text"
          errorText={errors.ad_phone_number && byPhoneErrorText}
          placeholder={byPhoneTextFieldPlaceholder}
          {...register("ad_phone_number", {
            validate: (value: string) => {
              // no value is when user has yet NOT toggled the checkfield, so those use cases should
              // also be considered as valid.
              if (value === null || value === undefined || value.length >= 0) {
                return true;
              }

              return byPhoneErrorText;
            },
          })}
        />
      </Box>
    </Flex>
  );
};
