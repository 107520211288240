/* eslint-disable compat/compat */
import { fetchFromAPI } from "lib/api";

const checkVideoStatusService = async (
  videoId: number,
): Promise<{ status: "playable" | "error" | "not_ready" }> => {
  const request = await fetchFromAPI(
    `/api/video/get-video-status?video_id=${videoId}`,
  );
  const data = await request.json();

  if (data && data["status"]) {
    return Promise.resolve({
      status: data["status"],
    });
  }

  return Promise.reject();
};

export { checkVideoStatusService };
