import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { isAfter } from "date-fns";
import { SigningStatus } from "../../common/interfaces";

const a10ReleaseDate = new Date("2022/11/17 12:00:00 GMT+0100");

const isContractA10 = (signingDate: string) =>
  isAfter(new Date(signingDate), new Date(a10ReleaseDate));

export const shouldShowDraftAttachments = (
  signStatus: SigningStatus,
  sentForSigningAt: string | null,
) => {
  if (App.settings.market === Market.BOSTADSPORTAL_SE) {
    return true;
  }

  if (signStatus === SigningStatus.COMPLETED) {
    return true;
  }

  if (!sentForSigningAt) {
    return true;
  }

  return isContractA10(sentForSigningAt);
};
