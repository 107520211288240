import * as React from "react";
import { Box, Flex } from "@boligportal/juice";
import { PageBlock } from "apps/landingpages/renting_out/components/PageBlock";
import { ScalabaleHeadline } from "./ScalabaleHeadline";

export const FAQSectionWrapper = ({
  headline,
  children,
}: {
  headline: string;
  children: React.ReactElement;
}) => (
  <PageBlock
    bg="default"
    py="extraLarge"
  >
    <Box
      px={{
        xs: 0,
        md: 6,
      }}
      m="auto"
      width={"auto"}
      maxWidth={"1390px"}
    >
      <Flex
        column
        justify="center"
        width={{
          xs: "100%",
          md: "100%",
          lg: "70%",
        }}
      >
        <Box
          px={{
            xs: 2,
            md: 0,
          }}
          pb={6}
          textAlign={{
            xs: "center",
            md: "left",
          }}
        >
          <ScalabaleHeadline>{headline}</ScalabaleHeadline>
        </Box>

        {children}
      </Flex>
    </Box>
  </PageBlock>
);
