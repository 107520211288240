import * as React from "react";
import { usePaymentRecordStatus } from "api/mutations";
import { TenancyPaymentProviderStatus } from "business/domain/tenancy/TenancyPaymentProviderStatus";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentTransactionEntity } from "business/domain/tenancy/TenancyPaymentTransaction";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { AddTenantPaymentDialog } from "features/rentable_tenancy/components/PartialPaymentDialog/AddTenantPaymentDialog";
import { PaymentRecordChangeStatusConfirmation } from "features/rentable_tenancy/components/PaymentRecordChangeStatusConfirmation";
import { PaymentRecordChangeStatusNotPossible } from "features/rentable_tenancy/components/PaymentRecordChangeStatusNotPossible";
import { PaymentRecordMarkAsManuallyPaidConfirm } from "features/rentable_tenancy/components/PaymentRecordMarkAsManuallyPaidConfirm/PaymentRecordMarkAsManuallyPaidConfirm";
import { t } from "lib/i18n";

type PaymentStatusChangeCandidate = {
  wasManuallyChanged: boolean;
  paymentProvider: TenancyPaymentType;
  paymentRecordId: number;
  paymentProviderStatus: TenancyPaymentProviderStatus | null;
  fromStatus: TenancyPaymentStatus;
  toStatus: TenancyPaymentStatus;
  arrears?: number;
  transactions: TenancyPaymentTransactionEntity;
  rent: number;
};

type ChangePaymentStatusContextValue = {
  open: boolean;
  candidate: PaymentStatusChangeCandidate | null;
  setChangePaymentStatusCandidate: (
    candidate: PaymentStatusChangeCandidate | null,
  ) => void;
  onCancel: () => void;
  invalidationQueries: string[];
};

const ChangePaymentStatusContext =
  React.createContext<ChangePaymentStatusContextValue>(null!);

const PaymentRecordStatusChangeDialog = () => {
  const {
    candidate,
    open,
    onCancel,
    setChangePaymentStatusCandidate,
    invalidationQueries,
  } = React.useContext(ChangePaymentStatusContext);
  const paymentRecordStatusMutation =
    usePaymentRecordStatus(invalidationQueries);

  const changePaymentStatus = (
    paymentRecordId: number,
    toStatus: TenancyPaymentStatus,
  ) => {
    paymentRecordStatusMutation.mutate(
      {
        paymentRecordId,
        status: toStatus,
      },
      {
        onSettled: () => {
          setChangePaymentStatusCandidate(null);
        },
      },
    );
  };

  if (!candidate) {
    return null;
  }

  const {
    wasManuallyChanged,
    paymentRecordId,
    paymentProvider,
    paymentProviderStatus,
    fromStatus,
    toStatus,
    arrears,
    transactions,
    rent,
  } = candidate;

  const isSentToPaymentProvider = paymentProviderStatus !== null;
  const wasRejected =
    paymentProviderStatus === TenancyPaymentProviderStatus.REJECTED;

  const paymentProviderName = getPaymentProviderName(paymentProvider);
  if (fromStatus !== toStatus) {
    return (
      <AddTenantPaymentDialog
        open
        onClose={onCancel}
        arrears={arrears}
        tenancyPaymentRecordId={paymentRecordId}
        transactions={transactions}
        rentAmount={rent}
        paymentType={paymentProvider}
      />
    );
  }

  if (
    wasManuallyChanged &&
    wasRejected &&
    fromStatus === TenancyPaymentStatus.PAID
  ) {
    let message = "";

    if (toStatus === TenancyPaymentStatus.NOT_PAID) {
      message = t(
        "payment.status_change_not_possible.paid_to_not_paid.message_text",
        {
          paymentProvider: paymentProviderName,
        },
      );
    }

    return (
      <PaymentRecordChangeStatusNotPossible
        open
        onConfirm={onCancel}
        message={message}
      />
    );
  }

  if (
    fromStatus === TenancyPaymentStatus.NOT_PAID &&
    toStatus === TenancyPaymentStatus.PAID &&
    (!isSentToPaymentProvider || wasRejected)
  ) {
    return (
      <PaymentRecordChangeStatusConfirmation
        title={t("payment.status_change.paid.title")}
        message={t("payment.status_change.paid.message_new", {
          paymentType:
            paymentProvider === TenancyPaymentType.MOBILEPAY
              ? t("common.mobilepay")
              : t("common.betalings_service"),
        })}
        cancelText={t("payment.status_change.paid.cancel_text")}
        confirmText={t("payment.status_change.paid.confirm_text")}
        checkFieldLabel={t("payment.status_change.paid.check_field_label_new", {
          paymentType:
            paymentProvider === TenancyPaymentType.MOBILEPAY
              ? t("common.mobilepay")
              : t("common.betalings_service"),
        })}
        checkFieldErrorText={t(
          "payment.status_change.paid.check_field_error_text",
        )}
        checkFieldDisclaimer={t(
          "payment.status_change.paid.check_field_disclaimer_text",
        )}
        paymentProvider={paymentProvider}
        open={open}
        onCancel={onCancel}
        onConfirm={() => changePaymentStatus(paymentRecordId, toStatus)}
      />
    );
  }

  if (
    toStatus === TenancyPaymentStatus.NOT_PAID &&
    isSentToPaymentProvider &&
    !wasRejected
  ) {
    return (
      <PaymentRecordChangeStatusNotPossible
        open
        onConfirm={onCancel}
        message={t(
          "payment.status_change_not_possible.waiting_to_paid.message_text",
          {
            paymentProvider: paymentProviderName,
          },
        )}
      />
    );
  }

  if (
    fromStatus === TenancyPaymentStatus.PAID &&
    toStatus === TenancyPaymentStatus.NOT_PAID &&
    isSentToPaymentProvider
  ) {
    return (
      <PaymentRecordChangeStatusNotPossible
        open
        onConfirm={onCancel}
        message={t(
          "payment.status_change_not_possible.paid_to_not_paid.message_text",
          {
            paymentProvider: paymentProviderName,
          },
        )}
      />
    );
  }

  if (
    fromStatus === TenancyPaymentStatus.NOT_PAID &&
    toStatus === TenancyPaymentStatus.PAID &&
    isSentToPaymentProvider
  ) {
    return (
      <PaymentRecordMarkAsManuallyPaidConfirm
        open={open}
        onCancel={onCancel}
        onConfirm={() => changePaymentStatus(paymentRecordId, toStatus)}
        paymentRecordId={paymentRecordId}
      />
    );
  }

  return null;
};

type ProviderProps = {
  children: React.ReactNode;
  invalidationQueries: string[];
};

export const PaymentRecordStatusChangeProvider = ({
  children,
  invalidationQueries,
}: ProviderProps) => {
  const [paymentStatusChangeCandidate, setPaymentStatusChangeCandidate] =
    React.useState<PaymentStatusChangeCandidate | null>(null);
  return (
    <ChangePaymentStatusContext.Provider
      value={{
        open: Boolean(paymentStatusChangeCandidate),
        candidate: paymentStatusChangeCandidate,
        setChangePaymentStatusCandidate: (
          candidate: PaymentStatusChangeCandidate,
        ) => setPaymentStatusChangeCandidate(candidate),
        onCancel: () => setPaymentStatusChangeCandidate(null),
        invalidationQueries,
      }}
    >
      {children}
      <PaymentRecordStatusChangeDialog />
    </ChangePaymentStatusContext.Provider>
  );
};

export const usePaymentRecordChangeStatus = () => {
  const context = React.useContext(ChangePaymentStatusContext);
  if (!context) {
    throw "usePaymentRecordChangeStatus hook can only be used inside function component as a Child of PaymentRecordStatusChangeProvider";
  }

  const { setChangePaymentStatusCandidate, invalidationQueries, open } =
    context;
  const paymentRecordStatusMutation =
    usePaymentRecordStatus(invalidationQueries);

  const setPaymentStatusChangeCandidate = ({
    fromStatus,
    toStatus,
    paymentType,
    paymentRecordId,
    paymentProviderStatus,
    wasManuallyChanged,
    arrears,
    transactions,
    rent,
  }: {
    paymentType: TenancyPaymentType;
    paymentProviderStatus: null | TenancyPaymentProviderStatus;
    paymentRecordId: number;
    fromStatus: TenancyPaymentStatus;
    toStatus: TenancyPaymentStatus;
    wasManuallyChanged: boolean;
    arrears?: number;
    transactions: TenancyPaymentTransactionEntity;
    rent: number;
  }) => {
    if (fromStatus === toStatus) {
      return;
    }

    setChangePaymentStatusCandidate({
      wasManuallyChanged,
      paymentProvider: paymentType,
      paymentRecordId,
      paymentProviderStatus,
      fromStatus,
      toStatus,
      arrears,
      transactions,
      rent,
    });
  };

  return {
    isActive: open,
    setPaymentStatusChangeCandidate: React.useCallback(
      setPaymentStatusChangeCandidate,
      [setChangePaymentStatusCandidate, paymentRecordStatusMutation],
    ),
  };
};

const getPaymentProviderName = (paymentProvider: TenancyPaymentType) => {
  let paymentProviderName = "";
  if (paymentProvider === TenancyPaymentType.MOBILEPAY) {
    paymentProviderName = t("mobile_pay");
  }
  return paymentProviderName;
};
