import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  Dialog,
  dialogActions,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Divider,
  Flex,
  FlexColumn,
  Logo,
  Spinner,
  styled,
  Text,
  TextField,
} from "@boligportal/juice";
import { App } from "components/app";
import { API } from "lib/api";
import { t } from "lib/i18n";
import SvgAppStore from "media/svg/AppStore";
import SvgDansk from "media/svg/Dansk";
import SvgDeutch from "media/svg/Deutch";
import SvgEnglish from "media/svg/English";
import SvgFacebookLogo from "media/svg/FacebookLogo";
import SvgInstagramLogo from "media/svg/InstagramLogo";
import SvgLinkedInLogo from "media/svg/LinkedInLogo";
import SvgSvenska from "media/svg/Svenska";
import { Market } from "./markets/market_settings";

const FlagDansk = styled(SvgDansk)`
  width: 20px;
  margin-left: 4px;
  margin-right: 8px;
`;
const FlagEnglish = styled(SvgEnglish)`
  width: 20px;
  margin-left: 4px;
  margin-right: 8px;
`;
const FlagSvenska = styled(SvgSvenska)`
  width: 20px;
  margin-left: 4px;
  margin-right: 8px;
`;
const FlagDeutch = styled(SvgDeutch)`
  width: 20px;
  margin-left: 4px;
  margin-right: 8px;
`;

const StyledLogoWrapper = styled.div`
  opacity: 0.3;
`;

const StyledFooter = styled.footer`
  display: block;
  background-color: #202020;
`;

const StyledDivider = styled(Divider)`
  border-top: 1px solid #5b5b5e;
  margin-bottom: 8px;
  width: 100%;
`;

const CustomImgWrapper = styled(Flex)`
  border-color: #5b5b5e;
  width: 50px;
  height: 50px;

  &:hover {
    background: #5b5b5e;
    cursor: pointer;
  }
`;

const StyledA = styled.a`
  margin-bottom: 8px;
`;

const CustomA = styled.a`
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colorPalette.gray[80]};
  text-decoration: none !important;
  &:hover {
    color: ${(props) => props.theme.colorPalette.gray[60]};
    text-decoration: none !important;
  }
`;

const FooterLink = styled.a`
  padding-top: ${(props) => props.theme.unit(0.25)};
  padding-bottom: ${(props) => props.theme.unit(0.25)};
  margin-bottom: ${(props) => props.theme.unit(1)};
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colorPalette.gray[80]};
  text-decoration: none !important;
  &:hover {
    color: ${(props) => props.theme.colorPalette.gray[60]};
    text-decoration: none !important;
  }
`;

const FooterText = styled.span<{ hover?: boolean }>`
  cursor: ${(props) => (props.hover ? "pointer" : "auto")};
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colorPalette.gray[80]};
  text-decoration: none !important;
  &:hover {
    color: ${(props) =>
      props.hover
        ? props.theme.colorPalette.gray[60]
        : props.theme.colorPalette.gray[80]};
    text-decoration: none !important;
  }
`;

const FooterHeadline = styled(Text)`
  color: #5b5b5e;
  margin-bottom: 8px;
`;

const StyledSpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
`;

const excludeCurrentLanguageFromAll = () =>
  App.settings.languages.filter((lang) => lang.code != App.settings.language);

const toUpperCase = (str: string) => str.toUpperCase();

const marketIsBoligPortal = () => {
  const boolToReturn: boolean = App.settings.market === Market.BOLIGPORTAL_DK;
  return boolToReturn;
};

const AppBadges = ({ desktop }: { desktop: boolean }) => (
  <>
    {/* APP BADGES ONLY FOR BOLIGPORTAL (DANISH MARKET) */}
    {App.settings.market === Market.BOLIGPORTAL_DK && (
      <FlexColumn
        hidden={
          desktop
            ? {
                xs: true,
                md: false,
              }
            : {
                xs: false,
                md: true,
              }
        }
        size={{
          xs: 12,
          md: 3,
          lg: 2,
        }}
      >
        <Flex column>
          <FooterHeadline
            size="small"
            color="muted"
          >
            {toUpperCase(t("footer.get_app"))}
          </FooterHeadline>
          <Flex
            gap={{
              xs: 2,
              md: 0,
            }}
            column={{
              xs: false,
              md: true,
            }}
            align={{
              xs: "start",
              md: "start",
            }}
            justify="start"
          >
            <Box>
              <a
                href="https://play.google.com/store/apps/details?id=dk.boligportal.app"
                target="_blank"
                rel="noreferrer"
                aria-label={`Google Play - ${App.settings.brand_name}`}
              >
                <img
                  width={117}
                  height={35}
                  alt="Google Play"
                  src="/static/images/footer/get_app_icons/GooglePlay.png"
                />
              </a>
            </Box>

            <Box
              mt={{
                xs: 0,
                md: 1,
              }}
            >
              <a
                href="https://appstore.com/boligportal/boligportal"
                target="_blank"
                rel="noreferrer"
                aria-label={`App Store - ${App.settings.brand_name}`}
              >
                <SvgAppStore
                  width="105px"
                  height="35px"
                />
              </a>
            </Box>
          </Flex>
        </Flex>
      </FlexColumn>
    )}
  </>
);

const facebookUrl = () => {
  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    return "https://www.facebook.com/boligportal/";
  } else {
    return "https://www.facebook.com/BostadsPortal/";
  }
};

const linkedInUrl = () => {
  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    return "https://www.linkedin.com/company/boligportal-/";
  } else {
    return "https://www.linkedin.com/company/bostadsportal-se/";
  }
};

const instagramUrl = () => "https://www.instagram.com/boligportal/";

const Flag = ({ language }: { language: string }) => {
  switch (language) {
    case "English":
      return (
        <FlagEnglish
          width="20px"
          height="15px"
        ></FlagEnglish>
      );
    case "Svenska":
      return (
        <FlagSvenska
          width="20px"
          height="15px"
        ></FlagSvenska>
      );
    case "Dansk":
      return (
        <FlagDansk
          width="20px"
          height="15px"
        ></FlagDansk>
      );
    case "Deutch":
      return (
        <FlagDeutch
          width="20px"
          height="15px"
        ></FlagDeutch>
      );
  }
  return (
    <FlagEnglish
      width="20px"
      height="15px"
    ></FlagEnglish>
  );
};

const CancelButton = () => {
  const actions = useContext(dialogActions);
  return (
    <Button
      variant="subtle"
      onClick={actions.hide}
    >
      {t("Cancel")}
    </Button>
  );
};

type SearchAdIdForm = {
  ad_id: number;
};

const FooterTextWithDialog = () => {
  const { handleSubmit, register, watch } = useForm<SearchAdIdForm>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [stateError, setError] = useState<string>("");

  const watcher = watch("ad_id");

  const shouldBeDisabled = () => {
    if (watcher !== undefined && watcher.toString() !== "") {
      return false;
    } else {
      return true;
    }
  };

  const onAdIdSearchSubmit = ({ ad_id }) => {
    if (!ad_id) {
      return;
    }
    setError("");
    setLoading(true);
    API.getAdsForIds([ad_id])
      .then((response) => {
        window.location.href = response[0].url;
      })
      .catch(() => {
        setError(t("footer.search_for_ad.error"));
        setLoading(false);
      });
  };

  return (
    <Dialog opener={<FooterText hover>{t("footer.search_for_ad")}</FooterText>}>
      <DialogHeader>{t("footer.search_for_ad.dialog.header")}</DialogHeader>
      <DialogForm
        method="post"
        onSubmit={handleSubmit(onAdIdSearchSubmit)}
      >
        <DialogContent>
          <Box height="100px">
            {isLoading && (
              <StyledSpinnerWrapper>
                <Spinner size="large" />
              </StyledSpinnerWrapper>
            )}
            <TextField
              errorText={stateError}
              type="number"
              label={
                isLoading
                  ? t("footer.search_for_ad.searching")
                  : t("footer.search_for_ad.search_for")
              }
              {...register("ad_id")}
            />
          </Box>
        </DialogContent>
        <DialogFooter>
          <Flex gap>
            <CancelButton />
            <Button
              disabled={shouldBeDisabled()}
              type="submit"
              variant="branded"
            >
              {t("footer.search_for_ad.submit_btn")}
            </Button>
          </Flex>
        </DialogFooter>
      </DialogForm>
    </Dialog>
  );
};

const Footer = () => (
  <StyledFooter>
    <Container>
      <Flex
        column
        py={8}
      >
        <Flex wrap>
          <AppBadges desktop></AppBadges>

          <FlexColumn
            size={{
              xs: 6,
              md: 3,
            }}
          >
            <Flex column>
              <FooterHeadline
                size="small"
                color="muted"
              >
                {toUpperCase(App.settings.brand_name)}
              </FooterHeadline>
              <FooterLink href={App.settings.routes.about}>
                {t("footer.About us")}
              </FooterLink>
              <FooterLink href={App.settings.blog_url}>
                {t("footer.Blog")}{" "}
              </FooterLink>
              <FooterLink href={App.settings.routes.imprint}>
                {t("footer.Imprint")}
              </FooterLink>
              <FooterLink href={App.settings.routes.privacy}>
                {t("footer.Privacy policy")}
              </FooterLink>
              <FooterLink href={App.settings.routes.cookie_policy}>
                {t("footer.Cookie policy")}
              </FooterLink>
              <FooterLink href={App.settings.routes.payments_policy}>
                {t("footer.Payments policy")}
              </FooterLink>
              <FooterLink
                target="_blank"
                rel="noreferrer"
                href="https://whistleblowersoftware.com/secure/94bfaa16-645d-4742-9377-47b11f907446"
              >
                {t("footer.whistleblower program")}
              </FooterLink>
              {App.settings.jobs_url && (
                <FooterLink href={App.settings.jobs_url}>
                  {t("footer.Jobs")}
                </FooterLink>
              )}
              {App.settings.boligmanager_url && (
                <FooterLink href={App.settings.boligmanager_url}>
                  Boligmanager
                </FooterLink>
              )}
              {App.settings.market === Market.BOLIGPORTAL_DK && (
                <FooterLink href="https://data-insights.dk/">
                  {t("footer.Data Insights")}
                </FooterLink>
              )}
            </Flex>
          </FlexColumn>
          <FlexColumn
            size={{
              xs: 6,
              md: 3,
            }}
          >
            <Flex
              mb={2}
              column
            >
              <FooterHeadline
                size="small"
                color="inverted"
              >
                {toUpperCase(t("footer.Support"))}
              </FooterHeadline>
              <FooterLink href={App.settings.rent_out_room_url_absolute}>
                {t("footer.rent out room")}
              </FooterLink>
              <FooterLink href={App.settings.move_in_report_url_absolute}>
                {t("footer.move in report")}
              </FooterLink>
              <FooterLink href={App.settings.move_out_report_url_absolute}>
                {t("footer.move out report")}
              </FooterLink>

              {App.settings.market === Market.BOLIGPORTAL_DK && (
                <FooterLink href={App.settings.sublease_contract_url_absolute}>
                  {t("footer.sublease contract")}
                </FooterLink>
              )}
              <FooterLink
                href={App.settings.routes.renting_out_sublease_landingpage}
              >
                {t("footer.renting_out_sublease_landingpage")}
              </FooterLink>

              <FooterLink
                target="_blank"
                href={App.settings.support_url}
              >
                {t("footer.contact and help")}
              </FooterLink>

              <FooterLink href={App.settings.routes.terms}>
                {t("footer.Terms and conditions")}
              </FooterLink>
              <Box mb={1}>
                <FooterTextWithDialog />
              </Box>
            </Flex>
          </FlexColumn>
          <FlexColumn
            size={{
              xs: 12,
              md: marketIsBoligPortal() ? 3 : 6,
              lg: marketIsBoligPortal() ? 4 : 6,
            }}
          >
            <Flex
              mb={2}
              column
              justify={{
                xs: "start",
                md: "end",
              }}
              textAlign={{
                xs: "left",
                md: "right",
              }}
            >
              <FooterHeadline size="small">
                {toUpperCase(t("footer.social_media"))}
              </FooterHeadline>

              <Flex
                wrap
                gap={1}
                mt={1}
                justify={{
                  xs: "start",
                  md: "end",
                }}
              >
                <StyledA
                  href={facebookUrl()}
                  target="_blank"
                  aria-label={`Facebook - ${App.settings.brand_name}`}
                >
                  <CustomImgWrapper
                    align="center"
                    justify="center"
                    border
                    borderRadius="sm"
                  >
                    <SvgFacebookLogo
                      height="20px"
                      width="20px"
                      fill="white"
                    ></SvgFacebookLogo>
                  </CustomImgWrapper>
                </StyledA>
                <StyledA
                  href={linkedInUrl()}
                  target="_blank"
                  aria-label={`LinkedIn - ${App.settings.brand_name}`}
                >
                  <CustomImgWrapper
                    align="center"
                    justify="center"
                    border
                    borderRadius="sm"
                  >
                    <SvgLinkedInLogo
                      height="20px"
                      width="20px"
                      fill="white"
                    ></SvgLinkedInLogo>
                  </CustomImgWrapper>
                </StyledA>
                {App.settings.market === Market.BOLIGPORTAL_DK && (
                  <StyledA
                    href={instagramUrl()}
                    target="_blank"
                    aria-label={`Instagram - ${App.settings.brand_name}`}
                  >
                    <CustomImgWrapper
                      align="center"
                      justify="center"
                      border
                      borderRadius="sm"
                    >
                      <SvgInstagramLogo
                        height="20px"
                        width="20px"
                        fill="white"
                      ></SvgInstagramLogo>
                    </CustomImgWrapper>
                  </StyledA>
                )}
              </Flex>
            </Flex>
          </FlexColumn>

          <AppBadges desktop={false} />
        </Flex>

        <StyledDivider />

        <Flex>
          <StyledLogoWrapper>
            <a
              href={App.settings.routes.index}
              aria-label={App.settings.brand_name}
            >
              <Logo
                width="140px"
                height="100%"
                market={App.settings.market}
                inverse
              />
            </a>
          </StyledLogoWrapper>

          <Flex
            align="center"
            flexPush
            gap={0.5}
          >
            {excludeCurrentLanguageFromAll().map((link) => (
              <CustomA
                href={link.href}
                key={link.href}
              >
                <Flex align="center">
                  <Box>
                    <Flag language={link.text} />
                  </Box>
                  <Box>{link.text}</Box>
                </Flex>
              </CustomA>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  </StyledFooter>
);

export { Footer };
