import { useState } from "react";
import { Dialog, Notice } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { useRegisterPaywall } from "features/paywall/PaywallProvider";
import {
  PlanAndPaymentSelectorDialog,
  PlanAndPaymentSelectorDialogView,
} from "features/paywall/coordinators/PlanAndPaymentSelectorDialog";
import { PaywallSignupOrLogin } from "features/paywall/coordinators/sign_in_log_in/PaywallSignupOrLogin";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";

type Props = {
  UNIQUE_PAYWALL_ID: string;
  relatedAdId?: number;
  relatedRentableId?: number;
  isSignedIn: boolean;
  signInUseCase: SignupContext;
  paywallTitle: string;
  plans: Plan[];
  renderButton: (onButtonClick: () => void) => JSX.Element;
  isFeatureUnlocked: () => boolean;
  onTriggerFeature: () => void;
  onRefetchPlans: () => void;
  /**
   * Called before the `onTriggerFeature` method. This function is triggered when a subscription is activated.
   * Consumers should avoid reloading page properties in this callback as it can lead to errors, such as the loss
   * of registered paywall information, essential for the `onTriggerFeature` method. A common use case for this
   * callback is to trigger tracking events related to the subscription activation.
   *
   * @param {Function} [onSubscriptionActivated] - A callback function that is executed when a subscription is activated.
   * Typically used for triggering tracking events.
   */
  onSubscriptionActivated?: () => void;
};

export const PaywallTrigger = ({
  UNIQUE_PAYWALL_ID,
  isSignedIn,
  signInUseCase,
  paywallTitle,
  plans,
  relatedAdId,
  relatedRentableId,
  renderButton,
  isFeatureUnlocked,
  onTriggerFeature,
  onRefetchPlans,
  onSubscriptionActivated,
}: Props) => {
  const [planAndPaymentSelection, setPlanAndPaymentSelection] = useState<{
    type?: PaymentMethodType;
    plan: Plan;
  } | null>(null);
  const [showPlanAndPaymentSelector, setShowPlanAndPaymentSelector] =
    useState(false);
  const [noticeText, setNoticeText] = useState<string>();

  const { paymentMethods, setPaywallCandidate } = useRegisterPaywall(
    UNIQUE_PAYWALL_ID,
    plans,
    {
      onSubscriptionActivated: () => {
        onSubscriptionActivated?.();
      },
      onPaymentFailedRetryButtonClick: () => {
        onRefetchPlans();
        setPlanAndPaymentSelection(null);
        setShowPlanAndPaymentSelector(true);
      },
      onPaymentSuccessContinueButtonClick: () => {
        setShowPlanAndPaymentSelector(false);
        setPlanAndPaymentSelection(null);
        onTriggerFeature();
      },
    },
  );

  return (
    <>
      {renderButton(() => {
        const isUnlocked = isFeatureUnlocked();
        if (isUnlocked) {
          onTriggerFeature();
        } else {
          setShowPlanAndPaymentSelector(true);
        }
      })}
      {showPlanAndPaymentSelector && (
        <PlanAndPaymentSelectorDialog
          renderNoticeSlot={(selectedPlanId) =>
            renderAlreadyUsedIntroAlert(selectedPlanId, noticeText)
          }
          title={paywallTitle}
          plans={plans}
          paymentMethods={paymentMethods}
          onClose={() => {
            setShowPlanAndPaymentSelector(false);
            setPlanAndPaymentSelection(null);
          }}
          initialView={
            isSignedIn && planAndPaymentSelection?.plan
              ? PlanAndPaymentSelectorDialogView.PAYMENT_METHOD_VIEW
              : PlanAndPaymentSelectorDialogView.PLAN_SELECTION_VIEW
          }
          onChoosePaymentMethod={(paymentMethod) => {
            if (isSignedIn) {
              setPaywallCandidate({
                plan: paymentMethod.plan,
                paymentMethodType: paymentMethod.type,
                adId: relatedAdId ?? null,
                rentableId: relatedRentableId ?? null,
                title: paywallTitle,
                uniquePaywallKey: UNIQUE_PAYWALL_ID,
              });
              setShowPlanAndPaymentSelector(false);
            } else {
              setPlanAndPaymentSelection(paymentMethod);
              setShowPlanAndPaymentSelector(false);
            }
          }}
          initialSelectedPlanId={planAndPaymentSelection?.plan.plan_id}
        />
      )}
      {!showPlanAndPaymentSelector &&
        planAndPaymentSelection &&
        !isSignedIn && (
          <Dialog
            open
            size="564px"
            onClose={() => {
              setPlanAndPaymentSelection(null);
              setShowPlanAndPaymentSelector(false);
            }}
          >
            <PaywallSignupOrLogin
              selectedPlanId={planAndPaymentSelection.plan.plan_id}
              authUsecase={signInUseCase}
              onAuthenticatedAndHasPremiumAccess={() => {
                onTriggerFeature();
                setPlanAndPaymentSelection(null);
                setShowPlanAndPaymentSelector(false);
              }}
              onAuthenticatedAndAlreadyUsedIntroPlan={() => {
                setNoticeText(
                  t("product_explainer.info.user_already_used_intro"),
                );
                onRefetchPlans();
                setPlanAndPaymentSelection(null);
                setShowPlanAndPaymentSelector(true);
              }}
              onAuthenticated={() => {
                setPaywallCandidate({
                  plan: planAndPaymentSelection.plan,
                  paymentMethodType: planAndPaymentSelection.type,
                  adId: relatedAdId ?? null,
                  rentableId: relatedRentableId ?? null,
                  title: paywallTitle,
                  uniquePaywallKey: UNIQUE_PAYWALL_ID,
                });
                setPlanAndPaymentSelection({
                  plan: planAndPaymentSelection.plan,
                  type: planAndPaymentSelection.type,
                });
                setShowPlanAndPaymentSelector(true);
              }}
            />
          </Dialog>
        )}
    </>
  );
};

const renderAlreadyUsedIntroAlert = (
  selectedPlanId: number | null,
  alreadyUsedIntroAlert?: string,
) => {
  if (selectedPlanId === null && alreadyUsedIntroAlert) {
    return <Notice type="info">{alreadyUsedIntroAlert}</Notice>;
  }
  return null;
};
