// TODO: Make decision about better Password Strength
/**
 *
 * @param inputValue
 *
 * CURRENT PASSWORD VALIDATION STRATEGY
 *
 * - minimum length: 8
 * - password can't be entirely numeric
 *
 */
export const passwordValidation = (inputValue: string): boolean => {
  if (inputValue.length < 8) {
    return false;
  }

  const numbersOnly = /^\d+$/.test(inputValue);
  if (numbersOnly) {
    return false;
  }

  return true;
};

/**
 *
 * @param inputValue
 *
 * CURRENT EMAIL VALIDATION STRATEGY
 *
 * - based on the Javascript regex here: https://emailregex.com
 *
 */
export const emailValidation = (inputValue: string): boolean => {
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(inputValue);
};

/**
 *
 * @param inputValue
 *
 * BASIC FULL NAME VALIDATION
 *
 * - test for at at least 2 words of min 3 characters length and ignore trailing spaces
 *
 */
export const fullNameValidation = (inputValue: string): boolean => {
  const pattern = /(.+\s).+$/;
  return pattern.test(inputValue);
};

export const maxThreeMonthlyRentValidation =
  (monthly_rent: number) => (value: number) => {
    if (isNaN(value)) {
      return true;
    }
    return value <= 3 * monthly_rent;
  };
export const noMaxMonthlyRentValidation = () => (value: number) => {
  if (isNaN(value)) {
    return true;
  }
  return value >= 0 || value === null || !value;
};
