import { useState, useEffect } from "react";
import {
  Button,
  DialogFooter,
  DialogContent,
  Flex,
  CheckField,
  Box,
  Notice,
  Divider,
} from "@boligportal/juice";
import { useUpdateExistingTenancy } from "api/mutations";
import { t } from "lib/i18n";
import {
  getTenancyBaseDiffSelectionFrom,
  getTenancyTenantsDiffSelectionFrom,
  makeAllBaseFieldsChecked,
  makeAllTenantFieldsChecked,
  makeNewStateForTenancyBaseDiff,
  makeNewStateForTenancyTenantsDiff,
  makeTenancyBasePayloadFrom,
  makeTenancyTenantPayloadFrom,
} from "../helpers";
import {
  BeforeAfterItemType,
  DiffSelection,
  TenancyBaseDiff,
  TenancyDiff,
  TenancyTenantDiff,
  TenantBeforeAfterItemType,
} from "../types";
import { CheckableTenancyBaseDiffView } from "./CheckableTenancyBaseDiffView";
import { CheckableTenancyTenantsDiffView } from "./CheckableTenancyTenantsDiffView";

type Props = {
  tenancyId: number;
  tenancyBaseDiff: TenancyDiff["tenancy_base_diff"];
  tenancyTenantsDiff: TenancyDiff["tenancy_tenants_diff"];
  noticeDescription: string;
  onCancel: () => void;
  onUpdateSuccess?: () => void;
};

export const DialogContentWithInternalState = ({
  tenancyId,
  tenancyBaseDiff,
  tenancyTenantsDiff,
  noticeDescription,
  onCancel,
  onUpdateSuccess,
}: Props) => {
  const { mutate, isLoading } = useUpdateExistingTenancy();
  const [diffSelection, setDiffSelection] = useState<DiffSelection>("all");

  const [checkableTenancyBaseDiff, setCheckableTenancyBaseDiff] =
    useState<TenancyBaseDiff>(() =>
      makeAllBaseFieldsChecked(tenancyBaseDiff, diffSelection === "all"),
    );
  const [checkableTenancyTenantsDiff, setCheckableTenancyTenantsDiff] =
    useState<TenancyTenantDiff[]>(() =>
      makeAllTenantFieldsChecked(tenancyTenantsDiff, diffSelection === "all"),
    );

  const save = () => {
    mutate(
      {
        tenancyId: tenancyId,
        tenancyBasePayload: makeTenancyBasePayloadFrom(
          checkableTenancyBaseDiff,
        ),
        tenancyTenantPayload: makeTenancyTenantPayloadFrom(
          checkableTenancyTenantsDiff,
        ),
      },
      {
        onSuccess: onUpdateSuccess,
        onSettled: onCancel,
      },
    );
  };

  const onHandleBaseChange = (
    item: BeforeAfterItemType,
    isChecked: boolean,
  ) => {
    setCheckableTenancyBaseDiff(
      makeNewStateForTenancyBaseDiff(checkableTenancyBaseDiff, item, isChecked),
    );
  };

  const onHandleTenancyTentantChange = (
    item: TenantBeforeAfterItemType,
    isChecked: boolean,
  ) => {
    setCheckableTenancyTenantsDiff(
      makeNewStateForTenancyTenantsDiff(
        checkableTenancyTenantsDiff,
        item,
        isChecked,
      ),
    );
  };

  useEffect(() => {
    const baseChecked = getTenancyBaseDiffSelectionFrom(
      checkableTenancyBaseDiff,
    );
    const tenantsChecked = getTenancyTenantsDiffSelectionFrom(
      checkableTenancyTenantsDiff,
    );

    if (baseChecked === "all" && tenantsChecked === "all") {
      setDiffSelection("all");
    } else if (baseChecked === "none" && tenantsChecked === "none") {
      setDiffSelection("none");
    } else {
      setDiffSelection("some");
    }
  }, [checkableTenancyBaseDiff, checkableTenancyTenantsDiff]);

  return (
    <>
      <DialogContent>
        <Notice type="info">{noticeDescription}</Notice>
        <Box py={1}>
          <CheckField
            label={t("tenancy.diff_update.check_all")}
            checked={diffSelection !== "none"}
            indeterminate={diffSelection === "some"}
            onChange={() => {
              const isChecked = diffSelection !== "all";
              setCheckableTenancyBaseDiff(
                makeAllBaseFieldsChecked(checkableTenancyBaseDiff, isChecked),
              );
              setCheckableTenancyTenantsDiff(
                makeAllTenantFieldsChecked(
                  checkableTenancyTenantsDiff,
                  isChecked,
                ),
              );
            }}
          />
        </Box>
        <Divider />
        {checkableTenancyBaseDiff && (
          <CheckableTenancyBaseDiffView
            diff={checkableTenancyBaseDiff}
            onChange={onHandleBaseChange}
          />
        )}
        {checkableTenancyTenantsDiff && (
          <CheckableTenancyTenantsDiffView
            diff={checkableTenancyTenantsDiff}
            onChange={onHandleTenancyTentantChange}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onCancel}
          >
            {t("Cancel")}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading || diffSelection === "none"}
            variant="primary"
            onClick={save}
          >
            {t("tenancy.diff_update.save_all_checked")}
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};
