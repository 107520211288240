import { IconClose } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import {
  getFormattedPlanDuration,
  getPlanTitle,
  isPlanPopular,
  isTrial,
} from "components/plan_utils";
import { format } from "date-fns";
import { SeekerPlanItem } from "features/paywall/components/PlanSelector";
import { getDateFnsLocale } from "lib/date";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";

export const createSeekerPlanItemsDK = (plans: Plan[]): SeekerPlanItem[] =>
  plans.map((plan) => ({
    planId: plan.plan_id,
    planCardItem: {
      planName: getPlanTitle(plan),
      planInfo: getPlanInfo(plan),
      planSelectedInfoSlot: getSelectedInfoComponent(plan),
      planSelectedInfoIcon: getSelectedInfoIcon(plan),
      priceFormatted: getPlanPriceFormatted(plan),
      badgeText: getPlanBadgeText(plan),
      priceInfo: getPriceInfo(plan),
    },
  }));

export const createSeekerPlanItemsSE = (plans: Plan[]): SeekerPlanItem[] =>
  plans.map((plan) => ({
    planId: plan.plan_id,
    planCardItem: {
      planName: getPlanTitle(plan),
      planInfo: getPlanInfo(plan),
      planSelectedInfoSlot: null,
      planSelectedInfoIcon: null,
      priceFormatted: getPlanPriceFormatted(plan),
      badgeText: getPlanBadgeText(plan),
      priceInfo: getPriceInfo(plan),
    },
  }));

const getSelectedInfoComponent = (plan: Plan) => {
  if (isTrial(plan) && plan.next_plan) {
    return (
      <div className="text-xs leading-snug">
        {t("product_explainer.plan.trial_plan.inbox_disclaimer")}
      </div>
    );
  }
  return undefined;
};

const getSelectedInfoIcon = (plan: Plan) => {
  if (isTrial(plan) && plan.next_plan) {
    return <IconClose color="danger" />;
  }
  return undefined;
};

const getPlanInfo = (plan: Plan) => {
  if (isTrial(plan) && plan.next_plan) {
    return t("product_explainer.plan.trial_plan.explainer_text", {
      plan_price: formatCurrency(plan.next_plan.price.total, plan.currency, {
        round: true,
      }),
      duration_days: format(
        new Date().setDate(plan.next_plan.duration_days),
        "do",
        {
          locale: getDateFnsLocale(),
        },
      ),
    });
  }
  return t("product_explainer.plan.subscriptions_continue.full_access.text");
};

const getPlanPriceFormatted = (plan: Plan) =>
  formatCurrency(plan.price.total, plan.currency, {
    round: true,
  });

const getPriceInfo = (plan: Plan) => {
  if (!isTrial(plan) && plan.next_plan) {
    return getFormattedPlanDuration(plan);
  }
  return undefined;
};

const getPlanBadgeText = (plan: Plan) => {
  if (isPlanPopular(plan)) {
    return t("product_explainer.plan.popular_badge");
  }
  return undefined;
};
