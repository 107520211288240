import * as React from "react";
import { Badge, Box, Text } from "@boligportal/juice";
import { EyeOpened } from "components/icons/eye_opened";
import { useAdStatusFeature } from "features/cs/ad_status_feature/hooks/use_ad_status_feature";
import { AdStates } from "lib/enums";
import { Claimer } from "../pages/ad_detail_page/interfaces";
import { ReviewReason } from "../pages/ads_page/interfaces";

const AdStateLabel = ({
  size = "tiny",
  claimer,
  needsFollowUp,
  reviewReason,
  state,
  isDeleted,
}: {
  claimer: Claimer | null;
  state: AdStates;
  needsFollowUp: boolean;
  reviewReason: null | ReviewReason;
  size?: React.ComponentProps<typeof Text>["size"];
  isDeleted: boolean;
}) => {
  const { adStatusColor, adStatusText } = useAdStatusFeature(
    state,
    needsFollowUp,
    reviewReason,
  );
  const claimerName = claimer?.first_name ?? claimer?.email ?? null;

  return (
    <Text size={size}>
      <Box inline>
        <Badge color={adStatusColor}>{adStatusText}</Badge>
        {isDeleted && <Badge color="red">Deleted</Badge>}
        {claimer && (
          <Box>
            <Text size="tiny">
              <EyeOpened /> {claimerName}
            </Text>
          </Box>
        )}
      </Box>
    </Text>
  );
};
export { AdStateLabel };
