export const Paragraph11Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="57"
    height="57"
    viewBox="0 0 32 32"
  >
    <defs>
      <clipPath id="b">
        <rect
          width="32"
          height="32"
        />
      </clipPath>
    </defs>
    <g
      id="a"
      clipPath="url(#b)"
    >
      <g transform="translate(-2.289 1.115)">
        <path
          d="M22.948,29.771H1a.975.975,0,0,1-1-.947V.946A.975.975,0,0,1,1,0H18.492l5.454,5.277V28.823A.975.975,0,0,1,22.948,29.771ZM6.142,24.493l-1.127,0a1.459,1.459,0,0,0,.558,1.23,2.56,2.56,0,0,0,1.563.423,2.442,2.442,0,0,0,1.477-.4,1.274,1.274,0,0,0,.537-1.086,1.364,1.364,0,0,0-.525-1.142,1.157,1.157,0,0,0,.631-1.074,1.38,1.38,0,0,0-.184-.729,1.509,1.509,0,0,0-.565-.523,4.752,4.752,0,0,0-1.1-.413,3.758,3.758,0,0,1-.94-.34.5.5,0,0,1-.214-.436.487.487,0,0,1,.233-.424,1.209,1.209,0,0,1,.662-.155.941.941,0,0,1,.655.214.7.7,0,0,1,.243.555H9.174a1.547,1.547,0,0,0-.53-1.245,2.255,2.255,0,0,0-1.493-.448,2.409,2.409,0,0,0-1.48.416,1.318,1.318,0,0,0-.545,1.095,1.368,1.368,0,0,0,.528,1.142,1.187,1.187,0,0,0-.631,1.082,1.384,1.384,0,0,0,.176.718,1.535,1.535,0,0,0,.538.516,4.6,4.6,0,0,0,1.072.42,3.97,3.97,0,0,1,.962.361.482.482,0,0,1,.254.421.45.45,0,0,1-.226.4,1.2,1.2,0,0,1-.662.15,1.22,1.22,0,0,1-.745-.19.637.637,0,0,1-.25-.539Zm10.114-3.846h0v4.415h1.126V19.282h-.12l-2.35.856v.933l1.344-.424Zm-4.583,0h0v4.415H12.8V19.282h-.12l-2.35.856v.933l1.344-.424ZM3.326,10.826a.676.676,0,0,0,0,1.353H20.62a.676.676,0,0,0,0-1.353Zm0-3.383a.676.676,0,0,0,0,1.353H20.62a.676.676,0,0,0,0-1.353Zm0-3.383a.676.676,0,0,0,0,1.353h11.84a.716.716,0,0,0,.666-.676.716.716,0,0,0-.666-.676Zm4.5,19.061h0l-.865-.265a1.968,1.968,0,0,1-.648-.307.559.559,0,0,1-.16-.424.591.591,0,0,1,.294-.558l.884.281a1.991,1.991,0,0,1,.636.317.51.51,0,0,1,.179.4.611.611,0,0,1-.32.556Z"
          transform="translate(6.316)"
          fill="#fff"
        />
        <path
          d="M47.023,5.305h-5.5V0Z"
          transform="translate(-16.761)"
          fill="#d3d5dd"
        />
      </g>
    </g>
  </svg>
);
