import { useCallback, useState } from "react";
import {
  DropzoneInputProps,
  DropzoneOptions,
  DropzoneRootProps,
  DropzoneState,
  FileRejection as DZFileRejection,
  useDropzone,
  ErrorCode,
} from "react-dropzone";
import {
  Button,
  Center,
  IconArrowUpCircle,
  Spinner,
  styled,
  Text,
  useTheme,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { t } from "lib/i18n";

interface FileRejection extends DZFileRejection {}

export { ErrorCode, FileRejection };

export const disabledOpacity = 0.6;

type S3Config = {
  fields: {
    AWSAccessKeyId: string;
    acl: string;
    key: string;
    policy: string;
    signature: string;
  };
  url: string;
};

export type S3FileUpload = {
  id?: number;
  s3_key?: string;
  name: string;
  type: string;
  size: number;
  url: string | null;
};

interface IProps {
  accept?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  maxFiles?: number;

  /**
   * Maximum file size (in bytes)
   */
  maxSize?: number;

  /**
   * A function that returns a promise that resolves into a valid S3Config response
   */
  configResolver: (totalBytes: number) => Promise<S3Config>;
  /**
   * An error handler when there was an error when getting the config
   */
  onError?: () => void;

  /**
   * Handler that will be called for every file upload to verify it against the backend - resolves into a unique file id
   */
  onFileUpload?: (upload: S3FileUpload) => Promise<number | void>;
  /**
   * Error handler that will be called for every failed file upload
   */
  onFileUploadError?: (file: FileRejection) => void;
  onComplete?: (uploads: S3FileUpload[]) => void;

  /**
   * Render prop for allowing more control of the layout
   */
  children?: (
    props: RenderProps,
    baseLayout: (props: RenderProps) => JSX.Element,
  ) => JSX.Element;
}

const getBorderColor = (
  props: Partial<DropzoneState>,
  theme: ReturnType<typeof useTheme>,
) => {
  if (props.isDragAccept) {
    return theme.colorPalette.primary[400];
  }

  if (props.isDragReject) {
    return theme.colorPalette.red[500];
  }

  return theme.color.border;
};

const Container = styled.div<{ disabled?: boolean }>`
  border-radius: ${(props) => props.theme.borderRadius.md};
  height: 205px;
`;

const Wrapper = styled.div<{
  disabled?: boolean;
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
}>`
  background-color: ${(props) =>
    props.isDragActive ? "#f3f7fd" : props.theme.color.bg.base};
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  overflow: hidden;
  opacity: ${(props) => (props.disabled ? disabledOpacity : 1)};
  padding: ${(props) => props.theme.unit(4)};
  position: relative;
  border: 2px dashed ${(props) => getBorderColor(props, props.theme)};
  transition: border 0.1s ease-in-out;
  border-radius: inherit;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props.theme.colorPalette.gray[30]};
      }
    `}
`;

const DragOverlay = styled(Center)`
  background-color: #f3f7fd;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.fixed};
`;

export const S3DirectUpload = ({
  accept,
  disabled,
  multiple,
  maxFiles,
  maxSize,
  configResolver,
  onError,
  onFileUpload,
  onFileUploadError,
  onComplete,
  children,
}: IProps) => {
  const onDrop = useCallback<Required<DropzoneOptions>["onDrop"]>(
    (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length) {
        uploadFiles(acceptedFiles);
      }

      if (fileRejections.length) {
        fileRejections.forEach(
          (fileRejection) =>
            onFileUploadError && onFileUploadError(fileRejection),
        );
      }
    },
    [],
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    accept,
    disabled,
    multiple,
    maxFiles,
    maxSize,
    onDrop,
  });

  const [uploading, setUploading] = useState(false);

  const formDataForFile = (file: File, config: S3Config) => {
    const formData = new FormData();

    Object.keys(config.fields).forEach((field) => {
      if (field === "key") {
        formData.append(
          "key",
          config.fields.key
            .replace("${filename}", file.name)
            .replace(/[^\w\/.-]/g, ""),
        );
      } else {
        formData.append(field, config.fields[field]);
      }
    });

    formData.append("file", file);
    return formData;
  };

  const uploadFile = async (
    file: File,
    config: S3Config,
  ): Promise<S3FileUpload> => {
    const formData = formDataForFile(file, config);

    try {
      const response = await fetch(config.url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const upload: S3FileUpload = {
          name: file.name.trim(),
          s3_key: formData.get("key")?.toString(),
          size: file.size,
          type: file.type,
          url: response.headers.get("location"),
        };

        if (onFileUpload) {
          try {
            const fileId = await onFileUpload(upload);
            if (fileId) {
              upload.id = fileId;
            }
          } catch (error) {
            if (onFileUploadError) {
              onFileUploadError({
                file,
                errors: [
                  {
                    message: "onFileUpload failed",
                    code: "onfileupload-failed",
                  },
                ],
              });
            }
          }
        }

        return upload;
      } else {
        if (onFileUploadError) {
          onFileUploadError({
            file,
            errors: [
              {
                message: "S3 Upload failed",
                code: "s3-upload-failed",
              },
            ],
          });
        }
      }
    } catch (error) {
      if (onFileUploadError) {
        onFileUploadError({
          file,
          errors: [
            {
              message: "S3 Upload failed",
              code: "s3-upload-failed",
            },
          ],
        });
      }
    }

    return Promise.reject();
  };

  const uploadFiles = async (files: File[]) => {
    setUploading(true);

    const totalBytes = files
      .map((file) => file.size)
      .reduce((acc, curr) => acc + curr);

    const config = await configResolver(totalBytes).catch(() => onError?.());

    if (!config) {
      setUploading(false);
      return;
    }

    const uploads = await Promise.all(
      files.map((file) => uploadFile(file, config)),
    );

    setUploading(false);

    if (onComplete) {
      onComplete(uploads);
    }
  };

  const props = {
    disabled,
    isDragActive,
    uploading,
    open,
    rootProps: getRootProps({
      isDragAccept,
      isDragActive,
      isDragReject,
    }),
    inputProps: getInputProps(),
  };

  if (children) {
    return <Container>{children(props, Layout)}</Container>;
  }

  return (
    <Container>
      <Layout {...props} />
    </Container>
  );
};

type RenderProps = {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  uploading: boolean;
  isDragActive: boolean;
  open: () => void;
} & Pick<IProps, "disabled">;

const Layout = ({
  disabled,
  rootProps,
  inputProps,
  uploading,
  isDragActive,
  open,
}: RenderProps) => {
  const theme = useTheme();

  return (
    <Wrapper
      disabled={disabled}
      {...rootProps}
    >
      {uploading ? (
        <Spinner />
      ) : (
        <>
          <input {...inputProps} />

          {isDragActive && (
            <DragOverlay>{t("uploader.drop_files_to_upload")}</DragOverlay>
          )}

          <div
            onClick={async (event) => {
              event.stopPropagation();
              if (disabled) {
                return;
              }
              open();
            }}
          >
            <Center>
              <IconArrowUpCircle
                css={css`
                  color: ${theme.colorPalette.gray[100]};
                  font-size: 50px;
                  margin-bottom: ${theme.unit(1)};
                `}
              />

              <Button variant="default">{t("uploader.browse")}</Button>

              <Text
                color="muted"
                pt={2}
                size="small"
              >
                {t("uploader.or_drop_files")}
              </Text>
            </Center>
          </div>
        </>
      )}
    </Wrapper>
  );
};
