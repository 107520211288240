import * as React from "react";
import { Flex } from "@boligportal/juice";
import { SortableImage } from "../interfaces/SortableImage";

export const ImageItem = ({
  image,
  isDragging,
  styles,
}: {
  image: SortableImage;
  isDragging?: boolean;
  styles?: React.CSSProperties;
}) => (
  <div
    style={{
      ...styles,
      boxShadow: isDragging ? "0px 7px 16px 5px rgba(0,0,0,0.29)" : undefined,
    }}
  >
    <Flex
      width="100%"
      height="100%"
      column
      bg="tint"
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${image.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    </Flex>
  </div>
);
