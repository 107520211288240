import { t } from "lib/i18n";
import { CrawlingDraftForm } from "../interfaces/CrawlingDraftForm";

export const createInitialFieldValuesFromData = (
  data: any,
): Partial<CrawlingDraftForm> => {
  const result: Partial<CrawlingDraftForm> = {
    available_from: data.available_from,
    available_from_option: data.available_from ? "date" : "asap",
    balcony: data.balcony,
    category: t("Choose category"),
    description: data.description,
    deposit: data.deposit,
    elevator: data.elevator,
    furnished: data.furnished,
    monthly_rent: data.monthly_rent,
    monthly_rent_extra_costs: data.monthly_rent_extra_costs,
    parking: data.parking,
    pet_friendly: data.pet_friendly,
    prepaid_rent: data.prepaid_rent,
    rental_period: data.rental_period,
    rooms: data.rooms,
    senior_friendly: data.senior_friendly,
    shareable: data.shareable,
    size_m2: data.size_m2,
    student_only: data.student_only,
    title: data.title,
  };

  return result;
};
