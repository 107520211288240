import * as React from "react";
import { add, isBefore } from "date-fns";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import {
  Action_hidewMobileFilters,
  Action_showAgentModal,
} from "../search_result_reducer";

const srpWithCityFilterCountKey = "SRP_Visit_Count";
const searchAgentPopupLastShownDateKey = "Search_agent_shown_at";

const adDetailPageViewCountKey = "Ad_Detail_Page_Count";

/** Used for counting number of views for ad detail page. Which is used to trigger the SearchAgentPopup on the SRP. */
export const useCountAdDetailPageViews = () => {
  const [count, setCount] = useLocalStorageState(adDetailPageViewCountKey, 0);

  const incrementViewCount = React.useCallback(
    () => setCount((count) => count + 1),
    [setCount],
  );

  return {
    count,
    incrementViewCount,
  };
};

/** Count number of SRP views with a city as filter */
const useCountCitySrpPageViews = (
  currentUrl: string,
  hasCityLevelValues: boolean,
) => {
  const [count, setCount] = useLocalStorageState(srpWithCityFilterCountKey, 0);

  React.useEffect(() => {
    if (hasCityLevelValues) {
      setCount((count) => count + 1);
    }
  }, [currentUrl, hasCityLevelValues, setCount]);

  return {
    count,
  };
};

const searchAgentPopupFrequencyInHours = 24;

/**
 * Displays a Search Agent Popup based on the following conditions:
 * The popup is shown once everytime the searchAgentPopupFrequencyInHours time has gone by.
 * User must either have made:
 * - At least 2 searches with a city filter.
 * - At least 1 search with a city filter and seen one Ad Detail page.
 *
 * The original intent was to simply display the popup after two searches with a city filter. The main issue is that
 * going from an SRP to an Ad detail page, and then using the browsers back button, will in some browsers serve
 * the user a cached version of the page before, which also uses a cached version of localStorage - meaning the count
 * doesnt' get incremeted, and therefore the popup doesn't get triggered when using the browser back button.
 */
export const useShowSearchAgentPopup = (
  currentUrl: string,
  agentId: number | null,
  hasCityLevelValues: boolean,
  dispatch: React.Dispatch<Action_showAgentModal | Action_hidewMobileFilters>,
) => {
  const { count: adDetailPageViewCount } = useCountAdDetailPageViews();
  const { count: citySrpViewCount } = useCountCitySrpPageViews(
    currentUrl,
    hasCityLevelValues,
  );
  const [searchAgentPopupLastShownDate, setSearchAgentPopupLastViewDate] =
    useLocalStorageState(searchAgentPopupLastShownDateKey, null);

  React.useEffect(() => {
    const shouldShowPopup =
      citySrpViewCount >= 2 ||
      (citySrpViewCount >= 1 && adDetailPageViewCount >= 1);
    if (agentId || !shouldShowPopup || !hasCityLevelValues) {
      return;
    }

    // Passing null to new Date is okay since it will just initialize it to be Jan 01 1970.
    const lastTimeSearchAgentWasShown = new Date(searchAgentPopupLastShownDate);
    const nextTimeToShowSearchAgentAt = add(lastTimeSearchAgentWasShown, {
      hours: searchAgentPopupFrequencyInHours,
    });
    const now = new Date();

    // If it hasn't been long enough since we triggered the agent last, abort.
    if (isBefore(now, nextTimeToShowSearchAgentAt)) {
      return;
    }

    const timeout = setTimeout(() => {
      setSearchAgentPopupLastViewDate(now);
      dispatch({
        type: "hideMobileFilters",
      });
      dispatch({
        type: "showAgentModal",
      });
    }, 2000);

    return () => clearTimeout(timeout);
  }, [
    agentId,
    citySrpViewCount,
    adDetailPageViewCount,
    dispatch,
    hasCityLevelValues,
    searchAgentPopupLastShownDate,
    setSearchAgentPopupLastViewDate,
  ]);
};
