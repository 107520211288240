import * as React from "react";

export const useIntersectionObserver = (
  elementRef: React.RefObject<HTMLElement>,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false },
) => {
  const [entry, setEntry] = React.useState<any>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  React.useEffect(() => {
    const node = elementRef?.current;

    // eslint-disable-next-line compat/compat
    const isSupported = !!window.IntersectionObserver;

    if (!isSupported || frozen || !node) {
      return;
    }

    const observerParams = {
      threshold,
      root,
      rootMargin,
    };
    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
};
