import { App } from "components/app";
import { PaymentFeedItemInterface } from "../user_detail_page/subscriptions_feed/interfaces";
import { IPaymentsTableItem } from "./payments_page";

export const createDataproviderForPaymentsFeed = (
  items: PaymentFeedItemInterface[],
): IPaymentsTableItem[] => {
  const paymentItems: IPaymentsTableItem[] = [];

  items.forEach((item) => {
    paymentItems.push({
      user: {
        id: item.user_id !== null ? item.user_id : null,
        fullname: `${item.first_name} ${item.last_name}`,
        username: item.username,
        user_ban_reasons: item.user_ban_reasons,
      },
      status: item.total_refunded > 0 ? "REFUNDED" : item.status,
      createdDate: item.date,
      paymentId: item.payment_id,
      orderId: item.order_id,
      paymentMethod: item.payment_method,
      paymentMethodType: item.payment_method_type,
      product: {
        isFreeProduct: item.amount > 0 == false,
        priceFormatted: `${item.amount} ${App.settings.currency_symbol}`,
        description: item.duration_days
          ? `${item.duration_days} days duration`
          : "-",
        productName: item.product_name,
      },
      subscriptionId:
        item.subscription_id !== null ? `${item.subscription_id}` : "-",
    });
  });

  return paymentItems;
};
