import { t } from "lib/i18n";

export const createRentableTenancyBreadCrumbs = (config: {
  lastCrumbText: string;
  myRentablesBase: string;
  rentableId: number;
  address: string;
  tenancy: {
    tenancyId: number;
    displayLabel: string;
  } | null;
}) => {
  const { tenancy, address, rentableId, myRentablesBase, lastCrumbText } =
    config;

  const firstLevel = {
    text: t("menu.landlord.my_rentables"),
    href: myRentablesBase,
  };

  const secondLevel = {
    text: address,
    href: `/property-owner/my-rentables/${rentableId}`,
  };

  const last = {
    text: lastCrumbText,
  };

  if (!tenancy) {
    return [firstLevel, secondLevel, last];
  }

  return [
    firstLevel,
    secondLevel,
    {
      text: t("rentables.detail.tabs.tenancies"),
      href: `/property-owner/my-rentables/${rentableId}/tenancies`,
    },
    {
      text: `${tenancy.displayLabel}`,
      href: `/property-owner/my-rentables/${rentableId}/tenancies/${tenancy.tenancyId}`,
    },
    last,
  ];
};
