import * as React from "react";
import { useForm } from "react-hook-form";
import { Box, TextField } from "@boligportal/juice";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { ProfileFormData } from "../helpers/profile_helpers";

interface Props {
  profile: ProfileFormData;
  onProfileChange(profile: ProfileFormData): void;
  setTitle(value: React.SetStateAction<string>): void;
}

export const Description = ({ profile, onProfileChange, setTitle }: Props) => {
  const { register, getValues, setValue } = useForm<ProfileFormData>({
    mode: "onChange",
    defaultValues: profile,
  });

  React.useEffect(() => {
    setTitle(t("profile_completion.description_modal.title"));
    Tracking.trackVirtualPageview(
      "/fill-profile-modal/users-description",
      "Users description",
    );
  }, [setTitle]);

  React.useEffect(() => {
    setValue("description", (profile && profile.description) || "");
  }, [profile, setValue]);

  const handleChange = () => {
    onProfileChange(getValues());
  };

  return (
    <Box>
      <strong
        style={{
          display: "block",
          marginBottom: "15px",
        }}
      >
        {t("profile_completion.description_form.title")}
      </strong>

      <form onChange={handleChange}>
        <TextField
          multiLine={10}
          {...register("description")}
          placeholder={t("profile_completion.description_form.placeholder")}
        />
      </form>
    </Box>
  );
};
