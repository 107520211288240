import { t } from "lib/i18n";

export const getRentalPeriodLabel = (id: number) => {
  switch (id) {
    case 0: {
      return t("rental_period.unlimited");
    }
    case 6: {
      return t("rental_period.half_a_year");
    }
    case 12: {
      return t("rental_period.year");
    }
    case 24: {
      return t("rental_period.two_years");
    }
    default: {
      return t("rental_period.unlimited");
    }
  }
};
