import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  open: boolean;
  paymentRecordId: number;
  onCancel: () => void;
  onConfirm: (paymentRecordId: number) => void;
};

export const PaymentRecordMarkAsManuallyPaidConfirm = ({
  open,
  paymentRecordId,
  onCancel,
  onConfirm,
}: Props) => (
  <Dialog
    open={open}
    size="large"
    onClose={onCancel}
  >
    <DialogHeader>
      {t("payment.mark_as_manually_paid.confirm_dialog.title")}
    </DialogHeader>
    <DialogContent>
      <>
        <Text
          block
          weight="bold"
        >
          {t("common.question.are_you_sure")}
        </Text>
        <Text block>
          {t("payment.mark_as_manually_paid.confirm_dialog.message_text")}
        </Text>
      </>
    </DialogContent>

    <DialogFooter>
      <Flex gap>
        <Button
          variant="subtle"
          onClick={onCancel}
        >
          {t("common.buttons.no_go_cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onConfirm(paymentRecordId);
          }}
        >
          {t("payment.mark_as_manually_paid.confirm_dialog.confirm_text")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
