import { Box, styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { formatCurrency, calculatePrice } from "lib/utils";

type Props = {
  currency: string;
  price: {
    percent_off: number;
    total: number;
    subtotal: number;
    tax: number;
  };
};

export const UpsellCardPriceVAT = ({ price, currency }: Props) => {
  const showPriceBadge = price.percent_off !== 0;
  return (
    <>
      {showPriceBadge && (
        <StyledPriceBadge>
          <Text
            weight="headline"
            size="h4"
          >
            {`-${price.percent_off}%`}
          </Text>
          <Text
            weight="semiBold"
            size="tiny"
          >
            {t("upsell_page.badge.normal_price", {
              price: price.total,
            })}
          </Text>
        </StyledPriceBadge>
      )}
      <Box
        mb={1}
        mt={1}
      >
        <Text
          size="h3"
          block
          weight="headline"
        >
          {formatCurrency(
            calculatePrice(price.total, price.percent_off),
            currency,
          )}
        </Text>
        <Text
          size="small"
          block
          weight="semiBold"
        >
          {t("upsell_page.inc_vat")}
        </Text>
      </Box>
      <Box mb={1}>
        <Text
          size="body"
          block
          weight="semiBold"
          color="muted"
        >
          {formatCurrency(
            calculatePrice(price.subtotal, price.percent_off),
            currency,
          )}
        </Text>
        <Text
          size="small"
          block
          weight="semiBold"
          color="muted"
        >
          {t("upsell_page.ex_vat")}
        </Text>
      </Box>
    </>
  );
};

const StyledPriceBadge = styled.div`
  position: absolute;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colorPalette.yellow[300]};
  top: 65px;
  right: 5px;
  flex-direction: column;
  @media (max-width: 1440px) {
    top: auto;
  }
`;
