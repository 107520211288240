import { styled } from "@boligportal/juice";

export const ScalabaleHeadline = styled.h2`
  font-size: 60px;
  line-height: 1.2em;
  color: #191919;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 42px;
  }
`;
