/* eslint-disable object-curly-newline */

/* eslint-disable comma-dangle */
import { useState, useCallback, useEffect } from "react";
import { Plan } from "components/interfaces/plan";
import { API } from "lib/api";
import { PaymentMethodType } from "../enums/PaymentMethodType";
import { calculateTotalPriceFromPlans } from "../utils/calculateTotalPriceFromPlans";

const usePaymentFeature = (
  plans: Plan[],
  paymentMethodTypes: PaymentMethodType[],
  selectedPaymentMethod?: PaymentMethodType,
) => {
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] =
    useState<PaymentMethodType | null>(selectedPaymentMethod ?? null);
  const autoCheckAccountInfo = paymentMethodTypes.some(
    (value) => value === PaymentMethodType.Account,
  );
  const [isLoadingAccountInfo, setIsLoadingAccountInfo] = useState(false);
  const [canUseAccount, setCanUseAccount] = useState(false);

  const checkAccountInfo = useCallback(async () => {
    setIsLoadingAccountInfo(true);
    const accountInfo = await API.getAccountInfo();
    const purchasableWithAccount =
      accountInfo !== null &&
      accountInfo.enabled &&
      (accountInfo.allow_negative_balance ||
        accountInfo.balance >= calculateTotalPriceFromPlans(plans));
    setCanUseAccount(purchasableWithAccount);
    setIsLoadingAccountInfo(false);

    // Autoselect other payment method when not purchasable with Account
    if (!purchasableWithAccount && paymentMethodTypes.length === 2) {
      const otherAccount = paymentMethodTypes.filter(
        (item) => item !== PaymentMethodType.Account,
      );
      setSelectedPaymentMethodType(otherAccount[0]);
    }
  }, [plans, paymentMethodTypes]);

  useEffect(() => {
    if (autoCheckAccountInfo) {
      checkAccountInfo();
    }
  }, [checkAccountInfo, autoCheckAccountInfo]);

  useEffect(() => {
    if (paymentMethodTypes.length == 1) {
      setSelectedPaymentMethodType(paymentMethodTypes[0]);
    }
  }, [paymentMethodTypes]);

  return {
    selectedPaymentMethodType,
    setSelectedPaymentMethodType,
    isLoadingAccountInfo,
    checkAccountInfo,
    canUseAccount,
  };
};

export { usePaymentFeature };
