import { createRoot, hydrateRoot } from "react-dom/client";
import "@boligportal/juice/dist/bundle.css";
import { AppProps } from "components/interfaces/app_props";
import { initTrackingErrors } from "lib/tracking/errors";
import "./tailwind.css";
import { WrapComponentInApp } from "./wrap_in_app";

const containerId = "app";
const { props } = store;

initTrackingErrors({
  dsn: props.globals.settings.sentry_react_dsn,
  environment: props.globals.settings.env,
  release: process.env.SOURCE_VERSION,
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // Other
    "Network Error",
    "timeout exceeded",
    // Instagram error that Meta isn't fixing but is not impacting users
    // See https://developers.facebook.com/community/threads/320013549791141/
    "_AutofillCallbackHandler",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});

export function renderPage(component, hardcodedLanguage?: string) {
  const container = document.getElementById(containerId);
  if (container !== null) {
    const propsThatMightHaveHardCodedLanguage: AppProps = {
      ...props,
      globals: {
        settings: {
          ...props.globals.settings,
          language: hardcodedLanguage ?? props.globals.settings.language,
        },
      },
    };

    const wrappedComponent = (
      <WrapComponentInApp
        appProps={propsThatMightHaveHardCodedLanguage}
        component={component}
      />
    );

    if (container.children.length > 0) {
      hydrateRoot(container, wrappedComponent);
    } else {
      const root = createRoot(container);
      root.render(wrappedComponent);
    }
  }
}
