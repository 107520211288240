import { useState } from "react";
import { Box, Divider, Flex, IconAdd, Text } from "@boligportal/juice";
import { useGetPaymentRecordDetails } from "api/queries";
import { TenancyPaymentTransaction } from "business/domain/tenancy/TenancyPaymentTransaction";
import { App } from "components/app";
import { WordWrapButton } from "components/buttons/WordWrapButton";
import { CurrencyAmountLabel } from "components/juice-candidates/CurrencyAmountLabel";
import { isPast, isToday } from "date-fns";
import { useTransferArrears } from "features/rent_collection/useTransferArrears";
import { calculateArrears } from "features/rentable_tenancy/helpers/calculateArrears";
import { calculateTenancyPaymentsSum } from "features/rentable_tenancy/helpers/calculateTenancyPaymentsSum";
import { formatArrears } from "features/rentable_tenancy/helpers/formatArrears";
import { formatWithLocale } from "lib/date";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";
import { AddTenantPaymentDialog } from "../../PartialPaymentDialog/AddTenantPaymentDialog";

export const PaymentHistory = ({
  paymentRecordId,
  transactions,
  tenancyId,
}: {
  paymentRecordId: number;
  transactions: TenancyPaymentTransaction[];
  tenancyId?: number;
}) => {
  const {
    getPaymentRecordDetailsQuery: { data },
  } = useGetPaymentRecordDetails(paymentRecordId);
  const { setTransferArrearsCandidate } = useTransferArrears();
  const onTransferArrears = () => {
    if (tenancyId && data) {
      setTransferArrearsCandidate({
        tenancyId,
        paymentRecordId,
        arrears,
        paymentDueDate: data.paymentDueDate,
      });
    }
  };

  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => setShowDialog(true);

  const closeDialog = () => setShowDialog(false);

  if (!data) {
    return null;
  }

  const { rentRegulation, recurringRent, oneTimePayments, paymentType } = data;
  const items = rentRegulation.length > 0 ? rentRegulation : recurringRent;

  const totalRent =
    calculateTenancyPaymentsSum(items) +
    calculateTenancyPaymentsSum(oneTimePayments);

  const { arrears } = calculateArrears(totalRent, transactions);

  const totalPayment = transactions.reduce(
    (acc, transaction) => acc + transaction.amount,
    0,
  );

  const paymentDueDateIsPastAndNotToday =
    arrears < 0 &&
    isPast(new Date(data.paymentDueDate)) &&
    !isToday(new Date(data.paymentDueDate));

  return (
    <>
      {showDialog && (
        <AddTenantPaymentDialog
          open
          onClose={closeDialog}
          arrears={arrears}
          tenancyPaymentRecordId={paymentRecordId}
          transactions={{
            items: transactions,
          }}
          rentAmount={totalRent}
          paymentType={paymentType}
        />
      )}
      {transactions.map((transaction) => {
        const accountingDateFormatted = formatWithLocale(
          new Date(transaction.accounting_date),
          "d. MMM u",
        );

        return (
          <Flex
            key={transaction.id}
            justify="space-between"
            my={2}
          >
            <Text>{`${accountingDateFormatted} ${transaction.label}`}</Text>
            <Text>
              {formatCurrency(transaction.amount, App.settings.currency)}
            </Text>
          </Flex>
        );
      })}

      <Box ml={-1.6}>
        <WordWrapButton
          text
          variant="primary"
          icon={IconAdd}
          onClick={openDialog}
        >
          {t("rentable.rent.payment_history.button.add_new_payment")}
        </WordWrapButton>
      </Box>
      <Flex
        justify="end"
        mt={2}
      >
        <CurrencyAmountLabel
          label={`${t("rentable.rent.payment_history.sum_of_payments")}`}
          amount={totalPayment}
          size="body"
          labelWeight="base"
          emphasizeAmount="headline"
          amountSize="lead"
        />
      </Flex>

      {!!arrears && (
        <>
          <Divider my={3} />
          <Flex
            column
            align="end"
            mt={2}
          >
            <Flex align="baseline">
              <Text color={arrears < 0 ? "danger" : "base"}>
                {arrears > 0
                  ? t("partial_payments.paid_too_much")
                  : t("partial_payments.not_paid_yet")}
              </Text>
              <Text
                color={arrears < 0 ? "danger" : "base"}
                weight="bold"
                ml={1}
                size="lead"
              >
                {formatArrears(arrears)}
              </Text>
            </Flex>
            {paymentDueDateIsPastAndNotToday && (
              <WordWrapButton
                text
                variant="primary"
                onClick={onTransferArrears}
              >
                {t(
                  "rentable.rent.payment_history.button.move_arrears_to_next_payment",
                )}
              </WordWrapButton>
            )}
            <WordWrapButton
              text
              variant="primary"
              icon={IconAdd}
              onClick={openDialog}
            >
              {arrears < 0
                ? t("partial_payments.mark_as_manually_paid")
                : t("partial_payments.mark_as_manually_refund")}
            </WordWrapButton>
          </Flex>
        </>
      )}
    </>
  );
};
