import {
  Button,
  IconEllipsisVertical,
  Menu,
  MenuBaseItem,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { commonRowActionsTrans } from "lib/translations";
import { DangerMenuItem } from "./components/CustomMenuItems";

export enum ContractEllipsisMenuItemOption {
  Delete = "Delete",
  Duplicate = "Duplicate",
  Goto_Builder = "Goto_Builder",
  View_Document = "View_Document",
}

export interface ContractEllipsisMenuItem
  extends MenuBaseItem<ContractEllipsisMenuItem> {
  optionId?: ContractEllipsisMenuItemOption;
}

export type ContractElipsisMenuCell = {
  contractEllipsisMenuCell: {
    onMenuItemSelect: (item: ContractEllipsisMenuItem) => void;
  };
  hasAdditionalMobileActions?: boolean;
  isMigratedContracts?: boolean;
};

export const ContractEllipsisMenuCellRenderer = ({
  contractEllipsisMenuCell,
  hasAdditionalMobileActions = false,
  isMigratedContracts = false,
}: ContractElipsisMenuCell) => {
  const {
    deleteRowActionLabel,
    copyRowActionLabel,
    fillContractRowActionLabel,
  } = commonRowActionsTrans();

  const items: ContractEllipsisMenuItem[] = [];

  if (hasAdditionalMobileActions) {
    if (isMigratedContracts) {
      items.push({
        name: t("contracts.migrated_contract.button.see_documents"),
        optionId: ContractEllipsisMenuItemOption.View_Document,
      });
    } else {
      items.push({
        name: fillContractRowActionLabel,
        optionId: ContractEllipsisMenuItemOption.Goto_Builder,
      });
    }
  }

  items.push({
    optionId: ContractEllipsisMenuItemOption.Duplicate,
    name: copyRowActionLabel,
  });

  items.push({});

  items.push({
    optionId: ContractEllipsisMenuItemOption.Delete,
    name: deleteRowActionLabel,
    renderFunction: DangerMenuItem,
  });

  return (
    <Menu<ContractEllipsisMenuItem>
      align="end"
      verticalContentPadding={0}
      verticalSeparatorPadding={0}
      items={items}
      onMenuItemSelect={contractEllipsisMenuCell.onMenuItemSelect}
      openerEl={
        <Button
          data-testid="contracts-elipsis-menu"
          variant="subtle"
          icon={IconEllipsisVertical}
        />
      }
    />
  );
};
