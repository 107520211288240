import * as React from "react";

const SvgRemove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#CFCFCF"
      d="M3 11h18v2H3z"
    />
  </svg>
);
export default SvgRemove;
