import * as React from "react";

const SvgVisa = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    viewBox="0 0 44 28"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <path
        fill="#FFF"
        stroke="#DDD"
        d="M3 .5A2.5 2.5 0 00.5 3v22A2.5 2.5 0 003 27.5h38a2.5 2.5 0 002.5-2.5V3A2.5 2.5 0 0041 .5H3z"
      />
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M40 26H4V3.007h36V26"
        />
        <path
          fill="#F9AB0C"
          d="M4.6 22.264h34.8v3.137H4.6v-3.137"
        />
        <path
          fill="#1D337A"
          d="M4.6 6.747h34.8V3.61H4.6v3.137m17.1 3.456l-1.835 8.623h-2.218l1.834-8.623h2.218zm9.333 5.568l1.168-3.238.672 3.238h-1.84zm2.477 3.055h2.051l-1.792-8.623h-1.892a1.01 1.01 0 00-.945.632l-3.33 7.99h2.33l.463-1.287h2.846l.27 1.288zm-5.792-2.815c.01-2.276-3.129-2.402-3.108-3.419.007-.309.3-.638.941-.722.318-.041 1.194-.074 2.188.386l.389-1.83a5.954 5.954 0 00-2.075-.38c-2.194 0-3.737 1.17-3.749 2.85-.014 1.241 1.102 1.933 1.941 2.347.866.422 1.156.693 1.151 1.07-.006.58-.69.836-1.326.845-1.117.018-1.763-.303-2.279-.545l-.403 1.89c.52.24 1.476.447 2.467.458 2.331 0 3.856-1.158 3.863-2.95zm-9.189-5.808l-3.594 8.623H12.59l-1.77-6.882c-.107-.423-.2-.578-.526-.757-.534-.292-1.415-.564-2.19-.734l.053-.25h3.776c.48 0 .913.322 1.023.879l.935 4.99 2.308-5.869h2.33z"
        />
      </g>
    </g>
  </svg>
);
export default SvgVisa;
