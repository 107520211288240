import * as React from "react";
import { Box, FlexColumn } from "@boligportal/juice";

export const CellWrapper = ({
  children,
  index,
}: {
  children: React.ReactNode;
  index: number;
}) => (
  <FlexColumn
    size={{
      xs: 12,
      md: 12,
      lg: 6,
    }}
  >
    <Box
      height="100%"
      px={{
        xs: 0,
        md: 1,
      }}
      py={1}
      bg={{
        xs: "base",
        md: index % 2 === 0 ? "tint" : "base",
        lg: Math.floor(index / 2) % 2 === 0 ? "tint" : "base",
      }}
      borderBottom={{
        xs: true,
        md: false,
      }}
    >
      {children}
    </Box>
  </FlexColumn>
);
