/* eslint-disable comma-dangle */
import * as React from "react";
import {
  ChooseOrCreateRentableFeature,
  ChoseRentable,
  initialChooseOrCreateRentableFeature,
} from "./interfaces/ChooseOrCreateRentableFeature";
import {
  ChooseOrCreateRentableState,
  initialChooseOrCreateRentableState,
} from "./interfaces/ChooseOrCreateRentableState";
import { useChooseOrCreateRentable } from "./useChooseOrCreateRentable";

const ChoseOrCreateRentableFeatureContext =
  React.createContext<ChooseOrCreateRentableFeature>(
    initialChooseOrCreateRentableFeature,
  );

const ChoseOrCreateRentableStateContext =
  React.createContext<ChooseOrCreateRentableState>(
    initialChooseOrCreateRentableState,
  );

interface ChooseOrCreateRentableProviderProps {
  onRentableId: ChoseRentable;
  children: React.ReactNode;
}

export const ChooseOrCreateRentableProvider = ({
  children,
  onRentableId,
}: ChooseOrCreateRentableProviderProps) => {
  const { feature, state } = useChooseOrCreateRentable(onRentableId);
  return (
    <ChoseOrCreateRentableFeatureContext.Provider value={feature}>
      <ChoseOrCreateRentableStateContext.Provider value={state}>
        {children}
      </ChoseOrCreateRentableStateContext.Provider>
    </ChoseOrCreateRentableFeatureContext.Provider>
  );
};

export const useChooseOrCreateRentableFeature = () =>
  React.useContext(ChoseOrCreateRentableFeatureContext);

export const useChooseOrCreateRentableState = () =>
  React.useContext(ChoseOrCreateRentableStateContext);
