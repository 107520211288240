import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Flex, SelectField, TextField } from "@boligportal/juice";
import { getFloorOptions } from "apps/property_owner/create_listing_form/helpers/getFloorOptions";
import { ManualAddressRentableEntity } from "features/eco_system/components/ManualAddress/ManualAddressLayout";
import { t } from "lib/i18n";
import { commonFormTrans } from "lib/translations";
import { VALID_DANISH_POSTAL_CODE_REG_EX } from "../common/hooks/postCodes";
import { useFetchAndFillPostalTown } from "../common/hooks/useFetchAndFillPostalTown";

export type AddressManualFormDenmark = ManualAddressRentableEntity & {
  address: {
    door: string;
    floor: string;
    side: string;
  };
};

export const AddressManualDenmark = () => {
  const {
    streetNameLabel,
    houseNumberLabel,
    floorLabel,
    doorLabel,
    doorPlacementLabel,
    postalCodeLabel,
    postalTownLabel,
  } = commonFormTrans();

  const inputfieldIsRequiredTrans = t("required_field.input");

  const allFormMethods = useFormContext<AddressManualFormDenmark>();
  const { register, formState, setValue, watch } = allFormMethods;
  const { errors } = formState;
  const { getAndSetPostalTown } = useFetchAndFillPostalTown(
    setValue,
    "address.postal_town",
    VALID_DANISH_POSTAL_CODE_REG_EX,
  );

  const watchZipCode = watch("address.postal_code");
  const watchSide = watch("address.side");
  const watchDoor = watch("address.door");

  useEffect(() => {
    getAndSetPostalTown(watchZipCode);
  }, [watchZipCode, setValue, getAndSetPostalTown]);

  const disableDoorForm = watchSide !== undefined && watchSide !== "";
  const disableSideForm = watchDoor !== undefined && watchDoor !== "";

  return (
    <>
      <Flex gap>
        <Box width="100%">
          <TextField
            required
            {...register("address.street_name", {
              required: true,
            })}
            label={streetNameLabel}
            errorText={errors.address?.street_name && inputfieldIsRequiredTrans}
          />
        </Box>
        <TextField
          required
          {...register("address.street_number", {
            required: true,
          })}
          label={houseNumberLabel}
          errorText={errors.address?.street_number && inputfieldIsRequiredTrans}
        />
      </Flex>
      <Flex gap>
        <Box width="100%">
          <SelectField
            {...register("address.floor")}
            defaultValue="0"
            label={floorLabel}
            errorText={errors.address?.floor && inputfieldIsRequiredTrans}
            items={getFloorOptions()}
          />
        </Box>

        <Box width="100%">
          <TextField
            {...register("address.door")}
            label={doorLabel}
            disabled={disableDoorForm}
          />
        </Box>
        <Box width="100%">
          <SelectField
            {...register("address.side")}
            label={doorPlacementLabel}
            items={[
              {
                label: "",
                value: "",
              },
              {
                value: "tv",
                label: t("left"),
              },
              {
                value: "th",
                label: t("right"),
              },
              {
                value: "mf",
                label: t("mid"),
              },
            ]}
            disabled={disableSideForm}
          />
        </Box>
      </Flex>
      <Flex gap>
        <TextField
          required
          type="number"
          {...register("address.postal_code", {
            required: true,
          })}
          label={postalCodeLabel}
          errorText={errors.address?.postal_code && inputfieldIsRequiredTrans}
        />
        <TextField
          required
          {...register("address.postal_town", {
            required: true,
          })}
          readOnly
          label={postalTownLabel}
          errorText={errors.address?.postal_town && inputfieldIsRequiredTrans}
        />
      </Flex>
    </>
  );
};
