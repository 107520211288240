import { useCallback } from "react";
import { Control, UseFormGetValues, useFormState } from "react-hook-form";
import { useSaveOnLeave } from "apps/moving_reports/hooks";
import { fromPairs } from "lodash-es";
import { MovingReportPatch } from "../interfaces/movingReportPatch";

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  if (dirtyFields === true) {
    return allValues;
  }

  if (Array.isArray(dirtyFields)) {
    const result: any[] = [];

    for (let index = 0; index < dirtyFields.length; index++) {
      const dirtyField = dirtyFields[index] as any;
      const values = allValues[index] as any;

      if (dirtyField) {
        result.push({
          ...fromPairs(
            Object.keys(dirtyField).map((key) => [
              key,
              dirtyValues(dirtyField[key], values[key]),
            ]),
          ),
          id: values.id,
        });
      }
    }

    return result;
  }

  return fromPairs(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

export const useFormAutosave = <T extends Record<any, any>>(
  control: Control<T>,
  getValues: UseFormGetValues<T>,
  saveAction: (updateData: Partial<MovingReportPatch>) => Promise<any>,
) => {
  const { dirtyFields } = useFormState({
    control,
  });

  const save = useCallback(
    async (updateData: Partial<T>) => {
      await saveAction(updateData);
    },
    [saveAction],
  );

  const trySave = useCallback(async () => {
    if (Object.keys(dirtyFields).length > 0) {
      return await save(dirtyValues(dirtyFields, getValues()) as any);
    }
  }, [dirtyFields, save]);

  useSaveOnLeave(trySave);

  return {
    triggerManualSave: save,
  };
};
