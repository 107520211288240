import * as React from "react";

const SvgFilter18Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M11 7h3v4h-3zM3 1h3v4H3zm0 12h3v4H3z"
      />
      <path
        fill="currentColor"
        d="M8 14h9a1 1 0 010 2H8v-2zM8 2h9a1 1 0 010 2H8V2zm8 6h1a1 1 0 010 2h-1V8zM1 2h1v2H1a1 1 0 110-2zm0 6h9v2H1a1 1 0 110-2zm0 6h1v2H1a1 1 0 010-2z"
      />
    </g>
  </svg>
);
export default SvgFilter18Px;
