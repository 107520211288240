import * as React from "react";
import { Center, Dialog, DialogContent, Spinner } from "@boligportal/juice";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { Plan } from "components/interfaces/plan";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { usePaymentFeature } from "features/payments_feature/hooks/usePaymentFeature";
import { t } from "lib/i18n";
import { AccountPaymentModal } from "./account_payment_modal";
import { ChoosePaymentMethodModal } from "./choose_payment_method_modal";
import { MobilePayPaymentModal } from "./mobilepay_payment_modal";
import { QuickPayPaymentModal } from "./quickpay_payment_modal";

type Props = {
  onSuccess: () => void;
  onSubscriptionActivated?: () => void;
  plans: Plan[];
  /** If paymentMethodTypes includes MobilePay, this parameter is required for the useStartSubscriptionModal hook to work */
  adId: number | null;
  /** If paymentMethodTypes includes MobilePay, this parameter is required for the useStartSubscriptionModal hook to work */
  rentableId?: number;
  paymentMethodTypes: PaymentMethodType[];
  title: string;
  type?: React.ComponentProps<typeof ChoosePaymentMethodModal>["type"];
  onBeforeMobilePayRedirect?: () => void;
  selectedPaymentMethod?: PaymentMethodType;
};

export const PaymentModal = ({
  onSuccess,
  onSubscriptionActivated,
  plans,
  adId,
  rentableId,
  paymentMethodTypes,
  title,
  onBeforeMobilePayRedirect,
  type = "default",
  selectedPaymentMethod,
}: Props) => {
  const appActions = useAppActions();
  const {
    selectedPaymentMethodType,
    setSelectedPaymentMethodType,
    isLoadingAccountInfo,
    canUseAccount,
    checkAccountInfo,
  } = usePaymentFeature(plans, paymentMethodTypes, selectedPaymentMethod);

  if (isLoadingAccountInfo) {
    return (
      <Dialog
        open
        size="medium"
        dismissable={false}
      >
        <DialogContent>
          <Center height="200px">
            <Spinner size="large" />
          </Center>
        </DialogContent>
      </Dialog>
    );
  }

  if (canUseAccount) {
    return (
      <AccountPaymentModal
        adId={adId}
        plans={plans}
        onSuccess={onSuccess}
        onSubscriptionActivated={onSubscriptionActivated}
        backToPaymentModal={checkAccountInfo}
      />
    );
  }

  return (
    <>
      {selectedPaymentMethodType === null && (
        <ChoosePaymentMethodModal
          paymentMethodTypes={paymentMethodTypes}
          onPaymentMethodSelected={setSelectedPaymentMethodType}
          type={type}
        />
      )}
      {selectedPaymentMethodType === PaymentMethodType.MobilePay && (
        <MobilePayPaymentModal
          plans={plans}
          title={
            type === "default"
              ? t("payment_modal.title")
              : t("payment_modal.upsell.title")
          }
          onBeforeRedirect={onBeforeMobilePayRedirect}
          adId={adId}
          rentableId={rentableId}
        />
      )}
      {selectedPaymentMethodType === PaymentMethodType.QuickPay && (
        <QuickPayPaymentModal
          adId={adId}
          plans={plans}
          onSuccess={onSuccess}
          onSubscriptionActivated={onSubscriptionActivated}
          title={title}
          appActions={appActions}
        />
      )}
    </>
  );
};
