import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SelectField } from "@boligportal/juice";
import { EnumRentableCategory } from "administration-api/generated/types";
import { Option } from "components/interfaces/option";
import { t } from "lib/i18n";

interface Props {
  options: Array<Option>;
}

type SupportedMarketplaceCategories =
  | "rental_apartment"
  | "rental_room"
  | "rental_house"
  | "rental_cabin"
  | "rental_townhouse";

const administrationToMarketplaceMapping: Record<
  EnumRentableCategory,
  SupportedMarketplaceCategories | ""
> = {
  rental_apartment: "rental_apartment",
  rental_room: "rental_room",
  rental_house: "rental_house",
  rental_townhouse: "rental_townhouse",
  rental_cabin: "rental_cabin",
  rental_condominium: "rental_apartment",
  rental_club_room: "rental_room",
  rental_multi_family_house: "rental_house",
  rental_double_house: "rental_house",
  rental_half_double_house: "rental_house",
  rental_parcel_house: "rental_house",
  rental_small_house: "rental_house",
  rental_city_house: "rental_house",
  rental_detached_single_family_house: "rental_house",
  rental_youth_housing: "rental_apartment",
  rental_summer_house: "rental_house",
  rental_housing_cooperative: "rental_apartment",
  rental_villa: "rental_house",
  // These are business categories which we don't show in marketplace
  // rental_any: "",
  rental_assembly_room: "",
  rental_basement_room: "",
  rental_carport: "",
  rental_commercial_premises: "",
  rental_garage: "",
  rental_parking_space: "",
  rental_shop: "",
  rental_storage_room: "",
  rental_warehouse: "",
  rental_workshop: "",
} as const;

const RentalCategory = ({ options }: Props) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<{ category: string }>();
  const category = useWatch({ control, name: "category" });

  useEffect(() => {
    // If the selected category is a supported option we don't do anything
    if (options.some((opt) => opt.id === category)) return;

    // If the selected category isn't supported in Marketplace we convert it.
    setValue("category", administrationToMarketplaceMapping[category], {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
  }, [category, setValue, options]);

  const chooseCategory = t("Choose category");

  const items: Array<{ value: string; label: string }> = [
    {
      value: chooseCategory,
      label: chooseCategory,
    },
  ];

  options.forEach((option) => {
    items.push({
      value: `${option.id}`,
      label: option.name,
    });
  });

  return (
    <SelectField
      required
      items={items}
      testId={"createRentableCategory"}
      label={t("create.field.category.heading")}
      errorText={errors.category && t("required_field.selection")}
      {...register("category", {
        validate: (value: string) => value !== chooseCategory,
      })}
    />
  );
};

export { RentalCategory };
