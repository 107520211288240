import * as React from "react";
import * as ReactDOM from "react-dom";
import { modalActions } from "./modal_controller";

export const MODAL_OPEN = "modal-is-open";

export const Modal = ({
  onInsertedIntoDOM,
  onRemovedFromDOM,
  children,
}: {
  onInsertedIntoDOM?: () => void;
  onRemovedFromDOM?: () => void;
  children: React.ReactNode;
}) => {
  const [insertedIntoDOM, setInsertedIntoDOM] = React.useState(false);
  const el = React.useRef<HTMLDivElement>();
  const modal = React.useContext(modalActions);

  React.useEffect(() => {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node.
    const modalRoot = document.getElementById("modal-root");

    // Save current scroll position before opening modal
    const scrollTop =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
    if (modalRoot) {
      el.current = document.createElement("div");
      modalRoot.appendChild(el.current);
      setInsertedIntoDOM(true);
      modal.onModalOpen(scrollTop);
      window.scrollTo(0, 0);
      document.body.classList.add(MODAL_OPEN);
      if (onInsertedIntoDOM) {
        onInsertedIntoDOM();
      }
    }

    return () => {
      if (modalRoot) {
        if (el.current) {
          modalRoot.removeChild(el.current);
        }
        setInsertedIntoDOM(false);
        modal.onModalClose();
        document.body.classList.remove(MODAL_OPEN);

        // Re-apply saved scroll position
        setTimeout(() => {
          window.scrollTo(0, scrollTop);
        }, 0);
        if (onRemovedFromDOM) {
          onRemovedFromDOM();
        }
      }
    };
  }, []);

  if (!el.current) {
    // Don't show this component by default (when server-side rendering)
    return null;
  }

  // Only render children if the modal element is inserted into the DOM.
  // This helps avoid problems with child components mounting before the DOM is ready
  return ReactDOM.createPortal(
    <>{insertedIntoDOM ? children : null}</>,
    el.current,
  );
};
