import { TenancyPaymentInfo } from "./TenancyPaymentInfo";
import { TenancyPaymentStatus } from "./TenancyPaymentStatus";

export const shouldDisplayPaymentInfoNotice = (
  paymentInfo: null | TenancyPaymentInfo,
  status: TenancyPaymentStatus,
) => {
  if (status === TenancyPaymentStatus.PAID) {
    return false;
  }

  return Boolean(paymentInfo?.messageKey);
};
