import { ChooseOrCreateRentableMain } from "./ChooseOrCreateRentableMain";
import { ChooseOrCreateRentableProvider } from "./ChooseOrCreateRentableProvider";
import { CreateRentableDialogTrigger } from "./components/dialog/common/CreateRentableDialogTrigger";
import { ChooseOrCreateRentableLayoutHeaderProps } from "./components/layouts/ChooseOrCreateRentableHeaderLayout";
import { ChoseRentable } from "./interfaces/ChooseOrCreateRentableFeature";

interface Props {
  onRentableId: ChoseRentable;
  dialogHeadline: string;
  primaryButtonLabel: string;
  url: string;
}

export const ChooseOrCreateRentable = ({
  onRentableId,
  headline,
  text,
  url,
}: Props & ChooseOrCreateRentableLayoutHeaderProps) => (
  <ChooseOrCreateRentableProvider onRentableId={onRentableId}>
    <ChooseOrCreateRentableMain
      headline={headline}
      text={text}
      createNewRentableButton={<CreateRentableDialogTrigger url={url} />}
    />
  </ChooseOrCreateRentableProvider>
);

export const CreateRentable = ({ onRentableId, url }: Props) => (
  <ChooseOrCreateRentableProvider onRentableId={onRentableId}>
    <CreateRentableDialogTrigger url={url} />
  </ChooseOrCreateRentableProvider>
);
