import { useState } from "react";
import * as React from "react";
import {
  Text,
  Box,
  Flex,
  styled,
  Switch,
  useNotifications,
} from "@boligportal/juice";
import { Action_updateAgentId } from "apps/search_result/search_result_reducer";
import { Filters } from "components/interfaces/filters";
import { t } from "lib/i18n";
import { AgentAlert, AgentAlertState } from "./AgentAlert";
import { useAgentActions } from "./useAgentActions";

interface CreateAgentButtonProps {
  filters: Filters;
  agentId: number | null;
  dispatch: React.Dispatch<Action_updateAgentId>;
  colorStyle?: "brand" | "neutral";
}

const LowBox = styled(Box)`
  line-height: 1em;
`;

const LightText = styled(Text)`
  font-weight: 300;
  font-size: 0.8em;
`;

const AgentButtonContainer = styled.div<{ colorStyle: "brand" | "neutral" }>`
  background-color: ${(props) =>
    props.colorStyle === "brand"
      ? props.theme.colorPalette.brand["500"]
      : "white"};

  border: 1px solid
    ${(props) =>
      props.colorStyle === "brand"
        ? props.theme.colorPalette.brand["500"]
        : props.theme.colorPalette.gray[40]};

  cursor: pointer;
  border-radius: 3px;
`;

export const CreateAgentButton = ({
  agentId,
  dispatch,
  filters,
  colorStyle = "brand",
}: CreateAgentButtonProps) => {
  const { addNotification } = useNotifications();
  const [alert, setAlert] = useState<AgentAlertState>(null);

  const onAgentError = () => {
    addNotification({
      title: t("srp.list.must_select_city_to_create_agent.title"),
      content: t("srp.list.must_select_city_to_create_agent.content"),
      autoDismiss: true,
    });
  };

  const { onEnableAgent, onDisableAgent } = useAgentActions({
    filters,
    dispatch,
    agentId,
    onAgentCreated: () => setAlert("created"),
    onAgentDeleted: () => setAlert("deleted"),
    onError: onAgentError,
    trackingLabel: "srp_empty_state",
  });

  return (
    <>
      <AgentButtonContainer
        colorStyle={colorStyle}
        onClick={() => {
          if (!agentId) {
            onEnableAgent();
          } else {
            onDisableAgent();
          }
        }}
      >
        <Flex>
          <Box
            flexGrow
            p={1.5}
          >
            <Flex>
              <LowBox flexGrow>
                <Text
                  color={colorStyle === "brand" ? "inverted" : "base"}
                  weight={"bold"}
                  mx={0}
                >
                  {t("create_agent_button.toggle")}
                </Text>
              </LowBox>
              <LowBox
                hidden={{
                  xs: true,
                  lg: false,
                }}
                pl={2}
                mt={{
                  lg: 1,
                }}
              >
                <Switch
                  checked={Boolean(agentId)}
                  trackOffColor={colorStyle === "brand" ? "brand" : "tint"}
                />
              </LowBox>
            </Flex>
            <LowBox
              mt={{
                lg: 1,
              }}
            >
              <LightText
                color={colorStyle === "brand" ? "inverted" : "base"}
                size={"tiny"}
              >
                {t("create_agent_button.label")}
              </LightText>
            </LowBox>
          </Box>
          <Flex
            hidden={{
              lg: true,
            }}
            align={"center"}
          >
            <LowBox pr={1.2}>
              <Switch
                checked={Boolean(agentId)}
                trackOffColor={colorStyle === "brand" ? "brand" : "tint"}
              />
            </LowBox>
          </Flex>
        </Flex>
      </AgentButtonContainer>
      {<AgentAlert state={alert} />}
    </>
  );
};
