import { rejectAd, sendEmail } from "lib/customerServiceApi";
import { TaskResponseInterface } from "../../../interfaces";

export const rejectAdTask = async (
  templateId: number,
  adId: number,
  emailMessageBody: string,
): Promise<TaskResponseInterface> => {
  const rejectAdResponse = await rejectAd(adId);

  if (rejectAdResponse.success === false) {
    return Promise.resolve({
      success: false,
    });
  }

  if (templateId > -1) {
    const sendEmailResponse = await sendEmail(
      templateId,
      adId,
      emailMessageBody,
    );
    return Promise.resolve({
      success: sendEmailResponse.success,
    });
  }

  return Promise.resolve({
    success: rejectAdResponse.success,
  });
};
