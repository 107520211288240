import { useState, useMemo } from "react";
import { Box, ImageSliderOverlay, responsiveValue } from "@boligportal/juice";
import { Image } from "apps/moving_reports/interfaces/image";
import { getImageUrl } from "lib/image";
import { MultipleImages } from "./MultipleImages";
import { SingleImage } from "./SingleImage";

const getThumbnailUrl = (image: Image) =>
  getImageUrl({
    image,
    crop: true,
    width: 200,
    height: 200,
  });
const getPreviewUrl = (image: Image) =>
  getImageUrl({
    image,
    width: 1200,
    height: 1200,
  });

export const ImageList = ({
  images,
  limit,
  size = 4,
  showLabels,
  isSingle,
  onRemoveImage,
}: {
  images: Image[];
  limit?: number;
  size?: responsiveValue<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
  showLabels?: boolean;
  isSingle?: boolean;
  onRemoveImage?: (id: number) => void;
}) => {
  const [sliderInitialIndex, setSliderInitialIndex] = useState<
    number | undefined
  >();

  const settings = useMemo(() => {
    const imagesCount = images.length;

    if (limit && limit > 0) {
      const cropedImages = images.slice(0, limit).map((image) => ({
        ...image,
        url: getThumbnailUrl(image),
      }));

      return {
        isLimited: imagesCount > limit,
        remainingImagesCount: imagesCount - limit,
        images: cropedImages,
      };
    }

    const cropedImages = images.map((image) => ({
      ...image,
      url: getThumbnailUrl(image),
    }));

    return {
      isLimited: false,
      remainingImagesCount: 0,
      images: cropedImages,
    };
  }, [images, limit]);

  const previewUrlsList = images.map((image) => getPreviewUrl(image));

  return (
    <Box>
      {isSingle ? (
        <SingleImage
          images={settings.images}
          onPreview={setSliderInitialIndex}
        />
      ) : (
        <MultipleImages
          settings={settings}
          showLabels={showLabels}
          size={size}
          onRemoveImage={onRemoveImage}
          onPreview={setSliderInitialIndex}
        />
      )}

      <ImageSliderOverlay
        fullscreen
        initialIndex={sliderInitialIndex}
        images={previewUrlsList}
        onClose={() => setSliderInitialIndex(undefined)}
      />
    </Box>
  );
};
