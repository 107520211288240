import { Box, styled, Text } from "@boligportal/juice";
import { UpsellCardPrice } from "./UpsellCardPrice";
import { UpsellCardPriceVAT } from "./UpsellCardPriceVAT";

const StyledBox = styled(Box)`
  background: ${(props) => props.theme.colorPalette.gray[10]};
`;

type Props = {
  showVAT: boolean;
  currency: string;
  price: {
    percent_off: number;
    total: number;
    subtotal: number;
    tax: number;
  };
  priceText: string;
};

export const UpsellCardPriceBox = ({
  showVAT,
  currency,
  price,
  priceText,
}: Props) => (
  <>
    {showVAT && (
      <Text
        color="muted"
        size="small"
        block
        mb={2}
      >
        {priceText}
      </Text>
    )}

    <StyledBox
      width="100%"
      p={2}
      mb={4}
    >
      {showVAT ? (
        <UpsellCardPriceVAT
          currency={currency}
          price={price}
        />
      ) : (
        <>
          <UpsellCardPrice
            priceTotal={price.total}
            currency={currency}
          />
          <Text
            color="muted"
            size="small"
            block
            mb={2}
          >
            {priceText}
          </Text>
        </>
      )}
    </StyledBox>
  </>
);
