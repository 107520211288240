export const GoogleAdsLogo = () => (
  <svg
    width="57px"
    height="53px"
    viewBox="0 0 57 53"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.9"
    >
      <g
        transform="translate(-437.000000, -3013.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, 1961.000000)">
          <g transform="translate(437.000000, 952.000000)">
            <g transform="translate(0.000000, 100.000000)">
              <path d="M20.5161084,38.8831566 C19.0215181,33.3051687 13.2975964,29.9992831 7.71823494,31.4943313 C-1.25216867,33.8979458 -2.80971084,46.0136506 5.19810241,50.6371747 C13.187488,55.2497108 22.9327711,47.9022048 20.5161084,38.8831566 L20.5161084,38.8831566 Z" />
              <path d="M55.5423795,36.3630241 L37.6186265,5.31805422 C36.2231566,2.90081928 33.9697108,1.17181928 31.2736566,0.449361446 C22.3037108,-1.95459639 14.8962289,7.75943976 19.519753,15.7673675 L37.4436205,46.8123373 C40.3303614,51.8124277 46.7147048,53.525747 51.7177711,50.6371747 C56.7075602,47.7561566 58.4232831,41.3528133 55.5423795,36.3630241 Z" />
              <path d="M24.2146988,38.5981566 L26.3432711,34.9114699 L16.3477831,17.5988072 C15.5461205,16.2103193 15.008741,14.7407952 14.7184759,13.2486084 L6.1158253,28.1488434 C14.0277229,25.622759 22.4292711,30.4036627 24.2146988,38.5981566 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
