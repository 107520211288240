import * as React from "react";
import { SelectField, TextField } from "@boligportal/juice";
import { Option } from "components/interfaces/option";
import { GetEmailTemplateContentResponseInterface } from "../interfaces";

export type EmailTemplateContentLoadable = (
  templateId: number,
  adId: number,
) => Promise<GetEmailTemplateContentResponseInterface>;

const EmailTemplate = (props: {
  emailTemplateLoaderId: number;
  templateOptions: Option[];
  emailTemplateLoader: EmailTemplateContentLoadable;
  onEmailChange: (emailMessageBody: string) => void;
  onTemplateSelectionChange: (templateId: number) => void;
}) => {
  const [emailMessageBody, setEmailMessageBody] = React.useState("");
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);

  const handleEmailTemplateSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target;
    const templateId = Number(value);

    props
      .emailTemplateLoader(templateId, props.emailTemplateLoaderId)
      .then((response) => {
        props.onTemplateSelectionChange(templateId);
        setEmailMessageBody(response.content);
        setSelectedTemplate(templateId);
      })
      .catch((error) => {
        setEmailMessageBody("");
      });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMessageBody(event.target.value);
  };

  const { onEmailChange } = props;

  React.useEffect(() => {
    onEmailChange(emailMessageBody);
  }, [emailMessageBody, onEmailChange]);

  const templateOptions: {
    value: string;
    label: string;
    disabled?: boolean;
  }[] = [
    {
      value: "0",
      label: "Choose a template",
      disabled: true,
    },
  ];
  templateOptions.push(
    ...props.templateOptions.map((v) => ({
      value: v.id.toString(),
      label: v.name,
    })),
  );
  return (
    <div>
      <SelectField
        label="Select a template"
        items={templateOptions}
        placeholder={"Select a template"}
        value={selectedTemplate.toString()}
        onChange={handleEmailTemplateSelectionChange}
      />

      <TextField
        label=""
        multiLine={12}
        value={emailMessageBody}
        onChange={handleTextAreaChange}
      />
    </div>
  );
};

export { EmailTemplate };
