import * as React from "react";
import { Box } from "@boligportal/juice";

export const LandingPageContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    px={{
      xs: 2,
      md: 6,
    }}
    m="auto"
    width={"auto"}
    maxWidth={"1390px"}
  >
    {children}
  </Box>
);
