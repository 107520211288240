import {
  USER_TYPE,
  PublicProfile,
  genderLabel,
  userTypeLabel,
  occupationLabel,
} from "components/interfaces/profile";
import { t } from "lib/i18n";

export const profilePreferencesShortSummary = (profile?: PublicProfile) => {
  if (!profile) {
    return null;
  }

  const infoParts: string[] = [];

  if (typeof profile.pets !== "undefined" && profile.pets !== null) {
    infoParts.push(profile.pets ? t("inbox.has_pets") : t("inbox.has_no_pets"));
  }

  if (typeof profile.kids !== "undefined" && profile.kids !== null) {
    infoParts.push(profile.kids ? t("inbox.has_kids") : t("inbox.has_no_kids"));
  }

  if (typeof profile.smoking !== "undefined" && profile.smoking !== null) {
    infoParts.push(profile.smoking ? t("inbox.smoker") : t("inbox.non_smoker"));
  }

  if (
    typeof profile.share_apartment !== "undefined" &&
    profile.share_apartment !== null
  ) {
    infoParts.push(
      profile.share_apartment
        ? t("inbox.will_share")
        : t("inbox.will_not_share"),
    );
  }

  return infoParts.length ? infoParts.join(" · ") : "";
};

export const profileName = (profile?: PublicProfile): string | null => {
  if (!profile) {
    return null;
  }

  if (profile.user_type === USER_TYPE.COMPANY) {
    return profile.company_name || "";
  } else if (profile.first_name && profile.last_name) {
    return `${profile.first_name} ${profile.last_name}`;
  } else {
    return "";
  }
};

export const profileShortSummary = (profile?: PublicProfile) => {
  if (!profile) {
    return null;
  }

  const type =
    profile.user_type !== null ? t(userTypeLabel[profile.user_type]) : "";
  const age = profile.age !== null ? profile.age : "";
  const gender =
    profile.user_gender !== null ? t(genderLabel[profile.user_gender]) : "";
  const occupation =
    profile.occupation !== null ? t(occupationLabel[profile.occupation]) : "";

  if (profile.user_type === USER_TYPE.INDIVIDUAL) {
    const str: string[] = [type];
    if (gender) {
      str.push(gender);
    }
    if (age) {
      str.push(
        t("%{age} years", {
          age,
        }),
      );
    }
    if (occupation) {
      str.push(occupation);
    }
    return str.join(" · ");
  } else {
    const str: string[] = [];
    if (type) {
      str.push(type);
    }
    if (age) {
      str.push(
        t("%{age} years", {
          age,
        }),
      );
    }
    if (occupation) {
      str.push(occupation);
    }
    return str.join(" · ");
  }
};
