import * as React from "react";

const SvgAuraLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={321}
    height={79}
    fill="none"
    viewBox="0 0 321 79"
    {...props}
  >
    <g clipPath="url(#aura_logo_svg__aura_logo_svg__clip0_58_293)">
      <path
        fill="url(#aura_logo_svg__aura_logo_svg__paint0_linear_58_293)"
        d="M196.699 52.353l17.439 26.491h27.652l-20.537-29.746-24.554 3.255zm-11.588-33.339h20.768c3.556 0 9.752 2.133 9.752 8.643 0 6.511-6.196 8.644-9.752 8.644H180.98V53.7h27.652c9.868 0 30.176-5.164 30.176-27.053 0-20.205-17.325-26.379-33.158-26.379h-20.195l-.344 18.746zM165.262.268v78.576h23.521V.268h-23.521zm-33.389 78.464c15.26 0 23.521-9.766 23.521-23.91V.156h-23.521v55.003c0 2.357-1.491 4.602-4.36 4.602h-16.981c-2.868 0-4.36-2.245-4.36-4.602V.156h-23.52v54.666c0 14.144 8.26 23.91 23.52 23.91h25.701zM265.655 36.3v17.4h36.142V36.3h-36.142zM271.278.156c-15.26 0-23.521 9.766-23.521 23.91v54.666h23.521V23.729c0-2.358 1.492-4.603 4.36-4.603h16.981c2.868 0 4.36 2.245 4.36 4.603v55.003H320.5V24.065c0-14.143-8.261-23.91-23.521-23.91h-25.701zM18.514 36.75v17.399h36.142v-17.4H18.514zM24.02.156C8.761.156.5 9.922.5 24.066v54.666h23.521V23.729c0-2.358 1.491-4.603 4.36-4.603h16.981c2.868 0 4.36 2.245 4.36 4.603v55.003h23.521V24.065c0-14.143-8.261-23.91-23.521-23.91h-25.7z"
      />
    </g>
    <defs>
      <linearGradient
        id="aura_logo_svg__aura_logo_svg__paint0_linear_58_293"
        x1={320.5}
        x2={42.3}
        y1={78.84}
        y2={78.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE791B" />
        <stop
          offset={1}
          stopColor="#FF0097"
        />
      </linearGradient>
      <clipPath id="aura_logo_svg__aura_logo_svg__clip0_58_293">
        <path
          fill="#fff"
          d="M0 0h320v78.69H0z"
          transform="translate(.5 .156)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAuraLogo;
