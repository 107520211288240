import { Box, Button } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";

interface Props {
  label: string;
  gaTracking: () => void;
  context: SignupContext;
}

export const MovingReportsLandingPageCTAButton = ({
  label,
  gaTracking,
  context,
}: Props) => {
  const { user } = useUser();
  const { modalActionsInstance } = useAuthActions({});

  const ctaButtonClickHandler = () => {
    gaTracking();
    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={App.settings.routes.moving_reports}
          context={context}
        />,
      );
    } else {
      window.location.href = App.settings.routes.moving_reports;
    }
  };

  return (
    <Button
      variant="branded"
      onClick={ctaButtonClickHandler}
      fullWidth={{
        xs: true,
        lg: false,
      }}
    >
      <Box py={1}>{label}</Box>
    </Button>
  );
};
