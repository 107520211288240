import { useRef, useEffect } from "react";

export const useAutomaticScrollToTopOnPagination = (offset: number) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [offset]);

  return scrollContainerRef;
};
