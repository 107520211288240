import { useCallback } from "react";
import { API } from "lib/api";

export const useFetchAndFillPostalTown = (
  setValue: any,
  fieldName: string,
  postalCodeExpression: RegExp,
) => {
  const getAndSetPostalTown = useCallback(
    async (postalCode: string) => {
      if (postalCodeExpression.test(postalCode)) {
        try {
          const response = await API.getPostalTown(postalCode);
          const postal_town = response.postal_town ?? "";
          setValue(fieldName, postal_town, {
            shouldDirty: true,
            shouldValidate: true,
          });
        } catch (error) {
          setValue(fieldName, "");
        }
      } else {
        setValue(fieldName, "");
      }
    },
    [fieldName, setValue, postalCodeExpression],
  );

  return {
    getAndSetPostalTown,
  };
};
