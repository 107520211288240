import * as React from "react";

const SvgArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 14"
    className="Icon"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M21.477 12.403l-1.058 1.07a1.8 1.8 0 01-1.285.527c-.5 0-.923-.176-1.271-.527L11 6.543l-6.863 6.93a1.722 1.722 0 01-1.27.527 1.8 1.8 0 01-1.285-.528L.537 12.403A1.778 1.778 0 010 11.106c0-.513.179-.941.537-1.283L9.729.542A1.671 1.671 0 0111 0c.499 0 .927.18 1.285.542l9.192 9.28c.349.352.523.78.523 1.284 0 .494-.174.926-.523 1.297z"
    />
  </svg>
);
export default SvgArrowUp;
