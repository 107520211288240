import { App } from "components/app";
import { t } from "lib/i18n";
import { formatNumber } from "lib/utils";
import { TenantAgent } from "../interfaces/find_tenant_tenant";

const TenantAgentView = ({ agent }: { agent: TenantAgent }) => {
  const { filters } = agent;
  if (!filters) {
    return null;
  }

  const list: string[] = [];

  if (filters.max_monthly_rent) {
    list.push(
      t("agent.max_rent", {
        rent: `${formatNumber(filters.max_monthly_rent)} ${
          App.settings.currency_symbol
        }`,
      }),
    );
  }

  if (filters.min_size_m2) {
    list.push(
      t("agent.min_size", {
        size: filters.min_size_m2,
      }),
    );
  }

  if (filters.rooms) {
    if (filters.rooms.gte !== null && filters.rooms.gte === filters.rooms.lte) {
      list.push(
        t("agent.exact_%{rooms}_rooms", {
          rooms: filters.rooms.gte,
        }),
      );
    } else if (filters.rooms.gte !== null && filters.rooms.lte === null) {
      list.push(
        t("agent.min_%{rooms}_rooms", {
          rooms: filters.rooms.gte,
        }),
      );
    } else if (filters.rooms.gte !== null && filters.rooms.lte !== null) {
      list.push(
        t("agent.between_%{min}_and_%{max}rooms", {
          min: filters.rooms.gte,
          max: filters.rooms.lte,
        }),
      );
    } else if (filters.rooms.gte === null && filters.rooms.lte !== null) {
      list.push(
        t("agent.max_%{rooms}_rooms", {
          rooms: filters.rooms.lte,
        }),
      );
    }
  }

  if (filters.city_level_1 && filters.city_level_1.values) {
    const cities = filters.city_level_1.values.join(", ");
    list.push(cities);
  }

  if (list.length === 0) {
    return <>{t("inbox.notSpecified")}</>;
  }

  return <span>{list.join(" · ")}</span>;
};

export { TenantAgentView };
