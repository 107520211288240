import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  IconInformationCircle,
  Notice,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

export const StartSubscriptionFailedDialog = ({
  onClose,
  retryPayment,
  errorButtonLabel,
  additionalErrorText,
}: {
  onClose: () => void;
  retryPayment: () => void;
  errorButtonLabel?: string;
  additionalErrorText?: string | null;
}) => (
  <Dialog
    open
    size="medium"
    hideOnClickOutside={false}
    dismissable={!!onClose}
    onClose={onClose}
  >
    <DialogHeader>{t("payment_modal.failure.headline")}</DialogHeader>
    <DialogContent>
      <Text markdown>{t("payment_modal.failure.message.markdown")}</Text>
      {additionalErrorText && (
        <Notice
          type="info"
          icon={<IconInformationCircle />}
        >
          {additionalErrorText}
        </Notice>
      )}
    </DialogContent>
    <DialogFooter>
      <Button
        variant="branded"
        autoFocus
        onClick={retryPayment}
      >
        {errorButtonLabel ? errorButtonLabel : t("payment_modal.button.retry")}
      </Button>
    </DialogFooter>
  </Dialog>
);
