import * as React from "react";

export const NemId = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect
          width="32"
          height="32"
        />
      </clipPath>
    </defs>
    <g
      id="a"
      clipPath="url(#b)"
    >
      <path
        d="M152.217,195.055a3.832,3.832,0,1,0,3.832,3.832A3.833,3.833,0,0,0,152.217,195.055Zm6.259,2.555a6.387,6.387,0,1,0,0,2.555h7.792v1.277a1.277,1.277,0,0,0,2.555,0v-1.277h1.065v2.794a1.277,1.277,0,0,0,2.555,0v-2.794h1.064a1.277,1.277,0,1,0,0-2.555Z"
        transform="translate(-144.307 -182.887)"
        fill="#223663"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
