import { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  DataTable,
  Dialog,
  dialogActions,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Headline,
  Labelled,
  TextField,
  useNotifications,
  IconPersonOutline,
} from "@boligportal/juice";
import {
  CrawlingOwnerMapping,
  useEditCrawlingFeature,
} from "features/cs/crawlings_feature/hooks/use_edit_crawling_feature";
import { OwnerMappingPayload } from "features/cs/crawlings_feature/services/update_crawling_service";
import { SearchUsersDropdown } from "features/cs/search_users_feature/components/search_users_dropdown";
import { UserListItem } from "lib/customerServiceApi";

interface OwnerMappingViewModel extends CrawlingOwnerMapping {
  itemId: number;
}

const createViewModelsFromSourceOfTruth = (
  source: CrawlingOwnerMapping[],
): OwnerMappingViewModel[] => {
  const result: OwnerMappingViewModel[] = [];
  source.forEach((item, i) => {
    result.push({
      itemId: i,
      email: item.email,
      phone: item.phone,
      user: item.user,
    });
  });

  return result;
};

const createOwnerMappingPayloadFromViewModels = (
  source: OwnerMappingViewModel[],
): OwnerMappingPayload[] => {
  const result: OwnerMappingPayload[] = [];
  source.forEach((item) => {
    result.push({
      email: item.email,
      phone: item.phone,
      user_id: item.user!.id,
    });
  });
  return result;
};

export const EditCrawlingDialog = (props: {
  crawlingId: number;
  onClose: () => void;
  onSaved?: () => void;
}) => {
  const { crawlingId, onSaved } = props;
  // Feature hook
  const { getCrawling, saveCrawling, crawlingDetails } =
    useEditCrawlingFeature();

  // For showing system notifications
  const { addNotification } = useNotifications();

  // For closing the dialog programatically
  const actions = useContext(dialogActions);

  // Usecase: can edit name, default user, and add/remove mapings
  const [crawlingName, setCrawlingName] = useState("");
  const [crawlingDefaultUserId, setCrawlingDefaultUserId] = useState("");
  const [ownerMappingViewModels, setOwnerMappingViewModels] = useState<
    OwnerMappingViewModel[]
  >([]);

  // Retrieve crawling data initially
  useEffect(() => {
    getCrawling(crawlingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlingId]);

  // Create View state when the crawling data loads/changes.
  useEffect(() => {
    // Create ViewModels when source of truth changes
    if (crawlingDetails?.owner_mappings) {
      setOwnerMappingViewModels(
        createViewModelsFromSourceOfTruth(crawlingDetails.owner_mappings),
      );
    }
    setCrawlingName(crawlingDetails?.name ?? "");
    setCrawlingDefaultUserId(
      crawlingDetails?.default_owner?.id?.toString() ?? "",
    );
  }, [crawlingDetails]);

  const removeOwnerMappingActionButtonClickHandler = (
    item: OwnerMappingViewModel,
  ) => {
    const clone = [...ownerMappingViewModels];
    const spliceIndex = ownerMappingViewModels.indexOf(item);

    if (spliceIndex > -1) {
      clone.splice(spliceIndex, 1);
    }

    setOwnerMappingViewModels(clone);
  };

  const showUserThatItWasSuccefullySaved = () => {
    if (addNotification) {
      addNotification({
        title: "Changes saved",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  const showUserThatSavingFailed = () => {
    if (addNotification) {
      addNotification({
        title: "Error saving mappings",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
  };

  const saveButtonClickHandler = () => {
    saveCrawling(
      {
        name: crawlingName,
        default_owner_id: crawlingDefaultUserId
          ? Number(crawlingDefaultUserId)
          : null,
        owner_mappings: createOwnerMappingPayloadFromViewModels(
          ownerMappingViewModels,
        ),
      },
      // Happy Path
      () => {
        showUserThatItWasSuccefullySaved();
        actions.hide();
        if (onSaved) {
          onSaved();
        }
      },
      // Sad path
      () => {
        showUserThatSavingFailed();
      },
    );
  };

  const addOwnerHandler = (email: string, phone: string, userId: number) => {
    const clone = [...ownerMappingViewModels];
    const nextIndex = clone.length + 1;
    clone.push({
      itemId: nextIndex,
      email: email,
      phone: phone,
      user: {
        id: userId,
        username: "",
      },
    });

    setOwnerMappingViewModels(clone);
  };

  const defaultUserIdFoundHandler = (id: string) => {
    setCrawlingDefaultUserId(id);
  };

  return (
    <>
      <DialogHeader>Edit crawling</DialogHeader>
      <DialogContent>
        <Box
          mx={-3}
          borderBottom
        >
          <Box mx={3}>
            <form>
              <Flex gap>
                <TextField
                  label="Name"
                  required
                  value={crawlingName}
                  onChange={(event) => setCrawlingName(event.target.value)}
                />
                <TextField
                  label="Default owner id"
                  value={crawlingDefaultUserId}
                  onChange={(event) =>
                    setCrawlingDefaultUserId(event.target.value)
                  }
                  append={
                    <Dialog
                      opener={
                        <div
                          style={{
                            cursor: "pointer",
                            padding: 4,
                          }}
                        >
                          <IconPersonOutline />
                        </div>
                      }
                    >
                      <FindDefaultUserIdDialogContent
                        onDefaultUserIdFound={defaultUserIdFoundHandler}
                      />
                    </Dialog>
                  }
                />
              </Flex>
            </form>
          </Box>
        </Box>
        <Flex
          align="center"
          justify="end"
        >
          <Headline as="h4">Owner mapping</Headline>
          <Flex flexPush>
            <Dialog
              opener={<Button>Add mapping</Button>}
              size="large"
              dismissable={false}
            >
              <AddMappingDialogContent onAddOwner={addOwnerHandler} />
            </Dialog>
          </Flex>
        </Flex>
        <Box mx={-3}>
          <DataTable<OwnerMappingViewModel>
            items={ownerMappingViewModels}
            columns={[
              {
                fieldName: "email",
                fieldLabel: "Email",
              },
              {
                fieldName: "phone",
                fieldLabel: "Phone",
              },
              {
                fieldName: "user",
                fieldLabel: "Mapped user id",
                cellRenderer: (item) => item.user?.id,
              },
            ]}
            rowActions={[
              {
                label: "Remove",
                callback: removeOwnerMappingActionButtonClickHandler,
              },
            ]}
            keyField="itemId"
          />
        </Box>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="primary"
          onClick={saveButtonClickHandler}
        >
          Save
        </Button>
      </DialogFooter>
    </>
  );
};

// ************************************************************************************************
// Dialog content for looking up a user when adding default user id
// ************************************************************************************************

const FindDefaultUserIdDialogContent = ({
  onDefaultUserIdFound,
}: {
  onDefaultUserIdFound: (id: string) => void;
}) => {
  const actions = useContext(dialogActions);

  const userSelectHander = (user: UserListItem) => {
    onDefaultUserIdFound(user.id.toString());
    actions.hide();
  };

  return (
    <>
      <DialogHeader>Find default user id</DialogHeader>
      <DialogContent>
        <Labelled label="Find a user">
          <SearchUsersDropdown onUserSelect={userSelectHander} />
        </Labelled>
      </DialogContent>
    </>
  );
};
// ************************************************************************************************
// Dialog content for looking up a user when adding a owner mapping
// ************************************************************************************************

const AddMappingDialogContent = ({
  onAddOwner,
}: {
  onAddOwner: (email: string, phone: string, userId: number) => void;
}) => {
  const actions = useContext(dialogActions);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState<number | null>(null);

  const reset = () => {
    setEmail("");
    setPhone("");
    setUserId(null);
  };

  const userSelectHandler = (user: UserListItem) => {
    setUserId(user.id);
  };

  const cancelButtonClickHandler = () => {
    reset();
    actions.hide();
  };

  const addButtonClickeHandler = () => {
    if (userId === null) {
      return;
    }
    onAddOwner(email, phone, userId);
    reset();
    actions.hide();
  };

  const emailInvalid = email.length < 1;
  const phoneInvalid = phone.length < 1;
  const userIdInvalid = userId === null;

  return (
    <>
      <DialogHeader>Add mapping</DialogHeader>
      <DialogContent>
        <Flex>
          <Labelled
            label="Find a user"
            helpText={
              userId
                ? `user with id: ${userId} selected`
                : "no user selected yet"
            }
          >
            <SearchUsersDropdown onUserSelect={userSelectHandler} />
          </Labelled>
        </Flex>
        <Flex gap>
          <TextField
            disabled={userIdInvalid}
            label="Email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            disabled={userIdInvalid}
            label="Phone"
            name="phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="default"
            onClick={cancelButtonClickHandler}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={addButtonClickeHandler}
            disabled={emailInvalid || phoneInvalid || userIdInvalid}
          >
            Add
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};
