import { useState, useCallback } from "react";
import { useNotifications } from "@boligportal/juice";
import { LatLngLocation } from "components/interfaces/location";
import { getAdLocationService } from "../services/get_ad_location_service";
import { saveAdLocationService } from "../services/save_ad_location_service";

const useAdLocationFeature = () => {
  const notification = useNotifications();
  const [location, setLocation] = useState<LatLngLocation | null>(null);
  const [newLocation, setNewLocation] = useState<LatLngLocation | null>(null);
  const [canSave, setCanSave] = useState(false);

  const save = (adId: number) => {
    if (!newLocation) {
      return;
    }
    setCanSave(false);

    saveAdLocationService(adId, newLocation).then((response) => {
      // Happy path
      if (response.success) {
        setLocation(newLocation);
        setNewLocation(null);

        if (notification && notification.addNotification) {
          notification.addNotification({
            title: "Location updated successfully",
            autoDismiss: true,
          });
        }
      } else {
        // Sad path -> Display Error message
        if (notification && notification.addNotification) {
          notification.addNotification({
            title: "Error saving new Location",
          });
        }
      }
    });
  };

  const updateLocation = useCallback((newLocation: LatLngLocation) => {
    setNewLocation(newLocation);
    setCanSave(true);
  }, []);

  const fetchAdLocation = useCallback((adId: number) => {
    getAdLocationService(adId).then((response) => {
      setNewLocation(null);
      setCanSave(false);
      setLocation(response);
    });
  }, []);

  return {
    fetchAdLocation,
    location,
    newLocation,
    updateLocation,
    save,
    canSave,
  };
};

export { useAdLocationFeature };
