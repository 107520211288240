import { useEffect } from "react";
import { Flex, Headline, Text } from "@boligportal/juice";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { CurrencyAmountLabel } from "components/juice-candidates/CurrencyAmountLabel";
import { Market } from "components/markets/market_settings";
import { ModalActions } from "components/modal_controller";
import { PaymentModal } from "components/modals/payment_modal";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { useStartSubscriptionModal } from "features/upsell/useStartSubscriptionModal";
import { AccountInfo } from "lib/api";
import { t } from "lib/i18n";
import { DefaultUpsellScreen } from "./landord/DefaultUpsellScreen";
import { ProLandlordUpsellScreen } from "./pro_landlord/ProLandlordUpsellScreen";

type Props = {
  isAdLessThan24HoursOld: boolean;
  rentableId: number;
  listingId: number;
  plans: Record<string, Plan[]>;
  activeUpsellSubscriptions: AdSubscription[];
  accountInfo: AccountInfo | null;
  onSuccess: (plan?: Plan) => void;
  onSubscriptionActivated?: () => void;
  modalActions: ModalActions;
};

const upsellScreenMap = {
  [Market.BOLIGPORTAL_DK]: ProLandlordUpsellScreen,
  [Market.BOSTADSPORTAL_SE]: DefaultUpsellScreen,
};

export const UpsellPlansWithPaymentModal = ({
  isAdLessThan24HoursOld,
  rentableId,
  listingId,
  plans,
  activeUpsellSubscriptions,
  accountInfo,
  onSuccess,
  onSubscriptionActivated,
  modalActions,
}: Props) => {
  const { paymentMethodTypes } = useMarketSettings();
  const { user } = useUser();
  useStartSubscriptionModal({
    onSuccess,
  });

  const isPrivateLandlord = user?.is_private_landlord ?? false;
  useEffect(() => {
    if (window) {
      const searchParams = new URLSearchParams(window.location.search);
      const preselectedProductName = searchParams.get("n");
      const duration = searchParams.get("d");

      if (preselectedProductName !== null) {
        const plan = Object.values(plans)
          .flat()
          .find((plan) => {
            return (
              plan.product.name === preselectedProductName &&
              plan.duration_days.toString() === duration
            );
          });

        if (plan) {
          handleOpenModal(plan, listingId);
        }
      }
    }
  }, []);

  const handleOpenModal = (plan: Plan, listingId: number) => {
    const product_name = t(`products.${plan.product.name}.name`).toLowerCase();
    const upsellPaymentMethodTypes = plan.is_subscription
      ? paymentMethodTypes
      : paymentMethodTypes.filter(
          (item) => item !== PaymentMethodType.MobilePay,
        );

    const modal = (
      <PaymentModal
        plans={[plan]}
        adId={listingId}
        rentableId={rentableId}
        onSuccess={() => onSuccess(plan)}
        onSubscriptionActivated={onSubscriptionActivated}
        type="upsell"
        paymentMethodTypes={upsellPaymentMethodTypes}
        title={t("payment_modal.upsell.title_with_productname", {
          product_name: product_name,
        })}
      />
    );
    modalActions.showModal(modal);
  };

  const UpsellScreen = upsellScreenMap[App.settings.market];

  return (
    <>
      {accountInfo !== null && accountInfo.enabled && (
        <Flex
          column
          mb={5}
        >
          <CurrencyAmountLabel
            label={`${t("upsell_page.account_balance")}:`}
            amount={accountInfo.balance}
            size="h5"
          />
        </Flex>
      )}

      <Headline
        as="h1"
        variant="h2"
        m={0}
      >
        {t("upsell_products.pro_landlord.headline")}
      </Headline>
      <Text>{t("upsell_products.pro_landlord.description")}</Text>

      <UpsellScreen
        plans={plans}
        user={user}
        activeUpsellSubscriptions={activeUpsellSubscriptions}
        listingId={listingId}
        handleOpenModal={handleOpenModal}
        isAdLessThan24HoursOld={isAdLessThan24HoursOld}
        isPrivateLandlord={isPrivateLandlord}
      />
    </>
  );
};
