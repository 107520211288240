import {
  Notice,
  IconAlertCircle,
  Box,
  Button,
  IconArrowForward,
} from "@boligportal/juice";
import { IsRetrivingEnergyLabelOverlay } from "features/rentable/IsRetrivingEnergyLabelOverlay";
import { useRentableEnergyLabelRetriever } from "features/rentable/useRentableEnergyLabelRetriever";
import { t } from "lib/i18n";
import { DomutechSource } from "./DomuTechDialog";

export const DomutechNoticeWithDialog = ({
  rentableId,
  rentableAddress,
  source,
}: {
  rentableId: number;
  rentableAddress?: string;
  source: DomutechSource;
}) => {
  const { setRentableEnergyLabelCandidate, isRetrivingEnergyLabel } =
    useRentableEnergyLabelRetriever();

  return (
    <>
      <Notice
        type="info"
        icon={<IconAlertCircle size="large" />}
      >
        <strong>{t("energy_label.fetching.not_found.error.title")}</strong>
        <Box mb={2}>{t("energy_label.fetching.not_found.error.content")}</Box>
        <Button
          iconAfter={IconArrowForward}
          text
          onClick={() => {
            setRentableEnergyLabelCandidate({
              address: rentableAddress ?? "",
              rentableId,
              onSuccess: () => {},
              source,
            });
          }}
        >
          {t("leads.domutech.get_offer")}
        </Button>
      </Notice>
      {isRetrivingEnergyLabel && <IsRetrivingEnergyLabelOverlay />}
    </>
  );
};
