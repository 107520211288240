import * as React from "react";
import {
  Button,
  CheckField,
  Text,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Notice,
  Link,
} from "@boligportal/juice";
import { useTenancyDeletionMutation } from "api/mutations";
import { t } from "lib/i18n";

type TenancyDeletionCandidate = {
  tenancyId: number;
  rentableId: number | null;
  onDeleteSuccess?: () => void;
};

type TenancyDeletionContextValue = {
  open: boolean;
  candidate: TenancyDeletionCandidate | null;
  setTenancyDeletionCandidate: (
    candidate: TenancyDeletionCandidate | null,
  ) => void;
  onCancel: () => void;
  invalidationQueries: string[];
};

const TenancyDeletionContext = React.createContext<TenancyDeletionContextValue>(
  null!,
);

type ProviderProps = {
  children: React.ReactNode;
  invalidationQueries: string[];
};

const TenancyDeletionDialog = () => {
  const { candidate, open, onCancel, invalidationQueries } = React.useContext(
    TenancyDeletionContext,
  );
  const [didConsentToDelection, setDidConsentToDelection] =
    React.useState(false);
  const { canDeleteTenancyDryRun, deleteTenancyMutation } =
    useTenancyDeletionMutation(invalidationQueries);

  const closeAndResetConsentCheckmark = () => {
    setDidConsentToDelection(false);
    onCancel();
  };

  React.useEffect(() => {
    if (candidate?.tenancyId) {
      canDeleteTenancyDryRun.mutate(candidate.tenancyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  if (!candidate) {
    return null;
  }

  if (canDeleteTenancyDryRun.isIdle || canDeleteTenancyDryRun.isLoading) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={closeAndResetConsentCheckmark}
      size="large"
    >
      <DialogHeader>{t("common.tenancy.delete_tenancy")}</DialogHeader>
      <DialogContent>
        {canDeleteTenancyDryRun.status === "error" && (
          <Flex column>
            <Text
              markdown
              pb={2}
            >
              {t("tenancy.deletion.error_message_markdown")}
            </Text>
            <Flex
              column
              border
              borderRadius="md"
              bg="tint"
              pt={2}
              px={2}
            >
              <Text
                block
                weight="bold"
                pb={1}
              >
                {t("tenancy.deletion.error_codes.title")}
              </Text>
              <Notice type="danger">
                <Link
                  href="#"
                  underline="hover"
                  onClick={() =>
                    (window.location.href = `/property-owner/my-rentables/${candidate.rentableId}/tenancies/${candidate.tenancyId}`)
                  }
                >
                  {t("tenancy.deletion.error.has_future_payments_markdown")}
                </Link>
              </Notice>
            </Flex>
          </Flex>
        )}
        {canDeleteTenancyDryRun.status === "success" && (
          <Flex column>
            <Text
              markdown
              pb={2}
            >
              {t("tenancy.deletion.message_consent_markdown")}
            </Text>
            <CheckField
              label={t("tenancy.deletion.check_field_consent")}
              checked={didConsentToDelection}
              onChange={(event) =>
                setDidConsentToDelection(event.target.checked)
              }
            />
          </Flex>
        )}
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={closeAndResetConsentCheckmark}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={!didConsentToDelection}
            variant="danger"
            onClick={() => {
              deleteTenancyMutation
                .mutateAsync(candidate.tenancyId)
                .then((isSuccess) => {
                  if (isSuccess && candidate.onDeleteSuccess) {
                    candidate.onDeleteSuccess();
                  }
                })
                .finally(() => {
                  closeAndResetConsentCheckmark();
                });
            }}
          >
            {t("common.tenancy.delete_tenancy")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};

export const TenancyDeletionProvider = ({
  children,
  invalidationQueries,
}: ProviderProps) => {
  const [tenancyDeletionCandidate, setTenancyDeletionCandidate] =
    React.useState<TenancyDeletionCandidate | null>(null);
  return (
    <TenancyDeletionContext.Provider
      value={{
        open: Boolean(tenancyDeletionCandidate),
        candidate: tenancyDeletionCandidate,
        onCancel: () => setTenancyDeletionCandidate(null),
        setTenancyDeletionCandidate,
        invalidationQueries,
      }}
    >
      {children}
      <TenancyDeletionDialog />
    </TenancyDeletionContext.Provider>
  );
};

export const useTenancyDeletion = () => {
  const context = React.useContext(TenancyDeletionContext);

  if (!context) {
    throw "useTenancyDeletion hook can only be used inside function component as a Child of TenancyDeletionProvider";
  }

  const { setTenancyDeletionCandidate } = context;

  return {
    setTenancyDeletionCandidate,
  };
};
