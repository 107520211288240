import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

export const SwedishApartmentInfo = () => {
  const { register, formState } = useFormContext();

  return (
    <TextField
      type="number"
      label={t("create.field.swedish_apartment_info.heading")}
      {...register("apartment_number", {
        maxLength: 4,
        minLength: 4,
      })}
      errorText={
        formState.errors.apartment_number &&
        t("create.field.swedish_apartment_info.error_text")
      }
    />
  );
};
