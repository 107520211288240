import { ExternalLookupable } from "../interfaces/ExternalLookupable";

export const createExternalPhoneWebsites = (
  names: string[],
): ExternalLookupable[] => {
  const candidates: ExternalLookupable[] = [];

  names.forEach((name) => {
    const resolver = urlResolverMap[name];

    if (resolver) {
      candidates.push({
        name: name,
        urlResolver: resolver,
      });
    } else {
      console.warn(
        `no phone url resolver for: ${name}, Please check your market settings.`,
      );
    }
  });
  return candidates;
};

const krakPhoneUrlResolver = (phoneNumber: string) =>
  encodeURI(`https://map.krak.dk/s/${phoneNumber}`);
const deGuleSiderPersonPhoneUrlResolver = (phoneNumber: string) =>
  encodeURI(`https://kort.degulesider.dk/?q=${phoneNumber}`);
const hundredEightyPhoneUrlResolver = (phoneNumber: string) =>
  encodeURI(`https://www.180.dk/nummer/${phoneNumber}`);

const ratsItPhoneUrlResolver = (phoneNumber: string) => {
  // remove any country code and a leading zero
  let ratsitPhone = phoneNumber;
  if (ratsitPhone.charAt(0) === "+") {
    ratsitPhone = ratsitPhone.split(" ").join("");
    ratsitPhone = ratsitPhone.substr(3);
    ratsitPhone = `0${ratsitPhone}`;
  }

  return encodeURI(
    `https://www.ratsit.se/sok/person?vem=${ratsitPhone}&var=&m=1&k=1&r=1&er=1&b=1&eb=1&amin=&amax=&fon=1&typ=1&page=1`,
  );
};

const eniroPhoneUrlResolver = (phoneNumber: string) =>
  encodeURI(`https://www.eniro.se/${phoneNumber}/f%C3%B6retag`);

const hittaPhoneUrlResolver = (phoneNumber: string) => {
  const hittaPhone = phoneNumber.replace(/\s+/g, "");
  return encodeURI(`https://www.hitta.se/vem-ringde/${hittaPhone}`);
};

const urlResolverMap = {
  RatsIt: ratsItPhoneUrlResolver,
  Eniro: eniroPhoneUrlResolver,
  Hitta: hittaPhoneUrlResolver,
  Krak: krakPhoneUrlResolver,
  180: hundredEightyPhoneUrlResolver,
  DeGuleSider: deGuleSiderPersonPhoneUrlResolver,
};
