import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  IconArrowBack,
  Button,
  styled,
  Container,
  Hidden,
} from "@boligportal/juice";
import { HeaderContext } from "components/Providers/HeaderProvider";

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.color.bg.base};
  overflow: hidden;
`;

export const CustomHeader = () => {
  const { header } = useContext(HeaderContext);

  if (!header) {
    return null;
  }

  const renderTitle = () => {
    const { title } = header;

    if (!title) {
      return null;
    }

    if (typeof title === "string") {
      return (
        <Box>
          <Text
            size="h1"
            weight="bold"
          >
            {header.title}
          </Text>
        </Box>
      );
    }

    return title;
  };

  return (
    <StyledBox>
      <Container fluid>
        <Flex
          column
          mt={{
            xs: 2,
            lg: 3,
          }}
          mb={3}
        >
          <Hidden
            on={{
              lg: header.back && !header.back.desktop,
            }}
          >
            <>
              {header.back && (
                <Box mb={2}>
                  <Link to={header.back.to}>
                    <Button text>
                      <IconArrowBack inline /> {header.back.label}
                    </Button>
                  </Link>
                </Box>
              )}
            </>
          </Hidden>

          {renderTitle()}

          {header.subtitle && (
            <Box>
              <Text
                color="muted"
                size="small"
              >
                {header.subtitle}
              </Text>
            </Box>
          )}

          {header.actions && (
            <Flex
              justify="start"
              gap
              wrap
              mt={2}
            >
              {header.actions}
            </Flex>
          )}
        </Flex>
      </Container>

      {header.tabs && (
        <Box borderBottom>
          <Container fluid>{header.tabs}</Container>
        </Box>
      )}
    </StyledBox>
  );
};
