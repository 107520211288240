import { IconCheckmarkCircle, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

export const DialogLeadSucess = () => (
  <div className="flex flex-col items-center">
    <IconCheckmarkCircle
      color="success"
      mr={1}
      size="100px"
    />
    <Text
      block
      weight="semiBold"
      textAlign="center"
      mb={3}
    >
      {t("tenant_hub.leads.dialog.success_message")}
    </Text>
  </div>
);
