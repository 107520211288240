import { Plan } from "components/interfaces/plan";
import { t } from "lib/i18n";

export const getApplePayPlanTranslation = (plan: Plan) => {
  if (plan.duration_days === 1) {
    return {
      description: t("oneoff_applepay_intro_description"),
      billingAgreement: t("applepay_24h_intro_period_description", {
        price: plan.price.total,
        nextPrice: plan.next_plan?.price.total,
      }),
    };
  }

  if (plan.duration_days === 62) {
    return {
      description: t("oneoff_applepay_full_description"),
      billingAgreement: t("applepay_2m_subscription_description", {
        price: plan.price.total,
      }),
    };
  }

  return {
    description: t("oneoff_applepay_full_description"),
    billingAgreement: t("applepay_2m_short_description", {
      price: plan.price.total,
    }),
  };
};

export const getGooglePayPlanTranslation = (plan: Plan) => {
  if (plan.duration_days === 1) {
    return {
      description: t("oneoff_googlepay_intro_description"),
      billingAgreement: t("googlepay_24h_intro_period_description", {
        price: plan.price.total,
        nextPrice: plan.next_plan?.price.total,
      }),
    };
  }

  if (plan.duration_days === 62) {
    return {
      description: t("oneoff_googlepay_full_description"),
      billingAgreement: t("googlepay_2m_subscription_description", {
        price: plan.price.total,
      }),
    };
  }

  return {
    description: t("oneoff_googlepay_full_description"),
    billingAgreement: t("googlepay_2m_short_description", {
      price: plan.price.total,
    }),
  };
};
