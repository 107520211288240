import * as React from "react";
import { useQueryClient } from "react-query";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { SignUpPermissions } from "components/interfaces/signup_permissions";
import { User } from "components/interfaces/user";
import { modalActions } from "components/modal_controller";
import * as analytics from "lib/analytics";
import { API, LoginResult } from "lib/api";
import { TrackingEvent } from "lib/tracking/events";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { AuthView } from "./auth_modal";

const useAuthActions = (props: {
  view?: AuthView;
  onSuccess?;
  targetUrl?: string;
}) => {
  const { view, onSuccess, targetUrl } = props;
  const [authModalState, setAuthModalState] = React.useState<{
    view: AuthView;
  }>({
    view: view!,
  });

  const { reloadAppProps } = useAppActions();
  const modalActionsInstance = React.useContext(modalActions);
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    modalActionsInstance.closeModal();

    const voidFunc = () => undefined;
    const handleOnSuccess = onSuccess || voidFunc;

    // Reload all queries
    queryClient.invalidateQueries();

    // "Refresh" the page.
    reloadAppProps().then(
      // Technically speaking, the user might become logged out somehow, but it's unlikely,
      // so we can cast it to a User. Otherwise, we should probably handle it with an error message?
      (appProps) => handleOnSuccess(appProps.user as User, appProps.page_props),
    );
  };

  const showLoginModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    modal: JSX.Element,
  ) => {
    event.preventDefault();
    modalActionsInstance.showModal(modal);
  };

  const showCreateModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    modal: JSX.Element,
  ) => {
    event.preventDefault();
    modalActionsInstance.showModal(modal);
  };
  const actions = {
    switchView: (view: AuthView) => {
      setAuthModalState({
        view: view,
      });
    },

    signup: (
      firstName: string,
      lastName: string,
      username: string,
      password: string,
      signupPermissions: SignUpPermissions,
      preferredLanguage: string,
    ) =>
      API.signUp(
        firstName,
        lastName,
        username,
        password,
        signupPermissions,
        preferredLanguage,
      ).then((result) => {
        if (!result.errors) {
          TrackingEvent.newUserCreated("email");
          FacebookTrackingEvent.trackCompleteRegistration();
        }
        return result;
      }),

    login: (username: string, password: string) =>
      API.login(username, password).then((result) => {
        if (result == LoginResult.successful) {
          TrackingEvent.userLoggedIn("email");
          analytics.userLoggedIn("Email");
        }
        return result;
      }),

    logout: () => API.logout().then((result) => result),

    onLoginSuccessful: async () => {
      handleSuccess();
      if (targetUrl) {
        window.location.href = decodeURIComponent(targetUrl);
      }
    },
  };
  return {
    actions,
    authModalState,
    modalActionsInstance,
    showLoginModal,
    showCreateModal,
  };
};

export default useAuthActions;
