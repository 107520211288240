import * as React from "react";
import { Dialog, DialogHeader } from "@boligportal/juice";
import { ChooseTenant } from "features/choose_tenant/components/choose_tenant";

type Props = {
  open: boolean;
  slideOverTitle: string;
  onClose: () => void;
} & React.ComponentProps<typeof ChooseTenant>;

export const MarkAsRentedOutSlideOver = ({
  open,
  adId,
  rentableId,
  onMarkedRentedOut,
  preselectTenantId,
  slideOverTitle,
  onClose,
}: Props) => (
  <Dialog
    slideOver
    open={open}
    onClose={onClose}
  >
    <DialogHeader>{slideOverTitle}</DialogHeader>

    <ChooseTenant
      adId={adId}
      rentableId={rentableId}
      onMarkedRentedOut={onMarkedRentedOut}
      preselectTenantId={preselectTenantId}
    />
  </Dialog>
);
