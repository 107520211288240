import {
  Dialog,
  DialogHeader,
  DialogContent,
  Center,
  Spinner,
} from "@boligportal/juice";
import { ContractNudgeDialogContent } from "./ContractNudgeDialogContent";

export type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  isFetching: boolean;
  description: string;
  onConfirm: () => void;
  onConfirmText: string;
};

export const ContractNudgeDialog = ({
  open,
  onClose,
  title,
  subtitle,
  isFetching,
  description,
  onConfirm,
  onConfirmText,
}: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogContent>
      {isFetching ? (
        <Center>
          <Spinner size="medium" />
        </Center>
      ) : (
        <ContractNudgeDialogContent
          description={description}
          onConfirm={onConfirm}
          onConfirmText={onConfirmText}
          subtitle={subtitle}
        />
      )}
    </DialogContent>
  </Dialog>
);
