import * as React from "react";
import { Button, IconClose, Text } from "@boligportal/juice";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "components/dialog/dialog";
import { modalActions } from "components/modal_controller";
import { Tracking } from "lib/tracking/common";
import { Modal } from "./modal";

type DialogModalHeaderProps = {
  title: React.ReactNode;
  onClose?: () => void;
};

export const DialogModalHeader = ({
  title,
  onClose,
}: DialogModalHeaderProps) => {
  const modal = React.useContext(modalActions);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      modal.closeModal();
    }
  };

  return (
    <DialogHeader>
      <Text
        size="h4"
        weight="bold"
      >
        {title}
      </Text>
      <Button
        text
        onClick={handleClose}
      >
        <IconClose size="large" />
      </Button>
    </DialogHeader>
  );
};

type DialogModalBodyProps = {
  children: React.ReactNode;
};
export const DialogModalBody = ({ children }: DialogModalBodyProps) => (
  <DialogBody>{children}</DialogBody>
);

type DialogModalFooterProps = {
  children: React.ReactNode;
};
export const DialogModalFooter = ({ children }: DialogModalFooterProps) => (
  <DialogFooter>{children}</DialogFooter>
);

type DialogModalProps = {
  virtualPageviewURL?: string;
  virtualPageviewTitle?: string;
  children: React.ReactNode;
};
export class DialogModal extends React.Component<DialogModalProps> {
  onShown = () => {
    const { virtualPageviewTitle, virtualPageviewURL } = this.props;

    if (virtualPageviewURL && virtualPageviewTitle) {
      Tracking.trackVirtualPageview(virtualPageviewURL, virtualPageviewTitle);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <Modal onInsertedIntoDOM={this.onShown}>
        <Dialog>{children}</Dialog>
      </Modal>
    );
  }
}
