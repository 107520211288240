/* eslint-disable comma-dangle */
import { ListItemRendererPosition, Box, Text } from "@boligportal/juice";
import { SEAddressCandidate } from "../interfaces/SEAddressCandidate";

export const AddressCandidateRenderer = (
  item: SEAddressCandidate,
  isFocussed: boolean,
  position: ListItemRendererPosition,
) => (
  <Box
    px={2}
    py={1}
    bg={isFocussed ? "tint" : "base"}
    borderBottom
    borderLeft
    borderRight
    borderRadiusBL={position.isLast ? "md" : undefined}
    borderRadiusBR={position.isLast ? "md" : undefined}
  >
    <Box>
      <Text weight="semiBold">{item.candidateAddress}</Text>
    </Box>
    <Box>
      <Text size="small">{item.extra}</Text>
    </Box>
  </Box>
);
