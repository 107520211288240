import { useState } from "react";
import { API } from "lib/api";

export const useLogout = () => {
  const [shouldShowConfirmLogoutDialog, setShouldShowConfirmLogoutDialog] =
    useState(false);

  const confirmLogout = () => {
    API.logout().then(() => {
      window.location.href = "/";
    });
  };

  const showConfirmLogoutDialog = () => {
    setShouldShowConfirmLogoutDialog(true);
  };

  const hideConfirmLogoutDialog = () => {
    setShouldShowConfirmLogoutDialog(false);
  };

  return {
    shouldShowConfirmLogoutDialog,
    confirmLogout,
    showConfirmLogoutDialog,
    hideConfirmLogoutDialog,
  };
};
