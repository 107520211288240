import { LatLngLocation } from "components/interfaces/location";
import { fetchFromAPI } from "lib/api";

const getAdLocationService = async (adId: number): Promise<LatLngLocation> => {
  const url = `/customer-service/api/ad/${adId}/location`;
  try {
    const response = await fetchFromAPI(url);
    const data = await response.json();
    return data.location;
  } catch (error) {
    // eslint-disable-next-line compat/compat
    return Promise.reject(error);
  }
};

export { getAdLocationService };
