import { fetchFromAPI } from "lib/api";
import { CrawlersListItem } from "lib/customerServiceApi";

const getCrawlersService = async (
  query: string,
  offset?: number,
  limit?: number,
  signal?: AbortSignal,
  active?: boolean,
  sorting?: string,
  descending?: boolean,
): Promise<{ count: number; results: CrawlersListItem[] }> => {
  const url = "/customer-service/api/crawlings";

  try {
    const response = await fetchFromAPI(url, {
      signal,
      method: "POST",
      body: JSON.stringify({
        filters: {
          query,
          active,
        },
        offset,
        limit,
        sorting,
        descending,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getCrawlersService };
