import { ExternalLookupable } from "../interfaces/ExternalLookupable";

export const createExternalAddressWebsites = (
  names: string[],
): ExternalLookupable[] => {
  const candidates: ExternalLookupable[] = [];

  names.forEach((name) => {
    const resolver = urlResolverMap[name];

    if (resolver) {
      candidates.push({
        name: name,
        urlResolver: resolver,
      });
    } else {
      console.warn(
        `no address url resolver for: ${name}, Please check your market settings.`,
      );
    }
  });
  return candidates;
};

const ratsItUrlResolver = (fullAddress: string) =>
  encodeURI(
    `https://www.ratsit.se/sok/person?vem=&var=${fullAddress}&m=1&k=1&r=1&er=1&b=1&eb=1&amin=&amax=&fon=1&typ=1&page=1`,
  );

const eniroAddressUrlResolver = (fullAddress: string) =>
  encodeURI(`https://kartor.eniro.se/s/${fullAddress}`);

const hittaAddressUrlResolver = (fullAddress: string) =>
  encodeURI(`https://www.hitta.se/s%C3%B6k?vad=${fullAddress}`);

const krakAddressUrlResolver = (fullAddress: string) =>
  encodeURI(`https://map.krak.dk/s/${fullAddress}`);

const urlResolverMap = {
  RatsIt: ratsItUrlResolver,
  Eniro: eniroAddressUrlResolver,
  Hitta: hittaAddressUrlResolver,
  Krak: krakAddressUrlResolver,
};
