import { Box, Flex, IconClose, Text, styled } from "@boligportal/juice";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { dateHoursOld } from "lib/date";
import { t } from "lib/i18n";

const HeartMinus = () => (
  <svg
    width="132"
    height="122"
    viewBox="0 0 132 122"
    fill="none"
    style={{
      width: "100%",
    }}
  >
    <path
      d="m 65.816011,121.5 c -2.0323,-0.003 -4.0168,-0.617 -5.6953,-1.762 -24.8664,-16.881 -35.6337,-28.4548 -41.5726,-35.691 C 5.8918315,68.6222 -0.16734654,52.7861 0.00351346,35.6368 0.20284946,15.9848 15.969411,0 35.149911,0 c 13.9472,0 23.6071,7.85637 29.2328,14.3996 0.1782,0.2052 0.3984,0.3698 0.6456,0.4825 0.2473,0.1128 0.5159,0.1711 0.7877,0.1711 0.2718,0 0.5404,-0.0583 0.7876,-0.1711 0.2473,-0.1127 0.4675,-0.2773 0.6457,-0.4825 C 72.875011,7.85004 82.534911,0 96.482111,0 c 19.180899,0 34.946899,15.9848 35.145899,35.64 0.171,17.1524 -5.894,32.9885 -18.544,48.4102 -5.939,7.2362 -16.706299,18.8098 -41.572699,35.6908 -1.6789,1.144 -3.6634,1.757 -5.6953,1.759 z"
      fill="#ed6b2d"
      id="path1799"
    />
    <circle
      style={{
        fill: "#ffffff",
      }}
      id="path3781"
      cx="43.645344"
      cy="72.065643"
      r="32.940411"
    />
    <rect
      style={{
        fill: "#ed6b2d",
      }}
      id="rect9025"
      width="24.519554"
      height="6.9348235"
      x="31.385567"
      y="68.598228"
    />
  </svg>
);

const StyledDummyCard = styled(Flex)`
  position: relative;
  background: #ffffff;
`;

const StyledClose = styled(Box)`
  position: absolute;
  top: ${(props) => props.theme.unit(1)};
  right: ${(props) => props.theme.unit(1)};
`;

const UNAVAILABLE_REMINDER_DATE = "unavailable_reminder_start_date";
const SHOW_AFTER_DAYS = 3;

export const ReminderCard = ({
  unavailablePercent,
}: {
  unavailablePercent: string;
}) => {
  const [dateReminderHidden, setDateReminderHidden] = useLocalStorageState(
    UNAVAILABLE_REMINDER_DATE,
    null,
  );

  const isHidden =
    dateReminderHidden &&
    dateHoursOld(new Date(dateReminderHidden)) < SHOW_AFTER_DAYS * 24;

  if (isHidden) {
    return null;
  }

  const handleClose = () => {
    setDateReminderHidden(new Date());
  };

  return (
    <StyledDummyCard
      border
      align="center"
      m={0}
      p={2}
      py={{
        xs: 4,
        md: 2,
      }}
      borderRadius={{
        xs: "sm",
        md: "md",
      }}
    >
      <StyledClose onClick={handleClose}>
        <IconClose
          size="medium"
          color="muted"
        />
      </StyledClose>

      <Flex
        align="center"
        my="auto"
        column={{
          xs: false,
          md: true,
        }}
      >
        <Box
          width={{
            xs: "33%",
            md: "auto",
          }}
          mr={{
            xs: 2,
            md: 0,
          }}
          p={{
            xs: 1,
            md: 0,
          }}
        >
          <HeartMinus />
        </Box>
        <Box
          width={{
            xs: "66%",
            md: "auto",
          }}
        >
          <Text
            textAlign={{
              xs: "left",
              md: "center",
            }}
            block
            weight="bold"
            size={{
              xs: "body",
              md: "lead",
            }}
            mt={{
              xs: 0,
              md: 4,
            }}
            mb={2}
          >
            {t("my_favourite_ads.reminder.title", {
              count: unavailablePercent,
            })}
          </Text>
          <Text
            block
            size={{
              xs: "small",
              md: "body",
            }}
            textAlign={{
              xs: "left",
              md: "center",
            }}
          >
            {t("my_favourite_ads.reminder.description")}
          </Text>
        </Box>
      </Flex>
    </StyledDummyCard>
  );
};
