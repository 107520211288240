import * as React from "react";
import {
  Box,
  Flex,
  FlexColumn,
  Hidden,
  Text,
  styled,
  IconLockClosed,
} from "@boligportal/juice";
import RoundCheckmark from "media/icons/RoundCheckmark";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";
import { BulletSectionParagraph11 } from "./Icons/BulletSectionParagraph11";
import { NemId } from "./Icons/NemId";

const CustomHeadline = styled(Text)`
  font-weight: 500;
  color: #1e1e1e;
`;

const BulletItemIconWrapper = styled(Flex)`
  background: #fff;
  padding: ${(props) => props.theme.unit(1)};
  width: 40px;
  height: 40px;
`;

interface BulletItemProps {
  headline: string;
  text: string;
  justify: "start" | "center" | "end";
  icon: React.ReactNode;
}

const BulletItem = ({ headline, text, justify, icon }: BulletItemProps) => (
  <Flex
    gap
    my={2}
    align="center"
    justify={{
      xs: "center",
      md: "start",
      lg: justify,
    }}
    textAlign={{
      xs: "center",
      md: "left",
    }}
  >
    <Hidden
      on={{
        xs: true,
        md: false,
      }}
    >
      <BulletItemIconWrapper
        justify="center"
        align="center"
        borderRadius="full"
        my="auto"
      >
        {icon}
      </BulletItemIconWrapper>
    </Hidden>
    <Flex column>
      <CustomHeadline size="h3">{headline}</CustomHeadline>
      <Text
        size="small"
        color="muted"
      >
        {text}
      </Text>
    </Flex>
  </Flex>
);

interface Props {
  heading1: string;
  heading2: string;
  heading3: string;
  text1: string;
  text2: string;
  text3: string;
}

const BulletSection = ({
  heading1,
  heading2,
  heading3,
  text1,
  text2,
  text3,
}: Props) => (
  <PageBlock
    bg="gray"
    py="small"
    pyMobile="medium"
    bottomShadow
  >
    <ContractsLandingPageContainer>
      <Flex
        column
        align="center"
      >
        <Hidden
          on={{
            xs: false,
            md: true,
          }}
        >
          <Box my={2}>
            <RoundCheckmark fontSize="46px" />
          </Box>
        </Hidden>
        <Flex
          column={{
            xs: true,
            lg: false,
          }}
          align="center"
          width={{
            lg: "100%",
          }}
        >
          <FlexColumn
            size={{
              xs: 12,
              lg: 4,
            }}
          >
            <BulletItem
              headline={heading1}
              text={text1}
              justify="start"
              icon={<BulletSectionParagraph11 width="20px" />}
            />
          </FlexColumn>
          <FlexColumn
            size={{
              xs: 12,
              lg: 4,
            }}
          >
            <BulletItem
              headline={heading2}
              text={text2}
              justify="center"
              icon={<NemId />}
            />
          </FlexColumn>
          <FlexColumn
            size={{
              xs: 12,
              lg: 4,
            }}
          >
            <BulletItem
              headline={heading3}
              text={text3}
              justify="end"
              icon={
                <IconLockClosed
                  size="medium"
                  color="primary"
                />
              }
            />
          </FlexColumn>
        </Flex>
      </Flex>
    </ContractsLandingPageContainer>
  </PageBlock>
);

export { BulletSection };
