import { AdStates } from "lib/enums";
import { AdsPageAll } from "./ads_page_all";

const AdsPageApproved = () => (
  <AdsPageAll
    initialStates={[
      AdStates.ACTIVE_APPROVED,
      AdStates.RESERVED_APPROVED,
      AdStates.RENTED_OUT_APPROVED,
    ]}
    actionLabelText={"View"}
  />
);

export { AdsPageApproved };
