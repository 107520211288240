import * as React from "react";
import { usePaymentRecordOneTimePaymentMutation } from "api/mutations";
import { TenancyPaymentProviderStatus } from "business/domain/tenancy/TenancyPaymentProviderStatus";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { PaymentRecordOneTimePaymentNotPossible } from "features/rentable_tenancy/components/PaymentRecordOneTimePaymentNotPossible";
import { TenancyOneTimePaymentDialog } from "features/rentable_tenancy/components/TenancyOneTimePaymentDialog";

type PaymentRecordOneTimePaymentCandidate = {
  tenancyId: number;
  dueDate: string;
  rentItems: TenancyRentEntity[];
  oneTimePayments: TenancyRentEntity[];
  paymentRecordId: number;
  providerStatus: TenancyPaymentProviderStatus | null;
  paymentType: TenancyPaymentType;
  wasManuallyChanged: boolean;
  isPaymentEditable: boolean;
};

type PaymentRecordOneTimePaymentContextValue = {
  open: boolean;
  candidate: PaymentRecordOneTimePaymentCandidate | null;
  setPaymentRecordOneTimePaymentCandidate: (
    candidate: PaymentRecordOneTimePaymentCandidate | null,
  ) => void;
  onCancel: () => void;
  invalidationQueries: string[];
};

const PaymentRecordOneTimePaymentContext =
  React.createContext<PaymentRecordOneTimePaymentContextValue>(null!);

type ProviderProps = {
  children: React.ReactNode;
  invalidationQueries: string[];
};

const PaymentRecordOneTimePaymentDialog = () => {
  const { candidate, open, onCancel, invalidationQueries } = React.useContext(
    PaymentRecordOneTimePaymentContext,
  );
  const oneTimePaymentMutation =
    usePaymentRecordOneTimePaymentMutation(invalidationQueries);

  if (!candidate) {
    return null;
  }

  if (candidate.isPaymentEditable) {
    return (
      <TenancyOneTimePaymentDialog
        dueDate={candidate.dueDate}
        onCancel={onCancel}
        open={open}
        oneTimePayments={candidate.oneTimePayments}
        rentItems={candidate.rentItems}
        onSubmit={(extraPayments) => {
          oneTimePaymentMutation.mutate(
            {
              tenancyId: candidate.tenancyId,
              paymentRecordId: candidate.paymentRecordId,
              payload: extraPayments,
            },
            {
              onSettled: onCancel,
            },
          );
        }}
      />
    );
  }

  return (
    <PaymentRecordOneTimePaymentNotPossible
      open={open}
      onConfirm={onCancel}
    />
  );
};

export const PaymentRecordOneTimePaymentProvider = ({
  children,
  invalidationQueries,
}: ProviderProps) => {
  const [
    paymentRecordOneTimePaymentCandidate,
    setPaymentRecordOneTimePaymentCandidate,
  ] = React.useState<PaymentRecordOneTimePaymentCandidate | null>(null);
  return (
    <PaymentRecordOneTimePaymentContext.Provider
      value={{
        open: Boolean(paymentRecordOneTimePaymentCandidate),
        candidate: paymentRecordOneTimePaymentCandidate,
        invalidationQueries,
        setPaymentRecordOneTimePaymentCandidate,
        onCancel: () => setPaymentRecordOneTimePaymentCandidate(null),
      }}
    >
      {children}
      <PaymentRecordOneTimePaymentDialog />
    </PaymentRecordOneTimePaymentContext.Provider>
  );
};

export const usePaymentRecordOneTimePayment = () => {
  const context = React.useContext(PaymentRecordOneTimePaymentContext);
  if (!context) {
    throw "usePaymentRecordOneTimePayment hook can only be used inside function component as a Child of PaymentRecordOneTimePaymentProvider";
  }

  const { setPaymentRecordOneTimePaymentCandidate, open } = context;

  return {
    isActive: open,
    setPaymentRecordOneTimePaymentCandidate,
  };
};
