import { useFormContext } from "react-hook-form";
import { Divider, styled, TextField } from "@boligportal/juice";
import { t } from "lib/i18n";
import { InteractiveFloorPlanInfo } from "./matterport_floor_plan_with_modal_explainer";

export const StyledTextBlock = styled.span`
  background: ${(props) => props.theme.colorPalette.blue[100]};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

const Rental3dFloorplan = () => {
  const { register } = useFormContext();
  return (
    <>
      <Divider />
      <TextField
        type="text"
        label={t("create.field.3d_floor_plan.heading")}
        {...register("interactive_floor_plan_id")}
      />

      <InteractiveFloorPlanInfo />

      <Divider />
    </>
  );
};

export { Rental3dFloorplan };
