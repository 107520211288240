import { fetchFromAPI } from "lib/api";
import { SearchCompaniesResponse } from "lib/customerServiceApi";
import { Filters } from "../use_search_companies_with_pagination";

const searchCompaniesService = async (
  signal: AbortSignal,
  offset: number,
  limit: number,
  filters: Filters,
  sorting: { column: string; descending: boolean },
): Promise<SearchCompaniesResponse> => {
  const url = encodeURI("/customer-service/api/companies/");
  try {
    const response = await fetchFromAPI(url, {
      signal,
      method: "POST",
      body: JSON.stringify({
        offset,
        limit,
        filters,
        sorting: sorting.column,
        descending: sorting.descending,
      }),
    });

    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export { searchCompaniesService };
