import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Confirm,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  useNotifications,
} from "@boligportal/juice";
import { ClassNames } from "@emotion/react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { PageNavigationPosition } from "powerbi-models";
import { Report } from "report";
import { fetchFromAPI } from "../../../lib/api";
import { usePeriodicallyRefreshingToken } from "../hooks/usePeriodicallyRefreshingToken";

interface Props {
  reportId?: string;
  pdfDownloadButtonId?: string;
  powerBiReportGuid: string;
  refreshTokenUrl: string;
  getEmbedUrlUrl: string; // URL for retrieving the report's embedding URL, hence "...UrlUrl".
  pageName?: string;
  showPageNavigation?: boolean;
}

interface OngoingDownloadState {
  startTime: number;
  lastCheckTime: number;
  getPdfExportStatus: () => Promise<string>;
  downloadFinishedPdf: () => Promise<Blob>;
}

enum PdfExportStatus {
  InProgress = "IN_PROGRESS",
  Failed = "FAILED",
  Done = "DONE",
}

export const EmbeddedPowerBIReport = ({
  reportId,
  pdfDownloadButtonId,
  powerBiReportGuid,
  refreshTokenUrl,
  getEmbedUrlUrl,
  pageName,
  showPageNavigation,
}: Props) => {
  const { addNotification } = useNotifications();
  const periodicallyRefreshingToken =
    usePeriodicallyRefreshingToken(refreshTokenUrl);
  const [embedUrl, setEmbedUrl] = React.useState<string | undefined>(undefined);
  const [showExportDialog, setShowExportDialog] =
    React.useState<boolean>(false);
  const [showCancellationConfirmation, setShowCancellationConfirmation] =
    React.useState<boolean>(false);
  const [ongoingDownloadState, setOngoingDownloadState] = React.useState<
    OngoingDownloadState | undefined
  >(undefined);

  const initiatePdfDownload = async (
    reportId: string,
    pageName: string,
    serializedBookmark: string,
  ): Promise<{
    getPdfExportStatus: () => Promise<string>;
    downloadFinishedPdf: () => Promise<Blob>;
  }> => {
    const exportId = await fetchFromAPI(
      `/api/data-insights/${reportId}/initiate-pdf-export/`,
      {
        method: "POST",
        body: JSON.stringify({
          page_name: pageName,
          serialized_bookmark: serializedBookmark,
        }),
      },
    )
      .then((response) => response.json())
      .then((json) => json["export_id"]);

    const getPdfExportStatus = async (): Promise<string> =>
      await fetchFromAPI(
        `/api/data-insights/${reportId}/get-pdf-export-status/`,
        {
          method: "POST",
          body: JSON.stringify({
            export_id: exportId,
          }),
        },
      )
        .then((response) => response.json())
        .then((json) => json["status"]);

    const downloadFinishedPdf = async (): Promise<Blob> => {
      const blob = await fetchFromAPI(
        `/api/data-insights/${reportId}/download-pdf/`,
        {
          method: "POST",
          body: JSON.stringify({
            export_id: exportId,
          }),
        },
      ).then((response) => response.blob());
      setShowExportDialog(false);

      return blob;
    };

    return {
      getPdfExportStatus: getPdfExportStatus,
      downloadFinishedPdf: downloadFinishedPdf,
    };
  };

  const cancelDownload = () => {
    setShowExportDialog(false);
    setShowCancellationConfirmation(false);
    setOngoingDownloadState(undefined);
  };

  const showFailedNotificationAndCancelDownload = () => {
    addNotification({
      title: "Eksport fejlede",
      content: "Noget gik galt under PDF eksporten.",
      autoDismiss: false,
    });

    cancelDownload();
  };

  const POLL_INTERVAL_MS = 2000;
  const TIMEOUT_MS = 100000;

  useEffect(() => {
    if (!ongoingDownloadState) {
      // No download to monitor.
      return;
    }

    const now = Date.now();

    if (ongoingDownloadState.startTime + TIMEOUT_MS < now) {
      cancelDownload();

      addNotification({
        title: "Eksport timeout",
        content: "PDF eksporten blev ikke færdig indenfor forventet tid.",
        autoDismiss: false,
      });

      return;
    }

    const nextCheckDelay = Math.max(
      0,
      ongoingDownloadState.lastCheckTime + POLL_INTERVAL_MS - now,
    );

    // Used to keep track of whether the component was unmounted.
    let checkCancelled = false;

    const checkDownloadStatus = async () => {
      const status = await ongoingDownloadState.getPdfExportStatus();

      if (checkCancelled) {
        return;
      }

      if (status === PdfExportStatus.InProgress) {
        setOngoingDownloadState({
          ...ongoingDownloadState,
          lastCheckTime: Date.now(),
        });
      } else {
        if (status === PdfExportStatus.Failed) {
          showFailedNotificationAndCancelDownload();
        } else if (status === PdfExportStatus.Done) {
          const blob = await ongoingDownloadState.downloadFinishedPdf();
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "report.pdf";
          link.click();

          setOngoingDownloadState(undefined);
        } else {
          console.error(`Unknown status: ${status}`);
        }
      }
    };

    setTimeout(
      () =>
        checkDownloadStatus().catch(() =>
          showFailedNotificationAndCancelDownload(),
        ),
      nextCheckDelay,
    );

    return () => {
      checkCancelled = true;
    };
  }, [addNotification, ongoingDownloadState]);

  // Fetch the embed URL.
  React.useEffect(() => {
    fetchFromAPI(getEmbedUrlUrl, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((json) => {
        setEmbedUrl(json["embed_url"]);
      });
  }, [powerBiReportGuid, getEmbedUrlUrl]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: "iframe { border: none; }",
        }}
      />
      <Dialog
        size="medium"
        open={showExportDialog}
        dismissable={false}
      >
        <DialogHeader>PDF-eksport</DialogHeader>
        <DialogContent waiting>
          <Text block>
            Eksport i gang, vent venligst. Dette kan tage op mod et minut.
          </Text>
        </DialogContent>
        <DialogFooter>
          <Button
            variant="danger"
            onClick={() => setShowCancellationConfirmation(true)}
          >
            Annullér eksport
          </Button>
        </DialogFooter>
      </Dialog>
      <Confirm
        onConfirm={() => {
          cancelDownload();

          addNotification({
            title: "Eksport annulleret",
            content: "PDF-eksporten blev annulleret.",
            autoDismiss: true,
          });
        }}
        onCancel={() => setShowCancellationConfirmation(false)}
        open={showCancellationConfirmation}
        title="Annullér eksport"
        message="Er du sikker på, du vil annullere eksporten?"
        confirmText="Ja"
        cancelText="Nej"
      />

      <Flex flexGrow>
        <ClassNames>
          {({ css }) => (
            <PowerBIEmbed
              cssClassName={css({
                minHeight: "100%",
                width: "100%",
                backgroundColor: "#F7F7F7",
              })}
              embedConfig={{
                type: "report",
                id: powerBiReportGuid,
                embedUrl: embedUrl,
                tokenType: models.TokenType.Embed,
                accessToken: periodicallyRefreshingToken,
                pageName: pageName,
                permissions: models.Permissions.Read,
                settings: {
                  background: models.BackgroundType.Transparent,
                  panes: {
                    filters: {
                      visible: false,
                    },
                    pageNavigation: {
                      position: PageNavigationPosition.Left,
                      visible: showPageNavigation ?? false,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "buttonClicked",
                    (event, report: Report) => {
                      if (
                        !reportId ||
                        !pdfDownloadButtonId ||
                        !event ||
                        !report
                      ) {
                        return;
                      }

                      const handleClick = async () => {
                        if (event.detail.id === pdfDownloadButtonId) {
                          setShowExportDialog(true);
                          const pdfPage = await report
                            .getPages()
                            .then((pages) =>
                              pages.find((page) => page.displayName === "PDF"),
                            );

                          if (!pdfPage) {
                            console.log("Page not found.");
                            return;
                          }

                          const activeBookmarkState =
                            await report.bookmarksManager
                              .capture()
                              .then((bookmark) => bookmark.state);

                          if (!activeBookmarkState) {
                            console.error("No active bookmark state.");
                            return;
                          }

                          const { downloadFinishedPdf, getPdfExportStatus } =
                            await initiatePdfDownload(
                              reportId,
                              pdfPage.name,
                              activeBookmarkState,
                            );

                          setOngoingDownloadState({
                            startTime: Date.now(),
                            lastCheckTime: Date.now(),
                            getPdfExportStatus: getPdfExportStatus,
                            downloadFinishedPdf: downloadFinishedPdf,
                          });
                        }
                      };

                      // TODO: inline?
                      handleClick().catch(() =>
                        showFailedNotificationAndCancelDownload(),
                      );
                    },
                  ],
                ])
              }
            />
          )}
        </ClassNames>
      </Flex>
    </>
  );
};
