import * as React from "react";
import { App } from "components/app";
import { getLocale } from "lib/utils";
import Polyglot from "node-polyglot";

export class TranslationsProvider extends React.Component<{
  language: string;
  children: React.ReactNode;
}> {
  constructor(props) {
    super(props);
    this.initI18n();
  }

  initI18n = () => {
    const { language } = this.props;
    const phrases = {};

    if (process.env.NODE_ENV !== "test") {
      // convert to Polyglot format.
      const translations = require("locale/" + language + ".po");
      for (const key of Object.keys(translations)) {
        if (!translations.hasOwnProperty(key)) {
          continue;
        }
        const value = translations[key];
        if (!Array.isArray(value)) {
          continue;
        }
        if (value[0] === null && value[1] === "") {
          // Skip missing translations.
          continue;
        }
        // Polyglot expects plural forms to be separated by 4 pipes
        phrases[key] = value.slice(1).join(" |||| ");
      }
    }

    App.i18n = new Polyglot({
      phrases,
      allowMissing: true,
      onMissingKey: (key, options, locale) => {
        if (process.env.NODE_ENV !== "test") {
          console.warn(`Missing ${locale} translation for "${key}"`);
        }
        return key;
      },
    });
    // Set locale (for pluralization rules)
    App.i18n.locale(getLocale());
  };

  render() {
    const { children } = this.props;

    return <>{children}</>;
  }
}
