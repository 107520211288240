import { Box, Text } from "@boligportal/juice";
import { StyledMessageButton } from "apps/inbox/components/StyledMessageButton";
import { RentalAdViewModel } from "../../interfaces/RentalAdViewModel";

export const RentalAdInboxUnseenThreadCountCell = (data: RentalAdViewModel) => {
  const { unseen_thread_count } = data;

  if (unseen_thread_count === null) {
    return <Empty />;
  }

  return <InboxStatus {...unseen_thread_count} />;
};

const Empty = () => <Text color="muted">–</Text>;

export const InboxStatus = ({
  unseenThreadCount,
  totalThreadCount,
  inboxUrl,
  openNewTab = true,
}: {
  unseenThreadCount: number;
  totalThreadCount: number;
  inboxUrl: string;
  openNewTab?: boolean;
}) => {
  if (totalThreadCount === 0) {
    return <Empty />;
  }

  return (
    <Box py={1}>
      <StyledMessageButton
        highlighted={unseenThreadCount > 0}
        href={inboxUrl}
        target={openNewTab ? "_blank" : "_self"}
      >
        {unseenThreadCount}
      </StyledMessageButton>
    </Box>
  );
};
