import {
  Profile,
  OCCUPATION,
  USER_GENDER,
  USER_TYPE,
} from "components/interfaces/profile";
import { format } from "date-fns";
import { HistoricProfileDetailItem } from "../../../../../lib/customerServiceApi";
import { UserForCustomerService } from "../user_detail_view/user_detail_view";

export interface ProfileHeaderDTO {
  fullname: string;
  id: number;
  image: string;
  email: string;
  date_created: string;
  last_login: string;
  last_edited: string;
  needs_review: boolean;
  refetch: () => void;
}

export interface ProfileDetailsDTO {
  birthday: string;
  description: string;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  pets: boolean | null;
  kids: boolean | null;
  smoking: boolean | null;
  share_apartment: boolean | null;
  occupation: OCCUPATION | null;
  user_type: USER_TYPE | null;
  user_gender: USER_GENDER | null;
  address: string;
  phone_number: string;
  postal_code: string;
  postal_town: string;
  user_id: number;
  historic_profile?: HistoricProfileDetailItem | null;
  bank_id_verified: boolean;
}

export const createProfileDetailsDTO = (
  profile: Profile,
  user: UserForCustomerService,
): ProfileDetailsDTO => ({
  birthday:
    profile.birthday === null
      ? ""
      : format(new Date(profile.birthday), "yyyy-MM-dd"),
  first_name: profile.first_name === null ? "" : profile.first_name,
  last_name: profile.last_name === null ? "" : profile.last_name,
  email: user.email,
  description: profile.description || "",
  pets: profile.pets,
  kids: profile.kids,
  smoking: profile.smoking,
  share_apartment: profile.share_apartment,
  occupation: profile.occupation,
  user_type: profile.user_type,
  user_gender: profile.user_gender,
  company_name: profile.company_name === null ? "" : profile.company_name,
  address: profile.address || "",
  phone_number: profile.phone_number || "",
  postal_code: profile.postal_code || "",
  postal_town: profile.postal_town || "",
  user_id: user.id,
  bank_id_verified: profile.bank_id_verified,
});

export const createProfileHeaderDTO = (
  profile: Profile,
  user: UserForCustomerService,
  refetch: () => void,
): ProfileHeaderDTO => {
  const firstname = profile.first_name || "unnamed";
  const lastname = profile.last_name || "unnamed";
  const image = profile.profile_image || "";

  return {
    fullname: profile.last_name
      ? `${firstname} ${lastname}`
      : profile.company_name || "",
    id: user.id,
    image: image,
    email: user.email,
    date_created: user.date_created
      ? format(new Date(user.date_created), "yyyy-MM-dd HH:mm:ss")
      : "Never",
    last_login: user.last_login
      ? format(new Date(user.last_login), "yyyy-MM-dd HH:mm:ss")
      : "Never",
    last_edited: format(new Date(user.last_edited), "yyyy-MM-dd HH:mm:ss"),
    needs_review: user.needs_review,
    refetch,
  };
};
