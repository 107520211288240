export const FlagDenmark = () => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_343_10887)">
      <path
        d="M27.0976 0H0.902446C0.404039 0 0 0.417868 0 0.933333V19.0667C0 19.5821 0.404039 20 0.902446 20H27.0976C27.596 20 28 19.5821 28 19.0667V0.933333C28 0.417868 27.596 0 27.0976 0Z"
        fill="#D63A3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H11V20H8V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 8.5V11.5H0V8.5H28Z"
        fill="white"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="19"
      rx="3.5"
      stroke="white"
    />
    <defs>
      <clipPath id="clip0_343_10887">
        <rect
          width="28"
          height="20"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
