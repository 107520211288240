import { useState } from "react";
import * as tus from "tus-js-client";

// https://github.com/tus/tus-js-client

// Custom hook to ease tus usage, and for the implementing view
// to easily have access to the progress value while uploading.
const useTusUploader = () => {
  const [progress, setProgress] = useState(0);

  const resetTusUploader = () => {
    setProgress(0);
  };

  const startUpload = (
    uploadUrl: string,
    file: File,
    onSuccess: () => void,
    onError: (error: Error) => void,
  ) => {
    const uploader = new tus.Upload(file, {
      uploadUrl: uploadUrl,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      uploadSize: file.size,
      onError: onError,
      onProgress: function (bytesUploaded, bytesTotal) {
        setProgress(Math.round((bytesUploaded / bytesTotal) * 100));
      },
      onSuccess: onSuccess,
    });

    uploader.start();
  };

  return {
    progress,
    startUpload,
    resetTusUploader,
  };
};

export { useTusUploader };
