import { useContext, useEffect, useState } from "react";
import {
  Text,
  Box,
  Dialog,
  EmptyBox,
  Flex,
  Button,
  DialogContent,
  DialogHeader,
  Paginator,
} from "@boligportal/juice";
import { UserDetailPageContext } from "../../user_detail_page_context";
import { CompanyDetailsContext } from "../company_details_context";
import { Company } from "../interfaces";
import { CompanySelector } from "./company_selector";
import { CreateNewCompany } from "./create_new_company";

export const AttachUserToCompanyEmptyScreen = () => {
  const { user } = useContext(UserDetailPageContext);
  const {
    fetchCompanies,
    companies,
    totalCompaniesCount,
    pageLimit,
    addUsersToCompany,
  } = useContext(CompanyDetailsContext);
  const [isCreateCompanyDialogVisible, setIsCreateCompanyDialogVisible] =
    useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetchCompanies(offset, pageLimit);
  }, [fetchCompanies, offset, pageLimit]);

  const closeCreateCompanyDialog = () => {
    setIsCreateCompanyDialogVisible(false);
  };

  const handleCompanySelected = async (company: Company): Promise<void> => {
    if (!user) {
      return Promise.reject();
    }
    return await addUsersToCompany(company.id, [user.id]);
  };

  return (
    <EmptyBox>
      <Flex column>
        <Text>User has no company attached</Text>
        <Box
          pt={2}
          m={2}
        >
          <Flex
            gap
            justify="center"
            width={"100%"}
          >
            <Dialog
              opener={<Button variant="primary">Attach user to company</Button>}
            >
              <DialogHeader>Attach user to existing company</DialogHeader>
              <DialogContent>
                <Flex justify="end">
                  <Button onClick={() => setIsCreateCompanyDialogVisible(true)}>
                    Create company and attach user
                  </Button>
                </Flex>
                <CompanySelector
                  companies={companies}
                  onCompanySelected={handleCompanySelected}
                />

                <Flex
                  column
                  py={4}
                  align="center"
                >
                  <Paginator
                    totalRecords={totalCompaniesCount}
                    pageLimit={pageLimit}
                    value={offset}
                    nextButtonLabel="Next"
                    previousButtonLabel="Prev"
                    onChange={setOffset}
                  />
                </Flex>
              </DialogContent>
            </Dialog>

            <Dialog
              slideOver
              open={isCreateCompanyDialogVisible}
              onClose={closeCreateCompanyDialog}
            >
              <DialogHeader>Create new Company</DialogHeader>
              <CreateNewCompany />
            </Dialog>
          </Flex>
        </Box>
      </Flex>
    </EmptyBox>
  );
};
