import { forwardRef } from "react";
import {
  IconArrowBackForward,
  IconDocumentTextOutline,
  IconLogo,
  IconMenu,
  IconPeopleOutline,
  IconRentables,
  IconServerOutline,
  Menu,
  focusBorder,
  styled,
} from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { useOldModal } from "components/modal_controller";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { HeaderAction, TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { MenuItemWithIcon } from "../components/MenuItemWithIcon";
import { MenuItem } from "../top_nav";

interface Props {
  inverseColor: boolean;
}

export const UnauthenticatedHamburgerMenu = ({ inverseColor }: Props) => {
  const modalActions = useOldModal();

  const {
    RENTAL_CONTRACTS,
    MOVING_REPORTS,
    HOUSING_ADMINISTRATION,
    CREATE_USER,
    LOGIN,
    RENT,
    RENTAL_DATA,
    CREATE_LISTING,
    CREATE_CONTRACT,
    CREATE_RENTABLE,
    CREATE_RENT,
    CREATE_MOVING_REPORT,
    CREATE_TENANCY,
    COMMERCIAL_CONTRACT,
    COMMERCIAL_MOVING_REPORT,
    COMMERCIAL_RENTING_OUT,
  } = menuTranslations();

  const createItems = [
    {
      name: CREATE_LISTING,
      url: App.settings.routes.property_owner.create_listing,
      icon: <IconRentables />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.ADVERTISE_RENTABLE,
      context: SignupContext.CREATE_RENTABLE,
    },
    {
      name: CREATE_RENTABLE,
      url: App.settings.routes.property_owner.my_rentables,
      icon: <IconLogo />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_RENTABLE,
      context: SignupContext.CREATE_RENTABLE,
    },
    {},
    {
      name: CREATE_CONTRACT,
      url: `${App.settings.routes.contracts}choose-rentable`,
      icon: <IconDocumentTextOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_CONTRACT,
      context: SignupContext.CONTRACTS,
    },
    {
      name: CREATE_MOVING_REPORT,
      url: `${App.settings.routes.moving_reports}choose-rentable`,
      icon: <IconArrowBackForward />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_MOVING_REPORT,
      context: SignupContext.MOVING_REPORTS,
    },
    {
      name: CREATE_TENANCY,
      url: `${App.settings.routes.tenancy_list}choose-rentable`,
      icon: <IconPeopleOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_TENANCY,
      context: SignupContext.CREATE_TENANCY,
    },
  ];

  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    createItems.push({
      name: CREATE_RENT,
      url: `${App.settings.routes.property_owner.rent}create`,
      icon: <IconServerOutline />,
      renderFunction: MenuItemWithIcon,
      trackingAction: HeaderAction.CREATE_RENT,
      context: SignupContext.CREATE_RENT_COLLECTION,
    });
  }

  const adminItems = [
    {
      name: CREATE_LISTING,
      url: App.settings.routes.renting_out_landing_page,
      trackingAction: HeaderAction.RENTAL_COLLECTION,
      context: SignupContext.CREATE_RENTABLE,
    },
    {
      name: RENTAL_CONTRACTS,
      url: App.settings.routes.contracts_lease_landingpage,
      trackingAction: HeaderAction.CONTRACTS,
      context: SignupContext.CONTRACTS,
    },
    {
      name: MOVING_REPORTS,
      url: App.settings.routes.moving_report_landingpage,
      trackingAction: HeaderAction.MOVING_REPORTS,
      context: SignupContext.MOVING_REPORTS,
    },
  ];

  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    adminItems.push(
      {
        name: HOUSING_ADMINISTRATION,
        url: App.settings.routes.housing_administration_landingpage,
        trackingAction: HeaderAction.BOLIGADMINISTRATION,
        context: SignupContext.RENTAL_DATA,
      },
      {
        name: RENT,
        url: App.settings.routes.rent_collection_landingpage,
        trackingAction: HeaderAction.RENT_OUT,
        context: SignupContext.CREATE_RENT_COLLECTION,
      },
      {
        name: RENTAL_DATA,
        url: App.settings.routes.rental_data_landingpage,
        trackingAction: HeaderAction.RENTAL_DATA,
        context: SignupContext.RENTAL_DATA,
      },
    );
  }

  const commercialItems = [
    {
      name: COMMERCIAL_RENTING_OUT,
      url: App.settings.routes.commercial_landing_pages.renting_out,
      trackingAction: HeaderAction.RENT_OUT,
      context: SignupContext.CONTRACTS,
    },
    {
      name: COMMERCIAL_CONTRACT,
      url: App.settings.routes.commercial_landing_pages.contract,
      trackingAction: HeaderAction.CONTRACTS,
      context: SignupContext.CONTRACTS,
    },
    {
      name: COMMERCIAL_MOVING_REPORT,
      url: App.settings.routes.commercial_landing_pages.moving_report,
      trackingAction: HeaderAction.MOVING_REPORTS,
      context: SignupContext.MOVING_REPORTS,
    },
  ];

  if (App.settings.market === Market.BOSTADSPORTAL_SE) {
    commercialItems.unshift({
      name: HOUSING_ADMINISTRATION,
      url: App.settings.routes.housing_administration_landingpage,
      trackingAction: HeaderAction.BOLIGADMINISTRATION,
      context: SignupContext.RENTAL_DATA,
    });
  }

  const hamburgerItems = [
    ...(App.settings.market === Market.BOSTADSPORTAL_SE
      ? [
          {
            name: t("menu.commercial.dropdown"),
            items: commercialItems,
          },
        ]
      : []),
    {
      name: t("menu.admin.dropdown"),
      items: adminItems,
    },
    {
      name: t("menu.create"),
      items: createItems,
    },
    {},
    {
      name: t("menu.my_listings"),
      url: App.settings.routes.landlord_listings,
      context: SignupContext.CREATE_RENTABLE,
    },
    {
      name: t("menu.inbox"),
      href: App.settings.routes.inbox,
    },
    {
      name: t("menu.my_favourite_ads"),
      url: App.settings.routes.my_favourite_ads,
      context: SignupContext.FAVORITE,
    },
    {},
    {
      name: LOGIN,
    },
  ];

  const handleItemClick = async ({
    name,
    url,
    trackingAction,
    context,
  }: MenuItem) => {
    const shouldShowAuthModal =
      (name &&
        [
          CREATE_USER,
          CREATE_LISTING,
          CREATE_RENTABLE,
          CREATE_RENT,
          CREATE_CONTRACT,
          CREATE_MOVING_REPORT,
          CREATE_TENANCY,
          LOGIN,
        ].includes(name)) ||
      (context &&
        [SignupContext.FAVORITE, SignupContext.CREATE_RENTABLE].includes(
          context,
        ));

    if (shouldShowAuthModal) {
      const view = name === LOGIN ? AuthView.LOGIN : AuthView.SIGNUPWITHEMAIL;
      modalActions.showModal(
        <AuthModal
          view={view}
          context={context}
          targetUrl={url}
        />,
      );
      return;
    }

    if (trackingAction && url) {
      await TrackingEvent.clickNavigationBar(trackingAction);
      window.location.href = url;
    }
  };

  return (
    <Menu
      align="end"
      items={hamburgerItems}
      onMenuItemSelect={handleItemClick}
      backButtonLabel={t("menu.back")}
      openerEl={(isOpened) => (
        <MenuButtonOpener
          isOpened={isOpened}
          inverseColor={inverseColor}
        />
      )}
    />
  );
};

const StyledIconMenu = styled(IconMenu)`
  font-size: 34px;
`;

const StyledBlankButton = styled.button`
  padding: 0;
  appearance: none;
  display: block;
  background: transparent;
  border: none;
  ${focusBorder};
`;

type HamburgerOpenerProps = { isOpened: boolean; inverseColor: boolean };

const MenuButtonOpener = forwardRef<HTMLButtonElement, HamburgerOpenerProps>(
  (props, ref) => {
    const { isOpened, inverseColor, ...rest } = props;
    return (
      <StyledBlankButton
        aria-haspopup="true"
        aria-expanded={isOpened}
        aria-label="Toggle dropdown"
        ref={ref}
        {...rest}
      >
        <StyledIconMenu color={inverseColor ? "inverted" : "base"} />
      </StyledBlankButton>
    );
  },
);
