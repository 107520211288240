import { App } from "components/app";
import { formatCurrency } from "lib/utils";

export const formatArrears = (amount: number) => {
  const arrears = formatCurrency(amount, App.settings.currency);

  if (amount <= 0) {
    return arrears;
  }

  return `+${arrears}`;
};
