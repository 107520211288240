import { Flex, Text, styled, useTheme } from "@boligportal/juice";
import {
  SigningStatus,
  ContractViewModel,
} from "apps/contracts/components/common/interfaces";

export const ContractStateCellRenderer = ({
  state,
  stateInfo,
}: ContractViewModel) => (
  <SigningStatusCell
    state={state}
    stateLabel={stateInfo.stateLabel}
    stateSubLabel={stateInfo.stateSubLabel}
  />
);

export const SigningStatusCell = ({
  state,
  stateLabel,
  stateSubLabel,
}: {
  state: SigningStatus;
  stateLabel: string;
  stateSubLabel: string;
}) => {
  const theme = useTheme();

  const contractStateColorMap = {
    [SigningStatus.DRAFT]: theme.colorPalette.gray[60],
    [SigningStatus.AWAITING]: theme.colorPalette.primary[800],
    [SigningStatus.COMPLETED]: theme.colorPalette.green[500],
  };

  const stateColor =
    contractStateColorMap[state] ?? theme.colorPalette.gray[500];

  return (
    <Flex align="center">
      <StyledStateColor color={stateColor} />
      <Flex column>
        <Text
          weight="semiBold"
          size="small"
        >
          {stateLabel}
        </Text>
        <Text
          color="muted"
          size="small"
        >
          {stateSubLabel}
        </Text>
      </Flex>
    </Flex>
  );
};

const StyledStateColor = styled.div<{ color: string }>`
  border-radius: 1px;
  width: 6px;
  height: 46px;
  margin-right: ${(props) => props.theme.unit(1)};
  background-color: ${(props) => props.color};
`;
