import * as React from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import {
  Button,
  CheckField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Flex,
  Labelled,
  TextField,
} from "@boligportal/juice";
import { DesktopImageOrganiserForCreateNewCompany } from "features/image_organizer/DesktopImageOrganiserForCreateNewCompany";
import { DesktopImageOrganiserForCreateNewCompanyLogo } from "features/image_organizer/DesktopImageOrganiserForCreateNewCompanyLogo";
import { SortableImagesFeature } from "features/image_organizer/interfaces/SortableImagesFeature";
import { AdStates } from "../../../../../../../lib/enums";
import { stringToSlug } from "../../helpers";
import { CompanyPageSlideOverFormValues } from "./useCompanyPageForm";

type Props = {
  title: string;
  submitButtonLabel: string;
  isOpen: boolean;
  submit: React.FormEventHandler<HTMLFormElement>;
  watch: UseFormWatch<CompanyPageSlideOverFormValues>;
  register: UseFormRegister<CompanyPageSlideOverFormValues>;
  logoImageFeature: SortableImagesFeature;
  sortableImagesFeature: SortableImagesFeature;
  onClose: () => void;
};

export const CompanyPageDialogForm = ({
  title,
  submitButtonLabel,
  isOpen,
  submit,
  watch,
  register,
  logoImageFeature,
  sortableImagesFeature,
  onClose,
}: Props) => (
  <Dialog
    slideOver
    onClose={onClose}
    open={isOpen}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogForm onSubmit={submit}>
      <DialogContent>
        <TextField
          helpText={"/cp/" + stringToSlug(watch("slug"))}
          label="URL"
          {...register("slug", {
            required: true,
          })}
        />
        <TextField
          helpText="Apply needed filters at search page, copy URL and paste here"
          label="Filter URL"
          {...register("filter_url", {
            required: false,
          })}
        />

        <Labelled label="Upload Logo image">
          <DesktopImageOrganiserForCreateNewCompanyLogo
            sortableImagesFeature={logoImageFeature}
          />
        </Labelled>

        <Labelled label="Upload Slideshow Images (Max 5 images)">
          <DesktopImageOrganiserForCreateNewCompany
            sortableImagesFeature={sortableImagesFeature}
          />
        </Labelled>

        <TextField
          label="Headline"
          {...register("headline", {
            required: false,
          })}
        />
        <TextField
          label="Lead"
          {...register("lead", {
            required: false,
          })}
        />
        <TextField
          label="Meta description"
          {...register("meta_description", {
            required: false,
          })}
        />
        <TextField
          label="Body"
          multiLine
          {...register("body", {
            required: false,
          })}
        />
        <CheckField
          {...register("demo", {
            required: false,
          })}
          inline
          label="Demo mode"
        />
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="primary"
            type="submit"
          >
            {submitButtonLabel}
          </Button>
        </Flex>
      </DialogFooter>
    </DialogForm>
  </Dialog>
);
