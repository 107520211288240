import * as React from "react";
import { styled } from "@boligportal/juice";

enum BadgePosition {
  topRight = "badgeTopRight",
  middleRight = "badgeMiddleRight",
  middleLeft = "badgeMiddleLeft",
}

const BadgeWrapper = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
`;

const BadgeContent = styled.div<{ position: BadgePosition }>`
  ${(props) =>
    props.position === BadgePosition.topRight &&
    "border-radius: 50%; overflow: hidden;"}
  ${(props) =>
    props.position === BadgePosition.middleLeft && "margin-left: 25px;"}
`;

const BadgeCountWrapper = styled.span<{ position?: BadgePosition }>`
  pointer-events: none;
  background-color: ${(props) => props.theme.colorPalette.brand[500]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: 10px;
  z-index: 1;
  transform-origin: 100% 0%;
  font-size: 12px;
  color: #ffffff;
  font-weight: 500;
  box-shadow: 0 0 0 2px white;

  ${(props) =>
    props.position === BadgePosition.topRight &&
    `right: 0;
    transform: scale(1) translate(50%, -50%);`}

  ${(props) =>
    props.position === BadgePosition.middleRight &&
    `right: 0;
    transform: scale(1) translate(130%, 9%);`}


  ${(props) => props.position === BadgePosition.middleLeft && "left: 0;"}
`;

const StyledLinkWrapper = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;
interface BadgeProps {
  children?: React.ReactNode;
  count?: number;
  max?: number;
  position?: BadgePosition;
  countStyle?: React.CSSProperties;
  clickable?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler;
}

export const Badge = ({
  children,
  count = 0,
  max = 99,
  position = BadgePosition.topRight,
  clickable,
  href,
  countStyle,
  onClick,
}: BadgeProps) => {
  const badgeLabel: string = count >= max ? `${max}+` : `${count}`;
  const BadgeWrapperAndContent = () => (
    <BadgeWrapper>
      {count > 0 && (
        <BadgeCountWrapper
          position={position}
          style={countStyle}
        >
          {badgeLabel}
        </BadgeCountWrapper>
      )}

      {children && <BadgeContent position={position}>{children}</BadgeContent>}
    </BadgeWrapper>
  );

  if (clickable) {
    return (
      <StyledLinkWrapper
        href={href}
        onClick={onClick}
      >
        <BadgeWrapperAndContent />
      </StyledLinkWrapper>
    );
  } else {
    return (
      <div onClick={onClick}>
        <BadgeWrapperAndContent />
      </div>
    );
  }
};
