import { useFormContext } from "react-hook-form";
import { CheckField, Flex } from "@boligportal/juice";
import { CreateListingForm } from "apps/property_owner/create_listing_form/interfaces/CreateListingForm";
import { HeaderLabel } from "components/forms/header_label";
import { t } from "lib/i18n";

export const RentalContractAdOptions = () => {
  const { register } = useFormContext<CreateListingForm>();
  return (
    <Flex column>
      <HeaderLabel label={t("create.contract_ad_options.heading")} />
      <CheckField
        label={t("create.field.created_draft_contract.label")}
        {...register("created_draft_contract")}
      />
    </Flex>
  );
};
