import { formatOrdinal } from "lib/date";
import { t } from "lib/i18n";

export const getFloorOptions = () => {
  const options = [
    {
      label: "",
      value: "",
    },
    {
      value: "-1",
      label: t("floor.basement"),
    },
    {
      value: "0",
      label: t("floor.ground_floor"),
    },
  ];

  for (let floorNumber = 1; floorNumber <= 50; floorNumber++) {
    options.push({
      value: floorNumber.toString(),
      label: t("floor.%{floor_number}_suffix", {
        floor_number: formatOrdinal(floorNumber),
      }),
    });
  }

  return options;
};
