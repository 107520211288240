import { UseFormGetValues, UseFormTrigger } from "react-hook-form";
import { Flex, styled } from "@boligportal/juice";
import { useGetMutationStatusByAddress } from "../../hooks/useGetMutationStatusByAddress";
import { CreateRentableForm, RentableCreationCandidate } from "../../types";
import { getShouldCreateRentableWithEstate } from "../BulkCreate/getShouldCreateRentableWithEstate";
import { Address, Props as AddressProps } from "./Address";
import { CreationStatus, Props as CreationStatusProps } from "./CreationStatus";

const StyledFlex = styled(Flex)`
  align-self: stretch;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    // 3 units bottom margin is equivalent of the labelled fields has
    margin-bottom: ${(props) => props.theme.unit(3)};
  }
`;

type Props = Omit<CreationStatusProps & AddressProps, "retry" | "status"> & {
  address: string;
  getValues: UseFormGetValues<CreateRentableForm>;
  trigger: UseFormTrigger<CreateRentableForm>;
};

// CreationStatus and Address is bundled here since they both make use of the useGetMutationStatusByAddress
// hook. Only running it once per rentable instead of twice per rentable is a noteable performance improvement.
export const CreationStatusAndAddress = ({
  address,
  disabled,
  register,
  setValue,
  fieldName,
  getValues,
  trigger,
}: Props) => {
  const { status, createRentable } = useGetMutationStatusByAddress(
    address,
    disabled,
  );

  const retry = async () => {
    const {
      address,
      category,
      roomCount,
      sizeM2,
      estate,
    }: RentableCreationCandidate = getValues(fieldName);
    // Validate if the rentable fields still passes validation
    const isValid = trigger(fieldName, {
      shouldFocus: true,
    });

    // Validating a single rentable (passing argument to trigger) doesn't cause a rerender
    // Therefore we validate the entire form to trigger rerender and show errors.
    if (!isValid) {
      trigger();
      return;
    }

    const form = getValues();

    const shouldCreateRentableWithEstate = getShouldCreateRentableWithEstate(
      form.rentables,
    );

    createRentable({
      address,
      category: category!,
      room_count: roomCount!,
      size_m2: sizeM2!,
      apartment_number: null,
      estate: shouldCreateRentableWithEstate ? estate : null,
    })
      .then((res) => setValue(`${fieldName}.rentableId`, res.rentable_id))
      .catch(() => {});
  };

  return (
    <>
      <StyledFlex align="center">
        <CreationStatus
          register={register}
          fieldName={fieldName}
          disabled={disabled}
          status={status}
          retry={retry}
        />
      </StyledFlex>

      <StyledFlex
        column
        justify="center"
      >
        <Address
          address={address}
          disabled={disabled}
          status={status}
          fieldName={fieldName}
          getValues={getValues}
          setValue={setValue}
          retry={retry}
        />
      </StyledFlex>
    </>
  );
};
