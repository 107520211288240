import { fetchFromAPI } from "lib/api";
import { CrawlingDetails } from "lib/customerServiceApi";

const getCrawlingService = async (
  crawlingId: number,
): Promise<CrawlingDetails> => {
  const url = encodeURI(`/customer-service/api/crawlings/${crawlingId}/`);
  const response = await fetchFromAPI(url);
  return response.json();
};

export { getCrawlingService };
