import * as React from "react";
import { DataTable, SortableColumn, useMedia } from "@boligportal/juice";
import { MobileDataList } from "./MobileDataList";

export interface DesktopConfig<T> {
  useElipsisForSingleRowActions?: boolean;
  columns: {
    fieldName: keyof T;
    fieldLabel: string;
    fieldLabelRenderer?: React.ReactNode;
    alignRight?: boolean;
    width?: string;
    cellRenderer?: (data: T) => React.ReactNode;
    sortable?: boolean;
  }[];
  variant?: "simple" | "striped";
  hideTableHead?: boolean;
  onSortClick?: (fieldname: keyof T, descending: boolean) => void;
  sortableColumn?: SortableColumn<T>;
}

export interface MobileConfig<T> {
  useFluidContainer?: boolean;
  primary?: { rowRenderer: (data: T) => React.ReactNode };
  secondaries: {
    fieldName?: keyof T | undefined;
    fieldLabel?: string | undefined;
    fieldLabelFunction?: (data: T) => React.ReactNode;
    fieldValueFunction?: (data: T) => string | React.ReactNode;
  }[];
}

interface SharedRowActions<T> {
  callback?: (data: T) => void;
  label?: string;
  labelFunction?: (item: T) => React.ReactNode;
  disableFunction?: (data: T) => boolean;
}
[];

interface Props<T> {
  items: T[];
  keyField: keyof T;
  desktop: DesktopConfig<T>;
  mobile: MobileConfig<T>;
  rowActions?: SharedRowActions<T>[];
  fullscreen?: boolean;
  /**
   * Defaults to xl breakpoint and up
   */
  shouldShowDataTable?: () => boolean;
}

export const DataTableWithMobileDataList = <T extends unknown>({
  items,
  keyField,
  desktop,
  mobile,
  rowActions,
  fullscreen = true,
  shouldShowDataTable,
}: Props<T>) => {
  shouldShowDataTable = shouldShowDataTable ?? useWhenToShowDataTableDefault;

  if (shouldShowDataTable()) {
    return (
      <DataTable
        useElipsisForSingleRowActions={
          desktop.useElipsisForSingleRowActions ?? false
        }
        items={items}
        fullscreen={fullscreen}
        columns={desktop.columns}
        keyField={keyField}
        rowActions={rowActions as any}
        onSortClick={desktop.onSortClick}
        activeSortableColumn={desktop.sortableColumn}
        variant={desktop.variant}
        hideTableHead={desktop.hideTableHead}
      />
    );
  }
  return (
    <MobileDataList
      useFluidContainer={mobile.useFluidContainer}
      items={items}
      keyField={keyField}
      primary={mobile.primary}
      secondaries={mobile.secondaries}
      rowActions={rowActions as any}
    />
  );
};

const useWhenToShowDataTableDefault = () => {
  const media = useMedia(0);
  return media.xl.up;
};
