import {
  Box,
  DialogContent,
  DialogHeader,
  Flex,
  Headline,
} from "@boligportal/juice";
import {
  ImpersonateCompanyColleague,
  ImpersonateCompanyColleagueState,
} from "../useImpersonateCompanyColleague";
import { ImpersonateableColleague } from "./ImpersonateableColleague";

export const ImpersonateCompanyColleagueDialogContent = ({
  state,
  impersonateCompanyColleague,
}: {
  state: ImpersonateCompanyColleagueState;
  impersonateCompanyColleague: ImpersonateCompanyColleague;
}) => (
  <>
    <DialogHeader>{state.title}</DialogHeader>
    <DialogContent>
      <Headline as="h4">{state.companyName}</Headline>
      <Flex column>
        {state.colleagues.map((colleague) => (
          <Box key={colleague.id}>
            <ImpersonateableColleague
              colleague={colleague}
              impersonateCompanyColleague={impersonateCompanyColleague}
            />
          </Box>
        ))}
      </Flex>
    </DialogContent>
  </>
);
