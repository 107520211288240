import * as React from "react";
import { Dialog, DialogHeader, useNotifications } from "@boligportal/juice";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import { t } from "lib/i18n";
import { DialogContentWithInternalState } from "./components/DialogContentWithInternalState";
import { TenancyDiff } from "./types";

type ProviderProps = {
  diff: TenancyDiff | null;
  isEnabled: boolean;
  tenancyId: number | null;
  rentableId?: number;
  noticeDescription: string;
  children: React.ReactNode;
  onUpdateSuccess: () => Promise<void>;
};

type TenancyDiffUpdateCandidate = {
  tenancyId: number;
  diff: TenancyDiff;
};

type TenancyDiffUpdateContextValue = {
  displayDiffDialog: (() => void) | null;
  open: boolean;
  onCancel: () => void;
  onUpdateSuccess: () => Promise<void>;
  candidate: TenancyDiffUpdateCandidate | null;
};

const TenancyDiffUpdateContext =
  React.createContext<TenancyDiffUpdateContextValue>(null!);

const TenancyDiffUpdateProvider = ({
  children,
  isEnabled,
  tenancyId,
  rentableId,
  onUpdateSuccess,
  diff,
  noticeDescription,
}: ProviderProps) => {
  const [candidate, setCandidate] =
    React.useState<TenancyDiffUpdateCandidate | null>(null);

  const handleDisplayDiffDialog = () => {
    if (diff && tenancyId) {
      setCandidate({
        tenancyId,
        diff,
      });
    }
  };

  const hasDiffs =
    diff &&
    (diff.tenancy_base_diff !== null || diff.tenancy_tenants_diff.length > 0);

  return (
    <TenancyDiffUpdateContext.Provider
      value={{
        candidate,
        open: Boolean(candidate),
        onCancel: () => setCandidate(null),
        onUpdateSuccess,
        displayDiffDialog:
          isEnabled && hasDiffs ? handleDisplayDiffDialog : null,
      }}
    >
      {children}
      <TenancyDiffUpdaterDialog
        noticeDescription={noticeDescription}
        rentableId={rentableId}
      />
    </TenancyDiffUpdateContext.Provider>
  );
};

const TenancyDiffUpdaterDialog = ({
  noticeDescription,
  rentableId,
}: {
  noticeDescription: string;
  rentableId?: number;
}) => {
  const { candidate, open, onCancel, onUpdateSuccess } = React.useContext(
    TenancyDiffUpdateContext,
  );

  const { addNotification } = useNotifications();

  if (!candidate) {
    return null;
  }

  const handleUpdateSuccess = async () => {
    await onUpdateSuccess();

    if (rentableId) {
      addNotification({
        title: t("common.tenancy.notification.update_title"),
        actions: [
          {
            label: t("common.tenancy.notification.actions.goto_tenancy"),
            onClick: () => {
              window.location.href = `/property-owner/my-rentables/${rentableId}/tenancies/${candidate.tenancyId}`;
            },
          },
        ],
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <DialogHeader>{t("tenancy.diff_update.title")}</DialogHeader>
      <DialogContentWithInternalState
        tenancyId={candidate.tenancyId}
        tenancyBaseDiff={candidate.diff.tenancy_base_diff}
        tenancyTenantsDiff={candidate.diff.tenancy_tenants_diff}
        noticeDescription={noticeDescription}
        onCancel={onCancel}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Dialog>
  );
};

const useTenancyDiffUpdater = () => {
  const context = React.useContext(TenancyDiffUpdateContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useTenancyDiffUpdater",
      "TenancyDiffUpdateProvider",
    );
  }

  const { displayDiffDialog } = context;

  return {
    displayDiffDialog,
  };
};

export { useTenancyDiffUpdater, TenancyDiffUpdateProvider };
