import { OrderType } from "lib/enums";
import { t } from "lib/i18n";

export const getSortingOptions = () => [
  {
    value: OrderType.DEFAULT,
    label: t("sort_by.Age newest first"),
  },
  {
    value: OrderType.RENT_ASC,
    label: t("sort_by.Price low to high"),
  },
  {
    value: OrderType.RENT_DESC,
    label: t("sort_by.Price high to low"),
  },
  {
    value: OrderType.SIZE_M2_DESC,
    label: t("sort_by.Size m2"),
  },
  {
    value: OrderType.SIZE_M2_ASC,
    label: t("sort_by.Size m2 low to high"),
  },
];
