import * as React from "react";
import { Box, styled, useTheme } from "@boligportal/juice";

const StyledWrapper = styled.div<{
  bgGradient: { rotation: string; colors: string[] };
  bgColor?: string;
  negativeTopMargin?: string;
  bottomShadow?: boolean;
  topShadow?: boolean;
  fullViewHeight?: boolean;
}>`
  position: relative;
  background-color: ${(props) => props.bgColor};
  background-image: linear-gradient(
    ${(props) => props.bgGradient.rotation},
    ${(props) => props.bgGradient.colors.join(",")}
  );

  ${(props) => (props.fullViewHeight ? "height: 100vh" : null)};

  background-repeat: no-repeat;
  background-size: cover;
  margin-top: ${(props) => props.negativeTopMargin || "0"};
  box-shadow: ${(props) =>
    props.bottomShadow ? "inset 0 0 7px 0 rgba(0, 0, 0, 0.1)" : null};
  box-shadow: ${(props) =>
    props.topShadow ? "inset 0 7px 7px -7px rgba(0,0,0,0.1)" : null};
`;

type PageBlockProps = {
  bg?: "default" | "gray" | "grayDark" | "grayDarkest" | "brand" | "business";
  bgGradient?: boolean;
  bgBottomLeftOverlay?: React.ReactNode;
  bgBottomRightOverlay?: React.ReactNode;
  bgTransformOverlay?: {
    xAxis: string;
    yAxis: string;
  };
  /**
   * Vertical padding before and after Page Block content
   * Default unit value is 8 units
   */
  py?: "none" | "small" | "medium" | "large" | "extraLarge";
  pyMobile?: "none" | "small" | "medium" | "large" | "extraLarge";
  negativeTopMargin?: string;
  bottomShadow?: boolean;
  topShadow?: boolean;
  fullViewHeight?: boolean;
  children?: React.ReactNode;
};

const PageBlock = ({
  bg,
  bgGradient,
  py,
  pyMobile,
  children,
  bgBottomLeftOverlay,
  bgBottomRightOverlay,
  bgTransformOverlay,
  negativeTopMargin,
  bottomShadow,
  topShadow,
  fullViewHeight,
}: PageBlockProps) => {
  const theme = useTheme();

  // Only supporting gradient for business color
  const bgColor: {
    color: string;
    gradient: {
      rotation: string;
      colors: string[];
    };
  } = {
    default: {
      color: "#FFFFFF",
      gradient: {
        rotation: "0deg",
        colors: ["#FFFFFF", "#FFFFFF"],
      },
    },
    gray: {
      color: theme.colorPalette.gray[10],
      gradient: {
        rotation: "0deg",
        colors: [theme.colorPalette.gray[10], theme.colorPalette.gray[10]],
      },
    },
    grayDark: {
      color: theme.colorPalette.gray[500],
      gradient: {
        rotation: "0deg",
        colors: [theme.colorPalette.gray[500], theme.colorPalette.gray[500]],
      },
    },
    grayDarkest: {
      color: theme.colorPalette.gray[600],
      gradient: {
        rotation: "0deg",
        colors: [theme.colorPalette.gray[600], theme.colorPalette.gray[600]],
      },
    },
    brand: {
      color: theme.colorPalette.brand[500],
      gradient: {
        rotation: "0deg",
        colors: [theme.colorPalette.brand[500], theme.colorPalette.brand[500]],
      },
    },
    business: {
      color: theme.colorPalette.primary[800],
      gradient: {
        rotation: "131deg",
        colors: bgGradient
          ? [theme.colorPalette.primary[800], theme.colorPalette.primary[900]]
          : [],
      },
    },
  }[bg || "default"];

  let pyValue: number | undefined;
  switch (py) {
    case "none":
      pyValue = undefined;
      break;
    case "small":
      pyValue = 2;
      break;
    case "medium":
      pyValue = 4;
      break;
    case "large":
      pyValue = 8;
      break;
    case "extraLarge":
      pyValue = 14;
      break;
    default:
      pyValue = 8;
  }

  let pyValueMobile: number | undefined;
  switch (pyMobile) {
    case "none":
      pyValueMobile = 0;
      break;
    case "small":
      pyValueMobile = 2;
      break;
    case "medium":
      pyValueMobile = 4;
      break;
    case "large":
      pyValueMobile = 8;
      break;
    case "extraLarge":
      pyValueMobile = 14;
      break;
    default:
      pyValueMobile = undefined;
  }

  return (
    <StyledWrapper
      bgColor={bgColor.color}
      bgGradient={bgColor.gradient}
      negativeTopMargin={negativeTopMargin}
      bottomShadow={bottomShadow}
      topShadow={topShadow}
      fullViewHeight={fullViewHeight}
    >
      {bgBottomLeftOverlay && (
        <div
          style={{
            pointerEvents: "none",
            position: "absolute",
            transform: bgTransformOverlay
              ? `translate(${bgTransformOverlay.xAxis}, ${bgTransformOverlay.yAxis})`
              : "",
            right: 0,
            bottom: 0,
            left: 0,
            overflowX: "hidden",
          }}
        >
          {bgBottomLeftOverlay}
        </div>
      )}
      {bgBottomRightOverlay && (
        <div
          style={{
            pointerEvents: "none",
            position: "absolute",
            transform: bgTransformOverlay
              ? `translate(${bgTransformOverlay.xAxis}, ${bgTransformOverlay.yAxis})`
              : "",
            right: 0,
            bottom: 0,
            overflowX: "hidden",
          }}
        >
          {bgBottomRightOverlay}
        </div>
      )}
      <Box
        py={{
          xs: pyValueMobile === undefined ? pyValue : pyValueMobile,
          md: pyValue,
        }}
      >
        {children}
      </Box>
    </StyledWrapper>
  );
};

export { PageBlock };
