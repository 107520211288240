import {
  Button,
  IconEllipsisVertical,
  Menu,
  MenuBaseItem,
} from "@boligportal/juice";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { canUseMarkAsManuallyPaid } from "business/domain/tenancy/canUseMarkAsManuallyPaid";
import { isPast, isToday } from "date-fns";
import { t } from "lib/i18n";
import { DangerMenuItem } from "./components/CustomMenuItems";

export interface RentRecordElipsisMenuItem
  extends MenuBaseItem<RentRecordElipsisMenuItem> {}

export type RentRecordElipsisMenuCell = {
  rentRecordElipsisMenuCell: {
    status: TenancyPaymentStatus;
    paymentType: TenancyPaymentType;
    onMenuItemSelect: (item: RentRecordElipsisMenuItem) => void;
    arrears: number;
    paymentDueDate: string;
  };
};

export const rentRecordElipsisMenuCellRenderer = ({
  rentRecordElipsisMenuCell,
}: RentRecordElipsisMenuCell) => (
  <Menu<RentRecordElipsisMenuItem>
    align="end"
    verticalContentPadding={0}
    verticalSeparatorPadding={0}
    items={createMenuItems(
      rentRecordElipsisMenuCell.status,
      rentRecordElipsisMenuCell.paymentType,
    )}
    onMenuItemSelect={rentRecordElipsisMenuCell.onMenuItemSelect}
    openerEl={
      <Button
        variant="subtle"
        icon={IconEllipsisVertical}
      />
    }
  />
);

export const rentRecordElipsisMobileMenuCellRenderer = ({
  rentRecordElipsisMenuCell,
}: RentRecordElipsisMenuCell) => (
  <Menu<RentRecordElipsisMenuItem>
    align="end"
    verticalContentPadding={0}
    verticalSeparatorPadding={0}
    items={createMobileMenuItems(
      rentRecordElipsisMenuCell.status,
      rentRecordElipsisMenuCell.paymentType,
      rentRecordElipsisMenuCell.arrears,
      rentRecordElipsisMenuCell.paymentDueDate,
    )}
    onMenuItemSelect={rentRecordElipsisMenuCell.onMenuItemSelect}
    openerEl={
      <Button
        variant="subtle"
        icon={IconEllipsisVertical}
      />
    }
  />
);

const createMenuItems = (
  status: TenancyPaymentStatus,
  paymentType: TenancyPaymentType,
) => {
  const MARK_AS_MANUAL_PAID = t("rentable.rent.mark_as_manual_paid");
  const ADD_ONE_TIME_PAYMENT = t("rentable.rent.add_one_time_payment");
  const DELETE_PAYMENT = t("rentable.rent.delete_payment");
  const GOTO_SETUP_FOR_PAYMENT = t(
    "rentable.rent.go_to_rent_setup_for_tenancy",
  );

  let items: RentRecordElipsisMenuItem[] = [
    {
      name: ADD_ONE_TIME_PAYMENT,
    },
    {
      name: GOTO_SETUP_FOR_PAYMENT,
    },
    {},
    {
      name: DELETE_PAYMENT,
      renderFunction: DangerMenuItem,
    },
  ];

  if (canUseMarkAsManuallyPaid(status, paymentType)) {
    items = [
      {
        name: MARK_AS_MANUAL_PAID,
      },
      ...items,
    ];
  }

  return items;
};

const createMobileMenuItems = (
  status: TenancyPaymentStatus,
  paymentType: TenancyPaymentType,
  arrears: number,
  paymentDueDate: string,
) => {
  const items = createMenuItems(status, paymentType);
  const DETAILS = t("common.buttons.show_details");
  const MOVE_ARREARS = t("rentable.rent.transfer_arrears_to_another_payment");
  const MARK_PAYMENT_AS_PARTIALLY_PAID =
    arrears > 0
      ? t("rentable.rent.arrears_mark_as_manual_refund")
      : t("rentable.rent.arrears_mark_as_manual_paid");
  const moveArrearsEligible =
    arrears < 0 &&
    isPast(new Date(paymentDueDate)) &&
    !isToday(new Date(paymentDueDate));

  return [
    {
      name: DETAILS,
    },
    {
      name: MARK_PAYMENT_AS_PARTIALLY_PAID,
    },
    ...(moveArrearsEligible
      ? [
          {
            name: MOVE_ARREARS,
          },
        ]
      : []),
    ...items,
  ];
};
