import * as React from "react";
import { Box, Flex, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

interface Props {
  details: {
    title: string;
    text: React.ReactNode;
  }[];
  isNotResponsive?: boolean;
}
export const CardDetails = ({ details, isNotResponsive }: Props) => (
  <Flex
    hidden={
      isNotResponsive
        ? false
        : {
            xs: true,
            lg: false,
          }
    }
    column
    justify="center"
  >
    {details.map((detail) => (
      <CardDetail
        key={detail.title}
        detail={detail}
      />
    ))}
  </Flex>
);

type CardDetailProps = {
  detail: {
    title: string;
    text: React.ReactNode;
  };
};

const CardDetail = ({ detail }: CardDetailProps) => (
  <Flex
    mt="4px"
    align="baseline"
  >
    <Text
      weight="bold"
      size="small"
    >
      {detail.title}:
    </Text>

    <Box truncate>
      <Text
        ml={0.5}
        size="small"
      >
        {detail.text || (
          <Text
            size="small"
            color="muted"
          >
            {t("inbox.notSpecified")}
          </Text>
        )}
      </Text>
    </Box>
  </Flex>
);
