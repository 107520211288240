import * as React from "react";
import { Box, Flex, Headline, styled } from "@boligportal/juice";

const StyledBox = styled(Box)`
  position: relative;
  overflow: hidden;
`;

type Props = {
  children: React.ReactNode;
  hideTopBorders: boolean;
  title: string;
};

export const UpsellCardContent = ({
  children,
  title,
  hideTopBorders,
}: Props) => (
  <Flex
    column
    justify="start"
  >
    <StyledBox
      borderBottom
      borderLeft
      borderRight
      borderTop={!hideTopBorders}
      borderRadiusTL={hideTopBorders ? undefined : "md"}
      borderRadiusTR={hideTopBorders ? undefined : "md"}
      borderRadiusBL="md"
      borderRadiusBR="md"
      textAlign="center"
      p={4}
      width="100%"
      mb={{
        xs: 4,
      }}
      bg="base"
    >
      <Headline as="h3">{title}</Headline>
      {children}
    </StyledBox>
  </Flex>
);
