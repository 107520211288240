import { Controller } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const MoveInDateFormElement = <T extends any>(
  props: FormElementInterface<T>,
) => {
  const { isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
  );

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel={props.fieldLabel}
    >
      <Controller
        name={"available_from"}
        render={({ field: { onChange, value, ...rest } }) => (
          <TextField
            disabled={isSaving || props.isLocked}
            type="date"
            onChange={onChange}
            previousValue={props.previousValue}
            defaultValue={props.defaultValue}
            value={value}
            {...rest}
          />
        )}
      />
    </LockableFormField>
  );
};

export { MoveInDateFormElement };
