import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";

export const calculateTenancyPaymentsSum = (
  recurringRent: TenancyRentEntity[],
) => {
  let sum = 0;

  recurringRent.forEach((p) => {
    if (!isNaN(p.amount)) {
      sum += p.amount;
    }
  });

  return sum;
};
