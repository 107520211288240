import * as React from "react";
import { formatAddressWithExtraText } from "features/eco_system/utils/formatAddressWithExtraText";
import { useDebounce } from "hooks/useDebounce";
import { useBulkAddressAutocomplete } from "../../queries/useBulkAddressAutocomplete";
import { AddressSearchCandidate } from "./AddressSearchItemRenderer";

type Props = {
  onAddressChosen: (item: AddressSearchCandidate) => void;
};

export const useSearchAutocomplete = ({ onAddressChosen }: Props) => {
  const [inputValue, setInputValue] = React.useState({
    text: "",
    containerId: "",
  });
  const debouncedSearchValue = useDebounce(inputValue, 100);
  const { data: suggestions } = useBulkAddressAutocomplete(
    debouncedSearchValue.text,
    debouncedSearchValue.containerId,
  );
  const [showSuggestions, setShowSuggestions] = React.useState(true);

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestions(true);
    setInputValue({
      text: event.target.value,
      containerId: "",
    });
  };

  const lookForMatchesAgainOrChooseItem = React.useCallback(
    (item: AddressSearchCandidate) => {
      const text = formatAddressWithExtraText(item.address, item.extraText);

      setInputValue({
        text,
        containerId: item.id,
      });

      if (item.type === "address" || item.type === "bulk_create") {
        setShowSuggestions(false);
      }

      onAddressChosen(item);
    },
    [onAddressChosen],
  );

  return {
    searchValue: inputValue.text,
    updateSearchValue,
    selectAddress: lookForMatchesAgainOrChooseItem,
    suggestions: (showSuggestions && suggestions) || [],
  };
};
