import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
} from "@boligportal/juice";
import { t } from "lib/i18n";

interface Props {
  onConfirmed: () => void;
  onClose: () => void;
}

export const ConfirmLogoutDialog = ({ onConfirmed, onClose }: Props) => (
  <Dialog
    size="small"
    open
    onClose={onClose}
  >
    <DialogHeader>{t("logout.modal.header")}</DialogHeader>
    <DialogContent>{t("logout.modal.content")}</DialogContent>
    <DialogFooter>
      <Flex
        flexGrow
        column
      >
        <Button
          variant="branded"
          onClick={onConfirmed}
        >
          {t("logout.modal.confirm")}
        </Button>
        <Box my={1} />
        <Button
          text
          variant="subtle"
          onClick={onClose}
        >
          {t("logout.modal.cancel")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
