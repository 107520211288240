export const PaymentMethodCreditCards = ({
  cards,
}: {
  cards: React.ComponentType<React.SVGProps<SVGSVGElement>>[];
}) => (
  <div className="flex flex-row justify-center space-x-8">
    {cards.map((CreditCardSVG, index) => (
      //eslint-disable-next-line react/no-array-index-key
      <CreditCardSVG key={index} />
    ))}
  </div>
);
