import { claimLandlord } from "lib/customerServiceApi";
import { TaskResponseInterface } from "../../../interfaces";

export const takeOverAdTask = (
  adId: number,
  landlordId: number,
): Promise<TaskResponseInterface> =>
  claimLandlord(adId, landlordId, true)
    .then((response) => {
      if (response.success) {
        return Promise.resolve({
          success: true,
        });
      } else {
        return Promise.resolve({
          success: false,
        });
      }
    })
    .catch((error) =>
      Promise.resolve({
        success: false,
      }),
    );
