import {
  Box,
  Button,
  Flex,
  FlexColumn,
  Hidden,
  styled,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import { isBoligPortal } from "lib/utils";
import { LandingPageContainer } from "./LandingPageContainer";
import { PageBlock } from "./PageBlock";
import { Button55PxHeight } from "./top_hero_section";

const HeroHeader = styled.h2`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
`;
const HeroHeaderMobile = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
  text-align: center;
`;

const StyledOverflow = styled.div`
  overflow: hidden;
`;

const HouseSvg = () => (
  <svg
    width="492"
    height="500"
    viewBox="8 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const AdDetailCallToActionSection = ({
  headline,
  buttonText,
  trackingEvent,
}: {
  headline: string;
  buttonText: string;
  trackingEvent: () => void;
}) => {
  const { user } = useUser();
  const { modalActionsInstance } = useAuthActions({});

  const trackEventAndNavigateToCreateListing = () => {
    trackingEvent();
    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={App.settings.routes.property_owner.create_listing}
          context={SignupContext.CREATE_RENTABLE}
        />,
      );
    } else {
      window.location.href = App.settings.routes.property_owner.create_listing;
    }
  };

  return (
    <StyledOverflow>
      <PageBlock
        bg="business"
        bgGradient
        py="none"
        bgBottomLeftOverlay={
          <Box
            hidden={{
              xs: true,
              lg: false,
            }}
          >
            <HouseSvg />
          </Box>
        }
      >
        <LandingPageContainer>
          <Hidden
            on={{
              xs: true,
              lg: false,
            }}
          >
            <Box
              height="621px"
              pt={{
                lg: "165px",
              }}
            >
              <Flex>
                <FlexColumn size={7}>
                  <Box maxWidth="600px">
                    <HeroHeader>{headline}</HeroHeader>
                  </Box>
                  <Flex
                    column
                    mt={3}
                    mr={12}
                  >
                    <Button55PxHeight
                      testId={"rentOutCTA"}
                      variant="branded"
                      onClick={trackEventAndNavigateToCreateListing}
                    >
                      {buttonText}
                    </Button55PxHeight>
                  </Flex>
                </FlexColumn>
                <FlexColumn size={5}>
                  <CdnImage
                    style={{
                      transform: "translate(10px,0px)",
                      maxHeight: 460,
                      objectFit: "contain",
                      // on safari, with borderTopRightRadius/borderTopRightRadius the corners get displayed
                      // in a buggy/artifact-y way, but borderRadius works properly, so we can use that for the desktop view
                      // (since the bottom of the image doesn't get displayed)
                      // borderTopRightRadius: "5px",
                      // borderTopLeftRadius: "5px",
                      borderRadius: "5px",
                    }}
                    src={`/static/images/landing_page/renting_out/${
                      isBoligPortal() ? "BoligPortal" : "BostadsPortal"
                    }_Rentable.jpg`}
                  />
                </FlexColumn>
              </Flex>
            </Box>
          </Hidden>
        </LandingPageContainer>

        <Hidden
          on={{
            xs: false,
            lg: true,
          }}
        >
          <Box>
            <Box
              pt={8}
              px={2}
            >
              <HeroHeaderMobile>{headline}</HeroHeaderMobile>
            </Box>

            <Flex
              px={{
                xs: 2,
                md: 0,
              }}
              mt={3}
              justify="center"
            >
              <Button
                testId={"rentOutCTA"}
                fullWidth={{
                  xs: true,
                  md: false,
                }}
                onClick={trackEventAndNavigateToCreateListing}
                variant="branded"
              >
                {buttonText}
              </Button>
            </Flex>
            <Flex
              justify="start"
              pt={8}
            >
              {/*
                  hackaround for buggy border radius on safari (set white background and borderRadiusTR on wrapper box,
                  because we have to do borderRadius on the <img> itself)
                */}
              <Box
                bg="base"
                height="100%"
                width="90%"
                borderRadiusTR="md"
              >
                <CdnImage
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "6px",
                  }}
                  src={`/static/images/landing_page/renting_out/${
                    isBoligPortal() ? "BoligPortal" : "BostadsPortal"
                  }_Rentable_Mobile.jpg`}
                />
              </Box>
            </Flex>
          </Box>
        </Hidden>
      </PageBlock>
    </StyledOverflow>
  );
};
export { AdDetailCallToActionSection };
