import { useRef, useState, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  dialogActions,
  DialogContent,
  DialogFooter,
  Flex,
} from "@boligportal/juice";
import { RentableFields } from "components/forms/elements/FormElements";
import { GoBackToAutoAddressFormButton } from "features/address/common/components/GoBackToAutoAddressFormButton";
import { GotoManualAddressFormButton } from "features/address/common/components/GotoManualAddressFormButton";
import {
  AddressManualFormSweeden,
  AddressManualSweeden,
} from "features/address/se/AddressManualSweeden";
import { AddressSuggestionSweeden } from "features/address/se/AddressSuggestionSweeden";
import { transformAddressManualFormSwedenToManualAddress } from "features/address/se/helpers/transformAddressManualFormSwedenToManualAddress";
import { t } from "lib/i18n";

interface RentalAddressDialogProps {
  onAutoAddressUpdate: (autoSuggestedAddress: string) => void;
  onManualAddressUpdate: (manualAddressInput: Object) => void;
  startInManualAddressForm?: boolean;
  address: AddressManualFormSweeden;
  formatted_address?: string;
}

export const CS_EditAddressSweeden = ({
  onAutoAddressUpdate,
  onManualAddressUpdate,

  startInManualAddressForm,
  address,
  formatted_address,
}: RentalAddressDialogProps) => {
  const newAddressSuggestion = useRef<string>();

  const [addressViewState, setAddressViewState] = useState<"manual" | "auto">(
    startInManualAddressForm ? "manual" : "auto",
  );

  const allFormMethods = useForm<AddressManualFormSweeden>({
    defaultValues: address,
  });
  const { handleSubmit } = allFormMethods;

  const onAutoAddressSubmit = () => {
    if (newAddressSuggestion.current !== undefined) {
      onAutoAddressUpdate(newAddressSuggestion.current);
    }
  };

  const onManualAddressSubmit = (form: AddressManualFormSweeden) => {
    const manualAddress = transformAddressManualFormSwedenToManualAddress(form);
    onManualAddressUpdate(manualAddress);
  };

  return (
    <>
      <FormProvider {...allFormMethods}>
        <DialogContent>
          {addressViewState === "auto" && (
            <>
              <AddressSuggestionSweeden
                initialAddress={formatted_address}
                onAddress={(address) => {
                  newAddressSuggestion.current = address;
                }}
                onAddressCleared={() => {
                  newAddressSuggestion.current = undefined;
                }}
              />
              <GotoManualAddressFormButton
                hideHelpText
                onClick={() => setAddressViewState("manual")}
              />
            </>
          )}
          {addressViewState === "manual" && (
            <>
              <form
                onSubmit={handleSubmit(onManualAddressSubmit)}
                noValidate
              >
                <GoBackToAutoAddressFormButton
                  hideHelpText
                  onClick={() => setAddressViewState("auto")}
                />
                <AddressManualSweeden />
              </form>
            </>
          )}
        </DialogContent>
        <DialogFooter>
          <Flex gap>
            <CancelButton />
            {addressViewState === "manual" && (
              <Button
                variant="primary"
                onClick={handleSubmit(onManualAddressSubmit)}
              >
                {t("feature.address_form.save_button.text")}
              </Button>
            )}
            {addressViewState === "auto" && (
              <Button
                variant="primary"
                onClick={onAutoAddressSubmit}
              >
                {t("feature.address_form.save_button.text")}
              </Button>
            )}
          </Flex>
        </DialogFooter>
      </FormProvider>
    </>
  );
};

const CancelButton = () => {
  const actions = useContext(dialogActions);
  return (
    <Button
      variant="subtle"
      onClick={actions.hide}
    >
      {t("Cancel")}
    </Button>
  );
};

export const CS_ApartmentNumberForm = ({
  onApartmentNumberUpdate,
  initialApartmentNumber,
}: {
  initialApartmentNumber?: string;
  onApartmentNumberUpdate: (apartmentNumber: string) => void;
}) => {
  const allFormMethods = useForm<{ apartment_number: string }>();
  const { reset } = allFormMethods;
  const blurHandler = () => {
    const newValue = allFormMethods.getValues().apartment_number;
    if (initialApartmentNumber !== newValue) {
      onApartmentNumberUpdate(newValue);
    }
  };

  useEffect(() => {
    reset({
      apartment_number: initialApartmentNumber,
    });
  }, [reset, initialApartmentNumber]);

  return (
    <FormProvider {...allFormMethods}>
      <form onBlur={blurHandler}>
        <RentableFields.SwedishApartmentInfo />
      </form>
    </FormProvider>
  );
};
