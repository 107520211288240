import { vestExtendConfig } from "apps/contracts/components/common/validation";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { emailValidation } from "components/forms/validation";
import { t } from "lib/i18n";
import { isValidNumber, parse } from "libphonenumber-js";
import vest, { test, enforce } from "vest";
import { TenancySetupRentFormInput } from "../setup_rent/types/TenancySetupRentFormInput";
import { calculateTenancyPaymentsSum } from "./calculateTenancyPaymentsSum";

enforce.extend(vestExtendConfig);
export const tenancyFormValidationSuite = vest.create(
  (data: TenancySetupRentFormInput) => {
    data.tenants.forEach((tenant, index) => {
      const fieldName = `tenants.${index}.cpr` as const;
      if (data.tenancy_type === "private" && tenant.cpr?.length) {
        test(fieldName, t("contracts.validation.tenant_cpr"), () => {
          enforce(tenant.cpr).isValidCpr();
        });
      }

      if (
        [
          TenancyPaymentType.BETALINGS_SERVICE,
          TenancyPaymentType.MOBILEPAY,
        ].includes(data.paymentType)
      ) {
        test(`tenants.${index}.first_name`, () => {
          enforce(tenant.first_name).isNotEmpty();
        });
        test(`tenants.${index}.last_name`, () => {
          enforce(tenant.last_name).isNotEmpty();
        });
        test(`tenants.${index}.email`, () => {
          enforce(tenant.email).isNotEmpty();
          enforce(tenant.email && emailValidation(tenant.email)).isTruthy();
        });
        test(`tenants.${index}.phone`, () => {
          enforce(tenant.phone).isNotEmpty();
          enforce(
            tenant.phone &&
              tenant.phone.length > 0 &&
              isValidNumber(parse(tenant.phone)),
          ).isTruthy();
        });
      }
    });

    if (data.recurringRent && data.paymentType) {
      test(
        "recurringRent.0.amount" as const,
        t(
          "tenancy_payments.setup_rent.error.sum_less_than_minimum_not_allowed",
        ),
        () => {
          enforce(
            calculateTenancyPaymentsSum(data.recurringRent) > 0,
          ).isTruthy();
        },
      );
      data.tenants.forEach((tenant, index) => {
        test(`tenants.${index}.phone`, () => {
          if (tenant.phone && tenant.phone?.length > 0) {
            enforce(isValidNumber(parse(tenant.phone))).isTruthy();
          }
        });
      });
    }
  },
);
