import { useState, useEffect } from "react";
import { Flex, Spinner, Text, Link } from "@boligportal/juice";
import { useDawa } from "features/danish_address_web_api/hooks/use_dawa";
import { useOis } from "features/danish_property_data/hooks/use_ois";

export const OISOwner = ({ placeId }: { placeId: string }) => {
  const [isBusy, setIsBusy] = useState(true);
  const [bfeNumber, setBfeNumber] = useState<number>(0);
  const { getAddressSuggestions } = useDawa();
  const { getRentalInformation } = useOis();

  useEffect(() => {
    if (placeId !== "") {
      const run = async () => {
        setIsBusy(true);
        if (placeId !== null) {
          const rentalInformation = await getRentalInformation(placeId);
          const bfeNumber = rentalInformation?.property_info?.bfe_nummer ?? 0;
          setBfeNumber(bfeNumber);
        }
        setIsBusy(false);
      };
      run();
    }
  }, [placeId]);

  return (
    <Flex
      column
      pt={2}
      align="start"
    >
      <Text
        color="muted"
        size="tiny"
      >
        OISOwner:
      </Text>
      {isBusy && <Spinner size="small" />}
      {bfeNumber > 0 && (
        <Link
          newTab
          color="text"
          href={`https://nyt.ois.dk/search/${bfeNumber}`}
        >
          Check owner
        </Link>
      )}
    </Flex>
  );
};
