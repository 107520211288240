import { useState, useCallback, useEffect } from "react";
import { FieldValues, useFieldArray, UseFormReturn } from "react-hook-form";
import { TenancyRentEntityType } from "business/domain/tenancy/TenancyRentEntityType";
import { t } from "lib/i18n";
import { calculateTenancyPaymentsSum } from "../helpers/calculateTenancyPaymentsSum";
import { TenancyRecurringRentForm } from "../types/TenancyRecurringRentForm";

type FeatureFormType<T extends FieldValues> =
  T extends UseFormReturn<TenancyRecurringRentForm>
    ? UseFormReturn<TenancyRecurringRentForm>
    : UseFormReturn<T>;

export type TenancyRentTableFeature = ReturnType<typeof useTenancyRentTable>;

export const useTenancyRentTable = <T extends TenancyRecurringRentForm>(
  allFormMethods: FeatureFormType<T>,
) => {
  const [sum, setSum] = useState(0);

  const { control, watch, register, formState } = allFormMethods;

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "recurringRent",
    keyName: "id_that_doesnt_collide_with_a_tenancy_rent_base_key",
  });

  const addEmptyOption = useCallback(() => {
    append(
      {},
      {
        shouldFocus: false,
      },
    );
  }, [append]);

  const addRentOption = useCallback(() => {
    append(
      {
        type: TenancyRentEntityType.rent,
        name: t("common.form_fields.rent.label"),
      },
      {
        shouldFocus: false,
      },
    );
  }, [append]);

  const watchFieldArray = watch("recurringRent");
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  const updatePaymentNameForIndex = (
    index: number,
    name: string,
    type: TenancyRentEntityType,
    amount: number,
    id: number | null,
  ) => {
    update(index, {
      id,
      name,
      type,
      amount,
    });
  };

  useEffect(() => {
    const newSum = calculateTenancyPaymentsSum(controlledFields);
    setSum(newSum);
  }, [controlledFields]);

  return {
    register,
    control,
    sum,
    errors: formState.errors,
    addEmptyOption,
    addRentOption,
    removeOption: remove,
    updatePaymentNameForIndex,
    controlledFields,
  };
};
