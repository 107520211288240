import * as React from "react";
import { Box, Button, Flex, Text } from "@boligportal/juice";
import { BasicAvatar } from "components/design-system/atoms/avatar/basicAvatar";
import { SquareImage } from "components/design-system/atoms/square_image/square_image";
import { t } from "lib/i18n";

interface Props {
  image: { src: string; useSquare?: boolean };
  title: React.ReactNode;
  subtitle: React.ReactNode;
  linkButton?: {
    onClick: () => void;
    label: string;
  };
  onClick?: () => void;
}

export const CardHeader = ({
  image,
  subtitle,
  title,
  linkButton,
  onClick,
}: Props) => (
  <Box onClick={onClick}>
    <Flex>
      <CardHeaderImage image={image} />

      <Flex
        column
        ml={2}
        mr={2}
        justify="center"
      >
        <Text
          weight="bold"
          size="h5"
        >
          {title}
        </Text>

        {!subtitle ? (
          <Text
            size="small"
            color="muted"
          >
            {t("inbox.noInformationProvided")}
          </Text>
        ) : (
          <Text size="small">{subtitle}</Text>
        )}

        {linkButton && (
          <Box
            hidden={{
              xs: false,
              lg: true,
            }}
          >
            <Button
              text
              variant="branded"
              fontSize="small"
              onClick={linkButton.onClick}
            >
              {linkButton.label}
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  </Box>
);

type CardHeaderImageProps = {
  image: { src: string; useSquare?: boolean };
};

const CardHeaderImage = ({ image }: CardHeaderImageProps) => {
  if (!image) {
    return null;
  }

  if (image.useSquare) {
    return (
      <SquareImage
        src={(image && image.src) || "/static/images/no-image.jpg"}
      />
    );
  }

  return (
    <BasicAvatar
      src={image.src}
      size={60}
    />
  );
};
