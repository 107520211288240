import { useQuery } from "react-query";
import {
  getExpenditures,
  getLandlordSubscriptionProduct,
  getSubscription,
} from "api";
import { useUser } from "components/Providers/UserProvider";

export const subscriptionKeys = {
  subscription: () => ["subscription"] as const,
  expenditures: () => ["expenditures"] as const,
  landlordSubscriptionProducts: () => ["landlordSubscriptionProducts"] as const,
};

export const useSubscription = () => {
  const { user } = useUser();

  return useQuery(subscriptionKeys.subscription(), getSubscription, {
    enabled: user !== null,
  });
};

export const useGetHasExpenditures = () =>
  useQuery(subscriptionKeys.expenditures(), () => getExpenditures());

export const useLandlordSubscriptionProducts = () =>
  useQuery(
    subscriptionKeys.landlordSubscriptionProducts(),
    () => getLandlordSubscriptionProduct(),
    {
      /*
       * As for now backend is hardcoded to return only a single subscription for Rental Data product.
       * However, the "Mental Model" for this -> economy -> "Subscriptions Page" is more a page
       * where a Landlord would go to see/manage subscriptions that is not tightly coupled to one product.
       *
       * Therefore frontend view sees this as a list of {subscription:Subscription, plan: Plan}.
       */
      select: (data) => (data && data.subscription ? [data] : []),
      retry: (failureCount, error: any) => {
        if (error?.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );
