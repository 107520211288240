import { IconServerOutline } from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { LandlordOnbordingItem } from "../../types/LandlordOnbordingItem";
import { Flow } from "../../useLandlordOnboardingFeature";
import {
  createItemsForAdFlow,
  createItemsForContractFlow,
  getTitleAndDescription,
  item1Defaults,
  item2Defaults,
  item3Defaults,
} from "../shared";

export const createOnboardingViewModel_DK = (
  flow: Flow,
  onClose: () => void,
) => {
  const { title, description } = getTitleAndDescription(flow);
  let items: LandlordOnbordingItem[] = [];

  switch (flow) {
    case "ad":
      items = withRentCollectionItem(
        createItemsForAdFlow(flow, onClose),
        flow,
        onClose,
      );
      break;
    case "contract":
      items = withRentCollectionItem(
        createItemsForContractFlow(flow, onClose),
        flow,
        onClose,
      );
      break;
    case "moving-report":
      items = createItemsForMovingReportFlow(flow, onClose);
      break;
  }

  return {
    items,
    title,
    description,
  };
};

const item4Defaults = () => ({
  icon: IconServerOutline,
  href: "/property-owner/rent/create",
  title: t("landlord_onboarding.landlord_dialog.item4.title"),
  text: t("landlord_onboarding.landlord_dialog.item4.text"),
});

const createItemsForMovingReportFlow = (flow: Flow, onClose: () => void) => [
  {
    ...item1Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("ad", flow);
      onClose();
    },
  },
  {
    ...item2Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("contract", flow);
      onClose();
    },
  },
  {
    ...item3Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("moving-report", flow);
      onClose();
    },
  },
  {
    ...item4Defaults(),
    ctaButtonLabel: t("landlord_onboarding.landlord_dialog.item4.button.label"),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("rent-collection", flow);
      onClose();
    },
  },
];

const withRentCollectionItem = (
  items: LandlordOnbordingItem[],
  flow: Flow,
  onClose: () => void,
) => [
  ...items,
  {
    ...item4Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("rent-collection", flow);
      onClose();
    },
  },
];
