import { useState } from "react";
import { CheckField, Confirm } from "@boligportal/juice";

export const CompanyVerifiedCheckField = ({
  isVerified,
  handleUpdateField,
}) => {
  const [showVerificationConfirmModal, setShowVerificationConfirmModal] =
    useState(false);

  return (
    <>
      <CheckField
        label="Internally verified"
        checked={isVerified}
        onChange={() => {
          setShowVerificationConfirmModal(true);
        }}
      />
      <Confirm
        size="large"
        open={showVerificationConfirmModal}
        onConfirm={() => {
          handleUpdateField("verified", !isVerified);
          setShowVerificationConfirmModal(false);
        }}
        onCancel={() => {
          setShowVerificationConfirmModal(false);
        }}
        title="Change company verification"
        message="Are you sure about changing the company verification status?"
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </>
  );
};
