import { Box, Center, Container, Flex, Spinner } from "@boligportal/juice";
import { ListPaginator } from "../ads_page/list_paginator";
import { EmailLogFilters } from "./EmailLogFilters";
import { EmailLogsTable } from "./EmailLogsTable";
import { useEmailLogData } from "./useEmailLogData";

export const EmailLogsPage = () => {
  const { register, offset, setOffset, data, limit, isFetching, isError } =
    useEmailLogData();

  return (
    <Flex
      column
      width="100%"
    >
      <Flex
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <EmailLogFilters register={register} />
        </Container>
      </Flex>

      {isFetching ? (
        <Box height="100%">
          <Center height="100%">
            <Spinner size="large"></Spinner>
          </Center>
        </Box>
      ) : (
        <Box scrollable>
          <EmailLogsTable
            isError={isError}
            entries={data?.entries}
          />

          {!!data && data.count > limit && offset != undefined && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                itemCount={data.entries.length}
                totalCount={data.count}
                limit={limit}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};
