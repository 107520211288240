import { Box, Flex, Text } from "@boligportal/juice";
import { PAGE_HOME } from "apps/customer_service";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { NewBuild } from "apps/newbuilds/types";
import { NewBuildStateLabel } from "./NewBuildStateLabel";

type Props = {
  item: NewBuild;
};

export const TotalNewBuildsItem = ({ item }: Props) => {
  const { id, name, street, street_number, city, state } = item;

  const url = `${PAGE_HOME}/newbuild/detail/${id}`;
  return (
    <Flex
      column
      pb={2}
    >
      {state === "DRAFT" ? (
        <Box>
          <Flex align="start">
            <Flex column>
              <Box>
                <Text size="small">
                  <Text size="small">{`${name}, ${street} ${street_number}, ${city}`}</Text>
                </Text>
                <Box
                  inline
                  pl={1}
                >
                  <NewBuildStateLabel state={state} />
                </Box>
              </Box>
            </Flex>
            <Box flexPush>
              <Text
                size="small"
                weight="bold"
              >{`#${id}`}</Text>
            </Box>
          </Flex>
        </Box>
      ) : (
        <StyledLink to={url}>
          <Flex align="start">
            <Flex column>
              <Box>
                <Text size="small">
                  <Text size="small">{`${name}, ${street} ${street_number}, ${city}`}</Text>
                </Text>
                <Box
                  inline
                  pl={1}
                >
                  <NewBuildStateLabel state={state} />
                </Box>
              </Box>
            </Flex>
            <Box flexPush>
              <Text
                size="small"
                weight="bold"
              >{`#${id}`}</Text>
            </Box>
          </Flex>
        </StyledLink>
      )}
    </Flex>
  );
};
