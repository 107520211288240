import {
  Flex,
  Button,
  Box,
  Spinner,
  EmptyBox,
  Text,
  styled,
  IconClose,
} from "@boligportal/juice";
import { useDropzoneImageUpload } from "./hooks/useDropzoneImageUpload";
import { SortableImagesFeature } from "./interfaces/SortableImagesFeature";

export const DesktopImageOrganiserForCreateNewCompanyLogo = ({
  sortableImagesFeature,
}: {
  sortableImagesFeature: SortableImagesFeature;
}) => {
  const { images, addImage, removeImage } = sortableImagesFeature;

  const { fakeClickableRef, dropzoneRef, isUploading, resetDropzone } =
    useDropzoneImageUpload(
      (responsePayload) => {
        responsePayload.forEach((item) => {
          addImage({
            id: item.id.toString(),
            url: item.url,
          });
        });
      },
      undefined,
      undefined,
      undefined,
      true,
    );

  const logoImage = images.length > 0 ? images[0] : null;

  const hasLogoImage = logoImage !== null;

  return (
    <DropzoneContentWrapper>
      <Box hidden={!isUploading}>
        <Spinner />
      </Box>

      <Box hidden={isUploading || hasLogoImage}>
        <div ref={fakeClickableRef}>
          <div ref={dropzoneRef}>
            <Flex
              column
              mx={{
                xs: 2,
                lg: 6,
              }}
            >
              <Text
                size="h4"
                color="muted"
              >
                Drag & Drop Logo here, or click to select
              </Text>
              <Box pt={2}>
                <Button variant="branded">Browse Logo Image...</Button>
              </Box>
            </Flex>
          </div>
        </div>
      </Box>
      {hasLogoImage && (
        <ImageWithCloseButtonTopRightOverlay
          onClick={() => {
            removeImage(logoImage);
            resetDropzone();
          }}
        >
          <CompanyHeroSectionLogo src={logoImage.url} />
        </ImageWithCloseButtonTopRightOverlay>
      )}
    </DropzoneContentWrapper>
  );
};

const ImageWithCloseButtonTopRightOverlay = ({ children, onClick }) => (
  <Wrapper>
    {children}
    <StyledCloseButton onClick={onClick}>
      <IconClose />
    </StyledCloseButton>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

const StyledCloseButton = styled.div`
  cursor: pointer;
  background-color: white;
  border-radius: 999px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  right: 0;
`;

const CompanyHeroSectionLogo = ({ src }: { src: string }) => (
  <LogoContainer>
    <LogoImg src={src} />
  </LogoContainer>
);

const LogoImg = styled.div<{ src: string }>`
  width: 100px;
  height: 100px;

  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const LogoContainer = styled.div`
  background-color: white;
  border-radius: 999px;
  min-width: 100px;
  min-height: 100px;

  padding: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const DropzoneContentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    bg="tint"
    borderRadius="sm"
  >
    <EmptyBox>
      <Flex
        column
        align="center"
        justify="center"
      >
        {children}
      </Flex>
    </EmptyBox>
  </Box>
);
