import * as React from "react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Button, Flex, IconArrowBack, Notice } from "@boligportal/juice";
import { t } from "lib/i18n";
import { commonButtonTrans } from "lib/translations";
import { CardWithNoNegativeMargin } from "../CommonStyled";
import { CreateRentableButton } from "../CreateRentableButton";

export type ManualAddressRentableEntity = {
  address: {
    postal_code: string;
    postal_town: string;
    street_name: string;
    street_number: string;
  };
};

type Props<T extends ManualAddressRentableEntity> = {
  canCreateRentable: boolean;
  onGoBack: () => void;
  onRentableCreated: (rentableId: number) => void;
  formMethods: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
};

export const ManualAddressLayout = <T extends ManualAddressRentableEntity>({
  canCreateRentable,
  onGoBack,
  formMethods,
  onSubmit,
  children,
}: Props<T>) => {
  const { createNewRentableButton } = commonButtonTrans();

  return (
    <form
      noValidate
      onSubmit={formMethods.handleSubmit(onSubmit)}
    >
      <FormProvider {...formMethods}>
        <CardWithNoNegativeMargin>
          {canCreateRentable === false && (
            <Notice type="info">
              {t(
                "feature.choose_or_create_rentable.check_if_can_create_rentable.failed",
              )}
            </Notice>
          )}

          <Flex
            pb={2}
            align="center"
            gap={1}
          >
            <IconArrowBack />
            <Button
              text
              onClick={onGoBack}
            >
              {t("feature.address_form.go_back_to_address_search_button.text")}
            </Button>
          </Flex>

          {children}
        </CardWithNoNegativeMargin>

        <Flex
          justify="end"
          mx={{
            xs: 2,
            sm: 3,
            lg: 0,
          }}
        >
          <CreateRentableButton
            isLoading={formMethods.formState.isSubmitting}
            type="submit"
          >
            {createNewRentableButton}
          </CreateRentableButton>
        </Flex>
      </FormProvider>
    </form>
  );
};
