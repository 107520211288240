export const WireIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="57"
    height="57"
    viewBox="0 0 32 32"
  >
    <defs>
      <clipPath id="b">
        <rect
          width="32"
          height="32"
        />
      </clipPath>
    </defs>
    <g
      id="a"
      clipPath="url(#b)"
    >
      <g transform="translate(1.691 1.63)">
        <path
          d="M12.534,27.919c-.083,0-.168,0-.251-.006a6.5,6.5,0,0,1-4.538-2.1A6.873,6.873,0,0,1,5.9,21.118V17.992A7.379,7.379,0,0,1,0,10.764V7.024a.96.96,0,0,1,.958-.958H2.793V1.386A1.387,1.387,0,0,1,4.179,0c.044,0,.089,0,.133.006A1.43,1.43,0,0,1,5.563,1.444V6.066H9.209V1.386A1.384,1.384,0,0,1,10.587,0c.046,0,.094,0,.141.007a1.428,1.428,0,0,1,1.245,1.437V6.066H13.8a.96.96,0,0,1,.958.958v3.74a7.379,7.379,0,0,1-5.9,7.228v3.3a3.692,3.692,0,0,0,3.688,3.687,3.82,3.82,0,0,0,.472-.03,3.777,3.777,0,0,0,3.214-3.8,6.585,6.585,0,0,1,6.585-6.556h4.329a1.475,1.475,0,1,1-.005,2.95H22.864a3.691,3.691,0,0,0-3.686,3.687v.052a6.647,6.647,0,0,1-6.643,6.637h0ZM2.51,9.189a.5.5,0,0,0,0,1h9.54a.5.5,0,0,0,0-1Z"
          transform="translate(0 0.699)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
