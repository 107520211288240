import { Flex, Text } from "@boligportal/juice";

export const MenuItemWithIcon = ({ name, icon }) => (
  <Flex
    justify="space-between"
    align="center"
  >
    <Flex align="center">
      <Text pr={1.5}>{icon}</Text>
      <Text>{name}</Text>
    </Flex>
  </Flex>
);
