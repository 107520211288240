import { Box, Divider, Flex, Text } from "@boligportal/juice";
import { getRentalPeriodLabel } from "apps/ad_detail/refactor/helpers/getRentalPeriodLabel";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { Market } from "components/markets/market_settings";
import { AdUtils } from "lib/ad";
import { t } from "lib/i18n";

export const DetailedPrice = ({ ad }: { ad: Ad }) => {
  const {
    available_from,
    deposit,
    monthly_rent,
    monthly_rent_extra_costs,
    monthly_rent_currency,
    rental_period,
    prepaid_rent,
  } = ad;

  const monthlyRent = `${AdUtils.formatCostsTwoDecimalsCurrency(
    monthly_rent,
    false,
  )} ${monthly_rent_currency}`;
  const movingCosts = AdUtils.calcMoveInPrice(ad);

  const isDK = App.settings.market === Market.BOLIGPORTAL_DK;

  return (
    <Box px={2}>
      <Divider
        my={{
          md: 1,
          lg: 2,
        }}
      />

      <InfoRow
        title={t("ad_details.monthly_rent")}
        value={monthlyRent}
        isHighlited
      />

      {monthly_rent_extra_costs !== null && (
        <InfoRow
          title={t("ad_details.monthly_utilities")}
          value={AdUtils.formatCostsTwoDecimalsCurrency(
            monthly_rent_extra_costs,
          )}
        />
      )}

      {deposit !== null && (
        <InfoRow
          title={t("ad_details.deposit")}
          value={AdUtils.formatCostsTwoDecimalsCurrency(deposit)}
        />
      )}

      {prepaid_rent !== null && isDK && (
        <InfoRow
          title={t("ad_details.move_in_price.prepaid_rent")}
          value={AdUtils.formatCostsTwoDecimalsCurrency(prepaid_rent)}
        />
      )}

      {isDK && (
        <InfoRow
          title={t("ad_details.move_in_price.total")}
          value={AdUtils.formatCostsTwoDecimalsCurrency(movingCosts)}
        />
      )}

      <InfoRow
        title={t("ad_details.available_from")}
        value={AdUtils.moveInDate(available_from)}
      />

      {rental_period !== null && (
        <InfoRow
          title={t("ad_details.rental_period")}
          value={getRentalPeriodLabel(rental_period)}
        />
      )}
    </Box>
  );
};

const InfoRow = ({
  title,
  value,
  isHighlited,
}: {
  title: string;
  value: string;
  isHighlited?: boolean;
}) => (
  <Flex
    justify="space-between"
    align="baseline"
    mb={{
      xs: 0.5,
      lg: 1,
    }}
  >
    <Text
      size="tiny"
      color="muted"
    >
      {title}
    </Text>

    <Text
      color={isHighlited ? "branded" : undefined}
      size="small"
      weight="bold"
    >
      {value}
    </Text>
  </Flex>
);
