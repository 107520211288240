import * as React from "react";
import { Action_updateAgentId } from "apps/search_result/search_result_reducer";
import { useUser } from "components/Providers/UserProvider";
import { Filters } from "components/interfaces/filters";
import { User } from "components/interfaces/user";
import { useOldModal } from "components/modal_controller";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import { API } from "lib/api";
import { SignupContext } from "lib/enums";
import { trackError } from "lib/tracking/errors";
import { TrackingEvent } from "lib/tracking/events";
import { FacebookTrackingEvent } from "lib/tracking/facebook";

export const useAgentActions = ({
  filters,
  dispatch,
  agentId,
  onAgentCreated,
  onAgentDeleted,
  trackingLabel,
  onError,
}: {
  filters: Filters;
  dispatch: React.Dispatch<Action_updateAgentId>;
  agentId: number | null;
  onAgentCreated: (user: User) => void;
  onAgentDeleted: () => void;
  trackingLabel: "map" | "srp_empty_state";
  onError: () => void;
}) => {
  const [isAgentLoading, setAgentLoading] = React.useState(false);
  const modalActions = useOldModal();
  const { user } = useUser();

  const {
    min_lat,
    max_lat,
    min_lng,
    max_lng,
    city_level_1,
    company_filter_key,
  } = filters;
  const isAgentCreationDisabled = React.useMemo(() => {
    if (city_level_1?.values || !!company_filter_key) {
      return false;
    }
    if (!min_lat || !max_lat || !min_lng || !max_lng) {
      return true;
    }
    if (max_lat - min_lat > 1.5) {
      return true;
    }
    if (max_lng - min_lng > 2.8) {
      return true;
    }

    return false;
  }, [min_lat, max_lat, min_lng, max_lng, city_level_1, company_filter_key]);

  const createAgent = (user: User) => {
    setAgentLoading(true);
    API.createAgent(filters)
      .then((result) => {
        TrackingEvent.agentCreated(trackingLabel);
        FacebookTrackingEvent.trackAgentCreated();
        dispatch({
          type: "updateAgentId",
          payload: result.agent_id,
        });
        onAgentCreated(user);
        setAgentLoading(false);
      })
      .catch((err) =>
        trackError(err, {
          extra: {
            filters,
          },
        }),
      )
      .finally(() => setAgentLoading(false));
  };

  const removeAgent = () => {
    if (user && agentId) {
      setAgentLoading(true);
      API.deleteAgent(agentId)
        .then(() => {
          TrackingEvent.agentRemoved(trackingLabel);
          dispatch({
            type: "updateAgentId",
            payload: null,
          });
          setAgentLoading(false);
          onAgentDeleted();
        })
        .catch(() => {
          setAgentLoading(false);
        });
    }
  };

  const showAuthModal = () => {
    modalActions.showModal(
      <AuthModal
        view={AuthView.SIGNUPWITHEMAIL}
        onSuccess={createAgent}
        context={SignupContext.SEARCH_AGENT}
      />,
    );
  };

  const onEnableAgent = () => {
    if (isAgentLoading) {
      return;
    }

    if (isAgentCreationDisabled) {
      onError();

      return;
    }
    TrackingEvent.clickCreateAgent(trackingLabel);

    if (user) {
      createAgent(user);
    } else {
      showAuthModal();
    }
  };

  const onDisableAgent = () => {
    if (isAgentLoading) {
      return;
    }
    TrackingEvent.clickRemoveAgent(trackingLabel);
    removeAgent();
  };

  return {
    onEnableAgent,
    onDisableAgent,
  };
};
