import { useMutation } from "react-query";
import { captureException } from "@sentry/react";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { API, LandlordRemarksUpdate } from "apps/moving_reports/api";
import { t } from "lib/i18n";

export const useDeleteMovingReportMutation = () => {
  const { addErrorNotification } = useErrorNotification();

  const deleteReportMutation = useMutation(
    (payload: { reportId: number }) => API.deleteReport(payload.reportId),
    {
      onError: (error) => {
        addErrorNotification(t("Something went wrong. Try again."));
        captureException(error, {
          level: "error",
        });
      },
    },
  );

  return {
    deleteReportMutation,
  };
};

export const useUpdateDefectRemarks = () =>
  useMutation((data: { id: number; remarks: LandlordRemarksUpdate }) =>
    API.updateDefectRemarks(data.id, data.remarks),
  );
