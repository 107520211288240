import { isNotUndefinedOrEmptyString } from "../helpers";
import { RentalAddressValueObject } from "../types";

/**
 * Given a RentalAddressValueObject it returns a string formatted like the following
 * "street_name street_number, door, postal_code postal_town"
 */
export const clientFormattedAddressInSweeden = (
  rentalAddressValueObject: RentalAddressValueObject,
): string => {
  const { street_name, street_number, door, postal_code, postal_town } =
    rentalAddressValueObject;

  const hasStreetName = isNotUndefinedOrEmptyString(street_name);
  const hasStreetNumber = isNotUndefinedOrEmptyString(street_number);
  const hasDoor = isNotUndefinedOrEmptyString(door);
  const hasPostalCode = isNotUndefinedOrEmptyString(postal_code);
  const hasPostalTown = isNotUndefinedOrEmptyString(postal_town);

  let str = "";

  const streetParts: string[] = [];

  if (hasStreetName) {
    streetParts.push(street_name!);
  }

  if (hasStreetNumber) {
    streetParts.push(street_number!);
  }

  str = `${streetParts.join(" ")}`;

  if (hasDoor) {
    str = `${str}, ${door}`;
  }

  const postalParts: string[] = [];

  if (hasPostalCode) {
    postalParts.push(postal_code!);
  }

  if (hasPostalTown) {
    postalParts.push(postal_town!);
  }

  if (postalParts.length > 0) {
    str = `${str}, ${postalParts.join(" ")}`;
  }

  return str;
};
