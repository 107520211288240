import * as React from "react";
import { Box, Flex, styled } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { DropDownMenu } from "./header/drop_down_menu";
import { LogoLink } from "./header/logo_link/LogoLink";
import { TopNav } from "./header/top_nav";
import { ImpersonationWarning } from "./impersonation_warning";

interface Props {
  inverseColor?: boolean;
  desktopSearchComponent?: React.ReactNode;
  mobileSearchComponent?: React.ReactNode;
  hideLogoOnDesktop?: boolean;
}

export const Header = ({
  inverseColor = false,
  desktopSearchComponent,
  mobileSearchComponent,
  hideLogoOnDesktop,
}: Props) => {
  const { user } = useUser();

  return (
    <>
      <ImpersonationWarning />
      <HeaderLayout inverseColor={inverseColor}>
        <Flex
          align="center"
          justify="space-between"
        >
          <LogoLink
            hideLogoOnDesktop={hideLogoOnDesktop}
            inverseColor={inverseColor}
          />
          <Box flexGrow>
            <Box
              maxWidth="300px"
              hidden={{
                xs: true,
                md: false,
              }}
            >
              {desktopSearchComponent}
            </Box>
          </Box>
          <TopNav
            user={user}
            inverseColor={inverseColor}
          />
          {mobileSearchComponent && (
            <Box
              hidden={{
                xs: false,
                md: true,
              }}
            >
              {mobileSearchComponent}
            </Box>
          )}
          <DropDownMenu
            user={user}
            inverseColor={inverseColor}
          />
        </Flex>
      </HeaderLayout>
    </>
  );
};

const HeaderLayout = styled.header<{
  inverseColor?: boolean;
  alwaysShowBorder?: boolean;
  hideOnXl?: boolean;
}>`
  display: block;
  width: 100%;
  padding: ${(props) => props.theme.unit(2)};

  background: ${(props) =>
    props.inverseColor ? "transparent" : props.theme.color.bg.base};

  border-bottom: ${(props) =>
    props.alwaysShowBorder ? ` 1px solid ${props.theme.color.border}` : "none"};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    border-bottom: ${(props) =>
      props.inverseColor || !props.alwaysShowBorder
        ? "none"
        : ` 1px solid ${props.theme.color.border}`};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    display: ${(props) => (props.hideOnXl ? "none" : "block")};
  }
  @media print {
    display: none;
  }
`;
