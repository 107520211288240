import { useQuery } from "react-query";
import { API } from "lib/api";
import { OrderType } from "lib/enums";

export const searchResultsKeys = {
  newestAds: (city: string) => ["getNewestAds", city] as const,
};

const OFFSET = 0;
const FILTERS = {
  categories: {
    values: null,
  },
  newbuild: false,
  city_level_2: {
    values: null,
  },
  city_level_3: {
    values: null,
  },
  rooms: null,
  min_size_m2: null,
  max_monthly_rent: null,
  min_rental_period: null,
  max_available_from: "",
  company: null,
  company_filter_key: null,
  company_key: null,
  street_name: {
    values: null,
  },
  social_housing: false,
  min_lat: null,
  min_lng: null,
  max_lat: null,
  max_lng: null,
  shareable: false,
  furnished: false,
  student_only: false,
  pet_friendly: false,
  dishwasher: false,
  dryer: false,
  washing_machine: false,
  electric_charging_station: false,
  balcony: false,
  senior_friendly: false,
  parking: false,
  elevator: false,
  order: OrderType.DEFAULT,
};

export const useNewestAds = (city: string) => {
  const filters = {
    ...FILTERS,
    city_level_1: {
      values: [city],
    },
  };

  return useQuery(
    searchResultsKeys.newestAds(city),
    () => API.getListResults(filters, OFFSET),
    {
      enabled: city !== "",
      select: (data) => data.results.slice(0, 3),
    },
  );
};
