import { SelectField } from "@boligportal/juice";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const floorOptions = [
  {
    value: "-1",
    label: "Basement",
  },
  {
    value: "0",
    label: "Ground floor",
  },
  {
    value: "1",
    label: "First floor",
  },
  {
    value: "2",
    label: "Second floor",
  },
  {
    value: "3",
    label: "Third floor",
  },
  {
    value: "4",
    label: "Fourth floor",
  },
  {
    value: "5",
    label: "Fifth floor",
  },
  {
    value: "6",
    label: "Six floor",
  },
  {
    value: "7",
    label: "Seven floor",
  },
  {
    value: "8",
    label: "Eight floor",
  },
  {
    value: "9",
    label: "Ninth floor",
  },
  {
    value: "10",
    label: "Tenth floor",
  },
];

const FloorFormElement = <T extends any>(props: FormElementInterface<T>) => {
  const { register, save, isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
    true,
  );

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel={props.fieldLabel}
    >
      <SelectField
        {...register(props.fieldname)}
        label=""
        items={floorOptions}
        disabled={isSaving || props.isLocked}
        onChange={save}
        previousValue={props.previousValue}
        defaultValue={props.defaultValue}
      />
    </LockableFormField>
  );
};

export { FloorFormElement };
