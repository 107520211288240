import * as React from "react";

const SvgRoundCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g
      id="Final-design-and-spec"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop"
        transform="translate(-1354.000000, -976.000000)"
        fillRule="nonzero"
      >
        <g
          id="Trust"
          transform="translate(0.000000, 939.000000)"
        >
          <g
            id="Tickbox-circle"
            transform="translate(240.000000, 37.000000)"
          >
            <g
              id="Checkmark"
              transform="translate(1114.000000, 0.000000)"
            >
              <path
                d="M16.6666667,30.9018791 C19,30.9018791 19.6666667,33.5573134 21.6666667,32.8934548 C23.6666667,31.8976669 22.6666667,29.5741619 24.6666667,28.2464448 C26.6666667,26.5867983 28.6666667,28.2464448 30,26.5867983 C31,24.5952226 29,23.2675055 29.6666667,20.9440004 C30.6666667,18.2885661 33,18.6204954 33,16.6289197 C33,14.3054147 30.6666667,14.637344 29.6666667,11.9819097 C29,9.32647537 31,7.99875821 30,6.33911178 C28.6666667,4.67946534 26.6666667,6.33911178 24.6666667,4.67946534 C22.6666667,3.35174819 23.6666667,0.696313884 21.6666667,0.0324553081 C19.6666667,-0.29947398 19,2.02403103 16.6666667,2.02403103 C14,2.02403103 13.3333333,-0.29947398 11.3333333,0.0324553081 C9.33333333,1.02824317 10.3333333,3.35174819 8.33333333,4.67946534 C6.33333333,6.33911178 4.33333333,4.67946534 3,6.33911178 C2,8.3306875 4,9.65840465 3.33333333,11.9819097 C2.33333333,14.637344 0,14.3054147 0,16.6289197 C0,18.6204954 2.33333333,18.2885661 3.33333333,20.9440004 C4,23.5994347 2,24.9271519 3,26.5867983 C4.33333333,28.2464448 6.33333333,26.5867983 8.33333333,28.2464448 C10.3333333,29.5741619 9.33333333,32.2295962 11.3333333,32.8934548 C13.3333333,33.5573134 14,30.9018791 16.6666667,30.9018791 Z"
                id="Star"
                fill="#64B167"
              ></path>
              <polygon
                id="Line"
                fill="#FFFFFF"
                transform="translate(16.459496, 15.776369) rotate(41.000000) translate(-16.459496, -15.776369) "
                points="13.6260667 23.4435012 19.2932262 23.4440681 19.2916921 8.10897002 16.2914312 8.10866988 16.2926651 20.4434227 13.6257666 20.4431559"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgRoundCheckmark;
