import { AdStates } from "../../../../lib/enums";
import { CrawlingAdsPageAll } from "./crawling_ads_page_all";

const CrawlingAdsPendingApproval = () => (
  <CrawlingAdsPageAll
    initialReviewReasons={["MODIFIED", "AUTO_APPROVED"]}
    actionLabelText={"View"}
    initialStates={[AdStates.ACTIVE_APPROVED]}
  />
);

export { CrawlingAdsPendingApproval };
