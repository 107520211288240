import { emailValidation } from "components/forms/validation";
import { isFuture } from "date-fns";
import { isValidCpr } from "lib/utils";
import { isValidNumber, parse } from "libphonenumber-js";
import { create } from "vest";

export const vestExtendConfig = {
  inFuture: (value: string) => isFuture(new Date(value)),
  isAfter: (value: string, other: string) => new Date(value) > new Date(other),
  /* https://emailregex.com/ */
  isEmail: (email: string) => emailValidation(email),
  isValidInternationalPhoneNumber: (phoneNumber: string) => {
    const phone = phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
    return isValidNumber(parse(phone));
  },
  isDKPhone: (phoneNumber: string) =>
    Boolean(
      phoneNumber.match(/^((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})$/),
    ),
  isValidCpr: (cprNumber: string) => isValidCpr(cprNumber),
};

export type ValidationSuite = ReturnType<typeof create>;
