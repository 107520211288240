import * as React from "react";
import { Box, Flex, styled, Text } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { Header } from "components/header";
import { PageBlock } from "../PageBlock";

const HeroHeader = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 40px;
  }
`;

const HouseSvg = () => (
  <svg
    width="329"
    height="336"
    viewBox="0 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        // eslint-disable-next-line max-len
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const StyledOverflow = styled.div`
  overflow: hidden;
`;

interface Props {
  headerText: string;
  subline: string;
  picture: {
    src: string;
    width: number;
    height: number;
  };
  bulletsComponent: React.ReactNode;
  ctaButtonComponent: React.ReactNode;
}

export const CallToActionHeroSection = ({
  headerText,
  picture,
  bulletsComponent,
  ctaButtonComponent,
  subline,
}: Props) => (
  <StyledOverflow>
    <PageBlock
      bg="business"
      bgGradient
      py="none"
      bgBottomLeftOverlay={<HouseSvg />}
    >
      <Header inverseColor />
      <Box
        mx="auto"
        maxWidth="1395px"
        px={{
          xs: 2,
          md: 6,
        }}
        pt={{
          xs: 3,
          md: 10,
          lg: 14,
        }}
        pb={{
          xs: 8,
          md: 20,
        }}
        mt={{
          xs: 0,
          md: 8,
        }}
      >
        <Flex align="center">
          <Box
            flexGrow
            width="100%"
            minWidth={{
              md: "550px",
            }}
            maxWidth={{
              xs: "auto",
              lg: "800px",
            }}
          >
            <HeroHeader>{headerText}</HeroHeader>
            <Text
              color="inverted"
              size="h3"
              weight="bold"
            >
              {subline}
            </Text>
            <Flex
              mt={2}
              column
            >
              <Box>{bulletsComponent}</Box>
              <Box
                flexGrow
                pt={2}
              >
                {ctaButtonComponent}
              </Box>
            </Flex>
          </Box>
          <Flex
            hidden={{
              xs: true,
              lg: false,
            }}
            ml={3}
            align="center"
            justify="end"
          >
            <CdnImage
              {...picture}
              resolution="3x"
            />
          </Flex>
        </Flex>
      </Box>
    </PageBlock>
  </StyledOverflow>
);
