import { TextField } from "@boligportal/juice";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const ReferenceIdFormElement = <T extends any>(
  props: FormElementInterface<T>,
) => {
  const { register, save, isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
  );

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel={props.fieldLabel}
    >
      <TextField
        {...register(props.fieldname)}
        disabled={isSaving || props.isLocked}
        onBlur={save}
        previousValue={props.previousValue}
        defaultValue={props.defaultValue}
      />
    </LockableFormField>
  );
};

export { ReferenceIdFormElement };
