import { fetchFromAPI } from "lib/api";
import { TimelineResponse } from "./types";

export const getTimeline = async (userToken: string) =>
  getTenantHubTimeline(userToken);

// using fetchFromAPI bacause juice http client doesnt support language prefix
const getTenantHubTimeline = async (
  userToken: string,
): Promise<TimelineResponse> => {
  const response = await fetchFromAPI(`/api/tenant-hub/${userToken}/timeline`, {
    method: "GET",
  });

  return response.json();
};
