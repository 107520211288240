import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { Translatable } from "lib/translations";
import { ValidationErrorTranslation } from "../types";
import {
  UtilityOptions,
  HousingTypes,
  InventoryOptions,
  PaymentFrequency,
  RightOfUse,
  PaymentOptions,
  PetsAllowed,
} from "./store/enums";

export const HousingTypesTranslations = (): Translatable<HousingTypes> => ({
  [HousingTypes.APARTMENT]: t("contracts.se.housing_types.apartment"),
  [HousingTypes.ROOM]: t("contracts.se.housing_types.room"),
  [HousingTypes.HOUSE]: t("contracts.se.housing_types.house"),
  [HousingTypes.CABIN]: t("contracts.se.housing_types.cabin"),
  [HousingTypes.OTHER]: t("contracts.se.housing_types.other"),
});

export const PaymentFrequencyTranslations =
  (): Translatable<PaymentFrequency> => ({
    [PaymentFrequency.MONTHLY]: t("contracts.se.payment_frequency.monthly"),
    // [PaymentFrequency.ONE_TIME_PAYMENT]: t("contracts.se.payment_frequency.one_time_payment"),
  });

export const HeatingResponsibilityTranslations =
  (): Translatable<UtilityOptions> => ({
    [UtilityOptions.PART_OF_RENT]: t(
      "contracts.se.heating_responsibility.part_of_rent",
    ),
    [UtilityOptions.TENANT_PAYS]: t(
      "contracts.se.heating_responsibility.pay_monthly",
    ),
    [UtilityOptions.CONSUMPTION_BASED]: t(
      "contracts.se.heating_responsibility.pay_by_consumption",
    ),
    [UtilityOptions.TENANT_SUBSCRIPTION]: t(
      "contracts.se.heating_responsibility.tenant_has_own_subscription",
    ),
    [UtilityOptions.OTHER]: t("contracts.se.heating_responsibility.other"),
  });

export const HotWaterResponsibilityTranslations =
  (): Translatable<UtilityOptions> => ({
    [UtilityOptions.PART_OF_RENT]: t(
      "contracts.se.hot_water_responsibility.part_of_rent",
    ),
    [UtilityOptions.TENANT_PAYS]: t(
      "contracts.se.hot_water_responsibility.pay_monthly",
    ),
    [UtilityOptions.CONSUMPTION_BASED]: t(
      "contracts.se.hot_water_responsibility.pay_by_consumption",
    ),
    [UtilityOptions.TENANT_SUBSCRIPTION]: t(
      "contracts.se.hot_water_responsibility.tenant_has_own_subscription",
    ),
    [UtilityOptions.OTHER]: t("contracts.se.hot_water_responsibility.other"),
  });

export const ColdWaterResponsibilityTranslations =
  (): Translatable<UtilityOptions> => ({
    [UtilityOptions.PART_OF_RENT]: t(
      "contracts.se.cold_water_responsibility.part_of_rent",
    ),
    [UtilityOptions.TENANT_PAYS]: t(
      "contracts.se.cold_water_responsibility.pay_monthly",
    ),
    [UtilityOptions.CONSUMPTION_BASED]: t(
      "contracts.se.cold_water_responsibility.pay_by_consumption",
    ),
    [UtilityOptions.TENANT_SUBSCRIPTION]: t(
      "contracts.se.cold_water_responsibility.tenant_has_own_subscription",
    ),
    [UtilityOptions.OTHER]: t("contracts.se.cold_water_responsibility.other"),
  });

export const ElectricityResponsibilityTranslations =
  (): Translatable<UtilityOptions> => ({
    [UtilityOptions.PART_OF_RENT]: t(
      "contracts.se.electricity_responsibility.part_of_rent",
    ),
    [UtilityOptions.TENANT_PAYS]: t(
      "contracts.se.electricity_responsibility.pay_monthly",
    ),
    [UtilityOptions.CONSUMPTION_BASED]: t(
      "contracts.se.electricity_responsibility.pay_by_consumption",
    ),
    [UtilityOptions.TENANT_SUBSCRIPTION]: t(
      "contracts.se.electricity_responsibility.tenant_has_own_subscription",
    ),
    [UtilityOptions.OTHER]: t("contracts.se.electricity_responsibility.other"),
  });

export const RightOfUseTranslations = (): Translatable<RightOfUse> => ({
  [RightOfUse.ATTIC]: t("contracts.se.right_of_use.attic"),
  [RightOfUse.CELLAR]: t("contracts.se.right_of_use.cellar"),
  [RightOfUse.GARAGE]: t("contracts.se.right_of_use.garage"),
  [RightOfUse.OTHER]: t("contracts.se.right_of_use.other"),
});

export const InventoryOptionsTranslations =
  (): Translatable<InventoryOptions> => ({
    [InventoryOptions.APPENDIX]: t("contracts.se.inventory_appendix"),
    [InventoryOptions.LIST]: t("contracts.se.inventory_list"),
    [InventoryOptions.NOT_INCLUDED]: t("contracts.se.inventory_none"),
  });

export const PetsAllowedTranslations = (): Translatable<PetsAllowed> => ({
  [PetsAllowed.ALLOWED]: t("contracts.se.pets_allowed.allowed"),
  [PetsAllowed.DISPENSATION]: t("contracts.se.pets_allowed.dispensation"),
  [PetsAllowed.DISALLOWED]: t("contracts.se.pets_allowed.disallowed"),
});

export const LandlordPaymentTypeTranslations =
  (): Translatable<PaymentOptions> => ({
    [PaymentOptions.PLUS_GIRO_NO]: t(
      "contracts.se.landlord_payment_type.plus_giro",
    ),
    [PaymentOptions.BANK_GIRO_NO]: t(
      "contracts.se.landlord_payment_type.bank_giro",
    ),
    [PaymentOptions.BANK_TRANSFER_NO]: t(
      "contracts.se.landlord_payment_type.bank_transfer",
    ),
    [PaymentOptions.CASH_AGAINST_RECEIPT]: t(
      "contracts.se.landlord_payment_type.cash",
    ),
  });

// These are basically the labels that are used on the form fields.
export const ValidationErrorTranslations: ValidationErrorTranslation<Market.BOSTADSPORTAL_SE> =
  {
    /* GENERAL */
    monthly_rent: () => t("contracts.se.monthly_rent"),
    leasing_period_start_date: () =>
      t("contracts.se.leasing_period_start_date"),
    size: () => t("contracts.se.size"),
    num_rooms: () => t("contracts.se.number_of_rooms"),
    housing_type: () => t("contracts.se.housing_type"),
    housing_type_other: () => t("contracts.se.housing_type_other"),
    leasing_period_end_date: () => t("contracts.se.leasing_period_end_date"),

    /* UTILITIES */
    heating_supplier: () => t("contracts.se.heating_supplier_title"),
    hot_water_supplier: () => t("contracts.se.hot_water_supplier_title"),
    cold_water_supplier: () => t("contracts.se.cold_water_supplier_title"),
    electricity_supplier: () => t("contracts.se.electricity_supplier_title"),
    tenant_pays_for_tv: () => t("contracts.se.television_supplier_title"),
    tenant_pays_for_internet: () => t("contracts.se.internet_supplier_title"),
    utilities_other_label: () => t("contracts.se.other_contribution"),

    /* ACCESS, MAINTENANCE, INVENTORY */
    right_of_use: () => t("contracts.se.access_and_inventory_title"),
    right_of_use_other: () =>
      t("contracts.se.access_maintenance_inventory.other_contribution"),
    no_of_keys: () => t("contracts.se.number_of_keys_title"),
    pets_allowed: () => t("contracts.se.indoor_maintenance_duty_title"),

    /* LANDLORD */
    landlord_name: () => t("contracts.se.landlord_name"),
    landlord_street: () => t("contracts.se.landlord_address"),
    landlord_postal_code: () => t("contracts.se.landlord_postal_code"),
    landlord_postal_town: () => t("contracts.se.landlord_city"),
    landlord_email: () => t("contracts.se.landlord_contact_title"),
    landlord_phone: () => t("contracts.se.landlord_phone"),
    landlord_organisation_number: () =>
      t("contracts.se.landlord_organisation_number"),
    landlord_person_number: () => t("contracts.se.landlord_person_number"),
    payment_information: () => t("contracts.se.landlord_account"),
    payment_number: () => t("contracts.se.landlord_payment_number"),

    /* TENANTS */
    tenants_first_name: (number: number) =>
      t("contracts.se.tenants_%{number}_first_name", {
        number,
      }),
    tenants_last_name: (number: number) =>
      t("contracts.se.tenants_%{number}_last_name", {
        number,
      }),
    tenants_street: (number: number) =>
      t("contracts.se.tenants_%{number}_street", {
        number,
      }),
    tenants_postal_code: (number: number) =>
      t("contracts.se.tenants_%{number}_postal_code", {
        number,
      }),
    tenants_postal_town: (number: number) =>
      t("contracts.se.tenants_%{number}_postal_town", {
        number,
      }),
    tenants_email: (number: number) =>
      t("contracts.se.tenants_%{number}_email", {
        number,
      }),
    tenants_phone_number: (number: number) =>
      t("contracts.se.tenants_%{number}_phone", {
        number,
      }),
    tenants_person_number: (number: number) =>
      t("contracts.se.tenants_%{number}_person_number", {
        number,
      }),
    tenants_organisation_number: (number: number) =>
      t("contracts.se.tenants_%{number}_organisation_number", {
        number,
      }),

    /* SPECIAL TERMS */
    special_terms: () => t("contracts.se.special_terms_title"),
  };
