import { fetchFromAPI } from "lib/api";
import { TenancyInfoResponse } from "./types";

export const getTenancyInfo = async (userToken: string) =>
  getTenantHubInfo(userToken);

// using fetchFromAPI bacause juice http client doesnt support language prefix
const getTenantHubInfo = async (
  userToken: string,
): Promise<TenancyInfoResponse> => {
  const response = await fetchFromAPI(
    `/api/tenant-hub/${userToken}/tenancy-info`,
    {
      method: "GET",
    },
  );
  return response.json();
};
