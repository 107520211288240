import { Text, Flex, Box, styled } from "@boligportal/juice";
import { t } from "lib/i18n";

const CircleBadge = styled.div<{ bgColor: string; borderColor: string }>`
  width: 68px;
  height: 68px;
  border-radius: 50%;

  position: absolute;
  top: 2%;
  right: 2%;

  background-color: ${(props) => props.bgColor};
  box-shadow: inset 0px 0px 0px 8px ${(props) => props.borderColor};
`;

const StyledPopularBadge = styled(Flex)`
  border-radius: 20px;
  background-color: #e6a33c;
`;

const StyledText = styled(Text)<{ isPopular?: boolean; isMini?: boolean }>`
  color: ${({ theme, isPopular }) =>
    isPopular ? "#E6A33C" : theme.color.text.primary};
  ${({ isMini }) => isMini && "font-size: 10px;"}
`;

type Props = {
  title: string;
  priceOriginalLabel?: string;
  priceLabel: string;
  priceExVatLabel: string;
  mutedCaptionText?: string;
  mutedCaptionText2?: string;
  trafficMagnitude: number;
  trafficMagnitudeText: string;
  isPopular?: boolean;
  isPrivateLandlord?: boolean;
};

export const HoverableCardHeader = ({
  title,
  priceOriginalLabel,
  priceLabel,
  priceExVatLabel,
  mutedCaptionText,
  mutedCaptionText2,
  trafficMagnitude,
  trafficMagnitudeText,
  isPopular,
  isPrivateLandlord,
}: Props) => (
  <Flex column>
    <Flex pb={1}>
      <Flex
        column
        align="start"
        textAlign="left"
      >
        <Flex
          gap
          align="center"
        >
          <Text
            weight="bold"
            size="lead"
          >
            {title}
          </Text>
          {isPopular && isPrivateLandlord && (
            <StyledPopularBadge
              px={1.8}
              py={0.5}
              align="center"
              justify="center"
            >
              <Text
                color="inverted"
                weight="bold"
                size="small"
              >
                {t("product_explainer.plan.popular_badge")}
              </Text>
            </StyledPopularBadge>
          )}
        </Flex>
        {priceOriginalLabel && (
          <Text
            decoration="line-through"
            weight="bold"
            size="h3"
          >
            {priceOriginalLabel}
          </Text>
        )}

        <Text
          weight="bold"
          size="h3"
        >
          {priceLabel}
        </Text>
        <Text
          size="h5"
          color="muted"
        >
          {priceExVatLabel}
        </Text>
      </Flex>
      <Box
        flexPush
        textAlign="center"
      >
        <CircleBadge
          bgColor={isPopular ? "#FFF1DD" : "#ECF0FA"}
          borderColor={isPopular ? "#FFFAF3" : "#F7F8FC"}
        >
          <Flex
            column
            height="100%"
            justify="center"
          >
            <StyledText
              p={0}
              size="small"
              weight="bold"
              isPopular={isPopular}
            >
              {trafficMagnitude}x
            </StyledText>
            <StyledText
              p={0}
              weight="semiBold"
              isMini
              isPopular={isPopular}
            >
              {trafficMagnitudeText}
            </StyledText>
          </Flex>
        </CircleBadge>
      </Box>
    </Flex>
    {mutedCaptionText ? (
      <Text
        textAlign="left"
        size="small"
        color="muted"
      >
        {mutedCaptionText}
      </Text>
    ) : null}

    {mutedCaptionText2 ? (
      <Text
        textAlign="left"
        size="small"
        color="muted"
      >
        {mutedCaptionText2}
      </Text>
    ) : null}
  </Flex>
);
