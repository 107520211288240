import { CSSProperties } from "react";
import * as React from "react";
import { styled } from "@boligportal/juice";

const StyledMain = styled.main<{ backgroundColor?: string }>`
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  background: linear-gradient(110.37deg, #223663 26.52%, #111b32 123.13%);

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  @media print {
    overflow-y: visible;
    overflow-x: visible;
  }
`;

const houseImgStyle: CSSProperties = {
  position: "absolute",
  left: 0,
  bottom: 0,
};

export const DataInsightsContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <StyledMain>
    <img
      style={houseImgStyle}
      src="/static/images/data_insights/house.svg"
    />
    {children}
  </StyledMain>
);
