import { Filters } from "components/interfaces/filters";
import { format } from "date-fns";
import { OrderType } from "lib/enums";

export const getInitialFiltersFromProps = (filters: Filters): Filters => {
  const {
    balcony,
    pet_friendly,
    elevator,
    senior_friendly,
    newbuild,
    student_only,
    furnished,
    shareable,
    min_rental_period,
    parking,
    social_housing,
  } = filters;

  let maxAvailableFrom = "";

  if (filters.max_available_from) {
    maxAvailableFrom = format(
      new Date(filters.max_available_from),
      "yyyy-MM-dd",
    );
  }

  const initialFilters: Filters = {
    ...filters,
    balcony: balcony === null ? false : balcony,
    shareable: shareable === null ? false : shareable,
    pet_friendly: pet_friendly === null ? false : pet_friendly,
    elevator: elevator === null ? false : elevator,
    senior_friendly: senior_friendly === null ? false : senior_friendly,
    newbuild: newbuild === undefined ? false : newbuild,
    student_only: student_only === null ? false : student_only,
    furnished: furnished === null ? false : furnished,
    min_rental_period: min_rental_period,
    parking: parking === null ? false : parking,
    social_housing: social_housing === undefined ? false : social_housing,
    max_available_from: maxAvailableFrom,
  };

  if (!initialFilters.order) {
    initialFilters.order = OrderType.DEFAULT;
  }

  return initialFilters;
};
