import * as React from "react";
import {
  captureException,
  init,
  setUser,
  BrowserOptions,
  ErrorBoundary,
  addBreadcrumb as addSentryBreadcrumb,
  User as TrackingErrorUser,
} from "@sentry/react";
import { FallbackRender } from "@sentry/react/types/errorboundary";
import { ScopeContext } from "@sentry/types/types/scope";

export const initTrackingErrors = (options: BrowserOptions) => {
  init(options);
};

export const trackError = (message: any, extra?: Partial<ScopeContext>) => {
  captureException(message, {
    level: "error",
    ...extra,
  });
};

export const addBreadcrumb = (message: string) => {
  addSentryBreadcrumb({
    category: "breadcrumb",
    level: "info",
    type: "debug",
    message,
  });
};

export const setTrackingErrorUser = (user: TrackingErrorUser) => {
  setUser(user);
};

export const TrackingErrorsBoundary = ({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback?: React.ReactElement | FallbackRender;
}) => <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;

export const captureExceptionIfNotAbortError = (err: any) => {
  if (err?.name === "AbortError") {
    return;
  }
  trackError(err);
};
