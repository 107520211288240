import {
  Button,
  Flex,
  Text,
  IconChevronForward,
  IconChevronBack,
} from "@boligportal/juice";

export const ListPaginator = ({
  onNextPage,
  onPrevPage,
  totalCount,
  itemCount,
  offset,
}: {
  onNextPage: () => void;
  onPrevPage: () => void;
  totalCount: number | null;
  itemCount: number;
  offset: number;
  limit?: number;
}) => (
  <Flex
    gap
    align="center"
  >
    <Button
      onClick={() => onPrevPage()}
      disabled={offset === 0}
      icon={IconChevronBack}
    />

    <Flex
      column
      align="center"
      width="300px"
    >
      {totalCount !== null && (
        <Text>
          Showing {`${offset} to ${offset + itemCount} of ${totalCount}`}
        </Text>
      )}
      {totalCount === null && (
        <Text>Showing {`${offset} to ${offset + itemCount}`}</Text>
      )}
    </Flex>

    <Button
      onClick={onNextPage}
      disabled={totalCount !== null && offset + itemCount >= totalCount}
      icon={IconChevronForward}
    />
  </Flex>
);
