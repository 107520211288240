import { useState } from "react";
import styled from "@emotion/styled";
import { UserSilhouette } from "components/icons/user_silhouette";
import { getImageUrl } from "lib/image";

const StyledImg = styled.div<{ size: number }>`
  position: relative;
  display: block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: inherit;
  z-index: 1;
`;

const BasicAvatar = ({
  src,
  size = 40,
}: {
  src?: string | null;
  size?: number;
}) => {
  const [error, setError] = useState(false);

  if (!src || error) {
    return (
      <StyledImg size={size}>
        <UserSilhouette size={size} />
        <StyledShadow />
      </StyledImg>
    );
  }

  const imageSource = getImageUrl({
    image: {
      url: src,
    },
    width: size,
    height: size,
    crop: true,
    mask: "ellipse",
  });

  const imageSource2x =
    getImageUrl({
      image: {
        url: src,
      },
      width: size,
      height: size,
      dpr: 2,
      crop: true,
      mask: "ellipse",
    }) + " 2x";

  return (
    <StyledImg size={size}>
      <img
        src={imageSource}
        srcSet={imageSource2x}
        onError={() => setError(true)}
      />
      <StyledShadow />
    </StyledImg>
  );
};

export { BasicAvatar };
