import * as React from "react";
import { Box, Container, Flex } from "@boligportal/juice";

export const ListLayout = ({
  headerComponent,
  listComponent,
  sharedSearchComponent,
  sharedPaginatorComponent,
  desktopStateFilterComponent,
  mobileStateFilterComponent,
}: {
  headerComponent: React.ReactNode;
  listComponent: React.ReactNode;
  sharedSearchComponent?: React.ReactNode;
  sharedPaginatorComponent?: React.ReactNode;
  desktopStateFilterComponent?: React.ReactNode;
  mobileStateFilterComponent?: React.ReactNode;
}) => (
  <>
    <Box
      bg="base"
      borderBottom
    >
      <Container fluid>
        <Box pt={2}>{headerComponent}</Box>

        <Flex column>
          {sharedSearchComponent && (
            <Box
              pt={2}
              pb={3}
              width="100%"
              maxWidth={{
                xs: "100%",
                lg: "480px",
              }}
            >
              {sharedSearchComponent}
            </Box>
          )}

          {desktopStateFilterComponent && (
            <Box
              hidden={{
                xs: true,
                lg: false,
              }}
            >
              {desktopStateFilterComponent}
            </Box>
          )}

          {mobileStateFilterComponent && (
            <Box
              width="100%"
              hidden={{
                xs: false,
                lg: true,
              }}
            >
              {mobileStateFilterComponent}
            </Box>
          )}
        </Flex>
      </Container>
    </Box>

    <Box
      height="100%"
      pt={5}
    >
      <>
        {listComponent}
        {sharedPaginatorComponent}
      </>
    </Box>
  </>
);
