import { Box, Center, Text } from "@boligportal/juice";
import { TenancyPaymentEventItem } from "business/domain/tenancy/TenancyPaymentEventItem";
import { localizedDateTimeLongAndPretty } from "lib/date";
import { t } from "lib/i18n";

export const PaymentActivities = ({
  activities,
}: {
  activities: TenancyPaymentEventItem[];
}) => (
  <Box
    border
    borderRadius="md"
    bg="tint"
    p={3}
  >
    {activities.length === 0 && (
      <Center>
        <Text
          color="muted"
          size="small"
        >
          {t("rentable.rent.activities.empty_placeholder")}
        </Text>
      </Center>
    )}

    {activities.map((item, index) => {
      const isLast = !activities[index + 1];

      return (
        <Box
          key={`${item.event}${item.timestamp}`}
          mb={isLast ? 0 : 2}
        >
          <Text
            block
            color="muted"
            size="small"
          >
            {localizedDateTimeLongAndPretty(item.timestamp)}
          </Text>

          <Text markdown>{t(item.event)}</Text>
        </Box>
      );
    })}
  </Box>
);
