import * as React from "react";

const SvgArrowLeft14Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    className="Icon"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.68 6.18v1.64H3.52l4.66 4.68L7 13.68.32 7 7 .32 8.18 1.5 3.52 6.18z"
    />
  </svg>
);
export default SvgArrowLeft14Px;
