import currency from "currency.js";

export const options = {
  symbol: "kr.",
  decimal: ",",
  separator: ".",
  pattern: "# !",
  negativePattern: "-# !",
};

export const formatCurrency = (value) => currency(value, options).format();
