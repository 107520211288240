export const drawPin = (
  width: number,
  height: number,
  n: 1 | 2 | 3 = 1,
  selected: boolean = false,
): HTMLImageElement => {
  const maxN = 3;
  const devicePixelRatio = window.devicePixelRatio || 1;
  const canvas = document.createElement("canvas");
  const shadowBlur = 2;
  const borderRadius = 2;
  const shadowCircleRadius = 10;
  const offset = 3;
  const selectedColor = "#DF7744";
  const originX = shadowBlur + (maxN - 1) * offset;
  const originY = shadowBlur + (maxN - 1) * offset;
  const triangleSize = 20;
  canvas.width =
    (width + (maxN - 1) * offset * 2 + shadowBlur * 2) * devicePixelRatio;
  canvas.height =
    (height +
      triangleSize / 2 +
      (maxN - 1) * offset +
      shadowBlur * 2 +
      shadowCircleRadius) *
    devicePixelRatio;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No context for the canvas");
  }

  // Scale up based on device pixel ratio
  ctx.scale(devicePixelRatio, devicePixelRatio);

  // Draw shadow circle
  ctx.fillStyle = "rgba(0,0,0,0.05)";
  ctx.arc(
    canvas.width / devicePixelRatio / 2,
    canvas.height / devicePixelRatio - shadowCircleRadius - shadowBlur,
    shadowCircleRadius,
    0,
    2 * Math.PI,
  );
  ctx.fill();

  // Draw pins
  for (let i = n - 1; i >= 0; i--) {
    ctx.fillStyle = "white";
    if (selected) {
      ctx.lineWidth = 2;
      ctx.strokeStyle = selectedColor;
      ctx.shadowBlur = 0;
      ctx.shadowColor = "";
    } else {
      ctx.lineWidth = 0;
      ctx.strokeStyle = "";
      ctx.shadowBlur = shadowBlur * devicePixelRatio;
      ctx.shadowColor = "rgba(0,0,0,0.3)";
    }
    const x = (x: number) => x + originX - offset * i;
    const y = (y: number) => y + originY - offset * i;
    ctx.beginPath();
    ctx.moveTo(x(borderRadius), y(0));
    ctx.lineTo(x(width - borderRadius), y(0));
    ctx.quadraticCurveTo(x(width), y(0), x(width), y(borderRadius));
    ctx.lineTo(x(width), y(height - borderRadius));
    ctx.quadraticCurveTo(
      x(width),
      y(height),
      x(width - borderRadius),
      y(height),
    );
    // Only draw triangle for the top pin
    if (i === 0) {
      ctx.lineTo(x(width / 2 + triangleSize / 2), y(height));
      ctx.lineTo(x(width / 2), y(height + triangleSize / 2));
      ctx.lineTo(x(width / 2 - triangleSize / 2), y(height));
    }
    ctx.lineTo(x(0 + borderRadius), y(height));
    ctx.quadraticCurveTo(x(0), y(height), x(0), y(height - borderRadius));
    ctx.lineTo(x(0), y(borderRadius));
    ctx.quadraticCurveTo(x(0), y(0), x(borderRadius), y(0));
    ctx.fill();
    if (selected) {
      ctx.stroke();
    }
  }

  const image = document.createElement("img");
  image.src = canvas.toDataURL("image/png");

  return image;
};
