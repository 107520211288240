import { Box, Button, Flex, Text } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { t } from "lib/i18n";

export const ImpersonationWarning = () => {
  const { user } = useUser();

  if (!user || !user.impersonating) {
    return null;
  }

  return (
    <Flex
      width="100%"
      align="center"
      px={1.5}
      py={1.5}
      bg="tint"
      borderBottom
    >
      <Box
        flexGrow
        hidden={{
          xs: true,
          md: false,
        }}
        textAlign="center"
      >
        <Text>
          <Text>{`${t("impersonation.warning")} `}</Text>
          <Text weight="bold">{user.email}</Text>
        </Text>
      </Box>
      <Button
        fullWidth={{
          xs: true,
          md: false,
        }}
        variant="danger"
        href={user.impersonating.logout_url}
      >
        {t("impersonation.stop")}
      </Button>
    </Flex>
  );
};
