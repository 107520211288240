import { useRef, useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  TextField,
  Text,
} from "@boligportal/juice";
import { embeddedScriptTemplate, scriptUrl } from "./embedding_script_template";
import { Company } from "./interfaces";

type Props = {
  onClose: () => void;
  company: Company;
};

const DialogContents = ({ onClose, company }: Props) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const host = window.location.origin;

  const embeddedScript = useMemo(() => {
    if (company.embedded_allowed && company.key) {
      return embeddedScriptTemplate.replace("COMPANY_KEY", company.key);
    }

    return "";
  }, [company.embedded_allowed, company.key]);

  return (
    <>
      <DialogHeader>Embedding script</DialogHeader>

      <DialogContent>
        <Box mb={1}>
          <TextField
            ref={ref}
            multiLine={12}
            value={embeddedScript}
          />
        </Box>

        <Box mb={4}>
          <Button
            onClick={() => {
              if (ref.current) {
                ref.current.select();
                document.execCommand("copy");
              }
            }}
          >
            Copy to clipboard
          </Button>
        </Box>

        <Text
          size="h5"
          mb={2}
          weight="bold"
          block
        >
          Preview
        </Text>

        {company.key && (
          <Preview
            company_key={company.key}
            host={host}
          />
        )}
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button
            variant="primary"
            type="submit"
            onClick={onClose}
          >
            Close
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};

type PreviewProps = {
  company_key: string;
  host: string;
};

const Preview = ({ company_key, host }: PreviewProps) => {
  useEffect(() => {
    const scriptEmbed = document.createElement("script");
    const scriptBody = document.createElement("script");

    scriptEmbed.src = scriptUrl;
    scriptEmbed.async = true;

    scriptBody.innerHTML = `
      window.company_key = "${company_key}";
      window.host = "${host}";
    `;
    scriptBody.async = true;

    document.body.appendChild(scriptEmbed);
    document.body.appendChild(scriptBody);

    return () => {
      document.body.removeChild(scriptEmbed);
      document.body.removeChild(scriptBody);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => window.bpe("bp_embeddable_placeholder"), 1000);
  }, []);

  return (
    <div style={{ pointerEvents: "none" }}>
      <div id="bp_embeddable_placeholder" />
    </div>
  );
};

type WidgetProps = {
  company: Company;
  updateField: (fieldname: string, value: unknown) => Promise<void>;
};

export const EmbeddingWidget = ({ company, updateField }: WidgetProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dialogCloseHandler = () => {
    setShowDialog(false);
  };

  const dialogOpenHandler = () => {
    setShowDialog(true);
  };

  const toggleEmbedded = () => {
    if (!isLoading) {
      setIsLoading(true);
      updateField("embedded_allowed", !company.embedded_allowed).finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <Box key={company.id}>
        <Flex gap={1}>
          <Button
            onClick={toggleEmbedded}
            loading={isLoading}
          >
            {company.embedded_allowed ? "Disable" : "Enable"}
          </Button>

          {company.embedded_allowed && (
            <Button onClick={dialogOpenHandler}>View script</Button>
          )}

          <Dialog
            slideOver
            size="large"
            open={showDialog}
            onClose={dialogCloseHandler}
          >
            <DialogContents
              onClose={dialogCloseHandler}
              company={company}
            />
          </Dialog>
        </Flex>
      </Box>
    </>
  );
};
