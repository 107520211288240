import { styled } from "@boligportal/juice";
import { getLanguagePrefix } from "lib/utils";
import { SuggestionItem } from "./components/SuggestionItem";
import { SuggestionMap } from "./components/SuggestionMap";
import { useLocationAutoCompleteState } from "./hooks/useLocationAutoCompleteState";
import { LocationAutoCompleteOnSelect, LocationResult } from "./types";

const StyledSuggenstionsList = styled.ul`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.popover};
  background: ${(props) => props.theme.color.bg.base};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  list-style: none;
  padding: 0;
  color: ${(props) => props.theme.color.text.base};
`;

export const DefaultInputVariant = styled.input`
  border: none;
  border-radius: 5px;
  background-color: white;
  display: block;
  width: 100%;
  padding: 1em 1.25em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 1rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.color.text.base};
  }
`;

export const DenseInputVariant = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: ${(props) => props.theme.color.text.muted};
  }
`;

const ContainingDiv = styled.div`
  position: relative;
`;

const inputVariants = {
  default: DefaultInputVariant,
  dense: DenseInputVariant,
};
type Props = {
  onSelect: LocationAutoCompleteOnSelect;
  placeholder: string;
  latestSearches?: React.ReactNode;
  variant?: "default" | "dense";
  withSuggestionMap?: boolean;
};

export const LocationAutoComplete = ({
  onSelect,
  placeholder,
  latestSearches,
  variant = "default",
  withSuggestionMap = false,
}: Props) => {
  const {
    suggestions,
    containingDivRef,
    suggestionsRef,
    inputProps,
    show,
    selectionIndex,
  } = useLocationAutoCompleteState(onSelect);

  const InputComp = inputVariants[variant];

  return (
    <ContainingDiv ref={containingDivRef}>
      <InputComp
        data-test-id="locationAutoComplete"
        autoFocus={false}
        spellCheck={false}
        placeholder={placeholder}
        {...inputProps}
      />
      {show && (
        <StyledSuggenstionsList
          data-test-id="locationAutoCompleteSuggestions"
          ref={suggestionsRef}
        >
          {withSuggestionMap && <SuggestionMap />}
          {suggestions.length === 0
            ? latestSearches
            : suggestions.map((suggestion, index) => (
                <SuggestionItem
                  hasSelection={index === selectionIndex}
                  key={suggestion.id}
                  suggestion={suggestion}
                  onSelect={onSelect}
                />
              ))}
        </StyledSuggenstionsList>
      )}
    </ContainingDiv>
  );
};

export const navigateToSuggestion = (suggestion: LocationResult) => {
  if (getLanguagePrefix() === "") {
    window.location.href = suggestion.url;
  } else {
    window.location.href = `/${getLanguagePrefix()}${suggestion.url}`;
  }
};
