import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { TextField } from "@boligportal/juice";

type Props = {
  label?: string;
  errorText?: string;
} & UseFormRegisterReturn;

/**
 * In contrary to RentalSize from form/elements, this component supports flows where
 * multiple sized are being set in the same form.
 * */
export const RentalSizeItem = forwardRef<HTMLInputElement, Props>(
  ({ label, errorText, ...registerProps }, ref) => (
    <TextField
      type="number"
      label={label}
      errorText={errorText}
      min={0}
      append={
        <span>
          m<sup>2</sup>
        </span>
      }
      {...registerProps}
      ref={ref}
    />
  ),
);
