import { UseFormRegister } from "react-hook-form";
import { MutationStatus } from "react-query";
import {
  IconRefreshOutline,
  CheckFieldNaked,
  Hidden,
  styled,
  SuccessSpinner,
  Text,
  Box,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../../types";

const StyledCheckField = styled(CheckFieldNaked)`
  span {
    position: relative;
    bottom: 10px;
  }
`;

export type Props = {
  register: UseFormRegister<CreateRentableForm>;
  fieldName: `rentables.${number}`;
  disabled: boolean;
  retry: () => void;
  status: MutationStatus;
};

export const CreationStatus = ({
  register,
  fieldName,
  disabled,
  status,
  retry,
}: Props) => (
  <>
    {(status === "loading" || status === "success") && (
      <SuccessSpinner
        size="medium"
        isSuccess={status === "success"}
      />
    )}

    {status === "error" && (
      <Box onClick={retry}>
        <IconRefreshOutline
          color="primary"
          size={4.5}
        />
      </Box>
    )}

    {status === "idle" && (
      <>
        <StyledCheckField
          disabled={disabled}
          {...register(`${fieldName}.shouldCreate` as const)}
        />
        <Hidden
          on={{
            lg: true,
          }}
        >
          <Text ml={1}>
            {t("feature.address_form.rentable_list.item.create")}
          </Text>
        </Hidden>
      </>
    )}
  </>
);
