import { useState } from "react";
import { Box, styled } from "@boligportal/juice";
import { css } from "@emotion/react";
import { getQueryVariable } from "lib/utils";
import { CompanyDetailsContextProvider } from "./company_details_view/company_details_context";
import { CompanyDetailsView } from "./company_details_view/company_details_view";
import { UserDetailPageContextProvider } from "./user_detail_page_context";
import { UserDetailPageToolbar } from "./user_detail_page_toolbar";
import { UserDetailView } from "./user_detail_view/user_detail_view";

const activeTabStyles = (props) => css`
  border-left: 1px solid ${props.theme.colorPalette.gray[60]};
  border-right: 1px solid ${props.theme.colorPalette.gray[60]};
  border-top: 1px solid ${props.theme.colorPalette.gray[60]};
`;

const inActiveTabStyles = (props) => css`
  background-color: ${props.theme.colorPalette.gray[30]};
  border: 1px solid ${props.theme.colorPalette.gray[30]};
  border-bottom-color: ${props.theme.colorPalette.gray[60]};
`;

const StyledTab = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-top-left-radius: ${(props) => props.theme.borderRadius.sm};
  border-top-right-radius: ${(props) => props.theme.borderRadius.sm};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  display: block;
  border: none;
  margin-right: ${(props) => props.theme.unit(1)};
  background-color: ${(props) => props.theme.colorPalette.gray[20]};
  padding: ${(props) => `${props.theme.unit(1)} ${props.theme.unit(2)}`};
  transform: translate(0px, 1px);
  ${(props) => (props.active ? activeTabStyles : inActiveTabStyles)};
`;

const StyledTabContainer = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.unit(6)};
  padding-left: ${(props) => props.theme.unit(2)};
  border-bottom: 1px solid ${(props) => props.theme.colorPalette.gray[60]};
  background-color: ${(props) => props.theme.colorPalette.gray[30]};
`;

const UserDetailPage = () => {
  const initialTab =
    getQueryVariable("tab") == "COMPANY_DETAILS"
      ? "COMPANY_DETAILS"
      : "USER_DETAILS";
  const [activeTab, setActiveTab] = useState<
    "USER_DETAILS" | "COMPANY_DETAILS"
  >(initialTab);
  return (
    <UserDetailPageContextProvider>
      <CompanyDetailsContextProvider>
        <UserDetailPageToolbar />
        <Box
          height="100%"
          scrollable
          bg="tint"
        >
          <div>
            <StyledTabContainer>
              <StyledTab
                active={activeTab === "USER_DETAILS"}
                onClick={() => setActiveTab("USER_DETAILS")}
              >
                User Details
              </StyledTab>
              <StyledTab
                active={activeTab === "COMPANY_DETAILS"}
                onClick={() => setActiveTab("COMPANY_DETAILS")}
              >
                Company Details
              </StyledTab>
            </StyledTabContainer>
            {activeTab === "USER_DETAILS" && <UserDetailView />}
            {activeTab === "COMPANY_DETAILS" && <CompanyDetailsView />}
          </div>
        </Box>
      </CompanyDetailsContextProvider>
    </UserDetailPageContextProvider>
  );
};

export { UserDetailPage };
