import * as React from "react";
import { Box, Flex, Hidden, styled, Text } from "@boligportal/juice";
import { AnimationReveal } from "components/animation/AnimationReveal";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";
import { HammerIcon } from "./Icons/Hammer";
import { ListIcon } from "./Icons/List";
import { NotificationBellIcon } from "./Icons/NotificationBell";
import { Paragraph11Icon } from "./Icons/Paragraph11";
import { RentIcon } from "./Icons/Rent";
import { WireIcon } from "./Icons/Wire";

const HeroHeader = styled.h2`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 30px;
  }
`;

const MarketingSubHeader = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: #ffffff;
`;

// AGENT ===============================================================================================================
const AgentArrow = () => (
  <svg
    width="100%"
    viewBox="0 0 364 72"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M12.6970887,72.9858189 C78.243308,37.0370626 140.558585,14.4387225 199.64292,5.19079889 C258.727255,-4.05712477 314.644817,-1.52851357 367.395605,12.7766325"
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
        transform="translate(190.046347, 36.364967) rotate(19.000000) translate(-190.046347, -36.364967) "
      />
      <polyline
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        transform="translate(5.697089, 14.744114) rotate(-13.000000) translate(-5.697089, -14.744114) "
        points="9.69708869 6.74411446 1.69708869 14.7441145 9.69708869 22.7441145"
      />
    </g>
  </svg>
);

const TranslateAgentWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 150px;
    padding-right: 15px;
    transform: translate(-100%, -28px);

    .RentOutPage__TranslateWrapper__Content {
      width: 200px;

      transform: translate(-100px, -27px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 250px;
    padding-right: 30px;
    transform: translate(-100%, -68px);

    .RentOutPage__TranslateWrapper__Content {
      width: 300px;

      transform: translate(-110px, -45px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 364px;
    padding-right: 30px;
    transform: translate(-100%, -68px);

    .RentOutPage__TranslateWrapper__Content {
      transform: translate(-100px, -65px);
    }
  }
`;

const FacebookArrow = () => (
  <svg
    width="100%"
    height="53px"
    viewBox="0 0 166 53"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M2,6.77777778 C34.5378599,0.407407407 63.8901232,0.407407407 90.0567899,6.77777778 C116.223457,13.1481481 139.204527,25.8888889 159,45"
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
      />
      <polyline
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        transform="translate(160.000000, 48.000000) rotate(-124.000000) translate(-160.000000, -48.000000) "
        points="164 40 156 48 164 56"
      />
    </g>
  </svg>
);

const TranslateFacebookWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100px;
    padding-left: 15px;
    transform: translate(256px, 10px);

    .RentOutPage__TranslateWrapper__Content {
      width: 160px;

      transform: translate(50px, 0px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 140px;
    padding-left: 30px;
    transform: translate(256px, 10px);

    .RentOutPage__TranslateWrapper__Content {
      width: 240px;

      transform: translate(80px, 20px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 166px;
    padding-left: 30px;
    transform: translate(256px, 15px);

    .RentOutPage__TranslateWrapper__Content {
      width: 280px;

      transform: translate(180px, -20px);
    }
  }
`;

// Google ===============================================================================================================

const GoogleArrow = () => (
  <svg
    width="100%"
    height="107px"
    viewBox="0 0 210 107"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        // eslint-disable-next-line max-len
        d="M11,95 C56.53668,88.373195 95.7216866,77.3097925 128.55502,61.8097925 C161.388353,46.3097925 187.870013,26.373195 208,2"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeDasharray="5"
        transform="translate(109.500000, 48.500000) scale(-1, -1) translate(-109.500000, -48.500000) "
      />
      <polyline
        stroke="#FFFFFF"
        strokeWidth="2"
        transform="translate(6.941467, 102.000000) rotate(-62.000000) translate(-6.941467, -102.000000) "
        points="10.941467 94 2.94146699 102 10.941467 110"
      />
    </g>
  </svg>
);

const TranslateGoogleWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 150px;
    padding-right: 15px;
    transform: translate(-100%, 200px);

    .RentOutPage__TranslateWrapper__Content {
      width: 250px;

      transform: translate(-30px, 0px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 170px;
    padding-right: 30px;
    transform: translate(-100%, 180px);

    .RentOutPage__TranslateWrapper__Content {
      width: 280px;

      transform: translate(-130px, -10px);
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 210px;
    padding-right: 30px;
    transform: translate(-100%, 180px);

    .RentOutPage__TranslateWrapper__Content {
      width: 330px;

      transform: translate(-130px, -10px);
    }
  }
`;

const FeatureIconItem = ({
  headline,
  text,
  icon,
}: {
  headline: string;
  text: string;
  icon: JSX.Element;
}) => (
  <Flex
    textAlign={{
      xs: "center",
      md: "left",
    }}
    column
  >
    <Box mb={4}>{icon}</Box>
    <Box mb={1}>
      <Text
        color="inverted"
        weight="bold"
        size={{
          xs: "h2",
          md: "h4",
          lg: "h3",
        }}
      >
        {headline}
      </Text>
    </Box>
    <Box>
      <Text
        color="inverted"
        size={{
          xs: "body",
          md: "small",
          lg: "body",
        }}
      >
        {text}
      </Text>
    </Box>
  </Flex>
);

const MarketingMailDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateAgentWrapper>
      <AgentArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={
              App.settings.market === Market.BOLIGPORTAL_DK ? (
                <Paragraph11Icon />
              ) : (
                <ListIcon />
              )
            }
          />
        </AnimationReveal>
      </div>
    </TranslateAgentWrapper>
  </div>
);

const MarketingMailMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal delayInMilliseconds={300}>
    <FeatureIconItem
      headline={headline}
      text={text}
      icon={
        App.settings.market === Market.BOLIGPORTAL_DK ? (
          <Paragraph11Icon />
        ) : (
          <ListIcon />
        )
      }
    />
  </AnimationReveal>
);

const MarketingFacebookDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateFacebookWrapper>
      <FacebookArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal delayInMilliseconds={600}>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={
              App.settings.market === Market.BOLIGPORTAL_DK ? (
                <NotificationBellIcon />
              ) : (
                <RentIcon />
              )
            }
          />
        </AnimationReveal>
      </div>
    </TranslateFacebookWrapper>
  </div>
);

const MarketingFacebookMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal>
    <Box pt={8}>
      <FeatureIconItem
        headline={headline}
        text={text}
        icon={
          App.settings.market === Market.BOLIGPORTAL_DK ? (
            <NotificationBellIcon />
          ) : (
            <RentIcon />
          )
        }
      />
    </Box>
  </AnimationReveal>
);

const MarketingGoogleDesktopSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <div
    style={{
      position: "absolute",
    }}
  >
    <TranslateGoogleWrapper>
      <GoogleArrow />
      <div className="RentOutPage__TranslateWrapper__Content">
        <AnimationReveal>
          <FeatureIconItem
            headline={headline}
            text={text}
            icon={
              App.settings.market === Market.BOLIGPORTAL_DK ? (
                <WireIcon />
              ) : (
                <HammerIcon />
              )
            }
          />
        </AnimationReveal>
      </div>
    </TranslateGoogleWrapper>
  </div>
);

const MarketingGoogleMobileSection = ({
  headline,
  text,
}: {
  headline: string;
  text: string;
}) => (
  <AnimationReveal>
    <Box pt={8}>
      <FeatureIconItem
        headline={headline}
        text={text}
        icon={
          App.settings.market === Market.BOLIGPORTAL_DK ? (
            <WireIcon />
          ) : (
            <HammerIcon />
          )
        }
      />
    </Box>
  </AnimationReveal>
);

const MarketingSectionHouseSvg = () => (
  <svg
    width="536"
    height="547"
    viewBox="-5 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        // eslint-disable-next-line max-len
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const RelativeBox = styled(Box)`
  position: relative;
`;

interface Props {
  headline: string;
  subHeadline: string;
  feature1Headline: string;
  feature1SubHeadline: string;
  feature2Headline: string;
  feature2SubHeadline: string;
  feature3Headline: string;
  feature3SubHeadline: string;
  centeredComponent: React.ReactNode;
}

export const FeaturesIconSection = ({
  headline,
  subHeadline,
  feature1Headline,
  feature1SubHeadline,
  feature2Headline,
  feature2SubHeadline,
  feature3Headline,
  feature3SubHeadline,
  centeredComponent,
}: Props) => (
  <PageBlock
    bg="business"
    py="extraLarge"
    pyMobile="extraLarge"
    bgGradient
    bgBottomRightOverlay={
      <Hidden
        on={{
          xs: true,
          md: false,
        }}
      >
        <MarketingSectionHouseSvg />
      </Hidden>
    }
    negativeTopMargin="-250px"
  >
    <ContractsLandingPageContainer>
      <Flex
        mt={{
          xs: 20,
          md: 22,
        }}
        mb={{
          xs: 0,
          md: 30,
        }}
        textAlign="center"
        align="center"
        column
      >
        <HeroHeader>{headline}</HeroHeader>
        <Box
          maxWidth={{
            xs: "100%",
            sm: "500px",
          }}
          mb={{
            xs: 10,
            md: 24,
          }}
          mt={4}
        >
          <MarketingSubHeader>{subHeadline}</MarketingSubHeader>
        </Box>

        {/* Desktop */}
        <Flex
          width="100%"
          justify="center"
          hidden={{
            xs: true,
            md: false,
          }}
        >
          <RelativeBox>
            <MarketingMailDesktopSection
              headline={feature1Headline}
              text={feature1SubHeadline}
            />
            <MarketingFacebookDesktopSection
              headline={feature2Headline}
              text={feature2SubHeadline}
            />
            <MarketingGoogleDesktopSection
              headline={feature3Headline}
              text={feature3SubHeadline}
            />
          </RelativeBox>

          {centeredComponent}
        </Flex>

        {/* Mobile */}
        <Flex
          column
          hidden={{
            xs: false,
            md: true,
          }}
        >
          <MarketingMailMobileSection
            headline={feature1Headline}
            text={feature1SubHeadline}
          />
          <MarketingFacebookMobileSection
            headline={feature2Headline}
            text={feature2SubHeadline}
          />
          <MarketingGoogleMobileSection
            headline={feature3Headline}
            text={feature3SubHeadline}
          />
        </Flex>
      </Flex>
    </ContractsLandingPageContainer>
  </PageBlock>
);
