import { useTheme } from "@boligportal/juice";

interface Props {
  size: number;
  inverseColor?: boolean;
}

/*
  SVG mask id´s must be unigue, and because we can have multiple
  usages of the UserSilhouette at the same this we need this.
*/
let uniqueMaskId = 1;

export const UserSilhouette = ({ size, inverseColor }: Props) => {
  const theme = useTheme();
  const maskId = `userSilhouetteMask${uniqueMaskId++}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 37 37"
    >
      <circle
        className={`UserIcon__Background ${
          inverseColor ? "UserIcon__Background--inverse" : ""
        }`}
        cx="18.5"
        cy="18.5"
        r="18.5"
        fill={theme.colorPalette.gray[50]}
        x={0}
        y={0}
      />

      <mask id={maskId}>
        <circle
          cx="18.5"
          cy="18.5"
          r="18.5"
          fill="white"
        />
      </mask>

      <path
        // eslint-disable-next-line max-len
        d="M19 38h15s0-2.683-1.304-5.292c-.973-1.945-5.218-3.26-10.435-5.217v-3.913s1.956-1.305 1.956-3.913c.653 0 1.305-2.61 0-3.261 0-.388 1.747-3.654 1.305-5.87-.652-3.26-9.783-3.26-10.435-.652-3.913 0-1.304 5.992-1.304 6.522-1.305.652-.653 3.26 0 3.26 0 2.61 1.956 3.914 1.956 3.914v3.913c-5.217 1.956-9.463 3.272-10.435 5.217C4 35.317 4 38 4 38h15z"
        fill="#FFF"
        mask={`url(#${maskId})`}
      />
    </svg>
  );
};
