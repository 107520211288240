import { Flex, Box, Text, IconDocumentTextOutline } from "@boligportal/juice";
import { ContractViewModel } from "apps/contracts/components/common/interfaces";

export const ContractAddressCellRenderer = ({
  addressInfo,
  rentable_address,
}: ContractViewModel) => (
  <a href={`/contracts${addressInfo.link}/`}>
    <AddressAndTentantsCell
      address={rentable_address}
      tenants={addressInfo.tenantsDisplayLabel}
    />
  </a>
);

export const AddressAndTentantsCell = ({
  address,
  tenants,
}: {
  address: string;
  tenants: string;
}) => (
  <Flex>
    <Box pr={1}>
      <IconDocumentTextOutline
        size="large"
        color="muted"
      />
    </Box>
    <Flex column>
      <Box>
        <Text
          weight="bold"
          size="small"
        >
          {address}
        </Text>
      </Box>
      <Box>
        <Text
          color="muted"
          size="small"
        >
          {tenants}
        </Text>
      </Box>
    </Flex>
  </Flex>
);
