/* eslint-disable comma-dangle */
import { ListItemRendererPosition, Box, Text } from "@boligportal/juice";
import { AddressCandidate } from "features/address/common/interfaces/AddressCandidate";

export const AddressCandidateRenderer = (
  item: AddressCandidate,
  isFocussed: boolean,
  position: ListItemRendererPosition,
) => {
  const borderRadiusTop = position.isFirst ? "sm" : undefined;
  const borderRadiusBottom = position.isLast ? "sm" : undefined;

  return (
    <Box
      px={1.5}
      py={1}
      borderLeft
      borderRight
      borderTop
      borderBottom={position.isLast}
      borderRadiusTL={borderRadiusTop}
      borderRadiusTR={borderRadiusTop}
      borderRadiusBL={borderRadiusBottom}
      borderRadiusBR={borderRadiusBottom}
      bg={isFocussed ? "tint" : "base"}
    >
      <Text block>{item.candidateAddress}</Text>
    </Box>
  );
};
