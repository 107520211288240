import { Tracking } from "lib/tracking/common";

export const trackUserClickedToImpersonateCompanyColleague = (
  nameOfCompany: string,
) => {
  Tracking.trackEvent({
    category: "sidebar",
    label: nameOfCompany,
    action: "click log in as colleague",
    isInteraction: true,
  });
};
