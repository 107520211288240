import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  Box,
  Labelled,
  ListItemRendererPosition,
  SuggestionList,
  Text,
} from "@boligportal/juice";
import { useEstates } from "api/queries";
import { t } from "lib/i18n";

type Estate = {
  id: string;
  name: string;
};

export const RentalEstate = () => {
  const { control, setValue } = useFormContext<{ estate: string }>();
  const searchValue = useWatch({
    control,
    name: "estate",
  });
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const items = useEstateAutocomplete(searchValue);

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("estate", event.currentTarget.value, {
      shouldDirty: true,
    });
    setShowSuggestions(true);
  };

  const onSelectEstate = (estate: Estate) => {
    setShowSuggestions(false);
    setValue("estate", estate.name);
  };

  return (
    <Labelled label={t("create.field.estate.heading")}>
      <SuggestionList
        items={showSuggestions ? items : []}
        itemRenderer={EstateSearchRenderer}
        onSelect={onSelectEstate}
        inputProps={{
          type: "search",
          autoComplete: "off",
          placeholder: t("create.field.estate.placeholder"),
          value: searchValue,
          onChange: updateSearchValue,
          onBlur: () => {
            // Without the timeout the onSelect function will not be called
            setTimeout(() => {
              setShowSuggestions(false);
            }, 100);
          },
        }}
      />
    </Labelled>
  );
};

const useEstateAutocomplete = (searchValue: string): Estate[] => {
  const { data } = useEstates();

  if (!data || !searchValue) {
    return [];
  }

  const estatesMatchingSearchValue = data.estates
    .filter((estate) =>
      estate.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .slice(0, 5)
    .map((estate) => ({
      id: estate,
      name: estate,
    }));

  return estatesMatchingSearchValue;
};

const EstateSearchRenderer = (
  { name }: Estate,
  isFocussed: boolean,
  position: ListItemRendererPosition,
) => {
  const borderRadiusTop = position.isFirst ? "sm" : undefined;
  const borderRadiusBottom = position.isLast ? "sm" : undefined;

  return (
    <Box
      px={1.5}
      py={1}
      borderLeft
      borderRight
      borderTop
      borderBottom={position.isLast}
      borderRadiusTL={borderRadiusTop}
      borderRadiusTR={borderRadiusTop}
      borderRadiusBL={borderRadiusBottom}
      borderRadiusBR={borderRadiusBottom}
      bg={isFocussed ? "tint" : "base"}
    >
      <Text block>{name}</Text>
    </Box>
  );
};
