import { IconSearch } from "@boligportal/juice";
import { DebouncedTextField } from "components/juice-candidates/DebouncedTextField";
import { t } from "lib/i18n";
import {
  useChooseOrCreateRentableFeature,
  useChooseOrCreateRentableState,
} from "../ChooseOrCreateRentableProvider";

export const ChooseOrCreateRentableSearchInput = () => {
  const { searchValue } = useChooseOrCreateRentableState();
  const { updateSearchValue, updatePaginationStartIndex } =
    useChooseOrCreateRentableFeature();

  const handleSearch = (searchValue: string) => {
    updatePaginationStartIndex(0);
    updateSearchValue(searchValue);
  };

  return (
    <DebouncedTextField
      value={searchValue}
      onDebouncedValueChange={(searchValue) => handleSearch(searchValue)}
      placeholder={t("feature.choose_or_create_rentable.search.placeholder")}
      prependComponent={<IconSearch />}
    />
  );
};
