import { Box, Text } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { AdUtils } from "lib/ad";

export const Headline = ({ ad }: { ad: Ad }) => {
  const { rooms, category, size_m2, city, city_area, street_name } = ad;

  return (
    <Box
      p={2}
      pb={0}
      truncate
    >
      <Text
        weight="headline"
        size={{
          xs: "lead",
          md: "body",
        }}
      >
        {AdUtils.constructShortTitle(rooms, category, size_m2)}
      </Text>

      <Box />

      <Text
        size="small"
        color="muted"
      >
        {city_area || city}, {street_name}
      </Text>
    </Box>
  );
};
