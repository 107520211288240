import { Flex, Paginator } from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  filterCount: number;
  limit: number;
  startIndex: number;
  onChange: (value: number) => void;
};

export const ContractsListPaginator = ({
  filterCount,
  limit,
  startIndex,
  onChange,
}: Props) => (
  <Flex
    column
    align="center"
    my={4}
  >
    <Paginator
      totalRecords={filterCount}
      pageLimit={limit}
      value={startIndex}
      nextButtonLabel={t("paginator.next")}
      previousButtonLabel={t("paginator.previous")}
      onChange={onChange}
    />
  </Flex>
);
