import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  IconArrowBack,
  IconSearch,
  styled,
  Text,
} from "@boligportal/juice";
import { SuggestionItem } from "components/location_auto_complete/components/SuggestionItem";
import { SuggestionMap } from "components/location_auto_complete/components/SuggestionMap";
import { useLocationAutoCompleteState } from "components/location_auto_complete/hooks/useLocationAutoCompleteState";
import { LocationAutoCompleteOnSelect } from "components/location_auto_complete/types";
import { t } from "lib/i18n";

/**
 *
 * The MobileSearch Component can be used on smaller screens where we want to
 * use the entire screen while searching. (inspired by googles mobile search)
 *
 * @param displayTriggerAsIcon : boolean
 *
 * displayTriggerAsIcon determines if this component uses an Icon as the trigger
 * for entering the Mobile Search Overlay.
 *
 * If displayTriggerAsIcon is false, the <input /> field itself will act as the
 * trigger for entering the Mobile Search Overlay "on focus".
 *
 */

interface MobileSearchProps {
  displayTriggerAsIcon: boolean;
  onSelect: LocationAutoCompleteOnSelect;
  latestSearches?: React.ReactNode;
  withSuggestionMap?: boolean;
}

const MobileSearch = ({
  displayTriggerAsIcon,
  onSelect,
  latestSearches,
  withSuggestionMap = false,
}: MobileSearchProps) => {
  const [showMobileSearchOverlay, setShowMobileSearchOverlay] = useState(false);

  const handleClose = () => {
    setShowMobileSearchOverlay(false);
    window.scrollTo({
      top: 0,
    });
  };

  const handleSearchTrigger = () => {
    setShowMobileSearchOverlay(true);
  };

  return showMobileSearchOverlay ? (
    <SearchOverlay
      withSuggestionMap={withSuggestionMap}
      onClose={handleClose}
      onSelect={onSelect}
      latestSearches={latestSearches}
    />
  ) : (
    <SearchOverlayTrigger
      displayTriggerAsIcon={displayTriggerAsIcon}
      onTrigger={handleSearchTrigger}
    />
  );
};

export { MobileSearch };

// ===========================================================================

interface SearchOverlayTriggerProps {
  displayTriggerAsIcon: boolean;
  onTrigger: () => void;
}

const StyledMobileSearchInput = styled.input<{
  frontPage?: boolean;
  full?: boolean;
}>`
  -webkit-appearance: none;
  border: 1px solid ${(props) => props.theme.colorPalette.gray[500]};
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding: 0.375em 0.75em;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  padding: ${(props) => props.theme.unit(2.5)} 0;

  &::placeholder {
    color: ${(props) => props.theme.colorPalette.gray[500]};
    font-weight: ${(props) => props.theme.fontWeight.base};
    font-size: 1rem;
  }
  ${(props) =>
    props.frontPage &&
    `
      border: none;
      padding: 1.25em 1.25em;
      &::placeholder {
        color: ${props.theme.colorPalette.gray[500]};
        font-weight: ${props.theme.fontWeight.base};
        font-size: 1rem;
      }
    `}
  ${(props) =>
    props.full &&
    `
    border: none;
    `}
`;

const StyledMobileSearch = styled.div`
  height: 100vh;
  padding-bottom: 140px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
`;

/**
 * SearchOverlayTrigger is the part of the Mobile Search Feature that
 * renders the UI that triggers the Overlay.
 */

const SearchOverlayTrigger = ({
  displayTriggerAsIcon,
  onTrigger,
}: SearchOverlayTriggerProps) => {
  const handleInputFocus = () => {
    onTrigger();
  };

  const handleIconButtonClick = () => {
    onTrigger();
  };

  return displayTriggerAsIcon ? (
    <Flex
      justify="center"
      align="center"
    >
      <Button
        variant="subtle"
        aria-label="search"
        icon={IconSearch}
        onClick={handleIconButtonClick}
      />
    </Flex>
  ) : (
    <StyledMobileSearchInput
      frontPage
      type="text"
      placeholder={t("search_field.placeholder")}
      onFocus={handleInputFocus}
    />
  );
};

// ===========================================================================

interface SearchOverlayProps {
  onClose: () => void;
  onSelect: LocationAutoCompleteOnSelect;
  latestSearches?: React.ReactNode;
  withSuggestionMap?: boolean;
}

/**
 * SearchOverlay is the part of the Mobile Search Feature that
 * renders the Search Results in a View that covers the entire Screen.
 */
const SearchOverlay = ({
  onClose,
  onSelect,
  latestSearches,
  withSuggestionMap = false,
}: SearchOverlayProps) => {
  const { suggestions, inputProps } = useLocationAutoCompleteState(onSelect);

  const { ref: inputRef } = inputProps;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleTouchStart = () => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current === document.activeElement
    ) {
      inputRef.current.blur();
    }
  };

  const handleClose = () => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current === document.activeElement
    ) {
      inputRef.current.blur();
    }
    onClose();
  };

  return (
    <StyledMobileSearch onTouchStart={handleTouchStart}>
      <Flex
        shadow="md"
        align="center"
      >
        <Button
          variant="subtle"
          icon={IconArrowBack}
          onClick={handleClose}
          title={t("search_view.Back")}
        />
        <StyledMobileSearchInput
          full
          type="text"
          spellCheck={false}
          autoComplete="off"
          autoFocus
          placeholder={t("search_field.placeholder")}
          {...inputProps}
        />
      </Flex>

      {withSuggestionMap && <SuggestionMap />}
      {suggestions.length === 0 ? (
        latestSearches
      ) : (
        <>
          <Text
            color="muted"
            m={2}
            block
          >
            {t("Choose a place to live")}
          </Text>
          <StyledSuggenstionsList>
            {suggestions?.map((suggestion) => (
              <SuggestionItem
                hasSelection={false}
                key={suggestion.id}
                suggestion={suggestion}
                onSelect={onSelect}
              />
            ))}
          </StyledSuggenstionsList>
        </>
      )}
    </StyledMobileSearch>
  );
};

const StyledSuggenstionsList = styled.ul`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.popover};
  background: ${(props) => props.theme.color.bg.base};
  width: 100%;
  list-style: none;
  padding: 0;
  color: ${(props) => props.theme.color.text.base};
`;
