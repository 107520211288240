import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  IconTrash,
  NumberField,
  TextField,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { PartialPaymentForm } from "./AddTenantPaymentDialog";

type Props = {
  index: number;
  register: UseFormRegister<PartialPaymentForm>;
  errors: FieldErrors<PartialPaymentForm>;
  control: Control<PartialPaymentForm>;
  remove: (index: number) => void;
};

export const AddTenantPaymentDialogForm = ({
  index,
  register,
  errors,
  control,
  remove,
}: Props) => (
  <Flex
    justify="space-between"
    gap
    mb={1.5}
  >
    <Box
      minWidth={{
        xs: "150px",
        sm: "300px",
      }}
    >
      <TextField
        type="date"
        {...(index === 0 && {
          label: t("partial_payments.payment_date"),
          required: true,
        })}
        mb={0}
        {...register(`partial_payments.${index}.accounting_date` as const, {
          setValueAs: (value) => (value?.length === 0 ? null : value),
        })}
        errorText={errors?.partial_payments?.[index]?.accounting_date?.message}
      />
    </Box>
    <Controller
      control={control}
      name={`partial_payments.${index}.amount` as const}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value, ...rest } }) => (
        <NumberField
          {...(index === 0 && {
            label: t("common.table.field_name.amount"),
            required: true,
          })}
          mb={0}
          autoFocus
          append="kr."
          thousandSeparator="."
          decimalSeparator=","
          value={value as any}
          {...rest}
          onValueChange={(value) => {
            onChange(value.floatValue ?? null);
          }}
          defaultValue={"0"}
          errorText={errors?.partial_payments?.[index]?.amount?.message}
        />
      )}
    />
    <Flex
      align={
        errors?.partial_payments?.[index] && index === 0
          ? "center"
          : index !== 0
            ? "baseline"
            : "end"
      }
    >
      <Button
        icon={IconTrash}
        onClick={() => remove(index)}
      />
    </Flex>
  </Flex>
);
