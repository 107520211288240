import { useEffect } from "react";
import { Box, Button, Divider, Text } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { Filters } from "components/interfaces/filters";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { TrackingEvent } from "lib/tracking/events";
import { capitalize } from "lodash-es";

type Props = {
  ad: Ad;
  onCreated: () => void;
};

export const AgentSetup = ({ ad, onCreated }: Props) => {
  useEffect(() => {
    Tracking.trackVirtualPageview(
      "/fill-profile-modal/sign-up-agent",
      "Ask user to sign up for agent",
    );
  }, []);

  const filters = {
    rooms: {
      gte: ad.rooms,
    },
    categories: {
      values: [ad.category],
    },
    max_monthly_rent: Math.round(ad.monthly_rent * 1.2),
    city_level_1: {
      values: [ad.city_level_1],
    },
    city_level_2: {
      values: [ad.city_level_2],
    },
    city_level_3: {
      values: [ad.city_level_3],
    },
  } as Filters;

  const activateAgent = () => {
    API.createAgent(filters).then(() => {
      TrackingEvent.userCreatedAnAgentInProfileFlow();
      onCreated();
    });
  };

  const city =
    filters.city_level_1 && filters.city_level_1.values
      ? capitalize(filters.city_level_1.values[0]) + ", "
      : "";

  const minRooms = filters.rooms && filters.rooms.gte;
  const rooms =
    minRooms && minRooms > 1
      ? t("Min %{minRooms} rooms", {
          minRooms,
        })
      : t("Min %{minRooms} room", {
          minRooms,
        });

  const rent = t("Max %{maxRent} %{currency}", {
    maxRent: filters.max_monthly_rent,
    currency: ad.monthly_rent_currency,
  });

  return (
    <Box>
      <Text
        block
        markdown
        mx={1}
      >
        {t("profile_completion.agent.text")}
      </Text>

      <Box
        border
        p={2}
        mt={2}
        bg="tint"
        borderRadius="sm"
      >
        <Text
          block
          color="muted"
          size="small"
          mb={0.5}
        >
          {t("profile_completion.agent.search")}
        </Text>
        <Text>{`${city}${rooms}, ${rent}`}</Text>
      </Box>

      <Divider
        mx={{
          xs: -2,
          md: -3,
        }}
        my={2}
      />

      <Box mx={1}>
        <Button
          variant="branded"
          onClick={activateAgent}
          fullWidth
        >
          {t("profile_completion.agent.button")}
        </Button>
      </Box>
    </Box>
  );
};
