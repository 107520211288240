import * as React from "react";

const SvgApplePay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={29}
    fill="none"
    viewBox="0 0 44 29"
    {...props}
  >
    <path
      fill="#000"
      d="M40.06 0H3.49c-.128.002-.254.003-.381.007a5.525 5.525 0 00-.828.072c-.277.05-.535.132-.786.26A2.64 2.64 0 00.338 1.495c-.128.252-.209.51-.259.787a5.597 5.597 0 00-.073.827 16.05 16.05 0 00-.005.38L0 3.941V24.68l.006.38c.008.275.024.554.073.827.05.277.131.535.26.786a2.632 2.632 0 001.156 1.157c.251.128.51.21.786.26.273.048.552.065.828.072.127.003.253.005.38.005l.451.001h36.57l.381-.006a5.49 5.49 0 00.828-.073c.276-.05.534-.13.786-.26a2.63 2.63 0 001.156-1.156c.129-.251.21-.51.26-.787a5.44 5.44 0 00.072-.826l.006-.38.001-.452V3.941l-.001-.452c0-.127-.002-.253-.006-.38a5.493 5.493 0 00-.073-.827 2.765 2.765 0 00-.259-.787A2.65 2.65 0 0041.72.08a5.54 5.54 0 00-.828-.072 18.974 18.974 0 00-.38-.006L40.06 0z"
    />
    <path
      fill="#fff"
      d="M40.06.939h.444l.362.006c.21.006.457.017.686.058.2.036.367.091.528.173a1.701 1.701 0 01.745.746c.081.16.136.326.171.527.042.227.053.474.059.686a59.789 59.789 0 01.007.806v20.287c0 .148 0 .296-.002.447 0 .12-.002.24-.005.36a4.648 4.648 0 01-.06.687 1.82 1.82 0 01-.17.525 1.698 1.698 0 01-.747.746c-.16.082-.326.136-.524.172-.234.042-.49.053-.684.058-.122.003-.243.005-.367.005l-.443.001H3.493c-.12-.001-.242-.003-.359-.006a4.776 4.776 0 01-.686-.058 1.82 1.82 0 01-.528-.173 1.685 1.685 0 01-.745-.745 1.843 1.843 0 01-.172-.527 4.646 4.646 0 01-.058-.686c-.003-.12-.005-.24-.005-.36l-.001-.353V3.495l.006-.36c.006-.21.017-.457.059-.688a1.84 1.84 0 01.172-.527 1.694 1.694 0 01.745-.745 1.85 1.85 0 01.526-.171c.23-.042.476-.053.688-.059.12-.003.24-.004.36-.005L3.94.939h36.12z"
    />
    <path
      fill="#000"
      d="M12.012 9.474c.376-.471.632-1.104.565-1.75-.552.027-1.225.364-1.614.835-.35.404-.66 1.063-.58 1.683.62.053 1.238-.31 1.629-.768zm.558.889c-.9-.054-1.664.51-2.093.51-.43 0-1.087-.483-1.798-.47-.926.013-1.785.537-2.254 1.369-.966 1.665-.255 4.135.684 5.49.456.672 1.006 1.41 1.73 1.384.685-.027.953-.443 1.785-.443s1.073.443 1.798.43c.751-.014 1.221-.672 1.677-1.343.524-.765.738-1.504.752-1.544-.014-.014-1.45-.564-1.463-2.215-.013-1.383 1.127-2.04 1.181-2.081-.644-.953-1.65-1.06-2-1.087zm7.828-1.871c1.954 0 3.315 1.347 3.315 3.308 0 1.968-1.389 3.322-3.364 3.322h-2.164v3.44h-1.563V8.492h3.776zm-2.213 5.317h1.794c1.361 0 2.136-.732 2.136-2.002 0-1.27-.775-1.996-2.129-1.996h-1.8v3.998zm5.936 2.667c0-1.285.984-2.073 2.73-2.17l2.009-.12v-.565c0-.816-.551-1.305-1.472-1.305-.873 0-1.418.419-1.55 1.075h-1.424c.084-1.326 1.215-2.303 3.03-2.303 1.779 0 2.916.942 2.916 2.414v5.06h-1.444v-1.207h-.035c-.426.816-1.354 1.333-2.317 1.333-1.438 0-2.443-.893-2.443-2.212zm4.739-.663v-.58l-1.808.112c-.9.063-1.41.46-1.41 1.089 0 .642.531 1.06 1.34 1.06 1.054 0 1.878-.725 1.878-1.681zm2.864 5.45v-1.221c.112.028.363.028.489.028.698 0 1.074-.293 1.305-1.047 0-.014.132-.447.132-.454l-2.652-7.349h1.633l1.857 5.974h.028l1.856-5.974h1.591l-2.75 7.726c-.627 1.78-1.353 2.352-2.875 2.352a5.82 5.82 0 01-.614-.035z"
    />
  </svg>
);
export default SvgApplePay;
