import {
  Box,
  CheckField,
  Flex,
  IconArrowForward,
  styled,
  Text,
} from "@boligportal/juice";
import { formatCurrency } from "apps/contracts/components/common/money_format";
import { format } from "date-fns";
import { getDateFnsLocale } from "lib/date";
import { BeforeAfterItemField, BeforeAfterItemType } from "../types";

type Props<T extends BeforeAfterItemType> = {
  item: T;
  onChange: (item: T, isChecked: boolean) => void;
};

export const BeforeAfterItem = <T extends BeforeAfterItemType>({
  item,
  onChange,
}: Props<T>) => (
  <CheckField
    label={
      <Flex
        align="center"
        gap={0.5}
        wrap
      >
        <Text pr={0.5}>{item.label}:</Text>
        {item.before && (
          <>
            <RedBeforeText>
              {formatFieldValue(item.field, item.before)}
            </RedBeforeText>
            <Box>
              <IconArrowForward />
            </Box>
          </>
        )}
        <GreenAfterText>
          {formatFieldValue(item.field, item.after)}
        </GreenAfterText>
      </Flex>
    }
    checked={item.isChecked}
    onChange={(event) => {
      onChange(item, event.currentTarget.checked);
    }}
  />
);

const formatFieldValue = (
  field: BeforeAfterItemField,
  value: string | number,
) => {
  if (["move_in_date", "move_out_date"].includes(field)) {
    return format(new Date(value), "dd.MM.yyyy", {
      locale: getDateFnsLocale(),
    });
  }

  if (field === "deposit" || field === "prepaid_rent") {
    return formatCurrency(value);
  }

  return value;
};

const RedBeforeText = styled(Text)`
  background: ${(props) => props.theme.colorPalette.red[50]};
  padding: 0 4px;
  text-decoration: line-through;
`;

const GreenAfterText = styled(Text)`
  background: ${(props) => props.theme.colorPalette.green[50]};
  padding: 0 4px;
`;
