import { Box, Flex, SortableColumn } from "@boligportal/juice";
import {
  createRentRecordPresenterItems,
  presenterToRecordMap,
  recordToPresenterMap,
  RentRecordPresenter,
} from "apps/rent/presentation";
import { RentRecord } from "apps/rent/types/RentRecord";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import {
  addressAndTenancyCellRenderer,
  rentAmountCellRenderer,
  rentPaymentInfoCellRenderer,
  rentPaymentStatusCellRenderer,
  rentPaymentDetailButtonCellRenderer,
  rentRecordElipsisMenuCellRenderer,
  rentDueDateCellRenderer,
} from "components/cells";
import {
  RentRecordElipsisMenuItem,
  rentRecordElipsisMobileMenuCellRenderer,
} from "components/cells/rentRecordElipsisMenuCellRenderer";
import { DataTableWithMobileDataList } from "components/mobile-data-list/DataTableWithMobileDataList";
import { t } from "lib/i18n";

type Props = {
  shouldDisplayAsMobileOptimized: boolean;
  records: RentRecord[];
  sortableColumn: SortableColumn<RentRecord>;
  onSortClick: (sortable: SortableColumn<RentRecord>) => void;
  onDisplayPaymentStatusChange: (
    record: RentRecord,
    newStatus: TenancyPaymentStatus,
  ) => void;
  onDisplayRecordDetail: (record: RentRecord) => void;
  onMenuItemSelect: (
    record: RentRecord,
    menuItem: RentRecordElipsisMenuItem,
  ) => void;
};

export const TenanciesRentRecordsTable = ({
  shouldDisplayAsMobileOptimized,
  records,
  sortableColumn,
  onSortClick,
  onDisplayPaymentStatusChange,
  onDisplayRecordDetail,
  onMenuItemSelect,
}: Props) => (
  <>
    <DataTableWithMobileDataList<RentRecordPresenter>
      shouldShowDataTable={() => !shouldDisplayAsMobileOptimized}
      items={createRentRecordPresenterItems({
        records,
        onDisplayRecordDetail: onDisplayRecordDetail,
        onDisplayPaymentStatusChange: onDisplayPaymentStatusChange,
        onMenuItemSelect: onMenuItemSelect,
      })}
      keyField="id"
      mobile={{
        primary: {
          rowRenderer: (item) => (
            <Flex
              justify="space-between"
              align="center"
            >
              <Box>{addressAndTenancyCellRenderer(item)}</Box>
              <Box>{rentRecordElipsisMobileMenuCellRenderer(item)}</Box>
            </Flex>
          ),
        },
        secondaries: [
          {
            fieldLabel: t("common.table.field_name.date"),
            fieldValueFunction: rentDueDateCellRenderer,
          },
          {
            fieldLabel: t("common.table.field_name.amount"),
            fieldValueFunction: rentAmountCellRenderer,
          },
          {
            fieldLabel: t("common.table.field_name.status"),
            fieldValueFunction: rentPaymentStatusCellRenderer,
          },
          {
            fieldLabel: t("common.table.field_name.payment_type"),
            fieldValueFunction: rentPaymentInfoCellRenderer,
          },
        ],
      }}
      desktop={{
        sortableColumn: {
          column: recordToPresenterMap[sortableColumn.column],
          descending: sortableColumn.descending,
        },
        onSortClick: (column, descending) => {
          onSortClick({
            column: presenterToRecordMap[column],
            descending,
          });
        },
        columns: [
          {
            sortable: false,
            fieldLabel: t("rent.data_table.column.tenancy"),
            fieldName: "addressAndTenancyCell",
            cellRenderer: addressAndTenancyCellRenderer,
          },
          {
            sortable: true,
            fieldLabel: t("common.table.field_name.date"),
            fieldName: "rentDueDateCell",
            cellRenderer: rentDueDateCellRenderer,
          },
          {
            sortable: true,
            alignRight: true,
            fieldLabel: t("common.table.field_name.amount"),
            fieldName: "rentAmountCell",
            cellRenderer: rentAmountCellRenderer,
          },
          {
            sortable: true,
            fieldLabel: t("common.table.field_name.status"),
            fieldName: "rentPaymentStatusCell",
            cellRenderer: rentPaymentStatusCellRenderer,
          },
          {
            sortable: false,
            fieldLabel: t("common.table.field_name.payment_type"),
            fieldName: "rentPaymentInfoCell",
            cellRenderer: rentPaymentInfoCellRenderer,
          },
          {
            alignRight: true,
            fieldLabel: "",
            fieldName: "rentPaymentDetailButtonCell",
            cellRenderer: rentPaymentDetailButtonCellRenderer,
          },
          {
            fieldLabel: "",
            fieldName: "rentRecordElipsisMenuCell",
            alignRight: true,
            cellRenderer: rentRecordElipsisMenuCellRenderer,
          },
        ],
      }}
    />
  </>
);
