import { useEffect, useState } from "react";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { LoginSignupContextTranslation } from "components/modals/auth_modal";
import { API } from "lib/api";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { LoginWithEmail } from "./LoginWithEmail";
import { SignUpWithEmail } from "./SignUpWithEmail";

export type PaywallSignupOrLoginProps = {
  authUsecase: SignupContext;
  selectedPlanId: number;
  onAuthenticated: () => void;
  onAuthenticatedAndHasPremiumAccess: () => void;
  onAuthenticatedAndAlreadyUsedIntroPlan: () => void;
};

export const PaywallSignupOrLogin = ({
  authUsecase,
  selectedPlanId,
  onAuthenticated,
  onAuthenticatedAndHasPremiumAccess,
  onAuthenticatedAndAlreadyUsedIntroPlan,
}: PaywallSignupOrLoginProps) => {
  const [view, setView] = useState<"signup" | "login">("signup");
  const { replaceUser } = useAppActions();

  const handleLoginSuccessful = async () => {
    const newUser = await API.getAuthUser();
    const newPlans = await API.getSeekerPlans();

    replaceUser(newUser);

    if (newUser.has_premium_seeker_access) {
      onAuthenticatedAndHasPremiumAccess();
    } else if (
      hasUsedIntro(
        selectedPlanId,
        newPlans.map((plan) => plan.plan_id),
      )
    ) {
      onAuthenticatedAndAlreadyUsedIntroPlan();
    } else {
      onAuthenticated();
    }
  };

  useEffect(() => {
    if (view === "login") {
      Tracking.trackVirtualPageview(
        "/login",
        `${t("login_view.title")} ${
          LoginSignupContextTranslation()[authUsecase]
        }`,
      );
    } else if (view === "signup") {
      Tracking.trackVirtualPageview(
        "/signup-with-email",
        `${t("signup_view.title")} ${
          LoginSignupContextTranslation()[authUsecase]
        }`,
      );
    }
  }, [view, authUsecase]);

  if (view === "login") {
    return (
      <LoginWithEmail
        title={`${t("login_view.title")} ${
          LoginSignupContextTranslation()[authUsecase]
        }`}
        login={API.login}
        onLoginSuccessful={handleLoginSuccessful}
        onSwitchToSignUp={() => setView("signup")}
      />
    );
  }

  if (view === "signup") {
    return (
      <SignUpWithEmail
        title={`${t("signup_view.title")} ${
          LoginSignupContextTranslation()[authUsecase]
        }`}
        signup={API.signUp}
        onSignupSuccessful={handleLoginSuccessful}
        onSwitchToLogin={() => setView("login")}
      />
    );
  }

  return null;
};

/**
 * Determines if the user has already used the intro based on the selected plan ID.
 *
 * If the selected plan ID (while logged out) is not found in the plan IDs (when logged in),
 * then the user has already used the intro.
 *
 * @param selectedPlanId - The plan ID selected by the user while logged out.
 * @param updatedPlanIdsAfterLogin - The plan IDs associated with the authenticated user.
 * @returns True if the user has already used the intro, false otherwise.
 */
const hasUsedIntro = (
  selectedPlanId: number,
  updatedPlanIdsAfterLogin: number[],
): boolean => !updatedPlanIdsAfterLogin.includes(selectedPlanId);
