import { useState } from "react";
import { styled, IconArrowBackForward, useMedia } from "@boligportal/juice";
import { Image } from "apps/moving_reports/interfaces/image";
import { t } from "lib/i18n";

const StyledPreview = styled.div<{ src: string }>`
  position: absolute;
  top: -${(props) => props.theme.unit(31)};
  left: ${(props) => props.theme.unit(4)};
  width: ${(props) => props.theme.unit(33)};
  height: ${(props) => props.theme.unit(33)};
  padding: ${(props) => props.theme.unit(1)};
  background-image: url("${(props) => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  box-shadow: ${(props) => props.theme.shadow.lg};
  z-index: ${(props) => props.theme.zIndex.popover};
`;

const StyledCounter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.tiny};
`;

const StyledIcon = styled(IconArrowBackForward)`
  top: 25%;
  left: 25%;
  transform: rotate(-45deg);
`;

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: ${(props) => props.theme.borderRadius.sm};
`;

const ImageBoxSingle = styled.div<{ src: string }>`
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 4px;
  background-image: url("${(props) => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(250, 250, 250);
  cursor: pointer;
`;

const StyledNoImage = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 4px;
`;

/* eslint-disable react/no-unknown-property */
const SvgNoImage = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{
      position: "absolute",
      top: "14px",
      left: "15px",
    }}
  >
    <path
      d="M0.833344 0.833328L19.1667 19.1667"
      stroke="#A9A9A9"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.50001 2.5H12.5L14.1667 5H17.5C17.942 5 18.366 5.17559 18.6785 5.48816C18.9911 5.80072 19.1667 6.22464 19.1667 6.66667V14.45M12.7333 12.7333C12.4568 13.1375 12.0945 13.4758 11.6723 13.7241C11.2502 13.9725 10.7785 14.1247 10.2908 14.17C9.80312 14.2153 9.31145 14.1526 8.85076 13.9863C8.39007 13.8201 7.97168 13.5543 7.62536 13.208C7.27904 12.8617 7.01328 12.4433 6.84701 11.9826C6.68074 11.5219 6.61803 11.0302 6.66335 10.5425C6.70866 10.0549 6.86089 9.58317 7.1092 9.16101C7.35751 8.73884 7.6958 8.37658 8.10001 8.1M17.5 17.5H2.50001C2.05798 17.5 1.63406 17.3244 1.3215 17.0118C1.00894 16.6993 0.833344 16.2754 0.833344 15.8333V6.66667C0.833344 6.22464 1.00894 5.80072 1.3215 5.48816C1.63406 5.17559 2.05798 5 2.50001 5H5.00001L17.5 17.5Z"
      stroke="#A9A9A9"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const SingleImage = ({
  images,
  onPreview,
}: {
  images: Image[];
  onPreview: (id: number) => void;
}) => {
  const media = useMedia(0);
  const isMobile = media.sm.down;
  const [isHover, setIsHover] = useState<boolean>(false);

  const imagesCount = images.length;

  const handleClick = () => {
    onPreview(0);
    setIsHover(false);
  };

  return imagesCount === 0 ? (
    <StyledNoImage>
      <SvgNoImage />
    </StyledNoImage>
  ) : (
    <ImageBoxSingle
      src={images[0].url}
      onClick={() => handleClick()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && !isMobile && (
        <>
          <StyledOverlay>
            <StyledIcon
              size="large"
              color="inverted"
            />
          </StyledOverlay>
          <StyledPreview src={images[0].url}>
            {imagesCount > 1 && (
              <StyledCounter>
                {t("image_list.more", {
                  count: imagesCount - 1,
                })}
              </StyledCounter>
            )}
          </StyledPreview>
        </>
      )}
    </ImageBoxSingle>
  );
};
