import { useState } from "react";

export const usePagination = <T extends any>(initialLimit = 25) => {
  const [items, setItems] = useState<T[]>([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(initialLimit);
  const [offset, setOffset] = useState(0);

  return {
    items,
    setItems,
    count,
    setCount,
    limit,
    setLimit,
    offset,
    setOffset,
  };
};
