export const formatAddressWithExtraText = (
  address: string,
  extraText: string | null,
) => {
  let text = address;

  if (extraText) {
    text += `, ${extraText}`;
  }

  return text;
};
