import { useEffect, useState, useRef } from "react";
import {
  Box,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  Flex,
  Text,
  Button,
  CheckField,
  TextField,
  SelectField,
  Spinner,
  IconArrowBack,
} from "@boligportal/juice";
import { API, StandardMessages, InTouchTenant } from "lib/api";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";

const REJECTION_MESSAGE_MIN_LENGTH = 30;

type Props = {
  recipientCount: number;
  selectedTenant: undefined | InTouchTenant;
  rejectionMessage: string;
  sendRejection: boolean;
  onBack: () => void;
  onContinue: () => void;
  onMessageChange: (message: string) => void;
  onSendRejectionChange: (value: boolean) => void;
};

const ChooseTenantRejection = ({
  recipientCount,
  rejectionMessage,
  selectedTenant,
  sendRejection,
  onBack,
  onContinue,
  onMessageChange,
  onSendRejectionChange,
}: Props) => {
  const [standardMessages, setStandardMessages] = useState<StandardMessages>();
  const [showError, setShowError] = useState(false);

  const messageRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    Tracking.trackVirtualPageview("/mark-as-rented-out/rejection-message");

    (async function fetchTenants() {
      const response = await API.getStandardMessages();
      setStandardMessages(response);
    })();
  }, []);

  if (!standardMessages) {
    return <Spinner />;
  }

  const handleChange = (event) => {
    const { value } = event.target;

    onMessageChange(value);

    if (value.length >= REJECTION_MESSAGE_MIN_LENGTH) {
      setShowError(false);
    }
  };

  const handleContinue = () => {
    if (
      sendRejection &&
      rejectionMessage.length < REJECTION_MESSAGE_MIN_LENGTH
    ) {
      setShowError(true);
      return;
    }

    onContinue();
  };

  const canContinue = !sendRejection || Boolean(rejectionMessage.length);

  return (
    <>
      <DialogHeader>
        {t("rentable_details.choose_tenant.send_rejection")}
      </DialogHeader>

      <DialogContent>
        <Text paragraph>
          {t("rentable_details.choose_tenant.send_rejection_body")}
        </Text>

        <Text block>
          -{" "}
          {t(
            "rentable_details.choose_tenant.rejection_message_recipient_count",
            {
              count: recipientCount,
            },
          )}
        </Text>
        {selectedTenant && (
          <Text block>
            -{" "}
            {t(
              "rentable_details.choose_tenant.rejection_message_not_for_selected_tenant",
              {
                name: selectedTenant.display_name,
              },
            )}
          </Text>
        )}

        <Divider />

        <Box mb={3}>
          <CheckField
            checked={sendRejection}
            onChange={(event) => onSendRejectionChange(event.target.checked)}
            label={t(
              "rentable_details.choose_tenant.send_rejection_message_to_tenants",
            )}
          />
        </Box>

        <SelectField
          label={t(
            "rentable_details.choose_tenant.choose_from_standard_messages",
          )}
          disabled={!sendRejection}
          value=""
          items={[
            {
              label: t(
                "rentable_details.choose_tenant.choose_standard_message",
              ),
              value: "",
              disabled: true,
            },
            ...standardMessages.messages.map((message) => ({
              label: message.title,
              value: message.body,
            })),
          ]}
          onChange={(event) => {
            handleChange(event);
            messageRef.current?.focus();
          }}
        />

        <TextField
          ref={messageRef}
          multiLine={15}
          disabled={!sendRejection}
          placeholder={t(
            "rentable_details.choose_tenant.standard_message_placeholder",
          )}
          value={rejectionMessage}
          helpText={t(
            "rentable_details.choose_tenant.standard_message_help_error_text",
            {
              minLength: REJECTION_MESSAGE_MIN_LENGTH,
            },
          )}
          errorText={
            showError
              ? t(
                  "rentable_details.choose_tenant.standard_message_help_error_text",
                  {
                    minLength: REJECTION_MESSAGE_MIN_LENGTH,
                  },
                )
              : undefined
          }
          onChange={handleChange}
        />
      </DialogContent>

      <DialogFooter>
        <Flex
          flexGrow
          justify="space-between"
        >
          <Button
            icon={IconArrowBack}
            variant="subtle"
            onClick={onBack}
          >
            {t("rentable_details.choose_tenant.back")}
          </Button>

          <Button
            variant="primary"
            onClick={handleContinue}
            disabled={!canContinue}
          >
            {t("rentable_details.choose_tenant.continue")}
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};

export { ChooseTenantRejection };
