import { forwardRef } from "react";
import {
  Menu,
  styled,
  IconChevronDown,
  IconChevronUp,
} from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { HeaderAction, TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { MenuItem, ItemProps } from "../top_nav";

const StyledOpener = styled.div<{ inverseColor: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: inherit;
  color: ${(props) =>
    props.inverseColor
      ? props.theme.color.bg.base
      : props.theme.color.text.base};
  line-height: ${(props) => props.theme.unit(3.5)};
  cursor: pointer;

  & > span {
    margin-left: ${(props) => props.theme.unit(0.5)};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Opener = forwardRef<
  HTMLDivElement,
  {
    inverseColor: boolean;
    isOpen: boolean;
    title: string;
    tracking: () => void;
  }
>((props, ref) => {
  const { inverseColor, isOpen, title, tracking, ...openerProps } = props;

  return (
    <StyledOpener
      ref={ref}
      inverseColor={inverseColor}
      onClick={tracking}
      {...openerProps}
    >
      {title}
      {isOpen ? <IconChevronUp /> : <IconChevronDown />}
    </StyledOpener>
  );
});

const getItems = (market: string) => {
  const {
    HOUSING_ADMINISTRATION,
    RENT,
    MOVING_REPORTS,
    RENTAL_CONTRACTS,
    CREATE_LISTING_ADMIN_MENU,
    RENTAL_DATA,
  } = menuTranslations();

  const items = [
    {
      name: CREATE_LISTING_ADMIN_MENU,
      url: App.settings.routes.renting_out_landing_page,
      trackingAction: HeaderAction.RENTAL_COLLECTION,
    },
    {
      name: RENTAL_CONTRACTS,
      url: App.settings.routes.contracts_lease_landingpage,
      trackingAction: HeaderAction.CONTRACTS,
    },
    {
      name: MOVING_REPORTS,
      url: App.settings.routes.moving_report_landingpage,
      trackingAction: HeaderAction.MOVING_REPORTS,
    },
  ];

  if (market === Market.BOLIGPORTAL_DK) {
    items.unshift({
      name: HOUSING_ADMINISTRATION,
      url: App.settings.routes.housing_administration_landingpage,
      trackingAction: HeaderAction.BOLIGADMINISTRATION,
    });

    items.push(
      {
        name: RENT,
        url: App.settings.routes.rent_collection_landingpage,
        trackingAction: HeaderAction.RENT_OUT,
      },
      {
        name: RENTAL_DATA,
        url: App.settings.routes.rental_data_landingpage,
        trackingAction: HeaderAction.RENTAL_DATA,
      },
    );
  }

  return items;
};

export const TopNavItemRentableAdminMenu = ({
  inverseColor,
  onItemClick,
}: ItemProps) => {
  const items = getItems(App.settings.market);

  const handleTracking = () => {
    TrackingEvent.clickNavigationBar(HeaderAction.PRODUCTS);
  };

  return (
    <Menu<MenuItem>
      items={items}
      openerEl={(isOpen) => (
        <Opener
          inverseColor={inverseColor}
          isOpen={isOpen}
          tracking={handleTracking}
          title={t("menu.admin.dropdown")}
        />
      )}
      onMenuItemSelect={onItemClick}
      verticalContentPadding={1}
      verticalSeparatorPadding={1}
    />
  );
};
