import { fetchFromAPI } from "lib/api";

/* eslint-disable compat/compat */
const saveVideoService = async (
  adId: number,
  videoId: number,
): Promise<{ success: boolean; errorMessage: string | null }> => {
  const request = await fetchFromAPI(`/api/listing/${adId}/video/save`, {
    method: "POST",
    body: JSON.stringify({
      video_id: videoId,
    }),
  });

  // if everything went ok
  if (request.status === 200) {
    return Promise.resolve({
      success: true,
      errorMessage: null,
    });
  }
  // if you tried to save another video for the same ad?
  if (request.status === 400) {
    // TODO: Translation
    return Promise.resolve({
      success: false,
      errorMessage: "You tried to save another video for the same ad",
    });
  }

  // if the ad is not yours or you're not logged in
  if (request.status === 403) {
    // TODO: Translation
    return Promise.resolve({
      success: false,
      errorMessage: "ad is not yours or you're not logged in",
    });
  }

  return Promise.reject();
};

export { saveVideoService };
