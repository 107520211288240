/* eslint-disable compat/compat */
import { fetchFromAPI } from "lib/api";

const getVideoUploadLinkService = async (
  file: File,
): Promise<{ uploadUrl: string; videoId: number }> => {
  const request = await fetchFromAPI("/api/video/create-upload-link", {
    method: "POST",
    body: JSON.stringify({
      video_size: file.size,
    }),
  });

  const data = await request.json();

  if (data && data.upload_link && data.video_id) {
    const uploadUrl: string = String(data.upload_link);
    const videoId: number = Number(data.video_id);

    return Promise.resolve({
      uploadUrl,
      videoId,
    });
  }

  return Promise.reject();
};

export { getVideoUploadLinkService };
