import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { NumberField } from "@boligportal/juice";
import { t } from "lib/i18n";

export const MoveInPrice = () => {
  const { watch } = useFormContext();
  const [moveInPriceValue, setMoveInPriceValue] = useState<number>();

  const watchedRent = watch("monthly_rent");
  const watchedUtilities = watch("monthly_rent_extra_costs");
  const watchedDeposit = watch("deposit");
  const watchedPrepaidRent = watch("prepaid_rent");
  const watchedSocialHousing = watch("social_housing");

  useEffect(() => {
    const prepaidRent = watchedSocialHousing ? 0 : watchedPrepaidRent;
    const price = calcMoveInPrice(
      watchedRent,
      watchedDeposit,
      watchedUtilities,
      prepaidRent,
    );
    setMoveInPriceValue(price);
  }, [
    watchedRent,
    watchedDeposit,
    watchedUtilities,
    watchedPrepaidRent,
    watchedSocialHousing,
  ]);

  return (
    <NumberField
      readOnly
      isNumericString
      label={t("create.field.move_in_price.heading")}
      value={moveInPriceValue ? String(moveInPriceValue) : ""}
      decimalSeparator=","
      thousandSeparator="."
      append="kr."
      decimalScale={2}
    />
  );
};

const calcMoveInPrice = (
  rent: number,
  deposit: number,
  utilities: number,
  prepaidRent: number,
) => {
  const rentCosts = rent || 0;
  const depositCosts = deposit || 0;
  const utilitiesCosts = utilities || 0;
  const prepaidRentCosts = prepaidRent || 0;

  const total = rentCosts + depositCosts + utilitiesCosts + prepaidRentCosts;

  if (total === 0) {
    return undefined;
  }

  return total;
};
