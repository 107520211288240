import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  IconAdd,
  IconPerson,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

export type RentableTenanciesCell = {
  tenancies: {
    tenancyInfo: {
      displayLabel: string;
      href: string;
    } | null;
    onClick: () => void;
  };
};

export const RentableTenanciesCellRenderer = ({
  tenancies,
}: RentableTenanciesCell) => {
  if (!tenancies.tenancyInfo) {
    return (
      <Flex
        gap={0.5}
        align="center"
      >
        <IconAdd
          color="primary"
          size="medium"
        />
        <Box>
          <Button
            text
            onClick={tenancies.onClick}
          >
            {t("common.tenancy.create_tenancy")}
          </Button>
        </Box>
      </Flex>
    );
  }
  return (
    <RouterLink to={tenancies.tenancyInfo.href}>
      <Flex
        align="center"
        justify={{
          xs: "end",
          md: "start",
        }}
        gap
      >
        <IconPerson
          size="small"
          color="primary"
        />
        <Flex
          maxWidth={{
            xs: "160px",
            lg: "450px",
          }}
        >
          <Text
            weight="bold"
            size="small"
          >
            {tenancies.tenancyInfo.displayLabel}
          </Text>
        </Flex>
      </Flex>
    </RouterLink>
  );
};
