import * as React from "react";

const SvgFilter12Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    className="Icon"
    {...props}
  >
    <path
      fill="none"
      stroke="#262626"
      strokeLinecap="square"
      strokeWidth={1}
      d="M6 2.36h5m-10 0h1.36m0-1.36h1.82v2.73H2.36zm8.19 5H11M1 6h5.91m0-1.36h1.82v2.73H6.91zm-.91 5h5m-10 0h1.36m0-1.37h1.82V11H2.36z"
    />
  </svg>
);
export default SvgFilter12Px;
