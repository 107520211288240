import { Link } from "react-router-dom";
import { Box, Breadcrumbs, Container, CrumbLink } from "@boligportal/juice";
import { HorizontalOverflowContainer } from "components/layout/horisontal_overflow_container";

interface Props {
  breadcrumbs: { text: string; href: string }[];
  headlineComponent: JSX.Element;
  tabsComponent?: JSX.Element;
}

const LinkComponent = ({ to, text }: { to: string; text: string }) => (
  <CrumbLink
    as={Link}
    to={to}
  >
    {text}
  </CrumbLink>
);

export const PageHeaderLayout = ({
  breadcrumbs,
  headlineComponent,
  tabsComponent,
}: Props) => (
  <Box
    bg="base"
    borderBottom
  >
    <Container fluid>
      <Box pt={2}>
        <Breadcrumbs
          crumbs={breadcrumbs}
          linkComponent={LinkComponent}
        />
      </Box>
      <Box>{headlineComponent}</Box>
    </Container>
    {tabsComponent && (
      <HorizontalOverflowContainer>{tabsComponent}</HorizontalOverflowContainer>
    )}
  </Box>
);
