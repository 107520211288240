import { Controller } from "react-hook-form";
import { SelectField } from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const CategoryFormElement = <T extends any>(props: FormElementInterface<T>) => {
  const { save, isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
  );

  const marketSpecificCategoryOptions = createMarketSpecificOptions();

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel={props.fieldLabel}
    >
      <Controller
        name={props.fieldname}
        render={({ field: { onChange, value, ...rest } }) => (
          <SelectField
            label=""
            items={marketSpecificCategoryOptions}
            disabled={isSaving || props.isLocked}
            onChange={(event) => {
              onChange(event);
              save();
            }}
            previousValue={props.previousValue}
            value={props.defaultValue}
            {...rest}
          />
        )}
      />
    </LockableFormField>
  );
};

export { CategoryFormElement };

const createMarketSpecificOptions = () => {
  const categoryOptions = [
    {
      value: "rental_apartment",
      label: "Apartment",
    },
    {
      value: "rental_room",
      label: "Room",
    },
    {
      value: "rental_house",
      label: "House",
    },
  ];

  if (App.settings.market === Market.BOSTADSPORTAL_SE) {
    categoryOptions.push({
      value: "rental_cabin",
      label: "Cabin",
    });
  }

  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    categoryOptions.push({
      value: "rental_townhouse",
      label: "Townhouse",
    });
  }

  return categoryOptions;
};
