import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { AccountPaymentFlowCoordinator } from "features/paywall/coordinators/account/AccountPaymentFlowCoordinator";
import { ApplePayPaymentFlowCoordinator } from "features/paywall/coordinators/applepay/ApplePayPaymentFlowCoordinator";
import { GooglePayPaymentFlowCoordinator } from "features/paywall/coordinators/googlepay/GooglePayPaymentFlowCoordinator";
import { QuickpayPaymentFlowCoordinator } from "features/paywall/coordinators/quickpay/QuickpayPaymentFlowCoordinator";
import { Paywall } from "features/paywall/paywall";
import SvgApplePay from "media/svg/ApplePay";
import SvgGooglePay from "media/svg/Googlepay";
import SvgMastercard from "media/svg/Mastercard";
import SvgVisa from "media/svg/Visa";
import { createSeekerPlanItemsSE } from "./seekerPlanItems";
import { startSubscriptionTrackingSE } from "./startSubscriptionTracker";

export const paywall_se: Paywall = {
  creditCardIcons: {
    [PaymentMethodType.QuickPay]: [SvgVisa, SvgMastercard],
    [PaymentMethodType.ApplePay]: [SvgApplePay],
    [PaymentMethodType.GooglePay]: [SvgGooglePay],
  },
  seekerPaymentFlowCoordinators: {
    [PaymentMethodType.ApplePay]: ApplePayPaymentFlowCoordinator,
    [PaymentMethodType.QuickPay]: QuickpayPaymentFlowCoordinator,
    [PaymentMethodType.GooglePay]: GooglePayPaymentFlowCoordinator,
  },
  landlordPaymentFlowCoordinators: {
    [PaymentMethodType.Account]: AccountPaymentFlowCoordinator,
    [PaymentMethodType.QuickPay]: QuickpayPaymentFlowCoordinator,
  },
  startSubscriptionTracker: startSubscriptionTrackingSE,
  createSeekerPlanItems: createSeekerPlanItemsSE,
};
