import {
  Badge,
  Box,
  Flex,
  Headline,
  IconClose,
  NakedDialog,
  Text,
  styled,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { LandlordOnbordingItem } from "../types/LandlordOnbordingItem";
import { DialogContentItem } from "./DialogContentItem";

const Content = styled(Box)`
  background-color: ${({ theme }) => theme.colorPalette.primary[800]};
  background: linear-gradient(
    ${({ theme }) => theme.colorPalette.primary[800]},
    ${({ theme }) => theme.colorPalette.primary[900]}
  );
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding-top: ${({ theme }) => theme.unit(4)};
  padding-left: ${({ theme }) => theme.unit(4)};
  padding-right: ${({ theme }) => theme.unit(4)};
`;

const CloseButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.1em;
`;

const DescriptionText = styled(Text)`
  color: ${({ theme }) => theme.colorPalette.primary[50]};
`;

const HeadlineEqualLineheight = styled(Headline)`
  line-height: 1;
`;

type LandlordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  items: LandlordOnbordingItem[];
};

export const LandlordDialog = ({
  title,
  description,
  items,
  isOpen,
  onClose,
}: LandlordDialogProps) => (
  <NakedDialog
    open={isOpen}
    size="580px"
    onClose={onClose}
  >
    <Content>
      <Flex
        justify="space-between"
        align="center"
      >
        <Badge
          variant="outlined"
          state="success"
        >
          {t("landlord_onboarding.landlord_dialog.badge.text")}
        </Badge>

        <CloseButton
          onClick={onClose}
          tabIndex={-1}
        >
          <IconClose
            color="inverted"
            size="medium"
          />
        </CloseButton>
      </Flex>

      <HeadlineEqualLineheight
        as="h1"
        color="inverted"
      >
        {title}
      </HeadlineEqualLineheight>

      <DescriptionText>{description}</DescriptionText>

      <Box height={4} />

      {items.map((item, index) => (
        <DialogContentItem
          key={item.text}
          icon={item.icon}
          title={item.title}
          text={item.text}
          href={item.href}
          ctaButtonLabel={item.ctaButtonLabel}
          displayConnectingLineArrow={whenIndexIsLessThanItemWithCtaButton(
            items,
            index,
          )}
          onTrigger={item.onTrigger}
        />
      ))}
    </Content>
  </NakedDialog>
);

const whenIndexIsLessThanItemWithCtaButton = (
  items: LandlordOnbordingItem[],
  index: number,
) => {
  const itemWithCtaButton = items.find((item) => item.ctaButtonLabel);

  if (!itemWithCtaButton) {
    return true;
  }

  const indexOfItemWithCtaButton = items.indexOf(itemWithCtaButton);

  return index < indexOfItemWithCtaButton;
};
