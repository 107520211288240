import { useRef, useState, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  dialogActions,
  DialogContent,
  DialogFooter,
  Flex,
} from "@boligportal/juice";
import { GoBackToAutoAddressFormButton } from "features/address/common/components/GoBackToAutoAddressFormButton";
import { GotoManualAddressFormButton } from "features/address/common/components/GotoManualAddressFormButton";
import { ManualAddress } from "features/address/common/interfaces/ManualAddress";
import {
  AddressManualDenmark,
  AddressManualFormDenmark,
} from "features/address/dk/AddressManualDenmark";
import { AddressSuggestionDenmark } from "features/address/dk/AddressSuggestionDenmark";
import { transformAddressManualFormDenmarkToManualAddress } from "features/address/dk/helpers/transformAddressManualFormDenmarkToManualAddress";
import { t } from "lib/i18n";

interface RentalAddressDialogProps {
  onAutoAddressUpdate: (autoSuggestedAddress: string) => void;
  onManualAddressUpdate: (manualAddressInput: ManualAddress) => void;
  startInManualAddressForm?: boolean;
  address: AddressManualFormDenmark;
  formatted_address?: string;
}

export const CS_EditAddressDenmark = ({
  onAutoAddressUpdate,
  onManualAddressUpdate,
  startInManualAddressForm,
  address,
  formatted_address,
}: RentalAddressDialogProps) => {
  const newAddressSuggestion = useRef<string>();

  const [addressViewState, setAddressViewState] = useState<"manual" | "auto">(
    startInManualAddressForm ? "manual" : "auto",
  );

  const allFormMethods = useForm<AddressManualFormDenmark>({
    defaultValues: address,
  });
  const { handleSubmit } = allFormMethods;

  const onAutoAddressSubmit = () => {
    if (newAddressSuggestion.current !== undefined) {
      onAutoAddressUpdate(newAddressSuggestion.current);
    }
  };

  const onManualAddressSubmit = (form: AddressManualFormDenmark) => {
    const manualAddress =
      transformAddressManualFormDenmarkToManualAddress(form);
    onManualAddressUpdate(manualAddress);
  };

  return (
    <>
      <FormProvider {...allFormMethods}>
        <DialogContent>
          {addressViewState === "auto" && (
            <>
              <AddressSuggestionDenmark
                initialAddress={formatted_address}
                onAddress={(address) => {
                  newAddressSuggestion.current = address;
                }}
                onAddressCleared={() => {
                  newAddressSuggestion.current = undefined;
                }}
              />
              <GotoManualAddressFormButton
                hideHelpText
                onClick={() => setAddressViewState("manual")}
              />
            </>
          )}
          {addressViewState === "manual" && (
            <>
              <form
                onSubmit={handleSubmit(onManualAddressSubmit)}
                noValidate
              >
                <GoBackToAutoAddressFormButton
                  hideHelpText
                  onClick={() => setAddressViewState("auto")}
                />
                <AddressManualDenmark />
              </form>
            </>
          )}
        </DialogContent>
        <DialogFooter>
          <Flex gap>
            <CancelButton />
            {addressViewState === "manual" && (
              <Button
                variant="primary"
                onClick={handleSubmit(onManualAddressSubmit)}
              >
                {t("feature.address_form.save_button.text")}
              </Button>
            )}
            {addressViewState === "auto" && (
              <Button
                variant="primary"
                onClick={onAutoAddressSubmit}
              >
                {t("feature.address_form.save_button.text")}
              </Button>
            )}
          </Flex>
        </DialogFooter>
      </FormProvider>
    </>
  );
};

const CancelButton = () => {
  const actions = useContext(dialogActions);
  return (
    <Button
      variant="subtle"
      onClick={actions.hide}
    >
      {t("Cancel")}
    </Button>
  );
};
