import { http } from "@boligportal/juice";
import { captureException } from "@sentry/react";
import { ContractAPI } from "apps/contracts/components/common/ContractApi";
import { ElectricityAdditionalOptions } from "apps/contracts_signing_add_el_partner_cpr_page/types";
import { fetchFromAPI } from "lib/api";
import {
  ContractReminder,
  UpdateContractRemindersRequestPayload,
} from "../common/store/contract_status";
import { RentalContract } from "../types";
import { CreateManualContractResponsePayload } from "./interfaces/CreateManualContractResponsePayload";
import { GetContractByIdResponsePayload } from "./interfaces/GetContractByIdResponsePayload";
import { GetMigratedContractsRequestPayload } from "./interfaces/GetMigratedContractsRequestPayload";
import { ElectricityResponsibility } from "./store/enums";
import { ContractTenant_DK, Contract_DK } from "./store/types";

const dkBaseUrl = "/api/dk_contracts";

type TenantAndCPRPayload = {
  cpr?: string;
  partner?: ElectricityResponsibility | ElectricityAdditionalOptions;
};

export class API extends ContractAPI {
  static async getContract(
    contractId: number,
  ): Promise<GetContractByIdResponsePayload> {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/${contractId}/view/`,
      );

      if (!response.ok) {
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateContract(contractId: number, data: Partial<Contract_DK>) {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/${contractId}/update/`,
        {
          method: "PATCH",
          body: JSON.stringify(data),
        },
      );

      if (!response.ok) {
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createTenant(contractId: number) {
    try {
      const response = await fetchFromAPI(`${dkBaseUrl}/tenant/create/`, {
        method: "POST",
        body: JSON.stringify({
          contract_id: contractId,
        }),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getEnergyLabel(contractId: number) {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/${contractId}/energy_label/create/`,
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getSoilPollution(contractId: number) {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/${contractId}/soil_pollution/create/`,
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateTenants(data: ContractTenant_DK[]) {
    try {
      const response = await fetchFromAPI(`${dkBaseUrl}/tenants/update/`, {
        method: "PUT",
        body: JSON.stringify({
          tenants: data.map(({ id, ...tenant }) => ({
            id,
            tenant,
          })),
        }),
      });

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * For Danish Martket where tenancy is a thing, creating a contract
   * can take an optional tenancy id.
   */
  static async createContract(
    rentableId: number,
    tenancyId?: number,
  ): Promise<{
    contract_id: RentalContract["contract_id"];
  }> {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/create-or-duplicate/`,
        {
          method: "POST",
          body: JSON.stringify({
            rentable_id: rentableId,
            tenancy_id: tenancyId,
          }),
        },
      );

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async updateContractReminders(
    requestPayload: UpdateContractRemindersRequestPayload,
  ): Promise<{ notifications: Array<ContractReminder> }> {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/${requestPayload.contractId}/sms/`,
        {
          method: "POST",
          body: JSON.stringify({
            reminders: [
              {
                name: requestPayload.name,
                state: requestPayload.state,
              },
            ],
          }),
        },
      );
      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async getMigratedContracts(
    requestPayload: Partial<GetMigratedContractsRequestPayload>,
  ) {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/migrated_contracts/view/`,
        {
          method: "POST",
          body: JSON.stringify(requestPayload),
        },
      );

      if (!response.ok) {
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async createManualContract(
    rentableId: number | string,
  ): Promise<CreateManualContractResponsePayload> {
    try {
      const response = await fetchFromAPI(
        `${dkBaseUrl}/contract/manual-contract/`,
        {
          method: "POST",
          body: JSON.stringify({
            rentable_id: rentableId,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Something went wrong: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const updateContractTenancyId = (
  contractId: number,
  tenancyId: number | null,
) =>
  http.patch(
    `/dk_contracts/contract/${contractId}/set_tenancy_finalized_contract/`,
    {
      tenancy_id: tenancyId,
    },
  );

export const updateTenantCPR = (taskId: string, cpr: string) => {
  try {
    return http.patch(`/dk_contracts/add_cpr/${taskId}/`, { cpr });
  } catch (error) {
    captureException(error, {
      level: "error",
    });

    return Promise.reject(error);
  }
};

export const updateTenantPartnerAndCPR = (
  taskId: string,
  payload: TenantAndCPRPayload,
) => {
  try {
    return http.patch(`/dk_contracts/add_partner_and_cpr/${taskId}/`, {
      cpr: payload.cpr || null,
      partner: payload.partner || null,
    });
  } catch (error) {
    captureException(error, {
      level: "error",
    });

    return Promise.reject(error);
  }
};
