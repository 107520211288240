import { useState, useContext, useRef, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CheckField,
  Confirm,
  Dialog,
  dialogActions,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Divider,
  Flex,
  IconAlertCircleOutline,
  Labelled,
  SelectField,
  Text,
  TextField,
} from "@boligportal/juice";
import {
  banUser,
  getUserEmailTemplateContent,
  sendUserEmail,
} from "lib/customerServiceApi";
import { blacklistReasonEnum } from "lib/enums";
import { EmailTemplate } from "../../ad_detail_page/components/email_template";
import { EmailTemplateItem } from "../../ad_detail_page/interfaces";
import { UserDetailPageContext } from "../user_detail_page_context";
import { prettifiedReason } from "./blacklist_user_notice";

interface blacklistActions {
  expire_seeker_subscriptions?: boolean;
  expire_landlord_subscriptions?: boolean;
  nuke_login?: boolean;
  unsubscribe_from_all_emails?: boolean;
  force_waiting_for_approval?: boolean;
  inactivate_all_rentables?: boolean;
  send_email_to_user?: boolean;
  warn_users_in_contact?: boolean;
  mark_all_ads_as_rented_out?: boolean;
}

const prettifiedAction = {
  expire_seeker_subscriptions: "Expire seeker subscriptions",
  expire_landlord_subscriptions: "Expire landlord subscriptions",
  nuke_login: "Nuke login",
  unsubscribe_from_all_emails: "Unsubscribe from all emails",
  force_waiting_for_approval: "Force waiting for approval",
  inactivate_all_rentables: "Inactivate all rentables",
  mark_all_ads_as_rented_out: "Mark all ads as rented out",
};

const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`,
  );
};

const BlacklistUser = ({
  fullname,
  templateOptions,
}: {
  fullname: string;
  templateOptions: EmailTemplateItem[];
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [reason, setReason] = useState<blacklistReasonEnum>(
    blacklistReasonEnum.undefined,
  );
  const [stateActions, setStateActions] = useState<blacklistActions>({});
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState(-1);
  const { handleSubmit, register, watch, setValue } = useForm({});
  const { user, refetch } = useContext(UserDetailPageContext);
  const emailMessageBodyRef = useRef<string>("");

  const watchBlackList = watch("blacklist_note");

  const changeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const key = event.target.id;
    const checkedActionState = {
      ...stateActions,
      [key]: newValue,
    };

    setStateActions(checkedActionState);
  };

  const handleTemplateSelectionChange = (templateId: number) => {
    setTemplateId(templateId);
  };

  const handleEmailChange = (emailMessageBody: string) => {
    emailMessageBodyRef.current = emailMessageBody;
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmitDialog = () => {
    setShowConfirm(true);
  };

  useEffect(() => {
    setActions(reason);
  }, [reason]);

  const confirmBanUser = () => {
    if (user) {
      banUser({
        user_id: user.id,
        reason: reason,
        note: urlify(watchBlackList ?? ""),
        nuke_login: stateActions.nuke_login || false,
        expire_landlord_subscriptions:
          stateActions.expire_landlord_subscriptions || false,
        expire_seeker_subscriptions:
          stateActions.expire_seeker_subscriptions || false,
        force_waiting_for_approval:
          stateActions.force_waiting_for_approval || false,
        inactivate_all_rentables:
          stateActions.inactivate_all_rentables || false,
        unsubscribe_from_all_emails:
          stateActions.unsubscribe_from_all_emails || false,
        mark_all_ads_as_rented_out:
          stateActions.mark_all_ads_as_rented_out || false,
      })
        .then(() => {
          refetch();
          closeDialog();
        })
        .catch((error) => {
          console.log(error);
        });
      if (templateId > -1 && emailMessageBodyRef.current !== "") {
        sendUserEmail(templateId, user.id, emailMessageBodyRef.current);
      }
    }
  };

  const setActions = (reason: blacklistReasonEnum) => {
    setStateActions({});
    switch (reason) {
      case blacklistReasonEnum.PROFILE_SHARING:
        setStateActions({
          expire_seeker_subscriptions: true,
        });
        break;
      case blacklistReasonEnum.COMPANY_DEAL:
        setStateActions({});
        break;
      case blacklistReasonEnum.PHISHED:
        setStateActions({
          nuke_login: true,
          force_waiting_for_approval: true,
        });
        break;
      case blacklistReasonEnum.SOCIAL_HOUSING:
        setStateActions({
          expire_landlord_subscriptions: true,
          force_waiting_for_approval: true,
        });
        break;
      case blacklistReasonEnum.MAYBE_SCAMMER:
        setStateActions({
          force_waiting_for_approval: true,
        });
        break;
      case blacklistReasonEnum.BANNED_USER:
        setStateActions({
          force_waiting_for_approval: true,
          expire_seeker_subscriptions: true,
          expire_landlord_subscriptions: true,
          nuke_login: true,
          unsubscribe_from_all_emails: true,
          inactivate_all_rentables: true,
        });
        break;
      case blacklistReasonEnum.REJECTED_LANDLORD:
        setStateActions({
          expire_landlord_subscriptions: true,
          force_waiting_for_approval: true,
          mark_all_ads_as_rented_out: true,
        });
        break;
      case blacklistReasonEnum.REJECTED_SEEKER:
        setStateActions({
          expire_seeker_subscriptions: true,
        });
        break;
    }
  };

  const closeDialog = () => {
    setReason(blacklistReasonEnum.undefined);
    setValue("blacklist_note", "");
    setStateActions({
      expire_landlord_subscriptions: false,
      expire_seeker_subscriptions: false,
      force_waiting_for_approval: false,
      inactivate_all_rentables: false,
      mark_all_ads_as_rented_out: false,
      nuke_login: false,
      send_email_to_user: false,
      unsubscribe_from_all_emails: false,
      warn_users_in_contact: false,
    });
    setShowDialog(false);
  };

  const CancelButton = () => {
    const actions = useContext(dialogActions);
    return (
      <Button
        variant="subtle"
        onClick={actions.hide}
      >
        Cancel
      </Button>
    );
  };

  const BlacklistActions = () => (
    <>
      <Labelled
        label="Actions"
        required
      >
        <CheckField
          label="Expire seeker subscriptions"
          id="expire_seeker_subscriptions"
          checked={stateActions.expire_seeker_subscriptions}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Expire landlord subscriptions"
          id="expire_landlord_subscriptions"
          checked={stateActions?.expire_landlord_subscriptions}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Nuke login"
          id="nuke_login"
          checked={stateActions?.nuke_login}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Unsubscribe from all emails"
          id="unsubscribe_from_all_emails"
          checked={stateActions?.unsubscribe_from_all_emails}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Force waiting for approval"
          id="force_waiting_for_approval"
          checked={stateActions?.force_waiting_for_approval}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Inactivate all rentables"
          id="inactivate_all_rentables"
          checked={stateActions?.inactivate_all_rentables}
          onChange={(e) => changeCheck(e)}
        />
        <CheckField
          label="Mark all ads as rented out"
          id="mark_all_ads_as_rented_out"
          checked={stateActions?.mark_all_ads_as_rented_out}
          onChange={(e) => changeCheck(e)}
        />
      </Labelled>
    </>
  );

  return (
    <>
      <Flex>
        <Button
          text
          variant="danger"
          onClick={() => setShowDialog(true)}
        >
          <IconAlertCircleOutline
            inline
            color="danger"
          />
          Blacklist
        </Button>

        <Dialog
          slideOver
          open={showDialog}
          onClose={closeDialog}
          hideOnClickOutside={false}
        >
          <DialogHeader>Blacklist</DialogHeader>

          <DialogForm
            method="post"
            onSubmit={handleSubmit(() => handleSubmitDialog())}
          >
            <DialogContent>
              <SelectField
                value={reason}
                onChange={handleReasonChange}
                required
                label="Select Reason for blacklisting"
                items={[
                  {
                    label: "Select",
                    value: "",
                    disabled: true,
                  },
                  {
                    label: "📒 Profile sharing",
                    value: blacklistReasonEnum.PROFILE_SHARING,
                  },
                  {
                    label: "📒 Company deal",
                    value: blacklistReasonEnum.COMPANY_DEAL,
                  },
                  {
                    label: "📒 Phishing",
                    value: blacklistReasonEnum.PHISHED,
                  },
                  {
                    label: "📒 Social housing",
                    value: blacklistReasonEnum.SOCIAL_HOUSING,
                  },
                  {
                    label: "📒 Maybe scammer",
                    value: blacklistReasonEnum.MAYBE_SCAMMER,
                  },
                  {
                    label: "📒 Other (yellow)",
                    value: blacklistReasonEnum.OTHER_YELLOW,
                  },
                  {
                    label: "📕 Ban User",
                    value: blacklistReasonEnum.BANNED_USER,
                  },
                  {
                    label: "📕 Reject landlord",
                    value: blacklistReasonEnum.REJECTED_LANDLORD,
                  },
                  {
                    label: "📕 Reject seeker",
                    value: blacklistReasonEnum.REJECTED_SEEKER,
                  },
                  {
                    label: "📕 Other (red)",
                    value: blacklistReasonEnum.OTHER_RED,
                  },
                ]}
              />
              <BlacklistActions />
              <Divider />
              <Box mb={2}>
                <TextField
                  {...register("blacklist_note")}
                  multiLine
                  required
                  label="Blacklist note"
                ></TextField>
              </Box>
              {templateOptions && user && (
                <EmailTemplate
                  emailTemplateLoaderId={user.id}
                  templateOptions={templateOptions}
                  emailTemplateLoader={getUserEmailTemplateContent}
                  onTemplateSelectionChange={handleTemplateSelectionChange}
                  onEmailChange={handleEmailChange}
                />
              )}
            </DialogContent>

            <DialogFooter>
              <Flex gap>
                <CancelButton />
                <Button
                  type="submit"
                  variant="primary"
                >
                  Blacklist
                </Button>
              </Flex>
            </DialogFooter>
          </DialogForm>
        </Dialog>
      </Flex>

      <Confirm
        open={showConfirm}
        dangerous
        size="large"
        onCancel={() => {
          setShowConfirm(false);
        }}
        onConfirm={() => {
          setShowConfirm(false);
          confirmBanUser();
        }}
        title="Blacklist of user"
        message={
          <Box>
            <Text>{`Add "${prettifiedReason[reason]}" to ${fullname}. `}</Text>
            <ul>
              {Object.keys(stateActions).map((action, index) => {
                if (stateActions[action] === true) {
                  return (
                    <li key={`${action}-${index}`}>
                      {prettifiedAction[action]}
                    </li>
                  );
                }

                return; // eslint-disable-line
              })}
            </ul>
          </Box>
        }
        confirmText="Confirm blacklisting"
        cancelText="Cancel"
      />
    </>
  );
};

export default BlacklistUser;
