import * as React from "react";
import {
  Flex,
  Button,
  Box,
  Spinner,
  EmptyBox,
  Text,
  Badge,
  styled,
} from "@boligportal/juice";
import { DndContext, DragEndEvent, DragOverlay } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { MAX_COMPANY_PAGE_HERO_IMAGES } from "apps/company_page/list/components/company_hero_section/consts";
import { DraggableImageItem } from "./components/DraggableImageItem";
import { ImageItem } from "./components/ImageItem";
import { ImageItemControls } from "./components/ImageItemControls";
import { ImageOptionsMenu } from "./components/ImageOptionsMenu";
import { useDropzoneImageUpload } from "./hooks/useDropzoneImageUpload";
import { SortableImagesFeature } from "./interfaces/SortableImagesFeature";

export const DesktopImageOrganiserForCreateNewCompany = ({
  sortableImagesFeature,
}: {
  sortableImagesFeature: SortableImagesFeature;
}) => {
  const {
    images,
    addImage,
    moveImages,
    activeImage,
    setActiveImageFromId,
    clearActiveImage,
    removeImage,
    rotateImage,
    moveImageBackward,
    moveImageForward,
    putImageFirst,
  } = sortableImagesFeature;

  const { fakeClickableRef, dropzoneRef, isUploading } = useDropzoneImageUpload(
    (responsePayload) => {
      responsePayload.forEach((item) => {
        addImage({
          id: item.id.toString(),
          url: item.url,
        });
      });
    },
  );

  const handleDragStart = (event: DragEndEvent) => {
    setActiveImageFromId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const overId = event.over?.id;

    if (overId === undefined) {
      clearActiveImage();
      return;
    }

    const movingImageId = event.active.id;
    const replacingImageId = overId;

    moveImages(movingImageId, replacingImageId);
  };

  const hasMaxImages = images.length >= MAX_COMPANY_PAGE_HERO_IMAGES;

  return (
    <DropzoneContentWrapper>
      <Box
        hidden={!isUploading}
        mb={images.length > 0 ? 4 : 0}
      >
        <Spinner />
      </Box>

      <Box hidden={isUploading || hasMaxImages}>
        <div ref={fakeClickableRef}>
          <div ref={dropzoneRef}>
            <Flex
              column
              mx={{
                xs: 2,
                lg: 6,
              }}
            >
              <Text
                size="h4"
                color="muted"
              >
                Drag & Drop Slide image here, or click to select
              </Text>
              <Box
                pt={2}
                pb={4}
              >
                <Button variant="branded">Browse Slide Images...</Button>
              </Box>
            </Flex>
          </div>
        </div>
      </Box>

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={images.map((image) => image.id)}>
          <GridContainer>
            {images.map((image, index) => (
              <Box key={image.id}>
                <DraggableImageItem
                  fixedHeight={140}
                  imageId={image.id}
                  imageUrl={`${image.url}?auto=compress,format&w=500&h=140&fit=crop&crop=focalpoint`}
                  imagePrimaryComponent={
                    <Text size="tiny">
                      <Badge>First Slideshow Image</Badge>
                    </Text>
                  }
                  imageFloorPlanComponent={null}
                  imageControlsComponent={
                    <ImageItemControls
                      image={image}
                      canMoveBackward={index > 0}
                      canMoveForward={index < images.length - 1}
                      rotateImage={rotateImage}
                      moveImageBackward={moveImageBackward}
                      moveImageForward={moveImageForward}
                    />
                  }
                  imageOptionsComponent={
                    <ImageOptionsMenu
                      image={image}
                      putImageFirst={putImageFirst}
                      removeImage={removeImage}
                    />
                  }
                />
              </Box>
            ))}
          </GridContainer>
        </SortableContext>
        <DragOverlay>
          {activeImage && (
            <ImageItem
              image={activeImage}
              isDragging
              styles={{
                width: "100%",
                height: "100%",
                cursor: "grabbing",
              }}
            />
          )}
        </DragOverlay>
      </DndContext>
    </DropzoneContentWrapper>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const DropzoneContentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    bg="tint"
    borderRadius="sm"
  >
    <EmptyBox
      m={0}
      p={4}
    >
      {children}
    </EmptyBox>
  </Box>
);
