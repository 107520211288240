const key = "rentable_dialog_trigger";

export type UpsellDialogTrigger =
  | "list of rentable link"
  | "rentable save changes"
  | "rentable banner"
  | "inbox"
  | "find tenant"
  | "statistics"
  | "statistics email"
  | "exposure plus rentables list"
  | "exposure plus ad details"
  | "unknown";

/** Saves the rentable dialog trigger in localeStorage */
export const setRentableDialogTrigger = (value: UpsellDialogTrigger) =>
  localStorage.setItem(key, value);

/** Retrieves the rentable dialog trigger from localStorage */
export const getRentableDialogTrigger = (): UpsellDialogTrigger =>
  (localStorage.getItem(key) as UpsellDialogTrigger) || "unknown";

export const deleteRentableDialogTrigger = () => localStorage.removeItem(key);
