import { useState } from "react";
import { Dialog, DialogHeader } from "@boligportal/juice";
import { AppActions } from "components/Providers/AppActionsProvider";
import { MessageWriter } from "components/design-system/molecules/message_writer/message_writer";
import { Ad } from "components/interfaces/ad";
import { ModalActions } from "components/modal_controller";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { withModalActions } from "lib/injectors";
import { TrackingEvent } from "lib/tracking/events";
import { FillProfileModal } from "../fill_profile_modal/fill_profile_modal";

interface SendMessageToAdModalProps {
  appActions: AppActions;
  modalActions: ModalActions;
  ad: Ad;
  hideUpdateContactsDialog?: boolean;
  onSend?: () => void;
}

const SendMessageToAdModalComponent = ({
  appActions,
  modalActions,
  ad,
  hideUpdateContactsDialog,
  onSend,
}: SendMessageToAdModalProps) => {
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleMessageChange = (message: string) => {
    setMessage(message);
  };

  const closeModal = () => {
    modalActions.closeModal();
  };

  const handleSend = () => {
    setLoading(true);

    API.sendMessageToAd(ad.id, message).then(
      (sentMessageResponse) => {
        if (onSend) {
          onSend();
        }

        closeModal();

        if (!hideUpdateContactsDialog) {
          modalActions.showModal(<FillProfileModal ad={ad} />);
        }

        const seekerThreadId = sentMessageResponse.thread_ids![0];

        const onPagePropsUpdated = () => {
          setMessage("");
          setLoading(false);
        };

        // Update the ad so that seeker_thread_id is populated.
        // When that is finished, update the state to show the success message.
        appActions.updatePageProps(
          {
            ad: {
              ...ad,
              seeker_thread_id: seekerThreadId,
            },
          },
          onPagePropsUpdated,
        );
        TrackingEvent.seekerSentMessageFromDetailPage();
      },
      (rejectedReason) => {
        setLoading(false);
        console.error(rejectedReason);
      },
    );
  };

  return (
    <Dialog
      onClose={closeModal}
      open
    >
      <DialogHeader>{t("contact_landlord_modal.title")}</DialogHeader>
      <MessageWriter
        message={message}
        onMessageChange={handleMessageChange}
        onSend={handleSend}
        disabled={loading || ad.deleted}
      />
    </Dialog>
  );
};

export const SendMessageToAdModal = withModalActions(
  SendMessageToAdModalComponent,
);
