import React from "react";
import {
  Box,
  Confirm,
  Card,
  Switch,
  CardHeader,
  Badge,
  Text,
} from "@boligportal/juice";
import { useToggleMobilePayMutation } from "api/mutations";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";

const allowedEmails = [
  "rkj@boligportal.dk",
  "stb@boligportal.dk",
  "lve@boligportal.dk",
];

export const shouldShowMobilePaySettings = (email: string) =>
  allowedEmails.includes(email);

export const CustomerServiceSettingsPage = () => {
  const { user } = useUser();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [mobilePayEnabled, setMobilePayEnabled] = React.useState(
    App.settings.mobile_pay_enabled,
  );
  const mobilePayMutation = useToggleMobilePayMutation();

  const handleConfirm = () => {
    mobilePayMutation.mutateAsync(!mobilePayEnabled, {
      onSuccess: (data) => {
        setMobilePayEnabled(data.enabled);
        setShowConfirm(false);
      },
    });
  };

  const showMobilePaySettings = shouldShowMobilePaySettings(user?.email || "");
  if (!showMobilePaySettings) return null;

  return (
    <Box
      width="100%"
      bg="tint"
      p={4}
    >
      <Card>
        <CardHeader
          button={
            <Switch
              onChange={() => setShowConfirm(true)}
              checked={mobilePayEnabled}
              disabled={mobilePayMutation.isLoading}
            />
          }
          description={`${
            !mobilePayEnabled ? "Enables" : "Disables"
          } the users ability to pay with MobilePay`}
          title={
            <>
              <Text mr={1}>MobilePay</Text>
              <Badge state={mobilePayEnabled ? "success" : "warning"}>
                {mobilePayEnabled ? "Enabled" : "Disabled"}
              </Badge>
            </>
          }
        />

        <Confirm
          open={showConfirm}
          dangerous
          cancelText="Cancel"
          confirmText={!mobilePayEnabled ? "Enable" : "Disable"}
          onConfirm={handleConfirm}
          message={`Are you sure you want to ${
            !mobilePayEnabled ? "enable" : "disable"
          } MobilePay?`}
          onCancel={() => setShowConfirm(false)}
          title="Disable/enable MobilePay?"
          loading={mobilePayMutation.isLoading}
        />
      </Card>
    </Box>
  );
};
