import { useState, useCallback } from "react";
import { getIPAddressInfoService } from "../services/get_ip_address_Info_service";

const useIPAddressCheckFeature = () => {
  const [ipAddressInfo, setIpAddressInfo] = useState<{
    displayLabel: string;
    isSafe: boolean | null;
    isBoligPortal: boolean | null;
  }>();

  const getIPAddressInfo = useCallback((adId: number) => {
    getIPAddressInfoService(adId).then((response) => {
      const isBoligPortal = response.is_boligportal;
      const isSafe = response.is_safe;
      const hasIPAddress = response.ip;
      const hasCountryAndCity = response.country && response.city;
      const hasCountryOnly = response.country && !response.city;

      let label = hasIPAddress
        ? isBoligPortal
          ? "BoligPortal"
          : response.ip
        : "No IP Address";

      if (hasCountryOnly) {
        label = `${label}(${response.country})`;
      }

      if (hasCountryAndCity) {
        label = `${label}(${response.country}, ${response.city})`;
      }

      setIpAddressInfo({
        displayLabel: `IP Address: ${label}`,
        isSafe,
        isBoligPortal,
      });
    });
  }, []);

  return {
    ipAddressInfo,
    getIPAddressInfo,
  };
};

export { useIPAddressCheckFeature };
