import { Flex, Box, Text } from "@boligportal/juice";
import { PAGE_HOME } from "apps/customer_service";
import { AdStateLabel } from "apps/customer_service/components/ad_state_label";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { AdItem } from "../../ads_page/interfaces";

type Props = {
  item: AdItem;
};

const TotalAdsItem = ({ item }: Props) => {
  const { id, state, landlord_id, needs_follow_up, review_reason } = item;
  const { formatted_address, deleted_date } = item;

  return (
    <Flex
      column
      pb={2}
    >
      <StyledLink
        to={`${PAGE_HOME}/ads/detail/${id}?landlordId=${landlord_id}`}
      >
        <Flex align="start">
          <Flex column>
            <Box>
              <Text size="small">
                <Text size="small">{formatted_address}</Text>
              </Text>
              <Box
                inline
                pl={1}
              >
                <AdStateLabel
                  state={state}
                  claimer={null}
                  needsFollowUp={needs_follow_up}
                  reviewReason={review_reason}
                  isDeleted={Boolean(deleted_date)}
                />
              </Box>
            </Box>
          </Flex>
          <Box flexPush>
            <Text
              size="small"
              weight="bold"
            >{`#${id}`}</Text>
          </Box>
        </Flex>
      </StyledLink>
    </Flex>
  );
};

export { TotalAdsItem };
