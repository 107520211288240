import { styled } from "@boligportal/juice";
import { css } from "@emotion/react";

const linkStyles = (props) => css`
  font-size: ${props.theme.fontSize.tiny};
  color: ${props.theme.color.text.base};
  text-decoration: underline;
  &:hover {
    color: ${props.theme.color.text.base};
    text-decoration: none;
  }
`;
const StyledLink = styled.a`
  ${linkStyles};
`;

const SimpleTextLink = (props: { url: string; children: any }) => (
  <StyledLink
    target="_blank"
    href={props.url}
  >
    {props.children}
  </StyledLink>
);

export { SimpleTextLink };
