import { Box, Flex, styled, Hidden, useTheme } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const NewDesignHeader = styled.h2`
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.13px;
  color: #191919;
`;

const NewDesignSubHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1c1c;
`;

const CdnImageBackground = styled.div`
  background-color: #ffffff;
  box-shadow: ${(props) => props.theme.shadow.lg};
  padding: ${(props) => props.theme.unit(1)};
  padding-bottom: 2px;
  z-index: 2;
  border-radius: 4px;
`;

const VideoBackground = styled.div`
  background-color: #ffffff;
  boxshadow: ${(props) => props.theme.shadow.lg};
  padding: ${(props) => props.theme.unit(1)};
  padding-bottom: 2px;
  width: 100%;
  z-index: 2;
  border-radius: 4px;
`;

interface Props {
  headline: string;
  subHeadline: string;
  videoSource: string;
  mobileVideoSource: string;
}

export const VideoContractsSection = ({
  headline,
  subHeadline,
  videoSource,
  mobileVideoSource,
}: Props) => {
  const theme = useTheme();
  return (
    <PageBlock
      bg="default"
      py="extraLarge"
      pyMobile="large"
    >
      <ContractsLandingPageContainer>
        <Flex
          textAlign="center"
          align="center"
          column
        >
          <NewDesignHeader>{headline}</NewDesignHeader>

          <Box
            mt={2}
            mb={6}
            maxWidth={{
              xs: "100%",
              sm: "500px",
            }}
          >
            <NewDesignSubHeader>{subHeadline}</NewDesignSubHeader>
          </Box>
          <Hidden
            on={{
              xs: true,
              md: false,
            }}
          >
            <VideoBackground>
              <video
                width="100%"
                loop
                autoPlay
                muted
                playsInline
              >
                <source
                  src={videoSource}
                  type="video/mp4"
                />
              </video>
            </VideoBackground>
          </Hidden>

          <Hidden
            on={{
              xs: false,
              md: true,
            }}
          >
            <CdnImageBackground>
              <CdnImage
                width={259}
                height={560}
                src={mobileVideoSource}
              />
            </CdnImageBackground>
          </Hidden>
        </Flex>
      </ContractsLandingPageContainer>
    </PageBlock>
  );
};
