import * as React from "react";
import {
  Card,
  CardSection,
  CheckField,
  Container,
  Flex,
  FlexColumn,
  useNotifications,
  Box,
  Spinner,
} from "@boligportal/juice";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { App } from "components/app";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { leaveComment, updateProfile } from "lib/customerServiceApi";
import { useMutationToggleBankIdVerificationBadge } from "lib/customerServiceMutations";
import { useQueryNewBuilds } from "lib/customerServiceQueries";
import { blacklistReasonEnum } from "lib/enums";
import { AllLandlordsNewBuilds } from "../../../newbuild_detail_page/components/AllLandlordsNewBuilds";
import { ActivityStream } from "../../ad_detail_page/components/activity_stream";
import { AllLandslordAds } from "../../ad_detail_page/components/all_landlord_ads";
import BlacklistUserNotice from "../blacklist/blacklist_user_notice";
import { ProfileDetails } from "../profile_info/profile_details";
import { SubscriptionsFeed } from "../subscriptions_feed/subscriptions_feed";
import { UserDetailPageContext } from "../user_detail_page_context";

export interface blacklisted {
  agent: string;
  id: number;
  note: string;
  reason: blacklistReasonEnum;
  timestamp: Date;
}
export interface UserForCustomerService extends User {
  date_created: string;
  last_login: string;
  last_edited: string;
  id: number;
  company_id?: number | null;
  user_bans: blacklisted[];
  needs_review: boolean;
}

const UserDetailView = () => {
  const {
    user,
    userId,
    subscriptions,
    allAds,
    profileDetailsViewModel,
    activityStreamData,
    activityStreamOffset,
    setActivityStreamOffset,

    includeStaffActivities,
    includeUserActivities,
    setIncludeStaffActivities,
    setIncludeUserActivities,
    refetch,
    refetchProfile,
    isLoadingActivityStream,
  } = React.useContext(UserDetailPageContext);

  const isSE = App.settings.market === Market.BOSTADSPORTAL_SE;

  const { addErrorNotification, addResponseErrorNotification } =
    useErrorNotification();

  const { addNotification } = useNotifications();

  const displaySaveNotification = () => {
    if (addNotification) {
      addNotification({
        title: "Saving data...",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  React.useEffect(() => {
    refetch();
  }, [refetch, includeStaffActivities, includeUserActivities]);

  const { data, isLoading, isError } = useQueryNewBuilds(userId);
  const { mutateAsync, isLoading: isBankIdStatusLoading } =
    useMutationToggleBankIdVerificationBadge();

  const handleSubscriptionUpdated = () => {
    refetch();
  };

  const handleUpdateField = (fieldname: string, value: any) => {
    displaySaveNotification();

    updateProfile(userId, fieldname, value)
      .then((response) => {
        if (response.errors) {
          addResponseErrorNotification(response.errors);
        }

        refetchProfile();
      })
      .catch(() => {
        addErrorNotification(
          "An unexpected error occured while saving the user",
        );
      });
  };

  const handleCreateComment = (value: string): Promise<any> => {
    displaySaveNotification();

    const saveCommentPromise = leaveComment(userId, value);

    saveCommentPromise.then(() => {
      refetch();
    });

    return saveCommentPromise;
  };

  const handleIncludeUserActivitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeUserActivities(event.target.checked);
  };

  const handleIncludeStaffActivitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeStaffActivities(event.target.checked);
  };

  const handleBankIdVerify = async () => {
    await mutateAsync({ userId });

    refetchProfile();
  };

  return (
    <Container>
      <Flex
        column
        my={4}
      >
        {user && user.user_bans.length > 0 && (
          <BlacklistUserNotice
            bans={user.user_bans}
            view="user_detail"
          ></BlacklistUserNotice>
        )}
        {profileDetailsViewModel && (
          <Card sectioned>
            <CardSection title="Profile details">
              <ProfileDetails
                profileDetails={profileDetailsViewModel}
                updateField={handleUpdateField}
              />
            </CardSection>
          </Card>
        )}
        <Flex gap>
          <FlexColumn size={8}>
            <Card sectioned>
              <CardSection title="Activity Stream">
                <ActivityStream
                  items={activityStreamData?.stream || []}
                  offset={activityStreamOffset}
                  setOffset={setActivityStreamOffset}
                  totalRecords={activityStreamData?.count}
                  isLoading={isLoadingActivityStream}
                  onCreateComment={handleCreateComment}
                  filterComponent={
                    <Flex
                      gap
                      mb={1}
                    >
                      <CheckField
                        inline
                        id="includeStaffActivities"
                        label="Include staff activities in timeline"
                        onChange={handleIncludeStaffActivitiesChange}
                        checked={includeStaffActivities}
                      />
                      <CheckField
                        inline
                        id="includeUserActivities"
                        label="Include user activities in timeline"
                        onChange={handleIncludeUserActivitiesChange}
                        checked={includeUserActivities}
                      />
                    </Flex>
                  }
                />
              </CardSection>
            </Card>
          </FlexColumn>
          <FlexColumn size={4}>
            {isSE && (
              <Card sectioned>
                <CardSection title="BankID Verification">
                  <Flex align="baseline">
                    <CheckField
                      inline
                      label="Landlord is BankID verified"
                      onChange={handleBankIdVerify}
                      checked={profileDetailsViewModel?.bank_id_verified}
                    />
                    {isBankIdStatusLoading && <Spinner />}
                  </Flex>
                </CardSection>
              </Card>
            )}
            {allAds && (
              <Card sectioned>
                <CardSection title="All ads">
                  <AllLandslordAds
                    userId={userId}
                    items={allAds ? allAds.ads : []}
                    totalCount={allAds ? allAds.count : 0}
                  />
                  {user && (
                    <Box
                      hidden={allAds.count === 0}
                      pt={1}
                    >
                      <CheckField
                        label="Forward full messages from seekers to landlords email-address"
                        checked={user.forward_seeker_messages_to_landlord_email}
                        onChange={(event) =>
                          handleUpdateField(
                            "forward_seeker_messages_to_landlord_email",
                            event.target.checked,
                          )
                        }
                      />
                    </Box>
                  )}
                </CardSection>
              </Card>
            )}
            <Card sectioned>
              <CardSection title="Nyproduktion">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <AllLandlordsNewBuilds
                    items={data?.new_builds ? data.new_builds : []}
                    isError={isError}
                  />
                )}
              </CardSection>
            </Card>
            {user && (
              <Card sectioned>
                <CardSection title="Subscriptions">
                  <SubscriptionsFeed
                    items={subscriptions}
                    refundCandidate={user}
                    onSubscriptionUpdated={handleSubscriptionUpdated}
                  />
                </CardSection>
              </Card>
            )}
          </FlexColumn>
        </Flex>
      </Flex>
    </Container>
  );
};

export { UserDetailView };
