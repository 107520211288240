import { useContext, useState, useEffect } from "react";
import {
  Button,
  CheckField,
  Dialog,
  dialogActions,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Labelled,
  RadioField,
  Spinner,
  TextField,
} from "@boligportal/juice";
import endOfDay from "date-fns/endOfDay";
import formatISO from "date-fns/formatISO";
import {
  getFreePlans,
  PaymentType,
  startFreeSubscription,
} from "lib/customerServiceApi";

// Mapped values

const errorMessages = {
  subscription_is_not_free: "Subscription is not free",
  renewal_date_in_the_past: "Renewal date is in the past",
  subscription_already_active: "The subscription is already active",
  exposure_and_other_product_selected:
    "Exposure and other products can not be selected at once",
  "plan.product.name_not_known": "The chosen plan is not known",
};

const product_display_names = {
  seeker_subscription: "{ NOT SUPPORTED } Seeker subscription ",
  open_ad: "Custom open ad",
  promote_ad: "Custom top ad",
  exposure_ad: "Custom exposure ad",
  exposure_plus_ad: "Custom exposure+ ad",
};

// Describes which other plans a given plan excludes.
const product_exclusions_by_product = {
  open_ad: [],
  promote_ad: [],
  exposure_ad: ["open_ad", "promote_ad"],
  exposure_plus_ad: ["open_ad", "promote_ad", "exposure_ad"],
};

// Interfaces

interface PlanViewModel {
  id: number;
  name: string;
  selected: boolean;
  disabled: boolean;
}

// Helpers

const getTodayDate = (): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

const noCheckedPlans = (plans: PlanViewModel[]): boolean => {
  let result = true;
  plans.forEach((plan) => {
    if (plan.selected) {
      result = false;
    }
  });
  return result;
};

const getSelectedIds = (plans: PlanViewModel[]): number[] => {
  const ids: number[] = [];
  plans.forEach((plan) => {
    if (plan.selected) {
      ids.push(plan.id);
    }
  });
  return ids;
};

/* If a plan excludes other plans, a string describing that is returned. */
const getHelpText = (plan: PlanViewModel): string => {
  const product_exclusions = product_exclusions_by_product[plan.name].map(
    (name) => product_display_names[name],
  );
  const num_exclusions = product_exclusions.length;

  if (num_exclusions == 0) {
    return "";
  }

  let formatted_product_exclusions = "";

  if (num_exclusions > 1) {
    formatted_product_exclusions = `${product_exclusions
      .slice(0, num_exclusions - 1)
      .join(", ")}
                                      and ${
                                        product_exclusions[num_exclusions - 1]
                                      }`;
  } else {
    [formatted_product_exclusions] = product_exclusions;
  }

  return `If ${
    product_display_names[plan.name]
  } is checked, ${formatted_product_exclusions} will be disabled.`;
};

// Sub components

const CancelButton = () => {
  const actions = useContext(dialogActions);
  return (
    <Button
      variant="subtle"
      onClick={actions.hide}
    >
      Cancel
    </Button>
  );
};

// Feature

const AddFreePlanButton = ({
  userId,
  adId,
  onSubscriptionSuccess,
  children,
  type,
}: {
  userId: number;
  adId?: number;
  onSubscriptionSuccess?: () => void;
  type: "ad" | "user";
  children: string;
}) => {
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType>("invoice");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [xplans, setXPlans] = useState<PlanViewModel[]>([]);

  useEffect(() => {
    async function getAllFreeSubscriptionPlans() {
      const { plans } = await getFreePlans(userId);
      const newxPlans: PlanViewModel[] = [];

      plans.forEach((plan) => {
        if (type === "ad" && !plan.is_upsell) {
          return;
        }

        newxPlans.push({
          id: plan.plan_id,
          name: plan.product_name,
          selected: false,
          disabled: false,
        });
      });

      setXPlans(newxPlans);
    }
    getAllFreeSubscriptionPlans();
  }, [userId]);

  const handleConfirmClick = () => {
    if (selectedEndDate === undefined) {
      return;
    }

    setIsSubmitting(true);

    const dateInIso8601FormatRoundToMidnight = formatISO(
      endOfDay(new Date(selectedEndDate)),
    );
    const selectedPlanIds = getSelectedIds(xplans);

    startFreeSubscription({
      planIds: selectedPlanIds,
      renewalDate: dateInIso8601FormatRoundToMidnight,
      adId,
      userId,
      paymentType: selectedPaymentType,
    }).then((response) => {
      response.forEach((item) => {
        const errorMessage = item.error_message;

        if (errorMessage === "") {
          onSubscriptionSuccess?.();
          setShowSlideOver(false);
          setIsSubmitting(false);
        } else {
          setIsSubmitting(false);
          alert(errorMessages[errorMessage]);
        }
      });
    });
  };

  const handlePlanSelectionChange = (
    targetPlan: PlanViewModel,
    checked: boolean,
  ) => {
    const mutablePlans = [...xplans];

    // Mutate the target plan and gather plans that should be disabled after the mutation.
    let disabled_plans: Array<string> = [];
    mutablePlans.forEach((mutablePlan) => {
      if (mutablePlan.id === targetPlan.id) {
        mutablePlan.selected = checked;
      }

      if (mutablePlan.selected) {
        disabled_plans = [
          ...disabled_plans,
          ...product_exclusions_by_product[mutablePlan.name],
        ];
      }
    });

    const disabled_plans_set = new Set(disabled_plans);

    mutablePlans.forEach((mutablePlan) => {
      if (disabled_plans_set.has(mutablePlan.name)) {
        mutablePlan.selected = false;
        mutablePlan.disabled = true;
      } else {
        mutablePlan.disabled = false;
      }
    });

    setXPlans(mutablePlans);
  };

  const clearSelection = () => {
    const mutablePlans = [...xplans];
    mutablePlans.forEach((plan) => {
      plan.selected = false;
      plan.disabled = false;
    });
    setXPlans(mutablePlans);
  };

  const handleClose = () => {
    clearSelection();
    setSelectedEndDate("");
    setShowSlideOver(false);
  };

  const disableSubmitButton = !selectedEndDate || noCheckedPlans(xplans);

  return (
    <>
      <Dialog
        slideOver
        open={showSlideOver}
        onClose={handleClose}
      >
        <DialogHeader>{children}</DialogHeader>
        <DialogContent>
          {isSubmitting && <Spinner />}
          {!isSubmitting && (
            <>
              <Labelled label="Select one or more products">
                {xplans.map((plan) => (
                  <Labelled
                    key={plan.id}
                    helpText={getHelpText(plan)}
                  >
                    <CheckField
                      key={`${plan.id}_${plan.name}`}
                      checked={plan.selected}
                      disabled={plan.disabled}
                      label={product_display_names[plan.name]}
                      id={`${plan.id}`}
                      onChange={(event) =>
                        handlePlanSelectionChange(plan, event.target.checked)
                      }
                    />
                  </Labelled>
                ))}
              </Labelled>

              <Flex gap>
                <TextField
                  value={getTodayDate()}
                  type="date"
                  label="Start date"
                  disabled
                />
                <TextField
                  value={selectedEndDate}
                  type="date"
                  label="Select end date"
                  onChange={(event) => setSelectedEndDate(event.target.value)}
                />
              </Flex>
              <RadioField
                name="paymentOptions"
                label="Payment Option"
                value={selectedPaymentType}
                onChange={(event) =>
                  setSelectedPaymentType(event.target.value as PaymentType)
                }
                items={[
                  {
                    value: "invoice",
                    label: "Invoice",
                  },
                  {
                    value: "free",
                    label: "Free",
                  },
                ]}
              />
            </>
          )}
        </DialogContent>
        <DialogFooter>
          <Flex gap>
            <CancelButton />
            <Button
              variant="primary"
              disabled={disableSubmitButton}
              onClick={handleConfirmClick}
            >
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </Dialog>
      <Button onClick={() => setShowSlideOver(true)}>{children}</Button>
    </>
  );
};

export { AddFreePlanButton };
