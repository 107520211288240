import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  IconArrowForward,
  IconPeopleCircleOutline,
  styled,
  Text,
} from "@boligportal/juice";
import { SimpleTextLink } from "apps/customer_service/components/simple_text_link/SimpleTextLink";
import { USER_TYPE } from "components/interfaces/profile";
import { useExternalPhoneWebsites } from "features/cs/market_specific_feature/hooks/useExternalPhoneWebsites";
import BankId from "media/svg/BankId";
import { TaskResponseInterface } from "../interfaces";
import { TitleFormElement } from "./form_elements/title_form_element";

interface EditableLandlordInfoItem {
  email: string | null;
  phoneNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  profileCompanyName: string | null;
  user_type: USER_TYPE | null;
  isBankIdVerified: boolean;
}

interface LandlordInformationInterface {
  phone_number: string | null;
  landlord_id: number;
  email: string | null;
}

const StyledA = styled.a`
  color: ${(props) => props.theme.color.text.base};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.color.text.base};
    text-decoration: none;
  }
`;

const LandlordInformation = (props: {
  landlordUpdater: (
    fieldname: string,
    value: any,
  ) => Promise<TaskResponseInterface>;
  userUpdater: (
    fieldname: string,
    value: any,
  ) => Promise<TaskResponseInterface>;
  initialValues: EditableLandlordInfoItem;
  landlordUrlGenerator: (showCompanyTab: boolean) => string | null;
  isNativeAd?: boolean;
  landlordId: number | null;
  oisOwnerComponent?: React.ReactNode;
  social_housing?: boolean;
  hasOwner?: boolean;
}) => {
  const { externalPhoneWebsites } = useExternalPhoneWebsites();

  const initialValues = React.useMemo(
    () => ({
      phone_number: props.initialValues.phoneNumber,
      email: props.initialValues.email,
      first_name: props.initialValues.firstName,
      last_name: props.initialValues.lastName,
      company_name: props.initialValues.companyName,
      profile_company_name: props.initialValues.profileCompanyName,
    }),
    [props.initialValues],
  );
  const formMethods = useForm<LandlordInformationInterface>({
    mode: "onBlur",
  });

  React.useEffect(() => {
    Object.keys(initialValues).forEach(
      (key: keyof LandlordInformationInterface) => {
        formMethods.setValue(key, initialValues[key]);
      },
    );
  }, [initialValues]);

  const watchPhoneNumber = formMethods.watch(
    "phone_number",
    initialValues.phone_number,
  );

  const {
    firstName,
    lastName,
    profileCompanyName,
    companyName,
    isBankIdVerified,
  } = props.initialValues;

  return (
    <>
      <FormProvider {...formMethods}>
        <Box mb={1}>
          <Text size="small">{"Id: "}</Text>
          <Text
            size="small"
            weight="bold"
          >
            {props.landlordId}
          </Text>
        </Box>
        <Box mb={1}>
          <Text size="small">{"Name: "}</Text>
          <Text
            size="small"
            weight="bold"
          >
            {props.initialValues.user_type === USER_TYPE.COMPANY
              ? profileCompanyName || "n/a"
              : `${firstName} ${lastName}`}
          </Text>
        </Box>
        {companyName && (
          <Box mb={2}>
            <Text size="small">{"Company name: "}</Text>
            <Text
              weight="bold"
              size="small"
            >
              <StyledA href={props.landlordUrlGenerator(true) || "#"}>
                {companyName}{" "}
                {props.social_housing && (
                  <IconPeopleCircleOutline
                    inline
                    size="medium"
                  />
                )}
              </StyledA>
            </Text>
          </Box>
        )}

        {isBankIdVerified && props.hasOwner && (
          <Box
            mt={-1}
            mb={2}
          >
            <BankId />
            <Text
              size="small"
              ml={1}
            >
              BankID verified
            </Text>
          </Box>
        )}

        <TitleFormElement<LandlordInformationInterface>
          updateTask={props.landlordUpdater}
          fieldname="phone_number"
          fieldLabel="Contact phone"
        />

        <Flex
          column
          mb={3}
        >
          <Text
            color="muted"
            size="tiny"
            mr={1}
          >
            Lookup phone number in:
          </Text>
          <Flex
            gap={1}
            wrap
          >
            {externalPhoneWebsites.map((item) => (
              <SimpleTextLink
                key={item.name}
                url={item.urlResolver(watchPhoneNumber ?? "")}
              >
                {item.name}
              </SimpleTextLink>
            ))}
          </Flex>
          {props.oisOwnerComponent}
        </Flex>

        <TitleFormElement<LandlordInformationInterface>
          updateTask={props.landlordUpdater}
          fieldname="email"
          fieldLabel="User email"
        />
      </FormProvider>
      <Flex
        mt={2}
        gap
      >
        <Flex flexPush>
          <Button
            variant="subtle"
            iconAfter={IconArrowForward}
            as="a"
            href={`${props.landlordUrlGenerator(false)}`}
          >
            View landlord
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export { LandlordInformation };
