import { Box, Flex, SortableColumn } from "@boligportal/juice";
import {
  ContractEllipsisMenuCellRenderer,
  ContractEllipsisMenuItem,
  ContractEllipsisMenuItemOption,
} from "components/cells/ContractEllipsisMenuCellRenderer";
import { DataTableWithMobileDataList } from "components/mobile-data-list/DataTableWithMobileDataList";
import { useContractDeletion } from "features/contracts/useContractDeletion";
import { commonTableTrans } from "lib/translations";
import { ContractViewModel, SigningStatus } from "../interfaces";
import { ContractListItem } from "../store/contracts";
import { ContractsNoItemsEmptyState } from "./ContractsNoItemsEmptyState";
import { ContractAddressCellRenderer } from "./cell_renderers/ContractAddressCellRenderer";
import { ContractStateCellRenderer } from "./cell_renderers/ContractStateCellRenderer";
import { ContractStateRelatedActionCellRenderer } from "./cell_renderers/ContractStateRelatedActionCellRenderer";
import { createContractsListViewModels } from "./helpers/createContractViewModels";

interface Props {
  shouldDisplayEmptyScreen?: boolean;
  contractListItems: ContractListItem[];
  activeSortableColumn?: SortableColumn<ContractViewModel>;
  sortClickHandler?: (
    fieldname: keyof ContractViewModel,
    descending: boolean,
  ) => void;
  onContractDeleteSuccess: () => void;
  onCopyContract: (id: number) => void;
  onGotoBuilder: (id: number) => void;
}

export const ContractsDataTable = ({
  shouldDisplayEmptyScreen,
  contractListItems,
  activeSortableColumn,
  sortClickHandler,
  onContractDeleteSuccess,
  onCopyContract,
  onGotoBuilder,
}: Props) => {
  const { setContractDeletionCandidate } = useContractDeletion();
  const {
    tableFieldNameAddress,
    tableFieldNameMoveInDate,
    tableFieldNameStatus,
  } = commonTableTrans();

  const onHandleEllipsisMenuSelect = (
    record: ContractListItem,
    item: ContractEllipsisMenuItem,
  ) => {
    if (item.optionId === ContractEllipsisMenuItemOption.Duplicate) {
      onCopyContract(record.id);
    }
    if (item.optionId === ContractEllipsisMenuItemOption.Delete) {
      setContractDeletionCandidate({
        contractId: record.id,
        onDeleteSuccess: onContractDeleteSuccess,
        status: record.state as SigningStatus,
      });
    }
    if (item.optionId === ContractEllipsisMenuItemOption.Goto_Builder) {
      onGotoBuilder(record.id);
    }
  };

  const items: ContractViewModel[] = createContractsListViewModels({
    contracts: contractListItems,
    onEllipsisMenuSelect: onHandleEllipsisMenuSelect,
  });

  if (shouldDisplayEmptyScreen) {
    return <ContractsNoItemsEmptyState />;
  }

  return (
    <>
      <DataTableWithMobileDataList<ContractViewModel>
        items={items}
        keyField="id"
        mobile={{
          primary: {
            rowRenderer: (item) => (
              <Flex
                justify="space-between"
                align="center"
              >
                <Box>{ContractAddressCellRenderer(item)}</Box>
                <Box>
                  <ContractEllipsisMenuCellRenderer
                    {...item}
                    hasAdditionalMobileActions
                  />
                </Box>
              </Flex>
            ),
          },
          secondaries: [
            {
              fieldLabelFunction: ContractStateCellRenderer,
            },
            {
              fieldLabel: tableFieldNameMoveInDate,
              fieldName: "leasing_period_start_date",
            },
          ],
        }}
        desktop={{
          sortableColumn: activeSortableColumn,
          onSortClick: sortClickHandler,
          columns: [
            {
              sortable: true,
              fieldName: "rentable_address",
              fieldLabel: tableFieldNameAddress,
              cellRenderer: ContractAddressCellRenderer,
            },
            {
              sortable: true,
              fieldName: "leasing_period_start_date",
              fieldLabel: tableFieldNameMoveInDate,
            },
            {
              sortable: true,
              fieldName: "state",
              fieldLabel: tableFieldNameStatus,
              cellRenderer: ContractStateCellRenderer,
            },
            {
              fieldLabel: "",
              fieldName: "state",
              cellRenderer: ContractStateRelatedActionCellRenderer,
            },
            {
              fieldLabel: "",
              fieldName: "contractEllipsisMenuCell",
              alignRight: true,
              cellRenderer: ContractEllipsisMenuCellRenderer,
            },
          ],
        }}
      />
    </>
  );
};
