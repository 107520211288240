import { Controller } from "react-hook-form";
import { SelectField } from "@boligportal/juice";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const rentalPeriods = [
  {
    value: "6",
    label: "1-11 months",
  },
  {
    value: "12",
    label: "12-23 months",
  },
  {
    value: "24",
    label: "24+ months",
  },
  {
    value: "0",
    label: "Unlimited",
  },
];

const RentalPeriodFormElement = <T extends any>(
  props: FormElementInterface<T>,
) => {
  const { save, isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
    true,
  );

  return (
    <LockableFormField
      isLocked={props.isCrawledAd ? props.isLocked : undefined}
      fieldname={props.fieldname}
      fieldLabel={props.fieldLabel}
    >
      <Controller
        name={props.fieldname}
        render={({ field: { onChange, value, ...rest } }) => (
          <SelectField
            label=""
            items={rentalPeriods}
            disabled={isSaving || props.isLocked}
            onChange={(event) => {
              onChange(event);
              save();
            }}
            previousValue={props.previousValue}
            value={props.defaultValue}
            {...rest}
          />
        )}
      />
    </LockableFormField>
  );
};

export { RentalPeriodFormElement };
