export enum TenancyPaymentType {
  MANUAL_BANK_TRANSFER = "MANUAL",
  MOBILEPAY = "MOBILEPAY",
  BETALINGS_SERVICE = "BETALINGS_SERVICE",
}

export enum TenancyMobilePayPaymentTypeStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
}

export enum TenancyBetalingsservicePaymentTypeStatus {
  OK = "Ok",
  CLOSED = "Closed",
  PENDING = "Pending",
  FAILED = "Failed",
  UNKNOWN = "Unknown",
}
