import { useState } from "react";
import { Button } from "@boligportal/juice";
import { useSortableImages } from "features/image_organizer/hooks/useSortableImages";
import { createCompanyPage } from "../api";
import { CompanyPageDialogForm } from "./CompanyPageDialogForm";
import {
  CompanyPageSlideOverFormValues,
  createPayload,
  useCompanyPageForm,
} from "./useCompanyPageForm";

type Props = {
  companyId: number;
  onClose: () => void;
};

export const CreateCompanyPageDialog = ({ companyId, onClose }: Props) => {
  const sortableImagesFeature = useSortableImages([]);
  const logoImageFeature = useSortableImages([]);
  const { register, watch, reset, handleSubmit } = useCompanyPageForm();

  const [showDialog, setShowDialog] = useState(false);

  const resetAndClose = () => {
    setShowDialog(false);
    reset({
      slug: "",
      filter_url: "",
      headline: "",
      lead: "",
      body: "",
      meta_description: "",
    });
    sortableImagesFeature.images.forEach((image) => {
      sortableImagesFeature.removeImage(image);
    });
    onClose();
  };

  const submit = (companyPageForm: CompanyPageSlideOverFormValues) => {
    const payload = createPayload(
      companyPageForm,
      logoImageFeature.images,
      sortableImagesFeature.images,
    );

    if (typeof payload === "string") {
      alert(payload);
      return;
    }

    createCompanyPage(companyId, payload)
      .then(resetAndClose)
      .catch((err) => {
        alert(err.error);
      });
  };

  return (
    <>
      <Button onClick={() => setShowDialog(true)}>
        Create new Company Page
      </Button>
      <CompanyPageDialogForm
        isOpen={showDialog}
        title="Create Company Page"
        submitButtonLabel="Create"
        logoImageFeature={logoImageFeature}
        sortableImagesFeature={sortableImagesFeature}
        register={register}
        watch={watch}
        submit={handleSubmit(submit)}
        onClose={resetAndClose}
      />
    </>
  );
};
