import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Button,
  DialogForm,
  Notice,
  IconInformationCircle,
  Flex,
} from "@boligportal/juice";
import { vestResolver } from "@hookform/resolvers/vest";
import { benefitsKeys } from "api/queries";
import { sendTenantHubLead } from "apps/tenant_hub/api/send_lead";
import { ContactTenant } from "apps/tenant_hub/api/types";
import { tenantHubKeys } from "apps/tenant_hub/api_queries";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { LeadsSection, AllPartners } from "../LeadsSections";
import { DialogLeadForm } from "./DialogLeadForm";
import { DialogLeadIntro } from "./DialogLeadIntro";
import { DialogLeadSucess } from "./DialogLeadSucess";
import {
  ConfigType,
  leadPartnerDialogConfigs,
} from "./LeadServiceDialogPartnersConfig";
import { tenantHubLeadsValidationSuite } from "./leadValidationSuite";

interface Props {
  onClose: () => void;
  section: LeadsSection;
  userToken?: string;
  partner: AllPartners;
  tenantContact?: ContactTenant;
  tenancyAddress?: string;
  moveInDate?: string;
  isSigned: boolean;
  isButtonHidden?: boolean;
}

export type LeadForm = {
  address: string;
  move_in_date: string | null;
  tenant_first_name: string;
  tenant_last_name: string;
  email: string;
  phone: string;
  cpr: string | null;
  isAgree: boolean;
};

const enum DialogView {
  INTRO = "INTRO",
  FORM = "FORM",
  SUCCESS = "SUCCESS",
}

export const LeadServiceDialog = ({
  onClose,
  section,
  userToken,
  partner,
  tenantContact,
  tenancyAddress,
  moveInDate,
  isSigned = false,
  isButtonHidden,
}: Props) => {
  const [dialogView, setDialogView] = useState(
    isSigned ? DialogView.FORM : DialogView.INTRO,
  );
  const [address_changed, setAddressChanged] = useState(false);
  const isInternet = section === LeadsSection.INTERNET;
  const [errorSendingLead, setErrorSendingLead] = useState(false);

  const { ElecricityLeadConfig, InternetLeadConfig } =
    leadPartnerDialogConfigs();

  const queryClient = useQueryClient();

  const {
    register,
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    watch,
  } = useForm<LeadForm>({
    resolver: vestResolver(tenantHubLeadsValidationSuite),
    mode: "onSubmit",
    defaultValues: {
      address: tenancyAddress,
      tenant_first_name: tenantContact?.first_name,
      tenant_last_name: tenantContact?.last_name,
      email: tenantContact?.email,
      phone: tenantContact?.phone,
      move_in_date: isInternet ? null : moveInDate,
    },
  });

  const watchedAddress = watch("address");

  useMemo(() => {
    if (watchedAddress !== tenancyAddress) {
      setAddressChanged(true);
    }
  }, [watchedAddress, tenancyAddress]);

  const onSubmit: SubmitHandler<LeadForm> = async (data) => {
    const { ...payload } = data;
    return sendTenantHubLead(
      {
        ...payload,
        provider: partner,
        category: section,
        address_changed,
      },
      userToken,
    )
      .then((res) => {
        if (res.success) {
          setDialogView(DialogView.SUCCESS);
          TrackingEvent.tenantHubLeadsClickLeadConfirmSignUp(partner);
        } else {
          setErrorSendingLead(true);
        }
      })
      .finally(() => {
        userToken
          ? queryClient.invalidateQueries(tenantHubKeys.leads(userToken))
          : queryClient.invalidateQueries(benefitsKeys.benefitsLeads());
      });
  };

  const handleFormSubmit = async () => {
    if (dialogView === DialogView.FORM) {
      await handleSubmit(onSubmit)();
    } else if (dialogView === DialogView.SUCCESS) {
      onClose();
    } else {
      setDialogView(DialogView.FORM);
      TrackingEvent.tenantHubLeadsClickLeadSignUp(partner);
    }
  };

  let config: ConfigType | undefined;

  switch (section) {
    case LeadsSection.ELECTRICITY:
      config = ElecricityLeadConfig[partner];
      break;
    case LeadsSection.INTERNET:
      config = InternetLeadConfig[partner];
      break;
  }

  const { title, description, agreeText, introImage, notice } = config || {};

  const showIntro = dialogView === DialogView.INTRO;
  const showFormContent = dialogView === DialogView.FORM;
  const showSuccess = dialogView === DialogView.SUCCESS;

  const getButtomLabel = (dialogView: DialogView) => {
    if (dialogView !== DialogView.SUCCESS) {
      if (isInternet) {
        return t("tenant_hub.leads.dialog.contact_button_label");
      } else {
        return t("tenant_hub.leads.dialog.confirm_button_label");
      }
    } else {
      return t("continue");
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogHeader>{title}</DialogHeader>
      <DialogForm noValidate>
        <DialogContent>
          {errorSendingLead && (
            <Notice type="warning">
              {t("tenant_hub.leads.dialog.error_sending_lead")}
            </Notice>
          )}
          {isSigned && showFormContent && (
            <Notice type="info">
              <Flex
                justify="center"
                align="center"
                gap
              >
                <IconInformationCircle size="medium" />
                <strong>{notice}</strong>
              </Flex>
            </Notice>
          )}
          {showIntro && (
            <DialogLeadIntro
              description={description}
              introImage={introImage}
            />
          )}
          {showFormContent && (
            <DialogLeadForm
              register={register}
              control={control}
              errors={errors}
              agreeText={agreeText}
              isInternet={isInternet}
            />
          )}
          {showSuccess && <DialogLeadSucess />}
        </DialogContent>
        {!isButtonHidden && (
          <DialogFooter>
            <Button
              fullWidth
              disabled={isSubmitting}
              variant="branded"
              onClick={handleFormSubmit}
              loading={isSubmitting}
            >
              {getButtomLabel(dialogView)}
            </Button>
          </DialogFooter>
        )}
      </DialogForm>
    </Dialog>
  );
};
