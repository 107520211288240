export const ListIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6474 26.1487H51.9554C55.2044 26.1487 55.2044 30.8512 51.9554 30.8512H26.6474C25.3792 30.8512 24.2962 29.7682 24.2962 28.5C24.2962 27.0607 25.3792 26.1487 26.6474 26.1487ZM26.6474 8.07974H51.9554C55.2044 8.07974 55.2044 12.7822 51.9554 12.7822H26.6474C23.3984 12.7822 23.3984 8.07974 26.6474 8.07974ZM26.6474 44.2177H51.9554C55.2044 44.2177 55.2044 48.9202 51.9554 48.9202H26.6474C23.3984 48.9202 23.3984 44.2177 26.6474 44.2177Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47986 17.07C13.2741 17.07 16.3499 13.9942 16.3499 10.2C16.3499 6.40576 13.2741 3.32996 9.47986 3.32996C5.68567 3.32996 2.60986 6.40576 2.60986 10.2C2.60986 13.9942 5.68567 17.07 9.47986 17.07ZM10.3772 13.3413H9.26541V8.08105H9.23904L7.59988 9.20605V8.16455L9.25662 7H10.3772V13.3413Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47986 34.74C13.2741 34.74 16.3499 31.6642 16.3499 27.87C16.3499 24.0758 13.2741 21 9.47986 21C5.68567 21 2.60986 24.0758 2.60986 27.87C2.60986 31.6642 5.68567 34.74 9.47986 34.74ZM11.7719 31H7.3158V30.2266L9.55701 28.0425C10.3876 27.2295 10.5677 26.9087 10.5677 26.4385V26.4297C10.5634 25.8628 10.1371 25.4585 9.52625 25.4585C8.82312 25.4585 8.33093 25.9287 8.31775 26.5396V26.5703H7.25427V26.5396C7.25427 25.3794 8.22986 24.5488 9.51746 24.5488C10.7875 24.5488 11.6752 25.3179 11.6752 26.3638V26.3726C11.6752 27.1196 11.328 27.625 10.2118 28.6973L8.81433 30.0244V30.0728H11.7719V31Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47986 53.74C13.2741 53.74 16.3499 50.6642 16.3499 46.87C16.3499 43.0758 13.2741 40 9.47986 40C5.68567 40 2.60986 43.0758 2.60986 46.87C2.60986 50.6642 5.68567 53.74 9.47986 53.74ZM11.8466 48.2334C11.8466 49.3364 10.8798 50.1099 9.47791 50.1099C8.08484 50.1099 7.17957 49.3628 7.09167 48.2993L7.08728 48.2466H8.17273L8.17712 48.2949C8.23865 48.8091 8.73083 49.1826 9.47351 49.1826C10.2074 49.1826 10.6952 48.7783 10.6952 48.2026V48.1938C10.6952 47.5259 10.203 47.1567 9.39441 47.1567H8.64294V46.313H9.36804C10.0668 46.313 10.5326 45.9175 10.5326 45.3638V45.355C10.5326 44.7837 10.1459 44.4497 9.46472 44.4497C8.79675 44.4497 8.33972 44.7969 8.28259 45.3418L8.2782 45.3857H7.22351L7.22791 45.333C7.32019 44.2607 8.18591 43.5488 9.46472 43.5488C10.7743 43.5488 11.6224 44.2212 11.6224 45.2056V45.2144C11.6224 46.001 11.0511 46.5239 10.2909 46.6821V46.7041C11.2269 46.792 11.8466 47.3457 11.8466 48.2246V48.2334Z"
      fill="white"
    />
  </svg>
);
