/* @jsx jsx */
import { forwardRef } from "react";
import {
  Text,
  Flex,
  RadioFieldNaked,
  Box,
  styled,
  useTheme,
} from "@boligportal/juice";
import { css, jsx } from "@emotion/react";
import { Avatar } from "components/design-system/atoms/avatar/avatar";
import { timePassedSinceShort } from "lib/date";
import { t } from "lib/i18n";
import { stripHtml } from "lib/utils";

const StyledLabel = styled.label<{
  selected: boolean;
}>`
  border-top: 1px solid ${(props) => props.theme.color.border};
  cursor: pointer;
  margin: 0;
  padding: ${(props) => props.theme.unit(2)};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.theme.unit(3)};
  }

  &:hover {
    background: ${(props) => props.theme.color.bg.tint};
  }

  ${(props) =>
    props.selected &&
    `
    background: ${props.theme.color.bg.tint};
  `}
`;

const TenantItem = forwardRef<
  HTMLLabelElement,
  {
    image?: string;
    name: string;
    message: string;
    date: string;
    selected: boolean;
    latestMessageSelf: boolean;
    onSelect: () => void;
  }
>(
  (
    { message, selected, name, image, date, latestMessageSelf, onSelect },
    ref,
  ) => {
    const theme = useTheme();

    TenantItem.displayName = "TenantItem";

    const strippedMessage = message ? stripHtml(message) : "";

    return (
      <StyledLabel
        ref={ref}
        selected={selected}
      >
        <Flex
          align="center"
          gap
        >
          <Box>
            <RadioFieldNaked
              checked={selected}
              onChange={onSelect}
            />
          </Box>

          <Box>
            <Avatar
              src={image}
              size={60}
            />
          </Box>

          <Box flexGrow>
            <Flex justify="space-between">
              <Box>
                <Text weight="semiBold">{name}</Text>
              </Box>

              <Box>
                <Text
                  color="muted"
                  size="small"
                >
                  {timePassedSinceShort(new Date(date))}
                </Text>
              </Box>
            </Flex>

            {message && (
              <Box
                flexGrow
                truncate={1}
                css={css`
                  color: ${theme.color.text.muted};
                `}
                /* NOTE: Above CSS is just to change color of the ellipsis */
              >
                <Text
                  color="muted"
                  size="small"
                >
                  {latestMessageSelf ? `${t("inbox.you")}: ` : ""}
                  {strippedMessage}
                </Text>
              </Box>
            )}
          </Box>
        </Flex>
      </StyledLabel>
    );
  },
);

export { TenantItem };
