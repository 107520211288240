import { useEffect, useState, useContext } from "react";
import {
  dialogActions,
  Divider,
  Text,
  useNotifications,
  Confirm,
} from "@boligportal/juice";
import { API, InTouchTenant } from "lib/api";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { ChooseTenantRejection } from "./choose_tenant_rejection";
import { ChooseTenantList } from "./choost_tenant_list";

enum ChooseTenantSteps {
  List = "LIST",
  RejectionMessage = "REJECTION",
}

interface ChooseTenantProps {
  adId: number;
  rentableId: number;
  preselectTenantId?: number;
  onMarkedRentedOut: () => void;
}
const ChooseTenant = ({
  adId,
  rentableId,
  preselectTenantId,
  onMarkedRentedOut,
}: ChooseTenantProps) => {
  const actions = useContext(dialogActions);

  const { addNotification } = useNotifications();

  const [step, setStep] = useState<ChooseTenantSteps>(ChooseTenantSteps.List);
  const [tenants, setTenants] = useState<InTouchTenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<InTouchTenant>();
  const [sendRejection, setSendRejection] = useState(true);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    (async function fetchTenants() {
      const response = await API.getTenantsInTouch(rentableId);
      setTenants(response.results);
    })();
  }, [rentableId]);

  useEffect(() => {
    if (preselectTenantId) {
      const preselectedTenant = tenants.find(
        (tenant) => tenant.user_id === preselectTenantId,
      );

      if (preselectedTenant) {
        setSelectedTenant(preselectedTenant);
      }
    }
  }, [tenants, preselectTenantId]);

  const handleMarkRentedOut = async () => {
    setLoading(true);

    try {
      await API.markAdRentedOut(
        adId,
        selectedTenant?.user_id,
        rejectionMessage,
      );

      if (selectedTenant) {
        TrackingEvent.markedRentedOutWithTenant();
      } else {
        TrackingEvent.markedRentedOutWithRejectionMessage();
      }

      if (rejectionMessage) {
        TrackingEvent.markedRentedOutWithRejectionMessage();
      } else {
        TrackingEvent.markedRentedOutWithoutRejectionMessage();
      }

      actions.hide();
      onMarkedRentedOut();
    } catch (error) {
      setLoading(false);

      addNotification &&
        addNotification({
          title: t("rentable_details.choose_tenant.something_went_wrong"),
          autoDismiss: true,
        });
    }
  };

  const handleContinue = (skipSelection: boolean = false) => {
    if (skipSelection) {
      setSelectedTenant(undefined);
    }

    if (!tenants.length) {
      handleMarkRentedOut();
    } else {
      setStep(ChooseTenantSteps.RejectionMessage);
    }
  };

  const handleBack = () => {
    setStep(ChooseTenantSteps.List);
  };

  const rejectionRecipientCount = selectedTenant
    ? tenants.length - 1
    : tenants.length;

  return (
    <>
      {step === ChooseTenantSteps.List && (
        <ChooseTenantList
          tenants={tenants}
          selectedTenant={selectedTenant}
          onContinue={handleContinue}
          onTenantSelect={setSelectedTenant}
        />
      )}

      {step === ChooseTenantSteps.RejectionMessage && (
        <ChooseTenantRejection
          selectedTenant={selectedTenant}
          recipientCount={rejectionRecipientCount}
          rejectionMessage={rejectionMessage}
          sendRejection={sendRejection}
          onBack={handleBack}
          onContinue={() => setShowConfirm(true)}
          onMessageChange={setRejectionMessage}
          onSendRejectionChange={setSendRejection}
        />
      )}

      <Confirm
        open={showConfirm}
        size="large"
        title={t("rentable_details.choose_tenant.mark_rented_out")}
        loading={loading}
        message={
          <>
            <Text
              paragraph
              weight="bold"
            >
              {t("rentable_details.choose_tenant.are_you_sure")}
            </Text>

            {selectedTenant && (
              <Text block>
                -{" "}
                {t(
                  "rentable_details.choose_tenant.selected_tenant_and_conversations",
                  {
                    name: selectedTenant.display_name,
                  },
                )}
              </Text>
            )}

            {sendRejection && (
              <Text block>
                -{" "}
                {t("rentable_details.choose_tenant.rejection_recipient_count", {
                  count: rejectionRecipientCount,
                })}
              </Text>
            )}

            <Divider />

            <Text block>
              - {t("rentable_details.choose_tenant.no_longer_listed")}
            </Text>
            <Text block>
              - {t("rentable_details.choose_tenant.active_subscriptions_text")}
            </Text>
          </>
        }
        confirmText={t("rentable_details.choose_tenant.confirmation_continue")}
        cancelText={t("rentable_details.choose_tenant.confirmation_cancel")}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => handleMarkRentedOut()}
      />
    </>
  );
};

export { ChooseTenant };
