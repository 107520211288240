export enum PaymentMethodType {
  ManualBankTransfer = "manual_bank_transfer",
  QuickPay = "credit_card",
  MobilePay = "mobile_pay",
  Account = "account",
  Free = "free",
  Invoice = "invoice",
  IOS = "ios",
  Android = "android",
  ApplePay = "apple_pay",
  GooglePay = "google_pay",
}

export type OptionalPaymentMethod<K extends PaymentMethodType, T> = {
  [P in K]?: T;
};
