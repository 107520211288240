import { Text, styled, Badge } from "@boligportal/juice";
import { t } from "lib/i18n";

const StyledBadge = styled(Text)`
  position: absolute;
  top: ${(props) => props.theme.unit(2)};
  left: ${(props) => props.theme.unit(2)};
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    top: ${(props) => props.theme.unit(2)};
    left: ${(props) => props.theme.unit(2)};
  }
`;

export const FreeContactBadge = () => (
  <StyledBadge size="small">
    <Badge
      state="success"
      variant="solid"
    >
      {t("ad_card.open_contact")}
    </Badge>
  </StyledBadge>
);

export const ContactedBadge = () => (
  <StyledBadge size="small">
    <Badge
      color="green"
      variant="solid"
    >
      {t("ad_card.contacted")}
    </Badge>
  </StyledBadge>
);

export const UnavailableBadge = () => (
  <StyledBadge size="small">
    <Badge
      state="warning"
      variant="solid"
    >
      {t("ad_card.unavailable")}
    </Badge>
  </StyledBadge>
);
