import * as React from "react";

const SvgTenantHubInternet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <g clipPath="url(#Tenant_hub_Internet_svg__Tenant_hub_Internet_svg__clip0_1094_20980)">
      <circle
        cx={12.57}
        cy={12.02}
        r={11.15}
        fill="#FFDCC7"
      />
      <circle
        cx={12.18}
        cy={12.23}
        r={10.86}
        stroke="#fff"
        strokeWidth={0.69}
        transform="rotate(-37.644 12.18 12.23)"
      />
      <path
        stroke="#fff"
        strokeWidth={0.69}
        d="M6.817 2.722s1.918-.056 4.658.931c1.777.64 4.402 2.498 5.644 3.818.644.684 1.635 2.204 2.337 3.597.771 1.531 1.106 2.98 1.29 4.039.61 3.48.2 3.568.2 3.568M4.495 4.586s-.541 1.84-.282 4.742c.168 1.882 1.298 4.891 2.259 6.429.498.796 1.716 2.14 2.886 3.173 1.285 1.135 2.601 1.826 3.578 2.274 3.212 1.474 3.4 1.099 3.4 1.099"
      />
      <path
        stroke="#fff"
        strokeWidth={0.69}
        d="M4.72 20.246s6.782-.914 10.69-3.357c4.55-2.846 6.327-9.77 6.327-9.77M1.787 16.16s6.508-.702 10.415-3.145c4.551-2.846 6.328-9.77 6.328-9.77M1.125 10.761s3.783.306 7.69-2.138c4.55-2.846 5.116-7.083 5.116-7.083M5.719 3.57l13.302 17.245"
      />
    </g>
    <defs>
      <clipPath id="Tenant_hub_Internet_svg__Tenant_hub_Internet_svg__clip0_1094_20980">
        <path
          fill="#fff"
          d="M0 0h24v24H0z"
          transform="translate(.66)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTenantHubInternet;
