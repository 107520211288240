import { Box, Flex, styled, Hidden, useTheme } from "@boligportal/juice";
import { isBoligPortal } from "lib/utils";
import { LandingPageContainer } from "./LandingPageContainer";
import { PageBlock } from "./PageBlock";

const NewDesignBadge = styled.div`
  color: #ffffff;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8bGluZWFyR3JhZGllbnQgaWQ9Imc5OTAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMTAwJSIgeTE9IjEwMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRUVCODE2IiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjRUQ2QjJEIiBvZmZzZXQ9IjEiLz4KPC9saW5lYXJHcmFkaWVudD4KPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNnOTkwKSIgLz4KPC9zdmc+);
  background-repeat: no-repeat;
  background-size: cover;
`;

const NewDesignHeader = styled.h2`
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.13px;
  color: #191919;
`;

const NewDesignSubHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1c1c;
`;

const VideoInboxSection = ({
  badgeText,
  headline,
  subHeadline,
}: {
  badgeText: string;
  headline: string;
  subHeadline: string;
}) => {
  const theme = useTheme();
  let video;
  let mobileVideo;

  if (isBoligPortal()) {
    video =
      "497218710.hd.mp4?s=2ca2aa9b2a79908af8d17c34e766d3023ea59f3f&profile_id=174";
    mobileVideo =
      "497218755.hd.mp4?s=8cf2a5c0d01013b33659abbc9fc81ddc853a2c1a&profile_id=174";
  } else {
    video =
      "413589863.hd.mp4?s=dca4134992bd620f41f9b8d8c7deffecdc7e22b2&profile_id=175";
    mobileVideo =
      "415140313.hd.mp4?s=db447a9fb50d2f02469bcf752bf58b116363f53a&profile_id=175";
  }

  return (
    <PageBlock
      bg="default"
      py="extraLarge"
      pyMobile="large"
    >
      <LandingPageContainer>
        <Flex
          textAlign="center"
          align="center"
          column
        >
          <Box my={2}>
            <NewDesignBadge>{badgeText}</NewDesignBadge>
          </Box>
          <NewDesignHeader>{headline}</NewDesignHeader>

          <Box
            mt={2}
            mb={6}
            maxWidth={{
              xs: "100%",
              sm: "500px",
            }}
          >
            <NewDesignSubHeader>{subHeadline}</NewDesignSubHeader>
          </Box>
          <Hidden
            on={{
              xs: true,
              md: false,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: theme.shadow.lg,
                padding: theme.unit(1),
                paddingBottom: "2px",
                width: "100%",
                zIndex: 2,
                borderRadius: 4,
              }}
            >
              <video
                width="100%"
                loop
                autoPlay
                muted
                playsInline
              >
                <source
                  src={`https://player.vimeo.com/external/${video}`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Hidden>

          <Hidden
            on={{
              xs: false,
              md: true,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: theme.shadow.lg,
                padding: theme.unit(1),
                paddingBottom: "2px",
                width: "80%",
                zIndex: 2,
                borderRadius: 4,
              }}
            >
              <video
                width="100%"
                loop
                autoPlay
                muted
                playsInline
              >
                <source
                  src={`https://player.vimeo.com/external/${mobileVideo}`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Hidden>
        </Flex>
      </LandingPageContainer>
    </PageBlock>
  );
};

export { VideoInboxSection };
