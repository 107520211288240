import { Box, Flex, Text } from "@boligportal/juice";
import { MovingReportListItem } from "apps/moving_reports/store/slices/movingReports";
import { localizedMonthDayAndYear, localizedTimeOnly } from "lib/date";

export const InspectionDateCellRenderer = (item: MovingReportListItem) => {
  const { inspection_date } = item;

  const date = inspection_date
    ? localizedMonthDayAndYear(new Date(inspection_date))
    : "-";
  const time = inspection_date
    ? localizedTimeOnly(new Date(inspection_date)).toLowerCase()
    : null;

  return (
    <Flex column>
      <Box>
        <Text size="small">{date}</Text>
      </Box>

      {time && (
        <Box>
          <Text
            color="muted"
            size="small"
          >
            {time}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
