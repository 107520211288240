import { Center, Headline, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

export const ContractsNoItemsEmptyState = () => (
  <Center
    height="100%"
    pb={12}
  >
    <Headline as="h3">
      {t("contracts_list.no_items.empty_state.header")}
    </Headline>
    <Text size="body">{t("contracts_list.no_items.empty_state.body")}</Text>
  </Center>
);
