import { Text } from "@boligportal/juice";

export type MovingReportInternalIdCell = {
  movingReportInternalIdCell: {
    text: string;
  };
};

export const movingReportInternalIdCellRenderer = ({
  movingReportInternalIdCell,
}: MovingReportInternalIdCell) => (
  <Text size="small">{movingReportInternalIdCell.text}</Text>
);
