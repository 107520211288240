import { Image } from "components/interfaces/image";
import { SortableImage } from "../interfaces/SortableImage";

export const convertFromRentableImages = (
  rentableImages: Image[],
): SortableImage[] =>
  rentableImages.map((rentableImage) => ({
    id: rentableImage.image_id!.toString(),
    url: rentableImage.url,
    isFloorPlan: rentableImage.is_floor_plan ?? false,
  }));

export const convertFromSortableImages = (
  sortableImages: SortableImage[],
): Image[] =>
  sortableImages.map((sortableImage) => ({
    image_id: Number(sortableImage.id),
    url: sortableImage.url,
    is_floor_plan: sortableImage.isFloorPlan ?? false,
  }));
