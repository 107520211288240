import { useEffect } from "react";
import { Box, Button, Flex, Text, CardSection } from "@boligportal/juice";
import { LatLngLocation } from "components/interfaces/location";
import { useAdLocationFeature } from "../hooks/use_ad_location_feature";
import { CustomerServiceLocationMap } from "./customer_service_location_map";

// This View component implements the "Ad Location Feature" using a Map
// to display and edit the Location of the given adId
const MapLocationManager = ({ adId }: { adId: number }) => {
  const {
    location,
    fetchAdLocation,
    newLocation,
    updateLocation,
    canSave,
    save,
  } = useAdLocationFeature();

  useEffect(() => {
    fetchAdLocation(adId);
  }, [adId, fetchAdLocation]);

  const saveButtonClickHandler = () => {
    save(adId);
  };

  return (
    <CardSection
      title={`Ad Location (lat: ${location?.lat ?? ""} lng: ${
        location?.lng ?? ""
      })`}
    >
      <Box
        width="100%"
        bg="tint"
      >
        {location && (
          <CustomerServiceLocationMap
            location={location}
            onMapMove={updateLocation}
          />
        )}
      </Box>
      <Flex
        justify="space-between"
        align="center"
        pt={2}
      >
        <Box>
          {newLocation && <LocationDisplay location={newLocation} />}
          {!newLocation && (
            <Text size="tiny">
              <em>
                Note: Position the Map at desired center location and click the
                save button
              </em>
            </Text>
          )}
        </Box>
        <Button
          disabled={!canSave}
          onClick={saveButtonClickHandler}
        >
          Save
        </Button>
      </Flex>
    </CardSection>
  );
};

export { MapLocationManager };

// ************************************************************************************************
// Components used only by MapLocationManager component
// ************************************************************************************************

const LocationDisplay = ({ location }: { location: LatLngLocation }) => (
  <Text size="tiny">
    New unsaved location: {`Lat: ${location.lat} Lng: ${location.lng}`};
  </Text>
);
