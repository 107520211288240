import {
  Button,
  IconEllipsisVertical,
  Menu,
  MenuBaseItem,
} from "@boligportal/juice";
import {
  DangerMenuItem,
  DocumentMenuItem,
  LogoMenuItem,
  MovingMenuItem,
} from "components/cells/components/CustomMenuItems";
import { t } from "lib/i18n";

export interface RentableElipsisMenuItem
  extends MenuBaseItem<RentableElipsisMenuItem> {
  optionId?: string;
}

export type RentableElipsisMenuCell = {
  rentableElipsisMenuCell: {
    onMenuItemSelect: (item: RentableElipsisMenuItem) => void;
  };
};

export enum RentableElipsisActions {
  CreateListing = "CreateListing",
  CreateContract = "CreateContract",
  CreateMovingReport = "CreateMovingReport",
  Delete = "Delete",
}

export const RentableElipsisCellRenderer = ({
  rentableElipsisMenuCell,
}: RentableElipsisMenuCell) => {
  const { onMenuItemSelect } = rentableElipsisMenuCell;

  const items: RentableElipsisMenuItem[] = [
    {
      optionId: RentableElipsisActions.CreateListing,
      name: t("common.list_rentable"),
      renderFunction: LogoMenuItem,
    },
    {
      optionId: RentableElipsisActions.CreateContract,
      name: t("common.create_contract"),
      renderFunction: DocumentMenuItem,
    },
    {
      optionId: RentableElipsisActions.CreateMovingReport,
      name: t("common.create_moving_report"),
      renderFunction: MovingMenuItem,
    },
    {},
    {
      optionId: RentableElipsisActions.Delete,
      name: t("Delete"),
      renderFunction: DangerMenuItem,
    },
  ];

  return (
    <Menu<RentableElipsisMenuItem>
      align="end"
      verticalContentPadding={0}
      verticalSeparatorPadding={0}
      onMenuItemSelect={onMenuItemSelect}
      items={items}
      openerEl={
        <Button
          variant="subtle"
          icon={IconEllipsisVertical}
        />
      }
    />
  );
};
