import { Box, Flex, Paginator } from "@boligportal/juice";
import { t } from "lib/i18n";
import {
  useChooseOrCreateRentableFeature,
  useChooseOrCreateRentableState,
} from "../ChooseOrCreateRentableProvider";

export const ChooseOrCreateRentablePaginator = () => {
  const { filterCount, limit, start_index } = useChooseOrCreateRentableState();
  const { updatePaginationStartIndex } = useChooseOrCreateRentableFeature();

  return (
    <Flex
      column
      align="center"
    >
      <Box py={3}>
        <Paginator
          totalRecords={filterCount}
          pageLimit={limit}
          value={start_index}
          nextButtonLabel={t("paginator.next")}
          previousButtonLabel={t("paginator.previous")}
          onChange={updatePaginationStartIndex}
        />
      </Box>
    </Flex>
  );
};
