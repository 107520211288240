import { useMutation, useQueryClient } from "react-query";
import { Box, Link, Text, useNotifications } from "@boligportal/juice";
import * as api from "api";
import { GetFavoritesIdsResponsePayload } from "api";
import { useSettings } from "components/Providers/SettingsProvider";
import { t } from "lib/i18n";
import { favoriteKeys } from "./queries";

export const useMarkAdAsFavouriteMutation = (adId: number) => {
  const { addNotification } = useNotifications();
  const { routes } = useSettings();
  const queryClient = useQueryClient();

  const onAddFavourite = () => {
    addNotification({
      autoDismiss: true,
      content: (
        <Text
          color="inverted"
          size="small"
        >
          <Box mb={1}>{t("ad_favourite.added_as_favourite.text")}</Box>

          <Link
            href={routes.my_favourite_ads}
            color="inherit"
          >
            {t("ad_favourite.added_as_favourite.link")}
          </Link>
        </Text>
      ),
    });
  };

  const onRemoveFavourite = () => {
    addNotification({
      autoDismiss: true,
      content: t("ad_favourite.removed_from_favourite.text"),
    });
  };

  const optimisticallyToggleFavourite = (isFavorited: boolean) => {
    queryClient.setQueriesData<GetFavoritesIdsResponsePayload | undefined>(
      favoriteKeys.favouriteListing(),
      (previous) => {
        if (!previous) {
          return previous;
        }

        return {
          favorite_ad_ids: isFavorited
            ? [...previous.favorite_ad_ids, adId]
            : previous.favorite_ad_ids.filter((id) => id !== adId),
        };
      },
    );
  };

  return useMutation(
    ({ markAsFavourite }: { markAsFavourite: boolean }) =>
      api.markAdAsFavourite({
        adId,
        markAsFavourite,
      }),
    {
      onSuccess: (_, { markAsFavourite }) => {
        if (markAsFavourite) {
          onAddFavourite();
        } else {
          onRemoveFavourite();
        }

        optimisticallyToggleFavourite(markAsFavourite);

        queryClient.invalidateQueries(favoriteKeys.favouriteListing());
        queryClient.invalidateQueries(favoriteKeys.favouriteAds());
      },
    },
  );
};
