import { memo } from "react";
import { UseFormRegister } from "react-hook-form";
import { Box, Flex, TextField } from "@boligportal/juice";
import { format, addMonths } from "date-fns";
import { EmailLogsFilters } from "lib/customerServiceApi";

type Props = {
  register: UseFormRegister<EmailLogsFilters>;
};

export const EmailLogFilters = memo(({ register }: Props) => {
  const today = new Date();
  const maxDate = format(today, "yyyy-MM-dd");

  return (
    <Flex
      mt={2}
      gap
    >
      <Box width="300px">
        <TextField
          label="Email address"
          {...register("email")}
        />
      </Box>
      <Box width="300px">
        <TextField
          label="Email topic"
          {...register("email_topic")}
        />
      </Box>
      <Box>
        <Flex gap={0.5}>
          <Box width="175px">
            <TextField
              max={maxDate}
              type="date"
              label="From"
              {...register("date_from")}
            />
          </Box>
          <Box width="175px">
            <TextField
              max={maxDate}
              type="date"
              label="To"
              {...register("date_to")}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
});
