import * as React from "react";

const SvgDankort = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    viewBox="0 0 44 28"
    {...props}
  >
    <defs>
      <path
        id="Dankort_svg__a"
        d="M.005.014H36.74v23.841H.005z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <path
        fill="#FFF"
        stroke="#DDD"
        d="M3 .5A2.5 2.5 0 00.5 3v22A2.5 2.5 0 003 27.5h38a2.5 2.5 0 002.5-2.5V3A2.5 2.5 0 0041 .5H3z"
      />
      <g transform="matrix(1 0 0 -1 3 26)">
        <mask
          id="Dankort_svg__b"
          fill="#fff"
        >
          <use xlinkHref="#Dankort_svg__a" />
        </mask>
        <g
          fillRule="nonzero"
          mask="url(#Dankort_svg__b)"
        >
          <path
            fill="#FFF"
            d="M11.073 22.58C5.093 22.58.227 17.806.227 11.936S5.093 1.289 11.073 1.289h14.605c5.98 0 10.844 4.777 10.844 10.647s-4.865 10.646-10.844 10.646H11.073z"
          />
          <path
            fill="#FFF"
            d="M11.073 22.58C5.093 22.58.227 17.806.227 11.936S5.093 1.289 11.073 1.289h14.605c5.98 0 10.844 4.777 10.844 10.647s-4.865 10.646-10.844 10.646H11.073zM25.678 1.073H11.073C4.97 1.072.007 5.945.007 11.935s4.964 10.862 11.066 10.862h14.605c6.1 0 11.064-4.873 11.064-10.862 0-5.99-4.963-10.863-11.064-10.863"
          />
          <path
            fill="#E41D27"
            d="M28.231 12.585l4.857-5.67a8.69 8.69 0 01-.172 10.276l-4.685-4.606zM14.469 17.67c3.627 0 6.36-.907 6.64-3.907l3.818 3.907h7.6a9.06 9.06 0 01-6.849 3.107H11.073a9.06 9.06 0 01-6.851-3.108h10.247zm-5.976-3.783l-1.295-3.496h5.225c1.545 0 2.012.628 2.358 1.837.342 1.196-.517 1.66-1.573 1.66H8.493zm16.466-7.45l-4.037 5.01c-.747-3.416-3.19-5.009-7.771-5.009H4.022a9.057 9.057 0 017.05-3.346h14.606a9.056 9.056 0 017.05 3.345h-7.77z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgDankort;
