import { Tracking } from "lib/tracking/common";

export const trackUserClicksRentalDataSideBarMenuItem = () => {
  Tracking.trackEvent({
    category: "rental data",
    action: "clicks sidebar item",
    label: "rental data empty state",
    isInteraction: true,
  });
};

export type RentalDataCTASection =
  | "bulletSection"
  | "featuresSection"
  | "testimonialsSection";

export const trackUserClicksCTA = (from: RentalDataCTASection) => {
  const actionMap = {
    bulletSection: "clicks CTA value prop",
    featuresSection: "clicks CTA features",
    testimonialsSection: "clicks CTA testimonials",
  };

  Tracking.trackEvent({
    category: "rental data",
    action: actionMap[from],
    label: "rental data empty state",
    isInteraction: true,
  });
};

export const trackUserClicksCloseBuyRentalDataDialog = () => {
  Tracking.trackEvent({
    category: "rental data",
    action: "clicks sidebar item",
    label: "rental data empty state",
    isInteraction: true,
  });
};
