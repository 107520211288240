import {
  CheckField,
  Flex,
  Spinner,
  useNotifications,
} from "@boligportal/juice";
import { useMutationNewBuildBoostPackage } from "lib/customerServiceMutations";
import { NewBuildDateDisplay } from "./NewBuildDateDisplay";

type NewBuildBoostPackageProps = {
  isActive: boolean;
  advertiseDate: string | undefined;
  newBuildId: number;
};

export const NewBuildBoostPackage = ({
  isActive,
  advertiseDate,
  newBuildId,
}: NewBuildBoostPackageProps) => {
  const { isLoading, mutateAsync } = useMutationNewBuildBoostPackage();

  const { addNotification } = useNotifications();

  const displaySaveNotification = () => {
    if (addNotification) {
      addNotification({
        title: "Saving data...",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  const displayErrorNotification = () => {
    if (addNotification) {
      addNotification({
        content:
          "An unexpected error occured while activating the boost package",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  };

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      await mutateAsync({
        newbuildId: newBuildId,
        value: event.target.checked,
      });
      displaySaveNotification();
    } catch (error) {
      displayErrorNotification();
    }
  };

  return (
    <Flex column>
      {isLoading ? (
        <Spinner />
      ) : (
        <CheckField
          label="Enable"
          checked={isActive}
          onChange={handleCheckboxChange}
          disabled={isActive}
        />
      )}
      <NewBuildDateDisplay
        label="Active until:"
        date={advertiseDate}
      />
    </Flex>
  );
};
