import { Box, Button, SelectField } from "@boligportal/juice";
import { useUser } from "components/Providers/UserProvider";
import { Plan } from "components/interfaces/plan";
import { SellingPoint } from "features/upsell/components/SellingPoint/SellingPoint";
import { trafficMagnitude } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getBenefitsAfterCreateAdFlow, getProductName } from "lib/product";
import { calculatePrice, formatCurrency } from "lib/utils";
import { HoverableCardHeader } from "./HoverableCardHeader";
import { StyledHoverableCard } from "./styles";

type Props = {
  isHovered: boolean;
  plans: Plan[];
  selectedPlan: number;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onRequestBuyPlan: (plan: Plan) => void;
  disableAd: boolean;
  isPrivateLandlord: boolean;
  isAdLessThan24HoursOld: boolean;
};

export const HoverableCard = ({
  isHovered,
  plans,
  selectedPlan,
  handleChange,
  onRequestBuyPlan,
  disableAd,
  isPrivateLandlord,
  isAdLessThan24HoursOld,
}: Props) => {
  const { isPartOfACompany } = useUser();
  const hasMultiplePlans = plans.length > 1;
  return (
    <StyledHoverableCard isHovered={isHovered}>
      <HoverableCardHeader
        trafficMagnitude={trafficMagnitude(plans[selectedPlan].product.name)}
        trafficMagnitudeText={t(
          "upsell_products.pro_landlord.traffic_magnitude.text",
        )}
        title={getProductName(plans[selectedPlan].product)}
        priceOriginalLabel={
          plans[selectedPlan].price.percent_off
            ? formatCurrency(
                plans[selectedPlan].price.total,
                plans[selectedPlan].currency,
              )
            : undefined
        }
        priceLabel={formatCurrency(
          calculatePrice(
            plans[selectedPlan].price.total,
            plans[selectedPlan].price.percent_off,
          ),
          plans[selectedPlan].currency,
        )}
        priceExVatLabel={`(${formatCurrency(
          calculatePrice(
            plans[selectedPlan].price.subtotal,
            plans[selectedPlan].price.percent_off,
          ),
          plans[selectedPlan].currency,
        )} ${t("upsell_page.ex_vat")})`}
        mutedCaptionText={
          !hasMultiplePlans && plans[selectedPlan].is_subscription
            ? tn(
                "upsell_products.runningPeriod",
                plans[selectedPlan].duration_days,
              )
            : undefined
        }
        mutedCaptionText2={
          !hasMultiplePlans && plans[selectedPlan].is_subscription
            ? t("product_explainer.cancel_any_time")
            : undefined
        }
        isPrivateLandlord={isPrivateLandlord}
        isPopular={
          !isPrivateLandlord
            ? plans[0].product.name === "exposure_ad"
            : plans[0].product.name === "promote_ad"
        }
      />
      {hasMultiplePlans ? (
        <SelectField
          label={t("upsell.selected_duration_label")}
          items={plans.map((plan, index) => ({
            label: tn(
              "fixed_upsell_products.runningPeriod",
              plan.duration_days,
            ),
            value: index.toString(),
          }))}
          onChange={handleChange}
          value={selectedPlan?.toString()}
        />
      ) : null}
      <Box mt={2}>
        <Button
          fullWidth
          variant="primary"
          onClick={() => onRequestBuyPlan(plans[selectedPlan])}
          disabled={disableAd}
        >
          {t("upsell_page.submit_button")}
        </Button>
      </Box>
      <Box
        pt={4}
        textAlign="left"
      >
        {getBenefitsAfterCreateAdFlow(
          plans[selectedPlan].product,
          isAdLessThan24HoursOld,
          isPartOfACompany,
        ).map((benefit, i) => (
          <SellingPoint
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            text={benefit}
          />
        ))}
      </Box>
    </StyledHoverableCard>
  );
};
