import { useContext } from "react";
import { Button, Confirm, dialogActions } from "@boligportal/juice";
import { fetchFromAPI } from "lib/api";
import { CompanyDetailsContext } from "./company_details_context";

type Props = {
  companyId: number;
};

export const ResetBalanceConfirmationDialog = ({ companyId }: Props) => {
  const actions = useContext(dialogActions);
  const { fetchCompany } = useContext(CompanyDetailsContext);

  const handleResetBalance = async () => {
    await fetchFromAPI(
      `/customer-service/api/companies/${companyId}/account/reset-balance/`,
      {
        method: "POST",
      },
    );

    fetchCompany(companyId);

    actions.hide();
  };

  return (
    <Confirm
      cancelText="Cancel"
      confirmText="Reset"
      dangerous
      message="Are you sure you want to reset the balance for this account?"
      onConfirm={handleResetBalance}
      opener={<Button>Reset balance</Button>}
      title="Reset balance"
    />
  );
};
