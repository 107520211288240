import { useMemo } from "react";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { Market } from "components/markets/market_settings";
import { getGooglePayPlanTranslation } from "../stripe/stripePlanTranslations";

const marketSettings: Record<
  Market,
  { countryCode: string; currencyCode: string }
> = {
  [Market.BOLIGPORTAL_DK]: {
    countryCode: "DK",
    currencyCode: "DKK",
  },
  [Market.BOSTADSPORTAL_SE]: {
    countryCode: "SE",
    currencyCode: "SEK",
  },
};

export const useGooglePay = (plan: Plan) => {
  const paymentRequest: google.payments.api.PaymentDataRequest = useMemo(() => {
    const { billingAgreement, description } = getGooglePayPlanTranslation(plan);
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["VISA", "MASTERCARD"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "stripe",
              "stripe:version": "2024-06-20",
              "stripe:publishableKey": App.settings.stripe_pk,
            },
          },
        },
      ],
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: billingAgreement,
        transactionNote: description,
        totalPrice: plan.price.total.toString(),
        currencyCode: marketSettings[App.settings.market].currencyCode,
        countryCode: marketSettings[App.settings.market].countryCode,
      },
      merchantInfo: {
        merchantId: "BCR2DN4TWXW47US2",
        merchantName: "BoligPortal A/S",
      },
    };
  }, [plan]);

  return {
    paymentRequest,
  };
};
