import { vestExtendConfig } from "apps/contracts/components/common/validation";
import { differenceInDays } from "date-fns";
import { t } from "lib/i18n";
import vest, { enforce, test } from "vest";
import { LeadForm } from "./LeadServiceDialog";

enforce.extend(vestExtendConfig);

export const tenantHubLeadsValidationSuite = vest.create((data: LeadForm) => {
  Object.keys(data).forEach((field) => {
    if (field === "isAgree") {
      test(field, t("tenant_hub.validation.tenant_agree"), () => {
        enforce(data[field]).isTruthy();
      });
    } else if (field === "cpr") {
      test(field, t("tenant_hub.validation.tenant_cpr"), () => {
        enforce(data[field]).isValidCpr();
      });
    } else if (field === "phone") {
      test(field, t("required_field.phone"), () => {
        enforce(data[field]).isValidInternationalPhoneNumber();
      });
    } else if (field === "email") {
      test(field, t("validation.invalid_email"), () => {
        enforce(data[field]).isEmail();
      });
    } else if (field === "move_in_date") {
      test(field, t("required_field.move_in_date_in_past"), () => {
        if (data["move_in_date"] !== null) {
          enforce(
            differenceInDays(
              new Date().setHours(0, 0, 0, 0),
              data["move_in_date"] !== null
                ? new Date(data["move_in_date"]).setHours(0, 0, 0, 0)
                : new Date(),
            ) <= 7,
          ).isTruthy();
          test(field, t("required_field.input"), () => {
            enforce(data[field]).isNotEmpty();
          });
        }
      });
    } else {
      test(field, t("requried_field.input"), () => {
        enforce(data[field]).isNotEmpty();
      });
    }
  });
});
