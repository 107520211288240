import { styled } from "@boligportal/juice";

type GridItems =
  | "volume_bar_1"
  | "volume_bar_2"
  | "volume_bar_3"
  | "volume_bar_4"
  | "label"
  | "plan_card_1"
  | "plan_card_2"
  | "plan_card_3"
  | "plan_card_4";

export const GridSmallerScreens = styled.div`
  padding-top: 24px;
  display: grid;
  gap: 8px 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "plan_card_1"
    "plan_card_2"
    "plan_card_3"
    "plan_card_4";
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "plan_card_1 plan_card_2"
      "plan_card_3 plan_card_4";
  }
`;

export const Grid = styled.div<{ numberOfPlans: number }>`
  display: grid;
  gap: 8px 16px;
  grid-template-columns: ${(props) => `repeat(${props.numberOfPlans}, 1fr)`};
  grid-template-rows: minmax(90px, 160px) minmax(20px, 60px) 1fr;
  grid-template-areas:
    "volume_bar_1 volume_bar_2 volume_bar_3 volume_bar_4"
    "label . . ."
    "plan_card_1 plan_card_2 plan_card_3 plan_card_4";
`;

export const GridItem = styled.div<{
  name: GridItems;
  align?: "start" | "end";
}>`
  grid-area: ${(props) => props.name};
  align-self: ${(props) => props.align ?? "start"};
  text-align: center;
`;

export const GridContainer = styled.div<{ numberOfPlans: number }>`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-template-columns: ${(props) => `repeat(${props.numberOfPlans}, 1fr)`};
  }
`;
