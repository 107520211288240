import * as React from "react";
import { useMovingReportIdFromParams } from "apps/moving_reports/hooks";
import { API } from "../api";
import { MovingReport } from "../interfaces/movingReport";

interface ContextInterface {
  report?: MovingReport;
  replaceReport: (report?: MovingReport) => void;
}

const MovingReportContext = React.createContext<ContextInterface>({
  replaceReport: () => {},
});

export const MovingReportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { movingReportId } = useMovingReportIdFromParams();

  const [report, setReport] = React.useState<MovingReport>();

  React.useEffect(() => {
    setReport(undefined);
    loadReport();
  }, [movingReportId]);

  const loadReport = async () => {
    try {
      const response = await API.getMovingReport(movingReportId);
      setReport(response.report);
    } catch {
      setReport(undefined);
    }
  };

  const replaceReport = (report: MovingReport) => {
    setReport(report);
  };

  return (
    <MovingReportContext.Provider
      value={{
        report,
        replaceReport,
      }}
    >
      {children}
    </MovingReportContext.Provider>
  );
};

export const useMovingReport = () => {
  const context = React.useContext(MovingReportContext);

  return {
    replaceReport: context.replaceReport,
    report: context.report!,
  };
};
