import { Badge, Text } from "@boligportal/juice";

const PaymentRefundedStatus = () => (
  <Text
    mb={2}
    size="small"
  >
    <Badge color="blue">Refunded</Badge>
  </Text>
);

export { PaymentRefundedStatus };
