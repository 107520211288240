import { SegmentedControl } from "@boligportal/juice";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { t } from "lib/i18n";

export const TenancyPaymentStatusControl = ({
  size,
  onChange,
  value,
}: {
  onChange: (value: string) => void;
  size: "tiny" | "small" | "body" | "lead";
  value: string;
}) => (
  <SegmentedControl
    fontSize={size}
    shadow="sm"
    onChange={onChange}
    value={value}
    options={[
      {
        value: TenancyPaymentStatus.NOT_PAID,
        label: t("rentable.rent.payment_state.not_paid"),
        activeColor: "yellow",
      },
      {
        value: TenancyPaymentStatus.PAID,
        label: t("rentable.rent.payment_state.paid"),
        activeColor: "green",
      },
    ]}
  />
);
