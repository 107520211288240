import { Flex, Text } from "@boligportal/juice";
import {
  ISearchGroup,
  ISearchItem,
  ISearchItemAd,
  ISearchItemUser,
  ISearchItemCompany,
} from "../interfaces";
import { SearchGroupBody } from "./search_group_body";
import { SearchGroupHeader } from "./search_group_header";
import { SearchItemAd } from "./search_item_ad";
import { SearchItemCompany } from "./search_item_company";
import { SearchItemUser } from "./search_item_user";

type Props = {
  searchGroups: ISearchGroup[];
};

const SearchItems = ({ searchGroups }: Props) => (
  <div>
    {searchGroups.map((group) => (
      <div key={group.type}>
        <Flex column>
          <SearchGroupHeader
            count={group.count}
            numItems={group.searchItems.length}
            title={group.title}
            seeAllLink={group.seeAllLink}
          />
          {group.searchItems.length === 0 && (
            <Text
              size="small"
              m={1}
            >
              <em>{group.helpText}</em>
            </Text>
          )}
          <SearchGroupBody
            searchItems={group.searchItems}
            renderer={(searchItem: ISearchItem) => {
              switch (group.type) {
                case "Users": {
                  return (
                    <SearchItemUser data={searchItem.data as ISearchItemUser} />
                  );
                }
                case "Ads": {
                  return (
                    <SearchItemAd data={searchItem.data as ISearchItemAd} />
                  );
                }
                case "Companies": {
                  return (
                    <SearchItemCompany
                      data={searchItem.data as ISearchItemCompany}
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            }}
          />
        </Flex>
      </div>
    ))}
  </div>
);

export { SearchItems };
