import { Box, Text } from "@boligportal/juice";

export const ContactInformation = ({ email, phone, feedName }) => (
  <>
    <Box mb={1}>
      <Text size="small">{"Email: "}</Text>
      <Text
        size="small"
        weight="bold"
      >
        {email}
      </Text>
    </Box>
    <Box mb={1}>
      <Text size="small">{"Phone: "}</Text>
      <Text
        size="small"
        weight="bold"
      >
        {phone}
      </Text>
    </Box>
    <Box mb={1}>
      <Text size="small">{"Feed name: "}</Text>
      <Text
        size="small"
        weight="bold"
      >
        {feedName}
      </Text>
    </Box>
  </>
);
