import { useState, useEffect } from "react";
import { Box, Card, CardSection, Text } from "@boligportal/juice";
import { getUser } from "lib/customerServiceApi";

export const LandlordInformation = (props: { landlordId: number }) => {
  const { viewModel } = useLandlordInformation(props.landlordId);
  return (
    <Card sectioned>
      <CardSection title="User information (database)">
        <Box mb={1}>
          ID:{" "}
          <Text
            weight="bold"
            size="small"
          >
            {viewModel?.id}
          </Text>
        </Box>
        <Box mb={1}>
          User email:{" "}
          <Text
            weight="bold"
            size="small"
          >
            {viewModel?.email}
          </Text>
        </Box>
        <Box mb={1}>
          User name:{" "}
          <Text
            weight="bold"
            size="small"
          >
            {viewModel?.first_name} {viewModel?.last_name}
          </Text>
        </Box>
        <Box mb={1}>
          Company id:{" "}
          <Text
            weight="bold"
            size="small"
          >
            {viewModel?.company_id}
          </Text>
        </Box>
      </CardSection>
    </Card>
  );
};

const useLandlordInformation = (landlordId: number) => {
  const [viewModel, setViewModel] = useState<{
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    company_id?: number | null;
  }>();

  useEffect(() => {
    getUser(landlordId).then((response) => {
      // TODO; what landlord information is importal to show?
      setViewModel({
        id: response.user.id,
        email: response.user.email,
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        company_id: response.user?.company_id,
      });
    });
  }, [landlordId]);

  return {
    viewModel,
  };
};
