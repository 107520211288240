import { Text, Flex, Box, Headline, styled, Badge } from "@boligportal/juice";
import { t } from "lib/i18n";
import { Slide, OnboardingSlideType } from "./Slide";

const StyledImg = styled.img`
  object-fit: contain;
`;

export const Slideshow = ({
  slides,
  currentSlide,
}: {
  slides: Slide[];
  currentSlide: number;
}) => (
  <Box my={2}>
    <Flex
      align="center"
      justify="center"
      column
      minHeight={"300px"}
    >
      <Flex
        justify="center"
        column
        height={"250px"}
      >
        <StyledImg
          height="200px"
          width="100%"
          src={slides[currentSlide].image}
          alt={slides[currentSlide].title}
        />

        {[
          OnboardingSlideType.FAVOURITES,
          OnboardingSlideType.SEARCH_AGENT,
        ].includes(slides[currentSlide].slide) && (
          <Flex
            align="center"
            justify="center"
          >
            <Badge
              state="success"
              variant="transparent"
            >
              {t("onboarding.feature")}
            </Badge>
          </Flex>
        )}
      </Flex>
      <Flex
        column
        align="center"
        wrap
      >
        <Headline
          as="h2"
          variant={{
            xs: "h3",
            lg: "h2",
          }}
        >
          {slides[currentSlide].title}
        </Headline>
        <Flex
          wrap
          width={{
            xs: "100%",
            md: "400px",
          }}
        >
          <Box minHeight={"80px"}>
            <Text textAlign="center">{slides[currentSlide].description}</Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  </Box>
);
