import { useState, useCallback, useEffect } from "react";
import { styled } from "@boligportal/juice";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { App } from "components/app";
import { MapResults } from "components/interfaces/map_results";
import { GeoJsonProperties } from "geojson";
import { t } from "lib/i18n";
import { formatNumber } from "lib/utils";
import MapboxGl from "mapbox-gl";
import { drawPin } from "./drawPin";
import {
  createSinglePinLayer,
  createClusterLayer,
  createClusterCountLayer,
  createMultiplePinLayer,
  MAP_STYLE_URL,
  scheduleMaxZoomOnNextRender,
} from "./mapHelpers";

// Extend incomplete mapbox typings
declare module "mapbox-gl" {
  interface MapboxOptions {
    bounds?: MapboxGl.LngLatBoundsLike;
    fitBoundsOptions?: MapboxGl.FitBoundsOptions;
  }
}

const LAYER_ID_CLUSTER: string = "LAYER_ID_CLUSTER";
const LAYER_ID_PIN_NORMAL: string = "LAYER_ID_PIN_NORMAL";
const LAYER_ID_PIN_SELECTED: string = "LAYER_ID_PIN_SELECTED";
const LAYER_ID_PIN_X_NORMAL: string = "LAYER_ID_PIN_MULTIPLE_NORMAL";
const LAYER_ID_PIN_X_SELECTED: string = "LAYER_ID_PIN_MULTIPLE_SELECTED";
const ADS_DATA_SOURCE_ID: string = "ADS_DATA_SOURCE_ID";
const MAP_CONTAINER_ID: string = "MAP";

function getTextWidth(text: string, font?: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  if (font) {
    context.font = font;
  }
  return Math.ceil(context.measureText(text).width);
}

function drawMapPins(expectedMaximumRentLength: number) {
  const pinHeight = 26;
  const font =
    '12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
  const padding = 4;
  const currencyPlaceholder = formatNumber(
    Number("9".repeat(expectedMaximumRentLength)),
  );
  const singlePinWidth =
    getTextWidth(
      `${currencyPlaceholder} ${App.settings.currency_symbol}`,
      font,
    ) +
    padding * 2;
  const multiPinWidth =
    getTextWidth(`99 ${t("map.pin.rentables")}`, font) + padding * 2;
  return {
    singleUnselected: drawPin(singlePinWidth, pinHeight, 1),
    doubleUnselected: drawPin(multiPinWidth, pinHeight, 2),
    multipleUnselected: drawPin(multiPinWidth, pinHeight, 3),
    singleSelected: drawPin(singlePinWidth, pinHeight, 1, true),
    doubleSelected: drawPin(multiPinWidth, pinHeight, 2, true),
    multipleSelected: drawPin(multiPinWidth, pinHeight, 3, true),
  };
}

const findClusterIdFromAdIds = (
  map: MapboxGl.Map,
  ads: Array<number>,
  callback: (clusterId) => void,
) => {
  const source = map.getSource(ADS_DATA_SOURCE_ID) as MapboxGl.GeoJSONSource;

  const features = map.queryRenderedFeatures(undefined, {
    layers: [
      // LAYER_ID_CLUSTER,
      LAYER_ID_PIN_X_NORMAL,
      LAYER_ID_PIN_X_SELECTED,
      // LAYER_ID_PIN_NORMAL,
      // LAYER_ID_PIN_SELECTED,
    ],
  });

  if (features.length) {
    features.forEach((feature) => {
      const { properties } = feature;
      if (properties && properties.cluster_id) {
        const { cluster_id, point_count } = properties;
        // For each cluster on the map
        // NOTE: Sometimes, when this runs, the data will have changed in the meantime, and this will fail because
        // a cluster with that id no longer exists
        source.getClusterLeaves(
          cluster_id,
          point_count,
          0,
          (error, clusterFeatures) => {
            if (error || !clusterFeatures) {
              return;
            }

            // Get the ad ids of that cluster
            const clusterAdIds = clusterFeatures.map((clusterFeature) => {
              if (clusterFeature.properties) {
                return clusterFeature.properties.ad_id;
              }
            });

            // If the array of ads in that cluster is the same as the array of selected ads
            if (ads.toString() == clusterAdIds.toString()) {
              callback(cluster_id);
            }
          },
        );
      }
    });
  }

  return features;
};

// ============================================================================================================
// Map Component
// ============================================================================================================

type MapProps = {
  fitToBounds?: MapboxGl.LngLatBounds;
  zoom?: number;
  center?: MapboxGl.LngLatLike;
  results: MapResults;
  selectedAds: Array<number>;
  onAdsSelected: (ads: Array<number>) => void;
  onMapMove: (
    center: [number, number],
    zoom: number,
    bounds: MapboxGl.LngLatBounds,
  ) => void;
};

const Maps = ({
  fitToBounds,
  zoom = 3,
  center,
  results,
  onAdsSelected = () => {},
  onMapMove = () => {},
  selectedAds = [],
}: MapProps) => {
  const { expectedMaximumRentLength } = useMarketSettings();
  const [mapboxMap, setMapboxMap] = useState<MapboxGl.Map>();
  const [initialZoom] = useState(zoom);
  const [initialCenter] = useState(center);
  const [initialBounds] = useState(fitToBounds);
  const [selectedClusterId, setSelectedClusterID] = useState<number>();

  const minZoom = 2;
  const maxZoom = 15;

  const isMapReady = useCallback(
    () =>
      !!mapboxMap &&
      mapboxMap.isSourceLoaded(ADS_DATA_SOURCE_ID) &&
      mapboxMap.isStyleLoaded(),
    [mapboxMap],
  );

  // Initialize mapbox
  useEffect(() => {
    const mapboxMap = new MapboxGl.Map({
      container: MAP_CONTAINER_ID,
      style: MAP_STYLE_URL,
      center: initialCenter,
      zoom: initialZoom,
      bounds: initialBounds,
      fitBoundsOptions: {
        padding: 50,
      },
      fadeDuration: 0,
      interactive: true,
      attributionControl: false,
      dragRotate: false,
      touchZoomRotate: true,
      minZoom,
      maxZoom,
    });
    mapboxMap.touchZoomRotate.disableRotation();

    const pin = drawMapPins(expectedMaximumRentLength);

    mapboxMap.on("load", () => {
      // Data source
      mapboxMap.addSource(ADS_DATA_SOURCE_ID, {
        type: "geojson",
        cluster: true,
        clusterRadius: 50,
        clusterMaxZoom: maxZoom,
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      // Layers
      mapboxMap.addLayer(
        createClusterLayer(LAYER_ID_CLUSTER, ADS_DATA_SOURCE_ID, maxZoom),
      );
      mapboxMap.addLayer(createClusterCountLayer(ADS_DATA_SOURCE_ID, maxZoom));
      mapboxMap.addLayer(
        createSinglePinLayer(LAYER_ID_PIN_NORMAL, ADS_DATA_SOURCE_ID, false),
      );
      mapboxMap.addLayer(
        createSinglePinLayer(LAYER_ID_PIN_SELECTED, ADS_DATA_SOURCE_ID, true),
      );
      mapboxMap.addLayer(
        createMultiplePinLayer(
          LAYER_ID_PIN_X_NORMAL,
          ADS_DATA_SOURCE_ID,
          false,
          maxZoom,
          t("map.pin.rentables"),
        ),
      );
      mapboxMap.addLayer(
        createMultiplePinLayer(
          LAYER_ID_PIN_X_SELECTED,
          ADS_DATA_SOURCE_ID,
          true,
          maxZoom,
          t("map.pin.rentables"),
        ),
      );

      // Controls
      mapboxMap.addControl(new MapboxGl.AttributionControl(), "bottom-left");

      // Images
      mapboxMap.addImage("pin_1_normal", pin.singleUnselected);
      mapboxMap.addImage("pin_2_normal", pin.doubleUnselected);
      mapboxMap.addImage("pin_3_normal", pin.multipleUnselected);
      mapboxMap.addImage("pin_1_selected", pin.singleSelected);
      mapboxMap.addImage("pin_2_selected", pin.doubleSelected);
      mapboxMap.addImage("pin_3_selected", pin.multipleSelected);

      // Cursors
      mapboxMap.on("mouseenter", LAYER_ID_PIN_NORMAL, () => {
        mapboxMap.getCanvas().style.cursor = "pointer";
      });
      mapboxMap.on("mouseleave", LAYER_ID_PIN_NORMAL, () => {
        mapboxMap.getCanvas().style.cursor = "";
      });
      mapboxMap.on("mouseenter", LAYER_ID_PIN_X_NORMAL, () => {
        mapboxMap.getCanvas().style.cursor = "pointer";
      });
      mapboxMap.on("mouseleave", LAYER_ID_PIN_X_NORMAL, () => {
        mapboxMap.getCanvas().style.cursor = "";
      });
      mapboxMap.on("mouseenter", LAYER_ID_CLUSTER, () => {
        mapboxMap.getCanvas().style.cursor = "pointer";
      });
      mapboxMap.on("mouseleave", LAYER_ID_CLUSTER, () => {
        mapboxMap.getCanvas().style.cursor = "";
      });

      setMapboxMap(mapboxMap);
    });

    // Cleanup when unmounted
    return () => {
      mapboxMap.remove();
    };
  }, [initialCenter, initialZoom, initialBounds]);

  useEffect(() => {
    if (!mapboxMap) {
      return;
    }

    const handleMapClick = (event) => {
      if (!mapboxMap) {
        return;
      }
      const features = mapboxMap.queryRenderedFeatures(event.point, {
        layers: [LAYER_ID_PIN_NORMAL, LAYER_ID_PIN_X_NORMAL],
      });

      if (features.length > 0) {
        // Something was clicked
        const [feature] = features;
        const { properties } = feature;

        if (properties) {
          if (properties.ad_id) {
            // Select single pin
            onAdsSelected([properties.ad_id]);
          } else if (properties.cluster_id) {
            // Get ad ids from cluster id
            // FIXME: Source may not be ready yet!
            const geoJSONSource = mapboxMap.getSource(
              ADS_DATA_SOURCE_ID,
            ) as MapboxGl.GeoJSONSource;
            geoJSONSource.getClusterLeaves(
              properties.cluster_id,
              100,
              0,
              (error, features) => {
                if (error || !features) {
                  onAdsSelected([]);
                  return;
                }

                const ads = features.map((feature) => {
                  if (feature && feature.properties) {
                    return feature.properties.ad_id;
                  }
                });
                onAdsSelected(ads);
              },
            );
          }
        }
      } else {
        // No Pin was clicked
        onAdsSelected([]);
      }
    };
    mapboxMap.on("click", handleMapClick);
    return () => {
      mapboxMap.off("click", handleMapClick);
    };
  }, [mapboxMap, onAdsSelected]);

  useEffect(() => {
    if (!mapboxMap) {
      return;
    }

    const handleMapClusterClick = (event) => {
      const features = mapboxMap.queryRenderedFeatures(event.point, {
        layers: [LAYER_ID_CLUSTER],
      });
      const [feature] = features;

      if (!feature.properties) {
        return;
      }

      const geometry = feature.geometry as GeoJsonProperties;

      if (!geometry) {
        return;
      }

      const clusterId = feature.properties.cluster_id;
      const geoJSONSource = mapboxMap.getSource(
        ADS_DATA_SOURCE_ID,
      ) as MapboxGl.GeoJSONSource;

      geoJSONSource.getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error || zoom == null) {
          return;
        }

        // Clamp the extra zoom to a maximum of 2 zoom levels
        const extraZoom = Math.min(2, maxZoom / zoom);
        const targetZoom = zoom + extraZoom;

        mapboxMap.flyTo({
          center: geometry.coordinates,
          zoom: Math.min(maxZoom, targetZoom),
          speed: 2,
          curve: 1.5,
        });
      });
    };

    mapboxMap.on("click", LAYER_ID_CLUSTER, handleMapClusterClick);
    return () => {
      mapboxMap.off("click", LAYER_ID_CLUSTER, handleMapClusterClick);
    };
  }, [mapboxMap, maxZoom]);

  // onMapMove event
  useEffect(() => {
    if (!mapboxMap) {
      return;
    }

    const handleMapMoved = (event) => {
      if (!event.dontTriggerMapMove) {
        onMapMove(
          mapboxMap.getCenter().toArray() as [number, number],
          mapboxMap.getZoom(),
          mapboxMap.getBounds(),
        );
      }
    };

    mapboxMap.on("moveend", handleMapMoved);
    return () => {
      mapboxMap.off("moveend", handleMapMoved);
    };
  }, [mapboxMap, onMapMove]);

  // Ad selection
  useEffect(() => {
    if (!mapboxMap || !isMapReady) {
      return;
    }

    const selectMultiCluster = () => {
      findClusterIdFromAdIds(mapboxMap, selectedAds, (clusterId) => {
        setSelectedClusterID(clusterId);
      });
    };

    if (selectedAds.length === 1) {
      mapboxMap.setFilter(LAYER_ID_PIN_SELECTED, [
        "in",
        "ad_id",
        selectedAds[0],
      ]);
      mapboxMap.setFilter(LAYER_ID_PIN_X_SELECTED, ["in", "cluster_id"]);
      setSelectedClusterID(undefined);
    } else if (selectedAds.length > 1) {
      mapboxMap.setFilter(LAYER_ID_PIN_SELECTED, ["in", "ad_id"]);
      // Query matching clusters continuously. Data might not be ready at first, and cluster IDs can change on rerenders
      // "idle" is called after the last render, before the map enters an idle state
      mapboxMap.on("idle", selectMultiCluster);

      // Setting zoom on the mapboxMap instance here will trigger a rerender,
      // so we need to schedule it for the next render to avoid an infinite loop.
      scheduleMaxZoomOnNextRender(mapboxMap, 0.5);
    } else {
      mapboxMap.setFilter(LAYER_ID_PIN_X_SELECTED, ["in", "cluster_id"]);
      mapboxMap.setFilter(LAYER_ID_PIN_SELECTED, ["in", "ad_id"]);
      mapboxMap.setFilter(LAYER_ID_PIN_NORMAL, [
        "all",
        ["!has", "point_count"],
      ]);
      setSelectedClusterID(undefined);
    }
    return () => {
      if (!mapboxMap || !isMapReady) {
        return;
      }
      mapboxMap.off("idle", selectMultiCluster);
    };
  }, [isMapReady, mapboxMap, selectedAds]);

  // Multi pin selection
  useEffect(() => {
    if (!mapboxMap) {
      return;
    }
    if (selectedClusterId) {
      mapboxMap.setFilter(LAYER_ID_PIN_X_SELECTED, [
        "in",
        "cluster_id",
        selectedClusterId,
      ]);
    } else {
      mapboxMap.setFilter(LAYER_ID_PIN_X_SELECTED, ["in", "cluster_id"]);
    }
  }, [mapboxMap, selectedClusterId]);

  // When results change, update map data
  useEffect(() => {
    if (!mapboxMap) {
      return;
    }
    const source = mapboxMap.getSource(
      ADS_DATA_SOURCE_ID,
    ) as MapboxGl.GeoJSONSource;

    if (source) {
      source.setData(results);
    }
  }, [results, mapboxMap]);

  useEffect(() => {
    // If map is given bounds to fit to (based on the list of pins)
    if (mapboxMap && fitToBounds) {
      mapboxMap.fitBounds(
        fitToBounds,
        {
          padding: 50,
          animate: false,
        },
        {
          dontTriggerMapMove: true,
        },
      );
    }
  }, [fitToBounds, mapboxMap]);

  return <StyledMapsWrapper id={MAP_CONTAINER_ID} />;
};

export { Maps };

export const StyledMapsPageWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

export const StyledMapsWrapper = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee9e2; // Match color of map
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    left: 250px;
  }
`;
