import { Text, Flex } from "@boligportal/juice";
import { PAGE_USERS } from "apps/customer_service";
import { BlackListIndicator } from "apps/customer_service/components/blacklist_indicator/BlackListIndicator";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import { AdItemsInterface } from "../interfaces";

const LandlordCellRenderer = (data: AdItemsInterface) => {
  const { landlord_ad_stats, landlord_email, landlord_id, user_ban_reasons } =
    data;

  return (
    <>
      <StyledLink to={`${PAGE_USERS}/${landlord_id}`}>
        <Text
          block
          size="tiny"
        >
          {landlord_email}
        </Text>
      </StyledLink>
      {landlord_ad_stats && (
        <LandlordAdStats
          user_ban_reasons={user_ban_reasons}
          new_landlord={landlord_ad_stats.new_landlord}
          hidden_count={landlord_ad_stats.hidden_count}
          online_count={landlord_ad_stats.online_count}
          rented_out_count={landlord_ad_stats.rented_out_count}
          waiting_count={landlord_ad_stats.waiting_count}
        />
      )}
    </>
  );
};

const LandlordAdStats = ({
  user_ban_reasons,
  new_landlord,
  waiting_count,
  rented_out_count,
  online_count,
  hidden_count,
}) => (
  <Flex align="start">
    {user_ban_reasons && (
      <BlackListIndicator
        showTooltip
        reasons={user_ban_reasons}
      />
    )}
    <Text
      weight="bold"
      size="tiny"
    >
      {new_landlord
        ? "New landlord"
        : `${waiting_count} waiting, ${hidden_count} reserved, ${online_count} online, ${rented_out_count} rented out`}
    </Text>
  </Flex>
);

export { LandlordCellRenderer };
