import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
} from "@boligportal/juice";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";

// ============================================================

type AlertMessageCreator = (
  candidate: TenancyChangeCandidate,
) => React.ReactNode;

type DialogProps = {
  alertTitle: string;
  alertMessage: string | AlertMessageCreator;
  alertCancel: string;
  alertConfirm: string;
};

const TenancyChangeDialog = ({
  alertCancel,
  alertConfirm,
  alertMessage,
  alertTitle,
}: DialogProps) => {
  const { candidate, onCancel, changeTenancyService, setSelectedTenancyId } =
    React.useContext(TenancySelectorContext);

  if (!candidate) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onCancel}
    >
      <DialogHeader>{alertTitle}</DialogHeader>
      <DialogContent>
        {typeof alertMessage === "function"
          ? alertMessage(candidate)
          : alertMessage}
      </DialogContent>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onCancel}
          >
            {alertCancel}
          </Button>
          <Button
            variant="primary"
            data-testid="confirmButton"
            onClick={() =>
              changeTenancyService(candidate.tenancyId)
                .then(() => setSelectedTenancyId(candidate.tenancyId))
                .finally(onCancel)
            }
          >
            {alertConfirm}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};

// ============================================================

export type TenancyChangeCandidate = {
  tenancies: Tenancy[];
  tenancyId: number | null;
};

type TenancySelectorContextValue = {
  open: boolean;
  candidate: TenancyChangeCandidate | null;
  setTenancyChangeCandidate: (candidate: TenancyChangeCandidate | null) => void;
  setSelectedTenancyId: React.Dispatch<React.SetStateAction<number | null>>;
  onCancel: () => void;
  selectedTenancyId: number | null;
  changeTenancyService: (tenancyId: number | null) => Promise<any>;
};

const TenancySelectorContext = React.createContext<TenancySelectorContextValue>(
  null!,
);

type ProviderProps = {
  children: React.ReactNode;
  initialTenancyId: number | null;
  changeTenancyService: (tenancyId: number | null) => Promise<any>;
  alertTitle: string;
  alertMessage: string | AlertMessageCreator;
  alertCancel: string;
  alertConfirm: string;
};

export const TenancySelectorProvider = ({
  children,
  initialTenancyId,
  changeTenancyService,
  alertCancel,
  alertConfirm,
  alertMessage,
  alertTitle,
}: ProviderProps) => {
  const [selectedTenancyId, setSelectedTenancyId] = React.useState<
    number | null
  >(initialTenancyId);
  const [tenancyChangeCandidate, setTenancyChangeCandidate] =
    React.useState<TenancyChangeCandidate | null>(null);
  return (
    <TenancySelectorContext.Provider
      value={{
        candidate: tenancyChangeCandidate,
        open: Boolean(tenancyChangeCandidate),
        onCancel: () => setTenancyChangeCandidate(null),
        setTenancyChangeCandidate,
        setSelectedTenancyId,
        selectedTenancyId,
        changeTenancyService,
      }}
    >
      {children}
      <TenancyChangeDialog
        alertCancel={alertCancel}
        alertConfirm={alertConfirm}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
      />
    </TenancySelectorContext.Provider>
  );
};

// ============================================================

export const useTenancySelection = () => {
  const context = React.useContext(TenancySelectorContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useTenancySelector",
      "TenancySelectorProvider",
    );
  }

  const {
    selectedTenancyId,
    setSelectedTenancyId,
    setTenancyChangeCandidate,
    changeTenancyService,
  } = context;

  const trySetTenancyChangeCandidate = (
    candidate: TenancyChangeCandidate | null,
  ) => {
    if (!selectedTenancyId && candidate) {
      changeTenancyService(candidate.tenancyId).then(() =>
        setSelectedTenancyId(candidate.tenancyId),
      );
    } else {
      setTenancyChangeCandidate(candidate);
    }
  };

  return {
    selectedTenancyId,
    setTenancyChangeCandidate: trySetTenancyChangeCandidate,
  };
};
