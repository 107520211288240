import * as React from "react";
import {
  Box,
  Flex,
  styled,
  Switch,
  Text,
  useNotifications,
} from "@boligportal/juice";
import { Action_updateAgentId } from "apps/search_result/search_result_reducer";
import { useAgentActions } from "components/CreateAgent/useAgentActions";
import { Filters } from "components/interfaces/filters";
import { User } from "components/interfaces/user";
import { t } from "lib/i18n";

type Props = {
  filters: Filters;
  dispatch: React.Dispatch<Action_updateAgentId>;
  agentId: number | null;
};

const StyledFlex = styled(Flex)`
  height: 45px;

  label {
    display: flex;
    margin-bottom: 0;
  }
`;

export const CreateAgentFromMap = ({ dispatch, filters, agentId }: Props) => {
  const { addNotification } = useNotifications();

  const onAgentCreated = (user: User) =>
    addNotification({
      title: t("srp.map.agent_created.title"),
      content: t("srp.map.agent_created.description_%{email}", {
        email: user.email,
      }),
      autoDismiss: true,
    });

  const onAgentDeleted = () =>
    addNotification({
      title: t("srp.map.agent_deleted.title"),
      content: t("srp.map.agent_deleted.description"),
      autoDismiss: true,
    });

  const onAgentError = () => {
    addNotification({
      title: t("srp.map.must_decrease_search_area_to_create_agent.title"),
      content: t("srp.map.must_decrease_search_area_to_create_agent.content"),
      autoDismiss: true,
    });
  };

  const { onEnableAgent, onDisableAgent } = useAgentActions({
    filters,
    dispatch,
    agentId,
    onAgentCreated,
    onAgentDeleted,
    trackingLabel: "map",
    onError: onAgentError,
  });

  return (
    <Box
      bg="brand"
      borderRadius="full"
      onClick={() => {
        if (!agentId) {
          onEnableAgent();
        } else {
          onDisableAgent();
        }
      }}
    >
      <StyledFlex
        align="center"
        height="100%"
        px={4}
      >
        <Text
          color="inverted"
          mr={1}
        >
          {t("srp.map.search_agent.name")}
        </Text>
        <Switch
          checked={!!agentId}
          trackOffColor="brand"
        />
      </StyledFlex>
    </Box>
  );
};
