/* eslint-disable comma-dangle */
import * as React from "react";
import { useDebounce } from "hooks/useDebounce";
import { AddressCandidate } from "../interfaces/AddressCandidate";
import { GetAddressCandidates } from "../types/GetAddressCandidates";
import { ResolveAddressFromCandidate } from "../types/ResolveAddressFromCandidate";

interface Props {
  onAddress: (address: string) => void;
  onAddressCleared?: () => void;
  getAddressCandidates: GetAddressCandidates;
  resolveAddressFromCandidate: ResolveAddressFromCandidate;
  initialAddress?: string;
}

export const useAddressSuggestionList = ({
  onAddress,
  onAddressCleared,
  getAddressCandidates,
  resolveAddressFromCandidate,
  initialAddress,
}: Props) => {
  const shouldFetch = React.useRef(false);
  const canAutoSelect = React.useRef(false);

  const [matches, setMatches] = React.useState<Array<AddressCandidate>>([]);
  const [inputValue, setInputValue] = React.useState<{
    text: string;
    containerId: string;
  }>({
    text: initialAddress ?? "",
    containerId: "",
  });

  const debouncedValue = useDebounce(inputValue, 100);

  const lookForMatchesAgainOrChooseItem = React.useCallback(
    (item: AddressCandidate) => {
      if (item.isAddress) {
        shouldFetch.current = false;
        canAutoSelect.current = false;
        const address = resolveAddressFromCandidate(item);
        setInputValue({
          text: address,
          containerId: "",
        });
        setMatches([]);
        onAddress(address);
      } else {
        shouldFetch.current = true;
        canAutoSelect.current = true;
        setInputValue({
          text: item.candidateAddress,
          containerId: item.candidateId ?? "",
        });
      }
    },
    [onAddress, resolveAddressFromCandidate],
  );

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    canAutoSelect.current = false;
    const { value } = e.target;

    if (value === "") {
      shouldFetch.current = false;
      setMatches([]);
      onAddressCleared?.();
    } else {
      shouldFetch.current = true;
    }

    setInputValue({
      text: value,
      containerId: "",
    });
  };

  React.useEffect(() => {
    if (shouldFetch.current === false) {
      return;
    }
    getAddressCandidates(debouncedValue.text, debouncedValue.containerId).then(
      (response) => {
        if (
          canAutoSelect.current &&
          response.length === 1 &&
          response[0].isAddress
        ) {
          lookForMatchesAgainOrChooseItem(response[0]);
        } else {
          setMatches(response);
        }
      },
    );
  }, [debouncedValue, getAddressCandidates, lookForMatchesAgainOrChooseItem]);

  return {
    matches,
    updateInput,
    inputText: inputValue.text,
    lookForMatchesAgainOrChooseItem,
  };
};
