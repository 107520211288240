import { useState } from "react";
import { Box } from "@boligportal/juice";
import PaymentPolicyCheckfield from "./PaymentPolicyCheckfield";

type Props = {
  children: React.ReactNode;
};

export const PaymentPolicyButtonsWrapper = ({ children }: Props) => {
  const [hasPolicyBeenAccepted, setHasPolicyBeenAccepted] = useState(false);
  const [policyCheckErrorMessage, setPolicyCheckErrorMessage] = useState(false);
  const handleOnChange = (value: boolean) => {
    setHasPolicyBeenAccepted(value);
    setPolicyCheckErrorMessage(false);
  };

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!hasPolicyBeenAccepted) {
      setPolicyCheckErrorMessage(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };
  return (
    <>
      <Box mb={4}>
        <PaymentPolicyCheckfield
          onChange={handleOnChange}
          policyCheckErrorMessage={policyCheckErrorMessage}
        />
      </Box>
      <div onClickCapture={handleStopPropagation}>{children}</div>
    </>
  );
};
