import { MarketSettings } from "components/markets/market_settings";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import SvgLogoBostadsportal from "media/svg/LogoBostadsportal";
import SvgLogoBostadsportalWhite from "media/svg/LogoBostadsportalWhite";
import { paywall_se } from "./paywall/paywall_se";

export class BostadsportalSEMarketSettings implements MarketSettings {
  getHeaderLogoComponent(inverseTextColor?: boolean) {
    if (inverseTextColor) {
      return (
        <SvgLogoBostadsportalWhite
          width="180px"
          height="33px"
        />
      );
    }
    return (
      <SvgLogoBostadsportal
        width="180px"
        height="33px"
      />
    );
  }

  expectedMaximumRentLength = 5;
  countryBoundingBox = {
    sw: {
      lat: 55.1331192,
      lng: 10.5930952,
    },
    ne: {
      lat: 69.0599699,
      lng: 24.1776819,
    },
  };

  customerService = {
    externalAddressWebsites: ["RatsIt", "Eniro", "Hitta"],
    externalPhoneWebsites: ["RatsIt", "Eniro", "Hitta"],
  };

  paymentMethodTypes = [
    PaymentMethodType.Account,
    PaymentMethodType.QuickPay,
    PaymentMethodType.ApplePay,
    PaymentMethodType.GooglePay,
  ];

  paywall = paywall_se;
}
