import * as React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@boligportal/juice";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { GlobalSearchContext } from "../global_search_context";
import { ISearchItem } from "../interfaces";

const linkStyles = (props) => css`
  font-size: ${props.theme.fontSize.tiny};
  background-color: ${props.theme.colorPalette.white};
  color: ${props.theme.color.text.base};
  text-decoration: none;
  &:hover {
    background-color: ${props.theme.colorPalette.gray[20]};
    color: ${props.theme.color.text.base};
    text-decoration: none;
  }
`;

const ItemLink = styled(Link)`
  ${linkStyles};
`;

type Props = {
  renderer: (searchItem: ISearchItem) => React.ReactNode;
  searchItems: ISearchItem[];
};

const SearchGroupBody = ({ renderer, searchItems }: Props) => {
  const { reset } = React.useContext(GlobalSearchContext);

  return (
    <div
      style={{
        cursor: "pointer",
      }}
    >
      {searchItems.map((item) => (
        <Flex
          column
          key={item.link}
        >
          <ItemLink
            to={item.link}
            onClick={() => reset()}
          >
            {renderer(item)}
          </ItemLink>
        </Flex>
      ))}
    </div>
  );
};

export { SearchGroupBody };
