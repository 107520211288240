import * as React from "react";
import { Box, Button, CheckField } from "@boligportal/juice";
import { Icon } from "@boligportal/juice/dist/components/Icon/Icon";
import { Option } from "components/interfaces/option";
import { TaskResponseInterface } from "../../interfaces";
import { AdActionConfirmation } from "../ad_action_confirmation";
import { EmailTemplate, EmailTemplateContentLoadable } from "../email_template";

type SendEmailAndCanCreateContract = (
  templateId: number,
  emailMessageBody: string,
  shouldCreateContract: boolean,
) => Promise<TaskResponseInterface>;

const ConfirmableAction = (props: {
  buttonVariant?: "danger" | "default";
  emailTemplateLoaderId: number; // Either UserID or AdID
  actionTitle: string;
  actionCancelLabel: string;
  actionConfirmLabel: string;
  actionConfirmedTask: SendEmailAndCanCreateContract;
  emailTemplateLoader: EmailTemplateContentLoadable;
  /**
   * If true, a checkfield controls whether or not user needs to choose email template
   */
  emailOptional: boolean;
  hasContract?: boolean;
  showCreateDraftContract?: boolean;
  templateOptions: Option[];
  icon?: typeof Icon;
}) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(true);
  const [createContract, setCreateContract] = React.useState(false);

  const [templateId, setTemplateId] = React.useState(-1);
  const emailMessageBodyRef = React.useRef<string>("");

  const {
    emailTemplateLoaderId,
    emailTemplateLoader,
    actionConfirmedTask,
    templateOptions,
    actionTitle,
    actionCancelLabel,
    actionConfirmLabel,
    buttonVariant,
    emailOptional,
    showCreateDraftContract,
  } = props;

  const handleCloseClick = () => {
    setTemplateId(-1);
    setShowConfirmation(false);
  };
  const handleCancelClick = () => {
    setTemplateId(-1);
    setShowConfirmation(false);
  };
  const handleButtonClick = () => setShowConfirmation(true);

  const handleConfirmClick = () => {
    setIsSending(true);
    actionConfirmedTask(templateId, emailMessageBodyRef.current, createContract)
      .then((response) => {
        if (response.success) {
          // Happy path
          setShowConfirmation(false);
          setIsSending(false);
        } else {
          // Sad path
          setIsSending(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        // Sad path
        setIsSending(false);
        alert(error.message);
      });
  };

  const handleEmailChange = (emailMessageBody: string) => {
    emailMessageBodyRef.current = emailMessageBody;
  };

  const handleTemplateSelectionChange = (templateId: number) => {
    setTemplateId(templateId);
  };

  const handleSendEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSendEmail(event.target.checked);
  };

  return (
    <>
      <Button
        onClick={handleButtonClick}
        icon={props.icon}
        variant={buttonVariant ? buttonVariant : "default"}
      >
        {actionTitle}
      </Button>
      {showConfirmation && (
        <AdActionConfirmation
          disableConfirmButton={sendEmail ? templateId === -1 : false}
          title={actionTitle}
          cancelLabel={actionCancelLabel}
          confirmLabel={actionConfirmLabel}
          onCloseClick={handleCloseClick}
          onCancelClick={handleCancelClick}
          onConfirmClick={handleConfirmClick}
          loading={isSending}
        >
          <>
            {emailOptional && (
              <Box mb={3}>
                <CheckField
                  checked={sendEmail}
                  onChange={handleSendEmailChange}
                  label="Send email confirmation"
                />
              </Box>
            )}

            {showCreateDraftContract && (
              <Box mb={3}>
                <CheckField
                  checked={createContract}
                  onChange={(event) => setCreateContract(event.target.checked)}
                  label="Create a draft contract"
                />
              </Box>
            )}

            {sendEmail && (
              <EmailTemplate
                templateOptions={templateOptions}
                emailTemplateLoaderId={emailTemplateLoaderId}
                emailTemplateLoader={emailTemplateLoader}
                onEmailChange={handleEmailChange}
                onTemplateSelectionChange={handleTemplateSelectionChange}
              />
            )}
          </>
        </AdActionConfirmation>
      )}
    </>
  );
};

export { ConfirmableAction };
