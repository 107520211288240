import { Image } from "apps/moving_reports/interfaces/image";
import { NewDefectByTenant } from "apps/moving_reports/tenant_app/interfaces/newDefectByTenant";

type TenantDefectSelection = {
  description: string;
  images: Record<number, Image[]>;
  unit: number;
};

export const createNewTenantDefectFromUnitSelection = ({
  description,
  images,
  unit,
}: TenantDefectSelection): NewDefectByTenant => {
  const image_ids = extractImageIdsForUnit(unit, images);

  return {
    unit_id: unit,
    image_ids,
    defect_data: {
      description,
    },
  };
};

const extractImageIdsForUnit = (
  unit: number,
  images: Record<number, Image[]>,
): number[] => images[unit]?.map((image: Image) => image.id) || [];
