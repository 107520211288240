import {
  Box,
  Card,
  Divider,
  Flex,
  styled,
  Text,
  Headline,
} from "@boligportal/juice";

type Props = {
  logoSrc: string;
  title: string;
  subtitle: string;
  text: string;
};
export const RentalDataTestimonialCard = ({
  logoSrc,
  title,
  subtitle,
  text,
}: Props) => (
  <HeightAdjustableCard>
    <Flex
      justify="space-between"
      align="center"
      pb={2}
    >
      <Flex
        column
        pr={2}
      >
        <Headline
          m={0}
          as="h5"
        >
          {title}
        </Headline>
        <Text>{subtitle}</Text>
      </Flex>
      <Box
        height="24px"
        minHeight="24px"
      >
        <img src={logoSrc} />
      </Box>
    </Flex>

    <Divider />

    <Box pt={1}>
      <ItalicText>{text}</ItalicText>
    </Box>
  </HeightAdjustableCard>
);

const ItalicText = styled(Text)`
  font-style: italic;
`;

const HeightAdjustableCard = styled(Card)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    height: 100%;
  }
`;
