import * as React from "react";
import { PAGE_ADS_ALL, PAGE_COMPANIES } from "apps/customer_service";
import { useGloballySearch } from "features/cs/global_search_feature/hooks/use_globally_search";
import {
  UsersGlobalSearchResult,
  AdsGlobalSearchResult,
  CompaniesGlobalSearchResult,
} from "features/cs/global_search_feature/services/search_globally_service";
import {
  createGlobalSearchAdItems,
  createGlobalSearchUserItems,
  createGlobalSearchCompanyItems,
} from "./helpers";
import { ISearchGroup } from "./interfaces";

interface IGlobalSearch {
  isVisible: boolean;
  hide: () => void;
  show: () => void;
  reset: () => void;
  clear: () => void;
  searchValue: string;
  search: (value: string) => void;
  searchGroups: ISearchGroup[];
  addSearchGroups: (
    users: UsersGlobalSearchResult,
    ads: AdsGlobalSearchResult,
    companies: CompaniesGlobalSearchResult,
  ) => void;
}

const USERS_TITLE = "Users";
const USERS_HELP_TEXT = "Search by user ID, username, name or phone number";
const ADS_TITLE = "Ads";
const ADS_HELP_TEXT = "Search by ad ID, address or ad phone number";
const COMPANIES_TITLE = "Companies";
const COMPANIES_HELP_TEXT =
  "Search by company id, name, invoice email, phone, or att.";

export const GlobalSearchContext = React.createContext<IGlobalSearch>({
  isVisible: false,
  hide: () => {},
  show: () => {},
  reset: () => {},
  clear: () => {},
  searchValue: "",
  search: () => "",
  searchGroups: [],
  addSearchGroups: ({}, {}) => {},
});

const DefaultSearchGroups: ISearchGroup[] = [
  {
    type: "Users",
    helpText: USERS_HELP_TEXT,
    title: USERS_TITLE,
    searchItems: [],
    count: 0,
    seeAllLink: "",
  },
  {
    type: "Ads",
    helpText: ADS_HELP_TEXT,
    title: ADS_TITLE,
    searchItems: [],
    count: 0,
    seeAllLink: "",
  },
  {
    type: "Companies",
    helpText: COMPANIES_HELP_TEXT,
    title: COMPANIES_TITLE,
    searchItems: [],
    count: 0,
    seeAllLink: "",
  },
];

type Props = {
  children: React.ReactNode;
};

export const GlobalSearchContextProvider = ({ children }: Props) => {
  const { users, ads, companies, search, searchValue } = useGloballySearch();
  const [isVisible, setIsVisible] = React.useState(false);
  const [searchGroups, setSearchGroups] =
    React.useState<ISearchGroup[]>(DefaultSearchGroups);
  const hide = () => setIsVisible(false);
  const show = () => setIsVisible(true);
  const reset = () => {
    setIsVisible(false);
  };
  const clear = () => {
    setSearchGroups(DefaultSearchGroups);
  };

  React.useEffect(() => {
    addSearchGroups(users, ads, companies);
  }, [ads, users, companies]);

  const addSearchGroups = (
    users: UsersGlobalSearchResult,
    ads: AdsGlobalSearchResult,
    companies: CompaniesGlobalSearchResult,
  ) => {
    const userSearchItems = createGlobalSearchUserItems(users.results);
    const adsSearchItems = createGlobalSearchAdItems(ads.results);
    const companiesSearchItems = createGlobalSearchCompanyItems(
      companies.results,
    );

    setSearchGroups([
      {
        type: "Users",
        helpText: USERS_HELP_TEXT,
        title: USERS_TITLE,
        searchItems: userSearchItems,
        count: users.count,
        seeAllLink: `/customer-service/users?q=${searchValue}`,
      },
      {
        type: "Ads",
        helpText: ADS_HELP_TEXT,
        title: ADS_TITLE,
        searchItems: adsSearchItems,
        count: ads.count,
        seeAllLink: `${PAGE_ADS_ALL}?address=${searchValue}`,
      },
      {
        type: "Companies",
        helpText: COMPANIES_HELP_TEXT,
        title: COMPANIES_TITLE,
        searchItems: companiesSearchItems,
        count: companies.count,
        seeAllLink: PAGE_COMPANIES,
      },
    ]);
  };

  return (
    <GlobalSearchContext.Provider
      value={{
        addSearchGroups,
        hide,
        show,
        reset,
        clear,
        isVisible,
        searchValue,
        search,
        searchGroups,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};
