import { lazy, useState, useEffect, Suspense } from "react";

declare global {
  interface Window {
    loadDevtools?: () => Promise<void>;
  }
}

const importTools = () => lazy(() => import("./Tools"));

let resolver;

export const DevTools = () => {
  const [View, setView] = useState<any>(null);
  const [isDevtoolsUser, setIsDevtoolsUser] = useState(false);

  const loadDevtools = async () => {
    const View = await importTools();
    return setView(View);
  };

  useEffect(() => {
    window.loadDevtools = () => {
      setIsDevtoolsUser(true);

      return new Promise((resolve) => {
        loadDevtools().then(() => {
          resolver = resolve;
        });
      });
    };
  }, []);

  const onDevtoolsLoaded = () => {
    if (resolver) {
      resolver();
      resolver = null;
    }
  };

  // ReactDOMServer doesn't support Suspense, therefore we only render it when the user actually calls the loadDevtools function.
  if (!isDevtoolsUser) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <View onDidRender={onDevtoolsLoaded} />
    </Suspense>
  );
};
