import { ContractElipsisMenuCell } from "components/cells/ContractEllipsisMenuCellRenderer";
import { ContractFilterStateEnum } from "./contracts_list_page/enums/ContractFilterStateEnum";
import { ContractListItem } from "./store/contracts";

export type ContractId = number;
export type TenantId = number;

export interface GetContractsResponsePayload {
  entries: ContractListItem[];
  all_count: number;
  filter_count: number;
  awaiting_count: number;
  completed_count: number;
  drafts_count: number;
  expired_count: number;
  migrated_count: number;
}

export interface CopyContractResponsePayload {
  contract_id: number;
}

export interface GetContractsRequestPayload {
  contract_state: ContractFilterStateEnum | null;
  search_term: string | null;
  rentable_id: number | null;
  sorting: keyof ContractViewModel | null;
  descending: boolean | null;
  start_index: number | null;
  limit: number | null;
}

export interface CancelContractSigningResponsePayload {}

export enum SigningStatus {
  DRAFT = "DRAFT",
  AWAITING = "AWAITING",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
}

export type ContractAttachment = {
  id: number;
  filename: string;
  url: string;
  size: number;
  instance_created_date: string;
  type: string;
};

//

export type ContractViewModel = {
  id: number;
  rentable_address: string;
  addressInfo: {
    tenantsDisplayLabel: string;
    link: string;
  };
  leasing_period_start_date: string;
  state: SigningStatus;
  stateInfo: {
    stateLabel: string;
    stateSubLabel: string;
  };
} & ContractElipsisMenuCell;

export enum SigningOrder {
  NO_ORDER = "NO_ORDER",
  LANDLORD_SIGNS_LAST = "LANDLORD_SIGNS_LAST",
}

export interface BaseContract {
  contract_id: ContractId;
  instance_created_date: string;
  instance_modified_date: string;
  allow_custom_signature: boolean;
  leasing_period_start_date: string;
  leasing_period_end_date: string;
  landlord_name: string;
  landlord_email: string;
  landlord_phone: string;
  has_second_landlord: boolean;
  second_landlord_info_same_as_first: boolean;
  second_landlord_name: string;
  second_landlord_email: string;
  second_landlord_phone: string;
  second_landlord_postal_code: string;
  second_landlord_postal_town: string;
  second_landlord_street: string;
  second_landlord_cvr: string;
  days_for_signing_amount: number;
  additional_contract_recipients: { value: string }[];
  signing_order: SigningOrder;
}

export const daysForSigningAmountDefaultValue = 7;

export interface AttachmentUploadConfig {
  fields: {
    AWSAccessKeyId: string;
    acl: string;
    key: string;
    policy: string;
    signature: string;
  };
  url: string;
  max_file_size?: number;
}

// This is a const since enums aren't extendable, and this needs to be shared across markets
export const BaseAttachmentType = {
  DEFAULT: "DEFAULT",
  LANDLORD_UPLOADED_TERMS: "LANDLORD_UPLOADED_TERMS",
} as const;

/** Given an object returns a type of the values */
export type ObjectValues<T extends object> = T[keyof T];
