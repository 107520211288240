import { Flex, styled, Tooltip } from "@boligportal/juice";
import { blacklistReasonEnum } from "lib/enums";
import { prettifiedReason } from "../../pages/user_detail_page/blacklist/blacklist_user_notice";
import { useColorCategorizedBlacklistReasons } from "./hooks/useColorCategorizedBlacklistReasons";

interface IndicatorColor {
  color: "red" | "yellow";
}

interface BlackListIndicatorProps {
  reasons: blacklistReasonEnum[];
  showTooltip: boolean;
}

export const BlackListIndicator = ({
  reasons,
  showTooltip,
}: BlackListIndicatorProps) => {
  const { yellowReasons, redReasons } =
    useColorCategorizedBlacklistReasons(reasons);

  return (
    <Flex
      inline
      justify="center"
      align="center"
    >
      {yellowReasons && (
        <BlackListColorIndicator
          reasons={yellowReasons}
          showTooltip={showTooltip}
          color="yellow"
        />
      )}
      {redReasons && (
        <BlackListColorIndicator
          reasons={redReasons}
          showTooltip={showTooltip}
          color="red"
        />
      )}
    </Flex>
  );
};

const BlackListColorIndicator = ({
  reasons,
  showTooltip,
  color,
}: BlackListIndicatorProps & IndicatorColor) => (
  <>
    {showTooltip && (
      <Tooltip text={`Blacklist: ${prettifiedReason[reasons[0]]}`}>
        <StyledIndicator color={color} />
      </Tooltip>
    )}
    {!showTooltip && <StyledIndicator color={color} />}
  </>
);

const StyledIndicator = styled.span<{ color: string }>`
  margin-right: ${(p) => p.theme.unit(0.5)};
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${(p) =>
    p.color === "yellow"
      ? p.theme.colorPalette.yellow[500]
      : p.theme.colorPalette.red[500]};
  display: inline-block;
`;
