import * as React from "react";

const SvgMobilepayLogoBlue = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={28}
    viewBox="0 0 44 28"
    {...props}
  >
    <defs>
      <linearGradient
        id="MobilepayLogoBlue_svg__a"
        x1="48.74%"
        x2="52.4%"
        y1="76%"
        y2="-.58%"
      >
        <stop
          offset="0%"
          stopColor="#504678"
        />
        <stop
          offset="30.2%"
          stopColor="#504678"
          stopOpacity={0.62}
        />
        <stop
          offset="60.8%"
          stopColor="#504678"
          stopOpacity={0.28}
        />
        <stop
          offset="85.2%"
          stopColor="#504678"
          stopOpacity={0.08}
        />
        <stop
          offset="100%"
          stopColor="#504678"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id="MobilepayLogoBlue_svg__b"
        x1="48.74%"
        x2="52.4%"
        y1="76%"
        y2="-.58%"
      >
        <stop
          offset="0%"
          stopColor="#504678"
        />
        <stop
          offset="30.2%"
          stopColor="#504678"
          stopOpacity={0.62}
        />
        <stop
          offset="60.8%"
          stopColor="#504678"
          stopOpacity={0.28}
        />
        <stop
          offset="85.2%"
          stopColor="#504678"
          stopOpacity={0.08}
        />
        <stop
          offset="100%"
          stopColor="#504678"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id="MobilepayLogoBlue_svg__c"
        x1="13.7%"
        x2="57.38%"
        y1="66.34%"
        y2="41.26%"
      >
        <stop
          offset="0%"
          stopColor="#504678"
        />
        <stop
          offset="17.9%"
          stopColor="#504678"
          stopOpacity={0.87}
        />
        <stop
          offset="52.6%"
          stopColor="#504678"
          stopOpacity={0.54}
        />
        <stop
          offset="100%"
          stopColor="#504678"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id="MobilepayLogoBlue_svg__d"
        x1="47.72%"
        x2="45.26%"
        y1="34.97%"
        y2="18.38%"
      >
        <stop
          offset="0%"
          stopColor="#504678"
        />
        <stop
          offset="64.3%"
          stopColor="#504678"
          stopOpacity={0.33}
        />
        <stop
          offset="100%"
          stopColor="#504678"
          stopOpacity={0}
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <path
        fill="#5A78FF"
        d="M3 0h38a3 3 0 013 3v22a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3z"
      />
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M21.02 21.995c-.546 0-1.04-.33-1.25-.835L15.105 9.894c-.286-.69.041-1.48.73-1.766l4.89-2.024a1.35 1.35 0 011.764.73l4.667 11.268a1.351 1.351 0 01-.73 1.765l-4.89 2.025a1.342 1.342 0 01-.514.103zm.22-15.267a.62.62 0 00-.238.048L16.113 8.8a.624.624 0 00-.337.815l4.667 11.268a.624.624 0 00.815.337l4.888-2.026a.624.624 0 00.337-.815L21.816 7.113a.625.625 0 00-.576-.385z"
        />
        <path
          fill="url(#MobilepayLogoBlue_svg__a)"
          opacity={0.5}
          style={{
            mixBlendMode: "multiply",
          }}
          d="M8.02 1.53h1v4.83h-1z"
          transform="rotate(-22.5 30.966 -30.757)"
        />
        <path
          fill="url(#MobilepayLogoBlue_svg__b)"
          opacity={0.5}
          style={{
            mixBlendMode: "multiply",
          }}
          d="M10.1 6.23h1v5.47h-1z"
          transform="rotate(157.5 17.366 13.459)"
        />
        <path
          fill="#FFF"
          d="M25.324 14.284c-1.398.58-2.565 1.379-3.33 2.342l-1.97-4.76a8.321 8.321 0 013.33-2.341c1.398-.58 2.81-.86 4.01-.697l1.971 4.759a8.317 8.317 0 00-4.01.697z"
        />
        <g
          opacity={0.25}
          style={{
            mixBlendMode: "multiply",
          }}
        >
          <path
            fill="url(#MobilepayLogoBlue_svg__c)"
            d="M5.455 5.733c-1.398.58-2.566 1.379-3.33 2.342l-1.97-4.76A8.321 8.321 0 013.484.975c1.397-.58 2.81-.86 4.01-.697l1.97 4.76a8.317 8.317 0 00-4.01.696z"
            opacity={0.9}
            transform="translate(19.87 8.55)"
          />
          <path
            fill="url(#MobilepayLogoBlue_svg__d)"
            d="M5.455 5.733c-1.398.58-2.566 1.379-3.33 2.342l-1.97-4.76A8.321 8.321 0 013.484.975c1.397-.58 2.81-.86 4.01-.697l1.97 4.76a8.317 8.317 0 00-4.01.696z"
            opacity={0.7}
            transform="translate(19.87 8.55)"
          />
        </g>
        <path
          fill="#FFF"
          d="M25.966 15.735c-1.513 0-2.898.292-3.972.889v-4.812a8.321 8.321 0 013.972-.89c1.514 0 2.926.281 3.972.891v4.812a8.317 8.317 0 00-3.972-.89z"
        />
      </g>
    </g>
  </svg>
);
export default SvgMobilepayLogoBlue;
