import * as React from "react";
import { Text, Flex } from "@boligportal/juice";

export const RefundInfo = (props: {
  title?: string;
  children: React.ReactNode;
}) => (
  <Flex
    column
    mb={2}
  >
    {props.title && (
      <Text
        block
        size="tiny"
      >
        {props.title}
      </Text>
    )}
    {props.children}
  </Flex>
);
