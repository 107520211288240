import { TenancyPaymentStatus } from "./TenancyPaymentStatus";
import { TenancyPaymentType } from "./TenancyPaymentType";

export const canUseMarkAsManuallyPaid = (
  status: TenancyPaymentStatus,
  paymentType: TenancyPaymentType,
) => {
  if (paymentType === TenancyPaymentType.MOBILEPAY) {
    return status === TenancyPaymentStatus.NOT_PAID;
  }

  return false;
};
