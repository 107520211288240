import { TenancyPaymentFrequency } from "business/domain/tenancy/TenancyPaymentFrequency";
import { t } from "lib/i18n";

export const createTenancyPaymentFrequencyLabel = (
  frequency: TenancyPaymentFrequency,
) => {
  const frequencyToTranslation = {
    [TenancyPaymentFrequency.MONTHLY_1ST]: t("common.month.short"),
    [TenancyPaymentFrequency.MONTHLY_15TH]: t("common.month.short"),
    [TenancyPaymentFrequency.QUARTERLY_1ST]: t("common.quarter.short"),
    [TenancyPaymentFrequency.QUARTERLY_15TH]: t("common.quarter.short"),
  };

  const frequencyLabel = frequencyToTranslation[frequency];

  return `/${frequencyLabel}`;
};
