import { AdStates } from "lib/enums";
import { AdsPageAll } from "./ads_page_all";

const AdsPageRejected = () => (
  <AdsPageAll
    initialStates={[AdStates.INACTIVE_REJECTED]}
    actionLabelText={"View"}
  />
);

export { AdsPageRejected };
