import { Badge, Box, Button, IconAdd, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { SelectedUnit } from "./DefectDetails";

export const Units = ({
  units,
  isTenantFlow,
  isEditMode,
  onRemove,
  onEdit,
}: {
  units: SelectedUnit[];
  isTenantFlow: boolean;
  isEditMode: boolean;
  onRemove: (id: number) => void;
  onEdit: () => void;
}) => {
  const isUnitsNotEditable = isTenantFlow || isEditMode;

  return (
    <Box
      mb={3}
      pb={isTenantFlow ? 1 : 3}
      borderBottom
    >
      <Text
        size="h5"
        weight="bold"
      >
        {t("moving_reports.room.unit.headline")}
      </Text>

      <Box my={1}>
        {units.map((item) => (
          <Box
            key={item.id}
            mr={1}
            mb={1}
            inline
          >
            <Badge
              variant="transparent"
              state="primary"
              onClose={isUnitsNotEditable ? undefined : () => onRemove(item.id)}
            >
              {item.roomLabel} – {item.label}
            </Badge>
          </Box>
        ))}
      </Box>
      {!isUnitsNotEditable && (
        <Button
          text
          fontSize="small"
          textAlign="left"
          onClick={onEdit}
        >
          <IconAdd
            size="small"
            inline
          />
          {t("moving_reports.room.unit.create_defect.add_or_remove_unit")}
        </Button>
      )}
    </Box>
  );
};
