import { Badge, Flex, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { SeekerMenuItem } from "../drop_down_menu/UserAvatarSeekerMenu";

export const BenefitsMenuItem = (item: SeekerMenuItem) => {
  const { name } = item;
  return (
    <Flex
      align="center"
      ml={1}
      gap
      onClick={TrackingEvent.clickBenefitsDropdownMenu}
    >
      <Text>{name}</Text>
      <Badge variant="solid">{t("benefits_menu_item.badge")}</Badge>
    </Flex>
  );
};
