/* eslint-disable comma-dangle */
import { fetchFromAPI } from "lib/api";
import { RentalAdsRequestPayload } from "../interfaces/RentalAdsRequestPayload";
import { RentalAdsResponsePayload } from "../interfaces/RentalAdsResponsePayload";

export const getRentalAdsAsync = async (
  requestPayload: Partial<RentalAdsRequestPayload>,
): Promise<RentalAdsResponsePayload> => {
  const response = await fetchFromAPI("/api/listing/listings/", {
    method: "POST",
    body: JSON.stringify({
      ...requestPayload,
    }),
  });

  return response.json();
};
