import { AdStates } from "lib/enums";
import { AdsPageAll } from "./ads_page_all";

const AdsPageFollowUp = () => (
  <AdsPageAll
    initialStates={[AdStates.INACTIVE_PENDING_APPROVAL]}
    initialNeedsFollowUp
    actionLabelText={"Begin follow up"}
    companyAds={false}
  />
);

export { AdsPageFollowUp };
