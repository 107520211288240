import { BoligportalDKMarketSettings } from "components/markets/boligportal_dk_market_setting";
import { BostadsportalSEMarketSettings } from "components/markets/bostadsportal_se_market_settings";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { Paywall } from "features/paywall/paywall";

export enum Market {
  BOSTADSPORTAL_SE = "bostadsportal.se",
  BOLIGPORTAL_DK = "boligportal.dk",
}

export type MarketSettings = {
  getHeaderLogoComponent(inverseTextColor?: boolean): React.ReactNode;
  expectedMaximumRentLength: number;
  countryBoundingBox: {
    sw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
  };
  customerService: {
    externalAddressWebsites: string[];
    externalPhoneWebsites: string[];
  };
  paymentMethodTypes: PaymentMethodType[];
  paywall: Paywall;
};

const marketSettingsMap = {
  [Market.BOLIGPORTAL_DK]: BoligportalDKMarketSettings,
  [Market.BOSTADSPORTAL_SE]: BostadsportalSEMarketSettings,
};

export const getMarketSettings = (market: Market) =>
  new marketSettingsMap[market]();
