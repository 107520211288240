import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useAutoScrollIntoView = () => {
  const history = useHistory();
  const location = useLocation<{ focusFieldName: string }>();

  useEffect(() => {
    if (location.state && location.state.focusFieldName) {
      const fields = document.getElementsByName(location.state.focusFieldName);

      if (fields.length) {
        const field = fields[0];

        field.focus();
      }

      history.replace({
        ...history.location,
        state: undefined,
      });
    }
  }, []);
};
