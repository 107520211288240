import { IconPersonCircleOutline, Notice } from "@boligportal/juice";

export const NoLandlordAvailableDisplay = () => (
  <Notice type="info">
    <IconPersonCircleOutline
      inline
      size="medium"
    />{" "}
    No Landlord information is available
  </Notice>
);
