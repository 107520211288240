export const getDoorValue = (door: string, side: string): string | null => {
  if (side) {
    return side;
  }

  if (door) {
    return door;
  }

  return null;
};
