import { useQuery } from "react-query";
import { t } from "lib/i18n";
import { getContacts } from "../api/get_contacts";
import { getDocuments } from "../api/get_documents";
import { getLeads } from "../api/get_leads";
import { getTenancyInfo } from "../api/get_tenancy_info";
import { getTimeline } from "../api/get_timeline";

export const tenantHubKeys = {
  tenancyInfo: (userToken: string) => ["tenancy-hub-info", userToken] as const,
  timeline: (userToken: string) => ["tenancy-hub-timeline", userToken] as const,
  contacts: (userToken: string) => ["tenancy-hub-contacts", userToken] as const,
  documents: (userToken: string) =>
    ["tenancy-hub-documents", userToken] as const,
  leads: (userToken: string) => ["tenancy-hub-leads", userToken] as const,
};

export const useTenancyInfo = (userToken: string) => {
  const { data, isError } = useQuery(tenantHubKeys.tenancyInfo(userToken), () =>
    getTenancyInfo(userToken),
  );

  return {
    data,
    errorMessage: isError ? t("Something went wrong. Try again.") : null,
  };
};

export const useTenantHubTimeline = (userToken: string) => {
  const { data, isError } = useQuery(
    tenantHubKeys.timeline(userToken),
    // () => mockTimelineResponse(),
    () => getTimeline(userToken),
  );
  return {
    data,
    errorMessage: isError ? t("Something went wrong. Try again.") : null,
  };
};

export const useTenantHubContacts = (userToken: string) => {
  const { data, isError } = useQuery(
    tenantHubKeys.contacts(userToken),
    // () => mockContactsResponse(),
    () => getContacts(userToken),
  );
  return {
    data,
    errorMessage: isError ? t("Something went wrong. Try again.") : null,
  };
};

export const useTenantHubDocuments = (userToken: string) => {
  const { data, isError } = useQuery(
    tenantHubKeys.documents(userToken),
    // () => mockDocumentsResponse(),
    () => getDocuments(userToken),
  );
  return {
    data,
    errorMessage: isError ? t("Something went wrong. Try again.") : null,
  };
};

export const useTenantHubLeads = (userToken: string) => {
  const { data, isError } = useQuery(tenantHubKeys.leads(userToken), () =>
    getLeads(userToken),
  );
  return {
    data,
    errorMessage: isError ? t("Something went wrong. Try again.") : null,
  };
};
