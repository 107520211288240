import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { MutationStatus } from "react-query";
import { Box, Flex, IconHome, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../../types";

export type Props = {
  address: string;
  disabled: boolean;
  status: MutationStatus;
  fieldName: `rentables.${number}`;
  getValues: UseFormGetValues<CreateRentableForm>;
  setValue: UseFormSetValue<CreateRentableForm>;
  retry: () => void;
};

export const Address = ({
  address,
  disabled,
  status,
  fieldName,
  setValue,
  getValues,
  retry,
}: Props) => {
  const toggleShouldCreate = () => {
    const shouldCreateFieldName = `${fieldName}.shouldCreate` as const;
    const shouldCreate = getValues(shouldCreateFieldName);

    // @ts-ignore
    setValue(shouldCreateFieldName, !shouldCreate);
  };

  const onAddressClick = () => {
    if (status === "error") {
      retry();
      return;
    }

    toggleShouldCreate();
  };

  return (
    <>
      <Box
        onClick={disabled || status === "loading" ? undefined : onAddressClick}
      >
        <Text block>{address}</Text>

        {disabled && (
          <Flex align="center">
            <IconHome
              color="primary"
              mr={0.5}
            />
            <Text
              weight="bold"
              size="small"
              color="primary"
            >
              {t("feature.address_form.rentable_list.item.disabled")}
            </Text>
          </Flex>
        )}

        {status === "error" && (
          <Text
            color="danger"
            size="small"
          >
            {t("feature.address_form.rentable_list.item.failed_to_create")}
          </Text>
        )}
      </Box>
    </>
  );
};
