import { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckField, Confirm, Flex } from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { CompanyDetailsDTO } from "../helpers";

type CompanyDraft = {
  danske_udlejere: boolean;
  interactive_floor_plan_allowed: boolean;
  can_impersonate: boolean;
  social_housing: boolean;
  fixed_upsell: boolean;
  has_data_insights_access: boolean;
};

const CompanySettings = (props: {
  item: CompanyDetailsDTO;
  updateField: (fieldname: string, value: any) => void;
}) => {
  const {
    danske_udlejere,
    interactive_floor_plan_allowed,
    social_housing,
    fixed_upsell,
    has_data_insights_access,
    verified,
    can_impersonate,
  } = props.item;
  const [confimDialogState, setConfimDialogState] = useState(false);

  const { register, setValue } = useForm<CompanyDraft>({
    defaultValues: {
      danske_udlejere,
      interactive_floor_plan_allowed,
      social_housing,
      fixed_upsell,
      has_data_insights_access,
      can_impersonate,
    },
  });

  return (
    <Flex column>
      {App.settings.market === Market.BOLIGPORTAL_DK && (
        <>
          <CheckField
            {...register("danske_udlejere")}
            inline
            label="Danske Udlejere"
            onChange={(e) =>
              props.updateField("danske_udlejere", e.target.checked)
            }
          />
          <CheckField
            {...register("social_housing")}
            inline
            label="Almen bolig"
            onChange={(e) => {
              e.target.checked === false
                ? (setConfimDialogState(false),
                  props.updateField("social_housing", false))
                : setConfimDialogState(true);
            }}
          />
          {verified && (
            <CheckField
              {...register("fixed_upsell")}
              inline
              label="Enable fixed upsell"
              onChange={(e) =>
                props.updateField("fixed_upsell", e.target.checked)
              }
            />
          )}
          {confimDialogState && (
            <Confirm
              open
              title="Almen bolig"
              message={`You are about to mark "${props.item.name}" as Almen bolig. All company advertisements will be transformed into social housing. Are you sure?`}
              confirmText="Agree"
              cancelText="Cancel"
              onConfirm={() => {
                setValue("social_housing", true);
                props.updateField("social_housing", true);
                setConfimDialogState(false);
              }}
              onCancel={() => {
                setValue("social_housing", false);
                props.updateField("social_housing", false);
                setConfimDialogState(false);
              }}
            />
          )}
          <CheckField
            {...register("has_data_insights_access")}
            inline
            label="Data Insights access"
            onChange={(e) =>
              props.updateField("has_data_insights_access", e.target.checked)
            }
          />
        </>
      )}

      <CheckField
        {...register("interactive_floor_plan_allowed")}
        inline
        label="Enable 3D floorplan section"
        onChange={(e) =>
          props.updateField("interactive_floor_plan_allowed", e.target.checked)
        }
      />

      <CheckField
        {...register("can_impersonate")}
        inline
        label="Enable Colleague log in"
        onChange={(e) => props.updateField("can_impersonate", e.target.checked)}
      />
    </Flex>
  );
};
export { CompanySettings };
