import { TextField } from "@boligportal/juice";
import { FormElementInterface } from "../../interfaces";
import useUpdatableFormElement from "./useUpdatableFormElement";

const FloorplanFormElement = <T extends any>(
  props: FormElementInterface<T>,
) => {
  const { register, save, isSaving } = useUpdatableFormElement(
    props.fieldname,
    props.updateTask,
  );

  return (
    <TextField
      {...register(props.fieldname)}
      label={props.fieldLabel}
      disabled={isSaving}
      type="text"
      onBlur={save}
      previousValue={props.previousValue}
      defaultValue={props.defaultValue}
    />
  );
};

export { FloorplanFormElement };
