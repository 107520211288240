/* eslint-disable comma-dangle */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RentalAdViewQueryParamsForAdStateEnum } from "../enums/RentalAdViewQueryParamsForAdStateEnum";
import { RentalAdViewModel } from "../interfaces/RentalAdViewModel";

interface PersistStateInUrlProps {
  sorting: keyof RentalAdViewModel;
  descending: boolean;
  limit: number;
  start_index: number;
  search_term: string;
  ad_state: RentalAdViewQueryParamsForAdStateEnum;
  isAdDetails?: boolean;
}
export const usePersistStateInUrl = ({
  sorting,
  descending,
  limit,
  start_index,
  search_term,
  ad_state,
  isAdDetails,
}: PersistStateInUrlProps) => {
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);

    query.set("search_term", search_term || "");
    query.set("sorting", sorting || "");
    query.set("descending", descending ? "true" : "false");
    query.set("start_index", start_index?.toString() || "");
    query.set("limit", limit?.toString() || "");
    query.set("filter", ad_state || "");

    if (!isAdDetails) {
      history.replace(`${location.pathname}?${query.toString()}`);
    }
  }, [
    sorting,
    descending,
    limit,
    start_index,
    search_term,
    ad_state,
    isAdDetails,
    history,
  ]);
};
