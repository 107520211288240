import { Box, Flex, styled, Text, Tooltip } from "@boligportal/juice";
import { t } from "lib/i18n";

const shortenedEnergyLabel = (label: string) => {
  if (label === "A2010") {
    return "A10";
  } else if (label === "A2015") {
    return "A15";
  } else if (label === "A2020") {
    return "A20";
  } else {
    return label;
  }
};

const EnergyRatingIcon = styled.img<{ width?: string; border?: boolean }>`
  width: ${(props) => (props.width ? props.width : "24px")};
  ${(props) =>
    props.border &&
    `
    border: ${props.theme.borderWidth.sm} solid ${props.theme.color.border};
    border-radius: ${props.theme.borderRadius.sm};
    `}
`;

interface Props {
  label: string;
  text?: string | null;
  width?: string;
  border?: boolean | false;
}

export const EnergyLabel = ({ label, text, width, border }: Props) => (
  <>
    <Flex align="center">
      <Tooltip text={`${t("ad_details.energy_label.on_hover")}: ${label}`}>
        <EnergyRatingIcon
          src={`/static/images/energy_labels/${shortenedEnergyLabel(
            label,
          )}_str2.png`}
          width={width}
          border={border}
        />
      </Tooltip>
      {/* can be used together with text prop to display icon and text */}
      {text && (
        <Box ml={1}>
          <Text weight="bold">{text}</Text>
        </Box>
      )}
    </Flex>
  </>
);
