/* eslint-disable compat/compat */
import * as React from "react";
import {
  Box,
  SelectField,
  Spinner,
  useNotifications,
} from "@boligportal/juice";
import {
  getCompanyDiscount,
  giveCompanyDiscount,
} from "lib/customerServiceApi";
import { Company } from "./interfaces";

const DiscountWidget = ({
  company,
  onDiscountChanged,
}: {
  company: Company;
  onDiscountChanged: () => void;
}) => {
  const { addNotification } = useNotifications();
  const [discountValue, setDiscountValue] = React.useState<string | undefined>(
    undefined,
  );

  const loadDiscounts = React.useCallback(() => {
    getCompanyDiscount(company.id).then((response) => {
      if (response.discount) {
        setDiscountValue(String(response.discount.percent_off));
      } else {
        setDiscountValue("0");
      }
    });
  }, [company.id]);

  React.useEffect(() => {
    loadDiscounts();
  }, [company.id, loadDiscounts]);

  const handleDiscountChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const discountValue = event.target.value;
    setDiscountValue(discountValue);
    if (addNotification) {
      addNotification({
        title: "Saving discount...",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
    giveCompanyDiscount(company.id, Number(discountValue)).then((response) => {
      onDiscountChanged();

      if (addNotification) {
        addNotification({
          title: "Saved discount",
          autoDismiss: true,
          autoDismissTimeout: 1000,
        });
      }
    });
  };

  return (
    <Box key={company.id}>
      {discountValue === undefined && <Spinner size="large" />}
      {discountValue !== undefined && (
        <SelectField
          id="Discount"
          name="Discount"
          value={discountValue}
          onChange={handleDiscountChange}
          label={`Discount for: ${company.name}`}
          items={[
            {
              value: "0",
              label: "None Selected",
            },
            {
              value: "15",
              label: "15%",
            },
            {
              value: "20",
              label: "20%",
            },
            {
              value: "24.4",
              label: "24,4%",
            },
            {
              value: "24.6",
              label: "24,6%",
            },
            {
              value: "25",
              label: "25%",
            },
            {
              value: "25.8",
              label: "25,8%",
            },
            {
              value: "25.9",
              label: "25,9%",
            },
            {
              value: "26",
              label: "26%",
            },
            {
              value: "26.4",
              label: "26,4%",
            },
            {
              value: "26.6",
              label: "26,6%",
            },
            {
              value: "26.9",
              label: "26,9%",
            },
            {
              value: "27.7",
              label: "27,7%",
            },
            {
              value: "28",
              label: "28%",
            },
            {
              value: "28.1",
              label: "28,1%",
            },
            {
              value: "30",
              label: "30%",
            },
            {
              value: "30.1",
              label: "30,1%",
            },
            {
              value: "30.4",
              label: "30,4%",
            },
            {
              value: "31",
              label: "31%",
            },
            {
              value: "31.1",
              label: "31,1%",
            },
            {
              value: "31.5",
              label: "31,5%",
            },
            {
              value: "31.8",
              label: "31,8%",
            },
            {
              value: "32",
              label: "32%",
            },
            {
              value: "32.4",
              label: "32,4%",
            },
            {
              value: "35",
              label: "35%",
            },
            {
              value: "35.8",
              label: "35,8%",
            },
            {
              value: "36",
              label: "36%",
            },
            {
              value: "36.1",
              label: "36,1%",
            },
            {
              value: "36.3",
              label: "36,3%",
            },
            {
              value: "37.1",
              label: "37,1%",
            },
            {
              value: "37.3",
              label: "37,3%",
            },
            {
              value: "37.7",
              label: "37,7%",
            },
            {
              value: "37.8",
              label: "37,8%",
            },
            {
              value: "37.9",
              label: "37,9%",
            },
            {
              value: "38.4",
              label: "38,4%",
            },
            {
              value: "39.5",
              label: "39,5%",
            },
            {
              value: "39.6",
              label: "39,6%",
            },
            {
              value: "40",
              label: "40%",
            },
            {
              value: "41",
              label: "41%",
            },
            {
              value: "41.8",
              label: "41,8%",
            },
            {
              value: "42",
              label: "42%",
            },
            {
              value: "42.7",
              label: "42,7%",
            },
            {
              value: "43.4",
              label: "43,4%",
            },
            {
              value: "44.1",
              label: "44,1%",
            },
            {
              value: "44",
              label: "44%",
            },
            {
              value: "44.9",
              label: "44,9%",
            },
            {
              value: "45",
              label: "45%",
            },
            {
              value: "45.8",
              label: "45,8%",
            },
            {
              value: "46",
              label: "46%",
            },
            {
              value: "47",
              label: "47%",
            },
            {
              value: "48",
              label: "48%",
            },
            {
              value: "49",
              label: "49%",
            },
            {
              value: "49.9",
              label: "49,9%",
            },
            {
              value: "50",
              label: "50%",
            },
            {
              value: "51",
              label: "51%",
            },
            {
              value: "52",
              label: "52%",
            },
            {
              value: "53",
              label: "53%",
            },
            {
              value: "54",
              label: "54%",
            },
            {
              value: "55",
              label: "55%",
            },
            {
              value: "56",
              label: "56%",
            },
            {
              value: "57",
              label: "57%",
            },
            {
              value: "58",
              label: "58%",
            },
            {
              value: "59",
              label: "59%",
            },
            {
              value: "60",
              label: "60%",
            },
            {
              value: "63",
              label: "63%",
            },
          ]}
        />
      )}
    </Box>
  );
};

export { DiscountWidget };
