import { Alert, styled, Text } from "@boligportal/juice";
import { FormFieldError } from "./interfaces";

const StyledAlertContentWrapper = styled.div`
  cursor: pointer;
`;

const FieldErrorAlert = (props: { error: FormFieldError }) => {
  const handleClick = () => {
    // FIXME: Names aren't unique across forms. Make sure to only search inside current form
    const elements = document.getElementsByName(props.error.affectedField);
    if (elements.length > 0) {
      elements[0].focus();
    }
  };

  return (
    <Alert type="danger">
      <StyledAlertContentWrapper onClick={handleClick}>
        {props.error.heading && (
          <Text
            as="span"
            size="small"
            color="danger"
            weight="bold"
            pr={0.5}
          >
            {`${props.error.heading}:`}
          </Text>
        )}
        <Text
          as="span"
          size="small"
          color="danger"
        >
          {props.error.message}
        </Text>
      </StyledAlertContentWrapper>
    </Alert>
  );
};

export { FieldErrorAlert };
