import { UpsellDialogTrigger } from "features/upsell/components/RentableUpsellDialog/rentableDialogTrigger";
import { RentalAdViewQueryParamsForAdStateEnum } from "../enums/RentalAdViewQueryParamsForAdStateEnum";
import { RentalAdViewModel } from "./RentalAdViewModel";

type LoadRentalAds = () => void;
export type UpdateActiveSortableColumn = (
  fieldName: keyof RentalAdViewModel,
  descending: boolean,
) => void;
export type UpdatePaginationStartIndex = (newStartIndex: number) => void;
export type UpdateSearchValue = (newSearchValue: string) => void;
export type UpdateAdState = (
  newAdState: RentalAdViewQueryParamsForAdStateEnum,
) => void;
export type MarkAsActive = (rentableId: number, listingId: number) => void;
export type ShowUpsellDialog = (
  rentableId: number,
  trigger: UpsellDialogTrigger,
  visiblePlans?: string[],
) => void;
export type CloseUpsellDialog = () => void;

export interface RentalAdsFeature {
  loadRentalAds: LoadRentalAds;
  updateActiveSortableColumn: UpdateActiveSortableColumn;
  updatePaginationStartIndex: UpdatePaginationStartIndex;
  updateSearchValue: UpdateSearchValue;
  updateAdState: UpdateAdState;
  markAsActive: MarkAsActive;
  showUpsellDialog: ShowUpsellDialog;
  closeUpsellDialog: CloseUpsellDialog;
}

export const initialRentableAdsFeature: RentalAdsFeature = {
  loadRentalAds: () => {},
  updateActiveSortableColumn: () => {},
  updatePaginationStartIndex: () => {},
  updateSearchValue: () => {},
  updateAdState: () => {},
  markAsActive: () => {},
  showUpsellDialog: () => {},
  closeUpsellDialog: () => {},
};
