import { Box, styled, IconFlag, Flex, TextField } from "@boligportal/juice";
import { ThreadColorEnum } from "apps/inbox/thread_color_helper";
import {
  OCCUPATION,
  USER_TYPE,
  occupationLabel,
  userTypeLabel,
} from "components/interfaces/profile";
import { ThreadFilter } from "components/interfaces/thread_filter";
import { t } from "lib/i18n";
import { TrackingEvent, EventCategory } from "lib/tracking/events";
import { getThreadColorCode } from "../../../thread_color_helper";
import { OptionsGroup } from "./options_group";

const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export interface FilterInterface {
  filter: ThreadFilter;
  onChange: (filter: ThreadFilter) => void;
  trackingEventsCategory: EventCategory;
  showColorMarkFilter?: boolean;
}

export const Filter = (props: FilterInterface) => {
  const stringToBool = (value: string): boolean => {
    if (value === "true") {
      return true;
    }

    return false;
  };

  const handleAgeChange = (event) => {
    const parsedValue = parseInt(event.target.value, 10);

    const updatedFilter = {
      ...props.filter,
      profile: {
        ...props.filter.profile,
        [event.target.name]: isNaN(parsedValue) ? null : parsedValue,
      },
    };

    props.onChange(updatedFilter);

    TrackingEvent.filterClickedAge(props.trackingEventsCategory);
  };

  return (
    <Box p={1}>
      <Box mb={2}>
        <Box>
          <Box mb={1}>{t("inbox.messages")}</Box>
          <Box>
            <StyledLabel>
              <input
                type="checkbox"
                checked={props.filter.visibility.includeArchivedMessages}
                onChange={(event) => {
                  props.onChange({
                    ...props.filter,
                    visibility: {
                      ...props.filter.visibility,
                      includeArchivedMessages: event.target.checked,
                    },
                  });
                }}
                style={{
                  marginRight: "5px",
                }}
              />
              {t("inbox.filter_include_archived_messages")}
            </StyledLabel>
          </Box>
        </Box>
      </Box>

      {props.showColorMarkFilter && (
        <OptionsGroup<number>
          title={t("inbox.filter_by_color_mark")}
          valueConverter={(value): number => parseInt(value, 10)}
          options={{
            [ThreadColorEnum.GREEN]: (
              <InlineFlex>
                <Box mr={0.5}>
                  <IconFlag
                    css={{
                      color: getThreadColorCode(ThreadColorEnum.GREEN),
                    }}
                  />
                </Box>
                {t("inbox.green").replace(/^./, (l) => l.toUpperCase())}
              </InlineFlex>
            ),
            [ThreadColorEnum.RED]: (
              <InlineFlex>
                <Box mr={0.5}>
                  <IconFlag
                    css={{
                      color: getThreadColorCode(ThreadColorEnum.RED),
                    }}
                  />
                </Box>
                {t("inbox.red").replace(/^./, (l) => l.toUpperCase())}
              </InlineFlex>
            ),
            [ThreadColorEnum.YELLOW]: (
              <InlineFlex>
                <Box mr={0.5}>
                  <IconFlag
                    css={{
                      color: getThreadColorCode(ThreadColorEnum.YELLOW),
                    }}
                  />
                </Box>
                {t("inbox.yellow").replace(/^./, (l) => l.toUpperCase())}
              </InlineFlex>
            ),
          }}
          value={props.filter.color}
          onChange={(value) => {
            props.onChange({
              ...props.filter,
              color: value,
            });
            TrackingEvent.filterClickedColors(props.trackingEventsCategory);
          }}
        />
      )}

      <OptionsGroup<number>
        title={t("profileFilter.profile_type")}
        options={{
          [USER_TYPE.INDIVIDUAL]: t(userTypeLabel[USER_TYPE.INDIVIDUAL]),
          [USER_TYPE.COUPLE]: t(userTypeLabel[USER_TYPE.COUPLE]),
          [USER_TYPE.FAMILY]: t(userTypeLabel[USER_TYPE.FAMILY]),
          [USER_TYPE.COMPANY]: t(userTypeLabel[USER_TYPE.COMPANY]),
          [USER_TYPE.GROUP]: t(userTypeLabel[USER_TYPE.GROUP]),
        }}
        value={props.filter.profile.user_type}
        valueConverter={(value): number => parseInt(value, 10)}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              user_type: value,
            },
          });
          TrackingEvent.filterClickedProfileType(props.trackingEventsCategory);
        }}
      />

      <Box mb={3}>
        <Box mb={1}>{t("profileFilter.age")}</Box>
        <Flex align="center">
          <TextField
            name="min_age"
            placeholder="min."
            onChange={handleAgeChange}
            value={
              props.filter.profile.min_age === null
                ? ""
                : props.filter.profile.min_age.toString()
            }
          />
          <Box mx={1}>-</Box>
          <TextField
            name="max_age"
            placeholder="max."
            onChange={handleAgeChange}
            value={
              props.filter.profile.max_age === null
                ? ""
                : props.filter.profile.max_age.toString()
            }
          />
        </Flex>
      </Box>

      <OptionsGroup<number>
        title={t("profileFilter.occupation")}
        options={{
          [OCCUPATION.EMPLOYED]: t(occupationLabel[OCCUPATION.EMPLOYED]),
          [OCCUPATION.UNEMPLOYED]: t(occupationLabel[OCCUPATION.UNEMPLOYED]),
          [OCCUPATION.STUDENT]: t(occupationLabel[OCCUPATION.STUDENT]),
          [OCCUPATION.RETIRED]: t(occupationLabel[OCCUPATION.RETIRED]),
        }}
        value={props.filter.profile.occupation}
        valueConverter={(value): number => parseInt(value, 10)}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              occupation: value,
            },
          });
          TrackingEvent.filterClickedOccupation(props.trackingEventsCategory);
        }}
      />

      <OptionsGroup<boolean>
        title={t("profileFilter.pets")}
        options={{
          true: t("inbox.has_pets"),
          false: t("inbox.has_no_pets"),
        }}
        value={props.filter.profile.pets}
        valueConverter={stringToBool}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              pets: value,
            },
          });
          TrackingEvent.filterClickedPets(props.trackingEventsCategory);
        }}
      />

      <OptionsGroup<boolean>
        title={t("profileFilter.smoker")}
        options={{
          true: t("inbox.smoker"),
          false: t("inbox.non_smoker"),
        }}
        value={props.filter.profile.smoking}
        valueConverter={stringToBool}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              smoking: value,
            },
          });
          TrackingEvent.filterClickedSmoking(props.trackingEventsCategory);
        }}
      />

      <OptionsGroup<boolean>
        title={t("profileFilter.sharing")}
        options={{
          true: t("inbox.will_share"),
          false: t("inbox.will_not_share"),
        }}
        value={props.filter.profile.share_apartment}
        valueConverter={stringToBool}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              share_apartment: value,
            },
          });
          TrackingEvent.filterClickedSharing(props.trackingEventsCategory);
        }}
      />

      <OptionsGroup<boolean>
        title={t("profileFilter.kids")}
        options={{
          true: t("inbox.has_kids"),
          false: t("inbox.has_no_kids"),
        }}
        value={props.filter.profile.kids}
        valueConverter={stringToBool}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              kids: value,
            },
          });
          TrackingEvent.filterClickedKids(props.trackingEventsCategory);
        }}
      />

      <OptionsGroup<boolean>
        title={t("profileFilter.expat")}
        options={{
          true: t("inbox.expat"),
          false: t("inbox.not_expat"),
        }}
        value={props.filter.profile.expat}
        valueConverter={stringToBool}
        onChange={(value) => {
          props.onChange({
            ...props.filter,
            profile: {
              ...props.filter.profile,
              expat: value,
            },
          });
          TrackingEvent.filterClickedExpat(props.trackingEventsCategory);
        }}
      />
    </Box>
  );
};
