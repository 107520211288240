import * as React from "react";
import styled from "@emotion/styled";

const StyleStep = styled.div`
  margin-bottom: 41px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 80px;
  }
`;

export const Step = ({ children }: { children: React.ReactNode }) => (
  <StyleStep>{children}</StyleStep>
);
