export const FlagUk = () => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_343_10885)">
      <path
        d="M27.9694 0.99797V18.9521C27.9708 19.0717 27.9494 19.1903 27.9065 19.3013C27.8434 19.4735 27.7359 19.6245 27.5956 19.738C27.4553 19.8514 27.2877 19.9229 27.1108 19.9446C27.0758 19.9488 27.0403 19.9507 27.0047 19.9494H0.964883C0.929431 19.9505 0.893952 19.9489 0.858725 19.9446C0.68192 19.9228 0.514273 19.8513 0.373995 19.7379C0.233717 19.6245 0.126172 19.4735 0.0630412 19.3013C0.0200771 19.1903 -0.00134677 19.0717 -9.10596e-06 18.9521V0.99797C-0.00159184 0.878332 0.0196274 0.759543 0.0624369 0.648386C0.125613 0.476208 0.233202 0.325227 0.373516 0.211844C0.51383 0.0984618 0.681504 0.0270135 0.858322 0.00526149C0.893574 0.00109483 0.929027 -0.000571841 0.96448 0.000469826H27.0049C27.0403 -0.000571841 27.076 0.00109483 27.111 0.00526149C27.2879 0.027023 27.4555 0.0985019 27.5958 0.211927C27.7361 0.325351 27.8436 0.476381 27.9067 0.648595C27.9496 0.759636 27.971 0.878386 27.9696 0.997762L27.9694 0.99797Z"
        fill="#285680"
      />
      <path
        d="M27.9694 0.99791C27.9709 0.878314 27.9495 0.759597 27.9066 0.648535C27.8435 0.476321 27.736 0.325291 27.5957 0.211867C27.4554 0.0984418 27.2877 0.0269629 27.1109 0.00520144C27.0757 0.000948106 27.0402 -0.00065333 27.0047 0.000409772H25.4232L16.8782 6.09478V0.000409772H11.0913V6.09478L2.54624 0.000409772H0.964541C0.929157 -0.00063813 0.893745 0.000963273 0.858584 0.00520144C0.68178 0.0270076 0.514133 0.0985034 0.373855 0.211922C0.233577 0.325341 0.126031 0.476346 0.0629008 0.648535C0.0199796 0.759597 -0.00137625 0.878314 5.19202e-05 0.99791V1.81583L7.24319 6.98249H5.19202e-05V12.9677H7.23815L0.000253359 18.1342V18.9521C-0.00139827 19.0716 0.0199661 19.1903 0.0631023 19.3012C0.126159 19.4734 0.233658 19.6245 0.373909 19.7379C0.514159 19.8513 0.681794 19.9228 0.858584 19.9446C0.893635 19.9487 0.929088 19.9506 0.964743 19.9494H2.54644L11.0915 13.855V19.9494H16.878V13.855L25.423 19.9494H27.0047C27.0402 19.9505 27.0757 19.9489 27.1109 19.9446C27.2877 19.9228 27.4554 19.8513 27.5956 19.7379C27.7359 19.6244 27.8435 19.4734 27.9066 19.3012C27.9495 19.1902 27.9708 19.0716 27.9694 18.9521V18.1342L20.7263 12.9675H27.9694V6.98249H20.7309L27.9694 1.81562V0.99791Z"
        fill="white"
      />
      <path
        d="M27.9065 19.3012C27.8434 19.4735 27.7359 19.6245 27.5956 19.7379C27.4553 19.8513 27.2877 19.9228 27.1108 19.9446L17.3266 12.9675H19.0336L27.9065 19.3012ZM10.6428 6.98249H8.93562L0.0626444 0.648537C0.125775 0.476348 0.23332 0.325343 0.373598 0.211924C0.513876 0.0985052 0.681523 0.0270094 0.858328 0.00520325L10.6428 6.98249ZM27.9065 0.648537L19.0241 6.98249H17.3312L27.1108 0.00541158C27.2876 0.0271549 27.4553 0.0986028 27.5956 0.211989C27.7359 0.325375 27.8434 0.476362 27.9065 0.648537ZM10.638 12.9675L0.858529 19.9446C0.681725 19.9228 0.514078 19.8513 0.3738 19.7379C0.233522 19.6244 0.125976 19.4734 0.0628458 19.3012L8.94549 12.9675H10.638ZM0.000198364 8.97749H27.9692V10.9723H0.000198364V8.97749Z"
        fill="#C03A2B"
      />
      <path
        d="M13.0202 0.000411987H14.9492V19.9494H13.0202V0.000411987Z"
        fill="#C03A2B"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="19"
      rx="3.5"
      stroke="white"
    />
    <defs>
      <clipPath id="clip0_343_10885">
        <rect
          width="28"
          height="20"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
