import { useQuery, useQueryClient } from "react-query";
import { API } from "lib/api";

export const useSeekerPlans = () => {
  const queryClient = useQueryClient();
  const query = useQuery(plansQueryKeys.seekerPlans(), () =>
    API.getSeekerPlans(),
  );
  return {
    invalidatePlans: () =>
      queryClient.invalidateQueries(plansQueryKeys.seekerPlans()),
    plans: query.data,
  };
};

const plansQueryKeys = {
  seekerPlans: () => ["seekerPlans"] as const,
};
