import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
} from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  open: boolean;
  onConfirm: () => void;
};

export const PaymentRecordOneTimePaymentNotPossible = ({
  open,
  onConfirm,
}: Props) => (
  <Dialog
    open={open}
    size="large"
    onClose={onConfirm}
  >
    <DialogHeader>
      {t("payment.one_time_payment_not_possible.dialog.title")}
    </DialogHeader>
    <DialogContent>
      <Text block>
        {t("payment.one_time_payment_not_possible.dialog.message_text")}
      </Text>
    </DialogContent>

    <DialogFooter>
      <Flex gap>
        <Button
          variant="primary"
          onClick={onConfirm}
        >
          {t("Ok")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
