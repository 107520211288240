import { useRentCollectionNotificationCountQuery } from "api/queries";
import { BadgeCountWrapper } from "./InboxBadgeCount";

export const useGetRentCollectionNotificationCount = () => {
  const { rentCollectionNotificationCount, user } =
    useRentCollectionNotificationCountQuery();
  const key = rentCollectionNotificationCount
    ? Object.keys(rentCollectionNotificationCount).find(
        (key) => Number(key) === user?.id,
      )
    : null;
  const localStorageValue = key ? rentCollectionNotificationCount[key] : 0;

  return {
    localStorageValue,
  };
};

export const RentCollectionNotificationBadge = () => {
  const { localStorageValue } = useGetRentCollectionNotificationCount();

  if (!localStorageValue || localStorageValue === 0) {
    return null;
  }

  return <BadgeCountWrapper>{localStorageValue}</BadgeCountWrapper>;
};
