import * as React from "react";
import { styled, responsiveValue, responsive } from "@boligportal/juice";
import { keyframes } from "@emotion/react";
import { MODAL_OPEN } from "components/modal";
import { modalActions } from "components/modal_controller";

// Dialog
const StyledDialogWrapper = styled.div`
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const StyledDialogContainer = styled.div`
  max-width: 500px;
  position: absolute;
  margin: auto;
  top: 10vh;
  left: 0;
  bottom: 0;
  right: 0;
`;

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0,50px,0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

const StyledDialogWindow = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.color.bg.base};
  border-radius: ${(props) => props.theme.borderRadius.md}
    ${(props) => props.theme.borderRadius.md} 0 0;
  /*
  The solid white box shadow is there to cover the small
  see-through area below the dialog during the entry animation
  */
  box-shadow:
    0 10px 0 ${(props) => props.theme.color.bg.base},
    ${(props) => props.theme.shadow.lg};
  /* Temporarily disable animation for testing purpose */
  /* animation: ${appear} 0.4s cubic-bezier(0.42, 0.265, 0.12, 1.265); */
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    min-height: auto;
    border-radius: ${(props) => props.theme.borderRadius.md};
    box-shadow: ${(props) =>
      props.theme.shadow.lg}; /* Remove white shadow on desktop */
  }
`;

// Sidebar
const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(50px,0,0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

const StyledDialogSidebarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
`;

const StyledDialogSidebarWindow = styled.div<{
  width?: responsiveValue<string>;
  height?: responsiveValue<string>;
}>`
  width: 292px;
  ${(props) =>
    responsive(
      props.width,
      (value) => `
        width: ${value};
      `,
    )};
  ${(props) =>
    responsive(
      props.height,
      (value) => `
        height: ${value};
      `,
    )};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.bg.base};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: ${(props) => props.theme.shadow.lg};
  animation: ${slideIn} 0.4s cubic-bezier(0.42, 0.265, 0.12, 1.265);
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledBackdrop = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  body.${MODAL_OPEN} & {
    display: block;
    animation: ${fade} 0.2s ease-in-out;
  }
`;

// Header/Body/footer
export const DialogHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${(props) => props.theme.unit(2)};
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  border-top-left-radius: ${(props) => props.theme.borderRadius.md};
  border-top-right-radius: ${(props) => props.theme.borderRadius.md};
`;

export const DialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => props.theme.unit(2)};
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.md};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.md};
`;

export const DialogBody = styled.div`
  position: relative;
  padding: ${(props) => props.theme.unit(2)};
`;

// Components
export const Dialog = ({ children }: { children: React.ReactNode }) => (
  <StyledDialogWrapper
    tabIndex={-1}
    role="dialog"
  >
    <StyledDialogContainer>
      <StyledDialogWindow role="document">{children}</StyledDialogWindow>
    </StyledDialogContainer>
  </StyledDialogWrapper>
);

type DialogSidebarProps = {
  width?: responsiveValue<string>;
  height?: responsiveValue<string>;
  onClose?: () => void;
  children: React.ReactNode;
};

export const DialogSidebar = ({
  children,
  width,
  height,
  onClose,
}: DialogSidebarProps) => (
  <StyledDialogWrapper
    tabIndex={-1}
    role="dialog"
    {...(onClose && {
      onClick: onClose,
    })}
  >
    <StyledDialogSidebarContainer role="document">
      <StyledDialogSidebarWindow
        width={width}
        height={height}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </StyledDialogSidebarWindow>
    </StyledDialogSidebarContainer>
  </StyledDialogWrapper>
);

export const Backdrop = () => {
  const modal = React.useContext(modalActions);
  return <StyledBackdrop onClick={modal.closeModal} />;
};
