import * as React from "react";
import { SuggestionList } from "@boligportal/juice";
import { t } from "lib/i18n";
import {
  AddressSearchCandidate,
  AddressSearchItemRenderer,
} from "./AddressSearchItemRenderer";
import { useSearchAutocomplete } from "./useSearchAutocomplete";

type Props = {
  onAddress: (item: AddressSearchCandidate | null) => void;
  onSearchValueChange: () => void;
};

export const AddressSearch = ({ onAddress, onSearchValueChange }: Props) => {
  const { searchValue, updateSearchValue, suggestions, selectAddress } =
    useSearchAutocomplete({
      onAddressChosen: onAddress,
    });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchValueChange();
    updateSearchValue(event);
  };

  return (
    <SuggestionList
      items={suggestions}
      itemRenderer={AddressSearchItemRenderer}
      onSelect={selectAddress}
      testId="createRentableFillAddress"
      inputProps={{
        type: "search",
        autoComplete: "off",
        onChange,
        value: searchValue,
        placeholder: t("rentable_list.search.placeholder"),
      }}
    />
  );
};
