import { Text } from "@boligportal/juice";
import { NewBuild } from "apps/newbuilds/types";
import { TotalNewBuildsItem } from "./TotalNewBuildsItem";

interface AllLandslordNewBuildsProps {
  items: NewBuild[];
  isError?: boolean;
}
export const AllLandlordsNewBuilds = ({
  items,
  isError,
}: AllLandslordNewBuildsProps) => {
  const numberOfNewBuilds = items.length;

  if (isError) {
    return (
      <Text>
        <em>Could not load newbuilds</em>
      </Text>
    );
  }

  if (numberOfNewBuilds === 0) {
    return (
      <Text>
        <em>This user does not have any newbuilds</em>
      </Text>
    );
  }
  return (
    <>
      {items.map((item) => (
        <TotalNewBuildsItem
          key={`total-newbuilds-item-${item.id}`}
          item={item}
        />
      ))}
    </>
  );
};
