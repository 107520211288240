import {
  styled,
  Tooltip,
  IconLink,
  IconTerminalOutline,
  IconPeopleCircleOutline,
} from "@boligportal/juice";
import { CompanyListItem } from "lib/customerServiceApi";
import DanishFlag from "media/svg/Dansk";
import { BlackListIndicator } from "../../components/blacklist_indicator/BlackListIndicator";

const DanishFlagIcon = styled(DanishFlag)`
  width: 23px;
  height: 23px;
  margin-left: ${(props) => props.theme.unit(1)};
`;

const GreyIconLink = styled(IconLink)`
  color: ${(props) => props.theme.colorPalette.gray["200"]};
  margin-left: ${(props) => props.theme.unit(1)};
  transform: rotateZ(-45deg);
`;

const GreyIconSocialHousing = styled(IconPeopleCircleOutline)`
  color: ${(props) => props.theme.colorPalette.gray["200"]};
  margin-left: ${(props) => props.theme.unit(1)};
`;

const GreyIconTerminalOutline = styled(IconTerminalOutline)`
  color: ${(props) => props.theme.colorPalette.gray["200"]};
  margin-left: ${(props) => props.theme.unit(1)};
`;

const Indicators = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Indicator = ({
  tooltip,
  icon,
}: {
  tooltip: string;
  icon: React.ReactNode;
}) => (
  <Tooltip text={tooltip}>
    <div>{icon}</div>
  </Tooltip>
);

export const indicatorsCellRenderer = (item: CompanyListItem) => (
  <Indicators>
    <BlackListIndicator
      showTooltip
      reasons={[item.ban_reason]}
    />
    {item.company_page_enabled && (
      <Indicator
        tooltip="Company Page enabled"
        icon={<GreyIconLink size="medium" />}
      />
    )}
    {item.iframe_enabled && (
      <Indicator
        tooltip="Iframe enabled"
        icon={<GreyIconTerminalOutline size="medium" />}
      />
    )}
    {item.danske_udlejere && (
      <Indicator
        tooltip="Danske udlejere"
        icon={<DanishFlagIcon />}
      />
    )}
    {item.social_housing && (
      <Indicator
        tooltip="Almen bolig"
        icon={<GreyIconSocialHousing size="medium" />}
      />
    )}
  </Indicators>
);
