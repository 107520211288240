import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Divider,
  Flex,
  Notice,
  TextField,
  Text,
  Box,
} from "@boligportal/juice";
import { SigningStatus } from "apps/contracts/components/common/interfaces";
import { t } from "lib/i18n";

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  status: SigningStatus;
  errorMessage?: string;
};

export const ConfirmDialog = ({ onClose, onSubmit }: Props) => {
  const confirmationText = t("content_deletion.confirm_deletion");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ confirmDelete: string | null }>({
    mode: "onSubmit",
  });

  return (
    <Dialog
      open
      size="large"
      onClose={onClose}
    >
      <DialogHeader>
        {t("contracts.delete_contract_confirm_dialog.title")}
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Flex column>
            <Notice type="danger">
              <Box mb={-2}>
                <Text
                  size="small"
                  markdown
                  color={"danger"}
                >
                  {t("common.content_deletion.confirm_dialog.notice_markdown")}
                </Text>
              </Box>
            </Notice>
          </Flex>
          <Divider />
          <TextField
            {...register("confirmDelete", {
              validate: (value: string) =>
                value === confirmationText ||
                t("common.content_deletion.confirm_dialog.input_error"),
            })}
            required
            errorText={errors.confirmDelete?.message}
            label={t("common.content_deletion.confirm_dialog.input_label", {
              confirmationText,
            })}
            type="text"
          />
        </DialogContent>
      </form>
      <DialogFooter>
        <Flex gap>
          <Button
            variant="subtle"
            onClick={onClose}
          >
            {t("contracts.delete_contract_dialog.cancel_button")}
          </Button>
          <Button
            variant="danger"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {t("contracts.delete_contract_confirm_dialog.confirm_button")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
