import { Plan } from "components/interfaces/plan";
import { DURATION_OF_4_WEEK_PLAN, isTrial } from "components/plan_utils";

export const findPlanIdOfFirstNonTrialPlan = (plans: Plan[]) => {
  const preselectedPlanId = plans.find(
    (plan) => !isTrial(plan) && plan.duration_days === DURATION_OF_4_WEEK_PLAN,
  )?.plan_id;
  if (!preselectedPlanId) {
    return null;
  }
  return preselectedPlanId;
};
