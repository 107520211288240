import { NavLink, useRouteMatch } from "react-router-dom";
import cx from "classnames";

export const Tab = ({
  children,
  to,
  onClick,
}: {
  children: React.ReactNode;
  to: string;
  onClick?: () => void;
}) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  const normalTabStyles = "bg-opacity-5 text-branded hover:text-branded";
  const activeTabStyles = "text-white hover:text-white hover:no-underline";

  return (
    <NavLink
      to={to}
      className={cx(
        "bg-brand-500 flex items-center gap-2 whitespace-nowrap rounded-sm px-4 py-2",
        match ? activeTabStyles : normalTabStyles,
      )}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};
