import { Text } from "@boligportal/juice";
import { UserForCustomerService } from "../user_detail_view/user_detail_view";
import { SubscriptionFeedItem } from "./components/subscription_feed_item";
import { SubscriptionPaymentsItemInterface } from "./interfaces";

type Props = {
  items: SubscriptionPaymentsItemInterface[];
  refundCandidate: UserForCustomerService;
  onSubscriptionUpdated: () => void;
};

const SubscriptionsFeed = ({
  items,
  onSubscriptionUpdated,
  refundCandidate,
}: Props) => (
  <>
    {items.length === 0 && (
      <Text>
        <em>No payments have been made yet</em>
      </Text>
    )}
    {items.map((item) => (
      <SubscriptionFeedItem
        key={item.subscription.id}
        refundCandidate={refundCandidate}
        item={item}
        subscriptionUpdated={onSubscriptionUpdated}
      />
    ))}
  </>
);
export { SubscriptionsFeed };
