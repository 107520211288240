import { t } from "./i18n";

export type Translatable<T extends PropertyKey> = {
  [code in T]: string;
};

export const commonTableTrans = () => {
  const tableFieldNameUser = t("common.table.field_name.user");
  const tableFieldNameDate = t("common.table.field_name.date");
  const tableFieldNameProduct = t("common.table.field_name.product");
  const tableFieldNamePaymentType = t("common.table.field_name.payment_type");
  const tableFieldNameListingID = t("common.table.field_name.listing_id");
  const tableFieldNameInternalID = t("common.table.field_name.internal_id");
  const tableFieldNameAddress = t("common.table.field_name.address");
  const tableFieldNamePrice = t("common.table.field_name.price");
  const tableFieldNameMonth = t("common.table.field_name.month");
  const tableFieldNameDownloadFiles = t(
    "common.table.field_name.download_files",
  );
  const tableFieldNameAmount = t("common.table.field_name.amount");

  const tableFieldNameStatus = t("common.table.field_name.status");
  const tableFieldNameMoveInDate = t("common.table.field_name.move_in_date");
  return {
    tableFieldNameUser,
    tableFieldNameDate,
    tableFieldNameProduct,
    tableFieldNamePaymentType,
    tableFieldNameListingID,
    tableFieldNameInternalID,
    tableFieldNameAddress,
    tableFieldNamePrice,
    tableFieldNameMonth,
    tableFieldNameDownloadFiles,
    tableFieldNameAmount,
    tableFieldNameStatus,
    tableFieldNameMoveInDate,
  };
};

export const commonProductTrans = () => {
  const promote_ad = t("common.product.promote_ad");
  const top_ad = t("common.product.top_ad");
  const open_ad = t("common.product.open_ad");
  const exposure_ad = t("common.product.exposure_ad");
  const exposure_plus_ad = t("upsell_products.exposure_plus_ad.name");
  const rental_data_product = t("products.rental_data.name");
  const saas_level_1 = t("common.product.saas_level_1");
  const company_page_product = t("common.product.company_page_product");
  return {
    promote_ad,
    top_ad,
    open_ad,
    exposure_ad,
    exposure_plus_ad,
    saas_level_1,
    rental_data_product,
    company_page_product,
  };
};

export const commonAccountTrans = () => {
  const deposit = t("common.account.deposit");
  const transfer = t("common.account.transfer");
  const adjustment = t("common.account.adjustment");
  return {
    deposit,
    transfer,
    adjustment,
  };
};

export const commonFormTrans = () => {
  const nameLabel = t("common.form_fields.name.label");
  const phoneNumberLabel = t("common.form_fields.phone_number.label");
  const streetNameLabel = t("common.form_fields.street_name.label");
  const houseNumberLabel = t("common.form_fields.house_number.label");
  const floorLabel = t("common.form_fields.floor.label");
  const doorPlacementLabel = t("common.form_fields.door_placement.label");
  const doorLabel = t("common.form_fields.door.label");
  const postalCodeLabel = t("common.form_fields.postal_code.label");
  const postalTownLabel = t("common.form_fields.postal_town.label");

  const maxPriceAppend = t("common.form_fields.max_price.append");
  const minSizeAppend = t("common.form_fields.min_size.append");

  const selectionRequired = t("required_field.selection");
  const numberRequired = t("required_field.numeric");
  const dateRequiredTrans = t("required_field.date");
  const inputfieldRequired = t("required_field.input");
  const phoneNumberRequired = t("required_field.phone");

  const rangeRequired = (min: number, max: number) =>
    t("required_field.range", {
      min,
      max,
    });

  return {
    nameLabel,
    phoneNumberLabel,
    streetNameLabel,
    houseNumberLabel,
    floorLabel,
    doorPlacementLabel,
    doorLabel,
    postalCodeLabel,
    postalTownLabel,
    maxPriceAppend,
    minSizeAppend,
    //
    selectionRequired,
    numberRequired,
    rangeRequired,
    dateRequiredTrans,
    inputfieldRequired,
    phoneNumberRequired,
  };
};

export const commonButtonTrans = () => {
  const loadMoreButton = t("common.buttons.load_more");
  const searchButton = t("common.buttons.search");
  const makePrimaryButton = t("common.buttons.make_primary");
  const makeFloorPlanButton = t("common.buttons.make_floor_plan");
  const removeFloorPlanButton = t("common.buttons.remove_floor_plan");
  const moveBackwardButton = t("common.buttons.move_backward");
  const moveForwardButton = t("common.buttons.move_forward");
  const createNewRentableButton = t("common.buttons.create_new_rentable");
  const createNewContractButton = t("common.buttons.create_new_contract");
  const deleteContractButton = t("common.buttons.delete_contract");
  const showDetailsButton = t("common.buttons.show_details");
  const hideDetailsButton = t("common.buttons.hide_details");
  const createRentableListingButton = t(
    "common.buttons.create_rentable_listing",
  );

  return {
    loadMoreButton,
    searchButton,
    makePrimaryButton,
    makeFloorPlanButton,
    removeFloorPlanButton,
    moveBackwardButton,
    moveForwardButton,
    createNewRentableButton,
    deleteContractButton,
    showDetailsButton,
    hideDetailsButton,
    createNewContractButton,
    createRentableListingButton,
  };
};

export const commonConjunctionTrans = () => {
  const and = t("common.conjunctions.and");
  return {
    and,
  };
};

export const commonRowActionsTrans = () => {
  const chooseRentableRowActionLabel = t("common.row_actions.choose_rentable");
  const copyRowActionLabel = t("common.row_actions.copy");
  const deleteRowActionLabel = t("common.row_actions.delete");
  const gotoOverviewRowActionLabel = t("common.row_actions.goto_overview");
  const fillContractRowActionLabel = t("common.row_actions.fill_contract");
  const createMoveInReportRowActionLabel = t(
    "common.row_actions.create_move_in_report",
  );
  const createMoveOutReportRowActionLabel = t(
    "common.row_actions.create_move_out_report",
  );
  return {
    chooseRentableRowActionLabel,
    copyRowActionLabel,
    deleteRowActionLabel,
    gotoOverviewRowActionLabel,
    fillContractRowActionLabel,
    createMoveInReportRowActionLabel,
    createMoveOutReportRowActionLabel,
  };
};

export const commonPeopleTrans = () => {
  const tenantTrans = t("common.people.tenant");
  const tenantsTrans = t("common.people.tenants");
  const landlordTrans = t("common.people.landlord");
  return {
    tenantTrans,
    tenantsTrans,
    landlordTrans,
  };
};

enum MenuItems {
  ECONOMY = "ECONOMY",
  FIND_TENANT = "FIND_TENANT",
  INBOX = "INBOX",
  LOG_OUT = "LOG_OUT",
  MOVING_REPORTS = "MOVING_REPORTS",
  MY_FAVOURITE_ADS = "MY_FAVOURITE_ADS",
  MY_AGENTS = "MY_AGENTS",
  MY_LISTINGS = "MY_LISTINGS",
  MY_RENTABLES = "MY_RENTABLES",
  PROFILE = "PROFILE",
  LANDLORD_PROFILE = "LANDLORD_PROFILE",
  RENTAL_CONTRACTS = "RENTAL_CONTRACTS",
  RENT = "RENT",
  RENTAL_DATA = "RENTAL_DATA",
  SETTINGS = "SETTINGS",
  SIGN_IN_AS_COLLEAGUE = "SIGN_IN_AS_COLLEAGUE",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  LANDLORD_SUBSCRIPTIONS = "LANDLORD_SUBSCRIPTIONS",
  SUPPORT = "SUPPORT",
  TENANCY = "TENANCY",
  LIST_YOUR_PROPERTY = "LIST_YOUR_PROPERTY",
  HOUSING_ADMINISTRATION = "HOUSING_ADMINISTRATION",
  CREATE_USER = "CREATE_USER",
  LOGIN = "LOGIN",
  CREATE_LISTING = "CREATE_LISTING",
  CREATE_LISTING_NEW_MENU = "CREATE_LISTING_NEW_MENU",
  CREATE_LISTING_ADMIN_MENU = "CREATE_LISTING_ADMIN_MENU",
  CREATE_CONTRACT = "CREATE_CONTRACT",
  CREATE_MOVING_REPORT = "CREATE_MOVING_REPORT",
  CREATE_RENT = "CREATE_RENT",
  CREATE_TENANCY = "CREATE_TENANCY",
  CREATE_RENTABLE = "CREATE_RENTABLE",
  COMMERCIAL_CONTRACT = "COMMERCIAL_CONTRACT",
  COMMERCIAL_MOVING_REPORT = "COMMERCIAL_MOVING_REPORT",
  COMMERCIAL_RENTING_OUT = "COMMERCIAL_RENTING_OUT",
  BENEFITS = "BENEFITS",
  NEWBUILDS = "NEWBUILDS",
}

export const menuTranslations = (): Translatable<MenuItems> => ({
  [MenuItems.CREATE_CONTRACT]: t("menu.create_contract"),
  [MenuItems.CREATE_LISTING]: t("menu.create_listing"),
  [MenuItems.CREATE_LISTING_ADMIN_MENU]: t("menu.create_listing_admin_menu"),
  [MenuItems.CREATE_LISTING_NEW_MENU]: t("menu.create_listing_new_menu"),
  [MenuItems.CREATE_MOVING_REPORT]: t("menu.create_moving_report"),
  [MenuItems.CREATE_RENT]: t("menu.create_rent"),
  [MenuItems.CREATE_RENTABLE]: t("menu.create_rentable"),
  [MenuItems.CREATE_TENANCY]: t("menu.create_tenancy"),
  [MenuItems.CREATE_USER]: t("menu.create_user"),
  [MenuItems.ECONOMY]: t("menu.economy"),
  [MenuItems.FIND_TENANT]: t("menu.find_tenant"),
  [MenuItems.NEWBUILDS]: t("menu.newbuilds"),
  [MenuItems.HOUSING_ADMINISTRATION]: t("menu.housing_administration"),
  [MenuItems.INBOX]: t("menu.inbox"),
  [MenuItems.LIST_YOUR_PROPERTY]: t("menu.list_your_property"),
  [MenuItems.LOG_OUT]: t("menu.log_out"),
  [MenuItems.LOGIN]: t("menu.login"),
  [MenuItems.MOVING_REPORTS]: t("menu.moving_reports"),
  [MenuItems.MY_AGENTS]: t("menu.my_agents"),
  [MenuItems.MY_FAVOURITE_ADS]: t("menu.my_favourite_ads"),
  [MenuItems.MY_LISTINGS]: t("menu.my_listings"),
  [MenuItems.MY_RENTABLES]: t("menu.my_rentables"),
  [MenuItems.PROFILE]: t("menu.profile"),
  [MenuItems.LANDLORD_PROFILE]: t("menu.landlord_profile"),
  [MenuItems.RENT]: t("menu.rent"),
  [MenuItems.RENTAL_CONTRACTS]: t("menu.rental_contracts"),
  [MenuItems.RENTAL_DATA]: t("menu.rental_data"),
  [MenuItems.SETTINGS]: t("menu.settings"),
  [MenuItems.SIGN_IN_AS_COLLEAGUE]: t("menu.sign_in_as_colleague"),
  [MenuItems.SUBSCRIPTIONS]: t("menu.subscriptions"),
  [MenuItems.LANDLORD_SUBSCRIPTIONS]: t("menu.landlord_subscriptions"),
  [MenuItems.SUPPORT]: t("menu.support"),
  [MenuItems.TENANCY]: t("menu.tenancy"),
  [MenuItems.COMMERCIAL_CONTRACT]: t("menu.commercial_contract"),
  [MenuItems.COMMERCIAL_MOVING_REPORT]: t("menu.commercial_moving_report"),
  [MenuItems.COMMERCIAL_RENTING_OUT]: t("menu.commercial_renting_out"),
  [MenuItems.BENEFITS]: t("menu.benefits"),
});
