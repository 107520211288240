import * as React from "react";

const SvgEnglish = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={139}
    height={96}
    viewBox="0 0 139 96"
    {...props}
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    >
      <path
        fill="#285680"
        d="M138.848 4.79v86.18a4.373 4.373 0 01-.312 1.676 4.835 4.835 0 01-3.95 3.088c-.174.02-.35.029-.527.023H4.79a3.643 3.643 0 01-.527-.023 4.837 4.837 0 01-3.95-3.088A4.373 4.373 0 010 90.97V4.79a4.373 4.373 0 01.31-1.678A4.837 4.837 0 014.261.025c.175-.02.351-.028.527-.023H134.06c.176-.005.353.003.527.023a4.835 4.835 0 013.95 3.088c.213.533.319 1.103.312 1.676z"
      />
      <path
        fill="#FFF"
        d="M138.848 4.79a4.373 4.373 0 00-.312-1.677 4.835 4.835 0 00-3.95-3.088 3.636 3.636 0 00-.527-.023h-7.851l-42.42 29.253V.002H55.06v29.253L12.64.002H4.788a3.643 3.643 0 00-.526.023 4.837 4.837 0 00-3.95 3.088A4.373 4.373 0 000 4.79v3.926l35.957 24.8H0v28.729h35.932L.001 87.044v3.926a4.326 4.326 0 00.312 1.676 4.834 4.834 0 003.949 3.088c.174.02.35.029.527.023h7.852l42.42-29.253v29.253h28.726V66.504l42.42 29.253h7.852a3.57 3.57 0 00.527-.023 4.837 4.837 0 003.95-3.088 4.373 4.373 0 00.312-1.676v-3.926l-35.957-24.8h35.957V33.516h-35.934l35.934-24.801V4.789z"
      />
      <g fill="#C03A2B">
        <path d="M138.536 92.646a4.835 4.835 0 01-3.95 3.088l-48.572-33.49h8.474l44.048 30.402zm-85.702-59.13h-8.475L.311 3.113A4.837 4.837 0 014.261.025l48.573 33.49zm85.702-30.403L94.441 33.516h-8.404L134.586.026a4.835 4.835 0 013.95 3.087zM52.81 62.244L4.262 95.734a4.837 4.837 0 01-3.95-3.088l44.096-30.402h8.402zM.001 43.092h138.846v9.575H.001z" />
        <path d="M64.636.002h9.576v95.755h-9.576z" />
      </g>
    </g>
  </svg>
);
export default SvgEnglish;
