import * as React from "react";
import { API } from "lib/api";
import { AdStates } from "lib/enums";
import { t } from "lib/i18n";
import { MarkAsRentedOutSlideOver } from "./MarkAsRentedOutSlideOver";
import { MarkAsReserved } from "./MarkAsReserved";
import { ContractNudgeDialog } from "./contract_nudge/ContractNudgeDialog";
import {
  NudgePlacement,
  useContractNudgeDialog,
} from "./contract_nudge/useContractNudgeDialog";

type MarkAsRentedOutProps = Pick<
  React.ComponentProps<typeof MarkAsRentedOutSlideOver>,
  "onMarkedRentedOut" | "preselectTenantId" | "slideOverTitle"
>;

type ActiveModal = "MARK_AS_RESERVED" | "MARK_AS_RENTED_OUT";

type AdInfo = {
  adId: number;
  rentableId: number;
};

export type MarkAsReserved = (
  adInfo: AdInfo,
  callback: () => void,
  placement: NudgePlacement,
) => void;
type MarkAsRentedOut = (
  adInfo: AdInfo,
  markAsRentedOutProps: MarkAsRentedOutProps,
  placement: NudgePlacement,
) => void;

const MarkAsReservedOrRentedOutContext = React.createContext<{
  markAsReserved: MarkAsReserved;
  markAsRentedOut: MarkAsRentedOut;
}>({
  markAsReserved: Promise.resolve,
  markAsRentedOut: Promise.resolve,
});

export const MarkAsReservedOrRentedOutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [adInfo, setAdInfo] = React.useState<AdInfo | null>(null);
  const [markAsRentedOutProps, setMarkAsRentedOutProps] =
    React.useState<MarkAsRentedOutProps | null>();
  const [activeModal, setActiveModal] = React.useState<ActiveModal | null>(
    null,
  );
  const [markAsReservedCallback, setMarkAsReservedCallback] = React.useState<{
    func: () => void;
  } | null>(null);
  const { showDialog, props: contractNudgeDialogProps } =
    useContractNudgeDialog(adInfo?.rentableId);

  const closeModal = () => setActiveModal(null);

  const markAsRentedOut: MarkAsRentedOut = (
    adInfo,
    markAsRentedOutProps,
    placement,
  ) => {
    const onMarkedRentedOut = () => {
      closeModal();
      showDialog(
        {
          title: t("rentable_details.nudge_contract.dialog.rented_out.title"),
          subtitle: t(
            "rentable_details.nudge_contract.dialog.rented_out.subtitle",
          ),
        },
        {
          placement,
          name: "RENTED_OUT",
        },
      );
      markAsRentedOutProps.onMarkedRentedOut();
    };

    setActiveModal("MARK_AS_RENTED_OUT");
    setAdInfo(adInfo);
    setMarkAsRentedOutProps({
      ...markAsRentedOutProps,
      onMarkedRentedOut,
    });
  };

  const markAsReserved: MarkAsReserved = (adInfo, callback, placement) => {
    const onMarkedAsReserved = async () => {
      await API.reserveAd(adInfo.adId);

      closeModal();
      showDialog(
        {
          title: t("rentable_details.nudge_contract.dialog.reserved.title"),
          subtitle: t(
            "rentable_details.nudge_contract.dialog.reserved.subtitle",
          ),
        },
        {
          placement,
          name: "RESERVED",
        },
      );

      callback();
    };
    setMarkAsReservedCallback({
      func: onMarkedAsReserved,
    });
    setActiveModal("MARK_AS_RESERVED");
    setAdInfo(adInfo);
  };

  const onMarkedAsReserved = async () => {
    markAsReservedCallback?.func();
  };

  const onCloseContractNudgeDialog = React.useCallback(() => {
    setAdInfo(null);
    setMarkAsRentedOutProps(null);
    contractNudgeDialogProps.onClose();
  }, [setAdInfo, setMarkAsRentedOutProps, contractNudgeDialogProps]);

  return (
    <>
      <MarkAsReservedOrRentedOutContext.Provider
        value={{
          markAsReserved,
          markAsRentedOut,
        }}
      >
        {children}
      </MarkAsReservedOrRentedOutContext.Provider>

      {markAsRentedOutProps &&
        adInfo &&
        activeModal === "MARK_AS_RENTED_OUT" && (
          <MarkAsRentedOutSlideOver
            open
            {...markAsRentedOutProps}
            {...adInfo}
            onClose={closeModal}
          />
        )}

      {
        <ContractNudgeDialog
          {...contractNudgeDialogProps}
          onClose={onCloseContractNudgeDialog}
        />
      }

      {adInfo &&
        markAsReservedCallback &&
        activeModal === "MARK_AS_RESERVED" && (
          <MarkAsReserved
            open
            {...adInfo}
            onConfirm={onMarkedAsReserved}
            onClose={closeModal}
          />
        )}
    </>
  );
};

export const useMarkAsReservedOrRentedOut = () =>
  React.useContext(MarkAsReservedOrRentedOutContext);

export const canRentOutAd = (state?: AdStates) =>
  state === AdStates.ACTIVE_APPROVED || state === AdStates.RESERVED_APPROVED;
