import { SigningStatus } from "apps/contracts/components/common/interfaces";
import { ContractListItem } from "apps/contracts/components/common/store/contracts";
import { SigningStatusTranslations } from "apps/contracts/components/common/translations";
import { RentalContract } from "apps/contracts/components/types";
import { localizedMonthDayAndYear } from "lib/date";
import { t, tn } from "lib/i18n";
import { commonConjunctionTrans } from "lib/translations";

export const createStateLabel = (item: ContractListItem): string => {
  if (item.state === SigningStatus.EXPIRED) {
    return SigningStatusTranslations()[SigningStatus.AWAITING];
  }
  return SigningStatusTranslations()[item.state];
};

export const createStateSubLabel = (item: ContractListItem): string => {
  const { state, all_parties_signed_at } = item;

  if (state === SigningStatus.EXPIRED) {
    return t("contracts_list.status_column.sub_label.cancelled_or_expired");
  }

  if (state === SigningStatus.AWAITING) {
    const numTenantMissingSigning = item.tenants.filter(
      (x) => x.signed_at === null,
    ).length;
    const isLandlordMissingSigning = item.landlord_signed_at === null;

    if (numTenantMissingSigning > 0 && isLandlordMissingSigning) {
      return tn(
        "contracts_list.status_column.sub_label.tenants_and_landlord_missing",
        numTenantMissingSigning,
      );
    } else if (numTenantMissingSigning > 0 && !isLandlordMissingSigning) {
      return tn(
        "contracts_list.status_column.sub_label.tenants_missing",
        numTenantMissingSigning,
      );
    } else if (numTenantMissingSigning === 0 && isLandlordMissingSigning) {
      return t("contracts_list.status_column.sub_label.landlord_missing");
    }

    return "";
  }

  if (state === SigningStatus.COMPLETED) {
    return all_parties_signed_at
      ? localizedMonthDayAndYear(new Date(all_parties_signed_at))
      : "";
  }

  return t("contracts_list.status_column.sub_label.draft");
};

export const createTenantsDisplayLabel = (item: ContractListItem): string => {
  const { tenants } = item;

  const names = tenants.map((t) => createTenantName(t));
  return createReadableTenantNames(names);
};

const createTenantName = (tenant: RentalContract["tenants"][number]): string =>
  `${tenant.first_name ?? ""} ${tenant.last_name ?? ""}`;

export const createAddressLink = (item: ContractListItem): string => {
  const url = `/${item.id}`;

  if (item.state !== SigningStatus.DRAFT) {
    return `${url}/status`;
  }

  return url;
};

export const createReadableTenantNames = (names: Array<string>): string => {
  const { and } = commonConjunctionTrans();

  if (names.length === 1) {
    return names[0];
  }

  if (names.length === 2) {
    return `${names[0]} ${and} ${names[1]}`;
  }

  if (names.length > 2) {
    const allButLast = names.slice(0, names.length - 1);
    const last = names[names.length - 1];
    return `${allButLast.join(", ")} ${and} ${last}`;
  }

  return "";
};
