export interface Profile {
  first_name: string | null;
  last_name: string | null;
  company_name: string | null;
  birthday: string | null;
  pets: boolean | null;
  smoking: boolean | null;
  kids: boolean | null;
  expat: boolean | null;
  share_apartment: boolean | null;
  occupation: OCCUPATION | null;
  user_gender: USER_GENDER | null;
  user_type: USER_TYPE | null;
  description: string | null;
  public_url: string | null;
  profile_image: string | null;
  address: string | null;
  phone_number: string | null;
  postal_code: string | null;
  postal_town: string | null;
  bank_id_verified: boolean;
}

export interface PublicProfile {
  public_profile_id: string;
  first_name: string | null;
  last_name: string | null;
  company_name: string | null;
  age: string | null;
  pets: boolean | null;
  smoking: boolean | null;
  kids: boolean | null;
  expat: boolean | null;
  share_apartment: boolean | null;
  occupation: OCCUPATION | null;
  user_gender: USER_GENDER | null;
  user_type: USER_TYPE | null;
  description: string | null;
  profile_url: string;
  profile_image: string | null;
  last_activity_date: string | null;
}

export enum USER_TYPE {
  INDIVIDUAL = 0,
  COMPANY = 1,
  COUPLE = 2,
  FAMILY = 3,
  GROUP = 4,
}

export enum USER_GENDER {
  MALE = 0,
  FEMALE = 1,
  OTHER = 2,
}

export enum OCCUPATION {
  STUDENT = 0,
  EMPLOYED = 1,
  UNEMPLOYED = 2,
  RETIRED = 3,
}

export const occupationLabel = {
  [OCCUPATION.STUDENT]: "profile.occupation.student",
  [OCCUPATION.EMPLOYED]: "profile.occupation.employed",
  [OCCUPATION.UNEMPLOYED]: "profile.occupation.unemployed",
  [OCCUPATION.RETIRED]: "profile.occupation.retired",
};

export const genderLabel = {
  [USER_GENDER.MALE]: "profile.user_gender.male",
  [USER_GENDER.FEMALE]: "profile.user_gender_female",
  [USER_GENDER.OTHER]: "profile.user_gender_other",
};

export const userTypeLabel = {
  [USER_TYPE.INDIVIDUAL]: "profile.user_type.individual",
  [USER_TYPE.COMPANY]: "profile.user_type.company",
  [USER_TYPE.GROUP]: "profile.user_type.group",
  [USER_TYPE.COUPLE]: "profile.user_type.couple",
  [USER_TYPE.FAMILY]: "profile.user_type.family",
};
