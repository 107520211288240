import * as React from "react";
import {
  Box,
  Flex,
  Notice,
  Button,
  IconArrowForward,
} from "@boligportal/juice";
import { App } from "components/app";
import { Section } from "components/design-system/atoms/section/section";
import { Ad } from "components/interfaces/ad";
import { Market } from "components/markets/market_settings";
import { AdUtils } from "lib/ad";
import { t } from "lib/i18n";
import { adDetailsFloor } from "lib/utils";
import { CellWrapper } from "../components/CellWrapper";
import { EnergyLabel } from "../components/EnergyLabel";
import { NameValueCell } from "../components/NameValueCell";

export enum EnergyLabelHashEnum {
  ENERGY_LABEL_HASH = "energyLabel",
}

export const HousingDetails = ({ ad }: { ad: Ad }) => {
  const items: Array<React.ReactNode> = [];

  items.push(
    <NameValueCell
      name={t("ad_details.property_type")}
      value={AdUtils.getTransForCategory(ad.category)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.size")}
      value={`${ad.size_m2} m²`}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.rooms")}
      value={ad.rooms}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.floor")}
      value={
        adDetailsFloor(ad.floor, ad.category) !== null
          ? adDetailsFloor(ad.floor, ad.category)
          : "-"
      }
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.furnished")}
      value={getListingFeatureLabel(ad.features?.furnished)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.shareable")}
      value={getListingFeatureLabel(ad.features?.shareable)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.pets_allowed")}
      value={getListingFeatureLabel(ad.features?.pet_friendly)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.elevator")}
      value={getListingFeatureLabel(ad.features?.elevator)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.senior_friendly")}
      value={getListingFeatureLabel(ad.features?.senior_friendly)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.student_only")}
      value={getListingFeatureLabel(ad.features?.student_only)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.balcony")}
      value={getListingFeatureLabel(ad.features?.balcony)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.parking")}
      value={getListingFeatureLabel(ad.features?.parking)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.dishwasher")}
      value={getListingFeatureLabel(ad.features?.dishwasher)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.washing_machine")}
      value={getListingFeatureLabel(ad.features?.washing_machine)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.electric_charging_station")}
      value={getListingFeatureLabel(ad.features?.electric_charging_station)}
    />,
  );

  items.push(
    <NameValueCell
      name={t("ad_details.features.dryer")}
      value={getListingFeatureLabel(ad.features?.dryer)}
    />,
  );

  if (App.settings.market === Market.BOLIGPORTAL_DK) {
    if (ad.energy_rating) {
      items.push(
        <NameValueEnergyRatingCell
          name={t("ad_details.energy_label")}
          value={ad.energy_rating}
        />,
      );
    } else {
      items.push(
        <Flex column>
          <NameValueCell
            name={t("ad_details.energy_label")}
            value="-"
          />
          {ad.is_owner && (
            <Box
              mt={1}
              mb={-2}
            >
              <Notice type="info">
                <Flex
                  column
                  m={1}
                >
                  <Box mb={1.5}>
                    {t("ad_details.missing_energy_label.notice.text")}
                  </Box>
                  <Box>
                    <Button
                      iconAfter={IconArrowForward}
                      text
                      onClick={() =>
                        (window.location.href = `/property-owner/my-rentables/${ad.rentable_id}/listing#${EnergyLabelHashEnum.ENERGY_LABEL_HASH}`)
                      }
                    >
                      {t("ad_details.missing_energy_label.notice.btn_text")}
                    </Button>
                  </Box>
                </Flex>
              </Notice>
            </Box>
          )}
        </Flex>,
      );
    }
  }

  return (
    <Section
      heading={t("ad_details.section.about_property")}
      variant="h2"
    >
      <Flex
        mt={2}
        mb={4}
        gap={4}
        wrap
      >
        {items.map((item, index) => (
          <CellWrapper
            key={`housing_details${item?.valueOf}`}
            index={index}
          >
            {item}
          </CellWrapper>
        ))}
      </Flex>
    </Section>
  );
};

const NameValueEnergyRatingCell = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => (
  <NameValueCell
    name={name}
    value={<EnergyLabel label={value} />}
  />
);

/*
In case the Listing was crawled, we can't be sure that its feature values were set.
Also when we introduce new Listing features, old Listings won't have them set.
*/
const getListingFeatureLabel = (value: boolean | null | undefined) => {
  if (value === null || value === undefined) {
    return t("listing.feature.unknown");
  }

  return value ? t("Yes") : t("No");
};
