import { Box, Text } from "@boligportal/juice";
import { formatRelative } from "date-fns";

export const NewBuildDateDisplay = ({
  label,
  date,
}: {
  label: string;
  date: string | undefined;
}) => (
  <Box>
    <Text size="small">{label}</Text>
    <Text
      pl={1}
      size="small"
    >
      {date ? `${formatRelative(new Date(date), new Date())}` : "-"}
    </Text>
  </Box>
);
