import * as React from "react";
import { Box, Container, Flex, Hidden, styled } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { ContractsLandingPageContainer } from "../ContractsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const HeroHeader = styled.h2`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
`;
const HeroHeaderMobile = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;
  text-align: center;
`;

const StyledOverflow = styled.div`
  overflow: hidden;
`;

const StyledCdnImage = styled(CdnImage)`
  margin-left: auto;
`;

const HouseSvg = () => (
  <svg
    width="492"
    height="500"
    viewBox="8 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        // eslint-disable-next-line max-len
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

interface Props {
  picture: {
    src: string;
    width: number;
    height: number;
  };
  headline: string;
  ctaButtonComponent: React.ReactNode;
}

export const CallToActionImageSection = ({
  headline,
  ctaButtonComponent,
  picture,
}: Props) => (
  <StyledOverflow>
    <PageBlock
      bg="business"
      bgGradient
      py="none"
      bgBottomLeftOverlay={
        <Box
          hidden={{
            xs: true,
            lg: false,
          }}
        >
          <HouseSvg />
        </Box>
      }
    >
      <ContractsLandingPageContainer>
        <Hidden
          on={{
            xs: true,
            lg: false,
          }}
        >
          <Flex
            height="621px"
            align="center"
          >
            <Flex
              column
              minWidth="400px"
            >
              <Box maxWidth="400px">
                <HeroHeader>{headline}</HeroHeader>
              </Box>
              <Flex
                mt={3}
                mr={12}
              >
                {ctaButtonComponent}
              </Flex>
            </Flex>
            <StyledCdnImage
              {...picture}
              resolution="3x"
            />
          </Flex>
        </Hidden>
      </ContractsLandingPageContainer>

      <Hidden
        on={{
          xs: false,
          lg: true,
        }}
      >
        <Box>
          <Box
            pt={8}
            px={2}
          >
            <HeroHeaderMobile>{headline}</HeroHeaderMobile>
          </Box>

          <Flex
            px={{
              xs: 2,
              md: 0,
            }}
            mt={3}
            justify="center"
          >
            <Container fluid>{ctaButtonComponent}</Container>
          </Flex>
          <Flex
            justify="center"
            pt={6}
            px={0}
            mx={0}
            pb={8}
          >
            <Box
              height="100%"
              width="70%"
            >
              <CdnImage
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "6px",
                }}
                src={picture.src}
              />
            </Box>
          </Flex>
        </Box>
      </Hidden>
    </PageBlock>
  </StyledOverflow>
);
