import { Button, Flex, IconArrowForward, Text } from "@boligportal/juice";

interface Props {
  onClick: () => void;
  buttonLabel: string;
}

export const ActivateLeadButton = ({ onClick, buttonLabel }: Props) => (
  <Button
    variant="subtle"
    onClick={onClick}
  >
    <Flex align="center">
      <Text
        color="success"
        pr={1}
      >
        {buttonLabel}
      </Text>
      <IconArrowForward color="success" />
    </Flex>
  </Button>
);
