import * as React from "react";
import {
  Box,
  Flex,
  Divider,
  Dropdown,
  IconEllipsisVertical,
  DropdownMenuButton,
  Button,
  Container,
  DropdownMenuSeparator,
  styled,
} from "@boligportal/juice";

interface MobileDataListRowActionItem<T> {
  /**
   * optional value if set, the row actions button label will be set to this value.
   */
  label?: React.ReactNode;

  /**
   * optional value, if set, you must use the item passed as parameter to generate a
   * label for the action.
   */
  labelFunction?: (item: T) => string;

  /**
   * Required callback Function executed when the action (Button) is clicked.
   * Passes the data item for the row as parameter.
   */
  callback?: (data: T) => void;
  /**
   * Optional function called when the action button is being rendered.
   * Use the data item to decide if the action button should be disabled or not.
   *
   * example: disableFunction: item => item.status === "PENDING"
   */
  disableFunction?: (data: T) => boolean;
}

interface MobileDataListProps<T> {
  /**
   * You should pick a field from your data type :T, in which the
   * field value is unique for each item.
   */
  keyField: keyof T;
  items: T[];
  itemLinkFunction?: (item: T) => string;
  onItemClick?: (item: T) => void;
  primary?: { rowRenderer: (data: T) => React.ReactNode };
  secondaries: {
    fieldName?: keyof T;
    fieldLabel?: string;
    fieldValueFunction?: (data: T) => string | React.ReactNode;
    fieldLabelFunction?: (data: T) => React.ReactNode;
  }[];
  rowActions?: MobileDataListRowActionItem<T>[];
  /**
   * Optional, but defaults to true
   */
  useFluidContainer?: boolean;
}

const MobileDataListRowItem = ({
  fieldLabel,
  fieldValue,
}: {
  fieldLabel: React.ReactNode;
  fieldValue: React.ReactNode;
}) => (
  <Flex
    justify="space-between"
    py={0.5}
    align="center"
  >
    <Box flexGrow>{fieldLabel}</Box>
    {fieldValue}
  </Flex>
);

const ClickableWrapper = styled.div`
  display: block;
  margin-bottom: ${(props) => props.theme.unit(2)};
`;

const LinkWrapper = styled.a`
  display: block;
  margin-bottom: ${(props) => props.theme.unit(2)};
`;

const MobileDataList = <T extends any>({
  items,
  keyField,
  primary: primaryRow,
  secondaries: secondaryRows,
  rowActions,
  useFluidContainer = true,
  onItemClick,
  itemLinkFunction,
}: MobileDataListProps<T>) => {
  const renderActionButtonLabel = (
    action: MobileDataListRowActionItem<T>,
    item: T,
  ) => {
    if (action.labelFunction) {
      return action.labelFunction(item);
    }
    if (action.label) {
      return action.label;
    }
    throw "the action must have either a label or a labelFunction";
  };

  const shouldShowSeparator = (
    actionItem: MobileDataListRowActionItem<T>,
  ): boolean => {
    const { callback, disableFunction, label, labelFunction } = actionItem;
    return (
      callback === undefined &&
      disableFunction === undefined &&
      label === undefined &&
      labelFunction === undefined
    );
  };

  const renderRowActions = (
    actions: MobileDataListRowActionItem<T>[],
    item: T,
  ) => (
    <div
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Dropdown
        opener={
          <Button
            variant="subtle"
            icon={IconEllipsisVertical}
          />
        }
        items={actions.map((action) => {
          if (shouldShowSeparator(action)) {
            return <DropdownMenuSeparator />;
          }

          const label = renderActionButtonLabel(action, item);
          return (
            <DropdownMenuButton
              key={`actionButton_${item[keyField]}_${label}`}
              disabled={
                action.disableFunction ? action.disableFunction(item) : false
              }
              onClick={() => {
                action.callback?.(item);
              }}
            >
              {label}
            </DropdownMenuButton>
          );
        })}
      />
    </div>
  );

  const WrapperComponent = itemLinkFunction ? LinkWrapper : ClickableWrapper;
  const getWrapperProps = (item: T) => ({
    ...(onItemClick && {
      onClick: () => {
        onItemClick(item);
      },
      style: {
        cursor: "pointer",
      },
    }),

    ...(itemLinkFunction && {
      href: itemLinkFunction(item),
      style: {
        textDecoration: "none",
        color: "initial",
      },
    }),
  });

  return (
    <>
      {items.map((item) => (
        <WrapperComponent
          key={`item_${item[keyField]}`}
          {...getWrapperProps(item)}
        >
          <Box
            bg="base"
            shadow="none"
            borderTop
            borderBottom
            py={2}
          >
            {primaryRow && (
              <>
                <Container fluid={useFluidContainer}>
                  <Flex
                    align="center"
                    justify="space-between"
                  >
                    <Box flexGrow>{primaryRow.rowRenderer(item)}</Box>
                    {rowActions && (
                      <Box pl={1}>{renderRowActions(rowActions, item)}</Box>
                    )}
                  </Flex>
                </Container>

                {secondaryRows.length > 0 && (
                  <Box>
                    <Divider />
                  </Box>
                )}
              </>
            )}

            {secondaryRows.map((row) => {
              const label = row.fieldLabelFunction
                ? row.fieldLabelFunction(item)
                : row.fieldLabel ?? "-";
              const value = row.fieldValueFunction
                ? row.fieldValueFunction(item)
                : row.fieldName
                  ? item[row.fieldName]
                  : "";
              return (
                <Container
                  fluid={useFluidContainer}
                  key={`${label}_rowItem_${item[keyField]}`}
                >
                  <MobileDataListRowItem
                    fieldLabel={label}
                    fieldValue={value as React.ReactNode}
                  />
                </Container>
              );
            })}
          </Box>
        </WrapperComponent>
      ))}
    </>
  );
};

export { MobileDataList };
