import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { localizedDate } from "lib/date";
import { t } from "lib/i18n";
import { MovingReport } from "../interfaces/movingReport";

type Section = {
  label: string;
  subtitle?: string | null;
  slug: string;
  completed?: boolean;
};

enum steps {
  details = "details",
  tenants = "tenants",
  rooms = "rooms",
  meters = "meters",
  keys = "keys",
  leads = "leads",
  completion = "completion",
}

export const useReportSteps = (report: MovingReport) => {
  if (!report) {
    return [];
  }

  const doneRoomsCount = report.rooms.filter((room) => room.done).length;
  const metersWithReadingCount = report.meters.filter(
    (meter) => meter.reading_value,
  ).length;
  const doneKeysCount = report.keys.keys.filter(
    (key) => key.amount !== undefined,
  ).length;

  const isOutInspection = report.type === "OUT";
  const isDanishMarket = App.settings.market === Market.BOLIGPORTAL_DK;

  const tenantsNames = () => {
    if (report.tenants.length > 0) {
      const names: string[] = [];

      report.tenants.forEach((tenant) => {
        if (tenant.first_name || tenant.last_name) {
          names.push([tenant.first_name, tenant.last_name].join(" "));
        }
      });

      return names.join(
        names.length > 2 ? ", " : ` ${t("moving_reports.names.and")} `,
      );
    }

    return "";
  };

  const represented = !report.tenants.some(
    (t) =>
      t.presence === "REPRESENTED" &&
      (!t.representative_first_name ||
        !t.representative_last_name ||
        !t.representative_email ||
        !t.representative_phone_number),
  );

  const completed = {
    [steps.details]:
      Boolean(
        report.notice_date &&
          report.inspection_date &&
          report.landlord_representative_name,
      ) &&
      (report.tenant_defect_flow_enabled
        ? Boolean(
            report.tenant_defect_flow_start_date &&
              report.tenant_defect_flow_end_date,
          )
        : true),

    [steps.tenants]:
      report.tenants.length > 0 &&
      !report.tenants.some(
        (t) =>
          !t.first_name ||
          !t.last_name ||
          !t.email ||
          !t.phone_number ||
          !t.presence,
      ) &&
      represented &&
      (isOutInspection
        ? Boolean(
            (report.main_tenant_id && report.main_tenant_account_no) ||
              (report.main_tenant_id && report.main_tenant_account_reg_no) ||
              (report.main_tenant_id && report.main_tenant_new_address) ||
              (!report.main_tenant_account_no &&
                !report.main_tenant_account_reg_no &&
                !report.main_tenant_new_address),
          )
        : true),

    [steps.rooms]:
      report.rooms.length > 0 && report.rooms.length === doneRoomsCount,

    [steps.meters]:
      report.meters.length > 0 &&
      report.meters.length === metersWithReadingCount,

    [steps.keys]:
      report.keys.keys.length > 0 &&
      report.keys.keys.every((key) => key.amount) &&
      report.keys.keys.length === doneKeysCount,

    [steps.leads]: report.tenants.some((t) => t.lead_sent),
  };

  const sections: Section[] = [
    {
      label: t("moving_reports.overview.report_details"),
      slug: steps.details,
      subtitle: report.inspection_date
        ? t("moving_reports.overview.inspection_date", {
            date: localizedDate(new Date(report.inspection_date)),
          })
        : null,
      completed: completed[steps.details],
    },
    {
      label: t("moving_reports.overview.tenant_details"),
      subtitle: tenantsNames(),
      slug: steps.tenants,
      completed: completed[steps.tenants],
    },
    {
      label: t("moving_reports.overview.rooms_review"),
      subtitle: t("moving_reports.rooms_done", {
        done: doneRoomsCount,
        rooms: report.rooms.length,
      }),
      slug: steps.rooms,
      completed: completed[steps.rooms],
    },
    {
      label: t("moving_reports.overview.meter_reading"),
      slug: steps.meters,
      completed: completed[steps.meters],
    },
    {
      label: t("moving_reports.overview.key_delivery"),
      slug: steps.keys,
      completed: completed[steps.keys],
    },
    {
      label: t("moving_reports.overview.send_finish_sign"),
      slug: steps.completion,
    },
  ];

  if (!isOutInspection && isDanishMarket) {
    sections.splice(-1, 0, {
      label: t("moving_reports.overview.leads_step"),
      subtitle: t("moving_reports.overview.leads_step_subtext"),
      slug: steps.leads,
      completed: completed[steps.leads],
    });
  }

  return sections;
};
