/* eslint-disable compat/compat */
import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardSection,
  CheckField,
  Confirm,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  dialogActions,
} from "@boligportal/juice";
import { SearchUsersDropdown } from "features/cs/search_users_feature/components/search_users_dropdown";
import { UserListItem } from "lib/customerServiceApi";
import { CompanyDetailsContext } from "./company_details_context";
import { CompanyUsersList } from "./components/company_users_list";
import { formatUser } from "./helpers";
import { Company, UserListItemViewModel } from "./interfaces";

const UserListItemView = ({
  item,
  onChange,
}: {
  item: UserListItemViewModel;
  onChange: (item: UserListItemViewModel, checked: boolean) => void;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    onChange(item, newValue);
  };

  return (
    <Flex>
      <CheckField
        label={formatUser(item)}
        checked={item.isSelected}
        onChange={handleChange}
      />
    </Flex>
  );
};

const UserChangesConfirmation = (props: {
  company: Company;
  users: UserListItemViewModel[];
}) => {
  const { users, company } = props;
  const overrides: UserListItemViewModel[] = [];
  const adds: UserListItemViewModel[] = [];
  const sameCompanies: UserListItemViewModel[] = [];
  for (const user of users) {
    if (user.company_id !== null) {
      if (user.company_id !== company.id) {
        overrides.push(user);
      } else {
        sameCompanies.push(user);
      }
    } else {
      adds.push(user);
    }
  }

  const UserChangesUserList = (props: { users: UserListItemViewModel[] }) => (
    <>
      {props.users.map((user) => (
        <Box
          my={0.5}
          ml={0.5}
        >
          <Text key={user.id}>{formatUser(user)}</Text>
        </Box>
      ))}
    </>
  );

  return (
    <>
      {overrides.length > 0 && (
        <Box mb={3}>
          <Text weight="headline">
            {"You are going to override the company for the following user(s):"}
          </Text>
          <Box mt={1}>
            <UserChangesUserList users={overrides} />
          </Box>
        </Box>
      )}
      {adds.length > 0 && (
        <Box>
          <Text weight="headline">
            {"You are adding the following user(s):"}
          </Text>
          <Box mt={1}>
            <UserChangesUserList users={adds} />
          </Box>
        </Box>
      )}
      {sameCompanies.length > 0 && (
        <Box>
          <Text weight="headline">{`The following users are already a member of company ${company.name}:`}</Text>
          <Box mt={1}>
            <UserChangesUserList users={sameCompanies} />
          </Box>
        </Box>
      )}
    </>
  );
};

const CompanyUsersPanel = () => {
  const { companyUsers, removeUserFromCompany } = React.useContext(
    CompanyDetailsContext,
  );

  const handleRemove = (userId: number) => {
    removeUserFromCompany(userId);
  };

  return (
    <>
      <Card sectioned>
        <CardSection title="Company Users">
          <Box pb={4}>
            {companyUsers.map((companyUser) => (
              <CompanyUsersList
                key={companyUser.user_id}
                companyUser={companyUser}
                onRemove={handleRemove}
              />
            ))}
          </Box>

          <Dialog
            slideOver
            opener={<Button variant="default">Add users to company</Button>}
          >
            <AddUsersToCompanyDialog />
          </Dialog>
        </CardSection>
      </Card>
    </>
  );
};

export { CompanyUsersPanel };

// ************************************************************************************************
// Content shown in the dialog
// ************************************************************************************************

const AddUsersToCompanyDialog = () => {
  const actions = React.useContext(dialogActions);
  const { company, addUsersToCompany } = React.useContext(
    CompanyDetailsContext,
  );
  const [userListItems, setUserListItems] = React.useState<
    UserListItemViewModel[]
  >([]);
  const selectedUsers = userListItems.filter((item) => item.isSelected);

  const handleResultItemSelected = (user: UserListItem) => {
    if (userListItems.some((existingUser) => existingUser.id === user.id)) {
      // User already exists in list
      return;
    }
    const item: UserListItemViewModel = {
      id: user.id,
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      company_id: user.company_id,
      isSelected: true,
    };
    setUserListItems([...userListItems, item]);
  };

  const handleUserCheckedChange = (
    item: UserListItemViewModel,
    checked: boolean,
  ) => {
    const mutatableState = [...userListItems];
    mutatableState.forEach((x) => {
      if (x.id === item.id) {
        x.isSelected = checked;
      }
    });
    setUserListItems(mutatableState);
  };

  const handleAddUsers = () => {
    if (company) {
      actions.hide();
      addUsersToCompany(
        company.id,
        selectedUsers.map((item) => item.id),
      );
      reset();
    }
  };

  const reset = () => {
    setUserListItems([]);
  };

  return (
    <>
      <DialogHeader>Add users to Company</DialogHeader>
      <DialogContent>
        <SearchUsersDropdown onUserSelect={handleResultItemSelected} />
        <Flex
          column
          pt={2}
        >
          {userListItems.map((item) => (
            <UserListItemView
              key={item.id}
              item={item}
              onChange={handleUserCheckedChange}
            />
          ))}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex>
          <Confirm
            size="xlarge"
            title="Confirm user changes"
            confirmText="Yes, I'm sure"
            cancelText="No, cancel"
            onConfirm={handleAddUsers}
            opener={
              <Button
                variant="primary"
                disabled={selectedUsers.length === 0}
              >
                Add users
              </Button>
            }
            message={
              <>
                <Box mb={2}>
                  <Text>
                    Are you sure you want to change the company on the following
                    users:{" "}
                  </Text>
                </Box>
                {company !== undefined && (
                  <UserChangesConfirmation
                    users={selectedUsers}
                    company={company}
                  />
                )}
              </>
            }
          />
        </Flex>
      </DialogFooter>
    </>
  );
};
