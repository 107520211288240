import { http } from "@boligportal/juice";
import {
  AdDetailInterface,
  ApproveAdResponseInterface,
  ApproveUserResponseInterface,
  ClaimLandlordResponseInterface,
  CreateContractFromAdResponseInterface,
  EmailTemplateItem,
  GetAdToolbarResponseInterface,
  GetAndClaimNextAdResponseInterface,
  GetEmailTemplateContentResponseInterface,
  GetPreviewAdResponseInterface,
  RejectAdResponseInterface,
  ReserveAdResponseInterface,
  SendEmailResponseInterface,
  TaskResponseInterface,
  UnclaimLandlordResponseInterface,
  UnSkipPublishAdResponseInterface,
} from "apps/customer_service/pages/ad_detail_page/interfaces";
import {
  AdCounts,
  AdItem,
  IFilterState,
  LandlordAdStats,
  LandlordResponseInterface,
} from "apps/customer_service/pages/ads_page/interfaces";
import {
  EmailEvent,
  EmailLogEntry,
} from "apps/customer_service/pages/email_logs_page/types";
import { emailLogsFiltersToQueryParams } from "apps/customer_service/pages/email_logs_page/utils";
import { RefundFeedItem } from "apps/customer_service/pages/refunds_page/helpers";
import { CompanyDraft } from "apps/customer_service/pages/user_detail_page/company_details_view/components/company_details";
import {
  Company,
  CompanyFeed,
  CompanyUser,
  Discount,
} from "apps/customer_service/pages/user_detail_page/company_details_view/interfaces";
import {
  CustomerServiceAgentInterface,
  PaymentFeedItemInterface,
  RefundPayloadInterface,
  SubscriptionPaymentsItemInterface,
} from "apps/customer_service/pages/user_detail_page/subscriptions_feed/interfaces";
import { UserForCustomerService } from "apps/customer_service/pages/user_detail_page/user_detail_view/user_detail_view";
import { NewBuild } from "apps/newbuilds/types";
import { Ad } from "components/interfaces/ad";
import { Profile } from "components/interfaces/profile";
import { SkipPublishAdResponseInterface } from "../apps/customer_service/pages/ad_detail_page/interfaces";
import { emailLogsPageLimit } from "../apps/customer_service/pages/email_logs_page/useEmailLogData";
import { PaymentMethodType } from "../features/payments_feature/enums/PaymentMethodType";
import { AccountInfo, fetchFromAPI } from "./api";
import { blacklistReasonEnum, CrawlingFeedStatus } from "./enums";

export interface CompanyPage {
  body: string;
  filter_url: string;
  headline: string;
  id: number;
  image_id?: number | null;
  image_url?: string | null;
  lead: string;
  meta_description: string;
  slug: string;
  url: string;
}

export interface CompanyListItem {
  id: number;
  name: string;
  active_ads_count: string;
  total_rentables_count: string;
  ban_reason: blacklistReasonEnum;
  danske_udlejere: boolean;
  company_page_enabled: boolean;
  iframe_enabled: boolean;
  social_housing: boolean;
}

export type NewBuildSEOPayload = {
  meta_title: string;
  meta_description: string;
};

export interface SearchCompaniesResponse {
  companies: CompanyListItem[];
  count: number;
  results_pr_page: number;
  page: number;
}

export interface UserListItem {
  user_ban_reasons: blacklistReasonEnum[];
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  date_joined: string;
  date_last_login: string | null;
  company_id: number | null;
  date_profile_changed: string;
}

interface ActivityStreamAd {
  ad: {
    title: string;
    id: number;
    url: string;
  };
}

interface ActivityStreamCompany {
  company: Company;
}

interface ActivityStreamSubscription {
  subscription: {
    active: boolean;
  };
}

export enum ActionGroup {
  UserAction = "UserAction",
  ContactAction = "ContactAction",
  PaymentAction = "PaymentAction",
  CustomerServiceAction = "CustomerServiceAction",
  SystemAction = "SystemAction",
}

export enum ActivityStreamVerb {
  ChangedState = "Changed ad state from {old_state} to {new_state}",
  SetCompanyDiscount = "Set company discount to {percent_off}",
  RequestedPhoneNumber = "Requested a phone number for ad ID: {ad_id}",
  DepositedToCompanyAccount = "Deposited {amount} to company account ({company_name}). SalesForce ID: {salesforce_id}",
  AddedComment = "Added comment",
  CreatedCompany = "Created company",
  AddedUserIdToCompany = "Added {user_name} ({user_id}) to {company_name} ({company_id})",
  RemovedUserIdFromCompany = "Removed {user_name} ({user_id}) from {company_name} ({company_id})",
  EnabledEmbedded = "Enabled embedded",
  DisabledEmbedded = "Disabled embedded",
  EnabledIframe = "Enabled iframe",
  DisabledIframe = "Disabled iframe",
  BlacklistedUser = "Blacklisted user because of {reason} with the comment: {comment}",
  RemovedBlacklistOnUser = "Removed {reason} blacklist from user - comment was: {comment}",
  ChoosedTenantForAd = "Choose {user_id} tenant for ad : {ad_id}",
  ChoosenAsTenantForAd = "Got chosen as tenant for {ad_id} owned by {landlord_id}",
  DeletedRentable = "Rentable deleted",
}

interface ActivityStreamActionComment {
  comment: {
    comment: string | null;
  };
}

interface ActivityStreamActionInvoice {
  invoice: {
    paid: boolean;
  };
}

interface ActivityStreamActionRefund {
  refund: {
    refund_id: number;
    amount: number;
    zendesk_id: string;
  };
}

export interface ActivityStreamItem {
  timestamp: string;
  category: ActionGroup;
  verb: string;
  target: ActivityStreamAd | ActivityStreamSubscription | ActivityStreamCompany;
  action:
    | ActivityStreamActionComment
    | ActivityStreamActionInvoice
    | ActivityStreamActionRefund;
  data?: any;
}

export interface ActivityStreamResponse {
  stream: ActivityStreamItem[];
  page: number;
  page_size: number;
  count: number;
}

export interface PlanForCS {
  id: string;
  name_on_invoice: string;
  product_name: string;
  duration_days: number;
  price: number;
  purchasable: boolean;
  is_intro: boolean;
  is_winback: boolean;
  next_plan_id: string;
  num_active_subscriptions: number;
}

export interface CrawlersListItem {
  id: number;
  name: string;
  source: string;
  default_owner_id: number;
  created_date: string;
  first_name: string;
  last_name: string;
  username: string;
  ads: number;
  user: {
    fullname: string;
    username: string;
    id: number | null;
  };
  owner_mappings: {
    email: string;
    phone: string;
    user: {
      id: number;
      username: string;
    };
  }[];
  status: CrawlingFeedStatus;
}

export interface CrawlingDetails {
  id: number;
  name: string;
  source: string;
  default_owner: {
    id: number;
    username: string;
  } | null;
  owner_mappings: {
    email: string;
    phone: string;
    user: {
      id: number;
      username: string;
    };
  }[];
}

export interface DraftsListItem {
  id: number;
  name: string;
  address: string;
  import_file: string;
  errors: string;
  import_date: string;
}

export interface HistoricAdDetailItem {
  available_from: string | null;
  ad_phone_number?: string;
  category: string | null;
  city_level_1: string | null;
  city_level_2: string | null;
  city_level_3: string | null;
  created: string;
  deposit: number;
  description: string;
  digital_showing: boolean;
  social_housing: boolean;
  floor: number;
  has_video: boolean;
  id: number;
  monthly_rent: number;
  monthly_rent_extra_costs: number;
  needs_follow_up: boolean;
  needs_review: boolean;
  postal_code: string;
  prepaid_rent?: number;
  rentable_id: number;
  rental_period: number;
  rooms: number;
  size_m2: number;
  state: string;
  street_name: string;
  title: string;
  features: {
    balcony: boolean;
    elevator: boolean;
    furnished: boolean;
    parking: boolean;
    pet_friendly: boolean;
    senior_friendly: boolean;
    shareable: boolean;
    student_only: boolean;
    dryer: boolean;
    washing_machine: boolean;
    dishwasher: boolean;
    electric_charging_station: boolean;
  };
  hide_from_company_search: boolean;
  interactive_floor_plan_id: string;
  reference_id: string;
  address: {
    street_name: string;
    street_number: string;
    postal_code: string;
    postal_town: string;
    door: string;
    floor: string;
  };
}

export interface HistoricProfileDetailItem extends Profile {}

export type SortOrder = "desc" | "asc";

export type EmailLogsFilters = Partial<{
  start_index: number;
  end_index: number;
  email: string;
  email_topic: string;
  date_from: string;
  date_to: string;
}>;

export type LandLordInfo = {
  phone_number?: string;
  landlord_id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  profile_company_name?: string;
  user_type?: number;
  company_name?: string;
  company_search_enabled?: boolean;
};

export type BetalingsserviceMerchant = {
  bs_number: string;
  fi_number: string;
  debtor_group_number: string;
  user: LandLordInfo;
  bm_pay_client_id: number;
  status: BetalingsserviceMerchantStatus;
  id: number;
};

export type Payment = {
  user: string;
  agreement_id: string;
  status: string;
  payment_id: string;
};

export type Agreement = {
  user: string;
  agreement_id: string;
  status: string;
};

export const enum BetalingsserviceMerchantStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

interface NewBuildsResponse {
  new_builds: NewBuild[];
}

interface NewBuildResponse {
  data: Ad;
}

interface BoostPackageResponse {
  boost_until: string;
}

interface EditNewBuildAddressResponse {
  address: Ad["address"];
  newbuildId: number;
}

export async function getUsers(
  signal: AbortSignal,
  offset: number,
  limit: number,
  filters: { query: string },
): Promise<{
  results: UserListItem[];
  count: number;
}> {
  const url = encodeURI("/customer-service/api/users/");
  try {
    const response = await fetchFromAPI(url, {
      signal,
      method: "POST",
      body: JSON.stringify({
        filters,
        offset,
        limit,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function leaveCommentOnCompany(
  companyId: number,
  comment,
): Promise<Record<string, unknown>> {
  const url = encodeURI(
    `/customer-service/api/leave-comment/company/${companyId}/`,
  );

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCompanyActivityStream(
  id: number,
  order: SortOrder = "desc",
  start_index: number = 0,
  end_index: number,
): Promise<ActivityStreamResponse> {
  const url = encodeURI(
    `/customer-service/api/company/activity-stream/${id}/?start_index=${start_index}&end_index=${end_index}&order=${order}`,
  );

  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getActivityStream(
  id: number,
  type: "ad" | "user",
  userActivities: boolean = true,
  staffActivities: boolean = true,
  companyActivities: boolean = true,
  order: SortOrder = "desc",
  start_index: number = 0,
  end_index: number = 200,
): Promise<ActivityStreamResponse> {
  let url = encodeURI(
    `/customer-service/api/activity-stream/${type}/${id}/?start_index=${start_index}&end_index=${end_index}&order=${order}`,
  );
  url =
    url +
    `&include_user=${userActivities}&include_staff=${staffActivities}&include_company=${companyActivities}`;

  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// ============================================================================
// Ad Validation
// ============================================================================

export async function getAdToolbar(
  adId: number,
): Promise<GetAdToolbarResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/toolbar/`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getHistoricAdDetails(
  adId: number,
): Promise<{ data: HistoricAdDetailItem }> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/historic-details/`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getHistoricProfileDetails(
  userId: number,
): Promise<{ data: HistoricProfileDetailItem }> {
  const url = encodeURI(
    `/customer-service/api/user/${userId}/historic-details/`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAdDetails(adId: number): Promise<AdDetailInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/details/`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDraftDetails(draftId: number): Promise<any> {
  const url = encodeURI(
    `/customer-service/api/crawlings/drafts/${draftId}/details/`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function approveUser(
  userId: number,
): Promise<ApproveUserResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/user/${userId}/approve-profile-changes/`,
  );
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function approveAd(
  adId: number,
): Promise<ApproveAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/approve/`);
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function createContractFromAd(
  adId: number,
): Promise<CreateContractFromAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/create_contract/`);
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function reserveAd(
  adId: number,
): Promise<ReserveAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/reserve/`);
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function skipPublishAd(
  adId: number,
): Promise<SkipPublishAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/skip/`);
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function unSkipPublishAd(
  adId: number,
): Promise<UnSkipPublishAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/unskip/`);
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return response.json();
}

export async function rejectAd(
  adId: number,
): Promise<RejectAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/reject/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function followUp(adId: number): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/follow-up/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function takeOverAd(adId: number): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/take-over/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateAd(
  adId: number,
  fieldname: string,
  value: any,
): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/update/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        [fieldname]: value,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function removeFloorPlan(
  adId: number,
  imageId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/remove-floor-plan/`);

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        manual_floor_plan_image_id: imageId,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAdImage(
  adId: number,
  adImageId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/ad/${adId}/ad-images/${adImageId}`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "DELETE",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPreviewURL(
  adId: number,
): Promise<GetPreviewAdResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/preview/`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSortedEmailTemplates(): Promise<EmailTemplateItem[]> {
  const url = encodeURI("/customer-service/api/email-templates/");

  /** Sorts {@param items} in place by their name, and returns a reference to {@param items}. */
  const sortTemplatesInPlaceByName = (
    items: EmailTemplateItem[],
  ): EmailTemplateItem[] => {
    items.sort((firstItem, secondItem) =>
      firstItem.name.localeCompare(secondItem.name),
    );

    return items;
  };

  return fetchFromAPI(url)
    .then((response) => response.json())
    .then(sortTemplatesInPlaceByName);
}

export async function getEmailTemplateContent(
  templateId: number,
  adId: number,
): Promise<GetEmailTemplateContentResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/email-templates/${templateId}/ad/${adId}`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function sendEmail(
  templateId: number,
  adId: number,
  emailMessageBody: string,
): Promise<SendEmailResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/email-templates/${templateId}/ad/${adId}/send/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        content: emailMessageBody,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// ============================================================================
//NewBuilds endpoints
// ============================================================================

export async function getNewBuilds(userId: number): Promise<NewBuildsResponse> {
  const url = encodeURI(`/customer-service/api/newbuilds/${userId}`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getNewBuildDetails(
  newbuildId: number,
): Promise<NewBuildResponse> {
  const url = encodeURI(
    `/customer-service/api/newbuild/${newbuildId}/details/`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getNewBuildToolbar(
  newbuildId: number,
): Promise<GetAdToolbarResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/newbuild/${newbuildId}/toolbar/`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getNewBuildBoostPackage(
  newbuildId: number,
  value: boolean,
): Promise<BoostPackageResponse> {
  const url = encodeURI(`/customer-service/api/newbuild/${newbuildId}/boost/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({ boost_enabled: value }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editNewBuildAddress(
  newbuildId: number,
  payload: Ad["address"],
): Promise<EditNewBuildAddressResponse> {
  const url = encodeURI(
    `/customer-service/api/newbuild/${newbuildId}/address/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        newbuildId,
        address: {
          ...payload,
          street_name: payload.street_name,
          street_number: payload.street_number,
          postal_code: payload.postal_code,
          postal_town: payload.postal_town,
        },
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// ============================================================================
//blacklist user email endpoints
// ============================================================================
export async function getUserEmailTemplateContent(
  templateId: number,
  userId: number,
): Promise<GetEmailTemplateContentResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/email-templates/${templateId}/user/${userId}`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function sendUserEmail(
  templateId: number,
  userId: number,
  emailMessageBody: string,
): Promise<SendEmailResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/email-templates/${templateId}/user/${userId}/send/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        content: emailMessageBody,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// ============================================================================

export async function getProfile(
  userId: number,
): Promise<{ profile: Profile }> {
  const url = encodeURI(`/customer-service/api/profile/${userId}/`);

  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUser(
  userId: number,
): Promise<{ user: UserForCustomerService }> {
  const url = encodeURI(`/customer-service/api/user/${userId}/`);

  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function gdprDeleteUser(
  userId: number,
  confirm: string,
): Promise<{ message?: string }> {
  const url = encodeURI(
    `/customer-service/api/gdpr-delete/user/${userId}/?confirm=${confirm}`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "DELETE",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function setCompanyDealAccess(userId: number): Promise<any> {
  const url = encodeURI(
    `/customer-service/api/subscriptions/company-deal-access/${userId}`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        userId: userId,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function removeCompanyDealAccess(userId: number): Promise<any> {
  const url = encodeURI(
    `/customer-service/api/subscriptions/remove-company-deal-access/${userId}`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "DELETE",
      body: JSON.stringify({
        userId: userId,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateProfile(
  userId: number,
  fieldname: string,
  value: any,
): Promise<any> {
  const url = encodeURI(`/customer-service/api/profile/${userId}/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        [fieldname]: value,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUsername(
  userId: number,
  fieldname: string,
  value: any,
): Promise<{ success: boolean }> {
  const url = encodeURI(`/customer-service/api/user/${userId}/username/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        [fieldname]: value,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateNewBuild(
  newbuildId: number,
  payload: NewBuildSEOPayload,
): Promise<{ success: boolean; errors?: any }> {
  const url = encodeURI(`/customer-service/api/newbuild/${newbuildId}/update/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function leaveComment(userId: number, comment): Promise<{}> {
  const url = encodeURI(`/customer-service/api/leave-comment/user/${userId}/`);

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function commentOnAd(
  adId: number,
  comment,
): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/leave-comment/`);

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAdSubscriptions(
  adId: number,
): Promise<{ results: SubscriptionPaymentsItemInterface[] }> {
  const url = encodeURI(`/customer-service/api/subscriptions/?ad_id=${adId}`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// ================================================================================================
// Free Upsell Feature
// ================================================================================================

export type PaymentType = "invoice" | "free";

/**
 *
 * @param planId
 * @param renewalDate // yyyy-mm-dd
 * @param adId
 * @param userId
 */
export async function startFreeSubscription(payload: {
  planIds: number[];
  renewalDate: string;
  adId?: number;
  userId: number;
  paymentType: PaymentType;
}): Promise<
  {
    plan: number;
    subscription_created: boolean;
    error_message: string;
    invoice_paid: boolean;
  }[]
> {
  const url = encodeURI("/api/subscription-products/start-free-subscription");

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        plan_ids: payload.planIds,
        renewal_date: payload.renewalDate,
        ad_id: payload.adId,
        user_id: payload.userId,
        payment_type: payload.paymentType,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFreePlans(userId: number): Promise<{
  plans: { plan_id: number; product_name: string; is_upsell: boolean }[];
}> {
  const url = encodeURI(
    `/api/subscription-products/get-free-plans?user_id=${userId}`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// ------------------------------------------------------------------------------------------------

export async function getUserSubscriptions(
  userId: number,
): Promise<{ results: SubscriptionPaymentsItemInterface[] }> {
  const url = encodeURI(
    `/customer-service/api/subscriptions/?user_id=${userId}`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCustomerServiceAgents(): Promise<{
  results: CustomerServiceAgentInterface[];
}> {
  const url = encodeURI("/customer-service/api/customer-service-agents/");
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function beginApprovalActivityStream(adId: number){
  const url = encodeURI(`/customer-service/api/begin-approval/${adId}/`);
  const response = await fetchFromAPI(url);
  return await response.json();
}

// CLAIM APIs
export async function claimLandlord(
  adId: number,
  landlordId: number,
  takeOver: boolean,
): Promise<ClaimLandlordResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/ad/landlord/${landlordId}/claim/?ad_id=${adId}&force=${
      takeOver ? "1" : "0"
    }`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

// Call this on window leave/tab close
export async function unclaimLandlord(
  landlordId: number,
): Promise<UnclaimLandlordResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/ad/landlord/${landlordId}/unclaim/`,
  );
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAndClaimNextAd(
  currentAdId: number,
  filters: IFilterState,
): Promise<GetAndClaimNextAdResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/ad/get-and-claim-next-ad/?current_ad_id=${currentAdId}`,
  );
  const response = await fetchFromAPI(url, {
    method: "POST",
    body: JSON.stringify({
      ad_states: filters.adStates,
      review_reasons: filters.review_reasons,
      needs_follow_up: filters.needsFollowUp,
      crawling_ads: filters.crawlingAds,
      verified_company_ads: filters.verified_company_ads,
    }),
  });
  return response.json();
}

export async function getAds(data: {
  signal: AbortSignal;
  filters: IFilterState;
  offset?: number;
  limit?: number;
  descending?: boolean;
  sorting?: string;
  global_address_search?: string;
}): Promise<{ data: AdItem[]; count: number }> {
  const url = "/customer-service/api/ads/";
  const { offset, limit, signal, descending, sorting, global_address_search } =
    data;

  const response = await fetchFromAPI(url, {
    method: "POST",
    signal,
    body: JSON.stringify({
      filters: {
        ad_id: data.filters.adId,
        company_id: data.filters.companyId,
        address: data.filters.address,
        landlord_id: data.filters.landlordId,
        landlord_id_exact: data.filters.landlord_id_exact,
        landlord_email: data.filters.landlordEmail,
        contact_email: data.filters.contactEmail,
        contact_phone: data.filters.contactPhone,
        ad_states: data.filters.adStates,
        date_from: data.filters.dateFrom,
        date_to: data.filters.dateTo,
        review_reasons: data.filters.review_reasons,
        needs_follow_up: data.filters.needsFollowUp,
        crawling_ads: data.filters.crawlingAds,
        crawling_source_id: data.filters.crawlingSourceId,
        crawling_feed: data.filters.feed,
        verified_company_ads: data.filters.verified_company_ads,
        custom_address: data.filters.customAddress,
      },
      offset,
      limit,
      sorting,
      descending,
      global_address_search,
    }),
  });
  return response.json();
}

export async function adCounts(): Promise<AdCounts> {
  const url = "/customer-service/api/list_counts/";
  const response = await fetchFromAPI(url);
  return response.json();
}

export async function getLandlord(
  adId: number,
): Promise<LandlordResponseInterface> {
  const url = encodeURI(`/customer-service/api/ad/landlord/${adId}/`);
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateLandlord(
  adId: number,
  fieldname: string,
  value: string,
): Promise<{ success: boolean }> {
  const url = encodeURI(`/customer-service/api/ad/landlord/${adId}/update/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        [fieldname]: value,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function landlordAdStats(
  landlordIds: number[],
): Promise<{ data: LandlordAdStats[] }> {
  const url = encodeURI("/customer-service/api/landlord/ad-stats/");
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        landlord_ids: landlordIds,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaymentsFeed(data: {
  signal: AbortSignal;
  filters: {
    username?: string;
    subscriptionId?: number | null;
    orderId?: string;
    paymentStatuses?: string[];
    planIds?: number[];
    paymentMethod?: string;
    paymentMethodTypes?: PaymentMethodType[];
    dateFrom: string | null;
    dateTo: string | null;
    hasRefund?: boolean;
  };
  offset?: number;
  limit?: number;
}): Promise<{ results: PaymentFeedItemInterface[]; count: number }> {
  const url = "/customer-service/api/payments/";
  const {
    username,
    subscriptionId,
    orderId,
    paymentMethod,
    paymentMethodTypes,
    planIds,
    paymentStatuses,
    hasRefund,
    dateFrom,
    dateTo,
  } = data.filters;
  const { offset, limit, signal } = data;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      signal,
      body: JSON.stringify({
        filters: {
          username,
          subscription_id: subscriptionId,
          order_id: orderId,
          payment_statuses: paymentStatuses,
          has_refund: hasRefund,
          plan_ids: planIds,
          payment_method: paymentMethod,
          payment_method_types: paymentMethodTypes,
          date_from: dateFrom,
          date_to: dateTo,
        },
        offset,
        limit,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getRefundsFeed(data: {
  signal: AbortSignal;
  filters: {
    refundId?: number;
    username?: string;
    agents?: number[];
    amountMin?: string;
    amountMax?: string;
    orderId?: string;
    reasons?: string[];
    zendeskTicketId?: string;
    dateFrom?: string | null;
    dateTo?: string | null;
  };
  offset?: number;
  limit?: number;
}): Promise<{ results: RefundFeedItem[]; count: number }> {
  const url = "/customer-service/api/refunds/";
  const {
    refundId,
    username,
    agents,
    amountMin,
    amountMax,
    orderId,
    reasons,
    zendeskTicketId,
    dateFrom,
    dateTo,
  } = data.filters;
  const { offset, limit, signal } = data;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      signal,
      body: JSON.stringify({
        filters: {
          refund_id: refundId,
          username,
          agents,
          amount_from: amountMin,
          amount_to: amountMax,
          order_id: orderId,
          reasons,
          zendesk_ticket_id: zendeskTicketId,
          date_from: dateFrom,
          date_to: dateTo,
        },
        offset,
        limit,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function refundPayments(
  refundPayload: RefundPayloadInterface,
): Promise<{ success: boolean }> {
  const url = encodeURI("/customer-service/api/refund/");
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify(refundPayload),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function cancelSubscription(
  subscriptionId: number,
  reason: string,
  expire: boolean,
): Promise<{}> {
  const url = `/customer-service/api/subscriptions/cancel/${subscriptionId}/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        reason: reason,
        expire: expire,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function terminateSubscription(
  subscriptionId: number,
): Promise<{}> {
  const url = `/customer-service/api/subscriptions/terminate/${subscriptionId}`;
  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return await response.json();
}

export async function getCompanyDiscount(
  companyId: number,
): Promise<{ discount: Discount }> {
  const url = `/customer-service/api/companies/${companyId}/discount/`;
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadImage(file: File): Promise<{
  image_id: number;
  url: string;
}> {
  const url = "/api/image/upload";

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function giveCompanyDiscount(
  companyId: number,
  percentOff: number,
): Promise<{ discount: Discount }> {
  const url = `/customer-service/api/companies/${companyId}/discount/set/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        percent_off: percentOff,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPlans(): Promise<{ results: PlanForCS[] }> {
  const url = "/customer-service/api/plans/";
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reportAd(
  adId: number,
  reason: string,
  text: string,
): Promise<{}> {
  const request = fetchFromAPI("/customer-service/api/report/listing", {
    method: "POST",
    body: JSON.stringify({
      id: adId,
      reason,
      text,
    }),
  });

  return request.then((response) => response.json());
}

export async function createCompany(
  payload: CompanyDraft,
): Promise<{ company?: Company; errors?: { [key: string]: string[] } }> {
  const url = "/customer-service/api/companies/create/";
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateCompany(
  companyId: number,
  fieldname: string,
  value: any,
): Promise<{ errors?: { [key: string]: string[] } }> {
  const url = `/customer-service/api/companies/${companyId}/update/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        [fieldname]: value,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addUsersToCompany(
  companyId: number,
  payload: { user_ids: number[] },
): Promise<{ users_updated: number } | { error: { message: string } }> {
  const url = `/customer-service/api/companies/${companyId}/add-users/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function detachUserFromCompany(
  userId: number,
): Promise<{ user_updated: boolean } | { error: { message: string } }> {
  const url = `/customer-service/api/companies/${userId}/detach/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCompany(companyId: number): Promise<{
  company: Company;
  company_users: CompanyUser[];
  company_feeds: CompanyFeed[];
}> {
  const url = `/customer-service/api/companies/${companyId}`;
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteCompany(companyId: number) {
  const url = encodeURI(`/customer-service/api/companies/${companyId}/delete/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "DELETE",
    });

    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCompanies(
  offset: number,
  limit: number,
): Promise<{ companies: Company[]; count: number }> {
  const url = encodeURI("/customer-service/api/companies/");
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        offset,
        limit,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCrawlers(data: {
  signal: AbortSignal;
}): Promise<{ count: number; results: CrawlersListItem[] }> {
  const url = "/customer-service/api/crawlings";
  const { signal } = data;
  try {
    const response = await fetchFromAPI(url, {
      signal,
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDrafts(data: {
  signal: AbortSignal;
}): Promise<{ results: DraftsListItem[] }> {
  const url = "/customer-service/api/crawlings/drafts/";
  const { signal } = data;
  try {
    const response = await fetchFromAPI(url, {
      signal,
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function publishDraft(
  draftId: number,
  data: any,
): Promise<TaskResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/crawlings/drafts/${draftId}/publish/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        data,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteDraft(
  draftId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/crawlings/drafts/${draftId}/delete/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function pauseCrawling(
  crawlingId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(`/customer-service/api/crawlings/${crawlingId}/pause/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function makeAdsNative(
  crawlingId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/crawlings/${crawlingId}/make-native/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function activateCrawling(
  crawlingId: number,
): Promise<TaskResponseInterface> {
  const url = encodeURI(
    `/customer-service/api/crawlings/${crawlingId}/activate/`,
  );
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCrawling(
  crawlingId: number,
): Promise<CrawlingDetails> {
  const url = encodeURI(`/customer-service/api/crawlings/${crawlingId}/`);
  const response = await fetchFromAPI(url);
  return response.json();
}

export async function updateCrawling(
  crawlingId: number,
  update: {
    name: string;
    default_owner_id: number | null;
    owner_mappings: {
      email: string;
      phone: string;
      user_id: number;
    }[];
  },
): Promise<{ success: boolean }> {
  const url = encodeURI(
    `/customer-service/api/crawlings/${crawlingId}/update/`,
  );
  const response = await fetchFromAPI(url, {
    method: "PUT",
    body: JSON.stringify(update),
  });
  return response.json();
}

export async function getCompanyAccount(
  companyId: number,
): Promise<{ account: AccountInfo | null }> {
  const url = `/customer-service/api/companies/${companyId}/account/`;
  const response = await fetchFromAPI(url);
  return await response.json();
}

export interface AccountTransaction {
  id: number;
  date: string;
  amount: number;
  type: "DEPOSIT" | "REFUND" | "CHARGE" | "TRANSFER" | "ADJUSTMENT";
  user: UserListItem;
  salesforce_id: string | null;
  invoice_id: number | null;
}

export async function getCompanyAccountTransactions(
  companyId: number,
): Promise<{ transactions: AccountTransaction[] }> {
  const url = `/customer-service/api/companies/${companyId}/account/transactions/`;
  const response = await fetchFromAPI(url);
  return await response.json();
}

export interface AccountSettingsUpdate {
  enabled?: boolean;
  allowNegativeBalance?: boolean;
}

export async function setCompanyAccountSettings(
  companyId: number,
  settings: AccountSettingsUpdate,
): Promise<{ account: AccountInfo | null }> {
  const url = `/customer-service/api/companies/${companyId}/account/settings/`;
  const response = await fetchFromAPI(url, {
    method: "PUT",
    body: JSON.stringify({
      enabled: settings.enabled,
      allow_negative_balance: settings.allowNegativeBalance,
    }),
  });
  return await response.json();
}

interface MakeDepositInput {
  amount: string;
  salesForceID: string;
}

export async function makeDepositToCompanyAccount(
  companyId: number,
  deposit: MakeDepositInput,
): Promise<{ error?: string; account: AccountInfo | null }> {
  const url = `/customer-service/api/companies/${companyId}/account/deposit/`;
  const response = await fetchFromAPI(url, {
    method: "POST",
    body: JSON.stringify({
      amount: deposit.amount,
      salesforce_id: deposit.salesForceID,
    }),
  });
  return await response.json();
}

export async function banUser(data: {
  user_id: number;
  reason: blacklistReasonEnum;
  note: string;
  expire_seeker_subscriptions?: boolean;
  expire_landlord_subscriptions?: boolean;
  nuke_login?: boolean;
  force_waiting_for_approval?: boolean;
  unsubscribe_from_all_emails?: boolean;
  inactivate_all_rentables?: boolean;
  mark_all_ads_as_rented_out?: boolean;
}): Promise<{}> {
  const url = "/customer-service/api/user/ban";
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function liftBanOnUser(ban_id: number): Promise<{}> {
  const url = "/customer-service/api/user/lift-ban/";
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        ban_id: ban_id,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function sendUpsellOfferEmail(
  upsellProduct: string,
  adId: number,
): Promise<{}> {
  const url = encodeURI(`/customer-service/api/ad/${adId}/upsell-offer/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        upsell_product: upsellProduct,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUsersEmailLogStream(
  filters: EmailLogsFilters,
): Promise<{
  entries: EmailLogEntry[];
  count: number;
}> {
  const baseUrl = "/customer-service/api/mail-logs";

  if (!filters.start_index) {
    filters.start_index = 0;
    filters.end_index = emailLogsPageLimit;
  }

  const queryParams = emailLogsFiltersToQueryParams(filters);
  const url = `${baseUrl}?${queryParams.toString()}`;

  const response = await fetchFromAPI(url, {
    method: "POST",
  });
  return await response.json();
}

export async function getEmailEvents(id: string): Promise<{
  count: number;
  events: EmailEvent[];
}> {
  return fetchFromAPI(`/customer-service/api/mail-events?id=${id}`).then(
    (res) => res.json(),
  );
}

export async function getBetalingsserviceList(payload: {
  status?: BetalingsserviceMerchantStatus[];
}): Promise<{
  results: BetalingsserviceMerchant[];
}> {
  return fetchFromAPI("/customer-service/api/bs-merchants", {
    method: "POST",
    body: JSON.stringify({
      status: payload.status,
    }),
  }).then((res) => res.json());
}

export async function updateBetalingsserviceMerchantStatus(
  merchantId: number,
  status: BetalingsserviceMerchantStatus,
): Promise<{
  result: BetalingsserviceMerchant[];
}> {
  return fetchFromAPI(
    `/customer-service/api/bs-merchants/${merchantId}/update/`,
    {
      method: "PUT",
      body: JSON.stringify({
        status: status,
      }),
    },
  ).then((res) => res.json());
}

export const getPayments = (url: string) =>
  http.get<{ payments: Payment[] }>(`${url}`);

export const getAgreements = (url: string) =>
  http.get<{ agreements: Agreement[] }>(`${url}`);

export async function handleCompanyPageSubscription(
  userId: number,
  enabled: boolean,
): Promise<{ error?: string; error_code?: string }> {
  const url = `/customer-service/api/subscriptions/company-page-subscription/${userId}`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({
        userId,
        enabled,
      }),
    });
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function toggleMobilePay(
  enabled: boolean,
): Promise<{ enabled: boolean }> {
  const url = encodeURI(`/customer-service/api/toggle-mobile-pay/`);
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
      body: JSON.stringify({ enabled }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function toggleBankIdVerificationBadge(
  userId: number,
): Promise<{ enabled: boolean }> {
  const url = `/customer-service/api/landlord/mark-bank-id-verified/${userId}/`;
  try {
    const response = await fetchFromAPI(url, {
      method: "POST",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
