/* eslint-disable comma-dangle */
import { ChooseOrCreateRentableState } from "../interfaces/ChooseOrCreateRentableState";
import { ChooseOrCreateRentableReducerAction } from "./ChooseOrCreateRentableReducerAction";
import { ChooseOrCreateRentableReducerType } from "./ChooseOrCreateRentableReducerType";

export const ChooseOrCreateRentableReducer: ChooseOrCreateRentableReducerType =
  (
    state: ChooseOrCreateRentableState,
    action: ChooseOrCreateRentableReducerAction,
  ) => {
    switch (action.type) {
      case "UPDATE_RENTABLES": {
        return {
          ...state,
          rentables: action.rentables,
          filterCount: action.filterCount,
          allCount: action.allCount,
          start_index: action.start_index,
        };
      }
      case "UPDATE_PAGINATION_START_INDEX": {
        return {
          ...state,
          start_index: action.newStartIndex,
        };
      }
      case "UPDATE_SEARCH_VALUE": {
        return {
          ...state,
          searchValue: action.newSearchValue,
        };
      }
      case "SHOW_ADDRESS_DIALOG": {
        return {
          ...state,
          shouldShowAddressDialog: action.shouldShowAddressDialog,
        };
      }
    }
  };
