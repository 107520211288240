import { Button, styled } from "@boligportal/juice";

interface Props {
  text: string;
  onClick: () => void;
}

export const TinyTextButton = ({ text, onClick }: Props) => (
  <TinyButton
    text
    onClick={onClick}
  >
    {text}
  </TinyButton>
);

const TinyButton = styled(Button)`
  font-size: ${(p) => p.theme.fontSize.tiny};
`;
