import * as React from "react";
import { ModalActions, modalActions } from "components/modal_controller";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// Thanks to StackOverflow for help with the typings:
// https://stackoverflow.com/a/49726665
type TPropOmit<T, K extends string> = Pick<T, Exclude<keyof T, K>>;

interface TModalActionsPropInject {
  modalActions: ModalActions;
}

/**
 * Wrap your function or class-based component with this to provide the
 * modal actions as the `modalActions` prop, without having
 * to manually pass it in to the component.
 */
export function withModalActions<
  TPropsOrig extends TModalActionsPropInject,
  TPropsNew = TPropOmit<TPropsOrig, keyof TModalActionsPropInject>,
>(
  WrappedComponent: React.ComponentType<TPropsOrig>,
): React.ComponentType<TPropsNew> {
  function wrapper(props: TPropsNew): React.ReactElement<TPropsOrig> {
    return (
      <modalActions.Consumer>
        {(actions) => (
          <WrappedComponent
            modalActions={actions}
            {...(props as any)}
          />
        )}
      </modalActions.Consumer>
    );
  }
  (wrapper as any).displayName = `WithModalActions(${getDisplayName(
    WrappedComponent,
  )})`;
  return wrapper;
}
