import React from "react";
import {
  Badge,
  Box,
  Center,
  CheckField,
  Confirm,
  DataTable,
  Flex,
  Spinner,
  styled,
  Text,
} from "@boligportal/juice";
import { useMerchantStatusMutation } from "api/mutations";
import { useBetalingsserviceCSQuery } from "api/queries";
import { PAGE_USERS } from "apps/customer_service";
import { StyledLink } from "apps/customer_service/components/topbar/global_search/components/styled_link";
import {
  BetalingsserviceMerchant,
  BetalingsserviceMerchantStatus,
} from "lib/customerServiceApi";

const StyledSpinnerWrapper = styled(Center)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const BetalingsservicePage = () => {
  const [confirmDialogConfig, setConfirmDialogConfig] = React.useState<{
    showConfirm: boolean;
    item?: BetalingsserviceMerchant;
  }>({
    showConfirm: false,
  });
  const [filters, setFilters] = React.useState<
    BetalingsserviceMerchantStatus[]
  >([BetalingsserviceMerchantStatus.PENDING]);

  const { data, isFetching, isLoading } = useBetalingsserviceCSQuery(filters);
  const updateMerchantStatusMutation = useMerchantStatusMutation();

  const handleFilterChange = (filterValue) => {
    const filterIndex = filters.indexOf(filterValue);
    if (filterIndex > -1) {
      setFilters(filters.filter((filter) => filter !== filterValue));
    } else {
      setFilters([...filters, filterValue]);
    }
  };

  const confirmApprove = () => {
    confirmDialogConfig.item?.id &&
      updateMerchantStatusMutation.mutate({
        merchantId: confirmDialogConfig.item.id,
        status: BetalingsserviceMerchantStatus.ACCEPTED,
      });
    setConfirmDialogConfig({
      showConfirm: false,
    });
  };

  const badgeState: Record<
    BetalingsserviceMerchantStatus,
    React.ComponentProps<typeof Badge>["state"]
  > = {
    [BetalingsserviceMerchantStatus.PENDING]: "disabled",
    [BetalingsserviceMerchantStatus.ACCEPTED]: "success",
    [BetalingsserviceMerchantStatus.REJECTED]: "warning",
  };
  const badgeText: Record<BetalingsserviceMerchantStatus, string> = {
    [BetalingsserviceMerchantStatus.PENDING]: "Pending",
    [BetalingsserviceMerchantStatus.ACCEPTED]: "Approved",
    [BetalingsserviceMerchantStatus.REJECTED]: "Rejected",
  };

  return (
    <Flex
      column
      width="100%"
    >
      {isFetching ||
        isLoading ||
        (updateMerchantStatusMutation.isLoading && (
          <StyledSpinnerWrapper>
            <Spinner size="large" />
          </StyledSpinnerWrapper>
        ))}

      <Confirm
        open={confirmDialogConfig.showConfirm}
        cancelText="Cancel"
        confirmText="Approve"
        message={
          <Flex column>
            <Flex gap>
              <Text>Client ID:</Text>
              <Text weight="semiBold">
                {confirmDialogConfig.item?.bm_pay_client_id}
              </Text>
            </Flex>
            <Flex gap>
              <Text>PBS number:</Text>
              <Text weight="semiBold">
                {confirmDialogConfig.item?.bs_number}
              </Text>
            </Flex>
            <Flex gap>
              <Text>Debtor group number:</Text>
              <Text weight="semiBold">
                {confirmDialogConfig.item?.debtor_group_number}
              </Text>
            </Flex>
            <Flex gap>
              <Text>FI number: </Text>
              <Text weight="semiBold">
                {confirmDialogConfig.item?.fi_number}
              </Text>
            </Flex>
          </Flex>
        }
        onCancel={() =>
          setConfirmDialogConfig({
            showConfirm: false,
          })
        }
        onConfirm={confirmApprove}
        title="Approve Details"
      />

      <Box
        px={10}
        py={3}
        bg="tint"
      >
        <Flex>
          <Box mr={4}>
            <CheckField
              label={"Pending"}
              checked={filters.includes(BetalingsserviceMerchantStatus.PENDING)}
              onChange={() =>
                handleFilterChange(BetalingsserviceMerchantStatus.PENDING)
              }
            />
          </Box>
          <CheckField
            label={"Approved"}
            checked={filters.includes(BetalingsserviceMerchantStatus.ACCEPTED)}
            onChange={() =>
              handleFilterChange(BetalingsserviceMerchantStatus.ACCEPTED)
            }
          />
        </Flex>
      </Box>

      <DataTable<BetalingsserviceMerchant>
        rowActions={[
          {
            label: "Allow Edit (Reject)",
            callback: (item) =>
              updateMerchantStatusMutation.mutate({
                merchantId: item.id,
                status: BetalingsserviceMerchantStatus.REJECTED,
              }),
            disableFunction: (item: {
              status: BetalingsserviceMerchantStatus;
            }) => item.status === BetalingsserviceMerchantStatus.REJECTED,
          },
          {
            label: "Approve",
            callback: (item) =>
              setConfirmDialogConfig({
                showConfirm: true,
                item,
              }),
            disableFunction: (item) =>
              item.status === BetalingsserviceMerchantStatus.ACCEPTED,
          },
        ]}
        useElipsisForSingleRowActions
        fullscreen
        items={data?.results ?? []}
        keyField="bs_number"
        columns={[
          {
            fieldLabel: "",
            fieldName: "status",
            cellRenderer: ({ status }) => (
              <Badge
                state={badgeState[status]}
                variant="transparent"
              >
                {badgeText[status]}
              </Badge>
            ),
          },
          {
            fieldLabel: "User",
            fieldName: "user",
            cellRenderer: ({ user }) => (
              <Flex column>
                <Text
                  weight="semiBold"
                  size="small"
                >{`${user.first_name} ${user.last_name} #${user.landlord_id}`}</Text>
                <StyledLink to={`${PAGE_USERS}/${user.landlord_id}`}>
                  <Text
                    block
                    size="tiny"
                  >
                    {user.email}
                  </Text>
                </StyledLink>
              </Flex>
            ),
          },
          {
            fieldLabel: "Phone number",
            fieldName: "user",
            cellRenderer: ({ user }) => (
              <Text size="tiny">{user.phone_number}</Text>
            ),
          },
          {
            fieldLabel: "PBS number",
            fieldName: "bs_number",
            cellRenderer: ({ bs_number }) => (
              <Text size="small">{bs_number}</Text>
            ),
          },
          {
            fieldLabel: "Debtor group number",
            fieldName: "debtor_group_number",
            cellRenderer: ({ debtor_group_number }) => (
              <Text size="small">{debtor_group_number}</Text>
            ),
          },
          {
            fieldLabel: "FI number",
            fieldName: "fi_number",
            cellRenderer: ({ fi_number }) => (
              <Text size="small">{fi_number}</Text>
            ),
          },
        ]}
      />
    </Flex>
  );
};
