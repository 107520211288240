import { Text } from "@boligportal/juice";
import { AdStates } from "lib/enums";
import { ClaimItem } from "../interfaces";
import { ApprovalQueueItem } from "./approval_queue_item";

function needsApprovalOrReview(item: ClaimItem) {
  return (
    (item.needs_review &&
      [
        AdStates.ACTIVE_APPROVED,
        AdStates.RESERVED_APPROVED.toString(),
      ].includes(item.state)) ||
    item.state === AdStates.INACTIVE_PENDING_APPROVAL
  );
}

const ApprovalQueue = (props: {
  items: ClaimItem[];
  currentAdId: number;
  landlordId: number;
}) => {
  const workingQueue = props.items.filter(
    (item) => item.ad_id !== props.currentAdId && needsApprovalOrReview(item),
  );
  return (
    <>
      {workingQueue.length === 0 && (
        <Text>
          <em>No more ads are awaiting approval</em>
        </Text>
      )}
      {workingQueue.map((item) => (
        <ApprovalQueueItem
          key={`approval-queue-item-${item.ad_id}`}
          item={item}
          landlordId={props.landlordId}
        />
      ))}
    </>
  );
};
export { ApprovalQueue };
