import { createContext, useContext } from "react";
import { App } from "components/app";
import {
  Market,
  MarketSettings,
  getMarketSettings,
} from "components/markets/market_settings";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";

const MarketSettingsContext = createContext<MarketSettings | null>(null);

type Props = {
  children: React.ReactNode;
  market: Market;
};

export const MarketSettingsProvider = ({ children, market }: Props) => (
  <MarketSettingsContext.Provider value={getMarketSettings(market)}>
    {children}
  </MarketSettingsContext.Provider>
);

export const useMarketSettings = () => {
  const context = useContext(MarketSettingsContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useMarketSettings",
      "MarketSettingsProvider",
    );
  }

  return context;
};

export const useFBPPaymentMethodCreditCardSVGs = () => {
  const {
    paywall: { creditCardIcons },
  } = useMarketSettings();
  const mobilePayDisabled = !App.settings.mobile_pay_enabled;

  if (mobilePayDisabled) {
    delete creditCardIcons["mobile_pay"];
  }

  // return merged array of credit card icons
  return Object.values(creditCardIcons).reduce(
    (acc, value) => acc.concat(value),
    [],
  );
};

export const useUpsellPaymentMethodCreditCardSVGs = () => {
  const {
    paywall: { creditCardIcons },
  } = useMarketSettings();
  const mobilePayDisabled = !App.settings.mobile_pay_enabled;

  delete creditCardIcons["google_pay"];
  delete creditCardIcons["apple_pay"];

  if (mobilePayDisabled) {
    delete creditCardIcons["mobile_pay"];
  }
  // return merged array of credit card icons
  return Object.values(creditCardIcons).reduce(
    (acc, value) => acc.concat(value),
    [],
  );
};
