export const createRotatedImageBlob = (imageUrl: string): Promise<File> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${imageUrl}?orient=270`, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const { status } = xhr;
      if (status == 200) {
        resolve(xhr.response);
      } else {
        reject(status);
      }
    };
    xhr.send();
  });
