import {
  Button,
  Flex,
  IconDocumentTextOutline,
  Tooltip,
} from "@boligportal/juice";
import { ButtonBadgeOrEye } from "components/buttons/ButtonBadgeOrEye";
import { t } from "lib/i18n";

export type RentPaymentDetailButtonCell = {
  rentPaymentDetailButtonCell: {
    count: number;
    onClick: () => void;
    note: string | null;
  };
};

export const rentPaymentDetailButtonCellRenderer = ({
  rentPaymentDetailButtonCell,
}: RentPaymentDetailButtonCell) => {
  const { count, note, onClick } = rentPaymentDetailButtonCell;

  const truncateNote = (note: string) => {
    if (note.length >= 50) {
      return `${note.slice(0, 50)}...`;
    }
    return note;
  };

  return (
    <Flex justify="end">
      {note && (
        <Tooltip text={truncateNote(note)}>
          <Button
            variant="subtle"
            icon={IconDocumentTextOutline}
            onClick={onClick}
          ></Button>
        </Tooltip>
      )}
      <ButtonBadgeOrEye
        variant="subtle"
        count={count}
        onClick={onClick}
      >
        {t("common.buttons.show_details")}
      </ButtonBadgeOrEye>
    </Flex>
  );
};
