import { Card, CardHeader, CardSection, Flex, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { ActivateLeadButton } from "./ActivateLeadButton";

interface Props {
  onClick: () => void;
  action?: () => React.ReactNode;
  text: string;
  title: string;
}

export const LeadCardTrigger = ({ onClick, text, title, action }: Props) => (
  <Card sectioned>
    <CardHeader
      padding
      title={title}
      description={<Text markdown>{text}</Text>}
    />
    <CardSection>
      {action ? (
        <div onClick={onClick}>{action()}</div>
      ) : (
        <Flex justify="center">
          <ActivateLeadButton
            buttonLabel={t("leads.tryg.trigger.button_label")}
            onClick={onClick}
          />
        </Flex>
      )}
    </CardSection>
  </Card>
);
