import { useState, useLayoutEffect, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  Card,
  CardSection,
  Flex,
  FlexColumn,
  Text,
  TextField,
  Dialog,
  DialogHeader,
  Notice,
} from "@boligportal/juice";
import { SimpleTextLink } from "apps/customer_service/components/simple_text_link/SimpleTextLink";
import { App } from "components/app";
import { Image } from "components/interfaces/image";
import { Market } from "components/markets/market_settings";
import { createClientFormattedAddress } from "components/rental_address/createClientFormattedAddress";
import {
  RentalAddressValueObject,
  AutoSuggestedAddressUUID,
} from "components/rental_address/types";
import { format } from "date-fns";
import { ManualAddress } from "features/address/common/interfaces/ManualAddress";
import { AddressManualFormDenmark } from "features/address/dk/AddressManualDenmark";
import { isSide } from "features/address/dk/helpers/isSide";
import { LockableFormField } from "features/cs/lockable_form_field_feature/components/LockableFormField";
import { useExternalAddressWebsites } from "features/cs/market_specific_feature/hooks/useExternalAddressWebsites";
import { useDebounce } from "hooks/useDebounce";
import { AdOpenHouse } from "lib/api";
import { HistoricAdDetailItem } from "lib/customerServiceApi";
import { t } from "lib/i18n";
import { getImageUrl } from "lib/image";
import { isBoligPortal } from "lib/utils";
import { UserForCustomerService } from "../../user_detail_page/user_detail_view/user_detail_view";
import { AdImageInterface } from "../interfaces";
import {
  AdImageDeletionTask,
  AdImageRemoveManualFloorPlanTask,
  AdImageReorderTask,
  AdImageSetManualFloorPlanTask,
  FormElementUpdateTask,
} from "../types";
import { CS_EditAddressDenmark } from "./address_forms/CS_EditAddressDenmark";
import {
  CS_ApartmentNumberForm,
  CS_EditAddressSweeden,
} from "./address_forms/CS_EditAddressSweeden";
import { FloorplanFormElement } from "./form_elements/3d_floorplan_form_element";
import { CategoryFormElement } from "./form_elements/category_form_element";
import { CheckboxFormElement } from "./form_elements/checkbox_form_element";
import { DepositFormElement } from "./form_elements/deposit_form_element";
import { DescriptionFormElement } from "./form_elements/description_form_element";
import { EditAdPhoneNumberElement } from "./form_elements/edit_ad_phone_number_element";
import { FloorFormElement } from "./form_elements/floor_form_element";
import { ImagesFormElement } from "./form_elements/images_form_element";
import { MonthlyRentExtraCostsFormElement } from "./form_elements/monthly_rent_extra_costs_form_element";
import { MonthlyRentFormElement } from "./form_elements/monthly_rent_form_element";
import { MoveInDateFormElement } from "./form_elements/move_in_date_form_element";
import { PrepaidRentFormElement } from "./form_elements/prepaid_rent_form_element";
import { ReferenceIdFormElement } from "./form_elements/reference_id_form_element";
import { RentalPeriodFormElement } from "./form_elements/rental_period_form_element";
import { RoomsFormElement } from "./form_elements/rooms_form_element";
import { SizeFormElement } from "./form_elements/size_form_element";
import { TitleFormElement } from "./form_elements/title_form_element";

type AdDetailFormData = {
  formatted_address: string;
  title: string;
  description: string;
  floor: number | undefined;
  rental_period: number;
  rooms: number;
  size_m2: number;
  deposit: number | undefined;
  monthly_rent: number;
  monthly_rent_extra_costs: number | undefined;
  prepaid_rent: number | undefined;
  available_from: string | undefined;
  elevator: boolean;
  balcony: boolean;
  furnished: boolean;
  parking: boolean;
  pet_friendly: boolean;
  senior_friendly: boolean;
  student_only: boolean;
  shareable: boolean;
  washing_machine: boolean;
  dishwasher: boolean;
  electric_charging_station: boolean;
  dryer: boolean;
  digital_showing: boolean;
  social_housing: boolean;
  open_house: AdOpenHouse | null;
  images: Image[];
  category: string;
  adId: number;
  videoId: number | null;
  ad_phone_number: string | undefined;
  native_ad: boolean;
  locked_fields?: string[];
  hide_from_company_search: boolean;
  interactive_floor_plan_id: string;
  reference_id: string | null;
  address: RentalAddressValueObject;
  postal_code?: string;
  place_id: AutoSuggestedAddressUUID;
};

const EditableAdDetails = (props: {
  initialValues: AdDetailFormData;
  updater: FormElementUpdateTask;
  imageDeletionTask: AdImageDeletionTask;
  imageReorderTask: AdImageReorderTask;
  imageSetManualFloorPlanTask: AdImageSetManualFloorPlanTask;
  imageRemoveManualFloorPlanTask: AdImageRemoveManualFloorPlanTask;
  onAddressSaveSuccess: () => void;
  historicAdDetails: HistoricAdDetailItem | null;
  companySearchEnabled: boolean;
  social_housing: boolean;
  landLordUser: UserForCustomerService | null;
}) => {
  const {
    companySearchEnabled,
    historicAdDetails,
    imageDeletionTask,
    imageReorderTask,
    imageSetManualFloorPlanTask,
    imageRemoveManualFloorPlanTask,
    initialValues,
    social_housing,
    updater,
    landLordUser,
  } = props;
  const {
    address,
    native_ad,
    place_id,
    locked_fields,
    images,
    open_house,
    available_from,
  } = initialValues;
  const [showDialog, setShowDialog] = useState(false);

  const isCrawledAd = !native_ad;
  const isCustomAddress = place_id === "";
  const addressIsLockedValue = locked_fields?.includes("formatted_address");
  const addressWasManuallyCreated = place_id === "";

  const closeDialog = () => {
    setShowDialog(false);
  };

  const formMethods = useForm<AdDetailFormData>({
    mode: "onBlur",
  });

  const adImages: AdImageInterface[] = [];

  images.forEach((image) => {
    adImages.push({
      id: image.image_id!,
      url: getImageUrl({
        image,
      }),
      date_created: image.date_created || "",
      is_floor_plan: image.is_floor_plan ?? false,
    });
  });

  useLayoutEffect(() => {
    Object.keys(initialValues).forEach((key: keyof AdDetailFormData) => {
      formMethods.setValue(key, initialValues[key]);
    });
  }, [initialValues]);

  const autoAddressUpdateHandler = (autoSuggestedAddress: string) => {
    updater("address", autoSuggestedAddress);

    setShowDialog(false);
  };

  const manualAddressUpdateHandler = (manualAddress: ManualAddress) => {
    updater("address", manualAddress);
    setShowDialog(false);
  };

  const apartmentNumberUpdateHandler = (apartmentNumber: string) => {
    updater("address", {
      ...address,
      apartment_number: apartmentNumber,
    });
  };

  const enhancedFormattedAddress = createClientFormattedAddress(
    App.settings.market,
    address,
  );

  const debouncedAvailableDate = useDebounce(
    formMethods.watch("available_from"),
    1000,
  );

  useEffect(() => {
    if (
      debouncedAvailableDate !== undefined &&
      debouncedAvailableDate !== available_from
    ) {
      updater("available_from", debouncedAvailableDate);
    }
  }, [debouncedAvailableDate]);

  return (
    <FormProvider {...formMethods}>
      <Card sectioned>
        <CardSection title="Ad Details">
          <>
            <LockableFormField
              isLocked={isCrawledAd ? addressIsLockedValue : undefined}
              fieldname="formatted_address"
              fieldLabel="Address"
            >
              <TextField
                value={enhancedFormattedAddress}
                disabled={locked_fields?.includes("formatted_address")}
                onClick={() => setShowDialog(true)}
                readOnly
              />
            </LockableFormField>

            <Box
              mt={-2}
              mb={2}
            >
              <ExternalAddressWebsites
                selectedAddress={enhancedFormattedAddress}
              />
            </Box>
            {isCustomAddress && (
              <Notice type="warning">
                Rentable with <strong>custom address</strong>
              </Notice>
            )}
          </>

          {App.settings.market === Market.BOSTADSPORTAL_SE && (
            <Box>
              <CS_ApartmentNumberForm
                initialApartmentNumber={address.apartment_number}
                onApartmentNumberUpdate={apartmentNumberUpdateHandler}
              />
            </Box>
          )}

          {showDialog && (
            <Dialog
              slideOver
              open
              onClose={closeDialog}
            >
              <DialogHeader>Edit address</DialogHeader>
              {App.settings.market === Market.BOLIGPORTAL_DK && (
                <CS_EditAddressDenmark
                  onAutoAddressUpdate={autoAddressUpdateHandler}
                  onManualAddressUpdate={manualAddressUpdateHandler}
                  startInManualAddressForm={addressWasManuallyCreated}
                  formatted_address={enhancedFormattedAddress}
                  address={transformDataToDanishManualAddress(address)}
                />
              )}
              {App.settings.market === Market.BOSTADSPORTAL_SE && (
                <CS_EditAddressSweeden
                  onAutoAddressUpdate={autoAddressUpdateHandler}
                  onManualAddressUpdate={manualAddressUpdateHandler}
                  formatted_address={enhancedFormattedAddress}
                  startInManualAddressForm={addressWasManuallyCreated}
                  address={{
                    address: {
                      postal_code: address.postal_code ?? "",
                      postal_town: address.postal_town ?? "",
                      street_name: address.street_name ?? "",
                      street_number: address.street_number ?? "",
                    },
                  }}
                />
              )}
            </Dialog>
          )}
          <Flex
            mt={1}
            gap
          >
            <FlexColumn size={6}>
              <CategoryFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="category"
                fieldLabel={t("create.field.category.heading")}
                previousValue={historicAdDetails?.category?.toString()}
                defaultValue={initialValues.category?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("category")}
              />

              <RoomsFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="rooms"
                fieldLabel="Rooms"
                previousValue={historicAdDetails?.rooms?.toString()}
                defaultValue={initialValues.rooms?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("rooms")}
              />

              <MonthlyRentFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="monthly_rent"
                fieldLabel="Monthly rent"
                previousValue={historicAdDetails?.monthly_rent?.toString()}
                defaultValue={initialValues.monthly_rent?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("monthly_rent")}
              />

              <DepositFormElement<AdDetailFormData>
                updateTask={(fieldname, value) =>
                  updater(fieldname, value === "" ? null : value)
                }
                fieldname="deposit"
                fieldLabel={social_housing ? "Housing Deposit" : "Deposit"}
                previousValue={historicAdDetails?.deposit?.toString()}
                defaultValue={initialValues.deposit?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("deposit")}
              />

              <MoveInDateFormElement<AdDetailFormData>
                updateTask={(fieldname, value) =>
                  updater(fieldname, value === "" ? null : value)
                }
                fieldname="available_from"
                fieldLabel="Available from"
                previousValue={historicAdDetails?.available_from?.toString()}
                defaultValue={initialValues.available_from?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("available_from")}
              />

              <ReferenceIdFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="reference_id"
                fieldLabel="Internal ID"
                previousValue={historicAdDetails?.reference_id?.toString()}
                defaultValue={initialValues.reference_id?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("reference_id")}
              />
            </FlexColumn>
            <FlexColumn size={6}>
              {!isBoligPortal() && (
                <FloorFormElement<AdDetailFormData>
                  updateTask={updater}
                  fieldname="floor"
                  fieldLabel="Floor"
                  isCrawledAd={isCrawledAd}
                  isLocked={locked_fields?.includes("floor")}
                />
              )}

              <SizeFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="size_m2"
                fieldLabel="Size"
                previousValue={historicAdDetails?.size_m2?.toString()}
                defaultValue={initialValues.size_m2?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("size_m2")}
              />

              <MonthlyRentExtraCostsFormElement<AdDetailFormData>
                updateTask={(fieldname, value) =>
                  updater(fieldname, value === "" ? null : value)
                }
                fieldname="monthly_rent_extra_costs"
                fieldLabel="Utilities"
                previousValue={historicAdDetails?.monthly_rent_extra_costs?.toString()}
                defaultValue={initialValues.monthly_rent_extra_costs?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("monthly_rent_extra_costs")}
              />

              {App.settings.market === Market.BOLIGPORTAL_DK && (
                <PrepaidRentFormElement<AdDetailFormData>
                  updateTask={(fieldname, value) =>
                    updater(fieldname, value === "" ? null : value)
                  }
                  fieldname="prepaid_rent"
                  fieldLabel="Prepaid rent"
                  previousValue={historicAdDetails?.prepaid_rent?.toString()}
                  defaultValue={initialValues.prepaid_rent?.toString()}
                  isCrawledAd={isCrawledAd}
                  isLocked={locked_fields?.includes("prepaid_rent")}
                />
              )}

              <RentalPeriodFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="rental_period"
                fieldLabel="Rental period"
                previousValue={historicAdDetails?.rental_period?.toString()}
                defaultValue={initialValues.rental_period?.toString()}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("rental_period")}
              />
            </FlexColumn>
          </Flex>

          <TitleFormElement<AdDetailFormData>
            updateTask={updater}
            fieldname="title"
            fieldLabel="Title"
            previousValue={historicAdDetails?.title?.toString()}
            defaultValue={initialValues.title?.toString()}
            isCrawledAd={isCrawledAd}
            isLocked={locked_fields?.includes("title")}
          />
          <DescriptionFormElement<AdDetailFormData>
            updateTask={updater}
            fieldname="description"
            fieldLabel="Description"
            previousValue={historicAdDetails?.description?.toString()}
            defaultValue={initialValues.description?.toString()}
            isCrawledAd={isCrawledAd}
            isLocked={locked_fields?.includes("description")}
          />
        </CardSection>
      </Card>

      <Card sectioned>
        <CardSection title="Other features">
          <Flex column>
            <Flex gap>
              <FlexColumn size={4}>
                <Flex column>
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="elevator"
                    fieldLabel="Elevator"
                    defaultValue={initialValues.elevator}
                    previousValue={historicAdDetails?.features.elevator}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("elevator")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="balcony"
                    fieldLabel="Balcony"
                    defaultValue={initialValues.balcony}
                    previousValue={historicAdDetails?.features.balcony}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("balcony")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="furnished"
                    fieldLabel="Furnished"
                    defaultValue={initialValues.furnished}
                    previousValue={historicAdDetails?.features.furnished}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("furnished")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="electric_charging_station"
                    fieldLabel="Electric charging station"
                    defaultValue={initialValues.electric_charging_station}
                    previousValue={
                      historicAdDetails?.features.electric_charging_station
                    }
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes(
                      "electric_charging_station",
                    )}
                  />
                </Flex>
              </FlexColumn>
              <FlexColumn size={4}>
                <Flex column>
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="parking"
                    fieldLabel="Parking"
                    defaultValue={initialValues.parking}
                    previousValue={historicAdDetails?.features.parking}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("parking")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="pet_friendly"
                    fieldLabel="Pet friendly"
                    defaultValue={initialValues.pet_friendly}
                    previousValue={historicAdDetails?.features.pet_friendly}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("pet_friendly")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="senior_friendly"
                    fieldLabel="Senior friendly"
                    defaultValue={initialValues.senior_friendly}
                    previousValue={historicAdDetails?.features.senior_friendly}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("senior_friendly")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="dishwasher"
                    fieldLabel="Dishwasher"
                    defaultValue={initialValues.dishwasher}
                    previousValue={historicAdDetails?.features.dishwasher}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("dishwasher")}
                  />
                </Flex>
              </FlexColumn>
              <FlexColumn size={4}>
                <Flex column>
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="student_only"
                    fieldLabel="Student only"
                    defaultValue={initialValues.student_only}
                    previousValue={historicAdDetails?.features.student_only}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("student_only")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="shareable"
                    fieldLabel="Shareable"
                    defaultValue={initialValues.shareable}
                    previousValue={historicAdDetails?.features.shareable}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("shareable")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="dryer"
                    fieldLabel="Dryer"
                    defaultValue={initialValues.dryer}
                    previousValue={historicAdDetails?.features.dryer}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("dryer")}
                  />
                  <CheckboxFormElement<AdDetailFormData>
                    updateTask={updater}
                    fieldname="washing_machine"
                    fieldLabel="Washing Machine"
                    defaultValue={initialValues.washing_machine}
                    previousValue={historicAdDetails?.features.washing_machine}
                    isCrawledAd={isCrawledAd}
                    isLocked={locked_fields?.includes("washing_machine")}
                  />
                </Flex>
              </FlexColumn>
            </Flex>
          </Flex>
        </CardSection>
        {(landLordUser?.social_housing || social_housing) && (
          <CardSection title={"Social Housing"}>
            <Flex column>
              <CheckboxFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="social_housing"
                fieldLabel="Social Housing"
                defaultValue={initialValues.social_housing}
                previousValue={historicAdDetails?.social_housing}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("social_housing")}
              />
            </Flex>
          </CardSection>
        )}
        <CardSection title={"Open House"}>
          <Flex column>
            {open_house && (
              <Box>
                <Box>
                  <Text weight="bold">Date: </Text>{" "}
                  {format(new Date(open_house.event_date), "MMM do yyyy")}
                </Box>
                <Box>
                  <Text weight="bold">Start time: </Text>{" "}
                  {open_house.start_time}
                </Box>
                <Box>
                  <Text weight="bold">End time: </Text>
                  {open_house.end_time}
                </Box>
              </Box>
            )}
            {!open_house && "No open house"}
          </Flex>
        </CardSection>
        <CardSection title={"Showing"}>
          <FlexColumn size={4}>
            <Flex column>
              <CheckboxFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="digital_showing"
                fieldLabel="Digital showing"
                defaultValue={initialValues.digital_showing}
                previousValue={historicAdDetails?.digital_showing}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("digital_showing")}
              />
              <EditAdPhoneNumberElement<AdDetailFormData>
                updateTask={(fieldname, value) =>
                  updater(fieldname, value === "" ? null : value)
                }
                fieldLabel="Phone call"
                fieldname="ad_phone_number"
                defaultValue={initialValues.ad_phone_number}
                previousValue={historicAdDetails?.ad_phone_number}
                isCrawledAd={isCrawledAd}
                isLocked={locked_fields?.includes("ad_phone_number")}
              />
              <FloorplanFormElement<AdDetailFormData>
                updateTask={updater}
                fieldname="interactive_floor_plan_id"
                fieldLabel="3D floor plan"
                defaultValue={initialValues.interactive_floor_plan_id}
                previousValue={historicAdDetails?.interactive_floor_plan_id}
              />
            </Flex>
          </FlexColumn>
        </CardSection>
        {companySearchEnabled && (
          <CardSection title={"Company Page/iframe/embedded search"}>
            <FlexColumn size={9}>
              <Flex column>
                <CheckboxFormElement<AdDetailFormData>
                  updateTask={updater}
                  fieldname="hide_from_company_search"
                  fieldLabel="Exclude from iframe, embedded search and Company Page"
                  defaultValue={initialValues.hide_from_company_search}
                  previousValue={historicAdDetails?.hide_from_company_search}
                />
              </Flex>
            </FlexColumn>
          </CardSection>
        )}
      </Card>
      <Card sectioned>
        <CardSection title="Ad Pictures">
          <ImagesFormElement
            imageDeletionTask={imageDeletionTask}
            imageReorderTask={imageReorderTask}
            imageSetManualFloorPlanTask={imageSetManualFloorPlanTask}
            imageRemoveManualFloorPlanTask={imageRemoveManualFloorPlanTask}
            historicDetailsDate={historicAdDetails?.created}
            images={adImages}
          />
          <Box mt={2}>
            <Text size="tiny">
              <em>
                Note: you can reorder the image by dragging them to a new
                position. The picture at the first position is the Ads primary
                picure.
              </em>
            </Text>
          </Box>
        </CardSection>
      </Card>
    </FormProvider>
  );
};

export { EditableAdDetails };

const ExternalAddressWebsites = ({
  selectedAddress,
}: {
  selectedAddress: string;
}) => {
  const { externalAddressWebsites } = useExternalAddressWebsites();

  return (
    <Flex align="center">
      <Text
        block
        color="muted"
        size="tiny"
        mr={1}
      >
        Lookup address in:
      </Text>
      <Flex gap={1}>
        {externalAddressWebsites.map((item) => (
          <SimpleTextLink
            key={item.name}
            url={item.urlResolver(selectedAddress)}
          >
            {item.name}
          </SimpleTextLink>
        ))}
      </Flex>
    </Flex>
  );
};

const transformDataToDanishManualAddress = (
  address: RentalAddressValueObject,
): AddressManualFormDenmark => {
  let doorValue = address.door ?? "";
  let sideValue = address.side ?? "";

  if (isSide(doorValue)) {
    sideValue = doorValue;
    doorValue = "";
  }

  return {
    address: {
      door: doorValue,
      floor: address.floor?.toString() ?? "0",
      postal_code: address.postal_code ?? "",
      postal_town: address.postal_town ?? "",
      side: sideValue,
      street_name: address.street_name ?? "",
      street_number: address.street_number ?? "",
    },
  };
};
