import { t } from "lib/i18n";
import vest, { enforce, test } from "vest";
import { PartialPaymentForm } from "./AddTenantPaymentDialog";

export const partialPaymentValidationSuite = vest.create(
  (data: PartialPaymentForm) => {
    data.partial_payments.forEach((payment, index) => {
      const fieldName = `partial_payments.${index}`;
      test(
        `${fieldName}.amount`,
        t("partial_payments.amount.field_error.message"),
        () => {
          enforce(payment.amount).isNotNull();
        },
      );
      test(
        `${fieldName}.accounting_date`,
        t("partial_payments.accounting_date.field_error.message"),
        () => {
          enforce(payment.accounting_date).isNotEmpty();
        },
      );
    });
  },
);
