import { useQuery } from "react-query";
import {
  getNewBuildDetails,
  getNewBuilds,
  getNewBuildToolbar,
} from "./customerServiceApi";

export const customerServiceNewBuildKeys = {
  all: () => ["customer-service-new-build"] as const,
  newbuilds: (userId: number) =>
    [...customerServiceNewBuildKeys.all(), "newbuilds", userId] as const,
  newbuild: (newbuildId: number) =>
    [...customerServiceNewBuildKeys.all(), "newbuild", newbuildId] as const,
  newbuildToolbar: (newbuildId: number) =>
    [
      ...customerServiceNewBuildKeys.all(),
      "newbuild-toolbar",
      newbuildId,
    ] as const,
};

export const useQueryNewBuilds = (userId: number) => {
  return useQuery(customerServiceNewBuildKeys.newbuilds(userId), async () =>
    getNewBuilds(userId),
  );
};

export const useQueryNewBuildDetails = (newbuildId: number) => {
  return useQuery(customerServiceNewBuildKeys.newbuild(newbuildId), async () =>
    getNewBuildDetails(newbuildId),
  );
};

export const useQueryNewBuildToolbar = (newbuildId: number) => {
  return useQuery(
    customerServiceNewBuildKeys.newbuildToolbar(newbuildId),
    async () => getNewBuildToolbar(newbuildId),
  );
};
