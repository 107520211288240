import { SigningOrder } from "apps/contracts/components/common/interfaces";
import { AllPartners } from "apps/tenant_hub/pages/tenant_hub_services_page/LeadsSections";
import { Ad } from "components/interfaces/ad";
import { Flow as LandlordOnboardingFlow } from "features/landlord_onboarding/useLandlordOnboardingFeature";
import { UpsellDialogTrigger } from "features/upsell/components/RentableUpsellDialog/rentableDialogTrigger";
import { HedvigLeadOnAdDetailPagePlacement } from "leads/Hedvig/HedvigLeadOnAdDetailPage";
import { awaitTimeout } from "lib/timeout";
import { Tracking, EventParameters } from "lib/tracking/common";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { ProductTracking } from "lib/tracking/products";

export const ADDITIONALLY_TIME_FOR_TRACKING = 500;

export enum RentableListActionTracking {
  contracts = "clicks create contract",
  moving_reports = "clicks create moving report",
  advertise_rentable = "clicks create ad",
}

export enum FavouriteAdButtonPlacementTracking {
  srp = "SRP",
  ad_details = "Ad Details",
  home_page = "Homepage",
  similar_ads_ad_details = "Similar ads Ad Details",
  my_favourite_ads = "Favourites",
  company_page = "Company Page",
  company_ads_list = "Company Ad List",
  srp_map_view = "Map",
}

export enum EventCategory {
  leads = "leads",
  agent = "agent",
  contact = "contact",
  contact_flow = "Contact flow",
  ecommerce = "ecommerce",
  product_list_tracking = "product list tracking",
  users = "users",
  create_ad = "create ad",
  home_page = "home page",
  rent_out_landingpage = "rent out landingpage",
  rent_out_room_landingpage = "rent out room landingpage",
  search_result_page = "search result page",
  messaging = "messaging",
  profile_modals_after_message = "profile modals after message",
  find_tenant = "find tenant",
  rentable_detail = "rentable detail",
  ad_detail_page = "detail page",
  settings = "settings",
  moving_reports = "moving reports",
  mark_rented_out_choose_tenant = "mark as rented out and select tenant",
  landlord_sidebar_pages = "landlord sidebar pages",
  contracts = "contracts",
  upsell_page = "upsell_page",
  nudge_upsell = "nudge upsell",
  content_deletion = "content_deletion",
  all_rentables_list = "all rentables list",
  list_of_advertised_rentables_page = "list of advertised rentables page",
  rent_out_sublease_landingpage = "rent out sublease landingpage",
  nudge_rent_collection = "nudge rent collection",
  favourites = "favourites",
  header_navigation = "header navigation",
  favourites_page = "favourites page",
  rental_data = "rental_data",
  tenant_hub = "Tenant Hub",
  subscription = "subscription",
  rentable_edit_page = "rentable edit page",
  benefits_page = "Benefits Page",
}

export enum HeaderAction {
  PRODUCTS = "products",
  CREATE = "create",
  BOLIGADMINISTRATION = "boligadministration",
  RENT_OUT = "rent out",
  CONTRACTS = "contracts",
  MOVING_REPORTS = "moving reports",
  RENTAL_COLLECTION = "rental collection",
  RENTAL_DATA = "rental data",
  ADVERTISE_RENTABLE = "advertise rentable",
  CREATE_RENTABLE = "create rentable",
  CREATE_CONTRACT = "create contract",
  CREATE_MOVING_REPORT = "create moving report",
  CREATE_RENT = "create rent",
  CREATE_TENANCY = "create tenancy",
  ADVERTISED_RENTABLES = "advertised rentables",
  INBOX = "inbox",
  FAVOURITES = "favourites",
}

/**
 * From July 1, 2023 Google will no longer support Universal Analytics.
 * These events will still be tracked in the UA implementation.
 * For the GA4 implementation they will be mapped over in GTM.
 */
export class TrackingEvent {
  public static clickHomePageRentOutFrontPageBlueTopBanner() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "blue top banner",
      action: "Click rent out CTA blue top banner",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "blue top banner",
      name: "click create ad",
      value: "1",
    });
  }

  public static clickHomePageContractFrontPageBlueTopBanner() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "blue top banner",
      action: "Click contract CTA blue top banner",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "blue top banner",
      name: "click create contract",
      value: "1",
    });
  }

  public static clickHomePageMovingInReportFrontPageBlueTopBanner() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "blue top banner",
      action: "Click moving-in report CTA blue top banner",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "blue top banner",
      name: "click create MR",
      value: "1",
    });
  }

  public static clickHomePageRentCollectionFrontPageBlueTopBanner() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "blue top banner",
      action: "Click rent collection CTA blue top banner",
      isInteraction: true,
    });
  }

  public static clickHomePageRentOutFrontPageOrangeBottomBanner() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "",
      action: "Click rent out CTA orange bottom banner",
      isInteraction: true,
    });
  }

  public static clickHomePageSeeAllFavorites() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "favourites",
      action: "Click see more",
      isInteraction: false,
    });
  }

  public static clickSearchMapSweden() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "",
      action: "Click search on map",
      isInteraction: false,
    });
  }

  public static clickViewMapResults() {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: "",
      action: "click view result on map",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "click view result on map",
      name: "map",
      value: "1",
    });
  }

  public static landlordSentMessage() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "landlord",
      action: "message sent",
      isInteraction: false,
    });
  }

  public static seekerSentMessage() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "seeker",
      action: "message sent",
      isInteraction: false,
    });
  }

  public static seekerSentMessageFromDetailPage() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "seeker from detailpage",
      action: "message sent",
      isInteraction: false,
    });
  }

  public static clickCreateAdSubmit() {
    Tracking.trackEvent({
      category: EventCategory.create_ad,
      label: "",
      action: "click submit",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.create_ad,
      action: "click submit ad",
      name: "ad creation",
      value: "1",
    });
  }

  public static createAdWithInfoFromAnotherAdSuccess(ad?: Ad) {
    const label = ad
      ? `${ProductTracking.getProductBrand(ad)} - ID ${ad.id}`
      : "";
    Tracking.trackEvent({
      category: EventCategory.create_ad,
      label: label,
      action:
        "ad successfully created - copied information from other renatble",
      isInteraction: true,
    });
    FacebookTrackingEvent.trackEvent("ListingCreated");
  }

  public static createAdSuccess(ad?: Ad) {
    const label = ad
      ? `${ProductTracking.getProductBrand(ad)} - ID ${ad.id}`
      : "";
    Tracking.trackEvent({
      category: EventCategory.create_ad,
      label: label,
      action: "ad successfully created",
      isInteraction: true,
    });

    FacebookTrackingEvent.trackEvent("ListingCreated");

    Tracking.trackMatomo({
      category: EventCategory.create_ad,
      action: "ad successfully created",
      name: "ad creation",
      value: "1",
    });
  }

  public static createAdWithRentalContractSuccess(ad: Ad) {
    const label = ad
      ? `${ProductTracking.getProductBrand(ad)} - ID ${ad.id}`
      : "";
    Tracking.trackEvent({
      category: EventCategory.create_ad,
      label: label,
      action:
        "Ad was succesfully created - with rental contract checkmark checked",
      isInteraction: true,
    });
  }

  public static clickCreateAgent(
    label: "srp_empty_state" | "map" | "ad_details",
  ) {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label,
      action: "click create agent",
      isInteraction: true,
    });

    const eventMapping = {
      srp_empty_state: {
        category: EventCategory.search_result_page,
        action: "click create agent",
      },
      map: {
        category: EventCategory.search_result_page,
        action: "click create agent (map)",
      },
      ad_details: {
        category: EventCategory.ad_detail_page,
        action: "click create agent",
      },
    };

    Tracking.trackMatomo({
      category: eventMapping[label].category,
      action: eventMapping[label].action,
      name: "agent",
      value: "1",
    });
  }

  public static clickRemoveAgent(
    label: "map" | "srp_empty_state" | "ad_details",
  ) {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label,
      action: "click remove agent",
      isInteraction: true,
    });

    const eventMapping = {
      srp_empty_state: {
        category: EventCategory.search_result_page,
        action: "click remove agent",
      },
      map: {
        category: EventCategory.search_result_page,
        action: "click remove agent (map)",
      },
      ad_details: {
        category: EventCategory.ad_detail_page,
        action: "click remove agent",
      },
    };

    Tracking.trackMatomo({
      category: eventMapping[label].category,
      action: eventMapping[label].action,
      name: "agent",
      value: "1",
    });
  }

  public static navigateFromAgentLandingPageToSRP() {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label: "agent landing page",
      action: "navigation from landing page to srp",
      isInteraction: true,
    });
  }

  public static clickContactButton() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "",
      action: "click contact button on detail",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail",
      name: "contact",
      value: "1",
    });
  }

  public static clickContactButtonDigitalShowing() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "digital showing",
      action: "click contact button on detail",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail (digital showing)",
      name: "contact",
      value: "1",
    });
  }

  public static clickContactButtonOpenHouse() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "open house",
      action: "click contact button on detail",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail (open house)",
      name: "contact",
      value: "1",
    });
  }

  public static viewFBPDialog() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "FBP Dialog",
      action: "viewing FBP dialog",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "viewing FBP dialog",
      name: "FBP Dialog",
      value: "1",
    });
  }

  public static closeFBPDialog() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "FBP Dialog",
      action: "closing FBP dialog",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "closing FBP dialog",
      name: "FBP Dialog",
      value: "1",
    });
  }

  public static createdOpenHouse() {
    Tracking.trackEvent({
      category: EventCategory.rentable_detail,
      label: "create open house",
      action: "open house",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "clicks create open house",
      name: "open house",
      value: "1",
    });
  }

  public static editedOpenHouse() {
    Tracking.trackEvent({
      category: EventCategory.rentable_detail,
      label: "edit open house",
      action: "open house",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "clicks edit open house",
      name: "open house",
      value: "1",
    });
  }

  public static deletedOpenHouse() {
    Tracking.trackEvent({
      category: EventCategory.rentable_detail,
      label: "delete open house",
      action: "open house",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "clicks delete open house",
      name: "open house",
      value: "1",
    });
  }

  public static clickSeeLocationButton() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "",
      action: "click see location button on detail",
      isInteraction: true,
    });
  }

  public static clickCancelSubscription() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "",
      action: "click cancel subscription",
      isInteraction: true,
    });
  }

  public static clickConfirmCancelSubscription() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "",
      action: "click confirm cancel subscription",
      isInteraction: true,
    });
  }

  public static clickReactivateSubscription() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "",
      action: "click reactivate subscription",
      isInteraction: true,
    });
  }

  public static paymentSuccessfullyCompleted(productName: string) {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: productName,
      action: "payment successfully completed",
      isInteraction: true,
    });
  }

  public static clickCancelUpsell(productName: string) {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      action: "click cancel upsell",
      label: productName,
      isInteraction: true,
    });
  }

  public static clickConfirmCancelUpsell(productName: string) {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      action: "click confirm cancel upsell",
      label: productName,
      isInteraction: true,
    });
  }

  public static agentCreatedFromAgentPopup() {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label: "srp popup",
      action: "agent created",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "click create agent (pop up)",
      name: "agent",
      value: "1",
    });
  }

  public static agentCreated(label: "map" | "srp_empty_state") {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label,
      action: "agent created",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "agent created",
      name: "agent",
      value: "1",
    });
  }

  public static agentRemoved(label: "map" | "srp_empty_state") {
    Tracking.trackEvent({
      category: EventCategory.agent,
      label,
      action: "agent removed",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "agent removed",
      name: "agent",
      value: "1",
    });
  }

  public static newUserCreated(type: "facebook" | "email") {
    Tracking.trackEvent({
      category: EventCategory.users,
      label: type,
      action: "new user created",
      isInteraction: true,
    });
  }

  // This is ignored in the GA4 GTM Mapping
  public static userLoggedIn(type: "facebook" | "email") {
    Tracking.trackEvent({
      category: EventCategory.users,
      label: type,
      action: "user logged in",
      isInteraction: true,
    });
  }

  public static clickHomePagePopularCity(cityName: string) {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: cityName,
      action: "click popular city",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "click popular city",
      name: cityName,
      value: "1",
    });
  }

  public static clickCreateAdRentOutLandingPage(
    position: "top" | "middle" | "blue banner bottom",
  ) {
    Tracking.trackEvent({
      category: EventCategory.rent_out_landingpage,
      label: position,
      action: "click cta",
      isInteraction: true,
    });
  }

  public static clickContactUsRentOutLandingPage() {
    Tracking.trackEvent({
      category: EventCategory.rent_out_landingpage,
      label: "",
      action: "click contact us",
      isInteraction: true,
    });
  }

  public static clickCreateAdRentOutRoomLandingPage(
    position: "top" | "middle" | "blue banner bottom",
  ) {
    Tracking.trackEvent({
      category: EventCategory.rent_out_room_landingpage,
      label: position,
      action: "click cta",
      isInteraction: true,
    });
  }

  public static clickContactUsRentOutRoomLandingPage() {
    Tracking.trackEvent({
      category: EventCategory.rent_out_room_landingpage,
      label: "",
      action: "click contact us",
      isInteraction: true,
    });
  }

  public static srpCityLink() {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: "",
      action: "click previous level",
      isInteraction: true,
    });
  }

  public static trackDetailViewInAdWordsForRetargeting(ad: Ad) {
    if (window.ADWORDS_CONVERSION_ID) {
      Tracking.fireRemarketingTag({
        listing_id: ad.id,
        listing_totalvalue: ad.monthly_rent,
      });
    }
  }

  public static userCreatedAnAgentInProfileFlow() {
    Tracking.trackEvent({
      category: EventCategory.profile_modals_after_message,
      label: "",
      action: "agent created",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.contact,
      action: "clicks create agent",
      name: "agent",
      value: "1",
    });
  }

  public static filterClickedColors(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked colors",
      isInteraction: true,
    });
  }

  public static filterClickedProfileType(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked profile type",
      isInteraction: true,
    });
  }

  public static filterClickedAge(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked age",
      isInteraction: true,
    });
  }

  public static filterClickedOccupation(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked occupation",
      isInteraction: true,
    });
  }

  public static filterClickedPets(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked pets",
      isInteraction: true,
    });
  }

  public static filterClickedSmoking(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked smoking",
      isInteraction: true,
    });
  }

  public static filterClickedSharing(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked sharing",
      isInteraction: true,
    });
  }

  public static filterClickedKids(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked kids",
      isInteraction: true,
    });
  }

  public static filterClickedExpat(category: EventCategory) {
    Tracking.trackEvent({
      category: category,
      label: "",
      action: "filter clicked expat",
      isInteraction: true,
    });
  }

  public static profilesSearchboxClicked() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "",
      action: "profiles searchbox clicked",
      isInteraction: true,
    });
  }

  public static clickedLoadMorePromotedAds() {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: "",
      action: "load more promoted ads",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "load more promoted ads",
      name: "",
      value: "1",
    });
  }

  public static filterClickedSize(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked size",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked size",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedCategory(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked property type",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked property type",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedRooms(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked number of rooms",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked number of rooms",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedRentalPeriod(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked rental period",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked rental period",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedDate(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked move in date",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked move in date",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedFeature(view: "list-view" | "maps-view") {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: view,
      action: "filter clicked feature",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked feature",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedBackToListFromMap() {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: "",
      action: "click view result on list",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "click view result on list",
      name: "map",
      value: "1",
    });
  }

  public static srpClickedDrillDown() {
    Tracking.trackEvent({
      category: EventCategory.search_result_page,
      label: "",
      action: "drill down button clicked",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "drill down button clicked",
      name: "filter",
      value: "1",
    });
  }

  public static filterClickedRent() {
    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked rent",
      name: "filter",
      value: "1",
    });
  }

  public static clickBreadcrumbsPreviousLevel() {
    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "click previous level",
      name: "",
      value: "1",
    });
  }

  public static filterClickedSorting() {
    Tracking.trackMatomo({
      category: EventCategory.search_result_page,
      action: "filter clicked sorting",
      name: "filter",
      value: "1",
    });
  }

  public static findTenantSortByLastOnline() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "sort by last online",
      isInteraction: true,
    });
  }

  public static findTenantFilterButtonClicked() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "filter button clicked",
      isInteraction: true,
    });
  }

  public static findTenantRemoveAllFiltersClicked() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "remove all filters clicked",
      isInteraction: true,
    });
  }

  public static findTenantSeeProfileButtonClicked() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "see profile button clicked",
      isInteraction: true,
    });
  }

  public static findTenantContactButtonClicked() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "search-result / profile-modal",
      action: "contact button clicked",
      isInteraction: true,
    });
  }

  public static findTenantGoToConversationClicked() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "go to conversation clicked",
      isInteraction: true,
    });
  }

  public static findTenantSendMessage() {
    Tracking.trackEvent({
      category: EventCategory.find_tenant,
      label: "",
      action: "send message",
      isInteraction: true,
    });
  }

  public static standardMessageSendClicked() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "conversation view",
      action: "send standard message",
      isInteraction: true,
    });
  }

  public static standardMessageMultiSendClicked() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "conversation list multi select",
      action: "send standard message",
      isInteraction: true,
    });
  }

  public static standardMessageCreateClicked() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "message list",
      action: "create standard message",
      isInteraction: true,
    });
  }

  public static standardMessageCreated() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "create form",
      action: "create standard message",
      isInteraction: true,
    });
  }

  public static standardMessageUsed() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "",
      action: "selected standard message",
      isInteraction: true,
    });
  }

  public static multiMessageSent() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "landlord - multiselect",
      action: "message sent",
      isInteraction: true,
    });
  }

  public static undoInboxSelection() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "",
      action: "cancel multi messaage",
      isInteraction: true,
    });
  }

  public static inboxWriteMessageClicked() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "",
      action: "press write message for multi tenants",
      isInteraction: true,
    });
  }

  public static inboxCheckConversation() {
    Tracking.trackEvent({
      category: EventCategory.messaging,
      label: "",
      action: "selects conversation checkmark",
      isInteraction: true,
    });
  }

  public static clickedLoanLink() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      label: "",
      action: "click loan link",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click loan link",
      name: "leads",
      value: "1",
    });
  }
  public static clickedLoanTopLink() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      label: "",
      action: "click top loan link",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click top loan link",
      name: "leads",
      value: "1",
    });
  }

  public static userChangedPassword() {
    Tracking.trackEvent({
      category: EventCategory.settings,
      label: "password changed",
      action: "change password",
      isInteraction: true,
    });
  }

  public static userClickedCreateAgentOnCancelReason() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "choose cancelation reason",
      action: "link to boligagent landing page",
      isInteraction: true,
    });
  }

  public static userClickedCreateAgentOnWinbackActivated() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "winback activated",
      action: "link to boligagent landing page",
      isInteraction: true,
    });
  }

  public static userClickedCreateAgentOnSubscriptionCanceled() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      label: "subscription canceled",
      action: "link to agents overview",
      isInteraction: true,
    });
  }

  public static markedRentedOutWithTenant() {
    Tracking.trackEvent({
      category: EventCategory.mark_rented_out_choose_tenant,
      label: "chosen tenant",
      action: "marked as rented out",
      isInteraction: true,
    });
  }

  public static markedRentedOutWithoutTenant() {
    Tracking.trackEvent({
      category: EventCategory.mark_rented_out_choose_tenant,
      label: "no tenant chosen",
      action: "marked as rented out",
      isInteraction: true,
    });
  }

  public static markedRentedOutWithRejectionMessage() {
    Tracking.trackEvent({
      category: EventCategory.mark_rented_out_choose_tenant,
      label: "sends rejection message",
      action: "marked as rented out",
      isInteraction: true,
    });
  }

  public static markedRentedOutWithoutRejectionMessage() {
    Tracking.trackEvent({
      category: EventCategory.mark_rented_out_choose_tenant,
      label: "no rejection message",
      action: "marked as rented out",
      isInteraction: true,
    });
  }

  public static clickedFloorPlan() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      label: "",
      action: "click floor-plan button",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click floor-plan button",
      name: "",
      value: "1",
    });
  }

  public static clicked3DFloorPlan() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      label: "",
      action: "click 3D button",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click 3D button",
      name: "",
      value: "1",
    });
  }

  public static finalizedMovingReport() {
    Tracking.trackEvent({
      category: EventCategory.moving_reports,
      label: "",
      action: "report successfully finalized",
      isInteraction: true,
    });
  }

  public static sidebarTopbannerView(label: string) {
    Tracking.trackEvent({
      category: EventCategory.landlord_sidebar_pages,
      label: label,
      action: "views pop up",
      isInteraction: false,
    });
  }

  public static sidebarTopbannerCTAClick(label: string) {
    Tracking.trackEvent({
      category: EventCategory.landlord_sidebar_pages,
      label: label,
      action: "clicks learn more",
      isInteraction: false,
    });
  }

  public static contractNoteToTenantAdded() {
    Tracking.trackEvent({
      category: EventCategory.contracts,
      label: "text input field",
      action: "finishes contract flow with add text to tenant",
      isInteraction: false,
    });
  }

  public static copyRecipients(recipients: number) {
    Tracking.trackEvent({
      category: EventCategory.contracts,
      label: "e-mail input",
      action: "finishes contract flow with copy recipient",
      value: recipients.toString(),
      isInteraction: false,
    });
  }

  public static signingOrder(order: SigningOrder) {
    Tracking.trackEvent({
      category: EventCategory.contracts,
      label: "radio button",
      action: "finishes contract with radio button active",
      value:
        order === SigningOrder.NO_ORDER ? "no order" : "landlord signs last",
      isInteraction: false,
    });
  }

  public static showUpsellDialog(trigger: UpsellDialogTrigger) {
    Tracking.trackEvent({
      category: EventCategory.nudge_upsell,
      action: "show nudge upsell dialog",
      label: trigger,
      isInteraction: false,
    });
  }

  public static upsellDialogPaymentSuccess(trigger: UpsellDialogTrigger) {
    Tracking.trackEvent({
      category: EventCategory.nudge_upsell,
      action: "upsell purchased successfully",
      label: trigger,
      isInteraction: false,
    });

    if (trigger === "rentable save changes") {
      Tracking.trackMatomo({
        category: EventCategory.rentable_edit_page,
        action: "save changes",
        name: "Upsell purchase",
        value: "1",
      });
    }

    if (trigger === "rentable banner") {
      Tracking.trackMatomo({
        category: EventCategory.rentable_edit_page,
        action: "upsell banner",
        name: "Upsell purchase",
        value: "1",
      });
    }

    if (trigger === "statistics email" || trigger === "list of rentable link") {
      Tracking.trackMatomo({
        category: EventCategory.list_of_advertised_rentables_page,
        action: "promote link",
        name: "Upsell purchase",
        value: "1",
      });
    }

    if (trigger === "exposure plus rentables list") {
      Tracking.trackEvent({
        category: EventCategory.nudge_upsell,
        label: "advertised rentables list",
        action: "Exposure+ purchased succesfully",
        isInteraction: false,
      });
    }

    if (trigger === "exposure plus ad details") {
      Tracking.trackEvent({
        category: EventCategory.nudge_upsell,
        label: "ad detail page",
        action: "Exposure+ purchased succesfully",
        isInteraction: false,
      });
    }
  }

  public static upsellPurchasedAdCreated() {
    Tracking.trackMatomo({
      category: EventCategory.upsell_page,
      action: "ad creation flow",
      name: "Upsell purchase",
      value: "1",
    });
  }

  public static upsellPurchasedAdEdited() {
    Tracking.trackMatomo({
      category: EventCategory.upsell_page,
      action: "rentable edit page",
      name: "Upsell purchase",
      value: "1",
    });
  }

  public static ewiiNudgeDialogContinueWithoutEwii() {
    Tracking.trackEvent({
      category: EventCategory.leads,
      action: "dialogue-you-did-not-choose-ewii",
      label: "continue without ewii",
      isInteraction: false,
    });
  }

  public static clickHomePageCategory(category: string) {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      action: "click category",
      label: category,
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "click category",
      name: category,
      value: "1",
    });
  }

  public static clickHomePagePopularAd() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      action: "click ad card",
      label: "",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "click ad card",
      name: "",
      value: "1",
    });
  }

  public static goToAdFromRentableDetailPage() {
    Tracking.trackEvent({
      category: EventCategory.rentable_detail,
      action: "see ad on boligportal",
      label: "",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "see ad on boligportal",
      name: "ad detail page",
      value: "1",
    });
  }

  public static addressAlreadyCreated() {
    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "address already created",
      name: "address",
      value: "1",
    });
  }

  public static clickUploadVideo() {
    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "clicks upload video",
      name: "video",
      value: "1",
    });
  }

  public static clickDeleteVideo() {
    Tracking.trackMatomo({
      category: EventCategory.rentable_detail,
      action: "clicks delete video",
      name: "video",
      value: "1",
    });
  }

  public static adDetailPageToolbarMovingReportAction(
    action: "moving report opened" | "moving report created",
  ) {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      action,
      label: "",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action,
      name: "Ad detail toolbar",
      value: "1",
    });
  }

  public static adDetailPageToolbarContractAction(
    action: "contract opened" | "contract created",
  ) {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      action,
      label: "",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action,
      name: "Ad detail toolbar",
      value: "1",
    });
  }

  public static adDetailPageToolbarClickRentCollection() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      action: "rent collection opened",
      label: "",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "rent collection opened",
      name: "Ad detail toolbar",
      value: "1",
    });
  }

  public static adDetailPageTenantBannerClickSimilarProperties() {
    Tracking.trackEvent({
      category: EventCategory.ad_detail_page,
      action: "similar properties",
      label: "",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "similar properties",
      name: "",
      value: "1",
    });
  }

  public static clickedHedvigAdDetailPagePromotion(
    placement: HedvigLeadOnAdDetailPagePlacement,
  ) {
    const action =
      placement === "top" ? "clicks on top link" : "clicks on bottom link";
    const label =
      placement === "top"
        ? "Hedvig ad detail page top link"
        : "Hedvig ad detail page bottom link";

    Tracking.trackEvent({
      category: EventCategory.leads,
      action,
      label,
      isInteraction: true,
    });
  }

  public static trackDeleteFromReportsList = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "moving-reports-list",
      action: "Report successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(500);
  };

  public static trackDeleteFromStatusPage = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "moving-reports-status-page",
      action: "Report successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(500);
  };

  public static trackDeleteFromRentableTab = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "rentable-detail-page-moving-reports-tab",
      action: "Report successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(500);
  };

  public static trackDeletePOAFromStatusPage = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "poa-moving-reports-status-page",
      action: "POA successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(500);
  };

  public static trackDeleteFromRentableList = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "rentable-list",
      action: "Rentable successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(200);
  };

  public static trackDeleteRentableFromOverviewPage = async () => {
    Tracking.trackEvent({
      category: EventCategory.content_deletion,
      label: "rentable-overview-page",
      action: "Rentable successfully deleted",
      isInteraction: true,
      value: "",
    });

    return awaitTimeout(200);
  };

  public static adDetailPageHevigLeadSuccessfullySubmitted() {
    Tracking.trackEvent({
      category: EventCategory.leads,
      label: "Hedvig - Ad Detail Page",
      action: "hedvig lead successfully submitted",
      isInteraction: true,
    });
  }

  public static clickedActionOnRetableList(action: RentableListActionTracking) {
    Tracking.trackEvent({
      category: EventCategory.all_rentables_list,
      action,
      label: "product nudge",
      isInteraction: true,
    });

    const nameBindings: Record<RentableListActionTracking, string> = {
      [RentableListActionTracking.advertise_rentable]: "Create ad nudge",
      [RentableListActionTracking.contracts]: "Contract nudge",
      [RentableListActionTracking.moving_reports]: "MR nudge",
    };

    Tracking.trackMatomo({
      category: EventCategory.all_rentables_list,
      action,
      name: nameBindings[action],
      value: "1",
    });
  }

  public static clickCreateAdRentOutSubleaseLandingPage(
    position: "top" | "middle" | "blue banner bottom",
  ) {
    Tracking.trackEvent({
      category: EventCategory.rent_out_sublease_landingpage,
      label: position,
      action: "click cta",
      isInteraction: true,
    });
  }

  public static clickContactUsRentOutSubleaseLandingPage() {
    Tracking.trackEvent({
      category: EventCategory.rent_out_sublease_landingpage,
      label: "",
      action: "click contact us",
      isInteraction: true,
    });
  }

  public static clickUserBacklinkedAgentCreated() {
    Tracking.trackEvent({
      category: EventCategory.profile_modals_after_message,
      label: "back to srp",
      action: "backlink to search",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: EventCategory.contact,
      action: "backlink to SRP",
      name: "contact",
      value: "1",
    });
  }

  public static clickSeeAllRecomendedAds() {
    Tracking.trackEvent({
      category: EventCategory.contact_flow,
      label: "Recommendations",
      action: "Clicks on see all similar ads",
      isInteraction: false,
    });
  }

  public static clickRecomendedAd() {
    Tracking.trackEvent({
      category: EventCategory.contact_flow,
      label: "Recommendations",
      action: "Clicks on recommended ad",
      isInteraction: false,
    });
  }

  public static clickRentCollectionNudge() {
    Tracking.trackEvent({
      category: EventCategory.nudge_rent_collection,
      label: "clicks connect MP",
      action: "product nudge",
      isInteraction: true,
    });
  }

  public static markAdAsFavourite(
    markAsFavourite: boolean,
    placement: FavouriteAdButtonPlacementTracking,
  ) {
    Tracking.trackEvent({
      category: EventCategory.favourites,
      label: placement,
      action: markAsFavourite ? "click save" : "click remove",
      isInteraction: true,
    });

    Tracking.trackMatomo({
      category: placement,
      action: markAsFavourite ? "click save" : "click remove",
      name: "favourites",
      value: "1",
    });
  }

  public static favoritesPremiumAccessPurchase() {
    Tracking.trackEvent({
      category: EventCategory.favourites_page,
      label: "",
      action: "users buy subscription",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "users buy subscription",
      name: "favourites",
      value: "1",
    });
  }

  public static favoritesClickContactLandlord() {
    Tracking.trackEvent({
      category: EventCategory.favourites_page,
      label: "",
      action: "contact landlord",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "contact landlord",
      name: "favourites",
      value: "1",
    });
  }

  public static favoritesClickGoToInbox() {
    Tracking.trackEvent({
      category: EventCategory.favourites_page,
      label: "",
      action: "go to inbox",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "go to inbox",
      name: "favourites",
      value: "1",
    });
  }

  public static clickNavigationBar = async (action: HeaderAction) => {
    Tracking.trackEvent({
      category: EventCategory.header_navigation,
      label: "",
      action,
      isInteraction: false,
    });

    return awaitTimeout(500);
  };

  public static clickBuySubscriptionOnEmptyState() {
    Tracking.trackEvent({
      category: "Empty States",
      action: "click buy subscription on empty state",
      label: "",
      isInteraction: true,
    });
  }

  public static subscriptionBoughtThroughEmptyState() {
    Tracking.trackEvent({
      category: "Empty States",
      action: "subscription bought through empty state",
      label: "",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.subscription,
      action: "empty state",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static landlordOnboardingTrackViewEvent(flow: LandlordOnboardingFlow) {
    const eventAction = `View after ${flow} dialog`;
    const eventCategory = `Onboarding after ${flow}`;
    const eventLabel = "Landlord onboarding";

    Tracking.trackEvent({
      action: eventAction,
      category: eventCategory,
      label: eventLabel,
      isInteraction: true,
    });
  }

  public static landlordOnboardingTrackClickEvent(
    item: string,
    flow: LandlordOnboardingFlow,
  ) {
    const eventAction = `Clicks create ${item}`;
    const eventCategory = `Onboarding after ${flow}`;
    const eventLabel = "Landlord onboarding";

    Tracking.trackEvent({
      action: eventAction,
      category: eventCategory,
      label: eventLabel,
      isInteraction: true,
    });
  }

  public static favoritesPurchaseSubscriptionAfterExpire(
    params: EventParameters,
  ) {
    Tracking.trackGA4Event("purchase", params);

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "expire banner",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static frontpagePurchaseSubscriptionAfterExpire(
    params: EventParameters,
  ) {
    Tracking.trackGA4Event("purchase", params);

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "expire banner",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static contactButtonPurchaseSubscription() {
    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static contactButtonDigitalShowingPurchaseSubscription() {
    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail (digital showing)",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static contactButtonVideoPurchaseSubscription() {
    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail (video paywall)",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static contactButtonOpenHousePurchaseSubscription() {
    Tracking.trackMatomo({
      category: EventCategory.ad_detail_page,
      action: "click contact button on detail (open house)",
      name: "FBP purchase",
      value: "1",
    });
  }

  public static favoritesGoingToReactivateSubscription() {
    Tracking.trackEvent({
      action: "Start reactivation subscription",
      category: EventCategory.favourites_page,
      label: "",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "Start reactivation subscription",
      name: "favourites",
      value: "1",
    });
  }

  public static favoritesReactivateSubscriptionConfirm() {
    Tracking.trackEvent({
      action: "Reactivate subscription",
      category: EventCategory.favourites_page,
      label: "",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.favourites,
      action: "Reactivate subscription",
      name: "favourites",
      value: "1",
    });
  }

  public static frontpageReactivateSubscriptionConfirm() {
    Tracking.trackEvent({
      action: "Click reactivate sub CTA",
      category: EventCategory.home_page,
      label: "Intro urgency banner",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "click reactivate sub",
      name: "intro urgency banner",
      value: "1",
    });
  }

  public static frontpageDismissSubscription() {
    Tracking.trackEvent({
      action: "Click dismiss",
      category: EventCategory.home_page,
      label: "Intro urgency banner",
      isInteraction: false,
    });

    Tracking.trackMatomo({
      category: EventCategory.home_page,
      action: "dismisses banner",
      name: "intro urgency banner",
      value: "1",
    });
  }

  public static tenantHubLeadsClickLeadReadMore(partner: AllPartners) {
    Tracking.trackEvent({
      action: `Clicks read more for ${partner}`,
      category: EventCategory.tenant_hub,
      label: "tenant hub leads",
      isInteraction: false,
    });
  }

  public static tenantHubLeadsClickLeadSignUp(partner: AllPartners) {
    Tracking.trackEvent({
      action: `Clicks sign-up for ${partner}`,
      category: EventCategory.tenant_hub,
      label: "tenant hub leads",
      isInteraction: false,
    });
  }

  public static tenantHubLeadsClickLeadConfirmSignUp(partner: AllPartners) {
    Tracking.trackEvent({
      action: `Clicks confim sign-up for ${partner}`,
      category: EventCategory.tenant_hub,
      label: "tenant hub leads",
      isInteraction: false,
    });
  }

  public static clickCloseWinbackOffer() {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      action: "click close upsell winback",
      label: "",
      isInteraction: false,
    });
  }

  public static clickAcceptUpsellWinback(productName: string) {
    Tracking.trackEvent({
      category: EventCategory.ecommerce,
      action: "click accept upsell winback",
      label: productName,
      isInteraction: false,
    });
  }

  public static clickBenefitsSidebarMenu() {
    Tracking.trackEvent({
      category: EventCategory.benefits_page,
      label: "Leads",
      action: "Clicks on sidebar item",
      isInteraction: true,
    });
  }

  public static clickBenefitsDropdownMenu() {
    Tracking.trackEvent({
      category: EventCategory.benefits_page,
      label: "Leads",
      action: "Clicks on dropdown item",
      isInteraction: true,
    });
  }

  public static clickBenefitsTabElectricity() {
    Tracking.trackEvent({
      category: EventCategory.benefits_page,
      label: "Leads",
      action: "Clicks on electricity tab",
      isInteraction: true,
    });
  }

  public static clickBenefitsTabInternet() {
    Tracking.trackEvent({
      category: EventCategory.benefits_page,
      label: "Leads",
      action: "Clicks on internet tab",
      isInteraction: true,
    });
  }

  public static clickHomePageOnboardingProfile() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "onboarding cards",
      action: "click profile on homepage",
      isInteraction: false,
    });
  }

  public static clickHomePageOnboardingAgent() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "onboarding cards",
      action: "click agent on homepage",
      isInteraction: false,
    });
  }

  public static clickHomePageOnboardingFavorites() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "onboarding cards",
      action: "click favourites on homepage",
      isInteraction: false,
    });
  }

  public static clickHomePageOnboardingBenefits() {
    Tracking.trackEvent({
      category: EventCategory.home_page,
      label: "onboarding cards",
      action: "click benefits on homepage",
      isInteraction: false,
    });
  }

  public static viewFBPPaymentsDialog() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "FBP Dialog",
      action: "viewing FBP payments dialog",
      isInteraction: true,
    });
  }

  public static closeFBPPaymentsDialog() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "FBP Dialog",
      action: "closing FBP payments dialog",
      isInteraction: true,
    });
  }

  public static clickContractsElectricityReminderChange() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "electricity reminder",
      action: "clicks go to forbrug",
      isInteraction: false,
    });
  }

  public static clickContractsElectricityReminderCancel() {
    Tracking.trackEvent({
      category: EventCategory.contact,
      label: "electricity reminder",
      action: "clicks no thanks",
      isInteraction: false,
    });
  }

  public static clickBenefitsTabTenantHub() {
    Tracking.trackEvent({
      category: EventCategory.tenant_hub,
      label: "tenant hub tab",
      action: "Clicks benefits with notification",
      isInteraction: false,
    });
  }

  public static submitCPRByTenant() {
    Tracking.trackEvent({
      category: EventCategory.leads,
      label: "contract tenant own cpr input",
      action: "Submission success",
      isInteraction: false,
    });
  }

  public static clickRenewIconRentablesList() {
    Tracking.trackEvent({
      category: EventCategory.nudge_upsell,
      label: "advertised rentables list",
      action: "Click renew icon",
      isInteraction: false,
    });
  }

  public static clickRenewIconAdDetails() {
    Tracking.trackEvent({
      category: EventCategory.nudge_upsell,
      label: "ad detail page",
      action: "Renew CTA clicked",
      isInteraction: false,
    });
  }
}
