import { useState } from "react";
import styled from "@emotion/styled";
import { UserSilhouette } from "components/icons/user_silhouette";
import { getImageUrl } from "lib/image";

const StyledImg = styled.div<{
  size: number;
  bordered?: boolean;
  highlight?: boolean;
  isClickable?: boolean;
}>`
  display: inline-block;
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  flex: 0 0 auto;
  vertical-align: middle;
  background-color: white;
  box-shadow: ${(props) =>
    props.bordered
      ? props.highlight
        ? `0 0 10px rgba(0, 0, 0, 0.08), inset 0 0 0 2px ${(props) =>
            props.theme.colors.primary}`
        : `0 0 10px rgba(0, 0, 0, 0.08), inset 0 0 0 2px ${(props) =>
            props.theme.colorPalette.gray[200]}`
      : "0 0 10px rgba(0, 0, 0, 0.08)"};
  position: relative;
  background-color: white;
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};

  svg,
  img {
    border-radius: inherit;
    display: block;
    position: absolute;
    top: ${(props) => (props.bordered ? 4 : 2)}px;
    left: ${(props) => (props.bordered ? 4 : 2)}px;
    width: ${(props) => props.size - (props.bordered ? 8 : 4)}px;
    height: ${(props) => props.size - (props.bordered ? 8 : 4)}px;
  }
`;

const Avatar = ({
  src,
  size = 40,
  bordered,
  highlight,
  onClick,
}: {
  bordered?: boolean;
  highlight?: boolean;
  src?: string | null;
  size?: number;
  onClick?: () => void;
}) => {
  const [error, setError] = useState(false);

  if (!src || error) {
    return (
      <StyledImg
        size={size}
        bordered={bordered}
        highlight={highlight}
      >
        <UserSilhouette size={size - 4} />
      </StyledImg>
    );
  }

  const imageSource = getImageUrl({
    image: {
      url: src,
    },
    width: size,
    height: size,
    crop: true,
    mask: "ellipse",
  });

  const imageSource2x =
    getImageUrl({
      image: {
        url: src,
      },
      width: size,
      height: size,
      dpr: 2,
      crop: true,
      mask: "ellipse",
    }) + " 2x";

  return (
    <StyledImg
      size={size}
      bordered={bordered}
      highlight={highlight}
      isClickable={Boolean(onClick)}
      {...(onClick && {
        onClick,
      })}
    >
      <img
        src={imageSource}
        srcSet={imageSource2x}
        onError={() => setError(true)}
      />
    </StyledImg>
  );
};

export { Avatar };
