import { ComponentProps } from "react";
import { Badge } from "@boligportal/juice";
import { States } from "apps/newbuilds/types";

// Juice Badge Color Props
type BadgeColor = ComponentProps<typeof Badge>["color"];
const newbuildStateColors: Record<States, BadgeColor> = {
  [States.ADVERTISED]: "green",

  [States.RENTED_OUT]: "red",

  [States.DRAFT]: undefined,
};

const newbuildStateTexts: Record<States, string> = {
  [States.ADVERTISED]: "Advertised",
  [States.DRAFT]: "Draft",
  [States.RENTED_OUT]: "Rented out",
};

export const useNewBuildStatusFeature = (
  state: States,
): { newbuildStatusColor: BadgeColor; newbuildStatusText: string } => {
  let color: BadgeColor;
  let text = "";

  if (state === States.ADVERTISED) {
    text = newbuildStateTexts[state];
    color = newbuildStateColors[state];
  } else if (state === States.RENTED_OUT) {
    text = newbuildStateTexts[state];
    color = newbuildStateColors[state];
  } else if (state === States.DRAFT) {
    text = newbuildStateTexts[state];
    color = newbuildStateColors[state];
  }

  return {
    newbuildStatusColor: color,
    newbuildStatusText: text,
  };
};
