import { useContext } from "react";
import { Flex } from "@boligportal/juice";
import { ProfileHeader } from "./profile_info/profile_header";
import { UserDetailPageContext } from "./user_detail_page_context";

const UserDetailPageToolbar = () => {
  const { profileHeaderViewModel, refetch, subscriptions } = useContext(
    UserDetailPageContext,
  );
  const has_company_deal_access =
    subscriptions.find(
      (sub) =>
        sub.subscription.company_deal_access &&
        sub.subscription.ended_at &&
        new Date(sub.subscription.ended_at) > new Date(),
    ) != undefined;

  const handleGdprSuccess = () => {
    refetch();
  };
  return (
    <div
      style={{
        zIndex: 700,
        height: 70,
      }}
    >
      <Flex
        p={2}
        shadow="sm"
        bg="base"
        borderBottom
      >
        {profileHeaderViewModel && (
          <ProfileHeader
            {...profileHeaderViewModel}
            onGdprDeleteSuccess={handleGdprSuccess}
            year_deal={has_company_deal_access}
          />
        )}
      </Flex>
    </div>
  );
};

export { UserDetailPageToolbar };
