import {
  Flex,
  FlexColumn,
  IconTrashOutline,
  Text,
  styled,
  responsiveValue,
} from "@boligportal/juice";
import { Image } from "apps/moving_reports/interfaces/image";

const ImageBox = styled.div<{ src: string }>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 16px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  background-image: url("${(props) => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(250, 250, 250);
  cursor: pointer;
`;

const ImageLabel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  color: white;
`;

const LimitOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
`;

const RemoveButton = styled.button`
  border: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 36px;
  height: 36px;
  top: 8px;
  right: 8px;
`;

export const MultipleImages = ({
  settings,
  showLabels,
  size,
  onRemoveImage,
  onPreview,
}: {
  settings: any;
  showLabels?: boolean;
  size: responsiveValue<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
  onRemoveImage?: (id: number) => void;
  onPreview: (id: number) => void;
}) => {
  const imagesCount = settings.images.length;

  return (
    <Flex
      wrap
      gap
      mb={imagesCount !== 0 ? 2 : 0}
    >
      {settings.images.map((image: Image, index: number) => {
        const isLast = imagesCount - 1 === index;

        return (
          <FlexColumn
            key={image.id}
            size={size}
          >
            <ImageBox
              src={image.url}
              onClick={() => onPreview(index)}
            >
              {onRemoveImage && (
                <RemoveButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemoveImage(image.id);
                  }}
                >
                  <IconTrashOutline
                    color="inverted"
                    size="large"
                  />
                </RemoveButton>
              )}
              {isLast && settings.isLimited && (
                <LimitOverlay>
                  <Text
                    color="inverted"
                    size="h2"
                  >
                    {`+${settings.remainingImagesCount}`}
                  </Text>
                </LimitOverlay>
              )}
              {showLabels && image.label && (
                <ImageLabel>{image.label}</ImageLabel>
              )}
            </ImageBox>
          </FlexColumn>
        );
      })}
    </Flex>
  );
};
