import { useQuery } from "react-query";
import { getAvailableRentalData, getRentalDataPlan } from "api";

const rentalDataKeys = {
  getRentalDataDashboards: () => ["getRentalDataDashboards"] as const,
  getRentalDataPlan: () => ["getRentalDataPlan"] as const,
};

export const useRentalDataQuery = () =>
  useQuery(
    rentalDataKeys.getRentalDataDashboards(),
    () => getAvailableRentalData(),
    {
      retry: (failureCount, error: any) => {
        if (error?.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

export const useRentalDataPlan = () =>
  useQuery(rentalDataKeys.getRentalDataPlan(), () => getRentalDataPlan(), {
    retry: (failureCount, error: any) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
  });
