import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { Translatable } from "lib/translations";
import { ValidationErrorTranslation } from "../types";
import {
  HousingTypes,
  Inventory,
  RightOfUse,
  PaymentFrequency,
  LandlordSuppliedHeating,
  TenantSuppliedHeating,
  MaintenanceDuty,
  PetsAllowed,
  HouseRules,
  HotWaterAndHeatingResponsibility,
  WaterResponsibility,
  ElectricityResponsibility,
  TvSignalSupplier,
  AttachmentType,
  DepositMonths,
  TenantGenericElectricityPOA,
  PaymentPlace,
  FreeRentRegulationReason,
} from "./store/enums";

export const HousingTypesTranslations = (): Translatable<HousingTypes> => ({
  [HousingTypes.APARTMENT]: t("contracts.housing_types.apartment"),
  [HousingTypes.ROOM]: t("contracts.housing_types.room"),
  [HousingTypes.CONDOMINIUM]: t("contracts.housing_types.condominium"),
  [HousingTypes.HOUSING_COOPERATIVE]: t(
    "contracts.housing_types.housing_cooperative",
  ),
  [HousingTypes.VILLA]: t("contracts.housing_types.villa"),
  [HousingTypes.TOWN_HOUSE]: t("contracts.housing_types.town_house"),
  [HousingTypes.OTHER]: t("contracts.housing_types.other"),
});

export const InventoryTranslations = (): Translatable<Inventory> => ({
  [Inventory.STOVE]: t("contracts.inventory.stove"),
  [Inventory.DISH_WASHER]: t("contracts.inventory.dish_washer"),
  [Inventory.EXTRACTOR_HOOD]: t("contracts.inventory.extractor_hood"),
  [Inventory.REFRIGERATOR]: t("contracts.inventory.refrigerator"),
  [Inventory.WASHING_MACHINE]: t("contracts.inventory.washing_machine"),
  [Inventory.FREEZER]: t("contracts.inventory.freezer"),
  [Inventory.DRYER]: t("contracts.inventory.dryer"),
  [Inventory.FREEZER_AND_REFRIGERATOR_COMBO]: t(
    "contracts.inventory.freezer_and_refridgerator_combo",
  ),
  [Inventory.WASHING_MACHINE_AND_DRYER_COMBO]: t(
    "contracts.inventory.washing_maching_and_dryer_combo",
  ),
  [Inventory.OTHER]: t("contracts.inventory.other"),
});

export const RightOfUseTranslations = (): Translatable<RightOfUse> => ({
  [RightOfUse.COMMON_LAUNDRY]: t("contracts.right_of_use.common_laundry"),
  [RightOfUse.COMMON_BACK_YARD]: t("contracts.right_of_use.common_back_yard"),
  [RightOfUse.LOFT_BASEMENT_STORAGE]: t(
    "contracts.right_of_use.loft_basement_storage",
  ),
  [RightOfUse.BIKE_PARKING]: t("contracts.right_of_use.bike_parking"),
  [RightOfUse.GARAGE]: t("contracts.right_of_use.garage"),
  [RightOfUse.OTHER]: t("contracts.right_of_use.other"),
});

export const PaymentFrequencyTranslations =
  (): Translatable<PaymentFrequency> => ({
    [PaymentFrequency.MONTHLY]: t("contracts.payment_frequency.monthly"),
    [PaymentFrequency.QUARTERLY]: t("contracts.payment_frequency.quarterly"),
  });

export const PaymentPlaceTranslations = (): Translatable<PaymentPlace> => ({
  [PaymentPlace.BANK_TRANSFER]: t("contracts.payment_place.bank_transfer"),
  [PaymentPlace.PAYMENT_SERVICE]: t("contracts.payment_place.payment_service"),
});

export const FreeRentRegulationReasonTranslations =
  (): Translatable<FreeRentRegulationReason> => ({
    [FreeRentRegulationReason.NEWLY_BUILT_PROPERTY]: t(
      "contracts.free_rent_regulation.newly_built_property",
    ),
    [FreeRentRegulationReason.CONVERTED_BUSINESS]: t(
      "contracts.free_rent_regulation.converted_business",
    ),
    [FreeRentRegulationReason.NEW_ATTIC]: t(
      "contracts.free_rent_regulation.new_attic",
    ),
    [FreeRentRegulationReason.NEW_FLOOR]: t(
      "contracts.free_rent_regulation.new_floor",
    ),
  });

export const FreeRentRegulationReasonDescriptionTranslations =
  (): Translatable<FreeRentRegulationReason> => ({
    [FreeRentRegulationReason.NEWLY_BUILT_PROPERTY]: t(
      "contracts.free_rent_regulation.newly_built_property_description",
    ),
    [FreeRentRegulationReason.CONVERTED_BUSINESS]: t(
      "contracts.free_rent_regulation.converted_business_description",
    ),
    [FreeRentRegulationReason.NEW_ATTIC]: t(
      "contracts.free_rent_regulation.new_attic_description",
    ),
    [FreeRentRegulationReason.NEW_FLOOR]: t(
      "contracts.free_rent_regulation.new_floor_description",
    ),
  });

export const LandlordSuppliedHeatingTranslations =
  (): Translatable<LandlordSuppliedHeating> => ({
    [LandlordSuppliedHeating.DISTRICT_HEATING_OR_NATURAL_GAS]: t(
      "contracts.landlord_supplied_heating.district_heating_or_natural_gas",
    ),
    [LandlordSuppliedHeating.CENTRAL_HEATING_OIL]: t(
      "contracts.landlord_supplied_heating.central_heating_oil",
    ),
    [LandlordSuppliedHeating.ELECTRIC_HEATING]: t(
      "contracts.landlord_supplied_heating.electric_heating",
    ),
    [LandlordSuppliedHeating.OTHER]: t(
      "contracts.landlord_supplied_heating.other",
    ),
  });

export const TenantSuppliedHeatingTranslations =
  (): Translatable<TenantSuppliedHeating> => ({
    [TenantSuppliedHeating.ELECTRIC]: t(
      "contracts.tenant_supplied_heating.electric",
    ),
    [TenantSuppliedHeating.GAS]: t("contracts.tenant_supplied_heating.gas"),
    [TenantSuppliedHeating.OIL_PETROL]: t(
      "contracts.tenant_supplied_heating.oil_petrol",
    ),
    [TenantSuppliedHeating.DISTRICT_HEATING_OR_NATURAL_GAS]: t(
      "contracts.tenant_supplied_heating.district_heating_or_natural_gas",
    ),
    [TenantSuppliedHeating.OTHER]: t("contracts.tenant_supplied_heating.other"),
  });

export const MaintenanceDutyTranslations =
  (): Translatable<MaintenanceDuty> => ({
    [MaintenanceDuty.LANDLORD]: t("contracts.maintenance_duty.landlord"),
    [MaintenanceDuty.TENANT]: t("contracts.maintenance_duty.tenant"),
  });

export const PetsAllowedTranslations = (): Translatable<PetsAllowed> => ({
  [PetsAllowed.ALLOWED]: t("contracts.pets_allowed.allowed"),
  [PetsAllowed.DISALLOWED]: t("contracts.pets_allowed.disallowed"),
  [PetsAllowed.DISPENSATION]: t("contracts.pets_allowed.dispensation"),
});

export const HouseRulesTranslations = (): Translatable<HouseRules> => ({
  [HouseRules.NO_HOUSE_RULES]: t("contracts.house_rules.no_house_rules"),
  [HouseRules.STANDARD_RULES]: t("contracts.house_rules.standard_rules"),
  [HouseRules.CUSTOM_RULES]: t("contracts.house_rules.custom_rules"),
});

export const HotWaterAndHeatingResponsibilityTranslations =
  (): Translatable<HotWaterAndHeatingResponsibility> => ({
    [HotWaterAndHeatingResponsibility.LANDLORD]: t(
      "contracts.hot_water_and_heating_responsibility.landlord",
    ),
    [HotWaterAndHeatingResponsibility.TENANT]: t(
      "contracts.hot_water_and_heating_responsibility.tenant",
    ),
    [HotWaterAndHeatingResponsibility.PART_OF_RENT]: t(
      "contracts.hot_water_and_heating_responsibility.part_of_rent",
    ),
  });

export const WaterResponsibilityTranslations =
  (): Translatable<WaterResponsibility> => ({
    [WaterResponsibility.LANDLORD]: t(
      "contracts.water_responsibility.landlord",
    ),
    [WaterResponsibility.TENANT]: t("contracts.water_responsibility.tenant"),
    [WaterResponsibility.PART_OF_RENT]: t(
      "contracts.water_responsibility.part_of_rent",
    ),
  });

export const ElectricityResponsibilityTranslations =
  (): Translatable<ElectricityResponsibility> => ({
    [ElectricityResponsibility.LANDLORD]: t(
      "contracts.electricity_responsibility.landlord",
    ),
    [ElectricityResponsibility.TENANT]: t(
      "contracts.electricity_responsibility.tenant",
    ),
    [ElectricityResponsibility.PART_OF_RENT]: t(
      "contracts.electricity_responsibility.part_of_rent",
    ),
    [ElectricityResponsibility.OK]: t(
      "contracts.electricity_responsibility.ok",
    ),
    [ElectricityResponsibility.ENERGI_FYN]: t(
      "contracts.electricity_responsibility.energi_fyn",
    ),
    [ElectricityResponsibility.NORLYS]: t(
      "contracts.electricity_responsibility.norlys",
    ),
    [ElectricityResponsibility.ANDEL]: t(
      "contracts.electricity_responsibility.andel",
    ),
    [ElectricityResponsibility.AURA]: t(
      "contracts.electricity_responsibility.aura",
    ),
  });

export const ElectricityPOAResponsibilityTranslations =
  (): Translatable<TenantGenericElectricityPOA> => ({
    [TenantGenericElectricityPOA.NO_POA]: t(
      "contracts.electricity_responsibility.no_poa",
    ),
    [TenantGenericElectricityPOA.GENERIC]: t(
      "contracts.electricity_responsibility.poa",
    ),
  });

export const TvSignalSupplierTranslations =
  (): Translatable<TvSignalSupplier> => ({
    [TvSignalSupplier.LANDLORD]: t("contracts.tv_signal_supplier.landlord"),
    [TvSignalSupplier.TELEVISION_UNION]: t(
      "contracts.tv_signal_supplier.television_union",
    ),
    [TvSignalSupplier.NO_TELEVISION]: t(
      "contracts.tv_signal_supplier.no_television",
    ),
  });

export const AttachmentTypeTranslation = (): Translatable<
  (typeof AttachmentType)[keyof typeof AttachmentType]
> => ({
  [AttachmentType.DEFAULT]: t("contracts.attachment_type.default"),
  [AttachmentType.ENERGY_LABEL]: t("contracts.attachment_type.energy_label"),
  [AttachmentType.SOIL_POLLUTION]: t(
    "contracts.attachment_type.soil_pollution",
  ),
  [AttachmentType.HOUSE_RULES]: t("contracts.attachment_type.house_rules"),
  [AttachmentType.COMPUTED]: t("contracts.attachment_type.computed"),
  [AttachmentType.HOUSE_RULES_STANDARD]: t(
    "contracts.attachment_type.house_rules_standard",
  ),
  [AttachmentType.LANDLORD_UPLOADED_TERMS]: t(
    "contracts.attachment_type.landlord_uploaded_terms",
  ),
});

export const DepositMonthsTranslations = (): Translatable<DepositMonths> => ({
  [DepositMonths.ONE]: t("contracts.deposit_months.one_month"),
  [DepositMonths.TWO]: t("contracts.deposit_months.two_months"),
  [DepositMonths.THREE]: t("contracts.deposit_months.three_months"),
  [DepositMonths.OTHER]: t("contracts.deposit_months.other"),
  [DepositMonths.ZERO]: t("contracts.deposit_months.none"),
});

export const PaymentFrequencySummeryTranslations =
  (): Translatable<PaymentFrequency> => ({
    [PaymentFrequency.MONTHLY]: t(
      "contracts.payment_frequency.monthly_singular",
    ),
    [PaymentFrequency.QUARTERLY]: t(
      "contracts.payment_frequency.quarterly_singular",
    ),
  });

export const ValidationTranslations: ValidationErrorTranslation<Market.BOLIGPORTAL_DK> =
  {
    /* GENERAL */
    monthly_rent: () => t("contracts.monthly_rent"),
    size: () => t("contracts.size"),
    num_rooms: () => t("contracts.number_of_rooms"),
    housing_type: () => t("contracts.housing_type"),
    housing_type_other: () => t("contracts.housing_type_other"),
    leasing_period_start_date: () => t("contracts.leasing_period_start_date"),
    leasing_period_end_date: () => t("contracts.leasing_period_end_date"),

    /* RENT */
    free_rent_regulation_reason: () =>
      t("contracts.free_rent_regulation_reason"),
    rent_regulation_day: () => t("contracts.rent_regulation_day"),
    rent_regulation_index_month: () =>
      t("contracts.rent_regulation_index_month"),
    first_rent_regulation_date: () => t("contracts.first_rent_regulation_date"),
    bank_reg_no: () => t("contracts.landlord_bank_reg_no"),
    bank_account_no: () => t("contracts.landlord_bank_account_no"),
    bank_name: () => t("contracts.bank_name"),
    payment_place: () => t("contracts.payment_place"),

    /* UTILITIES */
    on_account_heating: () => t("contracts.on_account_heating"),
    on_account_water: () => t("contracts.on_account_water"),
    on_account_electricity: () => t("contracts.on_account_electricity"),
    landlord_supplied_heating_start_date: () =>
      t("contracts.landlord_supplied_heating_start_date"),
    landlord_supplied_water_start_date: () =>
      t("contracts.landlord_supplied_water_start_date"),
    landlord_supplied_electricity_start_date: () =>
      t("contracts.landlord_supplied_electricity_start_date"),
    landlord_supplied_heating_other: () =>
      t("contracts.landlord_supplied_heating.other"),
    tenant_supplied_heating_other: () =>
      t("contracts.tenant_supplied_heating.other"),
    electricity_poa: () => t("contracts.electricity_supplier.ewii_poa"),

    /* PAYMENTS */
    deposit_date: () => t("contracts.deposit_date"),
    deposit_amount: () => t("contracts.deposit_amount"),
    prepaid_rent_date: () => t("contracts.prepaid_rent_date"),

    /* ACCESS, MAINTENANCE, INVENTORY */
    move_in_inspection_at: () => t("contracts.move_in_inspection"),
    indoor_maintenance_account_amount: () =>
      t("contracts.indoor_maintenance_account_amount"),
    right_of_use_garage_no: () => t("contracts.right_of_use_garage_number"),
    right_of_use_storage_no: () => t("contracts.right_of_use_storage_number"),
    right_of_use_other1: () => t("contracts.right_of_use_other"),
    right_of_use_other2: () => t("contracts.right_of_use_other"),
    right_of_use_other3: () => t("contracts.right_of_use_other"),
    landlord_has_multiple_rentables: () =>
      t("contracts.landlord_has_multiple_rentables"),

    /* ATTACHMENTS */
    rentable_additional_information: () =>
      t("contracts.rentable_additional_information"),

    /* LANDLORD */
    landlord_name: () =>
      t("contracts.landlord_%{number}_name", {
        number: 1,
      }),
    landlord_email: () =>
      t("contracts.landlord_%{number}_email", {
        number: 1,
      }),
    landlord_phone: () =>
      t("contracts.landlord_%{number}_phone", {
        number: 1,
      }),
    landlord_street: () =>
      t("contracts.landlord_%{number}_address", {
        number: 1,
      }),
    landlord_postal_code: () =>
      t("contracts.landlord_%{number}_postal_code", {
        number: 1,
      }),

    second_landlord_name: () =>
      t("contracts.landlord_%{number}_name", {
        number: 2,
      }),
    second_landlord_email: () =>
      t("contracts.landlord_%{number}_email", {
        number: 2,
      }),
    second_landlord_phone: () =>
      t("contracts.landlord_%{number}_phone", {
        number: 2,
      }),
    second_landlord_street: () =>
      t("contracts.landlord_%{number}_address", {
        number: 2,
      }),
    second_landlord_postal_code: () =>
      t("contracts.landlord_%{number}_postal_code", {
        number: 2,
      }),

    /* TENANTS */
    tenants_first_name: (number: number) =>
      t("contracts.tenants_%{number}_first_name", {
        number,
      }),
    tenants_last_name: (number: number) =>
      t("contracts.tenants_%{number}_last_name", {
        number,
      }),
    tenants_street: (number: number) =>
      t("contracts.tenants_%{number}_street", {
        number,
      }),
    tenants_postal_code: (number: number) =>
      t("contracts.tenants_%{number}_postal_code", {
        number,
      }),
    tenants_postal_town: (number: number) =>
      t("contracts.tenants_%{number}_postal_town", {
        number,
      }),
    tenants_email: (number: number) =>
      t("contracts.tenants_%{number}_email", {
        number,
      }),
    tenants_phone_number: (number: number) =>
      t("contracts.tenants_%{number}_phone", {
        number,
      }),
    tenants_cpr: (number: number) =>
      t("contracts.tenants_%{number}_cpr", {
        number,
      }),
    tenants_cvr: (number: number) =>
      t("contracts.tenants_%{number}_cvr", {
        number,
      }),

    /* SPECIAL TERMS */
    special_terms: () => t("contracts.special_terms_title"),

    /* FINALIZE */
    signing_order: () => t("contracts.finalize.signing_order.title"),
    note_to_tenant: () => t("contracts.finalize.note_to_tenant.title"),
    additional_contract_recipients_value: () =>
      t("contracts.finalize.after_signature.copy_recipients.label"),
  };
