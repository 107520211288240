import { useState, useCallback, useEffect } from "react";

export const useIsParentWidthLessThan = (targetWidth: number) => {
  const [width, setWidth] = useState(targetWidth);
  const [ref, setContainingRef] = useState<HTMLDivElement | null>(null);

  const calculateWidth = useCallback(() => {
    if (ref) {
      setWidth(ref.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", calculateWidth);
      calculateWidth();
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", calculateWidth);
      }
    };
  }, [ref, calculateWidth]);

  return {
    containerWidthIsLessThanSpecified: width < targetWidth,
    containingRef: setContainingRef,
  };
};
