import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  CheckField,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Divider,
  IconAlertCircleOutline,
  Notice,
  RadioField,
  Text,
  NumberField,
  styled,
  Flex,
  IconArrowForward,
} from "@boligportal/juice";
import { NumberFieldChangeValues } from "@boligportal/juice/dist/components/NumberField/NumberField";
import { useReportActions } from "apps/moving_reports/api_wrappers/useReportActions";
import { MovingReport } from "apps/moving_reports/interfaces/movingReport";
import { App } from "components/app";
import { ElectricityPartner } from "leads/electricity/ElectricityPartner";
import { LeadCardTrigger } from "leads/shared_components/LeadCardTrigger";
import { SuccessDialog } from "leads/shared_components/SuccessDialog";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { EventCategory } from "lib/tracking/events";
import { isValidCpr } from "lib/utils";
import SvgAndelLogo from "media/svg/AndelLogo";
import SvgAuraLogo from "media/svg/AuraLogo";
import SvgEnergifynLogo from "media/svg/EnergifynLogo";
import SvgNorlysLogo from "media/svg/NorlysLogo";
import SvgOkLogo from "media/svg/OkLogo";
import { getFilteredAndSortedOptions } from "./electricityLeadSorter";
import { ElectricityLeadService } from "./interfaces/ElectricityLeadService";

const StyledTextLink = styled.a`
  color: ${(props) => props.theme.colorPalette.primary[800]};
  font-size: ${(props) => props.theme.fontSize.small};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colorPalette.primary[400]};
  }
`;

const StyledPartnerOption = styled(Box)<{ subscribed: boolean }>`
  ${({ subscribed, theme }) =>
    subscribed && `background-color: ${theme.color.context.success[100]};`})}
`;

interface ElectricityLeadMovingReportsProps {
  report: MovingReport;
  leadService: ElectricityLeadService;
  missingData: boolean;
}

interface PartnerOption {
  tagline: string;
  value: ElectricityPartner;
  logo: React.ReactNode;
}

type MapFunction<T, U extends string> = (option: U) => T | null;

export const ElectricityLeadMovingReports = ({
  report: { tenants, postal_code },
  leadService,
  missingData,
}: ElectricityLeadMovingReportsProps) => {
  const [contactPersonId, setContactPersonId] = useState("0");
  const [isDialog, setIsDialog] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [cpr, setCpr] = useState("");
  const [companyCvr, setCompanyCvr] = useState("");
  const [isConfirmedValid, setIsConfirmedValid] = useState(true);
  const [isCPRValid, setIsCPRValid] = useState(true);
  const [isCVRValid, setIsCVRValid] = useState(true);

  const [isCompany, setIsCompany] = useState(false);

  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [selectedLead, setSelectedLead] = useState<ElectricityPartner>();
  const subscribedPartner = useMemo(
    () => tenants.find((tenant) => tenant.lead_sent)?.lead_sent,
    [tenants],
  );
  const [showAllOptions, setShowAllOptions] = useState(false);

  const ElectricityPartners: PartnerOption[] = [
    {
      tagline: t("leads.ok.tagline"),
      value: ElectricityPartner.OK,
      logo: <SvgOkLogo height={"18px"} />,
    },
    {
      tagline: t("leads.energifyn.tagline"),
      value: ElectricityPartner.ENERGI_FYN,
      logo: <SvgEnergifynLogo height={"18px"} />,
    },
    {
      tagline: t("leads.norlys.tagline"),
      value: ElectricityPartner.NORLYS,
      logo: <SvgNorlysLogo height={"18px"} />,
    },
    {
      tagline: t("leads.andel.tagline"),
      value: ElectricityPartner.ANDEL,
      logo: (
        <SvgAndelLogo
          height={"18px"}
          width={"90px"}
        />
      ),
    },
    {
      tagline: t("leads.aura.tagline"),
      value: ElectricityPartner.AURA,
      logo: (
        <SvgAuraLogo
          height={"18px"}
          width={"60px"}
        />
      ),
    },
  ];

  const ElecricityLeadConfig: Record<
    ElectricityPartner,
    { title: string; description: string; agreeText: string }
  > = {
    [ElectricityPartner.OK]: {
      title: t("leads.ok.dialog.title"),
      description: t("leads.ok.dialog.description"),
      agreeText: t("leads.OK.agree"),
    },
    [ElectricityPartner.ENERGI_FYN]: {
      title: t("leads.energifyn.dialog.title"),
      description: t("leads.energifyn.dialog.description"),
      agreeText: t("leads.energifyn.agree"),
    },
    [ElectricityPartner.NORLYS]: {
      title: t("leads.norlys.dialog.title"),
      description: t("leads.norlys.dialog.description"),
      agreeText: t("leads.norlys.agree"),
    },
    [ElectricityPartner.ANDEL]: {
      title: t("leads.andel.dialog.title"),
      description: t("leads.andel.dialog.description"),
      agreeText: t("leads.andel.agree"),
    },
    [ElectricityPartner.AURA]: {
      title: t("leads.aura.dialog.title"),
      description: t("leads.aura.dialog.description"),
      agreeText: t("leads.aura.agree"),
    },
  };

  const reportActions = useReportActions();

  useEffect(() => {
    if (tenants.some((t) => t.lead_sent)) {
      setSubscribed(true);
    }
  }, [tenants]);

  const handleConfirmed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmedValid(true);
    setIsAgree(event.target.checked);
  };

  const handleCPRInput = (values: NumberFieldChangeValues) => {
    setIsCPRValid(true);
    setCpr(values.value);
  };

  const handleCVRInput = (values: NumberFieldChangeValues) => {
    setIsCVRValid(true);

    setCompanyCvr(values.value);
  };

  const handleSubmit = async () => {
    if (!isValidCpr(cpr) && !isCompany) {
      setIsCPRValid(false);

      return;
    }

    if (companyCvr.length !== 8 && isCompany) {
      setIsCVRValid(false);

      return;
    }

    if (!isAgree) {
      setIsConfirmedValid(false);

      return;
    }

    const tenantId = tenants[contactPersonId].id;
    if (tenantId && selectedLead) {
      setLoading(true);

      const success = await leadService({
        tenant_cpr: isCompany ? undefined : cpr,
        company_cvr: isCompany ? companyCvr : undefined,
        moving_report_tenant_id: tenantId,
        provider: selectedLead,
      });

      if (success) {
        setSubscribed(true);

        Tracking.trackEvent({
          category: EventCategory.leads,
          value: selectedLead,
          action: "lead successfully submitted",
          label: "leads - moving reports - step in builder",
          isInteraction: false,
        });
      }

      setLoading(false);
      await reportActions.updateReport({});
    }
  };

  const mapPartner: MapFunction<PartnerOption, ElectricityPartner> = (
    option,
  ) => {
    const partner = ElectricityPartners.find(
      (partner) => partner.value === option,
    );
    return partner || null;
  };

  const filteredElectricityPartners = getFilteredAndSortedOptions(
    postal_code,
    showAllOptions,
    ElectricityPartner,
    mapPartner,
  );

  const renderButton: () => React.ReactNode = () => (
    <>
      {filteredElectricityPartners.map(({ tagline, value, logo }) => (
        <StyledPartnerOption
          key={value}
          mb={1}
          borderRadius="md"
          border
          subscribed={subscribedPartner === value}
        >
          <Flex
            p={2}
            align="center"
          >
            <Flex
              column
              align="start"
            >
              {logo}
              <Text
                block
                mt={1}
              >
                {tagline}
              </Text>
            </Flex>
            <Flex flexPush>
              <Box>
                <Button
                  iconAfter={IconArrowForward}
                  text
                  disabled={missingData || Boolean(subscribedPartner)}
                  onClick={() => {
                    setSelectedLead(value);
                    setIsDialog(true);
                  }}
                >
                  {subscribedPartner === value
                    ? t("leads.common.subscribed")
                    : t("leads.common.subscribe")}
                </Button>
              </Box>
            </Flex>
          </Flex>
        </StyledPartnerOption>
      ))}
      <Button
        text
        onClick={() => setShowAllOptions(!showAllOptions)}
      >
        {showAllOptions ? t("leads.hide_options") : t("leads.show_options")}
      </Button>
    </>
  );

  const renderElectricityLead = () => (
    <LeadCardTrigger
      onClick={() => ({})}
      title={t("leads.ewii.trigger.card_title")}
      text={t("leads.ewii.trigger.card_info")}
      action={() => renderButton()}
    />
  );

  const notEmptyTenants = tenants.filter(({ first_name }) => first_name);

  const items = notEmptyTenants.map(({ first_name, last_name }, index) => ({
    label: `${first_name} ${last_name}`,
    value: index.toString(),
  }));

  const handleClose = () => {
    setIsDialog(false);
    setIsAgree(false);
  };

  const renderSucess = () => (
    <SuccessDialog
      onClose={handleClose}
      text={t("leads.common.dialog.subscribed.description")}
    />
  );

  const renderDialog = (selectedLead: ElectricityPartner) => {
    const { first_name, last_name, phone_number, email } =
      notEmptyTenants[contactPersonId];

    const { title, description, agreeText } =
      ElecricityLeadConfig[selectedLead];

    return (
      <Dialog
        open
        onClose={handleClose}
      >
        <DialogHeader>{title}</DialogHeader>

        <DialogContent>
          <Box>
            <Text
              block
              weight="semiBold"
              mb={3}
            >
              {description}
            </Text>

            <Notice
              type="info"
              icon={<IconAlertCircleOutline size="medium" />}
            >
              {t("leads.common.dialog.tenant_should_fill_form")}
            </Notice>
          </Box>

          <Box mt={4}>
            {notEmptyTenants.length > 1 && (
              <RadioField
                value={contactPersonId}
                label={t("leads.common.contact_person")}
                items={items}
                onChange={(event) => setContactPersonId(event.target.value)}
              />
            )}
          </Box>

          <Divider />

          <Box>
            <Text
              block
              size="small"
              pb={1}
            >
              {t("leads.common.dialog.contact_details")}
            </Text>
            <Text block>{`${first_name} ${last_name}`}</Text>
            <Text block>{`${t(
              "leads.common.dialog.phone",
            )} ${phone_number}`}</Text>
            <Text block>{email}</Text>

            <Box pt={2}>
              <CheckField
                checked={isCompany}
                onChange={(event) => setIsCompany(event.target.checked)}
                label={t("contracts.tenant_is_business")}
              />

              {isCompany ? (
                <NumberField
                  required
                  allowLeadingZeros
                  label={t("contracts.tenant_cvr")}
                  helpText={t("contracts.tenant_cvr_helptext")}
                  format="########"
                  mask="_"
                  placeholder="XXXXXXXX"
                  value={companyCvr}
                  onValueChange={handleCVRInput}
                  errorText={
                    !isCVRValid
                      ? t("leads.common.dialog.missing_cvr")
                      : undefined
                  }
                />
              ) : (
                <NumberField
                  required
                  allowLeadingZeros
                  label={t("leads.common.dialog.cpr_input_label")}
                  helpText={t("leads.common.dialog.cpr_input_help_text")}
                  format="######-####"
                  mask="_"
                  placeholder="XXXXXX-XXXX"
                  value={cpr}
                  onValueChange={handleCPRInput}
                  errorText={
                    !isCPRValid
                      ? t("leads.common.dialog.missing_cpr")
                      : undefined
                  }
                />
              )}
            </Box>
          </Box>

          <Divider />

          <Box>
            <CheckField
              onChange={handleConfirmed}
              label={agreeText}
            />
          </Box>
          {!isConfirmedValid && (
            <Box
              pl={{
                sm: 3,
              }}
            >
              <Text
                block
                color="danger"
                size="tiny"
                mt={0.5}
              >
                {t("leads.common.dialog.missing_confirmation")}
              </Text>
            </Box>
          )}

          <Box
            pl={{
              sm: 3,
            }}
          >
            <StyledTextLink
              href={App.settings.routes.privacy}
              target="_blank"
            >
              {t("leads.ewii.content.data_policy.text_link")}
            </StyledTextLink>
          </Box>
        </DialogContent>

        <DialogFooter>
          <Button
            fullWidth
            variant="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {t("leads.ewii.dialog.confirm_button_label")}
          </Button>
        </DialogFooter>
      </Dialog>
    );
  };

  return (
    <>
      {renderElectricityLead()}
      {!missingData &&
        isDialog &&
        !subscribed &&
        selectedLead &&
        renderDialog(selectedLead)}
      {!missingData && isDialog && subscribed && renderSucess()}
    </>
  );
};
