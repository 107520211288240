import * as React from "react";
import {
  Button,
  Center,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@boligportal/juice";
import { PaymentSpinner } from "components/payment_spinner";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { TrackingEvent } from "lib/tracking/events";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { AppActions } from "./Providers/AppActionsProvider";
import { App } from "./app";
import { Plan } from "./interfaces/plan";
import { Market } from "./markets/market_settings";

const SUBSCRIPTION_RETRY_DELAY_WHEN_PENDING_PAYMENT = 5000;
const SUBSCRIPTION_RETRY_MAX_ATTEMPTS = 6;

type StartSubscriptionModalProps = {
  onPurchaseComplete: () => void;
  onSubscriptionActivated?: () => void;
  paymentFailedAction: () => void;
  paymentMethodId: number;
  plans: Plan[];
  adId: number | null;
  appActions: AppActions;
  extraSuccessMessage?: React.ReactNode;
  errorButtonLabel?: string;
  onClose: () => void;
};

interface StartSubscriptionModalState {
  submitting: boolean;
  submitted: boolean;
  subscriptionActivated: boolean;
  paymentFailed: boolean;
  invoicePaid: boolean;
}

export const StartSubscriptionModal = ({
  adId,
  appActions,
  extraSuccessMessage = "",
  onClose,
  onPurchaseComplete,
  paymentFailedAction,
  paymentMethodId,
  plans,
  errorButtonLabel,
  onSubscriptionActivated,
}: StartSubscriptionModalProps) => {
  const [state, setState] = React.useReducer<
    React.Reducer<
      StartSubscriptionModalState,
      Partial<StartSubscriptionModalState>
    >
  >(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      submitting: true,
      submitted: false,
      subscriptionActivated: false,
      paymentFailed: false,
      invoicePaid: false,
    },
  );

  let subscriptionRetryAttempt = 0;

  const dataLayer = window.dataLayer || [];

  const startSubscriptions = () => {
    API.startSubscriptions(paymentMethodId, plans, adId)
      .then((startSubscriptionResults) => {
        const startSubscriptionResult = startSubscriptionResults[0];
        if (startSubscriptionResult.pending_payment) {
          if (subscriptionRetryAttempt >= SUBSCRIPTION_RETRY_MAX_ATTEMPTS) {
            throw Error("Exceed max retry attemps for starting subscription");
          }
          setTimeout(() => {
            subscriptionRetryAttempt += 1;
            startSubscriptions();
          }, SUBSCRIPTION_RETRY_DELAY_WHEN_PENDING_PAYMENT);
          return;
        }
        if (startSubscriptionResult.subscription_created) {
          Tracking.trackVirtualPageview("/payment/success", getTitle());
          const plan = plans[0];
          TrackingEvent.paymentSuccessfullyCompleted(plan.product.name);
          if (adId !== null && plan.product.name === "seeker_subscription") {
            if (App.settings.market === Market.BOSTADSPORTAL_SE) {
              dataLayer.push({
                event: "seeker_subscription_purchased",
              });
            }
            FacebookTrackingEvent.trackPurchase(
              plan.price.total,
              plan.currency,
              adId,
            );
          }
          setState({
            subscriptionActivated: true,
            submitting: false,
            submitted: true,
            invoicePaid: startSubscriptionResult.invoice_paid,
            paymentFailed: false,
          });

          if (onSubscriptionActivated) {
            onSubscriptionActivated();
          }
          appActions.reloadAppProps();
        } else {
          Tracking.trackVirtualPageview("/payment/failure", getTitle());
          setState({
            submitting: false,
            submitted: true,
            subscriptionActivated: false,
            paymentFailed: true,
          });
        }
      })
      .catch((_) => {
        Tracking.trackVirtualPageview("/payment/failure", getTitle());
        setState({
          submitting: false,
          submitted: false,
          subscriptionActivated: false,
          paymentFailed: true,
        });
      });
  };

  React.useEffect(() => {
    Tracking.trackVirtualPageview("/payment/processing", getTitle());
    startSubscriptions();
  }, []);

  const {
    invoicePaid,
    paymentFailed,
    submitted,
    submitting,
    subscriptionActivated,
  } = state;

  const renderContinueButton = () => {
    if (!subscriptionActivated) {
      return null;
    }
    return (
      <DialogFooter>
        <Button
          testId={"continueAfterPaying"}
          variant="branded"
          autoFocus
          onClick={onPurchaseComplete}
        >
          {t("payment_modal.button.continue")}
        </Button>
      </DialogFooter>
    );
  };

  const getTitle = () => {
    if (submitting) {
      return t("payment_modal.title.processing");
    } else if (submitted) {
      if (invoicePaid) {
        return t("payment_modal.title.success");
      } else {
        return t("payment_modal.title.success.payment_info_registered");
      }
    }
    return t("payment_modal.title");
  };

  const retryPayment = () => {
    setState({
      submitting: true,
      submitted: false,
      subscriptionActivated: false,
      paymentFailed: false,
    });
    paymentFailedAction();
  };

  return paymentFailed ? (
    <Dialog
      open
      size="medium"
      hideOnClickOutside={false}
      dismissable={!!onClose}
      onClose={onClose}
    >
      <DialogHeader>{t("payment_modal.failure.headline")}</DialogHeader>
      <DialogContent>
        <div
          style={{
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{
            __html: t("payment_modal.failure.message"),
          }}
        />
      </DialogContent>
      <DialogFooter>
        <Button
          variant="branded"
          autoFocus
          onClick={retryPayment}
        >
          {errorButtonLabel
            ? errorButtonLabel
            : t("payment_modal.button.retry")}
        </Button>
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog
      open
      size="medium"
      hideOnClickOutside={false}
      dismissable={!!onClose}
      onClose={onClose}
    >
      <DialogHeader>{getTitle()}</DialogHeader>
      <DialogContent>
        <div hidden={!submitting && !submitted}>
          <Center height="275px">
            <PaymentSpinner
              submitted={submitted}
              successHeadline={
                invoicePaid
                  ? t("payment_modal.success.headline")
                  : t("payment_modal.success.payment_info_registered.headline")
              }
              successMessage={
                invoicePaid
                  ? t("payment_modal.success.message")
                  : t("payment_modal.success.payment_info_registered.message")
              }
              extraSuccessMessage={invoicePaid ? extraSuccessMessage : ""}
            />
          </Center>
        </div>
      </DialogContent>
      {renderContinueButton()}
    </Dialog>
  );
};
