import * as React from "react";
import { Box, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import SvgArrowLeft16px from "media/icons/ArrowLeft16px";
import { Action_changeView } from "../apps/search_result/search_result_reducer";

const StyledHeader = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.colorPalette.gray[20]};
  border-top: 1px solid ${(props) => props.theme.color.border};
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  display: flex;
  padding-left: ${(props) => props.theme.unit(2)};
  align-items: center;
`;

interface Props {
  dispatch: React.Dispatch<Action_changeView>;
}

const AdFiltersNavigateBack = ({ dispatch }: Props) => (
  <StyledHeader
    style={{
      cursor: "pointer",
    }}
    onClick={() => {
      dispatch({
        type: "changeView",
        payload: {
          view: "list",
        },
      });
    }}
  >
    <Box pr={1}>
      <SvgArrowLeft16px />
    </Box>
    {t("srp.map.back_to_list")}
  </StyledHeader>
);

export { AdFiltersNavigateBack };
