import * as React from "react";

export const BulletSectionParagraph11 = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect
          width="32"
          height="32"
        />
      </clipPath>
    </defs>
    <g
      id="a"
      clipPath="url(#b)"
    >
      <path
        d="M19.061,29.469H4.235A4.228,4.228,0,0,1,0,25.258V4.209A4.227,4.227,0,0,1,4.235,0h6.089a.265.265,0,0,1,.266.263V9.472a3.17,3.17,0,0,0,3.176,3.156h9.266a.265.265,0,0,1,.265.264V25.258A4.228,4.228,0,0,1,19.061,29.469Zm-8.949-5.554-1.744.005A2.223,2.223,0,0,0,9.23,25.8a3.978,3.978,0,0,0,2.419.651,3.812,3.812,0,0,0,2.286-.608,1.951,1.951,0,0,0,.833-1.666,2.079,2.079,0,0,0-.814-1.751,1.774,1.774,0,0,0,.977-1.648,2.106,2.106,0,0,0-.284-1.118,2.346,2.346,0,0,0-.876-.8,7.422,7.422,0,0,0-1.7-.632,5.852,5.852,0,0,1-1.454-.523.759.759,0,0,1-.332-.669.742.742,0,0,1,.362-.65,1.878,1.878,0,0,1,1.027-.237,1.472,1.472,0,0,1,1.013.328,1.07,1.07,0,0,1,.374.851H14.8a2.365,2.365,0,0,0-.82-1.91,3.522,3.522,0,0,0-2.311-.687,3.748,3.748,0,0,0-2.293.639,2.01,2.01,0,0,0-.844,1.677,2.083,2.083,0,0,0,.82,1.751,1.817,1.817,0,0,0-.977,1.661,2.121,2.121,0,0,0,.271,1.1,2.4,2.4,0,0,0,.833.791A7.273,7.273,0,0,0,11.142,23a6.081,6.081,0,0,1,1.491.553.747.747,0,0,1,.392.644.692.692,0,0,1-.351.608,1.876,1.876,0,0,1-1.025.232A1.905,1.905,0,0,1,10.5,24.74.967.967,0,0,1,10.112,23.915Zm2.6-2.1h0L11.373,21.4a3.079,3.079,0,0,1-1-.467.852.852,0,0,1-.248-.651.908.908,0,0,1,.454-.857l1.369.432a3.022,3.022,0,0,1,.983.486.774.774,0,0,1,.278.614.925.925,0,0,1-.494.85Zm9.646-11.286H13.766a1.056,1.056,0,0,1-1.058-1.052V.933a.131.131,0,0,1,.081-.121A.133.133,0,0,1,12.84.8a.13.13,0,0,1,.093.039l9.518,9.46a.13.13,0,0,1,.029.143A.132.132,0,0,1,22.357,10.524Z"
        transform="translate(4.352 1.266)"
        fill="#223663"
      />
    </g>
  </svg>
);
