import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useNotifications } from "@boligportal/juice";
import { PAGE_CRAWLINGS_DRAFTS, PAGE_HOME } from "apps/customer_service";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { deleteDraft, publishDraft } from "lib/customerServiceApi";
import { CrawlingDraftForm } from "../interfaces/CrawlingDraftForm";
import { PublishDraftError } from "../interfaces/PublishDraftError";

export const useCrawlingDraft = (
  draftId,
  initialAddress,
  initialFieldValues,
) => {
  const { addNotification } = useNotifications();
  const history = useHistory();
  const allFormMethods = useForm({
    defaultValues: initialFieldValues,
  });
  const [addressViewState, setAddressViewState] = useState<"manual" | "auto">(
    "auto",
  );
  const [viewState, setViewState] = useState<
    "idle" | "publishing" | "deleting"
  >("idle");
  const [publishErrors, setPublishErrors] = useState<Array<PublishDraftError>>(
    [],
  );

  const tryDelete = useCallback(() => {
    if (!confirm("Are you sure you want to delete this Crawling Draft?")) {
      return;
    }

    setViewState("deleting");

    deleteDraft(draftId).then((response) => {
      if (response.success) {
        // The deleteDraft endpoint is super fast!
        // create small delay so user does not think UI is acting weird
        setTimeout(() => {
          history.replace(PAGE_CRAWLINGS_DRAFTS);
        }, 300);
      } else {
        setViewState("idle");
      }
    });
  }, [draftId, history]);

  const tryPublishDraft = useCallback(
    async (data: CrawlingDraftForm) => {
      let payload: any = {
        ...data,
      };

      if (addressViewState === "manual") {
        payload = {
          ...payload,
          address: data.address,
          formatted_address: undefined,
        };

        if (App.settings.market === Market.BOSTADSPORTAL_SE) {
          payload = {
            ...payload,
            address: {
              ...payload.address,
              // Backend expects a floor value of null. We have a Tech-dept to remove this on the backend
              // https://app.shortcut.com/boligportal/story/7311/remove-floor-when-creating-new-rentable-in-sweeden
              floor: null,
            },
          };
        }
      }

      if (addressViewState === "auto") {
        payload = {
          ...payload,
          address: undefined,
          formatted_address: initialAddress,
        };
      }

      setViewState("publishing");

      const response = await publishDraft(Number(draftId), payload);

      if (response.success) {
        if (response.ad_id) {
          addNotification({
            title: "Success",
            content: "Crawling Draft was successfully published.",
            actions: [
              {
                label: "Back to list",
                dismissOnClick: true,
                onClick: () => {
                  history.replace(PAGE_CRAWLINGS_DRAFTS);
                },
              },
              {
                label: "Go to Ad",
                dismissOnClick: true,
                onClick: () => {
                  history.replace(`${PAGE_HOME}/ads/detail/${response.ad_id}`);
                },
              },
            ],
          });
        }
      } else if (response.errors) {
        const publishErrors: PublishDraftError[] = [];
        for (const field in response.errors) {
          publishErrors.push({
            field: field,
            errorMessage: response.errors[field],
          });
        }
        setPublishErrors(publishErrors);
        setViewState("idle");
      }
    },
    [
      draftId,
      addressViewState,
      initialAddress,
      history,
      addNotification,
      setPublishErrors,
    ],
  );

  return {
    viewState,
    allFormMethods,
    addressViewState,
    setAddressViewState,
    tryPublishDraft,
    tryDelete,
    publishErrors,
  };
};
