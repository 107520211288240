import * as React from "react";
import { Box } from "@boligportal/juice";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useKey } from "hooks/useKey";
import { SearchItems } from "./components/search_items";
import { GlobalSearchContext } from "./global_search_context";

const inputStyles = (props) => css`
  display: block;
  width: 100%;

  color: ${props.theme.color.text.base};
  border: 2px solid ${props.theme.colorPalette.gray[20]};
  padding-left: ${props.theme.unit(5)};
  padding-top: ${props.theme.unit(1)};
  padding-bottom: ${props.theme.unit(1)};
  border-radius: ${props.theme.borderRadius.none};

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23333'%3E%3Cpath d='M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: ${props.theme.unit(1)}; center;
  background-size: 24px 24px;

  font-weight: ${props.theme.fontWeight.base};
  font-size: ${props.theme.fontSize.body};
  outline: none;

  &:hover {
    border: 2px solid ${props.theme.colorPalette.gray[40]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props.theme.colorPalette.gray[70]};
    background-color: ${props.theme.colorPalette.gray[10]};
  }

  &:focus {
    outline: none;
    background-color: white;
    border-color: ${props.theme.color.focusBorder};
  }

  &::placeholder {
    color: ${props.theme.color.inputPlaceholder};
  }
`;

const StyledInput = styled.input`
  ${inputStyles};
`;

const wrapperStyles = (props) => css`
  box-shadow: ${props.theme.shadow.lg};
  width: 600px;
  margin-top: 0;
  position: fixed;
  background-color: #ffffff;
  color: ${props.theme.color.text.base};
`;

const StyledSearchItemsWrapper = styled.div`
  ${wrapperStyles};
`;

const GlobalSearch = () => {
  const { show, hide, isVisible, searchValue, search, searchGroups } =
    React.useContext(GlobalSearchContext);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const clickOutsideWrapper = React.useRef<HTMLDivElement | null>(null);

  const isForwardSlashDown = useKey("/", ["input", "textarea"]);
  const isEscapeDown = useKey("escape");

  React.useEffect(() => {
    if (isForwardSlashDown && inputRef.current) {
      inputRef.current.focus();
    }
    if (isEscapeDown && inputRef.current) {
      search("");
      hide();
      inputRef.current.blur();
    }
  }, [isForwardSlashDown, isEscapeDown]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("focusin", handleFocusIn);

    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  const handleDocumentMouseDown = (event) => {
    // inside click
    if (
      clickOutsideWrapper &&
      clickOutsideWrapper.current &&
      clickOutsideWrapper.current.contains(event.target)
    ) {
      return;
    }
    // outside click
    hide();
  };

  const handleFocusIn = (event) => {
    // inside focus
    if (
      clickOutsideWrapper &&
      clickOutsideWrapper.current &&
      clickOutsideWrapper.current.contains(event.target)
    ) {
      return;
    }
    // outside focus
    hide();
  };

  const handleInputFocus = () => {
    show();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    search(event.target.value);
  };

  return (
    <div ref={clickOutsideWrapper}>
      <Box width="600px">
        <StyledInput
          type="text"
          ref={inputRef}
          placeholder={'Global search - press "/" to focus'}
          value={searchValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
      </Box>
      {isVisible && (
        <StyledSearchItemsWrapper>
          <SearchItems searchGroups={searchGroups} />
        </StyledSearchItemsWrapper>
      )}
    </div>
  );
};

export { GlobalSearch };
