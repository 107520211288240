import { Plan } from "components/interfaces/plan";
import { StartSubscriptionFlow } from "../StartSubscriptionFlow";
import { QuickpayFormDialog } from "./QuickpayFormDialog";
import { useQuickpay } from "./useQuickpay";

interface QuickpayPaymentModalProps {
  title: string;
  plans: Plan[];
  adId: number | null;
  onClose: () => void;
  onSubscriptionActivated?: () => void;
  onPurchaseComplete: (plan: Plan) => void;
}

export const QuickpayPaymentFlow = ({
  title,
  plans,
  adId,
  onClose,
  onSubscriptionActivated,
  onPurchaseComplete,
}: QuickpayPaymentModalProps) => {
  const {
    paymentUrl,
    orderId,
    cardAccepted,
    paymentMethodId,
    isWaitingForIFrameToLoad,
    handlePaymentFailedRetryButtonClick,
    handleIFrameLoad,
  } = useQuickpay(plans, adId);

  return cardAccepted && paymentMethodId ? (
    <StartSubscriptionFlow
      adId={adId}
      plans={plans}
      paymentMethodId={paymentMethodId}
      onClose={onClose}
      onPurchaseComplete={onPurchaseComplete}
      onSubscriptionActivated={onSubscriptionActivated}
      onPaymentFailedRetryButtonClick={handlePaymentFailedRetryButtonClick}
    />
  ) : (
    <QuickpayFormDialog
      isWaitingForIFrameToLoad={isWaitingForIFrameToLoad}
      title={title}
      plans={plans}
      paymentUrl={paymentUrl}
      orderId={orderId}
      handleIFrameLoad={handleIFrameLoad}
      onClose={onClose}
    />
  );
};
