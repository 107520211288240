import * as React from "react";

const SvgArrowLeft16Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="Icon"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 8h13M8 1L1 8l7 7"
    />
  </svg>
);
export default SvgArrowLeft16Px;
