export const RotateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#FFFFFF"
    height="26"
    viewBox="0 0 24 26"
    width="24"
  >
    <g>
      <path d="M18.02,9.5c0.33,0.56,0.59,1.18,0.76,1.82c0.11,0.43,0.51,0.73,0.96,0.73h0.01c0.65,0,1.14-0.61,0.98-1.23 c-0.21-0.84-0.54-1.63-0.98-2.35c-0.33-0.55-1.11-0.63-1.56-0.18L18.17,8.3C17.85,8.62,17.79,9.11,18.02,9.5z M16.76,19.24 L16.76,19.24c-0.32-0.32-0.82-0.39-1.21-0.16c-0.56,0.33-1.17,0.59-1.82,0.76C13.3,19.95,13,20.34,13,20.79v0.01 c0,0.65,0.61,1.14,1.23,0.98c0.84-0.21,1.63-0.55,2.35-0.98C17.14,20.47,17.22,19.69,16.76,19.24z M18.77,14.79 c-0.17,0.65-0.42,1.26-0.76,1.82c-0.23,0.39-0.16,0.88,0.16,1.2l0.01,0.01c0.46,0.46,1.23,0.38,1.56-0.18 c0.43-0.72,0.76-1.51,0.98-2.35c0.16-0.63-0.33-1.23-0.98-1.23h-0.01C19.28,14.06,18.89,14.36,18.77,14.79z M4.73,18.3 c1.32,1.79,3.12,2.92,5.05,3.41C10.4,21.87,11,21.37,11,20.73v0c0-0.45-0.3-0.85-0.73-0.96c-1.53-0.38-2.95-1.27-3.98-2.72 c-1.68-2.35-1.72-5.6-0.09-7.98c1.41-2.06,3.6-3.05,5.8-3.05v1.77c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79c0.2-0.2,0.2-0.51,0-0.71 l-2.79-2.79C12.54,1.54,12,1.76,12,2.21v1.81c-2.76,0-5.51,1.24-7.33,3.76C2.42,10.89,2.45,15.21,4.73,18.3z" />
    </g>
  </svg>
);
