import { useRef } from "react";
import { styled, Box } from "@boligportal/juice";
import { useIntersectionObserver } from "components/animation/hooks/useIntersectionObserver";
import { LatLngLocation } from "components/interfaces/location";
import { MapWithPin } from "components/map_with_pin";

type Props = {
  location: LatLngLocation | null;
  minZoom: number;
  initialZoom: number;
};

export const LazyLoadedMap = ({ location, minZoom, initialZoom }: Props) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const observerRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  });

  if (!location) return null;

  const elementEnteredViewport = observerRef?.isIntersecting ?? false;

  return (
    <BoxWithMapTilerMatchingBgColor
      height={{
        xs: "320px",
        md: "600px",
      }}
      ref={triggerRef}
    >
      {elementEnteredViewport && (
        <StyledMapWithPin
          location={location}
          minZoom={minZoom}
          initialZoom={initialZoom}
        />
      )}
    </BoxWithMapTilerMatchingBgColor>
  );
};

const StyledMapWithPin = styled(MapWithPin)`
  min-height: 1;
  height: 100%;
`;

const BoxWithMapTilerMatchingBgColor = styled(Box)`
  background-color: #eee9e2;
`;
