import * as React from "react";
import {
  BrowserRouter,
  Route,
  RouteProps,
  StaticRouter,
} from "react-router-dom";
import { App } from "components/app";
import { AppProps } from "components/interfaces/app_props";
import { TrackingErrorsBoundary } from "lib/tracking/errors";

export const IsServerSideContext = React.createContext(false);
export const RouteContext = React.createContext<RouteProps>({});

export function WrapComponentInApp({
  component,
  appProps,
  isServerSide,
  url,
}: {
  component: React.ComponentType;
  appProps: AppProps;
  isServerSide?: boolean;
  url?: string;
}) {
  // Always treat initial render as server side
  const [isServerSideContextValue, setIsServerSideContextValue] =
    React.useState(true);

  React.useEffect(() => {
    setIsServerSideContextValue(isServerSide || false);
  }, [isServerSide]);

  const RoutedAppComponent = (
    <Route
      path="/"
      render={(routeProps) => (
        <RouteContext.Provider value={routeProps}>
          <App
            appProps={appProps}
            component={component}
          />
        </RouteContext.Provider>
      )}
    />
  );

  return (
    <IsServerSideContext.Provider value={isServerSideContextValue}>
      {isServerSide ? (
        <StaticRouter
          basename={appProps.page_props.router_base}
          location={url}
        >
          {RoutedAppComponent}
        </StaticRouter>
      ) : (
        <TrackingErrorsBoundary fallback={() => <>Error!</>}>
          <BrowserRouter basename={appProps.page_props.router_base}>
            {RoutedAppComponent}
          </BrowserRouter>
        </TrackingErrorsBoundary>
      )}
    </IsServerSideContext.Provider>
  );
}
