import * as React from "react";
import { useNotifications } from "@boligportal/juice";
import * as api from "api";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import { DomuTechDialog, DomutechSource } from "leads/Domutech/DomuTechDialog";
import { t } from "lib/i18n";

type RentableEnergyLabelCandidate = {
  address: string;
  rentableId: number;
  onSuccess: () => void;
  source: DomutechSource;
};

type RentableEnergyLabelContextValue = {
  open: boolean;
  candidate: RentableEnergyLabelCandidate | null;
  setRentableEnergyLabelCandidate: (
    candidate: RentableEnergyLabelCandidate | null,
  ) => void;
  onCancel: () => void;
};

const RentableEnergyLabelContext =
  React.createContext<RentableEnergyLabelContextValue>(null!);

const RentableEnergyLabelDialog = () => {
  const { candidate, onCancel } = React.useContext(RentableEnergyLabelContext);

  if (!candidate) {
    return null;
  }

  return (
    <DomuTechDialog
      open
      onClose={onCancel}
      rentable_id={candidate.rentableId}
      source={candidate.source}
    />
  );
};

type ProviderProps = {
  children: React.ReactNode;
};

export const RentableEnergyLabelProvider = ({ children }: ProviderProps) => {
  const [rentableEnergyLabelCandidate, setRentableEnergyLabelCandidate] =
    React.useState<RentableEnergyLabelCandidate | null>(null);
  return (
    <RentableEnergyLabelContext.Provider
      value={{
        open: Boolean(rentableEnergyLabelCandidate),
        candidate: rentableEnergyLabelCandidate,
        onCancel: () => setRentableEnergyLabelCandidate(null),
        setRentableEnergyLabelCandidate,
      }}
    >
      {children}
      <RentableEnergyLabelDialog />
    </RentableEnergyLabelContext.Provider>
  );
};

export const useRentableEnergyLabelRetriever = () => {
  const context = React.useContext(RentableEnergyLabelContext);
  const [isRetriving, setIsRetrieving] = React.useState(false);
  const { addNotification } = useNotifications();

  if (!context) {
    throw new ContextProviderMissingError(
      "useRentableEnergyLabelRetriever",
      "RentableEnergyLabelProvider",
    );
  }

  const { setRentableEnergyLabelCandidate } = context;

  const setEnergyLabelCandidate = (
    candidate: RentableEnergyLabelCandidate | null,
  ) => {
    if (candidate) {
      setIsRetrieving(true);
      api
        .retrieveAndUpdateEnergyLabel(candidate.rentableId)
        .then((response) => {
          if (response.rating) {
            addNotification({
              title: t("energy_label.fetching.success.title"),
              content: t("energy_label.fetching.success.content", {
                address: candidate.address,
                energy_rating: response.rating,
              }),
              autoDismiss: true,
            });
            candidate.onSuccess();
          } else {
            setRentableEnergyLabelCandidate(candidate);
          }
        })
        .finally(() => {
          setIsRetrieving(false);
        });
    } else {
      setRentableEnergyLabelCandidate(candidate);
    }
  };

  return {
    isRetrivingEnergyLabel: isRetriving,
    setRentableEnergyLabelCandidate: setEnergyLabelCandidate,
  };
};
