import { useMemo } from "react";
import { ImageSlider, styled } from "@boligportal/juice";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { getAdImages } from "lib/image";

const StyledImageWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-height: 260px;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius.md};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-height: 230px;
    border-radius: ${(props) => props.theme.borderRadius.sm};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const StyledActionLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

const IMG_W = 600;
const IMG_H = 400;
const NO_IMAGE_URL = "/static/images/no-image.jpg";
const NO_IMAGE_EXP = /\/static\/images\/no-image.jpg/g;

type Props = {
  ad: Ad;
  isDisabled?: boolean;
  actionsLayer: React.ReactNode;
};

export const NewBuildSlider = ({ ad, isDisabled, actionsLayer }: Props) => {
  const src = useMemo(() => {
    const convertToAbsoluteUrl = (url: string) => {
      if (App.settings.isEmbedded) {
        return url.replace(
          NO_IMAGE_EXP,
          `${App.settings.apiBaseUrl}${NO_IMAGE_URL}`,
        );
      }

      return url;
    };

    const url = getAdImages({
      ad: ad,
      width: IMG_W,
      height: IMG_H,
      crop: true,
    });

    return url.map((item) => convertToAbsoluteUrl(item));
  }, [ad]);

  const images = isDisabled
    ? src.slice(0, 1) // hide slider arrows if disabled, so show only one image
    : src.slice(0, 5); // show max 5 images

  return (
    <>
      <StyledActionLayer>{actionsLayer}</StyledActionLayer>

      <StyledImageWrapper>
        {isDisabled && <StyledOverlay />}

        <ImageSlider
          images={images}
          slidesOnPage={1}
          compact
        />
      </StyledImageWrapper>
    </>
  );
};
