import { useQuery } from "react-query";
import { API } from "lib/api";

export const adKeys = {
  similarAds: (id: number | undefined) => ["similarAds", id] as const,
};

export const useSimilarAds = (id: number | undefined) =>
  useQuery(adKeys.similarAds(id), () => API.getSimilarAds(id), {
    enabled: id !== undefined,
  });
