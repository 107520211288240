import { CheckField, Labelled, Markdown, styled } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";

const StyledLabelled = styled(Labelled)`
  span {
    position: absolute;
  }
`;

type Props = {
  onChange: (value: boolean) => void;
  policyCheckErrorMessage: boolean;
};

const PaymentPolicyCheckfield = ({
  onChange,
  policyCheckErrorMessage,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <>
      <CheckField
        required
        onChange={handleChange}
        label={
          <Markdown
            overrides={{
              a: {
                color: "brand",
                newTab: true,
                underline: "hover",
              },
            }}
          >
            {t("product_explainer.payments_policy", {
              brand_name: App.settings.brand_name,
              url: App.settings.routes.payments_policy,
            })}
          </Markdown>
        }
      />

      {policyCheckErrorMessage && (
        <StyledLabelled
          errorText={t("product_explainer.payments_policy.required")}
        ></StyledLabelled>
      )}
    </>
  );
};

export default PaymentPolicyCheckfield;
