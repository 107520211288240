import { useReducer, useCallback, useEffect } from "react";
import {
  Box,
  Center,
  Dialog,
  DialogContent,
  DialogHeader,
  styled,
  Text,
} from "@boligportal/juice";
import { AppActions } from "components/Providers/AppActionsProvider";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { ModalActions } from "components/modal_controller";
import { PaymentSpinner } from "components/payment_spinner";
import { StartSubscriptionModal } from "components/start_subscription_modal";
import { calculateTotalPriceFromPlans } from "features/payments_feature/utils/calculateTotalPriceFromPlans";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { withModalActions } from "lib/injectors";
import { Tracking } from "lib/tracking/common";
import { FacebookTrackingEvent } from "lib/tracking/facebook";
import { OrderInfo } from "./quickpay_order_info";

declare global {
  interface Window {
    cardAccepted: (paymentMethodId: number) => void;
  }
}
interface QuickPayPaymentModalProps {
  onSuccess: () => void;
  onSubscriptionActivated?: () => void;
  appActions: AppActions;
  modalActions: ModalActions;
  plans: Plan[];
  adId: number | null;
  title: string;
}

interface QuickPayPaymentModalState {
  paymentUrl: string | null;
  orderId: string | null;
  cardAccepted: boolean;
  paymentMethodId: number | null;
  isWaitingForIFrameToLoad: boolean;
}

const checkoutFormVirtualPageURL = "/payment/checkout_form";

const QuickPayPaymentModalComponent = ({
  adId,
  appActions,
  modalActions,
  onSuccess,
  plans,
  title,
  onSubscriptionActivated,
}: QuickPayPaymentModalProps) => {
  const [state, setState] = useReducer<
    React.Reducer<QuickPayPaymentModalState, Partial<QuickPayPaymentModalState>>
  >(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      paymentUrl: null,
      orderId: null,
      cardAccepted: false,
      paymentMethodId: null,
      isWaitingForIFrameToLoad: true,
    },
  );

  const getPaymentFormUrl = useCallback(() => {
    const amount = calculateTotalPriceFromPlans(plans);
    API.getPaymentLink(App.settings.language, amount, plans, adId).then(
      (link) => {
        setState({
          paymentUrl: link.url,
          orderId: link.order_id,
        });
      },
    );
  }, [adId, plans]);

  useEffect(() => {
    Tracking.trackVirtualPageview(checkoutFormVirtualPageURL);
    if (adId) {
      FacebookTrackingEvent.trackInitiateCheckout(adId);
    }
    window.cardAccepted = (paymentMethodId: number) => {
      setState({
        paymentMethodId: paymentMethodId,
        cardAccepted: true,
      });
    };
    getPaymentFormUrl();
  }, [adId, getPaymentFormUrl]);

  const paymentFailed = () => {
    setState({
      paymentUrl: null,
      cardAccepted: false,
      paymentMethodId: null,
    });
    getPaymentFormUrl();
  };

  // This is when the QuickPay form element are loaded into the iframe.
  const handleIFrameLoad = () => {
    setState({
      isWaitingForIFrameToLoad: false,
    });
  };

  const {
    cardAccepted,
    isWaitingForIFrameToLoad,
    orderId,
    paymentMethodId,
    paymentUrl,
  } = state;

  return cardAccepted && paymentMethodId ? (
    <StartSubscriptionModal
      onClose={onSuccess}
      paymentMethodId={paymentMethodId}
      onPurchaseComplete={onSuccess}
      onSubscriptionActivated={onSubscriptionActivated}
      paymentFailedAction={paymentFailed}
      plans={plans}
      adId={adId}
      appActions={appActions}
    />
  ) : (
    <Dialog
      open
      size="medium"
      onClose={modalActions.closeModal}
      hideOnClickOutside={false}
    >
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>
        <Box
          minHeight="100px"
          mb="10px"
        >
          <OrderInfo plans={plans} />
        </Box>
        <Box
          height="500px"
          width="100%"
        >
          <Box width="100%">
            {paymentUrl && !cardAccepted && (
              <QuickPayIframe
                data-test-id="QuickPayIframe"
                isWaitingForIFrameToLoad={isWaitingForIFrameToLoad}
                src={paymentUrl}
                onLoad={handleIFrameLoad}
              />
            )}
          </Box>
          {isWaitingForIFrameToLoad && (
            // Show a spinner while the payment form is loading...
            <Center>
              <PaymentSpinner submitted={false} />
            </Center>
          )}
        </Box>
        {orderId && (
          <Text color="muted">
            {t("payment_modal.order_info.order_id")} {orderId}
          </Text>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const QuickPayPaymentModal = withModalActions<QuickPayPaymentModalProps>(
  QuickPayPaymentModalComponent,
);

const QuickPayIframe = styled.iframe<{ isWaitingForIFrameToLoad: boolean }>`
  display: ${(props) => (props.isWaitingForIFrameToLoad ? "none" : "block")};
  width: 100%;
  height: 500px;
  border: none;
`;
