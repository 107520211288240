import { useQuery } from "react-query";
import * as api from "api";
import { useUser } from "components/Providers/UserProvider";
import { OrderType, FavoriteCardStatus } from "lib/enums";

export const favoriteKeys = {
  favouriteAds: () => ["getFavouriteAds"] as const,
  favouriteTopAds: (city: string) => ["getFavouriteTopAds", city] as const,
  favouriteListing: () => ["getFavouriteListing"] as const,
  favouriteAdsWithOffsetAndLimit: (
    offset: number,
    limit: number,
    status: FavoriteCardStatus,
    sorting: OrderType,
  ) =>
    [...favoriteKeys.favouriteAds(), offset, limit, status, sorting] as const,
};

export const useListingFavorites = () => {
  const { user } = useUser();
  const { data } = useQuery(
    favoriteKeys.favouriteListing(),
    () => api.getFavoriteListing(),
    {
      enabled: user !== null,
    },
  );

  return {
    favorites: data ? data.favorite_ad_ids : [],
  };
};

export const useGetTopFavorites = (city: string) => {
  const { user } = useUser();

  return useQuery(
    favoriteKeys.favouriteTopAds(city),
    () =>
      api.getTopFavouriteAdsList({
        city,
      }),
    {
      enabled: user !== null && city !== "",
    },
  );
};

/*
1, Newest first (Default sorting option)
2, Price (Cheapest first)
3, Price (Most expensive first)
4, Size (Largest first)
5, Size (Smallest first)
*/

export const useGetFavourites = (params: {
  offset: number;
  limit: number;
  status: FavoriteCardStatus;
  sorting: OrderType;
}) => {
  const { offset, limit, status, sorting } = params;
  const { user } = useUser();

  return useQuery(
    favoriteKeys.favouriteAdsWithOffsetAndLimit(offset, limit, status, sorting),
    () =>
      api.getFavouriteAdsList({
        offset,
        limit,
        status,
        sorting,
      }),
    {
      enabled: user !== null,
      keepPreviousData: true,
      select: (data) => ({
        ads: data.ads,
        allCount: data.all_count,
      }),
    },
  );
};
