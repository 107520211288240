import { AdStates } from "lib/enums";
import { t } from "lib/i18n";

type BadgeState =
  | "success"
  | "danger"
  | "warning"
  | "important"
  | "disabled"
  | undefined;

interface ListingStateInfo {
  label: string;
  title: string;
  description: string;
  color: BadgeState;
}

export const getListingStateInfo = (listingState: AdStates) => {
  const stateInfos: { [key in AdStates]: ListingStateInfo } = {
    [AdStates.ACTIVE_APPROVED]: {
      label: t("rentable_state.advertised"),
      title: t("listing_state.advertised.title"),
      description: t("listing_state.advertised.description"),
      color: "success",
    },

    [AdStates.INACTIVE_REJECTED]: {
      color: "danger",
      title: t("listing_state.rejected.title"),
      description: t("This ad is currently rejected."),
      label: t("rentable_state.rejected"),
    },

    [AdStates.RESERVED_APPROVED]: {
      color: "warning",
      title: t("listing_state.reserved.title"),
      label: t("rentable_state.reserved"),
      description: t("listing_state.reserved.description"),
    },

    [AdStates.INACTIVE_PENDING_APPROVAL]: {
      color: "warning",
      title: t("listing_state.waiting_approval.title"),
      label: t("rentable_state.waiting_approval"),
      description: t("listing_state.waiting_approval.description"),
    },

    [AdStates.RENTED_OUT_APPROVED]: {
      label: t("rentable_state.rented_out_approved"),
      title: t("listing_state.rented_out_approved.title"),
      description: t("listing_state.rented_out_approved.description"),
      color: "disabled",
    },

    [AdStates.INACTIVE_DRAFT]: {
      label: "Inactive",
      title: t("listing_state.inactive_draft.title"),
      description: t("listing_state.inactive_draft.description"),
      color: undefined,
    },

    [AdStates.DRAFT]: {
      label: t("rentable_state.draft"),
      title: t("listing_state.draft.title"),
      description: t("listing_state.draft.description"),
      color: undefined,
    },
  };

  return stateInfos[listingState];
};
