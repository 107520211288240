import { useState, useEffect } from "react";
import { blacklistReasonEnum } from "lib/enums";

export const useColorCategorizedBlacklistReasons = (
  allReasons: blacklistReasonEnum[],
) => {
  const [yellowReasons, setYellowReasons] = useState<blacklistReasonEnum[]>();
  const [redReasons, setRedReasons] = useState<blacklistReasonEnum[]>();

  useEffect(() => {
    const yellowCategory = [
      "profile_sharing",
      "company_deal",
      "phished",
      "social_housing",
      "maybe_scammer",
      "other_yellow",
    ];
    const newYellowReasons: blacklistReasonEnum[] = [];
    const newRedReasons: blacklistReasonEnum[] = [];
    categorizeReasonsIntoColorSegments(
      allReasons,
      newYellowReasons,
      newRedReasons,
      yellowCategory,
    );

    if (newYellowReasons.length > 0) {
      setYellowReasons(newYellowReasons);
    }

    if (newRedReasons.length > 0) {
      setRedReasons(newRedReasons);
    }
  }, [allReasons]);

  return {
    yellowReasons,
    redReasons,
  };
};

const categorizeReasonsIntoColorSegments = (
  reasons: blacklistReasonEnum[],
  yellow: blacklistReasonEnum[],
  red: blacklistReasonEnum[],
  yellowCategory: string[],
) => {
  reasons.forEach((reason) => {
    if (reason === undefined || !reason) {
      return;
    }

    if (yellowCategory.includes(reason)) {
      yellow.push(reason);
    } else {
      red.push(reason);
    }
  });
};
