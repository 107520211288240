import { Button, IconPersonCircleOutline } from "@boligportal/juice";
import { Colleague } from "../interfaces/Colleague";
import { ImpersonateCompanyColleague } from "../useImpersonateCompanyColleague";

export const ImpersonateableColleague = ({
  colleague,
  impersonateCompanyColleague,
}: {
  colleague: Colleague;
  impersonateCompanyColleague: ImpersonateCompanyColleague;
}) => {
  const clickHandler = () => {
    impersonateCompanyColleague(colleague.id);
  };

  const getDispayName = () => {
    const hasName = colleague.name.length > 0;
    const hasEmail = colleague.email.length > 0;
    if (hasName && hasEmail) {
      return `${colleague.name}, ${colleague.email}`;
    }
    if (hasName && !hasEmail) {
      return colleague.name;
    }
    if (!hasName && hasEmail) {
      return colleague.email;
    }
    return "-";
  };

  return (
    <Button
      icon={IconPersonCircleOutline}
      text
      onClick={clickHandler}
    >
      {getDispayName()}
    </Button>
  );
};
