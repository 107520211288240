import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  DialogContent,
  DialogFooter,
  DialogForm,
  DialogHeader,
  Link,
  Notice,
  styled,
  Text,
  TextField,
} from "@boligportal/juice";
import { App } from "components/app";
import { LoginResult } from "lib/api";
import { SignupContext } from "lib/enums";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";
import { AuthView, LoginSignupContextTranslation } from "../auth_modal";

interface LoginViewState {
  alertMessage?: string | null;
  fieldErrors?: {
    [field: string]: string;
  };
  wasValidated?: boolean;
  email?: string;
}

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
`;

export const LoginView = (props: {
  authActions: any;
  context?: SignupContext;
}) => {
  const { authActions, context } = props;
  const [loginViewState, setLoginViewState] = useState<LoginViewState>({
    alertMessage: null,
    fieldErrors: {},
    wasValidated: false,
    email: "",
  });

  useEffect(() => {
    Tracking.trackVirtualPageview("/login", getTitle());
  }, []);

  const { register, handleSubmit, watch } = useForm({
    mode: "onChange",
  });

  const watchedFields = watch();

  const switchToSignUpView = () =>
    authActions.switchView(AuthView.SIGNUPWITHEMAIL);

  const handleLogin = (data: { username: string; password: string }) => {
    if (!data.username || !data.password) {
      return;
    }

    authActions.login(data.username, data.password).then((success) => {
      if (success === LoginResult.successful) {
        authActions.onLoginSuccessful();
      } else if (success === LoginResult.invalid_password) {
        setLoginViewState({
          alertMessage: t("Invalid username/password."),
        });
      } else {
        setLoginViewState({
          alertMessage: t("Something went wrong. Try again."),
        });
      }
    });
  };

  const renderAlert = () => {
    if (loginViewState.alertMessage) {
      return <Notice type="danger">{loginViewState.alertMessage}</Notice>;
    } else {
      return null;
    }
  };

  const getTitle = () => {
    if (context) {
      return `${t("login_view.title")} ${
        LoginSignupContextTranslation()[context]
      }`;
    }
    return t("login_view.title");
  };

  return (
    <>
      <DialogHeader>{getTitle()}</DialogHeader>
      <DialogForm
        onSubmit={handleSubmit(handleLogin)}
        noValidate
      >
        <DialogContent>
          {renderAlert()}

          <TextField
            testId={"loginUsername"}
            type="text"
            {...register("username")}
            label={t("signup.E-mail address")}
          />

          <TextField
            testId={"loginPassword"}
            type="password"
            {...register("password")}
            label={t("login.Password")}
          />

          <Button
            testId={"loginSubmit"}
            type="submit"
            variant="branded"
            fullWidth
            disabled={!watchedFields["username"] || !watchedFields["password"]}
          >
            {t("login_view.login_button")}
          </Button>
          <Box
            textAlign="center"
            mt={2}
          >
            <StyledLink
              href={App.settings.routes.reset_password}
              newTab
            >
              {t("login_view.footer.Forgot password?")}
            </StyledLink>
          </Box>
        </DialogContent>
      </DialogForm>
      <DialogFooter>
        <Box
          width="100%"
          textAlign="center"
        >
          <Text>{t("login_view.footer.New user?")} </Text>
          <Button
            variant="branded"
            text
            onClick={switchToSignUpView}
            testId={"switchToSignUpView"}
          >
            {t("login_view.footer.Sign up here")}
          </Button>
        </Box>
      </DialogFooter>
    </>
  );
};
