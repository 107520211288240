import { Market } from "components/markets/market_settings";
import { clientFormattedAddressInDenmark } from "./concretions/clientFormattedAddressInDenmark";
import { clientFormattedAddressInSweeden } from "./concretions/clientFormattedAddressInSweeden";
import { RentalAddressValueObject } from "./types";

export const createClientFormattedAddress = (
  market: Market,
  rentalAddressValueObject: RentalAddressValueObject,
): string => {
  if (market === Market.BOLIGPORTAL_DK) {
    return clientFormattedAddressInDenmark(rentalAddressValueObject);
  }

  if (market === Market.BOSTADSPORTAL_SE) {
    return clientFormattedAddressInSweeden(rentalAddressValueObject);
  }

  throw `Missing a Market implementation to create enhanced formatted address for market: ${market}`;
};
