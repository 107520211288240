import { Button, IconAdd, IconStopCircleOutline } from "@boligportal/juice";
import * as CustomerServiceApi from "../../../../../lib/customerServiceApi";

const CompanyDealAccess = ({
  user_id,
  year_deal,
}: {
  user_id: number;
  year_deal: boolean;
}) => {
  const handleAddCompanyDeal = () => {
    CustomerServiceApi.setCompanyDealAccess(user_id)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const handleRemoveCompanyDeal = () => {
    CustomerServiceApi.removeCompanyDealAccess(user_id)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  function CompanyDeal(props) {
    if (props.year_deal) {
      return (
        <Button
          variant="subtle"
          text
          onClick={handleRemoveCompanyDeal}
        >
          <IconStopCircleOutline inline />
          Remove company deal access
        </Button>
      );
    } else {
      return (
        <Button
          variant="subtle"
          text
          onClick={handleAddCompanyDeal}
        >
          <IconAdd inline />
          Add company deal access
        </Button>
      );
    }
  }

  return (
    <>
      <CompanyDeal year_deal={year_deal} />
    </>
  );
};

export default CompanyDealAccess;
