import { useEffect } from "react";
import { useRentableListingProductsQuery } from "api/queries";
import { Plan } from "components/interfaces/plan";
import { useStartSubscriptionModal } from "features/upsell/useStartSubscriptionModal";
import { dateHoursOld } from "lib/date";
import { TrackingEvent } from "lib/tracking/events";
import { RentableUpsellDialog } from ".";
import {
  deleteRentableDialogTrigger,
  getRentableDialogTrigger,
  setRentableDialogTrigger,
  UpsellDialogTrigger,
} from "./rentableDialogTrigger";
import { useHandleUpsellPlanPurchase } from "./useHandleUpsellPlanPurchase";

export const RentableUpsellDialogContainer = ({
  rentableId,
  open,
  close,
  onUpsellPurchased,
  title,
  trigger,
  visiblePlans,
}: {
  rentableId: number | null;
  open: boolean;
  close: () => void;
  onUpsellPurchased: () => void;
  title?: string;
  trigger: UpsellDialogTrigger;
  advertisedDate?: string;
  visiblePlans?: string[];
}) => {
  const { data, isLoading } = useRentableListingProductsQuery(rentableId);
  const { openPaymentModal } = useHandleUpsellPlanPurchase();

  useEffect(() => {
    if (open) {
      TrackingEvent.showUpsellDialog(trigger);
    }
  }, [open, trigger]);

  useStartSubscriptionModal({
    onSuccess: () => {
      close();
      onUpsellPurchased();
    },
    onSubscriptionActivated: () => {
      TrackingEvent.upsellDialogPaymentSuccess(getRentableDialogTrigger());
      deleteRentableDialogTrigger();
    },
  });

  const onPlanSelect = (plan: Plan) => {
    if (!data?.listingId || !rentableId) {
      return;
    }

    close();
    openPaymentModal({
      plan,
      rentableId,
      listingId: data.listingId,
      onSubscriptionActivated: () => {
        onUpsellPurchased();
        TrackingEvent.upsellDialogPaymentSuccess(trigger);
      },
      onBeforeMobilePayRedirect: () => setRentableDialogTrigger(trigger),
    });
  };

  let isAdLessThan24HoursOld = false;

  if (data) {
    const numHoursOld = dateHoursOld(new Date(data.advertisedDate));
    isAdLessThan24HoursOld = numHoursOld < 24 || !data.advertisedDate;
  }

  const getFilteredPlans = (): Record<string, Plan[]> => {
    if (!data?.plans) {
      return {};
    }

    if (visiblePlans) {
      return Object.keys(data.plans).reduce((acc, key) => {
        if (visiblePlans.includes(key)) {
          acc[key] = data.plans[key];
        }

        return acc;
      }, {});
    }

    return data.plans;
  };

  return (
    <RentableUpsellDialog
      isAdLessThan24HoursOld={isAdLessThan24HoursOld}
      open={open}
      title={title}
      close={close}
      plans={getFilteredPlans()}
      loading={isLoading}
      accountInfo={data?.accountInfo || null}
      selectPlan={onPlanSelect}
      activeUpsellSubscriptions={data?.activeUpsellSubscriptions || []}
    />
  );
};
