import { useMutation } from "react-query";
import {
  activateWinbackSubscription,
  createRentable,
  deleteRentable,
} from "api";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { Reasons } from "apps/property_owner/my_rentables/views/rentable_detail/views/listings/components/upsell/components/CancelSubscription/CancelSubscriptionConfirmDialog";
import { API } from "lib/api";
import { t } from "lib/i18n";

type Error = {
  response: {
    status: number;
    data: {
      errors: {
        error: {
          message: string;
          code: string;
        };
      }[];
    };
  };
};

export const useRentableDeletionMutation = () => {
  const { addErrorNotification } = useErrorNotification();

  const canDeleteRentableDryRun = useMutation((rentableId: number) =>
    deleteRentable(rentableId, true),
  );

  const deleteRentableMutation = useMutation(
    (rentableId: number) => deleteRentable(rentableId),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: Error) => {
        addErrorNotification(t("Something went wrong. Try again."));
      },
    },
  );

  return {
    canDeleteRentableDryRun,
    deleteRentableMutation,
  };
};

export const useCreateRentableMutation = () => useMutation(createRentable);

export const useActivateWinbackUpsellPlanMutation = () => {
  const activateWinbackPlanMutation = useMutation(
    ({ planId, subscriptionId }: { planId: number; subscriptionId: number }) =>
      activateWinbackSubscription(planId, subscriptionId),
  );
  return activateWinbackPlanMutation;
};

export const useCancelSubscriptionMutation = () => {
  const cancelSubscriptionMutation = useMutation(
    ({ subscriptionId, reason }: { subscriptionId: number; reason: Reasons }) =>
      API.cancelSubscriptions({
        subscription_ids: [subscriptionId],
        cancellation_reason: reason,
      }),
  );
  return cancelSubscriptionMutation;
};
