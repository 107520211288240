import {
  Dropdown,
  Button,
  IconArrowDown,
  DropdownMenuButton,
} from "@boligportal/juice";

const BlacklistGetUsers = ({ user_id }: { user_id: number }) => {
  const handleGetUsersWhoContactedUser = () => {
    window.location.href = `/customer-service/api/user/users-who-contacted-user/${user_id}/`;
  };

  const handleGetUsersWhoUserContacted = () => {
    window.location.href = `/customer-service/api/user/users-who-user-contacted/${user_id}/`;
  };

  return (
    <>
      <Dropdown
        opener={
          <Button
            variant="subtle"
            text
          >
            <IconArrowDown inline />
            Get contacted users
          </Button>
        }
        items={[
          <DropdownMenuButton onClick={handleGetUsersWhoContactedUser}>
            Get users who contacted this user
          </DropdownMenuButton>,
          <DropdownMenuButton onClick={handleGetUsersWhoUserContacted}>
            Get users who this user contacted
          </DropdownMenuButton>,
        ]}
      ></Dropdown>
    </>
  );
};

export default BlacklistGetUsers;
