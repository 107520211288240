import { t } from "lib/i18n";
import { TenancyPaymentFrequency } from "./TenancyPaymentFrequency";
import { TenancyPaymentType } from "./TenancyPaymentType";
import { TenancyRentEntity } from "./TenancyRentEntity";
import { TenancyTenant } from "./TenancyTenant";
import { TenancyType } from "./TenancyType";

export type TenancyId = number;
export interface Tenancy {
  is_collecting_rent: boolean;
  company_cvr?: string;
  company_name?: string;
  deposit?: number;
  move_in_date: null | string;
  move_out_date: null | string;
  note: string;
  recurringRent: TenancyRentEntity[];
  prepaid_rent?: number;
  tenancy_id: TenancyId;
  tenancy_type: TenancyType;
  tenants: TenancyTenant[];
  /*
   * All tenants names as a single string with applied business rules
   * about how to show none, 1 or more tenants.
   */
  tenants_display_label: string;
  first_payment_date: string;
  final_payment_date: null | string;
  payment_frequency: TenancyPaymentFrequency;
  payment_type: TenancyPaymentType;
  payment_subscription_provider_id: string;
}

export const createTenantsDisplayLabel = (tenants: TenancyTenant[]) => {
  const names: string[] = [];

  tenants.forEach((t) => {
    let name = "";

    if (t.first_name && t.first_name.trim() !== "") {
      name = t.first_name.trim();
    }

    if (t.last_name && t.last_name.trim() !== "") {
      if (name !== "") {
        name = `${name} ${t.last_name.trim()}`;
      } else {
        name = t.last_name.trim();
      }
    }

    if (name !== "") {
      names.push(name);
    }
  });

  const numNames = names.length;

  if (numNames === 1) {
    return names[0];
  } else if (numNames === 2) {
    return `${names[0]} ${t("common.conjunctions.and")} ${names[1]}`;
  } else if (numNames === 3) {
    const allButLast = names.slice(0, -1);
    const last = names[numNames - 1];

    return `${allButLast.join(", ")} ${t("common.conjunctions.and")} ${last}`;
  } else if (numNames > 3) {
    const firstTwo = [names[0], names[1]];
    const andNumOther = t("common.tenancy.and_{num_other_tenants}", {
      num_other_tenants: numNames - 2,
    });

    return `${firstTwo.join(", ")} ${andNumOther}`;
  }

  return t("common.people.tenant.no_name");
};
