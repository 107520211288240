import { UseFormSetValue } from "react-hook-form";
import * as api from "api";
import { AdCategories } from "lib/enums";
import { CreateRentableForm } from "../types";

/**
 * Checks if the rentable can be created using the address and category
 * and updates the fieldName.disabled appropriately
 */
export const checkAndUpdateDisabledState = async (
  fieldName: `rentables.${number}`,
  address: string,
  category: AdCategories,
  setValue: UseFormSetValue<CreateRentableForm>,
) => {
  const { can_create_rentable } = await api.checkCanCreateRentable(
    address,
    category,
  );

  // @ts-ignore RHF type isn't great when working with arrays.
  setValue(`${fieldName}.disabled` as const, !can_create_rentable);
};
