import * as React from "react";
import {
  Box,
  Flex,
  styled,
  IconCheckmarkCircle,
  Container,
} from "@boligportal/juice";

const XXL = 1920;
const XL = 1200;

type Props = {
  headline: string;
  feature1: string;
  feature2: string;
  feature3: string;
  imageSrc: string;
  ctaButton: React.ReactNode;
};

export const FeaturesDisplayScreen = ({
  headline,
  feature1,
  feature2,
  feature3,
  ctaButton,
  imageSrc,
}: Props) => {
  const windowWidth = useWindowWidth();

  if (windowWidth === -1) {
    return null;
  }

  if (windowWidth >= XXL) {
    return (
      <XXLView
        headline={headline}
        feature1={feature1}
        feature2={feature2}
        feature3={feature3}
        ctaButton={ctaButton}
        imageSrc={imageSrc}
      />
    );
  }

  if (windowWidth >= XL) {
    return (
      <XLView
        headline={headline}
        feature1={feature1}
        feature2={feature2}
        feature3={feature3}
        ctaButton={ctaButton}
        imageSrc={imageSrc}
      />
    );
  }

  return (
    <SmallView
      headline={headline}
      feature1={feature1}
      feature2={feature2}
      feature3={feature3}
      ctaButton={ctaButton}
    />
  );
};

const XXLView = ({
  headline,
  feature1,
  feature2,
  feature3,
  imageSrc,
  ctaButton,
}: Props) => (
  <Flex
    height="100%"
    align="center"
  >
    <Flex
      pl="80px"
      align="center"
    >
      <Box
        minWidth="680px"
        width="680px"
      >
        <FeatureHeadline
          fontSize={54}
          lineHeight={64}
        >
          {headline}
        </FeatureHeadline>
        <Box height="32px" />
        <Flex column>
          <XLFeature text={feature1} />
          <Box height="16px" />
          <XLFeature text={feature2} />
          <Box height="16px" />
          <XLFeature text={feature3} />
          <Box height="40px" />
          <Box>{ctaButton}</Box>
        </Flex>
      </Box>
      <Box pl="40px">
        <FeatureImage
          w={1153}
          h={880}
          src={imageSrc}
        />
      </Box>
    </Flex>
  </Flex>
);

const XLView = ({
  headline,
  feature1,
  feature2,
  feature3,
  imageSrc,
  ctaButton,
}: Props) => (
  <Flex
    height="100%"
    align="center"
  >
    <Flex
      pl="80px"
      align="center"
    >
      <Box
        minWidth="440px"
        width="440px"
      >
        <FeatureHeadline
          fontSize={32}
          lineHeight={32}
        >
          {headline}
        </FeatureHeadline>
        <Box height="32px" />
        <Flex column>
          <XLFeature text={feature1} />
          <Box height="16px" />
          <XLFeature text={feature2} />
          <Box height="16px" />
          <XLFeature text={feature3} />
          <Box height="40px" />
          <Box>{ctaButton}</Box>
        </Flex>
      </Box>
      <Box pl="40px">
        <FeatureImage
          w={750}
          h={580}
          src={imageSrc}
        />
      </Box>
    </Flex>
  </Flex>
);

const SmallView = ({
  headline,
  feature1,
  feature2,
  feature3,
  ctaButton,
}: Omit<Props, "imageSrc">) => (
  <Flex
    height="100%"
    align="center"
  >
    <Container fluid>
      <Box
        maxWidth="460px"
        pt={{
          xs: 4,
          md: 0,
        }}
      >
        <FeatureHeadline
          fontSize={32}
          lineHeight={32}
        >
          {headline}
        </FeatureHeadline>
        <Box height="32px" />
        <Flex column>
          <XLFeature text={feature1} />
          <Box height="16px" />
          <XLFeature text={feature2} />
          <Box height="16px" />
          <XLFeature text={feature3} />
          <Box height="40px" />
          <Box>{ctaButton}</Box>
        </Flex>
      </Box>
    </Container>
  </Flex>
);

const XLFeature = ({ text }) => (
  <Flex>
    <Box pr={1}>
      <IconCheckmarkCircle
        color="success"
        size="large"
      />
    </Box>
    <XLFeatureText>{text}</XLFeatureText>
  </Flex>
);

const FeatureHeadline = styled.span<{ fontSize: number; lineHeight: number }>`
  color: ${(props) => props.theme.colorPalette.primary[700]};
  font-weight: 700;
  line-height: ${(props) => props.lineHeight}px;
  font-size: ${(props) => props.fontSize}px;
`;

const XLFeatureText = styled.span`
  color: ${(props) => props.theme.color.text.base};
  font-weight: 600;
  line-height: 21px;
  font-size: 18px;
`;

const FeatureImage = styled.div<{ w: number; h: number; src: string }>`
  background-image: ${(props) => `url(\"${props.src}\")`};
  background-size: ${(props) => props.w}px ${(props) => props.h}px;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
`;

export const useWindowWidth = () => {
  const [width, setWidth] = React.useState(-1);

  React.useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateWidth);
      updateWidth();
    }
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return width;
};
