import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Flex, IconTrashOutline, TextField } from "@boligportal/juice";
import * as CustomerServiceApi from "lib/customerServiceApi";

const GDPRDelete = (props: {
  id: number;
  email: string;
  onGdprDeleteSuccess: () => void;
}) => {
  const { register, getValues, setValue, watch } = useForm();
  const [showComfirm, setShowConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const emailFieldWatcher = watch("email");

  const handleDeleteClick = () => {
    if (!showComfirm) {
      setShowConfirm(true);
    } else {
      setIsDeleting(true);
      const confirmValue = getValues().email;

      CustomerServiceApi.gdprDeleteUser(props.id, confirmValue)
        .then((response) => {
          setValue("email", "", {
            shouldDirty: true,
          });
          setShowConfirm(false);
          setIsDeleting(false);

          const { message } = response;

          if (message) {
            alert(`Error: ${message}`);
          } else {
            props.onGdprDeleteSuccess();
          }
        })
        .catch(() => {
          alert("Unexpected error. Please try again...");
        });
    }
  };

  const handleCancelClick = () => {
    setShowConfirm(false);
  };

  const disableConfirmButton =
    showComfirm &&
    (emailFieldWatcher === undefined || emailFieldWatcher !== props.email);

  return (
    <Flex>
      {showComfirm && (
        <Flex
          gap={1}
          pr={2}
        >
          <TextField
            type="text"
            placeholder="type users email to confirm"
            autoComplete="none"
            {...register("email")}
          />
          <Button onClick={handleCancelClick}>Cancel</Button>
        </Flex>
      )}

      <Button
        text
        disabled={disableConfirmButton}
        variant="danger"
        loading={isDeleting}
        onClick={handleDeleteClick}
      >
        <IconTrashOutline
          inline
          color="danger"
        />
        {showComfirm ? "Confirm" : "GDPR Delete"}
      </Button>
    </Flex>
  );
};
export { GDPRDelete };
