import { Flex, Text } from "@boligportal/juice";

export type MovingReportInspectionDateCell = {
  movingReportInspectionDateCell: {
    dateFormatted: string;
    timeFormatted: string;
  };
};

export const movingReportInspectionDateCellRenderer = ({
  movingReportInspectionDateCell,
}: MovingReportInspectionDateCell) => (
  <Flex column>
    <Text
      block
      size="small"
    >
      {movingReportInspectionDateCell.dateFormatted}
    </Text>
    <Text
      block
      color="muted"
      size="small"
    >
      {movingReportInspectionDateCell.timeFormatted}
    </Text>
  </Flex>
);
