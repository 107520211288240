import * as React from "react";

export type ModalActions = {
  showModal: (modal: React.ReactNode | null) => void;
  closeModal: () => void;
  offsetTop: number;
  onModalOpen: (offsetTop) => void;
  onModalClose: () => void;
  isOpen: boolean;
  modal: React.ReactNode | null;
};

export const modalActions = React.createContext<ModalActions>({
  showModal: () => {},
  closeModal: () => {},
  offsetTop: 0,
  onModalOpen: () => {},
  onModalClose: () => {},
  isOpen: false,
  modal: null,
});

export const useOldModal = () => ({
  ...React.useContext(modalActions),
});

export const ModalController = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [modal, setModal] = React.useState<React.ReactNode>(null);
  const [offsetTop, setOffsetTop] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const showModal = (modal: React.ReactNode | null) => {
    setModal(modal);
  };

  const closeModal = () => {
    setModal(null);
  };

  const onModalOpen = (offsetTop) => {
    setIsOpen(true);
    setOffsetTop(-offsetTop);
  };

  const onModalClose = () => {
    setIsOpen(false);
    setOffsetTop(0);
  };

  return (
    <modalActions.Provider
      value={{
        showModal,
        closeModal,
        onModalOpen,
        onModalClose,
        offsetTop,
        isOpen,
        modal,
      }}
    >
      {children}
    </modalActions.Provider>
  );
};
