import { styled } from "@boligportal/juice";
import { getImageUrl } from "lib/image";

const StyledShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: inherit;
  z-index: 1;
`;

const StyledImg = styled.div<{ size: number }>`
  position: relative;
  display: block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  overflow: hidden;
  z-index: 0;
  background-color: ${(props) => props.theme.color.bg.tint};

  img {
    width: 100%;
    height: 100%;
  }
`;

const SquareImage = ({ src, size = 60 }) => {
  if (!src) {
    return (
      <StyledImg size={size}>
        <img src="/static/images/no-image.jpg" />
        <StyledShadow />
      </StyledImg>
    );
  }

  const imageSource = getImageUrl({
    image: {
      url: src,
    },
    width: size,
    height: size,
    crop: true,
  });

  const imageSource2x =
    getImageUrl({
      image: {
        url: src,
      },
      width: size,
      height: size,
      dpr: 2,
      crop: true,
    }) + " 2x";

  return (
    <StyledImg size={size}>
      <img
        src={imageSource}
        srcSet={imageSource2x}
      />
      <StyledShadow />
    </StyledImg>
  );
};

export { SquareImage };
