import * as React from "react";
import { Box } from "@boligportal/juice";
import { UpdateProfileImage } from "apps/user_settings/update_profile_image/update_profile_image";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { useUser } from "components/Providers/UserProvider";
import { t } from "lib/i18n";
import { Tracking } from "lib/tracking/common";

interface Props {
  setTitle(value: React.SetStateAction<string>): void;
}

export const ProfilePictureUpload = ({ setTitle }: Props) => {
  const { user } = useUser();
  const appActions = useAppActions();

  React.useEffect(() => {
    setTitle(t("profile_completion.picture_upload_modal.title"));
    Tracking.trackVirtualPageview(
      "/fill-profile-modal/image-upload",
      "Image upload",
    );
  }, []);

  return (
    <Box minHeight="198px">
      <strong>{t("profile_completion.picture_upload_form.title")}</strong>

      <UpdateProfileImage
        user={user!}
        reloadAppProps={appActions.reloadAppProps}
      />
    </Box>
  );
};
