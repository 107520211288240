import { useQuery, useQueryClient } from "react-query";
import * as api from "api";
import { AutocompleteItem, PropertyDataResponse } from "api/types";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import {
  AddressSearchCandidate,
  AddressSearchCandidateType,
} from "../components/AddressSearch/AddressSearchItemRenderer";
import { createRentableQueryKeys } from "./keys";
import { getPropertyInfo } from "./usePropertyInfo";

const oneDayInMss = 60 * 60 * 24 * 1000;

const getDuplicateAccessAddressIdFromSuggestions = (
  suggestions: AutocompleteItem[],
) => {
  const duplicateAccessAddressIds = new Set<string>();
  for (let x = 0; x < suggestions.length; x++) {
    const suggestion = suggestions[x];

    if (
      suggestion.type !== "adresse" ||
      !suggestion.access_address_id ||
      suggestions.findIndex(
        (sug) => sug.access_address_id === suggestion.access_address_id,
      ) !== x
    ) {
      continue;
    }

    duplicateAccessAddressIds.add(suggestions[x].access_address_id);
  }

  return [...duplicateAccessAddressIds];
};

export const useBulkAddressAutocomplete = (
  searchValue: string,
  containerId: string,
) => {
  const queryClient = useQueryClient();

  return useQuery<AddressSearchCandidate[]>(
    createRentableQueryKeys.bulkAutocomplete(searchValue, containerId),
    async () => {
      if (searchValue === "") {
        return [];
      }

      // We don't have an OIS equivalent for BostadsPortal, so there's nothing else to do than query the autocomplete endpoint.
      if (App.settings.market === Market.BOSTADSPORTAL_SE) {
        const suggestions = await api.getAddressAutocompleteSE(
          searchValue,
          containerId,
        );

        return createAddressSearchCandidates(suggestions);
      }

      const suggestions = await api.getAddressAutocompleteDK(searchValue);
      const duplicateAccessAddressIds =
        getDuplicateAccessAddressIdFromSuggestions(suggestions);

      let addressSearchCandidates = createAddressSearchCandidates(suggestions);

      // If there's multiple rentables sharing the same access address we want to prepend a option
      // to allow selecting all of them
      if (duplicateAccessAddressIds.length) {
        const propertyInfo = await Promise.all(
          duplicateAccessAddressIds.map((accessAddressId) =>
            getPropertyInfo(queryClient, accessAddressId),
          ),
        );

        addressSearchCandidates = [
          ...createBulkCreationOptions(propertyInfo),
          ...addressSearchCandidates,
        ];
      }

      return addressSearchCandidates;
    },
    {
      staleTime: oneDayInMss,
      keepPreviousData: true,
    },
  );
};

const createAddressSearchCandidates = (
  suggestions: AutocompleteItem[],
): AddressSearchCandidate[] =>
  suggestions.map((suggestion) => ({
    accessAddressId: suggestion.access_address_id,
    address: suggestion.address,
    type: getSuggestionType(suggestion.type),
    addressIds: [suggestion.id],
    id: suggestion.id || suggestion.address, // id is null for road names so use the address instead
    extraText: suggestion.extra_text,
  }));

const getSuggestionType = (
  type: AutocompleteItem["type"],
): AddressSearchCandidateType => {
  if (type === "adresse" || type === "Address") {
    return "address";
  }

  if (type === "Street" || type === "vejnavn") {
    return "street";
  }

  return "unknown";
};

const createBulkCreationOptions = (
  properties: PropertyDataResponse[],
): AddressSearchCandidate[] => {
  const candidates: AddressSearchCandidate[] = [];

  for (let x = 0; x < properties.length; x++) {
    const property = properties[x];
    const firstResult = property.results[0];

    if (property.results.length === 1) {
      continue;
    }

    if (firstResult) {
      candidates.push({
        accessAddressId: firstResult.property_info.access_address_id,
        address: `${firstResult.property_info.access_address}`,
        id: firstResult.property_info.access_address_id,
        addressIds: property.results.map(({ address }) => address.place_id),
        type: "bulk_create",
        extraText: null,
      });
    }
  }

  return candidates;
};
