import { fetchFromAPI } from "lib/api";

const updateImageService = async (
  adId: number,
  imageId: number,
  imageCropPayload: string,
): Promise<{ success: boolean; imageId?: number; imageUrl?: string }> => {
  const response = await fetchFromAPI(
    `/customer-service/api/ad/${adId}/ad-images/${imageId}`,
    {
      method: "PUT",
      body: imageCropPayload,
    },
  );

  const data = await response.json();

  if (response.ok) {
    return Promise.resolve({
      success: true,
      imageId: data.image_id,
      imageUrl: data.url,
    });
  } else {
    return Promise.resolve({
      success: false,
    });
  }
};

export { updateImageService };
