import { Box } from "@boligportal/juice";
import { App } from "components/app";
import { Header } from "components/header";
import {
  LocationAutoComplete,
  navigateToSuggestion,
} from "components/location_auto_complete/LocationAutoComplete";
import { MobileSearch } from "components/mobile-search/mobile_search";
import { t } from "lib/i18n";

export const HeaderWithSearch = () => (
  <Box
    borderBottom={{
      xs: true,
      md: false,
    }}
  >
    <Header
      desktopSearchComponent={
        <Box ml={2}>
          <LocationAutoComplete
            onSelect={navigateToSuggestion}
            placeholder={t("search_field.placeholder", {
              search_placeholder_city: App.settings.search_placeholder_city,
            })}
            variant="dense"
          />
        </Box>
      }
      mobileSearchComponent={
        <MobileSearch
          displayTriggerAsIcon
          onSelect={navigateToSuggestion}
        />
      }
    />
  </Box>
);
