import { UserSilhouette } from "components/icons/user_silhouette";
import { User } from "components/interfaces/user";
import { getImageUrl } from "lib/image";

interface Props {
  user: User;
  size: number;
  inverseColor?: boolean;
}

export const ProfileImage = ({ user, size, inverseColor }: Props) => {
  const styles = {
    borderRadius: "50%",
    border: "1px solid #E6E6E6",
    width: size,
    height: size,
  };

  const profileImage: string | null = user["profile_image"];

  if (!profileImage) {
    return (
      <UserSilhouette
        size={size}
        inverseColor={inverseColor}
      />
    );
  }
  return (
    <img
      src={getImageUrl({
        image: {
          url: profileImage,
        },
        width: size,
        height: size,
        crop: true,
        mask: "ellipse",
      })}
      srcSet={
        getImageUrl({
          image: {
            url: profileImage,
          },
          width: size,
          height: size,
          dpr: 2,
          crop: true,
          mask: "ellipse",
        }) + " 2x"
      }
      style={styles}
    />
  );
};
