import { Box, styled } from "@boligportal/juice";

const Options = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

interface OptionsGroupInterface<T> {
  title: string;
  options: { [key: string]: string | JSX.Element };
  value: T[];
  onChange: (value: T[]) => void;
  valueConverter: (value: string) => T;
}

export const OptionsGroup = <T extends unknown>({
  onChange,
  options,
  title,
  value,
  valueConverter,
}: OptionsGroupInterface<T>) => {
  const onChangeHandler = (event) => {
    const { checked, name } = event.target;

    const changedValue = valueConverter(name);

    if (checked) {
      onChange([...value, changedValue]);
    } else {
      onChange([...value].filter((val) => val !== changedValue));
    }
  };

  return (
    <Box mb={2}>
      <Box>
        <Box mb={1}>{title}</Box>

        <Box>
          {Object.keys(options).map((key) => (
            <Options key={key}>
              <input
                type="checkbox"
                name={key}
                onChange={onChangeHandler}
                checked={value && value.indexOf(valueConverter(key)) > -1}
                style={{
                  marginRight: "5px",
                }}
              />
              {options[key]}
            </Options>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
