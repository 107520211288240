import { configureStore } from "@reduxjs/toolkit";
import { movingReportForTenantSlice } from "./store/slices/movingReportForTenant";
import { movingReportsSlice } from "./store/slices/movingReports";

export const store = configureStore({
  reducer: {
    movingReports: movingReportsSlice.reducer,
    movingReportForTenant: movingReportForTenantSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
