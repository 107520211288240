import * as React from "react";
import { Text, SuccessSpinner, Flex } from "@boligportal/juice";

interface PaymentSpinnerProps {
  submitted: boolean;
  successHeadline?: string;
  successMessage?: string;
  extraSuccessMessage?: React.ReactNode;
}

export const PaymentSpinner = ({
  extraSuccessMessage,
  submitted,
  successHeadline,
  successMessage,
}: PaymentSpinnerProps) => (
  <Flex
    align="center"
    justify="center"
    column
  >
    <SuccessSpinner
      isSuccess={submitted}
      size="xlarge"
    />
    <Text
      mt={2}
      mb={1}
      size="h5"
      weight="headline"
      block
    >
      {successHeadline}
    </Text>

    {extraSuccessMessage !== "" && (
      <Text
        mb={3}
        size="small"
        block
      >
        {extraSuccessMessage}
      </Text>
    )}
    <Text
      mb={3}
      size="small"
    >
      {successMessage}
    </Text>
  </Flex>
);
