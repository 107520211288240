import { Badge, Box, Text, styled } from "@boligportal/juice";

const StyledText = styled(Text)`
  :first-letter {
    text-transform: capitalize;
  }
`;

type Props = {
  text: string;
  clear: () => void;
};

export const FilterBadge = ({ text, clear }: Props) => (
  <Box
    inline
    mt={1}
    mr={1}
  >
    <Badge
      onClose={clear}
      variant="solid"
      color="blue"
    >
      <StyledText size="small">{text}</StyledText>
    </Badge>
  </Box>
);
