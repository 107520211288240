import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { getMobileOperatingSystem, isTouchDevice } from "./helpers";

const DK_URL =
  "https://play.google.com/store/apps/details?id=app.movingreports.dk";
const SE_URL =
  "https://play.google.com/store/apps/details?id=app.movingreports.se";

export const GooglePlayBadge = () => {
  const isDK = App.settings.market === Market.BOLIGPORTAL_DK;

  const isAndroidTouchDevice =
    getMobileOperatingSystem() === "Android" && isTouchDevice();

  if (!isTouchDevice() || isAndroidTouchDevice) {
    return (
      <a
        href={isDK ? DK_URL : SE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`/static/images/badges/google-play-badge-${
            isDK ? "dk" : "se"
          }.svg`}
        />
      </a>
    );
  }

  return null;
};
