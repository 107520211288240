import { Box, Flex, styled, Hidden, Text, useTheme } from "@boligportal/juice";
import { AppBadges } from "apps/landingpages/app_badges/AppBadges";
import { CdnImage } from "components/CdnImage";
import { MovingReportsLandingPageContainer } from "../MovingReportsLandingPageContainer";
import { PageBlock } from "../PageBlock";

const NewDesignBadge = styled.div`
  color: #ffffff;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8bGluZWFyR3JhZGllbnQgaWQ9Imc5OTAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMTAwJSIgeTE9IjEwMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRUVCODE2IiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjRUQ2QjJEIiBvZmZzZXQ9IjEiLz4KPC9saW5lYXJHcmFkaWVudD4KPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNnOTkwKSIgLz4KPC9zdmc+);
  background-repeat: no-repeat;
  background-size: cover;
`;

const NewDesignHeader = styled.h2`
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.13px;
  color: #191919;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
  }
`;

const NewDesignSubHeader = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1c1c;
`;

interface Props {
  badgeText: string;
  headline: string;
  subHeadline: string;
  videoSource: string;
  mobileVideoSource: string;
  backgroundPicture: string;
}

export const VideoWithBackgroundSection = ({
  badgeText,
  headline,
  subHeadline,
  videoSource,
  mobileVideoSource,
  backgroundPicture,
}: Props) => {
  const theme = useTheme();
  return (
    <PageBlock
      bg="default"
      py="extraLarge"
      pyMobile="large"
    >
      <MovingReportsLandingPageContainer>
        <Flex
          textAlign="center"
          align="center"
          column
        >
          <Box my={2}>
            <NewDesignBadge>{badgeText}</NewDesignBadge>
          </Box>
          <NewDesignHeader>{headline}</NewDesignHeader>

          <Box
            mt={2}
            mb={4}
            maxWidth={{
              xs: "100%",
              sm: "500px",
            }}
          >
            <NewDesignSubHeader markdown>{subHeadline}</NewDesignSubHeader>
          </Box>

          <Box mb={4}>
            <AppBadges />
          </Box>

          <Hidden
            on={{
              xs: true,
              md: false,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: theme.shadow.lg,
                padding: theme.unit(1),
                paddingBottom: "6px",
                width: "100%",
                zIndex: 2,
                borderRadius: 4,
                position: "relative",
              }}
            >
              <CdnImage
                src={backgroundPicture}
                width="100%"
              />
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  height="90%"
                  loop
                  autoPlay
                  muted
                  playsInline
                  style={{
                    borderRadius: 4,
                    padding: theme.unit(1),
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    top: 120,
                  }}
                >
                  <source
                    src={videoSource}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </Hidden>

          <Hidden
            on={{
              xs: false,
              md: true,
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: theme.shadow.lg,
                padding: theme.unit(1),
                paddingBottom: "2px",
                zIndex: 2,
                borderRadius: 4,
              }}
            >
              <video
                loop
                autoPlay
                muted
                playsInline
                width="334px"
                height="640px"
              >
                <source
                  src={mobileVideoSource}
                  type="video/mp4"
                />
              </video>
            </div>
          </Hidden>
        </Flex>
      </MovingReportsLandingPageContainer>
    </PageBlock>
  );
};
