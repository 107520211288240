import { Button, IconMailOutline } from "@boligportal/juice";
import { getEmailLogsUrl } from "../email_logs_page/utils";

export const UserEmailLogs = ({ email }: { email: string }) => (
  <Button
    text
    variant="subtle"
    icon={IconMailOutline}
    href={getEmailLogsUrl({
      email,
    })}
  >
    Email Logs
  </Button>
);
