import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Headline, IconArrowBack } from "@boligportal/juice";
import { t } from "lib/i18n";
import { BulkCreate } from "./components/BulkCreate/BulkCreate";
import { FullWidthMobileContainer } from "./components/CommonStyled";
import { ManualAddress } from "./components/ManualAddress/ManualAddress";

type Props = {
  onRentablesCreation: (rentableIds: number[]) => void;
  hideHeadline?: boolean;
};

export const CreateRentable = ({
  onRentablesCreation,
  hideHeadline = false,
}: Props) => {
  const [isManualAddress, setIsManualAddress] = useState(false);
  const history = useHistory();

  return (
    <Box mb={10}>
      <FullWidthMobileContainer
        px={{
          xs: 0,
          lg: 2,
        }}
      >
        <Box
          mx={{
            xs: 2,
            sm: 3,
            lg: 0,
          }}
          pt={{
            xs: 4,
            lg: 8,
          }}
          pb={2}
        >
          <Box ml={-1.5}>
            <Button
              text
              icon={IconArrowBack}
              onClick={() => history.goBack()}
            >
              {t("feature.address_form.go_back")}
            </Button>
          </Box>

          {!hideHeadline && (
            <Headline as="h1">{t("feature.address_form.headline")}</Headline>
          )}
        </Box>

        {isManualAddress ? (
          <ManualAddress
            onRentableCreated={(rentableId) =>
              onRentablesCreation([rentableId])
            }
            onUseAutocomplete={() => setIsManualAddress(false)}
          />
        ) : (
          <BulkCreate
            onRentablesCreated={onRentablesCreation}
            onClickManualAddress={() => setIsManualAddress(true)}
          />
        )}
      </FullWidthMobileContainer>
    </Box>
  );
};
