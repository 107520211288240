import { useMutation } from "react-query";
import { http } from "@boligportal/juice";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { t } from "lib/i18n";

type ErrorResponse = {
  response: {
    status: number;
    data: {
      error: {
        message: string;
        code: string;
      };
    };
  };
};

export const usePOADeletionMutation = () => {
  const { addErrorNotification } = useErrorNotification();
  const errorCodes = {
    contract_has_active_sign_request: t(
      "contracts.delete_poa_info_dialog.error.has_active_sign_request_markdown",
    ),
  };

  const deletePOAMutation = useMutation(
    (data: { signingAttachmentId: number }) =>
      http.delete(
        `/contracts/contract/${data.signingAttachmentId}/signed-attachment/delete/`,
      ),
    {
      onError: (error: ErrorResponse) => {
        addErrorNotification(t("Something went wrong. Try again."));
        error.response.data.error.message =
          errorCodes[error.response.data.error.code] ??
          t("Something went wrong. Try again.");
      },
    },
  );

  return {
    deletePOAMutation,
  };
};

export const useContractDeletionMutation = (apiPrefix: string) => {
  const { addErrorNotification } = useErrorNotification();
  const errorCodes = {
    contract_has_active_sign_request: t(
      "contracts.delete_contract_info_dialog.error.has_active_sign_request_markdown",
    ),
  };

  const deleteContractMutation = useMutation(
    (data: { contractId: number }) =>
      http.delete(`${apiPrefix}/${data.contractId}/delete/`),
    {
      onError: (error: ErrorResponse) => {
        addErrorNotification(t("Something went wrong. Try again."));
        error.response.data.error.message =
          errorCodes[error.response.data.error.code] ??
          t("Something went wrong. Try again.");
      },
    },
  );

  return {
    deleteContractMutation,
  };
};
