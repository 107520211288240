import * as React from "react";
import { Box, Flex, FlexColumn, Hidden, styled } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { Header } from "components/header";
import { PageBlock } from "../PageBlock";

const HeroHeader = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
  }
`;

const HouseSvg = () => (
  <svg
    width="329"
    height="336"
    viewBox="0 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        // eslint-disable-next-line max-len
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const StyledOverflow = styled.div`
  overflow: hidden;
`;

interface Props {
  headerText: string;
  picture: string;
  pictureBorder?: boolean;
  bulletsComponent: React.ReactNode;
  ctaButtonComponent: React.ReactNode;
}

export const CallToActionHeroSection = ({
  headerText,
  picture,
  pictureBorder = true,
  bulletsComponent,
  ctaButtonComponent,
}: Props) => (
  <StyledOverflow>
    <PageBlock
      bg="business"
      bgGradient
      py="none"
      bgBottomLeftOverlay={<HouseSvg />}
    >
      <Header inverseColor />
      <Box
        mx="auto"
        maxWidth="1390px"
        px={{
          xs: 2,
          md: 6,
        }}
        pt={{
          xs: 10,
          lg: 14,
        }}
        pb={20}
      >
        <Flex>
          <FlexColumn
            size={{
              xs: 12,
              lg: 6,
            }}
          >
            <Box
              maxWidth={{
                xs: "auto",
                lg: "600px",
              }}
              mt={{
                xs: 0,
                md: 14,
              }}
            >
              <HeroHeader>{headerText}</HeroHeader>
              <Flex
                mt={2}
                column
              >
                <Box>{bulletsComponent}</Box>
                <Box
                  flexGrow
                  pt={2}
                >
                  {ctaButtonComponent}
                </Box>
              </Flex>
            </Box>
          </FlexColumn>
          <Hidden
            on={{
              xs: true,
              lg: false,
            }}
          >
            <FlexColumn size={6}>
              <Flex>
                {pictureBorder ? (
                  <Box
                    p={2}
                    bg="base"
                    borderRadius="sm"
                  >
                    <CdnImage
                      width={653}
                      height={487}
                      src={picture}
                    />
                  </Box>
                ) : (
                  <CdnImage
                    width={653}
                    src={picture}
                  />
                )}
              </Flex>
            </FlexColumn>
          </Hidden>
        </Flex>
      </Box>
    </PageBlock>
  </StyledOverflow>
);
