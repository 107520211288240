import { useMutation, useQueryClient } from "react-query";
import { captureException } from "@sentry/react";
import {
  createNewBuild,
  markNewBuildActive,
  markNewBuildRentedOut,
  createNewBuildUnit,
  updateNewBuildById,
  updateNewBuildUnit,
  UnitPayload,
  UpdateNewBuildPayload,
  CreateNewBuildPayload,
  markNewBuildUnitRentedOut,
  markNewBuildUnitActive,
  showInterestForUnit,
} from "apps/newbuilds/api";
import { NewBuild, Unit } from "apps/newbuilds/types";
import { newBuildsKeys } from "./queries";

export const useCreateNewBuildMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CreateNewBuildPayload) => createNewBuild(payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(newBuildsKeys.newBuildsList());
      },
    },
  );
};

export const useUpdateNewBuildMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; payload: UpdateNewBuildPayload }) =>
      updateNewBuildById(data.id, data.payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(newBuildsKeys.newBuildsList());
        queryClient.invalidateQueries(newBuildsKeys.newBuild(variables.id));
      },
    },
  );
};

export const useActivateNewBuildMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: NewBuild["id"]) => markNewBuildActive(id), {
    onError: (error) => {
      captureException(error, {
        level: "error",
      });
    },
    onSuccess: (_, variables) => {
      setTimeout(() => {
        queryClient.invalidateQueries(newBuildsKeys.newBuildsList());
        queryClient.invalidateQueries(newBuildsKeys.newBuild(variables));
      }, 500);
    },
  });
};

export const useRentOutNewBuildMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: NewBuild["id"]) => markNewBuildRentedOut(id), {
    onError: (error) => {
      captureException(error, {
        level: "error",
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(newBuildsKeys.newBuildsList());
      queryClient.invalidateQueries(newBuildsKeys.newBuild(variables));
      queryClient.invalidateQueries(newBuildsKeys.newBuildUnitsList(variables));
    },
  });
};

export const useCreateNewBuildUnitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { newBuildId: number; payload: UnitPayload }) =>
      createNewBuildUnit(data.newBuildId, data.payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          newBuildsKeys.newBuildUnitsList(variables.newBuildId),
        );
      },
    },
  );
};

export const useUpdateNewBuildUnitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { newBuildId: number; payload: UnitPayload }) =>
      updateNewBuildUnit(data.newBuildId, data.payload),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          newBuildsKeys.newBuildUnitsList(variables.newBuildId),
        );
      },
    },
  );
};

export const useActivateNewBuildUnitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { buildId: NewBuild["id"]; unitId: Unit["id"] }) =>
      markNewBuildUnitActive(data.buildId, data.unitId),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          newBuildsKeys.newBuildUnitsList(variables.buildId),
        );
      },
    },
  );
};

export const useRentOutNewBuildUnitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { buildId: NewBuild["id"]; unitId: Unit["id"] }) =>
      markNewBuildUnitRentedOut(data.buildId, data.unitId),
    {
      onError: (error) => {
        captureException(error, {
          level: "error",
        });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          newBuildsKeys.newBuildUnitsList(variables.buildId),
        );
      },
    },
  );
};

export const useShowInterestForUnitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((unitId: number) => showInterestForUnit(unitId), {
    onSuccess: (_, unitId) => {
      queryClient.invalidateQueries(newBuildsKeys.showInterestForUnit(unitId));
    },
  });
};
