import * as React from "react";
import {
  Box,
  Button,
  CheckField,
  Flex,
  IconCheckmark,
  IconChevronForward,
  Text,
} from "@boligportal/juice";
import { Room } from "apps/moving_reports/interfaces/room";
import { Unit } from "apps/moving_reports/interfaces/unit";
import { t } from "lib/i18n";
import { groupBy, sortBy } from "lodash-es";

export const List = ({
  rooms,
  selected,
  roomToShow,
  isTenantFlow,
  onSelect,
  onSelectSingle,
}: {
  rooms: Room[];
  selected: Array<Unit["id"]>;
  roomToShow?: Room["id"];
  isTenantFlow: boolean;
  onSelect: (values: Array<Unit["id"]>, checked: boolean) => void;
  onSelectSingle: (value: number) => void;
}) => {
  const roomRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (roomToShow && roomRef.current) {
      roomRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [roomToShow]);

  const allUnits = rooms.reduce((memo, item) => [...memo, ...item.units], []);
  const groupedUnits = groupBy(allUnits, "type");

  const isAllUnitsByTypeSelected = (type: Unit["type"]) => {
    const currentUnitIds = groupedUnits[type].map((item) => item.id);
    const isGroupSelected = currentUnitIds.every((item) =>
      selected.includes(item),
    );

    return isGroupSelected;
  };

  const getAmount = (type: Unit["type"]) => groupedUnits[type].length;

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    onSelect([Number(value)], checked);
  };

  const handleSelectType = (type: Unit["type"]) => {
    const currentUnitIds = groupedUnits[type].map((item) => item.id);

    onSelect(currentUnitIds, true);
  };

  const getRoomUnits = ({ units }: Room) => (
    <Box>
      {sortBy(units, ["label"]).map((unit) =>
        isTenantFlow ? (
          <Box
            onClick={() => onSelectSingle(unit.id)}
            key={unit.id}
            py={1}
            borderBottom
          >
            <Flex>
              <Text>{unit.label}</Text>
              <Box flexPush>
                <IconChevronForward inline />
              </Box>
            </Flex>
          </Box>
        ) : (
          <Box key={unit.id}>
            <CheckField
              value={unit.id.toString()}
              label={
                selected.includes(unit.id) ? (
                  <SelectTypeAction
                    unit={unit}
                    amount={getAmount(unit.type)}
                    isAllSelected={isAllUnitsByTypeSelected(unit.type)}
                    onSelect={handleSelectType}
                  />
                ) : (
                  <Text>{unit.label}</Text>
                )
              }
              checked={selected.includes(unit.id)}
              onChange={handleSelect}
            />
          </Box>
        ),
      )}
    </Box>
  );

  return (
    <>
      {rooms.map((room) => (
        <Box
          key={room.id}
          mb={4}
        >
          <Box mb={1}>
            {roomToShow === room.id && <div ref={roomRef} />}

            <Text
              size="h3"
              weight="bold"
            >
              {room.label}
            </Text>
          </Box>
          {getRoomUnits(room)}
        </Box>
      ))}
    </>
  );
};

const SelectTypeAction = ({
  unit,
  amount,
  isAllSelected,
  onSelect,
}: {
  unit: Unit;
  amount: number;
  isAllSelected: boolean;
  onSelect: (unit: Unit["type"]) => void;
}) => {
  const { label, type } = unit;

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onSelect(type);
  };

  return (
    <Box>
      <Text block>{label}</Text>
      {isAllSelected ? (
        <Text
          block
          size="small"
          color="muted"
        >
          <IconCheckmark
            inline
            color="success"
            size="small"
          />
          {t("moving_reports.batch_unit_selector.all_units_selected", {
            label,
            amount,
          })}
        </Text>
      ) : (
        <Button
          text
          onClick={handleSelect}
        >
          <Text
            block
            size="small"
            color="muted"
          >
            {t("moving_reports.batch_unit_selector.select_all_units", {
              label,
              amount,
            })}
          </Text>
        </Button>
      )}
    </Box>
  );
};
