/* eslint-disable compat/compat */
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import { Flex } from "@boligportal/juice";
import { activityStreamPageLimit, useUserActivityStream } from "api/queries";
import {
  ActivityStreamResponse,
  getAds,
  getProfile,
  getUser,
  getUserSubscriptions,
} from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { AdItem } from "../ads_page/interfaces";
import {
  createProfileDetailsDTO,
  createProfileHeaderDTO,
  ProfileDetailsDTO,
  ProfileHeaderDTO,
} from "./profile_info/helpers";
import { SubscriptionPaymentsItemInterface } from "./subscriptions_feed/interfaces";
import { UserForCustomerService } from "./user_detail_view/user_detail_view";

interface UserDetailPageMatchParams {
  id: string;
}

interface UserDetailPageContextAPI {
  userId: number;
  user: UserForCustomerService | null;
  profileHeaderViewModel: ProfileHeaderDTO | null;
  profileDetailsViewModel: ProfileDetailsDTO | null;
  activityStreamData?: ActivityStreamResponse;
  activityStreamOffset: number;
  setActivityStreamOffset: (page: number) => void;
  allAds: { ads: AdItem[]; count: number } | null;
  includeStaffActivities: boolean;
  includeUserActivities: boolean;
  isLoadingActivityStream: boolean;
  subscriptions: SubscriptionPaymentsItemInterface[];
  setIncludeUserActivities: React.Dispatch<React.SetStateAction<boolean>>;
  setIncludeStaffActivities: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  refetchProfile: () => void;
}

export const UserDetailPageContext =
  React.createContext<UserDetailPageContextAPI>({
    userId: -1,
    user: null,
    profileHeaderViewModel: null,
    profileDetailsViewModel: null,
    isLoadingActivityStream: true,
    activityStreamOffset: 0,
    setActivityStreamOffset: () => {},
    subscriptions: [],
    allAds: null,
    setIncludeUserActivities: () => false,
    setIncludeStaffActivities: () => false,
    includeUserActivities: true,
    includeStaffActivities: false,
    refetch: () => {},
    refetchProfile: () => {},
  });

export const UserDetailPageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const match = useRouteMatch<UserDetailPageMatchParams>();
  const userId = Number(match.params.id);

  const [user, setUser] = React.useState<UserForCustomerService | null>(null);
  const [profileHeaderDTO, setProfileHeaderDTO] =
    React.useState<ProfileHeaderDTO | null>(null);
  const [profileDetailsDTO, setProfileDetailsDTO] =
    React.useState<ProfileDetailsDTO | null>(null);
  const [includeUserActivities, setIncludeUserActivities] =
    React.useState(false);
  const [includeStaffActivities, setIncludeStaffActivities] =
    React.useState(true);
  const [subscriptions, setSubscriptions] = React.useState<
    SubscriptionPaymentsItemInterface[]
  >([]);
  const [allAds, setAllAds] = React.useState<{
    ads: AdItem[];
    count: number;
  } | null>(null);
  const [activityStreamOffset, setActivityStreamOffset] = React.useState(0);

  const {
    data: activityStreamData,
    refetch: refetchActivityStream,
    isLoading: isLoadingActivityStream,
  } = useUserActivityStream(
    userId,
    "user",
    includeUserActivities,
    includeStaffActivities,
    true,
    "desc",
    activityStreamOffset,
    activityStreamOffset + activityStreamPageLimit,
  );

  const loadProfile = React.useCallback(() => {
    getUser(userId).then((response) => {
      setUser(response.user);
      getProfile(userId).then((res) => {
        const profileHeaderDTO = createProfileHeaderDTO(
          res.profile,
          response.user,
          refetch,
        );
        const profileDetailsDTO = createProfileDetailsDTO(
          res.profile,
          response.user,
        );
        setProfileHeaderDTO(profileHeaderDTO);
        setProfileDetailsDTO(profileDetailsDTO);
      });
    });
  }, [userId]);

  const loadSubscriptions = React.useCallback(() => {
    getUserSubscriptions(userId).then((response) => {
      if (response) {
        setSubscriptions(response.results);
      }
    });
  }, [userId]);

  const loadAds = React.useCallback(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getAds({
      signal,
      filters: {
        landlordId: userId,
      },
      limit: 5,
      sorting: "advertised_date",
      descending: true,
    })
      .then((response) => {
        setAllAds({
          ads: response.data,
          count: response.count,
        });
      })
      .catch(captureExceptionIfNotAbortError);
  }, [userId]);

  // API Handles
  const refetch = React.useCallback(() => {
    loadProfile();
    refetchActivityStream();
    loadSubscriptions();
    loadAds();
  }, [loadProfile, refetchActivityStream, loadSubscriptions, loadAds]);

  return (
    <UserDetailPageContext.Provider
      value={{
        user,
        userId,
        subscriptions,
        allAds,
        includeStaffActivities,
        includeUserActivities,
        setIncludeStaffActivities,
        setIncludeUserActivities,
        isLoadingActivityStream,
        activityStreamData,
        activityStreamOffset,
        setActivityStreamOffset,
        profileHeaderViewModel: profileHeaderDTO,
        profileDetailsViewModel: profileDetailsDTO,
        refetch,
        refetchProfile: loadProfile,
      }}
    >
      <Flex
        column
        flexGrow
      >
        {children}
      </Flex>
    </UserDetailPageContext.Provider>
  );
};
