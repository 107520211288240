import { Link } from "react-router-dom";
import { Box, Flex, Text } from "@boligportal/juice";
import { TOP_BAR_HEIGHT, SIDE_BAR_WIDTH } from "apps/customer_service";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { useUser } from "components/Providers/UserProvider";
import { Avatar } from "components/design-system/atoms/avatar/avatar";
import { GlobalSearch } from "./global_search/global_search";
import { GlobalSearchContextProvider } from "./global_search/global_search_context";

const Topbar = () => {
  const { getHeaderLogoComponent } = useMarketSettings();
  const { user } = useUser();

  return (
    <Flex
      align="center"
      height={TOP_BAR_HEIGHT}
      shadow="sm"
    >
      <Box
        width={SIDE_BAR_WIDTH}
        pl={2}
      >
        <Link to="/customer-service">{getHeaderLogoComponent(false)}</Link>
      </Box>

      <GlobalSearchContextProvider>
        <Box ml={4}>
          <GlobalSearch />
        </Box>
      </GlobalSearchContextProvider>

      {user && (
        <Box
          flexPush
          mr={3}
        >
          <Text weight="bold">{`${user.first_name} ${user.last_name}`}</Text>

          <Avatar src={user.profile_image} />
        </Box>
      )}
    </Flex>
  );
};

export { Topbar };
