import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FlexColumn,
  Hidden,
  IconInformationCircle,
  IconPerson,
  IconSearch,
  styled,
  Text,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Header } from "components/header";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import { PageBlock } from "./PageBlock";

export const Button55PxHeight = styled(Button)`
  height: 55px;
`;

const HeroHeader = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #ffffff;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 40px;
  }
`;

const HouseSvg = () => (
  <svg
    width="329"
    height="336"
    viewBox="0 -2 32 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="rgba(255,255,255,0.05)"
        d="M12.608 1.789L2.403 11.987C.478 13.911 0 14.643 0 17.35v12.626c0 1.26.501 1.764 1.765 1.764h27.181c1.277 0 1.764-.491 1.764-1.764V17.35c0-2.622-.476-3.444-2.397-5.363L18.095 1.78l-.983-.982C16.579.265 15.97 0 15.362 0c-.612 0-1.223.267-1.756.797-.708.705-1.04 1.036-.998.992z"
      />
    </g>
  </svg>
);

const StyledOverflow = styled.div`
  overflow: hidden;
`;
const StyledTransform = styled.div<{ x?: string; y?: string }>`
  transform: translate(
    ${(props) => props.x || "0"},
    ${(props) => props.y || "0"}
  );
`;
const StyledRotate = styled.div<{ degrees: number }>`
  transform: rotate(${(props) => props.degrees || "0"}deg);
`;

const StyledScale = styled.div<{ scale: number }>`
  transform: scale(${(props) => props.scale});
`;

const SlighltyTintedText = styled(Text)`
  color: #909090;
`;

const SmallerButton = styled(Button)`
  font-size: 14px;
`;

const DisabledDiv = styled.div`
  pointer-events: none;
`;

const profilePictureStyle = {
  borderRadius: "50%",
  border: "1px solid #E6E6E6",
  width: "60px",
  height: "60px",
};

const ProfileCardMockup = ({
  profileCardMockupText,
  picture,
  subText,
  text1,
  text3,
  contactButtonLabel,
  seeProfileButtonLabel,
}: {
  profileCardMockupText: string;
  picture: string;
  subText: string;
  text1: string;
  text3: string;
  contactButtonLabel: string;
  seeProfileButtonLabel: string;
}) => (
  <Box
    maxWidth="300px"
    border
    bg="base"
    borderRadius="sm"
    textAlign="left"
    shadow="lg"
  >
    <Flex
      mt={2}
      mx={2}
    >
      <Box mr={2}>
        <CdnImage
          src={picture}
          style={profilePictureStyle}
        />
      </Box>
      <Flex
        justify="center"
        column
      >
        <Box>
          <Text weight="bold">Patrik Melis</Text>
        </Box>
        <Box>
          <Text
            size="tiny"
            color="muted"
          >
            {subText}
          </Text>
        </Box>
      </Flex>
    </Flex>
    <Box>
      <Divider />
    </Box>
    <Box m={2}>
      <Flex
        align="center"
        py={1}
      >
        <Box mr={1}>
          <IconPerson />
        </Box>
        <SlighltyTintedText size="tiny">{text1}</SlighltyTintedText>
      </Flex>
      <Flex
        justify="start"
        py={1}
      >
        <Flex
          height="100%"
          mr={1}
        >
          <IconSearch />
        </Flex>
        <SlighltyTintedText size="tiny">
          {profileCardMockupText}
        </SlighltyTintedText>
      </Flex>
      <Flex
        align="center"
        py={1}
      >
        <Box mr={1}>
          <IconInformationCircle />
        </Box>
        <SlighltyTintedText size="tiny">{text3}</SlighltyTintedText>
      </Flex>

      <DisabledDiv>
        <Flex
          gap
          mt={1}
        >
          <SmallerButton variant="default">{contactButtonLabel}</SmallerButton>
          <SmallerButton variant="primary">
            {seeProfileButtonLabel}
          </SmallerButton>
        </Flex>
      </DisabledDiv>
    </Box>
  </Box>
);

const TopHeroSection = ({
  clickCreateAdTracking,
  headerText,
  addressButtonLabel,
  picture,
  profileCard,
  adCardMockup,
}: {
  clickCreateAdTracking: () => void;
  headerText: string;
  addressButtonLabel: string;
  picture: string;
  profileCard: {
    text: string;
    picture: string;
    subText: string;
    text1: string;
    text3: string;
    contactButtonLabel: string;
    seeProfileButtonLabel: string;
  };
  adCardMockup: React.ReactNode;
}) => {
  const { user } = useUser();
  const { modalActionsInstance } = useAuthActions({});

  const handleAddressSelect = () => {
    clickCreateAdTracking();
    if (!user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          targetUrl={App.settings.routes.property_owner.create_listing}
          context={SignupContext.CREATE_RENTABLE}
        />,
      );
    } else {
      window.location.href = App.settings.routes.property_owner.create_listing;
    }
  };

  return (
    <StyledOverflow>
      <PageBlock
        bg="business"
        bgGradient
        py="none"
        bgBottomLeftOverlay={<HouseSvg />}
      >
        <Header inverseColor />
        <Box
          mx="auto"
          maxWidth="1390px"
          px={{
            xs: 2,
            md: 6,
          }}
          pt={{
            xs: 10,
            lg: 14,
          }}
          pb={20}
        >
          <Flex>
            <FlexColumn
              size={{
                xs: 12,
                lg: 6,
              }}
            >
              <Box
                maxWidth={{
                  xs: "auto",
                  lg: "600px",
                }}
                mt={{
                  xs: 0,
                  md: 14,
                }}
              >
                <HeroHeader>{headerText}</HeroHeader>
                <Flex
                  mt={2}
                  gap
                  align="center"
                >
                  <Box flexGrow>
                    <Button55PxHeight
                      testId={"AdDetailPageButton"}
                      variant="branded"
                      onClick={handleAddressSelect}
                    >
                      {addressButtonLabel}
                    </Button55PxHeight>
                  </Box>
                </Flex>
              </Box>
            </FlexColumn>
            <Hidden
              on={{
                xs: true,
                lg: false,
              }}
            >
              <FlexColumn size={6}>
                <Flex>
                  <StyledTransform x="220px">
                    <Box
                      p={2}
                      bg="base"
                      borderRadius="sm"
                    >
                      <CdnImage
                        width={732}
                        height={487}
                        src={picture}
                      />
                    </Box>
                    <div
                      style={{
                        position: "absolute",
                      }}
                    >
                      <StyledTransform
                        x="-46%"
                        y="-235px"
                      >
                        <StyledScale scale={0.85}>
                          <StyledRotate degrees={-10}>
                            <ProfileCardMockup
                              profileCardMockupText={profileCard.text}
                              picture={profileCard.picture}
                              subText={profileCard.subText}
                              text1={profileCard.text1}
                              text3={profileCard.text3}
                              contactButtonLabel={
                                profileCard.contactButtonLabel
                              }
                              seeProfileButtonLabel={
                                profileCard.seeProfileButtonLabel
                              }
                            />
                          </StyledRotate>
                        </StyledScale>
                      </StyledTransform>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                      }}
                    >
                      <StyledTransform
                        x="-50%"
                        y="-545px"
                      >
                        <StyledRotate degrees={10}>{adCardMockup}</StyledRotate>
                      </StyledTransform>
                    </div>
                  </StyledTransform>
                </Flex>
              </FlexColumn>
            </Hidden>
          </Flex>
        </Box>
      </PageBlock>
    </StyledOverflow>
  );
};

export { TopHeroSection };
