import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { MovingReportForTenant } from "../../tenant_app/interfaces/movingReportForTenant";
import { NewDefectByTenant } from "../../tenant_app/interfaces/newDefectByTenant";

interface MovingReportsState {
  loading: boolean;
  movingReport?: MovingReportForTenant;
}

const initialMovingReportsState: MovingReportsState = {
  loading: true,
};

/**
 *
 * Moving Reports Defects Feature is being reused in the Tenant Hub
 * where user is not athenticated and we need to pass accessKey
 *
 */
export const fetchMovingReportForTenantHub = createAsyncThunk(
  "tenantApp/fetchMovingReportStatusForTenantHub",
  async (payload: { movingReportId: number; accessKey: string }) => {
    const response = await API.getMovingReportForTenantHub({
      moving_report_id: payload.movingReportId,
      accessKey: payload.accessKey,
    });
    return response;
  },
);

export const createDefectForTenantHub = createAsyncThunk(
  "tenantApp/createDefectStatus",
  async (payload: { newDefect: NewDefectByTenant; accessKey: string }) => {
    const response = await API.createDefectsByUnitTypeByTenantHub({
      defect: payload.newDefect,
      accessKey: payload.accessKey,
    });
    return response;
  },
);

// ------------------------------------------------------------

export const fetchMovingReport = createAsyncThunk(
  "tenantApp/fetchMovingReportStatus",
  async (id: number) => {
    const response = await API.getMovingReportForTenant(id);
    return response;
  },
);

export type CreateDefectDispatcher = (
  newDefect: NewDefectByTenant,
) => Promise<unknown>;

export const createDefect = createAsyncThunk(
  "tenantApp/createDefectStatus",
  async (newDefect: NewDefectByTenant) => {
    const response = await API.createDefectsByUnitTypeByTenant(newDefect);
    return response;
  },
);

export const movingReportForTenantSlice = createSlice({
  name: "tenantApp",
  initialState: initialMovingReportsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMovingReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.movingReport = payload;
    });

    builder.addCase(
      fetchMovingReportForTenantHub.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.movingReport = payload;
      },
    );

    builder.addCase(createDefect.fulfilled, (state, { payload }) => {
      state.movingReport = payload;
    });
  },
});
