import { PAGE_EMAIL_LOGS } from "apps/customer_service";
import { format, subWeeks } from "date-fns";
import { EmailLogsFilters } from "lib/customerServiceApi";
import { emailLogsPageLimit } from "./useEmailLogData";

export const getEmailRenderLink = (
  email: string,
  tags: { [key: string]: string },
  id: string,
) => {
  const url = `/customer-service/render-mail?id=${id}`;
  // if (tags.subscription_id) {
  //   return url + `&subscription_id=${tags.subscription_id}`;
  // }
  // if (tags.ads_id) {
  //   return url + `&ads_id=${tags.ads_id}&agent_id=${tags.agent_id}`;
  // }
  // if (tags.invoice_id) {
  //   return url + `&invoice_id=${tags.invoice_id}`;
  // }
  // if (tags.tenant_id) {
  //   url = url + `&tenant_id=${tags.tenant_id}`;
  // }
  // if (tags.signer_id) {
  //   url = url + `&signer_id=${tags.signer_id}`;
  // }
  // if (tags.tenancy_id) {
  //   url = url + `&tenancy_id=${tags.tenancy_id}`;
  // }
  // if (tags.defect_id) {
  //   url = url + `&defect_id=${tags.defect_id}`;
  // }
  // if (tags.moving_report_id) {
  //   url = url + `&moving_report_id=${tags.moving_report_id}`;
  // }
  // if (tags.link) {
  //   url = url + `&link=${tags.link}`;
  // }
  // if(tags.contract_id) {
  //   url = url + `&contract_id=${tags.contract_id}`;
  // }
  // if(tags.sign_request_id) {
  //   url = url + `&sign_request_id=${tags.sign_request_id}`;
  // }

  return url;
};

export const emailLogsFiltersToQueryParams = (filters: EmailLogsFilters) => {
  // eslint-disable-next-line compat/compat
  const queryParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (value || value === 0) {
      queryParams.set(key, value.toString());
    }
  });

  return queryParams;
};

const today = new Date();
// Calculate the date 2 weeks ago
const twoWeeksAgo = subWeeks(today, 2);
const formattedToday = format(today, "yyyy-MM-dd");
const formattedTwoWeeksAgo = format(twoWeeksAgo, "yyyy-MM-dd");

const defaultFilters: EmailLogsFilters = {
  start_index: 0,
  end_index: emailLogsPageLimit,
  date_from: formattedTwoWeeksAgo,
  date_to: formattedToday,
  email: "",
  email_topic: "",
};

export const queryParamStringToFilters = (queryParamString: string) => {
  // eslint-disable-next-line compat/compat
  const queryParams = new URLSearchParams(queryParamString);
  const filters = {
    ...defaultFilters,
  };

  // Iterate params and convert number like strings to numbers
  queryParams.forEach((value: any, key) => {
    if (isNaN(value)) {
      filters[key] = value;
    } else {
      filters[key] = Number(value);
    }
  });

  return filters;
};

export const getEmailLogsUrl = (filters: EmailLogsFilters) => {
  const queryString = emailLogsFiltersToQueryParams(filters);
  return `${PAGE_EMAIL_LOGS}?${queryString}`;
};
