import {
  Box,
  CheckField,
  Flex,
  FlexColumn,
  Text,
  TextField,
} from "@boligportal/juice";
import { App } from "components/app";
import { format } from "date-fns";
import {
  SelectedPaymentItemInterface,
  CommonPaymentItemInterface,
} from "../interfaces";
import {
  disablePaymentItem,
  findPaymentId,
  getPaymentAmount,
  getAmountSumStr,
} from "../refund_helpers";
import { PaymentRefundedStatus } from "./payment_refunded_status";

type Props = {
  selectedPayments: SelectedPaymentItemInterface[];
  onSelectedPaymentChange: (items: SelectedPaymentItemInterface[]) => void;
  payments: CommonPaymentItemInterface[];
};

const RefundPaymentsList = ({
  onSelectedPaymentChange,
  payments,
  selectedPayments,
}: Props) => {
  // ============================================================================
  // Callback Handlers
  // ============================================================================

  const handleCheckboxToggle = (
    items: SelectedPaymentItemInterface[],
    payment: SelectedPaymentItemInterface,
    isOn: boolean,
  ) => {
    if (isOn) {
      const clonedItems = [
        ...items,
        {
          payment_id: payment.payment_id,
          amount: Number(payment.amount),
          can_be_refunded: payment.can_be_refunded,
        },
      ];
      onSelectedPaymentChange(clonedItems);
    } else {
      const idIndex = items.findIndex(
        (item) => item.payment_id === payment.payment_id,
      );
      if (idIndex > -1) {
        const clonedItems = [...items];
        clonedItems.splice(idIndex, 1);
        onSelectedPaymentChange(clonedItems);
      }
    }
  };

  const handleAmountTextInputChange = (
    items: SelectedPaymentItemInterface[],
    paymentFeedItem: CommonPaymentItemInterface,
    value: number,
  ) => {
    const item = items.find(
      (item) => item.payment_id === paymentFeedItem.payment_id,
    );

    if (item !== undefined) {
      const clonedItems = [...items];

      clonedItems.forEach((payment) => {
        if (payment.payment_id === paymentFeedItem.payment_id) {
          payment.amount =
            value > paymentFeedItem.amount ? paymentFeedItem.amount : value;
        }
      });
      onSelectedPaymentChange(clonedItems);
    }
  };

  return (
    <Box
      borderTop
      borderBottom
    >
      <Flex>
        <FlexColumn size={1}></FlexColumn>
        <FlexColumn size={4}>
          <Text
            size="h4"
            weight="semiBold"
          >
            Product
          </Text>
        </FlexColumn>
        <FlexColumn size={4}>
          <Text
            size="h4"
            weight="semiBold"
          >
            Date
          </Text>
        </FlexColumn>
        <FlexColumn size={3}>
          <Flex justify="end">
            <Text
              size="h4"
              weight="semiBold"
            >
              Amount
            </Text>
          </Flex>
        </FlexColumn>
      </Flex>

      {payments.map((payment) => (
        <Box
          my={1}
          key={payment.order_id}
        >
          <Flex>
            <FlexColumn size={1}>
              {payment.total_refunded == 0 && (
                <CheckField
                  label=""
                  onChange={(event) =>
                    handleCheckboxToggle(
                      selectedPayments,
                      payment,
                      event.target.checked,
                    )
                  }
                  checked={findPaymentId(selectedPayments, payment)}
                  disabled={!payment.can_be_refunded}
                />
              )}
            </FlexColumn>
            <FlexColumn size={4}>
              <Text>
                {`${payment.amount}${App.settings.currency_symbol} - ${payment.duration_days} days access`}
              </Text>
            </FlexColumn>
            <FlexColumn size={4}>
              {format(new Date(payment.date), "yyyy-MM-dd HH:mm:ss")}
            </FlexColumn>
            <FlexColumn size={3}>
              {payment.total_refunded > 0 && <PaymentRefundedStatus />}
              {payment.total_refunded == 0 && (
                <TextField
                  min={0}
                  disabled={disablePaymentItem(
                    selectedPayments,
                    payment.payment_id,
                  )}
                  type="number"
                  value={getPaymentAmount(
                    selectedPayments,
                    payment.payment_id,
                  ).toString()}
                  onChange={(event) =>
                    handleAmountTextInputChange(
                      selectedPayments,
                      payment,
                      Number(event.target.value),
                    )
                  }
                />
              )}
            </FlexColumn>
          </Flex>
        </Box>
      ))}
      <Box mt={2}>
        <Flex>
          <FlexColumn size={1} />
          <FlexColumn size={8}>
            <Text size="h3">Total</Text>
          </FlexColumn>
          <FlexColumn size={3}>
            <Flex justify="end">
              <Text
                size="h3"
                weight="bold"
              >
                {`${getAmountSumStr(selectedPayments)} ${
                  App.settings.currency_symbol
                }`}
              </Text>
            </Flex>
          </FlexColumn>
        </Flex>
      </Box>
    </Box>
  );
};

export { RefundPaymentsList };
