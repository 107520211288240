import * as React from "react";
import { Box, Flex, styled } from "@boligportal/juice";
import { PageBlock } from "apps/landingpages/renting_out/components/PageBlock";

const ScalabaleHeadline = styled.h2`
  font-size: 60px;
  line-height: 1.2em;
  color: #191919;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 42px;
  }
`;

interface Props {
  headline: string;
  children: React.ReactNode;
}

export const FAQSectionWrapper = ({ headline, children }: Props) => (
  <PageBlock
    bg="default"
    py="extraLarge"
  >
    <Box
      px={{
        xs: 0,
        md: 6,
      }}
      m="auto"
      width={"auto"}
      maxWidth={"1390px"}
    >
      <Flex
        column
        justify="center"
        width={{
          xs: "100%",
          md: "100%",
          lg: "70%",
        }}
      >
        <Box
          px={{
            xs: 2,
            md: 0,
          }}
          pb={6}
          textAlign={{
            xs: "center",
            md: "left",
          }}
        >
          <ScalabaleHeadline>{headline}</ScalabaleHeadline>
        </Box>

        {children}
      </Flex>
    </Box>
  </PageBlock>
);
