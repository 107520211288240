import { useContext } from "react";
import { Button, Flex, IconArrowForward, Text } from "@boligportal/juice";
import { PAGE_PAYMENTS } from "apps/customer_service";
import { modalActions } from "components/modal_controller";
import { formatDuration } from "components/plan_utils";
import { format } from "date-fns";
import { UserForCustomerService } from "../../user_detail_view/user_detail_view";
import {
  CommonPaymentItemInterface,
  SubscriptionPaymentsItemInterface,
} from "../interfaces";
import { CancelSubscriptionSidebar } from "./cancel_subscription_sidebar";
import { PaymentRefundedStatus } from "./payment_refunded_status";
import { RefundPaymentsSidebar } from "./refund_payments_sidebar";
import { SubscriptionStatusBlock } from "./subscription_status_block";
import { TerminateSubscriptionSidebar } from "./terminate_subscription_sidebar";

const PaymentItemView = (props: {
  item: CommonPaymentItemInterface;
  isFreeProduct: boolean;
}) => {
  const { isFreeProduct, item } = props;

  const productDescription = isFreeProduct
    ? "Custom"
    : `${item.amount} - ${formatDuration(item.duration_days)} access`;
  return (
    <Flex
      column
      mb={1}
    >
      <Flex>
        <Text>{productDescription}</Text>
        {item.total_refunded > 0 && (
          <Flex flexPush>
            <PaymentRefundedStatus />
          </Flex>
        )}
      </Flex>
      <Flex>
        <Text size="tiny">
          {format(new Date(item.date), "yyyy-MM-dd HH:mm:ss")}
        </Text>
        <Text
          size="tiny"
          pl={0.5}
          weight="bold"
        >{`(${item.payment_method})`}</Text>
      </Flex>
    </Flex>
  );
};

const SubscriptionFeedItem = (props: {
  refundCandidate: UserForCustomerService;
  item: SubscriptionPaymentsItemInterface;
  subscriptionUpdated: () => void;
}) => {
  const modal = useContext(modalActions);
  const { item, refundCandidate, subscriptionUpdated } = props;

  const showCancelSubscriptionFlow = () => {
    modal.showModal(
      <CancelSubscriptionSidebar
        closeHandler={closeHandler}
        item={item}
        user={refundCandidate}
      />,
    );
  };

  const showTerminateSubscriptionFlow = () => {
    modal.showModal(
      <TerminateSubscriptionSidebar
        closeHandler={closeHandler}
        item={item}
        user={refundCandidate}
      />,
    );
  };

  const showRefundPaymentsFlow = () => {
    modal.showModal(
      <RefundPaymentsSidebar
        subscriptionPaymentsItem={item}
        refundCandidate={refundCandidate}
        onCancelClick={closeHandler}
      />,
    );
  };

  const closeHandler = () => {
    subscriptionUpdated();
    modal.closeModal();
  };

  function showCancelButton() {
    const {
      is_cancelable,
      is_itunes_subscription,
      is_google_play_subscription,
    } = item.subscription;
    return (
      is_cancelable && !is_itunes_subscription && !is_google_play_subscription
    );
  }

  function showExpireButton() {
    return (
      !item.subscription.is_cancelable &&
      item.subscription.can_be_terminated &&
      !item.subscription.is_itunes_subscription &&
      !item.subscription.is_google_play_subscription
    );
  }

  function showRefundButton() {
    return (
      !item.subscription.is_itunes_subscription &&
      !item.subscription.is_google_play_subscription
    );
  }

  return (
    <Flex column>
      <SubscriptionStatusBlock subscription={item.subscription} />

      <Flex mt={2}>
        <Flex gap={1}>
          {showCancelButton() && (
            <Button onClick={showCancelSubscriptionFlow}>Cancel</Button>
          )}
          {showExpireButton() && (
            <Button onClick={showTerminateSubscriptionFlow}>Expire</Button>
          )}
          {showRefundButton() && (
            <Button
              onClick={showRefundPaymentsFlow}
              disabled={
                item.payments.length === 0 || item.subscription.free_plan
              }
            >
              Refund
            </Button>
          )}
        </Flex>
      </Flex>

      {item.payments.length > 0 && (
        <>
          <Flex
            column
            mt={2}
          >
            <Text weight="headline">Payments</Text>
          </Flex>

          <Flex column>
            {item.payments.map((paymentItem) => (
              <PaymentItemView
                key={`${paymentItem.payment_id} ${paymentItem.date}`}
                item={paymentItem}
                isFreeProduct={item.subscription.free_plan}
              />
            ))}
          </Flex>

          <Flex
            py={2}
            mb={2}
            gap
            borderBottom
          >
            <Flex flexPush>
              <Button
                iconAfter={IconArrowForward}
                as="a"
                href={`${PAGE_PAYMENTS}?uid=${refundCandidate.id}&sid=${item.subscription.id}`}
                variant="subtle"
              >{`See all ${item.payments.length} payments`}</Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
export { SubscriptionFeedItem };
