import * as React from "react";
import { styled } from "@boligportal/juice";

type Props = {
  isDisabled: boolean;
  children: React.ReactNode;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
};

export const HoverableCardWrapper = ({
  isDisabled,
  children,
  onMouseOver,
  onMouseOut,
  onClick,
}: Props) => (
  <HandCursorDiv
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
    displayCursor={!isDisabled}
  >
    {children}
  </HandCursorDiv>
);

const HandCursorDiv = styled.div<{ displayCursor: boolean }>`
  cursor: ${({ displayCursor }) => (displayCursor ? "pointer" : "default")};
  pointer-events: ${({ displayCursor }) => (displayCursor ? "auto" : "none")};
  height: fit-content;
`;
