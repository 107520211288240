import { useEffect, useMemo, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { App } from "components/app";
import { Plan } from "components/interfaces/plan";
import { Market } from "components/markets/market_settings";
import { addDays } from "date-fns";
import { getApplePayPlanTranslation } from "../stripe/stripePlanTranslations";

const marketSettings: Record<
  Market,
  { country: string; currency: string; managementUrl: string }
> = {
  [Market.BOLIGPORTAL_DK]: {
    country: "DK",
    currency: "dkk",
    managementUrl: "https://www.boligportal.dk/subscription",
  },
  [Market.BOSTADSPORTAL_SE]: {
    country: "SE",
    currency: "sek",
    managementUrl: "https://www.bostadsportal.se/subscription",
  },
};

export const useApplePay = (plan: Plan) => {
  const [isLoading, setIsLoading] = useState(true);
  const [canMakeApplePay, setCanMakeApplePay] = useState<boolean | null>(null);
  const stripe = useStripe();
  const planIsTrial = plan.duration_days === 1;

  const { billingAgreement, description } = getApplePayPlanTranslation(plan);

  const paymentRequest = useMemo(() => {
    return stripe?.paymentRequest({
      country: marketSettings[App.settings.market].country,
      currency: marketSettings[App.settings.market].currency,
      total: {
        label: description,
        amount: plan.price.total * 100,
      },
      requestPayerEmail: true,
      requestPayerName: true,
      applePay: {
        recurringPaymentRequest: {
          paymentDescription: description,
          managementURL: marketSettings[App.settings.market].managementUrl,
          ...(planIsTrial
            ? {
                trialBilling: {
                  amount: plan.price.total * 100,
                  label: description,
                  recurringPaymentIntervalUnit: "day",
                  recurringPaymentEndDate: addDays(
                    new Date(),
                    plan.duration_days,
                  ),
                },
              }
            : {}),
          regularBilling: {
            recurringPaymentStartDate: planIsTrial
              ? addDays(new Date(), 1)
              : new Date(),
            amount:
              planIsTrial && plan.next_plan
                ? plan.next_plan.price.total * 100
                : Number(plan.price.total) * 100,
            label:
              planIsTrial && plan.next_plan
                ? getApplePayPlanTranslation(plan.next_plan).description
                : description,
            recurringPaymentIntervalUnit: "day",
            recurringPaymentIntervalCount:
              planIsTrial && plan.next_plan
                ? plan.next_plan.duration_days
                : plan.duration_days,
          },
          billingAgreement: billingAgreement,
        },
      },
    });
  }, [
    stripe,
    description,
    plan.price.total,
    plan.duration_days,
    plan.next_plan,
    planIsTrial,
    billingAgreement,
  ]);

  useEffect(() => {
    const checkPayment = async () => {
      if (paymentRequest) {
        const result = await paymentRequest.canMakePayment();
        setCanMakeApplePay(result?.applePay ?? false);
      }
      setIsLoading(false);
    };
    checkPayment();
  }, [paymentRequest]);

  return {
    canMakeApplePay,
    paymentRequest,
    stripe,
    isLoading,
  };
};
