import * as React from "react";
import { useDeleteMovingReportMutation } from "api/mutations";
import { ContextProviderMissingError } from "errors/ContextProviderMissingError";
import { ConfirmDialog } from "./ConfirmDialog";
import { InfoDialog } from "./InfoDialog";

type DeletionCandidate = {
  movingReportId: number;
  isDeletable: boolean;
  defectFlowEndDate: string | null;
  successHandler?: () => void;
};

type ReportDeletionContextValue = {
  open: boolean;
  candidate: DeletionCandidate | null;
  setMovingReportDeletionCandidate: (
    candidate: DeletionCandidate | null,
  ) => void;
  onCancel: () => void;
};

const ReportDeletionContext = React.createContext<ReportDeletionContextValue>(
  null!,
);

const MovingReportDeletionDialog = () => {
  const [activeView, setActiveView] = React.useState<
    "DELETE_INFO_DIALOG" | "DELETE_CONFIRM_DIALOG"
  >("DELETE_INFO_DIALOG");
  const { candidate, onCancel } = React.useContext(ReportDeletionContext);
  const { deleteReportMutation } = useDeleteMovingReportMutation();

  const resetActiveViewAndCancel = () => {
    setActiveView("DELETE_INFO_DIALOG");
    onCancel();
  };

  const handleDeleteReport = () => {
    if (!candidate) {
      return;
    }

    const { successHandler } = candidate;

    deleteReportMutation
      .mutateAsync({
        reportId: candidate.movingReportId,
      })
      .then(() => successHandler && successHandler())
      .finally(resetActiveViewAndCancel);
  };

  if (!candidate) {
    return null;
  }

  const { isDeletable, movingReportId, defectFlowEndDate } = candidate;

  return (
    <>
      {activeView === "DELETE_INFO_DIALOG" && (
        <InfoDialog
          onClose={resetActiveViewAndCancel}
          onSubmit={() => setActiveView("DELETE_CONFIRM_DIALOG")}
          reportId={movingReportId}
          isDeletable={isDeletable}
          defectFlowEndDate={defectFlowEndDate}
        />
      )}

      {activeView === "DELETE_CONFIRM_DIALOG" && (
        <ConfirmDialog
          onClose={resetActiveViewAndCancel}
          onSubmit={handleDeleteReport}
        />
      )}
    </>
  );
};

type ProviderProps = {
  children: React.ReactNode;
};

export const MovingReportDeletionProvider = ({ children }: ProviderProps) => {
  const [deletionCandidate, setMovingReportDeletionCandidate] =
    React.useState<DeletionCandidate | null>(null);
  return (
    <ReportDeletionContext.Provider
      value={{
        open: Boolean(deletionCandidate),
        candidate: deletionCandidate,
        onCancel: () => setMovingReportDeletionCandidate(null),
        setMovingReportDeletionCandidate,
      }}
    >
      {children}
      <MovingReportDeletionDialog />
    </ReportDeletionContext.Provider>
  );
};

export const useMovingReportDeletion = () => {
  const context = React.useContext(ReportDeletionContext);

  if (!context) {
    throw new ContextProviderMissingError(
      "useMovingReportDeletion",
      "MovingReportDeletionProvider",
    );
  }

  const { setMovingReportDeletionCandidate } = context;

  return {
    setMovingReportDeletionCandidate,
  };
};
