import { useContext, useMemo } from "react";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { PaymentFlowCoordinatorProps } from "features/paywall/PaywallProvider";
import { StartSubscriptionFlow } from "../StartSubscriptionFlow";
import { StripePaymentContext } from "../stripe/StripePaymentProvider";

export const ApplePayPaymentFlowCoordinator = ({
  paywallCandidate,
  registeredPaywalls,
  onClose,
}: PaymentFlowCoordinatorProps) => {
  const {
    paymentMethodId,
    stripeError,
    translateStripeErrorMessage,
    setStripeError,
    setPaymentMethodId,
  } = useContext(StripePaymentContext);

  const errorMessage = useMemo(
    () => (stripeError ? translateStripeErrorMessage(stripeError.code) : null),
    [stripeError, translateStripeErrorMessage],
  );

  if (
    !paywallCandidate ||
    paywallCandidate.paymentMethodType !== PaymentMethodType.ApplePay
  ) {
    return null;
  }

  const registeredPaywall = registeredPaywalls.find(
    (item) => item.uniquePaywallKey === paywallCandidate.uniquePaywallKey,
  );

  if (!registeredPaywall) {
    throw new Error(
      "The paywallCandidate's uniquePaywallKey does not match any of the registered paywall uniquePaywallKey",
    );
  }

  const handleOnClose = () => {
    setStripeError(null);
    setPaymentMethodId(null);
    onClose();
  };

  return paymentMethodId ? (
    <StartSubscriptionFlow
      adId={paywallCandidate.adId}
      plans={[paywallCandidate.plan]}
      paymentMethodId={paymentMethodId}
      onClose={handleOnClose}
      onPurchaseComplete={(plan) => {
        registeredPaywall.onPaymentSuccessContinueButtonClick(plan);
        onClose();
      }}
      onSubscriptionActivated={registeredPaywall.onSubscriptionActivated}
      onPaymentFailedRetryButtonClick={() => {
        registeredPaywall.onPaymentFailedRetryButtonClick(
          paywallCandidate.plan,
        );
        handleOnClose();
      }}
      additionalErrorText={errorMessage}
    />
  ) : null;
};
