import { useContext, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  CardSection,
  CheckField,
  Container,
  Flex,
  FlexColumn,
  useNotifications,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { UserDetailPageContext } from "../user_detail_page_context";
import { CompanyAccountPanel } from "./company_account_panel";
import { CompanyDetailsContext } from "./company_details_context";
import { CompanyFeedsPanel } from "./company_feeds_panel";
import { CompanyPageSubscriptionPanel } from "./company_page_subscription_panel";
import { CompanyPageCustomerServiceWidget } from "./company_pages/company_page_widget";
import { CompanyUsersPanel } from "./company_users_panel";
import { ActivityStreamCard } from "./components/ActivityStreamCard";
import { AttachUserToCompanyEmptyScreen } from "./components/AttachUserToCompanyEmptyScreen";
import { CompanyVerifiedCheckField } from "./components/CompanyVerifiedCheckField";
import { CompanyDetails } from "./components/company_details";
import { CompanyDetailsHeader } from "./components/company_details_header";
import { CompanySettings } from "./components/company_settings";
import { DiscountWidget } from "./discount_widget";
import { EmbeddingWidget } from "./embedding_widget";
import { IframeWidget } from "./iframe_widget";

export const CompanyDetailsView = ({ companyId }: { companyId?: number }) => {
  const {
    company,
    isDataReady,
    companyEditableDetails,
    fetchCompany,
    updateCompany,
    fetchActivityStream,
  } = useContext(CompanyDetailsContext);

  const { addNotification } = useNotifications();
  const { user } = useContext(UserDetailPageContext);

  const isStandaloneMode = useMemo(
    () => typeof companyId !== "undefined" && companyId !== null,
    [companyId],
  );

  useEffect(() => {
    if (user) {
      if (user.company_id) {
        fetchCompany(user.company_id);
      }

      return undefined;
    } else if (companyId) {
      fetchCompany(companyId);
      return undefined;
    } else {
      return undefined;
    }
  }, [user, fetchCompany, companyId]);

  const displaySaveNotification = () => {
    if (addNotification) {
      addNotification({
        title: "Saving data...",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  const handleUpdateField = (fieldname: string, value: boolean) => {
    if (company) {
      displaySaveNotification();
      return updateCompany(company.id, fieldname, value);
    }

    throw new Error("company missing");
  };

  if (
    (isStandaloneMode && !isDataReady) ||
    (!isStandaloneMode && user?.company_id && !isDataReady)
  ) {
    return null;
  }

  return (
    <Flex
      column
      width="100%"
      bg="tint"
    >
      {isStandaloneMode && company && (
        <CompanyDetailsHeader company={company} />
      )}
      <Box
        scrollable={isStandaloneMode}
        height="100%"
      >
        <Container>
          <Flex
            column
            my={4}
          >
            <Flex gap>
              <FlexColumn size={8}>
                {companyEditableDetails && (
                  <>
                    <Card sectioned>
                      <CardSection title="Company details">
                        <CompanyDetails
                          item={companyEditableDetails}
                          updateField={handleUpdateField}
                        />
                      </CardSection>
                      <CardSection title="Company settings">
                        <CompanySettings
                          item={companyEditableDetails}
                          updateField={handleUpdateField}
                        />
                      </CardSection>
                    </Card>
                    <Card sectioned>
                      <CardSection title="Create comment">
                        <ActivityStreamCard />
                      </CardSection>
                    </Card>
                  </>
                )}

                {!companyEditableDetails && <AttachUserToCompanyEmptyScreen />}
              </FlexColumn>

              <FlexColumn size={4}>
                {company && (
                  <>
                    <Card sectioned>
                      <CardSection title="Landlord status">
                        <CompanyVerifiedCheckField
                          isVerified={companyEditableDetails?.verified}
                          handleUpdateField={handleUpdateField}
                        />
                      </CardSection>
                    </Card>
                    <CompanyUsersPanel />
                    <CompanyFeedsPanel />
                    <Card sectioned>
                      <CardSection title="Discount">
                        <DiscountWidget
                          company={company}
                          onDiscountChanged={fetchActivityStream}
                        />
                      </CardSection>
                    </Card>
                    <Card sectioned>
                      <CardSection title="Company Pages">
                        <CompanyPageCustomerServiceWidget
                          companyId={company.id}
                        />
                      </CardSection>

                      <CompanyPageSubscriptionPanel />

                      <CardSection title="Embedding">
                        <EmbeddingWidget
                          company={company}
                          updateField={handleUpdateField}
                        />
                      </CardSection>

                      {company.iframe_allowed && (
                        <CardSection title="Iframe">
                          <IframeWidget
                            company={company}
                            updateField={handleUpdateField}
                          />
                        </CardSection>
                      )}
                    </Card>
                    <CompanyAccountPanel
                      onAccountChanged={fetchActivityStream}
                      company={company}
                    />
                    <Card sectioned>
                      <CardSection title="Rental Data free access">
                        <CheckField
                          label="Enabled"
                          checked={
                            companyEditableDetails?.rental_data_free_access
                          }
                          onChange={() => {
                            handleUpdateField(
                              "rental_data_free_access",
                              !companyEditableDetails?.rental_data_free_access,
                            );
                          }}
                        />
                      </CardSection>
                    </Card>
                    <Card sectioned>
                      <CardSection
                        title={t("company_page.new_builds_title_access")}
                      >
                        <CheckField
                          label={t("company_page.new_builds_checkfield_access")}
                          checked={company?.company_new_build_access}
                          onChange={() => {
                            handleUpdateField(
                              "company_new_build_access",
                              !company?.company_new_build_access,
                            );
                          }}
                        />
                      </CardSection>
                    </Card>
                  </>
                )}
              </FlexColumn>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
};
