import { useHistory } from "react-router-dom";
import { Box, DataTable, Flex, Text, Paginator } from "@boligportal/juice";
import { format } from "date-fns";
import { useCrawlingsDraftsPageFeature } from "features/cs/crawlings_feature/hooks/use_crawlings_drafts_page_feature";
import { DraftsListItem } from "lib/customerServiceApi";
import { PAGE_HOME } from "../..";

const CrawlingsDraftsPage = () => {
  const { items, offset, limit, count, pageChange } =
    useCrawlingsDraftsPageFeature();
  const history = useHistory();

  const handleGotoDetails = (item: DraftsListItem) => {
    history.push(`${PAGE_HOME}/crawlings/drafts/detail/${item.id}`);
  };

  return (
    <Flex
      column
      width="100%"
    >
      <Box scrollable>
        <Flex column>
          <DataTable<DraftsListItem>
            fullscreen
            items={items}
            keyField="id"
            rowActions={[
              {
                label: "Edit",
                callback: handleGotoDetails,
              },
            ]}
            columns={[
              {
                fieldLabel: "Id",
                fieldName: "id",
                cellRenderer: (item) => <Text size="tiny">{item.id}</Text>,
                width: "150px",
              },
              {
                fieldLabel: "Name",
                fieldName: "name",
              },
              {
                fieldLabel: "Address",
                fieldName: "address",
              },
              {
                fieldLabel: "Import file",
                fieldName: "import_file",
              },
              {
                fieldLabel: "Errors",
                fieldName: "errors",
              },
              {
                fieldLabel: "Import date",
                fieldName: "import_date",
                width: "150px",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(new Date(item.import_date), "yyyy-MM-dd HH:mm:ss")}
                  </Text>
                ),
              },
            ]}
          />
        </Flex>
        <Flex
          column
          pt={4}
          pb={6}
          align="center"
        >
          <Paginator
            totalRecords={count}
            pageLimit={limit}
            value={offset}
            nextButtonLabel="Next"
            previousButtonLabel="Prev"
            onChange={pageChange}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export { CrawlingsDraftsPage };
