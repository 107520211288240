import { Profile } from "components/interfaces/profile";
import { format } from "date-fns";

function booleanToString(bool: boolean | null): string {
  if (bool === true) {
    return "true";
  } else if (bool === false) {
    return "false";
  } else {
    return "";
  }
}

function stringToBoolean(str: string): boolean | null {
  if (str === "true") {
    return true;
  } else if (str === "false") {
    return false;
  } else {
    return null;
  }
}

export interface ProfileFormData {
  first_name: string;
  last_name: string;
  company_name: string;
  birthday: string;
  pets: string;
  smoking: string;
  kids: string;
  expat: string;
  share_apartment: string;
  occupation: string;
  user_gender: string;
  user_type: string;
  description: string;
  profile_image: string;
}

export const convertProfileToFormData = function (
  profile: Profile,
): ProfileFormData {
  const profileFormData = {} as ProfileFormData;
  for (const key in profile) {
    if (key !== "public_url") {
      profileFormData[key] = profile[key];
      // If null, replace with empty string
      profileFormData[key] =
        profileFormData[key] === null ? "" : profileFormData[key];
    }
  }
  profileFormData.pets = booleanToString(profile.pets);
  profileFormData.smoking = booleanToString(profile.smoking);
  profileFormData.kids = booleanToString(profile.kids);
  profileFormData.expat = booleanToString(profile.expat);
  profileFormData.share_apartment = booleanToString(profile.share_apartment);
  profileFormData.occupation =
    profile.occupation !== null ? profile.occupation.toString() : "";
  profileFormData.user_gender =
    profile.user_gender !== null ? profile.user_gender.toString() : "";
  profileFormData.user_type =
    profile.user_type !== null ? profile.user_type.toString() : "";
  profileFormData.birthday = profile.birthday
    ? `${format(new Date(profile.birthday), "yyyy-MM-dd")}`
    : "";
  return profileFormData;
};

export const convertFormDataToProfile = function (
  profileFormData: ProfileFormData,
): Profile {
  const profile = {} as Profile;
  for (const key in profileFormData) {
    profile[key] = profileFormData[key];
    // If empty string, replace with null
    if (key !== "company_name") {
      profile[key] = profile[key] === "" ? null : profile[key];
    }
  }
  profile.pets = stringToBoolean(profileFormData.pets);
  profile.smoking = stringToBoolean(profileFormData.smoking);
  profile.kids = stringToBoolean(profileFormData.kids);
  profile.expat = stringToBoolean(profileFormData.expat);
  profile.share_apartment = stringToBoolean(profileFormData.share_apartment);
  profile.occupation =
    profileFormData.occupation !== ""
      ? Number(profileFormData.occupation)
      : null;
  profile.user_gender =
    profileFormData.user_gender !== ""
      ? Number(profileFormData.user_gender)
      : null;
  profile.user_type =
    profileFormData.user_type !== "" ? Number(profileFormData.user_type) : null;

  return profile;
};

export function checkProfileIsFulfilled(profile: Profile | undefined) {
  if (!profile) {
    return false;
  }

  const mandatoryFields: Array<keyof Profile> = [
    "birthday",
    "expat",
    "kids",
    "pets",
    "smoking",
    "share_apartment",
    "occupation",
    "user_type",
    "user_gender",
    "profile_image",
  ];

  return Object.entries(profile).every(([key, value]) => {
    if (mandatoryFields.includes(key as keyof Profile)) {
      return value === null ? false : true;
    }

    return true;
  });
}
