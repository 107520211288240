import { t } from "lib/i18n";
import vest, { test, enforce } from "vest";
import {
  maxRoomValue,
  minRoomValue,
} from "../components/FormElements/RentalNumberOfRooms";
import { CreateRentableForm } from "../types";
import { shouldShowApartmentInfo } from "./shouldShowAppartmentInfo";

export const validationSuite = vest.create((form: CreateRentableForm) => {
  form.rentables.forEach((rentable, index) => {
    if (rentable.shouldCreate && !rentable.disabled) {
      const fieldName = `rentables.${index}` as const;
      test(`${fieldName}.category`, t("required_field.selection"), () => {
        enforce(rentable.category).isNotEmpty();
        enforce(rentable.category !== t("Choose category")).isTruthy();
      });

      test(`${fieldName}.sizeM2`, t("required_field.numeric"), () => {
        enforce(rentable.sizeM2).isTruthy();
      });

      test(
        `${fieldName}.roomCount`,
        t("required_field.range", {
          min: minRoomValue,
          max: maxRoomValue,
        }),
        () => {
          enforce(
            rentable.roomCount && rentable.roomCount >= minRoomValue,
          ).isTruthy();
          enforce(
            rentable.roomCount && rentable.roomCount <= maxRoomValue,
          ).isTruthy();
        },
      );

      if (shouldShowApartmentInfo(rentable.category)) {
        test(
          `${fieldName}.apartmentNumber`,
          t("create.field.swedish_apartment_info.error_text"),
          () => {
            enforce(rentable.apartmentNumber?.length === 4).isTruthy();
          },
        );
      }
    }
  });
});
