import { useState } from "react";
import { Flex, IconClose, Notice, styled, Text } from "@boligportal/juice";

const CloseButton = styled(IconClose)`
  cursor: pointer;
`;

type Props = {
  children: React.ReactNode;
};

export const Alert = ({ children }: Props) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <Notice type="info">
      <Flex>
        <Text
          color="primary"
          markdown
          size="small"
          as="a"
        >
          {children}
        </Text>
        <span onClick={handleClose}>
          <CloseButton
            size="medium"
            aria-label="Close"
          />
        </span>
      </Flex>
    </Notice>
  );
};
