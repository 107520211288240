import {
  approveAd,
  createContractFromAd,
  sendEmail,
} from "lib/customerServiceApi";
import { TaskResponseInterface } from "../../../interfaces";

export const approveAdTask = async (
  templateId: number,
  adId: number,
  createContract: boolean,
  emailMessageBody: string,
): Promise<TaskResponseInterface> => {
  const approveAdResponse = await approveAd(adId);
  let { success } = approveAdResponse;

  if (success === false) {
    return Promise.resolve({
      success,
    });
  }

  const promises: Promise<any>[] = [];

  if (templateId > -1) {
    promises.push(
      sendEmail(templateId, adId, emailMessageBody).then(
        ({ success: successResponse }) => {
          success = successResponse;
        },
      ),
    );
  }

  if (createContract) {
    promises.push(createContractFromAd(adId).catch());
  }

  await Promise.all(promises);
  return Promise.resolve({
    success,
  });
};
