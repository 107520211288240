import {
  Dialog,
  IconArrowBackForward,
  IconCallOutline,
  IconCardOutline,
  IconChatboxOutline,
  IconCog,
  IconDocumentTextOutline,
  IconHeartOutline,
  IconInformationCircleOutline,
  IconLogo,
  IconLogInOutline,
  IconLogOutOutline,
  IconNotificationsOutline,
  IconPersonOutline,
  IconSearchOutline,
  IconServerOutline,
  Badge,
  IconGiftOutline,
  IconBusinessOutline,
} from "@boligportal/juice";
import { InboxCountBadge } from "components/InboxBadgeCount";
import { App } from "components/app";
import { ConfirmLogoutDialog } from "components/header/confirm_logout_dialog/ConfirmLogoutDialog";
import { useLogout } from "components/header/confirm_logout_dialog/useLogout";
import { useSignInAsColleague } from "components/header/drop_down_menu/useSignInAsColleague";
import { User } from "components/interfaces/user";
import { Market } from "components/markets/market_settings";
import { ImpersonateCompanyColleagueDialogContent } from "features/impersonate_company_colleague/components/ImpersonateCompanyColleagueDialogContent";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import {
  SidebarMenuArea,
  SidebarMenuAreaBottom,
  SidebarMenuAreaTop,
  SideBarMenuSpacer,
} from ".";
import {
  MenuGroupCollapse,
  MenuGroupCollapseToggle,
  MenuGroupCollapseContent,
} from "./components/menu_group_collapse";
import { MenuItem } from "./components/menu_item/MenuItem";
import { isUserAllowedToSeeRent } from "./conditionals/isUserAllowedToSeeRent";

const MenuIconSize = "medium";
type Props = {
  user: User;
};

export const SeekerSidebar = ({ user }: Props) => {
  const {
    PROFILE,
    INBOX,
    MY_FAVOURITE_ADS,
    MY_AGENTS,
    SUBSCRIPTIONS,
    CREATE_LISTING,
    FIND_TENANT,
    RENTAL_CONTRACTS,
    MOVING_REPORTS,
    RENT,
    SUPPORT,
    SETTINGS,
    LOG_OUT,
    SIGN_IN_AS_COLLEAGUE,
    BENEFITS,
    NEWBUILDS,
  } = menuTranslations();

  const {
    confirmLogout,
    hideConfirmLogoutDialog,
    showConfirmLogoutDialog,
    shouldShowConfirmLogoutDialog,
  } = useLogout();

  const {
    state,
    shouldShowSignInAsCompanyColleagueDialog,
    showSignInAsColleague,
    hideSignInAsColleague,
    impersonateCompanyColleague,
    isUserAllovedToImpersonateColleague,
  } = useSignInAsColleague();

  const { routes, support_url, blog_url, market } = App.settings;

  const isDK = market === Market.BOLIGPORTAL_DK;

  return (
    <SidebarMenuArea>
      <SidebarMenuAreaTop>
        <MenuItem
          href={routes.profile}
          icon={<IconPersonOutline size={MenuIconSize} />}
          title={PROFILE}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.inbox}
          icon={<IconChatboxOutline size={MenuIconSize} />}
          title={INBOX}
          info={<InboxCountBadge />}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.my_favourite_ads}
          icon={<IconHeartOutline size={MenuIconSize} />}
          title={MY_FAVOURITE_ADS}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.agents}
          icon={<IconNotificationsOutline size={MenuIconSize} />}
          title={MY_AGENTS}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.subscription}
          icon={<IconCardOutline size={MenuIconSize} />}
          title={SUBSCRIPTIONS}
        />

        {isDK && (
          <>
            <SideBarMenuSpacer size={1} />
            <MenuItem
              href={routes.benefits}
              icon={<IconGiftOutline size={MenuIconSize} />}
              info={
                <Badge variant="solid">{t("benefits_menu_item.badge")}</Badge>
              }
              title={BENEFITS}
              onClick={TrackingEvent.clickBenefitsSidebarMenu}
            />
          </>
        )}

        <SideBarMenuSpacer size={2} />

        <MenuGroupCollapse id="Seeker_Sidebar_Udlejning">
          <MenuGroupCollapseToggle
            title={t("menu.seeker.group.rental_label")}
          />
          <MenuGroupCollapseContent>
            <MenuItem
              href={routes.property_owner.create_listing}
              icon={<IconLogo />}
              title={CREATE_LISTING}
            />

            <SideBarMenuSpacer size={1} />

            {user.company_new_build_access && (
              <MenuItem
                href={routes.newbuilds}
                icon={<IconBusinessOutline size={MenuIconSize} />}
                title={NEWBUILDS}
              />
            )}

            <SideBarMenuSpacer size={1} />

            <MenuItem
              href={routes.find_tenant}
              icon={<IconSearchOutline size={MenuIconSize} />}
              title={FIND_TENANT}
            />

            <SideBarMenuSpacer size={1} />

            <MenuItem
              href={routes.contracts}
              icon={<IconDocumentTextOutline size={MenuIconSize} />}
              title={RENTAL_CONTRACTS}
            />

            <SideBarMenuSpacer size={1} />
            <MenuItem
              href={routes.moving_reports}
              icon={<IconArrowBackForward size={MenuIconSize} />}
              title={MOVING_REPORTS}
            />

            {isUserAllowedToSeeRent() && (
              <>
                <SideBarMenuSpacer size={1} />
                <MenuItem
                  href={routes.property_owner.rent}
                  icon={<IconServerOutline size={MenuIconSize} />}
                  title={RENT}
                />
              </>
            )}
          </MenuGroupCollapseContent>
        </MenuGroupCollapse>
      </SidebarMenuAreaTop>

      <SidebarMenuAreaBottom>
        <MenuItem
          href={support_url}
          icon={<IconCallOutline size={MenuIconSize} />}
          title={SUPPORT}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={blog_url}
          icon={<IconInformationCircleOutline size={MenuIconSize} />}
          title={t("footer.Blog")}
        />

        <SideBarMenuSpacer size={1} />

        <MenuItem
          href={routes.user_settings}
          icon={<IconCog size={MenuIconSize} />}
          title={SETTINGS}
        />

        <SideBarMenuSpacer size={1} />

        {isUserAllovedToImpersonateColleague() && (
          <MenuItem
            href="#"
            icon={<IconLogInOutline size={MenuIconSize} />}
            title={SIGN_IN_AS_COLLEAGUE}
            onClick={showSignInAsColleague}
          />
        )}

        <MenuItem
          href="#"
          icon={<IconLogOutOutline size={MenuIconSize} />}
          title={LOG_OUT}
          onClick={showConfirmLogoutDialog}
        />

        {shouldShowConfirmLogoutDialog && (
          <ConfirmLogoutDialog
            onClose={hideConfirmLogoutDialog}
            onConfirmed={confirmLogout}
          />
        )}
        {isUserAllovedToImpersonateColleague() && (
          <Dialog
            open={shouldShowSignInAsCompanyColleagueDialog}
            onClose={hideSignInAsColleague}
          >
            <ImpersonateCompanyColleagueDialogContent
              state={state}
              impersonateCompanyColleague={impersonateCompanyColleague}
            />
          </Dialog>
        )}

        <SideBarMenuSpacer />
      </SidebarMenuAreaBottom>
    </SidebarMenuArea>
  );
};
