import { IconInformationCircle, styled, Text } from "@boligportal/juice";
import { EmailEventStatus } from "./types";

const ToolTip = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  position: absolute;
  width: 300px;
  padding: ${(props) => props.theme.unit(1)};
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  left: ${(props) => `calc(100% + ${props.theme.unit(1.5)})`};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;

const Status = styled(Text)`
  display: flex;
`;

const InfoIcon = styled(IconInformationCircle)`
  margin-left: ${(props) => props.theme.unit(0.5)};
`;

// https://docs.aws.amazon.com/ses/latest/dg/monitor-sending-activity.html
const statusExplanations: Record<EmailEventStatus, string> = {
  Send: "The send request was successful and Amazon SES will attempt to deliver the message to the recipient’s mail server. (If account-level or global suppression is being used, SES will still count it as a send, but delivery is suppressed.)",
  RenderingFailure:
    "The email wasn't sent because of a template rendering issue. This event type can occur when template data is missing, or when there is a mismatch between template parameters and data. (This event type only occurs when you send email using the SendTemplatedEmail or SendBulkTemplatedEmail API operations.)",
  Reject:
    "Amazon SES accepted the email, but determined that it contained a virus and didn’t attempt to deliver it to the recipient’s mail server.",
  Delivery:
    "Amazon SES successfully delivered the email to the recipient's mail server.",
  Bounce:
    "The recipient's mail server permanently rejected the email. (Soft bounces are only included when Amazon SES fails to deliver the email after retrying for a period of time.)",
  Complaint:
    "The email was successfully delivered to the recipient’s mail server, but the recipient marked it as spam.",
  DeliveryDelay:
    "The email couldn't be delivered to the recipient’s mail server because a temporary issue occurred. Delivery delays can occur, for example, when the recipient's inbox is full, or when the receiving email server experiences a transient issue.",
  Subscription:
    "The email was successfully delivered, but the recipient updated the subscription preferences by clicking List-Unsubscribe in the email header or the Unsubscribe link in the footer.",
  Open: "The recipient received the message and opened it in their email client.",
  Click: "The recipient clicked one or more links in the email.",
};

export const EmailStatusTooltip = ({
  status,
}: {
  status: EmailEventStatus;
}) => (
  <ToolTip>
    <Status
      color="muted"
      size="small"
    >
      {status}
      <InfoIcon size="medium" />
    </Status>
    <TooltipText>{statusExplanations[status]}</TooltipText>
  </ToolTip>
);
