import * as React from "react";
import { Box, focusBorder, styled } from "@boligportal/juice";

interface Props {
  title: React.ReactNode;
  href: string;
  onClick?: () => void;
  paddingLeft?: 2 | 3;
  icon?: React.ReactNode;
  info?: React.ReactNode;
}

export const MenuItem = React.forwardRef<HTMLAnchorElement, Props>(
  (props, ref) => {
    const pathname = encodeURI(location.pathname);
    const { href, title, icon, info, onClick, paddingLeft = 2 } = props;

    return (
      <StyledMenuItem
        ref={ref}
        href={href}
        isActive={pathname.indexOf(href) === 0}
        paddingLeft={paddingLeft}
        {...(onClick && {
          onClick: onClick,
        })}
      >
        {icon && (
          <>
            {icon}
            <Box pr={2} />
          </>
        )}
        <>{title}</>
        <Box flexGrow />
        {info && <>{info}</>}
      </StyledMenuItem>
    );
  },
);
MenuItem.displayName = "SidebarMenuItem";

const StyledMenuItem = styled.a<{ isActive: boolean; paddingLeft: 2 | 3 }>`
  display: flex;
  align-items: center;

  height: 40px;

  padding-left: ${(props) => props.theme.unit(props.paddingLeft)};
  padding-right: ${(props) => props.theme.unit(2)};

  cursor: pointer;
  color: rgba(255, 255, 255, ${(props) => (props.isActive ? 1 : 0.85)});
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background: ${(props) =>
    props.isActive
      ? `${props.theme.colorPalette.primary[700]} !important`
      : "none"};
  font-weight: ${(props) => (props.isActive ? "500" : "500")};
  border-left: 4px solid
    ${(props) =>
      props.isActive
        ? props.theme.colorPalette.brand[500]
        : props.theme.colorPalette.primary[800]};

  ${focusBorder};

  &:hover {
    background: ${(props) => props.theme.colorPalette.primary[700]};
    color: white;
    text-decoration: none;
    border-left: 4px solid
      ${(props) =>
        props.isActive
          ? props.theme.colorPalette.brand[500]
          : props.theme.colorPalette.primary[700]};
  }

  &:active {
    background: ${(props) => props.theme.colorPalette.primary[700]};
    color: white;
    text-decoration: none;
  }
`;

interface MenuMoreOpenerProps {
  title: string;
  paddingLeft?: 2 | 3;
  icon?: React.ReactNode;
}

const StyledButton = styled.button<{ paddingLeft: 2 | 3 }>`
  display: flex;
  align-items: center;

  height: 40px;

  padding-left: ${(props) => props.theme.unit(props.paddingLeft)};
  padding-right: ${(props) => props.theme.unit(2)};

  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background: none;
  font-weight: 500;
  border-left: 4px solid ${(props) => props.theme.colorPalette.primary[800]};

  border-top: none;
  border-right: none;
  border-bottom: none;

  ${focusBorder};

  &:hover {
    background: ${(props) => props.theme.colorPalette.primary[700]};
    color: white;
    text-decoration: none;
    border-left: 4px solid ${(props) => props.theme.colorPalette.primary[700]};
  }
`;

export const MenuMoreOpener = React.forwardRef<
  HTMLButtonElement,
  MenuMoreOpenerProps
>((props, ref) => {
  const { title, icon, paddingLeft = 2, ...rest } = props;
  return (
    <StyledButton
      paddingLeft={paddingLeft}
      ref={ref}
      {...rest}
    >
      {icon}
      <Box pr={2} />
      {title}
      <Box flexGrow />
    </StyledButton>
  );
});

MenuMoreOpener.displayName = "MenuMoreOpener";
