import { SelectField } from "@boligportal/juice";
import { App } from "components/app";
import { t } from "lib/i18n";

interface Props {
  category: string;
}

export const RentalCategoryReadOnly = ({ category }: Props) => (
  <SelectField
    disabled
    label={t("create.field.category.heading")}
    items={[
      {
        value: "",
        label: `${App.settings.rental_categories.find((x) => x.id === category)
          ?.name}`,
      },
    ]}
    value="hey"
  />
);
