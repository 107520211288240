import * as React from "react";
import { Box, Headline, styled } from "@boligportal/juice";

const SectionLine = styled.div`
  width: 70px;
  height: 3px;
  background-color: ${(props) => props.theme.colorPalette.brand["500"]};
  margin-bottom: 0px;
  margin-top: -12px;
`;

interface SectionProps extends React.ComponentProps<typeof Box> {
  heading: string;
  variant?: "h1" | "h2" | "h3" | "h4";
  sectionLine?: boolean;
}

const Section = ({
  children,
  sectionLine,
  heading,
  variant = "h3",
  ...props
}: SectionProps) => {
  const showSectionLine =
    sectionLine != undefined && !sectionLine ? sectionLine : true;

  return (
    <Box
      as="section"
      {...props}
    >
      <Headline as={variant}>{heading}</Headline>
      {variant !== "h4" && showSectionLine && <SectionLine />}
      {children}
    </Box>
  );
};

export { Section };
