import * as React from "react";
import { Flex, Button, Box, Spinner, styled } from "@boligportal/juice";
import { User } from "components/interfaces/user";
import { ProfileImage } from "components/profile_image";
import { API } from "lib/api";
import { t } from "lib/i18n";

enum FORM_STATE {
  IDLE,
  UPLOADING,
  DELETING,
}

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledEmptyButtonPlaceholder = styled.div`
  height: 24px;
`;

const StyledSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
`;

const UpdateProfileImage = (props: { user: User; reloadAppProps: any }) => {
  const { user, reloadAppProps } = props;
  const [formState, setFormState] = React.useState(FORM_STATE.IDLE);

  const hiddenFileInputRef: React.RefObject<HTMLInputElement> =
    React.createRef();

  const onFilesSelected = (files: FileList | null) => {
    if (files && files.length) {
      setFormState(FORM_STATE.UPLOADING);
      API.uploadProfileImage(files[0]).then((response) => {
        setFormState(FORM_STATE.IDLE);
        if (response.ok) {
          reloadAppProps();
        }
      });
    }
  };
  const onDeleteImage = (e) => {
    e.preventDefault();
    if (confirm(t("update_profile_image.Confirm_delete"))) {
      setFormState(FORM_STATE.DELETING);
      API.deleteProfileImage().then((response) => {
        setFormState(FORM_STATE.IDLE);
        if (response.ok) {
          reloadAppProps();
        }
      });
    }
  };

  const onFilesChanged = () => {
    const fileElement = hiddenFileInputRef.current;
    if (fileElement) {
      onFilesSelected(fileElement.files);
      fileElement.files = null;
    }
  };

  const onButtonClick = (e) => {
    e.preventDefault();
    const fileElement = hiddenFileInputRef.current;
    if (fileElement) {
      fileElement.addEventListener("change", onFilesChanged);
      fileElement.click();
    }
  };

  return (
    <Flex
      column
      align="center"
    >
      <input
        type="file"
        multiple={false}
        accept="image/*"
        hidden
        ref={hiddenFileInputRef}
      />

      {formState === FORM_STATE.UPLOADING ? (
        <>
          <StyledImageWrapper>
            <Button
              variant="subtle"
              disabled
              type="submit"
            >
              <ProfileImage
                user={user}
                size={150}
              />
            </Button>
            <StyledSpinnerWrapper>
              <Spinner size="large" />
            </StyledSpinnerWrapper>
          </StyledImageWrapper>
          <Box mt={1}>
            <StyledEmptyButtonPlaceholder />
          </Box>
        </>
      ) : (
        <>
          <Button
            variant="subtle"
            type="submit"
            onClick={onButtonClick}
          >
            <ProfileImage
              user={user}
              size={150}
            />
          </Button>
          {user.profile_image ? (
            <Box mt={1}>
              <Button
                loading={formState === FORM_STATE.DELETING}
                text
                variant="danger"
                onClick={onDeleteImage}
              >
                {t("update_profile_image.delete_button")}
              </Button>
            </Box>
          ) : (
            <Box mt={1}>
              <StyledEmptyButtonPlaceholder />
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

export { UpdateProfileImage };
