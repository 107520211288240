import { useQuery } from "react-query";
import {
  getLocations,
  getUserPartOfVerifiedCompany,
  LocationAutoCompleteResponse,
} from "api";
import { LocationResult } from "components/location_auto_complete/types";

export const utilsKeys = {
  autocompleteSuggestions: (search?: string) =>
    ["autocompleteSuggestions", search] as const,
  isUserPartOfVerifiedCompany: () => ["isUserPartOfVerifiedCompany"] as const,
};

const transformLocationResults = (
  data: LocationAutoCompleteResponse,
): LocationResult[] =>
  data.results.map((result) => ({
    id: result.url,
    url: result.url,
    text: result.name,
  }));

export const useLocationAutoCompleteSuggestions = (
  search?: string,
  onSuccess?: () => void,
) =>
  useQuery(
    utilsKeys.autocompleteSuggestions(search),
    () => getLocations(search),
    {
      retry: false,
      keepPreviousData: true,
      select: transformLocationResults,
      enabled: search !== undefined,
      onSuccess,
    },
  );

export const useAuthorizeIsUserPartOfVerifiedCompanyQuery = () =>
  useQuery(utilsKeys.isUserPartOfVerifiedCompany(), () =>
    getUserPartOfVerifiedCompany(),
  );
