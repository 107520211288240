import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  CheckField,
  Flex,
  Text,
} from "@boligportal/juice";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { t } from "lib/i18n";
import { LOCAL_STORAGE_KEY } from "./DefectDetails";

export const NoImagesConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: (state: boolean) => void;
  onConfirm: () => void;
}) => {
  const [_, setIsConfirmationDisabled] = useLocalStorageState(
    LOCAL_STORAGE_KEY,
    false,
  );

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmationDisabled(event.currentTarget.checked);
  };

  const handleClose = () => onClose(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      size="medium"
    >
      <DialogHeader>
        {t("moving_reports.room.unit.create_defect.picture_notice.title")}
      </DialogHeader>

      <DialogContent>
        <Box mb={3}>
          <Text>
            {t("moving_reports.room.unit.create_defect.picture_notice.message")}
          </Text>
        </Box>

        <CheckField
          label={t(
            "moving_reports.room.unit.create_defect.picture_notice.confirmation_checkbox",
          )}
          onChange={handleCheck}
        />
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button onClick={handleClose}>
            {t("moving_reports.room.unit.create_defect.picture_notice.cancel")}
          </Button>

          <Button
            variant="primary"
            onClick={onConfirm}
          >
            {t("moving_reports.room.unit.create_defect.picture_notice.confirm")}
          </Button>
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
