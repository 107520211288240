import { fetchFromAPI } from "lib/api";
import { CompanyPageForm, CompanyPageResponse, CompanyPage } from "./types";

export async function updateCompanyPage(
  companyId: number,
  companyPage: CompanyPage,
  companyPageForm: CompanyPageForm,
): Promise<CompanyPage | { error: string }> {
  const url = `/customer-service/api/companies/${companyId}/company-page/${companyPage.id}/`;
  const response = await fetchFromAPI(url, {
    method: "POST",
    body: JSON.stringify(companyPageForm),
  });
  const jsn = await response.json();
  if (response.status === 500) {
    return Promise.reject(jsn);
  }
  return jsn;
}

export async function createCompanyPage(
  companyId: number,
  companyPageForm: CompanyPageForm,
): Promise<CompanyPage | { error: string }> {
  const url = `/customer-service/api/companies/${companyId}/company-page/`;
  const response = await fetchFromAPI(url, {
    method: "POST",
    body: JSON.stringify(companyPageForm),
  });
  const jsn = await response.json();
  if (response.status === 500) {
    return Promise.reject(jsn);
  }
  return jsn;
}

export async function deleteCompanyPage(
  companyId: number,
  companyPage: CompanyPage,
): Promise<CompanyPage | { error: string }> {
  const url = `/customer-service/api/companies/${companyId}/company-page/${companyPage.id}/`;
  const response = await fetchFromAPI(url, {
    method: "DELETE",
  });
  return response.json();
}

export async function getCompanyPages(
  companyId: number,
): Promise<{ company_pages: CompanyPageResponse[] }> {
  const url = `/customer-service/api/companies/${companyId}/company-pages/`;
  try {
    const response = await fetchFromAPI(url);
    return await response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}
