import { Flex, Text } from "@boligportal/juice";
import { Icon } from "@boligportal/juice/dist/components/Icon/Icon";

export type PaywallBenefit = {
  text: string;
  icon: typeof Icon;
};

export const PaywallBenefits = ({
  benefits,
}: {
  benefits: PaywallBenefit[];
}) => (
  <>
    {benefits.map(({ icon: Icon, text }) => (
      <Flex
        key={text}
        gap
      >
        <Icon
          size="large"
          color="branded"
        />
        <Text>{text}</Text>
      </Flex>
    ))}
  </>
);
