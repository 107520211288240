import * as React from "react";
import { Box, styled } from "@boligportal/juice";

type Props = {
  children: React.ReactNode;
};

export const HorizontalOverflowContainer = ({ children }: Props) => (
  <HorizontalOverflow>
    <ContainerFluidThatDoesNotClipContent>
      {children}
    </ContainerFluidThatDoesNotClipContent>
  </HorizontalOverflow>
);

const ContainerFluidThatDoesNotClipContent = ({ children }: Props) => (
  <Box
    mx={{
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
    }}
    m="auto"
  >
    {children}
  </Box>
);

export const HorizontalOverflow = styled.div`
  overflow-x: auto;
`;
