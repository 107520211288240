import {
  AddressAndTenancyLinkCell,
  RentDueDateCell,
  RentAmountCell,
  RentPaymentInfoCell,
  RentPaymentStatusCell,
  RentPaymentDetailButtonCell,
  RentRecordElipsisMenuCell,
} from "components/cells";
import { RentRecord } from "../types/RentRecord";

export type RentRecordPresenter = { id: number } & AddressAndTenancyLinkCell &
  RentDueDateCell &
  RentAmountCell &
  RentPaymentStatusCell &
  RentPaymentInfoCell &
  RentPaymentDetailButtonCell &
  RentRecordElipsisMenuCell;

export type RentRecordSortableColumns = Pick<
  RentRecord,
  "address" | "due_date" | "status" | "amount" | "paymentInfo"
>;
export type RentRecordPresenterSortableColumns = Pick<
  RentRecordPresenter,
  | "addressAndTenancyCell"
  | "rentAmountCell"
  | "rentDueDateCell"
  | "rentPaymentInfoCell"
  | "rentPaymentStatusCell"
>;

export const recordToPresenterMap: Record<
  keyof RentRecordSortableColumns,
  keyof RentRecordPresenterSortableColumns
> = {
  address: "addressAndTenancyCell",
  amount: "rentAmountCell",
  due_date: "rentDueDateCell",
  paymentInfo: "rentPaymentInfoCell",
  status: "rentPaymentStatusCell",
};

export const presenterToRecordMap: Record<
  keyof RentRecordPresenterSortableColumns,
  keyof RentRecordSortableColumns
> = {
  addressAndTenancyCell: "address",
  rentAmountCell: "amount",
  rentDueDateCell: "due_date",
  rentPaymentInfoCell: "paymentInfo",
  rentPaymentStatusCell: "status",
};
