import { AdStateLabel } from "../../../components/ad_state_label";
import { AdItem } from "../interfaces";

const AdStatusRenderer = (data: AdItem) => {
  const { state, needs_follow_up, review_reason, claimed_by, deleted_date } =
    data;
  return (
    <AdStateLabel
      state={state}
      needsFollowUp={needs_follow_up}
      reviewReason={review_reason}
      claimer={claimed_by}
      isDeleted={Boolean(deleted_date)}
    />
  );
};

export { AdStatusRenderer };
