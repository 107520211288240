import { useRef, useState } from "react";
import { updateImageService } from "../services/updateImageService";
import { ImageCrop } from "../types/ImageCrop";
import { ImageCropPayload } from "../types/ImageCropPayload";

export const useReactCrop = (
  adId: number,
  initialImageId: number,
  initialImageUrl: string,
) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [imageData, setImageData] = useState({
    imageId: initialImageId,
    imageUrl: initialImageUrl,
  });

  const [realDimensions, setRealDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [crop, setCrop] = useState<ImageCrop>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [status, setStatus] = useState({
    canSave: false,
    isSaving: false,
  });

  const dragEndHandler = () => {
    setStatus((prev) => ({
      ...prev,
      canSave: crop.width > 0 && crop.height > 0,
    }));
  };

  const imageLoadedHandler = (imageEl: HTMLImageElement) => {
    imageRef.current = imageEl;
    setRealDimensions({
      width: imageEl.naturalWidth,
      height: imageEl.naturalHeight,
    });
  };

  const changeHandler = (newCrop: ImageCrop) => {
    setCrop(newCrop);
  };

  const save = () => {
    if (
      !imageRef.current ||
      !crop.width ||
      !crop.height ||
      !realDimensions.width ||
      !realDimensions.height
    ) {
      return;
    }

    const scaleWidth = realDimensions.width / imageRef.current.clientWidth;
    const scaleHeight = realDimensions.height / imageRef.current.clientHeight;

    const cropAreaWidthReal = Math.floor(crop.width * scaleWidth);
    const cropAreaHeightReal = Math.floor(crop.height * scaleHeight);
    const cropAreaXReal = Math.floor(crop.x * scaleWidth);
    const cropAreaYReal = Math.floor(crop.y * scaleHeight);

    const imageCropPayload = `${cropAreaXReal}x${cropAreaYReal}:${
      cropAreaXReal + cropAreaWidthReal
    }x${cropAreaYReal + cropAreaHeightReal}` as const;

    saveImage(adId, imageData.imageId, imageCropPayload);
  };

  const saveImage = async (
    adId: number,
    imageId: number,
    imageCropPayload: ImageCropPayload,
  ) => {
    setStatus((prev) => ({
      ...prev,
      isSaving: true,
    }));

    const response = await updateImageService(adId, imageId, imageCropPayload);

    if (response.imageUrl && response.imageId) {
      setCrop({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      });
      setImageData({
        imageId: response.imageId,
        imageUrl: response.imageUrl,
      });
    }

    setStatus((prev) => ({
      ...prev,
      isSaving: false,
    }));
  };

  return {
    save,
    dragEndHandler,
    imageLoadedHandler,
    changeHandler,
    ...status,
    crop,
    imageUrl: imageData.imageUrl,
  };
};
