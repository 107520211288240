import { Controller } from "react-hook-form";
import { Box, RadioField } from "@boligportal/juice";
import { Option } from "components/interfaces/option";
import { FormFieldBaseProps } from "./interfaces";

interface FormItemRadiosProps extends FormFieldBaseProps {
  options: Option[];
  initialSelection?: string;
}

export const FormItemRadios = (props: FormItemRadiosProps) => {
  const { label, name, errorText, helpText, options, control } = props;

  return (
    <Box mb={2}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioField
            label={label || ""}
            errorText={errorText}
            helpText={helpText}
            items={options.map((option) => ({
              label: option.name,
              value: option.id.toString(),
            }))}
            {...field}
          />
        )}
      />
    </Box>
  );
};
