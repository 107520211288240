import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  IconMailOpenOutline,
  Text,
  useNotifications,
} from "@boligportal/juice";
import { useImpersonateUserFeature } from "features/cs/impersonate_user/hooks/use_impersonate_user_feature";
import * as CustomerServiceApi from "lib/customerServiceApi";
import {
  getSortedEmailTemplates,
  getUserEmailTemplateContent,
} from "lib/customerServiceApi";
import { contactUserTask } from "../../ad_detail_page/components/toolbar_actions/action_tasks/contact_user_task";
import { ConfirmableAction } from "../../ad_detail_page/components/toolbar_actions/confirmable_action";
import { EmailTemplateItem } from "../../ad_detail_page/interfaces";
import BlacklistGetUsers from "../blacklist/blacklist_get_contacted_users";
import BlacklistUser from "../blacklist/blacklist_user";
import { GDPRDelete } from "../gdpr_delete/gdpr_delete";
import CompanyDealAccess from "../unlimited_seeker_access/unlimited_seeker_access";
import { UserEmailLogs } from "../user_email_logs_link";

interface ProfileHeaderProps {
  fullname: string;
  id: number;
  date_created: string;
  last_login: string;
  last_edited: string;
  email: string;
  onGdprDeleteSuccess: () => void;
  year_deal: boolean;
  needs_review: boolean;
  refetch: () => void;
}

const ProfileHeader = ({
  fullname,
  id,
  date_created,
  last_login,
  onGdprDeleteSuccess: gdprDeleteSuccess,
  year_deal,
  email,
  last_edited,
  needs_review,
  refetch,
}: ProfileHeaderProps) => {
  const { getImpersonateUserHref, impersonateUserLabel } =
    useImpersonateUserFeature();
  const [stateTemplates, setStateTemplates] = useState<EmailTemplateItem[]>();
  const { addNotification } = useNotifications();

  const getAllEmailTemplates = async (): Promise<EmailTemplateItem[]> => {
    const allEmailTemplates = getSortedEmailTemplates()
      .then((response) => {
        setStateTemplates(response);
        return response;
      })
      .catch((error) => Promise.reject(error));
    return allEmailTemplates;
  };

  const onClickApproveChanges = async () => {
    try {
      const response = await CustomerServiceApi.approveUser(id);
      if (response.success) {
        if (response.nextUserToApproveId !== null) {
          window.location.href = `/customer-service/users/${response.nextUserToApproveId}/`;
        } else {
          refetch();
        }
      } else {
        showApprovalFailedNotification();
      }
    } catch {
      showApprovalFailedNotification();
    }
  };

  const showApprovalFailedNotification = () => {
    if (addNotification) {
      addNotification({
        title: "Error while trying to approve user changes",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }
  };

  useEffect(() => {
    getAllEmailTemplates();
  }, []);

  const handleContactUserConfirmed = (
    templateId: number,
    emailMessageBody: string,
  ) => contactUserTask(templateId, id, emailMessageBody);
  return (
    <Flex
      gap
      width="100%"
    >
      <Flex
        column
        flexGrow
      >
        <Flex>
          <Text weight="bold">{fullname}</Text>
          <Text pl={1}>#{id}</Text>
        </Flex>
        <Flex gap={4}>
          <Flex>
            <Text
              weight="bold"
              size="small"
            >
              Created:
            </Text>
            <Text
              pl={1}
              size="small"
            >
              {date_created}
            </Text>
          </Flex>
          <Flex>
            <Text
              weight="bold"
              size="small"
            >
              Last login:
            </Text>
            <Text
              pl={1}
              size="small"
            >
              {last_login}
            </Text>
          </Flex>
          <Flex>
            <Text
              weight="bold"
              size="small"
            >
              Last edited:
            </Text>
            <Text
              pl={1}
              size="small"
            >
              {last_edited}
            </Text>
          </Flex>
        </Flex>
        <Flex
          gap={4}
          mt={0.5}
        >
          <UserEmailLogs email={email} />
          <BlacklistGetUsers user_id={id} />
          <CompanyDealAccess
            user_id={id}
            year_deal={year_deal}
          />
          <GDPRDelete
            id={id}
            onGdprDeleteSuccess={gdprDeleteSuccess}
            email={email}
          />
          <BlacklistUser
            fullname={fullname}
            templateOptions={stateTemplates ? stateTemplates : []}
          />
        </Flex>
      </Flex>
      <Flex
        align="center"
        gap={2}
      >
        {needs_review && (
          <Button onClick={onClickApproveChanges}>Approve changes</Button>
        )}
        <ConfirmableAction
          templateOptions={stateTemplates ? stateTemplates : []}
          emailTemplateLoaderId={id}
          emailTemplateLoader={getUserEmailTemplateContent}
          emailOptional={false}
          hasContract={false}
          actionTitle="Contact user"
          actionCancelLabel="Cancel"
          actionConfirmLabel="Confirm"
          actionConfirmedTask={handleContactUserConfirmed}
          icon={IconMailOpenOutline}
        />
        <Button
          as="a"
          href={getImpersonateUserHref(id)}
        >
          {impersonateUserLabel}
        </Button>
      </Flex>
    </Flex>
  );
};
export { ProfileHeader };
