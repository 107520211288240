import * as React from "react";
import { Box, Card, styled, useTheme } from "@boligportal/juice";

export const CardWithNoNegativeMargin = styled(Card)`
  margin-left: 0;
  margin-right: 0;
`;

export const FullWidthMobileContainer = ({
  children,
  px,
}: {
  children: React.ReactNode;
  px?: React.ComponentProps<typeof Box>["px"];
}) => {
  const theme = useTheme();

  return (
    <Box
      px={px}
      m="auto"
      width="auto"
      maxWidth={{
        ...theme.containerMaxWidth,
        xs: "100%",
        sm: "100%",
        md: "100%",
      }}
    >
      {children}
    </Box>
  );
};
