import { t } from "lib/i18n";

export const getListingErrorMessages = (config: {
  minRoomValue: number;
  maxRoomValue: number;
}) => {
  const inputfieldIsRequiredTrans = t("required_field.input");
  const selectionIsRequiredTrans = t("required_field.selection");
  const numberOfRoomsRequiredTrans = t("required_field.range", {
    min: config.minRoomValue,
    max: config.maxRoomValue,
  });
  const numberRequiredTrans = t("required_field.numeric");
  const dateRequiredTrans = t("required_field.date");
  const emailRequiredTrans = t("required_field.email");
  const phoneNumberRequiredTrans = t("required_field.phone");
  const referenceIdErrorLength = t(
    "rentable_details.reference_id.error.length",
  );
  const depositSizeError = t("deposit.error.exceeds_rent_rule");
  const prepaidRentSizeError = t("prepaid_rent.error.exceeds_rent_rule");

  const fieldToErrorMap = {
    category: selectionIsRequiredTrans,
    address: inputfieldIsRequiredTrans,
    postal_code: inputfieldIsRequiredTrans,
    rental_period: selectionIsRequiredTrans,
    available_from: dateRequiredTrans,
    ad_phone_number: phoneNumberRequiredTrans,
    contact_phone: phoneNumberRequiredTrans,
    contact_email: emailRequiredTrans,
    title: inputfieldIsRequiredTrans,
    description: inputfieldIsRequiredTrans,
    rooms: numberOfRoomsRequiredTrans,
    size_m2: numberRequiredTrans,
    monthly_rent: numberRequiredTrans,
    reference_id: referenceIdErrorLength,
    deposit: depositSizeError,
    prepaid_rent: prepaidRentSizeError,
    monthly_rent_extra_costs: numberRequiredTrans,
  };

  return {
    fieldToErrorMap,
    inputfieldIsRequiredTrans,
    selectionIsRequiredTrans,
    numberOfRoomsRequiredTrans,
    numberRequiredTrans,
    dateRequiredTrans,
    emailRequiredTrans,
    phoneNumberRequiredTrans,
    referenceIdErrorLength,
    depositSizeError,
    prepaidRentSizeError,
  };
};
