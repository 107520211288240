import { Flex, IconCheckmark, styled, Text } from "@boligportal/juice";

const Wrapper = styled(Flex)`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.unit(1)};
  }
`;

export const SellingPoint = ({ text }: { text: string }) => (
  <Wrapper align="center">
    <IconCheckmark
      color="success"
      size="medium"
    />
    <Text
      size="small"
      ml={1}
      markdown
    >
      {text}
    </Text>
  </Wrapper>
);
