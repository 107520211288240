import { CompanySearchToggle } from "components/forms/elements/company_search_toggle";
import { DanishExtraRoomInfo } from "components/forms/elements/danish_extra_room_info";
import { Deposit } from "components/forms/elements/deposit";
import { JournalNumber } from "components/forms/elements/journal_number";
import { MatterportFloorPlanWithModalExplainer } from "components/forms/elements/matterport_floor_plan_with_modal_explainer";
import { MonthlyRent } from "components/forms/elements/monthly_rent";
import { MonthlyRentExtraCosts } from "components/forms/elements/monthly_rent_extra_costs";
import { MoveInPrice } from "components/forms/elements/move_in_price";
import { PrepaidRent } from "components/forms/elements/prepaid_rent";
import { Rental3dFloorplan } from "components/forms/elements/rental_3d_floor_plan";
import { RentalCategory } from "components/forms/elements/rental_category";
import { RentalCategoryReadOnly } from "components/forms/elements/rental_category_read_only";
import { RentalContactAdOptions } from "components/forms/elements/rental_contact_ad_options";
import { RentalContractAdOptions } from "components/forms/elements/rental_contract_ad_options";
import { RentalDescription } from "components/forms/elements/rental_description";
import { RentalDigitalShowing } from "components/forms/elements/rental_digital_showing";
import {
  RentalEnergyLabel,
  RentalEnergyLabelWithDomutechNotice,
} from "components/forms/elements/rental_energi_label";
import { RentalEstate } from "components/forms/elements/rental_estate";
import { RentalFeatures } from "components/forms/elements/rental_features";
import { RentalMoveInDate } from "components/forms/elements/rental_move_in_date";
import { RentalNumberOfRooms } from "components/forms/elements/rental_number_of_rooms";
import { RentalPeriod } from "components/forms/elements/rental_period";
import { RentalReferenceId } from "components/forms/elements/rental_reference_id";
import { RentalSize } from "components/forms/elements/rental_size";
import { RentalTitle } from "components/forms/elements/rental_title";
import { SwedishApartmentInfo } from "components/forms/elements/swedish_apartment_info";

const ListingFields = {
  MoveInPrice,
  MonthlyRent,
  MonthlyRentExtraCosts,
  PrepaidRent,
  Deposit,
  CompanySearchToggle,
  JournalNumber,
  MatterportFloorPlanWithModalExplainer,
  Rental3dFloorplan,
  RentalCategoryReadOnly,
  RentalContactAdOptions,
  RentalDigitalShowing,
  RentalEnergyLabel,
  RentalEnergyLabelWithDomutechNotice,
  RentalFeatures,
  RentalMoveInDate,
  RentalNumberOfRooms,
  RentalPeriod,
  RentalReferenceId,
  RentalSize,
  RentalTitle,
  RentalDescription,
  RentalContractAdOptions,
};

const RentableFields = {
  SwedishApartmentInfo,
  DanishExtraRoomInfo,
  RentalCategory,
  RentalSize,
  RentalNumberOfRooms,
  RentalEstate,
};

export { ListingFields, RentableFields };
