import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  CheckFieldNaked,
  Dropdown,
  Flex,
  IconEllipsisVertical,
  styled,
  Text,
  useTheme,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { BasicAvatar } from "components/design-system/atoms/avatar/basicAvatar";
import { SquareImage } from "components/design-system/atoms/square_image/square_image";
import { stripHtml } from "lib/utils";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(230, 230, 230, 0.55);
  z-index: 1;
  pointer-events: none;
`;

const StyledLi = styled.li<{ hasOnSelect: boolean }>`
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.text.base};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  list-style-type: none;

  & > a {
    display: flex;
    height: 100%;
    padding: 15px;
    padding-left: ${({ hasOnSelect }) => (hasOnSelect ? "32px" : "15px")};
    color: ${({ theme }) => theme.color.text.base};

    &:focus {
      outline: none;
    }
    [data-whatinput="keyboard"] &:focus {
      box-shadow:
        inset 1px 0 0 2px ${({ theme }) => theme.color.focusBorder},
        inset -1px 0 0 2px ${({ theme }) => theme.color.focusBorder},
        0 0 0 1px ${({ theme }) => theme.color.focusBorder};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.bg.tint};
    }

    &:hover,
    &:link,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.color.text.base};
      text-decoration: none;
    }
  }
`;

const StyledThreadBody = styled.div<{ isRead: boolean; blur?: boolean }>`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isRead, theme, blur }) =>
    isRead
      ? `
    ${blur ? "filter: blur(2px);" : ""}
    color: ${theme.colorPalette.gray[400]};
  `
      : `
    ${blur ? "filter: blur(4px);" : ""}
    font-weight: bold;
    color: ${theme.color.text.base};
  `};
`;

interface Props {
  children: React.ReactNode;
  blur?: boolean;
  isRead: boolean;
}

export const ThreadBody = ({ children, blur, isRead }: Props) => {
  if (!children) {
    return null;
  }

  const strippedBody = stripHtml(children.toString());

  return (
    <StyledThreadBody
      isRead={isRead}
      blur={blur}
    >
      {strippedBody}
    </StyledThreadBody>
  );
};

const StyledActionsButton = styled(Button)`
  position: absolute;
  top: 23px;
  right: 10px;
  color: ${({ theme }) => theme.color.text.base} !important;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 5px;
  margin-top: -5px;
  &:hover {
    background-color: ${({ theme }) => theme.colorPalette.gray[30]};
  }
`;
const StyledCheckWrapper = styled(CheckFieldNaked)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListItemBasis = ({
  link,
  actions,
  children,
  image,
  isDisabled,
  onClick,
  onSelect,
  selected,
  title,
  titleUseBold,
  topRightCornerText,
}: {
  title?: string | JSX.Element;
  titleUseBold?: boolean;
  image?: { src?: string; useSquare?: boolean };
  link: string;
  topRightCornerText?: string;
  children?: React.ReactNode;
  actions?: React.ReactElement[];
  onSelect?: (selected: boolean) => void;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  reference_id?: string | null;
  isDisabled?: boolean;
}) => {
  const theme = useTheme();
  const renderImage = () => {
    if (!image) {
      return null;
    }

    if (image.useSquare) {
      return (
        <SquareImage
          src={(image && image.src) || "/static/images/no-image.jpg"}
        />
      );
    }

    return (
      <BasicAvatar
        src={image.src}
        size={60}
      />
    );
  };

  return (
    <StyledLi hasOnSelect={Boolean(onSelect)}>
      {isDisabled && <Overlay />}
      {onSelect && (
        <StyledCheckWrapper
          checked={Boolean(selected)}
          onChange={(e) => onSelect && onSelect(e.target.checked)}
        />
      )}
      <NavLink
        to={link}
        onClick={onClick}
        tabIndex={0}
        activeStyle={{
          backgroundColor: theme.color.bg.tint,
          boxShadow: `inset -2px 0 0 0 ${theme.colorPalette.primary[500]}`,
        }}
      >
        <Box width="60px">{renderImage()}</Box>

        <Flex
          ml="15px"
          flexGrow
          column
          justify="center"
        >
          <Flex
            mb="5px"
            justify="space-between"
            align="center"
          >
            {typeof title === "string" ? (
              <Text
                block
                size="small"
                weight={titleUseBold ? "bold" : "base"}
                css={css`
                  line-height: 15px;
                `}
              >
                {title}
              </Text>
            ) : (
              title
            )}

            {topRightCornerText && (
              <Text
                block
                color="muted"
                size="tiny"
                pl="5px"
                pr={actions ? "28px" : "0"}
                whiteSpace="nowrap"
              >
                {topRightCornerText}
              </Text>
            )}
          </Flex>
          {children}
        </Flex>
      </NavLink>
      {actions && (
        <Dropdown
          align="end"
          opener={
            <StyledActionsButton text>
              <IconEllipsisVertical size="small" />
            </StyledActionsButton>
          }
          items={actions}
        />
      )}
    </StyledLi>
  );
};
