import { Box, Text } from "@boligportal/juice";
import { format } from "date-fns";
import {
  StyledTimeline,
  StyledTimelineItem,
  StyledTimelineItemContent,
  StyledTimelineItemHead,
  StyledTimelineItemTail,
} from "../ad_detail_page/components/activity_stream";
import { EmailStatusTooltip } from "./EmailStatusTooltip";
import { EmailEvent } from "./types";

type Props = {
  events: EmailEvent[];
};

export const EventsTimeline = ({ events }: Props) => (
  <StyledTimeline>
    {events.map((event, index, self) => (
      /* eslint-disable react/no-array-index-key */
      <StyledTimelineItem key={index}>
        {index < self.length - 1 && <StyledTimelineItemTail />}
        <StyledTimelineItemHead />
        <StyledTimelineItemContent>
          <Text size="small">
            {format(new Date(event.date_time), "dd-MM-yyyy HH:mm:ss")}
          </Text>
          <Box ml={1}>
            <EmailStatusTooltip status={event.status} />
          </Box>
        </StyledTimelineItemContent>
      </StyledTimelineItem>
    ))}
  </StyledTimeline>
);
