/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable comma-dangle */
import { useState, useCallback, useEffect } from "react";
import { useDebounce } from "hooks/useDebounce";
import { usePrevious } from "hooks/usePrevious";
import { UserListItem } from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { searchUsersService } from "../services/search_users_service";

export const useSearchUsersForDropdown = () => {
  const [items, setItems] = useState<UserListItem[]>([]);

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 250);
  const previousSearchValue = usePrevious(debouncedSearchValue);

  const search = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    if (shouldSkipRequestAndClearItems()) {
      setItems([]);
      return;
    }

    // eslint-disable-next-line compat/compat
    const abortController = new AbortController();
    const { signal } = abortController;

    searchUsersService(
      {
        query: debouncedSearchValue,
        ban_reasons: null,
        subscription_status: null,
        creation_date_from: null,
        creation_date_to: null,
        login_date_from: null,
        login_date_to: null,
      },
      0,
      100,
      signal,
    )
      .then((response) => {
        setItems(response.results.map((x) => x.user));
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [debouncedSearchValue, previousSearchValue]);

  const shouldSkipRequestAndClearItems = () => {
    const numChars = debouncedSearchValue.length;
    return numChars < 3;
  };

  return {
    search,
    searchValue,
    items,
  };
};
