import { RentalAdViewQueryParamsForAdStateEnum } from "../enums/RentalAdViewQueryParamsForAdStateEnum";
import { RentalAdViewModel } from "../interfaces/RentalAdViewModel";
import { RentalAdsState } from "../interfaces/RentalAdsState";

export const createInitialRentalAdsState = (): RentalAdsState => {
  const defaultRentableAdsState: RentalAdsState = {
    rentalAds: [],

    activeSortableColumn: {
      column: "displayed_advertised_date",
      descending: true,
    },
    searchValue: "",
    limit: 50,
    filterCount: 0,
    start_index: 0,
    ad_state: RentalAdViewQueryParamsForAdStateEnum.ADVERTISED,

    reservedCount: 0,
    advertisedCount: 0,
    allCount: 0,
    rentedOutCount: 0,
    draftCount: 0,

    markAsReservedCandidate: null,

    isInitialLoad: true,
  };

  if (typeof window === "undefined") {
    return defaultRentableAdsState;
  }

  // eslint-disable-next-line compat/compat
  const searchParams = new URLSearchParams(location.search);

  const querySearchTerm = searchParams.get("search_term");
  const querySorting = searchParams.get("sorting");
  const queryDescending = searchParams.get("descending");
  const queryStartIndex = searchParams.get("start_index");
  const queryLimit = searchParams.get("limit");
  const queryAdState = searchParams.get("filter");

  if (querySearchTerm) {
    defaultRentableAdsState.searchValue = querySearchTerm;
  }

  if (querySorting && queryDescending) {
    defaultRentableAdsState.activeSortableColumn = {
      column: querySorting as keyof RentalAdViewModel,
      descending: queryDescending === "true",
    };
  }

  if (queryStartIndex) {
    defaultRentableAdsState.start_index = parseInt(queryStartIndex, 10);
  }

  if (queryLimit) {
    defaultRentableAdsState.limit = parseInt(queryLimit, 10);
  }

  if (queryAdState) {
    defaultRentableAdsState.ad_state =
      queryAdState as RentalAdViewQueryParamsForAdStateEnum;
  }

  return defaultRentableAdsState;
};
