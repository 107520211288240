import { Button } from "@boligportal/juice";
import { AdActionTask } from "../../types";

type Props = {
  adId: number;
  landLordId: number;
  title: string;
  actionConfirmedTask: AdActionTask;
  onTaskSuccess: () => void;
};
const ConfirmableDialogAction = ({
  actionConfirmedTask,
  adId,
  landLordId,
  onTaskSuccess,
  title,
}: Props) => {
  const handleButtonClick = () => {
    const didConfirm = confirm("Are you sure you want to do this?");
    if (didConfirm) {
      actionConfirmedTask(adId, landLordId)
        .then((response) => {
          if (response.success) {
            onTaskSuccess();
          } else {
            alert("ERROR IN TAKEN OVER AD");
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  return <Button onClick={handleButtonClick}>{title}</Button>;
};

export { ConfirmableDialogAction };
