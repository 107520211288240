import * as React from "react";

const SvgSvenska = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={139}
    height={96}
    viewBox="0 0 139 96"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth={1}
    >
      <rect
        width={139}
        height={96}
        x={0}
        y={0}
        fill="#246CDC"
        rx={4.48}
      />
      <path
        fill="#FFD54D"
        d="M41 0h13.45v96H41z"
      />
      <path
        fill="#FFD54D"
        d="M139 41.5v14H0v-14z"
      />
    </g>
  </svg>
);
export default SvgSvenska;
