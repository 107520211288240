import { useHistory } from "react-router-dom";
import { Box, Container, DataTable, Flex, Text } from "@boligportal/juice";
import { format } from "date-fns";
import { useSearchUsersProfileChangesPendingReview } from "features/cs/search_users_feature/hooks/use_search_users_profile_changes_pending_review";
import { UsersListViewModel } from "features/cs/search_users_feature/hooks/use_search_users_with_pagination";
import { useAutomaticScrollToTopOnPagination } from "hooks/useAutomaticScrollToTopOnPagination";
import { UserListItem } from "lib/customerServiceApi";
import { PAGE_USERS } from "../..";
import { ListPaginator } from "../ads_page/list_paginator";
import { SubscriptionStatusBadge } from "../user_detail_page/subscriptions_feed/components/subscription_status_badge";
import { UserCell } from "./cell_renderers/UserCell";

const ProfilesPageApproveUserChanges = () => {
  const history = useHistory();

  const { items, count, offset, limit, setOffset } =
    useSearchUsersProfileChangesPendingReview();

  const scrollContainerRef = useAutomaticScrollToTopOnPagination(offset);

  const handleGotoDetails = (item: UserListItem) => {
    history.push(`${PAGE_USERS}/${item.id}`);
  };

  return (
    <Flex
      column
      width="100%"
    >
      <Flex
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <Flex
            mt={2}
            gap
          ></Flex>
        </Container>
      </Flex>
      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<UsersListViewModel>
            fullscreen
            items={items}
            keyField="id"
            rowActions={[
              {
                label: "Review",
                callback: handleGotoDetails,
              },
            ]}
            columns={[
              {
                fieldLabel: "User",
                fieldName: "username",
                cellRenderer: (item) => (
                  <UserCell
                    id={item.id}
                    fullname={`${item.first_name} ${item.last_name}`}
                    username={item.username}
                    user_ban_reasons={item.user_ban_reasons}
                  />
                ),
              },
              {
                fieldLabel: "Access subscription status",
                fieldName: "id",
                cellRenderer: (item) =>
                  item.seeker_subscription ? (
                    <SubscriptionStatusBadge
                      subscription={item.seeker_subscription}
                    />
                  ) : (
                    "-"
                  ),
              },
              {
                fieldLabel: "Id",
                fieldName: "id",
                cellRenderer: (item) => <Text size="tiny">{item.id}</Text>,
                width: "150px",
              },
              {
                fieldLabel: "Date last login",
                fieldName: "date_last_login",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {item.date_last_login
                      ? format(
                          new Date(item.date_last_login),
                          "yyyy-MM-dd HH:mm:ss",
                        )
                      : "-"}
                  </Text>
                ),
              },
              {
                fieldLabel: "Date profile changed",
                fieldName: "date_profile_changed",

                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(
                      new Date(item.date_profile_changed),
                      "yyyy-MM-dd HH:mm:ss",
                    )}
                  </Text>
                ),
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                itemCount={items.length}
                totalCount={count}
                limit={limit}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { ProfilesPageApproveUserChanges };
