import { Box, Flex, Headline, styled } from "@boligportal/juice";
import { CustomTab } from "apps/rental_ads/components/desktop/RentalAdsAdStateTabsFilter";
import { HorizontalOverflow } from "components/layout/horisontal_overflow_container";
import { FavoriteCardStatus } from "lib/enums";
import { t } from "lib/i18n";

type Props = {
  active: FavoriteCardStatus;
  onTabSelect: (value: FavoriteCardStatus) => void;
};

const StyledContainer = styled(Flex)`
  white-space: nowrap;
`;

export const MyFavouriteAdsHeader = ({ active, onTabSelect }: Props) => {
  return (
    <Box
      pt={{
        xs: 2,
        xl: 0,
      }}
      mb={-2}
    >
      <Headline as="h1">{t("my_favourite_ads.headline")}</Headline>

      <HorizontalOverflow>
        <StyledContainer
          gap={1}
          width="100%"
        >
          <CustomTab
            label={t("ad_card.all")}
            value={FavoriteCardStatus.ALL}
            onSelect={onTabSelect}
            active={active === FavoriteCardStatus.ALL}
          />
          <CustomTab
            label={t("ad_card.available")}
            value={FavoriteCardStatus.AVAILABLE}
            onSelect={onTabSelect}
            active={active === FavoriteCardStatus.AVAILABLE}
          />
          <CustomTab
            label={t("ad_card.contacted")}
            value={FavoriteCardStatus.CONTACTED}
            onSelect={onTabSelect}
            active={active === FavoriteCardStatus.CONTACTED}
          />
          <CustomTab
            label={t("ad_card.unavailable")}
            value={FavoriteCardStatus.UNAVAILABLE}
            onSelect={onTabSelect}
            active={active === FavoriteCardStatus.UNAVAILABLE}
          />
        </StyledContainer>
      </HorizontalOverflow>
    </Box>
  );
};
