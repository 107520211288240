import * as React from "react";

const SvgPaymentMobilePay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={29}
    viewBox="0 0 28 29"
    {...props}
  >
    <g
      fill="#FFF"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    >
      <path d="M13.618 17.119c2.118-.646 3.91-.97 5.375-.974 2.423-.006 4.455.475 6.095 1.443v-7.04c-1.648-.853-3.51-1.309-5.587-1.366a12.399 12.399 0 00-5.883 1.268v6.669z" />
      <path d="M10.176 9.454l2.614 6.222V9.713a12.831 12.831 0 014.375-1.418c1.538-.207 3.287-.174 5.247.1L20.99 5a12.693 12.693 0 00-5.886.996 14.562 14.562 0 00-4.928 3.46z" />
      <path d="M15.348 5.045c-.23-.555-.572-1.388-1.03-2.498a2.281 2.281 0 00-2.975-1.249l-7.106 2.93A2.281 2.281 0 003.006 7.21l6.852 16.625a2.281 2.281 0 002.975 1.25l7.107-2.93a2.281 2.281 0 001.23-2.982l-.849-2.061a28.72 28.72 0 00-.849-.042 23.19 23.19 0 00-.809-.001l1.106 2.682a.76.76 0 01-.41.994l-7.107 2.929a.76.76 0 01-.992-.416L4.408 6.632a.76.76 0 01.41-.994l7.107-2.93a.76.76 0 01.991.417l1.04 2.525c.322-.157.569-.27.741-.34.173-.068.39-.157.65-.265z" />
    </g>
  </svg>
);
export default SvgPaymentMobilePay;
