import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { PaymentFlowCoordinatorProps } from "features/paywall/PaywallProvider";
import { AccountPaymentFlow } from "./AccountPaymentFlow";

export const AccountPaymentFlowCoordinator = ({
  paywallCandidate,
  registeredPaywalls,
  onClose,
}: PaymentFlowCoordinatorProps) => {
  if (
    !paywallCandidate ||
    paywallCandidate.paymentMethodType !== PaymentMethodType.Account
  ) {
    return null;
  }

  const registeredPaywall = registeredPaywalls.find(
    (item) => item.uniquePaywallKey === paywallCandidate.uniquePaywallKey,
  );

  if (!registeredPaywall) {
    throw new Error(
      "Then paywall paywallCandidate's uniquePaywallKey does not match any of the registered paywall uniquePaywallKey",
    );
  }

  const {
    onSubscriptionActivated,
    onPaymentSuccessContinueButtonClick,
    onPaymentFailedRetryButtonClick,
  } = registeredPaywall;

  return (
    <AccountPaymentFlow
      onClose={onClose}
      adId={paywallCandidate.adId}
      plan={paywallCandidate.plan}
      onSubscriptionActivated={onSubscriptionActivated}
      onPaymentSuccessContinueButtonClick={onPaymentSuccessContinueButtonClick}
      onPaymentFailedRetryButtonClick={onPaymentFailedRetryButtonClick}
    />
  );
};
