import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CheckField,
  dialogActions,
  DialogContent,
  DialogFooter,
  Flex,
  FlexColumn,
  SelectField,
  styled,
  TextField,
  useNotifications,
} from "@boligportal/juice";
import { emailValidation } from "components/forms/validation";
import { Market } from "components/markets/market_settings";
import { useDebounce } from "hooks/useDebounce";
import { NumberOfRentables } from "lib/enums";
import { isBoligPortal } from "lib/utils";
import { UserDetailPageContext } from "../../user_detail_page_context";
import { CompanyDetailsContext } from "../company_details_context";
import { CompanyDraft } from "./company_details";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.colorPalette.blue[400]} !important;
  font-size: ${(props) => props.theme.fontSize.tiny};
`;

const CreateNewCompany = () => {
  const { createCompany } = useContext(CompanyDetailsContext);
  const { user } = useContext(UserDetailPageContext);
  const actions = useContext(dialogActions);
  const { register, handleSubmit, formState, getValues, watch, setValue } =
    useForm<CompanyDraft>({});

  const { errors } = formState;
  const { addNotification } = useNotifications();

  const onSubmit = async (values) => {
    const createCompanyObj: CompanyDraft = {
      ...values,

      user_id: user?.id,
    };

    try {
      const response = await createCompany(createCompanyObj);
      if (!response.errors) {
        if (addNotification) {
          addNotification({
            title: "Company created",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
        actions.hide();
      }
    } catch (error) {}
  };

  return (
    <>
      <DialogContent>
        <Box mb={1}>{`Attaching user: ${user?.first_name} -  ${user?.id}`}</Box>
        <form>
          <Flex
            maxWidth="400px"
            column
          >
            <TextField
              {...register("name", {
                required: true,
              })}
              required
              label="Name"
              errorText={errors.name?.message?.toString()}
            />

            <TextField
              {...register("address")}
              type="text"
              label="Address"
            />

            <Flex gap>
              <FlexColumn size={4}>
                <TextField
                  {...register("postal_town")}
                  label="City"
                />
              </FlexColumn>
              <FlexColumn size={4}>
                <TextField
                  {...register("postal_code")}
                  label="Zip code"
                />
              </FlexColumn>
              <FlexColumn size={4}>
                <TextField
                  {...register("country")}
                  type="text"
                  label="Country"
                />
              </FlexColumn>
            </Flex>

            <Flex gap>
              <FlexColumn size={6}>
                <TextField
                  {...register("phone_number")}
                  type="text"
                  label="Company phone number"
                  errorText={errors.phone_number && "Phone number is not valid"}
                />
              </FlexColumn>
              <FlexColumn size={6}>
                <TextField
                  {...register("contact_email", {
                    validate: (value: string) =>
                      value === "" || emailValidation(value),
                  })}
                  type="text"
                  label="Contact email"
                  errorText={errors.contact_email && "Email is not valid"}
                />
              </FlexColumn>
            </Flex>

            <Flex gap>
              <FlexColumn size={6}>
                <SelectField
                  {...register("number_of_rentables")}
                  label="Number of rentables"
                  items={Object.keys(NumberOfRentables).map((key) => ({
                    value: NumberOfRentables[key],
                    label: NumberOfRentables[key],
                  }))}
                />
              </FlexColumn>
              <FlexColumn size={6}>
                <Box mb={-3}>
                  <TextField
                    {...register("cvr")}
                    label="CVR"
                  />
                </Box>
              </FlexColumn>
            </Flex>

            <TextField
              {...register("att")}
              type="text"
              label="Att."
            />

            <Flex column>
              {Market.BOLIGPORTAL_DK === Market.BOLIGPORTAL_DK && (
                <>
                  <CheckField
                    {...register("danske_udlejere")}
                    inline
                    label="Danske Udlejere"
                  />
                  <CheckField
                    {...register("social_housing")}
                    inline
                    label="Almen bolig"
                  />
                </>
              )}

              <CheckField
                {...register("verified")}
                inline
                label="Internally verified"
              />

              <CheckField
                {...register("interactive_floor_plan_allowed")}
                inline
                label="Enable 3D floorplan section"
              />
            </Flex>
          </Flex>
        </form>
      </DialogContent>

      <DialogFooter>
        <Flex gap>
          <Button
            variant="default"
            onClick={() => {
              actions.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Create Company
          </Button>
        </Flex>
      </DialogFooter>
    </>
  );
};
export { CreateNewCompany };
