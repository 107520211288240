import { IconAlertCircle, Notice } from "@boligportal/juice";
import { useLocalStorageState } from "hooks/useLocalStorageState";
import { t } from "lib/i18n";
import { SignedLead } from "../LeadsSections";

type Props = {
  leadsSigned: SignedLead[] | undefined;
};

export const ElectricityNotice = ({ leadsSigned }: Props) => {
  const [hasBeenDismissed, setHasBeenDismissed] = useLocalStorageState(
    "TENANT_HUB_lead_el_section",
    false,
  );

  const hasLeadsSignedEl = leadsSigned?.some(
    (lead) => lead.section === "ELECTRICITY",
  );

  if (hasLeadsSignedEl || hasBeenDismissed) {
    return null;
  }

  return (
    <Notice
      type="info"
      icon={<IconAlertCircle size="medium" />}
      onDismissClick={() => setHasBeenDismissed(true)}
    >
      {t("tenant_hub.leads.notice.electricity")}
    </Notice>
  );
};
