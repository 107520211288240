import * as React from "react";
import { TextField } from "@boligportal/juice";
import { useDebounce } from "hooks/useDebounce";

interface DebouncedTextFieldProps {
  value?: string;
  onDebouncedValueChange: (value: string) => void;
  placeholder?: string;
  debounceDelay?: number;
  prependComponent?: React.ReactNode;
}

export const DebouncedTextField = ({
  placeholder,
  value,
  onDebouncedValueChange,
  debounceDelay,
  prependComponent,
}: DebouncedTextFieldProps) => {
  const [textfieldValue, setTextfieldValue] = React.useState(value ?? "");
  const debouncedTextFieldValue = useDebounce(
    textfieldValue,
    debounceDelay ?? 250,
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextfieldValue(event.target.value);
  };

  const clearClickedHandler = () => {
    setTextfieldValue("");
  };

  React.useEffect(() => {
    onDebouncedValueChange(debouncedTextFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTextFieldValue]);

  return (
    <TextField
      type="search"
      prepend={prependComponent}
      onClearClicked={clearClickedHandler}
      value={textfieldValue}
      onChange={changeHandler}
      placeholder={placeholder}
    />
  );
};
