import { useState, useCallback, useEffect } from "react";
import { fetchFromAPI } from "lib/api";

interface TokenSpec {
  token: string;
  token_expiration: string;
}

async function fetchTokenSpec(url: string): Promise<TokenSpec> {
  return fetchFromAPI(url, {
    method: "POST",
  }).then((response) => response.json());
}

const REFRESH_TOKEN_BEFORE_EXPIRATION_MILLIS = 10 * 60 * 1000; // 10 minutes.
const TOKEN_EXPIRATION_CHECK_INTERVAL_MILLIS = 30 * 1000; // 30 seconds.

// TODO(Jens): go over this again and think about hook dependencies.
export const usePeriodicallyRefreshingToken = (
  fetchTokenSpecUrl: string,
): string | undefined => {
  const [tokenSpec, setTokenSpec] = useState<TokenSpec | undefined>(undefined);

  const refreshAccessToken = useCallback(async () => {
    const tokenSpec = await fetchTokenSpec(fetchTokenSpecUrl);
    console.log("Setting access token.");
    setTokenSpec(tokenSpec);
  }, [fetchTokenSpecUrl, setTokenSpec]);

  // Get token initially
  useEffect(() => {
    refreshAccessToken();
  }, [refreshAccessToken, fetchTokenSpecUrl]);

  const checkTokenAndRefreshIfNeeded = useCallback(() => {
    if (tokenSpec === undefined) {
      return;
    }

    const tokenExpirationTime = Date.parse(tokenSpec.token_expiration);
    const tokenUpdateDeadlineTime =
      tokenExpirationTime - REFRESH_TOKEN_BEFORE_EXPIRATION_MILLIS;
    const currentTime = Date.now();

    // Update the token if we have passed the update deadline
    if (currentTime >= tokenUpdateDeadlineTime) {
      refreshAccessToken();
    }
  }, [tokenSpec, refreshAccessToken]);

  // Check periodically whether to refresh token and if so, refresh
  useEffect(() => {
    const interval = setInterval(
      checkTokenAndRefreshIfNeeded,
      TOKEN_EXPIRATION_CHECK_INTERVAL_MILLIS,
    );
    return () => {
      clearInterval(interval);
    };
  }, [tokenSpec, tokenSpec?.token_expiration, checkTokenAndRefreshIfNeeded]);

  return tokenSpec?.token;
};
