import { Button, Text, Flex, styled } from "@boligportal/juice";
import { PaymentMethodType } from "features/payments_feature/enums/PaymentMethodType";
import { t } from "lib/i18n";
import SvgPaymentMobilePay from "media/svg/PaymentMobilePay";

type Props = {
  onClick: (option: PaymentMethodType) => void;
  vitestId?: string;
};

export const MobilePayPaymentMethodButton = ({ onClick, vitestId }: Props) => {
  const clickHandler = () => {
    onClick(PaymentMethodType.MobilePay);
  };
  return (
    <StyledMobilePayButton
      onClick={clickHandler}
      data-testid={vitestId}
    >
      <Flex
        align="center"
        justify="center"
        height="40px"
      >
        <SvgPaymentMobilePay />
        <Text
          ml={1}
          color="inverted"
        >
          {t("features.payments.choose_payment_method.pay_with_mobilepay")}
        </Text>
      </Flex>
    </StyledMobilePayButton>
  );
};

const StyledMobilePayButton = styled(Button)`
  background-color: #5a78ff;
  &:hover {
    background-color: #5d74f4;
  }
`;
