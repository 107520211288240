import { useEffect, useReducer } from "react";
import { useAppActions } from "components/Providers/AppActionsProvider";
import { useMarketSettings } from "components/Providers/MarketSettingsProvider";
import { Plan } from "components/interfaces/plan";
import { API } from "lib/api";
import { t } from "lib/i18n";

const SUBSCRIPTION_RETRY_DELAY_WHEN_PENDING_PAYMENT = 5000;
const SUBSCRIPTION_RETRY_MAX_ATTEMPTS = 6;

interface StartSubscriptionModalState {
  submitting: boolean;
  submitted: boolean;
  subscriptionActivated: boolean;
  paymentFailed: boolean;
  invoicePaid: boolean;
}

const initialState = {
  submitting: true,
  submitted: false,
  subscriptionActivated: false,
  paymentFailed: false,
  invoicePaid: false,
};

export const useStartSubscription = (
  paymentMethodId: number,
  plans: Plan[],
  adId: number | null,
  onSubscriptionActivated?: () => void,
) => {
  const {
    paywall: { startSubscriptionTracker },
  } = useMarketSettings();
  const { replaceUser } = useAppActions();

  const [state, setState] = useReducer<
    React.Reducer<
      StartSubscriptionModalState,
      Partial<StartSubscriptionModalState>
    >
  >(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    initialState,
  );

  let subscriptionRetryAttempt = 0;

  const startSubscriptions = async () => {
    try {
      const startSubscriptionResults = await API.startSubscriptions(
        paymentMethodId,
        plans,
        adId,
      );
      const startSubscriptionResult = startSubscriptionResults[0];

      if (startSubscriptionResult.pending_payment) {
        if (subscriptionRetryAttempt >= SUBSCRIPTION_RETRY_MAX_ATTEMPTS) {
          throw new Error(
            "Exceed max retry attempts for starting subscription",
          );
        }
        setTimeout(() => {
          subscriptionRetryAttempt += 1;
          startSubscriptions();
        }, SUBSCRIPTION_RETRY_DELAY_WHEN_PENDING_PAYMENT);
        return;
      }

      if (startSubscriptionResult.subscription_created) {
        startSubscriptionTracker.subscriptionCreated(
          getTitle(),
          plans[0],
          adId,
        );

        const updatedUser = await API.getAuthUser();
        replaceUser(updatedUser);

        setState({
          subscriptionActivated: true,
          submitting: false,
          submitted: true,
          invoicePaid: startSubscriptionResult.invoice_paid,
          paymentFailed: false,
        });

        if (onSubscriptionActivated) {
          onSubscriptionActivated();
        }
      } else {
        startSubscriptionTracker.subscriptionFailed(getTitle());

        setState({
          submitting: false,
          submitted: true,
          subscriptionActivated: false,
          paymentFailed: true,
        });
      }
    } catch (error) {
      startSubscriptionTracker.subscriptionFailed(getTitle());

      setState({
        submitting: false,
        submitted: false,
        subscriptionActivated: false,
        paymentFailed: true,
      });
    }
  };

  const getTitle = () => {
    if (state.submitting) {
      return t("payment_modal.title.processing");
    } else if (state.submitted) {
      if (state.invoicePaid) {
        return t("payment_modal.title.success");
      } else {
        return t("payment_modal.title.success.payment_info_registered");
      }
    }
    return t("payment_modal.title");
  };

  useEffect(() => {
    startSubscriptionTracker.subscriptionProcessing(getTitle());
    startSubscriptions();
  }, []);

  return {
    ...state,
    getTitle,
  };
};
