import * as React from "react";

const SvgX16Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="Icon"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.414 8l4.243 4.243a1 1 0 01-1.414 1.414L8 9.414l-4.243 4.243a1 1 0 01-1.414-1.414L6.586 8 2.343 3.757a1 1 0 111.414-1.414L8 6.586l4.243-4.243a1 1 0 111.414 1.414L9.414 8z"
    />
  </svg>
);
export default SvgX16Px;
