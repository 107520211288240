/* eslint-disable react/display-name */

/* eslint-disable compat/compat */
import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  DataTable,
  Flex,
  Labelled,
  Text,
  TextField,
} from "@boligportal/juice";
import { DropdownMultiple } from "apps/customer_service/components/dropdown/DropdownMultiple";
import { App } from "components/app";
import { Option } from "components/interfaces/option";
import { modalActions } from "components/modal_controller";
import { format } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { usePrevious } from "hooks/usePrevious";
import {
  getPaymentsFeed,
  getPlans,
  getUser,
  getUserSubscriptions,
  PlanForCS,
} from "lib/customerServiceApi";
import { captureExceptionIfNotAbortError } from "lib/tracking/errors";
import { formatCurrency } from "lib/utils";
import { PaymentMethodType } from "../../../../features/payments_feature/enums/PaymentMethodType";
import { ListPaginator } from "../ads_page/list_paginator";
import { RefundPaymentsSidebar } from "../user_detail_page/subscriptions_feed/components/refund_payments_sidebar";
import {
  UserCell,
  UserCellRendererProps,
} from "../users_page/cell_renderers/UserCell";
import { createDataproviderForPaymentsFeed } from "./payments_helpers";

interface IProductCell {
  priceFormatted: string;
  description: string;
  productName: string;
  isFreeProduct: boolean;
}

export interface IPaymentsTableItem {
  paymentId: number;
  user: UserCellRendererProps;
  product: IProductCell;
  status: string;
  orderId: string;
  paymentMethod: string;
  paymentMethodType: PaymentMethodType;
  subscriptionId: string;
  createdDate: string;
}

interface IPaymentsFilterState {
  username: string;
  subscriptionId: number | undefined | null;
  orderId: string;
  paymentMethod: string;
  planIds: number[];
  paymentStatuses: string[];
  paymentMethodTypes: PaymentMethodType[];
  hasRefund: boolean | undefined;
  dateFrom: string | null;
  dateTo: string | null;
}

const ProductCell = ({
  priceFormatted,
  description,
  productName,
  isFreeProduct,
}: IProductCell) => (
  <>
    <Text
      block
      size="tiny"
      weight="bold"
    >
      {priceFormatted}
    </Text>
    <Text
      block
      size="tiny"
    >
      {productName}
    </Text>
    <Text
      block
      size="tiny"
    >
      {isFreeProduct ? "Custom" : description}
    </Text>
  </>
);

const handleSeeReceiptClick = (item: IPaymentsTableItem) => {
  const currentUrl = new URL(window.location.href);
  const base = new URL("/", currentUrl);

  const urlToOpen = `${base.toString()}api/payments/receipts/${item.orderId}`;
  window.open(urlToOpen, "_blank");
};

const PaymentsPage = () => {
  const { items, setItems, count, setCount, limit, offset, setOffset } =
    usePagination<IPaymentsTableItem>();
  const location = useLocation();
  // Pull initial state from url params
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const userId = searchParams.get("uid") || "";
  const subscriptionId = searchParams.get("sid");

  const modal = React.useContext(modalActions);
  const closeHandler = () => modal.closeModal();

  const [productOptions, setProductOptions] = React.useState<Option[]>([]);
  //const [paymentItems, setPaymentItems] = React.useState<IPaymentsTableItem[]>([]);
  const [filters, setFilters] = React.useState<IPaymentsFilterState>({
    username: userId,
    subscriptionId: subscriptionId ? Number(subscriptionId) : null,
    orderId: "",
    paymentMethod: "",
    planIds: [],
    paymentStatuses: [],
    paymentMethodTypes: [],
    dateFrom: null,
    dateTo: null,
    hasRefund: undefined,
  });
  const prevFilters = usePrevious(filters);

  React.useEffect(() => {
    getPlans()
      .then((response) => {
        const formatPlan = (plan: PlanForCS): string =>
          `${plan.duration_days}d / ${formatCurrency(
            plan.price,
            App.settings.currency,
            {
              round: true,
            },
          )}`;
        setProductOptions(
          response.results.map((result) => {
            const badges: string[] = [];
            if (result.next_plan_id === null) {
              badges.push("📦");
            } else if (result.next_plan_id === result.id) {
              badges.push("🔄");
            }
            if (result.is_intro) {
              badges.push("🥇");
            }
            if (result.is_winback) {
              badges.push("🔙");
            }
            if (result.num_active_subscriptions > 0) {
              badges.push(`(💸 ×️ ${result.num_active_subscriptions})`);
            }
            const name = `${result.name_on_invoice} - ${formatPlan(
              result,
            )} ${badges.join(" ")}`;
            return {
              id: result.id,
              name: name,
            };
          }),
        );
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (prevFilters !== filters && offset !== 0) {
      // Clear offset on filters changed
      setOffset(0);
      return;
    }
    if (scrollContainerRef.current) {
      // Scroll to top
      scrollContainerRef.current.scrollTop = 0;
    }

    const abortController = new AbortController();
    const { signal } = abortController;
    getPaymentsFeed({
      signal,
      limit,
      offset,
      filters,
    })
      .then((response) => {
        const items: IPaymentsTableItem[] = createDataproviderForPaymentsFeed(
          response.results,
        );
        setItems(items);
        setCount(response.count);
      })
      .catch(captureExceptionIfNotAbortError);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [filters, offset, limit]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const { type } = event.target;
    let value: string | null = event.target.value; //eslint-disable-line

    // Backend expect null for "empty date values"
    if (type === "date" && value === "") {
      value = null;
    }

    const newFilterState: IPaymentsFilterState = {
      ...filters,
      [field]: value,
    };

    setFilters(newFilterState);
  };

  const handleStatusSelectionChange = (value: Option[]) => {
    const rawPaymentStatuses = value.map((option) => option.id.toString());
    const paymentStatuses = rawPaymentStatuses.filter(
      (option) => option !== "REFUNDED",
    );
    const refundStatusPresent = rawPaymentStatuses.indexOf("REFUNDED") >= 0;
    // In the backend, REFUNDED is not a payment status, but a flag (hasRefund)
    const hasRefund = value.length > 0 ? refundStatusPresent : undefined;
    setFilters({
      ...filters,
      paymentStatuses,
      hasRefund,
    });
  };

  const handlePaymentMethodTypeSelectionChange = (value: Option[]) => {
    const paymentMethodTypes = value.map(
      (option) => option.id as PaymentMethodType,
    );
    setFilters({
      ...filters,
      paymentMethodTypes,
    });
  };

  const handleProductSelectionChange = (value: Option[]) => {
    const planIds = value.map((option) => Number(option.id));
    setFilters({
      ...filters,
      planIds,
    });
  };

  const refundButtonDisableFunction = (item: IPaymentsTableItem) =>
    item.product.isFreeProduct ||
    [PaymentMethodType.Android, PaymentMethodType.IOS].includes(
      item.paymentMethodType,
    );

  const getPaymntMethodName = (paymentMethodType: PaymentMethodType) => {
    const names = new Map<PaymentMethodType, string>([
      [PaymentMethodType.IOS, "Apple IAP"],
      [PaymentMethodType.Android, "Google IAP"],
      [PaymentMethodType.QuickPay, "QuickPay"],
      [PaymentMethodType.MobilePay, "MobilePay"],
      [PaymentMethodType.Free, "Free"],
      [PaymentMethodType.Account, "Account"],
      [PaymentMethodType.Invoice, "Invoice"],
    ]);
    return names.get(paymentMethodType) ?? paymentMethodType;
  };

  return (
    <Flex
      column
      width="100%"
    >
      <Flex
        borderBottom
        bg="tint"
      >
        <Container fluid>
          <Flex
            gap
            align="end"
            mt={2}
          >
            <TextField
              label="User filter"
              value={filters.username}
              id="username"
              onChange={handleFilterChange}
            />
            <Flex column>
              <Labelled label="Products">
                <DropdownMultiple
                  title="All Products"
                  multiple
                  options={productOptions}
                  onSelectionChange={handleProductSelectionChange}
                />
              </Labelled>
            </Flex>
            <Flex column>
              <Labelled label="Statuses">
                <DropdownMultiple
                  title="All Statuses"
                  multiple
                  options={[
                    {
                      id: "ACCEPTED",
                      name: "Accepted",
                    },
                    {
                      id: "REJECTED",
                      name: "Rejected",
                    },
                    {
                      id: "REFUNDED",
                      name: "Refunded",
                    },
                  ]}
                  onSelectionChange={handleStatusSelectionChange}
                />
              </Labelled>
            </Flex>
            <TextField
              label="Order ID"
              value={filters.orderId}
              id="orderId"
              onChange={handleFilterChange}
            />
            <Flex column>
              <Labelled label="Payment Method Type">
                <DropdownMultiple
                  title="All payment method types"
                  multiple
                  options={[
                    {
                      id: PaymentMethodType.Android,
                      name: getPaymntMethodName(PaymentMethodType.Android),
                    },
                    {
                      id: PaymentMethodType.IOS,
                      name: getPaymntMethodName(PaymentMethodType.IOS),
                    },
                    {
                      id: PaymentMethodType.QuickPay,
                      name: getPaymntMethodName(PaymentMethodType.QuickPay),
                    },
                    {
                      id: PaymentMethodType.Account,
                      name: getPaymntMethodName(PaymentMethodType.Account),
                    },
                    {
                      id: PaymentMethodType.Free,
                      name: getPaymntMethodName(PaymentMethodType.Free),
                    },
                    {
                      id: PaymentMethodType.Invoice,
                      name: getPaymntMethodName(PaymentMethodType.Invoice),
                    },
                  ]}
                  onSelectionChange={handlePaymentMethodTypeSelectionChange}
                />
              </Labelled>
            </Flex>
            <TextField
              label="Payment Method"
              value={filters.paymentMethod}
              id="paymentMethod"
              onChange={handleFilterChange}
            />

            <TextField
              type="number"
              label="Subscription ID"
              value={`${filters.subscriptionId || ""}`}
              id="subscriptionId"
              onChange={handleFilterChange}
            />

            <TextField
              label="Date From"
              type="date"
              value={filters.dateFrom || ""}
              id="dateFrom"
              onChange={handleFilterChange}
            />
            <TextField
              label="Date To"
              type="date"
              value={filters.dateTo || ""}
              id="dateTo"
              onChange={handleFilterChange}
            />
          </Flex>
        </Container>
      </Flex>
      <Box
        scrollable
        ref={scrollContainerRef}
      >
        <Flex column>
          <DataTable<IPaymentsTableItem>
            fullscreen
            items={items}
            keyField="paymentId"
            columns={[
              {
                fieldName: "user",
                fieldLabel: "User",
                cellRenderer: (data) => <UserCell {...data.user} />,
              },
              {
                fieldName: "product",
                fieldLabel: "Product",
                cellRenderer: (item) => <ProductCell {...item.product} />,
                width: "140px",
              },
              {
                fieldName: "status",
                fieldLabel: "Status",
                width: "150px",
                cellRenderer: (item) => <Text size="tiny">{item.status}</Text>,
              },
              {
                fieldName: "orderId",
                fieldLabel: "Order ID",
                cellRenderer: (item) => <Text size="tiny">{item.orderId}</Text>,
              },
              {
                fieldName: "paymentMethod",
                fieldLabel: "Payment method",
                width: "170px",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {item.paymentMethod}
                    <br />
                    <i>{getPaymntMethodName(item.paymentMethodType)}</i>
                  </Text>
                ),
              },
              {
                fieldName: "subscriptionId",
                fieldLabel: "Subscription ID",
                width: "120px",
                cellRenderer: (item) => (
                  <Text size="tiny">{item.subscriptionId}</Text>
                ),
              },
              {
                fieldName: "createdDate",
                fieldLabel: "Date",
                width: "150px",
                cellRenderer: (item) => (
                  <Text size="tiny">
                    {format(new Date(item.createdDate), "yyyy-MM-dd HH:mm:ss")}
                  </Text>
                ),
              },
            ]}
            rowActions={[
              {
                label: "Refund",
                disableFunction: refundButtonDisableFunction,
                callback: async (data) => {
                  const userId = data.user.id;
                  if (userId === null) {
                    alert(
                      "User is deleted, please ask a developer to help with refund",
                    );
                    return;
                  }
                  const loadSubscriptions = async (id) => {
                    const response = await getUserSubscriptions(id);
                    return response.results.find(
                      (res) =>
                        res.subscription.id === Number(data.subscriptionId),
                    )!;
                  };

                  const fetchUser = async (id) => {
                    const response = await getUser(id);
                    return response.user;
                  };

                  const [refundData, refundCandidate] = await Promise.all([
                    loadSubscriptions(userId),
                    fetchUser(userId),
                  ]);
                  modal.showModal(
                    <RefundPaymentsSidebar
                      subscriptionPaymentsItem={refundData}
                      refundCandidate={refundCandidate}
                      onCancelClick={closeHandler}
                    />,
                  );
                },
              },
              {
                label: "See receipt",
                callback: handleSeeReceiptClick,
                disableFunction: (item) =>
                  item.status === "PENDING" || item.status === "REJECTED",
              },
            ]}
          />
          {count > items.length && (
            <Flex
              column
              pt={4}
              pb={6}
              align="center"
              bg="tint"
            >
              <ListPaginator
                offset={offset}
                totalCount={count}
                limit={limit}
                itemCount={items.length}
                onNextPage={() => setOffset(offset + limit)}
                onPrevPage={() => setOffset(offset - limit)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export { PaymentsPage };
