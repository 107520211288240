import { useState } from "react";
import { useUser } from "components/Providers/UserProvider";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Plan } from "components/interfaces/plan";
import { isNotEligible, isPopularPlan } from "features/upsell/helpers";
import { tn } from "lib/i18n";
import { getBenefitsAfterCreateAdFlow } from "lib/product";
import { DisabledUpsellCard } from "./DisabledUpsellCard";
import { UpsellCard } from "./UpsellCard";

type LandlordUpsellContainerProps = {
  plans: Plan[];
  activeUpsellSubscriptions: AdSubscription[];
  listingId: number;
  handleOpenModal: (plan: Plan, listingId: number) => void;
  isAdLessThan24HoursOld: boolean;
};

export const LandlordUpsellContainer = ({
  plans,
  activeUpsellSubscriptions,
  listingId,
  handleOpenModal,
  isAdLessThan24HoursOld,
}: LandlordUpsellContainerProps) => {
  const { user } = useUser();
  const { isPartOfACompany } = useUser();
  const [selectedPlan, setSelectedPlan] = useState(0);

  const hasMultiplePlans = plans.length > 1;

  const disableAd = isNotEligible(
    activeUpsellSubscriptions,
    plans[selectedPlan],
  );

  const activeSubsctiption = activeUpsellSubscriptions.find(
    (subscription) => subscription.plan.product.name === plans[0].product.name,
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setSelectedPlan !== undefined) {
      setSelectedPlan(parseInt(e.target.value));
    }
  };
  return !activeSubsctiption ? (
    <UpsellCard
      price={plans[selectedPlan].price}
      showIsPopular={isPopularPlan(user, plans[selectedPlan])}
      onClick={() => handleOpenModal(plans[selectedPlan], listingId)}
      price_text={
        plans[selectedPlan].is_subscription
          ? tn(
              "upsell_products.runningPeriod",
              plans[selectedPlan].duration_days,
            )
          : tn(
              "fixed_upsell_products.runningPeriod",
              plans[selectedPlan].duration_days,
            )
      }
      currency={plans[selectedPlan].currency}
      benefits={getBenefitsAfterCreateAdFlow(
        plans[selectedPlan].product,
        isAdLessThan24HoursOld,
        isPartOfACompany,
      )}
      handleChange={handleChange}
      selectedPlan={selectedPlan}
      plans={plans}
      activeUpsellSubscriptions={activeUpsellSubscriptions}
    />
  ) : (
    <DisabledUpsellCard
      price={activeSubsctiption.plan.price}
      showIsPopular={isPopularPlan(user, plans[selectedPlan])}
      disabled={disableAd}
      onClick={() => handleOpenModal(plans[selectedPlan], listingId)}
      price_text={
        activeSubsctiption.plan.is_subscription
          ? tn(
              "upsell_products.runningPeriod",
              activeSubsctiption.plan.duration_days,
            )
          : tn(
              "fixed_upsell_products.runningPeriod",
              activeSubsctiption.plan.duration_days,
            )
      }
      currency={activeSubsctiption.plan.currency}
      benefits={getBenefitsAfterCreateAdFlow(
        activeSubsctiption.plan.product,
        isAdLessThan24HoursOld,
        isPartOfACompany,
      )}
      hasMultiplePlans={hasMultiplePlans}
      activeUpsellSubscriptions={activeSubsctiption}
    />
  );
};
