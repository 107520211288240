import * as React from "react";
import {
  Box,
  Card,
  CardSection,
  Divider,
  Flex,
  styled,
  Text,
  Link,
  IconInformationCircle,
} from "@boligportal/juice";
import { Link as LinkType } from "components/interfaces/links";
import { t } from "lib/i18n";
import SvgCheckmark from "media/svg/Checkmark";

export const StyledPaymentProviderFooterSection = styled(CardSection)<{
  active: boolean | false;
  pending?: boolean | false;
}>`
  padding: 12px 24px !important;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  background-color: ${(props) =>
    props.active
      ? props.theme.color.context.success[50]
      : props.pending
        ? props.theme.colorPalette.yellow[200]
        : props.theme.colorPalette.gray[40]} !important;
`;

export const StyledPaymentProviderCard = styled(Card)`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    box-shadow: ${(props) => props.theme.shadow.md};
  }
`;

interface Props {
  header: React.ReactNode;
  description: {
    headline: string;
    paragraph: string;
    extra?: React.ReactNode;
    link?: LinkType;
    actions?: React.ReactNode;
  };
  footer?: {
    paymentProviderNotLinkedText: string;
    paymentProviderPendingStatusText?: string;
    button: React.ReactNode;
  };
  shouldDisplayPendingStatus: boolean;
  shouldDisplayPaymentConnectedStatus: boolean;
  children?: React.ReactNode;
}

export const PaymentProviderCard = ({
  header,
  description,
  footer,
  children,
  shouldDisplayPaymentConnectedStatus,
  shouldDisplayPendingStatus,
}: Props) => {
  const { headline, link, paragraph, extra, actions } = description;

  return (
    <Box maxWidth={"600px"}>
      <StyledPaymentProviderCard sectioned>
        <CardSection>
          <Flex
            justify="space-between"
            align="center"
            mb={2}
          >
            {header}
          </Flex>

          <Divider my={3} />

          {headline && (
            <Text
              block
              size="small"
              weight="bold"
            >
              {headline}
            </Text>
          )}

          <Text
            size="small"
            mb={2}
            block
          >
            {paragraph}
          </Text>

          {extra}

          {link && (
            <Link
              href={link.href}
              color="primary"
            >
              {link.text}
            </Link>
          )}

          {actions && (
            <>
              <Divider
                mb={3}
                mt={3}
              />

              {actions}
            </>
          )}
          {children}
        </CardSection>
        {footer && (
          <StyledPaymentProviderFooterSection
            active={shouldDisplayPaymentConnectedStatus}
            pending={shouldDisplayPendingStatus}
          >
            <Flex
              align="center"
              justify={
                shouldDisplayPaymentConnectedStatus ? "start" : "space-between"
              }
              ml={-0.2}
            >
              <Flex gap>
                {shouldDisplayPaymentConnectedStatus ? (
                  <SvgCheckmark />
                ) : (
                  <IconInformationCircle
                    size="large"
                    color="primary"
                  />
                )}
                {shouldDisplayPaymentConnectedStatus ? (
                  <Text
                    color="success"
                    weight="semiBold"
                  >
                    {t("payment_provider.account_connected")}
                  </Text>
                ) : (
                  <Text>
                    {shouldDisplayPendingStatus
                      ? footer.paymentProviderPendingStatusText
                      : footer.paymentProviderNotLinkedText}
                  </Text>
                )}
              </Flex>
              {shouldDisplayPaymentConnectedStatus || shouldDisplayPendingStatus
                ? null
                : footer.button}
            </Flex>
          </StyledPaymentProviderFooterSection>
        )}
      </StyledPaymentProviderCard>
    </Box>
  );
};
