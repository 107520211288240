import * as React from "react";
import { Flex, styled } from "@boligportal/juice";
import { useUser } from "./Providers/UserProvider";
import { SidebarHeader } from "./header_sidebar/SidebarHeader";
import { LandlordSidebar, SeekerSidebar } from "./sidebar-menu";

const StyledLayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
  }
`;

export const FullLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const StyledMain = styled.main`
  background-color: ${(props) => props.theme.color.bg.tint};
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  @media print {
    overflow-y: visible;
    overflow-x: visible;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  header?: React.ComponentProps<typeof SidebarHeader>;
};

export const SidebarLayout = ({ children, className, header }: Props) => {
  const { user } = useUser();

  const renderSidebar = () => {
    if (!user) {
      return null;
    }

    if (user.has_created_rentable || user.has_created_newbuild) {
      return <LandlordSidebar user={user} />;
    }

    return <SeekerSidebar user={user} />;
  };

  return (
    <SidebarLayoutContext.Provider
      value={{
        scrollToTop,
      }}
    >
      <StyledLayoutWrapper>
        {renderSidebar()}
        <StyledMain>
          <SidebarHeader {...header} />
          <Flex
            column
            flexGrow
            className={className}
          >
            {children}
          </Flex>
        </StyledMain>
      </StyledLayoutWrapper>
    </SidebarLayoutContext.Provider>
  );
};

// In our sidebar layout concept we cannot use window.scrollTo
// as the content is wrapped in main that acts as the scrollable
// container.
export const scrollToTop = () => {
  const elements = document.getElementsByTagName("main");
  if (elements.length > 0) {
    elements[0].scrollIntoView();
    elements[0].scrollTo(0, 0);
  }
};

interface SidebarLayoutAPI {
  scrollToTop: () => void;
}

const SidebarLayoutContext = React.createContext<SidebarLayoutAPI>({
  scrollToTop: () => {},
});

export const useSidebarLayout = () => ({
  ...React.useContext(SidebarLayoutContext),
});
