import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

export const RentalTitle = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      testId={"createAdTitle"}
      required
      type="text"
      errorText={errors.title && t("required_field.input")}
      label={t("create.field.title.heading")}
      {...register("title", {
        required: true,
      })}
    />
  );
};
