import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { getMobileOperatingSystem, isTouchDevice } from "./helpers";

const DK_URL =
  "https://apps.apple.com/us/app/flyttesyn-boligportal/id1579677723";
const SE_URL =
  "https://apps.apple.com/us/app/bostadsportal-besiktning/id6448440098";

export const AppStoreBadge = () => {
  const isDK = App.settings.market === Market.BOLIGPORTAL_DK;

  const isIosTouchDevice =
    getMobileOperatingSystem() === "iOS" && isTouchDevice();

  if (!isTouchDevice() || isIosTouchDevice) {
    return (
      <a
        href={isDK ? DK_URL : SE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`/static/images/badges/app-store-badge-${
            isDK ? "dk" : "se"
          }.svg`}
        />
      </a>
    );
  }

  return null;
};
