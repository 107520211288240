import { Button, IconAdd } from "@boligportal/juice";
import { commonButtonTrans } from "lib/translations";

interface Props {
  url?: string;
}

export const CreateRentableDialogTrigger = ({ url }: Props) => {
  const { createNewRentableButton } = commonButtonTrans();

  return (
    <Button
      variant="primary"
      icon={IconAdd}
      href={url}
      testId="goToCreateRentableFormButton"
    >
      {createNewRentableButton}
    </Button>
  );
};
