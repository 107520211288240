import React from "react";
import { BenefitsView } from "apps/benefits/BenefitsLayout";
import { ElectricityPartner } from "leads/electricity/ElectricityPartner";
import { t } from "lib/i18n";
import SvgAndelLogo from "media/svg/AndelLogo";
import SvgAuraLogo from "media/svg/AuraLogo";
import SvgEnergifynLogo from "media/svg/EnergifynLogo";
import SvgNorlysLogo from "media/svg/NorlysLogo";
import SvgOkLogo from "media/svg/OkLogo";
import SvgTenantHubElectricity from "media/svg/TenantHubElectricity";
import SvgTenantHubInternet from "media/svg/TenantHubInternet";
import { ElectricityNotice } from "./components/EletricityNotice";
import { InternetNotice } from "./components/InternetNotice";
import { LeadsPartner } from "./components/LeadsPartner";
import { TenantHubLeadsSection } from "./components/TenantHubLeadsSection";

export enum LeadsSection {
  ELECTRICITY = "ELECTRICITY",
  INTERNET = "INTERNET",
}

export enum InternetPartner {
  NORLYS_INTERNET = "NORLYS_INTERNET",
}

export type AllPartners = ElectricityPartner | InternetPartner;

export interface LeadsPartner {
  section: {
    section: LeadsSection;
    title: string;
    description: string;
    logo: React.ReactNode;
    notice?: React.ReactNode;
  };
  partners: {
    name: AllPartners;
    displayName: string;
    logo: React.ReactNode;
    successText?: string;
  }[];
}

export type SignedLead = {
  source: string;
  section: LeadsSection;
  signedPartner: AllPartners;
};

interface Props {
  handlePartnerClick: (
    section: LeadsSection,
    name: AllPartners,
    isSigned: boolean,
  ) => void;
  signedLeads: SignedLead[];
  benefitsView?: BenefitsView;
  hasNoticeMessage?: boolean;
  isElectricityIncludedInRent?: boolean;
}

export const LeadsSections = ({
  handlePartnerClick,
  signedLeads,
  benefitsView,
  hasNoticeMessage = false,
  isElectricityIncludedInRent,
}: Props) => {
  const data: LeadsPartner[] = [
    {
      section: {
        section: LeadsSection.ELECTRICITY,
        title: t("tenant_hub.leads.electricity"),
        description: t("tenant_hub.leads.electricity.section.description"),
        logo: <SvgTenantHubElectricity width={30} />,
        notice: hasNoticeMessage ? (
          <ElectricityNotice leadsSigned={signedLeads} />
        ) : undefined,
      },
      partners: [
        {
          name: ElectricityPartner.OK,
          displayName: t("tenant_hub.leads.electricity_ok"),
          logo: <SvgOkLogo height={15} />,
        },
        {
          name: ElectricityPartner.ENERGI_FYN,
          displayName: t("tenant_hub.leads.electricity_energi_fyn"),
          logo: <SvgEnergifynLogo height={15} />,
        },
        {
          name: ElectricityPartner.NORLYS,
          displayName: t("tenant_hub.leads.electricity_norlys"),
          logo: <SvgNorlysLogo height={16} />,
        },
        {
          name: ElectricityPartner.ANDEL,
          displayName: t("tenant_hub.leads.electricity_andel"),
          logo: (
            <SvgAndelLogo
              height={16}
              width={90}
            />
          ),
        },
        {
          name: ElectricityPartner.AURA,
          displayName: t("tenant_hub.leads.electricity_aura"),
          logo: (
            <SvgAuraLogo
              height={16}
              width={60}
            />
          ),
        },
      ],
    },
    {
      section: {
        section: LeadsSection.INTERNET,
        title: t("tenant_hub.leads.internet"),
        description: t("tenant_hub.leads.internet.section.description"),
        logo: <SvgTenantHubInternet width={30} />,
        notice: hasNoticeMessage ? (
          <InternetNotice
            leadsSigned={signedLeads}
            isElectricityIncludedInRent={isElectricityIncludedInRent}
          />
        ) : undefined,
      },
      partners: [
        {
          name: InternetPartner.NORLYS_INTERNET,
          displayName: t("tenant_hub.leads.internet_norlys"),
          logo: <SvgNorlysLogo height={16} />,
          successText: t("tenant_hub.leads.internet_norlys_success"),
        },
      ],
    },
  ];

  const filteredData = benefitsView
    ? data.filter((lead) => lead.section.section === benefitsView.valueOf())
    : data.map((lead) => {
        const isElectricitySection =
          lead.section.section === LeadsSection.ELECTRICITY;
        const isNorlysNotSigned = !signedLeads.some(
          (signedLead) =>
            signedLead.signedPartner === ElectricityPartner.NORLYS,
        );

        if (isElectricitySection && isNorlysNotSigned) {
          return {
            ...lead,
            partners: lead.partners.filter(
              (partner) => partner.name !== ElectricityPartner.NORLYS,
            ),
          };
        }

        return lead;
      });

  /*
  1. mapPartnerToSection:
    - map the partner name to a section name
  2. isSectionSigned:
    - check if the section is signed
  3. data.sort:
    - sort the sections based on the isSectionSigned function
    - if the first section is signed and the second section is not signed, the first section is moved to the bottom of the list
    - if the first section is not signed and the second section is signed, the second section is moved to the bottom of the list
    - if both sections are signed or both sections are not signed, the order is not changed
  */

  const mapPartnerToSection = (
    partnerName: ElectricityPartner | InternetPartner,
  ): LeadsSection | null => {
    if (
      Object.values(ElectricityPartner).includes(
        partnerName as ElectricityPartner,
      )
    ) {
      return LeadsSection.ELECTRICITY;
    }
    if (
      Object.values(InternetPartner).includes(partnerName as InternetPartner)
    ) {
      return LeadsSection.INTERNET;
    }
    return null;
  };

  const isSectionSigned = (sectionType: LeadsSection): boolean =>
    signedLeads.some(
      (lead) => mapPartnerToSection(lead.signedPartner) === sectionType,
    );

  filteredData.sort((firstSection, secondSection) => {
    const isFirstSectionSigned = isSectionSigned(firstSection.section.section);
    const isSecondSectionSigned = isSectionSigned(
      secondSection.section.section,
    );

    if (isFirstSectionSigned && !isSecondSectionSigned) {
      return 1;
    }

    if (!isFirstSectionSigned && isSecondSectionSigned) {
      return -1;
    }

    return 0;
  });

  return (
    <div>
      {filteredData
        .filter(
          ({ section }) =>
            !(
              isElectricityIncludedInRent &&
              section.section === LeadsSection.ELECTRICITY
            ),
        )
        .map(
          ({
            section: { section, description, logo, title, notice },
            partners,
          }) => {
            const hasSignedPartnerInSection = signedLeads.some(
              (lead) => lead.section === section,
            );

            return (
              <TenantHubLeadsSection
                key={title}
                leadsTitle={title}
                leadsDescription={description}
                leadsIconSlot={logo}
                leadsNotice={notice}
              >
                {partners.map(({ name, logo, displayName, successText }) => {
                  const isSignedPartner = signedLeads.some(
                    (lead) => lead.signedPartner === name,
                  );

                  return (
                    <LeadsPartner
                      key={name}
                      name={displayName}
                      successText={successText}
                      onClick={() =>
                        handlePartnerClick(section, name, isSignedPartner)
                      }
                      logo={logo}
                      disabled={hasSignedPartnerInSection}
                      isSigned={isSignedPartner}
                      section={section}
                    />
                  );
                })}
              </TenantHubLeadsSection>
            );
          },
        )}
    </div>
  );
};
