import {
  Box,
  Button,
  Flex,
  Text,
  IconAddCircleOutline,
  IconTrashOutline,
  IconMailOpenOutline,
  IconSend,
  styled,
  DropdownMenuButton,
  Dropdown,
  useNotifications,
  IconPause,
  IconCalendarOutline,
  IconPlaySkipForward,
  Badge,
} from "@boligportal/juice";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { formatRelative } from "date-fns";
import { IPAddressCheck } from "features/cs/ip_address_check_feature/IPAddressCheck";
import { getEmailTemplateContent, getPreviewURL } from "lib/customerServiceApi";
import { AdStates } from "lib/enums";
import { AdStateLabel } from "../../../components/ad_state_label";
import { ReviewReason } from "../../ads_page/interfaces";
import { ClaimAdAttempt } from "../ad_detail_page";
import { EmailTemplateItem } from "../interfaces";
import { approveAdTask } from "./toolbar_actions/action_tasks/approve_ad_task";
import { contactLandlordTask } from "./toolbar_actions/action_tasks/contact_landlord_task";
import { moveToFollowUpTask } from "./toolbar_actions/action_tasks/move_to_follow_up_task";
import { rejectAdTask } from "./toolbar_actions/action_tasks/reject_ad_task";
import { reserveAdTask } from "./toolbar_actions/action_tasks/reserve_ad_task";
import { sendUpsellOfferEmailTask } from "./toolbar_actions/action_tasks/send_upsell_offer";
import { skipPublishAdTask } from "./toolbar_actions/action_tasks/skip_publish_ad_task";
import { unSkipPublishAdTask } from "./toolbar_actions/action_tasks/un_skip_publish_ad_task";
import { AddFreePlanButton } from "./toolbar_actions/add_upsell/add_upsell_action";
import { ConfirmableAction } from "./toolbar_actions/confirmable_action";
import { PreviewAdAction } from "./toolbar_actions/preview_ad_action";

const StyledFlexWithChildrenSpacing = styled(Flex)`
  & > * {
    margin-bottom: ${(props) => props.theme.unit(1)};
  }
`;

type Props = {
  adId: number;
  landlordId: number | null;
  adState: AdStates;
  needs_follow_up: boolean;
  review_reason: ReviewReason | null;
  address: string;
  advertisedDate: string;
  createdDate: string;
  latestBumpTime: string | null;
  deletedDate: string | null;
  rentedOutDate: string | null;
  modifiedDate: string;
  claimAdAttempt: ClaimAdAttempt | null;
  emailTemplateItems: EmailTemplateItem[] | null;
  onActionSuccess: (
    autoClaimNext: boolean,
    adState: AdStates,
    needs_follow_up: boolean,
    review_reason: ReviewReason | null,
    adId: number,
  ) => void;
  isCrawledAd: boolean;
  hasOwner: boolean;
  hasContract: boolean;
};

type DropdownItem = {
  id: number;
  name: string;
  key: string;
};
const AdToolbarActions = ({
  adId,
  landlordId,
  adState,
  needs_follow_up,
  review_reason,
  address,
  advertisedDate,
  createdDate,
  latestBumpTime,
  modifiedDate,
  claimAdAttempt,
  emailTemplateItems,
  onActionSuccess,
  isCrawledAd,
  hasOwner,
  hasContract,
  deletedDate,
  rentedOutDate,
}: Props) => {
  const canApprove =
    review_reason ||
    needs_follow_up ||
    adState === AdStates.INACTIVE_REJECTED ||
    adState === AdStates.INACTIVE_PENDING_APPROVAL;
  const canReject = adState !== AdStates.INACTIVE_REJECTED;
  const canFollowUp =
    !needs_follow_up && adState === AdStates.INACTIVE_PENDING_APPROVAL;
  const canReserve = adState === AdStates.ACTIVE_APPROVED;
  const hideConfirmSliderWhenApproving =
    review_reason && adState === AdStates.ACTIVE_APPROVED;
  const canPutOffline = isCrawledAd && adState !== AdStates.INACTIVE_DRAFT;
  const canAddUpsell = hasOwner && adState === AdStates.ACTIVE_APPROVED;

  const handleRejectAdConfirmed = (
    templateId: number,
    emailMessageBody: string,
  ) =>
    rejectAdTask(templateId, adId, emailMessageBody).then((res) => {
      // Im the claimer so get the next in que
      // else refetch data so the view responds to new state
      const autoClaimNext =
        (claimAdAttempt && claimAdAttempt.didAttempt) || false;
      onActionSuccess(
        autoClaimNext,
        adState,
        needs_follow_up,
        review_reason,
        adId,
      );
      return res;
    });

  const handleApproveAdConfirmed = (
    templateId: number,
    emailMessageBody: string,
    shouldCreateContract: boolean,
  ) => {
    // We don't want to start the approval flow if we just approved an already rejected ad
    const autoClaimNext = adState != AdStates.INACTIVE_REJECTED;
    return approveAdTask(
      templateId,
      adId,
      shouldCreateContract,
      emailMessageBody,
    ).then((res) => {
      if (res.success) {
        onActionSuccess(
          autoClaimNext,
          adState,
          needs_follow_up,
          review_reason,
          adId,
        );
      }
      return res;
    });
  };

  const handleApproveAd = () => {
    // We don't want to start the approval flow if we just approved an already rejected ad
    const autoClaimNext = adState != AdStates.INACTIVE_REJECTED;
    return approveAdTask(-1, adId, false, "").then((res) => {
      if (res.success) {
        onActionSuccess(
          autoClaimNext,
          adState,
          needs_follow_up,
          review_reason,
          adId,
        );
      }
      return res;
    });
  };

  const handleMoveAdToFollowUpConfirmed = (
    templateId: number,
    emailMessageBody: string,
  ) =>
    moveToFollowUpTask(templateId, adId, emailMessageBody).then((res) => {
      if (res.success) {
        onActionSuccess(true, adState, needs_follow_up, review_reason, adId);
      }
      return res;
    });

  const handleReserveAd = async () => {
    const res = await reserveAdTask(adId);
    if (res.success) {
      onActionSuccess(false, adState, needs_follow_up, review_reason, adId);
    }
    return res;
  };

  const handleSkipPublishAd = async () => {
    const res = await skipPublishAdTask(adId);
    if (res.success) {
      onActionSuccess(false, adState, needs_follow_up, review_reason, adId);
    }
    return res;
  };

  const handleRePublishAd = async () => {
    const res = await unSkipPublishAdTask(adId);
    if (res.success) {
      onActionSuccess(false, adState, needs_follow_up, review_reason, adId);
    }
    return res;
  };

  const handleContactLandlordConfirmed = (
    templateId: number,
    emailMessageBody: string,
  ) =>
    // no need to refetch data here.
    contactLandlordTask(templateId, adId, emailMessageBody);

  const handleDeleteCrawledAdClick = async () => {
    const res = await approveAdTask(-1, adId, false, "");
    if (res.success) {
      onActionSuccess(false, adState, needs_follow_up, review_reason, adId);
    }
  };

  const handleUpsellAdded = () => {
    onActionSuccess(false, adState, needs_follow_up, review_reason, adId);
  };

  const { addNotification } = useNotifications();

  const offerUpsellViaEmail = (productName) =>
    sendUpsellOfferEmailTask(productName, adId).finally(() => {
      showOfferSent();
    });

  const showOfferSent = () => {
    if (addNotification) {
      addNotification({
        title: "Upsell offer sent",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  };

  const dropdownItems: DropdownItem[] = [
    { id: 1, name: "Top Ad", key: "promote_ad" },
    { id: 2, name: "Open Ad", key: "open_ad" },
    { id: 3, name: "Exposure", key: "exposure_ad" },
    { id: 4, name: "Exposure Plus", key: "exposure_plus_ad" },
  ];

  function filterSwedishMarketDropdownItems(items: DropdownItem[]) {
    if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      return items.filter((item) => item.id !== 4);
    }
    return items;
  }

  const filteredDropdownItems = filterSwedishMarketDropdownItems(dropdownItems);

  return (
    <div
      style={{
        zIndex: 799,
      }}
    >
      <Flex
        p={2}
        shadow="sm"
        bg="base"
        borderBottom
      >
        <Flex
          gap
          flexGrow
        >
          <Flex
            flexGrow
            minWidth="450px"
          >
            <Flex column>
              <Flex>
                <Text weight="bold">{address}</Text>
                <Text pl={1}>#{adId}</Text>
                <Flex
                  inline
                  ml={1}
                  px={1}
                >
                  <AdStateLabel
                    state={adState}
                    claimer={claimAdAttempt ? claimAdAttempt.claimer : null}
                    needsFollowUp={needs_follow_up}
                    reviewReason={review_reason}
                    isDeleted={Boolean(deletedDate)}
                  />
                  {hasContract && (
                    <Text size="tiny">
                      <Box inline>
                        <Badge color="blue">Contract created</Badge>
                      </Box>
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex column>
                <Flex gap={4}>
                  <DateDisplay
                    label="Created:"
                    date={createdDate}
                  />

                  <DateDisplay
                    label="Advertised:"
                    date={advertisedDate}
                  />

                  <DateDisplay
                    label="Rented Out:"
                    date={rentedOutDate}
                  />
                </Flex>

                <DateDisplay
                  label="Last edited:"
                  date={modifiedDate}
                />

                <DateDisplay
                  label="Deleted:"
                  date={deletedDate}
                />

                <DateDisplay
                  label="Last Bumped:"
                  date={latestBumpTime}
                />
              </Flex>
              <IPAddressCheck adId={adId} />
            </Flex>
          </Flex>
          <Box>
            <StyledFlexWithChildrenSpacing
              gap={1}
              wrap
              flexGrow
            >
              <Dropdown
                opener={
                  <Button>
                    <IconSend inline /> Offer upsell
                  </Button>
                }
                items={filteredDropdownItems.map((item) => (
                  <DropdownMenuButton
                    key={item.id}
                    onClick={() => offerUpsellViaEmail(item.key)}
                  >
                    {item.name}
                  </DropdownMenuButton>
                ))}
              />

              {canAddUpsell && landlordId && (
                <AddFreePlanButton
                  adId={adId}
                  userId={landlordId}
                  onSubscriptionSuccess={handleUpsellAdded}
                  type="ad"
                >
                  Add upsell
                </AddFreePlanButton>
              )}
              {emailTemplateItems && (
                <ConfirmableAction
                  templateOptions={emailTemplateItems}
                  emailTemplateLoaderId={adId}
                  emailTemplateLoader={getEmailTemplateContent}
                  emailOptional={false}
                  actionTitle="Contact Landlord"
                  actionCancelLabel="Cancel"
                  actionConfirmLabel="Confirm"
                  actionConfirmedTask={handleContactLandlordConfirmed}
                  icon={IconMailOpenOutline}
                />
              )}
              {!hideConfirmSliderWhenApproving &&
                canApprove &&
                emailTemplateItems && (
                  <ConfirmableAction
                    templateOptions={emailTemplateItems}
                    emailTemplateLoaderId={adId}
                    emailTemplateLoader={getEmailTemplateContent}
                    emailOptional
                    showCreateDraftContract={!hasContract}
                    actionTitle="Approve"
                    actionCancelLabel="Cancel"
                    actionConfirmLabel="Confirm"
                    actionConfirmedTask={handleApproveAdConfirmed}
                    icon={IconAddCircleOutline}
                  />
                )}
              {hideConfirmSliderWhenApproving &&
                canApprove &&
                emailTemplateItems && (
                  <Button
                    onClick={handleApproveAd}
                    icon={IconAddCircleOutline}
                  >
                    Approve
                  </Button>
                )}
              {canReject && emailTemplateItems && (
                <ConfirmableAction
                  templateOptions={emailTemplateItems}
                  emailTemplateLoaderId={adId}
                  emailTemplateLoader={getEmailTemplateContent}
                  emailOptional
                  actionTitle="Reject ad"
                  actionCancelLabel="Cancel"
                  actionConfirmLabel="Confirm"
                  actionConfirmedTask={handleRejectAdConfirmed}
                  icon={IconTrashOutline}
                  buttonVariant="default"
                />
              )}
              {canReserve && (
                <Button
                  onClick={handleReserveAd}
                  icon={IconPause}
                >
                  Reserve Ad
                </Button>
              )}
              {isCrawledAd && adState != AdStates.INACTIVE_DRAFT && (
                <Button
                  onClick={handleSkipPublishAd}
                  icon={IconPlaySkipForward}
                >
                  Skip publish
                </Button>
              )}
              {isCrawledAd && adState == AdStates.INACTIVE_DRAFT && (
                <Button
                  onClick={handleRePublishAd}
                  icon={IconPlaySkipForward}
                >
                  Publish
                </Button>
              )}

              <PreviewAdAction action={() => getPreviewURL(adId)} />
              {canFollowUp && emailTemplateItems && (
                <ConfirmableAction
                  templateOptions={emailTemplateItems}
                  emailTemplateLoaderId={adId}
                  emailTemplateLoader={getEmailTemplateContent}
                  emailOptional
                  actionTitle="Move to follow up"
                  actionCancelLabel="Cancel"
                  actionConfirmLabel="Confirm"
                  actionConfirmedTask={handleMoveAdToFollowUpConfirmed}
                  icon={IconCalendarOutline}
                />
              )}
              {canPutOffline && (
                <Button
                  onClick={handleDeleteCrawledAdClick}
                  icon={IconTrashOutline}
                >
                  Delete Ad
                </Button>
              )}
            </StyledFlexWithChildrenSpacing>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export { AdToolbarActions };

const DateDisplay = ({
  label,
  date,
}: {
  label: string;
  date: string | null;
}) => (
  <Box>
    <Text
      weight="bold"
      size="small"
    >
      {label}
    </Text>
    <Text
      pl={1}
      size="small"
    >
      {date ? `${formatRelative(new Date(date), new Date())}` : "-"}
    </Text>
  </Box>
);
