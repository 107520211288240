import {
  IconArrowBackForward,
  IconDocumentTextOutline,
  IconHome,
} from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { Flow } from "../useLandlordOnboardingFeature";

export const getTitleAndDescription = (flow: Flow) => {
  let title = "";
  let description = "";

  switch (flow) {
    case "ad":
      title = t("landlord_onboarding.landlord_dialog.ad_flow.title");
      description = t(
        "landlord_onboarding.landlord_dialog.ad_flow.description",
      );
      break;
    case "contract":
      title = t("landlord_onboarding.landlord_dialog.contract_flow.title");
      description = t(
        "landlord_onboarding.landlord_dialog.contract_flow.description",
      );
      break;
    case "moving-report":
      title = t("landlord_onboarding.landlord_dialog.moving_report_flow.title");
      description = t(
        "landlord_onboarding.landlord_dialog.moving_report_flow.description",
      );
      break;
  }

  return {
    title,
    description,
  };
};

export const item1Defaults = () => ({
  icon: IconHome,
  href: "/property-owner/create-ad",
  title: t("landlord_onboarding.landlord_dialog.item1.title"),
  text: t("landlord_onboarding.landlord_dialog.item1.text"),
});
export const item2Defaults = () => ({
  icon: IconDocumentTextOutline,
  href: "/contracts/choose-rentable",
  title: t("landlord_onboarding.landlord_dialog.item2.title"),
  text: t("landlord_onboarding.landlord_dialog.item2.text"),
});
export const item3Defaults = () => ({
  icon: IconArrowBackForward,
  href: "/moving-reports/choose-rentable",
  title: t("landlord_onboarding.landlord_dialog.item3.title"),
  text: t("landlord_onboarding.landlord_dialog.item3.text"),
});

export const createItemsForAdFlow = (flow: Flow, onClose: () => void) => [
  {
    ...item1Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("ad", flow);
      onClose();
    },
  },
  {
    ...item2Defaults(),
    ctaButtonLabel: t("landlord_onboarding.landlord_dialog.item2.button.label"),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("contract", flow);
      onClose();
    },
  },
  {
    ...item3Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("moving-report", flow);
      onClose();
    },
  },
];

export const createItemsForContractFlow = (flow: Flow, onClose: () => void) => [
  {
    ...item1Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("ad", flow);
      onClose();
    },
  },
  {
    ...item2Defaults(),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("contract", flow);
      onClose();
    },
  },
  {
    ...item3Defaults(),
    ctaButtonLabel: t("landlord_onboarding.landlord_dialog.item2.button.label"),
    onTrigger: () => {
      TrackingEvent.landlordOnboardingTrackClickEvent("moving-report", flow);
      onClose();
    },
  },
];
