import { useEffect, useState } from "react";
import { Plan } from "components/interfaces/plan";
import { calculateTotalPriceFromPlans } from "features/payments_feature/utils/calculateTotalPriceFromPlans";
import { fetchFromAPI } from "lib/api";
import { constructReturnUrl } from "./helpers";

type Options = {
  plans: Plan[];
  onBeforeRedirect?: () => void;
  adId: number | null;
  rentableId: number | null;
  uniquePaywallKey?: string;
};

export const useMobilePay = ({
  adId,
  plans,
  onBeforeRedirect,
  rentableId,
  uniquePaywallKey,
}: Options) => {
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPaymentLink = async () => {
      try {
        const amount = calculateTotalPriceFromPlans(plans);
        const planIds = plans.map((plan) => plan.plan_id);
        const returnUrl = constructReturnUrl({
          adId,
          rentableId,
          uniquePaywallKey,
          document,
        });

        const response = await fetchFromAPI(
          "/api/payments/mobilepay_payment_link",
          {
            method: "POST",
            body: JSON.stringify({
              amount,
              plan_ids: planIds,
              return_url: returnUrl,
              ad_id: adId,
            }),
          },
        );

        const data = await response.json();

        if (response.ok && data.url) {
          setPaymentLink(data.url);
        } else {
          setError("Failed to fetch payment link");
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    };

    fetchPaymentLink();
  }, [adId, plans, rentableId]);

  useEffect(() => {
    if (paymentLink) {
      onBeforeRedirect?.();
      window.location.href = paymentLink;
    }
  }, [paymentLink, onBeforeRedirect]);

  return {
    error,
  };
};
