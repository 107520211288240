import * as React from "react";
import { MutableRefObject, useEffect, useRef } from "react";
import {
  createMap,
  MAP_STYLE_URL,
  metersToPixelsAtMaxZoom,
} from "components/maps/mapHelpers";
import { Map } from "mapbox-gl";
import * as mapboxgl from "mapbox-gl";
import { LatLngLocation } from "./interfaces/location";

export const MapWithPin = ({
  location,
  initialZoom = 14,
  className = "",
  maxZoom = 16,
  minZoom = 6,
}: {
  location: LatLngLocation;
  initialZoom: number;
  className?: string;
  minZoom?: number;
  maxZoom?: number;
}) => {
  const mapElem = React.useRef<HTMLDivElement>(null);
  const mapRef: MutableRefObject<Map | undefined> = useRef();
  let map = mapRef.current;

  const updateMap = (center: [number, number]) => {
    if (!map) {
      return;
    }

    // Dont plot marker if data is bad.
    if (!center[0] || !center[1]) {
      return;
    }

    map.setCenter(center);
  };

  const handleMapClick = () => {
    if (!map) {
      return;
    }

    map.off("click", handleMapClick);
    map.touchZoomRotate.enable();
    map.dragPan.enable();
    map.scrollZoom.enable();
    map.doubleClickZoom.enable();
  };

  useEffect(() => {
    if (!mapElem.current) {
      return;
    }
    mapRef.current = createMap(
      mapElem.current,
      MAP_STYLE_URL,
      initialZoom,
      minZoom,
      maxZoom,
      location,
      false,
    );
    map = mapRef.current;

    // disable map rotation using right click + drag
    map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    // Map needs to be loaded before we can use it
    map.on("load", () => {
      if (!map) {
        return;
      }
      map.addControl(new mapboxgl.AttributionControl(), "bottom-left");
      map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
      );
      updateMap([location.lng, location.lat]);
      map.on("click", handleMapClick);

      map.addSource("locationCircleSource", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
            },
          ],
        },
      });

      map.addLayer({
        id: "locationCircleId",
        type: "circle",
        source: "locationCircleSource",
        paint: {
          "circle-radius": {
            stops: [
              [0, 0],
              [20, metersToPixelsAtMaxZoom(200, location.lat)],
            ],
            base: 2,
          },
          "circle-color": "#000000",
          "circle-stroke-width": 1,
          "circle-stroke-color": "#000000",
          "circle-stroke-opacity": 0.15,
          "circle-opacity": 0.075,
        },
      });
    });

    // Cleanup when unmounted
    return () => {
      if (!map) {
        return;
      }
      map.remove();
    };
  }, []);

  useEffect(() => {
    updateMap([location.lng, location.lat]);
  }, [location.lng, location.lat]);

  return (
    <div
      className={className}
      ref={mapElem}
    />
  );
};
