import * as React from "react";
import {
  Box,
  Button,
  CheckField,
  Flex,
  Text,
  TextField,
} from "@boligportal/juice";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogSidebar,
} from "components/dialog/dialog";
import { Modal } from "components/modal";
import * as CustomerServiceApi from "lib/customerServiceApi";
import SvgX16px from "media/icons/X16px";
import { UserForCustomerService } from "../../user_detail_view/user_detail_view";
import { RefundReasonsEnum, RefundStepsEnum } from "../enums";
import {
  RefundPayloadInterface,
  SelectedPaymentItemInterface,
  SubscriptionPaymentsItemInterface,
} from "../interfaces";
import { getAmountSum, getAmountSumStr } from "../refund_helpers";
import { RefundConfirmation } from "./refund_confirmation";
import { RefundInfo } from "./refund_info";
import { RefundPaymentsList } from "./refund_payments_list";
import { RefundReasons } from "./refund_reasons";
import { SubscriptionStatusBlock } from "./subscription_status_block";

type Props = {
  refundCandidate: UserForCustomerService;
  subscriptionPaymentsItem: SubscriptionPaymentsItemInterface;
  onCancelClick: () => void;
};

const RefundPaymentsSidebar = ({
  refundCandidate,
  subscriptionPaymentsItem,
  onCancelClick,
}: Props) => {
  const [pagesIndex, setPageIndex] = React.useState<RefundStepsEnum>(
    RefundStepsEnum.FillInForm,
  );
  const [refundInProgress, setRefundInProgress] = React.useState(false);
  const [terminateChecked, setTerminateChecked] = React.useState(true);
  const [zendeskTicketId, setZendeskTicketId] = React.useState("");
  const [selectedReason, setSelectedReason] = React.useState<
    RefundReasonsEnum | undefined
  >(undefined);
  const [refundReasonNote, setRefundReasonNote] = React.useState("");
  /**
   * Contains alle the items where items are checked
   */
  const [selectedPayments, setSelectedPayments] = React.useState<
    SelectedPaymentItemInterface[]
  >([]);

  // ==============================================================================================
  // Callback Handlers
  // ==============================================================================================

  const handleRefundButtonClick = () => {
    setPageIndex(RefundStepsEnum.ConfirmForm);
  };

  const handleAbortRefundButtonClick = () => {
    setPageIndex(RefundStepsEnum.FillInForm);
  };

  const handleConfirmRefundButtonClick = () => {
    if (selectedReason === undefined) {
      return;
    }

    setRefundInProgress(true);

    const refundPayload: RefundPayloadInterface = {
      note:
        selectedReason === RefundReasonsEnum.Exception
          ? refundReasonNote
          : selectedReason,
      zendesk_ticket_id: zendeskTicketId,
      reason: selectedReason,
      terminate: terminateChecked,
      payments: selectedPayments.map((payment) => {
        const paymentItem = {
          payment_id: payment.payment_id,
          amount: payment.amount,
          can_be_refunded: payment.can_be_refunded,
        };
        return paymentItem;
      }),
    };

    CustomerServiceApi.refundPayments(refundPayload)
      .then((response) => {
        if (response.success) {
          setRefundInProgress(false);
          onCancelClick();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleExpiredCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTerminateChecked(event.target.checked);
  };

  const handleZendeskTicketIdTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setZendeskTicketId(event.target.value);
  };

  const handleRefundReasonChange = (reason: RefundReasonsEnum) => {
    setSelectedReason(reason);
  };

  const handleRefundReasonTextChange = (text: string) => {
    setRefundReasonNote(text);
  };

  const handleSelectedPaymentChange = (
    items: SelectedPaymentItemInterface[],
  ) => {
    setSelectedPayments(items);
  };

  const disableRefundButton = (
    items: SelectedPaymentItemInterface[],
    reason: RefundReasonsEnum | undefined,
  ): boolean => {
    if (items.length == 0) {
      return true;
    }
    if (reason === undefined) {
      return true;
    }
    if (zendeskTicketId === "") {
      return true;
    }
    if (getAmountSum(items) === 0) {
      return true;
    }
    return false;
  };

  /**
   * Header and Footer component
   */
  const RefundModalFooter = () => (
    <Flex gap>
      <Button
        variant="subtle"
        onClick={onCancelClick}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        disabled={disableRefundButton(selectedPayments, selectedReason)}
        onClick={handleRefundButtonClick}
      >
        Refund
      </Button>
    </Flex>
  );

  const RefundModalHeader = () => (
    <Flex
      width="100%"
      align="center"
    >
      <Text size="h3">Refund payments</Text>
      <Box flexPush>
        <Button onClick={onCancelClick}>
          <SvgX16px />
        </Button>
      </Box>
    </Flex>
  );

  const renderPage = () => {
    switch (pagesIndex) {
      case RefundStepsEnum.FillInForm:
        return (
          <>
            <DialogBody>
              <RefundInfo title="User">
                <Text weight="bold">{`${refundCandidate.email} - ${refundCandidate.first_name} ${refundCandidate.last_name}`}</Text>
              </RefundInfo>
              <RefundInfo title="Product">
                <SubscriptionStatusBlock
                  subscription={subscriptionPaymentsItem.subscription}
                />
                <RefundPaymentsList
                  selectedPayments={selectedPayments}
                  payments={subscriptionPaymentsItem.payments}
                  onSelectedPaymentChange={handleSelectedPaymentChange}
                />
              </RefundInfo>
              <Box mt={1}>
                <CheckField
                  label="Expire subscription"
                  checked={terminateChecked}
                  onChange={handleExpiredCheckboxChange}
                />
              </Box>
              <RefundReasons
                selectedReason={selectedReason}
                onReasonChange={handleRefundReasonChange}
                onRefundReasonTextChange={handleRefundReasonTextChange}
                refundReasonNote={refundReasonNote}
              />

              <TextField
                label="Zendesk ticket"
                helpText="Insert a valid ID-reference to Zendesk ticket"
                onChange={handleZendeskTicketIdTextInputChange}
                value={zendeskTicketId}
              />
            </DialogBody>
            <DialogFooter>
              <RefundModalFooter />
            </DialogFooter>
          </>
        );
      case RefundStepsEnum.ConfirmForm:
        return (
          <DialogBody>
            <RefundConfirmation
              refundCandidate={refundCandidate}
              amountSumStr={getAmountSumStr(selectedPayments)}
              note={refundReasonNote}
              reason={selectedReason!}
              zendeskId={zendeskTicketId}
              terminate={terminateChecked}
              plan={subscriptionPaymentsItem.subscription.plan}
            />
            <Flex gap>
              <Button
                variant="subtle"
                disabled={refundInProgress}
                onClick={handleAbortRefundButtonClick}
              >
                Back to form
              </Button>
              <Button
                variant="primary"
                loading={refundInProgress}
                onClick={handleConfirmRefundButtonClick}
              >
                Confirm
              </Button>
            </Flex>
          </DialogBody>
        );
      default:
        return null;
    }
  };
  return (
    <Modal>
      <DialogSidebar
        width="640px"
        onClose={onCancelClick}
      >
        <DialogHeader>
          <RefundModalHeader />
        </DialogHeader>
        {renderPage()}
      </DialogSidebar>
    </Modal>
  );
};

export { RefundPaymentsSidebar };
