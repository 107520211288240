import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

interface Props {
  numberOfLines?: number;
}

export const RentalDescription = ({ numberOfLines = 5 }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      required
      testId={"createAdDescription"}
      multiLine={numberOfLines}
      label={t("create.field.description.heading")}
      errorText={errors.description && t("required_field.input")}
      helpText={t("create.field.description.help_text")}
      {...register("description", {
        required: true,
      })}
    />
  );
};
