import { useState } from "react";
import {
  Box,
  Text,
  useTheme,
  styled,
  Flex,
  Center,
  IconArrowForward,
  Button,
} from "@boligportal/juice";
import { Icon } from "@boligportal/juice/dist/components/Icon/Icon";
import { css } from "@emotion/react";
import { DialogContentConnectingLine } from "./DialogContentConnectingLine";

const CircleBox = styled(Box)<{ lineColor: string }>`
  border-radius: 50%;
  border: 2px solid ${({ lineColor }) => lineColor};
  background-color: ${({ theme }) => theme.colorPalette.primary[700]};
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.unit(1)};
  background-color: transparent;
  &:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.colorPalette.primary[700]};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colorPalette.primary[500]};
  }
  min-height: 85px; // Aproximate height of the link with 3 lines of text
`;

const VisuallyHidable = styled.div<{ isHidden: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`;

type Props = {
  icon: typeof Icon;
  text: string;
  title: string;
  ctaButtonLabel?: string;
  displayConnectingLineArrow: boolean;
  onTrigger?: () => void;
  href: string;
};

export const DialogContentItem = ({
  title,
  text,
  icon,
  ctaButtonLabel,
  displayConnectingLineArrow,
  onTrigger,
  href,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const Icon = icon;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const lineColor = displayConnectingLineArrow
    ? theme.colorPalette.primary[500]
    : "#ffffff";

  return (
    <Flex column>
      <StyledLink
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        href={href}
        onClick={onTrigger}
      >
        <CircleBox lineColor={lineColor}>
          <Center
            width="50px"
            height="50px"
          >
            <Icon
              css={css`
                color: #ffffff;
                font-size: 24px;
              `}
            />
          </Center>
        </CircleBox>

        <Flex
          flexGrow
          justify="space-between"
          align="center"
        >
          <Flex
            column
            px={2}
            maxWidth="300px"
          >
            <Text
              color="inverted"
              weight="bold"
            >
              {title}
            </Text>
            <Text color="inverted">{text}</Text>
          </Flex>
          {ctaButtonLabel ? (
            <Button
              variant="primary"
              href={href}
              onClick={onTrigger}
            >
              {ctaButtonLabel}
            </Button>
          ) : (
            <VisuallyHidable isHidden={!isHovered}>
              <IconArrowForward
                color="inverted"
                size="large"
              />
            </VisuallyHidable>
          )}
        </Flex>
      </StyledLink>

      <DialogContentConnectingLine
        lineColor={lineColor}
        lineStyle={displayConnectingLineArrow ? "solid" : "dashed"}
        displayArrow={displayConnectingLineArrow ?? false}
      />
    </Flex>
  );
};
