import * as React from "react";
import { RentRecord } from "apps/rent/types/RentRecord";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { App } from "components/app";
import { RentRecordElipsisMenuItem } from "components/cells";
import { calculateArrears } from "features/rentable_tenancy/helpers/calculateArrears";
import { t } from "lib/i18n";
import {
  PaymentRecordStatusChangeProvider,
  usePaymentRecordChangeStatus,
} from "./usePaymentRecordChangeStatus";
import {
  PaymentRecordDeletionProvider,
  usePaymentRecordDeletion,
} from "./usePaymentRecordDeletion";
import {
  PaymentRecordDetailProvider,
  usePaymentRecordDetail,
} from "./usePaymentRecordDetail";
import {
  PaymentRecordOneTimePaymentProvider,
  usePaymentRecordOneTimePayment,
} from "./usePaymentRecordOneTimePayment";
import {
  TransferArrearsProvider,
  useTransferArrears,
} from "./useTransferArrears";

type Props = {
  children: React.ReactNode;
  invalidationQueries: string[] | any;
};

export const AllPaymentRecordFeaturesProviders = ({
  children,
  invalidationQueries,
}: Props) => (
  <PaymentRecordDeletionProvider invalidationQueries={invalidationQueries}>
    <PaymentRecordStatusChangeProvider
      invalidationQueries={invalidationQueries}
    >
      <PaymentRecordOneTimePaymentProvider
        invalidationQueries={invalidationQueries}
      >
        <TransferArrearsProvider invalidationQueries={invalidationQueries}>
          <PaymentRecordDetailProvider
            invalidationQueries={invalidationQueries}
          >
            {children}
          </PaymentRecordDetailProvider>
        </TransferArrearsProvider>
      </PaymentRecordOneTimePaymentProvider>
    </PaymentRecordStatusChangeProvider>
  </PaymentRecordDeletionProvider>
);

export const useAllPaymentRecordFeatures = () => {
  const MARK_AS_MANUAL_PAID = t("rentable.rent.mark_as_manual_paid");
  const ADD_ONE_TIME_PAYMENT = t("rentable.rent.add_one_time_payment");
  const DELETE_PAYMENT = t("rentable.rent.delete_payment");
  const GOTO_SETUP_FOR_PAYMENT = t(
    "rentable.rent.go_to_rent_setup_for_tenancy",
  );
  const DETAILS = t("common.buttons.show_details");
  const MOVE_ARREARS = t("rentable.rent.transfer_arrears_to_another_payment");
  const MARK_PAYMENT_AS_PARTIALLY_PAID = t(
    "rentable.rent.arrears_mark_as_manual_paid",
  );

  const { setPaymentStatusChangeCandidate } = usePaymentRecordChangeStatus();
  const { setPaymentRecordDeletionCandidate } = usePaymentRecordDeletion();
  const { setPaymentRecordOneTimePaymentCandidate } =
    usePaymentRecordOneTimePayment();
  const { setPaymentRecordDetailCandidate } = usePaymentRecordDetail();
  const { setTransferArrearsCandidate } = useTransferArrears();

  const onHandleDisplayRecordDetail = (record: RentRecord) => {
    setPaymentRecordDetailCandidate({
      address: record.address,
      paymentRecordId: record.id,
      rentableId: record.rentableId,
      tenancyId: record.tenancyId,
      tenants: record.tenants,
    });
  };

  const onHandleMenuItemSelect = (
    record: RentRecord,
    menuItem: RentRecordElipsisMenuItem,
  ) => {
    const settingsLanguagePrefix = App.settings.i18n_prefix;

    switch (menuItem.name) {
      case DETAILS: {
        setPaymentRecordDetailCandidate({
          address: record.address,
          paymentRecordId: record.id,
          rentableId: record.rentableId,
          tenancyId: record.tenancyId,
          tenants: record.tenants,
        });

        break;
      }

      case MARK_AS_MANUAL_PAID: {
        setPaymentStatusChangeCandidate({
          wasManuallyChanged: record.paymentInfo.wasManuallyChanged,
          paymentRecordId: record.id,
          fromStatus: record.status,
          toStatus: TenancyPaymentStatus.PAID,
          paymentType: record.paymentInfo.paymentMethodType,
          paymentProviderStatus: record.paymentInfo.paymentProviderState,
          transactions: record.transactions,
          rent: record.amount,
        });

        break;
      }

      case ADD_ONE_TIME_PAYMENT: {
        const recurringRent = record.rent_regulation
          ? record.rent_regulation.items
          : record.recurring_rent;

        setPaymentRecordOneTimePaymentCandidate({
          dueDate: record.due_date,
          oneTimePayments: record.one_time_payment?.items ?? [],
          tenancyId: record.tenancyId,
          paymentRecordId: record.id,
          rentItems: recurringRent,
          providerStatus: record.paymentInfo.paymentProviderState,
          paymentType: record.paymentInfo.paymentMethodType,
          wasManuallyChanged: record.paymentInfo.wasManuallyChanged,
          isPaymentEditable: record.is_within_edit_deadline,
        });

        break;
      }

      case DELETE_PAYMENT: {
        setPaymentRecordDeletionCandidate({
          paymentRecordId: record.id,
        });

        break;
      }

      case GOTO_SETUP_FOR_PAYMENT: {
        window.location.href = `${settingsLanguagePrefix}/property-owner/my-rentables/${record.rentableId}/tenancies/${record.tenancyId}/rent`;

        break;
      }

      case MOVE_ARREARS: {
        setTransferArrearsCandidate({
          tenancyId: record.tenancyId,
          arrears: calculateArrears(record.amount, record.transactions.items)
            .arrears,
          paymentDueDate: record.due_date,
          paymentRecordId: record.id,
        });

        break;
      }

      case MARK_PAYMENT_AS_PARTIALLY_PAID: {
        setPaymentStatusChangeCandidate({
          wasManuallyChanged: record.paymentInfo.wasManuallyChanged,
          paymentRecordId: record.id,
          fromStatus: record.status,
          toStatus:
            record.status === TenancyPaymentStatus.NOT_PAID
              ? TenancyPaymentStatus.PAID
              : TenancyPaymentStatus.NOT_PAID,
          paymentType: record.paymentInfo.paymentMethodType,
          paymentProviderStatus: record.paymentInfo.paymentProviderState,
          arrears: calculateArrears(record.amount, record.transactions.items)
            .arrears,
          transactions: record.transactions,
          rent: record.amount,
        });

        break;
      }

      default: {
        break;
      }
    }
  };

  const onHandleDisplayPaymentStatusChange = (
    record: RentRecord,
    newStatus: TenancyPaymentStatus,
  ) => {
    setPaymentStatusChangeCandidate({
      wasManuallyChanged: record.paymentInfo.wasManuallyChanged,
      paymentRecordId: record.id,
      fromStatus: record.status,
      toStatus: newStatus,
      paymentType: record.paymentInfo.paymentMethodType,
      paymentProviderStatus: record.paymentInfo.paymentProviderState,
      arrears: calculateArrears(record.amount, record.transactions.items)
        .arrears,
      transactions: record.transactions,
      rent: record.amount,
    });
  };

  return {
    onHandleDisplayRecordDetail,
    onHandleMenuItemSelect,
    onHandleDisplayPaymentStatusChange,
  };
};
