import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
  Notice,
  Text,
} from "@boligportal/juice";
import { SigningStatus } from "apps/contracts/components/common/interfaces";
import { App } from "components/app";
import { t } from "lib/i18n";

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  status: SigningStatus;
  contractId: number;
};

export const InfoDialog = ({
  onClose,
  status,
  onSubmit,
  contractId,
}: Props) => (
  <Dialog
    open
    size="large"
    onClose={onClose}
  >
    <DialogHeader>
      {t("contracts.delete_contract_info_dialog.title")}
    </DialogHeader>
    <DialogContent>
      <Flex column>
        <Text markdown>
          {t("contracts.delete_contract_info_dialog.error_message_markdown")}
        </Text>
      </Flex>
      {status === SigningStatus.AWAITING && (
        <Flex column>
          <Flex
            column
            border
            borderRadius="md"
            bg="tint"
            pt={2}
            px={2}
          >
            <Text
              block
              weight="bold"
              size="small"
              pb={1}
            >
              {t("contracts.delete_contract_info_dialog.error.title")}
            </Text>
            <Notice type="danger">
              <Link
                href="#"
                underline="hover"
                onClick={() =>
                  (window.location.href = `${App.settings.routes.contracts}${contractId}/status`)
                }
              >
                {t(
                  "contracts.delete_contract_info_dialog.error.has_active_sign_request_markdown",
                )}
              </Link>
            </Notice>
          </Flex>
        </Flex>
      )}
    </DialogContent>
    <DialogFooter>
      <Flex gap>
        <Button
          variant="subtle"
          onClick={onClose}
        >
          {t("contracts.delete_contract_dialog.cancel_button")}
        </Button>
        <Button
          disabled={status === SigningStatus.AWAITING}
          variant="danger"
          onClick={onSubmit}
        >
          {t("contracts.delete_contract_info_dialog.confirm_button")}
        </Button>
      </Flex>
    </DialogFooter>
  </Dialog>
);
