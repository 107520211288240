import { useRef, useEffect } from "react";
import { Box } from "@boligportal/juice";
import { Section } from "components/design-system/atoms/section/section";
import { t } from "lib/i18n";
import { FieldErrorAlert } from "./field_error_alert";
import { FormFieldError } from "./interfaces";

const FieldErrorsDisplay = (props: {
  errors: FormFieldError[];
  autoScrollTo: boolean;
  noWrapping?: boolean;
}) => {
  // The first time any errors will render. make sure that they are in the viewport
  const wrapperElem = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.autoScrollTo && wrapperElem.current !== null) {
      wrapperElem.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  if (props.noWrapping) {
    return (
      <div ref={wrapperElem}>
        <p>{t("errors.section.info")}</p>
        <div>
          {props.errors.map((error) => (
            <FieldErrorAlert
              key={error.affectedField}
              error={error}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div ref={wrapperElem}>
      <Section
        heading={t("errors.section.heading")}
        variant="h3"
      >
        <Box py={1}>{t("errors.section.info")}</Box>
        <Box>
          {props.errors.map((error) => (
            <FieldErrorAlert
              key={error.affectedField}
              error={error}
            />
          ))}
        </Box>
      </Section>
    </div>
  );
};

export { FieldErrorsDisplay };
