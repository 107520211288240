export const NotificationBellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="57"
    height="57"
    viewBox="0 0 32 32"
  >
    <defs>
      <clipPath id="b">
        <rect
          width="32"
          height="32"
        />
      </clipPath>
    </defs>
    <g
      id="a"
      clipPath="url(#b)"
    >
      <g transform="translate(1.36 1.596)">
        <path
          d="M11.371,26.807A4.276,4.276,0,0,1,7.194,23.4h8.353A4.272,4.272,0,0,1,11.371,26.807ZM20.62,22.26H2.116a2.262,2.262,0,0,1-1.278-.4A1.89,1.89,0,0,1,.072,20.8,1.978,1.978,0,0,1,.7,18.759a7.634,7.634,0,0,0,2.715-5.836V9.753a7.959,7.959,0,0,1,7.959-7.959c.241,0,.5.013.76.04a7.878,7.878,0,0,0-.76,3.372,7.967,7.967,0,0,0,7.958,7.959h.013a7.568,7.568,0,0,0,2.677,5.573,1.982,1.982,0,0,1,.678,1.946A2.076,2.076,0,0,1,20.62,22.26ZM19.33,10.412a5.207,5.207,0,0,1-3.682-8.887,5.206,5.206,0,1,1,3.682,8.887ZM19.141,4h0V7.643h.947V2.873h-.1l-1.972.706v.77L19.141,4Z"
          transform="translate(2.372 1.001)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
