import { useRef } from "react";
import { ReviewReason } from "apps/customer_service/pages/ads_page/interfaces";
import { AdStates } from "lib/enums";

// Juice Badge Color Props
type BadgeColorProp =
  | "blue"
  | "green"
  | "orange"
  | "red"
  | "yellow"
  | undefined;

const adStateColors: Record<AdStates, BadgeColorProp> = {
  [AdStates.ACTIVE_APPROVED]: "green",
  [AdStates.INACTIVE_PENDING_APPROVAL]: "yellow",
  [AdStates.INACTIVE_REJECTED]: "red",
  [AdStates.RENTED_OUT_APPROVED]: undefined,
  [AdStates.RESERVED_APPROVED]: "yellow",
  [AdStates.INACTIVE_DRAFT]: "blue",
  [AdStates.DRAFT]: undefined, // TODO set new color
};

const adStateTexts: Record<AdStates, string> = {
  [AdStates.ACTIVE_APPROVED]: "Active",
  [AdStates.INACTIVE_DRAFT]: "Skipped",
  [AdStates.DRAFT]: "Draft",
  [AdStates.INACTIVE_PENDING_APPROVAL]: "Waiting for approval",
  [AdStates.INACTIVE_REJECTED]: "Rejected",
  [AdStates.RENTED_OUT_APPROVED]: "Rented out",
  [AdStates.RESERVED_APPROVED]: "Reserved",
};

/**
 * Customer-Service Feature.
 * Ad Status Feature contains the business logic for determening
 * what color and text to use to represent a given AdState.
 *
 * @param state
 * @param needsFollowUp
 * @param needsReview
 */

const useAdStatusFeature = (
  state: AdStates,
  needsFollowUp: boolean,
  reviewReason: null | ReviewReason,
): { adStatusColor: BadgeColorProp; adStatusText: string } => {
  let color: BadgeColorProp; // Default color
  let text = "";

  if (reviewReason && state === AdStates.ACTIVE_APPROVED) {
    text = "Needs review";
  } else if (needsFollowUp) {
    text = "Waiting for follow-up";
  } else {
    color = adStateColors[state];
    text = adStateTexts[state];
  }

  const adStatusItemRef = useRef<{
    color: BadgeColorProp;
    text: string;
  }>({
    color,
    text,
  });
  return {
    adStatusColor: adStatusItemRef.current.color,
    adStatusText: adStatusItemRef.current.text,
  };
};

export { useAdStatusFeature };
