import { useState } from "react";
import * as api from "api";
import { useLatestDraftContractId } from "api/queries";
import { ContractAPI } from "apps/contracts/components/common/ContractApi";
import { t } from "lib/i18n";
import { Props as ContractNudgeDialogProps } from "./ContractNudgeDialog";

type DialogText = Pick<ContractNudgeDialogProps, "title" | "subtitle">;

export type NudgePlacement =
  | "INBOX"
  | "RENTABLE_AD_PAGE"
  | "RENTABLE_AD_LIST"
  | "INBOX_AD_THREAD_LIST";
export type NudgeName = "RESERVED" | "RENTED_OUT" | "INBOX_PROMOTE_CONTRACTS";

type TrackingData = {
  placement: NudgePlacement;
  name: NudgeName;
};

export const useContractNudgeDialog = (
  rentableId?: number,
): {
  props: ContractNudgeDialogProps;
  showDialog: (texts: DialogText, trackingInfo: TrackingData) => void;
} => {
  const { data, isFetching } = useLatestDraftContractId(rentableId);
  const [open, setOpen] = useState(false);
  const [trackingData, setTrackingData] = useState<TrackingData | null>(null);
  const [titleAndDescription, setTitleAndDescription] = useState<DialogText>({
    title: "",
    subtitle: "",
  });

  const closeDialog = () => {
    setOpen(false);
    setTitleAndDescription({
      title: "",
      subtitle: "",
    });
  };

  const showDialog = (texts: DialogText, trackingInfo: TrackingData) => {
    setTitleAndDescription(texts);
    setTrackingData(trackingInfo);
    setOpen(true);
  };

  const dialogTranslations = data?.latest_draft_contract_id
    ? {
        description: t(
          "rentable_details.nudge_contract.dialog.description.draft_exists",
        ),
        onConfirmText: t(
          "rentable_details.nudge_contract.dialog.confirm_text.draft_exists",
        ),
      }
    : {
        description: t(
          "rentable_details.nudge_contract.dialog.description.no_draft_exists",
        ),
        onConfirmText: t(
          "rentable_details.nudge_contract.dialog.confirm_text.no_draft_exists",
        ),
      };

  const onConfirm = async () => {
    if (!rentableId) {
      return closeDialog();
    }

    let contractId = data?.latest_draft_contract_id;
    if (!contractId) {
      contractId = await ContractAPI.createContract(rentableId).then(
        (res) => res.contract_id,
      );
    }

    if (trackingData && contractId) {
      await api.trackContractNudgeAfterReservingOrRentingOutAd({
        ...trackingData,
        contract_id: contractId,
      });
    }

    window.location.href = `/contracts/${contractId}`;
  };

  return {
    showDialog,
    props: {
      open,
      isFetching,
      onClose: closeDialog,
      description: dialogTranslations.description,
      onConfirm,
      onConfirmText: dialogTranslations.onConfirmText,
      ...titleAndDescription,
    },
  };
};
