import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Flex, FlexColumn } from "@boligportal/juice";
import { vestResolver } from "@hookform/resolvers/vest";
import * as api from "api";
import { App } from "components/app";
import { RentableFields } from "components/forms/elements/FormElements";
import {
  AddressManualDenmark,
  AddressManualFormDenmark,
} from "features/address/dk/AddressManualDenmark";
import { transformAddressManualFormDenmarkToManualAddress } from "features/address/dk/helpers/transformAddressManualFormDenmarkToManualAddress";
import { ManualAddressLayout } from "./ManualAddressLayout";
import { validationSuite } from "./validation";

type Props = {
  onUseAutocomplete: () => void;
  onRentableCreated: (rentableId: number) => void;
};

export type ManualAddressInfo = AddressManualFormDenmark & {
  category: string;
  rooms: number;
  size_m2: number;
};

export const ManualAddressDenmark = ({
  onUseAutocomplete,
  onRentableCreated,
}: Props) => {
  const [canCreateRentable, setCanCreateRentable] = useState(true);
  const formMethods = useForm<ManualAddressInfo>({
    resolver: vestResolver(validationSuite),
  });

  const onSubmit: SubmitHandler<ManualAddressInfo> = async (data) => {
    const manualAddress =
      transformAddressManualFormDenmarkToManualAddress(data);
    const { category, rooms, size_m2 } = data;
    const { can_create_rentable } = await api.checkCanCreateRentable(
      manualAddress,
      category,
    );
    setCanCreateRentable(can_create_rentable);

    if (!can_create_rentable) {
      return;
    }

    const { rentable_id } = await api.createRentable({
      address: manualAddress,
      apartment_number: null,
      category: category,
      room_count: rooms,
      size_m2: size_m2,
      estate: null,
    });

    onRentableCreated(rentable_id);
  };

  return (
    <ManualAddressLayout<ManualAddressInfo>
      onGoBack={onUseAutocomplete}
      onRentableCreated={onRentableCreated}
      canCreateRentable={canCreateRentable}
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <AddressManualDenmark />

      <Flex>
        <Box flexGrow>
          <RentableFields.RentalCategory
            options={App.settings.rental_categories}
          />
          <Flex gap={2}>
            <FlexColumn
              size={{
                xl: 6,
                xs: 12,
              }}
            >
              <RentableFields.RentalSize />
            </FlexColumn>
            <FlexColumn
              size={{
                xl: 6,
                xs: 12,
              }}
            >
              <RentableFields.RentalNumberOfRooms />
            </FlexColumn>
          </Flex>
        </Box>
      </Flex>
    </ManualAddressLayout>
  );
};
