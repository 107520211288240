import * as React from "react";
import { Button } from "@boligportal/juice";
import styled from "@emotion/styled";

const dynamicButtonsWrapperStyle = (props) => `
  ${props.controlsContainerStyle}
`;

const dynamicButtonsInnerWrapperStyle = (props) => `
  ${props.controlsInnerContainerStyle}
`;

const StyledButtonsWrapper = styled.div<{ controlsContainerStyle?: string }>`
  ${dynamicButtonsWrapperStyle};
`;

const StyledButtonsInnerWrapper = styled.div<{
  controlsInnerContainerStyle?: string;
}>`
  display: flex;
  width: 100%;

  ${dynamicButtonsInnerWrapperStyle};
`;

const LeftButtonWrapper = styled.div`
  flex: 1 1 auto;
  width: 0;
`;

const RightButtonWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  width: 0;
`;

const PagesIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SteppedView = (props: {
  children: React.ReactNode;
  hideNavigation?: boolean;
  hideProgress?: boolean;
  backButtonLabel?: string;
  forwardButtonLabel?: string;
  completionButtonLabel?: string;
  completionButtonAction?(): void;
  controlsContainerStyle?: string;
  controlsInnerContainerStyle?: string;
}) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState<number>(0);

  const stepComponents = React.useMemo(
    () => React.Children.toArray(props.children),
    [props.children],
  );

  const hasPreviousStep = currentStepIndex > 0;
  const hasNextStep = currentStepIndex < stepComponents.length - 1;

  const goBack = () => {
    if (hasPreviousStep) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const goForward = () => {
    if (hasNextStep) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  return (
    <div>
      {stepComponents[currentStepIndex]}

      <StyledButtonsWrapper
        controlsContainerStyle={props.controlsContainerStyle}
      >
        <StyledButtonsInnerWrapper
          controlsInnerContainerStyle={props.controlsInnerContainerStyle}
        >
          <LeftButtonWrapper>
            {hasPreviousStep && (
              <Button
                variant="subtle"
                onClick={goBack}
              >
                {props.backButtonLabel}
              </Button>
            )}
          </LeftButtonWrapper>

          <PagesIndicatorWrapper>
            {!props.hideProgress &&
              stepComponents.length > 1 &&
              `${currentStepIndex + 1} | ${stepComponents.length}`}
          </PagesIndicatorWrapper>

          <RightButtonWrapper>
            {(hasNextStep || props.completionButtonLabel) && (
              <Button
                variant="branded"
                onClick={hasNextStep ? goForward : props.completionButtonAction}
              >
                {hasNextStep
                  ? props.forwardButtonLabel
                  : props.completionButtonLabel}
              </Button>
            )}
          </RightButtonWrapper>
        </StyledButtonsInnerWrapper>
      </StyledButtonsWrapper>
    </div>
  );
};

SteppedView.defaultProps = {
  hideNavigation: false,
  hideProgress: false,
  backButtonLabel: "<",
  forwardButtonLabel: ">",
};
