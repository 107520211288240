import { useNotifications, Badge, Box, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { MovingReportResponse } from "../api";

const translations = {
  email: "moving_reports.validation.tenant.invalid_email",
  phone_number: "moving_reports.validation.tenant.invalid_phone_number",
  representative_email:
    "moving_reports.validation.tenant_representative.invalid_email",
  representative_phone_number:
    "moving_reports.validation.tenant_representative.invalid_phone_number",
};

export const useResponseErrorsNotification = () => {
  const { addNotification } = useNotifications();

  return {
    showNotification: (response: MovingReportResponse) => {
      const messages: { field: string; text: string }[] = [];
      const { tenants } = response.errors;

      if (tenants) {
        const tenantsIds = Object.keys(tenants);

        tenantsIds.forEach((tenantId) => {
          const tenantIndex =
            response.report.tenants.indexOf(
              response.report.tenants.find(
                (t) => t.id === parseInt(tenantId, 10),
              )!,
            ) + 1;

          const tenantErrorFields = Object.keys(tenants[tenantId]);

          tenantErrorFields.forEach((errorField) => {
            if (translations[errorField]) {
              messages.push({
                field: `tenants.${tenantId}.${errorField}`,
                text: `#${tenantIndex} ${t(translations[errorField])}`,
              });
            }
          });
        });
      }

      addNotification({
        title: <Badge state="danger">{t("moving_reports.error")}</Badge>,
        content: (
          <Box mt={1}>
            {messages.map((message) => (
              <Box
                key={message.field}
                mb={2}
              >
                <Text color="inverted">{message.text}</Text>
              </Box>
            ))}
          </Box>
        ),
        autoDismiss: false,
      });
    },
  };
};
