import { Box, Hidden, Text } from "@boligportal/juice";
import { t } from "lib/i18n";

type Props = {
  isVisible?: boolean;
  onClick?: () => void;
};

export const PromotedButton = ({ isVisible, onClick }: Props) => {
  if (!isVisible || !onClick) {
    return null;
  }

  return (
    <Hidden
      on={{
        xs: false,
        md: true,
      }}
    >
      <Box
        pb={2}
        mb={2}
        borderBottom
        align="center"
      >
        <a onClick={onClick}>
          <Text
            color="base"
            decoration="underline"
          >
            {t("srp.load_more_promoted_ads")}
          </Text>
        </a>
      </Box>
    </Hidden>
  );
};
