import { t } from "lib/i18n";
import { Translatable } from "lib/translations";
import {
  MeterType,
  MeterUnit,
  MovingReportState,
  MovingReportType,
} from "./enums";

export const MovingReportTypeTranslations =
  (): Translatable<MovingReportType> => ({
    [MovingReportType.IN]: t("moving_reports.type.in"),
    [MovingReportType.OUT]: t("moving_reports.type.out"),
  });

export const MovingReportStateTranslations =
  (): Translatable<MovingReportState> => ({
    [MovingReportState.IN_PROGRESS]: t("moving_reports.state.in_progress"),
    [MovingReportState.TENANT_DEFECT_FLOW]: t(
      "moving_reports.state.tenant_defect_flow",
    ),
    [MovingReportState.FINISHED]: t("moving_reports.state.finished"),
  });

export const MeterTypeTranslations = (): Translatable<MeterType> => ({
  [MeterType.WATER]: t("moving_reports.meter_type.water"),
  [MeterType.HOT_WATER]: t("moving_reports.meter_type.hot_water"),
  [MeterType.HEATING_KWH]: t("moving_reports.meter_type.heating_kwh"),
  [MeterType.HEATING_MWH]: t("moving_reports.meter_type.heating_mwh"),
  [MeterType.HEATING_M3]: t("moving_reports.meter_type.heating_m3"),
  [MeterType.HEATING_GJ]: t("moving_reports.meter_type.heating_gj"),
  [MeterType.HEATING_HOURS]: t("moving_reports.meter_type.heating_hours"),
  [MeterType.HOURS]: t("moving_reports.meter_type.hours"),
  [MeterType.GAS]: t("moving_reports.meter_type.gas"),
  [MeterType.ELECTRICITY]: t("moving_reports.meter_type.electricity"),
  [MeterType.OIL]: t("moving_reports.meter_type.oil"),
  [MeterType.OTHER]: t("moving_reports.meter_type.other"),
  [MeterType.OTHER_M3]: t("moving_reports.meter_type.other_m3"),
  [MeterType.OTHER_L]: t("moving_reports.meter_type.other_l"),
  [MeterType.OTHER_KWH]: t("moving_reports.meter_type.other_kwh"),
});

export const MeterUnitTranslations = (): Translatable<MeterUnit> => ({
  [MeterUnit.UNIT_M3]: t("moving_reports.meter_unit.m3"),
  [MeterUnit.UNIT_GJ]: t("moving_reports.meter_unit.gj"),
  [MeterUnit.UNIT_KWH]: t("moving_reports.meter_unit.kwh"),
  [MeterUnit.UNIT_MWH]: t("moving_reports.meter_unit.mwh"),
  [MeterUnit.UNIT_L]: t("moving_reports.meter_unit.liters"),
  [MeterUnit.UNIT_HOURS]: t("moving_reports.meter_unit.hours"),
  [MeterUnit.UNIT_NONE]: "",
});
