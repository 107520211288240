import React from "react";
import { Flex, Box } from "@boligportal/juice";
import { Plan } from "components/interfaces/plan";
import { t } from "lib/i18n";
import { getProductName } from "lib/product";
import { VolumeToolTip } from "./VolumeBarToolTip";
import {
  VolumeBarExposure,
  VolumeBarExposurePlus,
  VolumeBarFreeAd,
  VolumeBarOpenAd,
  VolumeBarTopAd,
} from "./VolumeBars";

type Props = {
  plans: Plan[];
  selectedPlan: number;
  isHovered: boolean;
};

export const HoverableCardVolumeBars = ({
  plans,
  selectedPlan,
  isHovered,
}: Props) => {
  return (
    <Flex
      column
      height={20}
      justify={"end"}
    >
      {plans[selectedPlan].product.name === "open_ad" && (
        <Flex>
          <Flex
            width="40%"
            column
            justify={"space-between"}
          >
            <VolumeToolTip
              text={t("upsell_products.free.name")}
              isHovered={false}
            />
            <VolumeBarFreeAd isHovered={false} />
          </Flex>
          <Box width="60%">
            <VolumeToolTip
              text={getProductName(plans[0].product)}
              isHovered={isHovered}
            />
            <VolumeBarOpenAd isHovered={isHovered} />
          </Box>
        </Flex>
      )}
      {plans[selectedPlan].product.name === "promote_ad" && (
        <>
          <VolumeToolTip
            text={getProductName(plans[0].product)}
            isHovered={isHovered}
          />
          <VolumeBarTopAd isHovered={isHovered} />
        </>
      )}
      {plans[selectedPlan].product.name === "exposure_ad" && (
        <>
          <VolumeToolTip
            text={getProductName(plans[0].product)}
            isHovered={isHovered}
          />
          <VolumeBarExposure isHovered={isHovered} />
        </>
      )}
      {plans[selectedPlan].product.name === "exposure_plus_ad" && (
        <>
          <VolumeToolTip
            text={getProductName(plans[0].product)}
            isHovered={isHovered}
          />
          <VolumeBarExposurePlus isHovered={isHovered} />
        </>
      )}
    </Flex>
  );
};
