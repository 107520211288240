import { useFormContext } from "react-hook-form";
import { t } from "lib/i18n";
import { ControlledPaymentNumberField } from "../ControlledPaymentNumberField";

export const MonthlyRentExtraCosts = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <ControlledPaymentNumberField
      control={control}
      name="monthly_rent_extra_costs"
      label={t("create.field.monthly_rent_extra_costs.heading")}
      errorText={errors.monthly_rent_extra_costs && t("required_field.numeric")}
      testId="createAdMonthlyRentExtraCosts"
      required
    />
  );
};
