import { Box, Link, Text } from "@boligportal/juice";

export type RentableAddressCell = {
  address: {
    postalCity: string;
    streetAddress: string;
    href: string;
  };
};

export const RentableAddressCellRenderer = ({
  address,
}: RentableAddressCell) => {
  const { postalCity, streetAddress, href } = address;

  return (
    <Box>
      <Link
        href={href}
        color="primary"
        underline="hover"
      >
        <Box>
          <Text
            weight="bold"
            size="small"
          >
            {streetAddress}
          </Text>
        </Box>
        <Box>
          <Text
            size="small"
            color="muted"
          >
            {postalCity}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};
