/* eslint-disable comma-dangle */
import { FieldErrorsDisplay } from "components/forms/field_errors_display";
import { FormFieldError } from "components/forms/interfaces";
import { t } from "lib/i18n";
import { getListingErrorMessages } from "../helpers/getListingErrorMessages";

interface Props {
  errors: any;
  autoScrollTo: boolean;
  noWrapping?: boolean;
}

export const FormErrors = ({ errors, autoScrollTo, noWrapping }: Props) => {
  const getFieldHeading = (field: string) => t(`create.field.${field}.heading`);

  const { fieldToErrorMap } = getListingErrorMessages({
    minRoomValue: 1,
    maxRoomValue: 20,
  });

  const fieldErrors: FormFieldError[] = [];
  const fields = Object.keys(errors);

  fields.forEach((field) => {
    fieldErrors.push({
      heading: getFieldHeading(field),
      affectedField: field,
      message: fieldToErrorMap[field],
    });
  });

  if (fieldErrors.length === 0) {
    return null;
  }

  return (
    <FieldErrorsDisplay
      errors={fieldErrors}
      autoScrollTo={autoScrollTo}
      noWrapping={noWrapping}
    />
  );
};
