import * as React from "react";
import { Box, Flex, Hidden, styled } from "@boligportal/juice";
import { AdFiltersNavigateBack } from "components/ad_filters_navigate_back";
import { AdFiltersView } from "components/ad_filters_view";
import { Header } from "components/header";
import { Filters } from "components/interfaces/filters";
import { MapResults } from "components/interfaces/map_results";
import { Option } from "components/interfaces/option";
import { StyledMapsPageWrapper, StyledMapsWrapper } from "components/maps/maps";
import { API } from "lib/api";
import { debounce } from "lodash-es";
// FIXME: is this common or only used in map?
import { IsServerSideContext } from "../../../../wrap_in_app";
import {
  Action_changeView,
  Action_clearFilters,
  Action_indicateFetchingData,
  Action_mapAdsSelected,
  Action_mapPositionChange,
  Action_mapResultsChange,
  Action_toggleShowMobileFilters,
  Action_updateAgentId,
  Action_updateFilters,
} from "../../search_result_reducer";
import { SelectedAdPopup } from "../list/selected_ad_popup";
import { MapActions } from "./MapActions";
import { MapContainer } from "./map_container";

interface MapViewProps {
  selectedAdIds: Array<number>;
  filters: Filters;
  dispatch: React.Dispatch<
    | Action_changeView
    | Action_toggleShowMobileFilters
    | Action_indicateFetchingData
    | Action_mapResultsChange
    | Action_mapPositionChange
    | Action_mapAdsSelected
    | Action_updateFilters
    | Action_clearFilters
    | Action_updateAgentId
  >;
  mapResults: MapResults;
  mapCenter: [number, number] | null;
  mapZoom: number | null;
  categoryOptions: Array<Option>;
  isFetchingData: boolean;
  agentId: number | null;
}

const debouncedApiCall = debounce(
  (
    dispatch: React.Dispatch<
      Action_mapResultsChange | Action_indicateFetchingData
    >,
    filters: Filters,
    abortController: AbortController,
  ) => {
    const { min_lat, min_lng, max_lat, max_lng } = filters;

    const bbox =
      min_lat && min_lng && max_lat && max_lng
        ? {
            min_lat,
            max_lng,
            max_lat,
            min_lng,
          }
        : null;

    dispatch({
      type: "indicateFetchingData",
      payload: true,
    });

    API.getMapResults(filters, bbox, abortController.signal).then(
      (mapData) => {
        dispatch({
          type: "mapResultsChange",
          payload: {
            results: mapData.results,
            current_url: mapData.current_url,
            agent_id: mapData.agent_id,
          },
        });
      },
      (error) => {
        if (error.name === "AbortError") {
          // fetch request was cancelled
        } else {
          dispatch({
            type: "indicateFetchingData",
            payload: false,
          });
        }
      },
    );
  },
  100,
);

export const MapView = ({
  selectedAdIds,
  filters,
  dispatch,
  mapResults,
  mapCenter,
  mapZoom,
  categoryOptions,
  isFetchingData,
  agentId,
}: MapViewProps) => {
  const isServerSide = React.useContext(IsServerSideContext);

  React.useEffect(() => {
    // Nice video about AbortController and Hooks -> https://youtu.be/JGASDKLZcdw?t=253
    const abortController = new AbortController();
    debouncedApiCall(dispatch, filters, abortController);

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [filters, dispatch]);

  return (
    <MapViewWrapper>
      <Header />
      {!isServerSide && (
        <>
          <MapActions
            ignorePointerEvents={selectedAdIds.length > 0}
            filters={filters}
            dispatch={dispatch}
            agentId={agentId}
          />
          <MapContainer
            results={mapResults}
            mapCenter={mapCenter ?? undefined}
            mapZoom={mapZoom ?? undefined}
            selectedAds={selectedAdIds}
            dispatch={dispatch}
            filters={filters}
            slotForFilters={
              <Flex column>
                <AdFiltersNavigateBack dispatch={dispatch} />
                <Box p={2}>
                  <Hidden
                    on={{
                      xs: true,
                      lg: false,
                    }}
                  >
                    <AdFiltersView
                      dispatch={dispatch}
                      filters={filters}
                      categoryOptions={categoryOptions}
                      isMapView
                    />
                  </Hidden>
                </Box>
              </Flex>
            }
            isFetchingData={isFetchingData}
          />
          {!!selectedAdIds.length && (
            <SelectedAdPopup
              dispatch={dispatch}
              adIds={selectedAdIds}
            />
          )}
        </>
      )}
      {isServerSide && (
        <StyledMapsPageWrapper>
          <StyledMapsWrapper />
        </StyledMapsPageWrapper>
      )}
    </MapViewWrapper>
  );
};

export const MapViewWrapper = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;
