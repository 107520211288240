/* eslint-disable comma-dangle */
import { AddressCandidate } from "features/address/common/interfaces/AddressCandidate";
import { transformDataToAddressCandidates } from "../helpers/transformDataToAddressCandidates";

export const getAddressCandidates = async (
  value: string,
  containerId: string,
): Promise<Array<AddressCandidate>> => {
  const response = await fetch(
    `https://address.boligportal.dk/autocomplete/dk/${value}${
      containerId !== "" ? `?Container=${containerId}` : ""
    }`,
  );
  const data = await response.json();

  const matches = transformDataToAddressCandidates(data);

  return Promise.resolve(matches);
};
