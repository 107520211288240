import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

export const JournalNumber = () => {
  const { register } = useFormContext();

  return (
    <TextField
      type="text"
      label={t("rentable_details.editor.reference_id")}
      {...register("reference_id")}
    />
  );
};
