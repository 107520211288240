import * as React from "react";
import { TextField, Flex, SelectField } from "@boligportal/juice";
import { RefundReasonsEnum } from "../enums";

const refundReasons = [
  RefundReasonsEnum.TechnicalError,
  RefundReasonsEnum.Error40,
  RefundReasonsEnum.InternalError,
  RefundReasonsEnum.Duplicate,
  RefundReasonsEnum.DiscountForCustomer,
  RefundReasonsEnum.CustomerValue,
  RefundReasonsEnum.Exception,
];

interface SelectFieldItem {
  value: any;
  label: any;
}

const RefundReasons = (props: {
  selectedReason: RefundReasonsEnum | undefined;
  refundReasonNote: string;
  onReasonChange: (reason: RefundReasonsEnum) => void;
  onRefundReasonTextChange: (text: string) => void;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    props.onReasonChange(value as RefundReasonsEnum);
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onRefundReasonTextChange(event.target.value);
  };

  const items: SelectFieldItem[] = [];
  if (props.selectedReason === undefined) {
    items.push({
      value: null,
      label: "Choose reason",
    });
  }
  refundReasons.forEach((reason) =>
    items.push({
      value: reason,
      label: reason,
    }),
  );

  return (
    <Flex column>
      <SelectField
        label="Reason for refund"
        items={items}
        onChange={handleChange}
        value={props.selectedReason}
      />

      {props.selectedReason &&
        props.selectedReason === RefundReasonsEnum.Exception && (
          <TextField
            label=""
            multiLine
            onChange={handleTextAreaChange}
            value={props.refundReasonNote}
          />
        )}
    </Flex>
  );
};

export { RefundReasons };
