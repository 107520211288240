import * as React from "react";

const SvgNorlysLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={60}
    height={12}
    fill="none"
    viewBox="0 0 60 12"
    {...props}
  >
    <g
      fill="#F8232D"
      fillRule="evenodd"
      clipPath="url(#norlys_logo_svg__norlys_logo_svg__clip0_1321_609)"
      clipRule="evenodd"
    >
      <path d="M7.61.236v7.072L1.912.236H-.167v11.158h2.303V4.237l5.768 7.157h2.018V.236H7.61zm40.259 0L45.25 5.024 42.632.236h-2.605l4.058 7.22v3.938h2.331V7.457l4.058-7.22H47.87zm-13.221 0v11.158h7.65V9.066h-5.333V.236h-2.317zm-3.305 6.608a3.652 3.652 0 001.578-3.016c0-1.845-1.367-3.37-3.12-3.57a5.886 5.886 0 00-.49-.022h-5.514v11.158h2.317v-3.93h2.288l2.171 3.93h2.605l-2.363-4.273c.32-.131.496-.254.528-.277zm-1.96-1.702h-3.297V2.573h3.185c.171.005.346.017.535.08a1.3 1.3 0 01.812 1.22c0 .7-.555 1.269-1.236 1.269zm26.882-.372c-.532-.121-1.163-.218-1.72-.304-.393-.06-.764-.118-.964-.163-.81-.186-.807-.719-.805-.954.005-.587.66-.936 1.307-.987a4.666 4.666 0 01.818.002c.847.054 1.34.505 1.481.849.044.108.066.174.066.174h2.26c.001-.852-.404-1.704-1.114-2.338-.686-.613-1.608-.981-2.595-1.035a11.286 11.286 0 00-.889-.007c-2.05.065-3.605 1.493-3.62 3.322-.014 1.62.98 2.855 2.595 3.224.278.064.669.124 1.122.194.52.08 1.11.17 1.565.274 1.114.253 1.109.874 1.107 1.078-.006.77-.728 1.028-1.263 1.113a4.19 4.19 0 01-.85.07 6.797 6.797 0 01-.878-.052 1.965 1.965 0 01-1.182-.554 1.29 1.29 0 01-.352-.657h-2.256c0 .834.346 1.657.983 2.312.868.892 1.941 1.227 3.068 1.262.37.01.508.012.937.01 1.078-.008 1.97-.218 2.849-.899.797-.618 1.224-1.55 1.232-2.585.014-1.683-1.097-2.939-2.9-3.35zM21.043 1.363C20.265.567 19.274.1 18.207.028 17.78-.002 17.433 0 17.433 0v2.412s.64.033.962.118c.889.236 1.587 1.091 1.637 2.114.057 1.159.061 1.295 0 2.308-.06 1.03-.76 1.895-1.643 2.127-.372.097-.956.13-.956.13v2.4s.482-.008.774-.027c1.072-.076 2.053-.542 2.83-1.342a4.92 4.92 0 001.378-3.138c.066-1.113.062-1.338 0-2.583a4.852 4.852 0 00-1.373-3.156zM15.509.028c-1.067.072-2.057.54-2.835 1.335a4.852 4.852 0 00-1.373 3.157c-.062 1.244-.066 1.469 0 2.582a4.92 4.92 0 001.378 3.138c.777.8 1.758 1.266 2.83 1.341.292.02.774.028.774.028v-2.4s-.584-.032-.956-.13c-.884-.232-1.583-1.098-1.644-2.127-.06-1.013-.056-1.149 0-2.308.052-1.023.75-1.878 1.638-2.114.322-.086.962-.118.962-.118V0s-.347 0-.774.028z" />
    </g>
    <defs>
      <clipPath id="norlys_logo_svg__norlys_logo_svg__clip0_1321_609">
        <path
          fill="#fff"
          d="M0 0h59.33v12H0z"
          transform="translate(-.167)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNorlysLogo;
