import * as React from "react";
import { useForm } from "react-hook-form";
import {
  Text,
  Dialog,
  DialogContent,
  DialogHeader,
  Divider,
  Flex,
  TextField,
  Spinner,
  Center,
  Notice,
  Box,
  Button,
  IconAdd,
  DialogFooter,
  styled,
} from "@boligportal/juice";
import { usePaymentRecordNoteMutation } from "api/mutations";
import {
  useGetPaymentRecordDetails,
  useMerchantPlacesQuery,
} from "api/queries";
import { TenancyPaymentProviderStatus } from "business/domain/tenancy/TenancyPaymentProviderStatus";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { canUseMarkAsManuallyPaid } from "business/domain/tenancy/canUseMarkAsManuallyPaid";
import { shouldDisplayPaymentInfoNotice } from "business/domain/tenancy/shouldDisplayPaymentInfoNotice";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { WordWrapButton } from "components/buttons/WordWrapButton";
import { isPast } from "date-fns";
import { usePaymentRecordChangeStatus } from "features/rent_collection/usePaymentRecordChangeStatus";
import { usePaymentRecordDeletion } from "features/rent_collection/usePaymentRecordDeletion";
import { usePaymentRecordOneTimePayment } from "features/rent_collection/usePaymentRecordOneTimePayment";
import { useTransferArrears } from "features/rent_collection/useTransferArrears";
import { Collapser } from "features/rentable_tenancy/components/PaymentRecordDetailSliderOver/components/Collapser";
import { calculateArrears } from "features/rentable_tenancy/helpers/calculateArrears";
import { calculateTenancyPaymentsSum } from "features/rentable_tenancy/helpers/calculateTenancyPaymentsSum";
import { isDateInPast, localizedMonthDayAndYear } from "lib/date";
import { t } from "lib/i18n";
import { formatCurrency } from "lib/utils";
import { TenancyPaymentStatusControl } from "../TenancyPaymentStatusControl";
import { PaymentActivities } from "./components/PaymentActivities";
import { PaymentHistory } from "./components/PaymentHistory";
import { PaymentSection } from "./components/PaymentSection";

type Props = {
  open: boolean;
  paymentRecordId: number;
  onCancel: () => void;
  shortcutToTenancyOverview: React.ReactNode;
  shortcutToRentableOverview: React.ReactNode;
  shortcutToTenancySetupRent: React.ReactNode;
  invalidationQueries: string[];
};

export const PaymentRecordDetailSliderOver = ({
  open,
  paymentRecordId,
  onCancel,
  shortcutToTenancyOverview,
  shortcutToRentableOverview,
  shortcutToTenancySetupRent,
  invalidationQueries,
}: Props) => {
  const {
    setPaymentRecordOneTimePaymentCandidate,
    isActive: isOneTimePaymentDialogActive,
  } = usePaymentRecordOneTimePayment();
  const {
    setPaymentStatusChangeCandidate,
    isActive: isStatusChangeDialogActive,
  } = usePaymentRecordChangeStatus();
  const { setPaymentRecordDeletionCandidate, isActive: isDeletionActive } =
    usePaymentRecordDeletion();
  const { getPaymentRecordDetailsQuery } =
    useGetPaymentRecordDetails(paymentRecordId);
  const paymentRecordNoteMutation = usePaymentRecordNoteMutation(
    paymentRecordId,
    invalidationQueries,
  );
  const { data } = getPaymentRecordDetailsQuery;
  const { user } = useUser();
  const { setTransferArrearsCandidate, isActive: isTransferArrearsActive } =
    useTransferArrears();

  const shouldShowMobilePayProviderName = Boolean(
    user?.payment_connections.MOBILEPAY && data?.paymentSubscriptionProviderId,
  );
  const merchantPlacesQuery = useMerchantPlacesQuery(
    shouldShowMobilePayProviderName,
  );

  const { register, handleSubmit, setValue } = useForm<{ note: string }>();

  const hasNestedDialogs =
    isOneTimePaymentDialogActive ||
    isStatusChangeDialogActive ||
    isDeletionActive ||
    isTransferArrearsActive;

  const onSubmitHandler = (form: { note: string }) => {
    paymentRecordNoteMutation.mutate(form.note);
  };

  React.useEffect(() => {
    if (getPaymentRecordDetailsQuery.data) {
      setValue("note", getPaymentRecordDetailsQuery.data.note);
    }
  }, [getPaymentRecordDetailsQuery, setValue]);

  const paymentTypeTranslation = {
    [TenancyPaymentType.MOBILEPAY]: t("common.mobilepay"),
    [TenancyPaymentType.MANUAL_BANK_TRANSFER]: t("common.account.transfer"),
    [TenancyPaymentType.BETALINGS_SERVICE]: t("common.betalings_service"),
  };

  const tenancyPaymentProviderIdDisplayName = (id: string) =>
    merchantPlacesQuery.data?.SubscriptionProviders.find(
      (provider) => provider.SubscriptionProviderId === id,
    )?.Name;

  const items =
    data &&
    (data.rentRegulation.length > 0 ? data.rentRegulation : data.recurringRent);

  const totalRent =
    items && data
      ? calculateTenancyPaymentsSum(items) +
        calculateTenancyPaymentsSum(data.oneTimePayments)
      : 0;

  const { arrears } = calculateArrears(totalRent, data?.transactions ?? []);

  const paymentHasNegativeArrearsAndIsInThePast =
    arrears < 0 && data && isPast(new Date(data?.paymentDueDate));

  return (
    <Dialog
      open={open}
      slideOver
      onClose={onCancel}
      hideOnClickOutside={!hasNestedDialogs}
    >
      <DialogHeader>{t("rentable.rent.details_about_payment")}</DialogHeader>

      <DialogContent>
        {!data && (
          <DialogContent>
            <Center height="100%">
              <Spinner />
            </Center>
          </DialogContent>
        )}
        {data && (
          <>
            {(shouldDisplayPaymentInfoNotice(
              data.paymentInfo,
              data.paymentStatus,
            ) ||
              paymentHasNegativeArrearsAndIsInThePast) && (
              <>
                <Box mb={3}>
                  <PaymentInfoMessage
                    markAsPaid={
                      <StyledInfoBoxButton
                        text
                        variant="branded"
                        href="#"
                        onClick={() => {
                          setPaymentStatusChangeCandidate({
                            wasManuallyChanged:
                              data.paymentInfo.wasManuallyChanged,
                            fromStatus: data.paymentStatus,
                            toStatus: TenancyPaymentStatus.PAID,
                            paymentProviderStatus:
                              data.paymentInfo.paymentProviderState,
                            paymentRecordId: data.paymentRecordId,
                            paymentType: data.paymentType,
                            transactions: {
                              items: data.transactions,
                            },
                            rent: totalRent,
                            arrears,
                          });
                        }}
                      >
                        {t("payment.status_change.paid.confirm_text")}
                      </StyledInfoBoxButton>
                    }
                    transferArrears={
                      <StyledInfoBoxButton
                        text
                        variant="branded"
                        href="#"
                        onClick={() =>
                          setTransferArrearsCandidate({
                            tenancyId: data.tenancyId,
                            paymentRecordId: data.paymentRecordId,
                            arrears: arrears,
                            paymentDueDate: data.paymentDueDate,
                          })
                        }
                      >
                        {t("payment.transfer_arrears_to_next_payment")}
                      </StyledInfoBoxButton>
                    }
                    status={data.paymentStatus}
                    message={
                      data.paymentInfo.messageKey
                        ? t(data.paymentInfo.messageKey)
                        : t("payment.info_message.arrears", {
                            totalRent: formatCurrency(
                              totalRent,
                              App.settings.currency,
                            ),
                            transactionsAmount: formatCurrency(
                              totalRent - arrears * -1,
                              App.settings.currency,
                            ),
                            missingAmount: formatCurrency(
                              arrears * -1,
                              App.settings.currency,
                            ),
                          })
                    }
                  />
                </Box>

                <Divider
                  mb={3}
                  mx={{
                    xs: -2,
                    sm: -3,
                  }}
                />
              </>
            )}

            <Collapser
              title={t("rentable.rent.details_about_payment")}
              autoCollapseOnMobile={false}
            >
              <Flex
                justify="space-between"
                align="center"
                wrap
              >
                <Text>{t("rentable.rent.tenancy")}</Text>
                {shortcutToTenancyOverview}
              </Flex>

              <Flex
                justify={"space-between"}
                align="center"
                mt={2}
                wrap
              >
                <Text>{t("rentable.rent.rentable")}</Text>
                {shortcutToRentableOverview}
              </Flex>

              <Flex
                justify={"space-between"}
                align="center"
                my={2}
                wrap
              >
                <Text>{t("common.charging")}</Text>
                <Text weight="semiBold">
                  {paymentTypeTranslation[data.paymentType]}
                </Text>
              </Flex>
              {shouldShowMobilePayProviderName && (
                <Flex
                  justify={"space-between"}
                  align="center"
                  my={2}
                  wrap
                >
                  <Text>{t("common.payment_provider")}</Text>

                  <Text weight="semiBold">
                    {tenancyPaymentProviderIdDisplayName(
                      data.paymentSubscriptionProviderId,
                    )}
                  </Text>
                </Flex>
              )}
              {shortcutToTenancySetupRent}
            </Collapser>
            <Divider
              my={3}
              mx={{
                xs: -2,
                md: -3,
              }}
            />

            <Collapser
              title={t("rentable.rent.payment")}
              autoCollapseOnMobile={false}
            >
              <Flex
                justify={"space-between"}
                align="center"
                my={2}
                wrap
              >
                <Text>{t("Date")}</Text>
                <Text>
                  {localizedMonthDayAndYear(new Date(data.paymentDueDate))}
                </Text>
              </Flex>

              <Flex
                justify="space-between"
                align="center"
              >
                <Text block>{t("rentable.rent.payment_state")}</Text>

                <TenancyPaymentStatusControl
                  size="tiny"
                  value={data.paymentStatus}
                  onChange={(value) =>
                    setPaymentStatusChangeCandidate({
                      wasManuallyChanged: data.paymentInfo.wasManuallyChanged,
                      fromStatus: data.paymentStatus,
                      toStatus: value as TenancyPaymentStatus,
                      paymentProviderStatus:
                        data.paymentInfo.paymentProviderState,
                      paymentRecordId: data.paymentRecordId,
                      paymentType: data.paymentType,
                      transactions: {
                        items: data.transactions,
                      },
                      rent: totalRent,
                      arrears,
                    })
                  }
                />
              </Flex>
              <Divider my={3} />
              <PaymentSection
                oneTimePayments={data.oneTimePayments}
                recurringRent={data.recurringRent}
                rentRegulation={data.rentRegulation}
                onTimePaymentTrigger={
                  <WordWrapButton
                    text
                    variant="primary"
                    onClick={() => {
                      setPaymentRecordOneTimePaymentCandidate({
                        dueDate: data.paymentDueDate,
                        oneTimePayments: data.oneTimePayments,
                        paymentRecordId: data.paymentRecordId,
                        providerStatus: data.paymentInfo.paymentProviderState,
                        rentItems:
                          data.rentRegulation.length > 0
                            ? data.rentRegulation
                            : data.recurringRent,
                        tenancyId: data.tenancyId,
                        paymentType: data.paymentInfo.paymentMethodType,
                        wasManuallyChanged: data.paymentInfo.wasManuallyChanged,
                        isPaymentEditable: data.isPaymentEditable,
                      });
                    }}
                    iconAfter={IconAdd}
                  >
                    {t("rentable.rent.add_one_time_payment")}
                  </WordWrapButton>
                }
              />

              {canUseMarkAsManuallyPaid(
                data.paymentStatus,
                data.paymentType,
              ) && (
                <Button
                  text
                  variant="primary"
                  onClick={() => {
                    setPaymentStatusChangeCandidate({
                      wasManuallyChanged: data.paymentInfo.wasManuallyChanged,
                      fromStatus: data.paymentStatus,
                      toStatus: TenancyPaymentStatus.PAID,
                      paymentProviderStatus:
                        data.paymentInfo.paymentProviderState,
                      paymentRecordId: data.paymentRecordId,
                      paymentType: data.paymentType,
                      transactions: {
                        items: data.transactions,
                      },
                      rent: totalRent,
                    });
                  }}
                >
                  {t("rentable.rent.mark_as_manual_paid")}
                </Button>
              )}

              <Divider my={3} />
              <Text weight="headline">
                {t("rentable.rent.partial_payments_title")}
              </Text>
              <PaymentHistory
                paymentRecordId={data.paymentRecordId}
                transactions={data.transactions}
                tenancyId={data.tenancyId}
              />
            </Collapser>

            <Divider
              my={3}
              mx={{
                xs: -2,
                sm: -3,
              }}
            />

            <Collapser title={t("rentable.rent.activity")}>
              <PaymentActivities activities={data.eventLog} />
            </Collapser>

            <Divider
              my={3}
              mx={{
                xs: -2,
                sm: -3,
              }}
            />

            <Collapser title={t("rentable.rent.notes")}>
              <form onBlur={handleSubmit(onSubmitHandler)}>
                <TextField
                  multiLine={5}
                  {...register("note")}
                />
              </form>
            </Collapser>

            <Box pt={2}>
              <Button
                fontSize="small"
                onClick={() => {
                  setPaymentRecordDeletionCandidate({
                    paymentRecordId: data.paymentRecordId,
                    onDeleteSuccess: onCancel,
                  });
                }}
                text
                variant="danger"
              >
                {t("rentable.rent.delete_payment")}
              </Button>
            </Box>
          </>
        )}
      </DialogContent>

      {data &&
        isSentForAutomaticPaymentAndPassedDueDate(
          new Date(data.paymentDueDate),
          data.paymentType,
          data.paymentInfo.paymentProviderState,
        ) && (
          <DialogFooter>
            <Flex
              justify="center"
              flexGrow
              textAlign={{
                xs: "center",
                md: "left",
              }}
            >
              <Text>{t("rentable.rent.sent.cant_edit")}</Text>
            </Flex>
          </DialogFooter>
        )}
    </Dialog>
  );
};

type PaymentInfoMessageProps = {
  status: TenancyPaymentStatus;
  message: string;
  markAsPaid?: React.ReactNode;
  transferArrears: React.ReactNode;
};
const PaymentInfoMessage = ({
  status,
  message,
  markAsPaid,
  transferArrears,
}: PaymentInfoMessageProps) => {
  if (status === TenancyPaymentStatus.NOT_PAID && message.length > 0) {
    return (
      <Notice type="warning">
        {message}
        <Flex
          mt={1}
          wrap
        >
          {markAsPaid}
          <Box mx={1}>-</Box>
          {transferArrears}
        </Flex>
      </Notice>
    );
  }

  if (status === TenancyPaymentStatus.PAID && message.length > 0) {
    return (
      <Notice type="success">
        <Text size="small">{message}</Text>
      </Notice>
    );
  }

  return null;
};

const isSentForAutomaticPaymentAndPassedDueDate = (
  dueDate: Date,
  paymentType: TenancyPaymentType,
  providerStatus: TenancyPaymentProviderStatus | null,
) => {
  if (paymentType === TenancyPaymentType.MANUAL_BANK_TRANSFER) {
    return false;
  }

  if (providerStatus === null) {
    return false;
  }

  return isDateInPast(dueDate);
};

const StyledInfoBoxButton = styled(Button)`
  color: ${(props) => props.theme.colorPalette.yellow[700]} !important;
  text-decoration: underline !important;
`;
