import { useMemo } from "react";

export const useBrowserFeatureCheck = () => {
  const hasDateTimeLocalSupport = useMemo(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "dateTime-local");

    const notADateValue = "not-a-date";
    input.setAttribute("value", notADateValue);

    return input.value !== notADateValue;
  }, []);

  const hasTouchEventSupport = useMemo(() => {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  return {
    hasDateTimeLocalSupport,
    hasTouchEventSupport,
  };
};
