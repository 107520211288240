import { useEffect, useState, useCallback } from "react";
import { API, AccountInfo } from "lib/api";

interface Options {
  onSubscriptionActivated?: () => void;
}

export const useAccountPaymentFlow = ({ onSubscriptionActivated }: Options) => {
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<number | null>(null);

  useEffect(() => {
    const createAccountPaymentMethod = async () => {
      const paymentMethod = await API.createAccountPaymentMethod();
      setPaymentMethodId(paymentMethod.payment_method_id);
    };
    createAccountPaymentMethod();
  }, []);

  const handleSubscriptionActivated = useCallback(async () => {
    const newAccountInfo = await API.getAccountInfo();
    setAccountInfo(newAccountInfo);
    if (onSubscriptionActivated) {
      onSubscriptionActivated();
    }
  }, [onSubscriptionActivated]);

  return {
    accountInfo,
    paymentMethodId,
    handleSubscriptionActivated,
  };
};
