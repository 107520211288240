import { RentableCreationCandidate } from "features/eco_system/types";

export const getShouldCreateRentableWithEstate = (
  rentables: RentableCreationCandidate[],
) => {
  // We only want to create a rentable with an estate if there are more than one rentable being created
  const shouldCreateWithEstate =
    rentables.filter((rentable) => rentable.shouldCreate && !rentable.disabled)
      .length > 1;
  return shouldCreateWithEstate;
};
