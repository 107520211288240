import { Button } from "@boligportal/juice";

type Props = {
  isLoading: boolean;
  children: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit";
};

export const CreateRentableButton = ({
  onClick,
  isLoading,
  children,
  disabled,
  type = "button",
}: Props) => (
  <Button
    variant="primary"
    onClick={onClick}
    type={type}
    disabled={isLoading || disabled}
    loading={isLoading}
    testId="createRentableButton"
  >
    {children}
  </Button>
);
