import {
  Card,
  Text,
  Badge,
  Flex,
  Box,
  Button,
  styled,
  Hidden,
  SortingSelect,
  SelectField,
} from "@boligportal/juice";
import { App } from "components/app";
import { AdSubscription } from "components/interfaces/ad_subscription";
import { Market } from "components/markets/market_settings";
import { isPlanAlreadyActive } from "features/upsell/helpers";
import { t, tn } from "lib/i18n";
import { getProductName } from "lib/product";
import { calculatePrice, formatCurrency } from "lib/utils";
import { SellingPoint } from "../SellingPoint/SellingPoint";

const PurchaseButton = styled(Button)`
  min-width: 130px;
`;

const CardWithinCard = styled(Card)`
  background: theme.color.bg.tint;
  & {
    padding: ${(props) => props.theme.unit(2)};
  }
`;

const StyledCard = styled(Card)`
  background: theme.color.bg.base;
`;

const LineTroughText = styled(Text)`
  text-decoration: line-through;
`;

const StyledSortingSelect = styled(SortingSelect)`
  direction: unset !important;
`;

type Props = {
  benefits: string[];
  badgeText?: string;
  hasMultiplePlans?: boolean;
  isDisabled: boolean;
  activeUpsellSubscriptions: AdSubscription;
};

export const DisabledUpsellProduct = ({
  benefits,
  badgeText,
  hasMultiplePlans,
  isDisabled,
  activeUpsellSubscriptions,
}: Props) => {
  const price = formatCurrency(
    calculatePrice(
      activeUpsellSubscriptions.plan.price.total,
      activeUpsellSubscriptions.plan.price.percent_off,
    ),
    activeUpsellSubscriptions.plan.currency,
  );

  const subtotal = formatCurrency(
    calculatePrice(
      activeUpsellSubscriptions.plan.price.subtotal,
      activeUpsellSubscriptions.plan.price.percent_off,
    ),
    activeUpsellSubscriptions.plan.currency,
  );

  const originalPrice = activeUpsellSubscriptions.plan.price.percent_off
    ? formatCurrency(
        activeUpsellSubscriptions.plan.price.total,
        activeUpsellSubscriptions.plan.currency,
      )
    : "";

  const purchased = isPlanAlreadyActive(
    [activeUpsellSubscriptions],
    activeUpsellSubscriptions.plan,
  );

  const isSubscription = activeUpsellSubscriptions.plan.is_subscription;
  const planDurationText = activeUpsellSubscriptions.plan.is_subscription
    ? tn(
        "upsell_products.runningPeriod",
        activeUpsellSubscriptions.plan.duration_days,
      )
    : tn(
        "fixed_upsell_products.runningPeriod",
        activeUpsellSubscriptions.plan.duration_days,
      );

  const purchaseButtonText = purchased
    ? t("upsell.dialog.action_button_text_purchased")
    : t("upsell.dialog.action_button_text");

  return (
    <StyledCard>
      <Flex justify="space-between">
        <Flex align="center">
          <Text
            size="h3"
            weight="bold"
            mr={2}
          >
            {getProductName(activeUpsellSubscriptions.plan.product)}
          </Text>
          {badgeText && (
            <Badge
              state="success"
              variant="solid"
            >
              {badgeText}
            </Badge>
          )}
        </Flex>
        <Hidden
          on={{
            xs: true,
            sm: false,
          }}
        >
          <PurchaseButton
            variant="primary"
            disabled={isDisabled}
          >
            {purchaseButtonText}
          </PurchaseButton>
        </Hidden>
      </Flex>

      <Flex
        justify="space-between"
        mt={1}
        mb={2}
      >
        <Text
          size="h5"
          mr={2}
        >
          {originalPrice && (
            <LineTroughText mr={1}>{originalPrice}</LineTroughText>
          )}
          <b>{price} </b>
          {App.settings.market === Market.BOLIGPORTAL_DK && (
            <Text
              size="small"
              color="muted"
            >
              ({subtotal} {t("common.ex_vat")})
            </Text>
          )}
        </Text>
        <Hidden
          on={{
            xs: true,
            sm: false,
          }}
        >
          <Box textAlign="right">
            {hasMultiplePlans ? (
              <StyledSortingSelect
                disabled={isDisabled}
                label={t("upsell.selected_duration_label")}
                items={[
                  {
                    label: tn(
                      "fixed_upsell_products.runningPeriod",
                      activeUpsellSubscriptions.plan.duration_days,
                    ),
                    value: activeUpsellSubscriptions.plan.plan_id.toString(),
                  },
                ]}
                value={activeUpsellSubscriptions.plan.plan_id.toString()}
              />
            ) : (
              <Text
                block
                size="small"
                color="muted"
              >
                {planDurationText}
              </Text>
            )}

            {isSubscription && (
              <Text
                block
                size="small"
                color="muted"
              >
                {t("upsell.dialog.cancel_anytime")}
              </Text>
            )}
          </Box>
        </Hidden>
      </Flex>

      <CardWithinCard>
        {benefits.map((benefit) => (
          <SellingPoint
            key={benefit}
            text={benefit}
          />
        ))}
      </CardWithinCard>
      <Box
        hidden={{
          sm: true,
        }}
        textAlign="center"
      >
        <>
          <PurchaseButton
            fullWidths
            variant="primary"
            disabled={isDisabled}
          >
            {purchaseButtonText}
          </PurchaseButton>
          {hasMultiplePlans ? (
            <SelectField
              disabled={isDisabled}
              label={t("upsell.selected_duration_label")}
              items={[
                {
                  label: tn(
                    "fixed_upsell_products.runningPeriod",
                    activeUpsellSubscriptions.plan.duration_days,
                  ),
                  value: activeUpsellSubscriptions.plan.plan_id.toString(),
                },
              ]}
              value={activeUpsellSubscriptions.plan.plan_id.toString()}
            />
          ) : (
            <Text
              mt={1}
              block
              size="small"
              color="muted"
            >
              {planDurationText}
            </Text>
          )}

          {isSubscription && (
            <Text
              block
              size="small"
              color="muted"
            >
              {t("upsell.dialog.cancel_anytime")}
            </Text>
          )}
        </>
      </Box>
    </StyledCard>
  );
};
