import { useRef, useEffect } from "react";
import {
  useChooseOrCreateRentableFeature,
  useChooseOrCreateRentableState,
} from "./ChooseOrCreateRentableProvider";
import { ChooseOrCreateRentablePaginator } from "./components/ChooseOrCreateRentablePaginator";
import { ChooseOrCreateRentableSearchInput } from "./components/ChooseOrCreateRentableSearchInput";
import { RentablesDataTable } from "./components/RentablesDataTable";
import { RentableMobileDataList } from "./components/RentablesMobileDataList";
import { ChooseOrCreateRentableHeaderLayout } from "./components/layouts/ChooseOrCreateRentableHeaderLayout";
import { ChooseOrCreateRentableResponsiveLayout } from "./components/layouts/ChooseOrCreateRentableResponsiveLayout";

interface Props {
  headline: string;
  text: string;
  createNewRentableButton: JSX.Element;
}

export const ChooseOrCreateRentableMain = ({
  headline,
  text,
  createNewRentableButton,
}: Props) => {
  const isFirstLoad = useRef(true);
  const { loadRentables, showAddressDialog } =
    useChooseOrCreateRentableFeature();
  const { allCount } = useChooseOrCreateRentableState();

  useEffect(() => {
    loadRentables();
  }, [loadRentables]);

  useEffect(() => {
    if (allCount === 0 && isFirstLoad.current) {
      isFirstLoad.current = false;
      showAddressDialog();
    }
  }, [allCount, showAddressDialog]);

  return (
    <ChooseOrCreateRentableResponsiveLayout
      sharedHeaderComponent={
        <ChooseOrCreateRentableHeaderLayout
          headline={headline}
          text={text}
        />
      }
      sharedCreateButton={createNewRentableButton}
      sharedSearchComponent={<ChooseOrCreateRentableSearchInput />}
      sharedPaginatorComponent={<ChooseOrCreateRentablePaginator />}
      desktopRentalListComponent={<RentablesDataTable />}
      mobileRentalListComponent={<RentableMobileDataList />}
    />
  );
};
