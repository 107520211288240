import { useReducer, useCallback } from "react";
import { getRentables } from "api";
import {
  UpdatePaginationStartIndex,
  UpdateSearchValue,
} from "apps/rental_ads/interfaces/RentalAdsFeature";
import {
  ChooseOrCreateRentableFeature,
  ChoseRentable,
  LoadRentables,
} from "./interfaces/ChooseOrCreateRentableFeature";
import {
  ChooseOrCreateRentableState,
  initialChooseOrCreateRentableState,
} from "./interfaces/ChooseOrCreateRentableState";
import { ChooseOrCreateRentableReducer } from "./reducer/ChooseOrCreateRentableReducer";

interface UseChooseOrCreateRentableReturn {
  feature: ChooseOrCreateRentableFeature;
  state: ChooseOrCreateRentableState;
}

export const useChooseOrCreateRentable = (
  onChoseRentable: ChoseRentable,
): UseChooseOrCreateRentableReturn => {
  const [state, dispatch] = useReducer(
    ChooseOrCreateRentableReducer,
    initialChooseOrCreateRentableState,
  );

  const sorting = state.activeSortableColumn.column;
  const { descending } = state.activeSortableColumn;
  const { limit } = state;
  const search_term = state.searchValue;
  const { start_index } = state;

  const choseRental: ChoseRentable = (rentableId: number) => {
    onChoseRentable(rentableId);
  };

  const loadRentables: LoadRentables = useCallback(async () => {
    const response = await getRentables({
      filters: {
        search: search_term,
        sorting,
        descending,
      },
      limit: limit,
      start_index: start_index,
    });

    dispatch({
      type: "UPDATE_RENTABLES",
      rentables: response.data,
      filterCount: response.filter_count,
      allCount: response.all_count,
      start_index: start_index,
    });
  }, [search_term, sorting, descending, start_index, limit]);

  const updatePaginationStartIndex: UpdatePaginationStartIndex = (
    newStartIndex: number,
  ) => {
    dispatch({
      type: "UPDATE_PAGINATION_START_INDEX",
      newStartIndex,
    });
  };

  const updateSearchValue: UpdateSearchValue = (newSearchValue: string) => {
    dispatch({
      type: "UPDATE_SEARCH_VALUE",
      newSearchValue,
    });
  };

  const showAddressDialog = () => {
    dispatch({
      type: "SHOW_ADDRESS_DIALOG",
      shouldShowAddressDialog: true,
    });
  };

  const hideAddressDialog = () => {
    dispatch({
      type: "SHOW_ADDRESS_DIALOG",
      shouldShowAddressDialog: false,
    });
  };

  return {
    feature: {
      choseRental,
      loadRentables,
      showAddressDialog,
      hideAddressDialog,
      updatePaginationStartIndex,
      updateSearchValue,
    },
    state,
  };
};
